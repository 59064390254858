import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import Administrator from '@models/Administrator';
import { PowerShellOutType, PowerShellResultModel } from '@models/rmm/PowerShellResultModel';
import RmmCommand from '@models/rmm/RmmCommand';
import RmmCommandResponse from '@models/rmm/RmmCommandResponse';
import { RmmCommandType } from '@models/rmm/RmmCommandType';
import RmmHubResponse from '@models/rmm/RmmHubResponse';
import { ErrorHandlerService } from '@services/error-handler.service';
import { convertToCamelCase } from '@utils/convertToCamelCase';
import { generateUid } from '@utils/generateUid';
import { I18NextService } from 'angular-i18next';
import { ErrorsEnum, HttpResponseError, ResponseError, ToastService } from 'mbs-ui-kit';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { buffer, concatMap, delay, filter, finalize, first, switchMap, takeWhile, tap } from 'rxjs/operators';
import { forbiddenErrorText } from '../interceptors/error-handler.interceptor';
import { AudiencePermissionScope } from '../models/JwtToken';
import { AppPersistentStateService } from './app-persistent-state.service';
import { AuthService } from './auth.service';
import { ConfigurationService } from './configuration.service';
import { RmmWebsocketService } from './rmm-websocket.service';

const COMMAND_BASE_URL = 'api/Computers/rm';

@Injectable()
export class RmmCommandsService {
  public statCommandRes: BehaviorSubject<any> = new BehaviorSubject<any>('');

  private serverUrl: string;

  public sessionExpiredEvent = new EventEmitter<boolean>();
  private user: Administrator;

  constructor(
    private http: HttpClient,
    private rmmHub: RmmWebsocketService, // prettier
    private toast: ToastService,
    private auth: AuthService,
    private config: ConfigurationService,
    private appState: AppPersistentStateService,
    private i18nextService: I18NextService,
    private errorService: ErrorHandlerService
  ) {
    this.serverUrl = config.get('rmmBaseHref');
    this.auth.currentUser.subscribe((user) => (this.user = user));
  }

  sendCommand<TResult>(commandType: RmmCommandType, command: RmmCommand<TResult>, hid: string): void {
    this.sendCommandGetObservable(commandType, command, hid).subscribe((res) => {
      this.statCommandRes.next(res);
    });
  }

  sendCommandGetObservable<TResult>(
    commandType: RmmCommandType,
    command: RmmCommand<TResult>,
    hid: string,
    isActive = false
  ): Observable<RmmCommandResponse<TResult>> {
    return this.postCommand<RmmCommandResponse<TResult>>(commandType, command, hid, isActive);
  }

  /*
   * Execution of the command for PowerShell plugin
   *
   * @param command command description
   * @param hid target computer
   */
  sendPSCommand<TResult>(command: RmmCommand<TResult>, hid: string): Observable<RmmCommandResponse<TResult>> {
    return this.postPSCommand<RmmCommandResponse<TResult>>(command, hid);
  }

  /*
   * Execution of the command for other plugins
   *
   * @param commandType plugin type
   * @param command command description
   * @param hid target computer
   * @param isActive execute as active command
   */
  private postCommand<TResult>(
    commandType: RmmCommandType,
    command: RmmCommand<TResult>,
    hid: string,
    isActive: boolean
  ): Observable<TResult> {
    if (this.checkRmmPermission(isActive)) {
      // readonly provider tried to run active command
      const message = this.i18nextService.t('error:rmm:agent_option_disabled', { setting: 'Allow Remote Management' });
      this.toast.error(message);
      return throwError(() => new Error(message));
    }
    const commandString = command.toString();
    const invokeCommand = isActive ? 'invokeactivecommand' : 'invokecommand';
    const commandUrl = `${this.serverUrl}/${COMMAND_BASE_URL}/${hid}/commands/${invokeCommand}?commandName=${commandType}`;
    const request$ = this.http.post<TResult>(commandUrl, commandString);
    if (!isActive || this.canAccess(hid)) {
      return request$;
    } else {
      this.sessionExpiredEvent.emit(true);
      return this.auth.twoFAConfirmed.pipe(
        first(),
        // throw error if destroyed 2FA dialog
        // prevent send request for next confirm 2FA dialog
        switchMap((isConfirmed) =>
          isConfirmed
            ? request$.pipe(this.handleForbidden())
            : throwError({
                status: 403,
                error: { code: ErrorsEnum.RmmAccessDenied, title: 'Access denied', knownType: 403 } as ResponseError
              })
        )
      );
    }
  }

  /*
   * Execution of the command for PowerShell plugin
   *
   * @param command command description
   * @param hid target computer
   */
  private postPSCommand<TResult>(command: RmmCommand<TResult>, hid: string): Observable<TResult> {
    const commandString = command.toString();
    // Backend developers are lazy and ?commandName=PowerShellTerminalCmd will not be removed
    const commandUrl = `${this.serverUrl}/${COMMAND_BASE_URL}/${hid}/commands/invokepscommand?commandName=PowerShellTerminalCmd`;

    // don't check 2FA access before execution command
    // init powershell terminal will be request 2FA before
    return this.http.post<TResult>(commandUrl, commandString).pipe(this.handleForbidden());
  }

  /*
   * Execution of the command asynchronously for other plugins
   *
   * @param commandType plugin type
   * @param command command description
   * @param hid target computer
   * @param isActive execute as active command
   *
   * @throws {@link ResponseError}
   */
  sendCommandAsync<TResult>(commandType: RmmCommandType, command: RmmCommand<TResult>, hid: string, isActive = false): Observable<TResult> {
    if (this.checkRmmPermission(isActive)) {
      // readonly provider tried to run active command
      const message = this.i18nextService.t('error:rmm:agent_option_disabled', { setting: 'Allow Remote Management' });
      this.toast.error(message);
      return throwError(() => new Error(message));
    }
    return this.postCommandAsync(command, (cmdAsync) => this.postCommand<TResult>(commandType, cmdAsync, hid, isActive)).pipe(first());
  }

  /*
   * Execution of the command asynchronously for PowerShell plugin
   * Will be marked as complete after received the first `PowerShellOutType.End` message
   *
   * @param command command description
   * @param hid target computer
   */
  sendPsCommandAsync(command: RmmCommand<PowerShellResultModel>, hid: string): Observable<PowerShellResultModel> {
    const factory = (cmdAsync) => this.postPSCommand<string>(cmdAsync, hid);
    const request$ = this.postCommandAsync<string>(command, factory).pipe(
      switchMap((data) => {
        if (typeof data === 'string') {
          return of<PowerShellResultModel>(JSON.parse(data));
        }

        const errorResponse = convertToCamelCase<ResponseError>(data);
        // no way detect is error response
        if (errorResponse.code != undefined && errorResponse.priority != undefined) {
          if (errorResponse.code === ErrorsEnum.RmmAccessDenied) {
            this.toast.error(errorResponse && errorResponse.title ? errorResponse.title : forbiddenErrorText);
            this.sessionExpiredEvent.emit(true);
          }
          return throwError({ error: errorResponse });
        }

        return of<PowerShellResultModel>(JSON.parse(data));
      })
    );

    // prevent double finalize due buffer
    const bufferTrigger$ = new Subject<PowerShellResultModel>();

    let nextMessageNumber = 0;
    return request$.pipe(
      tap((m) => bufferTrigger$.next(m)),
      buffer(
        bufferTrigger$.pipe(
          filter((m) => m.number === nextMessageNumber),
          delay(100) // required for buffer because still empty before emit
        )
      ),
      filter((messages) => messages.length > 0),
      concatMap((messages) => {
        const orderMessages = messages.sort((m1, m2) => m1.number - m2.number);
        nextMessageNumber = orderMessages[orderMessages.length - 1].number + 1;

        return of(...orderMessages);
      }),
      takeWhile((data) => data.outType != PowerShellOutType.End, true)
    );
  }

  /*
   * Execution of the command asynchronously
   * The observable result  will self unsubscribe from `RmmWebsocketService` after complete or manual unsubscribe source observable
   *
   * NOTE: request with an error will be completed already
   *
   * @param command command description
   * @param commandRequestFactory factory to create http request
   *
   * @returns Observable with emit on specific messageId.
   */
  private postCommandAsync<TResult>(
    command: RmmCommand<TResult>,
    commandRequestFactory: (command) => Observable<TResult>
  ): Observable<TResult> {
    let message$: Observable<RmmHubResponse<string>>;

    return new Observable<TResult>((subscriber) => {
      this.rmmHub.init().then((status) => {
        if (status) {
          const messageId = generateUid();
          command.asyncID = messageId;

          message$ = this.rmmHub.subscribe<string>(messageId);
          const commandRequest$ = commandRequestFactory(command);

          commandRequest$.subscribe(
            (commandResponse: any) => {
              if (commandResponse.data.includes('AsyncID')) {
                message$.subscribe((m: any) => {
                  // m is RmmHubResponse<string> | ResponseError types
                  if (m.code && m.type && command.id !== 'Script') {
                    // in case of ResponseError and not for PowerShell
                    // PowerShell terminal has it's own errors handler
                    this.errorService.showToastWithDetails({ error: m, toastText: 'Something wrong, please...' });
                    subscriber.error(new HttpResponseError({ status: m.knownType, error: m }));
                  } else {
                    const data = JSON.parse(m.Data);
                    subscriber.next(data);
                  }
                });
              } else {
                subscriber.error(commandResponse);
              }
            },
            (err) => {
              subscriber.error(err);
            }
          );
        } else {
          subscriber.error(new HttpResponseError({ status: 503, error: { title: "Websocket isn't initialized" } }));
        }
      });
    }).pipe(
      // unsubscribe if complete, manual unsubscribe, error
      finalize(() => {
        message$ && this.rmmHub.unsubscribe(command.asyncID);
      })
    );
  }

  /*
   * Emit event on expire session
   */
  private handleForbidden<T>() {
    return (source: Observable<T>): Observable<T> => {
      return source.pipe(
        tap({
          error: (error: HttpResponseError) => {
            if (error && error.status === 403) {
              this.sessionExpiredEvent.emit(true);
            }
          }
        })
      );
    };
  }

  public canAccess(hid: string): boolean {
    const token = this.auth.decodedToken;
    return token.hasScope(AudiencePermissionScope.MBS_RM_ACCESS) && !token.isExpired() && token.hid === hid;
  }

  checkRmmPermission(isActive: boolean): boolean {
    return (
      this.user?.IsProvider &&
      this.appState.data.rmmSidepanel &&
      this.appState.data.rmmSidepanel.agentOptions &&
      this.appState.data.rmmSidepanel.agentOptions.rmmValues.readOnly &&
      isActive
    );
  }
}
