<mbs-table-nav *ngIf="showNav"
               (pageChange)="onPageChange($event)"
               (pageSizeChange)="handleChangePageSize($event)"
               (pageSwitch)="onPageSwitch($event)"
               (refresh)="refresh.emit()"
               (viewChange)="onViewChange($event)"
               [customContent]="navContent"
               [customControls]="customControls"
               [disableViewSwitch]="disableViewSwitch"
               [filters]="filters"
               [paginationOptions]="paginationOptions"
               [showExportToCSVButton]="showExportToCSVButton"
               [showRefreshButton]="showRefreshButton"
               [switcherView]="switcherView"
               [wideContentMode]="wideContentMode"
               [fullContentWidth]="fullContentWidth"
               [viewMode]="myViewMode">
</mbs-table-nav>

<div *ngIf="showTopControls"
     class="mbs-table-grid_accordion_controls">

  <div class="row align-items-center justify-content-between">

    <div class="col-auto mbs-table-grid_accordion_controls-checkbox">
      <mbs-checkbox *ngIf="data && data.length > 0"
                    (change)="topControlsCheckboxChangeHandler($event)"
                    [checked]="checkedTop"
                    [indeterminate]="indeterminateTop"
                    [label]="topControlsLabel"></mbs-checkbox>
    </div>

    <div *ngIf="topControlsDescription"
         class="col-auto mbs-table-grid_accordion_controls-description">
      {{ topControlsDescription }}
    </div>

  </div>

</div>

<ng-template #topControlsLabel>

  <ng-template [ngTemplateOutlet]="prependTopControlsLabel?.template"></ng-template>

  <span *ngIf="topControlsLabelSelected">
    {{ selectedItems?.length }}
  </span>

  <ng-template [ngTemplateOutlet]="appendTopControlsLabel?.template"></ng-template>

</ng-template>

<div class="mbs-table-grid-wrapper"
     [ngClass]="{ '-invalid': invalid && displayData.length }">

  <mbs-table *ngIf="myViewMode === sharedPersistentStateEnum.table"
             (changeSelected)="handleSelectedRow($event)"
             (clickTableElement)="handleClickTableElement($event)"
             (sort)="handleSort($event)"
             (selectAllOnAllPages)="setTotalSelectedItems($event)"
             (scrolled)="scrolled.emit()"
             (scroll)="scroll.emit($event)"
             [arrayRowsWithClasses]="arrayRowsWithClasses"
             [bindChildren]="bindChildren"
             [canSelectParent]='canSelectParent'
             [bindDisabledValues]="bindDisabledValues"
             [bindKeyForRowClass]="bindKeyForRowClass"
             [bindParentKey]="bindParentKey"
             [bindSelected]="bindSelected"
             [bindSelectedChildren]="bindSelectedChildren"
             [bordered]="borderedTable"
             [changeSortState]="changeSortState"
             [childHeaderClasses]="childHeaderClasses"
             [childRowsClasses]="childRowsClasses"
             [childrenTemplates]="childrenTemplates"
             [classesTable]="classesTable"
             [collapsibleMode]="collapsibleMode"
             [needChildrenPaddingLeft]="needChildrenPaddingLeft"
             [data]="displayData"
             [totalItems]="paginationOptions?.dataSize ? paginationOptions?.dataSize : totalItems"
             [totalSelectedItems]="totalSelectedItems"
             [getItems]="getItems"
             [headerTemplates]="headerTemplates"
             [headers]="headers"
             [height]="height"
             [highlightOpened]="highlightOpened"
             [hover]="hoverTable"
             [selectable]="selectable"
             [keepState]="keepState"
             [lazy]="lazy"
             [loadedChildren]="loadedChildren"
             [loaderType]="loaderType"
             [loading]="loading"
             [maxHeight]="maxHeight"
             [minHeight]="minHeight"
             [multipleSelectType]="multipleSelectType"
             [multipleSelect]="multipleSelect"
             [needToggleOnEndRow]="needToggleOnEndRow"
             [dependenceChildren]="dependenceChildren"
             [disableChildren]="disableChildren"
             [myTrackBy]="myTrackBy"
             [noDataMessage]="noDataMessage"
             [rotateSequence]="rotateSequence"
             [rowClasses]="rowClasses"
             [selectAllIgnoreDisabled]="selectAllIgnoreDisabled"
             [selectOnlyOnCheckboxClick]="selectOnlyOnCheckboxClick"
             [selectRowClass]="selectRowClass"
             [selectedItems]="selectedItems"
             [checkboxCellWidth]="checkboxCellWidth"
             [toggleCellWidth]="toggleCellWidth"
             [showCheckboxes]="showTableCheckboxes"
             [showCheckboxesForChildren]="showCheckboxesForChildren"
             [showChildrenHeaders]="showChildrenHeaders"
             [showHeaders]="showHeaders"
             [size]="sizeTable"
             [striped]="stripedTable"
             [subtitleHeaders]="subtitleHeaders"
             [staticHeightIfVoidData]="staticHeightIfVoidData"
             [templates]="cellTemplates"
             [virtualScrolling]="virtualScrolling"
             [virtualItemSize]="virtualItemSize"
             [virtualItemsNumber]="virtualItemsNumber"
             [showSelectAllHint]="showSelectAllHint"
             [entityName]="entityName"
             [selectedCountText]="selectedCountText"
             [selectAllButtonText]="selectAllButtonText"
             [cancelSelectionText]="cancelSelectionText"
             [infiniteScroll]="infiniteScroll"
             [infiniteScrollDistance]="infiniteScrollDistance"
             [infiniteScrollThrottle]="infiniteScrollThrottle"
             [infiniteScrollLoading]="infiniteScrollLoading"
             [scrollWindow]="scrollWindow">
  </mbs-table>


  <mbs-grid *ngIf="myViewMode === sharedPersistentStateEnum.grid"
            (changeSelectedCards)="handleSelectedRow($event)"
            (clickTableElement)="handleClickTableElement($event)"
            [data]="displayData"
            [headerTemplate]="gridHeaderTemplate"
            [headers]="headers"
            [isNeedSelectCard]="selectable"
            [loaderType]="loaderType"
            [loading]="loading"
            [multipleSelect]="multipleSelect"
            [multipleSelectType]="multipleSelectType"
            [noDataMessage]="noDataMessage"
            [selectedCards]="selectedItems"
            [bindSelected]="bindSelected"
            [bindSelectedChildren]="bindSelectedChildren"
            [selectCardClass]="selectCardClass"
            [templates]="cellTemplates"
            [tileTemplate]="tileTemplate"
            [maxHeight]="maxHeight"
            [minHeight]="minHeight"
            [myTrackBy]="myTrackBy">
  </mbs-grid>

</div>
