<mbs-sidepanel [id]="sidePanelId"
               side="right"
               innerContentType="inner"
               [isCreate]="false"
               [showDelete]="false"
               [actionNames]="{ save: ('buttons:saveChanges' | i18next), close: ('buttons:close' | i18next) }"
               [loading]="loadingSave"
               [loadingData]="loadingData"
               [disabledSave]="!isValidSidepanel()"
               [large]="true"
               [showExpandedCross]="true">
  <div header-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-name">
      {{ 'sidepanel-online-access:title' | i18next }}
    </div>
  </div>

  <ng-container body>
    <form [formGroup]="form">
      <section class="mbs-sidepanel_section">
        <div class="mbs-sidepanel_section-content">
          <div class="mbs-sidepanel_section-block">
            <mbs-form-group>
              <mbs-switcher formControlName="enableOnlineAccess"
                            id="enableOnlineAccess"
                            [label]="enableOnlineAccessLabel"></mbs-switcher>
              <div formGroupName="optionsForm"
                   class="mt-2">
                <mbs-checkbox formControlName="enableBackup"
                              [label]="enableBackupLabel"
                              class="ml-4 pl-3"></mbs-checkbox>
                <mbs-checkbox *ngIf="isConnectAvailable"
                              formControlName="enableConnect"
                              [label]="enableConnectLabel"
                              class="ml-4 pl-3 mt-2"></mbs-checkbox>
              </div>
            </mbs-form-group>
          </div>
        </div>
      </section>
      <section formGroupName="optionsForm"
               class="mbs-sidepanel_section -bordered">
        <div class="mbs-sidepanel_section-content">
          <div class="mbs-sidepanel_section-block">
            <mbs-form-group>
              <mbs-input id="alias"
                         formControlName="alias"
                         [placeholder]="'sidepanel-online-access:aliasPlaceholder' | i18next"
                         [label]="aliasLabel"
                         (change)="handleAliasChange()"></mbs-input>
            </mbs-form-group>
            <mbs-form-group *ngIf="('ShowLegacyPlans' | can:'read')">
              <mbs-input id="legacyURL"
                         formControlName="legacyURL"
                         [label]="legacyURLLabel"
                         [appendButtons]="appendButtonsLegacy"
                         (buttonClick)="handleURLButton($event)"
                         readonly="true"></mbs-input>
            </mbs-form-group>
            <mbs-form-group>
              <mbs-input id="commonURL"
                         formControlName="commonURL"
                         [label]="commonURLLabel"
                         [appendButtons]="appendButtonsCommon"
                         (buttonClick)="handleURLButton($event)"
                         readonly="true"></mbs-input>
            </mbs-form-group>
          </div>
        </div>
      </section>
      <section *ngIf="('CustomCertificatesBinding' | can:'read')"
               class="mbs-sidepanel_section -bordered">
        <div class="mbs-sidepanel_section-content">
          <div class="mbs-sidepanel_section-block">
            <ng-container>
              <mbs-form-group>
                <span class="row">
                  <span class="col font-weight-bold">{{'sidepanel-online-access:domainCustomization' | i18next}}</span>
                </span>
                <span class="row mt-2">
                  <span class="col">{{'sidepanel-online-access:youCanHaveDomain' | i18next}}</span>
                </span>
                <span class="row mt-1">
                  <span class="col"><strong>{{'sidepanel-online-access:domainExample' | i18next}}</strong></span>
                </span>
              </mbs-form-group>
              <mbs-button (click)="handleBindingRedirect()">
                {{'sidepanel-online-access:bindingRedirect' | i18next}}
              </mbs-button>
            </ng-container>
          </div>
        </div>
      </section>
    </form>
  </ng-container>
  <ng-template #enableOnlineAccessLabel>
    <span class="font-weight-bold">{{ 'sidepanel-online-access:allowOnlineAccess' | i18next }}</span>
  </ng-template>
  <ng-template #enableBackupLabel>
    {{ 'sidepanel-online-access:allowBackup' | i18next }}
  </ng-template>
  <ng-template #enableConnectLabel>
    {{ 'sidepanel-online-access:allowConnect' | i18next }}
  </ng-template>
  <ng-template #aliasLabel>
    <span>{{ 'sidepanel-online-access:alias' | i18next }}</span>
    <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
          [ngbTooltip]="'sidepanel-online-access:aliasText' | i18next"
          container="body"></span>
  </ng-template>
  <ng-template #legacyURLLabel>
    <span class="font-weight-bold">{{ 'sidepanel-online-access:legacyURL' | i18next }}</span>
  </ng-template>
  <ng-template #commonURLLabel>
    <span class="row align-items-center">
      <span class="col font-weight-bold">{{ 'sidepanel-online-access:commonURL' | i18next }}</span>
      <span class="col-auto bg-dark-100 text-white mbsOnlineAccessBetaLabel justify-content-center d-flex">{{ betaLabel }}</span>
    </span>
  </ng-template>
</mbs-sidepanel>
