<h4 *ngIf="enableTitle">{{ 'rmm.module:groupActions.stepConfigureAction.scriptLibraryTitle' | i18next: { format: 'title'
  } }}</h4>
<mbs-form-group [formGroup]="form">

  <div class="mb-2">
    <mbs-input-label class="font-weight-semibold"
                     [label]="'rmm-side-panel:powerShellTab.category' | i18next">
    </mbs-input-label>
    <mbs-select [items]="collections"
                bindLabel="name"
                [clearable]="false"
                [searchable]="false"
                formControlName="collection">
    </mbs-select>
  </div>

  <mbs-input-label class="font-weight-semibold"
                   [label]="'rmm-side-panel:powerShellTab.script' | i18next">
  </mbs-input-label>
  <mbs-select [items]="filteredEntries"
              bindLabel="name"
              [clearable]="false"
              [searchable]="false"
              formControlName="entry">
  </mbs-select>
  <ng-container *ngIf="entry?.body && entry?.accessLevel !== 'Common'">
    <div class="row mt-1">
      <div class="col-md-12">
        <div
             class="text-sm text-monospace p-3 text-break pre-wrap background-secondary border overflow-y-auto script-body-fixed-size">
          {{ entry?.body }}</div>
      </div>
    </div>
  </ng-container>
</mbs-form-group>

<ng-container *ngIf="entry?.description">
  <div class="row mt-2 mb-3">
    <div class="col-md-12 text-wrap text-break">
      <div class="font-weight-semibold mb-2">
        {{ 'rmm.module:groupActions.stepConfigureAction.scriptDescription' | i18next }}
      </div>
      <div>{{ entry.description }}</div>
    </div>
  </div>
</ng-container>

<form [formGroup]="form">
  <div class="mt-2">
    <mbs-input-label class="font-weight-semibold"
                     [label]="'rmm.module:groupActions.stepConfigureAction.scriptTimeout' | i18next">
    </mbs-input-label>
    <div class="row">
      <div class="col-2">
        <mbs-number [min]="form.get('scriptTimeMeasure').value.id === 0 ? 5 : 1"
                    [max]="form.get('scriptTimeMeasure').value.id === 0 ? 59 : 23"
                    formControlName="scriptTimeoutTime">
        </mbs-number>
      </div>
      <div class="col-4">
        <mbs-select formControlName="scriptTimeMeasure"
                    [items]="timeMeasureList"
                    [searchable]="false"
                    [clearable]="false">
        </mbs-select>
      </div>
    </div>

    <div class="mt-2">
      {{ 'rmm.module:groupActions.stepConfigureAction.scriptTimeoutDesc' | i18next }}
    </div>
  </div>
</form>
