import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ComputersFacade } from '@facades/computers.facade';
import AdministratorInCamelCase from '@models/AdministratorInCamelCase';
import Computer, { AgentType } from '@models/Computer';
import { InstallationView } from '@modules/wizards/steps/installation/installation.constants';
import { getBuildsSelectionConfig } from '@modules/wizards/try-rmm-for-providers/installation-step/installation-step.constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-installation-step',
  templateUrl: './installation-step.component.html',
  styleUrls: ['./installation-step.component.scss']
})
export class InstallationStepComponent {
  @Input() wizardType: string;
  @Input() autoInstall: boolean;
  @Input() selectedAdminList: AdministratorInCamelCase[];
  @Input() selectedComputerList: Computer[];
  @Input() isCurrent: boolean;
  @Input() buildGenerationLaunched = false;
  @Output() forceNextStep = new EventEmitter();
  @Output() setComputerWithInstalledRMM = new EventEmitter<Computer>();

  public readonly buildsSelectionConfig = getBuildsSelectionConfig(this.i18next);
  public readonly installationView = InstallationView.Rmm;
  public copied = false;

  public installationTitle: string;
  public installationDescription: string;
  private isInstalled = false;
  private pickedComputer: Computer;
  private rmmAgentInitialisationAverageTime = 9000;

  constructor(
    private i18next: I18NextPipe,
    private computersFacade: ComputersFacade, // install verification
    private facade: ComputersFacade
  ) {}

  ngOnInit(): void {
    this.getTexts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buildGenerationLaunched && this.buildGenerationLaunched === true) {
      this.autoInstall && this.automaticInstall();
    }
  }

  // automatic
  public automaticInstall(): void {
    (this.selectedComputerList || [])
      .filter((computer) => computer.online)
      .forEach((computer) => {
        this.facade
          .installAgent(computer, AgentType.RMM)
          .pipe(untilDestroyed(this))
          .subscribe((response) => {
            if (response.isErrorsInResultList === true) {
              this.installationDescription =
                response.ok === true
                  ? (this.installationDescription = this.i18next.transform('wizards:tryRmm:automatic_install_description_issue'))
                  : (this.installationDescription = this.i18next.transform('wizards:tryRmm:automatic_install_description_error'));
              this.autoInstall = false;
            } else {
              this.installationCheck();
            }
          });
      });
  }

  public onManualDownloadCompleted(computer: Computer): void {
    this.setComputerWithInstalledRMM.emit(computer);
    this.forceNextStep.emit(true);
  }

  private getTexts(): void {
    this.installationTitle = this.i18next.transform('wizards:tryRmm:automatic_install_title', { format: 'title' });
    this.installationDescription = this.i18next.transform('wizards:tryRmm:automatic_install_description');
  }

  private installationCheck(): void {
    const timerID = setInterval(() => {
      if (this.isInstalled) clearInterval(timerID);
      /* @todo Sergei I. on refactoring. RXJS interval can be use instead, better to use hook or receieve an event about success.
         @todo Also need to check logic and wait to all requested computers installed(not computers.length, which means 1 installed).
      */

      this.computersFacade
        .getComputers({ appIds: [AgentType.RMM] })
        .pipe(debounceTime(3000), untilDestroyed(this))
        .subscribe(({ data: computers }) => {
          if (computers?.length) {
            if (!this.pickedComputer) {
              this.pickedComputer = computers[0];
            } else {
              this.installationStepFinalSetup();
            }

            this.isInstalled = true;
          }
        });
    }, 8000);
  }

  // additional waiting time should be made for RMM Agent initialisation, before the next step
  private installationStepFinalSetup() {
    setTimeout(() => {
      this.setComputerWithInstalledRMM.emit(this.pickedComputer);
      this.forceNextStep.emit();
    }, this.rmmAgentInitialisationAverageTime);
  }
}
