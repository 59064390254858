<section class="d-flex flex-column h-100">
  <ng-container *ngIf="autoInstall">
    <header class="mb-3 text-center">
      <h4 class="mb-0">
        {{ installationTitle }}
      </h4>
    </header>
    <p class="text-xs text-center mb-5 pb-4">
      {{ installationDescription }}
    </p>
  </ng-container>

  <ng-content *ngTemplateOutlet="autoInstall ? bottomLoading : manualDownload"></ng-content>
  <ng-template #bottomLoading>
    <div class="flex-grow-2 d-flex flex-column justify-content-end">
      <div class="text-center">
        <span class="loader loader-primary"></span>
        <span class="text-xs">
          {{ 'wizards:tryRmm:automatic_install_bottom_text' | i18next }}
        </span>
      </div>
    </div>
  </ng-template>
</section>

<ng-template #manualDownload>
  <ng-container *ngIf="isCurrent">
    <mbs-wizards-installation-step [view]="installationView"
                                   [buildsSelectionConfig]="buildsSelectionConfig"
                                   (completed)="onManualDownloadCompleted($event)"></mbs-wizards-installation-step>
  </ng-container>
</ng-template>
