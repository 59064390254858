<div [ngClass]="{ '-selected-rows': selectable }"
     [ngStyle]="{'min-height': minHeight}"
     class="table-responsive"
     (sort)="handleSort($event)"
     [mbsTableSortGroup]="changeSortState">

  <ng-container *ngIf="parentScrollMode === 'infinite'; else noInfiniteScroll">

    <div [mbsTableSortGroup]="changeSortState"
         [ngClass]="classTableGridObject"
         [ngStyle]="{'max-height': maxHeight}"
         [rotateSequence]="rotateSequence"
         class="mbs-table-grid {{ classesTable }}"
         role="table"
         infiniteScroll
         [infiniteScrollThrottle]="infiniteScrollThrottle"
         [infiniteScrollDistance]="infiniteScrollDistance"
         [scrollWindow]="scrollWindow"
         (scrolled)="scrolled.emit()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>

  </ng-container>

  <ng-template #noInfiniteScroll>

    <div [mbsTableSortGroup]="changeSortState"
         [ngClass]="classTableGridObject"
         [ngStyle]="{'max-height': maxHeight,'min-height': minHeight}"
         [rotateSequence]="rotateSequence"
         (scroll)="scroll.emit($event)"
         class="mbs-table-grid {{ classesTable }}"
         role="table">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>

  </ng-template>

  <ng-template #textHeader
               let-column>

    <div *ngIf="column.sort"
         [ngClass]="headerClassToObject(column)"
         [sortable]="column.sort"
         class="mbs-table-grid_cell -sortable"
         role="cell">

      <div class="form-row overflow-hidden">

        <div class="col overflow-hidden">
          <mbs-text-ellipsis placement="top-left auto"
                             tooltipClass="tooltip-lg"
                             [tooltip]="column.name">
            {{ column.name }}
          </mbs-text-ellipsis>
        </div>

        <div class="col-auto px-0">
          <span sortArrow></span>
        </div>

      </div>

    </div>

    <div *ngIf="!column.sort"
         [ngClass]="headerClassToObject(column)"
         class="mbs-table-grid_cell"
         role="cell">
      {{ column.name }}
    </div>

  </ng-template>

  <ng-template #content>

    <div [ngClass]="{ 'position-sticky sticky-top': maxHeight }">

      <div *ngIf="showHeaders"
           class="mbs-table-grid_header mbs-table-grid_row"
           [ngStyle]="{'padding-left': !collapsibleMode && (bindChildren && showCheckboxes) ? checkboxCellWidth : ''}"
           [style.grid-template-columns]="rowStyles.gridHeaderColumns"
           role="row">

        <div *ngIf="showCheckboxes && (!bindChildren || collapsibleMode)"
             class="mbs-table-grid_cell">
          <mbs-checkbox *ngIf="multipleSelect"
                        [size]="size"
                        [(ngModel)]="allChecked"
                        [disabled]="isDisableHeaderCheckbox"
                        [indeterminate]="indeterminateMainCheckbox"></mbs-checkbox>
        </div>

        <div *ngIf="collapsibleMode"
             class="mbs-table-grid_cell">
        </div>

        <ng-container *ngFor="let column of headers">

          <div *ngIf="findHeaderTemplate(column) as template; else textHeaderOutlet"
               [ngClass]="headerClassToObject(column)"
               class="mbs-table-grid_cell"
               role="cell">
            <ng-container *ngTemplateOutlet="template; context: { $implicit: column }"></ng-container>
          </div>

          <ng-template #textHeaderOutlet>
            <ng-container *ngTemplateOutlet="textHeader; context: { $implicit: column }"></ng-container>
          </ng-template>

        </ng-container>

      </div>

      <ng-container *ngTemplateOutlet="selectAllTemplate"></ng-container>

    </div>

    <div [ngClass]="contentClass"
         class="mbs-table-grid_content">

      <mbs-loader *ngIf="loading"
                  [type]="loaderType"></mbs-loader>

      <div class="table-virtual-scroll"
           *ngIf="parentScrollMode === 'virtual'; else baseScrolling"
           [ngStyle]="{ height: virtualScrollViewportSize }">

        <cdk-virtual-scroll-viewport [itemSize]="virtualScrollItemSize"
                                     [minBufferPx]="minBufferPx"
                                     [maxBufferPx]="maxBufferPx"
                                     scrollViewport>
          <ng-container
                        *cdkVirtualFor="let row of (data || [])  templateCacheSize: 0; let rowIndex = index; trackBy: myTrackBy;">
            <ng-container *ngTemplateOutlet="tableRow; context: { row: row, rowIndex: rowIndex }"></ng-container>
          </ng-container>

          <ng-container *ngTemplateOutlet="noDataTemplate"></ng-container>

        </cdk-virtual-scroll-viewport>

      </div>

      <ng-template #baseScrolling>

        <ng-container *ngFor="let row of (data || []); let rowIndex = index; trackBy: myTrackBy">
          <ng-container *ngTemplateOutlet="tableRow; context: { row: row, rowIndex: rowIndex }"></ng-container>
        </ng-container>

        <ng-container *ngTemplateOutlet="noDataTemplate"></ng-container>

      </ng-template>

    </div>

  </ng-template>

</div>

<div *ngIf="infiniteScrollLoading"
     class="mt-2 d-flex justify-content-center">
  <span class="loader loader-dark"></span>
</div>

<ng-template #noDataTemplate>

  <ng-container *ngIf="!hasData() && !loading">

    <div class="mbs-table-grid_row d-flex align-items-center no-data"
         role="row">
      <ng-container *ngTemplateOutlet="noDataIsTemplate() ? noDataMessage : noDataDefault"></ng-container>
    </div>

  </ng-container>

</ng-template>

<ng-template #selectAllTemplate>

  <div *ngIf="showSelectAllHint && totalItems && data?.length && selectedItems?.length > 0"
       class="mbs-table-grid_row mbs-table-grid_hint bg-white">

    <div class="mbs-table-grid_cell -center d-flex "
         role="cell">

      <ng-container *ngIf="selectedCountText && isTemplate(selectedCountText);else countText"
                    [ngTemplateOutlet]="selectedCountText"></ng-container>

      <ng-template #countText> {{ selectedCountText || getSelectedCountTextDefault() }}&nbsp;</ng-template>

      <mbs-button *ngIf="!isSelectedAllOnAllPages()"
                  (click)="selectAllOnAllPagesHandle()"
                  customClasses="font-weight-semibold"
                  [isCtrl]="true">{{ selectAllButtonText || getSelectAllButtonTextDefault() }}</mbs-button>

      <mbs-button *ngIf="isSelectedAllOnAllPages()"
                  (click)="clearSelection()"
                  customClasses="font-weight-semibold"
                  [isCtrl]="true">{{ cancelSelectionText || cancelSelectionTextDefault }}</mbs-button>
    </div>

  </div>

</ng-template>

<ng-template #noDataDefault>

  <div class="mbs-table-grid_cell flex-grow-1 text-center"
       role="cell">
    {{ noDataMessage || noDataMessageDefault }}
  </div>

</ng-template>


<ng-template #tableRow
             let-row="row"
             let-rowIndex="rowIndex">

  <ng-container *ngIf="bindChildren || collapsibleMode">

    <div (click)="handleRowClick(row, true, $event)"
         [ngClass]="getClassListForRow(row.item, true)"
         [style.grid-template-columns]="collapsibleMode ? rowStyles?.gridTemplateColumns :subtitleStyles?.gridTemplateColumns"
         class="mbs-table-grid_row"
         role="row">

      <div *ngIf="showCheckboxes"
           [ngClass]="checkboxCellClass"
           class="mbs-table-grid_cell">

        <!-- parent checkbox -->
        <div class="mbs-table-grid_checkbox"
             [class.-checked]="isChecked(row, true)"
             [class.-indeterminate]="!collapsibleMode ? indeterminateState(row) : row.indeterminate"
             [class.-disabled]="disabledStateByRow(row.item)"
             [ngClass]="size ? '-' + size : ''"></div>

      </div>

      <div *ngIf="collapsibleMode && !needToggleOnEndRow"
           [ngClass]="{'align-items-center d-flex justify-content-center': lazy && row.loadingChildren}"
           class="mbs-table-grid_cell mbs-table-grid_cell-toggler"
           role="cell">

        <button *ngIf="(!lazy && bindChildren && row.item[bindChildren] && row.item[bindChildren].length > 0) ||
                        (lazy && !row.loadingChildren && !row.loadedChildren) ||
                        (lazy && row.loadedChildren && row.item[bindChildren] && row.item[bindChildren].length > 0)"
                class="ctrl ctrl-dark mbs-table-grid_toggler"
                [ngClass]="{ 'collapsed': isCollapsed(row.item) }"
                (click)="handleToggleCollapse($event, row)"></button>

        <span *ngIf="lazy && row.loadingChildren"
              class="loader loader-dark"></span>

      </div>

      <ng-container *ngFor="let template of myTemplates; let index = index;">

        <div [ngClass]="columnClassToObject(collapsibleMode ? headers : subtitleHeaders, index)"
             class="mbs-table-grid_cell"
             role="cell">
          <ng-container
                        *ngTemplateOutlet="template; context: {$implicit: row.item, row: row, rowIndex: rowIndex}"></ng-container>
        </div>

      </ng-container>

      <div *ngIf="collapsibleMode && needToggleOnEndRow"
           [ngClass]="{'align-items-center d-flex justify-content-center': lazy && row.loadingChildren}"
           class="mbs-table-grid_cell mbs-table-grid_cell-toggler"
           role="cell">

        <button *ngIf="(!lazy && bindChildren && row.item[bindChildren] && row.item[bindChildren].length > 0) ||
                        (lazy && !row.loadingChildren && !row.loadedChildren) ||
                        (lazy && row.loadedChildren && row.item[bindChildren] && row.item[bindChildren].length > 0)"
                class="ctrl ctrl-dark mbs-table-grid_toggler"
                [ngClass]="{ 'collapsed': isCollapsed(row.item) }"
                (click)="handleToggleCollapse($event, row)">
        </button>

        <span *ngIf="lazy && row.loadingChildren"
              class="loader loader-dark"></span>

      </div>

    </div>

    <div *ngIf="bindChildren && row.item[bindChildren] && row.item[bindChildren].length > 0 && (collapsibleMode ? !isCollapsed(row.item) : true)"
         [class.-collapsible]="collapsibleMode"
         class="mbs-table-grid_table-inner">

      <div *ngIf="collapsibleMode && showChildrenHeaders"
           [ngClass]="childHeaderClasses"
           [style.grid-template-columns]="subtitleStyles?.gridTemplateColumns"
           [style.paddingLeft]="collapsibleMode ? checkboxCellWidth : ''"
           class="mbs-table-grid_header mbs-table-grid_row "
           role="row">

        <div *ngIf="showCheckboxes && showCheckboxesForChildren"
             class="mbs-table-grid_cell"></div>

        <ng-container *ngFor="let column of subtitleHeaders">
          <ng-container *ngTemplateOutlet="textHeader; context: { $implicit: column }"></ng-container>
        </ng-container>

      </div>

      <ng-container *ngIf="collapsibleMode && virtualScrolling && (row.item[bindChildren]?.length > virtualItemsNumber); else
      noVirtualScrolling">

        <ng-scrollbar [ngStyle]="{ height: getCollapsableModeVSContainerHeight((row.item[bindChildren] || []).length)}">

          <cdk-virtual-scroll-viewport [itemSize]="getCollapsableModeVSContainerHeight((row.item[bindChildren] || []).length)"
                                       [minBufferPx]="minBufferPx"
                                       [maxBufferPx]="maxBufferPx"
                                       scrollViewport>

            <ng-container *cdkVirtualFor="let childRow of (row.item[bindChildren] || []); trackBy: myTrackBy">
              <ng-template [ngTemplateOutlet]="tableRow"
                           [ngTemplateOutletContext]="{ $implicit: childRow }"></ng-template>
            </ng-container>

          </cdk-virtual-scroll-viewport>

        </ng-scrollbar>

      </ng-container>

      <ng-template #noVirtualScrolling>

        <ng-container *ngFor="let childRow of (row.item[bindChildren] || []); trackBy: myTrackBy">
          <ng-template [ngTemplateOutlet]="tableRow"
                       [ngTemplateOutletContext]="{ $implicit: childRow }"></ng-template>
        </ng-container>

      </ng-template>

      <ng-template #tableRow
                   let-childRow>

        <div (click)="handleRowClick(childRow, false, $event);"
             [ngClass]="getClassListForRow(childRow, false)"
             [style.grid-template-columns]="collapsibleMode ? subtitleStyles?.gridTemplateColumns : rowStyles.gridTemplateColumns"
             [style.paddingLeft]="collapsibleMode && needChildrenPaddingLeft ? checkboxCellWidth : ''"
             class="mbs-table-grid_row "
             role="row">

          <div *ngIf="showCheckboxes && showCheckboxesForChildren"
               [ngClass]="checkboxCellClass"
               class="mbs-table-grid_cell">

            <!-- child checkbox -->
            <div class="mbs-table-grid_checkbox"
                 [class.-checked]="isChecked(childRow, false)"
                 [class.-disabled]="disabledStateByRow(row.item)"
                 [ngClass]="size ? '-' + size : ''"></div>

          </div>

          <ng-container *ngFor="let template of myChildrenTemplates; let childIndex = index;">

            <div [ngClass]="columnClassToObject(collapsibleMode ? subtitleHeaders : actualTableColumnsForStyles, childIndex)"
                 class="mbs-table-grid_cell"
                 role="cell">
              <ng-container
                            *ngTemplateOutlet="template; context: { $implicit: childRow, rowIndex: rowIndex, parent: row, parentItem: row.item }"></ng-container>
            </div>

          </ng-container>

        </div>

      </ng-template>

    </div>

  </ng-container>

  <ng-container *ngIf="!bindChildren">

    <div (click)="handleRowClick(row, true, $event)"
         [ngClass]="getClassListForRow(row.item, true)"
         [style.grid-template-columns]="rowStyles.gridTemplateColumns"
         class="mbs-table-grid_row"
         role="row">

      <div *ngIf="showCheckboxes"
           [ngClass]="checkboxCellClass"
           class="mbs-table-grid_cell">

        <!-- parent checkbox -->
        <div class="mbs-table-grid_checkbox"
             [class.-checked]="isChecked(row, true)"
             [class.-indeterminate]="indeterminateState(row)"
             [class.-disabled]="disabledStateByRow(row.item)"
             [ngClass]="size ? '-' + size : ''"></div>

      </div>

      <ng-container *ngFor="let template of myTemplates; let index = index">

        <div [class.-disabled]="disabledStateByRow(row.item)"
             [ngClass]="columnClassToObject(actualTableColumnsForStyles, index)"
             class="mbs-table-grid_cell"
             role="cell">
          <ng-container
                        *ngTemplateOutlet="template; context: { $implicit: row.item, row: row, rowIndex: rowIndex }"></ng-container>
        </div>

      </ng-container>

    </div>

  </ng-container>

</ng-template>
