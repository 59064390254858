import { formatDate, NgLocalization } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { AgentOptions } from '@models/AgentOptions';
import { OsType } from '@models/Computer';
import ProcessInfo from '@models/rmm/ProcessInfo';
import RmmCommand from '@models/rmm/RmmCommand';
import RmmLastStatData from '@models/rmm/RmmLastStatData';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppPersistentStateService } from '@services';
import { RmmService } from '@services/rmm.service';
import { getReversedTableSorting } from '@shared/utils/helpers/rmm/table-reversed-sorting';
import { exportToCsv } from '@utils/cvs';
import { baseDateFileNameFormatHoursAndMinutes, mediumDateWithTime } from '@utils/date';
import { FilterSearch } from '@utils/filterSearch';
import { hasActionsQueue } from '@utils/has-actions-queue';
import { InMemorySorter } from '@utils/inMemorySorter';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep, debounce } from 'lodash';
import {
  AutoFetchState,
  formatBytes,
  MbsPopupType,
  ModalService,
  ModalSettings,
  PaginationOptions,
  SidepanelService,
  SortEvent,
  TableHeader,
  TabsetItemDirective,
  ToastService
} from 'mbs-ui-kit';
import moment from 'moment';
import { forkJoin, interval, merge as rxmerge, noop, Observable, of, shareReplay, Subject, switchMap, throwError } from 'rxjs';
import { concatMap, debounceTime, delay, finalize, first, map, startWith, tap } from 'rxjs/operators';
import { SidepanelRmmComponent } from '../sidepanel-rmm.component';

enum RuntimeOperationEnum {
  KILL = 'kill',
  RUN = 'start'
}

function mapToastsMessage(operation: RuntimeOperationEnum) {
  return map((processNames: string[]) => {
    const userOperation = operation === RuntimeOperationEnum.KILL ? 'are kill' : 'was started';
    if (processNames.length > 5) {
      const message = `Selected processes ${userOperation}`;
      return [message];
    } else {
      return processNames.map((processName) => `Process "${processName}" was ${operation}ed`);
    }
  });
}

@UntilDestroy()
@Component({
  selector: 'mbs-runtime-tab',
  templateUrl: './runtime-tab.component.html'
})
export class RuntimeTabComponent implements OnInit {
  private emitKillToasts$: Subject<Observable<string>[]> = new Subject();
  private emitRunToasts$: Subject<Observable<string>[]> = new Subject();

  @Input() public hid: string;
  @Input() public readOnly: boolean;
  @Input() public isOnline: boolean;
  @Input() public agentOptions: AgentOptions;
  @Input() public computerName: string;
  @Input() isModal = false;

  public orderBy: SortEvent = { column: 'physicalMemoryUsage', direction: 'desc' };
  public paginationOptions: PaginationOptions;

  public runtimeLive = new Subject<ProcessInfo[]>();
  public rotateSequence = {
    asc: 'desc',
    desc: 'asc',
    '': 'asc'
  };

  public headers: TableHeader[] = [
    {
      name: 'Name',
      overflow: true,
      sort: 'name',
      gridColSize: '45fr',
      gridColMin: '40px'
    },
    {
      name: 'Memory',
      overflow: true,
      class: '-stretch text-right',
      sort: 'physicalMemoryUsage',
      gridColSize: '30fr',
      gridColMin: '105px'
    },
    {
      name: 'CPU',
      overflow: true,
      class: '-stretch text-right',
      sort: 'cpuUsagePercent',
      gridColSize: '20fr',
      gridColMin: '35px'
    }
  ];

  public defaultPaginationOptions = {
    maxSize: 3,
    rotate: true,
    pageSize: 10,
    dataSize: 0,
    page: 0
  };

  public updatedTime: string;
  public killedProcessList: ProcessInfo[] = [];
  public killProcessDisabled = false;

  private errorLogger = new Subject<AutoFetchState>();
  public totalProcessesInfo: Partial<ProcessInfo>;
  private flatAllProcesses: Array<ProcessInfo & { subProcesses?: ProcessInfo[] }> = [];
  public processes: ProcessInfo[] = [];

  public onLoad$: Subject<boolean> = new Subject();
  public refresh$: Subject<string> = new Subject();
  public loading$: Observable<boolean>;
  private close$: Observable<any>;

  private updateDataDebounce = debounce((data) => this.showData(data), 300);
  public searchQuery: string[];
  public model = '';

  public processName = new FormControl('', [Validators.required]);

  public selectedProcesses: ProcessInfo[] = [];

  public minInterval = 5; // sec
  public maxInterval = 120;
  public updateIntervalMask = {
    mask: 'size seconds',
    blocks: { size: { mask: Number, signed: true, min: this.minInterval, max: this.maxInterval } },
    lazy: false
  };
  public readonly alertType = MbsPopupType;
  public isMacOS: boolean;
  public updateInterval: FormControl;
  public runProcessExampleText = 'C:\\Windows\\System32\\notepad.exe';

  constructor(
    public rmmService: RmmService, //
    private rmmCommands: CommandService,
    private appState: AppPersistentStateService,
    private sidepanel: SidepanelService,
    private tabItem: TabsetItemDirective,
    private toastService: ToastService,
    private modal: ModalService,
    private store: Store,
    private localization: NgLocalization,
    private computersFacade: ComputersFacade,
    private i18n: I18NextPipe
  ) {
    // super();

    this.close$ = this.sidepanel.get(SidepanelRmmComponent).close.pipe(first());
    this.paginationOptions = cloneDeep(this.defaultPaginationOptions);
    this.updateInterval = new FormControl(this.appState.data.rmmSidepanel.updateInterval);

    this.updateInterval.valueChanges.pipe(untilDestroyed(this), debounceTime(3000)).subscribe((v) => {
      this.appState.data.rmmSidepanel.updateInterval = +v;
      if (this.isOnline) this.initInterval();
    });

    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (computer && computer.os) this.isMacOS = computer.os === OsType.apple;
      if (computer.os === OsType.linux) this.runProcessExampleText = 'apt install mc (yum install mc, zypper install mc)';
      if (computer.os === OsType.apple) this.runProcessExampleText = 'say Hello';
    });

    this.loading$ = this.onLoad$.pipe(hasActionsQueue());
  }

  ngOnInit(): void {
    this.onLoad$.next(true);
    this.handleSubTab(this.tabItem, false);

    this.runtimeLive.pipe(untilDestroyed(this)).subscribe((data) => {
      this.updatedTime = formatDate(new Date(), mediumDateWithTime, 'en-EU');
      if (!data) {
        return;
      }

      this.flatAllProcesses = Array.from(data);
      this.showData(data);
    });

    this.initStreams();

    this.initInterval();
    this.runtimeLive.pipe(first()).subscribe(() => this.onLoad$.next(false));
  }

  initStreams(): void {
    const toast$ = rxmerge(
      this.emitKillToasts$.pipe(
        concatMap((commands) => forkJoin(commands)),
        mapToastsMessage(RuntimeOperationEnum.KILL)
      ),
      this.emitRunToasts$.pipe(
        concatMap((commands) => forkJoin(commands)),
        mapToastsMessage(RuntimeOperationEnum.RUN)
      )
    ).pipe(
      concatMap((messages: string[]) =>
        of(...messages).pipe(
          delay(300),
          finalize(() => this.processName.reset(''))
        )
      )
    );

    toast$.pipe(debounceTime(300), untilDestroyed(this)).subscribe((message) => {
      this.toastService.success(message);
    });
  }

  initInterval(): void {
    // const currentTime = this.updateInterval.value * 1000;

    this.onLoad$.next(true);

    const updateInterval$ = this.updateInterval.valueChanges.pipe(startWith(this.updateInterval.value), shareReplay());

    const interval$ = updateInterval$.pipe(
      map((i) => i * 1000),
      debounceTime(500),
      switchMap((period) => interval(period).pipe(startWith(0)))
    );
    updateInterval$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.appState.data.rmmSidepanel.updateInterval = parseInt(value);
    });
    interval$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadData();
    });
  }

  refresh() {
    this.refresh$.next(this.updateInterval.value);
  }

  loadData() {
    this.rmmService.fetchLastData<RmmLastStatData<ProcessInfo>>('runtime', this.hid).subscribe((res: any) => {
      this.runtimeLive.next(res.data as ProcessInfo[]);
      this.onLoad$.next(false);
    });
  }

  showData(data: ProcessInfo[]): void {
    const filteredData = this.filterData(data, this.searchQuery);
    const nestedFilteredProcesses = this.getProcessesTree(filteredData);
    const summaryData = data.find((process) => process.processId === -1);
    let copyProcesses = nestedFilteredProcesses.map((parentProcess) => {
      const subProcesses = parentProcess.childs;
      if (!subProcesses || subProcesses.length === 0) {
        return parentProcess;
      }

      parentProcess.childs = InMemorySorter.sort(getReversedTableSorting(this.orderBy), parentProcess.childs, true);
      return parentProcess;
    });
    copyProcesses = InMemorySorter.sort(getReversedTableSorting(this.orderBy), copyProcesses, true);

    this.totalProcessesInfo = {
      cpuUsagePercent: summaryData?.cpuUsagePercent ? +summaryData.cpuUsagePercent.toFixed(1) : 0,
      physicalMemoryUsage: summaryData?.physicalMemoryUsage ? +summaryData.physicalMemoryUsage.toFixed(1) * 1024 : 0, // total data is in KB
      totalProcessorTime: copyProcesses?.reduce((acc, p) => acc + p.totalProcessorTime, 0) ?? 0
    };

    this.paginationOptions = {
      ...this.defaultPaginationOptions,
      dataSize: copyProcesses.length
    };

    this.processes = Array.from(copyProcesses);
  }

  getProcessesTree(data: ProcessInfo[]): ProcessInfo[] {
    const copyData = data.filter((d) => d.processId !== -1);

    const processes = new Map<number, ProcessInfo>(
      copyData.map((r) => {
        r.childs = [];
        return [r.processId, r];
      })
    );
    const rootProcesses: ProcessInfo[] = [];

    const getRootProcess = (p: ProcessInfo): ProcessInfo => {
      const parent = processes.get(p.parentId);
      if (!parent) {
        return p;
      }
      return parent.parentId === 0 ? parent : getRootProcess(parent);
    };

    for (const [id, process] of processes) {
      if (process.parentId === 0) {
        rootProcesses.push(process);
        continue;
      }

      const rootProcess = getRootProcess(process);
      if (rootProcess === process) {
        rootProcesses.push(process);
        continue;
      }

      if (!rootProcess.childs) {
        rootProcess.childs = [];
      }
      if (process.parentId === rootProcess.processId) {
        rootProcess.childs.push(process);
      }
    }

    return rootProcesses;
  }

  filterData(data: ProcessInfo[], filter: string[]): ProcessInfo[] {
    if (!filter || filter.length === 0) {
      return data;
    }

    return FilterSearch.filter(filter, ['name'], data);
  }

  handleSort(sort: SortEvent): void {
    this.orderBy = sort;
    this.showData(this.flatAllProcesses);
  }

  handleInputSearch(event): void {
    const word = event.target.innerText;
    this.updateFilters(word ? { words: [word] } : {});
  }

  handleProcessKill(killProcessTpl: TemplateRef<any>): void {
    this.modal
      .open(
        {
          header: { title: this.i18n.transform('rmm-side-panel:runTimeTab.killProcessTitle') },
          footer: { okButton: { text: this.i18n.transform('rmm-side-panel:runTimeTab.killBtn'), type: 'danger' } }
        },
        killProcessTpl
      )
      .then(() => {
        const command$ = this.selectedProcesses.map((s) => {
          const command = RmmCommand.create('Kill').addParam('ID', s.processId.toString());
          return this.rmmCommands.sendCommand('RuntimeCmd', command, this.hid, true).pipe(
            switchMap((response: any) => (response?.error?.code ? throwError(() => response) : of(response))),
            tap((value) => {
              const newKilledProcessList = this.killedProcessList.concat(this.selectedProcesses);
              this.killedProcessList = [...newKilledProcessList];

              const index = this.processes.findIndex((process) => s.processId === process.processId && s.name === process.name);
              if (index) {
                this.processes.splice(index, 1);
                this.processes = [...this.processes];
              }

              this.verifyKillProcessButtonState(true);
            }),
            map(() => s.name),
            first()
          );
        });

        this.emitKillToasts$.next(command$);
      })
      .catch(noop);
  }

  handleRunProgram(template: TemplateRef<any>): void {
    const settings: ModalSettings = {
      header: { title: `${this.i18n.transform('rmm-side-panel:runTimeTab.startProcessTitle')} ${this.computerName}` },
      footer: {
        okButton: {
          text: this.i18n.transform('buttons:run'),
          disabled$: this.processName.valueChanges.pipe(
            map((v) => !v),
            startWith(true)
          )
        }
      }
    };
    this.modal
      .open(settings, template)
      .then(() => {
        const command = RmmCommand.create('Start').addParam('PATH', this.processName.value);
        const command$ = this.rmmCommands.sendCommand('RuntimeCmd', command, this.hid, true).pipe(
          switchMap((response: any) => (response?.error?.code ? throwError(() => response) : of(response))),
          map(() => this.processName.value),
          untilDestroyed(this)
        );

        this.emitRunToasts$.next([command$]);
      })
      .catch(() => this.processName.reset(''));
  }

  handleSubTab(tabItem, loading = false) {
    !this.isModal && Promise.resolve().then(() => (tabItem.loading = loading));
  }

  updateFilters(event): void {
    if (event) {
      this.searchQuery = null;
      if (event.words) {
        this.searchQuery = event.words;
      }
    }
    this.updateDataDebounce(this.flatAllProcesses);
  }

  myTrackBy(index: number, item: ProcessInfo): number {
    return item.parentId * item.processId;
  }

  itemsChecked(items: ProcessInfo[]): void {
    const childs: ProcessInfo[] = [];

    items.forEach((item) => {
      item.childs.forEach((child) => {
        const itemWasFoundInTheMainList = items.some((filteringItem) => this.isTheSameProcess(filteringItem, child));
        if (!itemWasFoundInTheMainList) childs.push(child);
      });
    });

    const newItems = items.concat(childs);

    this.selectedProcesses = newItems.filter((process) => !this.isTheProcessInTheKilledList(process));
    this.verifyKillProcessButtonState(!this.selectedProcesses.length);
  }

  verifyKillProcessButtonState(disable: boolean): void {
    this.killProcessDisabled = disable ? true : this.selectedProcesses.some((value) => this.isTheProcessInTheKilledList(value));
  }

  isTheProcessInTheKilledList(process: ProcessInfo): boolean {
    return this.killedProcessList.some((killedProcess) => this.isTheSameProcess(killedProcess, process));
  }

  private isTheSameProcess(first: ProcessInfo, second: ProcessInfo): boolean {
    return first.processId === second.processId && first.name === second.name;
  }

  public export() {
    exportToCsv(
      this.getTableName(),
      this.headers.map((header) => header.name),
      this.headers.map((header) => header.sort),
      this.processes.map((process) => ({
        ...process,
        physicalMemoryUsage: formatBytes(process.physicalMemoryUsage)
      }))
    );
  }

  private getTableName(): string {
    return `${this.computerName} ${this.i18n.transform('rmm-side-panel:sidePanelTabsName.processes')} ${moment().format(
      baseDateFileNameFormatHoursAndMinutes
    )}`;
  }
}
