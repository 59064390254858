import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addBreadcrumb, SeverityLevel } from '@sentry/browser';
import { ConfigurationService } from '@services/configuration.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {
  private unauthorizedErrorExcludedUrls = [
    this.configurationService.get('rmmBaseHref'),
    this.configurationService.get('rmmBaseSignalRHref'),
    this.configurationService.get('rmmScriptLibraryHref'),
    this.configurationService.get('rmmCustomSettings'),
    this.configurationService.get('licensesHref'),
    this.configurationService.get('appsBaseHref'),
    this.configurationService.get('deepInstinctHref')
  ];

  constructor(private configurationService: ConfigurationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const start = new Date().getTime();

    return next.handle(req).pipe(
      tap((res) => {
        if (res instanceof HttpResponse) {
          this.sendBreadcrumb(req, res, start);
        }

        return res;
      }),
      catchError((e: HttpErrorResponse) => {
        const url = new URL(e.url);
        const excludedByUnauthorizedError =
          e.status === HttpStatusCode.Unauthorized &&
          !this.unauthorizedErrorExcludedUrls.some((excludedUrl) => excludedUrl.includes(url.origin));

        if (e instanceof HttpErrorResponse && !excludedByUnauthorizedError) {
          this.sendBreadcrumb(req, e, start);
        }

        throw e;
      })
    );
  }

  private sendBreadcrumb(req: HttpRequest<any>, res: HttpResponse<any> | HttpErrorResponse, start): void {
    const now = new Date().getTime();
    const duration = (now - start) / 1000;
    addBreadcrumb({
      category: 'xhr',
      data: {
        method: req.method,
        url: req.url,
        status_code: res.status,
        duration,
        traceId: res.headers.get('trace-id')
      },
      timestamp: now / 1000,
      type: 'http',
      level: res instanceof HttpErrorResponse ? ('error' as SeverityLevel) : ('info' as SeverityLevel)
    });
  }
}
