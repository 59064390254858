import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { OsType } from '@models/Computer';
import { EventLogEntryTypeEnum } from '@models/rmm/EventLogInfo';
import HostInfo from '@models/rmm/HostInfo';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { TabsetItemDirective } from 'mbs-ui-kit';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, pluck } from 'rxjs/operators';
import { EventTotalTabMode } from './event-total-tab.model';
import { clearEventCharts, loadEventCharts } from './store/event-chart.actions';
import * as EventEntryFilterActions from './store/event-entry-filter.actions';
import { clearEventEntries, loadEventEntries } from './store/event-entry.actions';
import { ChangeMode, UpdateHost } from './store/event-total.actions';
import { State } from './store/event-total.reducer';
import { selectChartLoading, selectState } from './store/event-total.selectors';

@UntilDestroy()
@Component({
  selector: 'mbs-event-total-tab',
  templateUrl: './event-total-tab.component.html'
})
export class EventTotalTabComponent implements OnInit {
  @Input() hid: any;
  @Input() hostInfo: HostInfo;
  @Input() isOnline: boolean;

  modes: typeof EventTotalTabMode = EventTotalTabMode;
  state$: Observable<State>;
  mode$: Observable<EventTotalTabMode>;
  isMacOrLinuxOS$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  modalWindowTitle = this.i18n.transform('rmm-side-panel:eventTotalTab.eventTotalModalTitle');

  constructor(
    private store: Store,
    private tabItem: TabsetItemDirective,
    private computersFacade: ComputersFacade,
    private i18n: I18NextPipe
  ) {
    this.state$ = this.store.select(selectState);
  }

  ngOnChanges(_changes: SimpleChanges) {
    if (this.isOnline !== undefined && this.hid !== null && this.hostInfo) {
      this.store.dispatch(
        UpdateHost({
          online: this.isOnline,
          hid: this.hid,
          utcOffset: (this.hostInfo || ({} as Partial<HostInfo>)).utcOffsetInMinutes || 0,
          mode: this.isMacOrLinuxOS$.getValue() ? EventTotalTabMode.GRID : EventTotalTabMode.CHART
        })
      );
      this.initState();
    }
  }

  filter() {
    this.store.dispatch(
      EventEntryFilterActions.setActiveFilter({
        id: EventLogEntryTypeEnum.Info
      })
    );
  }

  changeMode() {
    this.store.dispatch(ChangeMode({}));
  }

  ngOnInit(): void {
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (computer && computer.hid) {
        this.hid = computer.hid;
      } else if (this.hid) {
        this.computersFacade.setSelected(this.hid);
      }
      this.isMacOrLinuxOS$.next([OsType.apple, OsType.linux].includes(computer?.os));
    });

    this.isMacOrLinuxOS$
      .pipe(
        debounceTime(300),
        distinctUntilChanged((previous, current) => previous !== current),
        untilDestroyed(this)
      )
      .subscribe((isMacOrLinuxOS) => {
        if (isMacOrLinuxOS) {
          this.modalWindowTitle = this.i18n.transform('rmm-side-panel:eventTotalTab.diagnosticCrashReportTitle');
          this.store.dispatch(ChangeMode({ mode: EventTotalTabMode.GRID }));
        }
        this.mode$ = this.store.select(selectState).pipe(pluck('mode'), untilDestroyed(this));
      });

    this.store
      .select(selectChartLoading)
      .pipe(untilDestroyed(this))
      .subscribe((loading) =>
        queueMicrotask(() => {
          this.tabItem.loading = loading;
          this.tabItem.detectChanges();
        })
      );
  }

  private initState(): void {
    this.state$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((a, b) => {
          return Math.abs(moment(a.start).diff(moment(b.start), 'minutes')) < 10 && a.logType === b.logType && a.mode === b.mode;
        })
      )
      .subscribe((state) => {
        if (this.isMacOrLinuxOS$.getValue()) state.mode = EventTotalTabMode.GRID;
        switch (state.mode) {
          case EventTotalTabMode.CHART:
            this.store.dispatch(clearEventCharts());
            this.store.dispatch(loadEventCharts({ state }));
            break;
          case EventTotalTabMode.GRID:
            this.store.dispatch(clearEventEntries());
            this.store.dispatch(loadEventEntries({ state }));
            break;
          default:
            break;
        }
      });
  }
}
