import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TFAService } from '@components/tfa/services/tfa.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProviderService } from '@services/provider.service';
import { mediumDateWithTimeMoment } from '@utils/date';
import { AbilityService } from 'ability';
import { ModalComponent } from 'mbs-ui-kit';
import moment from 'moment';
import OpenCrypto from 'opencrypto';
import { catchError, Observable, of, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-password-recovery-modal-component',
  templateUrl: './password-recovery-modal.component.html'
})
export class PasswordRecoveryModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  public readonly maxLengthRecoveryKeyControl = 6000;
  public readonly elementsSelector = { name: { recoveryKeyControl: 'recoveryKeyControl' } };

  public keysCreatedDate: string = new Date().toString();
  public dateFormat: string = mediumDateWithTimeMoment;
  public loading = false;
  public recoveryKeyControl: FormControl = new FormControl('', [Validators.required]);
  public gotError = false;
  public passwordCanBeRestored = true;

  private preDecodedKey: string;
  private crypt = new OpenCrypto();

  constructor(public ability: AbilityService, private providerService: ProviderService, private TFAService: TFAService) {}

  close = (): void => {
    this.baseModal.save();
  };

  dismiss = (): void => {
    this.baseModal.close();
  };

  ngOnInit() {
    this.loading = true;
    const backupPath = this.baseModal.data.path;
    const hid = this.baseModal.data.hid;

    this.setPasswordRecoveryData(hid, backupPath);

    this.recoveryKeyControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.gotError = false;
    });
  }

  show2FAConfirm(data): Promise<unknown> {
    return this.TFAService.openApproveModal(data);
  }

  setPasswordRecoveryServiceState(targetState: boolean, publicKey: string, mfaCode: string): Observable<any> {
    return this.providerService
      .setPasswordRecoveryState(targetState, publicKey ? JSON.parse(publicKey) : false, mfaCode)
      .pipe(untilDestroyed(this));
  }

  setPasswordRecoveryData(hid: string, backupPath: string): void {
    this.providerService
      .getPasswordRecoveryData(hid, backupPath)
      .pipe(
        take(1),
        catchError((error) => of(error.error))
      )
      .subscribe((res) => {
        if (res.authId) {
          this.show2FAConfirm(res)
            .then(() => this.setPasswordRecoveryData(hid, backupPath))
            .catch(() => this.baseModal.close())
            .finally(() => (this.loading = false));
        } else {
          if (res.data && res.keyTime) {
            this.preDecodedKey = res.data;
            this.keysCreatedDate = moment(res.keyTime).toString();
          } else {
            this.passwordCanBeRestored = false;
          }
          this.loading = false;
        }
      });
  }

  async decrypt(): Promise<string> {
    const val = this.recoveryKeyControl.value;
    let privateKey;
    try {
      privateKey = await this.crypt.pemPrivateToCrypto(val, {
        name: 'RSA-OAEP',
        hash: 'SHA-1',
        usages: ['decrypt'],
        isExtractable: true
      });
    } catch (e) {
      this.gotError = true;
      return new Promise((resolve, reject) => {
        reject(Error());
      });
    }

    const cypherText = b642ab(this.preDecodedKey.replace(/(.{48})/g, '$1\n'));
    const decodedBuffer = await window.crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP'
      },
      privateKey,
      cypherText
    );
    return atob(ab2b64(decodedBuffer));
  }

  handleRecovery(): void {
    this.decrypt()
      .then((pass: string) => {
        this.baseModal.save(pass);
      })
      .catch((err) => {
        this.recoveryKeyControl.setErrors({
          decrypt: {
            message: 'Failed to recover password. The specified recovery key is invalid'
          }
        });
        this.recoveryKeyControl.markAsDirty({ onlySelf: true });
      });
  }

  triggerUploadFile(): void {
    this.fileInput.nativeElement.click();
  }

  handleUploadFile(event): void {
    if (event.target.files && event.target.files[0]) {
      this.readFromFile(event.target.files[0]);
      this.gotError = false;
    }
  }

  private readFromFile(file) {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const key = atob(event.target.result.toString().split(',')[1]);
      this.recoveryKeyControl.setValue(key, { onlySelf: true, emitEvent: false });
    });
    reader.readAsDataURL(file);
  }
}

function ab2b64(arrayBuffer) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
}

function b642ab(base64string) {
  return Uint8Array.from(atob(base64string), (c) => c.charCodeAt(0));
}
