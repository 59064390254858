import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { BuildVersionType } from '@models/build';
import { AgentType, ComputersFiltersType, convertAgentTypeToNeededCase, GetComputersParams } from '@models/Computer';
import { AgentAvailableBuildVersions, AgentCommand, ComputerAvailableVersions, ComputerBasedModal } from '@models/ComputersModals';
import { GroupActionMode, ParamsForceUpdate, UpdateBuild } from '@models/rm/remote-command-model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BuildService } from '@services/build-service/build.service';
import { RmCommandsAbstractWrapper } from '@services/rm-commands.wrapper';
import { versionCompare } from '@utils/version-compare';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import {
  MbsPopupType,
  MbsSize,
  ModalComponent,
  ModalService,
  ModalSettings,
  SharedPersistentStateEnum,
  TableHeader,
  Toast,
  ToastService
} from 'mbs-ui-kit';
import { forkJoin, noop, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ForceUpdateAgentType } from './computers-force-update-modal-interface';

@UntilDestroy()
@Component({
  selector: 'mbs-computers-force-update-modal',
  templateUrl: './computers-force-update-modal.component.html',
  styleUrls: ['./computers-force-update-modal.component.scss']
})
export class ComputersForceUpdateModalComponent implements ComputerBasedModal, OnInit {
  public readonly forceUpdateText = 'Force Update';
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;
  public readonly alertType = MbsPopupType;
  // about computer
  public computer: ComputerAvailableVersions = null;
  public computerName = '';
  // about group computers
  public isSandBoxAvailable = false;
  public hids: string[] = [];
  // TODO: Remove it after removing legacy Computers page
  public legacyFilters = {
    searchQuery: null,
    unSupportedVersion: false,
    companyId: null,
    hidden: false
  };
  public filters: Observable<GetComputersParams>;
  // support Builds
  public backupAgent: AgentAvailableBuildVersions = null;
  public rmmAgent: AgentAvailableBuildVersions = null;
  public raAgent: AgentAvailableBuildVersions = null;
  public availableBuilds: AgentAvailableBuildVersions[];
  public isAvailableBackup = false;
  public isAvailableRMM = false;
  public isAvailableRA = false;
  // if using group action
  public mode = GroupActionMode.Single;
  // loading state
  public isLoading = true;
  public loading = false;
  // copy enum for using in view (HTML)
  public readonly forceUpdateAgentType = ForceUpdateAgentType;
  public readonly buildVersionType = BuildVersionType;
  public readonly agentType = AgentType;
  // headers for table in modal with error
  public headers: TableHeader[] = [
    {
      name: 'Icon',
      class: '-stretch pl-0',
      gridColSize: '1fr',
      gridColMin: '150px'
    },
    {
      name: 'Status Bar',
      gridColSize: '250px',
      gridColMin: '250px',
      class: '-stretch text-right'
    }
  ];
  // subheaders for table in modal with error
  public subHeaders: TableHeader[] = [
    {
      name: 'Message',
      class: '-stretch pl-0',
      gridColSize: '1fr'
    }
  ];

  @ViewChild('errorTemplate', { static: true }) private errorTemplate: TemplateRef<any>;
  @ViewChild(ModalComponent, { static: true }) private baseModal;

  public forceUpdateForm = new UntypedFormGroup(
    {
      backup: new FormControl(ForceUpdateAgentType.NotNecessary),
      rmm: new FormControl(ForceUpdateAgentType.NotNecessary),
      ra: new FormControl(ForceUpdateAgentType.NotNecessary)
    },
    [this.buildsValidator.bind(this)]
  );

  public get isGroupAction(): boolean {
    return [GroupActionMode.Group, GroupActionMode.Bulk].includes(this.mode);
  }

  constructor(
    private rmCommands: RmCommandsAbstractWrapper,
    private facade: ComputersFacade,
    private modalService: ModalService,
    private toastService: ToastService,
    private buildService: BuildService,
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe
  ) {}

  ngOnInit(): void {
    if (!this.isGroupAction) {
      forkJoin([this.buildService.isSandBoxAvailable(), this.facade.getAvailableVersions(this.computer.hid)]).subscribe(
        ([isSandBoxAvailable, computer]) => {
          this.isSandBoxAvailable = isSandBoxAvailable;
          this.computer = cloneDeep(computer);
          this.computerName =
            this.computer.displayName && this.computer.displayName !== '' ? this.computer.displayName : this.computer.name;

          this.computer.applications.forEach((agent: AgentAvailableBuildVersions) => {
            switch (agent.applicationId.toLowerCase()) {
              case AgentType.Backup:
                this.backupAgent = agent;
                return;
              case AgentType.RMM:
                this.rmmAgent = agent;
                return;
              case AgentType.RA:
                this.raAgent = agent;
                return;
            }
          });
          this.availableBuilds = [this.backupAgent, this.rmmAgent, this.raAgent].filter(
            (agent: AgentAvailableBuildVersions) => !(agent === null || agent === undefined)
          );
          this.isAvailableBackup = this.isAvailableVersion(this.backupAgent, false) || this.isAvailableVersion(this.backupAgent, true);
          this.isAvailableRMM = this.isAvailableVersion(this.rmmAgent, false) || this.isAvailableVersion(this.rmmAgent, true);
          this.isAvailableRA = this.isAvailableVersion(this.raAgent, false);
          this.updateForm();
          this.isLoading = false;
        }
      );
    } else {
      this.buildService.isSandBoxAvailable().subscribe((isSandBoxAvailable: boolean) => {
        this.isSandBoxAvailable = isSandBoxAvailable;
        this.updateForm();
        this.isLoading = false;
      });
    }
  }

  /*
   * Check is Agent force update supporting and online
   * @param agent
   */
  isForceUpdateSupporting(agent: AgentAvailableBuildVersions): boolean {
    return agent && agent.online && agent.forceUpdateSupporting;
  }

  /*
   * Updating form for one computer
   * @private
   */
  private updateForm(): void {
    this.forceUpdateForm.patchValue({
      backup: this.setDefaultValueByType(AgentType.Backup),
      rmm: this.setDefaultValueByType(AgentType.RMM),
      ra: this.setDefaultValueByType(AgentType.RA)
    });
  }

  /*
   * Return default value to FormControl by available version build
   * @param agentType
   * @private
   */

  private setDefaultValueByType(agentType): number | null {
    if (this.isGroupAction) {
      return ForceUpdateAgentType.NotNecessary;
    } else {
      if (
        this.isOneAvailableBuildOnly() ||
        (this.backupAgent && !this.rmmAgent && !this.raAgent) ||
        (this.rmmAgent && !this.backupAgent && !this.raAgent)
      ) {
        switch (agentType) {
          case AgentType.Backup:
            if (this.backupAgent === null || this.backupAgent === undefined) return null;
            return this.isAvailableVersion(this.backupAgent, false) ? ForceUpdateAgentType.Public : ForceUpdateAgentType.Sandbox;
          case AgentType.RMM:
            if (this.rmmAgent === null || this.rmmAgent === undefined) return null;
            return this.isAvailableVersion(this.rmmAgent, false) ? ForceUpdateAgentType.Public : ForceUpdateAgentType.Sandbox;
          case AgentType.RA:
            if (this.raAgent === null || this.raAgent === undefined) return null;
            return this.isAvailableVersion(this.raAgent, false) ? ForceUpdateAgentType.Public : null;
          default:
            return null;
        }
      } else {
        return this.isAgentTypeByApplicationID(agentType) ? ForceUpdateAgentType.NotNecessary : null;
      }
    }
  }

  /*
   * Send payload/payloadBulk by type command
   */
  handleResolve(): void {
    // TODO STATEFUL STREAMS
    this.loading = true;

    of(this.mode)
      .pipe(
        switchMap((mode) => {
          const prepareUpdate = this.prepareUpdateBuilds();
          // TODO: Remove legacy block after removing legacy Computers page
          if (mode === GroupActionMode.Legacy) {
            return this.rmCommands.bulkForceUpdate(
              {
                search: this.legacyFilters.searchQuery,
                companyIds: [this.legacyFilters.companyId],
                hidden: this.legacyFilters.hidden,
                type: this.legacyFilters.unSupportedVersion ? ComputersFiltersType.Unsupported : ComputersFiltersType.None,
                appIds: prepareUpdate.map((app) => app.appId as AgentType)
              },
              prepareUpdate
            );
          }
          if (mode === GroupActionMode.Bulk) {
            return this.filters.pipe(
              map((filters) => ({ ...filters, appIds: prepareUpdate.map((app) => app.appId as AgentType) })),
              switchMap((filters) => this.rmCommands.bulkForceUpdate(filters, prepareUpdate))
            );
          }
          return this.rmCommands.groupForceUpdate(
            this.prepareAgentCommands(mode === GroupActionMode.Group ? this.hids : [this.computer.hid])
          );
        }),
        catchError(() => {
          return of(false);
        })
      )
      .subscribe({
        next: (state) => {
          !!state?.resultList && this.displayingResult(state);
          this.baseModal.close();
          this.loading = false;
        },
        error: noop
      });
  }

  /*
   * Validation for the presence of at least one agent
   * @param form
   */
  buildsValidator(form: UntypedFormGroup): ValidationErrors | null {
    const backupControl = form.get('backup').value;
    const rmmControl = form.get('rmm').value;
    const raControl = form.get('ra').value;

    if (backupControl || rmmControl || raControl) {
      return null;
    }

    return { agent: { message: 'Not any build available version for updating' } };
  }

  /*
   * Checking for the presence of an agent in the computer apps by id
   * @param type
   */
  isAgentTypeByApplicationID(type: string): boolean {
    return this.computer.applications.some((app) => app.applicationId.toLowerCase() === type.toLowerCase());
  }

  /*
   * Check is available version for build
   * @param agent
   * @param isSandbox
   */
  isAvailableVersion(agent: AgentAvailableBuildVersions, isSandbox: boolean): boolean {
    if (agent === null || agent === undefined) return false;
    if (isSandbox && !this.isSandBoxAvailable) return false;
    const buildVersionType = agent.builds[isSandbox ? BuildVersionType.Sandbox : BuildVersionType.Public];

    return (
      !(agent.builds === null || agent.builds === undefined) &&
      !(buildVersionType === null || buildVersionType === undefined) &&
      !(buildVersionType.version === null || buildVersionType.version === undefined) &&
      buildVersionType.version !== '' &&
      versionCompare(buildVersionType.version, agent.currentVersion) > 0
    );
  }

  /*
   * Helper method for wrapping text brackets (using in view)
   * @param text
   */
  wrapTextBranch(text): string {
    return text ? '(' + text + ')' : '';
  }

  /*
   * Check the computer has more than one application
   */
  isPluralAgentBuilds(): boolean {
    return this.availableBuilds.length > 1;
  }

  /*
   * Check the computer has online and supporting only one build for updating
   */
  isOneAvailableBuildOnly(): boolean {
    if (this.availableBuilds.length === 1) {
      const isForceUpdateSupporting = this.isForceUpdateSupporting(this.availableBuilds[0]);
      const isAvailablePublicVersion = this.isAvailableVersion(this.availableBuilds[0], false) && isForceUpdateSupporting;
      const isAvailableSandboxVersion = this.isAvailableVersion(this.availableBuilds[0], true) && isForceUpdateSupporting;

      return (isAvailablePublicVersion && !isAvailableSandboxVersion) || (!isAvailablePublicVersion && isAvailableSandboxVersion);
    }
    return false;
  }

  /*
   * Check the computer has online and supporting any one (one or more) build for updating
   */
  isAvailableAndSupportedAnyOneBuild(): boolean {
    return (
      (this.isAvailableBackup && this.isForceUpdateSupporting(this.backupAgent)) ||
      (this.isAvailableRMM && this.isForceUpdateSupporting(this.rmmAgent)) ||
      (this.isAvailableRA && this.isForceUpdateSupporting(this.raAgent))
    );
  }

  /*
   * Prepare agentCommands before PUT on server
   * @private
   */
  private prepareAgentCommands(hids: string[]): AgentCommand<ParamsForceUpdate>[] {
    const form = this.forceUpdateForm.value;
    const updateBuilds = this.prepareUpdateBuilds();
    const agentTypes = Object.entries(form)
      .filter(([, value]) => Number(value) > 0)
      .map(([key]) => key as AgentType);

    return hids.flatMap((hid) =>
      agentTypes.map((agentType) => ({
        computerHid: hid,
        agentType,
        params: {
          updateBuilds
        }
      }))
    );
  }

  /*
   * Prepare updateBuilds before PUT on server
   * @private
   */
  private prepareUpdateBuilds(): UpdateBuild[] {
    const form = this.forceUpdateForm.value;
    return Object.entries(form)
      .filter(([, value]) => Number(value) > 0)
      .map(([key, value]: [string, number]) => {
        return {
          appId: key,
          versionType: Object.keys(ForceUpdateAgentType)[Object.values(ForceUpdateAgentType).indexOf(value)]
        };
      });
  }

  /*
   * Get classes for insert to ico in mbs-status
   * @param agent
   */
  getStatusClasses(agent: AgentAvailableBuildVersions): string {
    if (!agent.online) {
      return 'ico ico-pause-circle text-muted';
    } else if (agent.online && !agent.forceUpdateSupporting) {
      return 'ico ico-exclamation-circle text-danger';
    } else {
      return 'ico ico-check-circle text-success';
    }
  }

  /*
   * Show result force update in toast
   * @param state
   * @private
   */
  private displayingResult(state: any): void {
    const prepareState = cloneDeep(state);
    const newResults = [];
    prepareState.resultList.forEach((item: any) => {
      const found = newResults.find((res) => res && res.hid === item.hid);
      if (!found) {
        item.childs = [{ ...item.result, agentType: convertAgentTypeToNeededCase(item.agentType) }];
        newResults.push(item);
      } else {
        found.childs.push({ ...item.result, agentType: convertAgentTypeToNeededCase(item.agentType) });
      }
    });
    prepareState.resultList = newResults;
    if (prepareState && prepareState.ok) {
      prepareState.isErrorsInResultList ? this.handleShowRejectToast(prepareState) : this.handleShowResolveToast(prepareState);
    } else {
      this.toastService.toast(
        new Toast({
          header: this.forceUpdateText,
          content: `The command <span class="font-weight-semibold">${this.forceUpdateText}</span> is not defined`,
          type: MbsPopupType.danger
        })
      );
    }
  }

  /*
   * Show resolve toast for force update success result
   * @param prepareState
   * @private
   */
  private handleShowResolveToast(prepareState): void {
    const getContentText = (): string => {
      const compUpdateResult: any[] = prepareState.resultList;
      if (this.isGroupAction) {
        return `The request to ${this.forceUpdateText.toLowerCase()} for ${compUpdateResult.length} computer${
          compUpdateResult.length !== 1 ? 's' : ''
        } has been sent`;
      } else {
        return `${this.forceUpdateText} has been started for ${
          compUpdateResult[0].displayName &&
          compUpdateResult[0].displayName.trim().toLowerCase() !== compUpdateResult[0].name.trim().toLowerCase()
            ? compUpdateResult[0].displayName + ' [' + compUpdateResult[0].name + ']'
            : compUpdateResult[0].name
        }`;
      }
    };

    this.toastService.toast(
      new Toast({
        header: this.forceUpdateText,
        content: getContentText(),
        type: this.isGroupAction ? MbsPopupType.info : MbsPopupType.success,
        icon: true
      })
    );
  }

  /*
   * Show reject toast for force update failed result
   * @param prepareState
   * @private
   */
  private handleShowRejectToast(prepareState): void {
    this.toastService.toast(
      new Toast({
        header: this.forceUpdateText,
        content: prepareState.message,
        type: MbsPopupType.danger,
        icon: true,
        buttons: [
          {
            clickHandler: function (): void {
              this.handleOpenModalWithDetail(prepareState, this.forceUpdateText);
            }.bind(this)
          }
        ]
      })
    );
  }

  private handleOpenModalWithDetail(content: any, title: string): void {
    const settings: ModalSettings = {
      collapsing: true,
      header: {
        title: `${title} Details`,
        showExpandedCross: true,
        textOverflow: true
      },
      data: {
        context: content
      },
      size: MbsSize.lg
    };

    this.modalService.open(settings, this.errorTemplate).finally(noop);
  }
}
