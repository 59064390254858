import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { EmailServiceStatus } from '@models/Notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { NotificationServerSettingsService } from '@services/notification-server-settings.service';
import { Alert, AlertService, MbsPopupType } from 'mbs-ui-kit';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-email-service-status-component',
  templateUrl: './email-service-status.component.html'
})
export class EmailServiceStatusComponent implements AfterViewInit {
  @ViewChild('alertTemplate', { static: true }) alertTemplate: TemplateRef<any>;
  constructor(
    private appStorage: AppPersistentStateService,
    private notificationService: NotificationServerSettingsService,
    private alertService: AlertService
  ) {}

  ngAfterViewInit(): void {
    if (this.appStorage.data.showUnconfiguredEmailNotifications) {
      this.notificationService
        .checkEmailServiceConfigured()
        .pipe(
          filter((status) => status !== EmailServiceStatus.Ready),
          untilDestroyed(this)
        )
        .subscribe(() => {
          // may not appear without queueMicrotask because of 'speed run'
          queueMicrotask(() =>
            this.alertService.alert(new Alert({ content: this.alertTemplate, keepAfterRouteChange: true, type: MbsPopupType.danger }))
          );
          this.appStorage.data.showUnconfiguredEmailNotifications = false;
        });
    }
  }
}
