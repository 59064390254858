import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { getGeneralHostInfo, HostInfoRow } from '@components/computers-shared/get-general-host-Info';
import HostInfo from '@models/rmm/HostInfo';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmmService } from '@services/rmm.service';
import { CsvTemplate, generateCsvTable } from '@utils/cvs';
import { saveAs } from 'file-saver';
import { pluck } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-rmm-pc-info-tab',
  templateUrl: './sidepanel-rmm-pc-info-tab.component.html'
})
export class SidepanelRmmPCInfoTabComponent implements OnInit, OnChanges {
  @Input() hostInfo: HostInfo;
  @Input() isOnline = false;
  @Input() lastUpdated: string;
  @Input() hid: string;
  @Input() loading = true;

  myHostInfoRows: Array<HostInfoRow> = [];

  constructor(private rmmService: RmmService) {}

  ngOnInit(): void {
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hostInfo) {
      this.requestHostInfo();
    }
  }

  private requestHostInfo(): void {
    this.myHostInfoRows = getGeneralHostInfo(this.hostInfo, this.isOnline);
    this.loading = false;
  }

  refreshHostInfo(): void {
    this.loading = true;
    this.rmmService
      .fetchLastStat('host', this.hid)
      .pipe(pluck('data', '0', 'data', '0'))
      .pipe(untilDestroyed(this))
      .subscribe((value: HostInfo) => {
        this.hostInfo = value;
        this.loading = false;
      });
    this.requestHostInfo();
  }

  exportToCsv(): void {
    const withValuesOnlyList = this.myHostInfoRows.flatMap((element) => (element.value && element.value !== '' ? element : []));

    const computerName = this.hostInfo.computerName ? this.hostInfo.computerName : 'Computer-Info';
    const rows: CsvTemplate['rows'] = withValuesOnlyList.map((element) => ({
      Name: element.key,
      Value: `"${element.value}"`
    }));

    const exportTemplate = {
      cols: ['Name', 'Value'],
      rows
    };

    const blob = new Blob([generateCsvTable(exportTemplate)], { type: 'text/csv' });
    saveAs(blob, `${computerName}-Info.csv`);
  }
}
