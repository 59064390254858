import { Component, OnInit } from '@angular/core';
import EventLogInfo, { EventLogEntryTypeEnum, EventLogTypesEnum } from '@models/rmm/EventLogInfo';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Chart } from 'chart.js';
import { InteractionItem } from 'chart.js/dist/types';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { EventChartConfig, EventTotalTabMode } from '../event-total-tab.model';
import { EventLogEntryTypeToClassRelateMap, createChartConfig } from '../event-total-tab.utils';
import { ChangeMode, ViewLogByEntryType } from '../store/event-total.actions';
import { selectChartTotalCount, selectChartWithState, selectState } from '../store/event-total.selectors';

@UntilDestroy()
@Component({
  selector: 'mbs-event-chart',
  templateUrl: './event-chart.component.html'
})
export class EventChartComponent implements OnInit {
  public componentMode = EventTotalTabMode.CHART;
  public eventCharts$: Observable<EventChartConfig[]>;
  public totalEvents$: Observable<number>;
  public isOnline: boolean;

  constructor(private store: Store) {}

  ngOnInit() {
    this.eventCharts$ = this.store.select(selectChartWithState).pipe(map(({ charts, state }) => this.mapConfig(charts, state.online)));

    this.totalEvents$ = this.store.select(selectChartTotalCount);

    this.store
      .select(selectState)
      .pipe(pluck('online'), untilDestroyed(this))
      .subscribe((isOnline) => (this.isOnline = isOnline));
  }

  public getLegendLabel(eventType: EventLogTypesEnum) {
    return EventLogEntryTypeToClassRelateMap[eventType];
  }

  viewLogByEntryType(logType: EventLogTypesEnum, entry: EventLogEntryTypeEnum) {
    if (this.isOnline) {
      this.store.dispatch(ViewLogByEntryType({ logType, entry }));
    }
  }

  handleMode() {
    this.store.dispatch(ChangeMode({ mode: EventTotalTabMode.GRID }));
  }

  select({ active }: { active?: InteractionItem[] }, chart: Chart) {
    if (active[0]) {
      this.viewLogByEntryType(chart.data.datasets[0].label as any, (chart.data.labels || [])[active[0].index] as any);
    }
  }

  mapConfig = (logs: EventLogInfo[], isOnline) => {
    const data = logs.map((log) => createChartConfig<EventLogEntryTypeEnum>(log, isOnline)).filter(Boolean);

    return data.length > 0 ? data : null;
  };
}
