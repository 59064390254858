import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AbilityService } from 'ability';
import { TFARequireModalComponent } from '@components/tfa/components/tfa-require-modal/tfa-require-modal.component';
import { ComputersFacade } from '@facades/computers.facade';
import { AgentType } from '@models/Computer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AuthService, TFARMMPermissionHelperService } from '@services';
import { I18NextPipe } from 'angular-i18next';
import { InputButton, MbsSize, ModalService, WizardComponent, WizardStep, WizardStepComponent } from 'mbs-ui-kit';
import { filter, map, noop, take } from 'rxjs';
import { GroupTaskActions, GroupTaskSelectors } from '../group-tasks/store';
import { GAActions, GASelectors } from './store/group-action';
import { GroupActionStepsList } from './store/group-action/group-action.model';
import EditGroupTaskUtility from './utility/edit-group-task-utility';

@UntilDestroy()
@Component({
  selector: 'mbs-rmm-group-action-wizard',
  templateUrl: './rmm-group-action-wizard.component.html'
})
export class RMMGroupActionWizardComponent implements OnInit, OnDestroy {
  @ViewChild(WizardComponent, { static: false }) rmmGroupActionWizard: WizardComponent;
  @ViewChild('result', { static: false }) resultStep: WizardStepComponent;
  @ViewChild('start', { static: false }) startStep: WizardStepComponent;
  public wizardFinish = false;
  public currentStep: WizardStep;
  public formFirstStep: UntypedFormGroup;

  public validLastStep = false;
  public stepOrientation = 'vertical';
  public wizardCheckChanges = true;
  public showSteps = true;
  public showSaveBtnOnClose = false;
  public editMode = false;
  public canReset = false;

  public nextButton: InputButton;
  public saveButton: InputButton;
  public backButton: InputButton;

  public titleSteps: any = Object.values(GroupActionStepsList).reduce((acc, id) => {
    acc[id] = this.i18n.transform(`rmm.module:groupActions.wizardStepNames.${id}`, { format: 'title' });
    return acc;
  }, {});

  public anyComputerHid: string = null;
  public applyToIsValid: boolean;
  public actionIsValid: boolean;
  private is2FAEnabled: boolean;

  constructor(
    private ability: AbilityService,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService,
    private modalService: ModalService,
    private store: Store,
    private computersFacade: ComputersFacade,
    private i18n: I18NextPipe,
    private editGroupTaskUtility: EditGroupTaskUtility,
    private authService: AuthService
  ) {
    this.authService.currentUser.pipe(filter(Boolean), take(1), untilDestroyed(this)).subscribe((user) => {
      this.is2FAEnabled = user.Is2FAEnabled;
      if (!this.is2FAEnabled) this.resetSteps();
    });

    this.store
      .select(GroupTaskSelectors.selectGroupTaskToEdit)
      .pipe(take(1))
      .subscribe((groupTask) => {
        if (groupTask) {
          this.store.dispatch(
            GAActions.setupGroupTask({ wizardGroupAction: this.editGroupTaskUtility.getGroupActionFromGroupTask(groupTask) })
          );
          this.editMode = true;
        } else {
          this.store.dispatch(GroupTaskActions.clearRegisteredGroupTaskData());
          this.store.dispatch(GAActions.resetGActionWizard());
        }
      });
    this.setupWizardButtonValidatorsOnChanges();
  }

  resetSteps() {
    this.store.dispatch(GAActions.setGActionParameters({ parameters: null }));
    this.rmmGroupActionWizard.selectManualStep(null, this.startStep);
    this.rmmGroupActionWizard.resetStepsAfter(this.startStep);
  }

  ngOnInit(): void {
    this.getAnyComputerFromAvailableComputerList();
    this.store
      .select(GASelectors.selectGActionSelectedType)
      .pipe(
        filter((value) => !!value && this.canReset && !this.editMode),
        untilDestroyed(this)
      )
      .subscribe((value) => this.resetSteps());
  }

  // @TODO Get rid of this dirty hack after 2FA won't use computer's hid
  getAnyComputerFromAvailableComputerList(): void {
    this.computersFacade
      .getComputers({ appIds: [AgentType.RMM], limit: 1 })
      .pipe(
        map((response) => response.data),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (data?.length) this.anyComputerHid = data[0].hid;
      });
  }

  finishWizard() {
    if (!this.ability.can('read', '2FAEnabled')) {
      this.modalService
        .openCustom(TFARequireModalComponent, {
          size: MbsSize.sm
        })
        .finally(noop);
      return;
    }

    const title = this.i18n.transform('rmm.module:groupActions.wizardTitle');
    const twoFAContainer = 'mbs-rmm-group-action-wizard .mbs-wizard-steps_container';

    this.tfaRMMPermissionHelper
      .is2FAPassedStream([this.anyComputerHid], twoFAContainer, true, title)
      .pipe(untilDestroyed(this))
      .subscribe((confirmed) => confirmed && this.jumpToFinalStep() && this.handleSave());
  }

  jumpToFinalStep(): boolean {
    this.rmmGroupActionWizard.selectManualStep(null, this.resultStep);
    return true;
  }

  handleSave(): void {
    this.editMode ? this.store.dispatch(GAActions.saveGActionChanges()) : this.store.dispatch(GAActions.saveNewGAction());
  }

  handleStepChange(event: any): void {
    this.setupWizardButtons(event.title);
  }

  setupWizardButtonValidatorsOnChanges(): void {
    this.store
      .select(GASelectors.selectGAction)
      .pipe(untilDestroyed(this))
      .subscribe((groupTask) => {
        this.applyToIsValid =
          groupTask.applyTo.allCompanies || !!groupTask.applyTo.companies.length || !!groupTask.applyTo.computers.length;
        this.actionIsValid = !!groupTask.parameters && !!groupTask.groupActionCommand;
        this.setupWizardButtons(this.currentStep?.title);
      });
  }

  setupWizardButtons(stepTitle: string) {
    // wizard can be reseted after first action
    if (stepTitle === this.titleSteps[GroupActionStepsList.COMPUTER]) this.canReset = true;

    switch (stepTitle) {
      case this.titleSteps[GroupActionStepsList.COMPUTER]:
        this.setupBackButton(true);
        this.setupNextButton(this.applyToIsValid);
        this.setupSaveButton(this.applyToIsValid && this.actionIsValid && this.editMode);
        break;
      case this.titleSteps[GroupActionStepsList.RUN]:
      case this.titleSteps[GroupActionStepsList.SCHEDULE]:
        this.setupBackButton(true);
        this.setupNextButton(this.applyToIsValid && this.actionIsValid);
        this.setupSaveButton(this.applyToIsValid && this.actionIsValid && this.editMode);
        break;
      case this.titleSteps[GroupActionStepsList.SUMMARY]:
        this.setupBackButton(true);
        this.setupNextButton(false);
        this.setupSaveButton(this.applyToIsValid && this.actionIsValid);
        break;
      case this.titleSteps[GroupActionStepsList.RESULT]:
        this.wizardCheckChanges = false;
        this.showSaveBtnOnClose = false;
        this.setupBackButton(false);
        this.setupNextButton(false);
        this.setupSaveButton(false);
        break;
      case this.titleSteps[GroupActionStepsList.ACTION]:
      default:
        this.setupBackButton(false);
        this.setupNextButton(true);
        this.setupSaveButton(this.applyToIsValid && this.actionIsValid && this.editMode);
        break;
    }
  }

  setupBackButton(enable = true) {
    this.backButton = {
      text: this.i18n.transform('buttons:back', { format: 'title' }),
      hide: false,
      disabled: this.is2FAEnabled ? !enable : true
    };
  }

  setupNextButton(enable = true) {
    this.nextButton = {
      text: this.i18n.transform('buttons:next', { format: 'title' }),
      hide: false,
      disabled: this.is2FAEnabled ? !enable : true
    };
  }

  setupSaveButton(enable = true) {
    this.saveButton = {
      text: this.editMode
        ? this.i18n.transform('buttons:save', { format: 'title' })
        : this.i18n.transform('buttons:create', { format: 'title' }),
      hide: false,
      loading: false,
      disabled: this.is2FAEnabled ? !enable : true
    };
  }

  closeWizardOnRedirect() {
    this.wizardFinish = true;
  }

  ngOnDestroy(): void {
    queueMicrotask(() => {
      this.store.dispatch(GroupTaskActions.clearGroupTaskGuidToEdit());
      this.store.dispatch(GroupTaskActions.clearRegisteredGroupTaskData());
      this.store.dispatch(GAActions.resetGActionWizard());
    });
  }
}
