<mbs-modal #modal>
  <ng-container modal-header>
    {{ modalTitle }}
  </ng-container>
  <div modal-body>
    <ng-container *ngTemplateOutlet="installWinSoftwareModalBody"></ng-container>
  </div>
  <ng-container modalFooter>
    <mbs-button type="primary"
                [disabled]="installDisabled"
                (click)="sendCommandAfterTwoFA()">
      {{ 'buttons:install' | i18next }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="closeModal()">
      {{ 'buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>



<ng-template #installWinSoftwareModalBody>


  <div class="row mb-3"
       *ngIf="wingetEnabled">
    <div class="col-12">
      <div class="row button-group text-center m-0 mb-1">
        <button class="col btn btn-secondary d-flex install-software-tab-group-button-rounded-left-button justify-content-center align-items-center"
                [class.active]="modeSelected(modeSelectType.usingWinget)"
                (click)="setModeSelect(modeSelectType.usingWinget)">
          {{ 'rmm.module:groupActions.stepConfigureAction.installAndUpdate' | i18next }}
        </button>
        <button class="col btn btn-secondary d-flex install-software-tab-group-button-rounded-right-button justify-content-center align-items-center"
                [class.active]="modeSelected(modeSelectType.usingLink)"
                (click)="setModeSelect(modeSelectType.usingLink)">
          {{ 'rmm.module:groupActions.stepConfigureAction.installByLink' | i18next }}
        </button>
      </div>
    </div>
  </div>

  <ng-container [ngSwitch]="installationModeFormType.get('modeSelect').value">
    <mbs-install-winget-action *ngSwitchCase="modeSelectType.usingWinget"
                               [hid]="hid"
                               (updateSelectedSoftware)="updateSelectedSoftware($event)">
    </mbs-install-winget-action>
    <mbs-install-software *ngSwitchCase="modeSelectType.usingLink"
                          (updateInstallLinkParams)="updateInstallLinkParams($event)">
    </mbs-install-software>

  </ng-container>
</ng-template>
