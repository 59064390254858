import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateTimeFormatComponent } from '@shared/components/date-time-format/date-time-format.component';
import { SummaryComputersModule } from '@modules/summary-computers/summary-computers.module';
import { DateTimeFormatModule } from './date-time-format.module';
import { RmmDateTimeFormatComponent } from './rmm-date-time-format.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, DateTimeFormatModule, SummaryComputersModule, NgbTooltipModule],
  declarations: [RmmDateTimeFormatComponent],
  exports: [RmmDateTimeFormatComponent, DateTimeFormatComponent]
})
export class RmmDateTimeFormatModule {}
