import { Component, Input } from '@angular/core';
import Computer, { OsType } from '@models/Computer';
import { PlanMode, PlanTypes } from '@models/PlanTypes.enum';
import { ComputersAbstractService } from '@services/computers.service';
import { MbsSize, ModalSettings } from 'mbs-ui-kit';
import { PlansWizardMode } from '../../models/base/base-plan-models';
import { BackupFilesWizard } from '../../remote-management-wizards/backup-files-wizard/backup-files-wizard.component';
import { ImageBasedWizard } from '../../remote-management-wizards/image-based-wizard/image-based-wizard.component';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { WizardType } from '../try-rmm-for-providers.component';

@Component({
  selector: 'mbs-result-step',
  templateUrl: './result-step.component.html',
  styleUrls: ['./result-step.component.scss']
})
export class ResultStepComponent {
  @Input() wizardType: WizardType;
  public computer: Computer;

  constructor(private wizardService: RemoteManagementWizardsService, private computerService: ComputersAbstractService) {
  }

  handleIbBackup(): void {
    this.openWizard(PlanTypes.BackupDiskImagePlan);
  }
  handleFileBackup(): void {
    this.openWizard(PlanTypes.Plan);
  }

  openWizard(type): void {
    const modalSettings = new ModalSettings();
    modalSettings.size = MbsSize.lg;

    const wizardSettings = {
      hid: this.computer.hid,
      mode: PlanMode.create,
      compName: this.computer.displayName || this.computer.name,
      userId: this.computer?.userAccount?.id,
      wizardMode: PlansWizardMode.forRD,
      isLinux: this.computer.os === OsType.linux,
      type
    };
    const wizard = wizardSettings.type === PlanTypes.BackupDiskImagePlan ? ImageBasedWizard : BackupFilesWizard;
    this.wizardService.setWizard(wizard, modalSettings, wizardSettings);
  }
}
