import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { I18NextModule } from 'angular-i18next';
import { GroupTasksEffects } from './store/effects';
import { reducer } from './store/reducer';
import { StoreTypes } from '@shared/store/storeTypes.enum';

@NgModule({
  declarations: [],
  imports: [CommonModule, I18NextModule, StoreModule.forFeature(StoreTypes.GroupAction, reducer), EffectsModule.forFeature([GroupTasksEffects])],
  exports: []
})
export class GroupTasksModule {}
