import { Component, Inject, Injector } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { WizardStepComponent } from 'mbs-ui-kit';
import { map, Observable } from 'rxjs';
import { GroupTaskOsTypes } from '../../../group-tasks/store/model';
import { GASelectors } from '../../store/group-action';
import {
  GroupActionsCommands,
  LinuxAvailableActions,
  MacAvailableActions,
  WindowsAvailableActions
} from '../../store/group-action/group-action.model';

@UntilDestroy()
@Component({
  selector: 'mbs-configure-action',
  templateUrl: './configure-action.component.html',
  host: {
    class: 'rmm-group-action'
  }
})
export class ConfigureActionComponent {
  public action$: Observable<WindowsAvailableActions | LinuxAvailableActions | MacAvailableActions>;
  public selectedActionEnum: any = WindowsAvailableActions;

  constructor(protected injector: Injector, @Inject(WizardStepComponent) protected parent: WizardStepComponent, private store: Store) {
    this.store
      .select(GASelectors.selectGActionOsType)
      .pipe(untilDestroyed(this))
      .subscribe((osType) => {
        switch (osType) {
          case GroupTaskOsTypes.Windows:
            this.selectedActionEnum = WindowsAvailableActions;
            break;
          case GroupTaskOsTypes.Linux:
            this.selectedActionEnum = LinuxAvailableActions;
            break;
          case GroupTaskOsTypes.Mac:
            this.selectedActionEnum = MacAvailableActions;
            break;
          default:
            this.selectedActionEnum = [];
        }
      });
    this.action$ = this.store.select(GASelectors.selectGActionSelectedType).pipe(
      map((action: any) => {
        if (action === GroupActionsCommands.INSTALL_SOFTWARE) return GroupActionsCommands.WIN_GET;

        if (action === GroupActionsCommands.SCRIPT_LIBRARY_BASH) return GroupActionsCommands.BASH;
        if (action === GroupActionsCommands.SCRIPT_LIBRARY) return GroupActionsCommands.POWERSHELL;

        return action;
      }),
      untilDestroyed(this)
    );
  }
}
