import {ERROR_LINE, NEW_LINE, WARN_LINE, asyncIdPrefix as prefix} from './const';
import {TerminalDataType, TerminalTransfer} from './store/terminal.model';

import {isNumber} from 'lodash/fp';

export interface createSession {
  hid: string,
  emuId?: string,
  stamp?: string | number,
}

export function createSessionId(params: createSession) {
  const {hid} = params;
  return `${prefix}:${hid}`
}

export function createAsyncId(params: createSession) {
  const {hid, emuId, stamp} = params;
  return `${prefix}:${hid}:${emuId}:${stamp}`;
}

export function parseAsyncId(session) {
  const [prefix, hid, emuId, stamp] = session.split(':')

  return {
    prefix,
    hid,
    emuId,
    stamp: stamp || Date.now(),
  }
}

export function createChunkId({stamp, number}: {stamp: number, number: number}) {
  stamp = stamp || Date.now()
  number = isNumber(number) ? number + 1 : 0;
  return {id: `${stamp}:${number}`, number}
}

export function parseChunkId(id: string) {
  const [stamp, number] = id.split(';')
  return {stamp, number}
}

function serializeError(transfer: TerminalTransfer) {
  const marker = new RegExp(`^${ERROR_LINE.trim()}`, 'i');

  const has = transfer.data.match()
  if (!has) {
    return false;
  } else {
    return transfer.data.replace(marker, '');
  }
}

function serializeWarning(transfer: TerminalTransfer) {
  const marker = new RegExp(`^${WARN_LINE.trim()}`, 'i');

  const has = transfer.data.match()
  if (!has) {
    return false;
  } else {
    return transfer.data.replace(marker, '');
  }
}


export function normalize (source: string) {
  const clear = cleanString(source)

  if (/.*(\r\n)$/g.test(clear)) {
    return replaceEndLine(clear);
  } else {
    return replaceEndLine(clear) + NEW_LINE;
  }
  
}

function replaceEndLine(source) {
  return source
      .replaceAll('\n\r', '\n')
      .replaceAll('\r\n', '\n')
      .replaceAll('\n', '\r\n')
}

function cleanString(input) {
  let detected = false;
  let output = "";
  for (let i=0; i<input.length; i++) {
      if (input.charCodeAt(i) <= 127) {
        output += input.charAt(i);
      } else {
        detected = true;
      }
  }
  return !detected 
    ? output
    : output
      .replaceAll(/,\s*\./g, '')
      .replaceAll(/\.\s*,/g, '')
      .replaceAll(/\.\s*\./g, '')
      .replaceAll(/,\s*,/g, '')
}

export function serializeMessage(transfer: TerminalTransfer) {
  const error = serializeError(transfer)
  const warning = serializeWarning(transfer)

  switch (true) {
    case error: {
      return {data: error, type: TerminalDataType.ERROR};
    }
    case warning: {
      return {data: warning, type: TerminalDataType.WARN};
    }
    default: return {data: transfer.data, type: TerminalDataType.OUTPUT};
  }
}
