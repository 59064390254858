import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AddDeviceModalComponent } from '@components/tfa/components/add-device-modal/add-device-modal.component';
import { DeleteDeviceModalComponent } from '@components/tfa/components/delete-device-modal/delete-device-modal.component';
import { EnablePushModalComponent } from '@components/tfa/components/enable-push-modal/enable-push-modal.component';
import { TwoFactorEnableModalComponent } from '@components/tfa/components/two-factor-enable-modal/two-factor-enable-modal.component';
import { getTableHeaders } from '@components/tfa/constants/table';
import { Device } from '@components/tfa/models/device';
import { TwoFactorMessages } from '@components/tfa/models/tfa';
import { TFAService } from '@components/tfa/services/tfa.service';
import { SignalRService } from '@modules/signal-r/signal-r.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DevicesService } from '@services/devices.service';
import { tableMaxHeight, tableMinHeight } from '@utils/constants/table-height-constants';
import { I18NextService } from 'angular-i18next';
import { MbsSize, ModalService, SidepanelBase, SidepanelService, TabsetDirective } from 'mbs-ui-kit';
import { catchError, filter, noop, take, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-devices',
  templateUrl: './sidepanel-devices.component.html'
})
export class SidepanelDevicesComponent extends SidepanelBase implements OnInit, OnDestroy {
  public loading = false;
  public headers = null;
  public tableMinHeight = tableMinHeight;
  public tableMaxHeight = tableMaxHeight;
  public devices: Array<Device>;
  public isExpanded = false;

  @ViewChild(TabsetDirective) public tabset: TabsetDirective;

  constructor(
    private devicesService: DevicesService,
    private i18: I18NextService,
    private modal: ModalService,
    private cdr: ChangeDetectorRef,
    private sidepanelService: SidepanelService,
    private signalR: SignalRService,
    private TFAService: TFAService
  ) {
    super();
  }

  ngOnInit(): void {
    this.signalR.authorizedZoneConnection.on('OnDeviceRemoved', (device) => {
      const index = this.devices?.findIndex((d) => d.id === device.deviceId);
      if (this.devices?.[index]?.isEnabledTwoFactorAuth) {
        this.turnTFACheckbox(false);
      }

      this.removeDeviceFromArray(index);

      this.cdr.detectChanges();
    });

    this.headers = getTableHeaders(this.i18);
  }

  ngOnDestroy(): void {
    this.sidepanelService.removeByType(SidepanelDevicesComponent);
  }

  setDevices(): void {
    this.loading = true;
    this.devicesService
      .getDevices()
      .pipe(
        take(1),
        tap(() => (this.loading = false)),
        filter((devices) => !!devices.length)
      )
      .subscribe((devices) => (this.devices = devices));
  }

  addDevice(): void {
    this.modal
      .openCustom(AddDeviceModalComponent, {
        data: {
          title: 'Add New Device'
        },
        size: MbsSize.md
      })
      .then((result) => {
        if (result) {
          this.setDevices();
        }
      })
      .catch(noop);
  }

  enableTFA(device: Device): void {
    const prevTFADevice = this.devices?.find((d) => d.isEnabledTwoFactorAuth && d.id !== device.id);

    if (prevTFADevice) {
      prevTFADevice.isEnabledTwoFactorAuth = false;
    }

    device.isEnabledTwoFactorAuth = true;

    this.devicesService.enableTFA(device.id).subscribe((result) => {
      this.modal
        .openCustom(TwoFactorEnableModalComponent, {
          data: {
            title: 'Enable Two-Factor Authentication',
            isDeviceModal: true,
            isEnableTFA: true,
            device,
            qrCodeUrl: result.qrCodeUrl,
            timeExpired: result.timeExpired
          },
          size: MbsSize.md
        })
        .then(() => {
          this.turnTFACheckbox(true);
          this.devices = this.devices.slice(0);
        })
        .catch(() => {
          device.isEnabledTwoFactorAuth = false;
          if (prevTFADevice) {
            prevTFADevice.isEnabledTwoFactorAuth = true;
          }

          this.devices = this.devices.slice(0);

          this.TFAService.resetAuthId().subscribe();
        });
    });
  }

  disableTFA(device: Device): void {
    this.devicesService
      .disableTFA(device.id)
      .pipe(
        catchError((error) => {
          if (error.error.authId) {
            this.TFAService.openApproveModal({
              title: 'Disable Two-Factor Authentication',
              ...error.error
            })
              .then(() => {
                this.disableTFA(device);
              })
              .catch(() => {
                device.isEnabledTwoFactorAuth = true;
                this.devices = this.devices.slice(0);
              });
          }
          return error;
        })
      )
      .subscribe(() => {
        this.turnTFACheckbox(false);

        device.isEnabledTwoFactorAuth = false;
        this.devices = this.devices.slice(0);
      });
  }

  enablePush(device: Device): void {
    this.cdr.markForCheck();
    if (device.hasPushPossibility) {
      this.devicesService.enablePush(device.id).pipe(take(1)).subscribe();
    } else {
      this.modal
        .openCustom(EnablePushModalComponent, {
          data: {
            title: this.i18.t('tfa:enablePush'),
            device
          },
          size: MbsSize.sm
        })
        .then(() => {
          this.setDevices();
        })
        .catch(() => {
          device.push = false;
          this.devices = this.devices.slice(0);
        });
    }
  }

  disablePush(device: Device): void {
    this.devicesService.disablePush(device.id).pipe(take(1)).subscribe();
  }

  deleteDevice(device: Device): void {
    this.modal
      .openCustom(DeleteDeviceModalComponent, {
        data: {
          title: 'Delete Device',
          deviceName: device.name,
          id: device.id,
          hasTwoFactorAuth: device.isEnabledTwoFactorAuth
        },
        size: MbsSize.sm
      })
      .finally(noop);
  }

  removeDeviceFromArray(index: number): void {
    if (index !== -1) {
      this.devices.splice(index, 1);
      this.devices = this.devices.slice(0);
    }
  }

  turnTFACheckbox(value: boolean): void {
    const iframe = document.getElementsByTagName('iframe')?.[0];
    iframe?.contentWindow.postMessage({ msg: TwoFactorMessages.TurnTwoFactorAuthCheckBox, payload: { value } }, '*');
    this.TFAService.updateTFAState().subscribe();
  }

  onExpandedChange(event): void {
    this.isExpanded = event;
    this.headers = getTableHeaders(this.i18, this.isExpanded);
    this.setDevices();
  }
}
