import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ComputersFacade } from '@facades/computers.facade';
import Computer, { OsType } from '@models/Computer';
import { UtilitySelectionModalComponent } from '@modules/utility-selection-modal/utility-selection-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { MbsSize, ModalService } from 'mbs-ui-kit';
import { noop } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-computer-select-step',
  templateUrl: './computer-select-step.component.html'
})
export class ComputerSelectStepComponent implements OnInit {
  @Output() setNextButtonEnabled = new EventEmitter();
  @Output() setSelectedComputerList = new EventEmitter<Computer[]>();

  public selectedComputerList: Computer[] = [];
  public computerList: Computer[];
  public selectedCompanyList: { id: string; name: string }[] = [];

  public modeSelectionForm: UntypedFormGroup = new UntypedFormGroup({
    mode: new FormControl('manual')
  });

  constructor(private computersFacade: ComputersFacade, private modalService: ModalService, private i18nPipe: I18NextPipe) {}

  ngOnInit(): void {
    queueMicrotask(() => {
      this.getComputerList();
    });
    this.onChanges();
  }

  onChanges(): void {
    this.modeSelectionForm.get('mode').valueChanges.subscribe((value) => {
      this.onComputerModeChange(value);
    });
  }

  private getComputerList(): void {
    this.computersFacade
      .getComputers({ os: [OsType.windows] })
      .pipe(untilDestroyed(this))
      .subscribe((computers) => (this.computerList = computers.data.filter((computer) => computer.os === 'windows')));
  }

  public onComputerModeChange(formValue: string): void {
    switch (formValue) {
      case 'companySelection':
        // eslint-disable-next-line no-case-declarations
        const selectedComputerList = this.selectComputersInCompanies();
        this.setSelectedComputerList.emit(selectedComputerList);
        this.setNextButtonEnabled.emit(!!selectedComputerList.length);
        break;
      case 'computerSelection':
        this.setSelectedComputerList.emit(this.selectedComputerList);
        this.setNextButtonEnabled.emit(!!this.selectedComputerList.length);
        break;
      case 'manual':
      default:
        // switch to manual RMM Agent installation
        this.setSelectedComputerList.emit([]);
        this.setNextButtonEnabled.emit(true);
        break;
    }
  }

  selectComputersInCompanies(): Computer[] {
    const computersInSelectedCompanies = [];
    // TODO Refactor Sergey I
    // eslint-disable-next-line  sonarjs/no-ignored-return
    this.computerList.map((computer) => {
      this.selectedCompanyList.forEach((company) => {
        if (computer.company && computer.company.id === company.id) {
          computersInSelectedCompanies.push(computer);
        } else if (computer.company == null && company.name === 'no-company') computersInSelectedCompanies.push(computer);
      });
    });
    return computersInSelectedCompanies;
  }

  openSelectComputersModal(): void {
    this.modeSelectionForm.get('mode').setValue('computerSelection');
    const groups = this.selectedComputerList.map((selectedComputer) => selectedComputer.id);
    this.modalService
      .openCustom(UtilitySelectionModalComponent, {
        data: {
          model: groups,
          modalTitle: this.i18nPipe.transform('wizards:tryRmm:select_computers_modal_title'),
          applyTo: 'Computer List',
          noComputersText: this.i18nPipe.transform('wizards:tryRmm:select_computers_modal_no_computers_desc'),
          saveButtonText: this.i18nPipe.transform('wizards:tryRmm:modal_accept_btn')
        },
        size: MbsSize.sm,
        collapsing: true
      })
      .then((selectedEntities: any) => {
        if (selectedEntities) {
          this.selectedComputerList = selectedEntities.flatMap((entity) => entity.selectedItems.map((computer) => computer));
        }
        this.onComputerModeChange(this.modeSelectionForm.get('mode').value);
      })
      .catch(noop);
  }

  openSelectCompaniesModal(): void {
    this.modeSelectionForm.get('mode').setValue('companySelection');
    const groups = this.selectedCompanyList.map((companyId) => companyId.id);
    this.modalService
      .openCustom(UtilitySelectionModalComponent, {
        data: {
          model: groups,
          modalTitle: this.i18nPipe.transform('wizards:tryRmm:select_companies_modal_title'),
          applyTo: 'Company List',
          noComputersText: this.i18nPipe.transform('wizards:tryRmm:select_companies_modal_no_companies_desc'),
          saveButtonText: this.i18nPipe.transform('wizards:tryRmm:modal_accept_btn')
        },
        size: MbsSize.sm,
        collapsing: true
      })
      .then((selectedEntities: any) => {
        if (selectedEntities) {
          this.selectedCompanyList = selectedEntities.map((entity) => ({
            id: entity.id,
            name: entity.title
          }));
        }
        this.onComputerModeChange(this.modeSelectionForm.get('mode').value);
      })
      .catch(noop);
  }
}
