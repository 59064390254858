import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { filter, map, pluck, shareReplay, startWith } from 'rxjs/operators';
import IpWhiteList from '../models/IpWhiteList';

type IpWhiteListFullModel = { restrictionByIpsOn: boolean; whiteLists: IpWhiteList[] };

export class IpWhiteListService {
  private data$ = new Subject<IpWhiteListFullModel>();
  public enabled$ = this.data$.pipe(
    pluck('restrictionByIpsOn'),
    startWith(false),
    filter(v => !(v === null || v === undefined)),
    shareReplay()
  );
  public ips$ = this.data$.pipe(
    pluck<IpWhiteListFullModel>('whiteLists'),
    startWith<IpWhiteList[]>([]),
    filter(v => !(v === null || v === undefined)),
    shareReplay()
  );

  public loaded$ = this.ips$.pipe(
    startWith(false),
    map(ips => !!ips)
  );

  constructor(private http: HttpClient) {}

  public get(sort: boolean = null): Observable<IpWhiteList[]> {
    this.http.get<IpWhiteListFullModel>(`Admin/IPWhiteLists/GetOwnerIPWhiteLists?desc=${sort}`).subscribe(model => this.data$.next(model));

    return this.ips$;
  }

  public update(model: Partial<IpWhiteListFullModel> & { restrictionOn: boolean }): Observable<boolean> {
    return this.http.post<boolean>(`Admin/IPWhiteLists/SaveChanges`, model);
  }

  public getCurrentIp(): Observable<string> {
    return this.http.get<any>('Admin/IPWhiteLists/GetProvidersCurrentIP').pipe(pluck('currentIp'));
  }
}
