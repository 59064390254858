import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@environments/environments.service';
import Computer, { AgentType } from '@models/Computer';
import { ComputersFacade } from '@shared/facades/computers.facade';
import { Observable } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';

/**
 * Will update computer entity in case of ServiceUnavailable (408) error during rm command request
 */
@Injectable()
export class RmCommandServiceUnavailableInterceptor implements HttpInterceptor {
  public selectedHid = '';

  constructor(private environment: EnvironmentService, private computerFacade: ComputersFacade) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const includedCommands = ['/commands/invokecommand', '/commands/invokepscommand', '/commands/invokeactivecommand'];
    const isIncludedByCommand = includedCommands.some((command) => req.url.includes(command));

    return next.handle(req).pipe(
      catchError((e) => {
        if (e.status === HttpStatusCode.RequestTimeout && this.environment.production && isIncludedByCommand) {
          this.updateComputer();
        }

        throw e;
      })
    );
  }

  updateComputer(): void {
    this.computerFacade.currentComputer$
      .pipe(
        filter((computer) => this.selectedHid !== computer?.hid && Computer.isAgentOnline(computer, AgentType.RMM)),
        take(1)
      )
      .subscribe((computer) => {
        this.selectedHid = computer.hid;
        this.computerFacade.loadComputerByHid({ hid: computer.hid, quiet: false, force: true });
      });
  }
}
