<div class="mbs-page">
  <div class="mbs-page_content">
    <div class="mbs-page-fixed_header">
      <header class="mbs-page_header row mb-3 align-items-center">
        <div class="col-auto pr-2">
          <h1 class="mbs-page_title mb-0">
            {{ 'rmm-script-library.module:pageTitle' | i18next }}
          </h1>
        </div>
        <div class="col pl-2">
          <mbs-button type="primary"
                      [square]="true"
                      (click)="add()"
                      [ngbTooltip]="'rmm-script-library.module:addScript' | i18next"
                      container="body"
                      placement="bottom"
                      [icon]="'ico ico-Plus'"></mbs-button>
        </div>
      </header>

      <div class="mbs-form mbs-form-filter">
        <div class="form-row py-1">
          <div class="col-12 col-md-6">
            <mbs-advanced-search [(ngModel)]="model"
                                 (ngModelChange)="search($event)"
                                 [data]="searchTemplates"
                                 [accessUrlQuery]="false">
            </mbs-advanced-search>
          </div>
        </div>
      </div>

      <mbs-table-nav [showRefreshButton]="true"
                     [wideContentMode]="true"
                     (refresh)="load()">

        <ng-template mbsTableFilter="label">
          {{ "rmm-script-library.module:scripts" | i18next}} {{ (data$ | async).length }}
        </ng-template>

        <ng-template mbsTableControl="append">
          <mbs-button [icon]="'fa fa-cog text-lg'"
                      container="body"
                      placement="left"
                      [ngbTooltip]="'Manage Categories'"
                      (click)="openCategoryModal()">
          </mbs-button>
        </ng-template>


      </mbs-table-nav>

    </div>
    <div class="mbs-script-library-page-straight_content">

      <mbs-table-grid [headers]="headers"
                      [data]="data$ | async"
                      (sort)="sort($event)"
                      [loading]="loading$ | async"
                      [changeSortState]="orderBy"
                      [noDataMessage]="'rmm-script-library.module:noTableDataMessage' | i18next">

        <ng-template mbsTableCell
                     let-data>
          <mbs-button [isCtrl]="true"
                      (click)="openScriptInfo(data)">
            <mbs-text-ellipsis [tooltip]="data?.name"
                               placement="top-left auto"
                               tooltipClass="tooltip-md">
              {{ data?.name }}
            </mbs-text-ellipsis>
          </mbs-button>
        </ng-template>

        <ng-template mbsTableCell
                     let-data>
          <mbs-text-ellipsis [tooltip]="data?.description"
                             placement="top-left auto"
                             tooltipClass="tooltip-md">
            {{ data?.description }}
          </mbs-text-ellipsis>
        </ng-template>

        <ng-template mbsTableCell
                     let-data>
          <mbs-text-ellipsis [tooltip]="data?.categories"
                             placement="top-left auto"
                             tooltipClass="tooltip-md">
            {{ data?.categories }}
          </mbs-text-ellipsis>

        </ng-template>

        <ng-template mbsTableCell
                     let-data>
          {{ data?.accessLevel }}
        </ng-template>

        <ng-template mbsTableCell
                     let-data>
          {{ data?.accessLevel === 'Common' ? 'MSP360' : data?.subadminName ? data?.subadminName : data?.owner }}
        </ng-template>

        <ng-template mbsTableCell
                     let-data>
          <mbs-button-group type="secondary">
            <mbs-button icon="fa fa-edit mr-0"
                        [hidden]="data?.accessLevel === 'Common'"
                        (click)="edit(data.id)">

            </mbs-button>
            <mbs-button icon="fa fa-trash-o mr-0"
                        [hidden]="data?.accessLevel === 'Common'"
                        (click)="delete(data)">

            </mbs-button>
          </mbs-button-group>
        </ng-template>

      </mbs-table-grid>
    </div>
  </div>
</div>
