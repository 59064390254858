import * as TerminalActions from './terminal.actions';

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {TerminalSession} from './terminal.model';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface State extends EntityState<TerminalSession> {};
export const adapter: EntityAdapter<TerminalSession> = createEntityAdapter<TerminalSession>();
export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(TerminalActions.addSubscriber, (state, action) => {
    const entry: TerminalSession = state.entities[action.session] || ({} as TerminalSession);
    const subscribers = entry?.subscribers || [];
    const update: Partial<TerminalSession> = {
      id: action.session,
      subscribers: [...subscribers, action.emuId]
    }
    return adapter.upsertOne(update as TerminalSession, state);
  }),
  on(TerminalActions.removeSubscriber, (state, action) => {
    const entry: TerminalSession = state.entities[action.session];
    const subscribers = entry?.subscribers || [];
    const update: Partial<TerminalSession> = {
      id: action.session,
      subscribers: subscribers.filter((emuId) => emuId !== action.emuId)
    }
    return adapter.upsertOne(update as TerminalSession, state);
  }),
  on(TerminalActions.setInfo, (state, action) => {
    const entry: TerminalSession = state.entities[action.session] || ({} as TerminalSession);
    const update: Partial<TerminalSession> = {
      id: action.session,
      info: Object.assign({}, entry?.info, action.info)
    }
    return adapter.upsertOne(update as TerminalSession, state)
  }),
  on(TerminalActions.addTimeout, (state, action) => {
    const update: Partial<TerminalSession> = {
      id: action.session,
      timeout: action.timeout,
    }
    return adapter.upsertOne(update as TerminalSession, state)
  }),
  on(TerminalActions.clearSession, (state, action) => adapter.removeOne(action.session, state)),
  on(TerminalActions.setProcessing, (state, action) => {
    const update: Partial<TerminalSession> = {
        id: action.session,
        processing: action.processing,
    }
    return adapter.upsertOne(update as TerminalSession, state)
  }),
  on(TerminalActions.setDisconnected, (state, action) => {
    const update: Partial<TerminalSession> = {
        id: action.session,
        disconnected: action.disconnected,
    }
    return adapter.upsertOne(update as TerminalSession, state)
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
