<mbs-table-nav [switcherView]="false"
               [showRefreshButton]="false"
               [showExportToCSVButton]="isModal"
               [exportButtonName]="'buttons:exportToCSV' | i18next"
               (export)="export()">

  <ng-template mbsFiltersGroup>
    <ng-container *ngIf="!isMacOrLinuxOS && !(loading$ | async)">
      <mbs-button-group>
        <mbs-button *ngFor="let filter of (filters$ | async)"
                    size="MbsSize.sm"
                    [icon]="filter.icon"
                    [active]="filter.active"
                    (click)="toggleFilter(filter)"
                    [ngbTooltip]="filter.name"
                    tooltipClass="-no-arrow">
          <span *ngIf="filter.id === eventLogEntryTypeEnum.All">{{ filter.name + ':' }}</span>
          <span class="text-primary">{{filter.count}}</span>
        </mbs-button>
      </mbs-button-group>
    </ng-container>
  </ng-template>

  <ng-template *ngIf="!isMacOrLinuxOS && !isModal"
               mbsTableControl="prepend">
    <div class="mbs-table_switcher lh-1">
      <mbs-button-group [toggle]="true">
        <mbs-button [active]="true"
                    [disabled]="(loading$ | async)"
                    class="mbs-table_switcher-ctrl"
                    customClasses="cursor-default"
                    icon="ico ico-ListView"
                    ngbTooltip="Table view"
                    tooltipClass="-no-arrow">
        </mbs-button>
        <mbs-button [disabled]="(loading$ | async)"
                    [active]="false"
                    class="mbs-table_switcher-ctrl"
                    icon="ico ico-PieChart"
                    (click)="handleMode()"
                    ngbTooltip="Chart view"
                    tooltipClass="-no-arrow">
        </mbs-button>
      </mbs-button-group>
    </div>

  </ng-template>

  <ng-template *ngIf="!isModal"
               mbsTableControl="append">
    <ng-content select="[modal]"
                class="ml-3"></ng-content>
  </ng-template>
</mbs-table-nav>

<mbs-table-grid class="ph-no-capture mb-0"
                [headers]="headers"
                [showNav]="false"
                [data]="tableData$ | async"
                [virtualScrolling]="true"
                [virtualItemsNumber]="itemCount"
                [virtualItemSize]="itemSize"
                (clickTableElement)="showDetail($event)"
                (sort)="handleSort($event)"
                [changeSortState]="sortEvent$ | async"
                [myTrackBy]="trackBy"
                [stripedTable]="false"
                [height]="height"
                classesTable="mbs-event-total-grid mb-0"
                [loading]="loading$ | async"
                [noDataMessage]="'rmm-side-panel:noDataProvided' | i18next">

  <ng-container *ngIf="!isMacOrLinuxOS">
    <ng-template mbsTableCell
                 let-data>
      <span placement="top"
            container="body"
            [class]="data.icon"
            [ngbTooltip]="data.level">
      </span>
    </ng-template>
  </ng-container>

  <ng-template mbsTableCell
               let-data>
    <mbs-rmm-date-time-format [container]="'body'"
                              [time]="data.timeUTC"
                              [dateTimeFormat]="'h:mm A'"
                              [showIcon]="!isMacOrLinuxOS"></mbs-rmm-date-time-format>
  </ng-template>

  <ng-template mbsTableCell
               let-data>
    <mbs-text-ellipsis placement="top-left"
                       container="body"
                       [tooltip]="data.source">
      {{ data.source || ('emptyValue' | i18next) }}</mbs-text-ellipsis>
  </ng-template>

  <ng-container *ngIf="!isMacOrLinuxOS">
    <ng-template mbsTableCell
                 let-data>
      <mbs-text-ellipsis placement="top-left"
                         container="body"
                         [tooltip]="data.eventID">
        {{ data.eventID || ('emptyValue' | i18next) }}</mbs-text-ellipsis>
    </ng-template>
  </ng-container>
</mbs-table-grid>
