<div [ngClass]="{ 'justify-content-center': isQRCodeInvalid }"
     [ngStyle]="!isQRCodeUsed && { 'min-height': '230px' }"
     class="d-flex align-items-center justify-content-start flex-column mb-4 position-relative">
  <ng-container *ngIf="QRCodeUri && !isQRCodeUsed">
    <img [ngStyle]="{ opacity: getQRCodeOpacity() }"
         alt="QR code"
         id="msp-qr-code"
         src="{{ QRCodeUri }}"/>
    <div *ngIf="!isTimeExpired && !isInvalidLocalTime">
      <mbs-tfa-timer (dateExpired)="isTimeExpired = true" [expiredDate]="timeExpired"></mbs-tfa-timer>
    </div>
    <mbs-button (click)="setQRCode()"
                *ngIf="isTimeExpired && !isInvalidLocalTime"
                [isCtrl]="true">
      {{ 'tfa:mspQRCode:requestNewCode' | i18next }}
    </mbs-button>
    <mbs-incorrect-time-alert *ngIf="isInvalidLocalTime" [expiredDate]="timeExpired"></mbs-incorrect-time-alert>
  </ng-container>
  <ng-container *ngIf="isQRCodeInvalid">
    {{ error }}
    <mbs-button (click)="setQRCode()"
                [isCtrl]="true"
                class="mt-2">
      {{ 'tfa:mspQRCode:requestNewCode' | i18next }}
    </mbs-button>
  </ng-container>

  <mbs-loader *ngIf="isQRCodeLoading"></mbs-loader>
</div>
