import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordModalComponent } from '@modules/password-modal/password-modal.component';
import { PasswordRecoveryModule } from '@modules/password-recovery/password-recovery.module';
import { I18NextModule } from 'angular-i18next';
import { AlertModule, ButtonModule, FormGroupModule, InputModule, ModalModule } from 'mbs-ui-kit';

@NgModule({
  imports: [
    AlertModule,
    ButtonModule,
    CommonModule,
    FormsModule,
    FormGroupModule,
    InputModule,
    I18NextModule,
    ModalModule,
    PasswordRecoveryModule,
    ReactiveFormsModule
  ],
  declarations: [PasswordModalComponent],
  exports: [PasswordModalComponent]
})
export class PasswordModalModule {
  constructor() {}
}
