import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import Computer, { AgentState, AgentType, OsType } from '@models/Computer';
import { SummaryExtension, SummaryStatCounter, SummaryStatus, SummaryStatusIcon, SummaryStatusType } from '@models/rmm/SummaryStat';
import * as AppsActions from '@modules/computer-apps/store/computer-apps.actions';
import { ComputerApp } from '@modules/computer-apps/store/computer-apps.model';
import * as AppsSelectors from '@modules/computer-apps/store/computer-apps.selectors';
import { TypeOfSetting } from '@modules/rmm-custom-alerting/store/rmm-custom-alert.model';
import { AlertCustomisationHandler, RMM_ALERT_CUSTOMISATION_TOKEN } from '@modules/rmm-custom-alerting/tokens';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import * as SummaryActions from '@modules/summary-computers/store/summary-computer.actions';
import { SummaryComputer } from '@modules/summary-computers/store/summary-computer.model';
import * as SummarySelectors from '@modules/summary-computers/store/summary-computer.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TFARMMPermissionHelperService } from '@services';
import { mediumDateWithTime } from '@utils/date';
import { mapStatusComponentData } from '@utils/helpers/summary/summary-mappers';
import { I18NextPipe } from 'angular-i18next';
import { chunk as lChunk, get } from 'lodash/fp';
import { MbsSize, ModalComponent, ModalService, TabsetDirective, TabsetItemDirective } from 'mbs-ui-kit';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { IS_MODAL_CONTENT_OPEN } from '../sidepanel-rmm.component';
import { getTemperatureInFahrenheit } from '@shared/utils/temperature';

type SummaryKeys = 'hdd' | 'cpu' | 'ram' | 'update' | 'antivirus' | 'eventLog' | 'systemTime' | 'smart' | 'temperature';

type ExtendedSummary = SummaryStatus & {
  key: string;
  sub?: string;
  size: string;
  detail?: any;
  tooltip?: string;
};

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-rmm-general-tab',
  templateUrl: './sidepanel-rmm-general-tab.component.html'
})
export class SidepanelRmmGeneralTabComponent implements OnInit {
  @Input() hid: string;

  @Input() loading: boolean;
  @Input() isModalContent: boolean;
  @Input() isOnline: boolean;
  @Input() tabset: TabsetDirective;
  @Output() isModalContentChange = new EventEmitter<boolean>();

  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;

  public summary$: BehaviorSubject<SummaryComputer> = new BehaviorSubject({} as SummaryComputer); // SummaryStatus
  public agent$: BehaviorSubject<ComputerApp> = new BehaviorSubject(null);
  public agentState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public summary: ExtendedSummary[];

  public taskToLaunch: 'monitoring' | 'powerShell' | 'remoteDesktop' | null = null;
  public twoFAisDone = false;

  public readonly mediumDateWithTime = mediumDateWithTime;
  public monitoringForm: UntypedFormGroup = new UntypedFormGroup({
    enabled: new FormControl(false)
  });

  public alertSettingsFormTypes = [TypeOfSetting.Global, TypeOfSetting.Company, TypeOfSetting.Computer];
  public stateOrder: SummaryKeys[] = ['hdd', 'cpu', 'ram', 'update', 'antivirus', 'eventLog', 'systemTime', 'smart', 'temperature']; // alerts has different values, so it should be called explicitly if needs
  public isMacOS: boolean;
  public isAlertCustomisationSupported = false;
  private alertCustomisationMinVersion = 160;

  private thresholdDefaultValue = {
    hdd: 90,
    cpu: 90,
    ram: 90,
    temperature: 80
  };

  constructor(
    private modalService: ModalService,
    private computersFacade: ComputersFacade,
    private store: Store,
    public i18nPipe: I18NextPipe,
    private tabItem: TabsetItemDirective,
    private commandService: CommandService,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService,
    @Inject(RMM_ALERT_CUSTOMISATION_TOKEN) private rmmAlertCustomisationHandler: AlertCustomisationHandler
  ) {
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (computer && computer.os) this.isMacOS = computer.os === OsType.apple;
      this.isAlertCustomisationSupported = Computer.IsSupportedAgentVersion(computer, AgentType.RMM, this.alertCustomisationMinVersion);
    });

    const current$ = this.store.select(SummarySelectors.selectCurrent).pipe(filter(Boolean));

    current$
      .pipe(
        switchMap((summary) => {
          return this.store.select(AppsSelectors.selectRMMAgent(summary.id));
        }),
        untilDestroyed(this)
      )
      .subscribe((agent) => {
        this.agent$.next(agent);
      });

    current$
      .pipe(
        switchMap((summary) => this.store.select(AppsSelectors.selectRMMAgentState(summary.id))),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((state) => {
        this.agentState$.next(state);
        this.monitoringForm.get('enabled').setValue(state, { emitEvent: false });
      });

    current$.pipe(untilDestroyed(this)).subscribe((summary) => {
      this.hid = summary.id;
      this.isOnline = summary.online;
      this.summary$.next(summary);
    });

    current$
      .pipe(
        switchMap((summary) =>
          combineLatest([
            this.store.select(SummarySelectors.selectLoadingByHid(summary.id)),
            this.store.select(AppsSelectors.selectLoading)
          ])
        ),
        untilDestroyed(this)
      )
      .subscribe(([summary, agent]) => {
        const loading = summary || agent;

        queueMicrotask(() => (this.tabItem.loading = loading));
      });
  }

  ngOnInit(): void {
    this.twoFAisDone = this.commandService.canHidAccess(this.hid);
    this.monitoringForm
      .get('enabled')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.twoFAisDone) {
          this.setRmmAgentState();
        } else {
          this.requestPermissions('monitoring');
        }
      });

    const DEFAULT_SUMMARY: SummaryStatus = {
      type: SummaryStatusType.MUTED,
      icon: SummaryStatusIcon.UNKNOW,
      text: this.i18nPipe.transform('app:emptyValue')
    };

    combineLatest([this.summary$, this.agentState$])
      .pipe(
        map(([data, state]) => {
          return this.stateOrder.map((key: SummaryKeys) => {
            return {
              key,
              ...mapStatusComponentData(get(key, data), false, DEFAULT_SUMMARY),
              ...this.mapStatusValues(get(key, data), key),
              ...(state ? {} : { type: DEFAULT_SUMMARY.type }),
              size: MbsSize.md,
              tooltip: data.online && state ? '' : moment(data.timestamp).format(mediumDateWithTime)
            };
          });
        }),
        untilDestroyed(this)
      )
      .subscribe((summary) => {
        this.summary = summary;
        if (this.isMacOS) {
          const index = this.stateOrder.findIndex((name) => name === 'antivirus');
          if (index !== -1) this.summary.splice(index, 1);
        }
      });
  }

  private mapStatusValues = (stat: SummaryStatCounter, key: SummaryKeys): Partial<ExtendedSummary> => {
    const value = get('value', stat);

    switch (key) {
      case 'systemTime':
      case 'smart':
        return value ? { text: value } : {};
      case 'hdd':
      case 'cpu':
      case 'ram':
        return this.getPercentsText(value, key, stat?.extData);
      case 'temperature':
        return this.getTemperatureText(value, stat?.extData);
      case 'eventLog':
        return this.eventTotalParse(stat);
      default:
        return {};
    }
  };

  getPercentsText(percent, key, ext: any | SummaryExtension[]) {
    const thresholdValue = ext?.length ? ext.find((item) => item.key === 'AlertPercent')?.value : this.thresholdDefaultValue[key];
    const threshold = this.i18nPipe.transform('rmm-side-panel:threshold', { value: thresholdValue + '%' });
    if (!percent) {
      return { sub: threshold };
    }

    return {
      text: percent + '%',
      sub: threshold
    };
  }

  getTemperatureText(temperatureInCelsius: string, ext: SummaryExtension[]): Partial<ExtendedSummary> {
    if (!temperatureInCelsius)
      return {
        text: this.i18nPipe.transform('rmm-side-panel:noDataAvailable', { format: 'title' }),
        sub: ''
      };

    if (Number(temperatureInCelsius)) {
      const thresholdValue = ext?.length
        ? ext.find((item) => item.key === 'AlertHighTemperature')?.value
        : this.thresholdDefaultValue.temperature;
      const threshold = this.i18nPipe.transform('rmm-side-panel:threshold', {
        value: `${thresholdValue}\u00B0C (${getTemperatureInFahrenheit(thresholdValue)}\u00B0F)`
      });

      return {
        text: `${temperatureInCelsius}\u00B0C (${getTemperatureInFahrenheit(temperatureInCelsius)}\u00B0F)`,
        sub: threshold
      };
    } else {
      // No need in threshold, if we have string data (macOS use case)
      return {
        text: temperatureInCelsius,
        sub: ''
      };
    }
  }

  private eventTotalParse(stat: SummaryStatCounter): Partial<ExtendedSummary> {
    const data = get('description', stat);

    if (!data) {
      return { text: this.i18nPipe.transform('rmm-side-panel:noDataAvailable', { format: 'title' }) };
    }
    /* parse begin */
    // remove title from start of string to ':' char
    const detailString = data.replace(/^\w.+:/, '');
    const detailList = detailString.split(',');
    // one string result check for macAgent
    if (detailList.length === 1)
      return {
        text: detailString
      };
    // parse detail key and value
    const detail = detailList.map((sub) => {
      const [key, value] = sub.split(' - ');
      return {
        key: key.trim().toLocaleLowerCase(), // 'application' | 'hardware' | 'security' | 'system'
        value: Number.parseInt(value.trim()) // count of events
      };
    });
    /* parse end */

    return {
      text: this.i18nPipe.transform('rmm-side-panel:general-info-events.label'),
      // join even and odd detail to one string
      detail: lChunk(2, detail).map((chunk) => {
        return chunk
          .map(({ key, value }) => this.i18nPipe.transform('rmm-side-panel:general-info-events.' + key, { format: 'title', value }))
          .join(', ');
      })
    };
  }
  // @TODO - remove method after rmm14 agent version lock. Compatibility fix for translation.
  eventsKeyCheck(key: string): string {
    const oldHardwareKey = 'hardware events';
    const newHardwareKey = 'hardware';
    if (key === oldHardwareKey) return newHardwareKey;
    return key;
  }
  private setRmmAgentState(): void {
    const newState = !this.agentState$.getValue();

    this.store.dispatch(
      AppsActions.loadUpdateComputerApp({
        hid: this.summary$.getValue().id,
        appType: AgentType.RMM,
        applicationState: newState ? AgentState.Enabled : AgentState.Disabled
      })
    );
    if (newState) {
      this.onRmmAppDataChanged();
    }
  }

  onRmmAppDataChanged() {
    this.computersFacade.loadComputerByHid({ hid: this.summary$.getValue().id, quiet: true, force: true });
    this.store.dispatch(SummaryActions.loadSummaryComputer({ hid: this.summary$.getValue().id }));
  }

  requestPermissions(taskToLaunch: 'monitoring' | 'powerShell' | 'remoteDesktop' | null) {
    const container = IS_MODAL_CONTENT_OPEN.getValue() ? 'body .modal .modal-body' : 'mbs-sidepanel-rmm-info .mbs-form_content';
    this.tfaRMMPermissionHelper
      .is2FAPassedStream([this.hid], container)
      .pipe(untilDestroyed(this))
      .subscribe((confirmed) => {
        if (confirmed) this.actionAfterTFA(taskToLaunch);
        this.monitoringForm.get('enabled').setValue(this.agentState$.getValue(), { emitEvent: false });
      });
  }

  actionAfterTFA(taskToLaunch) {
    if (taskToLaunch === 'monitoring') {
      this.setRmmAgentState();
      this.taskToLaunch = null;
    }
  }

  clickOnItemHandler(itemKey: SummaryKeys): void {
    switch (itemKey) {
      case 'smart':
        this.tabset.select('disk');
        break;
      case 'eventLog':
        this.tabset.select('event');
        break;
      case 'antivirus':
        this.tabset.select('antivirus');
        break;
      case 'update':
        this.tabset.select('availableHotfixes');
        break;
      default:
        break;
    }
  }

  public openAlertCustomisation(): void {
    this.rmmAlertCustomisationHandler.navigate(TypeOfSetting.Computer, this.hid);
  }
}
