import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { OsType } from '@models/Computer';
import { EventLogTypesEnum } from '@models/rmm/EventLogInfo';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { distinctUntilDateChanged } from '@utils/operators/distinct-until-date-changed';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { EventTotalTabMode } from '../event-total-tab.model';
import { UpdateDate, UpdateType } from '../store/event-total.actions';
import { selectEntryLoading, selectState } from '../store/event-total.selectors';

@UntilDestroy()
@Component({
  selector: 'mbs-event-state',
  templateUrl: './event-state.component.html'
})
export class EventStateComponent implements OnInit, OnDestroy {
  public eventForm: UntypedFormGroup;

  public eventLogTypesList = [
    EventLogTypesEnum.Application,
    EventLogTypesEnum.HardwareEvents,
    EventLogTypesEnum.Security,
    EventLogTypesEnum.System
  ];

  isMacOrLinuxOS: boolean;
  isGrid$: Observable<boolean>;

  constructor(private fb: FormBuilder, private store: Store, private computersFacade: ComputersFacade) {
    this.eventForm = this.fb.group({
      logType: [],
      date: []
    });

    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      this.isMacOrLinuxOS = [OsType.apple, OsType.linux].includes(computer?.os);
    });

    this.store
      .select(selectState)
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.eventForm.patchValue({ logType: state.logType, date: state.date }, { emitEvent: false, onlySelf: true });
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectEntryLoading)
      .pipe(untilDestroyed(this))
      .subscribe((loading) => (loading ? this.eventForm.disable() : this.eventForm.enable()));

    this.eventForm
      .get('logType')
      .valueChanges.pipe(distinctUntilChanged(), filter(Boolean), untilDestroyed(this))
      .subscribe((logType: EventLogTypesEnum) => {
        this.store.dispatch(UpdateType({ logType }));
      });

    this.eventForm
      .get('date')
      .valueChanges.pipe(distinctUntilDateChanged('minute'), filter(Boolean), untilDestroyed(this))
      .subscribe((date: Date) => this.store.dispatch(UpdateDate({ date })));

    this.isGrid$ = this.store.select(selectState).pipe(
      untilDestroyed(this),
      map((state) => {
        return state.mode === EventTotalTabMode.GRID;
      })
    );
  }

  ngOnDestroy(): void {
    // empty
  }

  compareType = (iterable, selected) => {
    return iterable === selected;
  };
}
