import { HttpErrorResponse } from '@angular/common/http';
import { merge } from 'lodash';
import { Observable, scheduled, SchedulerLike, Subject, throwError, TimeoutError, timer } from 'rxjs';
import { repeatWhen, retryWhen, switchMap, timeout } from 'rxjs/operators';

export interface AutoFetchConfig {
  interval: number;
  maxRetryAttempts: number;
  excludedStatusCodes?: number[];
  excludedApplicationCodes?: number[];
}

export interface AutoFetchState {
  attempts: number;
  exceeded: boolean;
  nextTickDuration: number;
  error: any;
}

export const defaultConfig: AutoFetchConfig = {
  interval: 5000,
  maxRetryAttempts: 4,
  excludedStatusCodes: [401],
  excludedApplicationCodes: []
};

/**
 * Autofetching source stream by interval with increase delay after failure
 *
 * @param {AutoFetchState} [withLogger] logger stream will
 * @param {AutoFetchConfig} [config] autofetch configuration
 * @param {SchedulerLike} [scheduler] Scheduler controlling when timeout checks occur.
 * @return {Function}
 */
export function autoFetch<TResult>(withLogger: Subject<AutoFetchState>, config?: AutoFetchConfig, scheduler?: SchedulerLike) {
  return function(request$: Observable<TResult>): Observable<TResult> {
    config = merge({}, defaultConfig, config);

    const autofetch = request$.pipe(
      timeout(config.interval, scheduler),
      // first repeat should with delay
      repeatWhen(() => timer(config.interval, config.interval, scheduler)),
      retryWhen(error =>
        error.pipe(
          switchMap((response, i) => {
            const retryAttempt = i + 1;

            const payload: AutoFetchState = {
              attempts: retryAttempt,
              exceeded: retryAttempt >= config.maxRetryAttempts,
              nextTickDuration: retryAttempt * config.interval,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              error: response
            };

            if (payload.exceeded) {
              payload.nextTickDuration = null;
              withLogger.next(payload);
              return throwError(payload);
            }

            if (response instanceof TimeoutError) {
              withLogger.next(payload);
              return timer(payload.nextTickDuration);
            }

            if (
              response instanceof HttpErrorResponse &&
              (config.excludedStatusCodes.includes(response.status) ||
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                (response.error && config.excludedApplicationCodes.includes(response.error.code as number)))
            ) {
              return throwError(payload);
            }

            return timer(config.interval);
          })
        )
      )
    );

    return scheduler ? scheduled(autofetch, scheduler) : autofetch;
  };
}
