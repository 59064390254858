import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { getAdministrativeAccountConfig } from '@modules/wizards/onboarding/onboarding.helpers';
import { StepsService } from '@modules/wizards/onboarding/services/steps/steps.service';
import { NotUniqueDomainError, SignInError } from '@modules/wizards/onboarding/steps/apps/sign-in/sign-in.error';
import { BaseStep } from '@modules/wizards/onboarding/steps/shared/base/base.step';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountId, AuthDataFailedResponseState } from '@services/apps/apps.constants';
import { AppsService } from '@services/apps/apps.service';
import { LoginData } from '@services/apps/apps.types';
import { AuthService } from '@services/auth.service';
import { I18NextPipe } from 'angular-i18next';
import { MbsSize, ModalService } from 'mbs-ui-kit';
import { map, of, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AccountType } from './sign-in.types';

@UntilDestroy()
@Component({
  selector: 'mbs-apps-sign-in-step',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class AppsSignInComponent<StepId> extends BaseStep<StepId> implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @ViewChild('googleInstructionsTemplate', { static: true, read: TemplateRef }) googleInstructionsTemplate: TemplateRef<any>;

  public readonly accountTilesConfig = getAdministrativeAccountConfig(this.i18N);
  public readonly accountId = AccountId;
  public readonly stepId = 'signIn';

  public isLoading = false;
  public accountIdControl: AbstractControl;
  public swiperInstance = null;
  public alias: string;

  private readonly ALIAS_LENGTH = 12;

  constructor(
    public i18N: I18NextPipe,
    private stepService: StepsService<StepId>,
    private onboardingService: OnboardingService,
    private appsService: AppsService,
    private authService: AuthService,
    public modalService: ModalService,
    public cdr: ChangeDetectorRef
  ) {
    super(stepService);
  }

  ngOnInit() {
    const { providerId } = this.form.value;
    this.alias = providerId?.slice(providerId.length - this.ALIAS_LENGTH, providerId.length);
    this.accountIdControl = this.form.get('accountId');
    this.setState(this.accountIdControl.value);

    this.accountIdControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.setState(value);
    });

    this.appsService
      .setSettings({ enabled: true, alias: this.alias })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => this.form.get('aliasUrl').setValue(data.aliasUrl),
        error: (e) => console.log(e)
      });

    this.stepService.customTransitionForward.pipe(untilDestroyed(this)).subscribe((step) => {
      if (step?.id !== (this.stepId as unknown as StepId)) return;

      this.transition(step?.next);
    });
  }

  public transition(stepId: StepId): void {
    this.isLoading = true;
    const { msalData, googleData, accountId } = this.form.value;
    const data = accountId === AccountId.GoogleWorkspace ? googleData : msalData;

    of(null)
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.appsService.setAuthData(accountId, data, this.alias)),
        map((response) => {
          if (Object.values(AuthDataFailedResponseState).includes(response.State)) {
            throw new SignInError(this.getSignInError(response.State));
          }

          this.form.get('userName').setValue(response.UserName);
          this.form.get('domainName').setValue(response.Customer);

          return response;
        }),
        mergeMap(() => {
          return this.onboardingService
            .createDomain({
              domainName: this.form.value?.domainName,
              sharePointTeamDrives: 0
            })
            .pipe(
              catchError((e) => {
                if (e.error.title === 'Not unique property') {
                  return throwError(() => new NotUniqueDomainError('signInError'));
                }

                return throwError(() => e);
              })
            );
        }),
        switchMap(({ id }) => {
          this.form.get('domainId').setValue(id);
          return this.authService.getAppsToken({ domain: this.form.value?.domainName });
        })
      )
      .subscribe({
        next: (data) => {
          this.form.get('tokenData').setValue(data);
          this.stepService.customTransitionForward.next(null);
          this.stepService.transition(stepId);
        },
        error: (err) => {
          if (err instanceof SignInError) {
            this.showAlert(err.message);
            this.isLoading = false;

            return;
          }

          if (err instanceof NotUniqueDomainError) {
            this.showAlert(this.i18N.transform(`onboarding:alerts.${err.name}`));
            this.isLoading = false;

            return;
          }

          this.onboardingService.hasError$.next(true);
        }
      });
  }

  public onSelectedAccountChanged(account: AccountType): void {
    const isMicrosoftProvider = this.isMicrosoftProvider(account.id);
    const loginData = this.form.get('authSettings')?.value?.find((data) => data.Provider === account.id);

    const promise = isMicrosoftProvider
      ? this.microsoftLogin(loginData)
      : this.modalService
          .open(
            {
              header: this.i18N.transform('onboarding:administrative_account.google_workspace.allow_access', { format: 'title' }),
              size: MbsSize.xl,
              footer: {
                show: false
              }
            },
            this.googleInstructionsTemplate
          )
          .then(() => this.googleLogin(loginData));

    promise
      .then((data) => {
        this.accountIdControl.setValue(account.id);

        this.form.get(isMicrosoftProvider ? 'msalData' : 'googleData').setValue(data);
      })
      .catch((error) => console.log('error', error));
  }

  public findAccountById(id: AccountId): AccountType {
    return this.accountTilesConfig.find((account) => account.id === id);
  }

  public getImagePath(name: string): string {
    return `assets/images/onboarding/google_instructions/${name}`;
  }

  public logout(): void {
    this.microsoftLogout({
      clientId: this.form.get('authSettings')?.value?.find((data) => data.Provider === AccountId.MicrosoftPersonal)?.ClientId,
      homeAccountId: this.form.get('msalData')?.value?.account?.homeAccountId
    }).then(() => {
      localStorage.setItem('onboardingFlow', 'apps');
      localStorage.setItem('onboardingStep', 'signIn');
    });
  }

  private setState(value: AccountId): void {
    this.onboardingService.stable$.next(!!value);
    this.isValid = !!value;
  }

  private getSignInError(state: AuthDataFailedResponseState): string {
    switch (state) {
      case AuthDataFailedResponseState.WrongAccount ||
        AuthDataFailedResponseState.AccessRequest ||
        AuthDataFailedResponseState.ServiceUnavailable:
        return this.i18N.transform('onboarding:appsSignInError.signInError');

      case AuthDataFailedResponseState.TokenInvalid:
        return this.i18N.transform('onboarding:appsSignInError.tokenInvalid');

      case AuthDataFailedResponseState.NotGrant || AuthDataFailedResponseState.Approve:
        return this.i18N.transform('onboarding:appsSignInError.tokenInvalid');

      case AuthDataFailedResponseState.AccessDenied:
        return this.i18N.transform('onboarding:appsSignInError.customerExcluded');

      case AuthDataFailedResponseState.Blocked:
        return this.i18N.transform('onboarding:appsSignInError.customerBlocked');

      case AuthDataFailedResponseState.SignInDisabled:
        return this.i18N.transform('onboarding:appsSignInError.signInDisabled');

      case AuthDataFailedResponseState.NoLicensesAvailable:
        return this.i18N.transform('onboarding:appsSignInError.noLicenses');

      default:
        return this.i18N.transform('onboarding:appsSignInError.signInError');
    }
  }

  public isMicrosoftProvider = (accountId: AccountId): boolean =>
    [AccountId.MicrosoftBusiness, AccountId.MicrosoftPersonal].includes(accountId);

  private microsoftLogin = ({ ClientId, Scopes, Prompt }: LoginData): Promise<any> => {
    const config = {
      auth: {
        clientId: ClientId,
        redirectUri: location.origin,
        oauth2AllowIdTokenImplicitFlow: true
      }
    };

    const application = new msal.PublicClientApplication(config);

    const loginRequest = {
      scopes: Scopes,
      prompt: Prompt
    };

    return application.loginPopup(loginRequest);
  };

  private microsoftLogout = ({ clientId, homeAccountId }: { clientId: string; homeAccountId: string }): Promise<void> => {
    const config = {
      auth: {
        clientId,
        redirectUri: location.origin,
        postLogoutRedirectUri: location.origin
      }
    };
    const application = new msal.PublicClientApplication(config);

    const logoutRequest = {
      account: application.getAccountByHomeId(homeAccountId),
      mainWindowRedirectUri: location.origin
    };

    return application.logoutPopup(logoutRequest);
  };

  private googleLogin({ ClientId, Scopes }: LoginData): Promise<any> {
    return new Promise((res) => {
      google.accounts.oauth2
        .initTokenClient({
          client_id: ClientId,
          scope: Scopes?.join(' '),
          ux_mode: 'popup',
          callback(tokenResponse) {
            res(tokenResponse);
          }
        })
        .requestAccessToken({});
    });
  }
}
