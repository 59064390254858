import { BehaviorSubject, shareReplay, Observable } from 'rxjs';
import { Router, ParamMap } from '@angular/router';
import { inject } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { AlertingScanRange, TypeOfSetting } from './store/rmm-custom-alert.model';

export interface AlertCustomisationHandler {
  /**
   * Hot RMMAlertCustomisation observer
   *
   * @type {Observable<AlertingScanRange>}
   */
  state$: Observable<AlertingScanRange>;
  /**
   * Sync RMMAlertCustomisation getter
   *
   * @type {() => AlertingScanRange}
   */
  getState: () => AlertingScanRange;
  /**
   * Setter RMMAlertCustomisation by Router.queryParamsMap
   *
   * @type {(query: ParamMap) => void}
   */
  setByQueryMap: (query: ParamMap) => void;
  /**
   * Setter RMMAlertCustomisation by AlertingScanRange props
   *
   * @type {(type: TypeOfSetting, hid?: string) => void}
   */
  set: (type: TypeOfSetting, hid?: string) => void;
  /**
   * Navigate to RMMAlertCustomisation
   *
   * @type {(type: TypeOfSetting, hid?: string) => void}
   */
  navigate: (type: TypeOfSetting, hid?: string) => void;
}

export const RMM_ALERT_CUSTOMISATION_TOKEN = new InjectionToken<AlertCustomisationHandler>('RMM_ALERT_CUSTOMISATION_TOKEN', {
  providedIn: 'root',
  factory: () => {
    const router = inject(Router);
    const stateSubject$ = new BehaviorSubject<AlertingScanRange>(null);

    return {
      state$: stateSubject$.pipe(shareReplay()),
      getState: () => stateSubject$.getValue(),
      setByQueryMap: (query: ParamMap) => {
        switch (true) {
          case query.has(TypeOfSetting.Global): {
            stateSubject$.next({ type: TypeOfSetting.Global, id: null });
            break;
          }
          case query.has(TypeOfSetting.Company): {
            stateSubject$.next({ type: TypeOfSetting.Company, id: query.get(TypeOfSetting.Company) });
            break;
          }
          case query.has(TypeOfSetting.Computer): {
            stateSubject$.next({ type: TypeOfSetting.Computer, id: query.get(TypeOfSetting.Computer) });
            break;
          }
          default:
            stateSubject$.next(null);
            break;
        }
      },
      set: (type: TypeOfSetting, hid?: string) => {
        stateSubject$.next({ type, hid } as AlertingScanRange);
      },
      navigate: (type: TypeOfSetting, hid = '') => {
        const queryParams = { [type]: hid };
        router.navigate(['/AP/RMMAlertCustomisation'], { queryParams });
      }
    };
  }
});
