import { createAction, props } from '@ngrx/store';

import { KeyActions } from './model';

const inputPrefix = '[Shell Input Action]';

export const Backspace = createAction(`${inputPrefix} ${KeyActions.Backspace}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Break = createAction(`${inputPrefix} ${KeyActions.Break}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Copy = createAction(`${inputPrefix} ${KeyActions.Copy}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Delete = createAction(`${inputPrefix} ${KeyActions.Delete}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Down = createAction(`${inputPrefix} ${KeyActions.Down}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const End = createAction(`${inputPrefix} ${KeyActions.End}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Error = createAction(`${inputPrefix} ${KeyActions.Error}`, props<{ error?: string; payload?: any }>());
export const ErrorOutput = createAction(`${inputPrefix} ${KeyActions.ErrorOutput}`, props<{ error?: string; payload?: any }>());
export const Home = createAction(`${inputPrefix} ${KeyActions.Home}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Insert = createAction(`${inputPrefix} ${KeyActions.Insert}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Left = createAction(`${inputPrefix} ${KeyActions.Left}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const LastCMD = createAction(`${inputPrefix} ${KeyActions.LastCMD}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const NewLine = createAction(`${inputPrefix} ${KeyActions.NewLine}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Next = createAction(`${inputPrefix} ${KeyActions.Next}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Paste = createAction(`${inputPrefix} ${KeyActions.Paste}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Prev = createAction(`${inputPrefix} ${KeyActions.Prev}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Prompt = createAction(`${inputPrefix} ${KeyActions.Prompt}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Right = createAction(`${inputPrefix} ${KeyActions.Right}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Run = createAction(`${inputPrefix} ${KeyActions.Run}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const OutsideRun = createAction(`${inputPrefix} ${KeyActions.OutsideRun}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Output = createAction(`${inputPrefix} ${KeyActions.Output}`, props<{ payload?: any }>());
export const UnSupport = createAction(`${inputPrefix} ${KeyActions.UnSupport}`, props<{ event?: KeyboardEvent; payload?: any }>());
export const Up = createAction(`${inputPrefix} ${KeyActions.Up}`, props<{ event?: KeyboardEvent; payload?: any }>());
