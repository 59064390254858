<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           #modal>
  <div modal-body>
    <mbs-msp-qr-code [isDeviceModal]="true"> </mbs-msp-qr-code>
    <span class="font-weight-semibold">
      {{ 'tfa:addDeviceModal:addNewDevice' | i18next }}
    </span>
    <ol class="pl-4">
      <li class="mt-2">
        {{ 'tfa:downloadApplication' | i18next }}
        <mbs-install-msp-app-badges></mbs-install-msp-app-badges>
      </li>
      <li class="mt-2">{{ 'tfa:addDeviceModal:openApplication' | i18next }}</li>
      <li class="mt-2">
        {{ 'tfa:addDeviceModal:goToApp' | i18next }}
      </li>
    </ol>
  </div>

  <ng-container modalFooter>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">
      {{ 'tfa:close' | i18next: { format: 'title' } }}
    </mbs-button>
  </ng-container>
</mbs-modal>
