import {
  EventLogEntrySourceTypeEnum,
  EventLogEntryTypeEnum,
  EventLogIconEnum,
  default as EventLogInfo,
  EventLogTypeDataFieldsEnum,
  EventLogTypesEnum
} from '@models/rmm/EventLogInfo';
import { CSSVariables, CssColorFormat, getCssColorVariable } from '@utils/getCssVariable';
import { ActiveElement, Chart, ChartConfiguration, ChartEvent, TooltipItem } from 'chart.js';
import { cloneDeep, get, invert } from 'lodash/fp';
import * as moment from 'moment';
import { EventChartConfig, EventDateRange } from './event-total-tab.model';

export const EventLogEntryTypeRelateMap = {
  [EventLogEntryTypeEnum.Info]: EventLogEntrySourceTypeEnum.Information,
  [EventLogEntryTypeEnum.Warn]: EventLogEntrySourceTypeEnum.Warning,
  [EventLogEntryTypeEnum.Error]: EventLogEntrySourceTypeEnum.Error,
  [EventLogEntryTypeEnum.Success]: EventLogEntrySourceTypeEnum.SuccessAudit,
  [EventLogEntryTypeEnum.Failure]: EventLogEntrySourceTypeEnum.FailureAudit
};

export const EventLogEntryTypeToIconRelateMap = {
  [EventLogEntryTypeEnum.Info]: EventLogIconEnum.Info,
  [EventLogEntryTypeEnum.Warn]: EventLogIconEnum.Warning,
  [EventLogEntryTypeEnum.Error]: EventLogIconEnum.Danger,
  [EventLogEntryTypeEnum.Success]: EventLogIconEnum.Success,
  [EventLogEntryTypeEnum.Failure]: EventLogIconEnum.Danger
};

export const InvertedEventLogEntryTypeRelateMap = invert(EventLogEntryTypeRelateMap);

export const EventLogEntryTypeToClassRelateMap = {
  [EventLogEntryTypeEnum.Info]: CSSVariables.Info,
  [EventLogEntryTypeEnum.Warn]: CSSVariables.Warning,
  [EventLogEntryTypeEnum.Error]: CSSVariables.Danger,
  [EventLogEntryTypeEnum.Failure]: CSSVariables.Danger,
  [EventLogEntryTypeEnum.Success]: CSSVariables.Success
};

export const EventLogTypesLabels = {
  NO_DATA: 'No data',
  [EventLogTypesEnum.Application]: 'Application',
  [EventLogTypesEnum.HardwareEvents]: 'Hardware Events',
  [EventLogTypesEnum.Security]: 'Security',
  [EventLogTypesEnum.System]: 'System'
};

export const DEFAULT_DATA_FIELDS = [EventLogTypeDataFieldsEnum.Info, EventLogTypeDataFieldsEnum.Warn, EventLogTypeDataFieldsEnum.Error];

export const EventTypeDataFields = {
  [EventLogTypesEnum.Application]: DEFAULT_DATA_FIELDS,
  [EventLogTypesEnum.HardwareEvents]: DEFAULT_DATA_FIELDS,
  [EventLogTypesEnum.System]: DEFAULT_DATA_FIELDS,
  [EventLogTypesEnum.Security]: [EventLogTypeDataFieldsEnum.Success, EventLogTypeDataFieldsEnum.Failure]
};

export const DEFAULT_DATA_COLORS = [
  getCssColorVariable(EventLogEntryTypeToClassRelateMap.Info, CssColorFormat.RGB),
  getCssColorVariable(EventLogEntryTypeToClassRelateMap.Warn, CssColorFormat.RGB),
  getCssColorVariable(EventLogEntryTypeToClassRelateMap.Error, CssColorFormat.RGB)
];

export const EventTypeColors = {
  [EventLogTypesEnum.Application]: DEFAULT_DATA_COLORS,
  [EventLogTypesEnum.HardwareEvents]: DEFAULT_DATA_COLORS,
  [EventLogTypesEnum.System]: DEFAULT_DATA_COLORS,
  [EventLogTypesEnum.Security]: [
    getCssColorVariable(EventLogEntryTypeToClassRelateMap.Success, CssColorFormat.RGB),
    getCssColorVariable(EventLogEntryTypeToClassRelateMap.Failure, CssColorFormat.RGB)
  ]
};

export const DEFAULT_DATA_CSS_CLASS = [
  EventLogEntryTypeToClassRelateMap.Info,
  EventLogEntryTypeToClassRelateMap.Warn,
  EventLogEntryTypeToClassRelateMap.Error
];

export const EventTypeCssClass = {
  [EventLogTypesEnum.Application]: DEFAULT_DATA_CSS_CLASS,
  [EventLogTypesEnum.HardwareEvents]: DEFAULT_DATA_CSS_CLASS,
  [EventLogTypesEnum.System]: DEFAULT_DATA_CSS_CLASS,
  [EventLogTypesEnum.Security]: [EventLogEntryTypeToClassRelateMap.Success, EventLogEntryTypeToClassRelateMap.Failure]
};

export const DEFAULT_DATA_LABELS = [EventLogEntryTypeEnum.Info, EventLogEntryTypeEnum.Warn, EventLogEntryTypeEnum.Error];

export const EventTypeLabelsForChart = {
  [EventLogTypesEnum.Application]: DEFAULT_DATA_LABELS,
  [EventLogTypesEnum.HardwareEvents]: DEFAULT_DATA_LABELS,
  [EventLogTypesEnum.System]: DEFAULT_DATA_LABELS,
  [EventLogTypesEnum.Security]: [EventLogEntryTypeEnum.Success, EventLogEntryTypeEnum.Failure]
};

export const EventTypeLabelsForGreed = {
  [EventLogTypesEnum.Application]: [EventLogEntryTypeEnum.All, ...DEFAULT_DATA_LABELS],
  [EventLogTypesEnum.HardwareEvents]: [EventLogEntryTypeEnum.All, ...DEFAULT_DATA_LABELS],
  [EventLogTypesEnum.System]: [EventLogEntryTypeEnum.All, ...DEFAULT_DATA_LABELS],
  [EventLogTypesEnum.Security]: [EventLogEntryTypeEnum.All, EventLogEntryTypeEnum.Success, EventLogEntryTypeEnum.Failure]
};

export const getRelateByEventLogEntryId = (id: EventLogEntrySourceTypeEnum) => {
  const entry = InvertedEventLogEntryTypeRelateMap[id];

  return {
    entry,
    cssClass: EventLogEntryTypeToClassRelateMap[entry]
  };
};

export const isEmptyData = (active): boolean => {
  if (active[0]) {
    const labels: any[] = get(['_chart', 'data', 'labels'], active[0]);

    return !labels || labels.length <= 0 || labels.includes(EventLogTypesLabels.NO_DATA);
  } else {
    return false;
  }
};

const onHover = (event: ChartEvent, active: Array<ActiveElement>) => {
  const el = event.native.target as HTMLCanvasElement;
  el.style.cursor = active[0] && !isEmptyData(active) ? 'pointer' : 'default';
};

const customLabel = (tooltipItem: TooltipItem<'doughnut'>): string => {
  return tooltipItem.label + ': ' + tooltipItem.raw;
};

const drawEmptyData = (chart: Chart) => {
  if (chart.data.datasets[0].data.length === 1) {
    const ctx = chart.ctx;
    const width = chart.width / 2;
    const height = (chart.chartArea.top + chart.height) / 2;

    ctx.save();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = "12px normal 'Open Sans'";
    ctx.fillText(EventLogTypesLabels.NO_DATA, width, height);
    ctx.restore();
  }
};

export const DEFAULT_CHART_CONFIG: EventChartConfig = {
  customLegend: true,
  type: 'doughnut',
  data: {
    datasets: []
  },
  options: {
    aspectRatio: 1.25,
    cutout: '65%',
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'doughnut'>): string {
            return customLabel(tooltipItem);
          }
        }
      },
      legend: {
        display: false
      },
      title: {
        display: true
      }
    }
  },
  plugins: [
    {
      id: 'drawEmptyData',
      afterDraw: (chart, args, options) => drawEmptyData(chart)
    }
  ]
};

const getData = (log: EventLogInfo) => {
  return EventTypeDataFields[log.logType].map((key: string) => log[key]);
};

const toPercent = (arr: number[]): number[] => {
  const total = arr.reduce((acc, it) => acc + it, 0);
  return arr.map((it) => (it / total) * 100);
};

export function createChartConfig<T>(log: EventLogInfo, isOnline: boolean): ChartConfiguration<'doughnut'> {
  const config = cloneDeep(DEFAULT_CHART_CONFIG);
  const data = getData(log);

  config.options.plugins.title.text = EventLogTypesLabels[log.logType];

  if (isOnline) {
    config.options.onHover = onHover;
  }

  config.plugins.push({
    id: 'afterInit',
    afterInit: (chart: any) => {
      chart.entity = log.logType;
      chart.entityEntrys = EventTypeLabelsForChart[log.logType];
    }
  });

  if (data.every((d) => d === 0)) {
    config.data.datasets = [
      {
        hoverOffset: 4,
        data: [1],
        backgroundColor: undefined,
        label: EventLogTypesLabels[log.logType]
      }
    ];

    config.options.plugins.tooltip = {
      enabled: false
    };

    config.data.labels = [EventLogTypesLabels.NO_DATA];
    config.customLegend = false;

    return config;
  }

  const backgroundColor = EventTypeColors[log.logType];
  const cssClass = EventTypeCssClass[log.logType];
  config.data.labels = EventTypeLabelsForChart[log.logType];

  config.data.datasets = [
    {
      label: EventLogTypesLabels[log.logType],
      hoverOffset: 4,
      backgroundColor,
      // cssClass,
      // themeTypes: EventTypeLabelsForChart[log.logType],
      data: toPercent(data).map((it) => Math.max(it, 1))
      // dataRaw: data
    }
  ];

  return config;
}

export const isLastTimeOfDate = (date: Date, newDate: Date, stop: string): boolean => {
  const duration = Math.abs(moment.duration(moment(date).diff(moment(newDate))).asHours());
  return duration < 24 ? true : false;
};

export const getISOStrings =
  (utcOffset: number) =>
  (date: Date = new Date()): EventDateRange => {
    const copy = moment.utc({
      year: date.getFullYear(),
      day: date.getDate(),
      month: date.getMonth()
    });

    const start = moment(copy).startOf('day').subtract(utcOffset, 'minutes').toISOString();
    const stop = moment(copy).endOf('day').subtract(utcOffset, 'minutes').toISOString();

    return { start, stop };
  };

export const stringToBoolean = (bool: string) => {
  switch (bool) {
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
    case '':
    default:
      return false;
  }
};

export const parseXML = (data: string) => {
  if (!data) return [];
  let xmlDoc;

  if (window['DOMParser']) {
    const parser = new DOMParser();
    xmlDoc = parser.parseFromString(data, 'text/xml');
  }
  return [];
};
