import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { DevicesService } from '@services/devices.service';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { catchError, of, take } from 'rxjs';
import { TFAService } from '../../services/tfa.service';
import { ModalService } from 'mbs-ui-kit/modal/modal.service';

@Component({
  selector: 'mbs-delete-device-modal',
  templateUrl: './delete-device-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteDeviceModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public loading = false;
  public deleteLoading = false;

  public readonly alertType = MbsPopupType;

  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private deviceService: DevicesService,
    private modal: ModalService,
    private TFAService: TFAService
  ) {}

  delete(): void {
    this.loading = true;

    this.deviceService
      .deleteDevice(this.baseModal.data.id, this.baseModal.data.hasTwoFactorAuth)
      .pipe(
        take(1),
        catchError((error) => {
          this.loading = false;
          return of(error);
        })
      )
      .subscribe((res) => {
        if (res?.error?.authId) {
          this.showConfirmActionModal(res.error);
        } else {
          this.baseModal.save();
        }
        this.loading = false;
        this.deleteLoading = false;
        this.cdr.detectChanges();
      });
  }

  showConfirmActionModal(data): Promise<unknown> {
    return this.TFAService.openApproveModal(data)
      .then(() => {
        this.deleteLoading = true;
        this.cdr.detectChanges();
        this.delete();
      })
      .catch(() => {
        this.baseModal.close();
      });
  }
}
