import { AfterViewInit, Directive, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isNil } from 'lodash';
import { merge, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ConfirmReason } from '../services/data-change-watcher/ConfirmReason';
import { DataChangeWatcherBase } from '../services/data-change-watcher/data-change-watcher-base.directive';
import { DataChangeWatcherService } from '../services/data-change-watcher/data-change-watcher.service';
import { TabsetDirective } from '../tabset/directives/tabset.directive';
import { EnumHelper, GuidEmpty } from '../utils';
import { Sidepanel } from './Sidepanel';
import { SidepanelComponent } from './sidepanel.component';

@UntilDestroy()
@Directive()
export abstract class SidepanelCrudBase<T> extends DataChangeWatcherBase implements Sidepanel, AfterViewInit, OnDestroy {
  @Output() close = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() delete = new EventEmitter();

  protected mergeClose = merge(this.save, this.close, this.delete).subscribe(() => this.genericPanel.close());

  public data$: Subject<T> = new Subject();
  public dataCopy: T;
  public create = false;

  public set loadingData(value: boolean) {
    this._loadingData = value;
    this.changeTabsetItemsStatusLoadingAndDisabled(value);
  }

  public get loadingData(): boolean {
    return this._loadingData;
  }

  private _loadingData = false;

  abstract updateData(data: T): void;
  abstract handleSave(): Observable<boolean>;
  abstract handleDelete(): Observable<boolean>;

  @ViewChild(SidepanelComponent, { static: true }) genericPanel: SidepanelComponent;
  @ViewChild(TabsetDirective, { read: TabsetDirective, static: false }) tabset: TabsetDirective;
  protected constructor(protected cdNew: DataChangeWatcherService) {
    super(cdNew);

    this.detectChanges$ = this.detectChanges$.pipe(
      switchMap((modalResult: ConfirmReason) => {
        if (modalResult === ConfirmReason.SAVE) {
          return this.handleSave().pipe(map(() => modalResult));
        }
        return of(modalResult);
      })
    );

    this.data$
      .pipe(
        filter(Boolean),
        switchMap((data) => {
          return this.genericPanel.show ? this.canUpdateData().pipe(map((canUpdate) => (canUpdate ? data : undefined))) : of(data);
        }),
        filter(Boolean), // after modal result check again
        untilDestroyed(this)
      )
      .subscribe((data) => {
        const id = (data['Id'] || data['id']) as string | number;
        this.create = isNil(data) || isNil(id) || id === GuidEmpty;
        this.genericPanel.isCreate = this.create;
        this.tabset && this.tabset.select(this.tabset.items.first.id);
        this.updateData(cloneDeep(data));
        this.genericPanel.open();
        this.dataCopy = cloneDeep(data);
      });
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    this.destroy();
  }

  ngAfterViewInit(): void {
    this.genericPanel.saveEvent.subscribe(() => this.handleSaveVoid());
    this.genericPanel.deleteEvent.subscribe(() => this.handleDeleteVoid());
    this.genericPanel.panelClosed.subscribe(() => this.handleCloseVoid());
    this.genericPanel.panelOpened.subscribe(() => this.open.emit());

    this.changeTabsetItemsStatusLoadingAndDisabled(this.loadingData);
  }

  public changeTabsetItemsStatusLoadingAndDisabled(value: boolean): void {
    if (this.tabset?.items) {
      this.tabset.items.forEach((item) => {
        item.forceDisabled = value;
        item.forceLoading = value;
      });
    }
  }

  public handleClose(): Observable<boolean> {
    return this.canUpdateData().pipe(
      tap((canClose) => {
        if (canClose) {
          this.hold();
          this.close.emit();
        }
      })
    );
  }

  public handleSaveVoid(): void {
    if (!this.isValidSidepanel() && this.tabset?.activeId) {
      this.tabset.afterUpdate.emit(this.tabset.activeId);
    }

    this.handleSave().subscribe();
  }

  public handleCloseVoid(): void {
    this.handleClose().subscribe();
  }

  public handleDeleteVoid(): void {
    this.handleDelete().subscribe();
  }

  protected canUpdateData(): Observable<boolean> {
    return this.detectChanges$.pipe(map((modalResult: ConfirmReason) => EnumHelper.HasFlag(modalResult, ConfirmReason.CAN_CLOSE)));
  }

  public isValidSidepanel(): boolean {
    if (this.tabset?.items) {
      return this.cdNew.tabsService.allTabsValid;
    }

    return true;
  }
}
