import { APP_BASE_HREF, CommonModule, DatePipe, Location } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddNetworkShareModalComponent } from '@components/add-network-share-modal/add-network-share-modal.component';
import { AllowOfflineEditModalComponent } from '@components/allow-offline-edit-modal/allow-offline-edit-modal.component';
import { FooterModule } from '@components/app-footer/app-footer.module';
import { BackupDestinationModalComponent } from '@components/backup-destination-modal/backup-destination-modal.component';
import { ComputersModalModule } from '@components/computers-modal/computers-modal.module';
import { RmmDateTimeFormatModule } from '@components/date-time-format/rmm-date-time-format.module';
import { DownloadBuildModule } from '@components/download-build/download-build.module';
import { DownloadConnectModule } from '@components/download-connect/download-connect.module';
import { BALANCE_TOKEN } from '@components/licenses/tokens/balance.token';
import { LoginModule } from '@components/login/login.module';
import { NoComputersModule } from '@components/no-computers/no-computers.module';
import { NotFoundWrapperComponent } from '@components/not-found-wrapper/not-found-wrapper.component';
import { QuickRestoreSchemaModalComponent } from '@components/quick-restore-schema-modal/quick-restore-schema-modal.component';
import { SidepanelComputersWrapperComponent } from '@components/sidepanel-computers-wrapper/sidepanel-computers-wrapper.component';
import { SidepanelDownloadsComponent } from '@components/sidepanel-downloads/sidepanel-downloads.component';
import { TwoFactorModule } from '@components/sidepanel-rmm/two-factor-auth-confirm/two-factor.module';
import { StorageAccountsModule } from '@components/storage-accounts/storage-accounts.module';
import { TFASharedModule } from '@components/tfa/tfa-shared.module';
import { UnsupportedVersionComponent } from '@components/unsupported-version/unsupported-version.component';
import { WidgetsModule } from '@components/widgets/widgets.module';
import { ComputersFacade } from '@facades/computers.facade';
import { EmailServiceStatusComponent } from '@modules/email-service-status/email-service-status.component';
import { EmptyPageModule } from '@modules/empty-page/empty-page.module';
import { ErrorHandlerModule } from '@modules/error-handler/error-handler.module';
import { ErrorModule } from '@modules/error/error.module';
import { OverdueModalModule } from '@modules/overdue-modal/overdue-modal.module';
import { PasswordRecoveryModule } from '@modules/password-recovery/password-recovery.module';
import { PosthogModule } from '@modules/posthog/posthog.module';
import { RmmLicensesModule } from '@modules/rmm-licenses/rmm-licenses.module';
import { RMMGroupActionWizardModule } from '@modules/schedule-group-action/rmm-group-action-wizard.module';
import { SidepanelRmmManagementModule } from '@modules/script-library/sidepanel/sidepanel-rmm-management.module';
import { SignalRModule } from '@modules/signal-r/signal-r.module';
import { AllWizardsModule } from '@modules/wizards/all-wizards.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18N_PROVIDERS } from '@root/mbs-ui/i18';
import { AppInitService } from '@services/app-init.service';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { AuthService } from '@services/auth.service';
import { ComputersAbstractService, ComputersService } from '@services/computers.service';
import { ComputersStatisticAbstractService, ComputersStatisticService } from '@services/computersStatistic.service';
import { DownloadService } from '@services/download.service';
import { LicensesService } from '@services/licenses.service';
import { OnlineAccessService } from '@services/onlineAccess';
import { ProviderService } from '@services/provider.service';
import { RmCommandsAbstractService, RmCommandsService } from '@services/rm-commands.service';
import { RmCommandsAbstractWrapper, RmCommandsWrapper } from '@services/rm-commands.wrapper';
import { AppHeaderComponent } from '@shared/components/app-header/app-header.component';
import { ComputersLegacyComponent } from '@shared/components/computers-legacy/computers-legacy.component';
import { DashboardComponent } from '@shared/components/dashboard/dashboard.component';
import { DeepInstinctModalComponent } from '@shared/components/deep-instinct-modal/deep-instinct-modal.component';
import { FeedbackComponent } from '@shared/components/feedback/feedback.component';
import { ForgotPasswordComponent } from '@shared/components/forgot-password/forgot-password.component';
import { HelpComponent } from '@shared/components/help/help.component';
import { POST_PAYMENT_TOKEN } from '@shared/components/licenses/tokens/post-payment.token';
import { MarketingIframeComponent } from '@shared/components/marketing-iframe/marketing-iframe.component';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { PaymentDebtModalComponent } from '@shared/components/payment-debt-modal/payment-debt-modal.component';
import { PaymentModalComponent } from '@shared/components/payment-modal/payment-modal.component';
import { RdWithNoLicenseComponent } from '@shared/components/rd-with-no-license/rd-with-no-license.component';
import { SidepanelIpWhiteListComponent } from '@shared/components/sidepanel-ip-white-list/sidepanel-ip-white-list.component';
import { SidepanelRmmModule } from '@shared/components/sidepanel-rmm/sidepanel-rmm.module';
import { SignupComponent } from '@shared/components/signup/signup.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ErrorHandlerInterceptor } from '@shared/interceptors/error-handler.interceptor';
import { RequestInterceptor } from '@shared/interceptors/request.interceptor';
import { RmCommandServiceUnavailableInterceptor } from '@shared/interceptors/rm-command-error.interceptor';
import { SentryInterceptor } from '@shared/interceptors/sentry.interceptor';
import { ServiceUnavailableInterceptor } from '@shared/interceptors/service-unavailable/service-unavailable.interceptor';
import { SessionInterceptor } from '@shared/interceptors/session.interceptor';
import { ConfigurationService } from '@shared/services/configuration.service';
import { AppLayoutComponent } from '@shared/_layout/app-layout/app-layout.component';
import { SimpleLayoutComponent } from '@shared/_layout/simple-layout/simple-layout.component';
import { AppStoreModule } from '@store/appStore.module';
import { APP_BACKEND_BUILD_VERSIONS } from '@utils/app-backend-build-versions';
import { WEB_CONNECT_URL } from '@utils/connect-url';
import { UtilsModule } from '@utils/utils.module';
import { ComputersFiltersAbstractWrapper, ComputersFiltersWrapper } from '@wrappers/computer.filters.wrapper';
import { AbilityModule, AbilityService } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { APP_READONLY_MODE, MbsUiKitModule, TreeModule } from 'mbs-ui-kit';
import { NgTerminalModule } from 'ng-terminal';
import { map } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const BASIC_APP_URL = window['__BASIC_APP_URL__'];
const BACKEND_BUILD_VERSIONS = window['__BACKEND_BUILD_VERSIONS__'];

@NgModule({
  declarations: [
    AddNetworkShareModalComponent,
    AppComponent,
    AppLayoutComponent,
    SimpleLayoutComponent,
    AppHeaderComponent,
    DashboardComponent,
    SidepanelDownloadsComponent,
    HelpComponent,
    SignupComponent,
    ForgotPasswordComponent,
    AllowOfflineEditModalComponent,
    SidepanelIpWhiteListComponent,
    UnsupportedVersionComponent,
    MarketingIframeComponent,
    NotFoundComponent,
    NotFoundWrapperComponent,
    SidepanelComputersWrapperComponent,
    RdWithNoLicenseComponent,
    EmailServiceStatusComponent,
    FeedbackComponent,
    PaymentModalComponent,
    PaymentDebtModalComponent,
    DeepInstinctModalComponent,
    ComputersLegacyComponent,
    QuickRestoreSchemaModalComponent,
    BackupDestinationModalComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppStoreModule,
    I18NextModule.forRoot(),
    HttpClientModule,
    NgbModule,
    NgSelectModule,
    AllWizardsModule,
    AbilityModule,
    MbsUiKitModule,
    FooterModule,
    LoginModule,
    SidepanelRmmManagementModule,
    UtilsModule,
    RmmLicensesModule,
    SidepanelRmmModule,
    NgTerminalModule,
    RMMGroupActionWizardModule,
    WidgetsModule,
    OverdueModalModule,
    StorageAccountsModule,
    DirectivesModule,
    TreeModule,
    RmmDateTimeFormatModule,
    RmmLicensesModule,
    EmptyPageModule,
    ErrorModule,
    DownloadBuildModule,
    DownloadConnectModule,
    PosthogModule,
    // NgxMatomoTrackerModule.forRoot({ trackerUrl: 'https://matomo.stage.mspbackups.com/', siteId: '1' }),
    // NgxMatomoRouterModule,
    PasswordRecoveryModule,
    TFASharedModule,
    NoComputersModule,
    TwoFactorModule,
    ComputersModalModule,
    SignalRModule.forRoot(),
    ErrorHandlerModule.forRoot()
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: BASIC_APP_URL },
    { provide: APP_BACKEND_BUILD_VERSIONS, useValue: BACKEND_BUILD_VERSIONS },
    { provide: WEB_CONNECT_URL, useValue: '/AP/WebConnect' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServiceUnavailableInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RmCommandServiceUnavailableInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SentryInterceptor,
      multi: true
    },
    ConfigurationService,
    AppInitService,
    {
      provide: APP_INITIALIZER,
      deps: [AppInitService],
      useFactory: (appInitService: AppInitService) => () => appInitService.init(),
      multi: true
    },
    {
      provide: POST_PAYMENT_TOKEN,
      deps: [AuthService],
      useFactory: (authService: AuthService) => authService.currentUser.pipe(map((r) => r?.ProviderInfo?.IsPostPayment))
    },
    {
      provide: BALANCE_TOKEN,
      deps: [LicensesService],
      useFactory: (licensesService: LicensesService) => licensesService.balance
    },
    Location,
    { provide: RmCommandsAbstractWrapper, useClass: RmCommandsWrapper },
    { provide: RmCommandsAbstractService, useClass: RmCommandsService },
    { provide: ComputersStatisticAbstractService, useClass: ComputersStatisticService },
    { provide: ComputersAbstractService, useClass: ComputersService },
    { provide: ComputersFiltersAbstractWrapper, useClass: ComputersFiltersWrapper },
    ComputersFacade,
    DownloadService,
    ProviderService,
    AppPersistentStateService,
    OnlineAccessService,
    {
      provide: APP_READONLY_MODE,
      useFactory: (ability: AbilityService): boolean => ability.can('read', 'Readonly'),
      deps: [AbilityService]
    },
    I18N_PROVIDERS(),
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
