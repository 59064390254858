<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:schedule_title' | i18next: { format: 'title' } }}
    </h4>
    <div class="mbs-wizard_form-description">
      {{ 'wizards:schedule_subtitle' | i18next: { type: isRestore ? 'restore' : 'backup' } }}
    </div>
  </header>

  <div class="mbs-wizard_form-content"
       [class.pb-5]="isNBF">

    <mbs-form-group>
      <mbs-radio formControlName="ScheduleType"
                 name="ScheduleType"
                 value="noschedule"
                 (change)="scheduleTypeChangeHandler($event)"
                 [label]="'wizards:no_schedule_label' | i18next"
                 [id]="elementSelectors.id.scheduleTypeRadio1"></mbs-radio>

      <mbs-radio *ngIf="canShowFFI || isFFIEnabled"
                 formControlName="ScheduleType"
                 name="ScheduleType"
                 value="ffi"
                 (change)="scheduleTypeChangeHandler($event)"
                 [disabled]="!!invalidFFIStorage || !isFFIEnabled"
                 [label]="isFFIEnabled ? (!!invalidFFIStorage ? invalidLabelFFI : labelFFI) : labelFFI"
                 [id]="elementSelectors.id.scheduleTypeRadio6"></mbs-radio>

      <mbs-radio *ngIf="!isNBF || isRestore"
                 formControlName="ScheduleType"
                 name="ScheduleType"
                 value="once"
                 (change)="scheduleTypeChangeHandler($event)"
                 [label]="'wizards:specific_date_label' | i18next"
                 [descriptionOutside]="false"
                 [id]="elementSelectors.id.scheduleTypeRadio2">
        <ng-template radioDescription
                     [hide]="stepForm.get('ScheduleType').value !== 'once'">
          <div class="form-row"
               formGroupName="specificDateGroup">
            <div class="col-3">
              <mbs-datepicker formControlName="date"
                              [needSetCurrent]="true"
                              [id]="'specific-date-group-date-picker'"></mbs-datepicker>
            </div>
            <div class="col-3">
              <mbs-timepicker formControlName="time"
                              [id]="'specific-date-group-time-picker'"
                              [meridian]="true"
                              [needChangeInvalid]="true"></mbs-timepicker>
            </div>
          </div>
        </ng-template>
      </mbs-radio>

      <mbs-radio *ngIf="!isRestore && (isNBF || !isLinux)"
                 formControlName="ScheduleType"
                 name="ScheduleType"
                 value="predefined"
                 (change)="scheduleTypeChangeHandler($event)"
                 [descriptionOutside]="false"
                 [labelClasses]="'d-flex align-items-center'"
                 [label]="!isNBF ? ('wizards:predefined_label' | i18next) : ('wizards:incremental_label' | i18next)"
                 [id]="elementSelectors.id.scheduleTypeRadio3">
        <ng-container *ngIf="isNBF"
                      appendLabel>
          <mbs-button class="ml-3"
                      [id]="elementSelectors.id.scheduleTypeRadio3Edit"
                      [disabled]="stepForm.get('ScheduleType').value !== 'predefined'"
                      [isCtrl]="true"
                      (click)="openScheduleModal('incremental')">{{'buttons:edit' | i18next}}</mbs-button>
        </ng-container>
        <ng-template radioDescription
                     [hide]="isNBF">
          <div class="text-xs">
            {{'wizards:incremental_subtitle' | i18next}}
          </div>
        </ng-template>
      </mbs-radio>

      <mbs-radio *ngIf="!isNBF || isRestore"
                 formControlName="ScheduleType"
                 name="ScheduleType"
                 value="recurring"
                 (change)="scheduleTypeChangeHandler($event)"
                 [descriptionOutside]="false"
                 [label]="'wizards:advanced_label' | i18next: { advanced: isRestore ? '' : '(advanced schedule)'}"
                 [id]="elementSelectors.id.scheduleTypeRadio4">
        <ng-template radioDescription>
          <div class="text-xs">
            {{'wizards:advanced_subtitle' | i18next}}
          </div>
        </ng-template>
      </mbs-radio>

      <mbs-radio *ngIf="isBackupPlan && !isLinux && !isNBF"
                 formControlName="ScheduleType"
                 name="ScheduleType"
                 value="instantly"
                 (change)="scheduleTypeChangeHandler($event)"
                 [descriptionOutside]="false"
                 [label]="'wizards:real_time_label' | i18next"
                 [id]="elementSelectors.id.scheduleTypeRadio5">
        <ng-template radioDescription>
          <div class="text-xs">
            {{'wizards:real_time_subtitle' | i18next}}
          </div>
        </ng-template>
      </mbs-radio>
    </mbs-form-group>

    <mbs-form-group *ngIf="isNBF && !isRestore"
                    formGroupName="fullBackupData">
      <mbs-checkbox formControlName="enabled"
                    (change)="enabledFullChangeHandler($event)"
                    [label]="isLinux ? ('wizards:execute_full_linux_label' | i18next) : 'wizards:execute_full_label' | i18next"
                    [id]="elementSelectors.id.fullBackupEnabledCheckbox">
        <ng-container appendLabel>
          <mbs-button class="ml-3"
                      [id]="elementSelectors.id.fullBackupEnabledCheckboxEdit"
                      [disabled]="stepForm.get('ScheduleType').value !== 'predefined' || !stepForm.get('fullBackupData')?.value?.enabled"
                      [isCtrl]="true"
                      (click)="openScheduleModal(null)">{{'buttons:edit' | i18next}}</mbs-button>
        </ng-container>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="(isRestore && isNBF) || (!isLinux && !isNBF && !isSQLPlan)">
      <mbs-checkbox formControlName="syncBeforeRun"
                    [label]="isRestore ? ('wizards:restores_sync_local_with_storage_label' | i18next) : ('wizards:sync_local_with_storage_label' | i18next)"
                    [id]="'sync-before-run-checkbox'"></mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group>
      <div class="form-row align-items-center">
        <div class="col-auto">
          <mbs-checkbox formControlName="StopAfterEnabled"
                        (change)="stopAfterEnabledChangeHandler($event)"
                        [label]="'wizards:stop_plan_label' | i18next"
                        [id]="'stop-after-enabled-checkbox'"></mbs-checkbox>
        </div>
        <div class="col">
          <div class="form-row align-items-center">
            <div class="col-2">
              <mbs-number formControlName="stopAfterHours"
                          min="0"
                          (change)="stopAfterHoursChangeHandler($event)"
                          [id]="'stop-after-hours-number'"></mbs-number>
            </div>
            <div class="col-auto">
              <label [class.-disabled]="!stepForm.get('StopAfterEnabled').value"
                     class="mbs-form_label mb-0"
                     for="stop-after-hours-number">
                {{'wizards:hours' | i18next}}
              </label>
            </div>
            <div class="col-2">
              <mbs-number formControlName="stopAfterMinutes"
                          min="0"
                          max="59"
                          [id]="'stop-after-minutes-number'"></mbs-number>
            </div>
            <div class="col-auto">
              <label [class.-disabled]="!stepForm.get('StopAfterEnabled').value"
                     class="mbs-form_label mb-0"
                     for="stop-after-minutes-number">
                {{'wizards:minutes' | i18next}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </mbs-form-group>

    <mbs-form-group *ngIf="showOverdue">
      <div class="form-row align-items-center">
        <div class="col-auto">
          <mbs-checkbox formControlName="overdueAfterSuccessEnabled"
                        (change)="overdueAfterSuccessChangeHandler($event)"
                        [label]="'wizards:overdue_label' | i18next"
                        [id]="'overdue-after-success-enabled-checkbox'"></mbs-checkbox>
        </div>
        <div class="col">
          <div class="form-row align-items-center">
            <div class="col-2">
              <mbs-number formControlName="overdueAfterSuccessAmount"
                          min="1"></mbs-number>
            </div>
            <div class="col-3">
              <mbs-select formControlName="overdueAfterSuccessPeriod"
                          [bindValue]="'value'"
                          [bindLabel]="'label'"
                          [clearable]="false"
                          [hideSelected]="true"
                          [items]="OverduePeriod"
                          [id]="'overdue-after-success-period-select'"
                          [searchable]="false"></mbs-select>
            </div>
            <div class="col-7">
              <label [class.-disabled]="!stepForm.get('overdueAfterSuccessEnabled').value"
                     class="mbs-form_label mb-0"
                     for="overdue-after-success-period-select">
                {{'wizards:overdue_without_success' | i18next}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </mbs-form-group>

    <mbs-form-group [class.mb-3]="!isNBF"
                    [class.mb-5]="isNBF">
      <mbs-checkbox formControlName="ForceMissedSchedule"
                    [label]="'wizards:run_missed_label' | i18next"
                    [id]="'run-missed-checkbox'"></mbs-checkbox>
    </mbs-form-group>
  </div>

  <mbs-form-group *ngIf="!isRestore && isNBF && ((stepForm.get('ScheduleType').value === 'predefined') || (stepForm.get('ScheduleType').value === 'ffi'))">
    <mbs-card [size]="'sm'">
      <mbs-card-header [title]="'Total info:'"></mbs-card-header>
      <p *ngIf="stepForm.get('fullBackupData')?.value?.enabled && stepForm.get('ScheduleType').value !== 'ffi'"
         class="mb-0">
        {{'wizards:full_text' | i18next}} {{schedulesTexts.fullBackupData}}
      </p>

      <p *ngIf="stepForm.get('ScheduleType').value === 'predefined'" class="mb-0">
        {{'wizards:incremental_text' | i18next}} {{schedulesTexts.incrementalData}}
      </p>
      <p *ngIf="stepForm.get('ScheduleType').value === 'ffi'" class="mb-0">
        {{'wizards:ffi_label' | i18next}}: {{schedulesTexts.incrementalData}}
      </p>
    </mbs-card>
  </mbs-form-group>
</section>

<ng-template #fastNTFSModalContent>
  <p>{{'wizards:fast_ntfs_not_support_text' | i18next}}</p>
  <mbs-alert [type]="mbsPopupType.warning"
             [icon]="true"
             class="mb-0">
    {{'wizards:fast_ntfs_not_support_alert_text' | i18next}}
  </mbs-alert>
</ng-template>

<ng-template #labelFFI>
  <mbs-tag [clickable]="false"
           [closable]="false"
           [active]="true"
           [noBorder]="true"
           [class]="FFITagClass">
    {{'app:notifications:new' | i18next}}
  </mbs-tag>
  <span class="ml-2">{{ 'wizards:ffi_label' | i18next }}</span>
  <span>
    <mbs-button class="ml-3"
                [id]="elementSelectors.id.scheduleTypeRadio6Edit"
                [disabled]="!isFFIEnabled || stepForm.get('ScheduleType').value !== 'ffi'"
                [isCtrl]="true"
                (click)="openScheduleModal('ffi')">{{'buttons:edit' | i18next}}</mbs-button>
  </span>
  <p *ngIf="isFFIEnabled" class="text-sm mb-0 mt-1">{{ 'wizards:ffi_label_info' | i18next }}</p>
  <p *ngIf="isFFIEnabled" class="mb-0"><strong class="text-sm">{{ 'wizards:ffi_label_note' | i18next }}</strong></p>
  <mbs-alert *ngIf="isRDMode || canShowFFI"
             class="{{isFFIEnabled ? 'mt-1' : 'mt-3'}}"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{ 'wizards:ffi_label_min_version_attention' | i18next }}
  </mbs-alert>
</ng-template>

<ng-template #invalidLabelFFI>
  <mbs-tag [clickable]="false"
           [closable]="false"
           [active]="true"
           [noBorder]="true"
           [class]="'bg-red-35 mt-n1'">
    {{'app:notifications:new' | i18next}}
  </mbs-tag>
  <span class="ml-2">{{ 'wizards:ffi_label' | i18next }}</span>
  <mbs-alert class="mt-3"
             [type]="mbsPopupType.info"
             [icon]="true">
    {{ invalidFFIStorageAlert }}
  </mbs-alert>
</ng-template>

<ng-template #FFIPayAttentionGFS>
  <mbs-alert *ngIf="!isGFSMode"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:ffi_warning_title_prefix'|i18next}}
    <strong>{{'wizards:ffi_label'|i18next}}</strong>
    {{'wizards:ffi_warning_title_postfix'|i18next}}
  </mbs-alert>
  <mbs-alert *ngIf="isGFSMode"
             [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:ffi_warning_title_prefix'|i18next}}
    <strong>{{'wizards:ffi_label'|i18next}}</strong>
    {{'wizards:ffi_warning_title_postfix'|i18next}}.
    {{'wizards:ffi_warning_title_with_gfs_prefix'|i18next}},
    <strong>{{'wizards:ffi_warning_title_with_gfs'|i18next}}</strong>
    {{'wizards:ffi_warning_title_with_gfs_postfix'|i18next}}
  </mbs-alert>
  <strong>{{'app:notifications:attention'|i18next}}!</strong>
  <ul>
    <li>{{'wizards:ffi_warning_aspect_1'|i18next}}</li>
    <li>{{'wizards:ffi_warning_aspect_2'|i18next}}</li>
  </ul>
  <a *ngIf="ability.can('read', 'HelpMarketing')"
     class="col-3 d-flex align-items-baseline justify-content-end"
     href="/AP/Help/backup/about/backup-format/ffi"
     target="_blank">
    {{'wizards:learn_more_link'|i18next}}
    <span class="ml-1 fa fa-external-link"></span>
  </a>
</ng-template>
