import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PlanMode } from '@models/PlanTypes.enum';
import { StorageClass, TypesForFFI } from '@models/StorageType.enum';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { AdvancedOptionsStepValue, AzureAccessTierType, BlockSizeEnum } from '@modules/wizards/models/advanced-options-models';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { FilesFoldersListComponent } from '@modules/wizards/steps/components/files-folders-list/files-folders-list.component';
import { FormPipeOperators, StepBase } from '@modules/wizards/steps/StepBase.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { EnumHelper, FormsUtil, ModalService, ModalSettings } from 'mbs-ui-kit';
import { debounceTime } from 'rxjs/operators';

const AdvancedOptionsStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => AdvancedOptionsStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-advanced-options-step',
  templateUrl: './advanced-options-step.component.html',
  providers: [AdvancedOptionsStepValueAccessor]
})
export class AdvancedOptionsStepComponent extends StepBase<AdvancedOptionsStepValue> implements OnInit {
  @Input() advancedOptionsFlags = null;

  @Output() changeStorageType = new EventEmitter<{ azureType?: AzureAccessTierType; isStorageBadForFFI: boolean }>();
  @Output() restoreVerificationEnabled = new EventEmitter();

  private readonly notSQLDisabledFields = ['UseS3Acceleration', 'StorageClass', 'azureAccessTier'];
  private readonly defaultValuesMap = {
    PrefetchBlockCount: { value: 0 },
    BlockSize: { value: 0 },
    excludeRules: { value: [] },
    SyntheticFull: { value: true }
  };
  private readonly syntheticFullModalSettings: ModalSettings = {
    header: { title: this.i18nPipe.transform('wizards:error_synthetic_title', { format: 'title' }) },
    footer: {
      okButton: { text: this.i18nPipe.transform('buttons:yes', { format: 'title' }), type: 'success' },
      cancelButton: { text: this.i18nPipe.transform('buttons:no', { format: 'title' }) }
    }
  };
  private readonly keepEFSFilesAsIsModalSettings: ModalSettings = {
    header: { title: this.i18nPipe.transform('wizards:keep_efs_encryption_warning_header', { format: 'title' }) },
    footer: {
      okButton: { text: this.i18nPipe.transform('buttons:enable', { format: 'title' }), type: 'primary' },
      cancelButton: { text: this.i18nPipe.transform('buttons:cancel', { format: 'title' }) }
    }
  };

  public StorageClasses = StepsHelpers.getStorageClasses();

  public AccessTires = EnumHelper.EnumToSelectIndexesArray(AzureAccessTierType);

  public foldersForm = new UntypedFormGroup({ filesFoldersData: new FormControl({}) });

  public blockSizesArray = EnumHelper.EnumToSelectIndexesArray(BlockSizeEnum);
  public correctlyVersionForShowBlockCount = false;
  public canShowEFSRequiredInfo = false;

  private needValidate = false;
  private needValidateStorageClassOrAzure = false;

  get isShowSyntheticFull(): boolean {
    return (this.isNBF || !this.isBackupPlan) && !this.isLinux && !!this.advancedOptionsFlags?.SyntheticFullSettingPossible;
  }

  /**
   * We can rule this option if:
   * *
   * Files backup
   * NBF and not linux only
   * Agent version >= 7.6
   */
  get isShowEFS(): boolean {
    const rightVersion = this.mainService.backupVersionUpdated && +this.mainService.backupVersionUpdated.substring(0, 2) >= 76;
    return !this.isLinux && this.isBackupPlan && this.isNBF && (rightVersion || this.isRDMode);
  }

  @ViewChild('errorSyntheticFull', { static: true, read: TemplateRef }) errorSyntheticFull: TemplateRef<any>;
  @ViewChild('fileFoldersComponent', { static: true, read: FilesFoldersListComponent }) fileFoldersComponent: FilesFoldersListComponent;
  @ViewChild('EFSPayAttention', { static: true, read: TemplateRef }) EFSPayAttention: TemplateRef<any>;

  constructor(public i18nPipe: I18NextPipe, public mainService: RemoteManagementWizardsService, public modalService: ModalService) {
    super(mainService);

    const version = mainService.backupVersionUpdated && mainService.backupVersionUpdated.substring(0, 3);

    this.correctlyVersionForShowBlockCount = this.isRDMode || (version && +version > 711);

    if (this.isRDMode || (version && (+version >= 731 || (this.isLinux && +version >= 331)))) {
      this.addGlacierIR();
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this), debounceTime(200)];
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      UseS3Acceleration: new FormControl(false),
      StorageClass: new FormControl(1),
      azureAccessTier: new FormControl(0),
      ExcludeEnabled: new FormControl(false),
      excludeRules: new FormControl([], [Validators.required]),
      SyntheticFull: new FormControl(false, this.syntheticFullValidator.bind(this)),
      SaveDeletedDataInCloud: new FormControl(false),
      IgnoreBadSectors: new FormControl(false),
      DisableVSS: new FormControl(false),
      useSystemVSSProvider: new FormControl(false),
      KeepEFSFilesAsIs: new FormControl(false),
      PrefetchBlockCount: new FormControl(0),
      BlockSize: new FormControl(),
      AlwaysUseVSS: new FormControl(false),
      BackupNTFSPermissions: new FormControl(false),
      UseFastNtfsScan: new FormControl(false)
    });

    this.subscribeToFoldersForm();
    this.initFormEvents();

    setTimeout(() => {
      if (this.mainService.mode === PlanMode.create) return void this.updateFormState(this.advancedOptionsFlags);

      if (this.isSQLPlan) return void this.disableFieldsForSQLPlan(Object.keys(this.stepForm.getRawValue()));
    });

    this.setValues();
  }

  subscribeToFoldersForm(): void {
    this.foldersForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value: any) => {
      if (!value.filesFoldersData) return;

      if (value.filesFoldersData.paths?.length || !value.filesFoldersData.enabledFields) {
        this.needValidate = !this.value.valid;
      }

      this.stepForm.get('ExcludeEnabled').setValue(value.filesFoldersData.enabledFields);
      this.excludeEnabledChangeHandler(value.filesFoldersData.enabledFields);
      this.stepForm.get('excludeRules').setValue(value.filesFoldersData.paths);
    });
  }

  onStepFormChange(value: AdvancedOptionsStepValue): void {
    if (this.stepForm.touched || this.stepForm.dirty) {
      this.value = { ...value, valid: this.stepForm.valid };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stepForm && changes.advancedOptionsFlags) {
      this.updateFormState(changes.advancedOptionsFlags.currentValue);

      if (!this.advancedOptionsFlags.AzureAccessTierType) {
        this.azureAccessTierChangeHandler(AzureAccessTierType.Hot);

        if (this.advancedOptionsFlags.StorageClassSettingPossible) this.storageClassChangeHandler(this.stepForm.get('StorageClass').value);
      }
      if (!this.advancedOptionsFlags.StorageClassSettingPossible) {
        this.storageClassChangeHandler(StorageClass.STANDARD);

        if (this.advancedOptionsFlags.AzureAccessTierType) this.azureAccessTierChangeHandler(this.stepForm.get('azureAccessTier').value);
      }
    }

    if (!this.confirmedAsEnabled && changes?.stepFocused?.currentValue) {
      this.showEnableStepModal(this.modalService);
    }
  }

  syntheticFullValidator(control: AbstractControl): ValidationErrors | null {
    if (this.isLinux || !this.stepForm || !control.value || (!this.isIBBPlan && !this.isNBF)) return null;
    if (!this.advancedOptionsFlags || !this.needValidateStorageClassOrAzure) return null;

    if (this.advancedOptionsFlags.StorageClassSettingPossible) {
      return TypesForFFI.unsupported.Amazon.includes(this.stepForm.get('StorageClass').value) ? { invalidValue: true } : null;
    }

    if (this.advancedOptionsFlags.AzureAccessTierType || this.isNBF) {
      return TypesForFFI.unsupported.Azure.includes(this.stepForm.get('azureAccessTier').value) ? { invalidValue: true } : null;
    }

    return null;
  }

  updateForm(value: AdvancedOptionsStepValue): void {
    if (this.isRDMode) {
      if (value.ExcludeEnabled || (value.excludeRules && value.excludeRules.length)) {
        delete value.ExcludeEnabled;
        delete value.excludeRules;
        this.importedPlanChanged = true;
      }

      this.correctlyVersionForShowBlockCount = true;
      this.addGlacierIR();
    }

    this.stepForm.reset(value);

    this.changeStepFormState(value);

    if (!isNil(value.AlwaysUseVSS)) {
      this.alwaysUseVSSChangeHandler(value.AlwaysUseVSS);

      if (!isNil(value.UseFastNtfsScan)) {
        if (value.UseFastNtfsScan) this.useFastNtfsScanChangeHandler(value.UseFastNtfsScan);
        else this.stepForm.get('AlwaysUseVSS').enable();
      }
    } else if (!isNil(value.UseFastNtfsScan) && !value.UseFastNtfsScan) {
      this.useFastNtfsScanChangeHandler(value.UseFastNtfsScan);
    }

    if (!isNil(value.ExcludeEnabled)) {
      this.excludeEnabledChangeHandler(value.ExcludeEnabled);
      this.foldersForm.get('filesFoldersData').reset({ enabledFields: value.ExcludeEnabled, paths: value.excludeRules });
    }

    this.setValuesFromObjOrDefault(value);
    this.updateCanShowEFSRequiredInfo();
  }

  forceValid(currentStep: any): void {
    const main = this.stepForm.value;
    this.needValidate = main.ExcludeEnabled && !main.excludeRules.length;

    if (!this.isRDMode && this.advancedOptionsFlags?.ExcludePossible) this.fileFoldersComponent.updateValidate();
    if (!this.isLinux && (this.isIBBPlan || this.isNBF) && currentStep.isNext && this.stepForm.get('SyntheticFull').invalid) {
      this.showSyntheticFullModal(currentStep.isNext);
    }

    FormsUtil.triggerValidation(this.stepForm);

    if (this.value?.valid && !isNil(this.value?.PrefetchBlockCount)) {
      this.stepForm.get('PrefetchBlockCount').reset(this.value.PrefetchBlockCount);
    }
  }

  storageClassChangeHandler(storageClass: StorageClass): void {
    this.needValidateStorageClassOrAzure = TypesForFFI.unsupported.Amazon.includes(storageClass);
    this.restoreVerificationEnabled.emit(!this.needValidateStorageClassOrAzure);
    this.stepForm.get('SyntheticFull').updateValueAndValidity();
    this.changeStorageType.emit({
      isStorageBadForFFI: StepsHelpers.isStorageBadForFFI(storageClass, 'Amazon')
    });
  }

  azureAccessTierChangeHandler(type: AzureAccessTierType): void {
    this.needValidateStorageClassOrAzure = type === AzureAccessTierType.Archive;
    this.restoreVerificationEnabled.emit(type !== AzureAccessTierType.Archive);
    this.stepForm.get('SyntheticFull').updateValueAndValidity();
    this.changeStorageType.emit({
      azureType: type,
      isStorageBadForFFI: StepsHelpers.isStorageBadForFFI(type, 'Azure')
    });
  }

  useFastNtfsScanChangeHandler(event: boolean): void {
    if (event) {
      this.stepForm.get('AlwaysUseVSS').setValue(true);
      this.alwaysUseVSSChangeHandler(event);
      return void this.stepForm.get('AlwaysUseVSS').disable();
    }

    this.stepForm.get('AlwaysUseVSS').enable();
  }

  alwaysUseVSSChangeHandler(event: boolean): void {
    if (!event) this.stepForm.get('useSystemVSSProvider').setValue(false);

    this.toggleFormControls(['useSystemVSSProvider'], event);
  }

  excludeEnabledChangeHandler(event: boolean): void {
    this.toggleFormControls(['excludeRules'], event);
  }

  keepEFSFilesAsIsChangeHandler(value: boolean): void {
    if (value !== true) return void this.updateCanShowEFSRequiredInfo();

    this.modalService
      .open(this.keepEFSFilesAsIsModalSettings, this.EFSPayAttention)
      .then(() => this.updateCanShowEFSRequiredInfo())
      .catch(() => {
        this.stepForm.get('KeepEFSFilesAsIs').setValue(false);
        this.updateCanShowEFSRequiredInfo();
      });
  }

  // Private block
  private updateFormState(flags): void {
    const keys = Object.keys(flags);
    const formObj = this.stepForm.getRawValue();
    const formObjKeys = Object.keys(formObj);
    const isCreate = this.mainService.mode === PlanMode.create;

    if (this.isSQLPlan) {
      this.disableFieldsForSQLPlan(formObjKeys);
    }

    keys.forEach((key) => {
      const idx = formObjKeys.findIndex((k) => key.toLowerCase().includes(k.toLowerCase()));
      const formKey = formObjKeys[idx];

      if (idx === -1) return;

      if (!this.advancedOptionsFlags[key]) return void this.disableFormFields(formKey);

      this.enableAndSetDefaultValue(
        formKey,
        formKey === 'AlwaysUseVSS'
          ? isNil(this.stepForm.value[formKey]) && !this.stepForm.value['UseFastNtfsScan']
          : (isCreate && formKey === 'SyntheticFull') || (formKey !== 'UseS3Acceleration' && formKey !== 'SyntheticFull')
          ? isNil(this.stepForm.value[formKey])
          : isNil(this.value[formKey])
      );
    });

    this.stepForm.markAsDirty();
    this.stepForm.updateValueAndValidity();
  }

  private addGlacierIR(): void {
    StepsHelpers.updateStorageClasses(this.StorageClasses);
  }

  private setValuesFromObjOrDefault(value: AdvancedOptionsStepValue): void {
    if (!isNil(value.StorageClass)) this.storageClassChangeHandler(value.StorageClass);
    else this.stepForm.get('StorageClass').setValue(1);

    if (!isNil(value.azureAccessTier)) this.azureAccessTierChangeHandler(value.azureAccessTier);
    else this.stepForm.get('azureAccessTier').setValue(0);

    if (this.isNBF) this.stepForm.get('SyntheticFull').enable();
    if (this.mainService.mode === PlanMode.create) this.stepForm.get('SyntheticFull').setValue(true);
  }

  private disableFormFields(key): void {
    if (key !== 'SyntheticFull' || (!this.isNBF && !this.isBackupPlan)) this.stepForm.get(key).disable();
  }

  private enableAndSetDefaultValue(key, isNullValue: boolean): void {
    if (isNullValue && key !== 'azureAccessTier' && key !== 'StorageClass') {
      const needValueFromMap = key === 'SyntheticFull' ? this.mainService.mode === PlanMode.create : this.defaultValuesMap[key];
      this.stepForm.get(key).patchValue(needValueFromMap ? this.defaultValuesMap[key].value : false);
    }

    if (key === 'useSystemVSSProvider') {
      if (!this.isBackupPlan || this.stepForm.get('AlwaysUseVSS').value) this.stepForm.get(key).enable();

      return;
    }

    if (key === 'AlwaysUseVSS' && this.stepForm.get('UseFastNtfsScan').value) return void this.stepForm.get(key).disable();

    this.stepForm.get(key).enable();
  }

  private changeStepFormState(value: AdvancedOptionsStepValue): void {
    /** Don`t disable KeepEFSFilesAsIs radio buttons if not linux */
    const keys = this.isLinux ? Object.keys(value) : Object.keys(value).concat(['KeepEFSFilesAsIs']);
    const formKeys = Object.keys(this.stepForm.getRawValue());

    formKeys.forEach((key) => this.stepForm.get(key)[keys.includes(key) ? 'enable' : 'disable']());
  }

  private showSyntheticFullModal(needNextStep: boolean): void {
    this.modalService
      .open(this.syntheticFullModalSettings, this.errorSyntheticFull)
      .then((confirm) => {
        if (!confirm) return;

        if (this.isNBF) this.advancedOptionsFlags.SyntheticFullSettingPossible = true;

        this.stepForm.get('SyntheticFull').setValue(false);
        this.stepForm.updateValueAndValidity();

        if (needNextStep) this.nextStep.emit();
      })
      .catch(() => this.stepForm.updateValueAndValidity());
  }

  private updateCanShowEFSRequiredInfo(): void {
    this.canShowEFSRequiredInfo = !this.isLinux && this.isRDMode && this.stepForm.get('KeepEFSFilesAsIs').value;
  }

  private disableFieldsForSQLPlan(fields: string[]): void {
    fields.forEach((key: string) => {
      if (!this.notSQLDisabledFields.includes(key)) this.stepForm.get(key).disable();
    });
  }
}
