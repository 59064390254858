import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TFAService } from '@components/tfa/services/tfa.service';
import { AuthService } from '@services/auth.service';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { catchError, of, take } from 'rxjs';

@Component({
  selector: 'mbs-change-email-modal',
  templateUrl: './change-email-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeEmailModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public loading = false;

  public form: UntypedFormGroup = new UntypedFormGroup({
    email: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required])
  });

  public passwordType: 'text' | 'password' = 'password';
  public MbsPopupType = MbsPopupType;

  public isEmailChanged = false;
  public newEmail = null;
  public oldEmail = null;

  constructor(public authService: AuthService, private cdr: ChangeDetectorRef, private TFAService: TFAService) {}

  changePasswordType(): void {
    this.passwordType = this.passwordType == 'password' ? 'text' : 'password';
  }

  show2FAConfirm(data): Promise<unknown> {
    return this.TFAService.openApproveModal(data);
  }

  changeEmail(): void {
    this.authService
      .changeEmail(this.form.value)
      .pipe(
        take(1),
        catchError((error) => of(error))
      )
      .subscribe((result) => {
        if (result?.error?.authId) {
          this.show2FAConfirm(result.error)
            .then(() => this.changeEmail())
            .catch(() => this.baseModal.close());
        } else if (result.currentEmail === result.newEmail) {
          this.baseModal.save();
        } else {
          this.isEmailChanged = true;
          this.newEmail = result.newEmail;
          this.oldEmail = result.currentEmail;
          this.cdr.detectChanges();
        }
      });
  }
}
