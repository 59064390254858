import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Computer from '@models/Computer';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { ModalComponent } from 'mbs-ui-kit';
import { Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { TerminalComponent } from './terminal.component';

@Component({
  selector: 'mbs-modal-terminal',
  templateUrl: './modal-terminal.component.html',
  styleUrls: ['./ng-terminal-wrapper.component.scss']
})
export class ModalTerminalComponent extends TerminalComponent implements OnInit, OnDestroy {
  @ViewChild(ModalComponent, { static: true }) baseModal;
  protected store$: Store;
  protected emuId = getGuid();

  private facade: ComputersFacade;
  public showModalButton = false;
  public require2faContainer = 'body .modal .modal-body';
  public computer$: Observable<Computer>;
  public processing$: Observable<boolean>;
  private subscriptions = new Subscription();

  constructor(protected injector: Injector) {
    super(injector);
    this.store$ = this.injector.get(Store);
    this.facade = this.injector.get(ComputersFacade);
  }

  ngOnInit(): void {
    this.initStreams();
    const hid$ = this.hid$.pipe(filter(Boolean));
    this.subscriptions.add(hid$.subscribe((hid) => this.facade.loadComputerByHid({ hid, quiet: true })));
    this.computer$ = hid$.pipe(switchMap((hid) => this.facade.getByHid(hid)));
  }

  ngOnDestroy(): void {
    this.subscriptions && this.subscriptions.unsubscribe();
  }
}
