import { Directive, OnDestroy } from '@angular/core';
import { StepsService } from '@modules/wizards/onboarding/services/steps/steps.service';
import { MbsPopupType, StepCondition } from 'mbs-ui-kit';

@Directive()
export class BaseStep<StepId> implements OnDestroy {
  protected stepId: string;
  protected isValid: boolean;
  protected errorMessage: string;
  public readonly mbsPopupType = MbsPopupType;

  constructor(private stepsService: StepsService<StepId>) {}

  ngOnDestroy(): void {
    this.stepsService.setProperty(
      this.stepId as unknown as StepId,
      'condition',
      this.isValid ? StepCondition.Completed : StepCondition.Progress
    );
  }

  public showAlert(message: string): void {
    this.errorMessage = message;
    setTimeout(() => (this.errorMessage = null), 5000);
  }

  public removeError() {
    this.errorMessage = null;
  }
}
