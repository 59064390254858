export interface Page {
  page: string[];
  id: number;
}

export type PagingParams = {
  currentPageNumber: number;
  perPage: number;
};

export function getPagingParams(pagination: PagingParams): QueryPagingParams {
  return {
    offset: (pagination.currentPageNumber - 1) * pagination.perPage,
    limit: pagination.perPage
  };
}

export type QueryPagingParams = {
  offset: number;
  limit?: number;
};

export enum SortDirections {
  Ascending = 'asc',
  Descending = 'desc'
}

export type SortDirection = 'asc' | 'desc' | '';

export interface SortParams {
  column: string;
  direction: SortDirection;
}

export function getSortParams(sorting: SortParams): QuerySortParams {
  // temporarily inverted sort parameter (until the bug in the table-sort.directive is fixed)
  const direction = sorting?.direction === 'asc' ? 'desc' : sorting?.direction === 'desc' ? 'asc' : '';
  return {
    orderBy: sorting && sorting.column && direction ? `${sorting.column}:${direction}` : null
  };
}

export interface QuerySortParams {
  orderBy: string;
}

export type QueryFiltersParams = {
  [param: string]: string | number | boolean;
};

export type QueryParams = Partial<QuerySortParams> & Partial<QueryPagingParams> & Partial<QueryFiltersParams>;

export class PagedResponse<T> {
  data: T[];
  total: number;
}

export type ClearPagingState = {
  loaded: boolean;
  loading: boolean;
  total: number;
};

export type PagingState = ClearPagingState &
  PagingParams & {
    sort: SortParams;
  };

export type AllPagesState = {
  [page: number]: string[];
};

export type ExtendedPagingState<T, F> = {
  paging: ClearPagingState &
    PagingParams & {
      pages: AllPagesState;
      filters: T;
      obsolescenceMark: number;
    };
  data: ClearPagingState & {
    // no filters, no sorting
    entities: string[];
    obsolescenceMark: number;
  };
  loading: boolean; // combined loading for data and entities
  selected: string;
  custom: F;
};

export enum PerPageSize {
  Small = 10,
  Medium = 25,
  Large = 50
}
