import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ForceMSP2FaModalComponent } from '@components/tfa/components/force-msp-2fa-modal/force-msp-2fa-modal.component';
import { IncorrectTimeAlertComponent } from '@components/tfa/components/incorrect-time-alert/incorrect-time-alert.component';
import { TfaTimerComponent } from '@components/tfa/components/tfa-timer/tfa-timer.component';
import { NgbDropdownModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { ButtonModule, MbsUiKitModule, ModalModule } from 'mbs-ui-kit';
import { AddDeviceModalComponent } from './components/add-device-modal/add-device-modal.component';
import { ChangeEmailModalComponent } from './components/change-email-modal/change-email-modal.component';
import { DeleteDeviceModalComponent } from './components/delete-device-modal/delete-device-modal.component';
import { EnablePushModalComponent } from './components/enable-push-modal/enable-push-modal.component';
import { GoogleQRCodeComponent } from './components/google-qr-code/google-qr-code.component';
import { InstallMspAppBadgesComponent } from './components/install-msp-app-badges/install-msp-app-badges.component';
import { InstallMspAppInstructionComponent } from './components/install-msp-app-instruction/install-msp-app-instruction.component';
import { MspQRCodeComponent } from './components/msp-qr-code/msp-qr-code.component';
import { RecoveryCodeModalComponent } from './components/recovery-code-modal/recovery-code-modal.component';
import { RecoveryCodeComponent } from './components/recovery-code/recovery-code.component';
import { TFARequireModalComponent } from './components/tfa-require-modal/tfa-require-modal.component';
import { TwoFactorApproveModalComponent } from './components/two-factor-approve-modal/two-factor-approve-modal.component';
import { TwoFactorEnableModalComponent } from './components/two-factor-enable-modal/two-factor-enable-modal.component';
import { TFAService } from './services/tfa.service';
import { SidepanelDevicesModule } from '@components/tfa/components/sidepanel-devices/sidepanel-devices.module';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    FormsModule,
    NgbModule,
    NgbTooltipModule,
    NgSelectModule,
    ReactiveFormsModule,
    I18NextModule,
    RouterModule,
    AbilityModule,
    SidepanelDevicesModule,
    MbsUiKitModule,
    ButtonModule,
    ModalModule
  ],
  declarations: [
    TwoFactorEnableModalComponent,
    TwoFactorApproveModalComponent,
    AddDeviceModalComponent,
    DeleteDeviceModalComponent,
    EnablePushModalComponent,
    MspQRCodeComponent,
    RecoveryCodeModalComponent,
    RecoveryCodeComponent,
    GoogleQRCodeComponent,
    ChangeEmailModalComponent,
    TFARequireModalComponent,
    InstallMspAppBadgesComponent,
    InstallMspAppInstructionComponent,
    ForceMSP2FaModalComponent,
    TfaTimerComponent,
    IncorrectTimeAlertComponent
  ],
  exports: [
    TwoFactorEnableModalComponent,
    TwoFactorApproveModalComponent,
    AddDeviceModalComponent,
    DeleteDeviceModalComponent,
    EnablePushModalComponent,
    MspQRCodeComponent,
    RecoveryCodeModalComponent,
    RecoveryCodeComponent,
    GoogleQRCodeComponent,
    ChangeEmailModalComponent,
    TFARequireModalComponent,
    InstallMspAppBadgesComponent,
    InstallMspAppInstructionComponent,
    ForceMSP2FaModalComponent,
    TfaTimerComponent,
    IncorrectTimeAlertComponent
  ],
  providers: [TFAService]
})
export class TFASharedModule {}
