import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreTypes } from '@shared/store/storeTypes.enum';
import { GroupTasksStore } from './model';

export const selectGroupTasksStore = createFeatureSelector<GroupTasksStore>(StoreTypes.GroupAction);

export const selectLoading = createSelector(selectGroupTasksStore, (state) => state.loading);
export const selectError = createSelector(selectGroupTasksStore, (state) => state.error);

export const selectLastGroupTasksResponse = createSelector(selectGroupTasksStore, (state) => state.groupTasksDataResponse);

export const selectGroupTasks = createSelector(selectGroupTasksStore, (state) => state.groupTasksDataResponse.data);
export const selectGroupTaskById = (guid: string) =>
  createSelector(selectGroupTasksStore, (state) => state.groupTasksDataResponse.data.find((groupTask) => groupTask.groupTaskGuid === guid));

export const selectGroupTasksTotalCount = createSelector(selectGroupTasksStore, (state) => state.groupTasksDataResponse.dataCount);
export const selectGroupTasksOnPageCount = createSelector(selectGroupTasksStore, (state) => state.groupTasksDataResponse.pageCount);
export const selectGroupTasksPageNumber = createSelector(selectGroupTasksStore, (state) => state.groupTasksDataResponse.pageNumber);
export const selectGroupTasksPageSize = createSelector(selectGroupTasksStore, (state) => state.groupTasksDataResponse.pageSize);

// Registered task selectors
export const selectRegisteredGroupTask = createSelector(selectGroupTasksStore, (state) => state.groupTasksDataResponse.registeredTask);
export const selectComputersFromNew = createSelector(selectGroupTasksStore, (state) =>
  state.groupTasksDataResponse.registeredTask ? state.groupTasksDataResponse.registeredTask.computers : []
);
export const selectExpectedComputersFromNew = createSelector(selectGroupTasksStore, (state) =>
  state.groupTasksDataResponse.registeredTask ? state.groupTasksDataResponse.registeredTask?.expected : []
);
export const selectTasksFromNew = createSelector(selectGroupTasksStore, (state) =>
  state.groupTasksDataResponse.registeredTask ? state.groupTasksDataResponse.registeredTask.tasks : []
);

export const selectGroupTaskToEdit = createSelector(selectGroupTasksStore, (state) =>
  state.groupTasksDataResponse?.taskGuidToEdit
    ? state.groupTasksDataResponse.data.find((groupTask) => groupTask.groupTaskGuid === state.groupTasksDataResponse.taskGuidToEdit)
    : null
);

export const selectGroupTaskToEditGuid = createSelector(
  selectGroupTasksStore,
  (state) => state.groupTasksDataResponse?.taskGuidToEdit ?? null
);

export const selectNameFromGroupTaskToEdit = createSelector(selectGroupTasksStore, selectGroupTaskToEdit, (state, data) => data.name);
export const selectTypeFromGroupTaskToEdit = createSelector(selectGroupTasksStore, selectGroupTaskToEdit, (state, data) => data.type);
export const selectApplyToFromGroupTaskToEdit = createSelector(selectGroupTasksStore, selectGroupTaskToEdit, (state, data) =>
  !data
    ? null
    : {
        allCompanies: data?.allComputers,
        companies: data?.companies,
        computers: data?.computers,
        computerTags: data?.computerTags
      }
);
export const selectScheduleFromGroupTaskToEdit = createSelector(selectGroupTasksStore, selectGroupTaskToEdit, (state, data) =>
  !data
    ? null
    : {
        runType: data?.runType,
        scheduleType: data?.scheduleType,
        scheduleData: data?.scheduleData
      }
);
export const selectParametersFromGroupTaskToEdit = createSelector(
  selectGroupTasksStore,
  selectGroupTaskToEdit,
  (state, data) => data?.parameters
);
export const selectScriptGuidFromGroupTaskToEdit = createSelector(
  selectGroupTasksStore,
  selectGroupTaskToEdit,
  (state, data) => data?.scriptGuid
);
