import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TFAService } from '@components/tfa/services/tfa.service';
import { ProviderService } from '@services/provider.service';
import { ModalComponent, ModalService } from 'mbs-ui-kit';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'mbs-disable-password-recovery-modal-component',
  templateUrl: './disable-password-recovery-modal.component.html'
})
export class DisablePasswordRecoveryModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  public confirmControl = new FormControl(false);

  constructor(private modalService: ModalService, private providerService: ProviderService, private TFAService: TFAService) {}

  close = (): void => {
    this.baseModal.save();
  };
  dismiss = (): void => {
    this.baseModal.close();
  };

  handleDisable(): void {
    this.providerService
      .setPasswordRecoveryState(false)
      .pipe(catchError((error) => of(error)))
      .subscribe((result) => {
        if (result?.error?.authId) {
          this.showTFAModal(result.error);
        } else {
          this.baseModal.save();
        }
      });
  }

  showTFAModal(data) {
    this.TFAService.openApproveModal({ title: 'Disable Password Recovery', ...data }).then(() => {
      this.handleDisable();
    });
  }
}
