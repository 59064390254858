import * as KeyCode from 'keycode-js';
import * as ShellInputActions from './actions';

import { KeyAction, KeyActionOptions, KeyActions, PayloadWrite } from './model';

import { UNSUPPORTED } from '../const';

/**
 * create ngrx like action
 * @template T = any
 * @param {string} action
 * @param {*} [event=null]
 * @param {*} [payload=null]
 * @return {KeyAction<T>}
 */
export const createKeyAction = <T = any>(action: string, event = null, payload = null): KeyAction<T> => {
  return ShellInputActions[action]({ event, payload });
};

/*
 * detect F1-F12 char code
 * @param code
 * @returns
 */
function isCodeFKey(code) {
  return /F\d+/.test(code) ? code : UNSUPPORTED;
}

/*
 * handle Enter key by modifications
 * @param mod
 * @param event
 * @param _payload
 * @returns
 */
function handleENTER(mod, event, _payload) {
  return mod ? createKeyAction(KeyActions.NewLine, event) : createKeyAction(KeyActions.Run, event);
}

/*
 * handle UP key by modifications (history of move)
 * @param mod
 * @param event
 * @param _payload
 * @returns
 */
function handleUP(mod, event, _payload) {
  return mod ? createKeyAction(KeyActions.Prev, event) : createKeyAction(KeyActions.Up, event, _payload);
}

/*
 * handle DOWN key by modificators (history of move)
 * @param mod
 * @param event
 * @param _payload
 * @returns
 */
function handleDOWN(mod, event, _payload) {
  return mod ? createKeyAction(KeyActions.Next, event) : createKeyAction(KeyActions.Down, event, _payload);
}

/*
 * handle C key by modificators (insert C or Copy)
 * @param mod
 * @param event
 * @param _payload
 * @returns
 */
function handleC(mod, event, _payload) {
  return mod ? createKeyAction(KeyActions.Break, event) : createKeyAction(KeyActions.Copy, event);
}

/*
 * handle other keys by modificator (inert or never)
 * @param mod
 * @param event
 * @param _payload
 * @returns
 */
function handlePrintable(mod, event, _payload) {
  return mod ? createKeyAction<PayloadWrite>(KeyActions.Insert, event, _payload) : null;
}

/*
 * handle clipboard event Paste
 * @param event
 * @returns
 */
export const pasteEventHandler = async event => {
  try {
    const write = await navigator.clipboard.readText();
    return createKeyAction(KeyActions.Paste, event, { write });
  } catch (e) {
    return createKeyAction(KeyActions.Error, event, {
      error: 'Access to clipboard is denied. Please allow it in browser to enable paste!'
    });
  }
};

/*
 * behavior by press key
 * @param event
 * @param payload
 * @param options
 * @returns
 */
export const keyEventHandler = async (event: KeyboardEvent, payload, options: KeyActionOptions = {}): Promise<KeyAction> => {
  if (event.type === 'keyup') {
    return null;
  }

  const printable = !event.altKey && !event.ctrlKey && !event.metaKey;

  switch (event.code) {
    case UNSUPPORTED:
    case isCodeFKey(event.code):
    case KeyCode.CODE_TAB:
    case KeyCode.CODE_PAGE_DOWN:
    case KeyCode.CODE_PAGE_UP: {
      return null;
    }
    case KeyCode.CODE_NUMPAD_ENTER:
    case KeyCode.CODE_ENTER:
      return handleENTER(event.shiftKey, event, payload);

    case KeyCode.CODE_LEFT:
      return createKeyAction(KeyActions.Left, event);

    case KeyCode.CODE_RIGHT:
      return createKeyAction(KeyActions.Right, event);

    case KeyCode.CODE_UP:
      return handleUP(options.history, event, payload);

    case KeyCode.CODE_DOWN:
      return handleDOWN(options.history, event, payload);

    case KeyCode.CODE_HOME:
      return createKeyAction(KeyActions.Home, event, payload);

    case KeyCode.CODE_END:
      return createKeyAction(KeyActions.End, event, payload);

    case KeyCode.CODE_BACK_SPACE:
      return createKeyAction(KeyActions.Backspace, event, payload);

    case KeyCode.CODE_DELETE:
      return createKeyAction(KeyActions.Delete, event, payload);

    case KeyCode.CODE_C: {
      if (event.ctrlKey || event.metaKey) {
        return handleC(options.execution, event, payload);
      }
      return createKeyAction(KeyActions.Insert, event, payload);
    }
    case KeyCode.CODE_V: {
      if (event.ctrlKey || event.metaKey) {
        return pasteEventHandler(event);
      }
      return createKeyAction(KeyActions.Insert, event, payload);
    }
    default:
      return handlePrintable(printable && !options.execution, event, payload);
  }
};
