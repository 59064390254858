import { ContentChildren, Directive, forwardRef, Inject, Injectable, Input, OnDestroy, Optional, QueryList } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, mapTo, startWith } from 'rxjs/operators';
import { FormsUtil } from '../utils/forms-util';
import { TabsetItemDirective } from './directives/tabset-item.directive';
import { TabsetDirective } from './directives/tabset.directive';
import { TabsetDirectiveToken, TabsetDirectiveType } from './tokens/tabset.directive.token';

export class TabsetItemTabComponentPair {
  tabSetItem: TabsetItemDirective;
  component: TabBase;

  constructor(init?: Partial<TabsetItemTabComponentPair>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  private errorsBus$ = new ReplaySubject<TabsetItemDirective>(1);
  private myTabSets = new Map<TabsetDirective, TabsetValidatedDirective>();
  private tabSetComponentPairs: TabsetItemTabComponentPair[] = [];

  get allTabsValid(): boolean {
    return this.tabSetComponentPairs.length == 0 || !this.tabSetComponentPairs.some(x => x.component.valid === false);
  }

  constructor() {}

  attachTabSet(tabSetValidated: TabsetValidatedDirective): void {
    this.myTabSets.set(tabSetValidated.tabSet, tabSetValidated);
  }

  deattachTabSet(tabset: TabsetDirective): void {
    this.myTabSets.delete(tabset);
    tabset.items.forEach((item: TabsetItemDirective) => {
      this.tabSetComponentPairs = this.tabSetComponentPairs.filter(t => t.tabSetItem !== item);
    });
  }

  attachComponent(component: TabBase, tabSetItem: TabsetItemDirective): void {
    if (this.myTabSets.has(tabSetItem.parentTabset)) {
      const customValidItem = (this.myTabSets.get(tabSetItem.parentTabset).validTabsetItems || []).find(
        it => it.tabSetItem == tabSetItem
      ) as TabsetItemValidDirective;
      if (customValidItem) {
        component.valid = customValidItem.valid;
      }
    }
    component.showErrors$ = this.errorsBus$.pipe(
      filter(item => item === tabSetItem),
      mapTo(true)
    );
    const pair = this.tabSetComponentPairs.find(t => t.tabSetItem == tabSetItem);
    if (pair) {
      pair.component = component;
    } else {
      this.tabSetComponentPairs.push(
        new TabsetItemTabComponentPair({
          tabSetItem,
          component
        })
      );
    }
  }

  deattachComponent(component: TabBase): void {
    const pairs = this.tabSetComponentPairs.find(t => t.component == component);
    if (pairs) {
      const newTab = {} as TabBase;
      newTab.valid = pairs.component.valid;
      pairs.component = newTab;
    }
  }

  openFirstError(): void {
    const firstFoundPair = this.tabSetComponentPairs.find(x => x.component.valid === false);
    if (firstFoundPair) {
      firstFoundPair.tabSetItem.activateSelf();
      this.errorsBus$.next(firstFoundPair.tabSetItem);
    }
  }

  getDataByTabId(tabId: string): any {
    return this.tabSetComponentPairs.find(x => x.tabSetItem.id == tabId).component.model;
  }
}

@Directive({
  selector: '[mbsTabsetItem][mbsTabSetValid]',
  exportAs: 'mbsTabSetValid'
})
export class TabsetItemValidDirective {
  @Input('mbsTabSetValid') valid = true;
  constructor(public tabSetItem: TabsetItemDirective) {}
}

@Directive({
  selector: '[mbsTabset][mbsTabSetValidated]',
  exportAs: 'mbsTabSetValidated'
})
export class TabsetValidatedDirective implements OnDestroy {
  private tabSetService: TabsService;
  @ContentChildren(TabsetItemValidDirective) validTabsetItems: QueryList<TabsetItemValidDirective>;

  constructor(
    @Inject(forwardRef(() => TabsService)) tabSetService: TabsService,
    @Optional() @Inject(TabsetDirectiveToken) public tabSet: TabsetDirectiveType
  ) {
    this.tabSetService = tabSetService;
    this.tabSetService.attachTabSet(this);
  }

  ngOnDestroy(): void {
    this.tabSetService.deattachTabSet(this.tabSet);
  }
}

type FormStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';
@Injectable()
export class TabBase implements OnDestroy {
  public model: any;
  public valid: boolean;

  private myLastStatus: FormStatus;
  public get lastStatus(): FormStatus {
    return this.myLastStatus;
  }

  protected form: FormGroup;

  private subscriptions: Subscription;
  public showErrors$: Observable<boolean>;
  private tabSetService: TabsService;

  constructor(@Inject(forwardRef(() => TabsService)) tabsService: TabsService, parentTab: TabsetItemDirective) {
    this.tabSetService = tabsService;
    this.tabSetService.attachComponent(this, parentTab);
  }

  afterFormInit(): void {
    if (this.form && !this.subscriptions) {
      this.subscriptions = new Subscription();
      this.subscriptions.add(
        this.showErrors$.subscribe({
          next: () => {
            FormsUtil.triggerValidation(this.form);
          }
        })
      );
      this.subscriptions.add(
        this.form.statusChanges.pipe(startWith(this.form.status)).subscribe((status: FormStatus) => {
          this.myLastStatus = status;
          this.valid = status == 'VALID';
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    this.tabSetService.deattachComponent(this);
    this.subscriptions && this.subscriptions.unsubscribe();
    this.subscriptions = undefined;
  }
}

/**
 * The directive will remove this tabset from list of tabsets
 * to prevent validation after destroy.
 */
@Directive({
  selector: '[mbsTabset]',
  exportAs: 'mbsTabsetDestroyer'
})
export class TabsetDestroyerDirective implements OnDestroy {
  constructor(@Optional() @Inject(TabsetDirectiveToken) private tabset: TabsetDirectiveType, private tabsetService: TabsService) {}

  ngOnDestroy(): void {
    this.tabsetService.deattachTabSet(this.tabset);
  }
}
