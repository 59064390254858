import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TFARequireModalComponent } from '@components/tfa/components/tfa-require-modal/tfa-require-modal.component';
import { PasswordRecoveryModalComponent } from '@modules/password-recovery/modals/password-recovery-modal/password-recovery-modal.component';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { noop } from 'lodash';
import { MbsSize, ModalService, ModalSettings } from 'mbs-ui-kit';

type ResultPoint = { path: string; restorePoint: string };

@Component({
  selector: 'app-password-recovery-handler',
  templateUrl: './password-recovery-handler.component.html'
})
export class PasswordRecoveryHandlerComponent {
  readonly FIRST_BACKUP_AGENT_SUPPORTED_PASSWORD_RECOVERY = 770;

  @Input() resultPoint: ResultPoint;
  @Input() hid: string;
  @Input() passwordControl: AbstractControl;
  @Input() backupVersionUpdated: string;

  @ViewChild('versionUnsupportedModal', { static: true, read: TemplateRef }) versionUnsupportedModal: TemplateRef<any>;

  constructor(private modalService: ModalService, public i18nPipe: I18NextPipe, private ability: AbilityService) {}

  handleRestorePassword(): void {
    if (this.ability.cannot('read', '2FAEnabled')) {
      this.modalService
        .openCustom(TFARequireModalComponent, {
          size: MbsSize.sm,
          data: {
            actionName: 'Password Recovery'
          }
        })
        .finally(noop);
      return;
    }
    if (Number(this.backupVersionUpdated.slice(0, 3)) < this.FIRST_BACKUP_AGENT_SUPPORTED_PASSWORD_RECOVERY) {
      const modalSettings: ModalSettings = {
        header: { title: this.i18nPipe.transform('pass-recovery:unsupported_version', { format: 'title' }) },
        footer: { okButton: { show: false }, cancelButton: { text: this.i18nPipe.transform('buttons:close', { format: 'title' }) } }
      };
      this.modalService.open(modalSettings, this.versionUnsupportedModal).finally(noop);

      return;
    }

    this.modalService
      .openRef(PasswordRecoveryModalComponent, {
        data: {
          path: this.getResultPointPath(this.resultPoint),
          hid: this.hid
        }
      })
      .result.then((pass) => {
        this.passwordControl.setValue(pass);
      })
      .catch(noop);
  }

  private getResultPointPath(resultPoint: ResultPoint): string {
    if (!resultPoint?.path) return '';

    const splitted = resultPoint.path.split('\\');
    const sliceIndex = splitted.indexOf(resultPoint.restorePoint);

    return splitted.slice(0, sliceIndex - splitted.length).join('\\');
  }
}
