import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidepanelRmmComponent } from '@components/sidepanel-rmm/sidepanel-rmm.component';
import { SidepanelRouteType } from '@models/backup/sidepanel-route-type';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComputersAbstractService } from '@services/computers.service';
import { AbilityService } from 'ability';
import { SidepanelService } from 'mbs-ui-kit';

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-computers-wrapper-component',
  template: ''
})
export class SidepanelComputersWrapperComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sidepanelService: SidepanelService,
    private computersService: ComputersAbstractService,
    private ability: AbilityService
  ) {}

  private redirectToComputers(): void {
    const queryParams = {};
    if (this.route.snapshot.queryParams['IFRAME']) {
      queryParams['IFRAME'] = this.route.snapshot.queryParams['IFRAME'];
    }
    this.router.navigate(['../'], { relativeTo: this.route, queryParams });
  }

  ngOnInit(): void {
    const computerHid = this.route.snapshot.params.hid;
    const sidepanelType: SidepanelRouteType = (this.route.snapshot.queryParams.sidepanel || '').toLowerCase();
    const activeTab = this.route.snapshot.queryParams.activeTab;

    if (computerHid && sidepanelType === SidepanelRouteType.RMM) {
      if (this.ability.cannot('read', PermissionsEnum.Rmm)) {
        this.redirectToComputers();
        return;
      }
      const rmmSidepanel = this.sidepanelService.add(SidepanelRmmComponent);
      const computer$ = this.computersService.getComputerByHid(computerHid);
      computer$.pipe(untilDestroyed(this)).subscribe({
        next: (comp) => {
          if (comp === null || comp === undefined) {
            this.redirectToComputers();
          }
          this.sidepanelService.toggle('computer_rmm_info', comp);
          this.sidepanelService.toggleLoadingDataByType(SidepanelRmmComponent);
          rmmSidepanel.data$.next(comp);
          const isTabExist = rmmSidepanel.tabset.items.toArray().some((tab) => tab.id === activeTab);
          if (activeTab && isTabExist) {
            rmmSidepanel.tabset.select(activeTab);
          } else {
            rmmSidepanel.tabset.select('general');
          }
        },
        error: () => {
          this.redirectToComputers();
        }
      });
    }
  }
}
