import * as ansiStyle from 'ansi-colors';

import {COLOR_WHITE, COLOR_YELLOW, NEW_LINE} from '../const';
import { TerminalChunk, TerminalDataType } from '../store/terminal.model';

import { PromptBuffer } from './prompt-buffer';
import {ShellHistory} from './history';
import { count } from 'rxjs/operators';
import { isEmpty } from 'lodash/fp';

/**
 * Color Red Bg for line
 * @export
 * @param {string} source
 * @param {PromptBuffer} buffer
 * @param {boolean} tail // is tail of chank flag
 * @return {*}
 */
export function colorError(source: string, buffer?: PromptBuffer) {
  const headline = (buffer ? buffer.config.errorLine : '')
  return ansiStyle.bgRed(COLOR_WHITE + headline + source);
}
/**
 * Color Yellow Bg for line
 * @export
 * @param {string} source
 * @param {PromptBuffer} buffer
 * @param {boolean} tail // is tail of chank flag
 * @return {*}
 */
export function colorWarn(source: string, buffer?: PromptBuffer) {
  const headline = (buffer ? buffer.config.warnLine : '')
  return ansiStyle.bgYellow(COLOR_WHITE + headline + source);
}
/**
 * Color prompt by PS rules
 * @export
 * @param {string[]} data
 * @param {boolean} has
 * @return {string}
 */
export function colorPowerShellWrite(data: string[], has: boolean) {
  return data.map((line: string, index) => {
    const startColor = has && index === 0 ? COLOR_WHITE : COLOR_YELLOW;
    const parsedArray: string[] = line.length > 1 ? line.replace(/\s+/, '\x01').split('\x01') : [line];
    return startColor + parsedArray.join(' ' + COLOR_WHITE) + COLOR_WHITE;
  })
}
export function restoreHistory(cash: TerminalChunk[], history: ShellHistory) {
  if (history) {
    cash.reverse().forEach((chunk) => {
      if (chunk.type === TerminalDataType.INPUT) {
        history.set(chunk.data);
      }
    })
  }
}

export function convertCacheToAnsi(cash: TerminalChunk[], buffer: PromptBuffer) {
  const slicer = new RegExp(`.{1,${buffer.config.cols}}`, 'g');

  const { lines } = cash.reduce((acc, chunk) => {
    switch (chunk.type) {
      case TerminalDataType.PROMPT: {
        acc.lastPrompt = chunk.data;
        break;
      }
      /** 
       * @TODO Create execution file from history
       * case TerminalDataType.FILE: {
       *   acc.lines.push(acc.lastPrompt + chunk.file);
       *   break;
       * }
       */
      case TerminalDataType.FILE:
      case TerminalDataType.INPUT: {
        const coloredInput = chunk.data
          .split(buffer.config.newLine)
          .map((line) => colorPowerShellWrite([line], false))
          .flat();

        const head = coloredInput[0]
        const tail = coloredInput.slice(1);

        acc.lines.push(acc.lastPrompt + head, ...tail);
        acc.lines.push(' ')
        break;
      }
      case TerminalDataType.ERROR: {
        const splitted = chunk.data.split(buffer.config.newLine).filter(Boolean)
          .map((line) => line.match(slicer))
          .flat()

        const head = colorError(splitted[0], buffer)
        const tail = splitted.splice(1).map((line) => colorError(line))

        acc.lines.push(head, ...tail);
        acc.lines.push(' ')
        break;
      }
      case TerminalDataType.WARN: {
        const splitted = chunk.data.split(buffer.config.newLine).filter(Boolean)
          .map((line) => line.match(slicer))
          .flat()

        const head = colorWarn(splitted[0], buffer)
        const tail = splitted.splice(1).map((line) => colorWarn(line))

        acc.lines.push(head, ...tail);
        acc.lines.push(' ')
        break;
      }
      default: {
        const splitted = chunk.data
          .split(buffer.config.newLine)
          .filter(Boolean)
          .map((line) => line.match(slicer))
          .flat()

        acc.lines.push(...splitted)
        acc.lines.push(' ')
      };
    }

    return acc;
  }, { lines: [], lastPrompt: null})

  return lines.length > 5000
    ? colorWarn('[...previous]', null) + NEW_LINE +
      lines
        .slice(-5000)
        .join(NEW_LINE) + NEW_LINE

    : lines.join(NEW_LINE) + NEW_LINE
}

export function sliceByCols(chunk: string[] | string, marker = '\r\n', cols?: number) {
  const preparedString = typeof chunk === 'string' ? chunk : chunk.join('');
  const slicer = new RegExp(`.{1,${cols}}`, 'g');

  return preparedString
    .split(marker)
    .map((line => {
      return cols 
       ? line.match(slicer)
       : [line]
    }))
    .flat();
}