import { Action } from '@ngrx/store';
import { PromptBuffer } from './prompt-buffer';
import { ShellLine } from './shell-line';

export enum KeyActions {
  Backspace = 'Backspace',
  Break = 'Break',
  Copy = 'Copy',
  Delete = 'Delete',
  Down = 'Down',
  End = 'End',
  Error = 'Error',
  ErrorOutput = 'ErrorOutput',
  Home = 'Home',
  Insert = 'Insert',
  Left = 'Left',
  LastCMD = 'LastCMD',
  NewLine = 'NewLine',
  Next = 'Next',
  Paste = 'Paste',
  Prev = 'Prev',
  Prompt = 'Prompt',
  Right = 'Right',
  Run = 'Run',
  OutsideRun = 'OutsideRun',
  Output = 'Output',
  UnSupport = 'UnSupport',
  Up = 'Up'
}

export enum LineTypes {
  Prompt = 'Prompt',
  New = 'New',
  Regular = 'Regular',
  Blank = 'Blank',
  Error = 'Error'
}

export interface PromptBufferConfig {
  cols: number;
  rows: number;
  viewportY?: number;
  prompt?: string;
  promptNewLine?: string;
  newLine?: string;
  errorLine?: string;
  warnLine?: string;
  type?: LineTypes;
  history?: boolean;
}

export interface PayloadState {
  state?: boolean;
}

export interface PayloadWrite {
  write?: string;
}

export interface PayloadAny {
  [key: string]: any;
}

export type Payload = PayloadAny & PayloadState & PayloadWrite;

export interface KeyActionOptions {
  history?: boolean;
  execution?: boolean;
}

export interface KeyAction<P = Payload> extends Action {
  type: Action['type'];
  event?: KeyboardEvent;
  payload?: P;
  history?: string;
  error?: string;
}

export type ShellCommandOperator = (action?: KeyAction) => (cash: PromptBuffer) => string;
export type ShellLineFactory = (source?: string, y?: number) => ShellLine;
export type CursorPosition = { cursorX: number; cursorY: number; jump?: boolean };

export enum TerminalState {
  PROCESSING = "PROCESSING",
  DISCONNECTED = "DISCONNECTED",
  READY = "READY",
  LOCK = "LOCK",
}
