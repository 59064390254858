import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { I18NEXT_NAMESPACE } from 'angular-i18next';
import { ScriptLibraryManageComponent } from './components/script-library-manage/script-library-manage.component';

export const routes: Routes = [
  {
    path: '',
    component: ScriptLibraryManageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: I18NEXT_NAMESPACE,
      useValue: ['rmm-script-library.module']
    }
  ]
})
export class ScriptLibraryRoutingModule {}
