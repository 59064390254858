import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComputersStatisticHealthStateModel, ComputersStatisticModel, ComputersStatisticStateModel } from '@models/computersStatistic';
import { Observable } from 'rxjs';

export abstract class ComputersStatisticAbstractService {
  abstract getComputersStatistic(): Observable<ComputersStatisticModel>;
  abstract getComputersStatisticState(): Observable<ComputersStatisticStateModel>;
  abstract getComputersStatisticHealthState(): Observable<ComputersStatisticHealthStateModel>;
}

@Injectable()
export class ComputersStatisticService implements ComputersStatisticAbstractService {
  constructor(private http: HttpClient) {}

  getComputersStatistic(): Observable<ComputersStatisticModel> {
    return this.http.get<ComputersStatisticModel>(`api/computers/statistic`);
  }

  getComputersStatisticState(): Observable<ComputersStatisticStateModel> {
    return this.http.get<ComputersStatisticStateModel>(`api/computers/statistic/state`);
  }

  getComputersStatisticHealthState(): Observable<ComputersStatisticHealthStateModel> {
    return this.http.get<ComputersStatisticHealthStateModel>(`api/computers/statistic/health-state`);
  }
}
