import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, on } from '@ngrx/store';
import { InMemorySorter } from '@utils/inMemorySorter';
import { SortEvent } from 'mbs-ui-kit';
import * as EventEntryActions from './event-entry.actions';
import * as EventTotalActions from './event-total.actions';
import { EventEntry } from './model';
import { createImmerReducer } from 'ngrx-immer/store';

/* util */
const sortEventTable = (data: EventEntry[], sortEvents: SortEvent[]): EventEntry[] => {
  if (sortEvents.length < 1) return data;

  return InMemorySorter.multipleSort<EventEntry>(sortEvents, data).map((entry, index) => ({ ...entry, order: index }));
};

/* reducer begin */
export const eventEntriesFeatureKey = 'eventEntries';

export type State = EntityState<EventEntry> & {
  selectedId: EventEntry['id'];
  open: boolean;
  loading: boolean;
};

export const adapter: EntityAdapter<EventEntry> = createEntityAdapter<EventEntry>();

export const initialState: State = adapter.getInitialState({
  open: false,
  selectedId: null,
  loading: false
});

const reducer = createImmerReducer(
  initialState,
  on(EventTotalActions.loadError, state => Object.assign({}, state, { loading: false })),
  on(EventEntryActions.showDetailEntry, (state, { id }) => Object.assign({}, state, { selectedId: id, open: true })),
  on(EventEntryActions.hideDetailEntry, state => Object.assign({}, state, { selectedId: null, open: false })),
  on(EventEntryActions.sortEntry, (state, { sort }) => {
    const entries = Object.values(state.entities);
    const sorted = sortEventTable(entries, sort);
    const cleared = adapter.removeAll(state);
    return adapter.setAll(sorted, cleared);
  }),
  on(EventEntryActions.loadEventEntries, state => Object.assign({}, state, { loading: true })),
  on(EventEntryActions.addEventEntry, (state, action) => adapter.addOne(action.eventEntry, state)),
  on(EventEntryActions.addAllEventEntry, (state, action) => {
    const ordered = action.eventEntries.map((entry, index) => ({ ...entry, order: index }));
    const newState = adapter.setAll(ordered, state);
    return Object.assign(newState, { loading: false });
  }),
  on(EventEntryActions.clearEventEntries, state => adapter.removeAll(state))
);

export function eventEntryReducer(state: State, action: Action) {
  return reducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedId;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
