import { createAction, props } from '@ngrx/store';
import { GroupTask, GroupTaskFiltersPayload, GroupTaskReg, GroupTasksDataResponse } from './model';
import { StoreTypes } from '@shared/store/storeTypes.enum';

export const loadGroupTasks = createAction(`[${StoreTypes.GroupAction}] Load Group Tasks`, props<{ params?: GroupTaskFiltersPayload }>());

export const loadGroupTasksSuccess = createAction(`[${StoreTypes.GroupAction}] Load Group Tasks Success`, props<{ data: GroupTasksDataResponse }>());
export const loadGroupTasksError = createAction(`[${StoreTypes.GroupAction}] Load Group Tasks Error`, props<{ error: string }>());

export const loadGroupTask = createAction(`[${StoreTypes.GroupAction}] Load Group Task`, props<{ guid: string }>());
export const refreshGroupTask = createAction(`[${StoreTypes.GroupAction}] Refresh Group Task`, props<{ guid: string }>());

export const clearGroupTasks = createAction(`[${StoreTypes.GroupAction}] Clear Group Tasks`);

export const registerGroupTask = createAction(`[${StoreTypes.GroupAction}] Register Group Task`, props<{ task: GroupTaskReg }>());
export const saveChangesToGroupTask = createAction(
  `[${StoreTypes.GroupAction}] Save Changes To Group Task`,
  props<{ groupTaskGuid: string; task: GroupTaskReg }>()
);
export const forceRunGroupTask = createAction(`[${StoreTypes.GroupAction}] Force Run Group Task`, props<{ task: GroupTask }>());
export const forceRunGroupTaskForPC = createAction(
  `[${StoreTypes.GroupAction}] Force Run Group Task For Computer`,
  props<{ hid: string; groupTaskGuid: string }>()
);

export const changeGroupTaskState = createAction(
  `[${StoreTypes.GroupAction}] Change Group Task State`,
  props<{ groupTaskGuid: string; state: boolean }>()
);
export const updateGroupTask = createAction(`[${StoreTypes.GroupAction}] Update Group Task`, props<{ groupTask: GroupTask }>());

export const updateGroupTaskState = createAction(
  `[${StoreTypes.GroupAction}] Update Group Task State`,
  props<{ groupTaskGuid: string; state: boolean }>()
);

export const deleteGroupTask = createAction(
  `[${StoreTypes.GroupAction}] Delete Group Task`,
  props<{ guid: string; params?: GroupTaskFiltersPayload }>()
);

export const setGroupTaskGuidToEdit = createAction(`[${StoreTypes.GroupAction}] Set Group Task Guid For Update`, props<{ groupTaskGuid: string }>());
export const clearGroupTaskGuidToEdit = createAction(`[${StoreTypes.GroupAction}] Clear Group Task Guid For Update`);
export const clearRegisteredGroupTaskData = createAction(`[${StoreTypes.GroupAction}] Clear Registered Group Task Data`);
