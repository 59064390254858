<div class="row justify-content-between my-2">
  <div class="col-auto">
    <h6 class="mb-0">
      ({{hddVolumeInfo.letterName}}) {{hddVolumeInfo.volumeLabel}}
      <span class="text-gray-500 pl-2">
        {{hddVolumeInfo.fileSystem}}
      </span>
    </h6>
  </div>
  <div class="col-auto text-xs">
    <ng-container *ngIf="hddVolumeInfo.freeSpace === -1; else standardInfo">
      {{ hddVolumeInfo.totalSpace | size }}
    </ng-container>
    <ng-template #standardInfo>
      {{ hddVolumeInfo.freeSpace | size }} free of {{ hddVolumeInfo.totalSpace | size }}
    </ng-template>
  </div>
</div>

<div class="my-2">
  <ng-container *ngIf="hddVolumeInfo.freeSpace === -1; else standardProgressBar">
    <mbs-progress-bar [type]="usageStatus"
                      [value]="0"></mbs-progress-bar>
  </ng-container>
  <ng-template #standardProgressBar>
    <mbs-progress-bar [type]="usageStatus"
                      [value]="usageValue"></mbs-progress-bar>
  </ng-template>
</div>
