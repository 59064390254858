import { Directive, EventEmitter, Output } from '@angular/core';
import { MbsSize, ModalService, ModalSettings } from 'mbs-ui-kit';

import { ModalTerminalComponent } from '../components/modal-terminal.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[mbsModalTerminal]',
  exportAs: 'mbsModalTerminal',
})
export class MbsModalTerminalDirective {
  terminalModalRef: NgbModalRef;

  @Output() modalState = new EventEmitter();

  constructor(private modalService: ModalService) {

  }

  handleShowModal(): void {
    const modalSettings: ModalSettings = {
      size: MbsSize.xl,
      header: { title: 'Remote PowerShell terminal', icon: 'ico-hyperv', showExpandedCross: true },
      footer: { show: false },
      collapsing: true
    };

    this.modalState.emit(true);
    const terminalModalRef = this.modalService.openRef(ModalTerminalComponent, modalSettings);
    terminalModalRef.result.finally(() => {
      this.modalState.emit(false);
    });

    this.terminalModalRef = terminalModalRef;
  }
}
