export enum OnboardingView {
  Computers = 'computers',
  HyperV = 'hyper-v',
  Apps = 'apps'
}

export enum FinishPath {
  Computers = '/AP/Computers',
  Domains = '/AP/Apps/Domains'
}
