import { Component, Inject, Input, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import Computer, { AgentType, OsType } from '@models/Computer';
import { PowerShellOutType } from '@models/rmm/PowerShellResultModel';
import RmmCommand from '@models/rmm/RmmCommand';
import { RmmCommandType } from '@models/rmm/RmmCommandType';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import * as ScriptLibraryActions from '@modules/script-library/store/script-library.actions';
import { ScriptLibraryEntry, ScriptLibraryEntryTypes, ScriptLibraryTag } from '@modules/script-library/store/script-library.model';
import * as ScriptLibrarySelectors from '@modules/script-library/store/script-library.selectors';
import * as SummaryComputerSelectors from '@modules/summary-computers/store/summary-computer.selectors';
import { TerminalComponent } from '@modules/terminal-emulator/components/terminal.component';
import { TerminalActions, TerminalSelectors } from '@modules/terminal-emulator/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { TFARMMPermissionHelperService } from '@services';
import { ComputersFacade } from '@shared/facades/computers.facade';
import { CsvTemplate, generateCsvTable } from '@utils/cvs';
import { I18NextPipe } from 'angular-i18next';
import { toByteArray } from 'base64-js';
import saveAs from 'file-saver';
import { isEmpty, noop } from 'lodash';
import { MbsPopupType, MbsSize, ModalService, ModalSettings, TabsetItemDirective } from 'mbs-ui-kit';
import moment from 'moment';
import { NgTerminal } from 'ng-terminal';
import { BehaviorSubject, combineLatest, filter, map, Observable, startWith, switchMap, take, tap, throwError } from 'rxjs';

export enum ModeSelectTypes {
  fromScriptFile,
  fromScriptLibrary,
  fromPowerShell
}

export enum ScriptType {
  Bash = 'Bash',
  Powershell = 'PowerShell'
}

@UntilDestroy()
@Component({
  selector: 'mbs-powershell-terminal-tab',
  templateUrl: './powershell-terminal-tab.component.html',
  styleUrls: ['./powershell-terminal-tab.component.scss']
})
export class PowershellTerminalTabComponent implements OnInit {
  @ViewChild(TerminalComponent, { static: true }) terminal: TerminalComponent;
  @ViewChild('scriptLibraryBodyModal', { static: true, read: TemplateRef }) scriptLibraryBodyModal: TemplateRef<any>;
  @ViewChild('scriptResult', { static: true, read: TemplateRef }) scriptResult: TemplateRef<any>;

  @Input() hid: string;
  openInModalDisabled = true;

  processing$: Observable<boolean>;
  warning = '';
  scriptBody = '';
  scriptName = '';

  public modeSelectType = ModeSelectTypes;
  public executeScriptFormType: UntypedFormGroup = new UntypedFormGroup({
    modeSelect: new FormControl(this.modeSelectType.fromScriptLibrary)
  });

  public scriptLibraryForm: UntypedFormGroup = new UntypedFormGroup({
    collection: new FormControl(null, Validators.required),
    entry: new FormControl(null, Validators.required)
  });

  public newTerminalForm: UntypedFormGroup = new UntypedFormGroup({
    terminalValue: new FormControl('')
  });

  public collections: ScriptLibraryTag[];
  private entries: ScriptLibraryEntry[];
  public filteredEntries: ScriptLibraryEntry[];

  private latestInfoMessage$ = null;
  private responseAsyncId = null;
  public entry: ScriptLibraryEntry;
  public currentScriptFromLibrary: string;
  public scriptFromFileName = '';
  public scriptFromFile = null;
  public lastCommandResult = '';
  public isLoading = false;
  public lastCommandType: MbsPopupType = null;
  public canViewScript = false;
  public canExecute = false;
  public isWindows = true;
  public isInteractive = false;
  public notInteractiveModeVersion = 160;
  public newTerminalAgentVersion = 200;
  private commandTypeName: RmmCommandType = 'PowerShellTerminalCmd';

  private scriptExecutionDate: string;
  private ngTerminal: NgTerminal;
  public fileExtension: '' | 'ps1' = '';
  public shouldUseNewTerminalVersion = true;
  public shouldInitTerminal = true;
  public shouldFocusTerminal = false;
  public powerShellTabContainer = 'mbs-sidepanel-rmm-info .mbs-form_content';

  constructor(
    private store: Store,
    private modalService: ModalService,
    private commandService: CommandService,
    private computersFacade: ComputersFacade,
    private tabItem: TabsetItemDirective,
    private i18n: I18NextPipe,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService,
    @Optional() @Inject('IS_MODAL_CONTENT_OPEN') private IS_MODAL_CONTENT_OPEN: BehaviorSubject<boolean>
  ) {
    // Should be true after MBS-20827 completion
    this.tabItem.loading = false;

    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (computer && computer.os) this.isWindows = computer.os === OsType.windows;

      this.fileExtension = this.isWindows ? 'ps1' : '';
      this.commandTypeName = this.isWindows ? 'PowerShellTerminalCmd' : 'BashCmd';

      this.shouldUseNewTerminalVersion = Computer.IsSupportedAgentVersion(computer, AgentType.RMM, this.newTerminalAgentVersion);
      this.isInteractive = !Computer.IsSupportedAgentVersion(computer, AgentType.RMM, this.notInteractiveModeVersion);
    });

    this.latestInfoMessage$ = this.commandService.messages$.pipe(
      filter((response) => !!response && (this.responseAsyncId ? response.MessageId === this.responseAsyncId : true)),
      map((response: any) => {
        // agent version < 1.6
        if (typeof response === 'object') return JSON.parse(response?.Data);
        // agent version >= 1.6
        return response?.Data && typeof response?.Data === 'string' && JSON.parse(response?.Data);
      })
    );
  }

  ngOnInit(): void {
    this.store.dispatch(ScriptLibraryActions.loadScriptsInLibrary());
    this.processing$ = this.store.select(TerminalSelectors.selectProcessing(this.hid));

    this.scriptLibraryForm
      .get('collection')
      .valueChanges.pipe(
        filter((value) => !!value),
        untilDestroyed(this)
      )
      .subscribe((collection) => {
        this.filteredEntries = this.getEntriesPerCollection(this.entries, collection);
        if (this.filteredEntries?.length) this.scriptLibraryForm.get('entry').setValue(this.filteredEntries[0]);
      });

    const modeChanges$ = this.executeScriptFormType.get('modeSelect').valueChanges.pipe(startWith(this.modeSelectType.fromScriptLibrary));
    const scriptFromLibrary$ = this.scriptLibraryForm.get('entry').valueChanges.pipe(
      tap((script: ScriptLibraryEntry) => {
        this.entry = script;
        this.store.dispatch(ScriptLibraryActions.loadScriptBody({ scriptGuid: script.scriptGuid }));
      })
    );

    combineLatest([modeChanges$, scriptFromLibrary$])
      .pipe(untilDestroyed(this))
      .subscribe(([mode, scriptFromLibrary]) => {
        switch (mode) {
          case this.modeSelectType.fromScriptLibrary:
            this.canViewScript = scriptFromLibrary?.accessLevel !== 'Common';
            this.canExecute = !!this.entry;
            this.shouldInitTerminal = false;
            break;
          case this.modeSelectType.fromScriptFile:
            this.scriptFromFile = null;
            this.canViewScript = !!this.scriptFromFile;
            this.canExecute = !!this.scriptFromFile;
            this.shouldInitTerminal = false;
            break;
          default:
            this.canViewScript = false;
            this.canExecute = false;
            this.shouldInitTerminal = true;
            break;
        }
      });

    this.setupScriptLibraryData();
  }

  private setupScriptLibraryData(): void {
    const entries$ = this.store.select(ScriptLibrarySelectors.selectAll).pipe(
      filter((entries) => !!entries?.length),
      take(1),
      map((entries) => entries.filter((entry) => this.isEntryFitsConditions(entry))),
      tap((entries) => {
        this.entries = [...entries];
        const collections = [];
        this.entries.forEach((entry) =>
          entry.tags.forEach((tag) => {
            if (collections.find((tagEntity) => tagEntity.name === tag.name && tagEntity.accessLevel === tag.accessLevel)) return;
            collections.push(tag);
          })
        );

        this.collections = collections;
        const formCollection = this.scriptLibraryForm.get('collection');
        const defaultCollectionName = this.collections?.find((collection) => collection.name === 'General');

        formCollection.setValue(defaultCollectionName ? defaultCollectionName : this.collections[0]);
        this.tabItem.loading = false;
      })
    );

    entries$.pipe(untilDestroyed(this)).subscribe();
  }

  handleInitialized(ngTerminal: NgTerminal): void {
    this.ngTerminal = ngTerminal;
  }

  executeScriptHandler(e): void {
    if (isEmpty(this.terminal.value)) {
      this.store.dispatch(TerminalActions.send({ hid: this.hid, prompt: this.scriptBody, emuId: getGuid(), file: this.scriptName }));
    } else {
      this.terminal.execute();
    }
  }

  errorCommandHandler(e) {
    //
  }

  handleWarningText(text: string): void {
    this.warning = text;
  }

  ngOnDestroy(): void {
    //
  }

  private getEntriesPerCollection(entries: ScriptLibraryEntry[], collection: ScriptLibraryTag): ScriptLibraryEntry[] {
    if (!entries.length || !collection) return [];
    const filteredEntries = [];

    entries.forEach((entry) => {
      if (entry.tags.find((entryTag) => entryTag.name === collection.name && entryTag.accessLevel === collection.accessLevel))
        filteredEntries.push(entry);
    });
    return filteredEntries;
  }

  private isEntryFitsConditions(entry: ScriptLibraryEntry): boolean {
    if (this.isWindows) {
      return entry.type === ScriptLibraryEntryTypes.PowerShell || entry.type === ScriptType.Powershell;
    } else {
      return entry.type === ScriptLibraryEntryTypes.Bash || entry.type === ScriptType.Bash;
    }
  }

  public viewScript() {
    this.executeScriptFormType.get('modeSelect').value === this.modeSelectType.fromScriptLibrary
      ? this.viewScriptFromLibrary()
      : this.viewScriptFromFile();
  }

  public viewScriptFromLibrary() {
    const scriptEntry = this.scriptLibraryForm.get('entry').value;
    this.currentScriptFromLibrary = '';
    this.store
      .select(ScriptLibrarySelectors.selectEntryBody(scriptEntry.scriptGuid))
      .pipe(take(1))
      .subscribe((body) => (this.currentScriptFromLibrary = body));
    this.modalService.open(this.getModalSettings(scriptEntry.name, true), this.scriptLibraryBodyModal).finally(noop);
  }

  private viewScriptFromFile() {
    this.modalService.open(this.getModalSettings(this.scriptFromFileName, true), this.scriptLibraryBodyModal).finally(noop);
  }

  executeScript() {
    this.isLoading = true;
    this.canExecute = false;

    // using setTimeout to disable multiclick. 2FA can't be reached using pipe in the commandService stream
    setTimeout(() => {
      this.canExecute = true;
    }, 1000);

    (this.executeScriptFormType.get('modeSelect').value === this.modeSelectType.fromScriptLibrary
      ? this.executeScriptFromLibrary()
      : this.executeScriptFromLoadedFile()
    ).subscribe({
      next: (response: any) => this.setupLastResult(response),
      error: () => noop
    });
  }

  private executeScriptFromLibrary() {
    const scriptEntry = this.scriptLibraryForm.get('entry').value;

    return combineLatest([
      this.store.select(ScriptLibrarySelectors.selectEntryBody(scriptEntry.scriptGuid)),
      this.requestPermissionsStream()
    ]).pipe(
      filter(([body, confirmed]) => !!body && confirmed),
      switchMap(([scriptBody, confirmed]) => {
        if (!confirmed) throwError(() => null);
        return this.commandService.sendCommandAsync(this.commandTypeName, this.getCommand(scriptBody), this.hid, true);
      }),
      tap((value) => this.showResult(scriptEntry.name)),
      switchMap((value) => {
        this.responseAsyncId = this.commandService.getAsyncIdFromResponse(value);
        return this.latestInfoMessage$;
      })
    );
  }

  private executeScriptFromLoadedFile() {
    return this.requestPermissionsStream().pipe(
      filter((confirmed) => confirmed),
      switchMap((confirmed) =>
        this.commandService.sendCommandAsync(this.commandTypeName, this.getCommand(this.scriptFromFile), this.hid, true)
      ),
      tap((value) => this.showResult(this.scriptFromFileName)),
      switchMap((value) => {
        this.responseAsyncId = this.commandService.getAsyncIdFromResponse(value);
        return this.latestInfoMessage$;
      })
    );
  }

  private setupLastResult(response: any) {
    const responseObj = JSON.parse(response);
    // rmm version >=16
    const meetsNotInteractiveCondition =
      !this.isInteractive && (responseObj?.outType === PowerShellOutType.End || responseObj?.outType === PowerShellOutType.Error);
    // rmm version < 16
    const meetsInteractiveCondition = responseObj?.outType === PowerShellOutType.Succeed && this.isInteractive;

    if (meetsInteractiveCondition || meetsNotInteractiveCondition) {
      this.lastCommandType = responseObj?.outType === PowerShellOutType.End ? MbsPopupType.success : MbsPopupType.warning;
      this.lastCommandResult = responseObj?.data;
      this.scriptExecutionDate = moment().format('DD/MM/YYYY h:mm:ss a');
      this.isLoading = false;
    }
  }

  showResult(title = 'Result') {
    this.modalService.open(this.getModalSettings(title), this.scriptResult).finally(noop);
  }

  fileLoadHandler(e): void {
    let base64 = e.base64;
    const index = base64.indexOf(',');
    base64 = base64.slice(index + 1);
    const byteArray = toByteArray(base64);
    const script = new TextDecoder().decode(byteArray);

    if (script) {
      this.scriptFromFileName = e.file.name;
      this.scriptFromFile = script;
      this.canViewScript = true;
      this.canExecute = true;
    }
  }

  private getModalSettings(title: string, showFooter = false): ModalSettings {
    return {
      header: {
        title: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.scriptTitle', { value: title }),
        icon: '',
        showExpandedCross: false
      },
      footer: {
        show: showFooter,
        okButton: { show: false },
        cancelButton: { text: this.i18n.transform('buttons:close') }
      }
    };
  }

  private getCommand(script: any) {
    const asyncId = getGuid();
    const command = RmmCommand.create('Script', asyncId);
    command.addParam('SCRIPT', script);
    command.addParam('TIMEOUT', null);
    command.addParam('INTERACTIVE', `${this.isInteractive}`);
    command.addParam('STOP', 'false');

    return command;
  }

  exportResult() {
    this.store
      .select(SummaryComputerSelectors.selectCurrentComputer)
      .pipe(take(1))
      .subscribe((computer) => {
        const rows: CsvTemplate['rows'] = [
          {
            Computer: Computer.getComputerName(computer),
            Date: this.scriptExecutionDate,
            Status: `${this.lastCommandType === MbsPopupType.success ? PowerShellOutType.End : PowerShellOutType.Error}`,
            Output: `"${this.lastCommandResult}"`
          }
        ];

        const template: CsvTemplate = {
          cols: ['Computer', 'Date', 'Status', 'Output'],
          rows
        };

        const blob = new Blob([generateCsvTable(template)], { type: 'text/csv' });
        saveAs(blob, `Result.csv`);
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  openTerminalInModalTab(template: TemplateRef<any>) {
    this.shouldInitTerminal = false;
    const settings: ModalSettings = {
      size: MbsSize.md,
      responsive: false,
      header: {
        title: this.i18n.transform('rmm-side-panel:powerShellTab.remoteWindowsPowershell'),
        showCloseCross: true,
        showExpandedCross: true,
        icon: 'ico ico-Terminal'
      },
      footer: { show: false }
    };

    this.shouldFocusTerminal = false;

    this.IS_MODAL_CONTENT_OPEN.next(true);
    this.modalService.open(settings, template).finally(() => {
      this.newTerminalForm.get('terminalValue').setValue(this.newTerminalForm.get('terminalValue').value);
      this.shouldFocusTerminal = true;
      this.IS_MODAL_CONTENT_OPEN.next(false);
    });
  }

  modeSelected(modeSelectType: ModeSelectTypes) {
    return modeSelectType === this.executeScriptFormType.get('modeSelect').value;
  }

  setModeSelect(modeSelectType: ModeSelectTypes) {
    this.executeScriptFormType.get('modeSelect').setValue(modeSelectType);
  }

  public enableOpenInModal(enabled: boolean) {
    this.openInModalDisabled = !enabled;
  }

  // utility to cancel Script execution request before
  requestPermissionsStream() {
    return this.tfaRMMPermissionHelper.is2FAPassedStream([this.hid], this.powerShellTabContainer).pipe(untilDestroyed(this));
  }
}
