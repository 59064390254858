import { EventLogTypeDataFieldsEnum } from '@models/rmm/EventLogInfo';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as EventChartActions from './event-chart.actions';
import * as EventTotalActions from './event-total.actions';
import { EventChart } from './model';

export const eventChartsFeatureKey = 'eventCharts';

export type State = EntityState<EventChart> & {
  total: number;
  loading: boolean;
};

export const adapter: EntityAdapter<EventChart> = createEntityAdapter<EventChart>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  total: 0,
  loading: false
});

const reducer = createReducer(
  initialState,
  on(EventTotalActions.loadError, (state) => Object.assign({}, state, { loading: false })),
  on(EventChartActions.loadEventCharts, (state) => Object.assign({}, state, { loading: true })),
  on(EventChartActions.addAllEventCharts, (state, action) => {
    const newState = adapter.setAll(action.eventCharts, state);
    const total = action.eventCharts.reduce((summa, log) => {
      summa =
        summa +
        Object.values(EventLogTypeDataFieldsEnum).reduce((sum, f) => {
          sum = sum + log[f];
          return sum;
        }, 0);

      return summa;
    }, 0);

    return Object.assign({}, newState, { total, loading: false });
  }),
  on(EventChartActions.clearEventCharts, (state) => adapter.removeAll(state))
);

export function eventChartReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
