<mbs-modal [title]="'Add New Cloud Storage Account'" [isCustomModal]="true" #modal>
  <div modal-body>
    <p>
      You are already using MSP360 (Amazon&nbsp;S3) storage. Along with&nbsp;it, we&nbsp;offer
      <a target="_`blank" href="/AP/Help/administration/licenses/aws-ub-licenses">some licenses</a>
      at&nbsp;a&nbsp;discounted price. With the new cloud storage account added, the discount for
      {{ credits?.licenseCount }} licenses in&nbsp;use will be&nbsp;revoked.
    </p>
    <p>
      To&nbsp;add a&nbsp;new cloud storage account, you will need to&nbsp;pay the difference. Total surcharge:
      {{ currency }}{{ credits?.surchargeTotal }}
    </p>

    <p *ngIf="credits?.surchargeLeft > 0; else enoughCreditsTemplate">
      The surcharge amount will be&nbsp;partially covered with credits {{ currency }}{{ credits?.creditsLeft }} and the rest of&nbsp;the
      amount {{ currency }}{{ credits?.surchargeLeft }} will be&nbsp;charged from your account
    </p>

    <ng-template #enoughCreditsTemplate>
      <p>
        The surcharge will be&nbsp;covered with your credits. Currently, you have
        {{ currency }}{{ credits?.creditsTotal }} in&nbsp;credits
      </p>
    </ng-template>
  </div>
  <ng-container modalFooter>
    <mbs-button type="success" [loading]="loading$ | async" (click)="handleResolve()">Pay</mbs-button>
    <mbs-button type="secondary" data-dismiss="modal" (click)="modal.close()">Close</mbs-button>
  </ng-container>
</mbs-modal>
