import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ComputerAppActions from './computer-apps.actions';
import { ComputerApp, State } from './computer-apps.model';

export const adapter: EntityAdapter<ComputerApp> = createEntityAdapter<ComputerApp>();

export const initialState: State = adapter.getInitialState({
  loading: false
});

export const reducer = createReducer(
  initialState,
  // custom reducers
  on(ComputerAppActions.loadComputerApp, (state, action) => Object.assign({}, state, { loading: true })),
  on(ComputerAppActions.loadUpdateComputerApp, (state, action) => Object.assign({}, state, { loading: true })),

  on(ComputerAppActions.loadComputerAppSuccess, (state, action) => {
    return { ...adapter.upsertOne(action.computerApp, state), ...{ loading: false } };
  }),

  // default reducers
  on(ComputerAppActions.addComputerApp, (state, action) => adapter.addOne(action.computerApp, state)),
  on(ComputerAppActions.upsertComputerApp, (state, action) => adapter.upsertOne(action.computerApp, state)),
  on(ComputerAppActions.addComputerApps, (state, action) => adapter.addMany(action.computerApps, state)),
  on(ComputerAppActions.upsertComputerApps, (state, action) => adapter.upsertMany(action.computerApps, state)),
  on(ComputerAppActions.updateComputerApp, (state, action) => adapter.updateOne(action.computerApp, state)),
  on(ComputerAppActions.updateComputerApps, (state, action) => adapter.updateMany(action.computerApps, state)),
  on(ComputerAppActions.deleteComputerApp, (state, action) => adapter.removeOne(action.id, state)),
  on(ComputerAppActions.deleteComputerApps, (state, action) => adapter.removeMany(action.ids, state)),
  on(ComputerAppActions.loadComputerApps, (state, action) => adapter.setAll(action.computerApps, state)),
  on(ComputerAppActions.clearComputerApps, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
