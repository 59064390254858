import { Injectable } from '@angular/core';
import * as ScheduleGroupTaskActions from '@modules/group-tasks/store/actions';
import { GroupTaskReg } from '@modules/group-tasks/store/model';
import * as ScheduleGroupTaskSelectors from '@modules/group-tasks/store/selectors';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { I18_KEY } from '@shared/services/smart-search-template.service';
import { I18NextPipe } from 'angular-i18next';
import * as moment from 'moment';
import { map, tap } from 'rxjs/operators';
import { GAActions, GASelectors } from '.';
import { WizardGroupAction } from './group-action.model';

@Injectable()
export class RmmGroupTaskWizardEffects {
  saveGroupActionTaskChanges$ = createEffect(() => {
    return this.actions$.pipe(ofType(GAActions.saveGActionChanges)).pipe(
      concatLatestFrom(() => [
        this.store.select(GASelectors.selectGroupActionState),
        this.store.select(ScheduleGroupTaskSelectors.selectGroupTaskToEditGuid)
      ]),
      tap(([action, wizardGroupTask, groupTaskToEdit]) =>
        this.store.dispatch(
          ScheduleGroupTaskActions.saveChangesToGroupTask({
            groupTaskGuid: groupTaskToEdit,
            task: this.getGroupTaskFromData({...wizardGroupTask})
          })
        )
      ),
      map(() => GAActions.successGroupAction())
    );
  });

  saveGroupActionTask$ = createEffect(() => {
    return this.actions$.pipe(ofType(GAActions.saveNewGAction)).pipe(
      concatLatestFrom(() => this.store.select(GASelectors.selectGroupActionState)),
      tap(([, wizardGroupTask]) =>
        this.store.dispatch(ScheduleGroupTaskActions.registerGroupTask({ task: this.getGroupTaskFromData({...wizardGroupTask}) }))
      ),
      map(() => GAActions.successGroupAction())
    );
  });

  private getGroupTaskFromData(data: WizardGroupAction): GroupTaskReg {
    return {
      ...data.parameters,
      ...data.schedule,
      allComputers: data.applyTo.allCompanies,
      companies: data.applyTo.companies,
      computers: data.applyTo.computers,
      computerTags: data.applyTo.computerTags,
      name: data.name,
      osType: data.osType
    } as GroupTaskReg;
  }

  constructor(private actions$: Actions, private store: Store, private i18n: I18NextPipe) {}

  getTimeOutError(timeout = 60000) {
    const t = moment.duration(timeout).asSeconds();

    return this.i18n.transform(`${I18_KEY}errors.timeout`, { timeout: t });
  }
}
