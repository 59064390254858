export class FilterSearch {
  static filter(texts: string[], fields: string[], data: any[]) {
    return data.filter(item => {
      return fields.some(field => {
        return texts.some(text => {
          return item[field] && item[field].toLowerCase().includes(text.toLowerCase());
        });
      });
    });
  }
}
