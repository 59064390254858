<mbs-event-state></mbs-event-state>

<ng-container [ngSwitch]="mode$ | async">
  <mbs-event-chart *ngSwitchCase="modes.CHART"></mbs-event-chart>
  <mbs-event-grid *ngSwitchCase="modes.GRID">
    <ng-container modal>
      <mbs-modal-tab-content [modalTitle]="modalWindowTitle"
                             [showExpandedCross]="true">
        <ng-template>
          <mbs-event-state></mbs-event-state>
          <mbs-event-grid [isModal]="true"></mbs-event-grid>
        </ng-template>
      </mbs-modal-tab-content>
    </ng-container>
  </mbs-event-grid>
  <div *ngSwitchDefault>{{ 'rmm-side-panel:eventTotalTab.noData' | i18next }}</div>
</ng-container>

<mbs-event-entry></mbs-event-entry>
