import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AddComputerComponent } from '@components/add-computer/add-computer.component';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { AgentType } from '@models/Computer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { MbsSize, ModalService } from 'mbs-ui-kit';
import { noop } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-no-computers',
  templateUrl: './no-computers.component.html',
  styleUrls: ['./no-computers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoComputersComponent implements OnInit {
  public isReturnToGswAvailable = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private ability: AbilityService,
    private i18N: I18NextPipe,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.authService.currentUser.pipe(untilDestroyed(this)).subscribe((provider) => {
      this.isReturnToGswAvailable =
        !(provider.ResellerInfo && !this.ability.can('read', 'B2Only')) && !this.ability.can('read', 'SubAdmin');
    });
  }

  public onAddComputerButtonClick() {
    const modal = this.modalService.openRef(AddComputerComponent, {
      size: MbsSize.lg
    });
    modal.componentInstance.mode = AgentType.Backup;
    modal.componentInstance.showPendingFilterButton = false;
    modal.result.finally(noop);
  }

  public getDescription() {
    const returnToGSWText = this.isReturnToGswAvailable ? `\n${this.i18N.transform('computers.module:returnToGSW')}` : '';

    return this.i18N.transform('computers.module:noComputersDescription') + returnToGSWText;
  }

  public goToGettingStarted() {
    this.http
      .post('/api/gettingstarted/restart', {})
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        location.href = RoutingPath.ApGettingStart;
      });
  }
}
