import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ComputersService } from '@services/computers.service';
import { ComputersStatisticAbstractService } from '@services/computersStatistic.service';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { ComputersStatisticStoreActions, ComputersStatisticStoreSelectors } from '.';

@Injectable()
export class ComputersStatisticEffects {
  loadHealthData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStatisticStoreActions.loadHealthData),
      mergeMap(() =>
        this.computersStatisticService.getComputersStatisticHealthState().pipe(
          map((data) => ComputersStatisticStoreActions.loadHealthDataSuccess({ data })),
          catchError((error: HttpErrorResponse) => of(ComputersStatisticStoreActions.loadHealthDataError({ error: error.message })))
        )
      )
    );
  });
  loadStateData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStatisticStoreActions.loadStateData),
      mergeMap(() =>
        this.computersStatisticService.getComputersStatisticState().pipe(
          map((data) => ComputersStatisticStoreActions.loadStateDataSuccess({ data })),
          catchError((error: HttpErrorResponse) => of(ComputersStatisticStoreActions.loadStateDataError({ error: error.message })))
        )
      )
    );
  });
  loadStatistic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStatisticStoreActions.loadStatistic),
      mergeMap(() =>
        this.computersStatisticService.getComputersStatistic().pipe(
          map((data) => ComputersStatisticStoreActions.loadStatisticSuccess({ data })),
          catchError((error: HttpErrorResponse) => of(ComputersStatisticStoreActions.loadStatisticError({ error: error.message })))
        )
      )
    );
  });
  checkOneComputer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersStatisticStoreActions.checkOneComputerExist),
      concatLatestFrom(() => this.store.select(ComputersStatisticStoreSelectors.selectOneComputerLoaded)),
      filter(([action, loaded]) => !loaded || action.force),
      mergeMap(() =>
        this.computersService.getComputers({ withOnlineInfo: false, includes: String.fromCharCode(32), limit: 1, offset: 0 }).pipe(
          map((data) => ComputersStatisticStoreActions.checkOneComputerSuccess({ data: !!data.total })),
          catchError(() => of(ComputersStatisticStoreActions.checkOneComputerError()))
        )
      )
    );
  });
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  constructor(
    private actions$: Actions,
    private computersStatisticService: ComputersStatisticAbstractService,
    private computersService: ComputersService,
    public store: Store
  ) {}
}
