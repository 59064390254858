import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@environments/environments.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Will reload page in case of ServiceUnavailable (503) error
 */
@Injectable()
export class ServiceUnavailableInterceptor implements HttpInterceptor {
  constructor(private environment: EnvironmentService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedCommands = ['/commands/invokepscommand', '/commands/invokeactivecommand'];
    const isExcludedByCommand = excludedCommands.some((command) => req.url.includes(command));

    return next.handle(req).pipe(
      catchError((e) => {
        if (e.status === HttpStatusCode.ServiceUnavailable && this.environment.production && !isExcludedByCommand) {
          location.reload();
        }

        throw e;
      })
    );
  }
}
