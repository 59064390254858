import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbilityModule } from 'ability';
import { ButtonModule } from 'mbs-ui-kit';
import { IosTopWidgetComponent } from './ios-top-widget/ios-top-widget.component';

@NgModule({
  declarations: [IosTopWidgetComponent],
  imports: [CommonModule, ButtonModule, AbilityModule],
  exports: [IosTopWidgetComponent],
  providers: []
})
export class WidgetsModule {}
