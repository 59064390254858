<div class="mt-2 d-flex align-items-center">
  <a target="_blank"
     href="https://apps.apple.com/us/app/msp-admin/id1382254249?itsct=apps_box_badge&amp;itscg=30200"
     style="display: inline-block; overflow: hidden; width: 96px; height: 32px">
    <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1536883200&h=6a1229c86e996054a0de11ef13138c52"
         alt="Download on the App Store"
         style="width: 96px; height: 32px" />
  </a>
  <a target="_blank"
     href="https://play.google.com/store/apps/details?id=com.msp360.msp360admin&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
    <img alt="Get it on Google Play"
         src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
         style="width: 121px; height: 47px" />
  </a>
</div>
