import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { ToastService } from 'mbs-ui-kit';
import * as ScriptLibraryActions from '../../store/script-library.actions';
import { ScriptLibraryEntry } from '../../store/script-library.model';
import * as ScriptLibrarySelectors from '../../store/script-library.selectors';

@UntilDestroy()
@Component({
  selector: 'mbs-script-library-body',
  templateUrl: './script-library-body.component.html',
  styleUrls: ['./script-library-body.component.scss']
})
export class ScriptLibraryBodyComponent implements OnInit {
  @Input() script: ScriptLibraryEntry;
  scriptBody: string;

  constructor(private store: Store, private toastService: ToastService, private i18nPipe: I18NextPipe) {}

  ngOnInit(): void {
    queueMicrotask(() => {
      this.store.dispatch(ScriptLibraryActions.loadScriptBody({ scriptGuid: this.script.scriptGuid }));
      this.store
        .select(ScriptLibrarySelectors.selectEntryBody(this.script.scriptGuid))
        .pipe(untilDestroyed(this))
        .subscribe((body) => (this.scriptBody = body));
    });
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.scriptBody);

    this.toastService.success(
      this.i18nPipe.transform('rmm-script-library.module:sidePanel.scriptBodyTab.copiedMessage', { format: 'title' })
    );
  }
}
