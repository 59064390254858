<h4>{{ 'onboarding:configure_backup' | i18next: { format: 'title' } }}</h4>

<ng-container *ngIf="labelsControl.value?.length; else loader">
  <mbs-checkbox *ngFor="let control of valueControl?.controls; let index = index"
                class="mt-3"
                [label]="prepareSource(labelsControl.value[index])"
                [formControl]="control"></mbs-checkbox>
</ng-container>

<ng-template #loader>
  <mbs-loader></mbs-loader>
</ng-template>
