import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { PlanMode, PlanTypes } from '@models/PlanTypes.enum';
import { ViewMode } from '@modules/wizards/constants/view-mode';
import { StepsDataFromPlanHelper } from '@modules/wizards/helpers/steps-data-from-plan-helper/steps-data-from-plan-helper';
import { WhatBackupStepValue } from '@modules/wizards/models/what-backup-models';
import { WhatBackupTreeStepValue } from '@modules/wizards/models/what-backup-tree-model';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { StepsService } from '@modules/wizards/onboarding/services/steps/steps.service';
import { getSourcesConfig } from '@modules/wizards/onboarding/steps/computers/backup-source/backup-source.helpers';
import { BaseStep } from '@modules/wizards/onboarding/steps/shared/base/base.step';
import { BackupDataType } from '@modules/wizards/onboarding/views/computers/computers.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { ModalService, StepCondition } from 'mbs-ui-kit';
import { BehaviorSubject, noop } from 'rxjs';
import { CheckboxLegendComponent } from '@modules/wizards/steps/components/checkbox-legend/checkbox-legend.component';

@UntilDestroy()
@Component({
  selector: 'mbs-backup-source-step',
  templateUrl: './backup-source.component.html',
  styleUrls: ['./backup-source.component.scss']
})
export class BackupSourceComponent<StepId> extends BaseStep<StepId> implements OnInit, OnDestroy, AfterContentChecked {
  public readonly PlanType = PlanTypes;
  public readonly whatBackupViewMode = ViewMode;

  public isValid = false;
  public stepId = 'backupSource';
  public isDataLoading = true;
  public sources = getSourcesConfig(this.i18N);
  public selectedSource = this.sources[0];
  public whatBackupStep = new BehaviorSubject<WhatBackupStepValue>(null);
  public whatBackupTreeStep = new BehaviorSubject<WhatBackupTreeStepValue>(null);
  public mode: PlanMode;
  private initiate = true;
  private selectedPlan;

  @Input() set plan(plan) {
    this.selectedPlan = plan;
    if (!plan) return;

    if (!this.initiate) {
      if (plan.PlanType === PlanTypes.Plan) {
        this.whatBackupTreeStep.next({
          includeFolders: [],
          excludeFolders: [],
          valid: true
        });
      } else {
        this.whatBackupStep.next(StepsDataFromPlanHelper.updateWhatBackup(plan));
      }
    }

    this.isDataLoading = false;
    this.onboardingService.stable$.next(true);
  }
  @Input() initialData: BackupDataType;
  @Input() settings: {
    hid: string;
    isLinux: boolean;
    isNBF: boolean;
    backupVersionUpdated: string;
  };

  @Output() planTypeChanged = new EventEmitter<PlanTypes>();
  @Output() selectedData = new EventEmitter<BackupDataType>();

  constructor(
    private i18N: I18NextPipe,
    private cdr: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private stepService: StepsService<StepId>,
    private modalService: ModalService
  ) {
    super(stepService);
  }

  ngOnInit() {
    this.initiate = false;
    this.whatBackupTreeStep.pipe(untilDestroyed(this)).subscribe((data) => {
      this.switchValidityByDataChanged(data, 'includeFolders', PlanTypes.Plan);
    });

    this.whatBackupStep.pipe(untilDestroyed(this)).subscribe((data) => {
      this.switchValidityByDataChanged(data, 'partitions', PlanTypes.BackupDiskImagePlan);
    });

    if (this.initialData?.type) {
      this.selectedSource = this.sources.find((source) => source.type === this.initialData.type);

      if (this.initialData?.type === PlanTypes.Plan) {
        this.whatBackupTreeStep.next(this.initialData.data as WhatBackupTreeStepValue);
      } else {
        this.setWhatBackupStepEditData();
      }
    } else {
      this.onSourceChanged(this.selectedSource);
    }
  }

  private setWhatBackupStepEditData(): void {
    this.mode = PlanMode.edit;
    const initialData = this.initialData.data as WhatBackupStepValue;
    const data = {
      ...initialData,
      partitions: this.selectedPlan.DiskVolumes.map((volume) => {
        return { ...volume, Enabled: initialData?.partitions.some((partition) => partition.Identity === volume.Identity) };
      })
    } as WhatBackupStepValue;

    this.whatBackupStep.next(data);
  }

  private switchValidityByDataChanged(
    data: WhatBackupStepValue | WhatBackupTreeStepValue,
    property: 'partitions' | 'includeFolders',
    planType: PlanTypes
  ): void {
    const value = data?.[property];

    if (value && !value?.length && this.selectedSource?.type === planType) {
      this.onboardingService.nextStepAvailable$.next(false);
      this.isValid = false;
    } else {
      this.onboardingService.nextStepAvailable$.next(true);
      this.isValid = true;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    const { type } = this.selectedSource;

    this.selectedData.emit({
      type,
      data: type === PlanTypes.Plan ? this.whatBackupTreeStep.getValue() : this.whatBackupStep.getValue()
    });
    this.stepService.setProperty('backupSource' as unknown as StepId, 'condition', this.isValid ? StepCondition.Completed : 'progress');
    this.onboardingService.nextStepAvailable$.next(true);
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  onSourceChanged(source): void {
    this.selectedSource = source;
    this.planTypeChanged.emit(source.type);
    this.isDataLoading = true;
    this.onboardingService.stable$.next(false);
  }

  showLegend(): void {
    this.modalService.openCustom(CheckboxLegendComponent).then().catch(noop);
  }
}
