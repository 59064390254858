import { AgentType } from '@models/Computer';

export const FeatureKey = 'ComputerApps';
export const FeaturePrefix = '[Computer Apps]';

export const generateAgentId = (type: AgentType, hid): string => `${type}:${hid}`;
export const extractFromAgentId = (id: string): { type: AgentType; hid: string } => {
  const [type, hid] = id.split(':');
  return { type: type as AgentType, hid };
};
