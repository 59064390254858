import { Component, ViewChild } from '@angular/core';
import { AbilityService } from 'ability';
import { FormBuilder, FormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { uniq } from 'lodash/fp';
import { ModalComponent } from 'mbs-ui-kit';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import * as ScriptLibraryActions from '../../store/script-library.actions';
import { ScriptLibraryAccessLevel, ScriptLibraryTag } from '../../store/script-library.model';
import * as ScriptLibrarySelectors from '../../store/script-library.selectors';

@UntilDestroy()
@Component({
  selector: 'mbs-category-list-editor',
  templateUrl: './category-list-editor.component.html',
  styleUrls: ['./category-list-editor.component.scss']
})
export class CategoryListEditorComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public form: UntypedFormGroup;
  public categoryList$: Observable<Partial<ScriptLibraryTag[]>>;
  private categoryList: ScriptLibraryTag[] = [];
  public permission: string[] = [];
  public addButtonDisabled = true;
  public showError = false;

  protected unRemovableCategoryNames = ['General', 'MSP360PSModule', 'Uncategorized'];

  constructor(private store: Store, private fb: FormBuilder, private ability: AbilityService, private i18n: I18NextPipe) {
    this.permission = uniq([
      this.ability.can('read', 'Rmm') ? ScriptLibraryAccessLevel.Private : null,
      this.ability.can('read', 'Provider') && this.ability.can('read', 'Rmm') ? ScriptLibraryAccessLevel.Public : null,
      this.ability.can('read', 'AccessToAllCompanies') && this.ability.can('read', 'Rmm') ? ScriptLibraryAccessLevel.Public : null,
      this.ability.can('read', 'SuperAdmin') ? ScriptLibraryAccessLevel.Common : null
    ]).filter(Boolean);

    this.form = this.fb.group({
      name: ['', [Validators.required, this.categoryNameValidator.bind(this)]]
    });

    this.initStreams();
  }

  initStreams() {
    this.store.dispatch(ScriptLibraryActions.loadTags());

    this.categoryList$ = combineLatest([
      this.store.select(ScriptLibrarySelectors.selectTags),
      this.store.select(ScriptLibrarySelectors.selectAll)
    ]).pipe(
      map(([tags, scripts]) => {
        this.categoryList = tags;
        return tags.map((tag) => ({
          ...tag,
          scriptCounter: scripts.reduce((ac, next) => ac + (next.tags.some((scriptTag) => scriptTag.tagGuid === tag.tagGuid) ? 1 : 0), 0)
        }));
      }),
      untilDestroyed(this)
    );

    this.form
      .get('name')
      .valueChanges.pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((name) => {
        this.addButtonDisabled = !name?.trim() || this.categoryList.some((category) => category.name.toLowerCase() === name.toLowerCase());
        this.showError = this.categoryList.some((category) => category.name.toLowerCase() === name.toLowerCase());
      });
  }

  addCategory(): void {
    const name = this.form.get('name').value;

    if (name.trim()) {
      this.store.dispatch(ScriptLibraryActions.registerTag({ tagName: name }));
      this.form.get('name').setValue('');
      this.form.controls['name'].reset();
    }
  }

  deleteCategory(tagGuid: string): void {
    this.store.dispatch(ScriptLibraryActions.deleteTag({ tagGuid }));
  }

  categoryNameValidator(control: FormControl): ValidationErrors | null {
    return this.categoryList.some((category) => category?.name?.toLowerCase() === control.value?.toLowerCase()) ? { notUnique: true } : null;
  }
}
