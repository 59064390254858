<div *ngIf="scriptBody"
     class="mb-3">
  <pre>{{ scriptBody }}</pre>
</div>

<mbs-button [icon]="'fa fa-clipboard'"
            [size]="'md'"
            (click)="copyToClipboard()">
  {{ 'rmm-script-library.module:sidePanel.scriptBodyTab.copyToClipboard' | i18next }}
</mbs-button>
