import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UbAwsChangingPriceModalComponent } from '@modules/storage-accounts/ub-aws-changing-price-modal/ub-aws-changing-price-modal.component';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { ButtonModule, CheckboxModule, LoaderModule, ModalModule } from 'mbs-ui-kit';
import { SubscribeUbAwsComponent } from './subscribe-ub-aws/subscribe-ub-aws.component';
import { SubscribeUbWasabiComponent } from './subscribe-ub-wasabi/subscribe-ub-wasabi.component';
import { UbCancelDeletionComponent } from './ub-cancel-deletion/ub-cancel-deletion.component';
import { UbNotificationsComponent } from './ub-notifications/ub-notifications.component';
import { UbSubscribeService } from './ub-subscribe.service';

@NgModule({
  declarations: [
    UbNotificationsComponent,
    UbCancelDeletionComponent,
    SubscribeUbAwsComponent,
    SubscribeUbWasabiComponent,
    UbAwsChangingPriceModalComponent
  ],
  exports: [
    UbNotificationsComponent,
    UbCancelDeletionComponent,
    SubscribeUbAwsComponent,
    SubscribeUbWasabiComponent,
    UbAwsChangingPriceModalComponent
  ],
  imports: [CommonModule, ModalModule, ButtonModule, LoaderModule, CheckboxModule, FormsModule, RouterModule, AbilityModule, I18NextModule],
  providers: [UbSubscribeService]
})
export class StorageAccountsModule {}
