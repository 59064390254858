import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComputersFacade } from '@facades/computers.facade';
import { Build, BuildsInfo } from '@models/build';
import { BuildType } from '@models/BuildType.enum';
import Computer, { AgentType } from '@models/Computer';
import { InstallationView } from '@modules/wizards/steps/installation/installation.constants';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BuildService } from '@services/build-service/build.service';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { BehaviorSubject, EMPTY, expand, of, repeat } from 'rxjs';
import { delay, filter, map, mergeMap, takeWhile, tap } from 'rxjs/operators';
import { BuildSelectionType } from './installation.types';

@UntilDestroy()
@Component({
  selector: 'mbs-wizards-installation-step',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss']
})
export class InstallationComponent implements OnInit {
  readonly installationView = InstallationView;
  readonly buildType = BuildType;

  public selectedBuild: BuildSelectionType;
  public initialLoading = true;
  public accessibleProviderBuilds: Build[] = null;
  public currentBuild$ = new BehaviorSubject<Build>(null);
  public windowsSupported = this.i18nService.t('builds:windows.supported', { returnObjects: true });
  public windowsCanBackup = this.i18nService.t('builds:windows.can_backup', { returnObjects: true });
  public linuxDebSupported = this.i18nService.t('builds:linux_deb.supported', { returnObjects: true });
  public linuxRpmSupported = this.i18nService.t('builds:linux_rpm.supported', { returnObjects: true });
  public macOsSupported = this.i18nService.t('builds:mac_os.supported', { returnObjects: true });
  public windowsRmmSupported = this.i18nService.t('builds:rmm.supported', { returnObjects: true });
  public linuxDebRmmSupported = this.i18nService.t('builds:rmm_linux_deb.supported', { returnObjects: true });
  public linuxRpmRmmSupported = this.i18nService.t('builds:rmm_linux_rpm.supported', { returnObjects: true });
  public macRmmSupported = this.i18nService.t('builds:rmm_mac_os.supported', { returnObjects: true });

  private selectedBuildType: BuildType;

  constructor(
    private i18nPipe: I18NextPipe,
    private i18nService: I18NextService,
    private buildService: BuildService,
    private clipBoard: Clipboard,
    private computersFacade: ComputersFacade
  ) {}

  @Input() view: InstallationView;
  @Input() buildsSelectionConfig: BuildSelectionType[];
  @Output() completed = new EventEmitter<Computer>();
  @Output() stable = new EventEmitter<boolean>();
  @Output() hasError = new EventEmitter();

  ngOnInit(): void {
    this.initialize();
    this.currentBuild$.pipe(untilDestroyed(this)).subscribe((build) => {
      this.stable.next(!!build);
    });
  }

  public copyDownloadLink(link: string): void {
    this.clipBoard.copy(link);
  }

  public downloadBuild(): void {
    window.open(this.getDownloadLink(), '_blank');
  }

  public getDownloadLink(): string {
    const { mbsBuildType } = this.currentBuild$.getValue();
    return `${location.origin}/api/build/download-gsw?buildType=${mbsBuildType}&sandbox=false`;
  }

  public onSelectedBuildChange(buildSelection: BuildSelectionType): void {
    this.currentBuild$.next(null);
    const currentBuild = this.accessibleProviderBuilds.find((build) => buildSelection.buildType === build.mbsBuildType);

    if (currentBuild) {
      this.currentBuild$.next(currentBuild);
      this.getComputers(this.selectedBuild);
    } else {
      this.generateNewBuild(buildSelection.buildType);
    }
  }

  public handleOpenBuildTooltip(tooltip: NgbTooltip, build: BuildSelectionType): void {
    tooltip.open({ $implicit: build });
  }

  public handleCloseBuildTooltip(tooltip: NgbTooltip): void {
    tooltip.close();
  }

  public getTypeByView(view: InstallationView): string {
    return view === InstallationView.Rmm ? this.i18nPipe.transform('products.rmm') : this.i18nPipe.transform('products.backup');
  }

  private initialize() {
    this.buildService
      .getAll()
      .pipe(
        untilDestroyed(this),
        tap(({ builds }) => {
          this.accessibleProviderBuilds = builds;
        }),
        mergeMap(({ builds }) => {
          const buildTypes = builds.map((build) => build.mbsBuildType);
          const initialBuildSelection =
            this.buildsSelectionConfig.length === 1
              ? this.buildsSelectionConfig[0]
              : this.buildsSelectionConfig.find((installationPackage) => buildTypes.includes(installationPackage.buildType));

          return initialBuildSelection
            ? of(initialBuildSelection)
            : this.buildService
                .getActiveBuildRequest()
                .pipe(
                  map((builds) =>
                    this.buildsSelectionConfig.find(
                      (installationPackage) => builds.length && builds[0].requestedBuild === installationPackage.buildType
                    )
                  )
                );
        })
      )
      .subscribe({
        next: (initialBuildSelection) => {
          this.finishInitialization(initialBuildSelection || this.buildsSelectionConfig[0]);
        },
        error: (err) => this.hasError.next(err)
      });
  }

  private finishInitialization(initialBuildSelection: BuildSelectionType): void {
    this.selectedBuild = initialBuildSelection;
    this.onSelectedBuildChange(initialBuildSelection);
    this.initialLoading = false;
  }

  private generateNewBuild(buildType: BuildType): void {
    this.buildService
      .requestBuild({ buildsTypeList: [buildType], isBuildDestinationSandbox: false })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.updateBuilds(buildType),
        error: () => this.hasError.next(true)
      });
  }

  private updateBuilds(buildType: BuildType): void {
    this.selectedBuildType = buildType;
    const query$ = this.buildService.getAll().pipe(delay(5000));
    const hasBuild = (buildRequest: BuildsInfo): boolean =>
      buildRequest.builds.some((build) => build.mbsBuildType === this.selectedBuildType);

    query$
      .pipe(
        untilDestroyed(this),
        expand((response) => (!hasBuild(response) ? query$ : EMPTY)),
        filter((response) => hasBuild(response)),
        map((builds) => {
          return builds.builds;
        })
      )
      .subscribe({
        next: (builds) => {
          this.currentBuild$.next(builds.find((build) => build.mbsBuildType === this.selectedBuildType));
          this.accessibleProviderBuilds = builds;

          this.getComputers(this.selectedBuild);
        },
        error: () => {
          this.hasError.next(true);
        }
      });
  }

  private getComputers(selectedBuild: BuildSelectionType) {
    const isOsChanged = () => selectedBuild.buildType !== this.selectedBuild.buildType;

    const query$ = of(null).pipe(
      untilDestroyed(this),
      mergeMap(() =>
        this.computersFacade.getComputers({
          os: [selectedBuild.os],
          appIds: [this.view === this.installationView.Rmm ? AgentType.RMM : AgentType.Backup],
          online: true
        })
      ),
      tap(({ data: computers }) => {
        computers?.length && this.completed.emit(computers[0]);
      }),
      delay(5000),
      repeat(),
      takeWhile(({ data: computers }) => !isOsChanged() && !computers.length)
    );

    query$.subscribe({
      error: () => this.hasError.next(true)
    });
  }
}
