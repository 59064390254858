/* eslint-disable @typescript-eslint/unbound-method */
import { ISortByFunction, ISortByObjectSorter, createNewSortInstance } from 'fast-sort';
import { SortEvent } from 'mbs-ui-kit';

const naturalSort = createNewSortInstance({
  comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare
});

const dateSort = createNewSortInstance({
  comparer: (a: any, b: any) => {
    if (!a) {
      return -1;
    }

    if (!b) {
      return 1;
    }

    return new Date(a).getTime() - new Date(b).getTime();
  }
});

const createSortByFunction =
  <T>(column: string) =>
  (s: any): ISortByFunction<T> => {
    const item = s[column];

    return item instanceof Date ? item.getTime() : item;
  };

export class InMemorySorter {
  static multipleSort<T>(sortArgs: SortEvent[], data: T[]): T[] {
    if ((sortArgs || []).length == 0) {
      return data;
    }

    const sourceData = Array.from(data);

    const sortByObjectSorter: ISortByObjectSorter<T>[] = sortArgs.reduce((acc, event: SortEvent) => {
      if (!event.column) return acc;

      switch (event.direction) {
        default:
        case 'asc':
          acc.push({ asc: createSortByFunction<T>(event.column) });
          break;
        case '':
        case 'desc':
          acc.push({ desc: createSortByFunction<T>(event.column) });
          break;
      }

      return acc;
    }, []);

    return naturalSort<T>(sourceData).by(sortByObjectSorter);
  }

  static sortByDate<T>(sortArgs: SortEvent, data: T[]): T[] {
    let sortedData: T[] = [];
    const sourceData = Array.from(data);
    if (sortArgs) {
      switch (sortArgs.direction) {
        default:
        case 'asc':
          sortedData = dateSort<T>(sourceData).asc((s) => s[sortArgs.column]);
          break;
        case '':
        case 'desc':
          sortedData = dateSort<T>(sourceData).desc((s) => s[sortArgs.column]);
          break;
      }
    } else {
      sortedData = sourceData;
    }
    return sortedData;
  }

  static sort<T>(sortArgs: SortEvent, data: T[], bySortObject = false): T[] {
    let sortedData: T[] = [];
    const sourceData = Array.from(data);
    if (sortArgs) {
      switch (sortArgs.direction) {
        default:
        case 'asc':
          sortedData = bySortObject
            ? naturalSort<T>(sourceData).by({ asc: createSortByFunction<T>(sortArgs.column) })
            : naturalSort<T>(sourceData).asc((s) => s[sortArgs.column] || undefined);
          break;
        case '':
        case 'desc':
          sortedData = bySortObject
            ? naturalSort<T>(sourceData).by({ desc: createSortByFunction<T>(sortArgs.column) })
            : naturalSort<T>(sourceData).desc((s) => s[sortArgs.column] || undefined);
          break;
      }
    } else {
      sortedData = sourceData;
    }
    return sortedData;
  }
}
