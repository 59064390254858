import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ComputersFacade } from '@facades/computers.facade';
import RmmCommand from '@models/rmm/RmmCommand';
import { RmmCounterType } from '@models/rmm/RmmCounterType';
import { SummaryCounter } from '@models/rmm/SummaryInfo';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { RmmService } from '@services/rmm.service';
import { mediumDateWithTime } from '@utils/date';
import { ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import { ChartType } from 'chart.js/dist/types';
import { UnionToIntersection } from 'chart.js/dist/types/utils';
import { cloneDeep, find, merge } from 'lodash/fp';
import { MbsPopupType, TabsetItemDirective, bytesFrom, formatBytes } from 'mbs-ui-kit';
import { BaseChartDirective } from 'ng2-charts';
import { Observable, Subject, interval, shareReplay, switchMap } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { I18NextPipe } from 'angular-i18next';

type DataSets = Array<ChartDataset & { counterType: string; dataRaw?: number[] }>;

type ChartConfig = {
  counterType: string;
  datasets: DataSets;
  options: ChartOptions;
};

interface ProcessTooltipItem<TType extends ChartType> extends TooltipItem<TType> {
  dataset: UnionToIntersection<ChartDataset<TType>> & { dataRaw: Array<number> };
}

const createAsyncId = (hid) => {
  return `GetPerformanceInfo:${hid}`;
};
@UntilDestroy()
@Component({
  selector: 'mbs-processes-charts-tab',
  templateUrl: './processes-charts-tab.component.html'
})
export class ProcessesChartsTabComponent implements OnInit, OnDestroy {
  @Input() public hid: string;
  @Input() isOnline: boolean;
  @ViewChildren(BaseChartDirective) chartList!: QueryList<BaseChartDirective>;

  public runtimeLive = new Subject<SummaryCounter[]>();
  public error: string;
  private chartCountValues = 10;

  public chartLabels: number[] = [];

  public charts: ChartConfig[] = [];
  public shortCharts = [];
  // hack with destroy for prevent undefined previous refs to dataset
  public selectedCharts = [];
  public updatedTime = formatDate(new Date(), mediumDateWithTime, 'en-EU');
  public minInterval = 5; // sec
  public maxInterval = 120;
  public cpuTranslation = this.i18n.transform('rmm-side-panel:processesTab.cpu');

  public updateIntervalMask = {
    mask: 'size seconds',
    blocks: { size: { mask: Number, signed: true, min: this.minInterval, max: this.maxInterval } },
    lazy: false
  };

  updateInterval: FormControl;

  private close$: Observable<any>;

  public showTryRecovery = false;
  public isActiveRecoveryStatus = false;
  public readonly alertType = MbsPopupType;

  constructor(
    public rmmService: RmmService,
    protected appState: AppPersistentStateService,
    private commandService: CommandService,
    private computersFacade: ComputersFacade,
    private tab: TabsetItemDirective,
    private i18n: I18NextPipe
  ) {
    this.updateInterval = new FormControl(this.appState.data.rmmSidepanel.updateInterval || this.minInterval);
    this.computersFacade.currentComputer$
      .pipe(
        switchMap((computer) => this.commandService.selectDataByAsyncId$(createAsyncId(computer.hid))),
        untilDestroyed(this)
      )
      .subscribe((data: any) => {
        queueMicrotask(() => (this.tab.loading = false));
        this.updateLabels(this.updateInterval.value ?? this.minInterval);
        this.updateCharts(data.counters);
      });
  }

  ngOnInit(): void {
    queueMicrotask(() => (this.tab.loading = true));
    this.initInterval();
    this.initChartOptions();
  }

  initInterval() {
    const updateInterval$ = this.updateInterval.valueChanges.pipe(startWith(this.updateInterval.value), shareReplay());

    const interval$ = updateInterval$.pipe(
      map((i) => i * 1000),
      debounceTime(500),
      switchMap((period) => interval(period).pipe(startWith(0)))
    );
    updateInterval$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.appState.data.rmmSidepanel.updateInterval = parseInt(value);
    });
    interval$.pipe(untilDestroyed(this)).subscribe((_) => {
      if (this.isOnline) this.sendCommand();
    });
  }

  sendCommand() {
    const command = RmmCommand.create('GetPerformanceInfo', createAsyncId(this.hid)).addParam('GETHISTORY', 'False');
    this.commandService.sendCommand('SummaryCmd', command, this.hid, false).subscribe(({ error }: any) => {
      this.error = error;
    });
  }

  initChartOptions(): void {
    this.chartLabels = new Array(this.chartCountValues * +this.updateInterval.value)
      .fill(0)
      .map((v, i) => i)
      .filter((v) => v % this.updateInterval.value === 0);

    const options = {
      responsive: true,
      scales: {
        y: {
          stepSize: 50,
          max: 100,
          min: 0
        },
        x: {
          reverse: true
        }
      }
    };

    const titleTooltips = function (tooltipItem: ProcessTooltipItem<'line'>[]): string {
      const title = tooltipItem[0].label;
      return title + ' seconds ago';
    };

    const memoryMinConfig: ChartConfig = {
      counterType: RmmCounterType.UsedRAM,
      datasets: [
        {
          type: 'line',
          counterType: RmmCounterType.UsedRAM,
          data: [],
          dataRaw: [],
          tension: 0,
          borderColor: 'rgba(105,105,252)',
          backgroundColor: 'rgba(105,105,252,0.3)'
        }
      ],
      options: {
        datasets: {
          line: {}
        },
        plugins: {
          title: {
            display: true,
            text: 'Memory'
          },
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            max: 100,
            min: 0,
            ticks: {
              stepSize: 50
            }
          }
        }
      }
    };

    const memoryConfig: ChartConfig = merge(cloneDeep(memoryMinConfig), {
      options: {
        ...options,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem: ProcessTooltipItem<'line'>): string {
                const rawValue = tooltipItem.dataset.dataRaw[tooltipItem.dataIndex];
                return `${this.i18n.transform('rmm-side-panel:processesTab.inUse')} ${formatBytes(rawValue)}`;
              },
              title: titleTooltips
            }
          }
        }
      }
    });

    const cpuMinConfig: ChartConfig = {
      counterType: RmmCounterType.ProcessorTime,
      datasets: [
        {
          type: 'line',
          counterType: RmmCounterType.ProcessorTime,
          data: [],
          dataRaw: [],
          tension: 0,
          label: this.cpuTranslation,
          borderColor: 'rgba(45,108,162)',
          backgroundColor: 'rgba(45,108,162,0.3)'
        }
      ],
      options: {
        datasets: {
          line: {}
        },
        plugins: {
          title: {
            display: true,
            text: `CPU`
          },
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            max: 100,
            min: 0,
            ticks: {
              stepSize: 50
            }
          }
        }
      }
    };

    const cpuConfig: ChartConfig = merge(
      {
        options: {
          ...options,
          tooltip: {
            callbacks: {
              label: (tooltipItem: ProcessTooltipItem<'line'>) => {
                return this.i18n.transform('rmm-side-panel:processesTab.cpuValue', { value: tooltipItem?.formattedValue });
              },
              title: titleTooltips
            }
          }
        }
      },
      cloneDeep(cpuMinConfig)
    );

    const hddMinConfig: ChartConfig = {
      counterType: RmmCounterType.HDDRead + '_' + RmmCounterType.HDDWrite,
      datasets: [
        {
          type: 'line',
          counterType: RmmCounterType.HDDRead,
          data: [],
          label: this.i18n.transform('rmm-side-panel:processesTab.totalRead'),
          tension: 0,
          borderColor: 'rgba(34,177,76)',
          backgroundColor: 'rgba(34,177,76,0.3)'
        },
        {
          type: 'line',
          counterType: RmmCounterType.HDDWrite,
          data: [],
          label: this.i18n.transform('rmm-side-panel:processesTab.write'),
          tension: 0,
          borderColor: 'rgba(34,177,76)',
          backgroundColor: 'rgba(34,177,76,0.3)',
          fill: false,
          borderDash: [5, 5]
        }
      ],
      options: {
        datasets: {
          line: {}
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: this.i18n.transform('rmm-side-panel:processesTab.hddReadOrWrite')
          }
        }
      }
    };

    const hddConfig: ChartConfig = merge(cloneDeep(hddMinConfig), {
      options: {
        scales: {
          y: {
            suggestedMax: 300,
            min: 0
          },
          x: {
            reverse: true
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            fullWidth: false
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem: ProcessTooltipItem<'line'>): string {
                const [hddSpeed, unit] = formatBytes(+tooltipItem.label * bytesFrom('KB')).split(' ');
                return `${tooltipItem.raw}: ${Math.floor(+hddSpeed)} ${unit}/s`;
              },
              title: titleTooltips
            }
          }
        }
      }
    });

    const networkMinConfig: ChartConfig = {
      counterType: RmmCounterType.NetworkReceived + '_' + RmmCounterType.NetworkSent,
      datasets: [
        {
          type: 'line',
          counterType: RmmCounterType.NetworkReceived,
          data: [],
          tension: 0,
          label: this.i18n.transform('rmm-side-panel:processesTab.networkReceived'),
          borderColor: 'rgba(247,108,136)', // prettier
          backgroundColor: 'rgba(247,108,136,0.3)'
        },
        {
          type: 'line',
          counterType: RmmCounterType.NetworkSent,
          data: [],
          label: this.i18n.transform('rmm-side-panel:processesTab.networkSent'),
          tension: 0,
          borderColor: 'rgba(247,108,136)',
          backgroundColor: 'rgba(247,108,136,0.3)',
          fill: false,
          borderDash: [5, 5]
        }
      ],
      options: {
        datasets: {
          line: {}
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: this.i18n.transform('rmm-side-panel:processesTab.network')
          }
        }
      }
    };

    const networkConfig: ChartConfig = merge(cloneDeep(networkMinConfig), {
      options: {
        scales: {
          y: {
            min: 0
          },
          x: {
            reverse: true
          }
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem: ProcessTooltipItem<'line'>): string {
              return `${tooltipItem.dataset.label}: ${tooltipItem.label} Mbit/s`;
            },
            title: titleTooltips
          }
        }
      }
    });

    this.charts = [memoryConfig, cpuConfig, hddConfig, networkConfig].map((c) => {
      const options = c.options.datasets.line;

      c.datasets.forEach((d) => {
        const borderColor = d.borderColor as string;

        options.pointHoverBackgroundColor = borderColor;
        options.pointHoverBorderColor = borderColor;
        options.pointBackgroundColor = borderColor;
        options.pointBorderColor = borderColor;
      });
      return c;
    });

    this.shortCharts = [memoryMinConfig, cpuMinConfig, hddMinConfig, networkMinConfig].map((c, index) => {
      c.options = merge(c.options, {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          y: {
            min: 0,
            ticks: {
              display: false
            }
          },
          x: {
            reverse: true,
            ticks: {
              display: false
            }
          }
        }
      });
      c.datasets = [...this.charts[index].datasets];
      c.options.datasets.line = this.charts[index].options.datasets.line;

      return c;
    });
    if (this.charts.length) this.handleSelectChart(this.charts[0]);
  }

  updateLabels(interval: number): void {
    const copyLabels = Array.from(this.chartLabels);
    copyLabels.forEach((l, index) => (copyLabels[index] = l + interval));
    copyLabels.unshift(0);
    copyLabels.pop();
    this.chartLabels = copyLabels;
  }

  updateCharts(data: SummaryCounter[]): void {
    if (!data) return;
    for (const counter of data) {
      const chartConfig = this.charts.find((c) => c.counterType.includes(counter.name));
      if (!chartConfig) {
        continue;
      }
      const chartInstance = this.chartList.find((c) => Boolean(find({ counterType: counter.name }, c.datasets)));
      const dataset = chartConfig.datasets.find((d) => d.counterType === counter.name);
      const memoryUsage = +data.find((p) => p.name === RmmCounterType.UsedRAM).value;
      const memoryTotal = +data.find((p) => p.name === RmmCounterType.TotalRAM).value;

      const title = `${this.i18n.transform('rmm-side-panel:processesTab.memoryTitle')} ${formatBytes(memoryTotal * bytesFrom('KB'))}`;

      let nextValue = 0;
      switch (counter.name) {
        case RmmCounterType.ProcessorTime:
          nextValue = +data.find((p) => p.name === RmmCounterType.ProcessorTime).value;
          break;
        case RmmCounterType.UsedRAM:
          chartConfig.options.plugins.title.text = title;
          chartInstance.chart.options.plugins.title.text = title;
          chartInstance.chart.update();
          dataset.dataRaw.unshift(memoryUsage * bytesFrom('KB'));
          if (dataset.dataRaw.length > this.chartCountValues) {
            dataset.dataRaw.pop();
          }
          nextValue = (memoryUsage / memoryTotal) * 100;
          break;
        case RmmCounterType.HDDRead:
        case RmmCounterType.HDDWrite:
          nextValue = counter.value / bytesFrom('KB');
          this.updateHddChildCharts(chartConfig, counter.childs || []);
          break;
        case RmmCounterType.NetworkSent:
        case RmmCounterType.NetworkReceived:
          nextValue = counter.value;
          break;
      }

      dataset.data.unshift(nextValue);
      if (dataset.data.length > this.chartCountValues) {
        dataset.data.pop();
      }
    }
  }

  updateHddChildCharts(chart: ChartConfig, counters: SummaryCounter[]): void {
    counters.forEach((child) => {
      const childCounter = child.name + child.instance;
      let childDataset = chart.datasets.find((d) => d.counterType === childCounter);
      if (!childDataset) {
        const operation =
          child.name === RmmCounterType.HDDWrite
            ? this.i18n.transform('rmm-side-panel:processesTab.write')
            : this.i18n.transform('rmm-side-panel:processesTab.read');
        childDataset = {
          type: 'line',
          counterType: childCounter,
          data: [],
          label: `${this.i18n.transform('rmm-side-panel:processesTab.disk')} ${child.instance[0]} ${operation}`,
          tension: 0
        };
        if (child.name === RmmCounterType.HDDWrite) {
          childDataset.fill = false;
          childDataset.borderDash = [5, 5];
        }
        chart.datasets.push(childDataset);
        chart.datasets.sort((a, b) => a.counterType.localeCompare(b.counterType));
      }
      const childNextValue = child.value / bytesFrom('KB');
      childDataset.data.unshift(childNextValue);
      if (childDataset.data.length > this.chartCountValues) {
        childDataset.data.pop();
      }
    });
  }

  handleSelectChart(chart): void {
    if (chart !== this.selectedCharts[0]) {
      this.selectedCharts = [chart];
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
    // empty
  }
}
