<mbs-modal [title]="'Computer PowerShell Terminal'"
           [isCustomModal]="true"
           [showFooter]="false">
  <div modal-body>
    <div class="ng-terminal-wrapper"
      [ngClass]="{
        'initialized': (OnInitialized | async),
        'h-100': baseModal?.isExpanded,
        'ng-terminal-wrapper-modal': !baseModal?.isExpanded
      }"
      (scroll)="handleScroll($event)">
      <ng-template ng-terminal-host></ng-template>
    </div>

    <div class="row ml-1 mt-1 ng-terminal-warning">
      {{ warning }}
    </div>
  </div>
</mbs-modal>
