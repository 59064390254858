<mbs-button *ngIf="!isDropdownItem; else dropDownBtn"
            [class]="btnClass"
            [disabled]="isLoading"
            [ngbTooltip]="'rmm-side-panel:wakeOnLan.btnTooltip' | i18next"
            triggers="hover"
            tooltipClass="tooltip-xl"
            [container]="'body'"
            [placement]="['top', 'auto']"
            (click)="openConfirmModal()">
  <span [class]="(icon + iconClass)"></span>
  <span *ngIf="showText">
    {{ 'rmm-side-panel:wakeOnLan.btnName' | i18next }}
  </span>
</mbs-button>

<ng-template #dropDownBtn>
  <button ngbDropdownItem
          (click)="openConfirmModal()">
    {{ 'rmm-side-panel:wakeOnLan.btnName' | i18next }}
  </button>
</ng-template>

<ng-template #confirmationModalContent>
  <mbs-alert [type]="AlertType.info"
             [icon]="true">
    {{ 'rmm-side-panel:wakeOnLan.confirmationModalAlert' | i18next }}
  </mbs-alert>
</ng-template>

<ng-template #warningModalContent>
  <mbs-alert [type]="AlertType.warning"
             [icon]="true">
    <span>{{ 'rmm-side-panel:wakeOnLan.warningModalAlert' | i18next }}</span>
    <mbs-button [isCtrl]="true"
                (click)="redirectOnHelp()">
      {{ 'buttons:learnMore' | i18next }}
    </mbs-button>
  </mbs-alert>
  <div>
    {{ 'rmm-side-panel:wakeOnLan.warningModalText' | i18next: { value: computerName } }}
  </div>
</ng-template>
