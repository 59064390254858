import { GroupTask, GroupTaskOsTypes } from '@modules/group-tasks/store/model';
import { createAction, props } from '@ngrx/store';
import { StoreTypes } from '@shared/store/storeTypes.enum';
import { ScheduleMisfireHandling } from '../../components/schedule-action/schedule-action.component';
import {
  GroupActionsCommands,
  LinuxAvailableActions,
  MacAvailableActions,
  ScheduleState,
  SelectedEntities,
  WindowsAvailableActions,
  WizardGroupAction
} from './group-action.model';

export const setGActionName = createAction(`[${StoreTypes.RMMGroupActionWizard}] Set Name`, props<{ name: string }>());
export const setGActionOsType = createAction(`[${StoreTypes.RMMGroupActionWizard}] Set OS`, props<{ osType: GroupTaskOsTypes }>());
export const setGActionSelectedType = createAction(
  `[${StoreTypes.RMMGroupActionWizard}] Set Selected Type from Action`,
  props<{ actionSelectCommand: WindowsAvailableActions | LinuxAvailableActions | MacAvailableActions }>()
);

export const setGActionNameAndOsTypeAndCommandType = createAction(
  `[${StoreTypes.RMMGroupActionWizard}] Set Name, OS and Type from Select Action Step`,
  props<{
    name: string;
    osType: GroupTaskOsTypes;
    actionSelectCommand: WindowsAvailableActions | LinuxAvailableActions | MacAvailableActions;
  }>()
);

export const setGActionApplyTo = createAction(`[${StoreTypes.RMMGroupActionWizard}] Set ApplyTo`, props<{ applyTo: SelectedEntities }>());

export const setGActionCommandType = createAction(
  `[${StoreTypes.RMMGroupActionWizard}] Set Actual Command`,
  props<{ groupActionCommand: GroupActionsCommands }>()
);

export const setGActionParameters = createAction(
  `[${StoreTypes.RMMGroupActionWizard}] Set Parameters for Actual Command`,
  props<{ parameters: Partial<GroupTask> }>()
);

export const setGActionSchedule = createAction(`[${StoreTypes.RMMGroupActionWizard}] Set Schedule`, props<{ schedule: ScheduleState }>());
export const setGActionScheduleMisfireHandling = createAction(
  `[${StoreTypes.RMMGroupActionWizard}] Set Misfire Handling`,
  props<{ misfireHandling: ScheduleMisfireHandling }>()
);
export const resetGActionSchedule = createAction(`[${StoreTypes.RMMGroupActionWizard}] Reset Schedule`);

export const saveGActionChanges = createAction(`[${StoreTypes.RMMGroupActionWizard}] Save Changes to Existing GAction`);
export const saveNewGAction = createAction(`[${StoreTypes.RMMGroupActionWizard}] Create GAction`);

export const successGroupAction = createAction(`[${StoreTypes.RMMGroupActionWizard}] Complete Successfully`);
export const resetGActionWizard = createAction(`[${StoreTypes.RMMGroupActionWizard}] Reset Wizard`);
export const setupGroupTask = createAction(
  `[${StoreTypes.RMMGroupActionWizard}] Set Wizard Group Task`,
  props<{ wizardGroupAction: Partial<WizardGroupAction> }>()
);
