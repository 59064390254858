import { OnboardingView } from '@modules/wizards/onboarding/onboarding.constants';
import { AccountType } from '@modules/wizards/onboarding/steps/apps/sign-in/sign-in.types';
import { AccountId } from '@services/apps/apps.constants';
import { I18NextPipe } from 'angular-i18next';

export const getTilesConfig = (i18N: I18NextPipe) => {
  return [
    {
      id: OnboardingView.Computers,
      title: i18N.transform('onboarding:branch_choice.computers.title'),
      icon: 'assets/images/for-wizards/Computer.svg',
      description: i18N.transform('onboarding:branch_choice.computers.description')
    },
    {
      id: OnboardingView.Apps,
      title: i18N.transform('onboarding:branch_choice.apps.title'),
      icon: 'assets/images/for-wizards/Cloud.svg'
    },
    {
      id: OnboardingView.HyperV,
      title: i18N.transform('onboarding:branch_choice.hyper-v.title'),
      icon: 'assets/images/for-wizards/Hypervisor.svg'
    }
  ];
};

export const getAdministrativeAccountConfig = (i18N: I18NextPipe): AccountType[] => [
  {
    id: AccountId.MicrosoftBusiness,
    text: i18N.transform('onboarding:administrative_account.microsoft_business', { format: 'title' }),
    icon: 'assets/logo_office_365.svg'
  },
  {
    id: AccountId.MicrosoftPersonal,
    text: i18N.transform('onboarding:administrative_account.microsoft_personal', { format: 'title' }),
    icon: 'assets/logo_office_365.svg'
  },
  {
    id: AccountId.GoogleWorkspace,
    text: i18N.transform('onboarding:administrative_account.google_workspace.title', { format: 'title' }),
    icon: 'assets/logo_google.svg'
  }
];
