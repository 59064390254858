<ng-container *ngIf="!tabItem?.isLoading">
  <ng-container *ngIf="isBackupAgentSupportsNewRM || !computerData?.online; else unsupported">
    <ng-container *ngIf="!(storages$ | async).length">
      <div class="text-center">
        <img src="assets/load-image.svg"
             alt="No Backup Destinations Specified for this Computer" />
      </div>
      <div class="text-center text-gray-500 mt-1 mb-3">
        No Backup Destinations specified for this Computer
      </div>
      <div class="text-center">
        <mbs-button (click)="handleRefresh()"
                    type="secondary">Reload Destinations</mbs-button>
      </div>
    </ng-container>

    <ng-container *ngIf="!!(storages$ | async).length">
      <div class="form-row justify-content-end mb-3">
        <div *ngIf="authService?.isMBSMode"
             class="col-auto">
          <mbs-button icon="ico ico-OpenHistory"
                      (click)="openBackupHistory()">
            <span class="text-sm">History</span>
          </mbs-button>
        </div>
        <div class="col-auto">
          <mbs-button (click)="handleRefresh()"
                      icon="ico ico-Refresh">
          </mbs-button>
        </div>
      </div>
    </ng-container>

    <div class="row"
         *ngIf="(storages$ | async)">
      <div class="col-12 py-2"
           [ngClass]="{'col-lg-6 col-xxl-4': fullscreen}"
           *ngFor="let storage of (storages$ | async)">

        <mbs-accordion (panelChange)="panelChangeHandler($event, storage)"
                       toggleButtonClass="overflow-hidden"
                       [activeIds]="storage.ID"
                       xScroll="true">
          <mbs-accordion-panel>
            <ng-container panelTitle>
            <span class="form-row">
              <span class="col-auto">
                <span class="mbs-storage mbs-storage-sm mbs-storage_{{ storage.StorageType | storageIcon }}"></span>
              </span>
              <span class="col overflow-hidden">
                <mbs-text-ellipsis [tooltip]="storage.DisplayName">
                  {{storage.DisplayName}}
                </mbs-text-ellipsis>
              </span>
            </span>
              <span class="form-row text-xs font-weight-base"
                    *ngIf="storage.StorageAccountName">
              <span class="col-5 text-muted">
                Storage Account Name:
              </span>
              <span class="col overflow-hidden">
                <mbs-text-ellipsis [tooltip]="storage.StorageAccountName">
                  {{storage.StorageAccountName}}
                </mbs-text-ellipsis>
              </span>
            </span>
              <span class="form-row text-xs font-weight-base"
                    *ngIf="storage.Bucket">
              <span class="col-5 text-muted">
                Bucket:
              </span>
              <span class="col overflow-hidden">
                <mbs-text-ellipsis [tooltip]="storage.Bucket">
                  {{storage.Bucket}}
                </mbs-text-ellipsis>
              </span>
            </span>
              <span class="form-row text-xs font-weight-base"
                    *ngIf="storage.Region">
              <span class="col-5 text-muted">
                Region:
              </span>
              <span class="col overflow-hidden">
                <mbs-text-ellipsis [tooltip]="storage.Region">
                  {{storage.Region}}
                </mbs-text-ellipsis>
              </span>
            </span>
            </ng-container>

            <div class="d-flex align-items-start justify-content-end pr-3 mt-2"
                 panelAppendHeader>
              <mbs-button *ngIf="storage.StorageType !== storageType.LocalFS"
                          class="_hover-target"
                          icon="fa fa-download ml-2"
                          type="primary"
                          [hideDropdownIcon]="true"
                          [isCtrl]="true">
                <ng-template mbsButtonDropdown
                             [size]="MbsSize.sm">
                  <button (click)="downloadStorageClickHandler(storage)"
                          ngbDropdownItem>
                    Restore to <span class="font-weight-semibold ml-1">Your Computer</span>
                  </button>
                </ng-template>
              </mbs-button>
            </div>

            <mbs-form-group class="mb-0">
              <div *ngIf="storageHashTable[storage.ID]?.isLoading"
                   class="d-block position-relative py-2 w-100">
                <mbs-loader size="xsmall"></mbs-loader>
              </div>
              <div *ngIf="!storageHashTable[storage.ID]?.isLoading && storageHashTable[storage.ID]?.data"
                   class="backup-storage-tree">
                <mbs-tree #subTree="mbsTree"
                          [root]="getRoot(storage.ID)"
                          [disableIfLoadingSubtree]="true"
                          [data]="storageHashTable[storage.ID]?.data || []"
                          [getItems]="getSubtree.bind(this)"
                          [loadMoreNotLazy]="getMore.bind(this)"
                          [loadMore]="getSubtree.bind(this)"
                          [lazy]="true">
                  <ng-template treeElement
                               let-item>
                  <span class="form-row flex-nowrap _hover-parent">
                    <span class="col-auto">
                      <span
                        class="d-flex align-items-center justify-content-center position-relative backup-storage-tree_img">
                        <img [src]="item.iconCustom"
                             class="backup-storage-tree_img-main"
                             alt="Tree main icon">
                        <img *ngIf="item.rightBottomIcon"
                             [src]="item.rightBottomIcon"
                             class="backup-storage-tree_img-bottom"
                             alt="Tree icon right bottom">
                        <img *ngIf="item.leftTopIcon"
                             [src]="item.leftTopIcon"
                             class="backup-storage-tree_img-top"
                             alt="Tree icon left top">
                      </span>
                    </span>
                    <span class="col d-flex text-gray overflow-hidden">
                      <span class="text-overflow h-100 d-flex align-items-center text-sm pr-1"
                            (click)="subTree.loadingSubtree && $event.stopPropagation()">
                        <ng-container
                          *ngIf="item.deepSync === deepSyncEnum.InProgress || item.deepSync === deepSyncEnum.InProgressColdStorage; else labelData">
                          <span class="loader loader-success align-self-center mr-2"></span>
                          DeepSync process {{item.deepSyncProgress}}%
                        </ng-container>
                        <ng-template #labelData>
                          {{ item.label }}
                        </ng-template>
                      </span>
                      <span *ngIf="item.GFSSettings && item.GFSSettings.type !== generationGFSType.NotAssigned"
                            class="mx-1 cursor-help text-info"
                            container="body"
                            placement="left-top"
                            [ngbTooltip]="item.GFSSettings.purgeSummary || 'N/A'">
                        <img [src]="generationGFSTypeIcons[item.GFSSettings.type]"
                             class="backup-storage-tree_img-main mt-n1"
                             alt="Tree icon for gfs settings type - {{ item.GFSSettings.type }}">
                      </span>
                    </span>
                  </span>
                  </ng-template>

                  <ng-template mbsTreeAppend
                               let-item>
                  <span *ngIf="loadingQuickRestore[item.id]"
                        class="loader loader-dark align-self-center"></span>
                    <mbs-button *ngIf="!loadingQuickRestore[item.id] && item?.path && !item?.hideButton && item?.isCanBeQuickRestored"
                                class="_hover-target"
                                icon="fa fa-download"
                                type="primary"
                                [hideDropdownIcon]="true"
                                [isCtrl]="true">
                      <ng-template mbsButtonDropdown
                                   [size]="MbsSize.sm">
                        <button (click)="downloadToSelectedComputer(item)"
                                ngbDropdownItem>
                          Restore to <span class="font-weight-semibold ml-1">{{computerData.name}}</span>
                        </button>
                        <button (click)="downloadClickHandler(item)"
                                ngbDropdownItem>
                          Restore to <span class="font-weight-semibold ml-1">Your Computer</span>
                        </button>
                      </ng-template>
                    </mbs-button>
                  </ng-template>



                  <ng-template treeLoadMore
                               let-item>
                    <ng-container *ngIf="getOtherComputers(storage.ID) as otherComputersData">
                      <mbs-button (click)="subTree.handleClickLoadMore(item.node?.parent, $event, item.node?.itemType === itemTypeEnum.Prefix)"
                                  [isCtrl]="true"
                                  type="primary">
                      <span *ngIf="item.node?.itemType === itemTypeEnum.Prefix; else defaultLoadMore"
                            class="row text-sm">
                        <span class="col text-left">
                          {{ otherComputersData.loadMoreAppendText || 'Load more...' }}
                        </span>
                      </span>

                        <ng-template #defaultLoadMore>
                        <span class="d-flex align-items-center">
                          <span *ngIf="subTree.loadingSubtree"
                                class="loader mr-2"></span>
                          <span *ngIf="!subTree.loadingSubtree"
                                class="ico ico-Refresh mr-2"></span>
                          Load more...
                        </span>
                        </ng-template>
                      </mbs-button>
                    </ng-container>
                  </ng-template>
                </mbs-tree>
              </div>
              <ng-container *ngIf="!storageHashTable[storage.ID]?.isLoading && !storageHashTable[storage.ID]?.data?.length">
                <ng-container *ngIf="getOtherComputers(storage.ID) as otherComputersData">
                  <div *ngIf="!otherComputersData.loadMoreText"
                       class="text-center text-gray-500 my-3">
                    No backups to display
                  </div>
                  <div *ngIf="otherComputersData.loadMoreText">
                  <span class="row text-sm">
                    <span class="col text-left text-muted">
                      {{ otherComputersData.loadMoreText }}
                    </span>
                  </span>
                    <mbs-button (click)="loadMore(storage.ID)"
                                [isCtrl]="true"
                                type="primary">
                    <span class="row text-sm">
                      <span class="col text-left">
                        {{ otherComputersData.loadMoreAppendText }}
                      </span>
                    </span>
                    </mbs-button>
                  </div>
                </ng-container>
              </ng-container>
            </mbs-form-group>
          </mbs-accordion-panel>
        </mbs-accordion>
      </div>
    </div>
  </ng-container>

  <ng-template #unsupported>
    <mbs-alert [type]="mbsPopupType.danger" [icon]="true">
      {{ 'sidepanel-backup:backupStorageTab.unsupportedAlertText' | i18next }}
    </mbs-alert>
  </ng-template>
</ng-container>

<ng-template #bitLockerPasswordModal>
  <p>
    Provide one of the following key protectors to unlock the Bitlocker volume
  </p>
  <form class="mbs-form-group"
       [formGroup]="bitlockerPasswordForm">
    <mbs-radio label="Use the password"
               value="1"
               [checked]="true"
               formControlName="passwordType"
               [id]="'bitlocker-password-radio1'"
               class="mb-2"
               (change)="changeBitLockerPasswordType()"></mbs-radio>
    <mbs-input [id]="'encryptionPassword'"
               name="encryptionPassword"
               [autocomplete]="'off'"
               [type]="encryptionPasswordType ? 'text' : 'password'"
               formControlName="password"
               (buttonClick)="encryptionPasswordType = !encryptionPasswordType"
               [appendButtons]="[
                   {
                     icon: encryptionPasswordType ? 'fa fa-eye-slash' : 'fa fa-eye',
                     id: 'changeTypeEncryptionPassword',
                     disabled: false,
                     type: 'secondary'
                   }
                 ]">
    </mbs-input>
    <mbs-radio label="Use the recovery password"
               value="2"
               formControlName="passwordType"
               [id]="'bitlocker-password-radio2'"
               class="mt-2 mb-2"
               (change)="changeBitLockerPasswordType()"></mbs-radio>
    <mbs-input [id]="'recoveryPassword'"
               name="recoveryPassword"
               [autocomplete]="'off'"
               [type]="recoveryPasswordInputType ? 'text' : 'password'"
               formControlName="recoveryPassword"
               (buttonClick)="recoveryPasswordInputType = !recoveryPasswordInputType"
               [appendButtons]="[
                   {
                     icon: recoveryPasswordInputType ? 'fa fa-eye-slash' : 'fa fa-eye',
                     id: 'changeTypeRecoveryPassword',
                     disabled: false,
                     type: 'secondary'
                   }
                 ]">
    </mbs-input>
    <mbs-alert *ngIf="!isBitLockedFileSupported"
               [type]="AlertType.warning"
               [icon]="true"
               class="mt-4 mb-1">
      {{ this.bitlockerFileNotAvailable }}
    </mbs-alert>
    <mbs-radio label="Use the key file"
               value="3"
               formControlName="passwordType"
               [id]="'bitlocker-password-radio3'"
               class="mt-2 mb-2"
               [disabled]="!isBitLockedFileSupported"
               (change)="changeBitLockerPasswordType()"></mbs-radio>
    <mbs-input [type]="'file'"
               [clearButton]="true"
               [placeholder]="bitLockerFileName ? bitLockerFileName : 'Choose file'"
               [filePlaceholderOverflow]="true"
               [trim]="false"
               [multiple]="false"
               formControlName="keyFile"
               [disabled]="!isBitLockedFileSupported"
               (change)="onChangeBLPassFile($event)"></mbs-input>
  </form>
</ng-template>
