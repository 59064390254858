import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { OsType } from '@models/Computer';
import RmmCommand from '@models/rmm/RmmCommand';
import ServiceInfo from '@models/rmm/ServiceInfo';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TFARMMPermissionHelperService } from '@services';
import { RmmService } from '@services/rmm.service';
import { baseDateFileNameFormatHoursAndMinutes } from '@utils/date';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash/fp';
import { ModalService, ModalSettings, TableHeader, TabsetItemDirective, ToastService } from 'mbs-ui-kit';
import moment from 'moment';
import { forkJoin, noop, Observable, of, Subject, throwError } from 'rxjs';
import { concatMap, delay, finalize, map, switchMap } from 'rxjs/operators';
import { RmmTabBaseComponent } from '../rmm-tab-base.component';

type ServiceOperation = 'start' | 'stop' | 'restart';

function mapToastsMessage(operation, isWindows = true) {
  return map((serviceNames: string[]) => {
    if (serviceNames.length > 5) {
      const userOperation = operation === 'stop' ? 'are stopped' : `were ${operation}ed`;
      const message = `Selected ${this.isWindows ? 'service' : 'daemon'}s ${userOperation}`;
      return [message];
    } else {
      const userOperation = operation === 'stop' ? 'stopped' : `${operation}ed`;
      return serviceNames.map((serviceName) => `The "${serviceName}" ${isWindows ? 'service' : 'daemon'} was ${userOperation}`);
    }
  });
}

const createAsyncId = (hid) => {
  return `ServiceInfo:${hid}`;
};
@UntilDestroy()
@Component({
  selector: 'mbs-services-tab',
  templateUrl: './services-tab.component.html'
})
export class ServicesTabComponent extends RmmTabBaseComponent<ServiceInfo> implements OnInit {
  public emitToasts$: Subject<{
    operation: ServiceOperation;
    commands: Observable<string>[];
  }> = new Subject();

  public translationKey = 'rmm-side-panel:servicesTab.';
  public shortHeaders: TableHeader[] = [
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.name'),
      overflow: true,
      sort: 'name',
      gridColSize: '40fr'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.startMode'),
      overflow: true,
      sort: 'startMode',
      gridColSize: '30fr'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.state'),
      overflow: true,
      sort: 'state',
      gridColSize: '25fr'
    }
  ];

  public fullHeaders: TableHeader[] = [
    this.shortHeaders[0], // name
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.displayName'),
      overflow: true,
      sort: 'displayName',
      gridColSize: '50fr'
    },
    ...this.shortHeaders.slice(1),
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.pid'),
      sort: 'processId',
      gridColSize: '18fr'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.exitCode'),
      sort: 'exitCode',
      gridColSize: '15fr'
    }
  ];

  @Input() computerName: string;
  @Input() readonly: boolean;

  private commandMap = {
    start: 'Start',
    stop: 'Stop',
    restart: 'Restart'
  };

  public isMacOS: boolean;
  public isWindows: boolean;
  public isLinux: boolean;

  public get disabledStart(): boolean {
    return this.selected.length === 0 || this.selected.every((s) => s.state === 'Running');
  }

  public get disabledStop(): boolean {
    if (this.isLinux && this.selected.every((s) => s.state !== 'Running')) return true;
    return this.selected.length === 0 || this.selected.every((s) => s.state === 'Stopped');
  }

  public get disabledRestart(): boolean {
    if (this.isLinux && this.selected.every((s) => s.state !== 'Running')) return true;
    return this.selected.length === 0 || this.selected.every((s) => s.state === 'Stopped');
  }

  constructor(
    public rmmService: RmmService,
    private tabItem: TabsetItemDirective,
    private commandService: CommandService,
    private modal: ModalService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private computersFacade: ComputersFacade,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService,
    private i18n: I18NextPipe
  ) {
    super();
  }

  initStreams() {
    this.initCommandPipe();

    this.onFetchLastData$
      .pipe(
        map((data) => (data?.length ? data : data.data)),
        untilDestroyed(this)
      )
      .subscribe((stat: any[] = []) => {
        if (stat) {
          this.allData = cloneDeep(stat);
          this.updateData(this.allData);
        }
        this.handleSubTab(this.tabItem);
      });
  }

  initCommandPipe(): void {
    const toast$ = this.emitToasts$.pipe(
      concatMap(({ operation, commands }) =>
        forkJoin(commands).pipe(
          mapToastsMessage(operation, this.isWindows),
          concatMap((messages) =>
            of(...messages).pipe(
              delay(300),
              finalize(() => {
                this.resetSelected.bind(this);
                this.fetchData();
              })
            )
          )
        )
      )
    );

    toast$.pipe(untilDestroyed(this)).subscribe((message) => this.toastService.success(message));
  }

  ngOnInit(): void {
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (computer && computer.os) {
        this.isMacOS = computer.os === OsType.apple;
        this.isWindows = computer.os === OsType.windows;
        this.isLinux = computer.os === OsType.linux;
      }
      this.headers = this.isModal
        ? this.getTableHeadersWithOSDependency(this.fullHeaders)
        : this.getTableHeadersWithOSDependency(this.shortHeaders);
    });

    this.searchFields = ['name'];
    this.handleSubTab(this.tabItem, true);
    this.initStreams();

    this.fetchData();
  }

  private getTableHeadersWithOSDependency(tableHeaders: TableHeader[]): TableHeader[] {
    if (!this.isMacOS) return tableHeaders;

    const servicesColumnNewName = this.i18n.transform(this.translationKey + 'daemons');
    const displayNameColumnNewName = 'Path';
    const columnListToRemove = [
      this.i18n.transform(this.translationKey + 'tableHeaders.startMode'),
      this.i18n.transform(this.translationKey + 'tableHeaders.exitCode')
    ];
    const indexesToDelete = [];
    const tempTable = tableHeaders.map((element, index) => {
      switch (element.name) {
        case columnListToRemove[0]:
        case columnListToRemove[1]:
          indexesToDelete.push(index);
          return element;
        case this.i18n.transform(this.translationKey + 'services'):
          element.name = servicesColumnNewName;
          return element;
        case this.i18n.transform(this.translationKey + 'tableHeaders.displayName'):
          element.name = displayNameColumnNewName;
          return element;
        default:
          return element;
      }
    });
    indexesToDelete.forEach((element, index) => tempTable.splice(element - index, 1));
    return tempTable;
  }

  fetchData(): void {
    this.rmmService
      .fetchLast<ServiceInfo>('service', this.hid)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.onFetchLastData$.next(res.data);
      });
  }

  itemsChecked(items: ServiceInfo[]): void {
    this.handleChangeSelected(items);
    this.cdr.detectChanges();
  }

  resetSelected() {
    this.selected = [];
    // fetch
  }

  handleToggleService(operation: 'start' | 'stop' | 'restart'): void {
    const commandStr = new TitleCasePipe().transform(operation);

    let selectedServiceStr: string;
    if (this.selected.length === 1) {
      selectedServiceStr = `${
        this.isWindows ? this.i18n.transform(this.translationKey + 'service') : this.i18n.transform(this.translationKey + 'daemon')
      }: <span class="font-weight-semibold">${this.selected[0].displayName}</span>`;
    } else if (this.selected.length > 1) {
      selectedServiceStr = `${this.i18n.transform(this.translationKey + 'following')} ${
        this.isWindows ? this.i18n.transform(this.translationKey + 'service') : this.i18n.transform(this.translationKey + 'daemon')
      }s: <span class="font-weight-semibold">${this.selected.map((item) => item.displayName).join(', ')}</span>`;
    }
    const confirmMessage = `${commandStr} the ${selectedServiceStr}?`;

    const modalSetting: ModalSettings = {
      header: {
        title:
          commandStr +
          ` ${
            this.isWindows
              ? this.i18n.transform(this.translationKey + 'service', { format: 'title' })
              : this.i18n.transform(this.translationKey + 'daemon', { format: 'title' })
          } on ` +
          this.computerName,
        textOverflow: true
      },
      footer: { okButton: { text: commandStr } }
    };

    if (operation === 'stop') {
      modalSetting.footer.okButton.type = 'danger';
    }

    this.modal
      .open(modalSetting, confirmMessage)
      .then(() => {
        const commands = this.selected.map((service) => {
          const command = new RmmCommand(this.commandMap[operation]).addParam('SERVICENAME', service.name);
          return this.commandService.sendCommand('ServiceCmd', command, this.hid, true).pipe(
            switchMap((response: any) => (response?.error?.code ? throwError(() => response) : of(response))),
            map((value) => service.name)
          );
        });
        this.emitToasts$.next({ operation, commands });
      })
      .catch(noop);
  }

  trackBy(index: number, item: any): string {
    return item.name;
  }
  public getTableName(): string {
    return `${this.computerName} ${this.i18n.transform(
      'rmm-side-panel:sidePanelTabsName.' +
        (this.isLinux || this.isMacOS
          ? this.i18n.transform(this.translationKey + 'daemons').toLowerCase()
          : this.i18n.transform(this.translationKey + 'services')
        ).toLowerCase()
    )} ${moment().format(baseDateFileNameFormatHoursAndMinutes)}`;
  }
}
