<section>
  <header class="mb-3 text-center">
    <h4 class="mb-0">
      {{ 'wizards:rmmConnectGreetingStep:title' | i18next: { format: 'title' } }}
      <br>
      {{ 'wizards:rmmConnectGreetingStep:titleRmm' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <p class="text-center mb-3">
    {{ 'wizards:rmmConnectGreetingStep:prosTitle' | i18next }}
  </p>

  <div class="row justify-content-center">
    <div class="col-7">
      <ul class="list-unstyled mb-0">
        <li class="mb-2">
          <span class="fa fa-check-circle mr-2 text-primary"></span>
          {{ 'wizards:rmmConnectGreetingStep:prosText1' | i18next }}
        </li>
        <li class="mb-2">
          <span class="fa fa-check-circle mr-2 text-primary"></span>
          {{ 'wizards:rmmConnectGreetingStep:prosText2' | i18next }}
        </li>
        <li class="mb-2">
          <span class="fa fa-check-circle mr-2 text-primary"></span>
          {{ 'wizards:rmmConnectGreetingStep:prosText3' | i18next }}
        </li>
        <li class="mb-2">
          <span class="fa fa-check-circle mr-2 text-primary"></span>
          {{ 'wizards:rmmConnectGreetingStep:prosText4' | i18next }}
        </li>
        <li class="mb-2">
          <span class="fa fa-check-circle mr-2 text-primary"></span>
          {{ 'wizards:rmmConnectGreetingStep:prosText5' | i18next }}
        </li>
      </ul>

      <p class="text-sm mb-0">
        <em>
          {{ 'wizards:rmmConnectGreetingStep:trialInfoPart1' | i18next }}
        </em>
        <br>
        <em>
          <span class="font-weight-semibold">
            {{ 'app:products.rmm' | i18next }} + {{ 'app:products.remote_desktop' | i18next }}
          </span>

          {{ 'wizards:rmmConnectGreetingStep:trialInfoPart2' | i18next }}

          <span class="font-weight-semibold">
            {{ 'app:products.remote_desktop' | i18next }}
          </span>
        </em>
        <em>
          {{ 'wizards:rmmConnectGreetingStep:trialInfoPart3' | i18next }}
        </em>
      </p>
    </div>
  </div>
</section>
