import { ScriptLibraryEntry, ScriptLibraryTag } from './store/script-library.model';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { selectAll, selectExistsTags } from './store/script-library.selectors';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Template } from 'mbs-ui-kit';

@Injectable({ providedIn: 'root' })
export class ScriptLibrarySearchTemplates {
  private readonly scripts$: Observable<ScriptLibraryEntry[]>;
  private readonly categories$: Observable<ScriptLibraryTag[]>;

  constructor(private store: Store) {
    this.scripts$ = this.store.select(selectAll);
    this.categories$ = this.store.select(selectExistsTags);
  }

  public readonly category: Template<any> = {
    tag: 'category',
    items: (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => {
          return this.categories$.pipe(
            map((array: ScriptLibraryTag[]) => array.map(item => item.name).filter(item => ~item.toLowerCase().indexOf(term.toLowerCase())))
          );
        })
      ),
    itemFormatter: (value: string) => {
      return `{${value}}`;
    }
  };

  public readonly script: Template<any> = {
    tag: 'script',
    items: (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => {
          return this.scripts$.pipe(
            map((array: ScriptLibraryEntry[]) =>
              array.map(item => item.name).filter(item => ~item.toLowerCase().indexOf(term.toLowerCase()))
            )
          );
        })
      ),
    itemFormatter: (value: string) => {
      return `{${value}}`;
    }
  };
}
