import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreTypes } from '@shared/store/storeTypes.enum';
import { WizardGroupAction } from './group-action.model';

export const selectGroupActionState = createFeatureSelector<WizardGroupAction>(StoreTypes.RMMGroupActionWizard);

export const selectGAction = createSelector(selectGroupActionState, (state) => state);

export const selectGActionName = createSelector(selectGroupActionState, (state) => state.name);
export const selectGActionOsType = createSelector(selectGroupActionState, (state) => state.osType);
export const selectGActionSelectedType = createSelector(selectGroupActionState, (state) => state.actionSelectCommand);

export const selectGActionNameAndOsTypeAndSelectType = createSelector(selectGroupActionState, (state) => ({
  name: state.name,
  osType: state.osType,
  actionSelectCommand: state.actionSelectCommand
}));

export const selectGActionCommandType  = createSelector(selectGroupActionState, (state) => state.groupActionCommand);
export const selectGActionParameters = createSelector(selectGroupActionState, (state) => state.parameters);

export const selectGActionApplyTo = createSelector(selectGroupActionState, (state) => state.applyTo);
export const selectGActionSchedule = createSelector(selectGroupActionState, (state) => state.schedule);
