import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { mediumDateWithTime } from '@utils/date';
import { merge } from 'lodash';
import { ModalComponent } from 'mbs-ui-kit';
import { fromEvent } from 'rxjs';
import { headerCheckEventData, onClickEventData, SelectionModalModeSelection, SelectionModalSettings } from './selection-modal-models';

export const DEFAULT_SELECTION_MODAL_SETTINGS = {
  modalTitle: null,
  noDataText: null,
  saveButtonText: null,
  closeButtonText: null,
  entityNaming: null,
  entitiesNaming: null,
  mode: 'accordeonList',
  entities: [],
  entityValueToBeShownInTheList: null,
  textAreaInputName: null,
  selected: [],
  selectionKey: null
};

@UntilDestroy()
@Component({
  selector: 'mbs-selection-modal',
  templateUrl: './selection-modal.component.html'
})
export class SelectionModalComponent implements OnInit, AfterViewInit {
  public readonly mediumDateWithTime = mediumDateWithTime;
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  form: UntypedFormGroup;

  public showModalFooterText = false;

  public modalTitle: string;
  public noDataText: string;
  public saveButtonText: string;
  public closeButtonText: string;
  public entityNaming: string;
  public entitiesNaming: string;
  public mode: SelectionModalModeSelection = 'accordeonList';

  public entities: any[];
  public entityValueToBeShownInTheList = 'name';
  public selectedEntities: any[];
  public selectionKey: string;
  public inputListInfo: string;

  public textInputData: string;
  public textAreaInputName: string;

  constructor(private fb: FormBuilder, private el: ElementRef) {}

  ngOnInit(): void {
    const newData: SelectionModalSettings = Object.assign({}, DEFAULT_SELECTION_MODAL_SETTINGS, this.baseModal.data);

    this.modalTitle = newData.modalTitle;
    this.noDataText = newData.noDataText;
    this.saveButtonText = newData.saveButtonText;
    this.closeButtonText = newData.closeButtonText;
    this.mode = newData.mode;

    this.entityNaming = newData.entityNaming;
    this.entitiesNaming = newData.entitiesNaming;

    this.entityValueToBeShownInTheList = newData.entityValueToBeShownInTheList;
    this.selectionKey = newData.selectionKey;
    this.inputListInfo = newData.inputListInfo;

    if (newData.mode === 'accordeonList') {
      this.parseEntitiesForAccordeon(newData.entities, newData.selectedEntities);
    } else if (newData.mode === 'checkboxList') {
      this.entities = [...newData.entities];
      this.selectedEntities = [...newData.selectedEntities];
    } else {
      this.textAreaInputName = newData.textAreaInputName;
      this.textInputData = '';
      newData.entities.forEach((text) => (this.textInputData += `${text},\r\n`));
      this.textInputData = this.textInputData.substring(0, this.textInputData.length - 3);
    }
  }

  parseEntitiesForAccordeon(entities: any[], selectedEntities: any[]): void {
    this.entities = entities.map((entity) => ({
      data: entity,
      checked: selectedEntities?.length ? this.isEntitySelected(entity, selectedEntities) : false,
      disabled: false
    }));

    this.selectedEntities = selectedEntities?.map((entity) => entity);
  }

  isEntitySelected(entity: any, selectedEntities: any[]): boolean {
    if (!this.selectionKey) return !!selectedEntities.find((selected) => entity === selected);
    return !!selectedEntities.find((selected) => entity[this.selectionKey] === selected[this.selectionKey]);
  }

  ngAfterViewInit(): void {
    /* @TODO: set config for backspace\enter keys behavior for WizardComponent. now used dirty hack! */
    merge(
      fromEvent(this.el.nativeElement, 'keydown'),
      fromEvent(this.el.nativeElement, 'keypress'),
      fromEvent(this.el.nativeElement, 'keyup')
    )
      .pipe(untilDestroyed(this))
      .subscribe((event: KeyboardEvent) => event.stopPropagation());
  }

  checkChanged(event: onClickEventData) {
    const updatedElement = event.row;
    const newEntities = this.entities.map((entity) => {
      if (this.selectionKey && entity?.data[this.selectionKey] === updatedElement?.data[this.selectionKey]) return updatedElement;
      if (!this.selectionKey && entity?.data === updatedElement?.data) return updatedElement;
      return entity;
    });
    this.entities = [...newEntities];
  }

  headerCheckChanged(event: headerCheckEventData) {
    const newData = this.entities.map((entity) => ({ ...entity, checked: event.event }));
    this.entities = [...newData];
  }

  /*
   * This method is not required for the current implementation
   */
  handleTopChange(event: Event) {}

  /*
   * This method is not required for the current implementation
   */
  handleSelectedCountAll(event: Event) {}

  close(): void {
    this.baseModal.close();
  }

  save(): void {
    if (this.mode === 'inputList') {
      // const regExpSplitter = '/,| /.|\r\n'
      this.entities = this.textInputData
        .replaceAll('\r\n', ',')
        .replaceAll('\n', ',')
        .replaceAll('.', ',')
        .split(',')
        .flatMap((value) => (value.trim() === '' ? [] : value.trim()));
      this.baseModal.save(this.entities);
    } else {
      this.baseModal.save(this.entities.flatMap((entity) => (entity.checked ? entity.data : [])));
    }
  }
}
