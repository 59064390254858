import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';

@Component({
  templateUrl: './confirm-redirect-to-apps-as-provider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmRedirectToAppsAsProviderComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public readonly MbsPopupType = MbsPopupType;
  public readonly domain$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() {}

  ngOnInit(): void {
    const { domain } = this.baseModal.data as { domain: string };
    this.domain$.next(domain);
  }

  confirmClickHandler(): void {
    this.baseModal.save(true);
  }

  cancelClickHandler(): void {
    this.baseModal.close();
  }
}
