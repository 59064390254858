<div *ngFor="let disk of disks"
     class="mb-4 pb-3">
  <div class="row flex-nowrap align-items-center mb-3">
    <div class="col-auto">
      <span class="RMM-HDD_ico fa fa-hdd-o"></span>
    </div>
    <div class="col text-overflow">
      <h6 class="mb-0 text-overflow">
        {{disk.basic.model}}
      </h6>
      <div class="text-xs text-overflow">
        {{disk.basic.serial}}
      </div>
    </div>
    <div class="col-auto"
         *ngIf="disk.hasSmartInfo && !isMacOS">
      <div class="form-row justify-content-end align-items-center">
        <div class="col-auto">
          <span class="mbs-status">
            <span class="ico mbs-status_ico -md"
                  [ngbTooltip]="disk.status"
                  [ngClass]="disk.icon"></span>
            {{ 'rmm-side-panel:hddTab.smart' | i18next }}
          </span>
        </div>
        <div class="col-auto">
          <ng-template #hddSmartModal>
            <mbs-hdd-smart-tab [smartDisk]="disk.smart"></mbs-hdd-smart-tab>
          </ng-template>
          <mbs-button [isCtrl]="true"
                      type="primary"
                      [disabled]="!disk.smart"
                      icon="ico ico-external-link-square"
                      (click)="handleShowSMART(hddSmartModal, disk)"></mbs-button>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngFor="let vol of disk.driveLetters">
    <mbs-hdd-volume-info [hddVolumeInfo]="vol">
    </mbs-hdd-volume-info>
  </ng-container>
</div>


<div *ngIf="!disks || disks.length === 0">
  <div class="text-center text-sm">
    {{ 'rmm-side-panel:noDataToDisplay' | i18next }}
  </div>
</div>
