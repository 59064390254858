import { Injectable } from '@angular/core';
import { Build, BuildsInfo } from '@models/build';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BuildService } from '@services/build-service/build.service';
import { ShowUpdatesAlertType } from '@store/builds/builds.types';
import { filter, forkJoin, map, mergeMap, Observable, of } from 'rxjs';
import { SettingsType } from '@store/builds/builds.types';
import { AbilityService } from 'ability';
import { concatMap, switchMap } from 'rxjs/operators';
import * as BuildsActions from './actions';
import * as BuildsSelectors from './selectors';
import { UiStorageService } from '@services/ui-storage.service';
import { UiStorageKey } from '@models/ui-storage';
import { isNil } from 'lodash';

@Injectable()
export class BuildsEffects {
  constructor(
    private actions$: Actions,
    private buildService: BuildService,
    private store: Store,
    private ability: AbilityService,
    private uiStorage: UiStorageService
  ) {
  }

  initiateBuilds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildsActions.loadInitialBuilds),
      concatLatestFrom(() => this.store.select(BuildsSelectors.selectBuilds)),
      filter(([, builds]) => !builds),
      switchMap(() => {
        return this.getInitialBuilds().pipe(
          concatMap(({ builds, settings, updates }) => {
            return of(
              BuildsActions.setBuilds({ builds: this.sortBuilds(builds) }),
              BuildsActions.updateSettings({ settings }),
              BuildsActions.updateShowNewVersionsAlertParams(updates)
            );
          })
        );
      })
    );
  });

  initiateBuildsInProgress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildsActions.loadInitialBuildsInProgress),
      switchMap(() => {
        return this.buildService.getActiveBuildRequest().pipe(
          map((builds) => {
            const buildTypes = builds.map(({ requestedBuild }) => requestedBuild);
            return BuildsActions.setBuildsInProgress({ buildTypes });
          })
        );
      })
    );
  });

  updateBuilds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuildsActions.updateBuilds),
      concatLatestFrom(() => this.store.select(BuildsSelectors.selectBuilds)),
      switchMap(([, storeBuilds]) => {
        return forkJoin([this.buildService.getAll(), this.buildService.getActiveBuildRequest()]).pipe(
          concatMap(([buildsInfo, buildsInProgress]) => {
            const settings = this.extractSettings(buildsInfo);
            const mergedBuilds = this.getUpdatedBuildList(storeBuilds, buildsInfo.builds);
            const buildTypes = buildsInProgress.map(({ requestedBuild }) => requestedBuild);
            const {
              backupVersions,
              rmmVersions
            } = this.buildService.getNewVersions(mergedBuilds, settings.isSandBoxAvailable);
            const backupVersionsHashCode = this.buildService.generateHashCode(backupVersions);
            const rmmVersionsHashCode = this.buildService.generateHashCode(rmmVersions);

            return forkJoin([this.uiStorage.storeSetting(UiStorageKey.BackupVersionsHashCode, backupVersionsHashCode),
              this.uiStorage.storeSetting(UiStorageKey.RmmVersionsHashCode, rmmVersionsHashCode)])
              .pipe(
                switchMap(() => {
                  return of(
                    BuildsActions.setBuilds({ builds: mergedBuilds }),
                    BuildsActions.setBuildsInProgress({ buildTypes }),
                    BuildsActions.updateSettings({ settings })
                  );
                })
              );
          })
        );
      })
    );
  });

  private getHasBuildsUpdate(builds: Build[], isSandBoxAvailable: boolean): Observable<ShowUpdatesAlertType> {
    const { backupVersions, rmmVersions } = this.buildService.getNewVersions(builds, isSandBoxAvailable);
    const backupVersionsHashCode = this.buildService.generateHashCode(backupVersions);
    const rmmVersionsHashCode = this.buildService.generateHashCode(rmmVersions);

    return this.uiStorage.getSettings().pipe(map((storageSettings) => {
      if (isNil(storageSettings[UiStorageKey.BackupVersionsHashCode]) && isNil(storageSettings[UiStorageKey.RmmVersionsHashCode])) {

        return {
          showRmmUpdateDot: !!rmmVersions?.length,
          showBackupUpdateDot: !!backupVersions?.length
        };
      }

      return {
        showBackupUpdateDot: backupVersionsHashCode !== storageSettings?.[UiStorageKey.BackupVersionsHashCode],
        showRmmUpdateDot: rmmVersionsHashCode !== storageSettings?.[UiStorageKey.RmmVersionsHashCode]
      };
    }));
  }

  private getInitialBuilds(): Observable<{
    builds: Build[],
    settings: SettingsType,
    updates: ShowUpdatesAlertType
  }> {
    return forkJoin([this.buildService.getDownloadInfo(), this.buildService.getAll()]).pipe(
      map(([availableBuilds, exitingBuildsInfo]): [Build[], SettingsType] => {
        const settings = this.extractSettings(exitingBuildsInfo);

        if (!availableBuilds) return [exitingBuildsInfo.builds, settings];

        const updatedBuildList = this.ability.can('read', 'Rebranding')
          ? this.getUpdatedBuildList(availableBuilds.builds, exitingBuildsInfo.builds)
          : this.getUpdatedBuildList(availableBuilds.builds, exitingBuildsInfo.builds).filter(this.filterByExistingBuildsPredicate);

        return [updatedBuildList, settings];
      }),
      mergeMap(([builds, settings]) => {
        return this.getHasBuildsUpdate(builds, settings.isSandBoxAvailable)
          .pipe(
            map((updates) => ({
              builds,
              settings,
              updates
            }))
          );
      })
    );
  }

  private getUpdatedBuildList(builds: Build[], update: Build[]): Build[] {
    return builds.map((available: Build) => {
      const newBuild = update.find((item) => item.mbsBuildType === available.mbsBuildType);

      return newBuild ? newBuild : { ...available, public: null, sandbox: null };
    });
  }

  private filterByExistingBuildsPredicate(build: Build): boolean {
    return !!build.public || !!build.sandbox;
  }

  private sortBuilds(builds: Build[]): Build[] {
    return builds.sort((buildA, buildB) => {
      if (Number(this.isBuildGenerated(buildA)) === Number(this.isBuildGenerated(buildB))) return 0;

      return Number(this.isBuildGenerated(buildA)) > Number(this.isBuildGenerated(buildB)) ? -1 : 1;
    });
  }

  private isBuildGenerated(build: Build): boolean {
    return !!(build.public || build.sandbox);
  }

  private extractSettings(buildInfo: BuildsInfo): SettingsType {
    return {
      isAutoUpdateEnabled: buildInfo.autoUpdateEnabled,
      isSandBoxAvailable: buildInfo.sandBoxAvailable,
      isSettingsButtonVisible: buildInfo.settingsButtonVisible
    };
  }
}
