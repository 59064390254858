import { Injectable } from '@angular/core';
import AdministratorInCamelCase from '@models/AdministratorInCamelCase';
import { Store } from '@ngrx/store';
import { PagingStoreFacade } from '@store/pagingStoreFactory/pagingStore.facade';
import { Observable, tap } from 'rxjs';
import { AdministratorService } from '../services/administrator.service';
import { AdministratorsStore } from '../store/administrators/administratorsStore';

@Injectable({
  providedIn: 'root'
})
export class AdministratorsFacade extends PagingStoreFacade<AdministratorInCamelCase> {
  private readonly administratorNameField = 'email';

  constructor(public store: Store, public administratorsStore: AdministratorsStore, private administratorService: AdministratorService) {
    super(store, administratorsStore);
  }

  create(admin: AdministratorInCamelCase): Observable<AdministratorInCamelCase> {
    return this.administratorService.createAdministrator(admin).pipe(tap(() => this.invalidateEntities()));
  }

  update(admin: AdministratorInCamelCase): Observable<AdministratorInCamelCase> {
    return this.administratorService.updateAdministrator(admin).pipe(tap((admin) => this.invalidateEntities(admin)));
  }

  delete(admin: AdministratorInCamelCase): Observable<null> {
    return this.administratorService.deleteAdministrator(admin?.id).pipe(tap(() => this.invalidateEntities()));
  }

  private invalidateEntities(admin?: AdministratorInCamelCase): void {
    this.refreshAfterCRUD(admin, this.administratorNameField);
  }
}
