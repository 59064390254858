import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComputersLegacyComponent } from '@components/computers-legacy/computers-legacy.component';
import { ForgotPasswordComponent } from '@components/forgot-password/forgot-password.component';
import { HelpComponent } from '@components/help/help.component';
import { LoginComponent } from '@components/login/login.component';
import { MarketingIframeComponent } from '@components/marketing-iframe/marketing-iframe.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { SidepanelComputersWrapperComponent } from '@components/sidepanel-computers-wrapper/sidepanel-computers-wrapper.component';
import { SignupComponent } from '@components/signup/signup.component';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { EmptyPageComponent } from '@modules/empty-page/empty-page.component';
import { AppLayoutComponent } from '@shared/_layout/app-layout/app-layout.component';
import { NotFoundWrapperComponent } from '@shared/components/not-found-wrapper/not-found-wrapper.component';
import { CurrentUserGuard } from '@shared/guards/currentUser.guard';
import { DataChangeWatcherGuard } from '@shared/guards/data-change-watcher.guard';
import { LicensesGuard } from '@shared/guards/licenses.guard';
import { PermissionsGuard } from '@shared/guards/permissions.guard';
import { StatisticsGuard } from '@shared/guards/statistics.guard';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';

export const appsDomainsModule = 'backup-statistics.module';
export const clientSettingsModule = 'client-settings';
export const rmmTranslateModule = 'rmm.module';
export const rmmSidePanelModule = 'rmm-side-panel';
export const rmmGroupTaskModule = 'rmm-group-task.module';
export const notificationModule = 'notifications-module';
export const eventTotalModule = 'event-total.module';
export const customAlertingModule = 'rmm-custom-alerting.module';
export const computersModule = 'computers.module';
export const sidePanelPreInstallSettings = 'sidepanel-preinstall-settings';
export const passRecoveryModule = 'pass-recovery';
const cloudRestoreModule = 'cloud-restore';
const tagsModule = 'tags';

// export const iframeBlankLayoutRoutes: Routes = [
//   {
//     path: 'AP',
//     component: SimpleLayoutComponent,
//     canActivate: [CurrentUserGuard],
//     canActivateChild: [
//       DataChangeWatcherGuard,
//       PermissionsGuard,
//       CurrentUserGuard // Check finish account init steps
//     ],
//     children: [{ path: 'GettingStart.aspx', component: EmptyPageComponent }]
//   }
// ];

export const iframePagesWithStandardLayout: Routes = [
  { path: 'ADIntegration.aspx', component: EmptyPageComponent },
  { path: 'AddAdmin.aspx', component: EmptyPageComponent },
  { path: 'AddUser.aspx', component: EmptyPageComponent },
  {
    path: 'AdminProfile.aspx',
    component: EmptyPageComponent,
    data: {
      permissions: ['SubAdmin']
    }
  },
  { path: 'Administrators.aspx', component: EmptyPageComponent },
  { path: 'AuditLog.aspx', component: EmptyPageComponent },
  { path: 'AzureRestoreWizard.aspx', component: EmptyPageComponent },
  { path: 'BackupHistory.aspx', component: EmptyPageComponent },
  { path: 'Billing.aspx', component: EmptyPageComponent },
  { path: 'Calculator.aspx', component: EmptyPageComponent },
  { path: 'calc.aspx', component: EmptyPageComponent },
  { path: 'ClientSettings.aspx', component: EmptyPageComponent },
  { path: 'Companies.aspx', component: EmptyPageComponent },
  { path: 'CompareBackupEditions.aspx', component: EmptyPageComponent },
  {
    path: 'Computers.aspx',
    component: ComputersLegacyComponent,
    data: {
      i18nextNamespaces: [eventTotalModule, computersModule, rmmGroupTaskModule, rmmTranslateModule, rmmSidePanelModule]
    },
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    },
    children: [{ path: ':hid', component: SidepanelComputersWrapperComponent }] // todo remove after completed migration
  },
  { path: 'ConfigList.aspx', component: EmptyPageComponent },
  {
    path: 'Default.aspx',
    component: EmptyPageComponent,
    data: {
      permissions: [PermissionsEnum.Dashboard]
    }
  },
  { path: 'DownloadInvoice.aspx', component: EmptyPageComponent },
  { path: 'EC2.aspx', component: EmptyPageComponent },
  { path: 'EC2RestoreHistory.aspx', component: EmptyPageComponent },
  { path: 'EC2RestoreWizard.aspx', component: EmptyPageComponent },
  { path: 'EC2VolumeSnapshots.aspx', component: EmptyPageComponent },
  { path: 'EC2Volumes.aspx', component: EmptyPageComponent },
  { path: 'Ec2History.aspx', component: EmptyPageComponent },
  { path: 'EditAccount.aspx', component: EmptyPageComponent },
  { path: 'EditAdmin.aspx', component: EmptyPageComponent },
  { path: 'EditEC2Plan.aspx', component: EmptyPageComponent },
  { path: 'EditPlan2.aspx', component: EmptyPageComponent },
  { path: 'EditUser.aspx', component: EmptyPageComponent },
  { path: 'EmailsCustomization.aspx', component: EmptyPageComponent },
  { path: 'ForgotPass.aspx', component: EmptyPageComponent },

  // Added old `Apps` redirect links for support. Will be removed from release 6.5 and up.
  { path: 'Apps/General.aspx', redirectTo: RoutingPath.ApAppsGeneral },
  { path: 'Apps.aspx', redirectTo: RoutingPath.ApAppsGeneral },
  { path: 'GoogleApps.aspx', redirectTo: RoutingPath.ApAppsGeneral },

  { path: 'Apps/Domains.aspx', redirectTo: RoutingPath.ApAppsDomains },
  { path: 'BackupStatistics/Domains.aspx', redirectTo: RoutingPath.ApAppsDomains },
  { path: 'AppsDomains.aspx', redirectTo: RoutingPath.ApAppsDomains },
  { path: 'GoogleAppsDomains.aspx', redirectTo: RoutingPath.ApAppsDomains },

  // Reports.aspx page was completely deleted
  { path: 'Apps/Reports.aspx', redirectTo: RoutingPath.ApAppsDomains },
  { path: 'GappsUsersReport.aspx', redirectTo: RoutingPath.ApAppsDomains },
  { path: 'AppsUsersReport.aspx', redirectTo: RoutingPath.ApAppsDomains },
  // end

  { path: 'GoogleAppsBilling.aspx', component: EmptyPageComponent },
  { path: 'IntegrationAutotask.aspx', component: EmptyPageComponent },
  { path: 'IntegrationConnWise.aspx', component: EmptyPageComponent },
  { path: 'IntegrationConnectWiseList.aspx', component: EmptyPageComponent },
  { path: 'IntegrationLabTech.aspx', redirectTo: '/AP/Help/integrations/connectwise-automate' },
  { path: 'IntegrationOptiTune.aspx', redirectTo: '/AP/Help/integrations/optitune' },
  {
    path: 'LicenseActivation.aspx',
    component: EmptyPageComponent,
    data: {
      permissions: ['LicenseActivate']
    }
  },
  {
    path: 'LicenseUsageHistory.aspx',
    component: EmptyPageComponent,
    data: {
      permissions: [PermissionsEnum.LicenseUsageHistory]
    }
  },
  {
    path: 'Licenses.aspx',
    canActivate: [LicensesGuard],
    component: EmptyPageComponent,
    data: {
      permissions: [PermissionsEnum.Licenses]
    }
  },
  { path: 'LicensesLegacy.aspx', component: EmptyPageComponent },
  { path: 'LicensesSubscriptionHistory.aspx', component: EmptyPageComponent },
  { path: 'ManageEc2Plans.aspx', component: EmptyPageComponent },
  { path: 'Monitoring', component: EmptyPageComponent },
  { path: 'Monitoring/', component: EmptyPageComponent },
  { path: 'Monitoring/:id', component: EmptyPageComponent },
  { path: 'Monitoring.aspx', component: EmptyPageComponent },
  { path: 'MonitoringLegacy.aspx', component: EmptyPageComponent },
  { path: 'NewPassword.aspx', component: EmptyPageComponent },
  { path: 'Notifications.aspx', component: EmptyPageComponent },
  { path: 'OnlineAccess.aspx', component: EmptyPageComponent },
  { path: 'Plans.aspx', component: EmptyPageComponent },
  { path: 'PolicyDesigner.aspx', component: EmptyPageComponent },
  { path: 'RMBackupHistory.aspx', component: EmptyPageComponent },
  { path: 'RebrandResources.aspx', component: EmptyPageComponent },
  { path: 'Rebranding.aspx', component: EmptyPageComponent },
  { path: 'RemoteDeployConfiguration.aspx', component: EmptyPageComponent },
  { path: 'ReportDownload.aspx', component: EmptyPageComponent },
  { path: 'ScheduledReports.aspx', component: EmptyPageComponent },
  {
    path: 'Settings.aspx',
    component: EmptyPageComponent,
    data: {
      permissions: [PermissionsEnum.Provider]
    }
  },
  { path: 'SiteMap.aspx', component: EmptyPageComponent },
  { path: 'StandaloneMarketplaceLicenses.aspx', component: EmptyPageComponent },
  { path: 'StorageCapacityReport.aspx', component: EmptyPageComponent },
  { path: 'StorageCostSettings.aspx', component: EmptyPageComponent },
  { path: 'StorageUsageReport.aspx', component: EmptyPageComponent },
  { path: 'StorageUtilization.aspx', component: EmptyPageComponent },
  { path: 'SubmitTicketForm.aspx', component: EmptyPageComponent },
  { path: 'Tariffs.aspx', component: EmptyPageComponent },
  { path: 'UserList.aspx', component: EmptyPageComponent },
  { path: 'UserPlanReport.aspx', component: EmptyPageComponent },
  { path: 'BackupHistory', component: EmptyPageComponent },
  { path: 'BackupHistory/', component: EmptyPageComponent },
  { path: 'BackupHistory/:id', component: EmptyPageComponent },
  { path: 'ConnectWise/Authentication', component: EmptyPageComponent },
  { path: 'ConnectWise/Billing', component: EmptyPageComponent },
  { path: 'ConnectWise/Contacts', component: EmptyPageComponent },
  { path: 'ConnectWise/Tickets', component: EmptyPageComponent }
];

const routes: Routes = [
  {
    path: 'AP',
    canActivate: [CurrentUserGuard],
    canActivateChild: [
      CurrentUserGuard // Check finish account init steps
    ],
    children: [
      {
        path: 'WebConnect',
        data: {
          i18nextNamespaces: ['webrtc', computersModule, 'error']
        },
        loadChildren: () => import('@domains/webrtc-connect/webrtc-connect.module').then((m) => m.WebrtcConnectModule),
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      }
    ]
  },
  {
    path: 'AP',
    canActivate: [CurrentUserGuard],
    canActivateChild: [
      CurrentUserGuard // Check finish account init steps
    ],
    children: [
      {
        path: 'GettingStart',
        data: {
          i18nextNamespaces: ['onboarding', 'wizards', 'builds', 'error']
        },
        loadChildren: () => import('@domains/onboarding/onboarding.module').then((m) => m.OnboardingModule),
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      }
    ]
  },
  {
    path: 'AP',
    component: AppLayoutComponent,
    canActivate: [CurrentUserGuard],
    canActivateChild: [
      DataChangeWatcherGuard,
      PermissionsGuard,
      CurrentUserGuard // Check finish account init steps
    ],
    data: {
      i18nextNamespaces: ['pass-recovery', 'download-build.module']
    },
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    },
    children: [
      {
        path: 'ScriptLibrary',
        loadChildren: () => import('./modules/script-library/script-library.module').then((m) => m.ScriptLibraryModule),
        data: {
          i18nextNamespaces: ['rmm-script-library.module'],
          permissions: [PermissionsEnum.Rmm]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'Snmp',
        data: {
          permissions: [PermissionsEnum.Rmm]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('./domains/snmp/snmp.module').then((m) => m.SnmpModule)
      },
      {
        path: 'RMMAlertCustomisation',
        data: {
          i18nextNamespaces: [notificationModule, customAlertingModule, 'snmp.module'],
          permissions: [PermissionsEnum.Rmm]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('./modules/rmm-custom-alerting/rmm-custom-alerting.module').then((m) => m.RMMCustomAlertingModule)
      },
      {
        path: 'Computers',
        data: {
          permissions: [PermissionsEnum.RemoteManagement],
          i18nextNamespaces: [
            clientSettingsModule,
            eventTotalModule,
            computersModule,
            rmmGroupTaskModule,
            rmmTranslateModule,
            rmmSidePanelModule,
            cloudRestoreModule,
            tagsModule
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('@domains/computers/computers.module').then((m) => m.ComputersModule)
      },
      {
        path: 'Backup/Computers',
        data: {
          permissions: [PermissionsEnum.RemoteManagement],
          i18nextNamespaces: [
            clientSettingsModule,
            eventTotalModule,
            computersModule,
            rmmGroupTaskModule,
            rmmTranslateModule,
            rmmSidePanelModule,
            cloudRestoreModule,
            tagsModule
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('@domains/computers/computers.module').then((m) => m.ComputersModule)
      },
      {
        path: 'RMM/Computers',
        data: {
          permissions: [PermissionsEnum.RemoteManagement],
          i18nextNamespaces: [
            clientSettingsModule,
            eventTotalModule,
            computersModule,
            rmmGroupTaskModule,
            rmmTranslateModule,
            rmmSidePanelModule,
            cloudRestoreModule,
            tagsModule
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('@domains/computers/computers.module').then((m) => m.ComputersModule)
      },
      {
        path: 'Administrators',
        data: {
          permissions: [PermissionsEnum.Administrator],
          i18nextNamespaces: ['administrators']
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('@domains/administrators/administrators.module').then((m) => m.AdministratorsModule)
      },
      {
        path: 'Settings',
        loadChildren: () => import('@domains/settings/settings.module').then((m) => m.SettingsModule)
      },
      {
        path: 'Companies',
        loadChildren: () => import('@domains/companies/companies.module').then((m) => m.CompaniesModule),
        data: {
          permissions: [PermissionsEnum.ManageCompanies],
          i18nextNamespaces: ['companies-side-panel', notificationModule, customAlertingModule]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'Restore2Cloud',
        loadChildren: () => import('@domains/cloud-restore/cloud-restore.module').then((m) => m.CloudRestoreModule),
        data: {
          i18nextNamespaces: ['cloud-restore'],
          permissions: [PermissionsEnum.CloudRestore]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'Apps',
        pathMatch: 'full',
        redirectTo: RoutingPath.ApNotFound
      },
      {
        path: 'Apps',
        children: [
          {
            path: 'General',
            loadChildren: () => import('@domains/m365-google/general/general.module').then((m) => m.GeneralModule),
            data: {
              i18nextNamespaces: ['m365Google-general.module', appsDomainsModule, 'buttons'],
              permissions: [PermissionsEnum.GoogleApps]
            },
            resolve: {
              i18next: I18NEXT_NAMESPACE_RESOLVER
            }
          },
          {
            path: 'Domains',
            loadChildren: () => import('@domains/m365-google/backup-statistics/domains.module').then((m) => m.DomainsModule),
            data: {
              i18nextNamespaces: [appsDomainsModule, 'licenses']
            },
            resolve: {
              i18next: I18NEXT_NAMESPACE_RESOLVER
            }
          },
          {
            path: 'DomainUsers',
            loadChildren: () => import('@domains/m365-google/backup-statistics/domain-users.module').then((m) => m.DomainUsersModule),
            data: {
              i18nextNamespaces: [appsDomainsModule, 'licenses']
            },
            resolve: {
              i18next: I18NEXT_NAMESPACE_RESOLVER
            }
          }
        ]
      },
      {
        path: 'GlobalAgentOptions',
        loadChildren: () => import('@domains/global-agent-options/global-agent-options.module').then((m) => m.GlobalAgentOptionsModule),
        data: {
          permissions: [PermissionsEnum.AccessToAllCompanies]
        }
      },
      {
        path: 'Statistics',
        loadChildren: () => import('@domains/statistics/statistics.module').then((m) => m.StatisticsModule)
      },
      {
        path: 'RDStatistics',
        loadChildren: () => import('@domains/statistics/statistics.module').then((m) => m.StatisticsModule),
        data: {
          permissions: [PermissionsEnum.RemoteManagement]
        }
      },
      {
        path: 'Branding',
        data: {
          permissions: [PermissionsEnum.Rebranding],
          i18nextNamespaces: ['brands']
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('@domains/brands/brands.module').then((m) => m.BrandsModule)
      },
      {
        path: 'Notifications',
        loadChildren: () => import('./domains/notifications/notifications.module').then((m) => m.NotificationsModule),
        data: {
          permissions: [PermissionsEnum.Notification],
          i18nextNamespaces: [notificationModule, customAlertingModule]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'PlanReportSettings',
        data: {
          permissions: [PermissionsEnum.Notification]
        },
        loadChildren: () => import('@domains/plan-report-settings/plan-report-settings.module').then((m) => m.PlanReportSettingsModule)
      },
      {
        path: 'RemoteDeploy',
        redirectTo: 'BackupTemplates'
      },
      {
        path: 'BackupTemplates',
        data: {
          permissions: [PermissionsEnum.RemoteDeploy]
        },
        loadChildren: () => import('@domains/remote-deploy/remote-deploy.module').then((m) => m.RemoteDeployModule)
      },
      {
        path: 'Users',
        data: {
          i18nextNamespaces: ['users']
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('@domains/users/users.module').then((m) => m.UsersModule)
      },
      {
        path: 'AuditLog',
        data: {
          i18nextNamespaces: ['audit-log']
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('@domains/audit-log/audit-log.module').then((m) => m.AuditLogModule)
      },
      {
        path: 'Help',
        children: [
          {
            path: '**',
            component: HelpComponent
          }
        ],
        data: {
          permissions: [PermissionsEnum.HelpMarketing]
        }
      },
      {
        path: 'EmailService',
        data: {
          permissions: [PermissionsEnum.Notification]
        },
        loadChildren: () =>
          import('@domains/email-service-settings/email-service-settings.module').then((m) => m.EmailServiceSettingsModule)
      },
      ...iframePagesWithStandardLayout,
      {
        path: 'SSL',
        data: {
          permissions: ['CustomCertificatesBinding']
        },
        loadChildren: () => import('@domains/bindings-certificates/bindings-certificates.module').then((m) => m.BindingsCertificatesModule)
      },
      {
        path: 'Marketing',
        component: MarketingIframeComponent,
        data: {
          permissions: [PermissionsEnum.HelpMarketing]
        }
      },
      {
        path: 'Licenses',
        canActivate: [LicensesGuard],
        loadChildren: () => import('@domains/licenses/licenses.module').then((m) => m.LicensesModule)
      },
      {
        path: 'StorageCapacityReport',
        data: {
          permissions: [PermissionsEnum.CapacityReport]
        },
        loadChildren: () => import('@domains/capacity-report/capacity-report.module').then((m) => m.CapacityReportModule)
      },
      {
        path: 'RMMAlertsHistory',
        data: {
          i18nextNamespaces: ['rmm-alerts-history.module', rmmTranslateModule],
          permissions: [PermissionsEnum.Notification, PermissionsEnum.Rmm]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('@domains/alerts-history/alerts-history.module').then((m) => m.AlertsHistoryModule)
      },
      {
        path: 'RMMGroupTaskAction',
        data: {
          i18nextNamespaces: [rmmGroupTaskModule, rmmSidePanelModule, rmmTranslateModule],
          permissions: [PermissionsEnum.Rmm]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () => import('@domains/group-action-task/group-action-task.module').then((m) => m.GroupActionTaskModule)
      },
      {
        path: 'WebConsoleRebranding',
        data: {
          i18nextNamespaces: ['web-console-rebranding']
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        },
        loadChildren: () =>
          import('@domains/web-console-rebranding/web-console-rebranding.module').then((m) => m.WebConsoleRebrandingModule)
      },
      {
        path: 'NotFound',
        component: NotFoundComponent,
        data: {
          i18nextNamespaces: ['error']
        }
      },
      { path: '', redirectTo: RoutingPath.ApNotFound, pathMatch: 'full' }
    ]
  },
  {
    path: 'AS',
    loadChildren: () => import('./shared/_layout/unauthorized-layout/unauthorized-layout.module').then((m) => m.UnauthorizedLayoutModule)
  },
  {
    path: 'AP/Auth2FA',
    data: {
      isTFAEnabled: false
    },
    loadChildren: () => import('@domains/two-factor-auth/two-factor-auth.module').then((m) => m.TwoFactorAuthModule)
  },
  // no layout routes
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'Admin/payment.aspx', component: EmptyPageComponent },

  // otherwise redirect to home
  { path: '**', component: NotFoundWrapperComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CurrentUserGuard, StatisticsGuard, DataChangeWatcherGuard, PermissionsGuard, LicensesGuard]
})
export class AppRoutingModule {}
