import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GroupTask,
  GroupTaskFiltersPayload,
  GroupTaskReg,
  GroupTasksDataResponse,
  TaskFromGroupTaskResponse
} from '@modules/group-tasks/store/model';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class GroupTasksService {
  private serverUrl: string;
  private mbsStageKey: string;
  private apiEndPoint = 'api/GroupTasks' as const;
  private httpParams: GroupTaskFiltersPayload = {};

  constructor(private http: HttpClient, private config: ConfigurationService) {
    this.serverUrl = this.config.get('rmmBaseHref');
    this.mbsStageKey = this.config.get('mbsStageKey');
    if (this.mbsStageKey) this.httpParams.mbsStageKey = this.mbsStageKey;
  }

  getGroupTasks(httpParams: GroupTaskFiltersPayload): Observable<GroupTasksDataResponse> {
    if (this.mbsStageKey) httpParams.mbsStageKey = this.mbsStageKey;
    httpParams.all = true;
    // parameter to get all kinds of GroupTasks below
    httpParams.policy = '';
    return this.http.get<GroupTasksDataResponse>(`${this.serverUrl}/${this.apiEndPoint}`, { params: httpParams });
  }

  getGroupTask(groupTaskGuid: string): Observable<GroupTask> {
    return this.http.get<GroupTask>(`${this.serverUrl}/${this.apiEndPoint}/${groupTaskGuid}`, { params: this.httpParams });
  }

  registerGroupTask(task: GroupTaskReg): Observable<string> {
    return this.http.post<any>(`${this.serverUrl}/${this.apiEndPoint}/register`, task, { params: this.httpParams });
  }

  updateGroupTask(groupTaskGuid: string, update: any): Observable<string> {
    return this.http.put<any>(`${this.serverUrl}/${this.apiEndPoint}/${groupTaskGuid}`, update, { params: this.httpParams });
  }

  deleteGroupTask(groupTaskGuid: string): Observable<any> {
    return this.http.delete(`${this.serverUrl}/${this.apiEndPoint}/${groupTaskGuid}`, { params: this.httpParams });
  }

  getTasksFromGroupTask(groupTaskGuid: string, httpParams: GroupTaskFiltersPayload): Observable<TaskFromGroupTaskResponse> {
    if (this.mbsStageKey) httpParams.mbsStageKey = this.mbsStageKey;
    return this.http.get<TaskFromGroupTaskResponse>(`${this.serverUrl}/${this.apiEndPoint}/${groupTaskGuid}/tasks`, { params: httpParams });
  }

  executeGroupTask(hid: string, groupTaskGuid: string): Observable<any> {
    this.httpParams.groupTaskGuid = groupTaskGuid;
    return this.http.post(`${this.serverUrl}/api/Computers/rm/${hid}/commands/forcegrouptask`, null, { params: this.httpParams });
  }

  changeGroupTaskStateUtility(groupTaskGuid: string, state: boolean): Observable<string> {
    if (state === true) return this.enableGroupTask(groupTaskGuid);
    return this.disableGroupTask(groupTaskGuid);
  }

  disableGroupTask(groupTaskGuid: string): Observable<string> {
    return this.http.post<any>(`${this.serverUrl}/${this.apiEndPoint}/${groupTaskGuid}/disable`, { params: this.httpParams });
  }

  enableGroupTask(groupTaskGuid: string): Observable<string> {
    return this.http.post<any>(`${this.serverUrl}/${this.apiEndPoint}/${groupTaskGuid}/enable`, { params: this.httpParams });
  }
}
