import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnlineAccessSettings } from '@models/onlineAccessSettings';
import { Observable } from 'rxjs';

@Injectable()
export class OnlineAccessService {
  private readonly url = 'api/settings/online-access';

  constructor(private http: HttpClient) {}

  getSettings(): Observable<OnlineAccessSettings> {
    return this.http.get<OnlineAccessSettings>(this.url);
  }

  setSettings(data: OnlineAccessSettings): Observable<void> {
    return this.http.put<void>(this.url, data);
  }
}
