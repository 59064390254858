<mbs-modal [title]="baseModal.data.title" [isCustomModal]="true" [showFooter]="true" [titleTextOverflow]="true" [loading]="loading" #modal>
  <div modal-body>
    <div>
      {{ 'app:paymentDebtModal:unpaidBills' | i18next }}
      <b>{{ (balance | async)?.subscriptionCurrencySign }}{{ debt?.debtLeft }}</b>
    </div>
    <div class="mt-3">{{ 'app:paymentDebtModal:mustPayDebt' | i18next }}</div>
  </div>
  <ng-container modalFooter>
    <mbs-button type="primary" [loading]="payLinkLoading" (click)="goToPay()"
      >{{ 'app:paymentDebtModal:pay' | i18next: { format: 'title' } }}
    </mbs-button>
    <mbs-button type="secondary" data-dismiss="modal" (click)="modal.close()"
      >{{ 'app:buttons:cancel' | i18next: { format: 'title' } }}
    </mbs-button>
  </ng-container>
</mbs-modal>
