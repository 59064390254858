import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { ButtonModule, MbsUiKitModule, SidepanelModule, TabsetModule } from 'mbs-ui-kit';
import { SidepanelDevicesComponent } from './sidepanel-devices.component';
import { DateTimeFormatModule } from '@components/date-time-format/date-time-format.module';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    NgbTooltipModule,
    NgSelectModule,
    ReactiveFormsModule,
    TabsetModule,
    AbilityModule,
    DateTimeFormatModule,
    I18NextModule,
    SidepanelModule,
    MbsUiKitModule
  ],
  declarations: [SidepanelDevicesComponent]
})
export class SidepanelDevicesModule {}
