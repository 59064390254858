import { Injectable } from '@angular/core';
import * as ComputerAppsSelectors from '@modules/computer-apps/store/computer-apps.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { versionCompare } from '@utils/version-compare';
import { cloneDeep } from 'lodash';
import { ErrorsEnum } from 'mbs-ui-kit';
import * as moment from 'moment';
import { EMPTY, Observable, combineLatest, interval, of, switchMap } from 'rxjs';
import { concatMap, filter, mergeMap, take } from 'rxjs/operators';
import {
  createAsyncId,
  createChunkId,
  createSession,
  createSessionId,
  normalize,
  parseAsyncId,
  serializeMessage
} from '../terminal-emulator.util';
import { TerminalEmulatorService } from './../terminal-emulator.service';
import * as TerminalActions from './terminal.actions';
import { PSOutDataType, TerminalChunk, TerminalDataType, TerminalHostInfo, TerminalTransfer } from './terminal.model';
import * as TerminalSelectors from './terminal.selectors';

@Injectable()
export class TerminalEffects {
  /**
   *
   */
  output$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TerminalActions.output),
      concatMap(({ asyncId, transfer }) => {
        return this.handleOutput(asyncId, cloneDeep(transfer));
      })
    );
  });
  /**
   *
   */
  checkDisconnected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TerminalActions.checkDisconnected),
      mergeMap(({ hid }) => {
        const session = createSessionId({ hid });
        return this.service.checkDisconnected(hid).pipe(
          concatMap((status: boolean) => {
            return status
              ? of(
                  TerminalActions.setProcessing({ session, processing: false }),
                  TerminalActions.setDisconnected({ session, disconnected: status })
                )
              : of(TerminalActions.setDisconnected({ session, disconnected: status }));
          })
        );
      })
    );
  });
  /**
   *
   */
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TerminalActions.initTerminal),
      switchMap(({ hid, emuId }) =>
        combineLatest([
          this.store.select(ComputerAppsSelectors.selectRMMAgent(hid)).pipe(filter(Boolean), take(1)),
          this.store.select(TerminalSelectors.selectSession(hid)).pipe(filter(Boolean), take(1))
        ]).pipe(
          switchMap(([agent, session]) => {
            const asyncId = createAsyncId({ hid, emuId, stamp: Date.now() });
            const sessionId = createSessionId({ hid });

            if (session.processing && session.info) {
              return this.syncInit({ hid, emuId }, session.info);
            }

            return versionCompare(agent?.version || '', '1.4.0') > 0
              ? this.service
                  .init(hid, asyncId)
                  .pipe(concatMap(() => of(TerminalActions.setProcessing({ session: sessionId, processing: true }))))
              : this.syncInit({ hid, emuId }, { osVersionType: 'Windows', legacy: true });
          })
        )
      )
    );
  });
  /**
   *
   */
  send$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TerminalActions.send),
      switchMap(({ hid, prompt, emuId, file }) => {
        const stamp = Date.now();
        const script = typeof prompt === 'string' ? prompt : prompt.getInput();
        const asyncId = createAsyncId({ hid, emuId, stamp });
        const session = createSessionId({ hid });

        const request$ = this.service.send(prompt, hid, asyncId).pipe(
          concatMap((data) => {
            return data.error ? this.handleError(asyncId, stamp, { ...data.error }) : EMPTY;
          })
        );

        return of(
          of(this.createChunk(asyncId, { data: script, type: file ? TerminalDataType.FILE : TerminalDataType.INPUT, file })),
          of(TerminalActions.setProcessing({ session, processing: true })),
          request$
        ).pipe(concatMap((obs) => obs));
      })
    );
  });
  /**
   *
   */
  break$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TerminalActions.sendBreak),
      concatMap(({ hid, emuId }) => {
        const stamp = Date.now();
        const asyncId = createAsyncId({ hid, emuId, stamp });

        return this.service
          .break(hid, asyncId)
          .pipe(concatMap((data) => (data.error ? this.handleError(asyncId, stamp, data.error) : ([] as Action[]))));
      })
    );
  });
  /**
   *
   */
  getTimeOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TerminalActions.getTimeOut),
      concatMap(({ hid }) => this.service.getTimeOut(hid).pipe(concatMap(() => [] as Action[])))
    );
  });
  /**
   *
   */
  setTimeout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TerminalActions.setTimeout),
      concatMap(({ hid, timeout }) => this.service.setTimeout(hid, timeout).pipe(concatMap(() => [] as Action[])))
    );
  });
  /**
   *
   */
  keepAlive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TerminalActions.setProcessing),
      switchMap((action) => {
        const { hid } = parseAsyncId(action.session);
        return this.store.select(TerminalSelectors.selectSession(hid)).pipe(
          switchMap((session) => {
            switch (true) {
              case (session.subscribers?.length || 0) < 1: {
                return EMPTY;
              }
              case session.processing:
              case session.disconnected: {
                return interval(10000).pipe(switchMap(() => of(TerminalActions.checkDisconnected({ hid }))));
              }
              default:
                return EMPTY;
            }
          })
        );
      })
    );
  });

  constructor(private store: Store, private actions$: Actions, private service: TerminalEmulatorService) {}

  /**
   * Message service output handler
   * @private
   * @param {string} asyncId
   * @param {TerminalTransfer} transfer
   * @return {Observable<Action>}
   */
  private handleOutput = (asyncId: string, transfer: TerminalTransfer): Observable<Action> => {
    switch (transfer.outType) {
      case PSOutDataType.StdOut: {
        const { data, type } = serializeMessage(transfer);
        return of(this.createChunk(asyncId, { data, type, number: transfer.number }));
      }
      case PSOutDataType.InputWait: {
        const { hid } = parseAsyncId(asyncId);
        const session = createSessionId({ hid });
        return of(
          TerminalActions.setInfo({ info: { legacy: false }, session }),
          this.createChunk(asyncId, { data: transfer.data, type: TerminalDataType.PROMPT, number: transfer.number })
        );
      }
      case PSOutDataType.Warning: {
        transfer.data = normalize(transfer.data);
        return of(this.createChunk(asyncId, { data: transfer.data, type: TerminalDataType.WARN, number: transfer.number }));
      }
      case PSOutDataType.End: {
        const { hid } = parseAsyncId(asyncId);
        return of(
          this.createChunk(asyncId, { data: transfer.data, type: TerminalDataType.END, number: transfer.number }),
          TerminalActions.setProcessing({ session: createSessionId({ hid }), processing: false })
        );
      }
      case PSOutDataType.TimeOutSettings: {
        const { hid } = parseAsyncId(asyncId);
        const session = createSessionId({ hid });
        const timeout = transfer.data.timeout as number;
        return of(TerminalActions.addTimeout({ session, timeout }));
      }
      case PSOutDataType.SystemInfo: {
        const { hid, emuId } = parseAsyncId(asyncId);
        const session = createSessionId({ hid });
        return of(
          TerminalActions.setProcessing({ session, processing: false }),
          TerminalActions.setInfo({ info: JSON.parse(transfer.data), session }),
          TerminalActions.addSubscriber({ emuId, session })
        );
      }
      case PSOutDataType.Unknown:
      case PSOutDataType.StdErr: {
        return of(this.createChunk(asyncId, { data: normalize(transfer.data), type: TerminalDataType.ERROR, number: transfer.number }));
      }
      case PSOutDataType.Busy: {
        const { hid } = parseAsyncId(asyncId);
        return of(TerminalActions.setProcessing({ session: createSessionId({ hid }), processing: true }));
      }
      default:
        return EMPTY;
    }
  };
  /**
   * Handle Errors from API
   * @private
   * @param {sting} asyncId
   * @param {number} timestamp
   * @param {*} [error={}]
   * @return {Observable<Action>}
   */
  private handleError(asyncId: string, timestamp: number, error: any = {}) {
    const type = TerminalDataType.ERROR;

    let errorAction: TypedAction<string>;
    switch (error.code) {
      case ErrorsEnum.RmmTimeOut: {
        const now = Date.now();

        const duration = moment.duration(now - timestamp, 'milliseconds').format('m[m] s[s]', { trim: 'both', largest: 1 });

        const errorMessage = `Timeout exceeded (${duration}). Command break.`;
        errorAction = this.createChunk(asyncId, { data: errorMessage, type });
        break;
      }
      case ErrorsEnum.RmmAccessDenied: {
        const errorMessage = 'Access denied';
        errorAction = this.createChunk(asyncId, { data: errorMessage, type });
        break;
      }
      default: {
        errorAction = this.createChunk(asyncId, { data: error.title, type });
        break;
      }
    }

    return of(errorAction, this.createChunk(asyncId, { data: null, type: TerminalDataType.PROMPT }));
  }
  /**
   * Sync Initialization Session
   * @private
   * @param {createSession} params
   * @param {Partial<TerminalHostInfo>} info
   * @return {Observable<Action>}
   */
  private syncInit(params: createSession, info: Partial<TerminalHostInfo>) {
    const { emuId, hid } = params;
    const session = createSessionId({ hid });

    const actions: Action[] = [TerminalActions.setInfo({ info, session }), TerminalActions.addSubscriber({ emuId, session })];

    if (info.legacy) {
      actions.push(this.createChunk(session, { data: null, type: TerminalDataType.PROMPT, emuId }));
    }

    return of(...actions);
  }
  /**
   * Create Terminal-Chunk Action
   * @param {string} asyncId
   * @param {Partial<TerminalChunk>} params
   * @return {Action}
   */
  private createChunk(asyncId: string, params: Partial<TerminalChunk>) {
    const { hid, emuId, stamp } = parseAsyncId(asyncId);
    const { id, number } = createChunkId({ stamp, number: params.number });
    const session = createSessionId({ hid });

    const line = {
      id,
      number,
      emuId,
      session,
      stamp,
      data: params.data,
      type: params.type,
      file: params.file
    };

    return TerminalActions.addChunk({ line });
  }
}
