import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule } from 'angular-i18next';
import { FormGroupModule } from 'mbs-ui-kit/form-group/form-group.module';
import { InputModule } from 'mbs-ui-kit/form/input/input.module';
import { ConfirmPasswordComponent } from './confirm-password.component';

@NgModule({
  imports: [CommonModule, I18NextModule, InputModule, FormGroupModule, NgbTooltipModule, FormsModule, ReactiveFormsModule],
  declarations: [ConfirmPasswordComponent],
  exports: [ConfirmPasswordComponent]
})
export class ConfirmPasswordModule {}
