<div class="mbs-form-group mb-0">
  <mbs-advanced-search [accessUrlQuery]="false"
                       [(ngModel)]="model"
                       (search)="updateFilters($event)"
                       (input)="handleInputSearch($event)">
  </mbs-advanced-search>
</div>

<ng-container *ngIf="isWaitingForResponse">
  <mbs-alert [type]="alertType.info"
             [closable]="false"
             class="mt-3 mb-0">
    <div class="text-break">{{ 'rmm-side-panel:antivirusTab.waiting' | i18next }}</div>
  </mbs-alert>
</ng-container>

<ng-container *ngIf="shouldShowAlert ">
  <mbs-alert *ngIf="latestScanData?.length"
             [type]="alertType.info"
             [closable]="true"
             (close)="onAlertClose()"
             class="mt-3 mb-0">
    <ng-container *ngFor="let item of latestScanData">
      <div class="text-break">{{ item }}</div>
    </ng-container>
  </mbs-alert>
</ng-container>

<mbs-table-nav [switcherView]="false"
               (pageChange)="handlePageChange($event)"
               (pageSizeChange)="handlePageSizeChange($event)"
               [showRefreshButton]="true"
               [showExportToCSVButton]="isModal"
               [exportButtonName]="'buttons:exportToCSV' | i18next"
               (export)="export(getTableName())"
               (refresh)="fetchData()">

  <ng-template mbsTableFilter="label">
    {{ 'rmm-side-panel:antivirusTab.antiviruses' | i18next }} {{ paginationOptions.dataSize }}
  </ng-template>

  <ng-container *ngIf="isOnline">
    <ng-template mbsTableFilter="divider"></ng-template>

    <ng-template mbsTableFilter="label">
      <ng-container *ngIf="readOnly === false">
        <mbs-button class="mr-2"
                    size="md"
                    (click)="handleCheckSpecificDirectory(specificPath)"
                    [disabled]="!canHandleScan"
                    [ngbTooltip]="!isModal ? ('buttons:scan' | i18next) : ''"
                    tooltipClass="tooltip-lg -no-arrow"
                    icon="ico ico-Search">
          {{ isModal ? ('buttons:scan' | i18next) : '' }}
        </mbs-button>

        <mbs-button class="mr-2"
                    size="md"
                    (click)="handleUpdateBase()"
                    icon="ico ico-arrow-circle-up"
                    [disabled]="!canHandleDBUpdate"
                    [ngbTooltip]="!isModal ? ('buttons:updateDataBase' | i18next) : ''"
                    tooltipClass="tooltip-lg -no-arrow">
          {{ isModal ? ('buttons:updateDataBase' | i18next) : '' }}
        </mbs-button>
      </ng-container>

      <mbs-button class="mr-2"
                  size="md"
                  (click)="handleGetLatestData()"
                  [disabled]="selected.length === 0"
                  [ngbTooltip]="!isModal ? ('buttons:latestScanInfo' | i18next) : ''"
                  tooltipClass="tooltip-lg -no-arrow">
        <span class="fa fa-info-circle"></span>
        {{ isModal ? ('buttons:latestScanInfo' | i18next) : '' }}
      </mbs-button>

    </ng-template>

  </ng-container>

  <ng-template mbsTableControl="append"
               *ngIf="!isModal">
    <mbs-modal-tab-content modalTitle="Installed Antivirus"
                           [showExpandedCross]="true">
      <ng-template>
        <mbs-antivirus-tab [hid]="hid"
                           [isModal]="true"
                           [isOnline]="isOnline"
                           [readOnly]="readOnly"
                           [computerName]="computerName"></mbs-antivirus-tab>
      </ng-template>
    </mbs-modal-tab-content>
  </ng-template>

</mbs-table-nav>

<mbs-table-grid [loading]="loading"
                [data]="data"
                [showNav]="false"
                [headers]="headers"
                [multipleSelect]="false"
                [selectedItems]="selected"
                [selectable]="true"
                [selectAllIgnoreDisabled]="true"
                (sort)="handleSort($event)"
                (changeSelected)="itemsChecked($event)"
                [maxHeight]="isModal || !isOnline ? '60vh' : '56vh'"
                [changeSortState]="orderBy"
                [paginationOptions]="paginationOptions"
                [myTrackBy]="trackBy"
                [rotateSequence]="rotateSequence"
                [showTableCheckboxes]="isOnline"
                [viewMode]="sharedPersistentStateEnum.table"
                [disableViewSwitch]="true"
                [bindDisabledValues]="{ key: 'managed', value: false }"
                [noDataMessage]="'rmm-side-panel:noDataProvided' | i18next">

  <ng-template mbsTableCell
               *ngFor="let header of headers"
               let-item>

    <ng-template #tooltipOverflow>

      <div placement="auto"
           ngbTooltip="{{ item[header.sort] }}"
           container="body"
           tooltipClass="tooltip-lg text-left"
           class="text-overflow">
        {{ item[header.sort] }}
      </div>
    </ng-template>

    <ng-container *ngIf="!header.overflow; else tooltipOverflow">
      <ng-container [ngSwitch]="header.sort">
        <span *ngSwitchCase="'timeStamp'">
          <mbs-rmm-date-time-format [container]="'body'"
                                    [isUtc]="true"
                                    [time]="item[header.sort]"
                                    [showIcon]="true"></mbs-rmm-date-time-format>
        </span>
        <span *ngSwitchCase="'enabled'">
          {{ item[header.sort] | boolDisplay }}
        </span>
        <span *ngSwitchCase="'autoUpdate'">
          {{ item[header.sort] | boolDisplay }}
        </span>
        <span *ngSwitchCase="'upToDate'">
          {{ item[header.sort] | boolDisplay }}
        </span>
        <span *ngSwitchDefault>
          {{ item[header.sort] }}
        </span>
      </ng-container>

    </ng-container>

  </ng-template>

</mbs-table-grid>

<ng-template #specificPath>
  <div class="mbs-form-group mb-0">
    <mbs-input [formControl]="specificDirectory"
               rows="4"
               type="textarea"
               [label]="'rmm-side-panel:antivirusTab.specifyPathToScan' | i18next"
               placeholder="Enter Specific Paths"
               autocomplete="off"></mbs-input>
  </div>
  <div class="mt-2">
    <div class="mbs-form-text small mt-0">
      {{ 'rmm-side-panel:antivirusTab.example' | i18next }} <span class="nowrap">C:\Program Files;C:\ProgramData;</span>
    </div>
  </div>
</ng-template>
