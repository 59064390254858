import { ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export class EventBehavior {
  /**
   * @summary subscribers array, for unsubscribe on dispose
   */
  private subs: Subscription[] = [];
  /**
   * @summary Event emitter
   */
  private _actions$: ReplaySubject<Action> = new ReplaySubject(1);
  /**
   * @summary Event bus
   */
  public actions$: Observable<Action>;

  constructor() {
    this.actions$ = this._actions$.pipe(filter(Boolean));
  }
  /*
   * Save subscriber
   * @param type
   * @param fn
   */
  public subscribeOfType = (type, fn) => {
    this.subs.push(this.actions$.pipe(ofType(type)).subscribe(fn));
  };
  /*
   * Dispatcher
   * @param action
   */
  public dispatch = (action: Action) => {
    this._actions$.next(action);
  };
  /**
   * Close all subscriptions, clear this bus.
   */
  public dispose = () => {
    this._actions$.complete();
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  };
}
