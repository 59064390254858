import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountId } from '@services/apps/apps.constants';
import {
  AppsSettingsResponseType,
  LoginData,
  ODataCommonResponse,
  SetAuthDataResponseType,
  TokenDataType,
  User
} from '@services/apps/apps.types';
import { ConfigurationService } from '@services/configuration.service';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppsService {
  private API = {
    Onboarding: `${this.configuration.get('appsBaseHref')}/api/odata/onboarding`,
    Settings: 'api/apps/settings',
    Domains: 'api/apps/domains'
  };

  constructor(private http: HttpClient, private configuration: ConfigurationService) {}

  public getServices(id: AccountId): Observable<string[]> {
    return this.http
      .get<ODataCommonResponse<string[]>>(`${this.API.Onboarding}/services?provider=${id}`)
      .pipe(map((response) => response.value));
  }

  public getUsers(tokenData: TokenDataType, params?: { top: number }): Observable<{ value: User[]; status: HttpStatusCode }> {
    return this.http
      .get<ODataCommonResponse<User[]>>(`${this.API.Onboarding}/users`, {
        observe: 'response',
        params: params || { $top: 50 },
        headers: {
          Authorization: `${tokenData['token_type']} ${tokenData['access_token']}`
        }
      })
      .pipe(
        map(({ status, body }) => {
          return {
            status,
            value: body.value
          };
        })
      );
  }

  public getAuthSettings(): Observable<LoginData[]> {
    return this.http
      .get<ODataCommonResponse<LoginData[]>>(`${this.API.Onboarding}/auth`)
      .pipe(pluck<ODataCommonResponse<LoginData[]>, 'value'>('value'));
  }

  public setAuthData(id: AccountId, data: any, alias: string): Observable<SetAuthDataResponseType> {
    return this.http.post<SetAuthDataResponseType>(`${this.API.Onboarding}/auth?provider=${id}&alias=${alias}`, data);
  }

  public setSettings(settings: { enabled: boolean; alias: string }): Observable<AppsSettingsResponseType> {
    return this.http.post<AppsSettingsResponseType>(this.API.Settings, settings);
  }

  public launchBackup(tokenData: TokenDataType, params: { Services: string[]; Users: string[] }): Observable<null> {
    return this.http.post<null>(this.API.Onboarding, params, {
      headers: {
        Authorization: `${tokenData['token_type']} ${tokenData['access_token']}`
      }
    });
  }
}
