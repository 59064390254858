<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           [loading]="deleteLoading"
           #modal>

  <div modal-body>

    <ng-container>
      {{ 'tfa:deleteModal:question' | i18next: { deviceName: baseModal.data.deviceName } }}
      <mbs-alert [type]="alertType.warning"
                 [icon]="true"
                 *ngIf="baseModal.data.hasTwoFactorAuth"
                 class="mt-3">
        {{ 'tfa:deleteModal:deleteWarning' | i18next }}
      </mbs-alert>
    </ng-container>

  </div>

  <ng-container modalFooter>

    <mbs-button type="danger"
                (click)="delete()"
                [loading]="loading">
      {{ 'tfa:deleteModal:deleteButton' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">
      {{ 'tfa:close' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
