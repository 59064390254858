import { Injectable, Injector } from '@angular/core';
import EventLogInfo, {
  EventLogEntrySourceTypeEnum,
  EventLogEntryTypeEnum,
  IEventLogEntry
} from '@models/rmm/EventLogInfo';
import { FilterQuery } from '@models/rmm/FilterQuery';
import RmmCommand, { addRmmParam } from '@models/rmm/RmmCommand';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RmmService } from '@services/rmm.service';
import { find, get, pipe } from 'lodash/fp';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, pluck, withLatestFrom } from 'rxjs/operators';
import { FilterEntryMap } from '../event-total-tab.model';
import {
  EventLogEntryTypeRelateMap,
  EventLogEntryTypeToClassRelateMap,
  EventLogEntryTypeToIconRelateMap,
  EventTypeLabelsForGreed,
  getRelateByEventLogEntryId,
  parseXML,
  stringToBoolean
} from '../event-total-tab.utils';
import * as EventChartActions from './event-chart.actions';
import * as EventEntryFilterActions from './event-entry-filter.actions';
import * as EventEntryActions from './event-entry.actions';
import * as EventTotalActions from './event-total.actions';
import { selectFilters } from './event-total.selectors';

@Injectable()
export class EventTotalEffects {
  updateFilterEntryAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventEntryFilterActions.setActiveFilter),
      withLatestFrom(this.store.select(selectFilters)),
      concatMap(([payload, filters]) => {
        const id = payload.id;
        const eventEntryFilters = filters.map((filter) => ({
          id: filter.id,
          changes: {
            active: id === filter.id
          }
        }));
        return of(EventEntryFilterActions.updateEventEntryFilters({ eventEntryFilters: [...eventEntryFilters] }));
      })
    );
  });

  loadEventEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventEntryActions.loadEventEntries),
      concatMap(({ state }) => {
        const { start, stop, logType, hid, entry } = state;

        const filtersMap: FilterEntryMap = EventTypeLabelsForGreed[logType].reduce((acc, key) => {
          acc[key] = {
            id: key,
            name: key,
            value: EventLogEntryTypeRelateMap[key],
            type: EventLogEntryTypeToClassRelateMap[key],
            active: entry ? entry === key : key === EventLogEntryTypeEnum.All,
            count: 0,
            icon: EventLogEntryTypeToIconRelateMap[key]
          };

          return acc;
        }, {});

        return this.fetchEventLogEntry(start, stop, logType, hid).pipe(
          concatMap((data: IEventLogEntry[]) => {
            const eventEntries = data.map((eventEntry) => {
              const id = eventEntry.index;
              const entryType = eventEntry.entryType ? eventEntry.entryType : EventLogEntrySourceTypeEnum.Information;
              const { entry, cssClass } = getRelateByEventLogEntryId(entryType);
              const time = moment(eventEntry.timeWritten).format('h:mm A');
              const timeUTC = new Date(eventEntry.timeWritten);
              // side effect
              if (filtersMap[entry]) {
                filtersMap[entry].count = filtersMap[entry].count + 1;
              }

              filtersMap[EventLogEntryTypeEnum.All].count += 1

              return {
                ...eventEntry,
                id,
                logType,
                entryType,
                entry,
                level: entry,
                cssClass,
                time,
                timeUTC
              };
            });

            return of(
              EventEntryFilterActions.clearEventEntryFilters(),
              EventEntryFilterActions.addAllEventEntryFilters({ eventEntryFilters: Object.values(filtersMap) }),
              EventEntryActions.addAllEventEntry({ eventEntries: eventEntries })
            );
          }),
          catchError((error) => of(EventEntryFilterActions.clearEventEntryFilters(), EventTotalActions.loadError({ error })))
        );
      })
    );
  });

  loadEventChart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventChartActions.loadEventCharts),
      concatMap(({ state }) => {
        const { start, stop, hid } = state;

        return this.fetchEventLogChart(start, stop, hid).pipe(
          concatMap((data: EventLogInfo[]) => {
            const eventCharts = data.map((eventLogInfo) => ({
              id: eventLogInfo.logType,
              ...eventLogInfo
            }));
            return of(EventChartActions.addAllEventCharts({ eventCharts }));
          }),
          catchError((error) => of(EventTotalActions.loadError({ error })))
        );
      })
    );
  });

  clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventTotalActions.ClearEventTotal),
      concatMap(() =>
        of(EventEntryActions.clearEventEntries(), EventChartActions.clearEventCharts(), EventEntryFilterActions.clearEventEntryFilters())
      )
    );
  });

  commandService: CommandService;
  rmmService: RmmService;

  constructor(private actions$: Actions, private store: Store, private inject: Injector) {}

  fetchEventLogEntry = (start, stop, logType, hid): Observable<IEventLogEntry[]> => {
    if (!this.commandService) this.commandService = this.inject.get(CommandService);

    const command = RmmCommand.createPipe<IEventLogEntry>(
      addRmmParam('LOGDISPLAYNAME', logType),
      addRmmParam('SOURCENAME', ''),
      addRmmParam('START', start),
      addRmmParam('STOP', stop),
      addRmmParam('MAXCOUNT', '0'),
      addRmmParam('OFFSET', '0')
      // addRmmParam('XMLRESULT', 'true')
    )('GetLogEventsInfo');

    return this.commandService.sendCommand<IEventLogEntry>('EventLogCmd', command, hid, false).pipe(
      map((response: any) => {

        const tempData = response?.result && JSON.parse(response?.result);
        const data = get('data.data', tempData ?? response);

        if (tempData) {
          return data ? JSON.parse(data) : [];
        } else {
          if (typeof data === 'string') {
            const isXml = pipe(
              get(['data', 'command', 'parameters']),
              find((i: any) => i.name === 'XMLRESULT'),
              get('value'),
              stringToBoolean
            )(response);
            return isXml ? parseXML(data) : JSON.parse(data);
          }
          return data;
        }
      })
    );
  };

  fetchEventLogChart = (start, stop, hid): Observable<EventLogInfo[]> => {
    if (!this.rmmService) this.rmmService = this.inject.get(RmmService);

    const params: FilterQuery = {};
    params.fields = { 'header.utcTime': `${start}~|${stop}` };

    return this.rmmService.fetchStat<EventLogInfo>('eventtotal', hid, params).pipe(
      pluck('data', '0', 'data'),
      map((data) => data || [])
    ) as Observable<EventLogInfo[]>;
  };
}
