import { Injectable, NgZone } from '@angular/core';
import Administrator from '@models/Administrator';
import { AuthService } from '@services/auth.service';
import { ConfigurationService } from '@services/configuration.service';
import posthog from 'posthog-js/dist/module';
import { filter, first } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PosthogService {
  private initialized;
  private get posthog() {
    return posthog;
  }

  constructor(private ngZone: NgZone, private authService: AuthService, private config: ConfigurationService) {}

  public initialize() {
    if (this.needDeclinePostHog()) return;

    this.ngZone.runOutsideAngular(() => {
      const apiKey =
        this.config.get('production') === 'false'
          ? 'phc_W87dzexm7McSv2ms3rFkxJ1T8v5zAhTzBfH8DxIYcgo'
          : 'phc_ryMka3Ji8Hf5SIedewlqATKInfZy2eAo0KTpNpMRSbO';

      const apiHost =
        this.config.get('production') === 'false' ? 'https://posthog.stage.mspbackups.com' : 'https://posthog.mon.mspbackups.com';

      this.posthog.init(apiKey, {
        disable_session_recording: true,
        api_host: apiHost,
        loaded: () => {
          this.initialized = true;
          this.authService.currentUser
            .pipe(
              filter((x) => !!x),
              first(),
              tap((user: Administrator) => {
                const signUpDate = user.SignUpDate ? new Date(user.SignUpDate).toISOString() : '';
                this.posthog.identify(user.Id, {
                  firstName: user.FirstName,
                  lastName: user.LastName,
                  email: user.Email,
                  SignUpDate: signUpDate,
                  IsWizardComplete: user.IsWizardComplete,
                  IsProvider: user?.IsProvider
                });
              })
            )
            .subscribe();
        }
      });
    });
  }

  public startSessionRecording(): void {
    if (!this.initialized || this.posthog.sessionRecordingStarted()) return;

    this.posthog.startSessionRecording();
  }

  public stopSessionRecording(): void {
    if (!this.initialized) return;

    this.posthog.stopSessionRecording();
  }

  public needDeclinePostHog(): boolean {
    return !this.isAcceptedHost(location.host) || !this.isAcceptedPath(location.pathname) || !this.isAcceptedQuery(location.search);
  }

  public isAcceptedHost(host: string): boolean {
    const acceptedHosts: Array<string> = this.config.get('posthogAcceptedHosts') || [];
    const declinedHosts: Array<string> = this.config.get('posthogDeclinedHosts') || [];

    if (
      declinedHosts.some((mask) => {
        return this.isMatchWithMask(host, mask);
      })
    ) {
      return false;
    }

    if (
      acceptedHosts.some((mask) => {
        return this.isMatchWithMask(host, mask);
      })
    ) {
      return true;
    }

    return false;
  }

  public isAcceptedPath(path: string): boolean {
    const declinedPaths: Array<string> = this.config.get('posthogDeclinedPaths') || [];

    if (
      declinedPaths.some((mask) => {
        return this.isMatchWithMask(path, mask);
      })
    ) {
      return false;
    }

    return true;
  }

  public isAcceptedQuery(query: string): boolean {
    const declinedQueries: Array<string | { [query: string]: string }> = this.config.get('posthogDeclinedQuery') || [];
    if (!query || !declinedQueries.length) {
      return true;
    }

    const searchParams = new URLSearchParams(query.substring(query.indexOf('?')));
    const compare = declinedQueries.some((param) => {
      if (typeof param === 'string') {
        return searchParams.has(param);
      }

      const key = Object.keys(param)[0];

      return String(searchParams.get(key)) === String(param[key]);
    });

    return !compare;
  }

  private isMatchWithMask(value: string, mask: string): boolean {
    const lValue = value.toLowerCase();
    const lMask = mask.toLowerCase();

    if (lMask.startsWith('*')) {
      return lValue.endsWith(lMask.slice(1));
    }

    if (lMask.endsWith('*')) {
      return lValue.startsWith(lMask.slice(0, -1));
    }

    return lValue === lMask;
  }
}
