import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AbilityService } from 'ability';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { Observable } from 'rxjs';

@Injectable()
export class PermissionsGuard implements CanActivate, CanActivateChild {
  constructor(private ability: AbilityService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permissions = route.data['permissions'] as string[];

    if (permissions && permissions.length > 0) {
      if (permissions.every((p) => this.ability.can('read', p))) return true; // allow if user has all permissions

      return this.router.createUrlTree([this.ability.can('read', 'Provider') ? RoutingPath.ApDefaultAspx : RoutingPath.ApSettings]);
    }

    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
