<div class="d-flex flex-column align-items-center">
  <figure>
    <img alt="No Computers"
         src="assets/images/icons/NoComputers.svg"/>
  </figure>

  <span class="font-weight-bold">{{ 'computers.module:noComputersTitle' | i18next }}</span>

  <p class="pre-wrap text-center mt-2">{{ getDescription() }}</p>

  <div class="row">
    <div class="col-auto">
      <mbs-button [type]="'primary'"
                  [icon]="'fa fa-plus text-lg'"
                  (click)="onAddComputerButtonClick()"> {{ 'computers.module:addComputer' | i18next: {
        format: 'title'} }}
      </mbs-button>
    </div>

    <div *ngIf="isReturnToGswAvailable" class="col-auto">
      <mbs-button [type]="'primary'"
                  [icon]="'fa fa-arrow-circle-right text-lg'"
                  (click)="goToGettingStarted()"> {{ 'computers.module:gettingStartedWizard' | i18next: { format: 'title'} }}
      </mbs-button>
    </div>
  </div>
</div>
