<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           #modal>

  <div modal-body>
    {{ 'tfa:enablePushModal:info' | i18next }}
  </div>

  <ng-container modalFooter>

    <mbs-button type="primary"
                (click)="register()"
                [loading]="loading">
      {{ 'tfa:enablePushModal:scanQRCode' | i18next: { format: 'title' } }}
    </mbs-button>

    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">
      {{ 'tfa:cancel' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
