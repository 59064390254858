import { ChangeDetectorRef, Component, forwardRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlanMode } from '@root/mbs-ui/src/app/shared/models/PlanTypes.enum';
import { GuidEmpty, TableHeader } from 'mbs-ui-kit';
import { ComputerItem, ComputerToRestoreStepValue } from '../../models/selected-computers-models';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { ParamsForRCRestoreMethods, WizardStepsService } from '../../services/wizard-steps.service';
import { StepBase } from '../StepBase.class';

const SelectComputerStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => SelectComputerStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-select-computer-step',
  templateUrl: './select-computer-step.component.html',
  providers: [SelectComputerStepValueAccessor]
})
export class SelectComputerStepComponent extends StepBase<ComputerToRestoreStepValue> implements OnInit {
  @Input() storageId: string;

  public headers: TableHeader[] = [{ name: '', gridColSize: '10fr', class: '-stretch' }];
  public computers: ComputerItem[] = [];
  public computerName = '';

  private firstLoad = true;

  constructor(private cdr: ChangeDetectorRef, public mainService: RemoteManagementWizardsService, private stepService: WizardStepsService) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      computerName: new FormControl('', [Validators.required]),
      isCurrent: new FormControl(true)
    });

    this.initFormEvents();
  }

  onStepFormChange(value: ComputerToRestoreStepValue): void {
    if (value?.computerName) this.computerName = value.computerName;
    if (this.stepForm.dirty || this.stepForm.touched) this.value = { ...value, valid: this.stepForm.valid };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.storageId && this.stepForm && (!changes.storageId.currentValue || changes.storageId.currentValue === GuidEmpty)) {
      this.resetComputers();
    }
    if (changes.storageId && changes.storageId.currentValue && changes.storageId.currentValue !== GuidEmpty) {
      this.resetComputersIfCreateOrNotFirstLoad();
      const treeParams: ParamsForRCRestoreMethods = {
        agentType: 'backup',
        commandType: 'GetRestoreSourcePrefix',
        params: { ConnectionId: this.storageId }
      };
      this.loadInfo.emit({ loading: true });
      this.stepService
        .getRemoteCommandData(treeParams, this.mainService.hid)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (computers) => {
            if (computers && computers.data && computers.data.length) {
              this.computers = this.isLinux
                ? computers.data.map((p) => {
                    p.disabled = true;
                    return p;
                  })
                : computers.data;
              this.updateSelectedBunchFromName();
              this.mainService.currentPrefix = computers.data.find((c: ComputerItem) => c.isCurrent)?.name || '';
            }
            this.loadInfo.emit({ loading: false });
          },
          error: () => {
            this.loadInfo.emit({ loading: false });
            this.stepForm.get('isCurrent').setValue(false);
            this.stepForm.get('computerName').setValue('');
            this.computers = [];
          }
        });
    }
  }

  resetComputersIfCreateOrNotFirstLoad(): void {
    if (this.mainService.mode !== PlanMode.edit || !this.firstLoad) {
      if (this.stepForm) this.stepForm.get('computerName').markAsTouched();
      this.resetComputers();
    } else this.firstLoad = false;
  }

  resetComputers(): void {
    this.stepForm.get('isCurrent').setValue(false);
    this.stepForm.get('computerName').setValue('');
    this.computers = [];
  }

  updateSelectedBunchFromName(): void {
    const found = this.computers.find((c: ComputerItem) => c.name === this.computerName || (this.isLinux && c.isCurrent));
    if (found) {
      this.stepForm.get('isCurrent').setValue(found.isCurrent);
      this.stepForm.get('computerName').markAsTouched();
      this.stepForm.get('computerName').setValue(found.name);
      this.cdr.detectChanges();
    } else if (this.mainService.mode === PlanMode.create || this.isLinux) {
      const currentFound = this.computers.find((c: ComputerItem) => c.name === this.mainService.compName || c.isCurrent);
      if (currentFound) {
        this.stepForm.get('isCurrent').setValue(true);
        this.stepForm.get('computerName').markAsTouched();
        this.stepForm.get('computerName').setValue(currentFound.name);
        this.cdr.detectChanges();
      }
    }
  }

  clickHandler(): void {
    if (!this.stepForm.dirty) {
      this.stepForm.markAsDirty();
      this.stepForm.updateValueAndValidity();
    }
  }

  changeSelectedHandler(selectedComputers: string[]): void {
    if (selectedComputers && selectedComputers.length) {
      const name = selectedComputers[0];
      const comp = this.computers.find((c: ComputerItem) => c.name === name);
      if (comp) {
        this.stepForm.get('isCurrent').setValue(comp.isCurrent);
        this.stepForm.get('computerName').setValue(comp.name);
      }
    } else {
      this.stepForm.get('computerName').setValue('');
    }
    this.cdr.detectChanges();
  }
}
