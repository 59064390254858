export const getStringFromBase64Data = (data: string) => {
  const raw = atob(data);
  let result = '';

  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);

    result += hex.length === 2 ? hex : '0' + hex;

    if (i + 1 < raw.length) result += ' ';
  }

  return result.toUpperCase();
};
