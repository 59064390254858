import { AfterViewInit, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { UnifiedBillingAccount, UnifiedBillingAccountType } from '@models/storage-accounts/UnifiedBillingAccount';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { StorageAccountsService } from '@services/storage-accounts/storage-accounts.service';
import { POST_PAYMENT_TOKEN } from '@shared/components/licenses/tokens/post-payment.token';
import { DateTimeUtils } from '@utils/DateTimeUtils';
import { Alert, AlertService, bytesFrom, MbsPopupType } from 'mbs-ui-kit';
import * as moment from 'moment';
import 'moment-duration-format';
import { Observable, take } from 'rxjs';
import { UbSubscribeService } from '../ub-subscribe.service';

type UbAlertContext = { daysFromCreate: number; durationToRemoveOrExpire: string; totalUsageLimit: number; storage: UnifiedBillingAccount };

@UntilDestroy()
@Component({
  selector: 'mbs-ub-notifications',
  templateUrl: './ub-notifications.component.html',
  styles: []
})
export class UbNotificationsComponent implements AfterViewInit {
  readonly NEED_TO_SHOW_TRIAL_ALERT_DAYS_COUNT = 7;
  @ViewChild('alertTemplate', { static: true }) alertTemplate: TemplateRef<any>;
  private readonly alertIdPrefix = 'ub-notification';

  public UnifiedBillingAccountType = UnifiedBillingAccountType;

  private existsAlertIds: string[] = [];

  constructor(
    private storageService: StorageAccountsService,
    private alertService: AlertService,
    private appStorage: AppPersistentStateService,
    private router: Router,
    public ubSubscribe: UbSubscribeService,
    @Inject(POST_PAYMENT_TOKEN) public isPostPayment: Observable<boolean>
  ) {}

  ngAfterViewInit(): void {
    const userAccountAge = DateTimeUtils.getDaysCountBetweenDates(new Date(), new Date(this.appStorage.data.signUpDate));
    this.storageService.getUnifiedBillings();
    this.storageService.unifiedBillings$.pipe(untilDestroyed(this)).subscribe(({ items: storages }) => {
      // remove all UB alerts before
      this.existsAlertIds.forEach((alertId) => this.alertService.clearById(alertId));
      this.existsAlertIds = [];

      for (const storage of storages) {
        const alertId = this.alertIdPrefix + storage.id;

        if (userAccountAge < this.NEED_TO_SHOW_TRIAL_ALERT_DAYS_COUNT) {
          this.appStorage.data.showUbMessage[storage.id] = false;
          continue;
        }

        if (this.appStorage.data.showUbMessage[storage.id] === false) {
          continue;
        }

        const ubContext = this.updateMessage(storage);

        if (ubContext != null) {
          this.alertService
            .alert(
              new Alert({
                content: this.alertTemplate,
                context: ubContext as any,
                keepAfterRouteChange: true,
                type: MbsPopupType.danger,
                id: alertId
              })
            )
            .subscribe(() => {
              this.appStorage.data.showUbMessage = { ...this.appStorage.data.showUbMessage, [storage.id]: false };
            });

          this.existsAlertIds.push(alertId);
        }
      }
    });
  }

  updateMessage(storage: UnifiedBillingAccount): UbAlertContext {
    if (!storage) {
      return null;
    }

    const ubContext: Partial<UbAlertContext> = { storage };

    const daysFromCreate = moment.duration(moment().diff(moment.utc(storage.createDate, 'YYYY-MM-DD'))).asDays();
    ubContext.daysFromCreate = daysFromCreate;

    // also should same in ub-cancel-deletion.component.ts
    const durationToRemoveOrExpire = moment.duration(storage.usageTypeDaysCount, 'days').format('d [days]');
    ubContext.durationToRemoveOrExpire = durationToRemoveOrExpire;

    const totalUsageLimit = 2 * bytesFrom('TB');
    ubContext.totalUsageLimit = storage.ubTrialLimitSize || totalUsageLimit;

    const hidden =
      [UnifiedBillingAccountType.Paid, UnifiedBillingAccountType.Undefined].includes(storage.usageType) ||
      ([UnifiedBillingAccountType.TrialDeactivated, UnifiedBillingAccountType.PaidWithDebtDeactivated].includes(storage.usageType) &&
        storage.usageTypeDaysCount === 0) ||
      (this.ubSubscribe.deletedTypes.includes(storage.usageType) &&
        UnifiedBillingAccountType.PaidWithDebtManuallyDeactivated !== storage.usageType &&
        storage.usageTypeDaysCount === 0);

    return hidden ? null : (ubContext as UbAlertContext);
  }

  handleSubscribe(storage: UnifiedBillingAccount): void {
    this.ubSubscribe.showSwitchCommercial(storage);
  }

  handleGoLicensesPage(): void {
    // until we have iframe we need reload page for this page
    this.isPostPayment.pipe(take(1)).subscribe((isPostPayment) => {
      void this.router.navigate([isPostPayment ? RoutingPath.ApLicenses : RoutingPath.ApLicensesAspx], { queryParams: { renew: true } });
    });
  }
}
