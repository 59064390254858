import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { AdvancedSearchModel, MbsSize, ModalService, ModalSettings, SidepanelService, SortEvent, Template } from 'mbs-ui-kit';
import { BehaviorSubject, noop, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ScriptLibrarySearchTemplates } from '../../script-library-search-templates.service';
import { ScriptLibraryService } from '../../script-library.service';
import { SidepanelRmmManagementComponent } from '../../sidepanel/sidepanel-rmm-management.component';
import { DEFAULT_SORT, NEW_SCRIPT_HID } from '../../store/const';
import * as ScriptLibraryActions from '../../store/script-library.actions';
import {
  ScriptAccessLevel,
  ScriptLibraryAccessLevel,
  ScriptLibraryEntry,
  ScriptLibraryEntryExtends
} from '../../store/script-library.model';
import * as ScriptLibrarySelectors from '../../store/script-library.selectors';
import { CategoryListEditorComponent } from '../category-list-editor/category-list-editor.component';
import { ScriptLibraryEditorComponent } from '../script-library-editor/script-library-editor.component';

@UntilDestroy()
@Component({
  selector: 'mbs-script-library-manage',
  templateUrl: './script-library-manage.component.html',
  styleUrls: ['./script-library-manage.component.scss']
})
export class ScriptLibraryManageComponent implements OnInit, OnDestroy {
  public loading$: Observable<boolean>;
  public search$: BehaviorSubject<AdvancedSearchModel> = new BehaviorSubject({});
  public data$: Observable<ScriptLibraryEntry[]>;

  public model: AdvancedSearchModel;
  public searchTemplates: Template<any>[];

  public headers: any[];
  public searchForm: UntypedFormGroup;
  private uncategorized = 'Uncategorized';
  orderBy: SortEvent;

  constructor(
    private templateService: ScriptLibrarySearchTemplates,
    private scriptLibraryService: ScriptLibraryService,
    private store: Store,
    private modalService: ModalService,
    private sidepanelService: SidepanelService,
    private i18: I18NextPipe
  ) {
    this.createHeaders();
    this.load();

    this.searchTemplates = [this.templateService.category, this.templateService.script];
    this.loading$ = this.store.select(ScriptLibrarySelectors.loading);
    this.data$ = this.store.select(ScriptLibrarySelectors.selectList);
  }

  createHeaders() {
    this.headers = [
      {
        name: this.i18.transform('rmm-script-library.module:gridHeaders.script', { format: 'title' }),
        sort: 'name',
        gridColSize: '15fr',
        overflow: true
      },
      {
        name: this.i18.transform('rmm-script-library.module:gridHeaders.description', { format: 'title' }),
        sort: 'description',
        gridColSize: '25fr',
        overflow: true
      },
      {
        name: this.i18.transform('rmm-script-library.module:gridHeaders.category', { format: 'title' }),
        sort: ScriptLibraryEntryExtends.CATEGORIES,
        gridColSize: '15fr',
        overflow: true
      },
      {
        name: this.i18.transform('rmm-script-library.module:gridHeaders.access', { format: 'title' }),
        sort: 'accessLevel',
        gridColSize: '10fr',
        overflow: true
      },
      {
        name: this.i18.transform('rmm-script-library.module:gridHeaders.owner', { format: 'title' }),
        sort: 'owner',
        gridColSize: '15fr',
        overflow: true
      },
      {
        name: '',
        class: '-end',
        gridColSize: '10fr'
      }
    ];
  }

  ngOnInit(): void {
    this.store.dispatch(ScriptLibraryActions.clearFilters());
    this.store
      .select(ScriptLibrarySelectors.edit)
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe(() => this.openModal());
    this.setupUncategorised();
  }

  ngOnDestroy(): void {
    this.sidepanelService.removeByType(SidepanelRmmManagementComponent);
  }

  openScriptInfo(script: ScriptLibraryEntry): void {
    const sidepanel = this.sidepanelService.add(SidepanelRmmManagementComponent);
    sidepanel.data$.next({ script });
    this.sidepanelService.toggle('rmm_manage', {});
    sidepanel.tabset.select('general');
  }

  openModal() {
    const settings: ModalSettings = {
      size: MbsSize.md,
      responsive: true
    };
    const ref = this.modalService.openRef(ScriptLibraryEditorComponent, settings);

    ref.result.catch(() => noop).finally();
  }

  add() {
    this.store.dispatch(ScriptLibraryActions.callEditor({ id: NEW_SCRIPT_HID }));
  }

  edit(id: ScriptLibraryEntry['id']) {
    this.store.dispatch(ScriptLibraryActions.callEditor({ id }));
  }

  delete(entry: ScriptLibraryEntry) {
    const id = entry.id;
    const settings: ModalSettings = {
      header: { title: 'Delete Script' },
      footer: {
        okButton: {
          text: 'Delete',
          type: 'danger'
        },
        cancelButton: {
          text: 'Close',
          type: 'secondary'
        }
      }
    };

    this.modalService
      .open(
        settings,
        `
      The script ${entry.name} can be used in tasks.
      Delete anyway?
    `
      )
      .then(() => this.store.dispatch(ScriptLibraryActions.deleteScript({ id })))
      .catch(() => noop);
  }

  load() {
    this.store.dispatch(ScriptLibraryActions.clearScriptLibrary());
    this.store.dispatch(ScriptLibraryActions.loadScriptsInLibrary());
  }

  search(event: AdvancedSearchModel): void {
    const filters: AdvancedSearchModel = {
      tags: event.category,
      name: event.script,
      words: event.words
    };

    this.store.dispatch(ScriptLibraryActions.filterScriptsInLibrary({ filters }));
  }

  sort(sort: SortEvent): void {
    this.orderBy = sort;
    this.store.dispatch(ScriptLibraryActions.sortScriptsInLibrary({ sort: sort.direction ? sort : DEFAULT_SORT }));
  }

  openCategoryModal() {
    const settings: ModalSettings = {
      size: MbsSize.md,
      responsive: true
    };
    const ref = this.modalService.openRef(CategoryListEditorComponent, settings);

    ref.result.catch(() => noop).finally();
  }


  // Silent 'Uncategorized' category setup and creation if necessary
  setupUncategorised() {
    this.store.dispatch(ScriptLibraryActions.loadTags());

    this.store
      .select(ScriptLibrarySelectors.selectTags)
      .pipe(
        filter((categories) => !!categories.length),
        switchMap((categories) => {
          if (!categories.some((category) => category.name.toLowerCase() === this.uncategorized.toLowerCase())) {
            const params = new HttpParams({ fromObject: { accessLevel: ScriptAccessLevel.Public } });
            return this.scriptLibraryService.registerTag(this.uncategorized, params);
          }

          return of(null);
        }),
        untilDestroyed(this)
      )
      .subscribe((response) => {
        if (response) {
          this.store.dispatch(
            ScriptLibraryActions.addTag({
              tag: {
                accessLevel: ScriptLibraryAccessLevel.Public,
                name: this.uncategorized,
                tagGuid: response.result
              }
            })
          );
        }
      });
  }
}
