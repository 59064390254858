import { Component, Input, OnInit } from '@angular/core';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { OsType } from '@models/Computer';
import PrinterInfo from '@models/rmm/PrinterInfo';
import RmmLastStatData from '@models/rmm/RmmLastStatData';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RmmService } from '@services/rmm.service';
import { baseDateFileNameFormatHoursAndMinutes } from '@utils/date';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { get } from 'lodash/fp';
import { SharedPersistentStateEnum, TableHeader } from 'mbs-ui-kit';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import moment from 'moment';
import { concatMap, filter as rxfilter, finalize, map } from 'rxjs/operators';
import { RmmTabBaseComponent } from '../rmm-tab-base.component';

@UntilDestroy()
@Component({
  selector: 'mbs-printers-tab',
  templateUrl: './printers-tab.component.html'
})
export class PrintersTabComponent extends RmmTabBaseComponent<PrinterInfo> implements OnInit {
  @Input() computerName: string;
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;
  public translationKey = 'rmm-side-panel:printersTab.';
  public shortHeaders: TableHeader[] = [
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.name'),
      overflow: true,
      sort: 'name',
      gridColSize: '50fr'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.status'),
      sort: 'printerStatus',
      gridColSize: '20fr'
    }
  ];

  public fullHeaders: TableHeader[] = this.shortHeaders.concat([
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.lastErrorCode'),
      sort: 'lastErrorCode',
      gridColSize: '25fr',
      gridColMin: '115px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.errorDescription'),
      sort: 'errorDescription',
      overflow: true,
      gridColSize: '25fr',
      gridColMin: '120px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.deviceID'),
      sort: 'deviceID',
      overflow: true,
      gridColSize: '25fr'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.shareName'),
      sort: 'shareName',
      overflow: true,
      gridColSize: '25fr'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.location'),
      sort: 'location',
      overflow: true,
      gridColSize: '25fr'
    }
  ]);

  public isMacOS: boolean;

  constructor(
    private rmmService: RmmService,
    private store: Store<any>,
    private computersFacade: ComputersFacade,
    private i18n: I18NextPipe
  ) {
    super();
    this.headers = this.shortHeaders;
  }

  ngOnInit(): void {
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (computer && computer.os) this.isMacOS = computer.os === OsType.apple;
    });

    this.headers = this.isModal ? this.fullHeaders : this.shortHeaders;
    this.searchFields = ['name'];
    if (this.isModal) {
      this.searchFields = this.searchFields.concat(['location', 'shareName']);
    }
    this.initStreams();

    this.fetchData();
  }

  initStreams() {
    const lastStat$ = this.rmmService.fetchLastData<RmmLastStatData<PrinterInfo>>('printer').pipe(
      map(get('data')),
      finalize(() => this.onLoad$.next(false))
    );

    const dataLatest$ = this.onFetchLastData$.pipe(concatMap(() => lastStat$));

    dataLatest$.pipe(rxfilter(Boolean), untilDestroyed(this)).subscribe((stat: any[] = []) => {
      console.log(stat);
      this.allData = cloneDeep(stat);
      this.updateData(this.allData);
    });
  }

  getCachedData() {
    Promise.resolve().then(() => {
      this.onLoad$.next(true);
      this.onGetCachedData$.next({});
    });
  }

  fetchStatData(): void {
    this.onLoad$.next(true);
    this.onFetchLastData$.next({});
  }

  fetchData(): void {
    this.fetchStatData();
  }

  trackBy(index: number, item: ExtendedTableRow): string {
    return (item?.item as PrinterInfo)?.deviceID;
  }

  public getTableName(): string {
    return `${this.computerName} ${this.i18n.transform('rmm-side-panel:sidePanelTabsName.printers')} ${moment().format(
      baseDateFileNameFormatHoursAndMinutes
    )}`;
  }
}
