import { APP_BASE_HREF } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TFAService } from '@components/tfa/services/tfa.service';
import { convertToClientUrl } from '@root/mbs-ui/src/app/shared/utils/pipes/client-url.pipe';
import { AuthService } from '@services/auth.service';
import { noop } from 'lodash';
import { MbsPopupType } from 'mbs-ui-kit';
import { catchError, of, take } from 'rxjs';

@Component({
  selector: 'mbs-google-qr-code',
  templateUrl: './google-qr-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleQRCodeComponent implements OnInit {
  public setupKey: string = null;
  public QRCodeUri: string = null;
  public authId: string = null;
  public isQRCodeLoading = true;
  public code = null;
  public isInvalidCode = false;
  public isLoading = false;
  public readonly alertType = MbsPopupType;
  public isQRCodeInvalid = false;
  public error = null;

  @Output() onToggleAuthType = new EventEmitter();

  @Input() isFirstTimeLogin = false;
  @Input() isLogin = false;

  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private TFAService: TFAService,
    @Inject(APP_BASE_HREF) private appBaseHref: string
  ) {}

  ngOnInit(): void {
    this.setQRCode();
  }

  toggleAuthType(): void {
    this.onToggleAuthType.emit();
  }

  setQRCode(): void {
    this.isQRCodeInvalid = false;
    this.isQRCodeLoading = true;
    this.QRCodeUri = null;
    this.authId = null;
    this.error = null;

    this.TFAService.getGoogleQRCode()
      .pipe(
        take(1),
        catchError((error) => {
          this.isQRCodeInvalid = true;
          this.error = error.error.detail;
          return of(error);
        })
      )
      .subscribe((result) => {
        this.isQRCodeLoading = false;
        this.QRCodeUri = result.payload;
        this.setupKey = result.encodedSecretCode;
        this.authId = result.authId;
        this.cdr.detectChanges();
      });
  }

  validate(): void {
    this.isLoading = true;
    this.isInvalidCode = false;
    this.TFAService.activateGoogle(this.code, this.authId)
      .pipe(
        take(1),
        catchError(() => of(null))
      )
      .subscribe((res) => {
        this.isLoading = false;
        if (!res?.isValid) {
          this.isInvalidCode = true;
        }
        this.cdr.detectChanges();
      });
  }

  toLoginPage(): void {
    this.TFAService.logout()
      .pipe(take(1))
      .subscribe(() => {
        location.href = convertToClientUrl('/Admin/Login.aspx?logoff=1', this.appBaseHref);
      });
  }

  skip(): void {
    this.TFAService.refuse()
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigateByUrl('AP/Default.aspx').catch(noop);
      });
  }
}
