<mbs-sidepanel id="ip-white-list-panel"
               side="right"
               innerContentType="nowrap"
               [isCreate]="false"
               [showDelete]="false"
               [actionNames]="{ close: 'Reset'}"
               [loading]="loadingSave"
               [loadingData]="loadingData"
               [disabledSave]="('Readonly' | can:'save')"
               [disabledDelete]="('Readonly' | can: 'delete')">
  <div header-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-name">
      IP Allowlisting
    </div>
  </div>
  <div *ngIf="!loadingData"
       header-content>
    <div class="mbs-sidepanel_header-description">
      Current IP: {{ currentIP }}
    </div>
  </div>
  <div loading-data-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-ico text-success">
      <span class="fa fa-users"></span>
    </div>
    <div class="mbs-sidepanel_title-name">
      Loading IP Allowlisting...
    </div>
  </div>


  <ng-container body>
    <header class="mbs-sidepanel_content-header">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <h2 class="mbs-sidepanel_content-title">
            Enable IP Allowlisting
          </h2>
        </div>
        <div class="col-auto">
          <mbs-switcher [id]="'enable-ip-white-list'"
                        [(ngModel)]="enabled"
                        (ngModelChange)="handleEnableSettings()"></mbs-switcher>
        </div>
      </div>
    </header>
    <div class="mbs-sidepanel_content-scrolling tab-pane">
      <div class="mbs-form_content">
        <div class="mbs-form-group"
             *ngIf="enabled">
          <mbs-alert [type]="alertType.info"
                     [icon]="true">
            Note you can always use 2FA alternative codes to&nbsp;access your account regardless of&nbsp;the allowlist
            configuration.
            Make sure you have them <a [routerLink]="['/AP/Settings']">enabled.</a>
          </mbs-alert>
        </div>
        <div class="mbs-form-group">
          <div class="form-row">
            <div class="col-9">
              <mbs-input [clearButton]="true"
                         (clear)="handleFilterData()"
                         (buttonClick)="handleFilterData()"
                         [(ngModel)]="searchInput"
                         (keydown.enter)="handleFilterData()"
                         [appendButtons]="[{type:'secondary', icon: 'ico ico-Search', id:'search-in-white-list'}]"
                         placeholder="Search by Name or IP..."></mbs-input>

            </div>
            <div class="col-3">
              <mbs-button (click)="handleAddNew()"
                          type="primary"
                          [block]="true"
                          [disabled]="editIndex > -1">
                Add New
              </mbs-button>
            </div>
          </div>
        </div>

        <mbs-table [headers]="headers"
                   [data]="visibleData"
                   (sort)="handleSort($event)">
          <ng-template mbsTableCell
                       let-ips
                       let-index="rowIndex">
            <ng-container *ngIf="index !== editIndex">
              <span [ngClass]="{
                        'text-danger text-decoration-line-through': ips.State === IPWhiteListState.Delete,
                        'text-primary': [IPWhiteListState.Add, IPWhiteListState.Change].includes(ips.State)
                      }">
                {{ ips.description }}
              </span>
            </ng-container>
            <ng-container *ngIf="index === editIndex">
              <mbs-input [formControl]="editName"
                         placeholder="New Range"></mbs-input>
            </ng-container>
          </ng-template>
          <ng-template mbsTableCell
                       let-ipsRange
                       let-index="rowIndex">
            <span [ngClass]="{
                      'text-danger text-decoration-line-through': ipsRange.State === IPWhiteListState.Delete,
                      'text-primary': [IPWhiteListState.Add, IPWhiteListState.Change].includes(ipsRange.State)
                    }"
                  *ngIf="index !== editIndex">
              <ng-container *ngFor="let ipRaw of ipsRange.ips || [];">
                {{ ipRaw }}<br />
              </ng-container>
              <ng-container *ngFor="let ranges of ipsRange.ipRanges || []">
                {{ ranges }}<br />
              </ng-container>
            </span>
            <ng-container *ngIf="index === editIndex">
              <mbs-input type="textarea"
                         [formControl]="editRange"
                         rows="3"
                         [placeholder]="'192.168.1.100,192.168.1.100-192.168.1.150'">
              </mbs-input>
            </ng-container>

          </ng-template>
          <ng-template mbsTableCell
                       let-ips
                       let-index="rowIndex">
            <mbs-button-group *ngIf="index !== editIndex"
                              [disabled]="!!editModel">
              <mbs-button (click)="handleEdit(ips, index)">
                <span class="fa fa-pencil text-primary"></span>
              </mbs-button>
              <mbs-button (click)="handleDeleteRange(ips)">
                <span class="fa fa-remove text-danger"></span>
              </mbs-button>
            </mbs-button-group>
            <ng-container *ngIf="index === editIndex">
              <mbs-button type="primary"
                          (click)="handleEditSave(editModel, index)"
                          [block]="true">
                Save
              </mbs-button>
              <mbs-button (click)="handleEdit(ips, index)"
                          *ngIf="editModel.State === IPWhiteListState.Change"
                          [block]="true">
                Reset
              </mbs-button>
              <mbs-button (click)="handleEditCancel()"
                          *ngIf="editModel.State === IPWhiteListState.Add"
                          [block]="true">
                Cancel
              </mbs-button>
            </ng-container>
          </ng-template>

        </mbs-table>

      </div>
    </div>

  </ng-container>
</mbs-sidepanel>
