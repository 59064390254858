import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { AgentType } from '@models/Computer';
import { VirtualMachinesSelectedWithNumbersType, VirtualMachinesType } from '@modules/wizards/models/select-virtual-machines-models';
import { ModalService, ModalSettings } from 'mbs-ui-kit/modal/modal.service';
import { BehaviorSubject, concatMap, noop, Observable, of, throwError, timer } from 'rxjs';
import { catchError, filter, shareReplay, switchMap, take, takeUntil, timeout } from 'rxjs/operators';
import { ArchiveType } from '../models/backup-to-restore-models';

export type CheckLicenseResult = { usedSocketsCount: number; availableSocketsCount: number; isOk: boolean; message?: string };

export enum WindowsFeatures {
  hyperV,
  oneDrive,
  vMware,
  hyperVDiskListingSupported,
  hyperVChangedBlockTracking,
  hyperVApplicationProcessingOptions,
  hyperVCluster
}

export enum FilterRemoteCommand {
  All,
  Deleted,
  Actual,
  Changed
}

interface ParamsForAsync {
  isAsync?: boolean;
  requestId?: string;
}

interface AgentRemoteCommandParams {
  agentType: string;
  commandType: string;
  params: ParamsForAsync;
}

interface AgentTestHyperVParams extends AgentRemoteCommandParams {
  params: ParamsForAsync & {
    Feature: WindowsFeatures;
  };
}

interface AgentHttpClientMethodOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
}

export interface GetBackupContentParams extends AgentRemoteCommandParams {
  params: ParamsForAsync & {
    SessionId?: string;
    ConnectionId?: string;
    RestoreSourcePrefix?: string;
    BunchId?: string;
    RestorePointDateUtc?: string;
    ContentFilter?: string;
    Filter?: FilterRemoteCommand;
    Password?: string;
    Search?: string;
    path: string;
    offset: number;
    limit: number;
    order: string;
    BitlockerPasswordType?: BitLockerPasswordType;
    BitLockerPasswordValue?: string;
  };
}

export enum BitLockerPasswordType {
  Unset = 0,
  Password = 1,
  RecoveryPassword = 2,
  KeyFile = 3,
  CleanKey = 4
}

export interface ParamsForRCRestoreMethods extends AgentRemoteCommandParams {
  agentType: 'backup';
  commandType: 'GetBunchList' | 'GetRestoreSourcePrefix' | 'GetRestorePointList' | 'GetDiskInfo';
  params: ParamsForAsync & {
    ConnectionId?: string;
    RestoreSourcePrefix?: string;
    Type?: ArchiveType;
    BunchId?: string;
    GenerationId?: string;
    RestorePointDateUtc?: string;
  };
}

export interface ParamsForDeepSync extends AgentRemoteCommandParams {
  agentType: 'backup';
  commandType: 'RunDeepSync' | 'GetDeepSyncStatus';
  params: ParamsForAsync & {
    connectionId?: string;
    restoreSourcePrefix?: string;
    bunchId?: string;
    restorePointDateUtc?: string;
    Password?: string;
  };
}

export interface ParamsForTestCredentials extends AgentRemoteCommandParams {
  agentType: 'backup';
  commandType: 'CheckVMCredentials';
  params: ParamsForAsync & { Type: string; Server: string; Login: string; Password: string };
}

export interface ParamsForGetVirtualMachinesOrDisksList extends AgentRemoteCommandParams {
  agentType: 'backup';
  commandType: 'GetVirtualMachineList' | 'GetVirtualMachineDiskList';
  params: ParamsForAsync & {
    PlanId: string;
    VirtualMachineIds?: string[];
    Type: string;
    Server: string;
    Login: string;
    Password: string;
    IsCluster: boolean;
    Order?: string;
  };
}

export interface ParamsForCheckVMSocketLicenses extends AgentRemoteCommandParams {
  agentType: 'backup';
  commandType: 'CheckVMSocketLicenses';
  params: ParamsForAsync & {
    Type: VirtualMachinesType;
    Server: string;
    UserName: string;
    Password: string;
    PlanId: string;
    VMBackupType: VirtualMachinesSelectedWithNumbersType;
    Machines: string[];
  };
}

export interface ParamsForTreeData extends AgentRemoteCommandParams {
  params: ParamsForAsync & {
    connectionId?: string;
    planId?: string;
    path?: string;
    Password?: string;
    offset?: number;
    limit?: number;
    order?: string;
  };
}

export interface ParamsForIsWindowsFeatureEnabled extends AgentRemoteCommandParams {
  commandType: 'IsWindowsFeatureEnabled';
  params: ParamsForAsync & {
    Features: WindowsFeatures[];
  };
}

export interface ParamsForInstallFeature extends AgentRemoteCommandParams {
  commandType: 'InstallFeature';
  params: ParamsForAsync & {
    Feature: WindowsFeatures;
  };
}

export interface ParamsForDeleteBackupObject extends AgentRemoteCommandParams {
  agentType: 'backup';
  commandType: 'DeleteBackupObject';
  params: ParamsForAsync & { ObjectsToDelete?: string[] };
}

export interface ParamsForVerifyBackupPassword extends AgentRemoteCommandParams {
  agentType: 'backup';
  commandType: 'VerifyBackupPassword';
  params: ParamsForAsync & {
    path: string;
    password: string;
  };
}

export type getRemoteCommandDataType =
  | ParamsForTreeData
  | ParamsForTestCredentials
  | ParamsForGetVirtualMachinesOrDisksList
  | ParamsForCheckVMSocketLicenses
  | ParamsForRCRestoreMethods
  | ParamsForIsWindowsFeatureEnabled
  | ParamsForInstallFeature
  | ParamsForDeleteBackupObject
  | ParamsForVerifyBackupPassword;

export type MachinesDisksLoadedStatuses = { voidMachines: string[]; allVoid: boolean; loadingDisks: boolean };

@Injectable({
  providedIn: 'root'
})
export class WizardStepsService {
  public deepSyncSuccess$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public headers = { 'Content-Type': 'application/json; charset=utf-8' };

  private defaultInterval = 1 * 1000; // Every 1 seconds
  private defaultTimeout = 15 * 60 * 1000; // No longer than 15 minutes
  private featureStatuses: { [HID_features: string]: Observable<any> } = {};

  constructor(private http: HttpClient, private modalService: ModalService) {}

  prepareParams(params: getRemoteCommandDataType): void {
    const changeParamsRules = [
      {
        key: 'PlanId',
        replaceTo: null,
        wrongValues: ['']
      }
    ];

    changeParamsRules.forEach((rule) => {
      if (rule.wrongValues.includes((params as ParamsForCheckVMSocketLicenses)?.params[rule.key])) {
        (params as ParamsForCheckVMSocketLicenses).params[rule.key] = rule.replaceTo;
      }
    });
  }

  getRemoteCommandData(params: getRemoteCommandDataType, hid: string): Observable<any> {
    const needAsync = [
      'CheckVMSocketLicenses',
      'GetVirtualMachineList',
      'GetVirtualMachineDiskList',
      'GetVirtualMachineDiskContent',
      'DeleteBackupObject',
      'VerifyBackupPassword'
    ].includes(params.commandType);

    this.prepareParams(params);

    if (needAsync && !window['callAgentSync']) {
      return this.runAsyncCommand('post', `/api/computers/${hid}/remotecommand`, params, {
        headers: this.headers
      });
    }

    return this.runSyncCommand('post', `/api/computers/${hid}/remotecommand`, params, {
      headers: this.headers
    });
  }

  VMFeatureTest(hid: string, feature = WindowsFeatures.hyperV): Observable<any> {
    return this.getWindowFeaturesRequest(hid, feature).pipe(
      concatMap((data) => {
        const newData = { ...data };

        // Typical response for single feature contains result in data field
        // the Response for group features contains result in data.result field as a map
        // all we need to do for backward capability - is extract group result
        if (data?.data?.result) {
          newData.data = data.data.result[WindowsFeatures[feature]];
        }

        return of(newData);
      })
    );
  }

  showHyperVInfoModal(): void {
    const modalSettings: ModalSettings = {
      header: { title: 'Hyper-V is not installed' },
      footer: { okButton: { text: 'Ok' }, cancelButton: { show: false } }
    };
    const message = `Be aware, that in certain environments Hyper-V may not be installed correctly or affect system settings. <br/>
      For correct operation of the Restore Verification, install Hyper-V manually on target computers`;
    this.modalService.open(modalSettings, message).finally(noop);
  }

  infoModalShow(title: string, body: TemplateRef<any> | string): void {
    const modalSettings: ModalSettings = {
      header: { title: title },
      footer: { okButton: { text: 'Ok' }, cancelButton: { show: false } }
    };

    this.modalService.open(modalSettings, body).then().catch(noop);
  }

  private runAsyncCommand(
    method: 'post',
    url: string,
    params: AgentRemoteCommandParams,
    options: AgentHttpClientMethodOptions
  ): Observable<any> {
    return this.http[method](url, JSON.stringify({ ...params, params: { ...params.params, isAsync: true } }), options).pipe(
      switchMap((response) => {
        const requestId = this.getRequestId(response);

        if (requestId) {
          return timer(this.defaultInterval, this.defaultInterval).pipe(
            concatMap(() => this.http[method](url, JSON.stringify({ ...params, params: { requestId } }), options)),
            filter((newResponse) => !this.getRequestId(newResponse)),
            take(1),
            timeout(this.defaultTimeout),
            takeUntil(timer(this.defaultTimeout + this.defaultInterval))
          );
        }

        return of(response);
      }),
      catchError((e) => throwError(e))
    ) as Observable<any>;
  }

  private runSyncCommand(
    method: 'post',
    url: string,
    params: AgentRemoteCommandParams,
    options: AgentHttpClientMethodOptions
  ): Observable<any> {
    return this.http[method](url, JSON.stringify(params), options) as Observable<any>;
  }

  private getRequestId(response: unknown): string {
    return (response as unknown & { data?: { requestId: string } })?.data?.requestId || null;
  }

  private getWindowFeaturesRequest(hid: string, feature: WindowsFeatures): Observable<any> {
    const Features = this.getWindowFeaturesPack(feature);
    const key = `${hid}:${Features.join('-')}`;
    const params: ParamsForIsWindowsFeatureEnabled = {
      agentType: AgentType.Backup,
      commandType: 'IsWindowsFeatureEnabled',
      params: {
        Features: this.getWindowFeaturesPack(feature)
      }
    };

    this.featureStatuses[key] =
      this.featureStatuses[key] ||
      this.runSyncCommand('post', `/api/computers/${hid}/remotecommand`, params, {
        headers: this.headers
      }).pipe(shareReplay(1));

    return this.featureStatuses[key];
  }

  private getWindowFeaturesPack(feature: WindowsFeatures): WindowsFeatures[] {
    if (feature === WindowsFeatures.oneDrive) {
      return [WindowsFeatures.oneDrive];
    }

    if (feature === WindowsFeatures.vMware) {
      return [WindowsFeatures.vMware];
    }

    return [
      WindowsFeatures.hyperV,
      WindowsFeatures.hyperVDiskListingSupported,
      WindowsFeatures.hyperVChangedBlockTracking,
      WindowsFeatures.hyperVApplicationProcessingOptions,
      WindowsFeatures.hyperVCluster
    ];
  }
}
