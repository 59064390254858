import { Component, OnInit, ViewChild } from '@angular/core';
import { OverdueService, overdueSettingsParams } from '@services/overdue.service';
import { unidentifiedErrorText } from '@shared/interceptors/error-handler.interceptor';
import { EnumHelper, MbsPopupType, ModalComponent, ToastService } from 'mbs-ui-kit';
import { OverdueAfterSuccessPeriod } from '../wizards/models/schedule-models';

@Component({
  selector: 'mbs-overdue-modal',
  templateUrl: './overdue-modal.component.html'
})
export class OverdueModalComponent implements OnInit {
  public readonly alertType = MbsPopupType;
  public isSelected = true;
  public selectedComputers: {
    selectedPlansCount: number;
    selectedComputers?: { [key: string]: { isAll: boolean; plans: string[] } };
  } = null;
  public filters: any;

  public loading = false;

  public noSuccessfulMoreThan = false;
  public amount = 7;
  public period: OverdueAfterSuccessPeriod = OverdueAfterSuccessPeriod['Day(s)'];

  public OverduePeriod = EnumHelper.EnumToSelectIndexesArray(OverdueAfterSuccessPeriod);

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private overdueService: OverdueService, private toastService: ToastService) {}

  ngOnInit(): void {
    if (this.baseModal && this.baseModal.data) {
      this.isSelected = this.baseModal.data.isSelected;
      this.selectedComputers = this.baseModal.data.selectedComputers;
      if (!this.isSelected) this.filters = this.baseModal.data.filters;
    }
  }

  getParams(): overdueSettingsParams {
    const params: overdueSettingsParams = {
      overdueSettings: {
        enabled: this.noSuccessfulMoreThan,
        amount: this.amount,
        period: this.period
      }
    };
    if (this.isSelected) {
      params.planIds = [];
      Object.keys(this.selectedComputers.selectedComputers).forEach((key) => {
        this.selectedComputers.selectedComputers[key].plans.forEach((id) => params.planIds.push({ planId: id, computerHid: key }));
      });
    } else {
      params.filter = this.filters;
    }
    return params;
  }

  confirmClickHandler(): void {
    this.loading = true;
    const params: overdueSettingsParams = this.getParams();
    this.overdueService.overdueSettings(params, this.isSelected).subscribe(
      (data: { countToBeUpdated: number }) => {
        if (data) {
          this.showToast(data);
        }
        this.cancelClickHandler(data && !!data.countToBeUpdated);
      },
      (e) => {
        this.showToast(null, e);
        this.cancelClickHandler();
      }
    );
  }

  showToast(data: { countToBeUpdated: number }, error = null): void {
    const toast = {
      header: 'Success',
      content: data
        ? `${data.countToBeUpdated > 0 ? data.countToBeUpdated + ' plan(s)' : data.countToBeUpdated + ' plan'} will be updated`
        : '',
      type: MbsPopupType.success,
      delay: 5000
    };
    if (error) {
      toast.header = 'Error';
      toast.content = error.message ? error.message : unidentifiedErrorText;
      toast.type = MbsPopupType.danger;
    }
    this.toastService.toast(toast);
  }

  cancelClickHandler(isSuccess = false): void {
    this.loading = false;
    if (isSuccess) this.baseModal.save(true);
    else this.baseModal.close();
  }
}
