const mapSet = function(key) {
  // eslint-disable-next-line no-throw-literal
  throw "Can't add property " + key + ', map is not extensible';
};

const mapDelete = function(key) {
  // eslint-disable-next-line no-throw-literal
  throw "Can't delete property " + key + ', map is frozen';
};

const mapClear = function() {
  // eslint-disable-next-line no-throw-literal
  throw 'Can\'t clear map, map is frozen';
};

export function freezeMap(freeze) {
  freeze.set = mapSet;
  freeze.delete = mapDelete;
  freeze.clear = mapClear;
  freeze.freezed = true;
  Object.freeze(freeze);
  return freeze;
}
