import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UtilsModule } from '@utils/utils.module';
import { AbilityModule } from 'ability';
import { I18NextModule, I18NEXT_NAMESPACE } from 'angular-i18next';
import { BreadcrumbsModule, MbsUiKitModule } from 'mbs-ui-kit';
import { NgChartsModule, ThemeService } from 'ng2-charts';
import { ModalTabContentModule } from '../modal-tab-content/modal-tab-content.module';
import { EventChartComponent } from './event-chart/event-chart.component';
import { EventEntryModalComponent } from './event-entry-modal/event-entry-modal.component';
import { EventEntryComponent } from './event-entry/event-entry.component';
import { EventGridComponent } from './event-grid/event-grid.component';
import { EventStateComponent } from './event-state/event-state.component';
import { EventTotalTabComponent } from './event-total-tab.component';
import { eventChartReducer, eventChartsFeatureKey } from './store/event-chart.reducer';
import { eventEntryFilterReducer, eventEntryFiltersFeatureKey } from './store/event-entry-filter.reducer';
import { eventEntriesFeatureKey, eventEntryReducer } from './store/event-entry.reducer';
import { EventTotalEffects } from './store/event-total.effects';
import { eventTotalFeatureKey, eventTotalReducer } from './store/event-total.reducer';
import { RmmDateTimeFormatModule } from '@components/date-time-format/rmm-date-time-format.module';

const declarations = [
  EventTotalTabComponent,
  EventStateComponent,
  EventGridComponent,
  EventChartComponent,
  EventEntryComponent,
  EventEntryModalComponent
];
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MbsUiKitModule,
    AbilityModule,
    UtilsModule,
    NgSelectModule,
    NgbTooltipModule,
    BreadcrumbsModule,
    NgbModule,
    NgChartsModule,
    I18NextModule,
    RouterModule,
    ModalTabContentModule,
    RmmDateTimeFormatModule,
    StoreModule.forFeature(eventChartsFeatureKey, eventChartReducer),
    StoreModule.forFeature(eventEntriesFeatureKey, eventEntryReducer),
    StoreModule.forFeature(eventTotalFeatureKey, eventTotalReducer),
    StoreModule.forFeature(eventEntryFiltersFeatureKey, eventEntryFilterReducer),
    EffectsModule.forFeature([EventTotalEffects])
  ],
  exports: declarations,
  declarations: declarations,
  providers: [
    NgbActiveModal,
    ThemeService,
    {
      provide: I18NEXT_NAMESPACE,
      useValue: ['app', 'event-total.module']
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventTotalTabModule {}
