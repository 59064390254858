<ng-container *ngIf="!(isLoading$ | async); else loader">
  <ng-container *ngIf="!(isEmpty$ | async); else noComputers">
    <mbs-empty-page></mbs-empty-page>
  </ng-container>

  <ng-template #noComputers>
    <div class="mt-5">
      <mbs-no-computers></mbs-no-computers>
    </div>
  </ng-template>
</ng-container>

<ng-template #loader>
  <mbs-loader></mbs-loader>
</ng-template>
