import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, Input, OnInit } from '@angular/core';
import { Build, BuildOsType } from '@models/build';
import { AgentType } from '@models/Computer';
import { BuildsFacade } from '@facades/builds.facade';
import Brand from '@models/Brand';
import {
  getTilesConfig
} from '@components/add-computer/steps/download-and-install/download-and-install.constants';
import { I18NextPipe } from 'angular-i18next';
import { WizardForm } from '@components/add-computer/add-computer.types';
import { InstallationMode } from '@components/add-computer/add-computer.constants';

@UntilDestroy()
@Component({
  selector: 'add-computer-wizard-download-and-install-step',
  templateUrl: './download-and-install.component.html',
  styleUrls: ['./download-and-install.component.scss', '../../add-computer.component.scss']
})
export class DownloadAndInstallComponent implements OnInit {
  public readonly agentType = AgentType;
  public readonly tilesConfig = getTilesConfig(this.i18N);
  public readonly os = BuildOsType;

  public buildsSettings$ = this.buildsFacade.buildsSettings$;
  public openedPanelIds = [];
  public buildsInProgress = [];
  public installationModeEnum = InstallationMode;

  @Input() displayedBuilds: Build[];
  @Input() licensedBrandList: Brand[];
  @Input() wizardForm: WizardForm;
  @Input() networkDiscoveryBuild: Build;

  constructor(private buildsFacade: BuildsFacade, private i18N: I18NextPipe) {
  }

  ngOnInit(): void {
    this.buildsFacade.buildsInProgress$.subscribe((buildsInProgress) => {
      this.buildsInProgress = buildsInProgress;
    });
  }

  public onBuildPanelClick({panelId, opening}: {panelId: string; opening: boolean}) {
    opening && (this.openedPanelIds = [panelId]);
  }
}
