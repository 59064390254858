import { StorageType } from '@models/StorageType.enum';

export enum ImmutabilityMode {
  Disabled = 0,
  Compliance = 1,
  Governance = 2
}

export interface StorageConnection {
  DisplayName: string;
  ID: string;
  IsSupportCompression?: boolean;
  IsSupportSSE?: boolean;
  ImmutabilityMode?: ImmutabilityMode;
  S3CompatibleSynthetic?: boolean;
  StorageType: StorageType;
  StorageAccountType: string;
  StorageAccountName?: string;
  Bucket?: string;
  DestinationName?: string;
  Region?: string;
  CanBeSelected?: boolean;
  disabled?: boolean;
  MinimumStorageDuration: null | number;
}
