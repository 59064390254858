import { I18NextPipe } from 'angular-i18next';
import { BuildSelectionType } from '@modules/wizards/steps/installation/installation.types';
import { BuildType } from '@models/BuildType.enum';
import { OsType } from '@models/Computer';

export const getBuildsSelectionConfig = (i18n: I18NextPipe): BuildSelectionType[] => {
  return [
    {
      buildType: BuildType.RMMAgent,
      name: i18n.transform('rmm.windows'),
      iconClass: 'ico ico-windows',
      os: OsType.windows
    },
    {
      buildType: BuildType.RMMAgentMac,
      name: i18n.transform('rmm.mac'),
      iconClass: 'fa fa-apple download-build_logo-icon -lg',
      os: OsType.apple
    },
    {
      buildType: BuildType.RMMAgentLinuxRpm,
      name: i18n.transform('rmm.linuxRpm'),
      iconClass: 'fa fa-linux download-build_logo-icon -lg',
      os: OsType.linux
    },
    {
      buildType: BuildType.RMMAgentLinuxDeb,
      name: i18n.transform('rmm.linuxDeb'),
      iconClass: 'fa fa-linux download-build_logo-icon -lg',
      os: OsType.linux
    }
  ];
};
