import { HostInfoMemory } from './HostInfo';
export interface SummaryStatAlert {
  alertId: string;
  type: string;
  state: string;
  title: string;
  data: string;
  beginUtcTime: Date;
  endUtcTime: Date;
}

export interface SummaryStatCounter {
  title: string;
  state: 'OK' | 'Warning' | 'Danger' | 'Alarm';
  description: string;
  value?: string;
  extData?: SummaryExtension[];
}

export interface SummaryExtension {
  key: string;
  value: string;
}

export enum SummaryStatusType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  MUTED = 'muted',
  UNKNOW = 'muted'
}

export enum SummaryStatusIcon {
  SUCCESS = 'fa fa-check-circle',
  INFO = 'fa fa-info-circle',
  WARNING = 'fa fa-exclamation-triangle',
  DANGER = 'fa fa-exclamation-circle',
  MUTED = 'fa fa-circle',
  UNKNOW = 'ico ico-question-circle'
}
export interface SummaryStatus {
  type: SummaryStatusType;
  icon: SummaryStatusIcon;
  text?: string;
  clickable?: boolean;
}
export interface SummaryStatusBar {
  value: string;
  text?: string;
}

export type RamSummaryStat = SummaryStatCounter & Partial<HostInfoMemory>;

export default interface SummaryStat {
  cpu: SummaryStatCounter;
  ram: RamSummaryStat;
  hdd: SummaryStatCounter;
  antivirus: SummaryStatCounter;
  update: SummaryStatCounter;
  eventLog: SummaryStatCounter;
  smart: SummaryStatCounter;
  systemTime: SummaryStatCounter;
  temperature: SummaryStatCounter;
  alerts: SummaryStatAlert[];
  computerState: string;
  monitoring?: boolean;
  online?: boolean;
}
