import EventLogInfo, { EventLogTypesEnum, IEventLogEntry } from '@models/rmm/EventLogInfo';
import { FilterEntry } from '../event-total-tab.model';

export const ActionPrefix = '[EventTotal/API]';

export interface EventEntry extends IEventLogEntry {
  id?: number;
  level?: string;
  cssClass?: string;
  time?: Date | string;
  timeUTC?: Date;
  source: string;
  logType: EventLogTypesEnum;
  icon?: string;
  order?: number;
}

export interface EventChart extends EventLogInfo {
  id: any;
}

export type EventEntryFilter = FilterEntry;
