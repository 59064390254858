import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule, TreeModule } from 'mbs-ui-kit';
import { IterationCtrlButtonModule } from '../iteration-ctrl-button/iteration-ctrl-button.module';
import { UtilitySelectionModalComponent } from './utility-selection-modal.component';

@NgModule({
  declarations: [UtilitySelectionModalComponent],
  imports: [
    CommonModule,
    MbsUiKitModule,
    FormsModule,
    ReactiveFormsModule,
    IterationCtrlButtonModule,
    TreeModule,
    I18NextModule
  ],
  exports: [UtilitySelectionModalComponent]
})
export class UtilitySelectionModalModule {}
