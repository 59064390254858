import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { StorageAccountsService } from '@services/storage-accounts/storage-accounts.service';
import { BehaviorSubject } from 'rxjs';
import { Balance } from '../../licenses/models/license.model';
import { BALANCE_TOKEN } from '../../licenses/tokens/balance.token';
import { SubscribeUbComponent } from '../subscribe-ub-base';

@Component({
  selector: 'mbs-subscribe-ub-aws',
  templateUrl: './subscribe-ub-aws.component.html',
  styleUrls: ['./subscribe-ub-aws.component.scss']
})
export class SubscribeUbAwsComponent extends SubscribeUbComponent {
  constructor(
    storageAccounts: StorageAccountsService,
    auth: AuthService,
    router: Router,
    @Inject(DOCUMENT) dom: Document,
    @Inject(BALANCE_TOKEN) public balance: BehaviorSubject<Balance>
  ) {
    super(storageAccounts, auth, router, dom);
  }

  handleResolve(): void {
    super.handleResolve(this.acceptTerms, this.selectedStorageId, 'uba_toc_agree');
  }
}
