import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

export interface Environment {
  production: boolean;
  useSessionToken: boolean;
  sentry: {
    dsn: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements Environment {
  get production() {
    return environment.production;
  }

  get useSessionToken() {
    return environment.production;
  }

  get sentry() {
    return environment.sentry;
  }
}
