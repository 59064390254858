<mbs-modal [title]="'Confirm Transition to Dashboard'">

  <ng-container *ngIf="domain$ | async as domain"
                modal-body>

    <mbs-alert [type]="MbsPopupType.warning"
               [icon]="true">
      The provider has full access to the backup management, but does not have access to the backup data. To access the backup data, after the transition, sign out and then sign in using an account that has the required administrative permissions in this domain
    </mbs-alert>

    <p class="mb-0">
      Do you want to open the backup management Dashboard for <span class="font-weight-semibold">{{ domain }}</span>?
    </p>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button *ngIf="domain$ | async"
                [type]="'primary'"
                class="mr-2"
                (click)="confirmClickHandler()">Open</mbs-button>
    <mbs-button [type]="'secondary'"
                (click)="cancelClickHandler()">Cancel</mbs-button>
  </ng-container>

</mbs-modal>
