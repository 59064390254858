<mbs-modal *ngIf="(data$ | async) as data"
           [isCustomModal]="true"
           [showExpandedCross]="true"
           [showFooter]="true"
           [showHeader]="true"
           #modal>
  <ng-container modal-header>
    {{ title$ | async }}
  </ng-container>
  <div modal-body>
    <div class="ph-no-capture mbs-event-entry">

      <div class="text-sm text-monospace text-break p-3 background-secondary border overflow-y-auto"
           [ngClass]="isMacOrLinuxOS ? 'event-message-description_for-mac' : 'event-message-description'">
        {{ data?.message }}
      </div>

      <ng-container *ngIf="!isMacOrLinuxOS">
        <div class="row">
          <div *ngFor="let item of (dataTable$ | async)"
               class="col-6">
            <div class="mt-2 d-flex justify-content-between">
              <div class="text-sm text-muted">
                {{ 'event-total.module:labels.' + item.key | i18next: {format: 'title'} }}
              </div>
              <div [ngSwitch]="item.key"
                   class="text-sm text-right ml-2">
                <ng-container *ngSwitchCase="'logLevel'">
                  <span [ngClass]="'text-' + item.cssClass"
                        class="mbs-status_ico -md fa fa-circle"></span>
                  {{ item.value || ('emptyValue' | i18next) }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ item.value || ('app:emptyValue' | i18next) }}
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container modalFooter>

    <ng-container *ngIf="(siblings$ | async) as siblings">
      <mbs-button [disabled]="!siblings?.prev"
                  [type]="'secondary'"
                  [icon]="'ico ico-arrLeft mr-0'"
                  (click)="showSibling(siblings?.prev)"></mbs-button>
      <mbs-button [disabled]="!siblings?.next"
                  [type]="'secondary'"
                  [icon]="'ico ico-arrRight mr-0'"
                  (click)="showSibling(siblings?.next)"></mbs-button>
    </ng-container>

    <mbs-button [type]="'secondary'"
                (click)="close()"> {{ 'common.close' | i18next: { format: 'title' } }} </mbs-button>
  </ng-container>
</mbs-modal>
