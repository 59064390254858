import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SummaryComputersModule } from '@modules/summary-computers/summary-computers.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderModule } from 'mbs-ui-kit/loader/loader.module';
import { DateTimeFormatComponent } from './date-time-format.component';

@NgModule({
  imports: [NgbTooltipModule, SummaryComputersModule, CommonModule, LoaderModule],
  declarations: [DateTimeFormatComponent],
  exports: [DateTimeFormatComponent]
})
export class DateTimeFormatModule {}
