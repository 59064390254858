import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AbilityService } from 'ability';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class StatisticsGuard implements CanActivate {
  constructor(private authService: AuthService, private ability: AbilityService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(this.ability.can('read', 'Provider'));
  }
}
