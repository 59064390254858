import { APP_BASE_HREF, Location, ViewportScroller } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html'
})
export class HelpComponent implements OnInit {
  frameUrl: SafeResourceUrl;
  baseHref: string;
  frameHeight = '4000px';
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private location: Location,
    private viewportScroll: ViewportScroller,
    @Inject(APP_BASE_HREF) href
  ) {
    this.baseHref = href;
  }

  ngOnInit() {
    let pageName = this.location.path(true).replace('/AP/Help', '');
    if (pageName.startsWith('/')) {
      pageName = pageName.substr(1);
    }
    // Need to discuss with Alexander.M for undestend what is origin and basicOrigin
    // const helpUrlStr = `https://mbshelp.cloudberrylab.com/${pageName}?origin=${this.baseHref}/AP/Help&basicOrigin=${this.baseHref}`;

    const helpUrlStr = `https://help.mspbackups.com/${pageName}`;

    this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(helpUrlStr);
  }

  @HostListener('window:message', ['$event']) message(event) {
    if (event.data.maxHeight) {
      this.frameHeight = `${event.data.maxHeight}px`;
    }

    if (event.data.path) {
      this.location.replaceState(`/AP/Help/${event.data.path.slice(1)}`);
    }

    if (event.data.title) {
      this.titleService.setTitle(event.data.title);
    }

    if (event.data.externalUrl) {
      window.open(event.data.externalUrl, '_blank');
    }

    if (event.data.anchorOffsetTop) {
      this.viewportScroll.scrollToPosition([0, event.data.anchorOffsetTop]);
    }
  }
}
