<mbs-modal [isCustomModal]="true"
           [showFooter]="true"
           [showCloseCross]="true"
           #modal>
  <ng-container modal-header>
    {{ 'rmm-script-library.module:createScriptModal.title' | i18next: { format: 'title', word: isCreateMode ?
    'Add New' : 'Edit' }
    }}
  </ng-container>

  <div modal-body
       [formGroup]="form">

    <div class="row">
      <div class="col-12">
        <mbs-form-group>
          <label class="font-weight-semibold">
            {{ 'rmm-script-library.module:createScriptModal.labels.name' | i18next: { format: 'title' } }}
          </label>
          <mbs-input formControlName="name"
                     maxLength="50"
                     [placeholder]="'rmm-script-library.module:createScriptModal.placeholders.name' | i18next">
          </mbs-input>
        </mbs-form-group>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <mbs-form-group>
          <label class="font-weight-semibold">
            {{ 'rmm-script-library.module:createScriptModal.labels.type' | i18next: { format: 'title' } }}
          </label>

          <div class="row m-1 ">
            <button class="p-2 mr-1 bg-transparent border border-primary rounded text-md"
                    (click)="setScriptType()"
                    [disabled]="!isCreateMode"
                    [hidden]="!isCreateMode && isBashScript"
                    [ngClass]="[!isBashScript ? 'border-primary text-primary' : 'border-dark text dark', !isCreateMode ? 'col-6' : 'col' ]">
              <div class="row">
                <div class="col-2 d-flex justify-content-center align-items-center">
                  <span class="ml-2 ico ico-powerShell script-type-icon-size"></span>
                </div>
                <div class="col-10 text-left">
                  <div class="font-weight-semibold">
                    {{ 'rmm-script-library.module:createScriptModal.types.powershell' | i18next }}
                  </div>
                  <div>
                    {{ 'rmm-script-library.module:createScriptModal.windowBased' | i18next }}
                  </div>
                </div>
              </div>
            </button>

            <button class="p-2 ml-1 bg-transparent border rounded text-md"
                    [disabled]="!isCreateMode"
                    (click)="setScriptType(true)"
                    [hidden]="!isCreateMode && !isBashScript"
                    [ngClass]="[isBashScript ? 'border-primary text-primary' : 'border-dark text dark', !isCreateMode ? 'col-6' : 'col' ]">
              <div class="row">
                <div class="col-2 d-flex justify-content-center align-items-center">
                  <span class="ml-2 ico ico-bash script-type-icon-size"></span>
                </div>
                <div class="col-10 text-left">
                  <div class="font-weight-semibold">
                    {{ 'rmm-script-library.module:createScriptModal.types.bash' | i18next }}
                  </div>
                  <div>
                    {{ 'rmm-script-library.module:createScriptModal.unixBased' | i18next }}
                  </div>
                </div>
              </div>
            </button>

          </div>
        </mbs-form-group>
      </div>
    </div>


    <div class="row mb-0">
      <div class="col-12">
        <mbs-form-group>

          <label class="font-weight-semibold">
            {{ 'rmm-script-library.module:createScriptModal.labels.accessToScripts' | i18next: { format: 'title' } }}
          </label>
          <div class="row">
            <div class="col-auto">
              <mbs-switcher formControlName="isPublic"
                            id="enabled"
                            [disabled]="!isCreateMode">
              </mbs-switcher>
            </div>

            <div class="col">
              <label class="mbs-form_label mb-0"
                     for="enabled">
                {{ 'rmm-script-library.module:createScriptModal.accessToScriptDescription' | i18next }}
              </label>

            </div>
          </div>
        </mbs-form-group>
      </div>
    </div>

    <hr>

    <div class="row mb-2">
      <div class="col-12">
        <mbs-form-group>
          <label class="font-weight-semibold">
            {{ 'rmm-script-library.module:createScriptModal.labels.category' | i18next }}
          </label>
          <div class="mb-2">
            {{ 'rmm-script-library.module:createScriptModal.categoryGroup' | i18next }}
          </div>

          <div class="mbs-select mbs-select-editable">
            <ng-select #select
                       [multiple]="true"
                       formControlName="tags"
                       [placeholder]="'rmm-script-library.module:createScriptModal.placeholders.category' | i18next">
              <ng-template ng-label-tmp
                           let-item="item">
                {{ item }}
              </ng-template>

              <ng-option *ngFor="let cat of categories"
                         [value]="cat">{{cat}}</ng-option>
              <ng-template ng-footer-tmp>
                <mbs-button type="link"
                            (click)="addCategory(select)">
                  {{ 'rmm-script-library.module:createScriptModal.createBtn' | i18next }}
                </mbs-button>
              </ng-template>
            </ng-select>
          </div>
          <div>
            <ng-container *ngTemplateOutlet="createCategoryTemplate"></ng-container>
          </div>
        </mbs-form-group>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <mbs-form-group>
          <label class="font-weight-semibold">
            {{ 'rmm-script-library.module:createScriptModal.labels.description' | i18next }}
          </label>
          <mbs-input formControlName="description"
                     maxLength="250"
                     type="textarea"
                     [rows]="5"
                     [placeholder]="'rmm-script-library.module:createScriptModal.placeholders.description' | i18next">
          </mbs-input>
        </mbs-form-group>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-12">
        <label class="font-weight-semibold">{{ scriptLabelText }}</label>
      </div>
      <div class="col-12">
        <div class="row button-group text-center m-0 mb-1">
          <button class="col btn btn-secondary rounded-0 d-flex justify-content-center align-items-center"
                  [class.active]="!uploadMode"
                  (click)="setUploadMode(false)">
            {{ 'rmm-script-library.module:createScriptModal.scriptBtn' | i18next }}
          </button>
          <button class="col btn btn-secondary rounded-0 d-flex justify-content-center align-items-center"
                  [class.active]="uploadMode"
                  (click)="setUploadMode(true)">
            {{ 'rmm-script-library.module:createScriptModal.uploadBtn' | i18next }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12"
           *ngIf="!uploadMode">
        <mbs-form-group>
          <mbs-code-editor formControlName="body"></mbs-code-editor>
        </mbs-form-group>
      </div>

      <div class="col-12"
           *ngIf="uploadMode">
        <mbs-drop-file [width]="'100%'"
                       [height]="'100px'"
                       [labelText]="'rmm-script-library.module:createScriptModal.uploadScriptDescription' | i18next"
                       [returnBase64Allways]="true"
                       (fileLoad)="fileLoadHandler($event)"
                       [accept]="fileExtension">
        </mbs-drop-file>
      </div>
    </div>
  </div>

  <ng-container modalFooter>
    <mbs-button *ngIf="!isCreateMode"
                type="primary"
                [disabled]="form.invalid"
                (click)="handleSave()">
      {{ 'buttons:save' | i18next }}
    </mbs-button>
    <mbs-button *ngIf="isCreateMode$ | async"
                type="primary"
                [disabled]="form.invalid"
                (click)="handleAdd()">
      <span class="fa fa-plus"></span>
      {{ 'rmm-script-library.module:createScriptModal.addBtn' | i18next: { format: 'title' } }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">
      {{ 'buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>

</mbs-modal>


<ng-template #createCategoryTemplate>
  <form *ngIf="showCreateCategoryForm"
        [formGroup]="newCategoryForm"
        class="my-2 row d-flex align-items-center">

    <div class="col-8">
      <mbs-input formControlName="name"
                 required
                 [placeholder]="'rmm-script-library.module:createScriptModal.placeholders.category' | i18next">
      </mbs-input>
    </div>

    <div class="row col-4">
      <div class="col-8">
        <mbs-button [isCtrl]="true"
                    [icon]="'fa fa-plus'"
                    (click)="createCategory()">
          {{ 'rmm-script-library.module:createScriptModal.addCategoryBtn' | i18next }}
        </mbs-button>
      </div>
      <div class="col-4">
        <mbs-button [isCtrl]="true"
                    (click)="closeCreateCategoryForm()">
          {{ 'buttons:cancel' | i18next }}
        </mbs-button>
      </div>
    </div>
  </form>
  <div *ngIf="showError"
       class="text-danger text-sm">
    {{ 'rmm-script-library.module:createScriptModal.createCategoryErrorMsg' | i18next }}
  </div>
</ng-template>
