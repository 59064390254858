import { Component } from '@angular/core';
import { MbsPopupType } from 'mbs-ui-kit';

@Component({
  selector: 'remote-desktop-onboarding-wizard',
  templateUrl: './remote-desktop-onboarding.component.html'
})
export class RemoteDesktopOnboardingWizardComponent {
  public readonly alertType = MbsPopupType;
  public readonly steps = {
    description: 'description',
    download: 'download'
  }
}
