import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { GroupTask } from '@modules/group-tasks/store/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { MbsSize, ModalService } from 'mbs-ui-kit';
import { Observable, combineLatest, first, map, noop, pluck, take } from 'rxjs';

import { GAActions, GASelectors } from '../../../../store/group-action';
import { GroupActionsCommands } from '../../../../store/group-action/group-action.model';
import EditGroupTaskUtility from '../../../../utility/edit-group-task-utility';
import { SelectionModalSettings } from './selection-modal/selection-modal-models';
import { SelectionModalComponent } from './selection-modal/selection-modal.component';
import {
  DEFAULT_UPDATE_POLICY_PARAMETERS,
  LocalUpdatesItem,
  ModalSelectionType,
  NotifyTimeType,
  SeverityLevels,
  UpdatePolicyFormValues,
  UpdatePolicyObjectForSelection,
  UpdatePolicyParameters,
  WindowsUpdateCategories
} from './update-policy.models';
import { parseDeferTimeFromGroupTask } from './update-policy.utility';

@UntilDestroy()
@Component({
  selector: 'mbs-update-policy',
  templateUrl: './update-policy.component.html',
  styles: []
})
export class UpdatePolicyComponent implements OnInit {
  private translationKey = 'rmm.module:groupActions.updatePolicy.';
  private severityLevels = Object.keys(SeverityLevels).map((key, index) => ({
    id: index,
    name: this.i18next.transform(this.translationKey + SeverityLevels[key])
  }));

  private winUpdateCategories = Object.keys(WindowsUpdateCategories).map((key, index) => ({
    id: index,
    name: this.i18next.transform(this.translationKey + WindowsUpdateCategories[key])
  }));

  private selectedSeverityLevels: UpdatePolicyObjectForSelection[] = [];
  private selectedUpdateCategories: UpdatePolicyObjectForSelection[] = [];
  private selectedToExcludedUpdates: string[] = [];

  public selectedToExcludedUpdatesToShow: { name: string }[] = [];

  public notifyItems = Object.keys(NotifyTimeType).map((key) => NotifyTimeType[key]);

  public requiredNotifyTimeItems = [
    this.getTranslation('notificationNoType'),
    this.getTranslation('notificationMinutesType', { value: 15 }),
    this.getTranslation('notificationMinutesType', { value: 30 }),
    this.getTranslation('notificationHourType'),
    this.getTranslation('notificationHoursType', { value: 3 }),
    this.getTranslation('notificationHoursType', { value: 8 }),
    this.getTranslation('notificationHoursType', { value: 12 })
  ] as const;

  private localUpdatesItems: LocalUpdatesItem[] = [
    {
      name: this.getTranslation('localUpdatesSelectionItems.noAction'),
      value: null
    },
    {
      name: this.getTranslation('localUpdatesSelectionItems.enable'),
      value: true
    },
    {
      name: this.getTranslation('localUpdatesSelectionItems.disable'),
      value: false
    }
  ];

  public localUpdatesSelectionItems = this.localUpdatesItems.map((value) => value.name);

  private defaultDeferNumber = '1' as const;
  private defaultRebootRequiredTime = '12:00' as const;

  public updatePolicyForm = new UntypedFormGroup({
    severityLevel: new FormControl(false),
    updateCategory: new FormControl(false),
    excludeUpdates: new FormControl(false),
    localUpdatesSelection: new FormControl(this.localUpdatesSelectionItems[0]),
    localUpdatesPriority: new FormControl(false),
    updateDefer: new FormControl(false),
    updateDeferNumber: new FormControl(this.defaultDeferNumber),
    updateDeferType: new FormControl(NotifyTimeType.Days),
    rebootIfRequired: new FormControl(false),
    rebootIfRequiredTime: new FormControl(this.defaultRebootRequiredTime)
  });

  public severityEnabled = false;
  public updatesEnabled = false;
  public excludeEnabled = false;
  public localUpdatesPriorityEnabled = false;
  public installUpdateDeferEnabled = false;
  public rebootIfRequiredTimeEnabled = false;

  constructor(
    private i18next: I18NextPipe,
    private modalService: ModalService,
    private store: Store,
    private editGroupTaskUtility: EditGroupTaskUtility
  ) {
    this.updatePolicyForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values: UpdatePolicyFormValues) => {
      this.severityEnabled = values.severityLevel;
      this.updatesEnabled = values.updateCategory;
      this.excludeEnabled = values.excludeUpdates;

      this.localUpdatesPriorityEnabled = values.localUpdatesPriority;
      this.installUpdateDeferEnabled = values.updateDefer;
      this.rebootIfRequiredTimeEnabled = values.rebootIfRequired;

      this.store.dispatch(GAActions.setGActionCommandType({ groupActionCommand: GroupActionsCommands.UPDATE_POLICY }));
      this.updateStep();
    });
  }

  private updateStep() {
    this.store.dispatch(GAActions.setGActionParameters({ parameters: this.getGroupActionParams() }));
  }

  ngOnInit(): void {
    this.loadGroupTaskData();
  }

  private getUpdatePolicyFromGroupAction(): Observable<any> {
    return this.store.select(GASelectors.selectGActionParameters).pipe(
      first(),
      pluck('parameters'),
      map((parameters) => (parameters ? JSON.parse(parameters) : null)),
      pluck('parameters', '0', 'value'),
      map((value) => (value ? JSON.parse(value) : null))
    );
  }

  private loadGroupTaskData(): void {
    combineLatest([this.editGroupTaskUtility.getUpdatePolicyDataFromGroupTaskStream(), this.getUpdatePolicyFromGroupAction()])
      .pipe(take(1))
      .subscribe(([groupTaskParameters, currentGroupActionParameters]) => {
        const parametersToUse = currentGroupActionParameters ? currentGroupActionParameters : groupTaskParameters;
        if (!parametersToUse) {
          this.updateStep();
          return;
        }

        this.setupSelectedEntities(parametersToUse);
        const { deferTime, deferType } = parseDeferTimeFromGroupTask(parametersToUse?.deferInstall);
        this.updatePolicyForm.setValue({
          severityLevel: !!this.selectedSeverityLevels?.length,
          updateCategory: !!this.selectedUpdateCategories?.length,
          excludeUpdates: !!this.selectedToExcludedUpdates?.length,
          localUpdatesPriority: parametersToUse?.windowsLocalUpdates === null ? false : true,
          localUpdatesSelection:
            this.localUpdatesItems.find((item) => item.value === parametersToUse?.windowsLocalUpdates)?.name ??
            this.localUpdatesItems[0].name,
          updateDefer: !!parametersToUse?.deferInstall,
          updateDeferNumber: deferTime ?? this.defaultDeferNumber,
          updateDeferType: deferType,
          rebootIfRequired: !!parametersToUse?.rebootTime,
          rebootIfRequiredTime: parametersToUse?.rebootTime ?? this.defaultRebootRequiredTime
        });
      });
  }

  private setupSelectedEntities(groupTaskParameters: any): void {
    this.selectedSeverityLevels = groupTaskParameters?.severityLevels?.map((id: number) => ({ ...this.severityLevels[id] })) ?? [];
    this.selectedUpdateCategories = groupTaskParameters?.categories?.map((id: number) => ({ ...this.winUpdateCategories[id] } ?? []));
    this.selectedToExcludedUpdates = groupTaskParameters?.excludeUpdates ?? [];
    this.selectedToExcludedUpdatesToShow = this.selectedToExcludedUpdates.map((entity) => ({ name: entity }));
  }

  private getParametersForGroupAction(): UpdatePolicyParameters {
    const formValues: UpdatePolicyFormValues = this.updatePolicyForm.value;

    const parameters = {
      severityLevels: formValues.severityLevel ? this.getIdListFromObjectList(this.selectedSeverityLevels) : null,
      categories: formValues.updateCategory ? this.getIdListFromObjectList(this.selectedUpdateCategories) : null,
      excludeUpdates: formValues.excludeUpdates ? this.selectedToExcludedUpdates : null,
      windowsLocalUpdates: formValues.localUpdatesPriority
        ? this.localUpdatesItems.find((item) => item.name === formValues.localUpdatesSelection).value
        : null,
      deferInstall: formValues.updateDefer
        ? this.getDeferInstallValue(Number(formValues.updateDeferNumber), formValues.updateDeferType as NotifyTimeType)
        : null,
      rebootTime: formValues.rebootIfRequired ? this.getRebootIfRequiredValue(formValues.rebootIfRequiredTime) : null
      // notifyUserBeforeRebootTime: formValues.rebootIfRequiredNotifySelection
      //   ? this.getNotifyUserBeforeRebootTime(formValues.rebootIfRequiredNotifySelection)
      //   : null
    };

    return Object.assign({}, DEFAULT_UPDATE_POLICY_PARAMETERS, parameters);
  }

  private getIdListFromObjectList(objectList: UpdatePolicyObjectForSelection[]): number[] {
    return objectList?.map((object) => object.id);
  }
  private getDeferInstallValue(timeNumber: number, timeType: NotifyTimeType): string {
    const days = timeType === NotifyTimeType.Days ? timeNumber : '';
    const hours = timeType === NotifyTimeType.Hours ? this.getProperTimeFormat(timeNumber) : '00';
    const minutes = '00';
    const seconds = '00';
    return `${days}.${hours}:${minutes}:${seconds}`;
  }

  private getProperTimeFormat(value: number): string {
    if (value < 10) return `0${value}`;
    return `${value}`;
  }

  private getRebootIfRequiredValue(time: string): string {
    return time.split(':').length > 2 ? time : time + ':00';
  }

  private getNotifyUserBeforeRebootTime(data: string): string {
    switch (data) {
      case this.requiredNotifyTimeItems[1]:
        return '00:15:00';
      case this.requiredNotifyTimeItems[2]:
        return '00:30:00';
      case this.requiredNotifyTimeItems[3]:
        return '01:00:00';
      case this.requiredNotifyTimeItems[4]:
        return '03:00:00';
      case this.requiredNotifyTimeItems[5]:
        return '08:00:00';
      case this.requiredNotifyTimeItems[6]:
        return '12:00:00';
      case this.requiredNotifyTimeItems[0]:
      default:
        return null;
    }
  }

  public openSeverityLevelModal(): void {
    this.openModal(
      {
        modalTitle: this.i18next.transform(this.translationKey + 'updatePolicyModal.severityTitle'),
        saveButtonText: this.i18next.transform('buttons:select'),
        entityNaming: this.i18next.transform(this.translationKey + 'updatePolicyModal.entityNaming', { format: 'title' }),
        entitiesNaming: this.i18next.transform(this.translationKey + 'updatePolicyModal.entitiesNaming', { format: 'title' }),
        entities: this.severityLevels,
        entityValueToBeShownInTheList: 'name',
        selectionKey: 'id',
        selectedEntities: this.selectedSeverityLevels
      },
      ModalSelectionType.Severity
    );
  }

  public openWinUpdateCategoryModal(): void {
    this.openModal(
      {
        modalTitle: this.i18next.transform(this.translationKey + 'updatePolicyModal.categoryTitle'),
        saveButtonText: this.i18next.transform('buttons:select'),
        entityNaming: this.i18next.transform(this.translationKey + 'updatePolicyModal.entityNaming', { format: 'title' }),
        entitiesNaming: this.i18next.transform(this.translationKey + 'updatePolicyModal.entitiesNaming', { format: 'title' }),
        entities: this.winUpdateCategories,
        entityValueToBeShownInTheList: 'name',
        selectionKey: 'id',
        selectedEntities: this.selectedUpdateCategories
      },
      ModalSelectionType.Category
    );
  }
  public openExcludeUpdateModal(): void {
    this.openModal(
      {
        modalTitle: this.i18next.transform(this.translationKey + 'updatePolicyModal.excludeTitle'),
        saveButtonText: this.i18next.transform('buttons:exclude'),
        entities: this.selectedToExcludedUpdates,
        mode: 'inputList',
        inputListInfo: this.i18next.transform(this.translationKey + 'updatePolicyModal.inputListInfo'),
        textAreaInputName: this.i18next.transform(this.translationKey + 'updatePolicyModal.inputListName')
      },
      ModalSelectionType.Excluded
    );
  }

  private openModal(data: SelectionModalSettings, type: ModalSelectionType): void {
    (data.closeButtonText = this.i18next.transform('buttons:cancel')),
      this.modalService
        .openCustom(SelectionModalComponent, {
          data,
          size: MbsSize.sm,
          collapsing: true
        })
        .then((selectedEntities: any) => {
          this.updateSelectedItemLists(selectedEntities, type);
          this.updateStep();
        })
        .catch(noop);
  }

  private updateSelectedItemLists(selectedEntities: any, type: ModalSelectionType): void {
    if (selectedEntities) {
      switch (type) {
        case ModalSelectionType.Severity:
          this.selectedSeverityLevels = selectedEntities;
          break;
        case ModalSelectionType.Category:
          this.selectedUpdateCategories = selectedEntities;
          break;
        case ModalSelectionType.Excluded:
          this.selectedToExcludedUpdates = selectedEntities;
          this.selectedToExcludedUpdatesToShow = selectedEntities.map((entity) => ({ name: entity }));
          break;
        default:
          break;
      }
    }
  }

  private getTranslation(key: string, translationParameters: any = null): string {
    return this.i18next.transform(`${this.translationKey}${key}`, { ...translationParameters });
  }

  getGroupActionParams(): Partial<GroupTask> {
    const data = this.getParametersForGroupAction();

    if (!data.categories?.length && !data.severityLevels?.length) return null;

    return {
      disabled: false,
      type: 'Policy',
      pluginCommandId: null,
      parameters: JSON.stringify({
        asyncID: `${getGuid()}`,
        withAsyncProgress: false,
        id: 'SetPolicy',
        parameters: [
          {
            name: 'POLICY_AS_JSON',
            value: JSON.stringify(data)
          }
        ]
      }),
      name: 'Schedule Group Task',
      // Description to be changed
      description: this.getDescription(data),
      confidentialOutputData: false,
      pluginActiveCommand: true,
      pluginPsCommand: true,
      enableOffline: false,
      eventType: 0,
      scriptGuid: null,
      policyId: `WindowsUpdatePolicy`,
      softwareSourceGuid: null,
      allComputers: false,
      computers: [],
      computerTags: [],
      runType: 0,
      scheduleType: 0,
      scheduleData: null
    };
  }

  getDescription(data: UpdatePolicyParameters): string {
    const translationKey = 'rmm.module:groupActions.actionDescription.';
    const newRowSplitter = '\r\n\r\n'; // Doubled for a better view
    const textList = [];

    if (data?.severityLevels?.length)
      textList.push(
        this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'policySeverityList', {
          value: this.editGroupTaskUtility.getSeverityLevelsFromData(data.severityLevels).join(', ')
        })
      );

    if (data?.categories?.length)
      textList.push(
        this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'policyCategoryList', {
          value: this.editGroupTaskUtility.getUpdateCategoriesFromData(data.categories).join(', ')
        })
      );

    if (data?.excludeUpdates?.length)
      textList.push(
        this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'policyExcludeList', {
          value: data.excludeUpdates.join(', ')
        })
      );

    textList.push(
      this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'policyLocalUpdates', {}) +
        this.editGroupTaskUtility.getWindowsLocalUpdatesValue(data?.windowsLocalUpdates)
    );

    if (data?.deferInstall)
      textList.push(
        this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'policyDeferInstallation', {
          value: this.editGroupTaskUtility.getDeferInfoFromData(data?.deferInstall)
        })
      );

    if (data?.rebootTime) textList.push(this.editGroupTaskUtility.getRebootInfoFromData(data?.rebootTime));

    return textList.join(newRowSplitter);
  }
}
