<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           #modal>

  <div modal-body>

    <form [formGroup]="form"
          *ngIf="!isEmailChanged">

      <fieldset class="mbs-form_content">
        <div class="mbs-form-group">
          <mbs-input [label]="'New email'"
                     formControlName="email"
                     autocomplete="off"></mbs-input>
        </div>
      </fieldset>

      <fieldset class="mbs-form_content">
        <div class="mbs-form-group">
          <mbs-input [label]="'Password'"
                     formControlName="password"
                     [type]="passwordType"
                     [appendButtons]="[
                        {
                          icon: passwordType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                          id: 'changeTypeConfirmPass',
                          disabled: false,
                          type: 'secondary'
                        }
                     ]"
                     (buttonClick)="changePasswordType()"
                     autocomplete="off"></mbs-input>
        </div>
      </fieldset>

    </form>

    <mbs-alert [type]="MbsPopupType.info"
               *ngIf="isEmailChanged">
      This change will take effect after the new email address is verified. Verification email is sent to {{ newEmail }}. Email Change
      Notification email is sent to {{ oldEmail }}.
    </mbs-alert>

  </div>

  <ng-container modalFooter>

    <mbs-button type="primary"
                (click)="changeEmail()"
                [loading]="loading"
                *ngIf="!isEmailChanged">
      Change Email
    </mbs-button>

    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">
      {{ 'tfa:close' | i18next: { format: 'title' } }}
    </mbs-button>

  </ng-container>

</mbs-modal>
