<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [showCloseCross]="!this.codes?.length"
           [titleTextOverflow]="true"
           #modal>
  <ng-container modal-body>
    <mbs-google-qr-code *ngIf="isGoogleAuth === true && !isValidate"
                        (onToggleAuthType)="isGoogleAuth = false"></mbs-google-qr-code>

    <ng-container *ngIf="isGoogleAuth !== true && !isValidate">
      <mbs-msp-qr-code [QRCodeUri]="baseModal.data.qrCodeUrl"
                       [timeExpired]="baseModal.data.timeExpired"
                       [isEnableTFA]="baseModal.data.isEnableTFA"
                       [isDeviceModal]="baseModal.data.isDeviceModal">

      </mbs-msp-qr-code>
      <mbs-install-msp-app-instruction></mbs-install-msp-app-instruction>
      <div *ngIf="showThirdPartyAuthButton" class="d-flex align-items-center mt-4 justify-content-center">
        <mbs-button [id]="'third-auth-button'"
                    [isCtrl]="true"
                    icon="fa fa-refresh"
                    (click)="isGoogleAuth = true">
          {{ 'tfa:mspQRCode:useThirdAuthenticator' | i18next }}
        </mbs-button>
      </div>
    </ng-container>



    <mbs-recovery-code *ngIf="isValidate"
                       [codes]="codes"
                       (onSaveRecoveryCodes)="isSavedRecoveryCodes = $event"></mbs-recovery-code>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button type="primary"
                data-dismiss="modal"
                *ngIf="codes"
                [disabled]="!isSavedRecoveryCodes"
                (click)="modal.save(true)">
      {{ 'tfa:finish' | i18next: { format: 'title' } }}
    </mbs-button>
  </ng-container>
</mbs-modal>
