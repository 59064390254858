import { SummaryStatCounter, SummaryStatus, SummaryStatusIcon, SummaryStatusType } from '@models/rmm/SummaryStat';

export const mapStatusBarComponentData = (stat: SummaryStatCounter) => {
  if (!stat) {
    return {
      value: null
    };
  }

  return {
    value: stat.value,
    text: `Used: ${stat.value}%`,
    ...mapStatusComponentState(stat?.state)
  };
};
export const mapStatusComponentData = (stat: SummaryStatCounter, clickable?: boolean, defaulStat?: SummaryStatus): SummaryStatus => {
  const DEFAULT = defaulStat || {
    type: SummaryStatusType.MUTED,
    icon: SummaryStatusIcon.MUTED
  };

  if (!stat) {
    return DEFAULT;
  }

  const result = mapStatusComponentState(stat?.state);
  return {...result, ...(result.type === SummaryStatusType.MUTED ? {} : {text: stat.description, clickable}) }
};

const mapStatusComponentState = (state) => {
  switch (state) {
    case 'Warning':
      return {
        type: SummaryStatusType.WARNING,
        icon: SummaryStatusIcon.WARNING
      };
    case 'Danger':
    case 'Alarm':
      return {
        type: SummaryStatusType.DANGER,
        icon: SummaryStatusIcon.DANGER
      };
    case 'OK':
      return {
        type: SummaryStatusType.SUCCESS,
        icon: SummaryStatusIcon.SUCCESS
      };
    default:
      return {
        type: SummaryStatusType.MUTED,
        icon: SummaryStatusIcon.MUTED
      };
  }
};
