import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AddCompanyModalComponent } from '@components/add-company/add-company-modal.component';
import { ComputerAuthorizationModalMode } from '@components/computers-modal/computer-authorization/computer-authorization-modal.constants';
import { AddUserModalComponent } from '@domains/computers/modals/other/add-user/add-user-modal.component';
import { CompaniesFacade } from '@facades/companies.facade';
import { ComputersFacade } from '@facades/computers.facade';
import { UsersFacade } from '@facades/users.facade';
import Company from '@models/Company';
import Computer from '@models/Computer';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { User } from '@models/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { FormsUtil, MbsPopupType, ModalComponent, ModalService, ToastService } from 'mbs-ui-kit';
import { combineLatest, EMPTY, from, Observable, of, take } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AbilityService } from 'ability';

@UntilDestroy()
@Component({
  selector: 'mbs-computer-authorization-modal',
  templateUrl: './computer-authorization-modal.component.html'
})
export class ComputerAuthorizationModalComponent implements OnInit {
  public readonly alertType = MbsPopupType;
  public readonly computerAuthorizationModalMode = ComputerAuthorizationModalMode;
  public readonly permissionsEnum = PermissionsEnum;

  public loading$: Observable<boolean>;
  public companies: Company[] = null;
  public users: User[] = null;
  public modalForm: FormGroup<{
    company: FormControl<Company | null>;
    user: FormControl<User>;
    assignUserManually: FormControl<boolean>;
  }> = this.fb.group({
    company: [null as Company, [Validators.required]],
    user: [null],
    assignUserManually: [false]
  });
  public selectedCompanyUsers: User[] = null;
  public computers: Computer[];
  public isGroupOperation = false;
  public groupOperationComputersCount$: Observable<number>;
  public requestInProgress = false;
  public mode = ComputerAuthorizationModalMode.Create;
  private modeInitiated = false;
  public showFakeCompany = false;
  public fakeCompanyId = '-1';
  public fakeCompany: Company = null;

  private get company(): FormControl {
    return this.modalForm?.get('company') as FormControl;
  }

  private get user(): FormControl {
    return this.modalForm?.get('user') as FormControl;
  }

  public get showAddUserButton(): boolean {
    return (
      !this.isGroupOperation &&
      this.computers.length &&
      this.company?.value?.id !== this.fakeCompanyId &&
      this.ability.can('read', this.permissionsEnum.UsersCreateEdit)
    );
  }

  public get authorizeComputersAlertText$(): Observable<string> {
    return this.groupOperationComputersCount$.pipe(
      map((param) => this.i18n.transform('computers.module:authorizeComputersAlertText', { param }))
    );
  }

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    private companiesFacade: CompaniesFacade,
    private fb: FormBuilder,
    private usersFacade: UsersFacade,
    private modalService: ModalService,
    private computersFacade: ComputersFacade,
    private i18n: I18NextPipe,
    private toastService: ToastService,
    private ability: AbilityService
  ) {}

  ngOnInit(): void {
    this.showFakeCompany =
      this.mode === ComputerAuthorizationModalMode.Edit &&
      !this.isGroupOperation &&
      this.computers.length &&
      !this.computers[0]?.company &&
      !!this.computers[0]?.userAccount?.id;

    this.companiesFacade.loadData();
    this.usersFacade.loadData();

    this.companiesFacade.getDataWhenLoaded$.pipe(untilDestroyed(this)).subscribe((companies) => {
      if (!this.showFakeCompany) {
        this.companies = companies;
      } else {
        this.fakeCompany = {
          id: this.fakeCompanyId,
          name: `[${this.i18n.transform('computers.module:modals.userWithoutCompany')}]`
        } as Company;
        this.companies = [this.fakeCompany, ...companies];
      }
    });

    this.usersFacade.getDataWhenLoaded$.pipe(untilDestroyed(this)).subscribe((users) => {
      this.users = users;
      this.mode === ComputerAuthorizationModalMode.Edit &&
        !this.isGroupOperation &&
        this.computers.length &&
        this.setEditView(this.computers[0]);
    });

    this.loading$ = combineLatest([this.companiesFacade.getDataEntityLoading$, this.usersFacade.getDataEntityLoading$]).pipe(
      map(([companiesLoading, usersLoading]) => companiesLoading || usersLoading)
    );

    this.createFormSubscriptions();
  }

  public onAddCompanyButtonClick() {
    from(this.modalService.openCustom(AddCompanyModalComponent))
      .pipe(catchError(() => EMPTY))
      .subscribe((company) => {
        this.company.patchValue(company as Company);
        this.company.markAsDirty();
      });
  }

  public onAddUserButtonClick() {
    from(
      this.modalService.openCustom(AddUserModalComponent, {
        data: {
          companyId: this.modalForm.value.company.id,
          hid: this.computers[0].hid
        }
      })
    )
      .pipe(catchError(() => EMPTY))
      .subscribe((user) => {
        this.user.patchValue(user as User);
        this.user.markAsDirty();
      });
  }

  public onSaveButtonClick() {
    if (!this.modalForm.valid) {
      FormsUtil.triggerValidation(this.modalForm);
      return;
    }

    if (!this.isGroupOperation && !this.computers.length) {
      this.toastService.error(this.i18n.transform('computers.module:errors.incorrectComputerData'));
      return;
    }

    this.requestInProgress = true;

    of(this.isGroupOperation)
      .pipe(
        switchMap((isGroupOperation) => {
          const companyId = this.company.value.id;
          const userId = this.modalForm.get('assignUserManually').value ? this.user?.value?.id : null;

          if (!isGroupOperation) return this.computersFacade.authorizeComputer(this.computers[0].hid, userId, companyId);

          if (this.computers.length)
            return this.computersFacade.groupAuthorizeComputers(
              this.computers.map((computer) => computer.hid),
              userId,
              companyId
            );

          return this.computersFacade.currentPageRequestParams$(true, true).pipe(
            take(1),
            switchMap((filter) => this.computersFacade.bulkAuthorizeComputers(filter, userId, companyId))
          );
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success();
          this.isGroupOperation && this.computersFacade.invalidate();
          !this.isGroupOperation &&
            this.computers.length &&
            this.computersFacade.loadComputerByHid({ hid: this.computers[0].hid, force: true });
          this.computersFacade.checkOneComputerExist(true);
          this.baseModal.save();
        },
        error: () => (this.requestInProgress = false)
      });
  }

  private createFormSubscriptions(): void {
    if (this.mode === ComputerAuthorizationModalMode.Create) {
      this.modalForm
        .get('assignUserManually')
        .valueChanges?.pipe(untilDestroyed(this))
        .subscribe((value) => {
          const userControl = this.user;
          !value && this.user.patchValue(null);
          value ? userControl.setValidators([Validators.required, this.userEnabled.bind(this)]) : userControl.clearValidators();
          userControl.updateValueAndValidity();
        });
    }

    this.modalForm
      .get('company')
      .valueChanges?.pipe(untilDestroyed(this))
      .subscribe((company) => {
        this.user.patchValue(null);
        this.selectedCompanyUsers = this.users.filter((user) =>
          company.id === this.fakeCompanyId ? !user?.company : user?.company?.id === company.id
        );
      });
  }

  private setEditView(computer: Computer): void {
    if (this.modeInitiated) return;

    this.modeInitiated = true;
    this.modalForm.patchValue({
      assignUserManually: true,
      company: this.fakeCompany ? this.fakeCompany : computer.company,
      user: this.users?.find((user) => user.id === computer?.userAccount?.id)
    });
    this.selectedCompanyUsers = this.fakeCompany
      ? this.users?.filter((user) => !user?.company?.id)
      : this.users?.filter((user) => user?.company?.id === this.company?.value?.id);
    this.user.setValidators([Validators.required, this.userEnabled.bind(this)]);
    this.company.markAsDirty();
    this.user.markAsDirty();
  }

  private userEnabled(control: AbstractControl): ValidationErrors | null {
    return !control.value || this.users.find((user) => control.value?.id === user.id)?.enabled
      ? null
      : { userEnabled: { message: this.i18n.transform('computers.module:modals.userNotEnabled') } };
  }
}
