import HotfixAvailableInfo from './HotfixAvailableInfo';

export class UpdateStatus {
  updatesKBList: string[];
  tablesIsUpdated: boolean;
  lastDownloadAndUpdateCall: Date;
  lastTablesUpdate: Date;
  downloadProgressDescriptor: DownloadProgressDescriptor;
  installationProgressDescriptor: InstallationProgressDescriptor;
  downloadStatus: Status;
  searchStatus: Status;
  installationStatus: Status;

  downloadError: string;
  installationError: string;
  searchError: string;
  processing: boolean;
  rebootRequired: boolean | string;
  inWork: HotfixAvailableInfo[];
}

export enum Status {
  WaitingForLaunch = 0,
  Started = 1,
  Completed = 2,
  CompletedError = 3
}

export class DownloadProgressDescriptor {
  currentUpdateIndex: number;
  updatesCount: number;
  totalBytesDownloaded: number;
  totalBytesToDownload: number;
}

export class InstallationProgressDescriptor {
  currentUpdateIndex: number;
  updatesCount: number;
  currentUpdatePercentComplete: number;
}
