import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GroupTask, GroupTaskFiltersPayload } from '@modules/group-tasks/store/model';
import * as GroupTaskActions from '@modules/group-tasks/store/actions';
import * as GroupTaskSelectors from '@modules/group-tasks/store/selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ScriptLibraryEntry } from '../../store/script-library.model';

@UntilDestroy()
@Component({
  selector: 'mbs-script-library-entry',
  templateUrl: './script-library-entry.component.html',
  styleUrls: ['./script-library-entry.component.scss']
})
export class ScriptLibraryEntryComponent implements OnInit, OnChanges {
  @Input() script: ScriptLibraryEntry;

  public order = ['name', 'description', 'categories', 'accessLevel', 'owner'] as const;
  public groupTasks$: Observable<GroupTask[]>;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.script && changes.script.currentValue) this.loadGroupTasks();
  }

  private loadGroupTasks(): void {
    this.store.dispatch(GroupTaskActions.clearGroupTasks());
    const params: GroupTaskFiltersPayload = {
      scriptGuid: this.script.scriptGuid
    };

    this.store.dispatch(GroupTaskActions.loadGroupTasks({ params }));

    this.groupTasks$ = this.store.select(GroupTaskSelectors.selectGroupTasks).pipe(untilDestroyed(this));
  }
}
