import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';

@Component({
  selector: 'mbs-marketing-iframe',
  templateUrl: './marketing-iframe.component.html',
  styles: []
})
export class MarketingIframeComponent implements OnInit, AfterViewInit {
  public readonly marketingSrc = 'https://get.msp360.com/mspbackupsmarketing';

  public iframe: IFrameComponent;

  public showLoader = true;

  @ViewChild('iframe', { static: true }) marketingIframe: ElementRef<HTMLIFrameElement>;

  constructor() {}

  ngOnInit(): void {
    // empty
  }

  ngAfterViewInit(): void {
    this.marketingIframe.nativeElement.onload = () => this.handleInjectIframeResize(this.marketingIframe.nativeElement);
  }

  handleInjectIframeResize(target: HTMLIFrameElement): void {
    // @types/iframe-resizer is old...
    this.iframe = iframeResizer({ onMessage: this.handleReceivedMessage.bind(this) } as any, target)[0];

    // delay to calc height and render dom
    setTimeout(() => {
      this.iframe && this.iframe.iFrameResizer.resize();
      this.showLoader = false;
    }, 300);
  }

  handleReceivedMessage(msg: iframeResizer.IFrameMessageData): void {
    if (msg.message === 'mousedown') {
      this.iframe.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
    }
    if (msg.message === 'mouseup') {
      this.iframe.dispatchEvent(new MouseEvent('mouseup', { bubbles: true }));
    }
  }
}
