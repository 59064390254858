import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@mbs-ui/environments/environment';
import { ErrorsEnum, HttpResponseError } from 'mbs-ui-kit';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription, TimeoutError } from 'rxjs';
import { finalize, timeout } from 'rxjs/operators';
import { CommandService } from '../../modules/rmm/services/rmm-command.service';
import { OutputBuffer, OutputBufferType } from '@models/rmm/PowerShellOutputBuffer';
import { PowerShellOutType } from '@models/rmm/PowerShellResultModel';
import RmmCommand from '@models/rmm/RmmCommand';
import { ConfigurationService } from './configuration.service';
import { RmmWebsocketService } from './rmm-websocket.service';

const MAX_COLUMNS = 120;
const TIMEOUT = 30000;

export enum REASONS {
  BREAK = 'break',
  SUCCESS = 'success',
  COMPLETE = 'complete',
  ERROR = 'error'
}

@Injectable()
export class RmmPowershellService {
  public isExecuting = false;
  public commandCompleted = new Subject<string>();
  public commandExecuting = new Subject<string>();
  public writeOutput = new Subject<string>();
  public warningOutput = new Subject<string>();
  public serverUrl: string;
  public sessionExpiredEvent = new EventEmitter<boolean>();
  public isOpenedPowerShellModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // output buffer that saves all input commands and output
  public outputBuffer: OutputBuffer[] = [];

  private computerHid = '';
  private currentSubscriptionCommand: Subscription;

  constructor(
    private rmmHub: RmmWebsocketService, // prettier
    private commandService: CommandService,
    config: ConfigurationService
  ) {
    if (environment.production) {
      this.serverUrl = config.get('rmmBaseHref');
    }

    if (this.rmmHub.isConnected) {
      this.initPowershell();
    } else {
      this.rmmHub.init().then(() => {
        this.initPowershell();
      });
    }
  }

  initPowershell(): void {
    this.write(`\x1b[49m`, 'none');
  }

  resetOutputBuffer() {
    this.outputBuffer = [];
  }

  setHid(hid: string) {
    this.computerHid = hid;
  }

  get hid() {
    return this.computerHid;
  }

  setExecutingState(executing: boolean, reason?: REASONS) {
    const prevIsExecuting = this.isExecuting;
    this.isExecuting = executing;
    if (prevIsExecuting === true && executing === false) {
      this.commandExecuting.next('');
      this.commandCompleted.next(reason || REASONS.COMPLETE);
    }
  }
  /*
   * Start executing script
   */
  executeScript(command: string) {
    this.executeCommand(command);
  }
  /*
   * Start executing command
   */
  executeCommand(command: string) {
    this.registerExecute(command);

    const rmmCommand = RmmCommand.create('Script')
      .addParam('SCRIPT', command)
      .addParam('TIMEOUT', null)
      .addParam('INTERACTIVE', 'true')
      .addParam('STOP', 'false');

    this.sendCommand(rmmCommand);
  }
  /*
   * Register buffer and command stream
   * @param command
   */
  registerExecute(command) {
    this.setExecutingState(true);
    this.outputBuffer.push({ message: command, type: 'command' });
    this.commandExecuting.next(command);
  }
  /*
   * Cancel current executing command
   */
  breakCommand(command): void {
    if (this.currentSubscriptionCommand) {
      this.currentSubscriptionCommand.unsubscribe();
    }
    this.currentSubscriptionCommand = null;

    const rmmCommand = RmmCommand.create('Script')
      .addParam('SCRIPT', command)
      .addParam('TIMEOUT', null)
      .addParam('INTERACTIVE', 'true')
      .addParam('STOP', 'true');

    this.sendCommand(rmmCommand, true);
  }
  /*
   * Send command to computer
   * @param rmmCommand
   * @param stop
   */
  sendCommand(rmmCommand: RmmCommand<any>, stop = false): void {
    const startCommand = Date.now();
    // TODO STATEFUL STREAMS
    this.currentSubscriptionCommand = this.commandService
      .sendCommandAsync('PowerShellTerminalCmd', rmmCommand, this.hid, true)
      .pipe(
        timeout(TIMEOUT),
        finalize(() => {
          this.currentSubscriptionCommand = null;
        })
      )
      .subscribe(
        (messageData) => {
          if (stop) {
            this.setExecutingState(false, REASONS.BREAK);
            this.commandCompleted.next(REASONS.BREAK);
            this.isExecuting = false;
            return;
          }

          if (messageData.outType === PowerShellOutType.End) {
            this.setExecutingState(false, REASONS.SUCCESS);
          } else if (messageData.outType === PowerShellOutType.Succeed) {
            const messageTrimmedLinesRight = messageData.data
              .split('\r\n')
              .map((line) => line.trimRight())
              .join('\r\n');
            this.write(messageTrimmedLinesRight, 'output');
          } else if (messageData.outType === PowerShellOutType.Warning) {
            this.warningOutput.next(messageData.data);
          } else if (messageData.outType === PowerShellOutType.Error) {
            this.write(`\x1b[1;101m${messageData.data}\x1b[49m`, 'error');
          }
        },
        (error: HttpResponseError) => {
          if (stop) {
            this.setExecutingState(false, REASONS.BREAK);
            this.commandCompleted.next(REASONS.BREAK);
            this.isExecuting = false;
            return;
          }

          if (error instanceof TimeoutError || error?.error?.code === ErrorsEnum.RmmTimeOut) {
            this.write(
              `Timeout exceeded (${moment
                .duration(Date.now() - startCommand, 'milliseconds') // prettier
                .format('m[m] s[s]', { trim: 'both', largest: 1 })}). Command break.\r\n`,
              'error'
            );
            this.commandCompleted.next(REASONS.BREAK);
          } else if (error?.error?.code === ErrorsEnum.RmmAccessDenied) {
            this.sessionExpiredEvent.emit(true);
          } else if (error?.error?.code === ErrorsEnum.RmmPowerShellScriptsAre) {
            this.write(`\x1b[1;33m${error.error.title}\x1b[37m\r\n`, 'error');
          } else if (error?.error?.code === ErrorsEnum.RmmReadonlyMode) {
            this.write(`\x1b[1;33m${error.error.title}\x1b[37m\r\n`, 'error');
          } else {
            this.write(`\x1b[1;33mError occured while sending command to remote server.\x1b[37m\r\n`, 'error');
            this.write(`${error.status} ${error.statusText} \r\n`, 'error');
          }
          this.setExecutingState(false, REASONS.ERROR);
        }
      );
  }

  private write(message: string, type: OutputBufferType = 'output') {
    if (type !== 'none') {
      this.outputBuffer.push({ message, type });
    }
    this.writeOutput.next(message);
  }
}
