import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnboardingMode } from '@modules/wizards/services/onboarding/onboarding.constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { B2CredsError, B2DestinationError } from './onboarding.errors';
import { AppsSummaryType, B2CommonResponseType, CreateStorageAccountB2DataType } from './onboarding.types';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  readonly controllerPath = '/api/gettingstarted';

  public stable$ = new BehaviorSubject(false);
  public nextStepAvailable$ = new BehaviorSubject(true);
  public hasError$ = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}

  initialize(): Observable<null> {
    return this.http.post<null>(`${this.controllerPath}/initialize`, null);
  }

  complete(): Observable<string> {
    return this.http.post<string>(`${this.controllerPath}/complete`, null);
  }

  configureComputers(): Observable<string> {
    return this.http.post<string>(`${this.controllerPath}/configcomputers`, null);
  }

  configureComputerByHid(hid: string): Observable<string> {
    return this.http.post<string>(`${this.controllerPath}/configcomputer`, { hid });
  }

  mode(): Observable<{ mode: OnboardingMode }> {
    return this.http.get<{ mode: OnboardingMode }>(`${this.controllerPath}/mode`);
  }

  setTimezone(timeZone: string): Observable<null> {
    return this.http.put<null>(`${this.controllerPath}/set-timezone`, { timeZoneIona: timeZone });
  }

  sendAppsSummary(summary: AppsSummaryType): Observable<null> {
    return this.http.post<null>(`${this.controllerPath}/send-apps-summary`, summary);
  }

  createDomain(domain: { domainName: string; sharePointTeamDrives: 0 | 1 }): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.controllerPath}/apps/create-domain`, domain);
  }

  assignSharePoint({ domainName, ownerId }: { domainName: string; ownerId: string }): Observable<null> {
    return this.http.post<null>(`${this.controllerPath}/apps/assign-sharepoint`, {
      domain: domainName,
      ownerId
    });
  }

  createB2Storage(params: CreateStorageAccountB2DataType): Observable<B2CommonResponseType> {
    return this.http.post<B2CommonResponseType>(`${this.controllerPath}/create-b2-storage`, params).pipe(map(this.prepareB2CommonResponse));
  }

  validateB2(data: { publicKey: string; secretKey: string }): Observable<B2CommonResponseType> {
    return this.http.post<B2CommonResponseType>(`${this.controllerPath}/validate-b2-storage`, data).pipe(map(this.prepareB2CommonResponse));
  }

  private prepareB2CommonResponse(response: B2CommonResponseType): B2CommonResponseType {
    const { credsErrors, destinationErrors } = response.errors || {};

    if (credsErrors?.length) {
      throw new B2CredsError(credsErrors[0]);
    }

    if (destinationErrors?.length) {
      throw new B2DestinationError(destinationErrors[0]);
    }

    return response;
  }
}
