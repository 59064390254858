<h4>{{ 'rmm.module:groupActions.stepSummary.title' | i18next: { format: 'title' } }}</h4>

<div *ngIf="groupTaskSummary"
     [id]="elementSelectors.groupTaskSummary"
     class="text-md mt-3">

  <div class="font-weight-semibold mb-2">
    {{ 'rmm.module:groupActions.stepResult.taskName' | i18next }}
  </div>
  <div class="mb-2">{{ groupTaskSummary.name }}</div>

  <div class="font-weight-semibold mb-2">
    {{ 'rmm.module:groupActions.stepResult.taskActionType' | i18next }}
  </div>
  <div class="mb-2">
    {{ 'rmm.module:groupActions.actionNames.' + groupTaskSummary.type | i18next}}
  </div>

  <div class="font-weight-semibold mb-2">
    {{ 'rmm.module:groupActions.stepResult.taskApplyTo' | i18next }}
  </div>
  <div>
    {{ groupTaskSummary.applyTo.includeInfo }}
  </div>
  <div>
    {{ groupTaskSummary.applyTo.excludeInfo }}
  </div>

  <div *ngIf="groupTaskSummary.applyTo?.tags?.length"
       class="mt-2 mb-2">
    <span class="mr-1">{{ 'rmm.module:groupActions.stepResult.taskTags' | i18next }}</span>
    <span *ngFor="let tag of groupTaskSummary.applyTo.tags; last as last">
      <span *ngIf="(getTagById(tag) | async)?.name as tagName; else deletedTag">{{ tagName }}</span>
      <span>{{ last ? '' : ', ' }}</span>
      <ng-template #deletedTag>
        <span class="fa fa-ban text-danger"></span>
        <span class="text-danger ml-1">{{ 'rmm-group-task.module:removedTagName' | i18next: { value: tag } }}</span>
      </ng-template>



    </span>
  </div>

  <div class="font-weight-semibold mt-2 mb-2"
       *ngIf="groupTaskSummary?.description">
    {{ 'rmm.module:groupActions.stepResult.taskDescription' | i18next }}
  </div>
  <div class="mb-2 pre-wrap"
       *ngIf="groupTaskSummary?.description">{{ groupTaskSummary.description }}
  </div>

  <div class="font-weight-semibold mt-2 mb-2"
       *ngIf="groupTaskSummary?.schedule">
    {{ 'rmm.module:groupActions.stepResult.taskSchedule' | i18next }}
  </div>
  <div class="mb-2 pre-wrap"
       *ngIf="groupTaskSummary?.schedule">{{ groupTaskSummary.schedule }}
  </div>

</div>
