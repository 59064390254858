import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import AdministratorInCamelCase from '@models/AdministratorInCamelCase';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdministratorService } from '@services/administrator.service';
import { I18NextPipe } from 'angular-i18next';
import { ListSelectGroupModel, MbsSize, ModalService } from 'mbs-ui-kit';
import { noop } from 'rxjs';
import { UtilitySelectionModalComponent } from '@modules/utility-selection-modal/utility-selection-modal.component';

@UntilDestroy()
@Component({
  selector: 'mbs-user-select-step',
  templateUrl: './user-select-step.component.html'
})
export class UserSelectStepComponent implements OnInit {
  @Output() setSelectedAdminList = new EventEmitter<AdministratorInCamelCase[]>();
  @Output() setNextButtonEnabled = new EventEmitter();
  @Output() setProvider = new EventEmitter<AdministratorInCamelCase>();

  public selectedAdminList: AdministratorInCamelCase[] = [];
  public adminList: AdministratorInCamelCase[];
  public modeSelectionForm: UntypedFormGroup = new UntypedFormGroup({
    mode: new FormControl('manual')
  });

  constructor(private adminService: AdministratorService, private modalService: ModalService, private i18nPipe: I18NextPipe) {}

  ngOnInit(): void {
    this.getAdminUserList();
    this.onChanges();
  }

  onChanges(): void {
    this.modeSelectionForm.get('mode').valueChanges.subscribe((value) => {
      this.onUserModeChange(value);
    });
  }

  private getAdminUserList(): void {
    this.adminService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.adminList = response;
        this.setProvider.emit(response.find((administrator) => administrator.isProvider === true));
        if (this.adminList.length === 1) {
          this.setSelectedAdminList.emit([...this.adminList]);
        } else {
          this.setNextButtonEnabled.emit(true);
        }
      });
  }

  public onUserModeChange(formValue: string): void {
    const currentAdminList = this.getSelectedUsersByMode(formValue);
    this.setSelectedAdminList.emit(currentAdminList);
    this.setNextButtonEnabled.emit(!!currentAdminList.length);
  }

  private getSelectedUsersByMode(formValue: string): AdministratorInCamelCase[] {
    switch (formValue) {
      case 'allSelection':
        return [...this.adminList.filter((admin) => admin.enabled)];
      case 'customSelection':
        return [...this.selectedAdminList];
      case 'manual':
      default:
        return [this.adminList.find((admin) => admin.isProvider === true)];
    }
  }

  openSelectAdministratorsModal(): void {
    this.modeSelectionForm.get('mode').setValue('customSelection');
    const groups = this.selectedAdminList.map((admin) => admin.id);
    this.modalService
      .openCustom(UtilitySelectionModalComponent, {
        data: {
          model: groups,
          modalTitle: this.i18nPipe.transform('wizards:tryRmm:select_users_modal_title'),
          applyTo: 'Administrator List',
          noComputersText: this.i18nPipe.transform('wizards:tryRmm:select_users_modal_no_users_desc'),
          saveButtonText: this.i18nPipe.transform('wizards:tryRmm:modal_accept_btn')
        },
        size: MbsSize.sm,
        collapsing: true
      })
      .then((selectedEntities: ListSelectGroupModel<{ id: string; title: string }>[]) => {
        if (selectedEntities) {
          this.selectedAdminList = selectedEntities.map((entity) => this.adminList.find((admin) => admin.id === entity.id));
        }
        this.onUserModeChange(this.modeSelectionForm.get('mode').value);
      })
      .catch(noop);
  }
}
