import { StepId } from '@modules/wizards/onboarding/views/apps/apps.constants';
import { TabsConfigType } from '@modules/wizards/onboarding/views/apps/apps.types';
import { I18NextPipe } from 'angular-i18next';

export const getTabsConfig = (i18N: I18NextPipe): TabsConfigType => {
  return [
    {
      id: StepId.StorageAccount,
      title: i18N.transform('onboarding:storage_account', { format: 'title' }),
      description: i18N.transform('onboarding:tab.backup_storage_destination')
    },
    {
      id: StepId.SignIn,
      title: i18N.transform('onboarding:administrative_account.title', { format: 'title' }),
      description: i18N.transform('onboarding:tab.office_365_account')
    },
    {
      id: StepId.WhatToBackup,
      title: i18N.transform('onboarding:backup_source', { format: 'title' }),
      description: i18N.transform('onboarding:tab.what_to_backup')
    },
    {
      id: StepId.SelectUsers,
      title: i18N.transform('onboarding:select_users', { format: 'title' }),
      description: i18N.transform('onboarding:tab.select_users')
    },
    {
      id: StepId.Summary,
      title: i18N.transform('common.summary', { format: 'title' }),
      description: i18N.transform('common.summary', { format: 'title' })
    }
  ];
};
