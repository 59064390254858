import { Component, Inject, Injector, Input } from '@angular/core';
import Computer from '@models/Computer';
import { GroupTask, GroupTaskSummary, RunTypes } from '@modules/group-tasks/store/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ComputerTagsFacade } from '@root/mbs-ui/src/app/shared/facades/computer.tags.facade';
import { Tag } from '@shared/models/tag';
import { I18NextService } from 'angular-i18next';
import { WizardStepComponent } from 'mbs-ui-kit';
import { filter, Observable } from 'rxjs';
import { GASelectors } from '../../store/group-action';
import { ScheduleState, SelectedEntities, WizardGroupAction } from '../../store/group-action/group-action.model';
import EditGroupTaskUtility from '../../utility/edit-group-task-utility';

@UntilDestroy()
@Component({
  selector: 'mbs-show-summary',
  templateUrl: './show-summary.component.html',
  styleUrls: ['./show-summary.component.scss']
})
export class ShowSummaryComponent {
  public filteredComputers: Computer[] = [];
  @Input() resultInfo: any = {};

  public groupTaskSummary: GroupTaskSummary;
  public groupTask: any;

  public elementSelectors = {
    groupTaskSummary: 'group-task-summary-div',
    updatePolicy: 'update-policy-div',
    severityLevels: 'severity-levels-div',
    updateCategories: 'update-categories-div',
    excludeUpdates: 'exclude-updates-div',
    rebootInfo: 'reboot-info-div'
  };

  constructor(
    @Inject(WizardStepComponent) protected parent: WizardStepComponent,
    protected injector: Injector,
    private i18n: I18NextService,
    private store: Store,
    private editGroupTaskUtility: EditGroupTaskUtility,
    private tagsFacade: ComputerTagsFacade
  ) {
    this.store
      .select(GASelectors.selectGAction)
      .pipe(
        filter((groupAction) => !!groupAction),
        untilDestroyed(this)
      )
      .subscribe((groupAction) => {
        this.setupGroupTaskSummary(groupAction);
      });
  }

  setupGroupTaskSummary(groupAction: WizardGroupAction): void {
    this.groupTaskSummary = {
      name: groupAction.name,
      description: groupAction?.parameters?.description,
      type: this.editGroupTaskUtility.parseCommandTypeFromGroupTask({
        osType: groupAction?.osType,
        policyId: groupAction?.parameters?.policyId,
        ...groupAction?.parameters
      }),
      applyTo: this.getApplyToSummary(groupAction.applyTo),
      schedule: this.getScheduleByType(groupAction.schedule),
      updatePolicy: this.editGroupTaskUtility.getUpdatePolicyDataFromGroupTask({ ...groupAction, ...groupAction.parameters } as GroupTask)
    };
  }

  getApplyToSummary(applyTo: SelectedEntities): { includeInfo: string; excludeInfo: string; tags: number[] } {
    if (!applyTo) return null;

    if (applyTo.allCompanies) {
      return {
        includeInfo: this.i18n.t('rmm.module:groupActions.updatePolicy.allCompaniesInfo'),
        excludeInfo: this.editGroupTaskUtility.getApplyToComputersInfo(applyTo.computers),
        tags: applyTo.computerTags ?? []
      };
    }

    if (applyTo.companies.length) {
      let textInfo = this.i18n.t('rmm.module:groupActions.updatePolicy.companyListInfo');
      applyTo.companies.forEach((company, index) => (textInfo += index !== 0 ? `, ${company.name}` : company.name));

      return {
        includeInfo: textInfo,
        excludeInfo: this.editGroupTaskUtility.getApplyToComputersInfo(applyTo.computers),
        tags: applyTo.computerTags ?? []
      };
    }

    return {
      includeInfo: this.editGroupTaskUtility.getApplyToComputersInfo(applyTo.computers, false),
      excludeInfo: this.editGroupTaskUtility.getApplyToComputersInfo(applyTo.computers),
      tags: applyTo.computerTags ?? []
    };
  }

  getScheduleByType(schedule: ScheduleState): string {
    switch (schedule?.runType) {
      case RunTypes.none:
        return this.i18n.t('rmm.module:groupActions.stepSchedule.runManually', { returnObjects: true });
      case RunTypes.immediately:
        return this.i18n.t('rmm.module:groupActions.stepSchedule.runOnce', { returnObjects: true });
      case RunTypes.schedule:
        return schedule.scheduleDesc;
      case RunTypes.event:
      default:
        return null;
    }
  }

  public getTagById(id: number): Observable<Tag> {
    return this.tagsFacade.tagById$(id).pipe(untilDestroyed(this));
  }
}
