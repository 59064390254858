import { Injectable } from '@angular/core';
import { IframeMessage } from './IframeMessage';
import { InteractionEvent } from './InteractionEvent';

// @TODO remove after migration last WebForm page (I think that will never happen)
// communicate empty page features with modals.
// prevent circular dependencies error
@Injectable()
export class EmptyPageService {
  private iframeWindow: Window;

  constructor() {}

  public init(iframeWindow: Window): void {
    this.iframeWindow = iframeWindow;
  }

  public destroy(): void {
    this.iframeWindow = null;
  }

  private checkIframeExist(): void {
    if (!this.iframeWindow) {
      throw new Error('EmptyService should init');
    }
  }

  /**
   * If you have some global function in iframe, then you can try to call it with custom arguments.
   * Arguments will serialize and it allow pass any value like array, objects.
   *
   * @param {string}callback function name
   * @param {any[]}args will pass as multiple arguments with spread
   *
   *
   * Note: single argument of array type will spread. Wrap into another array to get the correct behavior.
   */
  callExternalFunctionToChild(callback: string, ...args: any[]): void {
    this.checkIframeExist();
    this.handleSendMessageToChild({
      msg: InteractionEvent.CALL_GLOBAL_FUNCTION_IN_IFRAME,
      callback: callback,
      arguments: JSON.stringify(args)
    });
  }

  handleSendMessageToChild(msg: IframeMessage): void {
    this.checkIframeExist();
    this.iframeWindow.postMessage(msg, '*');
  }

  handleSendMessageToParent(msg: IframeMessage): void {
    this.checkIframeExist();
    if (window.top != window) {
      window.top.postMessage(msg, '*');
    }
  }
}
