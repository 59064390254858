<div [hidden]="!show"
     class="mbs-widget"
     *ngIf="('Provider' | can: 'read')">
  <div class="mbs-widget_inner"
       [style.background-image]="'url(./images/widgets/iOS-bg.jpg)'">
    <div class="mbs-widget_controls row">
      <div class="col-auto">
        <mbs-button [isCtrl]="true"
                    type="dark"
                    icon="ico ico-Close"
                    title="Close the widget"
                    (click)="handleClose()">
        </mbs-button>
      </div>
    </div>
    <div class="row align-items-end justify-content-between">
      <div class="mbs-widget_content col-8">
        <div class="mbs-widget_header">
          <h3 class="mbs-widget_title text-brand">
            Admin App for IOS
          </h3>
        </div>
        <div class="mbs-widget_text text-brand">
          Monitor backup activity on the go
        </div>
      </div>
      <div class="col-4 text-right">
        <a href="https://apps.apple.com/us/app/cloudberry-backup-admin/id1382254249"
           target="_blank"
           class="mbs-widget_button mbs-widget_buttonAppstoreLink">
          <img src="assets/images/widgets/iOS-button.svg"
               alt="Download on the AppStore"
               class="mbs-widget_button-img" />

        </a>
      </div>
    </div>
  </div>
</div>
