import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@environments/environments.service';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { AppEnvStateService } from '@services/app-env-state.service';
import { getSentryEnvironment } from '@utils/get-sentry-environment';
import { noop } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AppInitService {
  constructor(
    private authService: AuthService,
    private appEnvState: AppEnvStateService,
    private environment: EnvironmentService,
    private location: Location
  ) {}

  private get isUnauthorizedRoute() {
    const unauthorizedRoute = ['AS/Auth2FA'];

    return unauthorizedRoute.some((route) => this.location.path().includes(route));
  }

  async init(): Promise<any> {
    this.appEnvState.appEnvState$.pipe(first(Boolean)).subscribe((appEnvState) => {
      if (!this.environment.production) return;

      Sentry.init({
        dsn: this.environment.sentry.dsn,
        release: appEnvState.version,
        integrations: [
          new TracingIntegrations.BrowserTracing(),
          new Sentry.Integrations.Breadcrumbs({
            console: false,
            /** Add own breadcrumb for xhr */
            xhr: false
          })
        ],
        beforeBreadcrumb: (breadcrumb, hint?) => {
          if (breadcrumb.category === 'ui.click') {
            const innerText = hint?.event?.innerText;

            innerText && (breadcrumb.message = `${breadcrumb.message} ${innerText}`);
          }

          return breadcrumb;
        },
        tracesSampleRate: 1,
        environment: getSentryEnvironment()
      });
    });

    if (this.environment.useSessionToken && !this.isUnauthorizedRoute) {
      return this.authService.getAuthTokenBySession$.toPromise().catch(noop);
    }
    return Promise.resolve();
  }
}
