import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  constructor() {}

  onPasswordChange(form: NgForm) {
    if (form.valid) {
      console.log(form.value);
    }
  }
}
