<div class="text-sm mb-2">
  {{ 'rmm-side-panel:processesTab.updateEvery' | i18next }}
  <mbs-button [isCtrl]="true"
              size="xs"
              class="mr-2">
    {{ updateInterval.value }} {{ 'app:timeUnits.seconds' | i18next }}
    <ng-template mbsButtonDropdown
                 container="body"
                 display="dynamic">
      <div class="py-1 px-2">
        <mbs-number [formControl]="updateInterval"
                    [min]="minInterval"
                    step="5"
                    [max]="maxInterval"
                    [id]="'rmm-chart-update-interval'"
                    [imaskOpts]="updateIntervalMask"
                    size="sm"></mbs-number>
      </div>
    </ng-template>

  </mbs-button>
  {{ 'rmm-side-panel:processesTab.last' | i18next }}
  <mbs-rmm-date-time-format [time]="updatedTime"
                            [showIcon]="true"></mbs-rmm-date-time-format>
</div>

<mbs-alert *ngIf="error"
           [type]="alertType.danger">
  {{ error }}
  <span *ngIf="showTryRecovery && isActiveRecoveryStatus"
        class="loader"></span>
  <mbs-button *ngIf="showTryRecovery && !isActiveRecoveryStatus"
              [isCtrl]="true"
              (click)="handleTryRecoveryCounter()">
    {{ 'rmm-side-panel:processesTab.tryRecovery' | i18next }}
  </mbs-button>
</mbs-alert>

<div class="row mbs-processes-charts">
  <div *ngFor="let chart of shortCharts; let i=index;"
       class="col-6 py-2 mbs-processes-charts_chart"
       (click)="handleSelectChart(charts[i]);">
    <span *ngIf="selectedCharts.length > 0 && selectedCharts[0] === charts[i]"
          class="ico ico-tick text-success mbs-processes-charts_chart-ico"></span>
    <canvas baseChart
            [labels]="chartLabels"
            [type]="chart.type"
            [datasets]="chart.datasets"
            [options]="chart.options">
    </canvas>
  </div>
</div>

<!-- hack with destroy for prevent undefined previous refs to datasets -->
<div *ngFor="let chart of selectedCharts"
     class="row py-2">
  <div class="col-12">
    <canvas baseChart
            [labels]="chartLabels"
            [type]="chart.type"
            [datasets]="chart.datasets"
            [options]="chart.options">
    </canvas>
  </div>
</div>
