/* eslint camelcase: 0 */
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, GuardsCheckEnd, NavigationCancel, NavigationEnd, NavigationError, Router, RouterEvent } from '@angular/router';
import { AllowOfflineEditModalComponent } from '@components/allow-offline-edit-modal/allow-offline-edit-modal.component';
import { ForceMSP2FaModalComponent } from '@components/tfa/components/force-msp-2fa-modal/force-msp-2fa-modal.component';
import { TFAService } from '@components/tfa/services/tfa.service';
import { AuthType } from '@components/tfa/services/tfa.types';
import { ApplicationStateFacade } from '@facades/application.facade';
import { environment } from '@mbs-ui/environments/environment';
import Administrator from '@models/Administrator';
import { AgentType, OsType } from '@models/Computer';
import { UiStorageKey } from '@models/ui-storage';
import { InteractionEvent } from '@modules/empty-page/InteractionEvent';
import { EmptyPageComponent } from '@modules/empty-page/empty-page.component';
import { PosthogModule } from '@modules/posthog/posthog.module';
import { SignalRService } from '@modules/signal-r/signal-r.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IsLoadingService } from '@service-work/is-loading';
import { ComputersAbstractService } from '@services';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { AuthService } from '@services/auth.service';
import { ConfigurationService } from '@services/configuration.service';
import { AppLayoutService } from '@shared/_layout/app-layout/app-layout.service';
import { AbilityService, EventTypes } from 'ability';
import { onlyDateFormat } from '@utils/date';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import jwtDecode from 'jwt-decode';
import { isNil } from 'lodash';
import { Alert, AlertService, MbsPopupType, ModalService } from 'mbs-ui-kit';
import moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, noop } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import FeedBackCredential from '../../models/FeedBackCredential';
import { PermissionsEnum } from '@models/PermissionsEnum';

// eslint-disable-next-line no-var
declare var sleek: {
  data: any;
  setUser(data: any): void;
  open(view, callback: any): void;
  showButton(): void;
  hideButton(): void;
  close(): void;
};

@UntilDestroy()
@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html'
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;

  public showFooter = true;
  public showFeedback$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public activateRouteComponent: any;
  public currentUser: Administrator;

  public disconnectAlert: Alert;

  private isIE;
  private readonly DAYS_FOR_SHOW_MODAL_AGAIN = 14;
  private readonly NUMBER_OF_MODAL_SHOW = 3;

  @ViewChild('timezoneAlertTemplate', { static: true }) timezoneAlertTemplate: TemplateRef<any>;
  @ViewChild('disconnectAlertTemplate', { static: true }) disconnectAlertTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private titleService: Title,
    private ability: AbilityService,
    private router: Router,
    private route: ActivatedRoute,
    private isLoadingService: IsLoadingService,
    @Inject(DOCUMENT) private dom: Document,
    private auth: AuthService,
    @Inject(APP_BASE_HREF) private appBaseHref: string,
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    private appPersistent: AppPersistentStateService,
    private cdr: ChangeDetectorRef,
    private appLayoutService: AppLayoutService,
    private postHogModule: PosthogModule,
    private modalService: ModalService,
    private tFAService: TFAService,
    private applicationFacade: ApplicationStateFacade,
    private config: ConfigurationService,
    private signalR: SignalRService,
    private computersService: ComputersAbstractService
  ) {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event) => {
      const child = this.route.children[0];
      this.showFooter = !(child.routeConfig.component && child.routeConfig.component === EmptyPageComponent);
      this.handleUpdateTitle();
    });
    ability.on(EventTypes.Update, () => {
      this.handleUpdateTitle();
    });
    this.handleUpdateTitle();

    // check and send to parent window if double iframe
    window.top != window &&
      window.top.postMessage({ msg: InteractionEvent.IFRAME_IN_IFRAME, currentRoute: dom.location.pathname.replace(appBaseHref, '') }, '*');

    auth.currentUser
      .pipe(
        first((user: Administrator) => !!user && !!user.ProviderInfo),
        untilDestroyed(this)
      )
      .subscribe((user: Administrator) => (this.currentUser = user));
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.isIE = Boolean(this.dom.documentMode); // document mode implements only in IE
    this.isLoading$ = this.isLoadingService.isLoading$();
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof GuardsCheckEnd ||
            event instanceof NavigationEnd ||
            event instanceof NavigationError ||
            event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        // If it's the end guard check, `add()` a loading indicator
        // For wait when resolve `DataChangeWatcher`
        if (event instanceof GuardsCheckEnd) {
          this.isLoadingService.add();
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });

    if (this.ability.can('read', 'HelpMarketing') && this.ability.cannot('read', 'HideFeedback')) {
      this.addSleekPlanFeedbackForm();
      this.checkRemindLateDate();
    }

    this.appLayoutService.showFooter$.pipe(untilDestroyed(this)).subscribe((showFooter) => {
      if (isNil(showFooter)) return;
      this.showFooter = showFooter;
    });

    if (localStorage.getItem('after2FALogin') === 'true' && this.ability.can('read', PermissionsEnum.HelpMarketing)) {
      localStorage.setItem('after2FALogin', 'false');
      this.showForceMbs2FAModal();
    }

    if (localStorage.getItem('showAllowOfflineEdit')) {
      this.computersService
        .getComputers({ os: [OsType.windows], appIds: [AgentType.Backup], offset: 0, limit: 1 })
        .pipe(
          tap((result) => !result?.data?.length && localStorage.removeItem('showAllowOfflineEdit')),
          untilDestroyed(this),
          first()
        )
        .subscribe({
          next: (result) => result?.data?.length && this.showAllowOfflineEdit()
        });
    }
  }

  showAllowOfflineEdit(): void {
    if (!this.currentUser?.IsProvider || this.ability.can('read', 'AllowOfflineSettingsEdit')) return;

    localStorage.setItem('isOpenAllowOfflineEdit', 'true');

    this.applicationFacade
      .getUiStorage()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (storage) => {
          const allowOfflineEdit = storage?.AllowOfflineEdit ? JSON.parse(storage.AllowOfflineEdit) : null;
          const currentAllowSettings = allowOfflineEdit?.[this.currentUser.Id];

          localStorage.removeItem('showAllowOfflineEdit');

          if (
            storage?.ShowOfflineEditBanner !== 'true' ||
            currentAllowSettings?.showed > this.NUMBER_OF_MODAL_SHOW ||
            (!isNil(currentAllowSettings) &&
              moment().diff(moment(allowOfflineEdit[this.currentUser.Id].lastShow, onlyDateFormat), 'days') <
                this.DAYS_FOR_SHOW_MODAL_AGAIN)
          ) {
            return localStorage.removeItem('isOpenAllowOfflineEdit');
          }

          const settings = {
            data: { allowOfflineEdit, id: this.currentUser.Id, simpleMode: false },
            header: { headerCustomClasses: 'p-0 m-0', showCloseCross: false }
          };

          this.modalService.openCustom(AllowOfflineEditModalComponent, settings).then(noop).catch(noop);
        },
        error: () => localStorage.removeItem('isOpenAllowOfflineEdit')
      });
  }

  ngAfterViewInit(): void {
    if (this.isIE) this.dom.documentElement.classList.add('-unsupported-browser');
    if (
      this.currentUser &&
      !this.currentUser.ProviderInfo.TimeZoneSpecified &&
      this.timezoneAlertTemplate &&
      !this.appPersistent.data.timeZoneAlertDismissed &&
      this.currentUser.IsWizardComplete
    ) {
      this.auth
        .fetchCurrentUser()
        .pipe(
          filter((user) => !user.ProviderInfo.TimeZoneSpecified),
          switchMap((user) =>
            this.alertService.alert(
              new Alert({ content: this.timezoneAlertTemplate, keepAfterRouteChange: true, type: MbsPopupType.danger })
            )
          ),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.appPersistent.data.timeZoneAlertDismissed = true;
        });
      this.cdr.detectChanges();
    }

    this.handleSignalDisconnect();
  }

  showForceMbs2FAModal() {
    combineLatest([this.tFAService.getAuthType(), this.applicationFacade.getUiStorage()]).subscribe({
      next: ([type, storage]) => {
        if (this.config.get('showForceMSP2FAModal') && type !== AuthType.MbsApp && storage[UiStorageKey.HideMSP2FAModal] !== 'true') {
          this.modalService.openCustom(ForceMSP2FaModalComponent).finally(noop);
        }
      }
    });
  }

  changeTimezoneClickHandler(): void {
    this.router.navigate(['/AP/Settings']);
  }

  ngOnDestroy(): void {
    if (this.ability.can('read', 'HelpMarketing') && this.ability.cannot('read', 'HideFeedback')) {
      window['$sleek'].resetUser();
      window['$sleek'].hideButton();
      if (environment.production) {
        window['$sleek'].close();
      }
    }
  }

  addSleekPlanFeedbackForm(): void {
    const onloadCallback = () => {
      this.auth.getFeedBackToken().subscribe((token) => {
        this.setSleekUserData(token);
        this.addSleekEvents();
      });
    };

    const feedBackWidget = this.dom.querySelector('#sleek-widget-wrap');
    if (feedBackWidget) {
      onloadCallback();
      return;
    }

    window['$sleek'] = [];
    window['SLEEK_PRODUCT_ID'] = this.config.get('production') === 'false' ? 804160924 : 956021337;

    const newScript = this.dom.createElement('script');

    newScript.onerror = (event: any) => {
      throw new URIError(`The Sleek Plan Feedback Form (${event.target.src}) didn't load correctly.`);
    };
    newScript.type = 'text/javascript';

    newScript.onload = onloadCallback;
    this.dom.head.appendChild(newScript);
    newScript.src = 'https://client.sleekplan.com/sdk/e.js';
  }

  setSleekUserData(token: FeedBackCredential): void {
    const tokenData = jwtDecode(token.access_token);
    window['$sleek'].setUser({
      mail: tokenData['mail'],
      id: tokenData['id'],
      token: token.access_token
    });
  }

  addSleekEvents(): void {
    window['$sleek'].on('open', () => {
      this.showFeedback$.next(false);
    });
    window['$sleek'].on('close', () => {
      this.showFeedback$.next(true);
    });
  }

  handleUpdateTitle(): void {
    if (this.ability.can('read', 'MBS')) {
      this.titleService.setTitle('Managed Backup Service');
    }
  }

  handleActiveRoute(componentInstance?: any): void {
    Promise.resolve()
      .then(() => {
        this.activateRouteComponent = componentInstance;
      })
      .catch(noop);
  }

  handleCloseFeedbackModal(): void {
    this.showFeedback$.next(false);
  }

  checkRemindLateDate(): void {
    if (this.appPersistent.data.feedbackRemindLateDate) {
      const delta = new Date().getTime() - new Date(this.appPersistent.data.feedbackRemindLateDate).getTime();
      if (delta > 30 * 24 * 60 * 60 * 1000) {
        this.showFeedback$.next(true);
      } else {
        this.showFeedback$.next(false);
      }
    } else if (this.currentUser.SignUpDate) {
      const delta = new Date().getTime() - new Date(this.currentUser.SignUpDate).getTime();
      if (delta > 2 * 24 * 60 * 60 * 1000) {
        this.showFeedback$.next(true);
      } else {
        this.showFeedback$.next(false);
      }
    } else {
      this.showFeedback$.next(true);
    }
  }

  handleOpenHelpFeedback(): void {
    this.showFeedback$.next(true);
  }

  startSignalConnection() {
    this.signalR.restartAuthorizedConnection();

    this.alertService.clearById(this.disconnectAlert.id);
  }

  handleSignalDisconnect() {
    this.signalR.onclose.pipe(untilDestroyed(this)).subscribe((error) => {
      console.log(`${new Date()}: signalR.onclose : ${error}`);
      this.showDisconnectAlert();
    });
  }

  showDisconnectAlert() {
    this.disconnectAlert = new Alert({
      id: 'disconnect-alert',
      icon: true,
      content: this.disconnectAlertTemplate,
      keepAfterRouteChange: false,
      type: MbsPopupType.danger
    });

    console.log(`${new Date()}: showDisconnectAlert : ${this.disconnectAlert}`);

    this.alertService.alert(this.disconnectAlert);
  }
}
