import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, NgControl, Validators } from '@angular/forms';
import {
  DropdownPosition,
  NgFooterTemplateDirective,
  NgHeaderTemplateDirective,
  NgLabelTemplateDirective,
  NgLoadingSpinnerTemplateDirective,
  NgLoadingTextTemplateDirective,
  NgMultiLabelTemplateDirective,
  NgNotFoundTemplateDirective,
  NgOptgroupTemplateDirective,
  NgOptionComponent,
  NgOptionTemplateDirective,
  NgSelectComponent,
  NgTagTemplateDirective,
  NgTypeToSearchTemplateDirective
} from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { MbsValidators } from '../../utils';
import { InputBase } from '../input-base/input-base';

export declare type AddTagFn = (term: string) => any | Promise<any>;
export declare type CompareWithFn = (a: any, b: any) => boolean;
export declare type GroupValueFn = (key: string | any, children: any[]) => string | any;

export interface SearchState {
  term: string;
  items: any[];
}

@UntilDestroy()
@Component({
  selector: 'mbs-select',
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent extends InputBase<any> implements OnInit, AfterViewInit {
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() markFirst = true;
  @Input() placeholder: string;
  @Input() notFoundText = 'Items not found';
  @Input() typeToSearchText: string;
  @Input() addTagText: string;
  @Input() loadingText: string;
  @Input() clearAllText: string;
  @Input() appearance: string;
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() appendTo: string;
  @Input() loading = false;
  @Input() closeOnSelect = true;
  @Input() hideSelected = false;
  @Input() selectOnTab = false;
  @Input() openOnEnter: boolean;
  @Input() maxSelectedItems: number;
  @Input() groupBy: string | (() => any);
  @Input() bufferAmount = 4;
  @Input() virtualScroll: boolean;
  @Input() selectableGroup = false;
  @Input() selectableGroupAsModel = true;
  @Input() searchFn = null;
  @Input() trackByFn = null;
  @Input() clearOnBackspace = true;
  @Input() labelForId = null;
  @Input() inputAttrs: { [key: string]: string } = {};
  @Input() labelForCounter: string;
  @Input() isCounterShowing = false;
  @Input() tabIndex: number;
  @Input() readonly = false;
  @Input() searchWhileComposing = true;
  @Input() minTermLength = 0;
  @Input() editableSearchTerm = false;
  @Input() keyDownFn = (arg: KeyboardEvent) => true;

  @Input() multiple = false;
  @Input() addTag: boolean | AddTagFn = false;
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() isCheckboxSelect = false;
  @Input() isAutoWidth = false;
  @Input() isOpen = null;

  @Input() public required = false;

  @Input() public typeahead: Subject<any>;

  @Input() set compareWith(fn: CompareWithFn) {
    this.ngSelect.compareWith = fn;
  }

  @Input() clearSearchOnAdd: boolean;

  @Input() items: any[] = [];
  @Input() selectedItemsFirst = false;

  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel = false;

  @Output('blur') blurEvent = new EventEmitter();
  @Output('focus') focusEvent = new EventEmitter();
  @Output('change') changeEvent = new EventEmitter();
  @Output('open') openEvent = new EventEmitter();
  @Output('close') closeEvent = new EventEmitter();
  @Output('search') searchEvent = new EventEmitter<{ term: string; items: any[] }>();
  @Output('clear') clearEvent = new EventEmitter();
  @Output('add') addEvent = new EventEmitter();
  @Output('remove') removeEvent = new EventEmitter();
  @Output('scroll') scroll = new EventEmitter<{ start: number; end: number }>();
  @Output('scrollToEnd') scrollToEnd = new EventEmitter();

  @ContentChild(NgOptionTemplateDirective, { read: TemplateRef, static: true }) optionTemplate: TemplateRef<any>;
  @ContentChild(NgOptgroupTemplateDirective, { read: TemplateRef, static: true }) optgroupTemplate: TemplateRef<any>;
  @ContentChild(NgLabelTemplateDirective, { read: TemplateRef, static: true }) labelTemplateSelect: TemplateRef<any>;
  @ContentChild(NgMultiLabelTemplateDirective, { read: TemplateRef, static: true }) multiLabelTemplate: TemplateRef<any>;
  @ContentChild(NgHeaderTemplateDirective, { read: TemplateRef, static: true }) headerTemplate: TemplateRef<any>;
  @ContentChild(NgFooterTemplateDirective, { read: TemplateRef, static: true }) footerTemplate: TemplateRef<any>;
  @ContentChild(NgNotFoundTemplateDirective, { read: TemplateRef, static: true }) notFoundTemplate: TemplateRef<any>;
  @ContentChild(NgTypeToSearchTemplateDirective, { read: TemplateRef, static: true }) typeToSearchTemplate: TemplateRef<any>;
  @ContentChild(NgLoadingTextTemplateDirective, { read: TemplateRef, static: true }) loadingTextTemplate: TemplateRef<any>;
  @ContentChild(NgTagTemplateDirective, { read: TemplateRef, static: true }) tagTemplate: TemplateRef<any>;
  @ContentChild(NgLoadingSpinnerTemplateDirective, { read: TemplateRef, static: true }) loadingSpinnerTemplate: TemplateRef<any>;

  @ContentChildren(NgOptionComponent, { descendants: true }) ngOptions: QueryList<NgOptionComponent>;

  @ViewChild(NgSelectComponent, { static: true }) ngSelect: NgSelectComponent;

  protected labelContentClassesDefault = 'mbs-select_label-content';

  public searchState: SearchState;

  private myValidationWhitespace = false;
  @Input() set validationWhitespace(value: boolean) {
    this.myValidationWhitespace = String(value) !== 'false';
    this.handleWhitespaceValidation(this.myValidationWhitespace);
  }

  public get isAllItemsSelected(): boolean {
    return this.formControlSelect.value && this.formControlSelect.value?.length === this.items.length;
  }

  public get formControlSelect(): AbstractControl {
    return this.ngControl.control ? this.ngControl.control : new FormControl('', this.ngControl.validator, this.ngControl.asyncValidator);
  }

  constructor(@Optional() @Self() ngControl: NgControl, protected cd: ChangeDetectorRef) {
    super(ngControl, cd);
  }

  ngOnInit(): void {
    if (this.ngControl.valueChanges) {
      this.ngControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
    }
    if (this.ngControl.statusChanges) {
      this.ngControl.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
    }
  }

  ngAfterViewInit(): void {
    this.handleWhitespaceValidation(this.myValidationWhitespace);

    if (this.optionTemplate) this.ngSelect.optionTemplate = this.optionTemplate;
    if (this.optgroupTemplate) this.ngSelect.optgroupTemplate = this.optgroupTemplate;
    this.ngSelect.labelTemplate = this.labelTemplateSelect;
    if (this.multiLabelTemplate) this.ngSelect.multiLabelTemplate = this.multiLabelTemplate;
    if (this.headerTemplate) this.ngSelect.headerTemplate = this.headerTemplate;
    this.ngSelect.footerTemplate = this.footerTemplate;
    this.ngSelect.notFoundTemplate = this.notFoundTemplate;
    this.ngSelect.typeToSearchTemplate = this.typeToSearchTemplate;
    this.ngSelect.loadingTextTemplate = this.loadingTextTemplate;
    this.ngSelect.tagTemplate = this.tagTemplate;
    this.ngSelect.loadingSpinnerTemplate = this.loadingSpinnerTemplate;
    this.ngSelect.ngOptions = this.ngOptions;

    this.ngSelect.ngAfterViewInit();
  }

  ngSelectChangeHandler<T>(event: T): void {
    if (event && this.bindValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      this.value = this.multiple && Array.isArray(event) ? event.map((i) => i[this.bindValue]) : event[this.bindValue];
    } else {
      this.value = event;
    }
    this.changeEvent.emit(this.value);
  }

  handleWhitespaceValidation(state: boolean): void {
    if (this.ngControl?.control && state !== false) {
      const extendValidators = Validators.compose([this.ngControl.control.validator, MbsValidators.whitespaceValidator]);
      this.ngControl.control.setValidators(extendValidators);
    }
  }

  handleOpen(): void {
    queueMicrotask(() => {
      const dropdownElement = document.querySelector(`#${this.ngSelect.dropdownId}`);
      if (dropdownElement) {
        this.updateDropdownClasses(dropdownElement);
      }
    });
    this.openEvent.emit();
  }

  handleClose(event) {
    if (this.selectedItemsFirst) {
      this.sortBySelection();
    }
    this.closeEvent.emit(event);
  }

  sortBySelection() {
    const first = [];
    const last = [];

    this.items.forEach((item) => {
      const value = item[this.bindValue];

      if (this.value?.includes(value)) {
        first.push(item);
      } else {
        last.push(item);
      }
    });

    this.items = [...first, ...last];
  }

  updateDropdownClasses(dropdownElement: Element) {
    dropdownElement.classList.remove('ng-select-sm');
    dropdownElement.classList.remove('ng-select-lg');
    const cssClass = this.size ? 'ng-select-' + this.size : '';
    if (cssClass) {
      dropdownElement.classList.add(cssClass);
    }
  }

  public onSearch(event: { term: string; items: any[] }) {
    this.searchState = event;
  }

  public clearSearch() {
    this.searchState = null;
  }

  public isNotFoundItems() {
    return (this.searchState?.term?.length && !this.searchState?.items?.length) || (!this.searchState?.term?.length && !this.items?.length);
  }

  onSelectAll(value: boolean) {
    const values = this.bindValue ? this.items.map((item) => item[this.bindValue]) : this.items;
    this.formControlSelect.patchValue(value ? values : []);
  }

  public isSomeItemsSelected(group?): boolean {
    const values = this.formControlSelect.value;

    return group ? group.children?.some((child) => values?.includes(child.value.value)) : values?.length && !this.isAllItemsSelected;
  }
}
