export enum ErrorCode {
  AnErrorOccured = 1003,
  InvalidDecryptionPassword = 2000,
  LicenseLimitExceeded = 4129,
  NoLicences = 4125,
  InvalidBitlockerPassword = 2046,
  DeepSyncIsRequired = 2526,
  ObjectNotFound = 4106,
  ExternalServiceRequestError = 4123,
  UnknownError = 4999,
  GroupTaskNotFound = 5109
}

/* eslint-disable sonarjs/no-duplicate-string */
export interface SupportError {
  code: number;
  caption: string;
  description: string;
}

export const SupportErrors: SupportError[] = [
  { code: 1003, caption: 'An error occured', description: 'An unknown error occured. Please contact support' },
  { code: 1010, caption: 'No access to bucket', description: 'Bucket specified in the Storage Account settings was not found' },
  { code: 1012, caption: 'Storage Account is not specified', description: 'Storage Account for the backup plan is not specified' },
  { code: 1013, caption: 'Unauthorized changes were detected', description: '{Plan_Name}' },
  { code: 1014, caption: 'Pre Action error', description: 'Description taken from exception' },
  { code: 1015, caption: 'Post Action error', description: 'Description taken from exception' },
  {
    code: 1020,
    caption: 'Internet connection is not available',
    description: 'There were problems with the Internet connection during the plan execution'
  },
  { code: 1021, caption: 'Files are missing in the storage', description: 'Inconsistent backup, to correct please run a Full backup.' },
  { code: 1025, caption: 'Interrupted by user', description: 'Network path was not found' },
  { code: 1027, caption: 'No files/folders were backed up', description: 'Nothing to back up' },
  {
    code: 1030,
    caption: 'No route to remote host',
    description:
      'Cannot connect to the backup destination. Description: Cannot connect to the backup destination: A socket operation was attempted to an unreachable network {ip:port}'
  },
  { code: 1036, caption: 'Unauthorized changes were detected', description: 'Unauthorized changes were detected in settings' },
  { code: 1037, caption: 'Not enough space', description: 'Not enough space to create a VSS snapshot' },
  { code: 1038, caption: 'Not enough space', description: 'The volume of repository or deduplication DB location ({0}) is full' },
  {
    code: 1039,
    caption: 'User account is disabled',
    description: 'Access to the network resource(s) is denied. User account is currently disabled or locked'
  },
  { code: 1042, caption: 'No active license', description: 'No active licenses for this product edition' },
  {
    code: 1046,
    caption: 'Malformed database',
    description: 'Cannot read data from the database. The disk where the database is located may be damaged or database files are corrupted'
  },
  {
    code: 1047,
    caption: 'Purge error',
    description: 'An error occurred while attempting to purge data on backup storage: {taken from exception}'
  },
  { code: 1054, caption: 'Pre / Post actions disabled', description: 'Execution of pre /post actions is restricted by the provider' },
  { code: 1402, caption: 'Cannot back up', description: 'The storage account you are trying to back up to is in the restore only mode' },
  {
    code: 1403,
    caption: 'License error',
    description:
      'Your current license limits VMware and Hyper-V host number to {0} sockets in total. Total sockets number is {1} which exceeds the license limit'
  },
  { code: 1404, caption: 'License error', description: 'Cannot run the plan with no license' },
  { code: 1406, caption: 'The plan type is not supported', description: 'The current edition does not support plans of this type' },
  { code: 1502, caption: 'Company storage limit exceeded', description: 'The storage limit specified for the company was exceeded' },
  {
    code: 1503,
    caption: 'Backup size limit error (mbs user limit)',
    description: 'Your quota in the company storage limit ( {0} ) was exceeded'
  },
  { code: 1506, caption: 'Not enough space', description: 'Not enough space in the selected backup destination: {0}' },
  {
    code: 1508,
    caption: 'Error on creating VSS snapshot',
    description: 'The current account has not enough privileges to create VSS snapshots'
  },
  { code: 1511, caption: 'No access to backup storage', description: 'The backup storage {0} cannot be reached' },
  { code: 1513, caption: 'Network resource(s) cannot be accessed', description: 'Specified credentials are invalid' },
  {
    code: 1516,
    caption: 'Cannot back up',
    description:
      'User data is currently being deleted. Backup operations cannot be performed. Please wait until user data is delelted. This make take up to several hours'
  },
  { code: 1517, caption: 'Not enough space', description: 'Not enough space for temporary files' },
  {
    code: 1524,
    caption: 'Duplicate revision number',
    description:
      '* File {0} has duplicated revisions in the cloud storage * Revision with the number {0} already exists in the cloud storage'
  },
  {
    code: 1525,
    caption: 'Incorrect chain order',
    description:
      '* File {0} has incorrect chain order in the cloud storage * Revision {0} ({1}) is older than revision {2} ({3}) * Revision {0} ({1}) is newer than revision {2} ({3})'
  },
  { code: 1527, caption: 'Full backup is missing', description: 'No full backup is found in the cloud storage for backup file {0}' },
  {
    code: 1528,
    caption: 'Backup destination is in read-only mode',
    description: 'Cannot back up. The storage account {0} is in the read-only mode'
  },
  { code: 1529, caption: 'Network resource cannot be accessed', description: 'Cannot access the network resource: {0}' },
  { code: 1534, caption: 'Backup storage unavailable', description: 'The file system storage account: {0} is not available' },
  {
    code: 1601,
    caption: 'No access to {0} files / folders',
    description: '1 file/folder selected for backup is not found in the backup source or cannot be accessed'
  },
  {
    code: 1602,
    caption: '{0} EFS-encrypted objects were detected. Access is denied',
    description: ' The access to 1 object in the backup source is denied due to EFS encryption'
  },
  {
    code: 1605,
    caption: '{0} files cannot be accessed',
    description: 'The access to 1 file in the backup source is denied because this file is used by other process'
  },
  { code: 1610, caption: 'Access was denied to {0} files/folders', description: 'Access is denied to local file set for backup' },
  { code: 1611, caption: 'Access was denied to {0} network resources', description: 'Access is denied to the network resource' },
  {
    code: 1614,
    caption: 'Could not back up {0} OneDrive On-Demand files',
    description: 'Cannot back up 1 OneDrive On-Demand file. Download timeout'
  },
  { code: 1617, caption: '{0} hybrid paths were not found', description: '1 hybrid path was not found' },
  { code: 1622, caption: 'No access to some files', description: 'Unable to access some files in backup source' },
  {
    code: 1623,
    caption: 'Incompatible characters',
    description: 'Files and folders containing symbols unsupported in UTF-8 encoding were not backed up: * Files - {0} * Folders - {1}.'
  },
  { code: 1625, caption: 'Scanning error', description: 'An error occurred on scanning backup source: {0}' },
  {
    code: 1628,
    caption: 'Infected file(s)',
    description: 'Could not back up {0} file(s): Windows Defender detected them as infected with malware'
  },
  { code: 1704, caption: 'Cannot find partitions', description: 'The following partitions in the backup source were not found: {0}' },
  { code: 1705, caption: 'Volume not found', description: 'Source partition was not found' },
  {
    code: 1706,
    caption: 'Synthetic error',
    description: 'Some backups were missing in the cloud storage. Missed data was uploaded from the local source'
  },
  {
    code: 1711,
    caption: 'Cannot back up disk(-s)',
    description: 'One of the disks (volumes) cannot be backed up. The volume {0} is BitLocker-encrypted'
  },
  { code: 1712, caption: 'Shadow copy is missing', description: 'Failed to complete the backup plan. Shadow copy was not found' },
  { code: 1714, caption: 'Cannot read MFT', description: 'One or more MFT records cannot be read. The disk may be corrupted' },
  { code: 1750, caption: 'Exchange base not found', description: '1 database was not found' },
  {
    code: 1753,
    caption: 'Not enough privileges',
    description: 'The Exchange instance does not have Audit Security Privilege on the domain controller. EventID: 2112'
  },
  { code: 1801, caption: 'Not enough space on disk', description: 'Not enough space to handle temporary MS SQL backup files' },
  { code: 1802, caption: 'Database not found', description: '1 database was not found' },
  { code: 1804, caption: 'Database has invalid state', description: '1 database has invalid state' },
  { code: 1805, caption: 'SQL connection error', description: 'Text from exception' },
  { code: 1806, caption: 'Authentication failed', description: 'Text from exception' },
  { code: 1807, caption: 'Error on MS SQL backup', description: 'Text from exception' },
  {
    code: 1812,
    caption: 'Log sequence numbers do not match',
    description:
      'Could not complete transaction log backup. Log sequence numbers do not match. A full backup was forced for the following databases: - {0} - {0}'
  },
  {
    code: 1813,
    caption: 'Unable to check LSN sequence',
    description:
      'The LSN sequence could not be checked. Despite the last differential/transaction log backup was successful, the backup consistency of the following databases cannot be guaranteed: {0}'
  },
  { code: 1904, caption: 'Virtual machine not found', description: '1 virtual machine was not found' },
  { code: 1905, caption: 'Virtual machine error ', description: 'Description taken from exception' },
  { code: 1906, caption: 'Connection error', description: 'Description taken from exception' },
  {
    code: 1908,
    caption: 'Could not apply the Changed Block Tracking feature to {0} VMs ',
    description:
      'Backup plan was executed without Changed Block Tracking. This feature cannot be applied to VMs having previously made snapshots. View the list of VMs below: {0}'
  },
  { code: 1909, caption: 'Cannot connect to ESXi server', description: 'Connection attempt to ESXi Server failed. Connection refused' },
  { code: 1910, caption: 'ESXi/vCenter server error', description: 'from exception' },
  {
    code: 1912,
    caption: 'Pass-through disks detected',
    description:
      'Pass-through disks were not backed up. Only recovery snapshots for virtual machines with pass-through disks were backed up. View the virtual machine list with pass-through disks: {0}'
  },
  { code: 1914, caption: 'WMI Error', description: 'WMI job failed. Reason: {0}' },
  { code: 1915, caption: 'WMI Error', description: 'Change to the last possible checkpoint' },
  { code: 1916, caption: 'WMI Error', description: 'Not enough permissions' },
  { code: 1917, caption: 'VMware error', description: 'Free vSphere Hypervisor is not supported' },
  {
    code: 1918,
    caption: 'Invalid host server credentials',
    description: 'The credentials for the host server are invalid or not specified'
  },
  {
    code: 1919,
    caption: 'Changed Block Tracking was not applied',
    description: 'Changed Block Tracking (CBT) UUID was changed for some reason. The following disks were backed up without CBT: {0}'
  },
  {
    code: 1922,
    caption: 'VMware Tools are missing',
    description:
      'VMware Tools required for quiescence are not installed on one or more VMs. View the list of VMs without VMware Tools below: {0}'
  },
  {
    code: 1930,
    caption: 'VMware Quiescing Error',
    description: 'Cannot create application-consistent snapshot(s) for one or more VMs. View the list of VMs below: {0}'
  },
  {
    code: 1931,
    caption: 'Hyper-V Quiescing Error',
    description: 'Cannot create application-consistent checkpoint(s) for one or more VMs. View the list of VMs below: {0}'
  },
  {
    code: 1932,
    caption: 'Cannot back up VM(s)',
    description:
      'Failed to create checkpoint(s) for VM(s). Could not find all disks listed in VM config(s) or could not find checkpoint file(s).  View the VM list below: {0}'
  },
  {
    code: 1933,
    caption: 'Changed Block Tracking Not Supported',
    description: "Changed Block Tracking was not applied for some VM's: their versions do not support CBT. View the list of VMs below: {0}"
  },
  {
    code: 1934,
    caption: 'Changed Block Tracking was not applied',
    description:
      'Could not get the previous backup tracking point(s) for some VMs. Changed Block Tracking was not applied. View the VM list below: {0}'
  },
  { code: 1939, caption: 'WMI error', description: 'Invalid parameter of one of Hyper-V components. WMI error code: {0}' },
  { code: 1940, caption: 'WMI error', description: 'System is in used. WMI error code: {0}' },
  { code: 2000, caption: 'Invalid decryption password', description: 'Invalid decryption password was specified. The restore plan failed' },
  { code: 2002, caption: 'Existing files were skipped', description: '1 existing file was skipped' },
  { code: 2003, caption: 'Cannot find file to restore', description: '1 file is not found in storage' },
  {
    code: 2004,
    caption: 'Not enough space',
    description: 'Not enough space on the specified restore destination: {0}. The restore plan cannot be completed'
  },
  { code: 2006, caption: 'Failed to restore database', description: 'Description taken from exception' },
  {
    code: 2008,
    caption: 'File not found or encryption password is incorrect',
    description: 'Cannot decrypt file names in storage or files are missing in storage'
  },
  { code: 2019, caption: 'Restore verification failed', description: 'Restore Verification failed by unknown reason or from exception' },
  { code: 2026, caption: 'Cannot restore data', description: 'Restore plan has failed. Revision, bitmap, or metadata file are missing' },
  {
    code: 2029,
    caption: 'Cannot restore all objects',
    description: 'Some data parts on backup storage are missing. Only objects from existing data parts were restored'
  },
  {
    code: 2030,
    caption: 'Cannot restore data',
    description: 'Failed to restore the backup content. Some data parts on backup storage are missing'
  },
  {
    code: 2035,
    caption: 'Invalid point in time',
    description:
      'The specified point in time is earlier than the first available version of the selected object(s) (file, VM, etc.). Nothing was restored. Specify a later point in time, or select a required object (file, VM, etc.) version manually in the restore plan wizard'
  },
  {
    code: 2037,
    caption: 'Restore limit exceeded',
    description: 'The restore limit ({0} GB) was exceeded while attempting to download the required data from backup storage'
  },
  { code: 2038, caption: 'Cannot restore data', description: 'No access to the target restore destination' },
  { code: 2400, caption: 'VSS Error', description: 'Shadow Copy Provider error: The system cannot find the file specified. Event ID: 11' },
  { code: 2401, caption: 'VSS Error', description: 'An error occurred on starting COM service. Event ID: 13' },
  {
    code: 2402,
    caption: 'VSS Error',
    description: 'A critical component required by the Volume Shadow Copy service is not registered. Event ID: 22'
  },
  {
    code: 2404,
    caption: 'VSS Error',
    description: 'VSS System Writer does not have permissions to read the service account. Event ID: 513'
  },
  { code: 2405, caption: 'VSS Error', description: 'The Volume Shadow Copy service failed to start. Event ID: 1000' },
  { code: 2407, caption: 'VSS Error', description: 'Unexpected Volume Shadow Copy Service (VSS) error has occurred. Event ID: 8193' },
  { code: 2408, caption: 'VSS Error', description: 'Access denied. No sufficient permissions. Event ID: 8194' },
  { code: 2411, caption: 'VSS Error', description: 'An error occurred while trying to communicate with VSS writer. Event ID: {0}' },
  { code: 2412, caption: 'VSS Error', description: 'Access denied. Event ID: {0}' },
  { code: 2415, caption: 'VSS Error', description: 'An error occurred while creating the Shadow Copy Provider COM class. Event ID: 12292' },
  { code: 2416, caption: 'VSS Error', description: 'An error occurred while calling a routine on a Shadow Copy Provider. Event ID: 12293' },
  { code: 2417, caption: 'VSS Error', description: 'Shadow copy creation period is exceeded. Event ID: 12298' },
  { code: 2418, caption: 'VSS Error', description: 'Shadow copy commit timeout. Event ID: 12310' },
  { code: 2419, caption: 'VSS Error', description: 'VSS Writer timeout. Event ID: 12340' },
  { code: 2422, caption: 'VSS Error', description: 'Unexpected Error. Event ID: 12289' },
  {
    code: 2500,
    caption: 'Consistency Check detected an issue',
    description:
      'One or more data parts are missing on backup storage for restore point: {0}. This restore point and all the following restore points may not be restored properly. A full backup was executed instead of the planned incremental backup and a new generation was created'
  },
  {
    code: 2503,
    caption: 'Backup from another computer(s) with the same prefix detected and marked as forbidden',
    description:
      'Backups to the same backup destination with the same backup prefix from different computers at the same time are not allowed. A full backup was forced'
  },
  {
    code: 2504,
    caption: 'Another computer marked current backup as forbidden',
    description:
      'Backups to the same backup destination with the same backup prefix from different computers at the same time are not allowed. A full backup was forced'
  },
  { code: 2507, caption: 'Consistency Check detected an issue', description: 'One or more generations were missing' },
  {
    code: 2509,
    caption: 'Full Consistency Check detected an issue',
    description:
      'One or more data parts are missing on backup storage for restore point: {0}. This restore point and all the following restore points may not be restored properly'
  },
  {
    code: 2512,
    caption: 'Deduplication blocks are missing',
    description: 'Some deduplication blocks were not found. The next backup plan run will be full backup'
  },
  {
    code: 2516,
    caption: 'Synthetic backup failed',
    description:
      'Objects in long-term storage were detected during synthetic backup run. Synthetic backup cannot be executed for these objects. A full backup was executed instead'
  },
  { code: 2519, caption: 'Native multipart upload is disabled', description: 'Cannot back up. Native multipart upload must be enabled' },
  {
    code: 2521,
    caption: 'Full Consistency Check detected an issue',
    description: 'An error occurred during the full consistency check in the generation {0}: {exception} '
  },
  {
    code: 2525,
    caption: 'Restore points creation time mismatch',
    description:
      'Could not create the new restore point: the current system time precedes the previous restore point creation time with the difference: {0}'
  },
  {
    code: 2527,
    caption: 'Generation without valid restore points',
    description:
      'The current generation does not contain any valid restore points: retention policy cannot be applied. Previous generations were not purged'
  },
  {
    code: 2600,
    caption: 'Hybrid backup error',
    description:
      '* File {0} has duplicated revisions in the cloud storage * Revision with the number {0} already exists in the cloud storage'
  },
  {
    code: 2601,
    caption: 'Hybrid backup error',
    description:
      '* File {0} has incorrect chain order in the cloud storage * Revision {0} ({1}) is older than revision {2} ({3}) * Revision {0} ({1}) is newer than revision {2} ({3})'
  },
  {
    code: 3000,
    caption: 'The Computer clock is not synchronized',
    description:
      '* Case if the cloud time is received: The difference between local system time and backup storage time is: {0} d {1} h {2} min {3} sec {4} * Case if the cloud time is not received: The system time differs from the time on backup storage'
  },
  {
    code: 3008,
    caption: 'Connection error',
    description: 'An error occured while connecting to a storage destinations %Exception message%'
  },
  {
    code: 3009,
    caption: 'Unsupported symbols in filenames',
    description: 'Filenames in a backup source contain symbols unsupported by a storage provider'
  },
  { code: 3010, caption: 'free slot!', description: '' },
  {
    code: 3014,
    caption: 'Access denied (MBS)',
    description: 'Operation could not be completed due to insufficient permissions for upload to cloud storage.'
  },
  { code: 3015, caption: 'Internal backup storage error', description: 'An internal error has occurred on backup storage: {exception}' },
  { code: 3017, caption: 'Bad request', description: 'The backup storage provider returned the following error: {taken from exception}' },
  { code: 3018, caption: 'Backup storage error', description: 'The backup storage server returned an unexpected response' },
  {
    code: 3021,
    caption: 'Cannot delete some multiparts',
    description: 'Some multiparts were not deleted on backup storage for some reason'
  },
  {
    code: 3024,
    caption: 'Backup storage unavailable',
    description: 'The file system backup storage ({0}) was not available at the moment of backup execution'
  },
  { code: 2000, caption: 'SGAccountNotFound', description: '' }, // warning! duplicated "code" with different values of other properties
  { code: 2004, caption: 'SGAccountIsOutOfSpace', description: '' }, // warning! duplicated "code" with different values of other properties
  { code: 2008, caption: 'SGOperationIsNotAllowedForThisStorage', description: '' }, // warning! duplicated "code" with different values of other properties
  { code: 3008, caption: 'EndUserUserDisabled', description: '' }, // warning! duplicated "code" with different values of other properties
  { code: 9999, caption: '', description: 'The network path was not found - {path}' }
];

export const SupportErrorCodes = SupportErrors.map((errorCode) => errorCode.code);

const ProblemErrorCodes: number[] = [
  1003, 1010, 1012, 1013, 1014, 1015, 1020, 1021, 1025, 1027, 1030, 1036, 1037, 1038, 1039, 1042, 1046, 1047, 1054, 1402, 1403, 1404, 1406,
  1502, 1503, 1506, 1508, 1511, 1513, 1516, 1517, 1524, 1525, 1527, 1528, 1529, 1534, 1601, 1602, 1605, 1610, 1611, 1614, 1617, 1622, 1623,
  1625, 1628, 1704, 1705, 1706, 1711, 1712, 1714, 1750, 1753, 1801, 1802, 1804, 1805, 1806, 1807, 1812, 1813, 1904, 1905, 1906, 1908, 1909,
  1910, 1912, 1914, 1915, 1916, 1917, 1918, 1919, 1922, 1930, 1931, 1932, 1933, 1934, 1939, 1940, 2000, 2002, 2003, 2004, 2006, 2008, 2019,
  2026, 2029, 2030, 2035, 2037, 2038, 2400, 2401, 2402, 2404, 2405, 2407, 2408, 2411, 2412, 2415, 2416, 2417, 2418, 2419, 2422, 2500, 2503,
  2504, 2507, 2509, 2512, 2516, 2519, 2521, 2525, 2527, 2600, 2601, 3000, 3008, 3009, 3010, 3014, 3015, 3017, 3018, 3021, 3024, 9999
];

export const SupportProblemErrorCodes = SupportErrorCodes.filter((errorCode) => ProblemErrorCodes.includes(errorCode));
