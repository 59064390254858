import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { StepsService } from '@modules/wizards/onboarding/services/steps/steps.service';
import { BaseStep } from '@modules/wizards/onboarding/steps/shared/base/base.step';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppsService } from '@services/apps/apps.service';
import { User } from '@services/apps/apps.types';
import { MbsPopupType, TableHeader } from 'mbs-ui-kit';
import { of, repeat } from 'rxjs';
import { delay, mergeMap, takeWhile, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-apps-select-users-step',
  templateUrl: './select-users.component.html'
})
export class AppsSelectUsersComponent<StepId> extends BaseStep<StepId> implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;

  public readonly stepId = 'selectUsers';
  public readonly alertType = MbsPopupType;
  public allUsersControl: AbstractControl;
  public selectedUsersControl: AbstractControl;
  public readonly headers: TableHeader[] = [
    {
      name: '',
      gridColSize: '3fr',
      isGridColumn: false
    },
    {
      name: '',
      class: '-right',
      gridColSize: '1fr'
    }
  ];

  constructor(
    private appsService: AppsService,
    private cdr: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    stepService: StepsService<StepId>
  ) {
    super(stepService);
  }

  ngOnInit(): void {
    this.allUsersControl = this.form.get('users.all');
    this.selectedUsersControl = this.form.get('users.selected');

    if (this.form.get('users.loading')?.value) {
      this.getUsers();
    }
  }

  ngOnDestroy(): void {
    this.isValid = this.selectedUsersControl.value?.length;
    super.ngOnDestroy();
  }

  public changeSelectedHandler(event: User[]): void {
    this.selectedUsersControl.setValue(event);
    this.cdr.detectChanges();
  }

  private getUsers() {
    const query$ = of(null).pipe(
      untilDestroyed(this),
      mergeMap(() => this.appsService.getUsers(this.form.get('tokenData')?.value)),
      tap((response) => {
        if (response.status === HttpStatusCode.PartialContent && this.allUsersControl?.value?.length) return;

        this.form.get('users').setValue({
          all: response.value,
          selected: response.status === HttpStatusCode.PartialContent ? response.value : this.selectedUsersControl?.value,
          loading: response.status !== HttpStatusCode.Ok
        });
      }),
      delay(5000),
      repeat(),
      takeWhile((response) => response.status === HttpStatusCode.PartialContent)
    );

    query$.subscribe({
      error: () => {
        this.onboardingService.hasError$.next(true);
      }
    });
  }
}
