import { Component, Input, OnInit } from '@angular/core';
import RmmCommand from '@models/rmm/RmmCommand';
import { RmmCommandType } from '@models/rmm/RmmCommandType';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmmCommandsService } from '@services/rmm-commands.service';
import { RmmCommands } from './rmmCommands';

@UntilDestroy()
@Component({
  selector: 'mbs-command-tab',
  templateUrl: './command-tab.component.html'
})
export class CommandTabComponent implements OnInit {
  @Input() public hid: string;

  commandTypeList = Object.keys(RmmCommands);
  commandList: string[] = [];

  commandParams = '';
  commandResult = '';

  selectedCommandType: RmmCommandType;
  selectedCommand: string; //  RmmCommand<any>;

  isAsync = false;

  constructor(public rmmCommandsService: RmmCommandsService) {}

  commandTypeSelectChangeHandler(commandType: RmmCommandType): void {
    this.selectedCommandType = commandType;
    this.selectedCommand = null;
    this.commandParams = '';
    this.commandResult = '';

    this.commandList = RmmCommands[commandType].map((item) => {
      return JSON.stringify(item, (key, value) => {
        if (value === 'async') return undefined;
        return value;
      });
    });
  }

  commandSelectChangeHandler(item: string): void {
    this.commandResult = '';
    if (item) {
      this.selectedCommand = item;
      this.commandParams = item;
    }
  }

  ngOnInit(): void {
    this.rmmCommandsService.statCommandRes.pipe(untilDestroyed(this)).subscribe((res) => {
      this.commandResult = JSON.stringify(res, null, 2);
    });
    this.commandResult = '';
  }

  sendCommand(): void {
    const command = RmmCommand.parse(this.commandParams);
    if (this.isAsync) {
      this.rmmCommandsService.sendCommandAsync(this.selectedCommandType, command, this.hid).subscribe((res) => {
        this.commandResult = JSON.stringify(res, null, 2);
      });
    } else {
      this.rmmCommandsService.sendCommand(this.selectedCommandType, command, this.hid);
    }
  }
}
