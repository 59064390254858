import * as Prism from 'prismjs';

import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-powershell';
import 'prismjs/plugins/line-numbers/prism-line-numbers';

import { Injectable } from '@angular/core';

@Injectable()
export class TerminalNewPrismService {
  constructor() { }

  highlightAll() {
    Prism.highlightAll();
  }

  convertHtmlIntoString(text: string) {
    return text
      .replace(new RegExp('&', 'g'), '&amp;')
      .replace(new RegExp('<', 'g'), '&lt;');
  }
}
