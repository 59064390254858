<mbs-modal [title]="'computers.module:authRequiredFor' | i18next: { param: computerName }">
  <ng-container modal-body>
    <mbs-alert [type]="alertType.warning"
               [icon]="true"
               [class.mb-0]="!needUpgradeBackupAgent">
      <ng-container *ngIf="needUpgradeBackupAgent; else reAuthArea">
        {{ 'computers.module:modals:reAuthInfoUpgradeMessage' | i18next: { param: computerName } }}
      </ng-container>
      <ng-template #reAuthArea>
        <ng-container *ngIf="reAuthRequired; else simpleAuthNotificationArea">
          {{ 'computers.module:modals:reAuthInfoRequired' | i18next }}
        </ng-container>
        <ng-template #simpleAuthNotificationArea>
          {{ 'computers.module:modals:reAuthInfoSimple' | i18next }}
        </ng-template>
      </ng-template>
      <a *ngIf="('HelpMarketing' | can: 'read')"
         href="https://kb.msp360.com/kb10140"
         target="_blank">
        {{ 'buttons:learnMore' | i18next }}
      </a>
    </mbs-alert>
    <ng-container *ngIf="needUpgradeBackupAgent">
      {{ 'computers.module:modals:reAuthInfoUpgradeBody' | i18next }}
    </ng-container>
  </ng-container>
  <ng-container modalFooter>
    <ng-container *ngIf="!needUpgradeBackupAgent">
      <mbs-button type="primary"
                  (click)="baseModal.save()">
        {{'buttons:continue' | i18next}}
      </mbs-button>
    </ng-container>
    <mbs-button (click)="baseModal.close()">
      {{needUpgradeBackupAgent ? ('buttons:close' | i18next) : ('buttons:cancel' | i18next)}}
    </mbs-button>
  </ng-container>
</mbs-modal>
