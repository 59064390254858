/* eslint-disable @typescript-eslint/unbound-method */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { SignalRService } from '@modules/signal-r/signal-r.service';

import { AuthService } from '@services/auth.service';

import { ModalComponent } from 'mbs-ui-kit';

import { WSTopicName } from '@modules/signal-r/signal-r.constants';
import { TFAService } from './../../services/tfa.service';

@Component({
  selector: 'mbs-two-factor-enable-modal',
  templateUrl: './two-factor-enable-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoFactorEnableModalComponent implements OnInit, OnDestroy {
  public isGoogleAuth = false;
  public isValidate = false;
  public isSavedRecoveryCodes = false;
  public codes: Array<string>;
  public showThirdPartyAuthButton = true;
  public authId = null;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    public authService: AuthService,
    private signalR: SignalRService,
    private cdr: ChangeDetectorRef,
    private TFAService: TFAService
  ) {}

  ngOnInit(): void {
    this.signalR.authorizedZoneConnection.on(WSTopicName.OnQrUsingWithinTwoFactorAuthOperation, (result) => {
      this.authId = JSON.parse(result).AuthId;
    });

    this.signalR.authorizedZoneConnection.on(WSTopicName.OnTwoFactorAuthProcessCompleted, (result) => {
      if (result.resolution === 1) {
        if (this.baseModal.data?.isForceMSP && this.authId !== result.authId) {
          return;
        }
        this.TFAService.generateCodes(result.authId).subscribe((result) => {
          this.TFAService.updateTFAState().subscribe();
          this.isValidate = true;
          this.codes = result;
          this.cdr.detectChanges();
        });
      }
      if (result.resolution === 2) {
        this.baseModal.close();
      }
    });
  }

  ngOnDestroy() {
    this.signalR.authorizedZoneConnection.off('OnTwoFactorAuthProcessCompleted');
  }

  public toggleAuthType() {
    this.isGoogleAuth = !this.isGoogleAuth;
  }
}
