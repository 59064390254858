import { Observable, UnaryFunction, pipe } from 'rxjs';
import { isNaN, isNull, isUndefined } from 'lodash/fp';
import { map, pluck, tap } from 'rxjs/operators';

import { EntityOp } from '@ngrx/data';
import { ofType } from '@ngrx/effects';

/**
 * analog ofType operator for @ngrx effects, filter action stream by feature and @ngrx/data Entity Oparetion enum
 * @param {string} feature
 * @param {EntityOp} type
 * @return {UnaryFunction<Observable<any>, Observable<T>>}
 */
export const ofEntity = (feature: string, type: EntityOp) => {
  return pipe(ofType(`[${feature}] ${type}`));
};
/**
 * analog pluck operator for select only entity data from @ngrx/data action
 * @template T
 * @param {...string[]} selectors
 * @return {UnaryFunction<Observable<any>, Observable<T>>}
 */
export const pluckCollection = <T>(...selectors: string[]): UnaryFunction<Observable<any>, Observable<T>> => {
  return pipe(pluck<any>('payload', 'data'), selectors.length ? pluck<T>(...selectors) : tap(() => null)) as UnaryFunction<
    Observable<any>,
    Observable<T>
  >;
};
/**
 * serialize any data to store entity required prop a ID
 * @template T
 * @param {string} prop
 * @return {UnaryFunction<Observable<any>, Observable<T>>}
 */
export const mapAsId = <T>(prop: string): UnaryFunction<Observable<any>, Observable<T>> => {
  return pipe(
    map((data: any) => {
      switch (true) {
        case Array.isArray(data): {
          data.forEach(i => (i.id = i[prop]));
          break;
        }
        case typeof data == 'string' || typeof data == 'number': {
          data = { id: data };
          break;
        }
        case isNaN(data) || isUndefined(data) || isNull(data): {
          return data;
        }
        default: {
          data.id = data[prop];
        }
      }

      return data as T;
    })
  );
};
