export class SignInError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SignInError';
  }
}

export class NotUniqueDomainError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NotUniqueDomainError';
  }
}
