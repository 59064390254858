<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:advanced_options_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <div class="mbs-wizard_form-content">
    <mbs-alert *ngIf="isRDMode && importedPlanChanged"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:where_backup_config_not_supported' | i18next}}
    </mbs-alert>

    <ng-container *ngIf="advancedOptionsFlags?.UseS3AccelerationPossible">
      <mbs-form-group>
        <mbs-checkbox formControlName="UseS3Acceleration"
                      [label]="'wizards:use_s3_label' | i18next"
                      [id]="'use-S3-acceleration-checkbox'">
          <ng-container appendLabel>
            <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                  [placement]="['right', 'bottom']"
                  [ngbTooltip]="'wizards:use_s3_label_tooltip' | i18next"></span>
          </ng-container>
        </mbs-checkbox>
      </mbs-form-group>

      <mbs-form-group *ngIf="advancedOptionsFlags?.StorageClassSettingPossible">
        <div class="row">
          <div class="col-4">
            <mbs-select formControlName="StorageClass"
                        (change)="storageClassChangeHandler($event)"
                        [appendTo]="'.modal-content'"
                        [bindValue]="'value'"
                        [bindLabel]="'label'"
                        [clearable]="false"
                        [hideSelected]="true"
                        [items]="StorageClasses"
                        [id]="'advancedOptionsStorageClass'"
                        [label]="storageClassLabel"
                        [searchable]="false"></mbs-select>
          </div>
        </div>
      </mbs-form-group>
    </ng-container>

    <mbs-form-group *ngIf="advancedOptionsFlags?.AzureAccessTierType">
      <div class="row">
        <div class="col-3">
          <mbs-select formControlName="azureAccessTier"
                      (change)="azureAccessTierChangeHandler($event)"
                      [appendTo]="'.modal-content'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [clearable]="false"
                      [hideSelected]="true"
                      [items]="AccessTires"
                      [id]="'advancedOptionsAzureAccessTier'"
                      [label]="azureAccessTierLabel"
                      [searchable]="false"></mbs-select>
        </div>
      </div>
    </mbs-form-group>

    <mbs-form-group [formGroup]="foldersForm">
      <mbs-files-folders-list #fileFoldersComponent
                              formControlName="filesFoldersData"
                              [showItem]="!isRDMode && advancedOptionsFlags?.ExcludePossible"
                              [isLinux]="isLinux"
                              [labelForEnabledFields]="'wizards:exclude_text' | i18next"></mbs-files-folders-list>
    </mbs-form-group>

    <mbs-form-group *ngIf="isShowSyntheticFull">
      <mbs-checkbox formControlName="SyntheticFull"
                    [label]="'wizards:synthetic_full_label' | i18next"
                    [subtitle]="'wizards:synthetic_full_subtitle' | i18next"
                    [id]="'synthetic-full-checkbox'"></mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="advancedOptionsFlags?.IgnoreBadSectorsPossible">
      <mbs-checkbox formControlName="IgnoreBadSectors"
                    [label]="'wizards:bad_sectors_label' | i18next"
                    [id]="'ignore-bad-sectors-checkbox'"></mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="advancedOptionsFlags?.DisableVSSPossible">
      <mbs-checkbox formControlName="DisableVSS"
                    [label]="'wizards:disable_vss_label' | i18next"
                    [id]="'disable-VSS-checkbox'"></mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="advancedOptionsFlags?.BackupNTFSPermissionsPossible">
      <mbs-checkbox formControlName="BackupNTFSPermissions"
                    [label]="'wizards:backup_ntfs_label' | i18next"
                    [id]="'backup-ntfs-permissions-checkbox'"></mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="!isLinux && !isNBF && isBackupPlan">
      <mbs-checkbox formControlName="SaveDeletedDataInCloud"
                    [label]="'wizards:mark_deleted_label' | i18next"
                    [id]="'save-deleted-data-in-cloud-checkbox'">
        <ng-container appendLabel>
          <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                [placement]="['bottom-left', 'left', 'auto']"
                [ngbTooltip]="'wizards:mark_deleted_label_tooltip' | i18next"></span>
        </ng-container>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="advancedOptionsFlags?.UseFastNtfsScanPossible">
      <mbs-checkbox formControlName="UseFastNtfsScan"
                    [label]="'wizards:use_ntfs_label' | i18next"
                    (change)="useFastNtfsScanChangeHandler($event)"
                    [id]="'use-fast-ntfs-scan-checkbox'">
        <ng-container appendLabel>
          <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                [ngbTooltip]="'wizards:use_ntfs_label_tooltip' | i18next"></span>
        </ng-container>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group>
      <ng-container *ngIf="isBackupPlan; else vssBlockForIBB">
        <ng-container *ngIf="advancedOptionsFlags?.AlwaysUseVSSPossible">
          <mbs-checkbox formControlName="AlwaysUseVSS"
                        [label]="'wizards:use_vss_label' | i18next"
                        (change)="alwaysUseVSSChangeHandler($event)"
                        [descriptionOutside]="false"
                        [id]="'always-use-VSS-provider-checkbox'">
            <ng-container appendLabel>
              <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                    [ngbTooltip]="'wizards:use_vss_label_tooltip' | i18next"></span>
            </ng-container>
            <ng-template checkboxDescription
                         [hide]="!advancedOptionsFlags?.UseVSSPossible">
              <mbs-checkbox formControlName="useSystemVSSProvider"
                            [label]="'wizards:use_system_vss_label' | i18next"
                            [id]="'use-system-VSS-provider-checkbox'">
                <ng-container appendLabel>
                  <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                        [ngbTooltip]="'wizards:use_system_vss_label_tooltip' | i18next"></span>
                </ng-container>
              </mbs-checkbox>
            </ng-template>

          </mbs-checkbox>
        </ng-container>
      </ng-container>

      <ng-template #vssBlockForIBB>
        <ng-container *ngIf="advancedOptionsFlags?.UseVSSPossible">
          <mbs-checkbox formControlName="useSystemVSSProvider"
                        [label]="'wizards:use_system_vss_label' | i18next"
                        [id]="'use-system-VSS-provider-checkbox'">
            <ng-container appendLabel>
              <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                    [ngbTooltip]="'wizards:use_system_vss_label_tooltip' | i18next"></span>
            </ng-container>
          </mbs-checkbox>
        </ng-container>
      </ng-template>
    </mbs-form-group>

    <mbs-form-group
      *ngIf="isShowEFS"
      [title]="'wizards:efs_encryption_label' | i18next: { format: 'title' }">
      <mbs-radio formControlName="KeepEFSFilesAsIs"
                 [label]="'wizards:decrypt_efs_encrypted_files_label' | i18next"
                 (change)="keepEFSFilesAsIsChangeHandler(false)"
                 [value]="false"
                 [id]="'decryptEFS'">
        <ng-container appendLabel>
              <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                    [ngbTooltip]="'wizards:decrypt_efs_encrypted_files_tooltip' | i18next"
                    [container]="'body'"></span>
        </ng-container>
      </mbs-radio>
      <mbs-radio formControlName="KeepEFSFilesAsIs"
                 [label]="'wizards:keep_efs_encryption_label' | i18next"
                 (change)="keepEFSFilesAsIsChangeHandler(true)"
                 [value]="true"
                 [id]="'KeepAsis'">
        <ng-container appendLabel>
              <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
                    [ngbTooltip]="EFSPayAttentionTooltip"
                    tooltipClass="tooltip-lg"
                    [container]="'body'"></span>
        </ng-container>
      </mbs-radio>
    </mbs-form-group>

    <ng-container *ngIf="!isBackupPlan && (!isNBF || correctlyVersionForShowBlockCount) && (advancedOptionsFlags?.PrefetchBlockCountPossible || advancedOptionsFlags?.BlockSizeSettingPossible)">
      <div class="row">
        <div class="col-6">
          <mbs-form-group>
            <mbs-number formControlName="PrefetchBlockCount"
                        [label]="'wizards:block_count_label' | i18next"
                        [containerClasses]="'w-50'"
                        min="0"
                        max="100"
                        [id]="'prefetch-block-count-input'"></mbs-number>
          </mbs-form-group>
        </div>
        <div *ngIf="!isNBF && advancedOptionsFlags?.BlockSizeSettingPossible"
             class="col-6">
          <mbs-form-group>
            <label class="mbs-form_label"
                   [for]="'block-size-select'">{{'wizards:block_size_label' | i18next}}</label>
            <div class="row">
              <div class="col-6">
                <mbs-select formControlName="BlockSize"
                            [appendTo]="'.modal-content'"
                            [bindValue]="'value'"
                            [bindLabel]="'label'"
                            [items]="blockSizesArray"
                            [id]="'block-size-select'"
                            [clearable]="false"
                            [searchable]="false"></mbs-select>
              </div>
            </div>
          </mbs-form-group>
        </div>
      </div>
    </ng-container>

    <mbs-alert *ngIf="canShowEFSRequiredInfo"
               [type]="mbsPopupType.info"
               [icon]="true"
               class="mb-0">
      {{ 'wizards:keep_efs_encryption_require_info' | i18next }}
    </mbs-alert>

  </div>
</section>

<ng-template #storageClassLabel>
  {{'wizards:storage_class_label' | i18next}}
  <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
        tooltipClass="tooltip-xl"
        [placement]="['left', 'bottom-left', 'bottom']"
        [container]="'body'"
        [ngbTooltip]="classSettingPossibleTooltip"></span>
</ng-template>

<ng-template #azureAccessTierLabel>
  {{'wizards:access_tier_label' | i18next}}
  <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
        [placement]="['bottom-left', 'bottom']"
        [ngbTooltip]="azureAccessTierTooltip"></span>
</ng-template>

<ng-template #classSettingPossibleTooltip>
  <dl class="text-left">
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_standard_label_tooltip' | i18next}}</span> {{'wizards:s3_standard_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_standard_ia_label_tooltip' | i18next}}</span> {{'wizards:s3_standard_ia_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_it_label_tooltip' | i18next}}</span> {{'wizards:s3_it_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_glacier_flex_label_tooltip' | i18next}}</span> {{'wizards:s3_glacier_flex_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_glacier_instant_label_tooltip' | i18next}}</span> {{'wizards:s3_glacier_instant_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_glacier_deep_label_tooltip' | i18next}}</span> {{'wizards:s3_glacier_deep_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:s3_one_zone_label_tooltip' | i18next}}</span> {{'wizards:s3_one_zone_label_tooltip_desc' | i18next}}
    </dd>
  </dl>
</ng-template>

<ng-template #azureAccessTierTooltip>
  <dl class="text-left">
    <dd>
      <span class="font-weight-semibold">{{'wizards:access_tier_hot_label_tooltip' | i18next}}</span> {{'wizards:access_tier_hot_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:access_tier_cool_label_tooltip' | i18next}}</span> {{'wizards:access_tier_cool_label_tooltip_desc' | i18next}}
    </dd>
    <dd>
      <span class="font-weight-semibold">{{'wizards:access_tier_archive_label_tooltip' | i18next}}</span> {{'wizards:access_tier_archive_label_tooltip_desc' | i18next}}
    </dd>
  </dl>
</ng-template>

<ng-template #errorSyntheticFull>
  <p>{{'wizards:error_synthetic_text' | i18next}}</p>
  <mbs-alert [type]="mbsPopupType.warning"
             [icon]="true"
             class="mb-0">
    {{'wizards:error_synthetic_alert_text' | i18next}}
  </mbs-alert>
</ng-template>

<ng-template #EFSPayAttentionTooltip>
  <dl class="text-left">
    <dd>
      <span>{{'wizards:keep_efs_encryption_tooltip' | i18next}}</span>
    </dd>
    <dd>
      <span>{{'wizards:keep_efs_encryption_warning_text_1' | i18next}}</span>
    </dd>
    <dd>
      <span>{{'wizards:keep_efs_encryption_warning_text_2' | i18next}}</span>
    </dd>
    <dd>
      <ul>
        <li>{{'wizards:keep_efs_encryption_warning_action_text_1' | i18next}}</li>
        <li>{{'wizards:keep_efs_encryption_warning_action_text_2' | i18next}}</li>
        <li>{{'wizards:keep_efs_encryption_warning_action_text_3' | i18next}}</li>
      </ul>
    </dd>
    <dd>
      <span>{{'wizards:keep_efs_encryption_warning_text_3' | i18next}}</span>
    </dd>
  </dl>
</ng-template>

<ng-template #EFSPayAttention>
  <mbs-alert [type]="mbsPopupType.warning"
             [icon]="true">
    {{'wizards:keep_efs_encryption_warning_text_1' | i18next}}
  </mbs-alert>
  <strong>{{'wizards:keep_efs_encryption_warning_text_2' | i18next}}</strong>
  <ul>
    <li>{{'wizards:keep_efs_encryption_warning_action_text_1' | i18next}}</li>
    <li>{{'wizards:keep_efs_encryption_warning_action_text_2' | i18next}}</li>
    <li>{{'wizards:keep_efs_encryption_warning_action_text_3' | i18next}}</li>
  </ul>
  <p class="mb-0">{{'wizards:keep_efs_encryption_warning_text_3' | i18next}}</p>
</ng-template>
