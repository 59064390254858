import { Directive, Input } from '@angular/core';
import { FilterSearch } from '@utils/filterSearch';
import { hasActionsQueue } from '@utils/has-actions-queue';
import { InMemorySorter } from '@utils/inMemorySorter';
import { debounce } from 'lodash';
import { PaginationOptions, SortEvent, TableHeader } from 'mbs-ui-kit';
import { Observable, Subject } from 'rxjs';
import { exportToCsv } from '@utils/cvs';
import { getReversedTableSorting } from '@shared/utils/helpers/rmm/table-reversed-sorting';

@Directive()
export abstract class RmmTabBaseComponent<TResult> {
  public onLoad$: Subject<boolean> = new Subject();
  public onGetCachedData$: Subject<any> = new Subject();
  public onFetchLastData$: Subject<any> = new Subject();
  public onFetchLiveData$: Subject<any> = new Subject();
  public loading$: Observable<boolean>;

  @Input() hid: string;
  @Input() headers: TableHeader[] = [];

  @Input() isModal = false;
  @Input() public isOnline: boolean;

  public model = '';
  public loading = true;
  public loaded = false;
  public selected: TResult[] = [];

  public tempSelected: TResult[] = [];
  public isTableSorting = false;

  protected selectPage = 1;
  protected searchQuery: string[];
  protected updateDataDebounce = debounce((data) => this.updateData(data), 300);

  public data: TResult[] = [];
  protected allData: TResult[] = [];
  public orderBy: SortEvent = { column: 'name', direction: 'desc' };

  public paginationOptions: PaginationOptions = {
    maxSize: 3,
    rotate: true,
    pageSize: 10,
    dataSize: 0,
    page: 1
  };

  protected searchFields: string[];

  public rotateSequence = {
    asc: 'desc',
    desc: 'asc',
    '': 'asc'
  };

  constructor() {
    this.loading$ = this.onLoad$.pipe(hasActionsQueue());
  }

  abstract fetchData(params?: any): void;

  updateFilters(event): void {
    if (event) {
      this.searchQuery = null;
      if (event.words && event.words.filter((w) => !!w)) {
        this.searchQuery = event.words;
      }
    }
    this.updateDataDebounce(this.allData);
  }

  getFilteredData(fields: string[], data: TResult[]): TResult[] {
    return this.searchQuery ? FilterSearch.filter(this.searchQuery, fields, data) : data;
  }

  handleSubTab(tabItem, loading = false): void {
    !this.isModal && queueMicrotask(() => (tabItem.loading = loading));
  }

  handleInputSearch(event): void {
    const word = event.target.innerText;
    this.updateFilters(word ? { words: [word] } : {});
  }

  handlePageChange(page: number): void {
    this.selectPage = page;
    this.updateData(this.allData);
  }

  handlePageSizeChange(options: PaginationOptions): void {
    this.paginationOptions = options;
    this.updateData(this.allData);
  }

  handleSort({ column, direction }: SortEvent): void {
    this.isTableSorting = true;
    this.tempSelected = [...this.selected];
    this.orderBy = { column, direction };
    this.data = this.handleSortInternal(getReversedTableSorting(this.orderBy), this.data);
  }

  private handleSortInternal(orderBy: SortEvent, data: TResult[]): TResult[] {
    const header = this.headers.find((h) => h.sort === orderBy.column);

    if (header && header['sortType'] === 'date') {
      return InMemorySorter.sortByDate(orderBy, data);
    }

    return InMemorySorter.sort(orderBy, data);
  }

  getPageChunk(data: TResult[]): TResult[] {
    this.paginationOptions = Object.assign({}, this.paginationOptions, {
      dataSize: data.length,
      pageSize: Number.MAX_SAFE_INTEGER
    });
    return data;
  }

  updateData(data: TResult[]): void {
    const filteredData = this.getFilteredData(this.searchFields, data);
    const sortedData = this.handleSortInternal(getReversedTableSorting(this.orderBy), filteredData);

    const chunkData = this.getPageChunk(sortedData);
    this.data = Array.from(chunkData);
    this.loaded = true;
  }

  handleChangeSelected(items: TResult[]) {
    if (this.isTableSorting) {
      this.isTableSorting = false;
      this.selected = this.tempSelected;
    } else {
      this.selected = items;
    }
  }

  export(name = 'Table Data') {
    exportToCsv(
      name,
      this.headers.map((header) => header.name),
      this.headers.map((header) => header.sort),
      this.data
    );
  }
}
