import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InMemorySorter } from '@utils/inMemorySorter';
import { isArray } from 'lodash/fp';
import { FeatureKey } from './const';
import { ScriptLibraryEntry, ScriptLibraryEntryExtends, ScriptLibraryTag, State } from './script-library.model';
import * as fromReducer from './script-library.reducer';

function normalizeSpaces(str) {
  return str.replace(/\s/g, ' ').toLocaleLowerCase();
}

const selectFeature = createFeatureSelector<State>(FeatureKey);

export const selectIds = createSelector(selectFeature, fromReducer.selectIds);
export const selectEntities = createSelector(selectFeature, fromReducer.selectEntities);
export const selectAll = createSelector(selectFeature, fromReducer.selectAll);
export const selectTotal = createSelector(selectFeature, fromReducer.selectTotal);

export const selectTags = createSelector(selectFeature, (state) => state.tags || []);
export const selectFilteredTags = (predicate: (tag: ScriptLibraryTag) => boolean) =>
  createSelector(selectTags, (tags) => tags.filter(predicate));
export const selectExistsTags = createSelector(selectAll, (all): ScriptLibraryTag[] => {
  const buffer = all.reduce((acc, entry) => {
    entry.tags.forEach((tag) => (acc[tag.tagGuid] = tag));

    return acc;
  }, {} as { [key: string]: ScriptLibraryTag });

  return Object.values(buffer);
});
export const loading = createSelector(selectFeature, (state) => state.loading);
export const edit = createSelector(selectFeature, (state) => state.edit);
export const selectSort = createSelector(selectFeature, (state) => state.sort);
export const selectFilters = createSelector(selectFeature, (state) => state.filters);
export const selectList = createSelector(selectAll, selectSort, selectFilters, (all, sort, filters) => {
  const words = filters?.words || [];
  const filterKeys = Object.keys(filters || {})
    .filter((key) => key !== 'words')
    .filter((key) => Boolean(filters[key]));

  const filtered = all.filter((item) => {
    const word = words.length ? Boolean(words.filter((i) => item.name.toLocaleLowerCase().includes(i.toLocaleLowerCase())).length) : true;

    const reduced = filterKeys.reduce((acc, filterKey) => {
      const filter: string[] = filters[filterKey].map(normalizeSpaces);
      const prop = isArray(item[filterKey])
        ? item[filterKey].map((i) => i.name.toLocaleLowerCase()).map(normalizeSpaces)
        : normalizeSpaces(item[filterKey]);

      return acc && Boolean(filter.filter((i) => prop.includes(i.toLocaleLowerCase())).length);
    }, true);

    return reduced && word;
  });

  return InMemorySorter.sort<ScriptLibraryEntry>(
    sort,
    filtered.map((item) => Object.assign({}, item, { [ScriptLibraryEntryExtends.CATEGORIES]: item.tags.map((tag) => tag.name).join(', ') }))
  );
});

export const selectByTag = (collection: ScriptLibraryTag, showAll = false) =>
  createSelector(selectAll, (all) => {
    if (!collection && showAll) {
      return all;
    }

    return all.filter((entry) => entry.tags.find((tag) => tag.tagGuid == collection.tagGuid));
  });

export const selectEntry = (id: string) => createSelector(selectEntities, (entities) => entities[id]);
export const selectEntryBody = (id: string) => createSelector(selectEntry(id), (entry) => entry?.body);
