import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ComputerAuthorizationModalComponent } from '@components/computers-modal/computer-authorization/computer-authorization-modal.component';
import { ComputersSendLogsModalComponent } from '@components/computers-modal/computers-send-logs-modal/computers-send-logs-modal.component';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { ComputerModalsFacade } from '@facades/computer.modals.facade';
import { AvailablePlanStartModes, PlanStartModesDisplay, PlanStartModesDisplayWithRun } from '@models/backup/available-plan-start-modes';
import { AvailablePlanType, AvailablePlanTypes, KindPlanEnum } from '@models/backup/available-plan-types';
import { BackupPlanInfo } from '@models/backup/backup-plan-info-model';
import { DetailedError } from '@models/backup/detailed-error';
import { PlanInfo, ShortPlanInfo } from '@models/backup/plan-info-model';
import { PlanRunSimpleStatus } from '@models/backup/plan-run-simple-status';
import { ProgressLineData } from '@models/backup/progress-line-data';
import { ProgressLineStatus } from '@models/backup/progress-line-status';
import { Solution } from '@models/backup/solution-model';
import Computer, { AgentType, AgentVersionStatus, minimalWindowsVersionForOfflineEdit, OsType } from '@models/Computer';
import { LicenseType } from '@models/LicenseType.enum';
import {
  ImagesOrPlanTypes,
  LastInOrderPlanTypes,
  PlanFormatAdapter,
  PlanFormatTypes,
  PlanTypes,
  UnableToCreateCBFPlanTypes,
  VMPlanTypes
} from '@models/PlanTypes.enum';
import { StorageAccountCamelCase } from '@models/StorageAccount';
import { CloudStorageProvider, StorageType } from '@models/StorageType.enum';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isRestorePlan } from '@utils/computer-utils';
import { SupportProblemErrorCodes } from '@utils/errorCodes';
import { getDestinationIconType } from '@utils/storage-type.utils';
import { PlanModalWrapper } from '@wrappers/plan.wrapper';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep, isNil } from 'lodash';
import { isNumber } from 'lodash/fp';
import { MbsPopupType, TabsetItemDirective } from 'mbs-ui-kit';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, tap } from 'rxjs/operators';

export enum PlanType {
  Backup = 'backup',
  Restore = 'restore'
}

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-plans-tab',
  templateUrl: './plans-tab.component.html',
  styleUrls: ['./plans-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlansTabComponent implements OnInit {
  @Input() fullscreen: boolean;
  @Input() mode: PlanType = PlanType.Backup;
  @Input() readonly: boolean;
  @Output() switchToStorage = new EventEmitter<ShortPlanInfo>();

  public readonly getDestinationIconType = getDestinationIconType;
  public readonly alertType = MbsPopupType;
  public readonly planType = PlanType;
  public readonly licenseType = LicenseType;
  public readonly planFormatTypes = PlanFormatTypes;
  public readonly isNil = isNil;
  public readonly kbidBase = 'https://kb.msp360.com/';
  public readonly elementsSelector = {
    name: {
      mainBlock: 'plans-tab-main-block',
      plansBlock: 'plans-tab-plans-block',
      noPlansHasUserAccountBlock: 'plans-tab-no-plans-has-account-block',
      noUserAccountAlert: 'plans-tab-no-account-alert',
      plansAndUserAccountBlock: 'plans-tab-plans-and-account-block',
      plansListBlock: 'plans-tab-plans-list-block',
      planBlock: 'plans-tab-plan-block',
      encryptionSummary: 'plans-tab-plan-encryption-summary',
      modeBackup: 'plans-tab-plan-mode-backup',
      modeNotBackup: 'plans-tab-plan-mode-not-backup',
      modeBackupStorage: 'plans-tab-plan-mode-backup-storage',
      modeNotBackupStorage: 'plans-tab-plan-mode-not-backup-storage',
      modeBackupNBFTag: 'plans-tab-plan-mode-backup-nbf-tag',
      compOnlineBlock: 'plans-tab-plan-comp-online-block',
      lastInfoRunningBtn: 'plans-tab-plan-last-info-running-btn',
      lastInfoNotRunningBtn: 'plans-tab-plan-last-info-not-running-btn',
      availableStartModesBtn: 'plans-tab-plan-available-start-modes-btn',
      startModesBtn: 'plans-tab-plan-available-start-modes-btn',
      lastResultBlock: 'plans-tab-plan-last-result-block',
      reportProblem: 'plans-tab-plan-report-problem',
      progressLineBtn: 'plans-tab-plan-progress-line-btn',
      lastRunInfoContentBlock: 'plans-tab-plan-last-run-info-content-block',
      lastInfoProgressItem: 'plans-tab-plan-last-info-progress-item',
      lastInfoProgressItemIco: 'plans-tab-plan-last-info-progress-item-ico',
      lastInfoProgressItemTotal: 'plans-tab-plan-last-info-progress-item-total',
      lastInfoProgressItemOperationName: 'plans-tab-plan-last-info-progress-item-operation-name',
      lastInfoProgressItemOperationNameBtn: 'plans-tab-plan-last-info-progress-item-operation-name-btn',
      lastInfoProgressRunning: 'plans-tab-plan-last-info-progress-item-operation-name-btn',
      lastInfoTotalDescription: 'plans-tab-plan-last-info-total-description',
      errorDetailTopBox: 'plans-tab-plan-error-detail-topBox',
      errorDetailPriority: 'plans-tab-plan-error-detail-priority',
      errorDetailOpenBtn: 'plans-tab-plan-error-detail-open-btn',
      errorDetailCodeLinkBlock: 'plans-tab-plan-error-detail-code-link-block',
      errorDetailCodeLink: 'plans-tab-plan-error-detail-code-link',
      errorDetailContentBlock: 'plans-tab-plan-error-detail-content-block',
      errorDetailSolutionsBlock: 'plans-tab-plan-error-detail-solutions-block',
      errorDetailSolutionsLink: 'plans-tab-plan-error-detail-solutions-link',
      modeBackupFormatBlock: 'plans-tab-plan-mode-backup-format-block',
      planSourceBlock: 'plans-tab-plan-source-block',
      planScheduleSummaryBlock: 'plans-tab-plan-schedule-summary-block',
      planCompressionSummaryBlock: 'plans-tab-plan-compression-summary-block',
      planEncryptionSummaryBlock: 'plans-tab-plan-encryption-summary-block',
      planSyntheticFullEnabledBlock: 'plans-tab-plan-synthetic-full-enabled-block',
      planConsistencyBlock: 'plans-tab-plan-consistency-block',
      planImmutabilityBlock: 'plans-tab-plan-immutability-block',
      planRestoreBlock: 'plans-tab-plan-restore-block',
      planRetentionBlock: 'plans-tab-plan-retention-block',
      planNextRunBlock: 'plans-tab-plan-next-run-block',
      planDateTimeUTCBlock: 'plans-tab-plan-date-time-utc-block'
    }
  };
  public readonly newRMSupportsMinimalWindowsVersion = 7223;
  public readonly newRMSupportsMinimalUnixVersion = 4100;

  public computer$: Observable<Computer>;
  public isBackupAgentSupportsNewRM: boolean;

  public selectedPlan: PlanInfo = null;
  public cloneName = '';

  /**
   * Store model
   */
  public visiblePlans$: Observable<PlanInfo[]>;

  public detailsOpenState = new Map<string, boolean>();
  /**
   * plan.id + operationName
   */
  public progressLineOpenState = new Map<string, boolean>();
  public errorDetailOpenState = new Map<string, boolean>();

  public isNumber = isNumber;
  public planTypes = PlanTypes;
  public planRunSimpleStatus = PlanRunSimpleStatus;
  public planStartModesDisplay = PlanStartModesDisplay;
  public planStartModesDisplayWithRun = PlanStartModesDisplayWithRun;
  public osTypes = OsType;
  public Date = Date;
  public ProgressLineStatus = ProgressLineStatus;

  public osType$: Observable<OsType>;

  public noPlansMessage: string;
  public destinationTypes: { [key: string]: StorageType | CloudStorageProvider };

  public availablePlanTypes: AvailablePlanTypes = [];

  private autoFetchSubscription: Subscription;

  private filterAvailablePlanTypes(types: AvailablePlanTypes): AvailablePlanTypes {
    const newTypes = types
      .filter(
        (type: AvailablePlanType) => type.needShow || type.format === PlanFormatTypes.NBF || !UnableToCreateCBFPlanTypes[type.planType]
      )
      .sort((a, b) => {
        if (ImagesOrPlanTypes[b.planType] && ImagesOrPlanTypes[a.planType] && a.planType === b.planType) {
          return b.format === PlanFormatTypes.NBF ? 1 : -1;
        }
        return ImagesOrPlanTypes[b.planType] ? 1 : VMPlanTypes[b.planType] && LastInOrderPlanTypes[a.planType] ? 1 : -1;
      });
    return this.mode === PlanType.Backup
      ? newTypes.filter((t: AvailablePlanType) => t.kind === KindPlanEnum.Backup || t.kind === KindPlanEnum.ConsistencyCheck)
      : newTypes.filter((t: AvailablePlanType) => t.kind === KindPlanEnum.Restore && t.planType !== PlanTypes.RestoreExchangePlan);
  }

  constructor(
    public ability: AbilityService,
    public i18nPipe: I18NextPipe,
    public facade: ComputerBackupFacade,
    private cdr: ChangeDetectorRef,
    private tabItem: TabsetItemDirective,
    private router: Router,
    private planWrapper: PlanModalWrapper,
    private modals: ComputerModalsFacade
  ) {}

  ngOnInit(): void {
    this.noPlansMessage = `No ${new TitleCasePipe().transform(this.mode)} Plans`;
    this.initStreams();
  }

  get isRestore(): boolean {
    return this.mode === PlanType.Restore;
  }

  initStreams(): void {
    this.computer$ = this.facade.computer$;
    const currentComputer$ = this.computer$.pipe(filter(Boolean));

    this.osType$ = currentComputer$.pipe(
      distinctUntilKeyChanged('hid'),
      map((computer) => computer?.os)
    );

    this.visiblePlans$ = this.facade.plans$.pipe(
      map((plans) =>
        (cloneDeep(plans) || []).filter((p) => (this.mode == PlanType.Backup ? !isRestorePlan(p.type) : isRestorePlan(p.type)))
      )
    );

    this.facade.destinations$.pipe(untilDestroyed(this)).subscribe((storageAccounts) => {
      this.destinationTypes = storageAccounts.reduce((acc, account: StorageAccountCamelCase) => {
        account.buckets.forEach((bucket) => (acc[bucket.id] = account.storageType));

        return acc;
      }, {});

      if (Object.keys(this.destinationTypes).length) {
        this.cdr.detectChanges();
      }
    });

    combineLatest([this.facade.plansLoading$, this.facade.availablePlansLoading$])
      .pipe(
        tap((value) => queueMicrotask(() => (this.tabItem.loading = value[0] || value[1]))),
        untilDestroyed(this)
      )
      .subscribe();

    // autofetch plans if at least one plan started
    this.facade.plans$
      .pipe(
        map((plans) => ({
          running: plans.some((plan) => plan?.lastRunInfo?.status === PlanRunSimpleStatus.Running),
          fetching: !!this.autoFetchSubscription && !this.autoFetchSubscription.closed
        })),
        filter((result) => result.running !== result.fetching)
      )
      .subscribe((result) => {
        this.autoFetchSubscription?.unsubscribe();
        if (result.running) this.autoFetchSubscription = this.facade.plansAutoFetch$.pipe(untilDestroyed(this)).subscribe();
      });

    currentComputer$
      .pipe(
        tap((computer) => {
          this.isBackupAgentSupportsNewRM = this.getIsBackupAgentSupportsNewRM(computer);
          this.facade.loadDestinations();
          this.handleReloadPlans({});
          this.facade.getAvailablePlanTypes();
          this.cdr.detectChanges();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.facade.availablePlans$
      .pipe(
        map((types: AvailablePlanTypes) => this.filterAvailablePlanTypes(types)),
        untilDestroyed(this)
      )
      .subscribe((types: AvailablePlanTypes) => (this.availablePlanTypes = types));
  }

  handleReloadPlans({ force = false, quiet = false }): void {
    this.facade.loadPlans({ force, quiet });
  }

  handleReloadAvailablePlans(options = { force: false }): void {
    this.facade.getAvailablePlanTypes(options);
  }

  handleRefresh(): void {
    this.handleReloadPlans({ force: true });
    this.handleReloadAvailablePlans({ force: true });
  }

  myTrackBy(index: number, item: BackupPlanInfo): string {
    return item.planId;
  }

  trackByProgressLine(index: number, item: ProgressLineData): string {
    return item.operationName;
  }

  trackByDetailedError(index: number, item: DetailedError): string {
    return item.title;
  }

  trackBySolution(index: number, item: Solution): string {
    return item.description;
  }

  needDisableForRestore(computer: Computer): boolean {
    return !computer.online && this.isRestore;
  }

  canShowCreteButtons(computer: Computer): boolean {
    return computer.online || (this.ability.can('read', 'AllowOfflineSettingsEdit') && computer?.os === OsType.windows);
  }

  handleEditPlan(plan: PlanInfo, computer: Computer): void {
    if (this.readonly) return;

    if (computer.online || Computer.IsSupportedAgentVersion(computer, AgentType.Backup, minimalWindowsVersionForOfflineEdit, true)) {
      return this.planWrapper.handleEditPlan(plan, computer);
    }

    this.modals.openOfflineEditLowVersionModal();
  }

  handleCreatePlan(availableType: AvailablePlanType, computer: Computer): void {
    if (computer.online || Computer.IsSupportedAgentVersion(computer, AgentType.Backup, minimalWindowsVersionForOfflineEdit, true)) {
      const needOldCreate = availableType.planType === PlanTypes.RestorePlan && availableType.format === PlanFormatTypes.CBF;
      return this.planWrapper.handleCreatePlan(
        availableType.planType,
        computer,
        availableType.format === PlanFormatTypes.NBF,
        needOldCreate
      );
    }

    this.modals.openOfflineEditLowVersionModal();
  }

  handleDeletePlan(plan: PlanInfo, computer: Computer): void {
    if (computer.online || Computer.IsSupportedAgentVersion(computer, AgentType.Backup, minimalWindowsVersionForOfflineEdit, true)) {
      this.selectedPlan = plan;
      return this.planWrapper.handleDeletePlan(plan, computer);
    }

    this.modals.openOfflineEditLowVersionModal();
  }

  handleClonePlan(plan: PlanInfo, computer: Computer): void {
    if (computer.online || Computer.IsSupportedAgentVersion(computer, AgentType.Backup, minimalWindowsVersionForOfflineEdit, true)) {
      return this.planWrapper.handleClonePlan(plan, computer);
    }

    this.modals.openOfflineEditLowVersionModal();
  }

  handleStartPlan(plan: PlanInfo, computer: Computer, mode: AvailablePlanStartModes = AvailablePlanStartModes.Regular): void {
    this.planWrapper.handleStartPlan(plan, computer, mode);
  }

  handleStopPlan(plan: PlanInfo, computer: Computer): void {
    this.planWrapper.handleStopPlan(plan, computer);
  }

  openBackupHistory(computer: Computer): void {
    this.router.navigate(['/AP/BackupHistory'], { queryParams: { filter: 'hid:' + computer.hid } });
  }

  getStatusIconsClasses(item: ProgressLineData): string {
    return item.status === ProgressLineStatus.Failed || (item.errors && item.errors.length)
      ? 'ico-failed text-danger'
      : item.status === ProgressLineStatus.Warning
      ? 'ico-warning text-warning'
      : 'ico-success text-success';
  }

  handleViewBackupStorage(plan: PlanInfo): void {
    if (plan && plan.id && plan.destinationId)
      this.switchToStorage.emit({ id: plan.id, destinationId: plan.destinationId } as ShortPlanInfo);
  }

  handlePlanNameView(visible: boolean, plan: PlanInfo, tooltip: NgbTooltip): void {
    const disableTooltip =  isNil((plan as any).hidePlanNameTooltip);

    (plan as any).hidePlanNameTooltip = visible;
    !disableTooltip && !visible && tooltip.open();
  }

  getPlanFormatFullName(plan: PlanInfo): string {
    return this.getPlanFormat(plan)?.fullName ?? '';
  }

  getPlanFormatShortName(plan: PlanInfo): string {
    return this.getPlanFormat(plan)?.shortName ?? '';
  }

  getEndingForPlanName(availableType: AvailablePlanType, computer: Computer): string {
    if (computer?.os !== OsType.windows) {
      return availableType.format === PlanFormatTypes.NBF && availableType.planType === PlanTypes.Plan
        ? '(NBF)'
        : availableType.planType === PlanTypes.RestorePlan && availableType.format === PlanFormatTypes.CBF
        ? '(Legacy)'
        : '';
    }

    return availableType.format === PlanFormatTypes.CBF && [PlanTypes.Plan, PlanTypes.BackupDiskImagePlan].includes(availableType.planType)
      ? ' (Legacy)'
      : '';
  }

  private getPlanFormat(plan: PlanInfo) {
    return PlanFormatAdapter.find((planFormat) => planFormat.planFormat === plan?.formatType);
  }

  showReportProblem(computer: Computer, plan: PlanInfo) {
    return (
      computer?.online &&
      [AgentVersionStatus.Latest, AgentVersionStatus.Outdated].includes(Computer.getAgentVersionStatus(computer, AgentType.Backup)) &&
      plan?.lastRunInfo?.status === PlanRunSimpleStatus.Failed &&
      (plan?.lastRunInfo?.errorDetails || []).some((errorDetail) => SupportProblemErrorCodes.includes(errorDetail.id))
    );
  }

  reportProblem(computer: Computer, plan: PlanInfo) {
    this.modals.openPlanBasedModal(ComputersSendLogsModalComponent, computer, plan);
  }

  isOpenStatePlanDetail(status: PlanRunSimpleStatus, id: string): boolean {
    return status === PlanRunSimpleStatus.Succeed ? this.detailsOpenState.get(id) : !this.detailsOpenState.get(id);
  }

  authorizeComputer(computer: Computer) {
    this.modals.openComputerBasedModal(ComputerAuthorizationModalComponent, computer);
  }

  private getIsBackupAgentSupportsNewRM(computer: Computer): boolean {
    const app = computer?.apps?.find((app) => app.applicationId === AgentType.Backup);
    const agentVersion = Number(app?.version.replaceAll('.', '').substring(0, 4));

    return (
      agentVersion >= (computer?.os === OsType.windows ? this.newRMSupportsMinimalWindowsVersion : this.newRMSupportsMinimalUnixVersion)
    );
  }
}
