import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentState } from '@models/AppEnvironmentState';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppEnvStateService {
  private myAppEnvState: AppEnvironmentState = null;

  get appEnvState$(): Observable<AppEnvironmentState> {
    if (this.myAppEnvState) {
      return of(this.myAppEnvState);
    } else {
      return this.http.get('/api/version').pipe(
        tap((appState: AppEnvironmentState) => {
          this.myAppEnvState = appState;
        })
      );
    }
  }

  constructor(private http: HttpClient) {}
}
