import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupTask } from '@modules/group-tasks/store/model';
import { GAActions } from '@modules/schedule-group-action/store/group-action';
import EditGroupTaskUtility from '@modules/schedule-group-action/utility/edit-group-task-utility';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { MbsPopupType } from 'mbs-ui-kit';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-install-software',
  templateUrl: './install-software.component.html'
})
export class InstallSoftwareComponent implements OnInit {
  @Input() enableTitle = true;
  public readonly alertType = MbsPopupType;

  public installForm = new UntypedFormGroup({
    url: new FormControl('', Validators.required),
    arguments: new FormControl('', Validators.required)
  });

  constructor(private store: Store, private editGroupTaskUtility: EditGroupTaskUtility) {}

  ngOnInit(): void {
    this.handleGroupAction();
    this.loadGroupTaskData();
  }

  handleGroupAction() {
    const value$ = this.installForm.valueChanges.pipe(untilDestroyed(this));

    value$.subscribe((data) => {
      if (!this.installForm.valid) {
        this.store.dispatch(GAActions.setGActionParameters({ parameters: null }));
        return;
      }

      this.store.dispatch(GAActions.setGActionParameters({ parameters: this.getGroupActionParams() }));
    });
  }

  private loadGroupTaskData(): void {
    this.editGroupTaskUtility
      .getInstallAppDataStream()
      .pipe(take(1))
      .subscribe((data) => {
        this.installForm.get('url').setValue(data.url ?? '');
        this.installForm.get('arguments').setValue(data.params ?? '');
      });
  }

  getGroupActionParams(): Partial<GroupTask> {
    const data = this.installForm.value;
    const fileName = (data.url as string).replace(/^.+\//, '');
    const timeout = 60 * 60;
    const parameters = [
      { name: 'url', value: data.url },
      { name: 'arguments', value: String(data.arguments) },
      { name: 'timeout', value: String(timeout) }
    ];

    return {
      disabled: false,
      type: 'pluginCommand',
      pluginCommandId: 'SoftwareCmd',
      parameters: JSON.stringify({ asyncID: `${getGuid()}[${fileName}]`, id: 'InstallProductSimple', parameters: parameters }),
      confidentialOutputData: false,
      pluginActiveCommand: true,
      pluginPsCommand: false,
      description: this.getDescription(data),
      enableOffline: false,
      eventType: 0,
      scriptGuid: null,
      softwareSourceGuid: null,
      allComputers: false,
      computers: [],
      computerTags: [],
      runType: 0,
      scheduleType: 0,
      scheduleData: null
    };
  }

  getDescription(data: any): string {
    const translationKey = 'rmm.module:groupActions.actionDescription.';
    const descriptionPart1 = this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'installSoftwareLink', {});
    const descriptionPart2 = this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'installSoftwareParameters', {});

    return descriptionPart1 + ` ${data.url}` + '\r\n' + descriptionPart2 + ` ${data.arguments}`;
  }
}
