import { BuildType } from '@models/BuildType.enum';
import { OsType } from '@models/Computer';
import { BuildSelectionType } from '@modules/wizards/steps/installation/installation.types';
import { StepId } from '@modules/wizards/onboarding/views/computers/computers.constants';
import { I18NextPipe } from 'angular-i18next';

export const getConfigureBackupConfig = (i18N: I18NextPipe) => {
  return [
    {
      id: StepId.StorageAccount,
      title: i18N.transform('onboarding:storage_account'),
      description: i18N.transform('onboarding:tab.backup_storage_destination')
    },
    {
      id: StepId.BackupSource,
      title: i18N.transform('onboarding:backup_source'),
      description: i18N.transform('onboarding:tab.what_to_backup')
    },
    {
      id: StepId.Notifications,
      title: i18N.transform('onboarding:notifications'),
      description: i18N.transform('onboarding:tab.email_alerts')
    }
  ];
};

export const getBuildsSelectionConfig = (i18n: I18NextPipe): BuildSelectionType[] => {
  return [
    {
      buildType: BuildType.CommonWindowsBuild,
      name: i18n.transform('backup.windows'),
      iconClass: 'ico ico-windows',
      os: OsType.windows
    },
    {
      buildType: BuildType.LinuxMac,
      name: i18n.transform('backup.mac'),
      iconClass: 'fa fa-apple download-build_logo-icon -lg',
      os: OsType.apple
    },
    {
      buildType: BuildType.LinuxRedHat6,
      name: i18n.transform('backup.linuxRpm'),
      iconClass: 'fa fa-linux download-build_logo-icon -lg',
      os: OsType.linux
    },
    {
      buildType: BuildType.LinuxUbuntu14,
      name: i18n.transform('backup.linuxDeb'),
      iconClass: 'fa fa-linux download-build_logo-icon -lg',
      os: OsType.linux
    }
  ];
};
