import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackupAgentUpdateRequiredModalComponent } from '@components/computers-modal/backup-agent-update-required-modal/backup-agent-update-required-modal.component';
import { BackupUpdateRequiredModalComponent } from '@components/computers-modal/backup-update-required-modal/backup-update-required-modal.component';
import { RemoteConnectionAllowModalComponent } from '@components/computers-modal/remote-connection-allow-modal/remote-connection-allow-modal.component';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { ConnectSettingsResponse, ConnectSettingsScope, IConnectSettings } from '@models/connect/connect-settings.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@root/mbs-ui/src/environments/environment';
import { ConfigurationService } from '@services/configuration.service';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, MbsSize, ModalService, ModalSettings, TextBreak, Toast, ToastService } from 'mbs-ui-kit';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { BehaviorSubject, EMPTY, from, fromEvent, interval, merge, noop, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  share,
  startWith,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap
} from 'rxjs/operators';
import { GrantLicenseForComputerModalComponent } from '../components/computers-modal/grant-license-for-computer-modal/grant-license-for-computer-modal.component';
import { RemoteConnectionChooseTypeModalComponent } from '../components/computers-modal/remote-connection-choose-type-modal/remote-connection-choose-type-modal.component';
import { RemoteConnectionDesktopLicenseSelectModalComponent } from '../components/computers-modal/remote-connection-desktop-license-select-modal/remote-connection-desktop-license-select-modal.component';
import { RemoteConnectionEnableModalComponent } from '../components/computers-modal/remote-connection-enable-modal/remote-connection-enable-modal.component';
import { RemoteConnectionInstallUpdateModalComponent } from '../components/computers-modal/remote-connection-install-update-modal/remote-connection-install-update-modal.component';
import { RemoteConnectionNotAvailableModalComponent } from '../components/computers-modal/remote-connection-not-available-modal/remote-connection-not-available-modal.component';
import { RemoteConnectionPrepareModalComponent } from '../components/computers-modal/remote-connection-prepare-modal/remote-connection-prepare-modal.component';
import { RemoteConnectionSuperAdminModalComponent } from '../components/computers-modal/remote-connection-super-admin-modal/remote-connection-super-admin-modal.component';
import { RemoteConnectionWebLicenseSelectModalComponent } from '../components/computers-modal/remote-connection-web-license-select-modal/remote-connection-web-license-select-modal.component';
import { ConnectionLicenseType } from '../components/computers-shared/enums';
import { ManageComputerModalComponent } from '../components/licenses/components/manage-license-computer-modal/manage-license-computer-modal.component';
import { POST_PAYMENT_TOKEN } from '../components/licenses/tokens/post-payment.token';
import { ComputersFacade } from '../facades/computers.facade';
import Administrator from '../models/Administrator';
import Computer, { AgentType, OsType } from '../models/Computer';
import { ConnectionType, PrepareConnectionMode } from '../models/ConnectionType';
import { LicenseType } from '../models/LicenseType.enum';
import { ConnectMenuOptionModel } from '../models/MenuOptionModel';
import { PermissionsEnum } from '../models/PermissionsEnum';
import { WEB_CONNECT_URL } from '../utils/connect-url';
import { isFontExists } from '../utils/isFontExists';
import { convertToClientUrl } from '../utils/pipes/client-url.pipe';
import { AdministratorService, LicenseAssignToAdmin } from './administrator.service';
import { AuthService } from './auth.service';
import { ConnectSettingsService } from './connect.settings.service';
import { LicensesService } from './licenses.service';

const poolingDelay = 3000;
const isLicenseRequiredCode = 403;
const isSuitableLicenseRequired = 406;
const minimumSupportedRAAgentVersion = 320;
const connectWithTokenSupportsAgentVersion = 340;
const backupWithTokenSupportsAgentVersion = 770;
const defaultWebConnectURL = '/AP/WebConnect';

export const REMOTE_CONNECTION_NOT_ENABLED_ERROR_TYPE = 'https://mspbackups.com/errors/mbs/4127';
export const ALLOW_REMOTE_ACCESS_ERROR_TYPE = 'https://mspbackups.com/errors/mbs/4128';
export const REMOTE_CONNECTION_NOT_ENABLED_FOR_SUB_ADMIN_ERROR_TYPE = 'https://mspbackups.com/errors/mbs/4105';

type RetryConnectionType = { computer: Computer; type?: ConnectionType; result?: boolean; needRepeat?: boolean };

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ConnectService {
  windowFocused: boolean;
  public currentUser: Administrator;
  public gotError$ = new Subject<boolean>();
  public connectionPrepareMode$: BehaviorSubject<PrepareConnectionMode> = new BehaviorSubject<PrepareConnectionMode>(
    PrepareConnectionMode.Installation
  );

  private deviceInfo: DeviceInfo;

  private connectRetries = 4; // retries count before showing error alert in modal
  private stopComputerPoll$ = new Subject<boolean>();
  private isProvider = false;
  private readonly settingsAPIUrl: string;
  private readonly settingsAPIParams: HttpParams;

  private menuOptionsWindows: ConnectMenuOptionModel[];
  private menuOptionsMacLinux: ConnectMenuOptionModel[];
  private connectClientUrl: string = environment.raDownloadURL.windows;

  constructor(
    private adminService: AdministratorService,
    private ability: AbilityService,
    private authService: AuthService,
    private computersFacade: ComputersFacade,
    private deviceDetectorService: DeviceDetectorService,
    private toastService: ToastService,
    private modalService: ModalService,
    public i18nPipe: I18NextPipe,
    private router: Router,
    private route: ActivatedRoute,
    private licenseService: LicensesService,
    private connectSettings: ConnectSettingsService,
    private apiClient: HttpClient,
    @Inject(ConfigurationService) private config: ConfigurationService,
    @Inject(POST_PAYMENT_TOKEN) public isPostPayment: Observable<boolean>,
    @Inject(WEB_CONNECT_URL) public webConnectPredefinedUrl: string,
    @Inject(APP_BASE_HREF) private appBaseHref: string
  ) {
    this.settingsAPIUrl = `${this.config.get('rmmCustomSettings')}/api/custom/provider`;
    this.settingsAPIParams = new HttpParams({
      fromObject: {
        mbsStageKey: this.config.get('mbsStageKey')
      }
    });

    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    merge(fromEvent(window, 'focus'), fromEvent(window, 'blur'))
      .pipe(
        startWith(null),
        map(() => window.document.hasFocus()),
        distinctUntilChanged(),
        share(),
        untilDestroyed(this)
      )
      .subscribe((windowFocus) => {
        this.windowFocused = windowFocus;
      });
    this.authService.currentUser
      .pipe(
        filter((c) => !!c),
        untilDestroyed(this)
      )
      .subscribe((user) => {
        this.currentUser = user;
        this.isProvider = user.IsProvider;
      });

      this.authService.isMBSMode &&
      this.connectSettings.getConnectClientBuild().subscribe((connectClientUrl) => {
        this.connectClientUrl = connectClientUrl;
      });
  }

  public getSettings(scope: ConnectSettingsScope, entityId?: string): Observable<IConnectSettings | null> {
    return this.apiClient.get(this.getSettingsAPIUrl(scope, entityId), { params: this.settingsAPIParams }).pipe(
      map((response: ConnectSettingsResponse) => {
        if (response && response.body) {
          try {
            return JSON.parse(response.body) as IConnectSettings;
          } catch (e) {
            noop;
          }
        }
        return null;
      })
    );
  }

  public getExternalDisplayUrl(computer: Computer, externalDisplayId: string): string {
    return this.router.serializeUrl(
      this.router.createUrlTree([this.webConnectURL], {
        relativeTo: this.route,
        queryParams: { hid: computer.hid, edid: externalDisplayId }
      })
    );
  }

  public deleteSettings(scope: ConnectSettingsScope, entityId: string) {
    return this.apiClient.delete(this.getSettingsAPIUrl(scope, entityId), { params: this.settingsAPIParams });
  }

  public updateSettings(settings: IConnectSettings, scope: ConnectSettingsScope, entityId?: string) {
    return this.apiClient.post(this.getSettingsAPIUrl(scope, entityId), JSON.stringify(settings), { params: this.settingsAPIParams });
  }

  private getSettingsAPIUrl(scope: ConnectSettingsScope, entityId?: string) {
    if (scope === ConnectSettingsScope.Global) {
      return `${this.settingsAPIUrl}/global/connect/host`;
    }

    return `${this.settingsAPIUrl}/${scope}/connect/host/${entityId}`;
  }

  public getMenuOptions(computer: Computer): ConnectMenuOptionModel[] {
    if (!this.menuOptionsWindows) {
      this.initMenuOptions();
    }
    return Computer.isMacOrLinux(computer) ? this.menuOptionsMacLinux : this.menuOptionsWindows;
  }

  public getFirstMenuOption(menuOptions: ConnectMenuOptionModel[] = []): ConnectMenuOptionModel {
    return menuOptions.length ? menuOptions[0] : null;
  }

  public getFirstMenuOptionByComputer(computer: Computer): ConnectMenuOptionModel {
    return this.getFirstMenuOption(this.getMenuOptions(computer));
  }

  public isConnectAvailable(computer: Computer): boolean {
    const isOnlineAccess = !this.authService.isMBSMode;
    const isAllowedOnlineAccess =
      this.currentUser.EnableOnlineAccessConnect && (this.ability.can('read', 'ShowRemoteConnection') || this.isProvider);
    const isWindows = Computer.isWindows(computer);
    const isAllowedMacOrLinux = Computer.isMacOrLinux(computer) && Computer.hasAgent(computer, AgentType.Backup);

    return (isOnlineAccess ? isAllowedOnlineAccess : true) && (isWindows || isAllowedMacOrLinux);
  }

  public isConnectDisabled(computer: Computer): boolean {
    return !((Computer.isWindows(computer) && computer?.online) || Computer.isAgentOnline(computer, AgentType.Backup));
  }

  public isConnectSettingsAvailable(computer: Computer): boolean {
    return (
      this.authService.isMBSMode &&
      ((this.ability.can('read', PermissionsEnum.ManageCompanies) && !!computer?.company) ||
        (this.ability.can('read', PermissionsEnum.ManageCompanies) && this.ability.can('read', PermissionsEnum.AccessToAllCompanies)) ||
        this.currentUser.IsProvider)
    );
  }

  public isConnectUseOldSettings(): boolean {
    return this.ability.can('read', PermissionsEnum.UseOldConnectSettings);
  }

  public isConnectGlobalSettingsAvailable(): boolean {
    return (
      this.currentUser.IsProvider ||
      (this.ability.can('read', PermissionsEnum.ManageCompanies) && this.ability.can('read', PermissionsEnum.AccessToAllCompanies))
    );
  }

  public connectAction(computerParam: Computer, connectionTypeParam?: ConnectionType) {
    if (this.currentUser.IsSuperAdmin) {
      return this.showConnectNotAvailableForSuperAdminModal();
    }

    // check os and connectionType
    return this.prepareDataForConnectAction(computerParam, connectionTypeParam).pipe(
      take(1),
      map((result) => result as RetryConnectionType),
      // refresh Computer data before connection
      switchMap((params) => this.computersFacade.getByHid(computerParam.hid, true).pipe(map((computer) => ({ ...params, computer })))),
      // 1st check
      switchMap((params) => this.checkConnectionAllowed(params)),
      takeWhile((param) => !!param.result),
      // 2nd check (because we can't receive all error codes in 1st check)
      switchMap((params) => (params.needRepeat ? this.checkConnectionAllowed(params) : of(params))),
      takeWhile((param) => !!param.result),
      // check installed Agent
      switchMap((param) => this.checkInstalledAgent(param.computer, param.type).pipe(map((readyState) => ({ ...readyState, ...param })))),
      tap(() => this.modalService.dismissAll()),
      switchMap((param) =>
        param.computer.os === OsType.windows
          ? this.connectToWindows({
              computer: param.computer,
              connectionType: param.type,
              connectWebRtc: param.ready && !param.installation && param.type === ConnectionType.WebRTC,
              connectionPrepareMode: param.installation ? PrepareConnectionMode.Installation : PrepareConnectionMode.Preparation
            })
          : this.connectToUnix(param.computer, param.type)
      )
    );
  }

  private initMenuOptions() {
    this.menuOptionsWindows = [
      {
        name: this.i18nPipe.transform('computers.module:connect.connectToRCWebRTC'),
        type: ConnectionType.WebRTC
      },
      {
        name: this.i18nPipe.transform('computers.module:connect.connectToRCApp'),
        type: ConnectionType.RD
      }
    ];

    this.menuOptionsMacLinux = [
      {
        name: this.i18nPipe.transform('computers.module:connect.connectToRCSSH'),
        type: ConnectionType.SSH
      },
      {
        name: this.i18nPipe.transform('computers.module:connect.connectToRCVNC'),
        type: ConnectionType.VNC
      }
    ];
  }

  private prepareDataForConnectAction(computer: Computer, type?: ConnectionType) {
    return of({ computer, type }).pipe(
      switchMap((params) =>
        !params.type && [OsType.apple, OsType.linux].includes(params.computer?.os)
          ? this.chooseConnectionType(params.computer).pipe(map((type) => ({ computer: params.computer, type })))
          : of(params)
      ),
      map((params) => ({
        computer: params.computer,
        type:
          ![ConnectionType.SSH, ConnectionType.VNC].includes(params.type) && [OsType.apple, OsType.linux].includes(params.computer?.os)
            ? ConnectionType.SSH
            : ![ConnectionType.RD, ConnectionType.WebRTC].includes(params.type) && [OsType.windows].includes(params.computer?.os)
            ? ConnectionType.RD
            : params.type,
        supportedOs: [OsType.apple, OsType.linux, OsType.windows].includes(params.computer?.os),
        isWindows: params.computer?.os === OsType.windows,
        hasBackupAgent: Computer.hasAgent(params.computer, AgentType.Backup)
      })),
      tap((params) => {
        !params.supportedOs &&
          this.toastService.error(this.i18nPipe.transform('computers.module:connect.notSupportedOperatingSystemError'));
        params.supportedOs &&
          !params.isWindows &&
          !params.hasBackupAgent &&
          this.toastService.error(this.i18nPipe.transform('computers.module:connect.backupAgentNotInstalled'));
      }),
      filter((params) => params.supportedOs && (params.isWindows || params.hasBackupAgent))
    );
  }

  private checkConnectionAllowed(params: RetryConnectionType) {
    const defaultResult = { result: false, needRepeat: false, ...params } as RetryConnectionType;
    const showConnectNotAvailable = () =>
      this.showConnectNotAvailableModal(params.computer).pipe(
        catchError(() => of(false)),
        map(() => defaultResult)
      );
    const showEnableConnect = () =>
      this.showEnableConnectModal(params.computer).pipe(
        map(() => ({ result: true, needRepeat: true, ...params } as RetryConnectionType)),
        catchError(() => of(defaultResult))
      );
    const showLicenseModals = () =>
      this.selectLicense(params.computer, params.type).pipe(
        map((result) => ({ ...params, needRepeat: !!result, result: !!result } as RetryConnectionType)),
        catchError(() => of(defaultResult))
      );

    const showAllowConnect = () =>
      this.showConnectNotAllowedModal(params.computer).pipe(
        catchError(() => of(false)),
        map(() => defaultResult)
      );

    return of(true).pipe(
      switchMap((value) =>
        this.authService.isMBSMode ? this.computersFacade.isConnectionAllowed(params.computer.hid, params.type) : of(value)
      ),
      map(() => ({ result: true, needRepeat: false, ...params } as RetryConnectionType)),
      catchError((error) => {
        const code = error.error.status;
        const type = error.error.type;
        const needEnable =
          type === REMOTE_CONNECTION_NOT_ENABLED_ERROR_TYPE || type === REMOTE_CONNECTION_NOT_ENABLED_FOR_SUB_ADMIN_ERROR_TYPE;
        const needAllow = type === ALLOW_REMOTE_ACCESS_ERROR_TYPE;
        const needLicense = code === isLicenseRequiredCode || code === isSuitableLicenseRequired;
        const errorTypes = [
          {
            rule: (needEnable && !this.isProvider) || (needLicense && !this.ability.can('read', PermissionsEnum.Licenses)),
            result: showConnectNotAvailable
          },
          { rule: needEnable, result: showEnableConnect },
          { rule: needLicense, result: showLicenseModals },
          { rule: needAllow, result: showAllowConnect }
        ];
        return errorTypes.find((errorType) => !!errorType.rule)?.result() ?? of(defaultResult);
      })
    );
  }

  private chooseConnectionType(computer: Computer) {
    return from(
      this.modalService.openCustom(RemoteConnectionChooseTypeModalComponent, {
        size: MbsSize.sm,
        header: this.i18nPipe.transform(`computers.module:modals.connectTo`, { param: Computer.getComputerName(computer) }),
        data: { computer }
      })
    ).pipe(
      map((result) => result as ConnectionType),
      catchError(() => EMPTY)
    );
  }

  public getRemoteAccessNotAllowedError(computer: Computer): string {
    const company = computer.company;

    if (
      this.isProvider ||
      (this.ability.can('read', PermissionsEnum.ManageCompanies) && this.ability.can('read', PermissionsEnum.AccessToAllCompanies))
    ) {
      if (company) {
        return this.i18nPipe.transform(`computers.module:connect.remoteAccessNotAllowedForCompanyProviderError`, {
          companyName: company.name,
          companyId: company.id
        });
      }

      return this.i18nPipe.transform(`computers.module:connect.remoteAccessNotAllowedProviderError`);
    }

    if (company) {
      return this.i18nPipe.transform(`computers.module:connect.remoteAccessNotAllowedForCompanyError`, { companyName: company.name });
    }

    return this.i18nPipe.transform(`computers.module:connect.remoteAccessNotAllowedError`);
  }

  public getUseOldSettingsMenuItemInfoText() {
    if (!this.isProvider && !this.ability.can('read', PermissionsEnum.AccessToAllCompanies)) {
      return this.i18nPipe.transform(`computers.module:connect.settingButtonMenuItemInfoForSubAdmin`);
    }

    return this.i18nPipe.transform(`computers.module:connect.settingButtonMenuItemInfoForProvider`);
  }

  private showEnableConnectModal(computer: Computer) {
    return from(this.modalService.openCustom(RemoteConnectionEnableModalComponent, { data: { computer } }));
  }

  private showConnectNotAvailableModal(computer: Computer) {
    return from(this.modalService.openCustom(RemoteConnectionNotAvailableModalComponent, { data: { computer } }));
  }

  private showConnectNotAvailableForSuperAdminModal() {
    return from(this.modalService.openCustom(RemoteConnectionSuperAdminModalComponent));
  }

  private showConnectNotAllowedModal(computer: Computer) {
    return from(this.modalService.openCustom(RemoteConnectionAllowModalComponent, { data: { computer } }));
  }

  // #region License
  private selectLicense(computer: Computer, type: ConnectionType) {
    return of({ computer, type }).pipe(
      switchMap((params) => {
        if ((params.type === ConnectionType.WebRTC && Computer.hasAgent(computer, AgentType.Backup)) || computer?.os !== OsType.windows) {
          return from(
            this.modalService.openCustom(RemoteConnectionWebLicenseSelectModalComponent, {
              data: { computer }
            })
          ).pipe(
            switchMap((result: ConnectionLicenseType) => {
              if (result === ConnectionLicenseType.RMM) {
                return this.showGrantLicenseAdminModal();
              } else {
                return this.isPostPayment.pipe(
                  take(1),
                  switchMap((isPostPayment) => (isPostPayment ? this.showManageLicenseComputerModal(computer) : this.tryGrant(computer)))
                );
              }
            })
          );
        } else {
          return from(
            this.modalService.openCustom(RemoteConnectionDesktopLicenseSelectModalComponent, {
              data: { type }
            })
          ).pipe(switchMap(() => this.showGrantLicenseDialog(params.type)));
        }
      })
    );
  }

  private showGrantLicenseAdminModal() {
    const licenseFilter = [LicenseType.RMM, LicenseType.RemoteDesktop];
    return this.isPostPayment.pipe(
      take(1),
      switchMap((isPostPayment) =>
        this.adminService
          .openGrantOrManageLicenseModal(
            { email: this.currentUser.Email, adminID: this.currentUser.Id, filter: licenseFilter },
            isPostPayment
          )
          .pipe(
            take(1),
            switchMap((license: LicenseAssignToAdmin) =>
              license?.adminId && license.license ? this.authService.fetchCurrentUser().pipe(map(() => true)) : of(false)
            )
          )
      )
    );
  }

  private showGrantLicenseComputerModal(computer: Computer, availableLicenses = []) {
    return from(
      this.modalService.openCustom(GrantLicenseForComputerModalComponent, {
        header: 'Grant License',
        data: {
          availableLicenses,
          hid: computer.hid
        }
      })
    ).pipe(map(() => true));
  }

  private showManageLicenseComputerModal(computer: Computer) {
    return from(
      this.modalService.openCustom(ManageComputerModalComponent, {
        data: {
          title: this.i18nPipe.transform('licenses:manageModal:title', {
            format: 'title'
          }),
          endpointId: computer.hid
        }
      })
    ).pipe(switchMap(() => this.authService.fetchCurrentUser().pipe(map(() => true))));
  }

  private tryGrant(computer: Computer) {
    return this.licenseService.tryGrantLicense(computer.hid).pipe(
      switchMap((res) => {
        if (!res.isGranted) {
          if (res.availableLicenses.length > 0) {
            return this.showGrantLicenseComputerModal(computer, res.availableLicenses);
          }
          if (!res.availableLicenses.length && res.isAllowBuy) {
            return this.showBuyLicenseModal();
          } else {
            return of(true);
          }
        } else {
          return of(true);
        }
      })
    );
  }

  private showBuyLicenseModal() {
    const settings: ModalSettings = {
      responsive: true,
      size: MbsSize.sm,
      header: { title: this.i18nPipe.transform('computers.module:modals.header'), size: MbsSize.lg },
      footer: {
        okButton: {
          text: this.i18nPipe.transform('licenses:licenseModal.buy'),
          type: 'success'
        },
        cancelButton: {
          text: this.i18nPipe.transform('licenses:licenseModal.close')
        }
      }
    };

    return from(
      this.modalService.open(settings, `<div class="text-center">${this.i18nPipe.transform('licenses:licenseModal.needLicense')}</div>`)
    ).pipe(
      catchError(() => of(false)),
      tap((result) => {
        if (result) {
          void this.router.navigate([RoutingPath.ApLicensesAspx], { queryParams: { buy: 'true' } });
        }
      })
    );
  }

  private showGrantLicenseDialog(connectionType: ConnectionType) {
    if (connectionType === ConnectionType.WebRTC) {
      return this.showGrantLicenseAdminModal();
    }

    const filter = connectionType === ConnectionType.RD ? [LicenseType.RMM, LicenseType.RemoteDesktop] : [LicenseType.RMM];
    return this.adminService.assignLicenseDialogueModal({ email: this.currentUser.Email, adminID: this.currentUser.Id, filter }).pipe(
      switchMap((license: LicenseAssignToAdmin) => {
        return license?.adminId && license.license ? this.authService.fetchCurrentUser().pipe(map(() => true)) : of(false);
      })
    );
  }
  // #endregion License

  private openConnectModal(computer: Computer, connectionType: ConnectionType) {
    const os = this.deviceInfo.os.toLocaleLowerCase();
    const downloadLink = this.prepareDownloadUrl(os);
    this.startPolling(computer, connectionType);
    return from(
      this.modalService
        .openCustom(RemoteConnectionPrepareModalComponent, {
          size: MbsSize.md,
          header: this.i18nPipe.transform(`computers.module:modals.connectTo`, { param: Computer.getComputerName(computer) }),
          data: {
            downloadLink,
            computer$: this.computersFacade.getByHid(computer.hid),
            connectionType: connectionType,
            connectionMode$: this.connectionPrepareMode$.asObservable()
          },
          footer: {
            show: false
          }
        })
        .catch(noop)
    ).pipe(
      finalize(() => {
        this.stopComputerPoll$.next(true);
        this.computersFacade.loadComputerByHid({ hid: computer.hid, force: true });
      })
    );
  }

  private prepareDownloadUrl(currentOs): string {
    const os = currentOs.toLowerCase();
    if (currentOs === 'windows') {
      return this.connectClientUrl;
    }
    if (os === 'mac') {
      return environment.raDownloadURL.mac;
    }
    if (os === 'ios') {
      return environment.raDownloadURL.ios;
    }
    if (os === 'android') {
      return environment.raDownloadURL.android;
    }

    return '';
  }

  private connectToUnix(computer: Computer, connectionType?: ConnectionType) {
    return of({ computer, connectionType }).pipe(
      switchMap((params) =>
        this.computersFacade.getConnectionUrl(params.computer, params.connectionType).pipe(
          map((urlData) => ({ ...params, urlData })),
          catchError((err) => {
            this.toastService.toast(
              new Toast({
                header: err.error.title,
                content: err.error.detail,
                textBreak: TextBreak.both,
                type: MbsPopupType.danger,
                showClose: true,
                delay: 0
              })
            );
            return EMPTY;
          })
        )
      ),
      tap((result) => this.openMacConnectResultModal(result.computer, result.urlData, result.connectionType))
    );
  }

  private connectToWindows(params: {
    computer: Computer;
    connectionType: ConnectionType;
    connectWebRtc: boolean;
    connectionPrepareMode: PrepareConnectionMode;
  }) {
    return of(params).pipe(
      tap((param) => param.connectWebRtc && this.connectWebRTC(param.computer)),
      filter((param) => !param.connectWebRtc),
      tap((param) => this.connectionPrepareMode$.next(param.connectionPrepareMode)),
      switchMap((param) => this.openConnectModal(param.computer, param.connectionType))
    );
  }

  private openMacConnectResultModal(computer: Computer, result, connectionType: ConnectionType): void {
    let template = `<div class="row"><div class="col-3">Public IP: </div><div class="col-auto">${result.publicIp}</div></div>
                    <div class="row"><div class="col-3">Port: </div><div class="col-auto">${result.port}</div></div>
                    <div class="row"><div class="col-3">Protocol: </div><div class="col-auto">${result.protocol.toUpperCase()}</div></div>`;
    if (connectionType === ConnectionType.SSH) {
      template += `<div class="text-sm text-monospace text-break p-3 mt-2 background-secondary border">ssh -p ${result.port} ${result.publicIp}</div>`;
    } else {
      template += `<div class="text-sm text-monospace text-break p-3 mt-2 background-secondary border">vnc://${result.publicIp}:${result.port}</div>`;
    }

    this.modalService
      .open(
        {
          header: `Connect to ${computer.displayName || computer.name}`,
          footer: { okButton: { show: false }, cancelButton: { text: 'Close' } }
        },
        template
      )
      .finally(noop);
  }

  private connectComputer(computer: Computer, connectionType: ConnectionType, retriesCount = 1): void {
    this.connectionPrepareMode$.next(PrepareConnectionMode.Preparation);
    this.computersFacade
      .getConnectionUrl(computer, connectionType)
      .pipe(takeUntil(this.stopComputerPoll$.asObservable()))
      .subscribe({
        next: (resp) => {
          this.connectionPrepareMode$.next(PrepareConnectionMode.Ready);
          const deepLink = typeof resp === 'string' ? resp : resp.url || resp.Url; // 47 string, prod object
          this.tryConnect(deepLink).catch(() => {
            const supportedOs = Object.keys(environment.raDownloadURL);
            const currentOs = this.deviceInfo.os.toLocaleLowerCase();
            if (!supportedOs.includes(currentOs)) {
              this.toastService.info(`Sorry, your OS is not supported`);
            }
          });
        },
        error: (error) => {
          if (retriesCount >= this.connectRetries) {
            error?.message && this.toastService.error(error.message);
            this.connectionPrepareMode$.next(PrepareConnectionMode.Ready);
          } else {
            this.connectComputer(computer, connectionType, ++retriesCount);
          }
        }
      });
  }

  private startPolling(computer: Computer, connectionType: ConnectionType) {
    // polling
    interval(poolingDelay)
      .pipe(
        takeUntil(this.stopComputerPoll$.asObservable()),
        tap(() => this.computersFacade.loadComputerByHid({ hid: computer.hid, quiet: true, force: true }))
      )
      .subscribe();

    this.computersFacade
      .getByHid(computer.hid)
      .pipe(
        takeUntil(this.stopComputerPoll$.asObservable()),
        filter(
          (computer) =>
            Computer.isAgentOnline(computer, AgentType.RA) &&
            (connectionType !== ConnectionType.WebRTC || this.isAgentSupportWebRTC(computer, AgentType.RA))
        ),
        take(1)
      )
      .subscribe(() => {
        this.stopComputerPoll$.next(true);
        if (this.connectionPrepareMode$.value === PrepareConnectionMode.Installation) {
          this.connectionPrepareMode$.next(PrepareConnectionMode.Installed);
          return;
        }
        this.startConnect(computer, connectionType, true);
      });
  }

  startConnect(computer: Computer, connectionType: ConnectionType, retry = false) {
    if (connectionType === ConnectionType.WebRTC) {
      this.connectWebRTC(computer);
    } else {
      this.connectComputer(computer, connectionType, retry ? 1 : this.connectRetries);
    }
  }

  checkInstalledAgent(computer: Computer, connectionType?: ConnectionType): Observable<{ ready: boolean; installation: boolean }> {
    return of({ computer, connectionType }).pipe(
      switchMap((params) => {
        if (params.computer?.os === OsType.windows) {
          const needInstallAgent = !Computer.hasAgent(params.computer, AgentType.RA);
          const needUpdateAgent =
            params.connectionType === ConnectionType.WebRTC && !this.isAgentSupportWebRTC(params.computer, AgentType.RA);

          if (needUpdateAgent && this.isShowUpdateBackupAgentInfoRequired(computer)) {
            return this.showUpdateBackupAgentRequiredModal(params.computer).pipe(
              filter(Boolean),
              map((param) => ({ ready: !!param, installation: false })),
              catchError(() => of({ ready: false, installation: false }))
            );
          }

          if (needInstallAgent && this.isShowUpdateBackupAgentBeforeUseConnectInfoRequired(computer)) {
            this.showUpdateBackupAgentBeforeUseConnectModal(computer);

            return of({ ready: false, installation: false });
          }

          if (needInstallAgent || needUpdateAgent) {
            return this.showInstallUpdateModal(params.computer, needInstallAgent).pipe(
              filter(Boolean),
              switchMap(() => (needInstallAgent ? this.installRAAgent(params.computer) : this.updateRAAgent(params.computer))),
              map((param) => ({ ready: !!param, installation: true })),
              catchError(() => of({ ready: false, installation: false }))
            );
          } else {
            if (this.isShowUpdateBackupAgentInfoRequired(computer)) {
              return this.showUpdateBackupAgentRequiredModal(params.computer).pipe(
                filter(Boolean),
                map((param) => ({ ready: !!param, installation: false })),
                catchError(() => of({ ready: false, installation: false }))
              );
            } else if (Computer.hasAgent(params.computer, AgentType.RA) && !Computer.getAgent(params.computer, AgentType.RA)?.online) {
              this.toastService.info(this.i18nPipe.transform('computers.module:connect.connectIsOffline'));
            }
          }
        }
        return of({ ready: true, installation: false });
      }),
      filter((params) => params.ready || params.installation)
    );
  }

  private isShowUpdateBackupAgentBeforeUseConnectInfoRequired(computer: Computer) {
    return (
      !Computer.hasAgent(computer, AgentType.RA) &&
      Computer.hasAgent(computer, AgentType.Backup) &&
      !Computer.IsSupportedAgentVersion(computer, AgentType.Backup, backupWithTokenSupportsAgentVersion)
    );
  }

  private isShowUpdateBackupAgentInfoRequired(computer: Computer) {
    return (
      Computer.hasAgent(computer, AgentType.RA) &&
      Computer.hasAgent(computer, AgentType.Backup) &&
      !Computer.IsSupportedAgentVersion(computer, AgentType.RA, connectWithTokenSupportsAgentVersion) &&
      !Computer.IsSupportedAgentVersion(computer, AgentType.Backup, backupWithTokenSupportsAgentVersion)
    );
  }

  private showUpdateBackupAgentRequiredModal(computer: Computer): Observable<boolean> {
    return from(
      this.modalService.openCustom(BackupUpdateRequiredModalComponent, {
        data: {
          computerName: Computer.getComputerName(computer)
        }
      })
    ).pipe(map((res) => !!res));
  }

  private showUpdateBackupAgentBeforeUseConnectModal(computer: Computer): void {
    this.modalService.openCustom(BackupAgentUpdateRequiredModalComponent, {
      data: {
        computerName: Computer.getComputerName(computer)
      }
    });
  }

  private installRAAgent(computer: Computer) {
    return this.computersFacade.installAgent(computer, AgentType.RA).pipe(
      tap((result) => {
        if (result.isErrorsInResultList) {
          const error = result.resultList.find((res) => res.result.ok === false);
          this.toastService.toast(
            new Toast({
              type: MbsPopupType.danger,
              content: error.result.message,
              textBreak: TextBreak.content
            })
          );
        }
      })
    );
  }

  private updateRAAgent(computer: Computer) {
    return this.computersFacade.newAgentVersionAvailable(computer.hid, AgentType.RA).pipe(
      tap(
        (buildType) => !buildType && this.toastService.error(this.i18nPipe.transform('computers.module:connect.newVersionNotFoundError'))
      ),
      filter(Boolean),
      switchMap((buildType) => this.computersFacade.updateAgent(computer.hid, AgentType.RA, buildType))
    );
  }

  private showInstallUpdateModal(computer: Computer, isInstallMode = true): Observable<boolean> {
    return from(
      this.modalService.openCustom(RemoteConnectionInstallUpdateModalComponent, {
        data: {
          isInstallMode,
          computerName: Computer.getComputerName(computer)
        }
      })
    ).pipe(map((res) => !!res));
  }

  isAgentSupportWebRTC(computer: Computer, agentType: AgentType): boolean {
    return Computer.IsSupportedAgentVersion(computer, agentType, minimumSupportedRAAgentVersion);
  }

  get webConnectURL(): string {
    return convertToClientUrl(this.webConnectPredefinedUrl || defaultWebConnectURL, this.appBaseHref);
  }

  private connectWebRTC(computer: Computer): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([this.webConnectURL], {
        relativeTo: this.route,
        queryParams: { hid: computer.hid, ...(this.authService.isMBSMode ? {} : { bh: this.appBaseHref }) }
      })
    );
    window.open(url, '_blank');
  }

  private tryConnect(url: string): Promise<void> {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return new Promise((resolve, reject) => {
      this.windowFocused = true;
      if (this.deviceInfo.os.includes('Win') && isFontExists('CloudBerry1')) {
        // Windows
        document.location.href = url;
        resolve();
      } else if (this.deviceInfo.userAgent.includes('Macintosh') || this.deviceInfo.userAgent.includes('Firefox')) {
        // Mac and Firefox
        const f = document.createElement('iframe');
        f.style.display = 'none';
        document.body.appendChild(f);
        try {
          (f.contentWindow as Window).location.href = url;
          setTimeout(() => {
            if (this.windowFocused) {
              reject(Error());
            } else {
              resolve();
            }
          }, 2000);
        } catch (e) {
          reject(e);
        }
        setTimeout(() => {
          document.body.removeChild(f);
        }, 3000);
      } else {
        document.location.href = url;
        // eslint-disable-next-line sonarjs/no-identical-functions
        setTimeout(() => {
          if (this.windowFocused) {
            reject(Error());
          } else {
            resolve();
          }
        }, 2000);
      }
    });
  }
}
