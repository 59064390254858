import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import IpWhiteList, { IpWhiteListState } from '@models/IpWhiteList';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IpWhiteListService } from '@services/ip-white-list.service';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep, isEqual } from 'lodash';
import {
  DataChangeWatcherService,
  FormsUtil,
  MbsPopupType,
  ModalService,
  ModalSettings,
  SidepanelCrudBase,
  SidepanelService,
  SortEvent,
  TableHeader,
  ToastService
} from 'mbs-ui-kit';
import * as rangeCheck from 'range_check';
import { noop, Observable, of, ReplaySubject } from 'rxjs';
import { finalize, first, map, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-ip-white-list',
  templateUrl: './sidepanel-ip-white-list.component.html',
  styleUrls: ['./sidepanel-ip-white-list.component.scss'],
  providers: [{ provide: IpWhiteListService, useClass: IpWhiteListService, deps: [HttpClient] }]
})
export class SidepanelIpWhiteListComponent extends SidepanelCrudBase<any> implements OnInit, OnDestroy {
  public headers: TableHeader[] = [
    {
      name: 'Name',
      overflow: true,
      gridColSize: '7fr',
      gridColMin: '152px',
      sort: 'name',
      class: '-top'
    },
    {
      name: 'IP/RANGE',
      overflow: true,
      gridColSize: '10fr',
      class: '-top'
    },
    {
      name: '',
      gridColSize: '90px',
      class: '-end -top'
    }
  ];

  public enabled = false;
  private _data: IpWhiteList[] = [];
  public visibleData: IpWhiteList[] = [];

  set data(data: IpWhiteList[]) {
    this._data = data;
    this.visibleData = Array.from(this._data);
  }

  get data(): IpWhiteList[] {
    return this._data;
  }

  public currentIP: string;

  public editModel: IpWhiteList = undefined;
  // require for hid inputs when id == undefined
  public editIndex = -1;

  ipValidator = (control: AbstractControl) => {
    if (!control.value) {
      return { message: 'IP address(es) required' };
    }
    const ipsRows = String(control.value)
      .split(/[,\n]/)
      .map((ip) => ip.trim())
      .filter((ip) => !!ip);

    let hasV4 = false;
    let hasV6 = false;

    for (let i = 0; i < ipsRows.length; i++) {
      const ipRange = ipsRows[i].split('-');

      hasV4 = hasV4 || ipRange.some((ip) => rangeCheck.isV4(ip));
      hasV6 = hasV6 || ipRange.some((ip) => rangeCheck.isV6(ip));
      if (hasV4 && hasV6) {
        return { ipValidator: { message: 'IPv4 and IPv6 addresses cannot combine' } };
      }

      const invalid =
        ipRange.length > 2 ||
        ipRange.map((ip) => ip.trim()).some((ip) => !((rangeCheck.isV4(ip) && ip.includes('.')) || rangeCheck.isV6(ip)));
      if (invalid) {
        return { ipValidator: { message: 'This is not a valid IP address/range' } };
      }
    }
    return null;
  };
  public editRange = new FormControl('', { validators: [this.ipValidator] });
  public editName = new FormControl('', [Validators.required, Validators.minLength(2)]);
  public IPWhiteListState = IpWhiteListState;

  public searchInput = '';
  public sort: SortEvent = { direction: 'asc', column: 'name' };

  public loadingSave = false;

  private completeEdit = new EventEmitter<IpWhiteList>(true);
  public readonly alertType = MbsPopupType;

  constructor(
    private listingService: IpWhiteListService,
    private toastService: ToastService,
    private modal: ModalService,
    public cdk: DataChangeWatcherService,
    private i18Next: I18NextPipe,
    public sidepanel: SidepanelService
  ) {
    super(cdk);

    sidepanel.onOpen.pipe(untilDestroyed(this)).subscribe((event) => {
      // todo replace to compare by sidepanel-id in 4.8
      if (event.name === this.constructor.name) {
        this.listingService.get();
      }
    });
  }

  ngOnInit(): void {
    this.listingService.ips$.pipe(untilDestroyed(this)).subscribe((d) => {
      this.updateData(d);
      this.hold();
    });
    this.listingService.enabled$.pipe(untilDestroyed(this)).subscribe((s) => {
      this.enabled = s;
      this.hold();
      this.loadingData = false;
    });
    this.listingService.get();

    this.listingService
      .getCurrentIp()
      .pipe(untilDestroyed(this))
      .subscribe((ip) => (this.currentIP = ip));

    this.attachObject('ipWhiteListKey', () => ({ data: cloneDeep(this.data), enabled: this.enabled }));
  }

  ngOnDestroy(): void {
    this.destroy();
    // empty
  }

  fetchData(): void {
    this.listingService.get();
  }

  updateData(source: IpWhiteList[]): void {
    let newData: IpWhiteList[] = this.data.filter((d) => d.State > IpWhiteListState.None);
    source.forEach((d) => {
      const find = newData.find((newD) => d.id === newD.id);
      if (!find) {
        newData.push(d);
      }
    });

    if (newData.length === 0) {
      newData = source;
    }
    this.data = newData;
    this.handleFilterData();
    this.handleSort(this.sort);
  }

  handleFilterData(): void {
    if (this.searchInput) {
      this.visibleData = this.data.filter((d) => {
        return (
          d.description.toLocaleLowerCase().includes(this.searchInput.toLocaleLowerCase()) ||
          (d.ipRanges ? d.ipRanges.includes(this.searchInput) : false) ||
          (d.ips ? d.ips.includes(this.searchInput) : false)
        );
      });
    }
  }

  // Add new row
  handleAddNew(): void {
    this.handleEdit(new IpWhiteList(), 0);
    this.editModel.State = IpWhiteListState.Add;
    this.data = [this.editModel].concat(this.data);
  }

  // Remove added row
  handleEditCancel(): void {
    this.data.splice(0, 1);
    this.data = Array.from(this.data);
    this.resetEditMode();
  }

  // Edit exists
  handleEdit(model: IpWhiteList, index: number): void {
    this.editModel = Object.assign({}, model);
    this.editModel.State = IpWhiteListState.Change;
    this.editRange.patchValue((this.editModel.ips || []).concat(this.editModel.ipRanges || []).join('\n'));
    this.editName.patchValue(this.editModel.description);
    this.editIndex = index;
  }

  // Delete exists
  handleDeleteRange(model: IpWhiteList): void {
    const data = Array.from(this._data);
    const remove = () => {
      // remove instantly
      if (model.id === null || model.id === undefined) {
        const index = data.findIndex((item) => isEqual(item, model));
        data.splice(index, 1);
      } else {
        model.State = IpWhiteListState.Delete;
      }

      // disable settings for prevent lose access
      if (data.filter((ip) => ip.State !== IpWhiteListState.Delete).length === 0) {
        this.enabled = false;
      }

      this.data = Array.from(data);
    };

    const otherIps = data.filter((ip) => ip !== model && ip.State !== IpWhiteListState.Delete);
    if (this.enabled && this.containsInStoreIPs(this.currentIP, [model]) && !this.containsInStoreIPs(this.currentIP, otherIps)) {
      this.showConfirmModal(IpWhiteListState.Delete)
        .then(() => remove())
        .catch(noop);
    } else {
      remove();
    }
  }

  /**
   * Save exists edit row
   * @param {IpWhiteList} model copy row ip white list
   * @param {number} index index into source data
   */
  handleEditSave(model: IpWhiteList, index: number): void {
    if (this.editRange.invalid || this.editName.invalid) {
      FormsUtil.triggerValidation(this.editRange);
      FormsUtil.triggerValidation(this.editName);
      return;
    }
    if (model) {
      const updateModel = (newModel: Partial<IpWhiteList>) => {
        model.ips = newModel.ips;
        model.ipRanges = newModel.ipRanges;
        model.description = newModel.description;
        this.data[index] = model;
        this.data = Array.from(this.data);
        this.completeEdit.emit(model);
        this.resetEditMode();
      };
      const newRanges = this.parseRanges(this.editRange.value);
      newRanges.description = this.editName.value;

      const excludeState = ({ State, ...m }) => m;
      const otherIps = this.data.filter(
        (ip) => !isEqual(excludeState(ip as any), excludeState(model as any)) && ip.State !== IpWhiteListState.Delete
      );
      if (
        this.enabled &&
        this.containsInStoreIPs(this.currentIP, [model]) &&
        !(this.containsInStoreIPs(this.currentIP, [newRanges]) || this.containsInStoreIPs(this.currentIP, otherIps))
      ) {
        this.showConfirmModal(IpWhiteListState.Change)
          .then(() => updateModel(newRanges))
          .catch(noop);
      } else {
        updateModel(newRanges);
      }
    } else {
      this.resetEditMode();
    }
  }

  isValidSidepanel(): boolean {
    return this.editIndex < 0 || (this.editRange.valid && this.editName.valid);
  }

  // Save all sidepanel data
  handleSave(): Observable<boolean> {
    if (!this.isValidSidepanel()) {
      FormsUtil.triggerValidation(this.editName);
      FormsUtil.triggerValidation(this.editRange);
      return of(false);
    }

    let preventSave$ = of<IpWhiteList>(null);
    if (this.editIndex > -1) {
      const preventSubject$ = new ReplaySubject<IpWhiteList>(1);
      this.completeEdit.subscribe((e) => preventSubject$.next(e));
      preventSave$ = preventSubject$.asObservable();
    }

    this.handleEditSave(this.visibleData[this.editIndex], this.editIndex);

    this.loadingSave = true;
    return preventSave$.pipe(
      first(),
      switchMap(() => this.listingService.update({ restrictionOn: this.enabled, whiteLists: this.data })),
      tap(() => {
        this.data = this.data.filter((d) => d.State != IpWhiteListState.Delete);
        this.data.forEach((d) => (d.State = IpWhiteListState.None));
        this.toastService.success(this.i18Next.transform('app:notifications.successfullySaved'));
        this.save.emit();
        this.hold();
      }),
      map(() => true),
      finalize(() => (this.loadingSave = false))
    );
  }

  // Reset sidepanel data
  handleClose(): Observable<boolean> {
    return super.handleClose().pipe(
      tap((result) => {
        if (result) {
          this.data = [];
          this.fetchData();
          this.searchInput = '';
          this.resetEditMode();
        }
      })
    );
  }

  handleSort(sort: SortEvent): void {
    this.sort = sort;
    this.data = this.data.sort((a, b) =>
      sort.column == 'name' ? (sort.direction == 'asc' ? 1 : -1) * a.description.localeCompare(b.description) : 0
    );
  }

  handleDelete(): Observable<boolean> {
    throw Error('NotImplement');
  }

  handleEnableSettings(): void {
    if (this.enabled && !this.containsInStoreIPs(this.currentIP, this.data)) {
      if (this.editIndex > -1) {
        this.handleEditCancel();
      }
      const currentIpModel = new IpWhiteList();
      currentIpModel.State = IpWhiteListState.Add;
      currentIpModel.description = 'Admin';
      currentIpModel.ips = [this.currentIP];
      this.data = [currentIpModel].concat(this.data);
    }
  }

  containsIpInRange(rawIp: string, [start, finish]: string[]): boolean {
    return this.compareIPs(rawIp, start) > -1 && this.compareIPs(rawIp, finish) < 1;
  }

  containsInStoreIPs(rawIp: string, store?: Array<Partial<IpWhiteList>>): boolean {
    const isLocal = this.isLocalIp(rawIp);
    if (!store || store.length === 0) {
      return false;
    }

    return store.some((d) => {
      const find = (d.ips || []).some((ip) => (isLocal && this.isLocalIp(ip)) || this.compareIPs(rawIp, ip) === 0);
      return find || (d.ipRanges || []).some((range) => this.containsIpInRange(rawIp, range.split('-')));
    });
  }

  compareIPs(ip1: string, ip2: string): number {
    const ipv6first = (rangeCheck.isV4(ip1) ? this.ip4ToIp6(ip1) : rangeCheck.displayIP(ip1)).split(':');
    const ipv6second = (rangeCheck.isV4(ip2) ? this.ip4ToIp6(ip2) : rangeCheck.displayIP(ip2)).split(':');
    for (let i = 0; i < 8; i++) {
      const first = parseInt(ipv6first[i], 16);
      const last = parseInt(ipv6second[i], 16);
      if (first < last) {
        return -1;
      } else if (first > last) {
        return 1;
      }
    }
    return 0;
  }

  ip4ToIp6(rawIp: string): string {
    const ipv6Prefix = '0000:0000:0000:0000:0000:FFFF:';
    const nums = rawIp.split('.').map((n) => (+n).toString(16).padStart(2, '0'));
    const ipv4toHex = nums[0] + nums[1] + ':' + nums[2] + nums[3];

    return ipv6Prefix + ipv4toHex;
  }

  isLocalIp(rawIp: string): boolean {
    return ['127.0.0.1', '::1'].includes(rangeCheck.storeIP(rawIp));
  }

  showConfirmModal(operation: IpWhiteListState): Promise<boolean> {
    const settings: ModalSettings = {
      header: {
        title: 'Warning'
      }
    };

    const msg = `This IP whitelist has the current computer IP address.
      You will not be able to access the management console from that
      IP address anymore if you ${operation === IpWhiteListState.Change ? 'change' : 'delete'} it.
      Do you want to proceed?`;

    return this.modal.confirm(settings, msg);
  }

  parseRanges(rawRange: string): Partial<IpWhiteList> {
    const result: { ips: string[]; ipRanges: string[] } = { ipRanges: [], ips: [] };
    const rows = rawRange.split(/[,\n]/);
    for (let i = 0; i < rows.length; i++) {
      const r = rows[i];
      if (r.includes('-')) {
        result.ipRanges.push(r);
      } else {
        result.ips.push(r);
      }
    }

    return result;
  }

  resetEditMode(): void {
    this.editModel = null;
    this.editRange.reset();
    this.editName.reset();
    this.editIndex = -1;
  }
}
