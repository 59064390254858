<h4>{{ 'rmm.module:groupActions.stepConfigureAction.selectAction' | i18next: { format: 'title' } }}</h4>


<div class="row mb-3">
  <div class="col-12">
    <div class="row button-group text-center m-0 mb-1">
      <button class="col btn btn-secondary d-flex install-software-tab-group-button-rounded-left-button justify-content-center align-items-center"
              [class.active]="modeSelected(modeSelectType.usingWinget)"
              (click)="setModeSelect(modeSelectType.usingWinget)">
        {{ 'rmm.module:groupActions.stepConfigureAction.installAndUpdate' | i18next }}
      </button>
      <button class="col btn btn-secondary d-flex install-software-tab-group-button-rounded-right-button justify-content-center align-items-center"
              [class.active]="modeSelected(modeSelectType.usingLink)"
              (click)="setModeSelect(modeSelectType.usingLink)">
        {{ 'rmm.module:groupActions.stepConfigureAction.installByLink' | i18next }}
      </button>
    </div>
  </div>
</div>

<ng-container [ngSwitch]="installationModeFormType.get('modeSelect').value">
  <mbs-install-winget-action *ngSwitchCase="modeSelectType.usingWinget"
                             [enableTitle]="false">
  </mbs-install-winget-action>
  <mbs-install-software *ngSwitchCase="modeSelectType.usingLink"
                        [enableTitle]="false">
  </mbs-install-software>

</ng-container>
