<form [formGroup]="wizardForm">
  <mbs-input-label [label]="'computers.module:addComputerWizard.actionStep.productLabel' | i18next"
                   [labelClasses]="'font-weight-bold'"></mbs-input-label>
  <div class="row">
    <div *ngFor="let data of tilesConfig; let index = index" class="col-4">
      <button (click)="agentTypeControl.patchValue(data.id)"
              [class.-selected]="data.id === agentTypeControl.value"
              class="app-add-computer-wizard_tile">
        <div class="d-flex mx-3 my-3">
          <div class="mr-3">
            <span [class]="data.icon"></span>
          </div>
          <div class="flex-grow-1 d-flex flex-column align-items-start">
            <div class="text-lg font-weight-bold">{{ data.title }}</div>
            <div class="text-left text-sm text-muted">{{ data.description }}</div>
          </div>
        </div>
      </button>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-6">
      <mbs-select
        formControlName="os"
        [showRequiredMark]="false"
        [clearable]="false"
        [labelClasses]="'font-weight-bold'"
        [label]="'computers.module:addComputerWizard.actionStep.platform' | i18next"
        [items]="availableOsTypes"></mbs-select>
    </div>

    <div class="col-6">
      <mbs-select
        formControlName="companyId"
        bindLabel="name"
        bindValue="id"
        [labelClasses]="'font-weight-bold'"
        [label]="'app:common.company' | i18next: {format: 'title'}"
        [items]="companies$ | async">
        <ng-template ng-footer-tmp>
          <ng-container *ngIf="(permissionsEnum.ManageCompanies | can: 'read') && (permissionsEnum.AccessToAllCompanies
           | can: 'read')">
            <mbs-button [isCtrl]="true"
                        (click)="onAddCompanyButtonClick()">{{'computers.module:addNewCompany' | i18next}}</mbs-button>
          </ng-container>
        </ng-template>
      </mbs-select>
    </div>
  </div>

  <div *ngIf="showCompanyUnsupportedAlert && wizardForm.value?.companyId"
       class="row mt-3">
    <div class="col-12">
      <mbs-alert [icon]="true">{{ 'computers.module:addComputerWizard.actionStep.companyUnsupportedAlert' | i18next }}</mbs-alert>
    </div>
  </div>
</form>
