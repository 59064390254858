import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { BrandsModalComponent } from './brands-modal/brands-modal.component';
import { DownloadBuildComponent } from './download-build.component';

@NgModule({
  imports: [CommonModule, MbsUiKitModule, I18NextModule, ReactiveFormsModule, NgbDropdownModule, NgbTooltipModule, AbilityModule],
  declarations: [DownloadBuildComponent, BrandsModalComponent],
  exports: [DownloadBuildComponent]
})
export class DownloadBuildModule {}
