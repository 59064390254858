<div class="row">
  <div class="col-auto">
    <span class="position-relative">
      <span class="ico ico-Mail mbs-feedback-icon"></span>
    </span>
  </div>
  <div class="col">
    <header class="mbs-feedback-header">
      <h5 class="mbs-feedback-title mb-0">
        {{ 'feedback:title' | i18next: { format: 'title' } }}
      </h5>
    </header>
    <div class="mbs-feedback-content text-md pt-2 pb-3">
      {{ 'feedback:text' | i18next }}
    </div>
    <footer class="mbs-feedback-footer">
      <div class="row align-items-center">
        <div class="col-auto">
          <button class="btn btn-white btn-md mbs-feedback-btn mbs-feedback-btn_submit"
                  data-sleek-feedback>
            {{ 'feedback:btn_submit' | i18next: { format: 'title' } }}
          </button>
        </div>
        <div class="col-auto">
          <mbs-button [isCtrl]="true"
                      [type]="'white'"
                      class="mbs-feedback-ctrl mbs-feedback-btn_postpone"
                      [size]="MbsSize.md"
                      (click)="setRemitLateDate()">
            {{ 'feedback:btn_postpone' | i18next }}
          </mbs-button>
        </div>
      </div>
    </footer>
  </div>
  <div class="col-auto">
    <mbs-button [isCtrl]="true"
                [type]="'white'"
                class="mbs-feedback-ctrl mbs-feedback-close"
                [icon]="'ico ico-Close mr-0'"
                [size]="MbsSize.sm"
                (click)="setRemitLateDate()">
    </mbs-button>
  </div>
</div>
