<mbs-table-nav [switcherView]="false"
               [showRefreshButton]="false">

  <ng-template mbsTableFilter="label">
    {{ 'rmm-side-panel:eventTotalTab.events' | i18next }} {{ totalEvents$ | async }}
  </ng-template>

  <ng-template *ngIf="isOnline"
               mbsTableControl="prepend">
    <div class="mbs-table_switcher lh-1">
      <mbs-button-group [toggle]="true">
        <mbs-button [disabled]="false"
                    [active]="false"
                    class="mbs-table_switcher-ctrl"
                    icon="ico ico-ListView"
                    (click)="handleMode()"
                    ngbTooltip="Table view"
                    tooltipClass="-no-arrow">
        </mbs-button>

        <mbs-button [disabled]="false"
                    [active]="true"
                    class="mbs-table_switcher-ctrl"
                    customClasses="cursor-default"
                    icon="ico ico-PieChart"
                    ngbTooltip="Chart view"
                    tooltipClass="-no-arrow">
        </mbs-button>
      </mbs-button-group>
    </div>

  </ng-template>
</mbs-table-nav>

<div *ngIf="(eventCharts$ | async) as eventCharts;else tplNoData"
     class="ph-no-capture row">
  <figure *ngFor="let chart of eventCharts"
          class="col-6 mb-3">
    <canvas baseChart
            [type]="'doughnut'"
            (chartClick)="select($event, chart)"
            [labels]="chart?.data.labels"
            [datasets]="chart?.data.datasets"
            [options]="chart?.options"
            [plugins]="chart?.plugins">
    </canvas>
    <figcaption *ngIf="chart.customLegend"
                class="mt-2">
      <div class="form-row align-items-center">
        <div *ngFor="let legendItem of chart?.data.labels; let i=index"
             class="col-6">
          <mbs-button [isCtrl]="true"
                      [type]="getLegendLabel(chart.data.labels[i])"
                      size="sm"
                      class="w-100"
                      customClasses="d-flex w-100 text-left align-items-baseline"
                      icon="fa fa-circle mr-2"
                      (click)="viewLogByEntryType(chart.data.datasets[0].label, legendItem)"
                      placement="bottom-left"
                      ngbTooltip="{{ legendItem }}: {{ chart.data.datasets[0].data[i] }}"
                      tooltipClass="-no-arrow">
            <span class="text-dark text-xs text-overflow">
              {{ legendItem }}: {{ chart.data.datasets[0].data[i] }}
            </span>
          </mbs-button>
        </div>
      </div>
    </figcaption>
  </figure>
</div>


<ng-template #tplNoData>
  <div class="text-center text-sm">
    {{ 'rmm-side-panel:noDataToDisplay' | i18next }}
  </div>
</ng-template>
