export class B2CredsError extends Error {
  constructor(message) {
    super(message);
    this.name = 'B2CredsError';
  }
}

export class B2DestinationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'B2DestinationError';
  }
}
