<mbs-wizard [title]="wizard.title"
            [showSteps]="false"
            [isOpenSteps]="false"
            [loading]="isLoading"
            [stepsOrientation]="'vertical'"
            [showFooter]="showFooter"
            [nextButton]="{text: 'Continue', type: 'primary', hide: !showContinueButton, disabled: !nextButtonEnabled}"
            [backButton]="{hide: true}"
            [cancelButton]="{
              hide: (wizardType === wizardTypeEnum.TryRmm || currentStep?.title === titleSteps.finalInfo),
              type: 'primary',
              text: ('wizards:skipWizard' | i18next: {format: 'title'})
            }"
            [saveButton]="{hide: !showSaveButton, text: saveButtonText, type: 'success', disabled: !nextButtonEnabled}"
            (next)="handleStepChange($event)"
            (save)="handleExecute()"
            [height]="wizardContentHeight"
            [hideCrossButton]="wizardType === wizardTypeEnum.Onboarding"
            [closeHandler]="wizardType === wizardTypeEnum.Onboarding ? skipOnboardingWizard.bind(this) : null">

  <!-- One step should always to get rid of wizard error -->
  <mbs-wizard-step [title]="titleSteps.startInfo"
                   [valid]="true">
    <mbs-rmm-connect-greeting-step></mbs-rmm-connect-greeting-step>
  </mbs-wizard-step>

  <ng-container *ngIf="wizardType === wizardTypeEnum.TryRmm">
    <mbs-wizard-step *ngIf="selectedAdminList.length !== 1 || currentStepNumber === 2"
                     [valid]="true">
      <mbs-user-select-step (setSelectedAdminList)="setSelectedAdminList($event)"
                            (setProvider)="setProvider($event)"
                            (setNextButtonEnabled)="setNextButtonEnabled($event)">
      </mbs-user-select-step>
    </mbs-wizard-step>

    <mbs-wizard-step [title]="titleSteps.installModeSelection"
                     [valid]="true">
      <mbs-computer-select-step (setSelectedComputerList)="setSelectedComputerList($event)"
                                (setNextButtonEnabled)="setNextButtonEnabled($event)">
      </mbs-computer-select-step>
    </mbs-wizard-step>
  </ng-container>

  <mbs-wizard-step [title]="titleSteps.installation"
                   [valid]="true">
    <mbs-installation-step [wizardType]="wizardType"
                           [autoInstall]="autoInstall"
                           [isCurrent]="currentStep?.title === titleSteps.installation"
                           [selectedAdminList]="selectedAdminList"
                           [selectedComputerList]="selectedComputerList"
                           [buildGenerationLaunched]="buildGenerationLaunched"
                           (setComputerWithInstalledRMM)="setComputerWithInstalledRMM($event)"
                           (forceNextStep)="forceNextStep()">
    </mbs-installation-step>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="titleSteps.finalInfo"
                   [valid]="true">
    <mbs-result-step [wizardType]="wizardType">
    </mbs-result-step>
  </mbs-wizard-step>
</mbs-wizard>
