<h4>{{ 'rmm.module:groupActions.stepSelectAction.title' | i18next: { format: 'title' } }}</h4>

<div *ngIf="!is2FAEnabled">
  <mbs-alert [type]="'danger'"
             [icon]="'fa fa-exclamation-circle'">
    {{ 'rmm.module:groupActions.stepSelectAction.noTwoFAEnabledBanner' | i18next }}
    <mbs-button [isCtrl]="true"
                [size]="'md'"
                (click)="enable2FAredirect()">
      {{ 'rmm.module:groupActions.stepSelectAction.noTwoFAEnabledBannerLink' | i18next }}
    </mbs-button>
  </mbs-alert>
</div>

<form>
  <mbs-form-group class="mbs-form-group"
                  [formGroup]="form">
    <div class="mb-4">
      <mbs-input [attr.data-test-element]="elementSelectors.id.taskNameSelector"
                 [placeholder]="'rmm.module:groupActions.stepSelectAction.taskNamePlaceholder' | i18next"
                 [label]="'rmm.module:groupActions.stepSelectAction.taskNameLabel' | i18next"
                 formControlName="name"
                 [(ngModel)]="taskName">
      </mbs-input>
    </div>

    <div class="mb-3">
      <mbs-select [attr.data-test-element]="elementSelectors.id.osTypeSelector"
                  [label]="'rmm.module:groupActions.stepSelectAction.selectOSLabel' | i18next"
                  formControlName="osType"
                  [clearable]="false"
                  [searchable]="false"
                  [disabled]="isEditMode"
                  [items]="osTypes"
                  [value]="selectedOS">
      </mbs-select>
    </div>

    <div class="mb-3">
      <mbs-select [attr.data-test-element]="elementSelectors.id.actionSelector"
                  [label]="'rmm.module:groupActions.stepSelectAction.actionLabel' | i18next"
                  formControlName="action"
                  [clearable]="false"
                  [searchable]="false"
                  [disabled]="isEditMode"
                  [items]="actions"
                  [value]="(command$ | async)">
      </mbs-select>
    </div>

    <span class="text-sm">
      {{ description }}
    </span>
    <div *ngIf="isUpdatePolicy"
         class="text-sm">
      {{ 'rmm.module:groupActions.stepSelectAction.description.updatePolicyNote' | i18next }}
    </div>
  </mbs-form-group>
</form>
