import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { State } from './event-total.reducer';
import { ActionPrefix, EventChart } from './model';

export const loadEventCharts = createAction(`${ActionPrefix} Load EventCharts`, props<{ state: State }>());

export const addAllEventCharts = createAction(`${ActionPrefix} Add All EventCharts`, props<{ eventCharts: EventChart[] }>());

export const addEventChart = createAction(`${ActionPrefix} Add EventChart`, props<{ eventChart: EventChart }>());

export const upsertEventChart = createAction(`${ActionPrefix} Upsert EventChart`, props<{ eventChart: EventChart }>());

export const addEventCharts = createAction(`${ActionPrefix} Add EventCharts`, props<{ eventCharts: EventChart[] }>());

export const upsertEventCharts = createAction(`${ActionPrefix} Upsert EventCharts`, props<{ eventCharts: EventChart[] }>());

export const updateEventChart = createAction(`${ActionPrefix} Update EventChart`, props<{ eventChart: Update<EventChart> }>());

export const updateEventCharts = createAction(`${ActionPrefix} Update EventCharts`, props<{ eventCharts: Update<EventChart>[] }>());

export const deleteEventChart = createAction(`${ActionPrefix} Delete EventChart`, props<{ id: string }>());

export const deleteEventCharts = createAction(`${ActionPrefix} Delete EventCharts`, props<{ ids: string[] }>());

export const clearEventCharts = createAction(`${ActionPrefix} Clear EventCharts`);
