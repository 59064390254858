/* eslint-disable @typescript-eslint/unbound-method */
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { SignalRService } from '@modules/signal-r/signal-r.service';
import { ModalComponent } from 'mbs-ui-kit';
@Component({
  selector: 'mbs-add-device-modal',
  templateUrl: './add-device-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeviceModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(public authService: AuthService, private signalR: SignalRService) {}

  ngOnInit(): void {
    this.signalR.authorizedZoneConnection.on('OnDeviceAdded', () => {
      this.baseModal.save(true);
    });

    this.signalR.authorizedZoneConnection.on('OnDeviceUpdated', () => {
      this.baseModal.save(true);
    });
  }
}
