import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OverdueAfterSuccessPeriod } from '@modules/wizards/models/schedule-models';

export type overdueSettingsParams = {
  planIds?: { planId: string; computerHid: string }[];
  filter?: {
    search: string;
    companyId: string;
    computerHid: string;
    statusFilter: 'All' | 'IssueOnly' | 'Running';
  };
  overdueSettings: {
    enabled: boolean;
    amount: number;
    period: OverdueAfterSuccessPeriod;
  };
};

@Injectable({
  providedIn: 'root'
})
export class OverdueService {
  constructor(private http: HttpClient) {}

  overdueSettings(params: overdueSettingsParams, isSelected: boolean) {
    return this.http.put(`api/monitoring/${isSelected ? 'group' : 'bulk'}/overdueSettings`, params);
  }
}
