<mbs-modal [isCustomModal]="true"
           [title]="'tfa:forceMSPAppModal.title' | i18next"
           #modal>
  <ng-container modal-body>
    <p class="font-weight-semibold">{{ 'tfa:forceMSPAppModal.tiredToTyping' | i18next }}</p>

    <p>
      {{ 'tfa:forceMSPAppModal.enable' | i18next }} <br/>
      <span class="font-weight-bold">{{ 'tfa:forceMSPAppModal.oneClickConfirmation' | i18next }}</span>
    </p>

    <mbs-checkbox [label]="'app:common.doNotShowAgain' | i18next" [formControl]="confirmationCheckbox"></mbs-checkbox>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="onForceMSPAppButtonClick()">
      {{ 'tfa:forceMSPAppModal.confirmButton' | i18next }}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="closeModal()">
      {{ 'buttons:close' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
