import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { GroupTask } from '@modules/group-tasks/store/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep, memoize } from 'lodash/fp';
import { MbsPopupType } from 'mbs-ui-kit';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith, take, tap } from 'rxjs/operators';
import { GAActions, GASelectors } from '../../../../store/group-action';
import EditGroupTaskUtility from '../../../../utility/edit-group-task-utility';
import { Software } from '../install-system-update/install-system-update.component';

interface SortOptions {
  column: string;
  direction: string;
}

function getComputerNames(data: Software) {
  return data.computers.map((computer) => computer.computerName).join(', ');
}

@UntilDestroy()
@Component({
  selector: 'mbs-uninstall-software',
  templateUrl: './uninstall-software.component.html',
  styles: []
})
export class UninstallSoftwareComponent implements OnInit {
  public loading$: Observable<boolean>;
  public data$: Observable<Software[]>;
  public selected: Software[] = [];
  public total = 0;
  public selectedCount = 0;
  public computersCount = 0;

  public selectedItems: Software[] = [];

  public dataToShow$: Observable<any>;
  // variable to disable additional click. select() event happens each time you're clicking on the table
  private isSortAction = false;
  public sort$ = new BehaviorSubject<SortOptions>({
    column: 'name',
    direction: 'none'
  });

  public searchForm = new UntypedFormGroup({
    searchValue: new FormControl('')
  });
  isLoading = false;

  public readonly alertType = MbsPopupType;
  public readonly headers = [
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderName'),
      sort: 'name',
      overflow: true,
      gridColSize: '40fr'
    },
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderPublisher'),
      sort: 'vendor',
      overflow: true,
      gridColSize: '40fr'
    },
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderNumberOfPC'),
      sort: 'computerCount',
      gridColSize: '20fr',
      overflow: true
    }
  ];

  constructor(private store: Store, private i18n: I18NextPipe, private editGroupTaskUtility: EditGroupTaskUtility) {
    this.data$ = this.editGroupTaskUtility.getAvailableSoftwareToRemoveStream().pipe(
      tap((data) => (this.total = data.length)),
      untilDestroyed(this)
    );

    this.dataToShow$ = combineLatest([this.searchForm.get('searchValue').valueChanges.pipe(startWith('')), this.data$, this.sort$]).pipe(
      map(([search, data, sort]) => {
        const filteredData = search
          ? data.filter((item) => [item?.name, item?.packageID, item?.vendor].join(',').toLowerCase().includes(search.toLowerCase()))
          : data;
        return this.handleSorting(filteredData, sort);
      }),
      untilDestroyed(this)
    );
  }

  handleSelected(softwares: Software[]) {
    this.selectedCount = softwares.length;
    this.computersCount = softwares.reduce((ac, next) => (ac += next.computerCount), 0);
    const osType$ = this.store.select(GASelectors.selectGActionOsType);

    combineLatest([of(softwares), osType$])
      .pipe(untilDestroyed(this))
      .subscribe(([softwares, osType]) => {
        if (!softwares.length) {
          this.store.dispatch(GAActions.setGActionParameters({ parameters: null }));
          return;
        }

        this.store.dispatch(GAActions.setGActionParameters({ parameters: this.getGroupActionParams(softwares) }));
      });
    this.selected = cloneDeep(softwares);
  }

  ngOnInit() {
    this.loadGroupTaskData();
  }

  getComputerNames = memoize(getComputerNames);

  handleSorting(softwareList: Software[], sort: SortOptions): Software[] {
    switch (sort.direction) {
      case 'asc':
        return softwareList.slice().sort((a, b) => (a[sort.column] > b[sort.column] ? 1 : -1));
      case 'desc':
        return softwareList.slice().sort((a, b) => (a[sort.column] < b[sort.column] ? 1 : -1));
      default:
        return softwareList.slice();
    }
  }

  handleSort(event) {
    this.isSortAction = true;
    this.sort$.next(event);
  }

  handleClear(e) {
    this.searchForm.setValue({ searchValue: '' });
  }

  handleSearch(e) {
    // Method should exists due search component
    // Ignore search button click method of the used component since form valueChanges event used
  }

  select(softwares) {
    if (this.isSortAction) {
      this.isSortAction = false;
    } else {
      this.handleSelected(softwares);
    }
  }

  updateFilters(event) {
    // console.warn(event);
  }

  private loadGroupTaskData(): void {
    combineLatest([this.dataToShow$, this.editGroupTaskUtility.getUninstallSoftwareStream()])
      .pipe(take(1))
      .subscribe(([dataToShow, dataFromTask]) => {
        const applicationToBeRemoved = dataToShow.find(
          (app) => app.identifyingNumber === dataFromTask.identifyingNumber && app.name === dataFromTask.productdisplayname
        );
        if (applicationToBeRemoved) this.selectedItems = [applicationToBeRemoved];
      });
  }

  getGroupActionParams(software: Software[]): Partial<GroupTask> {
    // Only one software per action untill rmm20 as minimum version
    const timeout = 60 * 60;
    const parameters = [
      { name: 'identifyingNumber', value: String(software[0].identifyingNumber) },
      { name: 'productdisplayname', value: String(software[0].name) },
      { name: 'timeout', value: String(timeout) }
    ];
    return {
      disabled: false,
      type: 'pluginCommand',
      pluginCommandId: 'SoftwareCmd',
      parameters: JSON.stringify({ asyncID: getGuid(), id: 'UninstallProduct', parameters: parameters }),
      description: this.getDescription(software),
      confidentialOutputData: false,
      pluginActiveCommand: true,
      pluginPsCommand: true,
      enableOffline: false,
      eventType: 0,
      scriptGuid: null,
      softwareSourceGuid: null,
      allComputers: false,
      computers: [],
      computerTags: [],
      runType: 0,
      scheduleType: 0,
      scheduleData: null
    };
  }

  getDescription(data: Software[]): string {
    const translationKey = 'rmm.module:groupActions.actionDescription.';
    const descriptionPart1 = this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'uninstallSoftwareInfo', {});
    const descriptionPart2 = this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'uninstallSoftwareDescription', {
      value: data[0].name
    });
    return descriptionPart1 + '\r\n' + descriptionPart2;
  }
}
