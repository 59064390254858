<div class="mbs-form-group mb-0">
  <div [formGroup]="eventForm"
       class="form-row">
    <div class="col-6"
    [class.col-12]="isMacOrLinuxOS">
      <mbs-datepicker formControlName="date"
                      [autoClose]="true"></mbs-datepicker>
    </div>

    <div class="col-6">
      <mbs-select *ngIf="!isMacOrLinuxOS && (isGrid$ | async)"
                  [items]="eventLogTypesList"
                  [clearable]="false"
                  [compareWith]="compareType"
                  formControlName="logType"
                  placeholder="Select log type"></mbs-select>
    </div>
  </div>
</div>
