<div class="row mb-3">
  <div class="col-12">
    <div class="row button-group text-center m-0 mb-1">
      <button class="col btn btn-secondary d-flex powershell-tab-group-button-rounded-left-button justify-content-center align-items-center"
              [class.active]="modeSelected(modeSelectType.fromScriptLibrary)"
              (click)="setModeSelect(modeSelectType.fromScriptLibrary)">
        {{ 'rmm.module:groupActions.stepConfigureAction.scriptFromLibraryLabel' | i18next }}
      </button>
      <button class="col btn btn-secondary d-flex justify-content-center align-items-center"
              [ngClass]="isWindows ? 'rounded-0' : 'powershell-tab-group-button-rounded-right-button'"
              [class.active]="modeSelected(modeSelectType.fromScriptFile)"
              (click)="setModeSelect(modeSelectType.fromScriptFile)">
        {{ 'rmm.module:groupActions.stepConfigureAction.scriptFromFileLabel' | i18next }}
      </button>
      <button *ngIf="isWindows"
              class="col btn btn-secondary d-flex powershell-tab-group-button-rounded-right-button justify-content-center align-items-center"
              [class.active]="modeSelected(modeSelectType.fromPowerShell)"
              [disabled]="!isWindows"
              (click)="setModeSelect(modeSelectType.fromPowerShell)">
        {{ 'rmm.module:groupActions.stepConfigureAction.terminal' | i18next }}
      </button>
    </div>
  </div>
</div>


<ng-container [ngSwitch]="executeScriptFormType.get('modeSelect').value">
  <ng-container *ngSwitchCase="modeSelectType.fromScriptLibrary">
    <ng-container *ngTemplateOutlet='fromScriptLibrary'>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="modeSelectType.fromScriptFile">
    <ng-container *ngTemplateOutlet='fromScriptFile'>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="modeSelectType.fromPowerShell">
    <ng-container *ngTemplateOutlet='shouldUseNewTerminalVersion ? newTerminal : fromPowerShell'>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault></ng-container>
</ng-container>


<ng-template #fromScriptLibrary>
  <mbs-form-group [formGroup]="scriptLibraryForm">
    <div class="mb-2">
      <mbs-input-label class="font-weight-semibold"
                       [label]="'rmm-side-panel:powerShellTab.category' | i18next">
      </mbs-input-label>
      <mbs-select [items]="collections"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  formControlName="collection">
      </mbs-select>
    </div>
    <mbs-input-label class="font-weight-semibold"
                     [label]="'rmm-side-panel:powerShellTab.script' | i18next">
    </mbs-input-label>
    <mbs-select [items]="filteredEntries"
                bindLabel="name"
                [clearable]="false"
                [searchable]="false"
                formControlName="entry">
    </mbs-select>
  </mbs-form-group>
  <ng-container *ngIf="entry?.description">
    <div class="row mb-3">
      <div class="col-md-12 text-wrap text-break">
        <div class="font-weight-semibold mb-2">
          {{ 'rmm.module:groupActions.stepConfigureAction.scriptDescription' | i18next }}
        </div>
        <div>{{ entry.description }}</div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngTemplateOutlet="scriptExecutionBtns"></ng-container>
</ng-template>


<ng-template #fromScriptFile>
  <div class="row">
    <div class="col-12 mb-3">
      <mbs-drop-file [width]="'auto'"
                     [height]="'100px'"
                     [labelText]="'rmm.module:groupActions.stepConfigureAction.uploadScript' | i18next"
                     [returnBase64Allways]="true"
                     (fileLoad)="fileLoadHandler($event)"
                     [accept]="fileExtension">
      </mbs-drop-file>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="scriptExecutionBtns"></ng-container>
</ng-template>

<ng-template #scriptLibraryBodyModal>
  <ng-container
                *ngIf="(executeScriptFormType.get('modeSelect').value !== modeSelectType.fromScriptLibrary && scriptFromFile) || (currentScriptFromLibrary && entry?.accessLevel !== 'Common'); else notAvailable">
    <div class="row">
      <div class="col-md-12">
        <div
             class="text-sm text-monospace p-3 text-break pre-wrap border bg-secondary p-3 overflow-y-auto script-body-fixed-size">
          {{ executeScriptFormType.get('modeSelect').value === modeSelectType.fromScriptLibrary ?
          currentScriptFromLibrary : scriptFromFile }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #notAvailable>
    {{ 'rmm-side-panel:powerShellTab.scriptViewNotAvailable' | i18next }}
  </ng-template>
</ng-template>


<ng-template #scriptExecutionBtns>
  <div class="d-flex justify-content-between align-items-center">
    <mbs-button [isCtrl]="true"
                (click)="showResult()"
                [disabled]="!lastCommandResult">
      {{ 'rmm-side-panel:powerShellTab.lastResult' | i18next }}
    </mbs-button>
    <div>
      <mbs-button class="mr-2"
                  [hidden]="!canViewScript"
                  (click)="viewScript()">
        {{ 'rmm-side-panel:powerShellTab.viewScript' | i18next }}
      </mbs-button>
      <mbs-button type="primary"
                  (click)="executeScript()"
                  [disabled]="!canExecute">
        {{ 'buttons:exec' | i18next }}
      </mbs-button>
    </div>
  </div>
</ng-template>


<ng-template #scriptResult>
  <ng-container *ngIf="!isLoading && lastCommandResult; else loadingTemplate">
    <div class="row">
      <div class="col-md-12">
        <mbs-alert [type]="lastCommandType"
                   [icon]="true"
                   class="mb-3">
          <span>
            {{ 'rmm-side-panel:powerShellTab.' + (lastCommandType === 'success' ? 'success' : 'warning') | i18next }}
          </span>
        </mbs-alert>
        <div>
          <b>{{ 'rmm-side-panel:powerShellTab.results' | i18next }}</b>
        </div>
        <div
             class="text-sm text-monospace text-break pre-wrap border bg-secondary p-3 overflow-y-auto script-body-result-fixed-size">
          {{ lastCommandResult }}</div>
      </div>
    </div>

    <div class="modal-footer mb-0 px-0">
      <mbs-button (click)="exportResult()"
                  [type]="'primary'">
        {{ 'rmm-side-panel:powerShellTab.downloadReport' | i18next }}
      </mbs-button>
      <mbs-button (click)="closeModal()"
                  data-dismiss="modal"
                  type="secondary">
        {{ 'buttons:close' | i18next }}
      </mbs-button>
    </div>
  </ng-container>

  <ng-template #loadingTemplate>
    <div class="row text-center">
      <div class="col-12">
        <span class="loader loader-lg"></span>
      </div>
      <div class="col-12">
        {{ 'rmm-side-panel:powerShellTab.waitingForExecution' | i18next }}
      </div>
    </div>
  </ng-template>
</ng-template>


<ng-template #fromPowerShell>
  <div class="mbs-form-group">
    <mbs-terminal [warning]="warning"
                  [autoFit]="true">
    </mbs-terminal>
  </div>
</ng-template>


<ng-template #newTerminal>

  <!-- used color styling for prism.js colors -->
  <div class="d-flex justify-content-between align-items-center"
       style="background-color: #f5f6f9;">
    <span class="ml-3">
      {{ 'rmm-side-panel:powerShellTab.remoteWindowsPowershell' | i18next }}
    </span>
    <mbs-button class="border-0"
                style="background-color: #f5f6f9;"
                icon="ico ico-external-link-square"
                size="md"
                [disabled]="openInModalDisabled"
                (click)="openTerminalInModalTab(newTerminalModal)">
    </mbs-button>
  </div>
  <hr class="m-0">

  <form [formGroup]="newTerminalForm">
    <mbs-terminal-new formControlName="terminalValue"
                      [hid]="hid"
                      [isModal]="false"
                      (enableOpenInModal)="enableOpenInModal($event)"
                      [shouldFocus]="shouldFocusTerminal"
                      [shouldInit]="shouldInitTerminal">
    </mbs-terminal-new>
  </form>
</ng-template>

<ng-template #newTerminalModal>
  <form [formGroup]="newTerminalForm">
    <mbs-terminal-new formControlName="terminalValue"
                      [hid]="hid"
                      [shouldInit]="false"
                      [shouldFocus]="shouldFocusTerminal"
                      height="78vh">
    </mbs-terminal-new>
  </form>
</ng-template>
