import { AgentType } from "@models/Computer";

// group action (for many computers)
export interface PayloadBulkForceUpdate {
  commandType: string;
  search: string;
  companyId: string;
  hidden: boolean;
  unsupported: boolean;
  appIds: AgentType[] | string[];
  params: {
    updateBuilds: UpdateBuild[];
  };
}

export interface UpdateBuild {
  appId: string;
  versionType: string;
}

export interface BuildAvailableVersion {
  public: {
    version: string;
  };
  sandbox: {
    version: string;
  };
  currentVersion?: string;
}

// for one computer
export interface PayloadForceUpdate {
  commandType: string;
  agentCommands: AgentCommand[];
}

export interface AgentCommand {
  computerHid: string;
  agentType: string;
  params: {
    updateBuilds: UpdateBuild[];
  };
}

export interface ComputerAvailableVersions {
  ownerId: string;
  hid: string;
  name: string;
  displayName: string;
  applications: AgentAvailableBuildVersions[];
}

export interface AgentAvailableBuildVersions {
  applicationId: AgentType;
  currentVersion: string;
  builds: {
    public: {
      version: string;
    };
    sandbox?: {
      version: string;
    };
  };
  online: boolean;
  forceUpdateSupporting: boolean;
}

export enum ForceUpdateAgentType {
  NotNecessary = 0,
  Public = 1,
  Sandbox = 2
}
