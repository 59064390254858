<h4 *ngIf="enableTitle">{{ 'rmm.module:groupActions.stepConfigureAction.installSoftTitle' | i18next: { format: 'title' }
  }}</h4>

<mbs-alert [type]="alertType.warning"
           class="mb-2">
  {{ 'rmm.module:groupActions.stepConfigureAction.installSoftBanner' | i18next }}
</mbs-alert>

<mbs-form-group [formGroup]="installForm">
  <div class="mb-3">
    <mbs-input [autocomplete]="'off'"
               [class]="'text-base'"
               [label]="'rmm.module:groupActions.stepConfigureAction.downloadLink' | i18next"
               formControlName="url"></mbs-input>
  </div>
  <mbs-input [autocomplete]="'off'"
             [class]="'text-base'"
             [label]="'rmm.module:groupActions.stepConfigureAction.installKeys' | i18next"
             formControlName="arguments"></mbs-input>

</mbs-form-group>
