import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyPageModalsModule } from '@modules/empty-page/modals/empty-page-modals.module';
import { EmptyPageComponent } from '@modules/empty-page/empty-page.component';
import { RouterModule } from '@angular/router';
import { MbsUiKitModule } from 'mbs-ui-kit';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    EmptyPageModalsModule,
    MbsUiKitModule
  ],
  declarations: [EmptyPageComponent],
  exports: [EmptyPageComponent]
})
export class EmptyPageModule {}
