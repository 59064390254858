import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Computer from '@models/Computer';
import {
  ComputerFromGroupTaskComputers,
  GroupTask,
  GroupTaskOsTypes,
  GroupTaskSummary,
  RunTypes,
  TaskFromGroupTask,
  TaskStates
} from '@modules/group-tasks/store/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComputerTagsFacade } from '@root/mbs-ui/src/app/shared/facades/computer.tags.facade';
import { Tag } from '@shared/models/tag';
import { CsvTemplate, generateCsvTable } from '@utils/cvs';
import { mediumDateWithTime } from '@utils/date';
import { I18NextPipe } from 'angular-i18next';
import * as saveAs from 'file-saver';
import { MbsPopupType, MbsSize, ModalService, WizardStepComponent } from 'mbs-ui-kit';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, filter, map, Observable, startWith, Subject, switchMap, tap } from 'rxjs';
import * as GroupTaskActions from '../../../group-tasks/store/actions';
import * as GroupTaskSelectors from '../../../group-tasks/store/selectors';

import { Store } from '@ngrx/store';
import EditGroupTaskUtility from '../../utility/edit-group-task-utility';

type ComputerMessage = string | { i18: string; opts?: any };
type FilterType = 'done' | 'all' | 'warn' | 'error';

type ComputerFilterCount = {
  all: number;
  done: number;
  warn: number;
  error: number;
};

@UntilDestroy()
@Component({
  selector: 'mbs-show-result',
  templateUrl: './show-result.component.html',
  styleUrls: ['./show-result.component.scss']
})
export class ShowResultComponent implements OnDestroy {
  public filteredComputers: Computer[] = [];
  @Input() resultInfo: any = {};
  @Output() closeWizardOnRedirect = new EventEmitter();
  @ViewChild('resultBox', { static: true, read: TemplateRef }) messageBox: TemplateRef<any>;

  cssClasses = {
    error: 'ico ico-error-circle text-danger',
    warn: 'ico ico-OverdueSettings text-warning',
    success: 'ico ico-success text-success'
  };

  counts$: Observable<any>;
  computers$: Observable<ComputerFromGroupTaskComputers[]>;

  filterEmitter$ = new Subject<FilterType>();
  getResult$ = new Subject<any>();

  public showComputerByResult = 'all';
  resultOutput = '';

  public mediumDateWithTime = mediumDateWithTime;
  public readonly alertType = MbsPopupType;

  showTerminalOutput: boolean;
  groupTask$: Observable<GroupTask> = null;
  action: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  counts: ComputerFilterCount = null;
  computers: ComputerFromGroupTaskComputers[];
  groupTaskGuid: string;
  groupTaskGuidInStore: string;
  tasks: TaskFromGroupTask[];
  refreshTaskInterval;

  public groupTaskSummary: GroupTaskSummary;
  public computerIcon = 'fa-windows';
  private inRunNowMode = true;

  constructor(
    private modalService: ModalService,
    @Inject(WizardStepComponent) protected parent: WizardStepComponent,
    protected injector: Injector,
    private i18n: I18NextPipe,
    private router: Router,
    private editGroupTaskUtility: EditGroupTaskUtility,
    private tagsFacade: ComputerTagsFacade,
    private store: Store
  ) {
    this.groupTask$ = this.store.select(GroupTaskSelectors.selectRegisteredGroupTask).pipe(
      tap((groupTask) => {
        switch (groupTask?.osType) {
          case GroupTaskOsTypes.Linux:
            this.computerIcon = 'fa-linux';
            break;
          case GroupTaskOsTypes.Mac:
            this.computerIcon = 'fa-apple';
            break;
          case GroupTaskOsTypes.Windows:
            this.computerIcon = 'fa-windows';
            break;
          default:
            this.computerIcon = 'fa-server';
            break;
        }
        return groupTask;
      }),
      untilDestroyed(this)
    );
    this.setupGroupTask();

    this.store
      .select(GroupTaskSelectors.selectGroupTaskToEditGuid)
      .pipe(untilDestroyed(this))
      .subscribe((result) => (this.groupTaskGuidInStore = result));

    this.computers$ = this.filterEmitter$.pipe(
      startWith('all'),
      switchMap((type: FilterType) =>
        combineLatest([
          this.store.select(GroupTaskSelectors.selectComputersFromNew),
          this.store.select(GroupTaskSelectors.selectExpectedComputersFromNew)
        ]).pipe(
          map(([computers, expected]) => computers.concat(expected).filter((computer) => !computer?.disabled && !computer?.deleted && !computer?.excludedByTaskTags)),
          map((computers) => {
            computers.sort((a, b) => (a.computerName > b.computerName ? -1 : 1));
            computers.sort((a, b) => (a.online >= b.online ? -1 : 1));

            this.setupFilterCount(computers);
            if (type === 'all') return computers;

            return computers.filter((computer) => computer.online && this.isComputerStateInFilter(computer, type));
          })
        )
      ),
      untilDestroyed(this)
    );
    this.computers$.pipe(untilDestroyed(this)).subscribe((computers) => (this.computers = computers));
  }

  isComputerStateInFilter(computer: ComputerFromGroupTaskComputers, state: FilterType): boolean {
    const computerState = TaskStates[computer.state];
    switch (Number(computerState)) {
      case TaskStates.Failure:
      case TaskStates.Aborted:
      case TaskStates.Timeout:
        return state === 'error';
      case TaskStates.Success:
        return state === 'done';
      case TaskStates.Processing:
      case TaskStates.NotStarted:
      case TaskStates.None:
      default:
        return false;
    }
  }

  setupFilterCount(computers: ComputerFromGroupTaskComputers[]): void {
    this.counts = { all: computers.length, done: 0, warn: 0, error: 0 };
    computers.forEach((computer) => {
      const computerState = TaskStates[computer.state];
      switch (Number(computerState)) {
        case TaskStates.Failure:
        case TaskStates.Aborted:
        case TaskStates.Timeout:
          this.counts.error++;
          break;
        case TaskStates.Success:
          this.counts.done++;
          break;
        case TaskStates.Processing:
        case TaskStates.NotStarted:
        case TaskStates.None:
          break;
        default:
          break;
      }
    });
  }

  onDeactivate() {
    this.clearGroupTaskUpdateInterval();
  }

  OnInit() {}

  ngOnDestroy(): void {
    queueMicrotask(() => {
      this.groupTaskGuid = null;
      this.clearGroupTaskUpdateInterval();
      this.store.dispatch(GroupTaskActions.clearGroupTaskGuidToEdit());
      this.store.dispatch(GroupTaskActions.clearRegisteredGroupTaskData());
    });
  }

  clearGroupTaskUpdateInterval(): void {
    if (this.refreshTaskInterval) {
      clearInterval(this.refreshTaskInterval);
      this.refreshTaskInterval = null;
    }
  }

  startGroupTaskUpdateInterval() {
    if (!this.refreshTaskInterval && this.inRunNowMode)
      this.refreshTaskInterval = setInterval(() => {
        if (this.groupTaskGuid) this.store.dispatch(GroupTaskActions.loadGroupTask({ guid: this.groupTaskGuid }));
      }, 8000);
  }

  private setupGroupTask(): void {
    this.store
      .select(GroupTaskSelectors.selectRegisteredGroupTask)
      .pipe(
        filter(() => !!this.refreshTaskInterval),
        untilDestroyed(this)
      )
      .subscribe((groupTask) => {
        if (groupTask) {
          this.groupTaskGuid = groupTask.groupTaskGuid;
          this.tasks = groupTask.tasks;
          this.inRunNowMode = groupTask.runType === RunTypes.immediately;
          this.action.next(this.editGroupTaskUtility.parseCommandTypeFromGroupTask(groupTask));
          this.groupTaskSummary = this.editGroupTaskUtility.getGroupTaskSummary(groupTask);
        }
      });

    this.startGroupTaskUpdateInterval();
  }

  public filterResults(type: FilterType): void {
    if ((type === this.showComputerByResult, type !== 'all')) {
      this.showComputerByResult = 'all';
    }
    this.showComputerByResult = type;
    this.filterEmitter$.next(type);
  }

  showComputerOutput(computer: ComputerFromGroupTaskComputers) {
    this.resultOutput = this.getLastReportMessage(computer);

    this.modalService
      .open(
        {
          size: MbsSize.md,
          header: {
            showExpandedCross: true,
            title:
              this.i18n.transform(`rmm.module:groupActions.stepResult.titles.${this.action.getValue()}`, { format: 'title' }) +
              this.i18n.transform('rmm.module:groupActions.stepResult.titles.output', { format: 'title' }) +
              Computer.getComputerName(computer)
          },
          footer: {
            show: false,
            okButton: { text: this.i18n.transform('buttons:done', { format: 'capitalize' }), type: 'secondary' },
            cancelButton: { show: false }
          },
          collapsing: true
        },
        this.messageBox
      )
      .catch((e) => e);
  }

  getMessage(message: ComputerMessage) {
    if (!message) {
      return null;
    }
    return typeof message !== 'string' && message.i18 ? this.i18n.transform(message.i18, message.opts) : (message as string);
  }

  getComputerCss(computer) {
    const state = TaskStates[computer.state];
    switch (Number(state)) {
      case TaskStates.Failure:
      case TaskStates.Aborted:
      case TaskStates.Timeout:
        return this.cssClasses.error;
      case TaskStates.Success:
        return this.cssClasses.success;
      case TaskStates.Processing:
      case TaskStates.NotStarted:
      case TaskStates.None:
      default:
        return 'loader loader-primary';
    }
  }

  trackByComputers(index: number, comp: Computer): any {
    return comp.hid;
  }

  exportToCSV(): void {
    const rows: CsvTemplate['rows'] = this.computers
      .filter((computer) => !computer?.disabled && !computer?.deleted)
      .map((computer: ComputerFromGroupTaskComputers) => ({
        Computer: Computer.getComputerName(computer),
        Begin: computer.dateStart ? moment(computer.dateStart).format('DD/MM/YYYY h:mm:ss a') : '-',
        End: computer.dateEnd ? moment(computer.dateEnd).format('DD/MM/YYYY h:mm:ss a') : '-',
        Status: `${computer.state}`,
        Output: `"${this.getLastReportMessage(computer)}"`
      }));

    const template: CsvTemplate = {
      cols: ['Computer', 'Begin', 'End', 'Status', 'Output'],
      rows
    };
    const blob = new Blob([generateCsvTable(template)], { type: 'text/csv' });
    saveAs(blob, `Result.csv`);
  }

  // utility
  getLastReportMessage(computer: ComputerFromGroupTaskComputers): string {
    if (!computer.online) return this.i18n.transform('rmm-group-task.module:groupTaskSidePanel.resultTab.computerIsOfflineMsg');

    if (computer.dateStart && moment(computer.dateStart).isBefore(moment(new Date()).hour()))
      return this.i18n.transform('rmm-group-task.module:groupTaskSidePanel.resultTab.noDataMsg');

    const result = computer?.result;
    const error = computer?.error;
    const message = computer?.message;

    return result || error || message || this.i18n.transform('rmm-group-task.module:groupTaskSidePanel.resultTab.noDataMsg');
  }

  openGroupTaskLink(): void {
    this.router.navigate(['AP/RMMGroupTaskAction'], { queryParams: { groupTaskGuid: this.groupTaskGuid } });
    this.closeWizardOnRedirect.emit();
  }

  public getTagById(id: number): Observable<Tag> {
    return this.tagsFacade.tagById$(id).pipe(untilDestroyed(this));
  }
}
