import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { TemporaryDataValue, TemporaryInstanceStepValue } from '../../../models/temporary-instance-models';
import { RemoteManagementWizardsService } from '../../../services/remote-management-wizards.service';
import { FormPipeOperators, StepBase } from '../../StepBase.class';

const TemporaryInstanceStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => TemporaryInstanceStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-temporary-instance-step',
  templateUrl: './temporary-instance-step.component.html',
  providers: [TemporaryInstanceStepValueAccessor]
})
export class TemporaryInstanceStepComponent extends StepBase<TemporaryInstanceStepValue> {
  @Input() temporaryData: TemporaryDataValue = {
    accounts: [],
    regions: [],
    zones: [],
    types: [],
    subnetItems: [],
    securityGroups: [],
    amiItems: []
  };

  constructor(public mainService: RemoteManagementWizardsService) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this), debounceTime(200)];
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      useTemporary: new FormControl(false),
      account: new FormControl('', Validators.required),
      region: new FormControl(''),
      availabilityZones: new FormControl(''),
      instanceType: new FormControl(''),
      subnet: new FormControl(''),
      securityGroup: new FormControl(''),
      ami: new FormControl('')
    });

    this.initFormEvents();
  }

  updateForm(value: TemporaryInstanceStepValue): void {
    this.stepForm.reset(value);

    this.useTemporaryChangeHandler(value.useTemporary);
  }

  useTemporaryChangeHandler(event): void {
    for (const key in this.stepForm.getRawValue()) {
      if (key && key !== 'useTemporary') {
        if (event) this.stepForm.get(key).enable();
        else this.stepForm.get(key).disable();
      }
    }
  }
}
