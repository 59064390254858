import { I18NextPipe } from 'angular-i18next';
import { InstallationMode } from '@components/add-computer/add-computer.constants';

export const getTilesConfig = (i18N: I18NextPipe) => ([
  {
    id: InstallationMode.Manual,
    title: i18N.transform('computers.module:addComputerWizard.readManualStep.manualInstallation.title'),
    icon: 'fa fa-desktop'
  },
  {
    id: InstallationMode.Bulk,
    title: i18N.transform('computers.module:addComputerWizard.readManualStep.bulkInstallation.title'),
    icon: 'fa fa-sitemap'
  }
])
