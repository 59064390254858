<mbs-modal [title]="'Set Overdue Options'">
  <ng-container modal-body>
    <p>
      {{ 'overdue:overdue_title' | i18next: { count: selectedComputers ? selectedComputers.selectedPlansCount || 0 : 0 } }}
    </p>
    <div class="mbs-form-group">
      <mbs-radio [(ngModel)]="noSuccessfulMoreThan"
                 name="noSuccessfulMoreThan"
                 [disabled]="!selectedComputers || !selectedComputers.selectedPlansCount"
                 [id]="'no-successful-more-than-radio-1'"
                 [label]="'overdue:did_not_start' | i18next"
                 [value]="false"></mbs-radio>
    </div>
    <div class="mbs-form-group">
      <div class="form-row align-items-center">
        <div class="col-auto">
          <mbs-radio [(ngModel)]="noSuccessfulMoreThan"
                     name="noSuccessfulMoreThan"
                     [disabled]="!selectedComputers || !selectedComputers.selectedPlansCount"
                     [id]="'no-successful-more-than-radio-2'"
                     [label]="'overdue:no_successful_more_than' | i18next"
                     [value]="true"></mbs-radio>
        </div>
        <div class="col">
          <div class="form-row align-items-center">
            <div class="col-3">
              <mbs-number [(ngModel)]="amount"
                          [disabled]="!noSuccessfulMoreThan || !selectedComputers || !selectedComputers.selectedPlansCount"
                          min="1"></mbs-number>
            </div>
            <div class="col-4">
              <mbs-select [(ngModel)]="period"
                          [appendTo]="'.modal-content'"
                          [bindValue]="'value'"
                          [bindLabel]="'label'"
                          [clearable]="false"
                          [disabled]="!noSuccessfulMoreThan || !selectedComputers || !selectedComputers.selectedPlansCount"
                          [hideSelected]="true"
                          [items]="OverduePeriod"
                          [id]="'overdue-period-select'"
                          [searchable]="false"></mbs-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mbs-alert *ngIf='!selectedComputers || !selectedComputers.selectedPlansCount'
               [type]="alertType.danger"
               [icon]="true"
               class="mb-0">
      No plans were selected
    </mbs-alert>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button type="primary"
                [disabled]="!selectedComputers || !selectedComputers.selectedPlansCount"
                [loading]="loading"
                (click)="confirmClickHandler()">Apply</mbs-button>
    <mbs-button type="secondary"
                [loading]="loading"
                (click)="cancelClickHandler()">Cancel</mbs-button>
  </ng-container>
</mbs-modal>
