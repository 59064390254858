import { StepType } from '@modules/wizards/onboarding/services/steps/steps.types';

export enum StepId {
  ConfigureBackup = 'configureBackup',
  StorageAccount = 'storageAccount',
  SignIn = 'signIn',
  WhatToBackup = 'whatToBackup',
  SelectUsers = 'selectUsers',
  Summary = 'summary'
}
export const StepsConfig: StepType<StepId>[] = [
  {
    id: StepId.ConfigureBackup,
    children: [
      {
        id: StepId.StorageAccount,
        parentId: StepId.ConfigureBackup,
        prev: null,
        next: StepId.SignIn
      },
      {
        id: StepId.SignIn,
        parentId: StepId.ConfigureBackup,
        prev: StepId.StorageAccount,
        next: StepId.WhatToBackup,
        customTransitionForward: true
      },
      {
        id: StepId.WhatToBackup,
        parentId: StepId.ConfigureBackup,
        prev: StepId.SignIn,
        next: StepId.SelectUsers
      },
      {
        id: StepId.SelectUsers,
        parentId: StepId.ConfigureBackup,
        prev: StepId.WhatToBackup,
        next: StepId.Summary
      },
      {
        id: StepId.Summary,
        parentId: StepId.ConfigureBackup,
        prev: StepId.SelectUsers,
        next: null
      }
    ]
  }
];
