import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MonthNamesEnum } from '@models/MonthNames.enum';
import { Period, PeriodDisplay } from '@models/Period.enum';
import { PlanMode } from '@models/PlanTypes.enum';
import { ImmutabilityConfirmModalComponent } from '@modules/immutability-modals/immutability-confirm-modal/immutability-confirm-modal.component';
import { FromTime, getDefaultGFSPolicySettings, RetentionPolicyStepValue } from '@modules/wizards/models/retention-policy-models';
import { IncrementalData } from '@modules/wizards/models/schedule-models';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { isKeepBackupForCorrect } from '@modules/wizards/steps/retention-policy-step/retention-policy-step.utils';
import { StepBase } from '@modules/wizards/steps/StepBase.class';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import { EnumHelper, FormsUtil, MbsSize, ModalService, ModalSettings } from 'mbs-ui-kit';
import * as moment from 'moment';
import { combineLatest, noop } from 'rxjs';

const RetentionPolicyStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => RetentionPolicyStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-retention-policy-step',
  templateUrl: './retention-policy-step.component.html',
  providers: [RetentionPolicyStepValueAccessor],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetentionPolicyStepComponent extends StepBase<RetentionPolicyStepValue> implements OnInit {
  @Input() minPeriod = 0;
  @Input() isHybrid = false;
  @Input() isFullBackup = false;
  @Input() isMonthly = false;
  @Input() showImmutability = false;
  @Input() showIntelligentRetention = false;
  @Input() showGFS = false;
  @Input() isFFIMode = false;
  @Input() incrementalScheduleData: IncrementalData = null;

  public readonly MbsSize = MbsSize;
  private readonly backupDate = 'backup date';
  public readonly elementsSelector = {
    name: {
      minPeriodAlert: 'min-period-alert',
      notSupportedAlert: 'not-supported-alert',
      notFullAlert: 'not-full-alert',
      nbfBlock: 'nbf-block',
      showGFSBlock: 'show-gfs-block',
      notNBFBlock: 'not-nbf-block',
      notSQLNotFileBlock: 'not-sql-not-file-block',
      isSQLorFileBlock: 'is-sql-or-file-block',
      fromTimeSelectedBlock: 'from-time-selected-block',
      alwaysKeepLastVersionBlock: 'always-keep-last-version-block',
      daysAgoAlert: 'days-ago-alert',
      notLinuxBlock: 'not-linux-block',
      fileTypeNotHybrid: 'file-type-not-hybrid',
      fileTypeNotHybridNotLinux: 'file-type-not-hybrid-not-linux',
      deleteIfLocallyDeletedAlert: 'delete-if-locally-deleted-alert',
      cbfHybridBlock: 'cbf-hybrid-block',
      hybridNotFileBlock: 'hybrid-not-file-block',
      hybridFileOrSQLBlock: 'hybrid-file-or-sql-block',
      hybridFromTimeSelectedBlock: 'hybrid-from-time-selected-block',
      hybridFromTimeSelectedBlockLabel: 'hybrid-always-keep-last-version-block-label',
      hybridAlwaysKeepLastVersionBlock: 'hybrid-always-keep-last-version-block',
      hybridNotLinuxBlock: 'hybrid-not-linux-block'
    }
  };

  public periodEnumSelectArray = EnumHelper.EnumToSelectIndexesArray(Period, PeriodDisplay);
  public monthNames = EnumHelper.EnumToSelectIndexesArray(MonthNamesEnum);
  public fromTimeSelectedItems = [
    { value: 0, label: 'modification date' },
    { value: 1, label: this.backupDate }
  ];

  public needShowAlert = false;
  public confirmNotFullBackup = false;
  public correctlyVersionForImmutability = false;
  private deleteVersionsOlderThanCountControl: AbstractControl;
  private deleteVersionsOlderThanPeriodControl: AbstractControl;

  get isShowAlert(): boolean {
    const GFSControls = (this.stepForm.get('GFSPolicySettings') as any).controls;
    const week = GFSControls.weekly && GFSControls.weekly.controls.enabled.value;
    return (
      GFSControls.enabled.value &&
      ((this.isFullBackup && this.isMonthly && week) ||
        (!this.isFullBackup &&
          (week ||
            (GFSControls.monthly && GFSControls.monthly.controls.enabled.value) ||
            (GFSControls.yearly && GFSControls.yearly.controls.enabled.value))))
    );
  }

  get isShowWarning(): boolean {
    return (
      this.stepForm &&
      !this.stepForm.get('RetentionUseDefaultSettings').value &&
      this.stepForm.get('deleteVersionsOlderThan').value &&
      this.stepForm.get('fromTimeSelected').value === FromTime['modification date'] &&
      (!this.isBackupPlan || !this.stepForm.get('alwaysKeepLastVersion').value)
    );
  }

  get daysCount(): number {
    const period = this.stepForm.get('deleteVersionsOlderThanPeriod').value;
    const count = this.stepForm.get('deleteVersionsOlderThanCount').value;
    const daysInPeriods = {
      [Period.day]: 1,
      [Period.week]: 7,
      [Period.month]: 30,
      [Period.year]: 365
    };

    return count * (daysInPeriods[period] ?? 1);
  }

  get needShowMinPeriodAlert(): boolean {
    if (!this.minPeriod || !this.isNBF) return false;

    if (this.stepForm.get('RetentionUseDefaultSettings').value) {
      return false;
    }

    const count = this.stepForm.get('deleteVersionsOlderThanCount').value;
    const period = this.stepForm.get('deleteVersionsOlderThanPeriod').value;

    return (
      period !== Period.year &&
      (period === Period.day ? count < this.minPeriod : period === Period.week ? count * 7 < this.minPeriod : count * 30 < this.minPeriod)
    );
  }

  public keepBackupFFIAlert: string;
  public deleteFilesLabelAlert = this.i18nPipe.transform('wizards:delete_files_label_alert', { returnObjects: true });
  public gfsLabelDesc = this.i18nPipe.transform('wizards:gfs_label_desc', { returnObjects: true });
  public retentionPolicyMinPeriodAlert = this.i18nPipe.transform(
    this.isFFIMode ? 'wizards:retention_policy_min_period_alert_ffi' : 'wizards:retention_policy_min_period_alert',
    {
      returnObjects: true,
      minPeriod: this.minPeriod
    }
  );

  private isShowedModal = false;

  @ViewChild('IntelligentRetentionPayAttention', { static: true, read: TemplateRef }) IntelligentRetentionPayAttention: TemplateRef<any>;
  @ViewChild('confirmNotFullModal', { static: true, read: TemplateRef }) confirmNotFullModal: TemplateRef<any>;

  constructor(
    public ability: AbilityService,
    private cdr: ChangeDetectorRef,
    public mainService: RemoteManagementWizardsService,
    public modalService: ModalService,
    public i18nPipe: I18NextPipe
  ) {
    super(mainService);
    const version = mainService.backupVersion ? mainService.backupVersion.split('.').join('') : '';
    this.correctlyVersionForImmutability = this.isRDMode || (version && +version.substring(0, 3) >= 720);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      RetentionUseDefaultSettings: new FormControl(!this.isFFIMode),
      keepNumberOfVersions: new FormControl(false),
      RetentionNumberOfVersions: new FormControl(1, Validators.min(1)),
      deleteVersionsOlderThan: new FormControl(false),
      deleteVersionsOlderThanCount: new FormControl(1, [Validators.min(1)]),
      deleteVersionsOlderThanPeriod: new FormControl(),
      fromTimeSelected: new FormControl(0),
      alwaysKeepLastVersion: new FormControl(false),
      delayPurgeForEnabled: new FormControl(false),
      delayPurgeFor: new FormControl(1, Validators.min(1)),
      delayPurgeForSelect: new FormControl(0),
      deleteIfLocallyDeleted: new FormControl(false),
      deleteAfter: new FormControl(1, Validators.min(1)),
      doNotShowWarning: new FormControl(false),
      intelligentRetention: new FormControl(false),
      GFSPolicySettings: new UntypedFormGroup({
        enabled: new FormControl(false),
        enabledImmutability: new FormControl(false),
        weekly: new UntypedFormGroup({
          enabled: new FormControl(false),
          storePeriod: new FormControl(1, [Validators.min(1), this.weeklyMoreMonthlyValidator.bind(this)])
        }),
        monthly: new UntypedFormGroup({
          enabled: new FormControl(false),
          storePeriod: new FormControl(1, [Validators.min(1), this.monthlyMoreMonthlyValidator.bind(this)])
        }),
        yearly: new UntypedFormGroup({
          enabled: new FormControl(false),
          storePeriod: new FormControl(1, [Validators.min(1)]),
          takeBackupFromMonth: new FormControl(1)
        })
      }),
      HybridRetentionPolicy: new UntypedFormGroup({
        RetentionUseDefaultSettings: new FormControl(true),
        keepNumberOfVersions: new FormControl(false),
        RetentionNumberOfVersions: new FormControl(1, Validators.min(1)),
        deleteVersionsOlderThan: new FormControl(false),
        deleteVersionsOlderThanCount: new FormControl(1),
        deleteVersionsOlderThanPeriod: new FormControl(),
        fromTimeSelected: new FormControl(0),
        alwaysKeepLastVersion: new FormControl(false),
        delayPurgeForEnabled: new FormControl(false),
        delayPurgeFor: new FormControl(1, Validators.min(1)),
        delayPurgeForSelect: new FormControl(0)
      })
    });

    this.initFormEvents();

    if (this.isLinux && this.isNBF) this.stepForm.get('GFSPolicySettings')?.disable();
  }

  protected initFormEvents(): void {
    this.deleteVersionsOlderThanCountControl = this.stepForm.get('deleteVersionsOlderThanCount');
    this.deleteVersionsOlderThanPeriodControl = this.stepForm.get('deleteVersionsOlderThanPeriod');

    combineLatest([
      this.deleteVersionsOlderThanCountControl.valueChanges,
      this.deleteVersionsOlderThanPeriodControl.valueChanges
    ]).subscribe({
      next: () => this.keepBackupForValidator()
    });

    super.initFormEvents();
  }

  onStepFormChange(value: RetentionPolicyStepValue): void {
    const invalidKeepBackupFor = this.isFFIMode && this.keepBackupFFIAlert;

    if (this.stepForm.dirty || this.stepForm.touched) {
      const data = value.GFSPolicySettings;
      const gfsEnabled = data && data.enabled;
      const oldValid = this.value.valid;
      this.needShowAlert =
        gfsEnabled &&
        (!data.weekly || !data.weekly.enabled) &&
        (!data.monthly || !data.monthly.enabled) &&
        (!data.yearly || !data.yearly.enabled);
      this.value = {
        ...value,
        valid:
          this.stepForm.valid &&
          !this.needShowAlert &&
          !invalidKeepBackupFor &&
          (!this.isNBF || this.isFullBackup || this.isFFIMode || this.confirmNotFullBackup)
      };

      if (oldValid !== this.value?.valid) {
        this.isValid.emit(this.value?.valid);
      }

      return;
    }

    if (this.value) this.value.valid = this.value.valid && !invalidKeepBackupFor;
  }

  /**
   * Depends of Schedule Step data
   * @return {ValidationErrors | null}
   */
  keepBackupForValidator(): void {
    if (!this.isFFIMode || !this.incrementalScheduleData) {
      return void delete this.keepBackupFFIAlert;
    }

    const selected: { count: number; period: Period } = {
      count: this.deleteVersionsOlderThanCountControl.value,
      period: this.deleteVersionsOlderThanPeriodControl.value
    };

    const isKeepBackupForResult = isKeepBackupForCorrect(this.incrementalScheduleData, selected);

    if (!isKeepBackupForResult.error) {
      this.deleteVersionsOlderThanCountControl.setErrors(null);
      this.deleteVersionsOlderThanPeriodControl.setErrors(null);
      return void delete this.keepBackupFFIAlert;
    }

    this.keepBackupFFIAlert = this.i18nPipe.transform('wizards:keep_backup_ffi_error_message', {
      count: isKeepBackupForResult.count,
      period: this.periodEnumSelectArray[isKeepBackupForResult.period].label.toLocaleString().toLowerCase().split('(')[0]
    });

    this.deleteVersionsOlderThanCountControl.setErrors({ keepBackupPeriodInvalid: true });
    this.deleteVersionsOlderThanPeriodControl.setErrors({ keepBackupPeriodInvalid: true });
  }

  monthlyMoreMonthlyValidator(control: AbstractControl): ValidationErrors | null {
    if (this.stepForm && control.value) {
      const yearly = this.stepForm.get('GFSPolicySettings').get('yearly').value;
      const invalidYearly = yearly && yearly.enabled && yearly.storePeriod < control.value / 12;
      return invalidYearly ? { moreThanYearly: true } : null;
    }

    return null;
  }

  weeklyMoreMonthlyValidator(control: AbstractControl): ValidationErrors | null {
    if (this.stepForm && control.value) {
      const GFS = this.stepForm.get('GFSPolicySettings');
      const monthly = GFS.get('monthly').value;
      const yearly = GFS.get('yearly').value;
      let invalidMonthly = false;
      let invalidYearly = false;
      const weeks = moment().add(control.value, 'weeks');
      if (monthly && monthly.enabled) {
        const months = moment().add(monthly.storePeriod, 'months');
        invalidMonthly = months.isBefore(weeks);
      }
      if (yearly && yearly.enabled) {
        const years = moment().add(yearly.storePeriod, 'years');
        invalidYearly = years.isBefore(weeks);
      }
      return invalidMonthly ? { moreThanMonthly: true } : invalidYearly ? { moreThanYearly: true } : null;
    }

    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let isFFIMode = this.isFFIMode;
    let needResetFFI = false;

    if (this.stepForm) {
      if (this.isNBF) {
        if (changes.isFullBackup || changes.isFFIMode) {
          this.confirmNotFullBackup = !!this.isFullBackup || !!this.isFFIMode;
          queueMicrotask(() => {
            this.stepForm.markAsDirty();
            this.stepForm.updateValueAndValidity();
            this.cdr.detectChanges();
          });
        }

        if (
          changes.showImmutability ||
          (!this.showImmutability && (this.stepForm.get('GFSPolicySettings') as any)?.controls?.enabledImmutability?.enabled)
        ) {
          const gfsControl = this.stepForm.get('GFSPolicySettings') as UntypedFormGroup;

          if (this.showImmutability && gfsControl.value.enabled) {
            gfsControl.get('enabledImmutability').enable();
          } else {
            gfsControl.get('enabledImmutability').disable();
          }
        }

        if (changes.showIntelligentRetention?.currentValue === false) {
          this.stepForm.get('intelligentRetention').setValue(true);
        }
      } else {
        if (changes.isHybrid && (!this.isRDMode || !isNil(changes.isHybrid.currentValue))) {
          this.updateDataFromHybridFlag();
        }
      }
    }

    if (changes.minPeriod || changes.incrementalScheduleData || changes.isFFIMode) {
      this.retentionPolicyMinPeriodAlert = this.i18nPipe.transform(
        this.isFFIMode ? 'wizards:retention_policy_min_period_alert_ffi' : 'wizards:retention_policy_min_period_alert',
        {
          returnObjects: true,
          minPeriod: this.minPeriod
        }
      );
    }

    if (this.stepForm && changes.isFFIMode) {
      if (changes.isFFIMode?.currentValue) {
        isFFIMode = true;
        this.turnFFIMode(true);
      } else {
        needResetFFI = true;
        this.stepForm.get('GFSPolicySettings').reset(getDefaultGFSPolicySettings());
      }
    }

    if (needResetFFI || changes.incrementalScheduleData) {
      setTimeout(() => {
        this.keepBackupForPeriodChangeHandler();
        this.turnFFIMode(isFFIMode);
      });
    }
  }

  updateDataFromHybridFlag(): void {
    if (!this.isRDMode && this.isHybrid) {
      return void queueMicrotask(() => {
        this.changeStateDeleteIfLocallyDeleted();
        this.updateHybridFields(this.isHybrid);
      });
    }

    this.changeStateDeleteIfLocallyDeleted();
    this.updateHybridFields(this.isHybrid);
  }

  turnFFIMode(on: boolean) {
    if (on) {
      this.stepForm.get('RetentionUseDefaultSettings').setValue(false);
      this.stepForm.get('GFSPolicySettings').get('enabled').setValue(false);
      this.enableGFSChangeHandler(false);
      this.doNotPurgeChangeHandler(false);
    }

    if (this.isNBF === null) return;

    this.stepForm.get('deleteVersionsOlderThanCount').markAsTouched();
    this.stepForm.get('deleteVersionsOlderThanPeriod').markAsTouched();
    this.stepForm.markAsDirty();
    this.stepForm.updateValueAndValidity();
  }

  updateForm(value: RetentionPolicyStepValue): void {
    if (this.isRDMode && value.HybridRetentionPolicy && !value.HybridRetentionPolicy.RetentionUseDefaultSettings) {
      delete value.HybridRetentionPolicy;
      this.importedPlanChanged = true;
    }

    this.stepForm.reset(value);
    this.isValid.emit(this.value.valid);

    if (!this.isNBF) {
      this.retentionUseDefaultSettingsChangeHandler(value.RetentionUseDefaultSettings);

      if (this.isBackupPlan) {
        this.fromTimeSelectedChangeHandler(value.fromTimeSelected);
        this.deleteIfLocallyDeletedChangeHandler(value.deleteIfLocallyDeleted);
      }

      this.updateHybridFields(
        this.isBackupPlan && this.isHybrid && !(value.HybridRetentionPolicy === null || value.HybridRetentionPolicy === undefined)
      );
    } else {
      this.doNotPurgeChangeHandler(value.RetentionUseDefaultSettings);
      this.enableGFSChangeHandler(value.GFSPolicySettings && value.GFSPolicySettings.enabled);
    }

    this.updateHybridFields(this.isHybrid && !(value.HybridRetentionPolicy === null || value.HybridRetentionPolicy === undefined));

    // Temporary only for old version of Retention Policy step MBS-21380
    {
      if (this.stepForm.get('intelligentRetention').value) {
        this.stepForm.get('intelligentRetention').disable();
      }
    }
  }

  forceValid(data: any = null): void {
    const GFS: any = this.stepForm.get('GFSPolicySettings');
    const week = GFS && GFS.controls.weekly && GFS.controls.weekly.controls.storePeriod && GFS.controls.weekly.controls.storePeriod.invalid;
    const month =
      GFS && GFS.controls.monthly && GFS.controls.monthly.controls.storePeriod && GFS.controls.monthly.controls.storePeriod.invalid;

    if (this.isFFIMode && this.stepForm.get('deleteVersionsOlderThanCount').invalid) {
      return void FormsUtil.triggerValidation(this.stepForm);
    }

    if (!this.isShowedModal) {
      if (!week && !month && this.needShowAlert) {
        this.isShowedModal = true;
        this.showGFSAlert(data.isNext, data.isSave);

        return;
      } else {
        FormsUtil.triggerValidation(this.stepForm);
        this.resetValidStateForValidFields();
      }

      if (this.isNBF && !this.isFullBackup && !this.isFFIMode && !this.confirmNotFullBackup && (data.isNext || data.isSave)) {
        this.isShowedModal = true;
        this.showConfirmNotFull(data.isNext, data.isSave);
      }
    }
  }

  keepBackupForPeriodChangeHandler(): void {
    this.stepForm.get('deleteVersionsOlderThanCount').updateValueAndValidity();
  }

  monthlyStorePeriodChangeHandler(): void {
    const weekly = this.stepForm.get('GFSPolicySettings').get('weekly');

    if (weekly && weekly.value && weekly.value.enabled) {
      weekly.get('storePeriod').updateValueAndValidity();
    }
  }

  yearlyStorePeriodChangeHandler(): void {
    const weekly = this.stepForm.get('GFSPolicySettings').get('weekly');
    const monthly = this.stepForm.get('GFSPolicySettings').get('monthly');

    if (weekly && weekly.value && weekly.value.enabled) {
      weekly.get('storePeriod').updateValueAndValidity();
    }
    if (monthly && monthly.value && monthly.value.enabled) {
      monthly.get('storePeriod').updateValueAndValidity();
    }
  }

  changeStateDeleteIfLocallyDeleted(): void {
    const action = this.isHybrid ? 'disable' : 'enable';

    ['deleteIfLocallyDeleted', 'deleteAfter', 'doNotShowWarning'].forEach((key) => this.stepForm.get(key)[action]());
  }

  updateHybridFields(isHybrid: boolean): void {
    const hybridFields = this.stepForm.get('HybridRetentionPolicy');

    if (isHybrid) {
      hybridFields.enable();
      this.setHybridDefaultValue(hybridFields);
    } else hybridFields.disable();

    this.retentionUseDefaultSettingsChangeHandler(!!hybridFields.get('RetentionUseDefaultSettings').value, true);

    this.fromTimeSelectedChangeHandler(hybridFields.get('fromTimeSelected').value, true);
  }

  setHybridDefaultValue(hybridFields): void {
    const keys = Object.keys(hybridFields.value);

    keys.forEach((key) => {
      const control = hybridFields.get(key);

      if (control.value === null || control.value === undefined) {
        if (key === 'fromTimeSelected' || key === 'delayPurgeForSelect') control.setValue(0);
        else if (key === 'deleteVersionsOlderThanCount') control.setValue(1);
        else if (key === 'deleteVersionsOlderThanPeriod') control.setValue(3);
        else if (key === 'delayPurgeFor' || key === 'RetentionNumberOfVersions') control.setValue(3);
        else control.setValue(false);
      }
    });
  }

  doNotPurgeChangeHandler(value: boolean): void {
    const action = value === false ? 'enable' : 'disable';

    if (this.isNBF && value === false) this.confirmNotFullBackup = value || this.isFullBackup || this.isFFIMode;

    ['deleteVersionsOlderThanCount', 'deleteVersionsOlderThanPeriod'].forEach((key) => this.stepForm.get(key)[action]());
  }

  retentionUseDefaultSettingsChangeHandler(event: boolean, isHybrid = false): void {
    const formGroup = isHybrid ? this.stepForm.get('HybridRetentionPolicy') : this.stepForm;
    const keepNumberOfVersions = formGroup.get('keepNumberOfVersions');
    const deleteVersionsOlderThan = formGroup.get('deleteVersionsOlderThan');
    const delayPurgeForEnabled = formGroup.get('delayPurgeForEnabled');
    const needEnable = isHybrid ? event : !event;

    if (needEnable) {
      keepNumberOfVersions.enable();
      deleteVersionsOlderThan.enable();

      if (this.isBackupPlan) delayPurgeForEnabled.enable();
    } else {
      keepNumberOfVersions.disable();
      deleteVersionsOlderThan.disable();

      if (this.isBackupPlan) delayPurgeForEnabled.disable();
    }

    this.keepNumberOfVersionsChangeHandler(needEnable && !!keepNumberOfVersions.value, isHybrid);
    this.deleteVersionsOlderThanChangeHandler(needEnable && !!deleteVersionsOlderThan.value, isHybrid);

    if (this.isBackupPlan) this.delayPurgeForEnabledChangeHandler(needEnable && !!delayPurgeForEnabled.value, isHybrid);
  }

  keepNumberOfVersionsChangeHandler(event: boolean, isHybrid = false): void {
    let keepNumberOfVersions = null;
    let RetentionNumberOfVersions = null;
    let deleteVersionsOlderThan = null;
    let delayPurgeForEnabled = null;

    if (!isHybrid) {
      keepNumberOfVersions = this.stepForm.get('keepNumberOfVersions');
      RetentionNumberOfVersions = this.stepForm.get('RetentionNumberOfVersions');
      deleteVersionsOlderThan = this.stepForm.get('deleteVersionsOlderThan');
      delayPurgeForEnabled = this.stepForm.get('delayPurgeForEnabled');
    } else {
      const hybridFields = this.stepForm.get('HybridRetentionPolicy');
      keepNumberOfVersions = hybridFields.get('keepNumberOfVersions');
      RetentionNumberOfVersions = hybridFields.get('RetentionNumberOfVersions');
      deleteVersionsOlderThan = hybridFields.get('deleteVersionsOlderThan');
      delayPurgeForEnabled = hybridFields.get('delayPurgeForEnabled');
    }

    if (event && keepNumberOfVersions.enabled) {
      RetentionNumberOfVersions.enable();
      delayPurgeForEnabled.enable();
      this.delayPurgeForEnabledChangeHandler(delayPurgeForEnabled.value, isHybrid);
    } else {
      RetentionNumberOfVersions.disable();

      if (!deleteVersionsOlderThan.value) {
        delayPurgeForEnabled.disable();
        this.delayPurgeForEnabledChangeHandler(event, isHybrid);
      }
    }
  }

  deleteVersionsOlderThanChangeHandler(event: boolean, isHybrid = false): void {
    let deleteVersionsOlderThan = null;
    let deleteVersionsOlderThanCount = null;
    let deleteVersionsOlderThanPeriod = null;
    let fromTimeSelected = null;
    let alwaysKeepLastVersion = null;
    let delayPurgeForEnabled = null;
    let keepNumberOfVersions = null;

    if (!isHybrid) {
      deleteVersionsOlderThan = this.stepForm.get('deleteVersionsOlderThan');
      deleteVersionsOlderThanCount = this.stepForm.get('deleteVersionsOlderThanCount');
      deleteVersionsOlderThanPeriod = this.stepForm.get('deleteVersionsOlderThanPeriod');
      fromTimeSelected = this.stepForm.get('fromTimeSelected');
      alwaysKeepLastVersion = this.stepForm.get('alwaysKeepLastVersion');
      delayPurgeForEnabled = this.stepForm.get('delayPurgeForEnabled');
      keepNumberOfVersions = this.stepForm.get('keepNumberOfVersions');
    } else {
      const hybridFields = this.stepForm.get('HybridRetentionPolicy');
      deleteVersionsOlderThan = hybridFields.get('deleteVersionsOlderThan');
      deleteVersionsOlderThanCount = hybridFields.get('deleteVersionsOlderThanCount');
      deleteVersionsOlderThanPeriod = hybridFields.get('deleteVersionsOlderThanPeriod');
      fromTimeSelected = hybridFields.get('fromTimeSelected');
      alwaysKeepLastVersion = hybridFields.get('alwaysKeepLastVersion');
      delayPurgeForEnabled = hybridFields.get('delayPurgeForEnabled');
      keepNumberOfVersions = hybridFields.get('keepNumberOfVersions');
    }

    if (event && deleteVersionsOlderThan.enabled) {
      deleteVersionsOlderThanCount.enable();
      deleteVersionsOlderThanPeriod.enable();

      if (fromTimeSelected.value !== FromTime[this.backupDate] && !this.isSQLPlan) alwaysKeepLastVersion.enable();

      if (this.isBackupPlan || (this.isSQLPlan && !isHybrid)) {
        fromTimeSelected.enable();

        if (!this.isSQLPlan) {
          delayPurgeForEnabled.enable();
          this.delayPurgeForEnabledChangeHandler(delayPurgeForEnabled.value, isHybrid);
        }
      }
    } else {
      deleteVersionsOlderThanCount.disable();
      deleteVersionsOlderThanPeriod.disable();
      alwaysKeepLastVersion.disable();

      if (this.isBackupPlan || this.isSQLPlan) {
        fromTimeSelected.disable();

        if (this.isSQLPlan || !keepNumberOfVersions.value) {
          delayPurgeForEnabled.disable();
          this.delayPurgeForEnabledChangeHandler(event, isHybrid);
        }
      }
    }
  }

  fromTimeSelectedChangeHandler(event, isHybrid = false): void {
    let RetentionUseDefaultSettings = null;
    let alwaysKeepLastVersion = null;

    if (!isHybrid) {
      RetentionUseDefaultSettings = this.stepForm.get('RetentionUseDefaultSettings');
      alwaysKeepLastVersion = this.stepForm.get('alwaysKeepLastVersion');
    } else {
      const hybridFields = this.stepForm.get('HybridRetentionPolicy');
      RetentionUseDefaultSettings = hybridFields.get('RetentionUseDefaultSettings');
      alwaysKeepLastVersion = hybridFields.get('alwaysKeepLastVersion');
    }

    if (!(event === null || event === undefined) && (isHybrid ? RetentionUseDefaultSettings.value : !RetentionUseDefaultSettings.value)) {
      const needEnabled = !(event.value === null || event.value === undefined)
        ? event.value !== FromTime[this.backupDate]
        : event !== FromTime[this.backupDate];

      if (needEnabled && !this.isSQLPlan) alwaysKeepLastVersion.enable();
      else {
        alwaysKeepLastVersion.setValue(true);
        alwaysKeepLastVersion.disable();
      }
    }
  }

  delayPurgeForEnabledChangeHandler(event: boolean, isHybrid = false): void {
    let RetentionUseDefaultSettings = null;
    let delayPurgeFor = null;
    let delayPurgeForSelect = null;

    if (!isHybrid) {
      RetentionUseDefaultSettings = this.stepForm.get('RetentionUseDefaultSettings');
      delayPurgeFor = this.stepForm.get('delayPurgeFor');
      delayPurgeForSelect = this.stepForm.get('delayPurgeForSelect');
    } else {
      const hybridFields = this.stepForm.get('HybridRetentionPolicy');
      RetentionUseDefaultSettings = hybridFields.get('RetentionUseDefaultSettings');
      delayPurgeFor = hybridFields.get('delayPurgeFor');
      delayPurgeForSelect = hybridFields.get('delayPurgeForSelect');
    }

    const validRetention = isHybrid ? RetentionUseDefaultSettings.value : !RetentionUseDefaultSettings.value;

    if (event && validRetention) {
      delayPurgeFor.enable();
      delayPurgeForSelect.enable();
    } else {
      delayPurgeFor.disable();
      delayPurgeForSelect.disable();
    }
  }

  deleteIfLocallyDeletedChangeHandler(event: boolean): void {
    const action = event ? 'enable' : 'disable';

    ['deleteAfter', 'doNotShowWarning'].forEach((key) => this.stepForm.get(key)[action]());
  }

  enableImmutabilityChangeHandler(event: boolean): void {
    if (event) {
      const modalSettings = new ModalSettings();

      modalSettings.responsive = true;
      this.modalService
        .openCustom(ImmutabilityConfirmModalComponent, modalSettings)
        .then(noop)
        .catch((result) => !result && this.stepForm.get('GFSPolicySettings').get('enabledImmutability').reset(false));
    }
  }

  enableGFSChangeHandler(event: boolean): void {
    const GFSFormGroup = this.stepForm.get('GFSPolicySettings') as UntypedFormGroup;

    for (const key of Object.keys(GFSFormGroup.getRawValue())) {
      if (key !== 'enabled') {
        const formGroup = GFSFormGroup.get(key) as UntypedFormGroup;

        if (event) {
          if (key !== 'enabledImmutability') {
            formGroup.enable();
            this.fullBackupsChangeHandler(formGroup.value.enabled, key);
          } else if (this.showImmutability) formGroup.enable();
        } else formGroup.disable();
      }
    }

    if (this.mainService.mode === PlanMode.edit) this.stepForm.markAsTouched();
  }

  fullBackupsChangeHandler(event: boolean, name: string): void {
    const GFSFormGroup = this.stepForm.get('GFSPolicySettings') as UntypedFormGroup;

    if (event) {
      if (name === 'yearly') {
        (GFSFormGroup.get(name) as UntypedFormGroup).controls.storePeriod.enable();
        (GFSFormGroup.get(name) as UntypedFormGroup).controls.takeBackupFromMonth.enable();
        this.yearlyStorePeriodChangeHandler();
      } else {
        (GFSFormGroup.get(name) as UntypedFormGroup).controls.storePeriod.enable();
        if (name === 'monthly') this.monthlyStorePeriodChangeHandler();
      }
    } else {
      if (name === 'yearly') {
        (GFSFormGroup.get(name) as UntypedFormGroup).controls.storePeriod.disable();
        (GFSFormGroup.get(name) as UntypedFormGroup).controls.takeBackupFromMonth.disable();
        this.yearlyStorePeriodChangeHandler();
      } else {
        (GFSFormGroup.get(name) as UntypedFormGroup).controls.storePeriod.disable();
        if (name === 'monthly') this.monthlyStorePeriodChangeHandler();
      }
    }
  }

  intelligentRetentionChangeHandler(value: boolean) {
    if (value) {
      return;
    }

    this.stepForm.get('intelligentRetention').setValue(true);

    const modalSettings: ModalSettings = {
      header: { title: this.i18nPipe.transform('wizards:intelligent_retention_alert_title', { format: 'title' }) },
      footer: {
        okButton: { text: this.i18nPipe.transform('app:buttons:disable'), type: 'danger' },
        cancelButton: { text: this.i18nPipe.transform('app:buttons:cancel') }
      }
    };

    this.modalService
      .open(modalSettings, this.IntelligentRetentionPayAttention)
      .catch(noop)
      .then((result) => {
        setTimeout(() => {
          this.stepForm.get('intelligentRetention').setValue(!result);
        });
      });
  }

  changeBackupScheduleClickHandler(): void {
    this.backStep.emit();
  }

  private showGFSAlert(needNextStep: boolean, needSave: boolean): void {
    const modalSettings: ModalSettings = {
      header: { title: this.i18nPipe.transform('wizards:disable_gfs_title', { format: 'title' }) },
      footer: { okButton: { show: false }, cancelButton: { text: 'Close' } }
    };

    this.modalService.open(modalSettings, this.i18nPipe.transform('wizards:disable_gfs_text')).catch(() => {
      this.stepForm.get('GFSPolicySettings').get('enabled').setValue(false);
      this.enableGFSChangeHandler(false);
      this.stepForm.updateValueAndValidity();
      this.isShowedModal = false;
      if (needNextStep || needSave) this.nextStep.emit(needSave);
    });
  }

  private showConfirmNotFull(needNextStep: boolean, needSave: boolean): void {
    const modalSettings: ModalSettings = {
      header: { title: this.i18nPipe.transform('wizards:not_full_confirm_title', { format: 'title' }) },
      footer: {
        okButton: { text: this.i18nPipe.transform('app:buttons:ok'), type: 'primary' },
        cancelButton: { show: false }
      }
    };

    this.modalService
      .open(modalSettings, this.confirmNotFullModal)
      .then(() => {
        this.confirmNotFullBackup = true;
        this.stepForm.updateValueAndValidity();
        this.isShowedModal = false;

        if (needNextStep || needSave) this.nextStep.emit(needSave);
      })
      .catch(() => (this.isShowedModal = false));
  }
}
