import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { OsType } from '@models/Computer';
import HddInfo from '@models/rmm/HddInfo';
import HddSmartInfo from '@models/rmm/HddSmartInfo';
import RmmLastStatData from '@models/rmm/RmmLastStatData';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmmService } from '@services/rmm.service';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { MbsSize, ModalService, TabsetItemDirective } from 'mbs-ui-kit';
import { forkJoin, map, noop, switchMap } from 'rxjs';

type HddSummary = HddInfo & { smart: HddSmartInfo } & { icon?: string };

@UntilDestroy()
@Component({
  selector: 'mbs-hdd-tab',
  templateUrl: './hdd-tab.component.html',
  styleUrls: ['./hdd-tab.component.scss']
})
export class HddTabComponent implements OnInit {
  @Input() public hid: string;

  disks: HddSummary[] = [];
  loading = true;
  public isMacOS: boolean;

  constructor(
    public rmmService: RmmService,
    private modal: ModalService, // prettier
    private i18n: I18NextPipe,
    private tabItem: TabsetItemDirective,
    private computersFacade: ComputersFacade
  ) {}

  ngOnInit(): void {
    this.loading = true;
    Promise.resolve().then(() => (this.tabItem.loading = true));

    const fetchData = this.computersFacade.currentComputer$.pipe(
      map((computer) => {
        if (computer && computer.os) this.isMacOS = computer.os === OsType.apple;
        return this.isMacOS;
      }),
      switchMap((isMacOS) => {
        if (isMacOS) return forkJoin([this.rmmService.fetchLastData<RmmLastStatData<HddInfo>>('hdd')]);
        return forkJoin([
          this.rmmService.fetchLastData<RmmLastStatData<HddInfo>>('hdd'),
          this.rmmService.fetchLastData<RmmLastStatData<HddSmartInfo>>('hddsmart')
        ]);
      }),
      untilDestroyed(this)
    );

    fetchData.subscribe(([disks, smarts]) => {
      let diskSmarts: HddSmartInfo[] = [];
      if (smarts) {
        const data = smarts.data;
        diskSmarts = cloneDeep(data);
      }

      if (disks.data) {
        const data = disks.data;
        this.disks = cloneDeep(data).map((disk: HddSummary) => {
          disk.smart = diskSmarts.find((sd) => sd.basic.deviceId === disk.basic.deviceId);
          disk.icon = this.getSmartIcon(disk);
          return disk;
        });
      }
      Promise.resolve().then(() => (this.tabItem.loading = false));
    });
  }

  private getSmartIcon(disk: HddSummary): string {
    switch (disk.isStatusOk) {
      case 'OK':
        return 'ico-success text-success';
      case 'Warning':
        return 'ico-OverdueSettings text-warning';
      case 'Unknown':
        return 'ico-question-circle text-muted';
      case 'Error':
      case 'Alarm':
        return 'ico-error text-danger';
      default:
        return '';
    }
  }

  handleShowSMART(smartTemplate: TemplateRef<any>, diskInfo: HddInfo): void {
    this.modal
      .open(
        {
          header: {
            title: `${this.i18n.transform('rmm-side-panel:hddTab.smartModalTitle')} ${diskInfo.basic.model} `
          },
          footer: { show: false },
          size: MbsSize.lg
        },
        smartTemplate
      )
      .finally(noop);
  }
}
