import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SignalRService } from '@modules/signal-r/signal-r.service';
import { DevicesService } from '@services/devices.service';

import { getInvalidDiffTime, getSecondsFromDate } from '@components/tfa/utils/date';
import { AuthService } from '@services';
import { MbsPopupType } from 'mbs-ui-kit';
import { catchError, of, take } from 'rxjs';

@Component({
  selector: 'mbs-msp-qr-code',
  templateUrl: './msp-qr-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MspQRCodeComponent implements OnInit, OnDestroy {
  @Input() timeExpired = null;
  @Input() QRCodeUri = null;
  @Input() isEnableTFA = false;
  @Input() isDeviceModal = false;

  public isQRCodeLoading = false;
  public isQRCodeUsed = false;
  public isQRCodeInvalid = false;
  public error = null;
  public isTimeExpired = false;
  public isInvalidLocalTime = false;
  public getInvalidDiffTime = getInvalidDiffTime;
  public readonly alertType = MbsPopupType;

  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private signalR: SignalRService,
    private devicesService: DevicesService
  ) {}

  ngOnInit(): void {
    this.setQRCode();

    this.signalR.authorizedZoneConnection.on('OnQrCodeUsed', () => {
      this.isQRCodeUsed = true;
      this.cdr.detectChanges();
    });
  }

  private checkInvalidDate() {
    this.isTimeExpired = false;
    this.isInvalidLocalTime = getSecondsFromDate(this.timeExpired) < 0;

    this.cdr.detectChanges();
  }

  getQRCodeOpacity() {
    const activeOpacity = 1;
    const inactiveOpacity = 0.4;

    return this.isTimeExpired ? inactiveOpacity : activeOpacity;
  }

  setQRCode(): void {
    this.isQRCodeInvalid = false;
    this.error = null;

    if (this.QRCodeUri && this.timeExpired && !this.isTimeExpired) {
      this.checkInvalidDate();
      return;
    }

    this.QRCodeUri = null;
    this.isQRCodeLoading = true;

    this.devicesService
      .addDevice(!this.isDeviceModal || this.isEnableTFA)
      .pipe(
        take(1),
        catchError((error) => {
          this.isQRCodeInvalid = true;
          this.error = error.error.detail;
          return of(error);
        })
      )
      .subscribe((result) => {
        this.isQRCodeLoading = false;
        this.QRCodeUri = result?.qrCodeUrl;

        this.timeExpired = result?.timeExpired;

        this.checkInvalidDate();
      });
  }

  ngOnDestroy(): void {
    this.signalR.authorizedZoneConnection.off('OnQrCodeUsed');
  }
}
