import { generateUid } from '@utils/generateUid';
import { TreeElement } from 'mbs-ui-kit';

export type ExtendedTreeElement = TreeElement & { treeIcon: RegEditTreeIconPath };
export enum RegEditTreeIconPath {
  'windowsFolder' = 'assets/images/icons/windowsFolder.svg',
  'listFile' = 'assets/images/icons/listFile.svg',
  'listFileString' = 'assets/images/icons/listFileString.svg',
  'listFileBinary' = 'assets/images/icons/listFileBinary.svg',
  'listFileNumeric' = 'assets/images/icons/listFileNumeric.svg'
}

export enum RegistryValueKind {
  // No data type.
  None = -1,

  // An unsupported registry data type. For example, the Microsoft Windows API registry
  // data type REG_RESOURCE_LIST is unsupported. Use this value to specify that the
  // Microsoft.Win32.RegistryKey.SetValue(System.String,System.Object) method should
  // determine the appropriate registry data type when storing a name/value pair.
  Unknown = 0,

  // A null-terminated string. This value is equivalent to the Windows API registry
  // data type REG_SZ.
  String = 1,

  // A null-terminated string that contains unexpanded references to environment variables,
  // such as %PATH%, that are expanded when the value is retrieved. This value is
  // equivalent to the Windows API registry data type REG_EXPAND_SZ.
  ExpandString = 2,

  // Binary data in any form. This value is equivalent to the Windows API registry
  // data type REG_BINARY.
  Binary = 3,

  // A 32-bit binary number. This value is equivalent to the Windows API registry
  // data type REG_DWORD.
  DWord = 4,

  // An array of null-terminated strings, terminated by two null characters. This
  // value is equivalent to the Windows API registry data type REG_MULTI_SZ.
  MultiString = 7,

  // A 64-bit binary number. This value is equivalent to the Windows API registry
  // data type REG_QWORD.
  QWord = 11
}

export const DefaultValueName = '(Default)';

export const RegistryTypes: Array<{ name: string; type: string; key: RegistryValueKind }> = [
  {
    name: 'REG_SZ',
    type: 'String',
    key: RegistryValueKind.String
  },
  {
    name: 'REG_DWORD',
    type: 'DWord',
    key: RegistryValueKind.DWord
  },
  {
    name: 'REG_QWORD',
    type: 'QWord',
    key: RegistryValueKind.QWord
  },
  {
    name: 'REG_BINARY',
    type: 'Binary',
    key: RegistryValueKind.Binary
  },
  {
    name: 'REG_MULTI_SZ',
    type: 'MultiString',
    key: RegistryValueKind.MultiString
  },
  {
    name: 'REG_EXPAND_SZ',
    type: 'ExpandString',
    key: RegistryValueKind.ExpandString
  }
];

export const InitialTreeElements: ExtendedTreeElement[] = [
  {
    label: 'HKEY_LOCAL_MACHINE',
    id: generateUid(),
    children: [],
    value: { type: null },
    treeIcon: RegEditTreeIconPath.windowsFolder
  },
  {
    label: 'HKEY_USERS',
    id: generateUid(),
    children: [],
    value: { type: null },
    treeIcon: RegEditTreeIconPath.windowsFolder
  },
  {
    label: 'HKEY_CURRENT_CONFIG',
    id: generateUid(),
    children: [],
    value: { type: null },
    treeIcon: RegEditTreeIconPath.windowsFolder
  },
  {
    label: 'HKEY_CLASSES_ROOT',
    id: generateUid(),
    children: [],
    value: { type: null },
    treeIcon: RegEditTreeIconPath.windowsFolder
  }
];
