import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { DataChangeWatcherService } from 'mbs-ui-kit';
import { Observable } from 'rxjs';

@Injectable()
export class DataChangeWatcherGuard implements CanActivateChild {
  constructor(private dcw: DataChangeWatcherService) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.dcw.detectChanges();
  }
}
