import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import * as Sentry from '@sentry/browser';
import { Extras } from '@sentry/types/dist/extra';
import { AuthService } from '@services/auth.service';
import { ConfigurationService } from '@services/configuration.service';
import { Subject, lastValueFrom, map, take } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class SignalRService {
  public onclose = new Subject();

  public authorizedZoneConnection: HubConnection;
  public nonAuthorizedZoneConnection: HubConnection;

  constructor(private authService: AuthService, private config: ConfigurationService) {
    this.startAuthorizedConnection();
  }

  private startConnection(url: string, token?: string): HubConnection {
    const connection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => {
          if (token) {
            return token;
          }

          return lastValueFrom(this.authService.refreshToken().pipe(map((response) => response?.access_token)));
        },
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        logger: LogLevel.Debug
      })
      .withAutomaticReconnect()
      .build();

    connection.start().catch((error) => {
      this.sendLog('Failed to connect to web sockets', { error });

      this.onclose.next(true);
    });

    connection.onclose((error) => {
      this.sendLog('Close connection to web sockets', { error });

      this.onclose.next(error);
    });

    this.startLoggingTopics(connection);

    return connection;
  }

  public startAuthorizedConnection() {
    this.authService.currentUser.pipe(filter(Boolean), take(1)).subscribe((user) => {
      this.authorizedZoneConnection?.stop();
      this.authorizedZoneConnection = user ? this.startConnection(this.config.get('signalRAuthorizedZoneHub')) : null;
    });
  }

  public restartAuthorizedConnection() {
    this.authService.tryRefreshToken$.pipe(take(1)).subscribe(() => {
      this.startAuthorizedConnection();
    });
  }

  public startNonAuthorizedConnection(authId: string): HubConnection {
    this.nonAuthorizedZoneConnection?.stop();
    this.nonAuthorizedZoneConnection = this.startConnection(this.config.get('signalRNonAuthorizedZoneHub'), authId);
    return this.nonAuthorizedZoneConnection;
  }

  private startLoggingTopics(connection: HubConnection) {
    this.config.get('loggedWSTopics')?.forEach((topic: string) => {
      connection.on(topic, (result) => {
        this.sendLog(topic, result);
      });
    });
  }

  private sendLog(message: string, extras?: Extras) {
    if (!environment.production) {
      console.log({
        message,
        extras
      });
      return;
    }
    Sentry.captureMessage(message, {
      level: 'info',
      tags: {
        type: 'signalR'
      },
      extra: extras
    });
  }
}
