export const storePrefix = '[Shell/API]';
export const UNSUPPORTED = 'UNSUPPORTED';
export const NEW_LINE = '\r\n';
export const PROMPT = 'PS> ';
export const PROMPT_NEW_LINE = '>> ';
export const ERROR_LINE = 'Error: ';
export const WARN_LINE = 'Warning: ';
export const EMPTY_COMMAND = '';
export const SPACE_CHAR = ' ';

export const isMac = navigator.userAgent.includes('Mac');
export const asyncIdPrefix = 'terminal'
// ansi colors
export const COLOR_YELLOW = '\u001b[93m';
export const COLOR_RED = '\u001b[91m';
export const COLOR_WHITE = '\u001b[39m';

export const TERMINAL_OPTIONS = {
  'Windows': {
    cursorStyle: 'underline',
    rendererType: 'dom',
    scrollback: 10000,
    cursorBlink: true,
    disableStdin: true,
    fontSize: 12,
    windowsMode: true,
    theme: {
      background: '#2D6CA2',
      cursorAccent: '#2D6CA2',
    }
  }
}

export const TERMINAL_GREETINGS = {
  'Windows': [
    `Remote Windows PowerShell${NEW_LINE}`,
    `Use ${isMac ? 'Cmd' : 'Ctrl'}+C to copy text from terminal,`,
    `    ${isMac ? 'Cmd' : 'Ctrl'}+V to paste`
  ]
}
