<div class="mbs-header">
  <nav [class]="'navbar navbar-light mbs-navbar ' + getNavbarButtonsClass">
    <div class="row no-gutters navbar-inner align-items-center">
      <div class="d-flex col-nav-2 col-nav-lg-auto order-nav-1">
        <button class="navbar-toggler mr-2"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                (click)="isNavbarCollapsed = !isNavbarCollapsed">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="home-page-area text-center border-right">
          <a id="homePageUrl"
             class="navbar-brand mbs-navbar_brand"
             [routerLink]="homePageUrl">
            <span *ngIf="appEnvState?.type === 'STAGE';else homeIcon"
                  class="fa fa-pie-chart text-danger"
                  [title]="appEnvState?.type.toLocaleUpperCase() + ' / ' + appEnvState?.version"></span>
            <ng-template #homeIcon>
              <span class="fa fa-pie-chart nav-link_ico"></span>
            </ng-template>
          </a>
        </div>
      </div>

      <!-- TODO: Optimize layout with conditions -->
      <div [ngbCollapse]="isNavbarCollapsed"
           class="collapse navbar-collapse col-nav-lg-auto order-nav-3 order-nav-lg-2"
           id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <ng-container *ngFor="let item of filterCanMenus(mainMenu)">
            <li class="nav-item"
                *ngIf="item.externalUrl">
              <a [href]="item.externalUrl"
                 class="font-weight-semibold nav-link"
                 [target]="item.target || '_self'">
                <span class="nav-link_ico"
                      [ngClass]="item.iconClass"></span>
                {{ item.name }}
              </a>
            </li>
            <li class="nav-item"
                [ngClass]="{
                  'active': item.routeUrl && isActiveRoute(item.routeUrl),
                  'cursor-default': item.routeUrl && isActiveRoute(item.routeUrl)
                }"
                *ngIf="(!item.children || item.children?.length === 0) && !item.action && !item.externalUrl">
              <a [routerLink]="isActiveRoute(item.routeUrl) ? null : item.routeUrl"
                 [queryParams]="item.queryParams"
                 class="nav-link d-inline-flex align-items-center">
                <span class="nav-link_ico mr-2"
                      [ngClass]="item.iconClass"></span>
                <span class="font-weight-semibold mr-1">{{ item.name }}</span>
                <span *ngIf="item.secondIconClass"
                      class="nav-link_ico mr-2"
                      [ngClass]="item.secondIconClass"></span>
                <span *ngIf="item.secondName"
                      class="font-weight-semibold mr-1">{{ item.secondName }}</span>
              </a>
            </li>
            <li class="nav-item position-relative"
                [class.mr-2]="item.hasDot"
                *ngIf="item.action">
              <button class="font-weight-semibold nav-link"
                      (click)="item.action()">
                <span class="nav-link_ico mr-2"
                      [ngClass]="item.iconClass"></span>
                <span class="font-weight-semibold"
                      [class.app-update-dot]="hasUpdates(item)"
                      [class.mbs-header-update-dot]="hasUpdates(item)">{{ item.name }}</span>
              </button>
            </li>

            <ng-container *ngIf="item.children?.length > 0">
              <ng-container *ngTemplateOutlet="itemLevel; context:{ item: item, placement: 'bottom-left' }">
              </ng-container>
            </ng-container>

            <ng-template #itemLevel
                         let-item="item"
                         let-placement="placement">
              <li ngbDropdown
                  [placement]="placement"
                  display="dynamic"
                  container="auto"
                  class="nav-item"
                  [ngClass]="{'active': isActiveMenuClass(item)}">
                <ng-container *ngIf="!getActiveSubTitle(item)">
                  <button class="d-inline-flex align-items-center nav-link"
                          [id]="item.name.toLowerCase()"
                          ngbDropdownToggle>
                    <span class="nav-link_ico mr-2"
                          [ngClass]="item.iconClass"></span>
                    <span class="font-weight-semibold mr-1">{{ item.name }}</span>
                    <span *ngIf="item.secondIconClass"
                          class="nav-link_ico mr-2"
                          [ngClass]="item.secondIconClass"></span>
                    <span *ngIf="item.secondName"
                          class="font-weight-semibold mr-1">{{ item.secondName }}</span>
                  </button>
                </ng-container>
                <ng-container *ngIf="getActiveSubTitle(item)">
                  <button class="nav-link -sublink"
                          [id]="item.name.toLowerCase()"
                          ngbDropdownToggle>
                    <span class="d-inline-flex align-items-center flex-row">
                      <span class="nav-link_ico"
                            [ngClass]="getParentIcon(item)"></span>
                      <span class="d-block mx-1">
                        <span class="font-weight-semibold nav-link_parent">{{item.name}}&nbsp;{{item.secondName}}</span>
                        <span class="font-weight-semibold d-block">{{ getActiveSubTitle(item)}}</span>
                      </span>
                    </span>
                  </button>
                </ng-container>
                <ul ngbDropdownMenu
                    [placement]="placement"
                    [attr.aria-labelledby]="item.name.toLowerCase()"
                    class="nav-submenu -arrow -rel-sm">
                  <ng-container *ngFor="let child of filterCanMenus(item.children)">
                    <ng-container *ngIf="child.isHorizontalDivider">
                      <hr class="header-menu-hr" />
                    </ng-container>
                    <li ngbDropdownItem
                        class="nav-submenu-item"
                        [ngClass]="{
                          'active': child.routeUrl && isActiveRoute(child.routeUrl),
                          'cursor-default': child.routeUrl && isActiveRoute(child.routeUrl)
                        }"
                        *ngIf="!child.children && !child.isHorizontalDivider && child.can === true">
                      <ng-container *ngIf="child.routeUrl">
                        <a [routerLink]="isActiveRoute(child.routeUrl) ? null : child.routeUrl"
                           [queryParams]="child.queryParams"
                           class="nav-submenu-link">
                          <span class="nav-link_ico"
                                [ngClass]="child.iconClass"></span>
                          {{ child.name }}
                        </a>
                      </ng-container>
                      <ng-container *ngIf="child.externalUrl">
                        <a [href]="child.externalUrl"
                           class="nav-submenu-link"
                           [target]="child.target || '_self'">
                          <span class="nav-link_ico"
                                [ngClass]="child.iconClass"></span>
                          {{ child.name }}
                        </a>
                      </ng-container>
                      <ng-container *ngIf="!item.isHorizontalDivider && child.action">
                        <button (click)="child.action()"
                                class="nav-submenu-link">
                          <span class="nav-link_ico"
                                [ngClass]="child.iconClass"></span>
                          {{ child.name }}
                        </button>
                      </ng-container>
                    </li>
                    <ng-container *ngIf="child.children?.length > 0">
                      <ng-container *ngTemplateOutlet="itemLevel; context:{ item: child, placement: 'right-top' }">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ul>
              </li>
            </ng-template>
          </ng-container>
        </ul>
      </div>

      <div class="d-flex col-nav-10 col-nav-lg-auto order-nav-2 order-nav-lg-3 justify-content-end">
        <div class="row no-gutters align-items-center justify-content-end">

          <ng-container *ngIf="showTryRMMBtn">
            <mbs-button [type]="'warning'"
                        [class]="'mr-1 mr-sm-3'"
                        [customClasses]="'text-white'"
                        (click)="handleRMMGettingStartedWizard()">
              {{ 'app-header:buttons.tryRMM' | i18next }}
            </mbs-button>
          </ng-container>

          <ng-container *ngIf="showBalanceBtn">
            <div [class]="'mr-1 mr-sm-3'">
              <span>
                {{ 'licenses:balance' | i18next : { format: 'title' } }}
              </span>
              <mbs-button [isCtrl]="true"
                          [customClasses]="'font-weight-semibold'"
                          [type]="balance?.hasDebt ? 'danger' : 'dark'"
                          (click)="balance?.hasDebt ? openPaymentDebtModal() : openPaymentModal()">
                {{ balance?.balance }}
              </mbs-button>
            </div>
          </ng-container>

          <div *ngIf="showAddBtn"
               ngbDropdown
               display="dynamic"
               container="auto"
               class="nav-item mr-1 mr-sm-3"
               placement="bottom-right bottom-left">
            <button class="btn btn-success"
                    id="dropdownAdd"
                    ngbDropdownToggle>
              <span class="fa fa-plus"></span>
              {{ 'app-header:buttons.add' | i18next }}
            </button>
            <ul ngbDropdownMenu
                aria-labelledby="dropdownAdd"
                placement="bottom-right"
                class="nav-submenu -arrow">
              <li ngbDropdownItem
                  class="nav-submenu-item dropdown-item"
                  *ngFor="let item of filterCanMenus(addMenuItems)">
                <ng-container *ngIf="item.routeUrl">
                  <a [routerLink]="item.routeUrl"
                     [queryParams]="item.queryParams"
                     [target]="item.target || '_self'"
                     class="nav-submenu-link right-submenu"
                     ngbDropdownItem>
                    <span class="nav-link_ico"
                          [ngClass]="item.iconClass"></span>
                    {{ item.name }}
                  </a>
                </ng-container>
                <ng-container *ngIf="item.externalUrl">
                  <a [href]="item.externalUrl"
                     [target]="item.target || '_self'"
                     class="nav-submenu-link right-submenu"
                     ngbDropdownItem>
                    <span class="nav-link_ico"
                          [ngClass]="item.iconClass"></span>
                    {{ item.name }}
                  </a>
                </ng-container>
                <ng-container *ngIf="item.action">
                  <button (click)="item.action()"
                          class="nav-submenu-link right-submenu"
                          ngbDropdownItem>
                    <span class="nav-link_ico"
                          [ngClass]="item.iconClass"></span>
                    {{ item.name }}
                  </button>
                </ng-container>
              </li>
            </ul>
          </div>

          <ng-container *ngIf="('HelpMarketing' | can:'read') && !('HideFeedback' | can: 'read')">
            <div class="navbar-btn-collapse">
              <button class="font-weight-bold btn btn-light mr-2"
                      data-sleek-changelog
                      data-badge-changelog>
                <span class="fa fa-bell"></span>
              </button>
            </div>
          </ng-container>

          <div ngbDropdown
               display="dynamic"
               container="auto"
               class="nav-item mr-1 mr-sm-2"
               placement="bottom-right bottom-left"
               *ngIf="'HelpMarketing' | can:'read'">
            <button class="font-weight-bold btn btn-light"
                    id="dropdownBasic1"
                    ngbDropdownToggle>
              <span class="nav-link_ico fa fa-question-circle"></span>
            </button>
            <ul ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                placement="bottom-right"
                class="nav-submenu -arrow">
              <li ngbDropdownItem
                  class="nav-submenu-item dropdown-item"
                  *ngFor="let item of filterCanMenus(helpMenuItems)">
                <ng-container *ngIf="item.routeUrl">
                  <a [title]="item.title"
                     [routerLink]="item.routeUrl"
                     [target]="item.target || '_self'"
                     class="nav-submenu-link right-submenu"
                     ngbDropdownItem>
                    <span class="nav-link_ico"
                          [ngClass]="item.iconClass"></span>
                    {{ item.name }}
                  </a>
                </ng-container>
                <ng-container *ngIf="item.externalUrl">
                  <a [title]="item.title"
                     [href]="item.externalUrl"
                     [target]="item.target || '_self'"
                     class="nav-submenu-link right-submenu"
                     ngbDropdownItem>
                    <span class="nav-link_ico"
                          [ngClass]="item.iconClass"></span>
                    {{ item.name }}
                  </a>
                </ng-container>
                <ng-container *ngIf="item.action">
                  <button [title]="item.title"
                          (click)="item.action()"
                          class="nav-submenu-link right-submenu"
                          ngbDropdownItem>
                    <span class="nav-link_ico"
                          [ngClass]="item.iconClass"></span>
                    {{ item.name }}
                  </button>
                </ng-container>
              </li>
            </ul>
          </div>

          <div ngbDropdown
               display="dynamic"
               container="auto"
               class="nav-item"
               #accountDropdown="ngbDropdown"
               placement="bottom-right bottom-left"
               *ngIf="currentUser">
            <button class="font-weight-bold btn btn-light"
                    id="dropdownUser"
                    ngbDropdownToggle
                    (click)="userSawUpdates()">
              <span class="nav-link_ico header-nav-link-user-ico fa fa-user-circle-o"
                    [class.app-update-dot]="hasUserUpdates"
                    [class.mbs-header-user-update-dot]="hasUserUpdates"
                    [ngClass]="{
                'text-success': currentUser?.IsProvider && !currentUser?.IsSuperAdmin,
                'text-purple': !currentUser?.IsProvider && !currentUser?.IsSuperAdmin,
                'text-danger': currentUser?.IsSuperAdmin,
                '-reseller': !!currentUser?.ResellerInfo
              }"></span>
            </button>
            <ul ngbDropdownMenu
                aria-labelledby="dropdownUser"
                placement="bottom"
                class="nav-submenu">
              <li class="mbs-navbar_userinfo-item"
                  disabled="true">
                <div class="text-xs font-weight-bold mb-1">
                  {{ 'app-header:texts.you' | i18next }}
                </div>
                <div class="text-sm mb-1">
                  {{ displayName(currentUser) }}
                </div>
                <div class="text-sm mb-1">
                  {{ currentUser?.ProviderInfo?.CompanyName }} {{ 'app-header:texts.company' | i18next }}
                </div>
                <div class="text-sm"
                     *ngIf="currentUser.Email">
                  {{ currentUser.Email }}
                </div>
              </li>

              <ng-container *ngIf="accountManager && ('HelpMarketing' | can:'read')">
                <ng-container *ngIf="accountManager?.fullPhotoUrl;else simpleAccountManager">
                  <li class="dropdown-divider mb-0"></li>
                  <li class="mbs-navbar_userinfo-item bg-blue-10 py-2"
                      disabled="true">
                    <div class="row no-gutters">
                      <div class="col d-flex align-items-center">
                        <div class="account-manager-photo"
                             [ngStyle]="{ 'background-image': 'url(' + getAccountManagerPhotoURL() + ')'}"></div>
                      </div>
                      <div class="col d-flex align-items-center">
                        <div class="pl-2">
                          <div class="row">
                            <div class="col text-xs font-weight-bold mb-1">
                              {{ 'app-header:texts.personalAccountManager' | i18next }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col text-sm">
                              {{ accountManager?.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="dropdown-divider my-0"></li>
                  <li class="mbs-navbar_userinfo-item bg-blue-10 py-2"
                      disabled="true">
                    <div *ngIf="accountManager?.phone"
                         class="text-sm mb-1">
                      {{ accountManager?.phone }}
                    </div>
                    <button class="nav-link right-submenu text-sm px-0 py-0"
                            [ngbTooltip]="'app-header:tooltip.copyEmail' | i18next"
                            container="body"
                            placement="top-left auto"
                            (click)="handleCopyAccountManagerEmail()">
                      {{ accountManager?.email }}
                    </button>
                  </li>
                </ng-container>
                <ng-template #simpleAccountManager>
                  <li class="dropdown-divider mb-0"></li>
                  <li class="mbs-navbar_userinfo-item bg-blue-10 py-2 bg-ico-container"
                      disabled="true">
                    <i class="ico ico-Briefcase bg-ico text-primary"></i>
                    <div class="text-xs font-weight-bold mb-1">
                      {{ 'app-header:texts.personalAccountManager' | i18next }}
                    </div>
                    <div class="text-sm mb-1">
                      {{ accountManager?.name }}
                    </div>
                    <button class="nav-link right-submenu text-sm px-0 py-0"
                            [ngbTooltip]="'app-header:tooltip.copyEmail' | i18next"
                            container="body"
                            placement="top-left auto"
                            (click)="handleCopyAccountManagerEmail()">
                      {{ accountManager?.email }}
                    </button>
                    <div *ngIf="accountManager?.phone"
                         class="text-sm mt-1">
                      {{ accountManager?.phone }}
                    </div>
                  </li>
                </ng-template>
              </ng-container>

              <li class="dropdown-divider"
                  [ngClass]="{'mt-0': accountManager && ('HelpMarketing' | can:'read')}"></li>
              <li *ngIf="!currentUser?.IsProvider && mode2022"
                  ngbDropdownItem
                  class="nav-item">
                <button class="nav-link right-submenu"
                        (click)="handleMyProfile()">
                  <span class="nav-link_ico fa fa-cloud"></span>
                  {{ 'app-header:buttons.myProfile' | i18next }}
                </button>
              </li>
              <li *ngIf="!currentUser?.IsProvider && mode2022"
                  class="dropdown-divider"></li>
              <li ngbDropdownItem
                  class="nav-submenu-item">
                <button class="nav-submenu-link right-submenu"
                        (click)="handleLogout()">
                  <span class="nav-link_ico fa fa-sign-out"></span>
                  {{ 'app-header:buttons.logOff' | i18next }}
                </button>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </nav>
</div>

<mbs-alert-container></mbs-alert-container>

<ng-template #tryBackupModal>
  <p>By clicking this button you are switching to <a href="https://www.msp360.com/managed-backup.aspx">MSP360 Managed
      Backup Service</a>, a data backup solution for MSPs with
    centralized job management, monitoring and reporting.</p>
</ng-template>

<ng-template #tryAddComputer>
  <mbs-alert [type]="'info' "
             [icon]="true"
             class="mb-0">
    <p class="mb-2">We are currently working on the “Add Computer” feature and looking for customers who are ready to
      help us build it.</p>
    <p class="mb-0">Would you like to participate in a 30-minute call with our Product Management team?</p>
  </mbs-alert>
</ng-template>
