import { EntityState } from '@ngrx/entity';

export enum PSOutDataType {
  StdOut = 0, // Standard Output from Agent
  StdErr = 1, // Standard Error from Agent
  End = 2, // Processing End, return exit code
  Busy = 3, // Terminal on Agent is busy
  Warning = 4, // Incorrect terminal version, on Agent host
  InputWait = 5, // Prompt message
  Unknown = 6, // Unknown error from Agent
  TimeOutSettings = 7, // Timeout error
  SystemInfo = 8 // Information about Agent host
}

export interface TerminalPromptState {
  single: string;
  multi: string;
}

export enum TerminalDataType {
  PROMPT = 'PROMPT',
  INPUT = 'INPUT',
  FILE = 'FILE',
  OUTSIDE = 'OUTSIDE',
  OUTPUT = 'OUTPUT',
  END = 'END',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

export type TerminalHostInfo = {
  osVersionType: 'Windows' | string,
  psVersionType?: 'Regular' | string,
  timeOutMin?: number,
  legacy?: boolean;
};

export interface TerminalChunk {
  id: string; // is stamp:?number
  data: string; // some data
  session: string; // prefix:hid:emuId:stamp
  type: TerminalDataType;
  emuId?: string; // emulator id
  file?: string; // file name
  number: number;
  stamp: number;
}


export interface TerminalSession {
  id: string; // terminal:hid
  hid: string; // hid
  session: string;
  subscribers: string[]; // uuid terminal instances
  info: TerminalHostInfo;
  processing: boolean;
  disconnected: boolean;
  timeout: number;
}

export interface TerminalTransfer {
  outType?: PSOutDataType;
  number?: number;
  Data?: any;
  data?: any;
}
