import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { DayOfWeek } from '@models/DayOfWeek.enum';
import { WeekNumber } from '@models/WeekNumber.enum';
import { RunTypes, ScheduledDataFromGroupTask, TimeUnits } from '@modules/group-tasks/store/model';
import { BaseForPlanHelper } from '@modules/wizards/helpers/bases/wizard-base-helpers';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { WizardStepComponent } from 'mbs-ui-kit';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, take } from 'rxjs';
import { GAActions, GASelectors } from '../../store/group-action';
import { GroupActionsCommands, ScheduleState } from '../../store/group-action/group-action.model';
import EditGroupTaskUtility from '../../utility/edit-group-task-utility';

export interface ScheduleData {
  recurringPeriod: 'Once' | 'Daily' | 'Monthly' | 'DayOfMonth';
  weeklyDayOfWeek: boolean[]; // bool
  occurrence: WeekNumber;
  dayOfWeek: number;
  dayOfMonthCount: number;
  dailyFreqPeriodOption: 'OccursAt' | 'OccursEvery';
  occursAtTime: string;
  repeatEveryCount: number;
  occursEveryCount: number;
  occursEveryPeriod: 'hours' | 'minutes';
  occursEveryFromTime: string;
  occursEveryToTime: string;
  startFromDate: string | Date;
}

export enum ScheduleMisfireHandling {
  DoNothing,
  IgnoreMisfires,
  FireAndProceed
}

const DEFAULT_SCHEDULE_FORM = {
  recurringPeriod: 'Once',
  weeklyDayOfWeek: [false, false, false, false, false, false, false],
  occurrence: -1,
  dayOfWeek: 3,
  dayOfMonthCount: 1,
  dailyFreqPeriodOption: 'OccursAt',
  occursAtTime: '00:00',
  occursEveryCount: 1,
  occursEveryPeriod: 'hours',
  occursEveryFromTime: '00:00',
  occursEveryToTime: '23:59',
  repeatEveryCount: 1,
  startFromDate: new Date()
};

@UntilDestroy()
@Component({
  selector: 'mbs-schedule-action',
  templateUrl: './schedule-action.component.html'
})
export class ScheduleActionComponent implements OnInit {
  public elementSelectors = {
    runManually: 'manual-radio-button',
    runOnce: 'once-radio-button',
    specificDate: 'specific-radio-button',
    scheduleData: 'schedule-data-div-element'
  };

  public formModeSelectionOptionsLabel = [
    this.i18n.transform('rmm.module:groupActions.stepSchedule.runManually'),
    this.i18n.transform('rmm.module:groupActions.stepSchedule.runOnce'),
    this.i18n.transform('rmm.module:groupActions.stepSchedule.specificDate')
  ] as const;

  public formModeSelectionOptions = [RunTypes.none, RunTypes.immediately, RunTypes.schedule] as const;
  public modeSelectionForm: UntypedFormGroup = new UntypedFormGroup({
    mode: new FormControl(this.formModeSelectionOptions[1])
  });

  public scheduleMisfireHandlingForm: UntypedFormGroup = new UntypedFormGroup({
    handleMissFire: new FormControl(ScheduleMisfireHandling.IgnoreMisfires)
  });

  public mbsCardStaticHeight = '140px';
  public showSchedule = false;
  public dataDescription: string;
  private momentFormatForBackend = 'YYYY-MM-DDTHH:mm:00';
  private checkboxChangedByUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public scheduleData: ScheduleData = null;
  public isUpdatePolicy = false;
  private groupTaskDataIsLoaded = false;

  constructor(
    protected injector: Injector,
    @Inject(WizardStepComponent)
    protected parent: WizardStepComponent,
    private i18n: I18NextPipe,
    private store: Store,
    private editGroupTaskUtility: EditGroupTaskUtility
  ) {}

  ngOnInit() {
    this.scheduleMisfireHandlingForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.checkboxChangedByUser.next(true);
      this.store.dispatch(
        GAActions.setGActionScheduleMisfireHandling({
          misfireHandling: value.handleMissFire ? ScheduleMisfireHandling.FireAndProceed : ScheduleMisfireHandling.DoNothing
        })
      );
    });
    this.modeSelectionForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.showSchedule = value.mode === RunTypes.schedule;

      switch (value.mode) {
        case RunTypes.none:
        case RunTypes.immediately:
          this.store.dispatch(
            GAActions.setGActionSchedule({
              schedule: {
                runType: value.mode,
                scheduleType: 0,
                scheduleData: null
              }
            })
          );
          break;
        case RunTypes.schedule:
        default:
          break;
      }
    });

    this.loadGroupTaskData();
  }

  public onScheduleDataChange(data: ScheduleData): void {
    this.dataDescription = this.setPanelTitle(data);
    this.registrationEventTimeParser(data);
  }

  private setPanelTitle(schedule: ScheduleData): string {
    if (schedule.recurringPeriod === 'Once') return this.getOnceAtTimeDescription(schedule);
    if (schedule?.weeklyDayOfWeek) schedule.weeklyDayOfWeek = schedule.weeklyDayOfWeek.map((d) => !!d);
    const newSchedule = BaseForPlanHelper.ToPaskalCase(schedule);
    if (newSchedule.RecurringPeriod === 'Daily' && newSchedule.WeeklyDayOfWeek.some((d) => !d)) newSchedule.RecurringPeriod = 'Weekly';
    else if (newSchedule.Occurrence === WeekNumber['Day Of Month'] && !newSchedule.WeeklyDayOfWeek?.some((d) => d))
      newSchedule.RecurringPeriod = 'DayOfMonth';
    newSchedule.RepeatEveryCount = 1;
    return StepsHelpers.getStringByScheduleData(newSchedule, false);
  }

  private registrationEventTimeParser(data: ScheduleData): void {
    switch (data.recurringPeriod) {
      case 'Monthly':
        this.handleMisfireHandlingChange(true);
        this.store.dispatch(GAActions.setGActionSchedule({ schedule: this.setupRunMonthly(data) }));
        break;
      case 'Daily':
        this.handleMisfireHandlingChange(false);
        this.store.dispatch(GAActions.setGActionSchedule({ schedule: this.setupRunDaily(data) }));
        break;
      case 'Once':
        this.handleMisfireHandlingChange(true);
        this.store.dispatch(GAActions.setGActionSchedule({ schedule: this.setupRunOnceOnDate(data) }));
        break;
      default:
        // run now
        this.store.dispatch(GAActions.resetGActionSchedule());
        break;
    }
  }

  handleMisfireHandlingChange(value): void {
    if (!this.checkboxChangedByUser.getValue())
      this.scheduleMisfireHandlingForm.get('handleMissFire').setValue(value, { emitEvent: false });
  }

  private getOnceAtTimeDescription(data: ScheduleData): string {
    const dateStartTime = moment(data.startFromDate).utc(true);
    const [hh, mm] = data.occursAtTime.split(':');
    dateStartTime.set({ h: Number(hh), m: Number(mm) });

    return this.i18n.transform('rmm.module:groupActions.stepSchedule.scheduleDescription', {
      value: dateStartTime.format('MMM DD, YYYY, [at] h:mm A')
    });
  }

  private setupRunOnceOnDate(data: ScheduleData): ScheduleState {
    const dateStartTime = moment(data.startFromDate);
    const [hh, mm] = data.occursAtTime.split(':');
    dateStartTime.set({ h: Number(hh), m: Number(mm) });

    return {
      runType: RunTypes.schedule,
      scheduleType: 1,
      scheduleData: {
        misfireHandling: this.scheduleMisfireHandlingForm.get('handleMissFire').value
          ? ScheduleMisfireHandling.FireAndProceed
          : ScheduleMisfireHandling.DoNothing,
        dateTimeStart: dateStartTime.format(this.momentFormatForBackend)
      },
      scheduleDesc: this.dataDescription
    };
  }

  private setupRunDaily(data: ScheduleData): ScheduleState {
    return {
      runType: RunTypes.schedule,
      scheduleType: data.dailyFreqPeriodOption === 'OccursEvery' ? 5 : 4,
      scheduleData: {
        misfireHandling: this.scheduleMisfireHandlingForm.get('handleMissFire').value
          ? ScheduleMisfireHandling.FireAndProceed
          : ScheduleMisfireHandling.DoNothing,
        sunday: data.weeklyDayOfWeek[DayOfWeek.Sunday],
        monday: data.weeklyDayOfWeek[DayOfWeek.Monday],
        tuesday: data.weeklyDayOfWeek[DayOfWeek.Tuesday],
        wednesday: data.weeklyDayOfWeek[DayOfWeek.Wednesday],
        thursday: data.weeklyDayOfWeek[DayOfWeek.Thursday],
        friday: data.weeklyDayOfWeek[DayOfWeek.Friday],
        saturday: data.weeklyDayOfWeek[DayOfWeek.Saturday],
        timeInterval: data.occursEveryCount,
        timeUnit: data.occursEveryPeriod === 'hours' ? TimeUnits.Hours : TimeUnits.Minutes,
        timeEnd: data.dailyFreqPeriodOption === 'OccursEvery' ? data.occursEveryToTime : data.occursAtTime,
        timeStart: data.dailyFreqPeriodOption === 'OccursEvery' ? data.occursEveryFromTime : data.occursAtTime,
        dateStart: moment(data.startFromDate).utc(true).startOf('day').format(this.momentFormatForBackend)
      },
      scheduleDesc: this.dataDescription
    };
  }

  private setupRunMonthly(data: ScheduleData): ScheduleState {
    return {
      runType: RunTypes.schedule,
      scheduleType: 6,
      scheduleData: {
        misfireHandling: this.scheduleMisfireHandlingForm.get('handleMissFire').value
          ? ScheduleMisfireHandling.FireAndProceed
          : ScheduleMisfireHandling.DoNothing,
        day: data.occurrence === WeekNumber['Day Of Month'] ? data.dayOfMonthCount : 1,
        timeStart: data.occursAtTime,
        dateStart: moment(data.startFromDate).utc(true).startOf('day').format(this.momentFormatForBackend)
      },
      scheduleDesc: this.dataDescription
    };
  }

  public dailyValidationCheck(data: ScheduleData): boolean {
    if (data.recurringPeriod !== 'Daily') return true;
    let validationPassed = false;
    const days = data.weeklyDayOfWeek;
    days.forEach((dayStatus) => {
      if (dayStatus) {
        validationPassed = true;
      }
    });
    return validationPassed;
  }

  private loadGroupTaskData(): void {
    const groupActionType$ = this.store.select(GASelectors.selectGActionCommandType);
    const schedule$ = this.editGroupTaskUtility.getScheduleDataFromGroupTaskStream();
    combineLatest([groupActionType$, schedule$])
      .pipe(take(1))
      .subscribe(([groupActionType, schedule]) => {
        this.isUpdatePolicy = groupActionType === GroupActionsCommands.UPDATE_POLICY;
        if (this.isUpdatePolicy) this.modeSelectionForm.get('mode').setValue(this.formModeSelectionOptions[2]);
        if (this.groupTaskDataIsLoaded) return;
        this.groupTaskDataIsLoaded = true;
        if (!schedule) return;
        this.scheduleMisfireHandlingForm.get('handleMissFire').setValue(schedule?.scheduleData?.misfireHandling);
        this.modeSelectionForm.get('mode').setValue(schedule.runType);
        if (schedule.scheduleType !== 'None') this.scheduleData = this.getParsedScheduleInfo(schedule);
      });
  }

  private getParsedScheduleInfo(schedule: ScheduleState): ScheduleData {
    switch (schedule.scheduleType) {
      case 'MonthlyOneTime':
        return this.getDataForScheduleForm({
          recurringPeriod: 'Monthly',
          dayOfMonthCount: schedule.scheduleData.day,
          startFromDate: schedule.scheduleData.dateStart,
          occursAtTime: schedule.scheduleData.timeStart
        });
      case 'WeeklyEveryTime':
        return this.getDataForScheduleForm({
          recurringPeriod: 'Daily',
          weeklyDayOfWeek: this.getWeeklyDayOfWeek(schedule.scheduleData),
          dailyFreqPeriodOption: 'OccursEvery',
          startFromDate: schedule.scheduleData.dateStart,
          occursEveryFromTime: schedule.scheduleData.timeStart,
          occursEveryToTime: schedule.scheduleData.timeEnd,
          occursEveryPeriod: schedule.scheduleData.timeUnit === TimeUnits.Hours ? 'hours' : 'minutes',
          occursEveryCount: schedule.scheduleData.timeInterval
        });
      case 'WeeklyOneTime':
        return this.getDataForScheduleForm({
          recurringPeriod: 'Daily',
          weeklyDayOfWeek: this.getWeeklyDayOfWeek(schedule.scheduleData),
          dailyFreqPeriodOption: 'OccursAt',
          occursAtTime: schedule.scheduleData.timeStart,
          startFromDate: schedule.scheduleData.dateStart
        });
      case 'OneTime':
        return this.getDataForScheduleForm({
          recurringPeriod: 'Once',
          dailyFreqPeriodOption: 'OccursAt',
          occursAtTime: this.getTimeFromDateFormatInString(schedule.scheduleData.dateTimeStart),
          startFromDate: schedule.scheduleData.dateTimeStart
        });
      default:
        return this.getDataForScheduleForm();
    }
  }

  public getDataForScheduleForm(dataObject?: Partial<ScheduleData>): ScheduleData {
    return Object.assign({}, DEFAULT_SCHEDULE_FORM, dataObject);
  }

  public getWeeklyDayOfWeek(schedule: ScheduledDataFromGroupTask): boolean[] {
    return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((d) => !!schedule[d]);
  }

  public getTimeFromDateFormatInString(date: string): string {
    if (!date) return null;
    const newDate = new Date(date);
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes() > 9 ? newDate.getMinutes() : `0${newDate.getMinutes()}`;

    return `${hours}:${minutes}`;
  }
}
