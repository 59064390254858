<button *ngFor="let step of steps"
        class="mbs-stepper_step"
        [disabled]="step.condition === condition.Disabled"
        [ngClass]="[step.condition ? '-' + step.condition : '']"
        (click)="stepChanged.emit(step)">

  <span class="mbs-stepper_step-indicator"></span>

  <span class="mbs-stepper_step-title">
    {{ step.name }}
  </span>

</button>
