import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailServiceStatus, NotificationGeneralProvider, NotificationMethod } from '@models/Notification';
import { AuthService } from '@services/auth.service';
import { AbilityService } from 'ability';
import { isNil } from 'lodash';
import { distinctUntilChanged, filter, map, Observable, of, shareReplay } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationServerSettingsService {
  private emailStatus$: Observable<EmailServiceStatus>;

  constructor(private http: HttpClient, private auth: AuthService, private ability: AbilityService) {}

  get(): Observable<NotificationGeneralProvider> {
    return this.http.get<NotificationGeneralProvider>('api/email-service-settings');
  }

  save(settings: NotificationGeneralProvider): Observable<NotificationGeneralProvider> {
    return this.http.put<NotificationGeneralProvider>(`api/email-service-settings/${NotificationMethod[settings.Type]}`, settings);
  }

  verifiedEmail(settings: NotificationGeneralProvider): Observable<NotificationGeneralProvider> {
    return this.http.post<NotificationGeneralProvider>(`api/email-service-settings/${NotificationMethod[settings.Type]}/verify-email`, {
      EmailFrom: settings.EmailFrom,
      s3AccountId: settings.SESSettings ? settings.SESSettings.Account : null
    });
  }

  checkVerifiedEmail(settings: NotificationGeneralProvider): Observable<boolean> {
    return this.http.get<boolean>(`api/email-service-settings/${NotificationMethod[settings.Type]}/email-verified`, {
      params: {
        emailFrom: settings.EmailFrom,
        s3AccountId: settings.Type === NotificationMethod.SES ? settings.SESSettings.Account : null
      }
    });
  }

  checkSESFullAccess(accountId: string): Observable<boolean> {
    return this.http.get<boolean>(`api/email-service-settings/ses/account/${accountId}/is-production-access`);
  }

  sendTestEmail(emailTo: string, settings: NotificationGeneralProvider): Observable<boolean> {
    return this.http.post<boolean>(`api/email-service-settings/${NotificationMethod[settings.Type]}/send-test-email`, {
      emailTo,
      settings
    });
  }

  checkStatus(settings: NotificationGeneralProvider): Observable<NotificationGeneralProvider> {
    return this.http.get<NotificationGeneralProvider>(`api/email-service-settings/${NotificationMethod[settings.Type]}/check-status`, {
      params: {
        emailFrom: settings.EmailFrom,
        s3AccountId: settings.Type === NotificationMethod.SES ? settings.SESSettings.Account : null
      }
    });
  }

  checkEmailServiceConfigured(force = false): Observable<EmailServiceStatus> {
    if (!this.emailStatus$ || force) {
      this.emailStatus$ = this.auth.currentUser.pipe(
        switchMap((user) => {
          const skipRequest = !user?.IsProvider && this.ability.cannot('read', 'Notification');
          return skipRequest ? of(null) : this.http.get<{ status: EmailServiceStatus }>('api/email-service/status');
        }),
        map((result) => result?.status),
        shareReplay(1)
      );
    }

    return this.emailStatus$.pipe(
      filter((value) => !isNil(value)),
      distinctUntilChanged()
    );
  }
}
