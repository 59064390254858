<div class="mbs-container">
  <div class="mbs-container-inner justify-content-center align-items-center">
    <form class="mbs-form mbs-form-welcome" #signup="ngForm" (ngSubmit)="onSignUpSubmit(signup)">
      <div class="mbs-form_content">
        <div class="mbs-form-welcome_header">
          <div class="mbs-form-welcome_header-text">Sign up to</div>
          <div class="mbs-form-welcome_header-name">Managed Backup Service</div>
        </div>

        <fieldset class="mbs-form-welcome_content">

          <div class="mbs-form-group">
            <div class="mbs-form_input-group">
              <input type="text" class="form-control form-control-lg" name="firstName" placeholder="First Name*" ngModel required
                #firstName="ngModel" [class.is-invalid]="firstName.touched && firstName.invalid" />
              <input type="text" class="form-control form-control-lg" name="lastName" placeholder="Last Name*" ngModel required
                #lastName="ngModel" [class.is-invalid]="lastName.touched && lastName.invalid">
              <input type="text" class="form-control form-control-lg" name="company" placeholder="Company*" ngModel required
                #company="ngModel" [class.is-invalid]="company.touched && company.invalid">
              <input type="tel" class="form-control form-control-lg" name="phone" placeholder="Phone" ngModel #phone="ngModel"
                [class.is-invalid]="phone.touched && phone.invalid">
              <input type="email" class="form-control form-control-lg" name="email" placeholder="Email*" ngModel email required
                #email="ngModel" [class.is-invalid]="email.touched && email.invalid">
            </div>
          </div>

        </fieldset>

        <div class="mbs-form-group pb-4">
          <button [disabled]="signup.form.invalid" type="submit" class="btn btn-warning btn-lg btn-block">
            Sign up
          </button>
        </div>

        <div class="mbs-form-group">
          <div class="text-center mbs-form_label">
            Already have an account? <a routerLink="/login">Login</a>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
