import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RmmDateTimeFormatModule } from '@components/date-time-format/rmm-date-time-format.module';
import { PasswordRecoveryHandlerComponent } from '@modules/password-recovery/components/password-recovery-handler/password-recovery-handler.component';
import { DisablePasswordRecoveryModalComponent } from '@modules/password-recovery/modals/disable-password-recovery-modal/disable-password-recovery-modal.component';
import { EnablePasswordRecoveryWizardComponent } from '@modules/password-recovery/modals/enable-password-recovery-wizard/enable-password-recovery-wizard.component';
import { PasswordRecoveryModalComponent } from '@modules/password-recovery/modals/password-recovery-modal/password-recovery-modal.component';
import { PasswordRecoveryTwoFaComponent } from '@modules/password-recovery/modals/password-recovery-two-fa/password-recovery-two-fa.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { SidepanelPasswordRecoveryComponent } from './components/sidepanel-password-recovery/sidepanel-password-recovery.component';
import {
  ForcePasswordRecoveryModalComponent
} from '@modules/password-recovery/modals/force-password-recovery-modal/force-password-recovery-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MbsUiKitModule,
    I18NextModule,
    AbilityModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    RmmDateTimeFormatModule
  ],
  declarations: [
    SidepanelPasswordRecoveryComponent,
    PasswordRecoveryTwoFaComponent,
    EnablePasswordRecoveryWizardComponent,
    DisablePasswordRecoveryModalComponent,
    PasswordRecoveryModalComponent,
    PasswordRecoveryHandlerComponent,
    ForcePasswordRecoveryModalComponent
  ],
  exports: [
    PasswordRecoveryTwoFaComponent,
    EnablePasswordRecoveryWizardComponent,
    DisablePasswordRecoveryModalComponent,
    PasswordRecoveryHandlerComponent,
    ForcePasswordRecoveryModalComponent
  ]
})
export class PasswordRecoveryModule {}
