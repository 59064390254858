<div class="justify-content-center mb-4 pb-2 row">
  <div class="col-4">
    <mbs-stepper [steps]="parentSteps"
                 size="lg"></mbs-stepper>
  </div>
</div>

<div class="mt-3">
  <mbs-alert *ngIf="errorMessage"
             [type]="mbsPopupType.danger"
             [closable]="true"
             [icon]="true"
             (close)="removeError()">{{ errorMessage }}</mbs-alert>
</div>

<ng-container *ngIf="stepsService.currentStep.getValue().parentId === stepId.DownloadBuild">
  <mbs-wizards-installation-step [view]="installationView"
                                 [buildsSelectionConfig]="buildsSelectionConfig"
                                 (stable)="onboardingService.stable$.next($event)"
                                 (hasError)="onboardingService.hasError$.next($event)"
                                 (completed)="onDownloadBuildStepCompleted($event)"></mbs-wizards-installation-step>
</ng-container>

<ng-container *ngIf="currentStep.parentId === stepId.ConfigureBackup">
  <div class="row no-gutters">
    <div class="col-3">
      <ul mbsTabset
          mode="vertical"
          [loadOnAppearance]="true"
          #activeContent="mbsTabset"
          class="bg-white h-100 p-0"
          (afterUpdate)="onTabClick($event)"
      >
        <ng-container *ngFor="let item of configureBackupConfig; let index = index">
          <li [mbsTabsetItem]="item.id"
              [disabled]="!stepsService.getStepById(item.id).touched"
              [active]="stepsService.getStepById(item.id).condition === stepCondition.Active"
          >
            <div mbsTabsetLink
                 role="button">
              <div class="d-flex align-items-baseline">
                <div class="mbs-onboarding-wizard-common-view_step-condition text-xxs"
                     [ngClass]="'-' + stepsService.getStepById(item.id).condition"> {{ index + 1 }} </div>
                <div class="d-flex flex-column">
                  <span class="font-weight-semibold"> {{item.title}} </span>
                  <span class="text-xs"> {{item.description}} </span>
                </div>
              </div>
            </div>

            <ng-container [ngSwitch]="item.id">
              <ng-template *ngSwitchCase="stepId.StorageAccount"
                           mbsTabsetContent>
                <div class="mbs-onboarding-wizard-common-view_substep-wrapper">
                  <mbs-storage-account-step [mode]="mode"
                                            [viewMode]="viewMode"
                                            [b2CredentialsForm]="b2CredentialsForm"></mbs-storage-account-step>
                </div>
              </ng-template>

              <ng-template *ngSwitchCase="stepId.BackupSource"
                           mbsTabsetContent>
                <div class="mbs-onboarding-wizard-common-view_substep-wrapper">
                  <mbs-backup-source-step
                    [plan]="plan"
                    [initialData]="backupData"
                    [settings]="{hid,isLinux, isNBF: !this.isLinux, backupVersionUpdated}"
                    (planTypeChanged)="onPlanTypeChanged($event)"
                    (selectedData)="onBackupDataChanged($event)"></mbs-backup-source-step>
                </div>
              </ng-template>

              <ng-template *ngSwitchCase="stepId.Notifications"
                           mbsTabsetContent>
                <div class="mbs-onboarding-wizard-common-view_substep-wrapper">
                  <mbs-onboarding-notifications-step [accountEmail]="provider.Email"
                                                     [initialData]="notificationsData"
                                                     (notificationsChanged)="onNotificationsDataChanged($event)"
                  ></mbs-onboarding-notifications-step>
                </div>
              </ng-template>
            </ng-container>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="col border-top border-right border-bottom"
         [mbsTabsetOutlet]="activeContent"></div>
  </div>
</ng-container>

<div *ngIf="!currentStep.isAutomatic"
     class="mt-4 d-flex justify-content-end row">
  <div class="col-auto"
       *ngIf="isBackButtonShown()">
    <mbs-button [disabled]="!(onboardingService.stable$ | async)"
                (click)="stepsService.transition(currentStep.prev)"> {{'onboarding:buttons.back' | i18next: { format: 'title' } }}</mbs-button>
  </div>

  <div class="col-auto"
       *ngIf="currentStep.next !== null">
    <mbs-button [disabled]="!(onboardingService.stable$ | async) || !(onboardingService.nextStepAvailable$ | async)"
                type="primary"
                (click)="stepsService.transition(currentStep.next, { direction: direction.Next })">{{'onboarding:buttons.next' | i18next : {
      format: 'title'
    } }}</mbs-button>
  </div>

  <div class="col-auto"
       *ngIf="currentStep.next === null">
    <mbs-button [disabled]="!(onboardingService.stable$ | async) || !isValid()"
                type="success"
                (click)="runBackup()">{{'onboarding:buttons.run' | i18next: { format: 'title' } }} </mbs-button>
  </div>
</div>
