import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { AssignLicenseDialogueComponent } from './components/assign-license-dialogue/assign-license-dialogue.component';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  imports: [CommonModule, MbsUiKitModule, I18NextModule],
  declarations: [AssignLicenseDialogueComponent],
  exports: [AssignLicenseDialogueComponent]
})
export class RmmLicensesModule {}
