<h4>{{'onboarding:notifications' | i18next: { format: 'title' } }}</h4>

<form [formGroup]="notificationsForm">
  <mbs-checkbox [label]="'wizards:notification_email_label' | i18next"
                formControlName="SendNotification"></mbs-checkbox>

  <div class="mt-2 ml-4">
    <ng-container *ngIf="onlyOnFailureControl">
      <mbs-radio [label]="'wizards:notification_email_when_fails_label' | i18next"
                 [value]="true"
                 formControlName="OnlyOnFailure"></mbs-radio>
      <mbs-radio [label]="'wizards:all_cases_label' | i18next"
                 [value]="false"
                 formControlName="OnlyOnFailure"></mbs-radio>
    </ng-container>

    <div class="mt-2">
      <mbs-input [label]="'common.email' | i18next"
                 [validationWhitespace]="true"
                 formControlName="Email"></mbs-input>
    </div>
  </div>
</form>
