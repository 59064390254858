import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from '../index';
import { isNil } from 'lodash';

@Pipe({ name: 'size' })
export class SizeConverterPipe implements PipeTransform {
  transform(bytes: number, params: { decimals?: number; placeholder?: string } = {} ): string {
    const { decimals = 2, placeholder = 'Unlimited' } = params;

    if (isNil(bytes)) return placeholder;

    return formatBytes(bytes, decimals);
  }
}
