import { Directive, forwardRef, Inject } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { debounceTime, defer, iif, Observable, of, tap } from 'rxjs';
import { ConfirmReason } from './ConfirmReason';
import { DataChangeWatcherService } from './data-change-watcher.service';

@Directive()
export abstract class DataChangeWatcherBase {
  abstract isValidSidepanel(): boolean;

  public canDiscardWithChanges = false;
  public detectChanges$: Observable<ConfirmReason>;

  protected changeDetector: DataChangeWatcherService;
  protected observableObjects: Array<{
    obj: any;
    getRawData: (obj: any) => any;
    previousData: any;
  }> = [];

  protected constructor(@Inject(forwardRef(() => DataChangeWatcherService)) changeDetector: DataChangeWatcherService) {
    this.changeDetector = changeDetector;
    this.changeDetector.attachComponent(this);
    this.detectChanges$ = iif(
      () => this.isChanged && !this.canDiscardWithChanges,
      defer(() => {
        return this.changeDetector.showSaveModal(this).pipe(tap((value) => {
          if (value === ConfirmReason.DISCARD) this.changeDetector.deattachComponent(this)
        }))
      }),
      of(ConfirmReason.DISCARD)
    )
  }

  protected attachObject<T>(obj: T, getRawData: (obj: T) => any): void {
    this.observableObjects = this.observableObjects.filter((ob) => ob.obj != obj);
    this.observableObjects.push({ obj, getRawData: getRawData, previousData: cloneDeep(getRawData(obj)) });
  }

  protected hold(obj?: any): void {
    if (obj) {
      const find = this.observableObjects.find((ob) => ob.obj == obj);
      find.previousData = find.getRawData(find.obj);
    } else {
      this.observableObjects.forEach((ob) => (ob.previousData = cloneDeep(ob.getRawData(ob.obj)) as unknown));
    }
  }

  get isChanged(): boolean {
    return this.observableObjects.length > 0 && this.observableObjects.some((ob) => !isEqual(ob.getRawData(ob.obj), ob.previousData));
  }

  destroy(): void {
    this.changeDetector.deattachComponent(this);
  }
}
