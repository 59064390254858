import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'mbs-ui-kit';
import { CommandService } from '../rmm/services/rmm-command.service';
import { TerminalNewComponent } from './components/terminal-new.component';
import { TerminalNewPrismService } from './terminal-new-prism.service';
import { TerminalNewService } from './terminal-new.service';

const components = [TerminalNewComponent];
@NgModule({
  exports: components,
  declarations: components,
  imports: [CommonModule, ReactiveFormsModule, ButtonModule],
  providers: [TerminalNewPrismService, CommandService, TerminalNewService]
})

export class TerminalNewModule {}
