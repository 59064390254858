<mbs-modal [isCustomModal]="true"
           [loading]="isLoading"
           [showFooter]="true"
           [title]="forceUpdateText + (!isGroupAction ? ': ' + computerName : '')"
           #modal>
  <div modal-body>
    <form *ngIf="!isLoading && (isGroupAction || backupAgent || rmmAgent || raAgent)"
          [formGroup]="forceUpdateForm"
          class="mbs-form">
      <mbs-alert *ngIf="isGroupAction || raAgent"
                 [type]="alertType.warning"
                 [icon]="true"
                 class="mb-4">
        {{ 'computers.module:modals:reAuthComputerForConnectCentrilizedConfiguration' | i18next }}
        <a *ngIf="('HelpMarketing' | can: 'read')"
           href="https://kb.msp360.com/kb10140"
           target="_blank">
          {{ 'buttons:learnMore' | i18next }}
        </a>
      </mbs-alert>
      <fieldset class="mbs-form_content">
        <p [ngClass]="isGroupAction || !isOneAvailableBuildOnly() ? 'mb-0' : ''"
           *ngIf="isGroupAction || isAvailableAndSupportedAnyOneBuild()">
          <!-- if using group force update -->
          <ng-container *ngIf="isGroupAction">
            Please select build version {{ isSandBoxAvailable ? '(sandbox or&nbsp;public)' : '' }} you want to&nbsp;update
          </ng-container>
          <!-- if don't using group force update and more one agent for updating -->
          <ng-container *ngIf="!isGroupAction && !isOneAvailableBuildOnly()">
            Please select available build version
            <!-- if have public and sandbox available updating -->
            <ng-container *ngIf="
                (isForceUpdateSupporting(backupAgent) && isAvailableVersion(backupAgent, true) && isAvailableVersion(backupAgent, false)) ||
                (isForceUpdateSupporting(rmmAgent) && isAvailableVersion(rmmAgent, true) && isAvailableVersion(rmmAgent, false))
              ">
              (sandbox build or&nbsp;public build)
            </ng-container>
            you want to&nbsp;update
          </ng-container>
          <!-- if don't using group force update and only one agent (Backup, RMM, or RA) and only one build for updating -->
          <ng-container *ngIf="!isGroupAction && isOneAvailableBuildOnly()">
            Do&nbsp;you really want to&nbsp;update your Agent?
          </ng-container>
        </p>
        <!-- if more one agent for updating -->
        <ng-container *ngIf="isGroupAction || !isOneAvailableBuildOnly()">
          <div class="row">
            <div [ngClass]="{
                    'col-6': isGroupAction || isPluralAgentBuilds(),
                    'col-12': !(isGroupAction || isPluralAgentBuilds()),
                    'mt-3': isGroupAction || isAvailableAndSupportedAnyOneBuild()
                  }"
                 *ngIf="isGroupAction || backupAgent">
              <section class="mbs-form-group mb-0">
                <h5 class="mbs-form-group_title mb-0">{{'app:products.backup' | i18next}} Agent</h5>
                <div class="row"
                     *ngIf="isGroupAction || isForceUpdateSupporting(backupAgent) && isAvailableBackup">
                  <div class="mt-2"
                       *ngIf="!(backupAgent && !rmmAgent && !raAgent)"
                       [ngClass]="!isGroupAction && !isPluralAgentBuilds() ? 'col-6' : 'col-12'">
                    <mbs-radio [formControlName]="agentType.Backup"
                               name="backupRadioControl"
                               [id]="'backupId' + forceUpdateAgentType.NotNecessary"
                               [value]="forceUpdateAgentType.NotNecessary"
                               label="Do not update"></mbs-radio>
                  </div>
                  <div class="mt-2"
                       [ngClass]="!isGroupAction && !isPluralAgentBuilds() ? 'col-6' : 'col-12'"
                       *ngIf="isGroupAction || isAvailableVersion(backupAgent, false)">
                    <mbs-radio [formControlName]="agentType.Backup"
                               name="backupRadioControl"
                               [id]="'backupId' + forceUpdateAgentType.Public"
                               [value]="forceUpdateAgentType.Public"
                               [label]="buildLabelTemplate"
                               [labelContext]="{ agent: backupAgent, isSandbox: false }"></mbs-radio>
                  </div>
                  <div class="mt-2"
                       [ngClass]="!isGroupAction && !isPluralAgentBuilds() ? 'col-6' : 'col-12'"
                       *ngIf="isSandBoxAvailable && (isGroupAction || isAvailableVersion(backupAgent, true))">
                    <mbs-radio [formControlName]="agentType.Backup"
                               name="backupRadioControl"
                               [id]="'backupId' + forceUpdateAgentType.Sandbox"
                               [value]="forceUpdateAgentType.Sandbox"
                               [label]="buildLabelTemplate"
                               [labelContext]="{ agent: backupAgent, isSandbox: true }"></mbs-radio>
                  </div>
                </div>
                <ng-container *ngIf="!isGroupAction && (!isForceUpdateSupporting(backupAgent) || !isAvailableBackup)">
                  <ng-template [ngTemplateOutlet]="buildAlertTemplate"
                               [ngTemplateOutletContext]="{ $implicit: backupAgent }"></ng-template>
                </ng-container>
              </section>
            </div>
            <div [ngClass]="{
                    'col-6': isGroupAction || isPluralAgentBuilds(),
                    'col-12': !(isGroupAction || isPluralAgentBuilds()),
                    'mt-3': isGroupAction || isAvailableAndSupportedAnyOneBuild()
                  }"
                 *ngIf="isGroupAction || rmmAgent">
              <section class="mbs-form-group mb-0">
                <h5 class="mbs-form-group_title mb-0">{{'app:products.rmm' | i18next}} Agent</h5>
                <div class="row"
                     *ngIf="isGroupAction || isForceUpdateSupporting(rmmAgent) && isAvailableRMM">
                  <div class="mt-2"
                       *ngIf="!(rmmAgent && !backupAgent && !raAgent)"
                       [ngClass]="!isGroupAction && !isPluralAgentBuilds() ? 'col-6' : 'col-12'">
                    <mbs-radio [formControlName]="agentType.RMM"
                               name="rmmRadioControl"
                               [id]="'rmmId' + forceUpdateAgentType.NotNecessary"
                               [value]="forceUpdateAgentType.NotNecessary"
                               label="Do not update"></mbs-radio>
                  </div>
                  <div class="mt-2"
                       [ngClass]="!isGroupAction && !isPluralAgentBuilds() ? 'col-6' : 'col-12'"
                       *ngIf="isGroupAction || isAvailableVersion(rmmAgent, false)">
                    <mbs-radio [formControlName]="agentType.RMM"
                               name="rmmRadioControl"
                               [id]="'rmmId' + forceUpdateAgentType.Public"
                               [value]="forceUpdateAgentType.Public"
                               [label]="buildLabelTemplate"
                               [labelContext]="{ agent: rmmAgent, isSandbox: false }"></mbs-radio>
                  </div>
                  <div class="mt-2"
                       [ngClass]="!isGroupAction && !isPluralAgentBuilds() ? 'col-6' : 'col-12'"
                       *ngIf="isSandBoxAvailable && (isGroupAction || isAvailableVersion(rmmAgent, true))">
                    <mbs-radio [formControlName]="agentType.RMM"
                               name="rmmRadioControl"
                               [id]="'rmmId' + forceUpdateAgentType.Sandbox"
                               [value]="forceUpdateAgentType.Sandbox"
                               [label]="buildLabelTemplate"
                               [labelContext]="{ agent: rmmAgent, isSandbox: true }"></mbs-radio>
                  </div>
                </div>
                <ng-container *ngIf="!isGroupAction && (!isForceUpdateSupporting(rmmAgent) || !isAvailableRMM)">
                  <ng-template [ngTemplateOutlet]="buildAlertTemplate"
                               [ngTemplateOutletContext]="{ $implicit: rmmAgent }"></ng-template>
                </ng-container>
              </section>
            </div>
            <div [ngClass]="{
                      'col-6': isGroupAction || isPluralAgentBuilds(),
                      'col-12': !(isGroupAction || isPluralAgentBuilds()),
                      'mt-3': isGroupAction || isAvailableAndSupportedAnyOneBuild() || (!isAvailableAndSupportedAnyOneBuild() && backupAgent && rmmAgent)
                  }"
                 *ngIf="isGroupAction || raAgent">
              <section class="mbs-form-group mb-0">
                <h5 class="mbs-form-group_title mb-0">{{'app:products.remote_desktop' | i18next}} Agent</h5>
                <div class="row"
                     *ngIf="isGroupAction || isForceUpdateSupporting(raAgent) && isAvailableRA">
                  <div class="mt-2"
                       [ngClass]="!isGroupAction && !isPluralAgentBuilds() ? 'col-6' : 'col-12'">
                    <mbs-radio [formControlName]="agentType.RA"
                               name="raRadioControl"
                               [id]="'raId' + forceUpdateAgentType.NotNecessary"
                               [value]="forceUpdateAgentType.NotNecessary"
                               label="Do not update"></mbs-radio>
                  </div>
                  <div class="mt-2"
                       [ngClass]="!isGroupAction && !isPluralAgentBuilds() ? 'col-6' : 'col-12'"
                       *ngIf="isGroupAction || isAvailableVersion(raAgent, false)">
                    <mbs-radio [formControlName]="agentType.RA"
                               name="raRadioControl"
                               [id]="'raId' + forceUpdateAgentType.Public"
                               [value]="forceUpdateAgentType.Public"
                               [label]="buildLabelTemplate"
                               [labelContext]="{ agent: raAgent, isSandbox: false }"></mbs-radio>
                  </div>
                </div>
                <ng-container *ngIf="!isGroupAction && (!isForceUpdateSupporting(raAgent) || !isAvailableRA)">
                  <ng-template [ngTemplateOutlet]="buildAlertTemplate"
                               [ngTemplateOutletContext]="{ $implicit: raAgent }"></ng-template>
                </ng-container>
              </section>
            </div>
          </div>
        </ng-container>
        <!-- if only one agent (Backup, RMM, or RA) and only one build for updating -->
        <p class="mb-0"
           *ngIf="!isGroupAction && isOneAvailableBuildOnly()">
          <ng-container *ngIf="isAgentTypeByApplicationID(agentType.Backup) && backupAgent">
            <span class="font-weight-semibold">{{'app:products.backup' | i18next}} Agent</span> will be updated to version
            {{ backupAgent.builds[isAvailableVersion( backupAgent, true ) ? buildVersionType.Sandbox : buildVersionType.Public].version }}
            {{ wrapTextBranch( isAvailableVersion( backupAgent, true ) ? 'Sandbox' : 'Public' ) }}
          </ng-container>
          <ng-container *ngIf="isAgentTypeByApplicationID(agentType.RMM) && rmmAgent">
            <span class="font-weight-semibold">{{'app:products.rmm' | i18next}} Agent</span> will be updated to version
            {{ rmmAgent.builds[isAvailableVersion( rmmAgent, true ) ? buildVersionType.Sandbox : buildVersionType.Public].version }}
            {{ wrapTextBranch( isAvailableVersion( rmmAgent, true ) ? 'Sandbox' : 'Public' ) }}
          </ng-container>
          <ng-container *ngIf="isAgentTypeByApplicationID(agentType.RA) && raAgent">
            <span class="font-weight-semibold">{{'app:products.remote_desktop' | i18next}} Agent</span> will be updated to version
            {{ raAgent.builds.public.version }}
            &nbsp;(Public)
          </ng-container>
        </p>
      </fieldset>
    </form>
    <!-- if agents cannot force update -->
    <mbs-alert *ngIf="!isLoading && !isGroupAction && !(backupAgent || rmmAgent || raAgent)"
               [type]="alertType.warning"
               class="mb-0">
      Cannot force the update. No agent installed.
    </mbs-alert>
  </div>
  <ng-container modalFooter>
    <mbs-button type="primary"
                *ngIf="isGroupAction || isAvailableAndSupportedAnyOneBuild()"
                [loading]="loading"
                [disabled]="!forceUpdateForm.valid"
                (click)="handleResolve()">Update
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">Close
    </mbs-button>
  </ng-container>
</mbs-modal>

<ng-template #buildLabelTemplate
             let-context>
  Update&nbsp;to {{ context.isSandbox ? 'Sandbox' : 'Public' }} Build{{ isGroupAction ? 's' : '' }}
  <div class="text-sm"
       *ngIf="!isGroupAction">
    {{ wrapTextBranch(
    context.agent.builds[context.isSandbox ? buildVersionType.Sandbox : buildVersionType.Public].version ) }}
  </div>
</ng-template>

<ng-template #buildAlertTemplate
             let-agent>
  <mbs-status class="mt-2"
              [icon]="getStatusClasses(agent)">
    <ng-container *ngIf="!agent.online">
      The Agent is offline
    </ng-container>
    <ng-container *ngIf="agent.online && !agent.forceUpdateSupporting">
      Failed to update automatically. Try to update the Agent manually.
    </ng-container>
    <ng-container *ngIf="agent.online && agent.forceUpdateSupporting">
      The latest version installed <span class="d-block text-sm mt-1">({{ agent.currentVersion }})</span>
    </ng-container>
  </mbs-status>
</ng-template>

<ng-template #errorTemplate
             let-context>
  <h5 *ngIf="isGroupAction">Computer{{ context.resultList.length !== 1 ? 's' : '' }}: {{ context.resultList.length }}</h5>
  <mbs-table-grid [multipleSelect]="false"
                  classesTable="-accordion"
                  rowClasses="background-light"
                  childHeaderClasses="background-white"
                  bindParentKey="hid"
                  bindChildren="childs"
                  [collapsibleMode]="true"
                  [viewMode]="sharedPersistentStateEnum.table"
                  [stripedTable]="false"
                  [showNav]="false"
                  [showHeaders]="false"
                  [headers]="headers"
                  [minHeight]="'200px'"
                  [height]="'calc(100vh - 8rem - 139px)'"
                  [showChildrenHeaders]="false"
                  [subtitleHeaders]="subHeaders"
                  [data]="context.resultList">
    <ng-template mbsTableCell
                 let-error>
      <div class="row align-items-center">
        <div class="col-auto text-center">
          <span class="mbs-table-grid_ico text-primary">
            <i class="fa fa-desktop text-primary"></i>
          </span>
        </div>
        <div class="col">
          <div class="mbs-table-grid_accordion-title">
            {{ error.name || error.displayName }}
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template mbsTableCell
                 let-error>
      <div class="row justify-content-end">
        <div class="col-auto lh-1"
             *ngFor="let child of error.childs">
          <mbs-status [icon]="child.ok ? 'ico ico-check-circle' : 'ico ico-exclamation-circle'"
                      [type]="child.ok ? 'success' : 'danger'">
            {{ child.agentType }}
          </mbs-status>
        </div>
      </div>
    </ng-template>

    <ng-template mbsTableCell
                 group="child"
                 let-child>
      <div class="force-update-details">
        <div class="font-weight-semibold mb-1">{{ child.agentType }}</div>
        <div class="form-row">
          <div class="col-auto lh-1">
            <span class="mbs-status">
              <span class="mbs-status_ico -sm fa fa-circle text-{{ child.ok ? 'success' : 'danger' }} mr-0"></span>
            </span>
          </div>
          <div class="col">
            {{ child.message }}
          </div>
        </div>
      </div>
    </ng-template>

  </mbs-table-grid>
</ng-template>
