import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import StorageLimit from '../models/StorageLimit';

// todo implement ngrx data
@Injectable({
  providedIn: 'root'
})
export class StorageLimitsService extends EntityCollectionServiceBase<StorageLimit> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient) {
    super('StorageLimit', serviceElementsFactory);
  }
}
