import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, UntypedFormGroup } from '@angular/forms';
import { StepsService } from '@modules/wizards/onboarding/services/steps/steps.service';
import { BaseStep } from '@modules/wizards/onboarding/steps/shared/base/base.step';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppsService } from '@services/apps/apps.service';

@UntilDestroy()
@Component({
  selector: 'mbs-apps-configure-backup-step',
  templateUrl: './configure-backup.component.html'
})
export class AppsConfigureBackupComponent<StepId> extends BaseStep<StepId> implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;

  public readonly stepId = 'whatToBackup';
  public labelsControl: AbstractControl;
  public valueControl: FormArray;

  constructor(private appsService: AppsService, private onboardingService: OnboardingService, stepService: StepsService<StepId>) {
    super(stepService);
  }

  ngOnInit(): void {
    this.labelsControl = this.form.get('sources.names');
    this.valueControl = this.form.get('sources.selected') as FormArray;
    this.setState(this.valueControl.value.some(Boolean));

    this.valueControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.setState(value.some(Boolean));
    });

    if (!this.labelsControl.value?.length) {
      this.appsService
        .getServices(this.form.get('accountId').value)
        .pipe(untilDestroyed(this))
        .subscribe((sources) => {
          this.labelsControl.setValue(sources);

          sources.forEach(() => {
            this.valueControl.push(new FormControl(false));
          });
        });
    }
  }

  private setState(value: boolean): void {
    this.onboardingService.stable$.next(value);
    this.isValid = value;
  }

  public prepareSource(source: string): string {
    if (source === 'TeamDrives') {
      return 'Shared Drives';
    }

    return source;
  }

  ngOnDestroy() {
    this.isValid = this.valueControl.value?.some((val) => val);
    super.ngOnDestroy();
  }
}
