import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { CompaniesFacade } from '@facades/companies.facade';
import { ComputersFacade } from '@facades/computers.facade';
import AdministratorInCamelCase from '@models/AdministratorInCamelCase';
import Company from '@models/Company';
import Computer, { ComputerWithStatus } from '@models/Computer';
import { SmartSearchValues } from '@models/SmartSearchValues';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdministratorService } from '@services/administrator.service';
import { ComputerSmartSearchService } from '@services/smart-search-template.service';
import { SmartSearchTemplates } from '@services/smart-search-templates';
import { mediumDateWithTime } from '@utils/date';
import { RDSelectComputersListFactory } from '@utils/models-converter';
import { SmartSearchUtils } from '@utils/smartSearchUtils';
import { isEqual, merge } from 'lodash';
import { ListSelectGroupModel, MbsPopupType, MbsSize, ModalComponent, ModelTemplate, TableHeader } from 'mbs-ui-kit';
import { PanelRowData } from 'mbs-ui-kit/accordion/accordion.component';
import { fromEvent, map, Observable } from 'rxjs';
import { GroupTaskOsTypes } from '../group-tasks/store/model';
import { GroupTaskOsTypeNames } from '../schedule-group-action/store/group-action/group-action.model';
import EditGroupTaskUtility from '../schedule-group-action/utility/edit-group-task-utility';

export enum SelectEntityType {
  administrators = 'Administrator List',
  computers = 'Computer List',
  companies = 'Company List'
}

@UntilDestroy()
@Component({
  selector: 'mbs-utility-selection-modal',
  templateUrl: './utility-selection-modal.component.html'
})
export class UtilitySelectionModalComponent implements OnInit, AfterViewInit {
  public readonly mediumDateWithTime = mediumDateWithTime;
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  form: UntypedFormGroup;

  data: ListSelectGroupModel<any>[] = [];
  selected: ListSelectGroupModel<any>[] = [];
  disableConfirmButton = true;

  selectedGroupsWithComputers: ListSelectGroupModel<Computer>[] = [];
  selectedAdministratorsIds: string[] = [];
  selectedCompaniesIds: string[] = [];
  initialSelectedComputersCount = 0;
  selectedComputersCount = 0;
  selectedAdminsCount = 0;
  loading = true;

  applyTo: SelectEntityType;
  applyToEnum = SelectEntityType;

  searchTemplates: ModelTemplate<any>[] = [];
  public headers: TableHeader[] = [
    {
      name: 'value',
      overflow: true,
      class: 'pl-0',
      gridColSize: '10fr'
    }
  ];

  filters: SmartSearchValues = {};
  filterTerm = '';
  computerSearchForm = new UntypedFormGroup({
    search: new FormControl('')
  });

  checkboxData: PanelRowData[][] = [];

  selectComputersAccordionPanelIdPrefix = 'apply-to-computer-accordion';
  noComputersText: string;

  enabled = false;
  needAddUnsupportedStatus = false;
  modalTitle = '';
  saveButtonText = '';
  osType: GroupTaskOsTypes = GroupTaskOsTypes.Windows;

  activeIds = [];

  computers$: Observable<Computer[]>;
  companies$: Observable<Company[]>;
  administrators$: Observable<AdministratorInCamelCase[]>;

  public withHistoryMode = false;
  public showModalFooterText = false;
  public readonly alertType = MbsPopupType;
  public readonly alertSize = MbsSize;
  public computerSearchWereActivated = false;

  private searchState: string = null;
  private emitSearchHandler = false;

  get voidComputersList(): boolean {
    return !this.data || !this.data.length;
  }

  constructor(
    private el: ElementRef,
    private computersFacade: ComputersFacade,
    private companiesFacade: CompaniesFacade,
    private adminService: AdministratorService,
    private templatesService: SmartSearchTemplates,
    private smartSearch: ComputerSmartSearchService,
    private editGroupTaskUtility: EditGroupTaskUtility
  ) {
    this.searchTemplates.push(templatesService.companiesItems);
  }

  ngOnInit(): void {
    this.modalTitle = this.baseModal.data.modalTitle;
    this.noComputersText = this.baseModal.data.noComputersText;
    this.saveButtonText = this.baseModal.data.saveButtonText;
    this.applyTo = this.baseModal.data.applyTo;
    this.osType = this.baseModal.data?.osType ?? GroupTaskOsTypeNames.Windows;

    queueMicrotask(() => {
      switch (this.applyTo) {
        case this.applyToEnum.administrators:
          this.selectedAdministratorsIds = this.baseModal.data.model || [];
          this.administrators$ = this.adminService.getAll();
          this.setTableDataForAdminList(this.administrators$);
          break;
        case this.applyToEnum.companies:
          this.companies$ = this.companiesFacade.getData$();
          this.companiesFacade.loadData();
          this.selectedCompaniesIds = this.baseModal.data.model || [];
          this.setTableDataForCompanies(this.companies$);
          break;
        case this.applyToEnum.computers:
          this.setComputerSearchData(this.baseModal.data);
          if (this.baseModal.data.computers && this.isCompanyEntityInComputers(this.baseModal.data.computers)) {
            this.selectedGroupsWithComputers = [...(this.baseModal.data.model || [])];
            this.PrepareComputersListSelectGroups(this.baseModal.data.computers);
            this.activeIds = this.data.map((computerInCompany) => this.selectComputersAccordionPanelIdPrefix + computerInCompany.id);
            this.loading = false;
          } else {
            this.selectedGroupsWithComputers = [...(this.baseModal.data.model || [])];
            this.setTableDataForComputers(this.computers$);
          }
          break;
        default:
          this.close();
      }
    });
  }

  isCompanyEntityInComputers(data: ListSelectGroupModel<Computer>[]): boolean {
    return !!data?.length && data.every((group) => group?.items?.every((computer) => !!computer.company));
  }

  setComputerSearchData(data: any): void {
    if (data?.isGroupActionUseCase) {
      this.searchTemplates = [...data.smartSearchTemplates];
      this.emitSearchHandler = true;
      this.computers$ = this.getCompanyDataInComputersFix();
    } else {
      this.computers$ = this.computersFacade.getData$(true).pipe(map((response) => response.map((comp) => ({ id: comp.hid, ...comp }))));
    }
  }

  getCompanyDataInComputersFix(): Observable<Computer[]> {
    return this.baseModal.data?.getComputersFromStore.pipe(map((computers: any) => this.getProperComputerEntity(computers)));
  }

  getProperComputerEntity(computers: any) {
    return computers.map(
      (computer) =>
        Object.assign(
          {},
          computer,
          { id: computer.hid },
          { company: computer.companyName ? { id: computer.companyId, name: computer.companyName } : null },
          { osType: computer.osType },
          { os: computer.operationSystemID.toLowerCase() }
        ) as Computer
    );
  }

  ngAfterViewInit(): void {
    /* @TODO: set config for backspace\enter keys behavior for WizardComponent. now used dirty hack! */
    merge(
      fromEvent(this.el.nativeElement, 'keydown'),
      fromEvent(this.el.nativeElement, 'keypress'),
      fromEvent(this.el.nativeElement, 'keyup')
    )
      .pipe(untilDestroyed(this))
      .subscribe((event: KeyboardEvent) => event.stopPropagation());
  }

  setTableDataForAdminList(administrators$: Observable<AdministratorInCamelCase[]>): void {
    administrators$.pipe(untilDestroyed(this)).subscribe((adminList) => {
      this.data = adminList
        .filter((admin) => !admin.isProvider && admin.enabled)
        .map((admin) => {
          return new ListSelectGroupModel({
            id: admin.id,
            title: admin.email
          });
        });
      this.checkboxData = this.data.map((admin) => this.GetGroupCheckboxData(admin));
      this.loading = false;
    });
  }

  setTableDataForCompanies(companies$: Observable<Company[]>): void {
    companies$.subscribe((companyList) => {
      this.data = companyList.map((company) => {
        return new ListSelectGroupModel({
          id: company.id,
          title: company.name
        });
      });
      this.checkboxData = this.data.map((company) => this.GetGroupCheckboxData(company));
      this.loading = false;
    });
  }

  setTableDataForComputers(computers$: Observable<ComputerWithStatus[]>): void {
    computers$.pipe(untilDestroyed(this)).subscribe((response) => {
      const computers = [...response];
      this.PrepareComputersListSelectGroups(computers);
      this.activeIds = this.data.map((computerInCompany) => this.selectComputersAccordionPanelIdPrefix + computerInCompany.id);
      this.loading = false;
    });
  }

  PrepareComputersListSelectGroups(computers: Computer[]): void {
    this.data = RDSelectComputersListFactory.ConvertComputersToCompaniesListSelectGroupModelsBySelectedComputersIds(
      computers,
      this.baseModal.data.model
    );
    this.checkboxData = this.data.map((x) => this.GetGroupCheckboxData(x));
    this.updateShownGroupsAndItems();
  }

  GetGroupCheckboxData(group: ListSelectGroupModel<Computer>): PanelRowData[] {
    return group.items.map((item: ComputerWithStatus) => {
      const unsupported = item.lastStatus === 'Unsupported';
      return {
        data: {
          ...item
        },
        checked: group.selectedItems.findIndex((x) => x.id === item.id) >= 0,
        disabled: unsupported
      } as PanelRowData;
    });
  }

  updateFilters(event): void {
    this.computerSearchWereActivated = true;
    if (event) {
      if (this.emitSearchHandler) {
        this.handleSearch(event);
        return;
      }

      this.filters = SmartSearchUtils.getValues(event);
      this.filterTerm = SmartSearchUtils.getJoinedValue(this.filters, 'words').toLowerCase();
      this.updateShownGroupsAndItems();
    }
  }

  updateShownGroupsAndItems(): void {
    const companyFilterTerm = SmartSearchUtils.getJoinedValue(this.filters, this.templatesService.companiesItems.tag).toLowerCase();
    for (let i = 0; i < this.data.length; i++) {
      const group = this.data[i];
      group.shown = true; // reset `group.shown` for repeat filtered
      if (companyFilterTerm && !group.title.toLowerCase().includes(companyFilterTerm)) {
        group.shown = false;
        continue;
      }
      if (!companyFilterTerm && !this.filterTerm) {
        group.shown = true;
      } else {
        this.updateShownGroupItems(group, i);
      }
    }
  }

  private updateShownGroupItems(group: ListSelectGroupModel<Computer>, groupIndex: number): void {
    let showGroup = false;
    if (!this.filterTerm) {
      return;
    }
    if (group.title.toLowerCase().includes(this.filterTerm)) {
      group.shown = true;
      return;
    }
    for (let j = 0; j < group.items.length; j++) {
      const item = group.items[j];
      if (
        (item.name && item.name.toLowerCase().includes(this.filterTerm)) ||
        (item.displayName && item.displayName.toLowerCase().includes(this.filterTerm))
      ) {
        showGroup = true;
      }
    }
    group.shown = showGroup;
  }

  handleTopChange(event): void {
    this.data.forEach((panel) => {
      this.headerCheckChanged({ event, panelId: panel.id });
    });
  }

  checkChanged(change: { panelId: string; row: PanelRowData; event: boolean }): void {
    const id = change.panelId.replace(this.selectComputersAccordionPanelIdPrefix, '');
    const group = this.data[this.data.findIndex((x) => x.id == id)];
    const element = group.items[group.items.findIndex((x) => x.id === change.row.data.id)];
    const elementInSelecctedIndex = group.selectedItems.findIndex((x) => x.id === change.row.data.id);
    const isElementSelectedAlready = elementInSelecctedIndex >= 0;
    if (change.row.checked) {
      if (!isElementSelectedAlready) {
        group.selectedItems.push(element);
      }
    } else {
      if (isElementSelectedAlready) {
        group.selectedItems = group.selectedItems.filter((item) => item.id !== element.id);
      }
    }
  }

  handleSelectedCountAll(count): void {
    this.selectedComputersCount = count;
    if (this.withHistoryMode) {
      if (this.selectedComputersCount !== this.initialSelectedComputersCount) {
        this.disableConfirmButton = false;
      } else {
        const hashMapData: { [key: string]: ListSelectGroupModel<Computer> } = {};
        this.data.forEach((i) => (hashMapData[i.id] = i));
        this.disableConfirmButton = !this.baseModal.data.model.some((item) => {
          return hashMapData[item.id] && item.selectedItems.some((si) => !hashMapData[item.id].selectedItems.includes(si));
        });
      }
    }
  }

  headerCheckChanged(change: { event: boolean; panelId: string }): void {
    const id = change.panelId.replace(this.selectComputersAccordionPanelIdPrefix, '');
    const group = this.data[this.data.findIndex((x) => x.id == id)];
    group.selectedItems =
      change.event && group.shown
        ? group.items.filter((c: ComputerWithStatus) => c.lastStatus !== 'Excluded' && c.lastStatus !== 'Unsupported')
        : [];
  }

  changeSelectedCompaniesHandler(event): void {
    this.selectedCompaniesIds = [...event];
  }

  changeSelectedAdminsHandler(event): void {
    this.selectedAdministratorsIds = [...event];
  }

  close(): void {
    this.baseModal.close();
  }

  save(): void {
    switch (this.applyTo) {
      case SelectEntityType.companies:
        // eslint-disable-next-line no-case-declarations
        const companyGroupsToReturn = [];
        for (const id of this.selectedCompaniesIds) {
          const companyIndex = this.data.findIndex((x) => x.id === id);
          if (companyIndex >= 0) {
            companyGroupsToReturn.push(this.data[companyIndex]);
          }
        }
        this.baseModal.save(companyGroupsToReturn);
        break;
      case SelectEntityType.computers:
        this.selectedGroupsWithComputers = this.data.filter((x) => x.selectedItems.length > 0);
        this.baseModal.save(this.selectedGroupsWithComputers);
        break;
      case SelectEntityType.administrators:
        // eslint-disable-next-line no-case-declarations
        const admins = [];
        for (const id of this.selectedAdministratorsIds) {
          const adminIndex = this.data.findIndex((x) => x.id === id);
          if (adminIndex >= 0) {
            admins.push(this.data[adminIndex]);
          }
        }
        this.baseModal.save(admins);
        break;
      default:
        this.close();
    }
  }

  getCompName(computer: Computer): string {
    if (computer.name) return Computer.getComputerName(computer);
    if (computer.computerName) return computer.computerTag ? `${computer.computerTag} [${computer.computerName}]` : computer.computerName;

    return '-';
  }

  //  Group Action use case
  fetchComputers(params: any = new HttpParams()): void {
    this.editGroupTaskUtility
      .getComputerListForGActionStream(this.osType, params)
      .pipe(
        map((computers: any) => this.getProperComputerEntity(computers)),
        untilDestroyed(this)
      )
      .subscribe((computers) => this.PrepareComputersListSelectGroups(computers));
  }

  handleSearch(search: any) {
    if (!search) return;
    if (isEqual(this.searchState, search)) {
      return;
    }

    this.searchState = search;
    const params = this.smartSearch.getHttpParams(search);
    this.fetchComputers(params);
  }
}
