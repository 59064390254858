import { NgLocalization } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import HypervInfo from '@models/rmm/HypervInfo';
import RmmCommand from '@models/rmm/RmmCommand';
import RmmLastStatData from '@models/rmm/RmmLastStatData';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmmService } from '@services/rmm.service';
import { exportToCsv } from '@utils/cvs';
import { baseDateFileNameFormatHoursAndMinutes, cobbledDateFormat, mediumDateWithTime } from '@utils/date';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep, noop } from 'lodash';
import { get } from 'lodash/fp';
import { ModalService, ModalSettings, SharedPersistentStateEnum, TableHeader, ToastService } from 'mbs-ui-kit';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import * as moment from 'moment';
import { merge, of, throwError } from 'rxjs';
import { concatMap, filter as rxfilter, finalize, map, mapTo, switchMap } from 'rxjs/operators';
import { RmmTabBaseComponent } from '../rmm-tab-base.component';

enum HyperVOperation {
  START = 'start',
  DISABLE = 'turn off',
  REBOOT = 'restart'
}
@UntilDestroy()
@Component({
  selector: 'mbs-hyperv-tab',
  templateUrl: './hyperv-tab.component.html'
})
export class HypervTabComponent extends RmmTabBaseComponent<HypervInfo> implements OnInit {
  @Input() computerName: string;
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;
  public translationKey = 'rmm-side-panel:hyperVTab.';
  public shortHeaders: TableHeader[] = [
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.name'),
      overflow: true,
      sort: 'elementName',
      gridColSize: '38fr',
      gridColMin: '100px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.state'),
      overflow: true,
      sort: 'enabledState',
      gridColSize: '27fr',
      gridColMin: '100px'
    }
  ];

  public fullHeaders: TableHeader[] = [
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.name'),
      overflow: true,
      sort: 'elementName',
      gridColSize: '33fr',
      gridColMin: '260px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.state'),
      overflow: true,
      sort: 'enabledState',
      gridColSize: '22fr',
      gridColMin: '200px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.location'),
      overflow: true,
      sort: 'address',
      gridColSize: '25fr',
      gridColMin: '130px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.createDate'),
      overflow: true,
      sort: 'creationTime',
      gridColSize: '47fr',
      gridColMin: '180px',
      class: '-end'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.guid'),
      overflow: true,
      sort: 'name',
      gridColSize: '22fr',
      gridColMin: '260px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.heartbeat'),
      sort: 'heartbeat',
      gridColSize: '24fr',
      gridColMin: '100px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.healthState'),
      sort: 'healthState',
      gridColSize: '27fr',
      gridColMin: '90px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.memoryUsage'),
      sort: 'memoryUsage',
      gridColSize: '27fr',
      gridColMin: '100px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.cores'),
      sort: 'numberOfProcessors',
      gridColSize: '14fr',
      gridColMin: '80px'
    },
    // {
    //   name: 'Memory',
    //   sort: 'memoryAvailable',
    //   gridColSize: '26fr',
    //   gridColMin: '70px'
    // },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.replicationMode'),
      sort: 'replicationMode',
      gridColSize: '27fr',
      gridColMin: '120px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.shielded'),
      sort: 'shielded',
      gridColSize: '27fr',
      gridColMin: '96px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.swapFiles'),
      sort: 'swapFilesInUse',
      gridColSize: '27fr',
      gridColMin: '96px'
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.versions'),
      sort: 'version',
      gridColSize: '27fr',
      gridColMin: '88px'
    }
  ];

  @Input() public isOnline: boolean;
  @Input() public readonly: boolean;

  public get disabledStart(): boolean {
    return this.selectedHyperV.length === 0 || this.selectedHyperV.some((s) => s.enabledState === 'VM is running');
  }

  public get disabledStop(): boolean {
    return this.selectedHyperV.length === 0 || this.selectedHyperV.some((s) => s.enabledState === 'VM is turned off');
  }

  public get disabledRestart(): boolean {
    return this.selectedHyperV.length === 0 || this.selectedHyperV.some((s) => s.enabledState === 'VM is turned off');
  }

  public selectedHyperV: HypervInfo[] = [];
  public hyperVOperation = HyperVOperation;
  private commandMap = {
    [HyperVOperation.START]: 'Enabled',
    [HyperVOperation.DISABLE]: 'Disabled',
    [HyperVOperation.REBOOT]: 'Reboot'
  };

  constructor(
    private rmmService: RmmService,
    private rmmCommands: CommandService,
    private modal: ModalService,
    private toastService: ToastService,
    private localization: NgLocalization,
    private i18n: I18NextPipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.orderBy = {
      column: 'elementName',
      direction: 'asc'
    };

    this.initStreams();

    this.headers = this.isModal ? this.fullHeaders : this.shortHeaders;
    this.searchFields = ['elementName'];

    this.fetchData();
  }

  initStreams() {
    const lastStat$ = this.rmmService.fetchLastData<RmmLastStatData<HypervInfo>>('hyperv').pipe(
      map(get('data')),
      finalize(() => this.onLoad$.next(false))
    );

    const dataLatest$ = this.onFetchLastData$.pipe(concatMap(() => lastStat$));

    dataLatest$.pipe(rxfilter(Boolean), untilDestroyed(this)).subscribe((stat: any[] = []) => {
      this.allData = cloneDeep(stat);
      this.normalizeMemory();
      this.formatDate();
      this.updateData(this.allData);
    });
  }

  formatDate(): void {
    this.allData.forEach((r) => {
      if (r.creationTime) {
        r.creationTime = moment.utc(r.creationTime.split('.')[0], cobbledDateFormat).toISOString();
      }
    });
  }

  normalizeMemory() {
    this.allData = this.allData.map((item) => ({
      ...item,
      memoryUsage: Number(item.memoryUsage),
      memoryAvailable: Number(item.memoryAvailable)
    }));
  }

  getCachedData() {
    Promise.resolve().then(() => {
      this.onLoad$.next(true);
      this.onGetCachedData$.next({});
    });
  }

  fetchStatData(): void {
    this.onLoad$.next(true);
    this.onFetchLastData$.next({} as any);
  }

  fetchLiveData(): void {
    this.onLoad$.next(true);
    this.onFetchLiveData$.next({} as any);
  }

  fetchData(): void {
    this.fetchStatData();
  }

  itemsChecked(items: HypervInfo[]): void {
    this.selectedHyperV = items;
  }

  resetSelected() {
    this.selectedHyperV = [];
    this.fetchStatData();
  }

  handleHyperVInstance(operation: HyperVOperation): void {
    const commandStr = operation.charAt(0).toUpperCase() + operation.slice(1);

    let selectedHyperVStr: string;
    if (this.selectedHyperV.length === 1) {
      selectedHyperVStr = `${this.i18n.transform(this.translationKey + 'virtualMachine')} <span class="font-weight-semibold">${
        this.selectedHyperV[0].elementName
      }</span>`;
    } else if (this.selectedHyperV.length > 1) {
      selectedHyperVStr = `${this.i18n.transform(
        this.translationKey + 'virtualMachines'
      )} <span class="font-weight-semibold">${this.selectedHyperV.map((item) => item.elementName).join(', ')}</span>`;
    }
    const confirmMessage = `${commandStr} the ${selectedHyperVStr}?`;

    const modalSettings: ModalSettings = {
      header: { title: `${commandStr} ${this.i18n.transform(this.translationKey + 'virtualMachineTitle')}` },
      footer: { okButton: { text: commandStr } }
    };

    if (operation === 'turn off') {
      modalSettings.footer.okButton.type = 'danger';
    }

    this.modal
      .open(modalSettings, confirmMessage)
      .then(() => {
        const allCommands = this.selectedHyperV.map((instance) => {
          const command = new RmmCommand(this.commandMap[operation]).addParam('VMNAME', instance.elementName);
          return this.rmmCommands.sendCommandAsync('HyperVCmd', command, this.hid, true).pipe(
            switchMap((response: any) => (response?.error?.code ? throwError(() => response) : of(response))),
            mapTo(instance.elementName)
          );
        });

        merge(...allCommands).subscribe(
          (instance) => {
            const userOperation = operation === HyperVOperation.DISABLE ? 'turned off' : `${operation}ed`;
            this.toastService.success(
              `The <span class="font-weight-semibold">${instance}</span> ${this.i18n.transform(
                this.translationKey + 'virtualMachineWas'
              )} ${userOperation}`
            );
          },
          noop, // show error toast in interceptor
          () => {
            // after complete all commands
            setTimeout(() => this.fetchStatData(), 3000);
          }
        );
      })
      .catch(noop);
  }

  trackBy(index: number, item: ExtendedTableRow): string {
    return (item?.item as HypervInfo)?.name;
  }

  export() {
    exportToCsv(
      this.getTableName(),
      this.headers.map((header) => header.name),
      this.headers.map((header) => header.sort),
      this.data.map((item) => ({
        ...item,
        creationTime: moment(item.creationTime).format(mediumDateWithTime)
      }))
    );
  }

  public getTableName(): string {
    return `${this.computerName} ${this.i18n.transform('rmm-side-panel:sidePanelTabsName.hyperVManager')} ${moment().format(
      baseDateFileNameFormatHoursAndMinutes
    )}`;
  }
}
