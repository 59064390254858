<h4>{{ 'rmm.module:groupActions.stepConfigureAction.uninstallSoftTitle' | i18next: { format: 'title' } }}</h4>

<mbs-form-group [formGroup]="searchForm">
  <mbs-input [placeholder]="'Search'"
             [formControlName]="'searchValue'"
             [validationWhitespace]="true"
             [clearButton]="true"
             (clear)="handleClear($event)"
             (buttonClick)="handleSearch($event)"
             [appendButtons]="[{
     text: '',
     id: 'group-action-search',
     type: 'secondary',
     icon: 'ico ico-Search',
     loading: isLoading
  }]">
  </mbs-input>
</mbs-form-group>

<mbs-alert [type]="alertType.warning"
           [class]="'mb-2'">
  {{ 'rmm.module:groupActions.stepConfigureAction.uninstallSoftBanner' | i18next }}
</mbs-alert>

<mbs-table-nav [switcherView]="false">

  <ng-template mbsTableFilter="item">
    <mbs-button [type]="'primary'"
                [size]="'sm'"
                [isCtrl]="true"
                customClasses="font-weight-semibold {{ false ? 'text-primary' : 'text-black' }}">

      <!-- (click)="showSelectedComputer = false" -->
      {{ 'app:labels.software' | i18next: { format: 'title' } }}: {{ total }}
    </mbs-button>
  </ng-template>

  <ng-template mbsTableFilter="item">

    <mbs-button [type]="'primary'"
                [size]="'sm'"
                [isCtrl]="true"
                customClasses="font-weight-semibold {{ false ? 'text-primary' : 'text-black' }}">
      {{ 'app:labels.selected' | i18next: { format: 'title' } }}: {{ selectedCount }}
    </mbs-button>
  </ng-template>
</mbs-table-nav>

<mbs-table-grid class="ph-no-capture"
                classesTable="mb-0"
                [loading]="loading$ | async"
                [data]="dataToShow$ | async"
                [showNav]="false"
                [headers]="headers"
                [multipleSelect]="false"
                [selectedItems]="selectedItems"
                [keepState]="true"
                [selectable]="true"
                [selectAllIgnoreDisabled]="true"
                [changeSortState]="sort$ | async"
                [mbsTableSortGroup]="sort$ | async"
                [showTableCheckboxes]="true"
                (sort)="handleSort($event)"
                (changeSelected)="select($event)"
                [switcherView]="false"
                [maxHeight]="'32vh'">

  <ng-template [mbsTableHeader]="'rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderNumberOfPC' | i18next"
               let-header>
    <div [sortable]="header.sort"
         class="mbs-table-grid_cell -sortable">
      <span sortArrow></span>
      <span>
        {{ 'rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderNumberOfPC' | i18next }}
      </span>
    </div>
  </ng-template>

  <ng-template mbsTableCell
               let-data>
    <mbs-text-ellipsis placement="top-left"
                       container="body"
                       [tooltip]="data.name">{{ data.name || ('emptyValue' | i18next) }}</mbs-text-ellipsis>
  </ng-template>
  <ng-template mbsTableCell
               let-data>
    <mbs-text-ellipsis placement="top-left"
                       container="body"
                       [tooltip]="data.vendor">{{ data.vendor || ('emptyValue' | i18next) }}</mbs-text-ellipsis>
  </ng-template>
  <ng-template mbsTableCell
               let-data>
    <div [ngbTooltip]="getComputerNames(data)"
         placement="top"
         container="body"
         class="cursor-default">
      {{ data.computerCount || ('emptyValue' | i18next) }} {{ data.computers.length === computersCount ?
      '(All)' : '' }}
    </div>
  </ng-template>
</mbs-table-grid>
