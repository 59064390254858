import { Directive } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfirmReason } from './ConfirmReason';
import { DataChangeWatcherBase } from './data-change-watcher-base.directive';
import { DataChangeWatcherService } from './data-change-watcher.service';

@Directive()
export abstract class DataPageBase extends DataChangeWatcherBase {
  abstract handleSave(): Observable<boolean>;

  protected constructor(changeDetector: DataChangeWatcherService) {
    super(changeDetector);

    this.detectChanges$ = this.detectChanges$.pipe(
      switchMap((modalResult: ConfirmReason) => {
        if (modalResult === ConfirmReason.SAVE) {
          return this.handleSave().pipe(map(() => modalResult));
        }
        return of(modalResult);
      })
    );
  }

  handleSaveVoid(): void {
    this.handleSave().subscribe();
  }
}
