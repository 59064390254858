import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilsModule } from '@utils/utils.module';
import { AbilityModule } from 'ability';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { ModalTabContentComponent } from './modal-tab-content.component';

const declarations = [ModalTabContentComponent];

@NgModule({
  imports: [CommonModule, MbsUiKitModule, AbilityModule, UtilsModule, NgbModule],
  exports: declarations,
  declarations: declarations,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalTabContentModule {}
