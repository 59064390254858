import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { FindResult, RegTreeItem } from '@models/rmm/RmmRegEditTypes';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RmmRegEditService } from '@services/rmm-regedit.service';
import { AgentOptions } from '@shared/models/AgentOptions';
import { generateUid } from '@utils/generateUid';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { MbsPopupType, ModalService, ModalSettings, ToastService, TreeComponent, TreeElement } from 'mbs-ui-kit';
import { BreadcrumbsItem } from 'mbs-ui-kit/breadcrumbs/breadcrumbs.model';
import { BehaviorSubject, filter, map, noop, Observable, switchMap } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import {
  DefaultValueName,
  ExtendedTreeElement,
  InitialTreeElements,
  RegEditTreeIconPath,
  RegistryTypes,
  RegistryValueKind
} from './constants';
import { RegTree } from './reg-tree';
import { getStringFromBase64Data } from './reg-tree-utility';

type RegistryTreeElement = TreeElement & { path?: string };

@UntilDestroy()
@Component({
  selector: 'mbs-remote-registry-tab',
  templateUrl: './remote-registry-tab.component.html',
  styleUrls: ['./remote-registry-tab.component.scss']
})
export class RemoteRegistryTabComponent implements OnInit {
  @ViewChild('editForm', { static: true, read: TemplateRef }) editForm: TemplateRef<any>;
  @ViewChild('messageBox', { static: true, read: TemplateRef }) messageBox: TemplateRef<any>;
  @ViewChild('treeComponent', { read: ElementRef }) treeComponent: ElementRef;
  @Input() public hid: string;
  @Input() regTree: RegTree;
  @Input() readOnly: boolean;
  @Input() agentOptions: AgentOptions;

  public disableChildren = false;
  public dontSelectChildren = false;
  public breadCrumbs = [];
  public addKeyMode = false;
  public addKeyValueMode = true;
  public keyMode = false;
  public messageText = '';
  public searchString = '';
  public registryTypesList = RegistryTypes;
  private previousSearch: string;

  public registrySearchForm = new UntypedFormGroup({
    searchValue: new FormControl('')
  });
  public isLoading = false;

  public nothingFound = false;
  public readonly alertType = MbsPopupType;

  public form = new UntypedFormGroup({
    name: new FormControl('', [this.formNameValidator.bind(this)]),
    value: new FormControl(''),
    regType: new FormControl('')
  });

  private disableFormSave$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  get valueInputType(): string {
    return this.form.get('regType').value === RegistryValueKind.MultiString ? 'textarea' : 'text';
  }

  @ViewChild(TreeComponent, { read: TreeComponent }) registryTree: TreeComponent;
  @ViewChildren(NgbDropdown) dropdownObjects: QueryList<NgbDropdown>;
  private dropDownIsOpen = false;

  // Scroll processing event for mouse and touchpad uses 'wheel'
  @HostListener('document:wheel', ['$event'])
  private onScroll($event: Event): void {
    if (this.dropDownIsOpen) {
      this.dropdownObjects.forEach((el) => {
        if (el.isOpen()) {
          this.dropDownIsOpen = false;
          el.close();
        }
        if (!this.dropDownIsOpen) return;
      });
    }
  }

  /* NgbDropDown OnChange event handler
   * Catching only true flag. False flag will be sent as the last one in case
   * that we are jumping from one dropDown to another.
   */
  public dropdownChange(isOpen: boolean): void {
    if (isOpen) this.dropDownIsOpen = true;
  }

  // #region Ctor
  constructor(
    private modalService: ModalService,
    private commandService: CommandService,
    public rmmRegEditService: RmmRegEditService,
    private toastService: ToastService,
    private cdRef: ChangeDetectorRef,
    private i18n: I18NextPipe,
    private renderer: Renderer2
  ) {}
  // #endregion

  // #region Interfaces implementation
  ngOnInit(): void {
    this.disableChildren = false;
    this.dontSelectChildren = false;
    this.regTree = new RegTree(cloneDeep(InitialTreeElements));
    this.rmmRegEditService.setHid(this.hid);
  }
  // #endregion

  getFoundTreeNodes(data: FindResult): Promise<TreeElement[]> {
    return new Promise((resolve, reject) => {
      let acc = [];
      let depth = 0;
      let current = data;
      const parentList = [data];
      while (!data.complete) {
        const treePart = this.regTree.getItemByPath(current.fullName);
        if (!current.valuesProcessed) {
          acc = this.processValues(current, treePart, acc);
          current.valuesProcessed = true;
        }
        if (current.mark && !current.pushed) {
          const treeNode = treePart.children.find((child) => {
            return child.label.toString().includes(current.name) || child.value.toString().includes(current.name);
          });
          if (treeNode) {
            acc.push(treeNode);
          } else {
            acc.push(treePart);
          }
          current.pushed = true;
        }
        const len = (current.subKeys || []).length;
        if (current.subKeys && len && (current.i || 0) < len) {
          current.i = current.i || 0;
          parentList.push(current);
          current = current.subKeys[current.i];
          depth++;
        } else {
          current.complete = true;
          parentList[depth].i = parentList[depth].i + 1;
          current = parentList.pop();
          depth--;
        }
      }
      resolve(acc);
    });
  }

  private processValues(data: FindResult, treePart: TreeElement, prevValues: TreeElement[]): TreeElement[] {
    const result = Array.from(prevValues);
    data.values.forEach((value) => {
      if (value.mark) {
        const treeNode = treePart.children.find((child) => {
          return child.label.toString().includes(value.name) || child.value.toString().includes(value.name);
        });
        if (treeNode) {
          result.push(treeNode);
        }
      }
    });
    return result;
  }

  // #region Async subscribers
  subscribeFindResult(data: FindResult): void {
    if (data === null || data === undefined) {
      this.nothingFound = true;
      return;
    }
    const path = data.fullName;
    this.regTree.regTreeData = cloneDeep(this.registryTree.data);
    this.regTree.addPath(path, data);
    this.getFoundTreeNodes(data).then((foundTreeNodes) => {
      let newTreeNodes: any[] = foundTreeNodes?.length ? foundTreeNodes : [this.regTree.getItemByPath(path)];
      this.setBreadCrumb(
        (newTreeNodes[0] as RegistryTreeElement)?.path ||
          (newTreeNodes[0]?.parent as RegistryTreeElement)?.path + '\\' + newTreeNodes[0].label ||
          path
      );
      setTimeout(() => {
        // TODO: after refactoring mbs-tree need to change this into sync code
        newTreeNodes = newTreeNodes.map((node) => {
          return this.registryTree.getItemById(node.id);
        });
        const foundParent = this.regTree.getItemByPath(path);
        this.registryTree.resetSelection();

        newTreeNodes.forEach((node) => {
          this.registryTree.findAndSelect(node.id);
        });
        this.registryTree.goToItem(foundParent);
      }, 300);
    });
  }
  // #endregion

  // #region UI handlers
  getSubtree(currentTreeElement: TreeElement): Observable<TreeElement[]> {
    const subtree$ = new BehaviorSubject<TreeElement[]>(null);
    const path = this.getFullPath(currentTreeElement);

    // Full path have additional \\ symbols at the start and one additional \ symbol in each sub-path. To get properPath we need to exclude them
    const properPath = path.replaceAll('\\\\', '\\').replace('\\', '');
    this.setBreadCrumb(properPath);

    this.rmmRegEditService
      .takeKey(path)
      .pipe(
        filter((data) => !data.error),
        switchMap((data) => this.rmmRegEditService.getMessageByAsyncId(data)),
        map((message) => message?.data),
        first()
      )
      .subscribe({
        next: (data: any) => {
          const subkeys = data && data.subKeysNames ? data.subKeysNames : [];
          const values = data && data.subKeysNames ? data.values : [];
          const treeNodes: ExtendedTreeElement[] = [];

          subkeys.forEach((p) => {
            const regItem: RegTreeItem = { name: p, type: null };
            treeNodes.push({
              label: p,
              id: generateUid(),
              icon: null,
              treeIcon: RegEditTreeIconPath.windowsFolder,
              value: regItem,
              children: [],
              gotChildren: false
            });
          });

          values.forEach((p) => {
            const name = p.name ? p.name : DefaultValueName;
            const typeKey = Object.keys(RegistryValueKind).find((key) => key.toLowerCase() === p.type.toLowerCase());
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const regItem = {
              name,
              type: RegistryValueKind[typeKey] || null,
              value: RegistryValueKind[typeKey] === RegistryValueKind.Binary ? getStringFromBase64Data(p.value) : p.value
            } as RegTreeItem;

            treeNodes.push({
              label: name,
              id: generateUid(),
              treeIcon: this.regTree.getIconByType(regItem.type),
              value: regItem,
              icon: null,
              children: [],
              gotChildren: true
            });
          });
          subtree$.next(treeNodes);
          this.cdRef.detectChanges();
        },
        error: (err: HttpErrorResponse | string) => {
          if (typeof err === 'string') {
            this.toastService.error(err);
          } else {
            this.toastService.error(err.error?.title);
          }
          subtree$.next([]);
        }
      });

    return subtree$ as Observable<TreeElement[]>;
  }

  treeClick(e): void {
    if (!e.item) return;
    this.breadCrumbs = this.getClickedPathItems(e);
  }

  handleAddKey(e, item): void {
    this.addKeyMode = true;
    this.addKeyValueMode = true;
    this.keyMode = true;

    const path = this.getFullPath(item);
    this.openEditor(null, path);
  }

  handleAddValue(e, item): void {
    this.addKeyMode = false;
    this.addKeyValueMode = true;
    this.keyMode = false;

    const path = this.getFullPath(item);
    this.openEditor(null, path);
  }

  handleEdit(e, item): void {
    const path = this.getFullPath(item);
    this.addKeyMode = false;
    this.addKeyValueMode = false;
    this.keyMode = item.value.type === null || item.value.type === undefined;
    this.openEditor(item.value, path);
  }

  handleDelete(e, item: TreeElement): void {
    const nodeType = item.value.type === null || item.value.type === undefined ? 'key' : 'value';
    this.messageText = `${this.i18n.transform('rmm-side-panel:registryTab.deleteModal.message')} ${nodeType} "${item.value.name}"?`;
    this.modalService
      .open(
        {
          header: { title: this.i18n.transform('rmm-side-panel:registryTab.deleteModal.title') },
          footer: { okButton: { text: this.i18n.transform('buttons:yes') }, cancelButton: { text: this.i18n.transform('buttons:no') } }
        },
        this.messageBox
      )
      .then((confirmed) => {
        if (confirmed) {
          this.delete(item);
        }
      })
      .catch((e) => e);
  }

  handleClear(e): void {
    this.registrySearchForm.setValue({ searchValue: '' });
  }

  handleSearch(e): void {
    const searchValue = this.registrySearchForm.value.searchValue.trim();
    switch (searchValue) {
      case '':
        break;
      case this.previousSearch:
        this.isLoading = true;
        this.rmmRegEditService
          .findNext(searchValue)
          .pipe(
            switchMap((data) => this.rmmRegEditService.getMessageByAsyncId(data)),
            first()
          )
          .subscribe({
            next: (message: any) => {
              if (this.isValidSearchResult(message)) this.subscribeFindResult(message?.data);
              this.isLoading = false;
            }
          });
        break;
      default:
        this.previousSearch = searchValue;
        this.isLoading = true;
        this.rmmRegEditService
          .find(searchValue)
          .pipe(
            switchMap((data) => this.rmmRegEditService.getMessageByAsyncId(data)),
            first()
          )
          .subscribe({
            next: (message: any) => {
              if (this.isValidSearchResult(message)) this.subscribeFindResult(message?.data);
              this.isLoading = false;
            },
            error: noop
          });
        break;
    }
  }

  private isValidSearchResult(message: any) {
    const parameterName = 'NAMEORFRAGMENT';
    const searchValueFromResponse = message?.command?.parameters.find((parameter) => parameter.name === parameterName)?.value;

    return searchValueFromResponse === this.registrySearchForm.value.searchValue.trim();
  }

  handleBreadCrumbNavigate(item: BreadcrumbsItem<any>): void {
    this.isLoading = true;

    this.breadCrumbs = this.breadCrumbs.slice(0, item.level + 1);

    let path = '';
    this.breadCrumbs.forEach((el, index) => (path += el.label + (index < this.breadCrumbs.length - 1 ? '\\\\' : '')));

    const regTreeItem = this.regTree.getItemByPath(path);
    regTreeItem.expanded = false;
    regTreeItem.children.forEach((el) => {
      el.expanded = false;
    });

    InitialTreeElements.forEach((item) => {
      if (item.label !== this.breadCrumbs[0].label) this.regTree.getItemByPath(item.label as string).expanded = false;
    });

    this.regTree.regTreeData = cloneDeep(this.regTree.regTreeData);

    this.cdRef.detectChanges();
    this.isLoading = false;
  }

  // #endregion

  // #region Private methods
  private openEditor(item: RegTreeItem, path: string): void {
    let treeItem = item;
    this.disableFormSave$.next(true);

    if (treeItem) {
      let value = item.value;

      if (item.type === RegistryValueKind.MultiString) {
        value = typeof item.value === 'string' ? item.value : Array.from(item.value).join('\n');
      }
      this.form.reset({
        name: item.name,
        regType: item.type,
        value
      });
    } else {
      treeItem = { type: RegistryValueKind.String };
      this.form.reset({
        name: '',
        value: '',
        regType: RegistryValueKind.String
      });
    }

    const settings: ModalSettings = {
      header: { title: this.i18n.transform('rmm-side-panel:registryTab.editModalTitle'), icon: 'ico-Edit' },
      collapsing: false,
      footer: {
        okButton: { text: this.i18n.transform('buttons:save'), disabled$: this.disableFormSave$.pipe(debounceTime(300)) },
        cancelButton: { text: this.i18n.transform('buttons:close') }
      }
    };

    this.modalService
      .open(settings, this.editForm)
      .then((confirmed) => {
        if (confirmed) {
          const form = this.form.getRawValue();
          treeItem.name = form.name;

          treeItem.value = form.value;
          treeItem.type = form.regType;

          this.save(path, treeItem, this.addKeyMode);
        }
      })
      .catch((e) => e);
  }

  private save(path: string, item: RegTreeItem, createMode: boolean): void {
    this.regTree.regTreeData = this.registryTree.data;
    if (createMode) {
      this.rmmRegEditService.createKey(path, item.name).then((result) => {
        const item = this.regTree.getItemByPath(result.path);
        if (item.gotChildren) {
          this.regTree.addKeyToItem(item, result.keyName);
          this.regTree.regTreeData = cloneDeep(this.regTree.regTreeData);
        }
      }, noop);
    } else {
      if (this.keyMode) {
        this.rmmRegEditService.renameKey(path, item.name).then((result) => {
          const node = this.regTree.getItemByPath(path);
          node.label = result.keyName;
          this.regTree.regTreeData = cloneDeep(this.regTree.regTreeData);
        }, noop);
      } else {
        this.rmmRegEditService.saveValue(path, item.name, item.value, item.type).then((result) => {
          const newValueData = item.type === RegistryValueKind.Binary ? getStringFromBase64Data(result.valueData) : result.valueData;

          this.regTree.addOrModifyValueByPath(result.path, result.valueName, item.type, newValueData);
          this.regTree.regTreeData = cloneDeep(this.regTree.regTreeData);
        }, noop);
      }
    }
  }

  private delete(item: TreeElement) {
    if (item.value.type === null || item.value.type === undefined) {
      const path = this.getFullPath(item, true);
      this.regTree.regTreeData = this.registryTree.data;
      this.rmmRegEditService.deleteKey(path, item.value.name).then((result) => {
        this.regTree.deleteSubTreeByPath(result.path, result.keyName);
        this.regTree.regTreeData = cloneDeep(this.regTree.regTreeData);
      }, noop);
    } else {
      const path = this.getFullPath(item);
      this.regTree.regTreeData = this.registryTree.data;
      this.rmmRegEditService.deleteValue(path, item.value.name).then((result) => {
        this.regTree.deleteSubTreeByPath(result.path, result.valueName);
        this.regTree.regTreeData = cloneDeep(this.regTree.regTreeData);
      }, noop);
    }
    this.cdRef.detectChanges();
  }

  private getFullPath(treeElement: TreeElement, excludeCurrentIfKey = false) {
    let node = treeElement;
    let registryPath = node.value.type === null || node.value.type === undefined ? `\\\\${node.label}` : ``;
    if (excludeCurrentIfKey) {
      registryPath = ``;
    }
    while (node.parent != null) {
      node = node.parent;
      registryPath = `\\\\${node.label}` + registryPath;
    }
    return registryPath;
  }

  private getClickedPathItems(e) {
    let current = e.item;
    let completed = false;
    const pathItems = [];
    while (!completed) {
      pathItems.push(current);
      if (current.parent) {
        current = current.parent;
      } else {
        completed = true;
      }
    }
    return pathItems.reverse();
  }

  private setBreadCrumb(path: string) {
    const pathItems = path.split('\\');
    this.breadCrumbs = pathItems.map((p) => {
      return { label: p };
    });
  }
  // #endregion

  formNameValidator(control: AbstractControl): ValidationErrors | null {
    if (this.form && control.value) {
      const nameValue = this.form.get('name').value;
      const isInvalidForm = nameValue.includes('\\');
      this.disableFormSave$.next(isInvalidForm);

      return isInvalidForm ? { name: true } : null;
    }
    return null;
  }
}
