import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { I18NextService } from 'angular-i18next';
import { filter } from 'rxjs';
import { GroupActionsCommands, InstallSofwareSelectionTypes } from '../../../../store/group-action/group-action.model';
import EditGroupTaskUtility from '../../../../utility/edit-group-task-utility';

@UntilDestroy()
@Component({
  selector: 'mbs-install-software-common',
  templateUrl: './install-software-common.html',
  styleUrls: ['./install-software-common.scss']
})
export class InstallSoftwareCommonComponent implements OnInit {
  public modeSelectType = InstallSofwareSelectionTypes;
  public installationModeFormType: FormGroup = new FormGroup({
    modeSelect: new FormControl(this.modeSelectType.usingWinget)
  });

  public fromFileLabel: string;
  public fromLibraryLabel: string;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private editGroupTaskUtility: EditGroupTaskUtility,
    private i18n: I18NextService
  ) {
    // this.installationModeFormType
    //   .get('modeSelect')
    //   .valueChanges.pipe(withLatestFrom(this.store.select(GASelectors.selectGroupActionType)), untilDestroyed(this))
    //   .subscribe(([mode, groupActionCommand]) => {
    // if (mode === this.modeSelectType.usingWinget)
    //   this.store.dispatch(
    //     GAActions.setGroupActionStrategy({
    //       command: GroupActionsCommands.WIN_GET
    //     })
    //   );
    // if (mode === this.modeSelectType.usingLink)
    //   this.store.dispatch(
    //     GAActions.setGroupActionStrategy({
    //       command: GroupActionsCommands.INSTALL_SOFTWARE
    //     })
    //   );
    // });
  }

  setupFormLabels(value: string): void {
    this.fromLibraryLabel = this.i18n.t('rmm.module:groupActions.stepConfigureAction.scriptFromLibraryLabel', { value });
    this.fromFileLabel = this.i18n.t('rmm.module:groupActions.stepConfigureAction.scriptFromFileLabel', { value });
  }

  ngOnInit(): void {
    this.editGroupTaskUtility
      .getGroupTask()
      .pipe(
        filter((groupTask) => !!groupTask),
        untilDestroyed(this)
      )
      .subscribe((groupTask) => {
        const commandType = this.editGroupTaskUtility.parseCommandTypeFromGroupTask(groupTask);

        if (commandType === GroupActionsCommands.INSTALL_SOFTWARE)
          this.installationModeFormType.setValue({ modeSelect: this.modeSelectType.usingLink });
      });
  }

  modeSelected(modeSelectType: InstallSofwareSelectionTypes) {
    return modeSelectType === this.installationModeFormType.get('modeSelect').value;
  }

  setModeSelect(modeSelectType: InstallSofwareSelectionTypes) {
    this.installationModeFormType.get('modeSelect').setValue(modeSelectType);
  }
}
