export enum EventLogTypesEnum {
  Application = 'Application',
  HardwareEvents = 'Hardware Events',
  Security = 'Security',
  System = 'System'
}

export enum EventLogEntryTypeEnum {
  All = 'All',
  Info = 'Info',
  Warn = 'Warn',
  Error = 'Error',
  Success = 'Success',
  Failure = 'Failure'
}

export enum EventLogTypeDataFieldsEnum {
  Info = 'informationCount',
  Warn = 'warningCount',
  Error = 'errorCount',
  Success = 'successAuditCount',
  Failure = 'failureAuditCount'
}

export enum EventLogIconEnum {
  Info = 'fa fa-info-circle text-info text-md',
  Warning = 'fa fa-exclamation-triangle text-warning text-md',
  Danger = 'fa fa-exclamation-circle text-danger text-md',
  Success = 'fa fa-check-circle text-success text-md'
}

export enum EventLogEntrySourceTypeEnum {
  /**
   * @summary An error event. This indicates a significant problem the user should know about; usually a loss of functionality or data.
   */
  Error = 1,
  /**
   * @summary A warning event. This indicates a problem that is not immediately significant, but that may signify conditions that could cause future problems.
   */
  Warning = 2,
  /**
   *@summary An information event. This indicates a significant, successful operation.
   */
  Information = 4,
  /**
   * @summary A success audit event. This indicates a security event that occurs when an audited access attempt is successful; for example, logging on successfully.
   */
  SuccessAudit = 8,
  /**
   * @summary A failure audit event. This indicates a security event that occurs when an audited access attempt fails; for example, a failed attempt to open a file.
   */
  FailureAudit = 16
}
export interface IEventLogEntry {
  logName?: EventLogTypesEnum;
  /**
   * @summary Gets the event type of this entry.
   */
  entryType: EventLogEntrySourceTypeEnum;
  /**
   * @summary Gets the application-specific event identifier for the current event entry.
   */
  eventID: number;
  /**
   * @summary Gets the index of this entry in the event log.
   */
  index: number;
  /**
   * @summary Gets the resource identifier that designates the message text of the event entry.
   */
  instanceId: number;
  /**
   * @summary Gets the name of the computer on which this entry was generated.
   */
  machineName: string;
  /**
   * @summary Gets the localized message associated with this event entry.
   */
  message: string;
  /**
   * @summary Gets the replacement strings associated with the event log entry.
   */
  replacementStrings: string[];
  /**
   * @summary Gets the name of the application that generated this event.
   */
  source: string;
  /**
   * @summary Gets the local time at which this event was generated.
   */
  timeGenerated: Date;
  /**
   * @summary Gets the local time at which this event was written to the log.
   */
  timeWritten: Date;
  /**
   * @summary Gets the name of the user who is responsible for this event.
   */
  userName: string;
}

export class EventLogEntry implements IEventLogEntry {
  public entryType: IEventLogEntry['entryType'];
  public eventID: IEventLogEntry['eventID'];
  public index: IEventLogEntry['index'];
  public instanceId: IEventLogEntry['instanceId'];
  public machineName: IEventLogEntry['machineName'];
  public message: IEventLogEntry['message'];
  public replacementStrings: IEventLogEntry['replacementStrings'];
  public source: IEventLogEntry['source'];
  public timeGenerated: IEventLogEntry['timeGenerated'];
  public timeWritten: IEventLogEntry['timeWritten'];
  public userName: IEventLogEntry['userName'];

  static create(data?) {
    return new EventLogEntry(data);
  }

  static createFromXML(data) {
    return new EventLogEntry(data);
  }

  constructor(data: any = {}) {
    this.entryType = data.entryType || null;
    this.eventID = data.eventID || null;
    this.index = data.index || null;
    this.instanceId = data.instanceId || null;
    this.machineName = data.machineName || null;
    this.message = data.message || null;
    this.replacementStrings = data.replacementStrings || null;
    this.source = data.source || null;
    this.timeGenerated = data.timeGenerated || null;
    this.timeWritten = data.timeWritten || null;
    this.userName = data.userName || null;
  }
}

export default class EventLogInfo {
  errorCount: number;
  failureAuditCount: number;
  informationCount: number;
  logType: EventLogEntryTypeEnum;
  logDisplayName: string;
  maximumKilobytes: number;
  minimumRetentionDays: number;
  successAuditCount: number;
  totalCount: number;
  warningCount: number;
}
