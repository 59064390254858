import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18NextModule, I18NEXT_NAMESPACE } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { ImmutabilityConfirmModalComponent } from './immutability-confirm-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, I18NextModule, MbsUiKitModule],
  declarations: [ImmutabilityConfirmModalComponent],
  exports: [ImmutabilityConfirmModalComponent],
  providers: [
    {
      provide: I18NEXT_NAMESPACE,
      useValue: ['immutability']
    }
  ]
})
export class ImmutabilityConfirmModalModule {
  constructor() {}
}
