import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AuthService } from '@services';
import { I18NextService } from 'angular-i18next';
import { MbsPopupType, WizardStepComponent } from 'mbs-ui-kit';
import moment from 'moment';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, startWith, take, tap } from 'rxjs';
import { GroupTaskOsTypes } from '../../../group-tasks/store/model';
import { GAActions, GASelectors } from '../../store/group-action';
import {
  GroupActionsCommands,
  LinuxAvailableActions,
  MacAvailableActions,
  WindowsAvailableActions
} from '../../store/group-action/group-action.model';
import EditGroupTaskUtility from '../../utility/edit-group-task-utility';

export enum GACommandNameTranslationKey {
  downloadAndInstall = 'rmm.module:groupActions.actionNames.downloadAndInstall',
  bash = 'rmm.module:groupActions.actionNames.bash',
  execScript = 'rmm.module:groupActions.actionNames.execScript',
  installProductSimple = 'rmm.module:groupActions.actionNames.installProductSimple',
  powerSHell = 'rmm.module:groupActions.actionNames.powerSHell',
  scriptLibrary = 'rmm.module:groupActions.actionNames.scriptLibrary',
  scriptLibraryBash = 'rmm.module:groupActions.actionNames.scriptLibraryBash',
  uninstallPatch = 'rmm.module:groupActions.actionNames.uninstallPatch',
  uninstallProduct = 'rmm.module:groupActions.actionNames.uninstallProduct',
  updatePolicy = 'rmm.module:groupActions.actionNames.updatePolicy',
  winGet = 'rmm.module:groupActions.actionNames.winGet'
}

export enum OsType {
  Windows = 'Windows',
  Linux = 'Linux',
  MacOS = 'MacOS'
}

export interface Action {
  id: number;
  label: string;
  commandKey: WindowsAvailableActions | LinuxAvailableActions | MacAvailableActions;
}

@UntilDestroy()
@Component({
  selector: 'mbs-select-action',
  templateUrl: './select-action.component.html',
  styleUrls: ['./select-action.component.scss'],
  host: {
    class: 'rmm-group-action'
  }
})
export class SelectActionComponent implements OnInit {
  public readonly alertType = MbsPopupType;
  public isUpdatePolicy = false;
  public translationKey = 'rmm.module:groupActions.actionNames.';

  public form: UntypedFormGroup;
  private tzOffset: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public taskName = '';
  public is2FAEnabled: boolean;
  public currentAction: string;

  public isEditMode = false;
  public osTypes = [OsType.Windows, OsType.Linux, OsType.MacOS];
  public selectedOS = OsType.Windows;
  public description: string;

  public actions: Action[] = this.getWindowsCommands();

  public elementSelectors = {
    id: {
      taskNameSelector: 'select-action-taskNameSelector',
      osTypeSelector: 'select-action-osTypeSelector',
      actionSelector: 'select-action-actionSelector'
    }
  };

  public command$: Observable<Action>;

  constructor(
    private fb: FormBuilder,
    @Inject(WizardStepComponent) protected parent: WizardStepComponent,
    protected injector: Injector,
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private editGroupTaskUtility: EditGroupTaskUtility,
    private i18n: I18NextService
  ) {
    this.form = this.fb.group({
      name: [this.taskName],
      osType: [this.selectedOS],
      action: []
    });

    this.command$ = this.store.select(GASelectors.selectGActionSelectedType).pipe(
      filter((value) => !!value && !!this.actions.length),
      map((value) => this.actions.filter((item) => item.commandKey === value)[0])
    );

    this.command$.subscribe((value) => {
      this.form.get('action').setValue(value, { emitEvent: false });
    });

    this.authService.currentUser.pipe(filter(Boolean), take(1), untilDestroyed(this)).subscribe((user) => {
      if (user.ProviderInfo.TimeZoneSpecified) this.tzOffset.next(user.ProviderInfo.TimeZoneOffset);
      this.taskName = `Task ${moment(new Date()).utcOffset(this.tzOffset.getValue()).format('MMM DD, YYYY, h:mm A')}`;
      this.is2FAEnabled = user.Is2FAEnabled;
    });
  }

  ngOnInit(): void {
    const name$ = this.form.get('name').valueChanges;
    const action$ = this.form.get('action').valueChanges;
    const osType$ = this.form.get('osType').valueChanges.pipe(
      startWith(OsType.Windows),
      distinctUntilChanged(),
      tap((osType) => this.onOSTypeChangedFiltering(osType)),
      untilDestroyed(this)
    );

    combineLatest([action$, name$, osType$])
      .pipe(untilDestroyed(this))
      .subscribe(([command, name, osType]) => {
        this.description = this.i18n.t('rmm.module:groupActions.stepSelectAction.description.' + command?.commandKey);
        this.isUpdatePolicy = command?.commandKey === WindowsAvailableActions.UpdatePolicy;
        this.setAction(command, name, osType);
      });

    this.loadGroupTaskData();
  }

  public setAction(command: any, name: string, osType: OsType): void {
    this.currentAction = command?.commandKey;

    this.store.dispatch(
      GAActions.setGActionNameAndOsTypeAndCommandType({
        actionSelectCommand: command?.commandKey,
        name,
        osType: this.getOsTypeNumber(osType)
      })
    );
  }

  private onOSTypeChangedFiltering(osType: OsType): void {
    this.actions = this.getActionsPerOsType(osType);
    this.form.get('action').setValue(this.actions[0]);
  }

  private getActionsPerOsType(osType: OsType): Action[] {
    switch (osType) {
      case OsType.Linux:
        return this.getLinuxCommands();
      case OsType.MacOS:
        return this.getMacCommands();
      case OsType.Windows:
        return this.getWindowsCommands();
      default:
        return [];
    }
  }

  private getWindowsCommands(): Action[] {
    return this.getActionsFromCommands(Object.keys(WindowsAvailableActions).map((key) => WindowsAvailableActions[key]));
  }

  private getLinuxCommands(): Action[] {
    return this.getActionsFromCommands(Object.keys(LinuxAvailableActions).map((key) => LinuxAvailableActions[key]));
  }

  private getMacCommands(): Action[] {
    return this.getActionsFromCommands(Object.keys(MacAvailableActions).map((key) => MacAvailableActions[key]));
  }

  public getActionsFromCommands(
    groupActionCommands: WindowsAvailableActions[] | LinuxAvailableActions[] | MacAvailableActions[]
  ): Action[] {
    return groupActionCommands.map((actionName, i) => ({
      id: i,
      label: this.i18n.t(this.translationKey + actionName),
      commandKey: actionName
    }));
  }

  enable2FAredirect(): void {
    this.router.navigate(['/AP/Settings']);
  }

  private loadGroupTaskData(): void {
    this.editGroupTaskUtility
      .getNameAndTypeAndOSFromGroupTaskStream()
      .pipe(
        take(1),
        filter(({ command, name, osType }) => !!command && !!name && !!osType)
      )
      .subscribe(({ command, name, osType }) => {
        this.updateSelectActionData(name, command, osType);
      });
  }

  private getSelectedOSFromCommandName(osType: GroupTaskOsTypes): OsType {
    switch (osType) {
      case GroupTaskOsTypes.Mac:
        return OsType.MacOS;
      case GroupTaskOsTypes.Linux:
        return OsType.Linux;
      case GroupTaskOsTypes.Windows:
      case GroupTaskOsTypes.None:
      default:
        return OsType.Windows;
    }
  }

  private getOsTypeNumber(osType: OsType): GroupTaskOsTypes {
    switch (osType) {
      case OsType.MacOS:
        return GroupTaskOsTypes.Mac;
      case OsType.Linux:
        return GroupTaskOsTypes.Linux;
      case OsType.Windows:
        return GroupTaskOsTypes.Windows;
      default:
        return GroupTaskOsTypes.None;
    }
  }

  public updateSelectActionData(
    name: string,
    command: GroupActionsCommands | WindowsAvailableActions | LinuxAvailableActions | MacAvailableActions,
    osType: GroupTaskOsTypes
  ): void {
    this.isEditMode = true;
    // load all commands in editMode to show the proper command name
    this.actions = this.getActionsFromCommands(Object.keys(GroupActionsCommands).map((key) => GroupActionsCommands[key]));
    const loadedAction = this.actions.find((action) => action.commandKey === command);
    this.taskName = name;

    this.form.get('osType').setValue(this.getSelectedOSFromCommandName(osType));
    this.form.get('action').setValue(loadedAction);
  }
}
