import { TerminalChunk, TerminalHostInfo, TerminalTransfer } from './terminal.model';
import { createAction, props } from '@ngrx/store';

import { FeatureKeyPrefix } from './const';
import { PromptBuffer } from '../shell-addon/prompt-buffer';

export const initTerminal = createAction(`${FeatureKeyPrefix} Init`, props<{hid: string, emuId: string}>());
export const addSubscriber = createAction(`${FeatureKeyPrefix} Add Subscriber`, props<{session: string, emuId: string}>());
export const removeSubscriber = createAction(`${FeatureKeyPrefix} Remove Subscriber`, props<{session: string, emuId: string}>());

export const output = createAction(`${FeatureKeyPrefix} Output`, props<{ asyncId: string; transfer: TerminalTransfer }>());
export const addChunk = createAction(`${FeatureKeyPrefix} Add Terminal Chunk`, props<{ line: TerminalChunk }>());
export const clearCache = createAction(`${FeatureKeyPrefix} Clear Terminal Chunks`);

export const setInfo = createAction(`${FeatureKeyPrefix} Add Info`, props<{ session: string; info: Partial<TerminalHostInfo> }>());
export const setProcessing = createAction(`${FeatureKeyPrefix} Set Processing`, props<{ session: string, processing: boolean }>());
export const setTimeout = createAction(`${FeatureKeyPrefix} Set Timeout`, props<{ hid: string; timeout: number }>());
export const getTimeOut = createAction(`${FeatureKeyPrefix} Get Timeout`, props<{ hid: string }>());
export const addTimeout = createAction(`${FeatureKeyPrefix} Add Timeout`, props<{ timeout: number, session }>());

export const send = createAction(`${FeatureKeyPrefix} Send Command`, props<{ hid: string; prompt: PromptBuffer | string, emuId: string, file?: string }>());
export const sendBreak = createAction(`${FeatureKeyPrefix} Break Terminal`, props<{ hid: string, emuId: string }>());
export const clearSession = createAction(`${FeatureKeyPrefix} Clear Session`, props<{session: string}>());

export const checkDisconnected = createAction(`${FeatureKeyPrefix} Check Disconnected`, props<{hid: string}>());
export const setDisconnected = createAction(`${FeatureKeyPrefix} Set Disconnected`, props<{session: string, disconnected: boolean}>());
