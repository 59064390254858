import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Optional, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UbSubscribeService } from '@components/storage-accounts/ub-subscribe.service';
import { CurrentCredits } from '@models/storage-accounts/CurrentCredits';
import { StorageType } from '@models/StorageType.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Balance } from '../../../shared/components/licenses/models/license.model';
import { BALANCE_TOKEN } from '../../../shared/components/licenses/tokens/balance.token';
import { EmptyPageService } from '../../empty-page/empty-page.service';

@UntilDestroy()
@Component({
  selector: 'mbs-ub-aws-changing-price-modal',
  templateUrl: './ub-aws-changing-price-modal.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UbAwsChangingPriceModalComponent {
  public loading$ = new BehaviorSubject<boolean>(false);
  public credits: CurrentCredits;
  public storageType: StorageType;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    private location: Location,
    private router: Router,
    private ubSubscribe: UbSubscribeService, // prettier
    public authService: AuthService,
    @Optional() @Inject(EmptyPageService) private emptyPageService: EmptyPageService,
    @Inject(BALANCE_TOKEN) public balance: BehaviorSubject<Balance>
  ) {}

  get currency() {
    return this.balance?.value?.subscriptionCurrencySign;
  }

  handleResolve(): void {
    this.loading$.next(true);
    this.ubSubscribe
      .spendCredits(this.storageType)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.loading$.next(false))
      )
      .subscribe((credits) => {
        if (!credits || credits.paymentLink == null) {
          this.baseModal.close();
          // @TODO replace on something better after migration StorageAccount page to angular
          this.emptyPageService.handleSendMessageToChild({ msg: 'forceNextStorageAccountWizardStep' });
        } else {
          window.location.href = credits.paymentLink;
          // this.location.go(credits.paymentLink);
        }
      });
  }
}
