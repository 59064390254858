<div class="browse-storage-table">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <h4 class="mb-0">{{ headerText }}</h4>

    <div *ngIf="selectedTreeItem?.itemType !== itemTypeEnum.Prefix && !isBackups && !isGenerations && !isPoints"
         class="d-flex">
      <mbs-button type="secondary"
                  [icon]="'fa fa-rotate-right'"
                  (click)="downloadClickHandler()"
                  [disabled]="disabledQRButton"
                  [loading]="deleteProcess">
        {{ 'backup-storages:restore' | i18next }}
      </mbs-button>
    </div>
  </div>

  <mbs-table-grid *ngIf="!isBackups && !isPoints && !isGenerations"
                  (changeSelected)="selectedItemsForQRChangeHandler($event)"
                  [data]="rightTableData"
                  [headers]="headers"
                  [multipleSelect]="true"
                  [selectable]="false"
                  [classesTable]="'mb-0 -bordered -vertical'"
                  [showRefreshButton]="false"
                  [disableViewSwitch]="true"
                  [isNeedSelectRow]="true"
                  [selectedItems]="selectedItemsForQR"
                  [selectOnlyOnCheckboxClick]="true"
                  [sizeTable]="MbsSize.sm"
                  [showTableCheckboxes]="showCheckboxes"
                  [switcherView]="false"
                  [viewMode]="viewMode"
                  [keepState]="true"
                  [attr.data-test-element]="elementsSelector.name.rightTableDefault"
                  bindSelected="id"
                  id="selected-items-for-qr-list">
    <ng-template *ngIf="existColumns.label"
                 let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)"
            class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img">
        <img [src]="item.iconCustom"
             class="browse-storage-table_img-main mr-2"
             alt="Table main icon">
        <mbs-text-ellipsis [tooltip]="item.label">{{ item.label }}</mbs-text-ellipsis>
      </span>
    </ng-template>
    <ng-template *ngIf="existColumns.modifyDateUTC"
                 let-item
                 mbsTableCell>
      <mbs-backup-date-time-format *ngIf="item?.modifyDateUTC; else notData"
                                   [attr.data-test-element]="elementsSelector.name.modifyDateUTCTableRow"
                                   [time]="item?.modifyDateUTC"
                                   [showIcon]="true"
                                   container="body"
                                   defaultText="Unknown"></mbs-backup-date-time-format>
    </ng-template>
    <ng-template *ngIf="existColumns.originalSize"
                 let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.originalSize || item.originalSize === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.originalSizeTableRow"
                         [tooltip]="getDiskSizeFromKB(item.originalSize)">
        {{getDiskSizeFromKB(item.originalSize)}}
      </mbs-text-ellipsis>
    </ng-template>
  </mbs-table-grid>

  <mbs-table-grid *ngIf="isBackups"
                  (changeSelected)="selectedItemsForQRChangeHandler($event)"
                  [data]="rightTableData"
                  [headers]="rightTableBunchHeaders"
                  [multipleSelect]="true"
                  [selectable]="false"
                  [classesTable]="'mbs-browse-table mb-0 -bordered -vertical'"
                  [showRefreshButton]="false"
                  [disableViewSwitch]="true"
                  [sizeTable]="MbsSize.sm"
                  [switcherView]="false"
                  [viewMode]="viewMode"
                  [keepState]="true"
                  [attr.data-test-element]="elementsSelector.name.rightTableBunches">
    <ng-container *ngFor="let header of rightTableBunchHeaders">
      <ng-template [mbsTableHeader]="header.name"
                   let-header>
        <span *ngIf="header.headerName !== 'Icons'; else iconsHeader"
              class="text-xs w-100 text-left">
          {{header.name}}
          <span *ngIf="header.headerName === 'totalSavings'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="tooltip-lg text-left"
                [placement]="'top-right'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.totalSavingsLabel' | i18next"></span>
        </span>
      </ng-template>
    </ng-container>

    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)"
            class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img">
        <span class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img mr-1">
          <img [src]="item.iconCustom"
               class="browse-storage-table_img-main"
               alt="Table main icon">
          <img *ngIf="item.rightBottomIcon"
               [src]="item.rightBottomIcon"
               class="browse-storage-table_img-bottom"
               alt="Table icon right bottom">
          <img *ngIf="item.leftTopIcon"
               [src]="item.leftTopIcon"
               class="browse-storage-table_img-top"
               alt="Table icon left top">
        </span>
        <mbs-text-ellipsis [tooltip]="item.label">{{ item.label }}</mbs-text-ellipsis>
      </span>
    </ng-template>

    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.firstRestoreDate; else notData"
                                     [attr.data-test-element]="elementsSelector.name.firstRestoreDateTableRow"
                                     [time]="item?.firstRestoreDate"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.lastRestoreDate; else notData"
                                     [attr.data-test-element]="elementsSelector.name.lastRestoreDateTableRow"
                                     [time]="item?.lastRestoreDate"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <div class="d-flex justify-content-between w-100">
        <span class="browse-storage-table-col-4 text-left">
          {{item.countOfSuccessfulStarts}}
        </span>
        <span class="browse-storage-table-col-4 text-center">
          {{item.countOfWarningStarts}}
        </span>
        <span class="browse-storage-table-col-4 text-right">
          {{item.countOfErrorStarts}}
        </span>
      </div>

    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.originalSize || item.originalSize === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.originalSizeTableRow"
                         [tooltip]="getDiskSizeFromKB(item.originalSize)">
        {{getDiskSizeFromKB(item.originalSize)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.sizeOnStorage || item.sizeOnStorage === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.sizeOnStorageTableRow"
                         [tooltip]="getDiskSizeFromKB(item.sizeOnStorage)">
        {{getDiskSizeFromKB(item.sizeOnStorage)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.totalSavings || item.totalSavings === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.totalSavingsTableRow"
                         [tooltip]="item.totalSavings + '%'">
        {{item.totalSavings + '%'}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <div class="cursor-help"
           [placement]="'top-right'"
           [container]="'body'"
           [ngbTooltip]="'backup-storages:browseStorage.tables.restore' | i18next: { level: generationLevelText }">
        <mbs-button type="secondary"
                    [icon]="'fa fa-rotate-right'"
                    (click)="downloadClickHandler(item)"
                    [disabled]="deleteProcess || !item.isCanBeQuickRestored"></mbs-button>
      </div>
      <div class="cursor-help ml-1"
           [placement]="'top-right'"
           [container]="'body'"
           [ngbTooltip]="getDeleteTooltipText(item)">
        <mbs-button type="secondary"
                    [icon]="'fa fa-trash-o'"
                    (click)="deleteSelectedClickHandler(item)"
                    [disabled]="deleteProcess || !item.isCanBeDeleted"></mbs-button>
      </div>
    </ng-template>
  </mbs-table-grid>

  <mbs-table-grid *ngIf="isGenerations"
                  (changeSelected)="selectedItemsForQRChangeHandler($event)"
                  [data]="rightTableData"
                  [headers]="rightTableGenerationHeaders"
                  [multipleSelect]="true"
                  [selectable]="false"
                  [classesTable]="'mbs-browse-table mb-0 -bordered -vertical'"
                  [showRefreshButton]="false"
                  [disableViewSwitch]="true"
                  [sizeTable]="MbsSize.sm"
                  [switcherView]="false"
                  [viewMode]="viewMode"
                  [keepState]="true"
                  [attr.data-test-element]="elementsSelector.name.rightTableGeneration">
    <ng-container *ngFor="let header of rightTableGenerationHeaders">
      <ng-template [mbsTableHeader]="header.name"
                   let-header>
        <span *ngIf="header.headerName !== 'Icons'; else iconsHeader"
              class="text-xs w-100 text-left">
          {{header.name}}
          <span *ngIf="header.headerName === 'totalSavings'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="tooltip-lg text-left"
                [placement]="'top-right'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.totalSavingsLabel' | i18next"></span>
        </span>
      </ng-template>
    </ng-container>

    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)"
            class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img">
        <img [src]="item.iconCustom"
             class="browse-storage-table_img-main"
             alt="Table main icon">
        <img *ngIf="item.rightBottomIcon"
             [src]="item.rightBottomIcon"
             class="browse-storage-table_img-bottom"
             alt="Table icon right bottom">
        <img *ngIf="item.leftTopIcon"
             [src]="item.leftTopIcon"
             class="browse-storage-table_img-top"
             alt="Table icon left top">
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.firstRestoreDate"
                                     [attr.data-test-element]="elementsSelector.name.firstRestoreDateTableRow"
                                     [time]="item?.firstRestoreDate"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.lastRestoreDate"
                                     [attr.data-test-element]="elementsSelector.name.lastRestoreDateTableRow"
                                     [time]="item?.lastRestoreDate"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <div class="d-flex justify-content-between w-100">
        <span class="browse-storage-table-col-4 text-left">
          {{item.countOfSuccessfulStarts}}
        </span>
        <span class="browse-storage-table-col-4 text-center">
          {{item.countOfWarningStarts}}
        </span>
        <span class="browse-storage-table-col-4 text-right">
          {{item.countOfErrorStarts}}
        </span>
      </div>

    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.originalSize || item.originalSize === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.originalSizeTableRow"
                         [tooltip]="getDiskSizeFromKB(item.originalSize)">
        {{getDiskSizeFromKB(item.originalSize)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.sizeOnStorage || item.sizeOnStorage === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.sizeOnStorageTableRow"
                         [tooltip]="getDiskSizeFromKB(item.sizeOnStorage)">
        {{getDiskSizeFromKB(item.sizeOnStorage)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.daysToPurge || item.daysToPurge === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.daysToPurgeTableRow"
                         [tooltip]="daysToPurgeToString(item.daysToPurge)">
        {{daysToPurgeToString(item.daysToPurge)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.totalSavings || item.totalSavings === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.totalSavingsTableRow"
                         [tooltip]="item.totalSavings + '%'">
        {{item.totalSavings + '%'}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <div class="cursor-help"
           [placement]="'top-right'"
           [container]="'body'"
           [ngbTooltip]="'backup-storages:browseStorage.tables.restore' | i18next: { level: generationLevelText }">
        <mbs-button type="secondary"
                    [icon]="'fa fa-rotate-right'"
                    (click)="downloadClickHandler(item)"
                    [disabled]="deleteProcess || !item.isCanBeQuickRestored"></mbs-button>
      </div>
      <div class="cursor-help ml-1"
           [placement]="'top-right'"
           [container]="'body'"
           [ngbTooltip]="getDeleteTooltipText(item)">
        <mbs-button type="secondary"
                    [icon]="'fa fa-trash-o'"
                    (click)="deleteSelectedClickHandler(item)"
                    [disabled]="deleteProcess || !item.isCanBeDeleted"></mbs-button>
      </div>
    </ng-template>
  </mbs-table-grid>

  <mbs-table-grid *ngIf="isPoints"
                  (changeSelected)="selectedItemsForQRChangeHandler($event)"
                  [data]="rightTableData"
                  [headers]="rightTablePointHeaders"
                  [multipleSelect]="true"
                  [selectable]="false"
                  [classesTable]="'mbs-browse-table mb-0 -bordered -vertical'"
                  [showRefreshButton]="false"
                  [disableViewSwitch]="true"
                  [sizeTable]="MbsSize.sm"
                  [switcherView]="false"
                  [viewMode]="viewMode"
                  [keepState]="true"
                  [attr.data-test-element]="elementsSelector.name.rightTablePoints">
    <ng-container *ngFor="let header of rightTablePointHeaders">
      <ng-template [mbsTableHeader]="header.name"
                   let-header>
        <span class="text-xs w-100 text-left">
          {{header.name}}
          <span *ngIf="header.headerName === 'consistencyCheck'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="tooltip-lg text-left"
                [placement]="'top-right'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.consistencyCheckLabel' | i18next"></span>
          <span *ngIf="header.headerName === 'restoreVerification'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="tooltip-lg text-left"
                [placement]="'top-right'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.restoreVerificationLabel' | i18next"></span>
          <span *ngIf="header.headerName === 'totalSavings'"
                class="ico ico-InfoCircle ml-2 cursor-help text-info"
                tooltipClass="tooltip-lg text-left"
                [placement]="'top-right'"
                [container]="'body'"
                [ngbTooltip]="'backup-storages:browseStorage.tables.totalSavingsLabel' | i18next"></span>
        </span>
      </ng-template>
    </ng-container>

    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)"
            class="d-flex align-items-center justify-content-start position-relative browse-storage-table_img">
        <img [src]="item.iconCustom"
             class="browse-storage-table_img-main"
             alt="Table main icon">
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <span (dblclick)="itemDblClickHandler($event, item)">
        <mbs-backup-date-time-format *ngIf="item?.date; else notData"
                                     [attr.data-test-element]="elementsSelector.name.dateTableRow"
                                     [time]="item?.date"
                                     [showIcon]="true"
                                     container="body"
                                     defaultText="Unknown">
        </mbs-backup-date-time-format>
      </span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-status *ngIf="item.backupResult && item.backupResult !== 'unknown'; else notDataWithText"
                  [attr.data-test-element]="elementsSelector.name.backupResultTableRow"
                  [textSize]="MbsSize.lg"
                  [ngbTooltip]="item.backupResult"
                  [placement]="'top-right'"
                  [container]="'body'"
                  [icon]="StatusIconType[item.backupResult]"
                  [type]="item.backupResult"></mbs-status>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-status *ngIf="item.consistencyCheckResult && item.consistencyCheckResult !== 'unknown'; else notDataWithText"
                  [attr.data-test-element]="elementsSelector.name.consistencyCheckResultTableRow"
                  [textSize]="MbsSize.lg"
                  [ngbTooltip]="item.backupResult"
                  [placement]="'top-right'"
                  [container]="'body'"
                  [icon]="StatusIconType[item.consistencyCheckResult]"
                  [type]="item.consistencyCheckResult"></mbs-status>
    </ng-template>
    <ng-template *ngIf="existPointsHeaderColumn('restoreVerification')"
                 let-item
                 mbsTableCell>
      <mbs-status *ngIf="item.restoreVerificationResult && item.restoreVerificationResult !== 'unknown'; else notDataWithText"
                  [attr.data-test-element]="elementsSelector.name.restoreVerificationResultTableRow"
                  [textSize]="MbsSize.lg"
                  [ngbTooltip]="item.backupResult"
                  [placement]="'top-right'"
                  [container]="'body'"
                  [icon]="StatusIconType[item.restoreVerificationResult]"
                  [type]="item.restoreVerificationResult"></mbs-status>
      <span *ngIf="!item.restoreVerificationResult || item.restoreVerificationResult === 'unknown';"
            class="ico ico-InfoCircle ml-2 cursor-help text-info"
            tooltipClass="tooltip-lg text-left"
            [placement]="'top-right'"
            [container]="'body'"
            [ngbTooltip]="'backup-storages:browseStorage.tables.rvNALabel' | i18next"></span>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.duration; else notDataWithText"
                         [attr.data-test-element]="elementsSelector.name.durationTableRow"
                         [tooltip]="item.duration">
        {{item.duration}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.originalSize || item.originalSize === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.originalSizeTableRow"
                         [tooltip]="getDiskSizeFromKB(item.originalSize)">
        {{getDiskSizeFromKB(item.originalSize)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.sizeOnStorage || item.sizeOnStorage === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.sizeOnStorageTableRow"
                         [tooltip]="getDiskSizeFromKB(item.sizeOnStorage)">
        {{getDiskSizeFromKB(item.sizeOnStorage)}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <mbs-text-ellipsis *ngIf="item.totalSavings || item.totalSavings === 0; else notData"
                         [attr.data-test-element]="elementsSelector.name.totalSavingsTableRow"
                         [tooltip]="item.totalSavings + '%'">
        {{item.totalSavings + '%'}}
      </mbs-text-ellipsis>
    </ng-template>
    <ng-template let-item
                 mbsTableCell>
      <div class="cursor-help"
           [placement]="'top-right'"
           [container]="'body'"
           [ngbTooltip]="'backup-storages:browseStorage.tables.restore' | i18next: { level: pointLevelText }">
        <mbs-button type="secondary"
                    [icon]="'fa fa-rotate-right'"
                    (click)="downloadClickHandler(item)"
                    [disabled]="deleteProcess"></mbs-button>
      </div>
    </ng-template>
  </mbs-table-grid>

  <ng-template #iconsHeader>
    <span class="d-flex justify-content-between w-100">
      <mbs-status [textSize]="MbsSize.lg"
                  [ngbTooltip]="'backup-storages:browseStorage.tables.iconSuccess' | i18next"
                  [placement]="'top-right'"
                  [container]="'body'"
                  [icon]="StatusIconType.success"
                  [type]="'success'"></mbs-status>
      <mbs-status [textSize]="MbsSize.lg"
                  [ngbTooltip]="'backup-storages:browseStorage.tables.iconWarning' | i18next"
                  [placement]="'top-right'"
                  [container]="'body'"
                  [icon]="StatusIconType.warning"
                  [type]="'warning'"></mbs-status>
      <mbs-status [textSize]="MbsSize.lg"
                  [ngbTooltip]="'backup-storages:browseStorage.tables.iconFail' | i18next"
                  [placement]="'top-right'"
                  [container]="'body'"
                  [icon]="StatusIconType.danger"
                  [type]="'danger'"></mbs-status>
    </span>
  </ng-template>

  <ng-template #notData>—</ng-template>
  <ng-template #notDataWithText>N/A</ng-template>
</div>

<ng-template #downloadModal>
  <span class="d-block mb-3 font-weight-semibold">{{ 'backup-storages:browseStorage.computerForRestore' | i18next }}</span>

  <mbs-radio name="isLocal"
             [(ngModel)]="isLocal"
             [label]="'backup-storages:browseStorage.localComputer' | i18next"
             [value]="false"
             [id]="'is-local-restore-1'"></mbs-radio>
  <mbs-radio name="isLocal"
             [(ngModel)]="isLocal"
             [label]="'backup-storages:browseStorage.sourceComputer' | i18next: { computerName: computerData?.name }"
             [value]="true"
             [id]="'is-local-restore-2'"></mbs-radio>
</ng-template>
