<h5>{{ "rmm-side-panel:quickToolsFromGeneralTab.title" | i18next }}</h5>
<div class="row">
  <div class="col-auto"
       *ngIf="isConnectAvailable">
    <mbs-connect-button-group [customClass]="'text-secondary'"
                              [computer]="computer"></mbs-connect-button-group>
  </div>
  <div class="col-auto">
    <mbs-button [icon]="'fa fa-terminal text-md mr-1'"
                [size]="'md'"
                [disabled]="!powerShellEnabled"
                [ngbTooltip]="'rmm-side-panel:quickToolsFromGeneralTab.run' | i18next: { value: getTypeOfTerminal() }"
                (click)="openPowerShell()">
      {{ "rmm-side-panel:quickToolsFromGeneralTab.run" | i18next: { value: getTypeOfTerminal() } }}
    </mbs-button>
  </div>

  <div class="col-auto">
    <mbs-button [icon]="'fa fa-link text-md mr-1'"
                [size]="'md'"
                [disabled]="isLoading"
                [ngbTooltip]="'rmm-side-panel:quickToolsFromGeneralTab.copyLinkTooltip' | i18next"
                [placement]="['top', 'auto']"
                [container]="'body'"
                (click)="copyLink()">
      {{ "buttons:copyLink" | i18next: { format: 'title' } }}
    </mbs-button>
  </div>

  <div class="col-auto"
       *ngIf="!isConnectAvailable">
    <ng-template *ngTemplateOutlet="snmpOrWakeOnLan"></ng-template>
  </div>
</div>

<div class="row mt-3"
     *ngIf="!isConnectAvailable">
  <ng-container *ngTemplateOutlet="rebootFeature"></ng-container>
</div>



<div class="row mt-3"
     *ngIf="isConnectAvailable">
  <div class="col-auto">
    <ng-template *ngTemplateOutlet="snmpOrWakeOnLan"></ng-template>
  </div>
  <ng-container *ngTemplateOutlet="rebootFeature"></ng-container>
</div>


<ng-template #snmpOrWakeOnLan>
  <div *ngIf="isOnline && isSnmpSupported">
    <mbs-button [size]="'md'"
                [icon]="'ico ico-snmp text-md'"
                [disabled]="isLoading"
                (click)="openPowerSmnp()">
      {{ "rmm-side-panel:quickToolsFromGeneralTab.snmp" | i18next }}
    </mbs-button>
  </div>
  <div *ngIf="!isOnline">
    <mbs-wake-on-lan></mbs-wake-on-lan>
  </div>
</ng-template>

<ng-template #rebootFeature>
  <div class="col-auto"
       *ngIf="isOnline && isRebootSupported && ability.can('read', 'RemoteManagement')">
    <mbs-reboot-computer [container]="rmmGeneralTabContainer"
                         [disabled]="!allowRemoteManagement">
    </mbs-reboot-computer>
  </div>
</ng-template>

<ng-template #newTerminalModal>
  <form [formGroup]="newTerminalForm">
    <mbs-terminal-new formControlName="terminalValue"
                      [hid]="hid"
                      [shouldInit]="true"
                      [shouldFocus]="true"
                      height="78vh">
    </mbs-terminal-new>
  </form>
</ng-template>
