export enum PowerShellOutType {
  Unknown = -1,
  Succeed = 0,
  Error = 1,
  End = 2,
  ResetTimeout = 3,
  Warning = 4,
  Completed = 5,
  Debug = 8
}

export class PowerShellResultModel {
  outType: PowerShellOutType;
  data: string;
  number: number;
}
