<div ngbDropdown
     class="mbs-datepicker-range"
     container="body"
     #dropdown="ngbDropdown"
     [placement]="placement">
  <mbs-input-range ngbDropdownAnchor
                   (buttonClick)="dropdown.toggle()"
                   [appendButtons]="[
      {
        type: 'secondary',
        icon: 'ico ico-Calendar',
        btnType: 'button'
      }
    ]">
    <mbs-input #startDate
               mbsFocus
               ngDefaultControl
               ngProjectAs="startDate"
               name="start"
               class="date-input"
               [placeholder]="'MM.DD.YYYY'"
               [id]="elementSelectors.id.startDateInput"
               [isFocused]="focusedInput === FocusedInput.START"
               [autocomplete]="'off'"
               [ngModel]="start | date: mask"
               (change)="isValueChangedByDatepicker = false"
               (blur)="onInputChange($event)"
               (keyup)="onEnter($event)"
               (focus)="dropdown.open(); focusedInput = FocusedInput.START">
    </mbs-input>
    <mbs-input #endDate
               mbsFocus
               ngDefaultControl
               name="end"
               ngProjectAs="endDate"
               class="date-input"
               [placeholder]="'MM.DD.YYYY'"
               [id]="elementSelectors.id.endDateInput"
               [isFocused]="focusedInput === FocusedInput.END"
               [autocomplete]="'off'"
               [ngModel]="end | date: mask"
               (change)="isValueChangedByDatepicker = false"
               (blur)="onInputChange($event, false)"
               (keyup)="onEnter($event, false)"
               (focus)="dropdown.open(); focusedInput = FocusedInput.END"></mbs-input>
  </mbs-input-range>

  <div ngbDropdownMenu
       #dropdownMenu
       class="mbs-datepicker_dropdown mbs-datepicker-range_dropdown -arrow">
    <div class="d-flex align-items-center">
      <mbs-datepicker [range]="true"
                      [dayTemplate]="day"
                      [hideFooter]="true"
                      [outsideDays]="'hidden'"
                      [startDate]="calendarPage"
                      [id]="elementSelectors.id.startDateCalendar"
                      (rangeSelect)="onPicked($event)"></mbs-datepicker>
    </div>

    <div class="row align-items-center mt-2 px-3 pb-2">
      <mbs-button class="col-auto" (click)="selectPeriod(period)" *ngFor="let period of periods" [isCtrl]="true"
                  [size]="'sm'">{{ period.title }}
      </mbs-button>
    </div>
  </div>
</div>

<ng-template #day
             let-date
             let-focused="focused">
  <span class="mbs-datepicker_datepicker-day"
        [id]="'day-'+date.day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
