import RmmCommand from '@models/rmm/RmmCommand';

const RuntimeCmd = [
  RmmCommand.create('GetInfo'),
  RmmCommand.create('GetProcessesTree'),
  RmmCommand.create('GetProcess').addParam('ID', '1'),
  RmmCommand.create('Kill').addParam('ID', '1'),
  //  RmmCommand.create('Close')
  //    .addParam('ID', '1'),
  RmmCommand.create('Restart').addParam('ID', '1'),
  RmmCommand.create('Start').addParam('PATH', 'C:\\WINDOWS\\system32\\notepad.exe').addParam('HIDE', 'True')
];

const DomainCmd = [RmmCommand.create('GetInfo')];

const ServiceCmd = [
  RmmCommand.create('GetInfo'),
  RmmCommand.create('Start').addParam('SERVICENAME', 'Audiosrv'),
  RmmCommand.create('Stop').addParam('SERVICENAME', 'Audiosrv'),
  RmmCommand.create('Pause').addParam('SERVICENAME', 'Audiosrv'),
  RmmCommand.create('Continue').addParam('SERVICENAME', 'Audiosrv'),
  RmmCommand.create('ConfigStartType').addParam('SERVICENAME', 'Audiosrv').addParam('STARTTYPE', 'Auto')
];

const PowerShellTerminalCmd = [
  RmmCommand.create('Script').addParam('SCRIPT', 'Get-Process'),
  //  RmmCommand.create('Close'),
  RmmCommand.create('GetLatestDataIfPresent')
];

const BashCmd = [
  RmmCommand.create('Script').addParam('SCRIPT', 'Get-Process'),
  //  RmmCommand.create('Close'),
  RmmCommand.create('GetLatestDataIfPresent')
];

const exampleKbValues = '234431, 123234, 445654';
const UpdateCmd = [
  RmmCommand.create('GetInfo'),
  RmmCommand.create('GetInstalled'),
  RmmCommand.create('IsAutomaticUpdates'),
  RmmCommand.create('AutomaticUpdatesOn'),
  RmmCommand.create('DownloadAndInstallAll'),
  RmmCommand.create('DownloadAndInstall').addParam('KB', exampleKbValues),
  RmmCommand.create('Download').addParam('KB', exampleKbValues),
  RmmCommand.create('Install').addParam('KB', exampleKbValues),
  RmmCommand.create('Pause').addParam('KB', exampleKbValues),
  RmmCommand.create('Uninstall').addParam('KB', exampleKbValues),
  RmmCommand.create('OfflineInstallUpdate').addParam('PATH', ''),
  RmmCommand.create('OfflineUninstallUpdate').addParam('KB', ''),
  RmmCommand.create('OfflineNorestartOn'),
  RmmCommand.create('OfflineWarnRestartOn'),
  RmmCommand.create('OfflinePromtRestartOn'),
  RmmCommand.create('OfflineForceRestartOn'),
  RmmCommand.create('OfflineNoRestartOff'),
  RmmCommand.create('OfflineWarnRestartOff'),
  RmmCommand.create('OfflinePromtRestartOff'),
  RmmCommand.create('OfflineForceRestartOff'),
  RmmCommand.create('OfflineQuietOff')
];

const EventLogCmd = [
  RmmCommand.create('GetInfo'),
  RmmCommand.create('GetLogEventsInfo')
    .addParam('LOGDISPLAYNAME', 'RMMToolsLog')
    .addParam('START', '')
    .addParam('STOP', '')
    .addParam('MAXCOUNT', 1000)
];

const SQLCmd = [
  RmmCommand.create('GetInfo'),
  RmmCommand.create('GetLogEventsInfo')
    .addParam('INSTANCENAME', 'SQLSERVER')
    .addParam('SOURCENAME', '')
    .addParam('LOGFILENAME', 'LOGFILE')
    .addParam('START', '')
    .addParam('STOP', '')
    .addParam('MAXCOUNT', 1000)
];

const SummaryCmd = [
  RmmCommand.create('GetInfo'),
  RmmCommand.create('GetRefreshedInfo'),
  RmmCommand.create('Restart').addParam('FORCEAPPCLOSED', 'True'),
  RmmCommand.create('Lock'),
  RmmCommand.create('GetPerformanceInfo').addParam('GETHISTORY', 'False'),
  RmmCommand.create('SetPerformanceHistorySettings').addParam('HISTORYLENGTH', '100'),
  RmmCommand.create('GetCommonPerformanceInfo').addParam('GETHISTORY', 'false'),
  RmmCommand.create('TryRecoveryCounters')
];

const AntivirusCmd = [
  RmmCommand.create('GetInfo'),
  RmmCommand.create('GetSummaryIfPresent'),
  RmmCommand.create('GetLatestDataIfPresent'),
  RmmCommand.create('QuickFindCommandLineTools'),
  RmmCommand.create('FindCommandLineToolsOnSystemDisk'),
  RmmCommand.create('FindCommandLineToolsInProgramFiles'),
  RmmCommand.create('GetCommandLineToolsList'),
  RmmCommand.create('GetActiveCommandLineTool'),
  RmmCommand.create('SetActiveCommandLineTool'),
  RmmCommand.create('QuarantineItems'),
  RmmCommand.create('QuickScan'),
  RmmCommand.create('FullScan'),
  RmmCommand.create('PathScan').addParam('PATH', 'C:\\'),
  RmmCommand.create('BootRecordScan'),
  RmmCommand.create('CustomCommand'),
  RmmCommand.create('SignatureUpdate').addParam('COMMAND', ' -YOUR COMMAND'),
  RmmCommand.create('GetHelp')
];

const exampleRegistryPath = '\\HKEY_CURRENT_USER\\Console';
const RegistryCmd = [
  RmmCommand.create('SelectLocalMachine'),
  RmmCommand.create('SelectClassesRoot'),
  RmmCommand.create('SelectCurrentConfig'),
  RmmCommand.create('SelectCurrentUser'),
  RmmCommand.create('SelectUsers'),
  RmmCommand.create('SelectPerformanceData'),
  RmmCommand.create('Goto').addParam('ADDRESSORKEYNAME', exampleRegistryPath),
  RmmCommand.create('GetValueNames'),
  RmmCommand.create('GetSubKeyNames'),
  RmmCommand.create('GetValueType').addParam('VALUENAME', 'CursorColor'),
  RmmCommand.create('CreateSubkey').addParam('SUBKEYNAME', 'NewKey'),
  RmmCommand.create('DeleteSubkeyOrTree').addParam('SUBKEYNAME', 'NewKey'),
  RmmCommand.create('FindKey').addParam('STARTADDRESS', exampleRegistryPath).addParam('NAMEORFRAGMENT', 'ewKey'),
  RmmCommand.create('FindKeyAndData').addParam('STARTADDRESS', exampleRegistryPath).addParam('NAMEORFRAGMENT', 'ewKey'),
  RmmCommand.create('TakeKey').addParam('ADDRESSORKEYNAME', exampleRegistryPath),
  RmmCommand.create('TakeTree').addParam('ADDRESSORKEYNAME', exampleRegistryPath)
];

const HyperVCmd = [
  RmmCommand.create('GetInfo'),
  RmmCommand.create('GetVMNames'),
  RmmCommand.create('GetState').addParam('VMNAME', ''),
  RmmCommand.create('Enabled').addParam('VMNAME', ''),
  RmmCommand.create('Disabled').addParam('VMNAME', ''),
  RmmCommand.create('Reboot').addParam('VMNAME', ''),
  RmmCommand.create('Reset').addParam('VMNAME', ''),
  RmmCommand.create('Paused').addParam('VMNAME', ''),
  RmmCommand.create('Suspended').addParam('VMNAME', '')
];

const NetworkCmd = [
  RmmCommand.create('GetInfo'),
  RmmCommand.create('GetStatistic'),
  RmmCommand.create('StatisticOn').addParam('QUEUELENGTH', '100').addParam('POLLINGTIMEMS', '100'),
  RmmCommand.create('StatisticOff')
];

const SoftwareCmd = [
  RmmCommand.create('GetInstalledSoftwareListPerMachine'),
  RmmCommand.create('CanUninstallProduct'),
  RmmCommand.create('UninstallSeveralProducts'),
  RmmCommand.create('GetLatestAsyncOperationStatus'),
  RmmCommand.create('UninstallProduct').addParam('identifyingNumber', ''),
  RmmCommand.create('TryCancelAsyncOperation').addParam('asyncID', ''),
  RmmCommand.create('GetCurrentAsyncOperationStatus').addParam('asyncID', '')
];

const restartCmd = [
  RmmCommand.create('Restart').addParam('asyncID', '').addParam('FORCEAPPCLOSED', 'False')
];

export const RmmCommands = {
  RuntimeCmd,
  DomainCmd,
  ServiceCmd,
  PowerShellTerminalCmd,
  UpdateCmd,
  EventLogCmd,
  SQLCmd,
  SummaryCmd,
  AntivirusCmd,
  RegistryCmd,
  HyperVCmd,
  NetworkCmd,
  SoftwareCmd,
  BashCmd,
  restartCmd
} as const;
