import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import Administrator from '@models/Administrator';
import Computer, { AgentType, OsType } from '@models/Computer';
import RmmCommand from '@models/rmm/RmmCommand';
import RmmLastStatData from '@models/rmm/RmmLastStatData';
import { Status, UpdateStatus } from '@models/rmm/UpdateStatus';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AuthService, RmmService } from '@services';
import { exportToCsv } from '@utils/cvs';
import { baseDateFileNameFormatHoursAndMinutes } from '@utils/date';
import { HotfixAvailableInfoExtend } from '@utils/helpers/rmm/severity-score';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import {
  autoFetch,
  AutoFetchState,
  formatBytes,
  HttpResponseError,
  MbsPopupType,
  ModalService,
  ModalSettings,
  SharedPersistentStateEnum,
  TableHeader,
  ToastService
} from 'mbs-ui-kit';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import moment from 'moment';
import { noop, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, map, share, switchMap } from 'rxjs/operators';
import { RmmTabBaseComponent } from '../rmm-tab-base.component';

export enum HeaderAvailability {
  Windows = OsType.windows,
  Linux = OsType.linux,
  Mac = OsType.apple
}

type TableHeaderExtend = TableHeader & {
  isFullHeader?: boolean;
  availableFor: HeaderAvailability[];
};

const UPDATE_STATUS_INTERVAL = 10000;

@UntilDestroy()
@Component({
  selector: 'mbs-hotfixes-available-tab',
  templateUrl: './hotfixes-available-tab.component.html'
})
export class HotfixesAvailableTabComponent extends RmmTabBaseComponent<HotfixAvailableInfoExtend> implements OnInit {
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;
  public readonly alertType = MbsPopupType;
  public translationKey = 'rmm-side-panel:hotfixesAvailableTab.';
  public fullHeaders: TableHeaderExtend[] = [
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.hotfix'),
      overflow: false,
      sort: 'kb',
      gridColSize: '20fr',
      gridColMin: '74px',
      availableFor: [HeaderAvailability.Windows]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.severity'),
      overflow: true,
      sort: 'severity',
      gridColSize: '25fr',
      gridColMin: '78px',
      availableFor: [HeaderAvailability.Windows]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.title'),
      overflow: true,
      sort: 'title',
      gridColSize: '40fr',
      gridColMin: '80px',
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Mac]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.description'),
      sort: 'description',
      overflow: true,
      gridColSize: '50fr',
      gridColMin: '100px',
      isFullHeader: true,
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Mac]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.downloaded'),
      sort: 'isDownloaded',
      gridColSize: '30fr',
      gridColMin: '50px',
      isFullHeader: true,
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Mac]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.size'),
      sort: 'size',
      gridColSize: '20fr',
      gridColMin: '30px',
      availableFor: [HeaderAvailability.Windows]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.name'),
      overflow: false,
      sort: 'kb',
      gridColSize: '30fr',
      gridColMin: '80px',
      availableFor: [HeaderAvailability.Linux]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.version'),
      overflow: true,
      sort: 'updateID',
      gridColSize: '30fr',
      gridColMin: '80px',
      availableFor: [HeaderAvailability.Linux]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.category'),
      overflow: true,
      sort: 'categories',
      gridColSize: '30fr',
      gridColMin: '80px',
      isFullHeader: true,
      availableFor: [HeaderAvailability.Windows]
    }
  ];
  public shortHeaders: TableHeaderExtend[] = this.fullHeaders.filter((h) => !h.isFullHeader);
  public selectedItems: HotfixAvailableInfoExtend[] = [];
  public rebootStatus$: Observable<boolean>;

  hasInstallationActivities = false;
  updateStatusText = '';
  lastUpdateStatusText = '';
  downloadedCount = 0;
  totalDownloadCount = 0;
  downloadedBytes = '0 MB';
  totalBytesForDownload = '0 MB';
  installedCount = 0;
  totalInstalledCount = 0;
  installQuery: HotfixAvailableInfoExtend[] = [];
  installMany = false;
  currentUser: Administrator;

  @Input() computerName: string;
  @Input() readOnly: boolean;

  private lastUpdateStatus: UpdateStatus;
  public isMacOS: boolean;
  public isLinuxOS: boolean;
  public showTableCheckboxes = false;
  public bindSelected = 'kb';
  public macUpdateStatus: string = null;
  private installSoftMinVersionForUnix = 160;
  public isInstallUpdateAvailable = false;

  @ViewChild('confirmInstallModal', { static: true, read: TemplateRef }) confirmInstallTpl: TemplateRef<any>;

  private errorLogger = new Subject<AutoFetchState>();
  constructor(
    public rmmService: RmmService,
    public commandService: CommandService,
    public authService: AuthService,
    private toastService: ToastService,
    private modal: ModalService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private computersFacade: ComputersFacade,
    private i18n: I18NextPipe
  ) {
    super();
    this.authService.currentUser.pipe(untilDestroyed(this)).subscribe((currentUser) => (this.currentUser = currentUser));
  }

  ngOnInit(): void {
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (computer && computer.os) {
        this.isMacOS = computer.os === OsType.apple;
        this.isLinuxOS = computer.os === OsType.linux;
        this.isInstallUpdateAvailable =
          (!this.isMacOS && !this.isLinuxOS) ||
          Computer.IsSupportedAgentVersion(computer, AgentType.RMM, this.installSoftMinVersionForUnix);
        this.showTableCheckboxes = this.isInstallUpdateAvailable;
        if (this.isMacOS || this.isLinuxOS) this.bindSelected = 'title';
      }
      this.headers = this.isModal
        ? this.getTableHeadersWithOSDependency(this.fullHeaders)
        : this.getTableHeadersWithOSDependency(this.shortHeaders);
    });

    this.setStatusInfo({} as UpdateStatus);
    this.searchFields = ['kb', 'title'];
    if (this.isOnline) {
      this.initStreams();
      this.fetchData();
    } else {
      this.fetchData();
    }
  }

  private getTableHeadersWithOSDependency(tableHeaders: TableHeaderExtend[]): TableHeaderExtend[] {
    switch (true) {
      case this.isLinuxOS:
        return tableHeaders.filter((column) => column.availableFor.includes(HeaderAvailability.Linux));
      case this.isMacOS:
        return tableHeaders.filter((column) => column.availableFor.includes(HeaderAvailability.Mac));
      default:
        return tableHeaders.filter((column) => column.availableFor.includes(HeaderAvailability.Windows));
    }
  }

  initStreams() {
    const commandParams = RmmCommand.create('GetUpdateStatus');
    const status$ = this.commandService.sendCommandAsync<UpdateStatus>('UpdateCmd', commandParams, this.hid, false).pipe(
      autoFetch(this.errorLogger, { interval: UPDATE_STATUS_INTERVAL, maxRetryAttempts: 4 }),
      switchMap((status) => this.commandService.messages$),
      map((response: any) => response?.Data && typeof response?.Data === 'string' && JSON.parse(response?.Data)),
      share()
    );

    this.rebootStatus$ = status$.pipe(
      filter((updateStatus) => updateStatus),
      map(({ rebootRequired }) => (rebootRequired ? Boolean(rebootRequired) : false))
    );

    status$.pipe(filter(Boolean), untilDestroyed(this)).subscribe(
      (status: UpdateStatus) => {
        if (this.isMacOS || this.isLinuxOS) this.macUpdateStatus = status.installationError;
        this.setStatusCounts(status);
        this.setStatusInfo(status);
        this.disableItems(status);
        this.lastUpdateStatus = status;
      },
      (error: HttpResponseError) =>
        this.toastService.error(this.i18n.transform(this.translationKey + 'errorMsg', { value: error.error.title }))
    );

    status$
      .pipe(
        filter(Boolean),
        distinctUntilChanged((a: UpdateStatus, b: UpdateStatus) => a.processing === b.processing),
        untilDestroyed(this)
      )
      .subscribe((status) => {
        if (!this.loaded || !status.processing) {
          this.fetchData();
        }
      });
  }

  setStatusInfo(updateStatus: UpdateStatus) {
    if (!updateStatus) {
      this.updateStatusText = this.i18n.transform(this.translationKey + 'statusMsg.idle');
      this.hasInstallationActivities = false;
      return;
    }

    if (updateStatus.installationStatus == Status.CompletedError) {
      // updates stopped, installationError occured
      this.updateStatusText = this.i18n.transform(this.translationKey + 'statusMsg.installUpdateError');
      this.lastUpdateStatusText = updateStatus.installationError;
      this.hasInstallationActivities = false;
    } else if (updateStatus.installationStatus == Status.Started) {
      // continue to work
      this.updateStatusText = this.i18n.transform(this.translationKey + 'statusMsg.installing');
      this.hasInstallationActivities = true;
    } else if (updateStatus.installationStatus == Status.Completed) {
      // updates stopped, all done
      this.updateStatusText = this.i18n.transform(this.translationKey + 'statusMsg.installed');
      this.hasInstallationActivities = false;
    } else if (updateStatus.downloadStatus == Status.CompletedError) {
      // updates stopped, dowloadError occured
      this.updateStatusText = this.i18n.transform(this.translationKey + 'statusMsg.downloadError');
      this.lastUpdateStatusText = updateStatus.downloadError;
      this.hasInstallationActivities = false;
    } else if (updateStatus.downloadStatus == Status.Started || updateStatus.downloadStatus == Status.Completed) {
      // continue to work
      this.updateStatusText = this.i18n.transform(this.translationKey + 'statusMsg.downloading');
      this.hasInstallationActivities = true;
    } else if (updateStatus.processing) {
      this.updateStatusText = this.i18n.transform(this.translationKey + 'statusMsg.preparingToInstall');
      this.hasInstallationActivities = true;
    } else {
      this.updateStatusText = this.i18n.transform(this.translationKey + 'statusMsg.idle');
      this.hasInstallationActivities = false;
    }
  }

  setStatusCounts(updateStatus: UpdateStatus): void {
    if (!updateStatus) return;

    const downloadProgressDescriptor = updateStatus.downloadProgressDescriptor;
    const installationProgressDescriptor = updateStatus.installationProgressDescriptor;

    if (downloadProgressDescriptor && installationProgressDescriptor) {
      this.downloadedCount = updateStatus.downloadProgressDescriptor.currentUpdateIndex;
      this.totalDownloadCount = updateStatus.downloadProgressDescriptor.updatesCount;
      this.downloadedBytes = formatBytes(updateStatus.downloadProgressDescriptor.totalBytesDownloaded);
      this.totalBytesForDownload = formatBytes(updateStatus.downloadProgressDescriptor.totalBytesToDownload);
      this.installedCount = updateStatus.installationProgressDescriptor.currentUpdateIndex;
      this.totalInstalledCount = updateStatus.installationProgressDescriptor.updatesCount;

      const downloadedInfo = this.i18n.transform(this.translationKey + 'downloaded', {
        value1: updateStatus.downloadProgressDescriptor.currentUpdateIndex,
        value2: updateStatus.downloadProgressDescriptor.updatesCount
      });
      const sizeInfo = this.i18n.transform(this.translationKey + 'size', {
        value1: formatBytes(updateStatus.downloadProgressDescriptor.totalBytesDownloaded),
        value2: formatBytes(updateStatus.downloadProgressDescriptor.totalBytesToDownload)
      });
      const installedInfo = this.i18n.transform(this.translationKey + 'installed', {
        value1: updateStatus.installationProgressDescriptor.currentUpdateIndex,
        value2: updateStatus.installationProgressDescriptor.updatesCount
      });

      this.lastUpdateStatusText = `${downloadedInfo}, ${sizeInfo}, ${installedInfo}`;
    }
  }

  fetchData(): void {
    this.loading = true;
    this.getAvailableUpdatesFromStore();
  }

  /**
   * Install many hotfixes
   * @param {HotfixAvailableInfoExtend[]} items hotfixes needs for install
   *
   * @return {Promise<void>}
   */
  handleInstallHotfixes(items: HotfixAvailableInfoExtend[]): Promise<void> {
    if (this.hasInstallationActivities) {
      return Promise.reject(Error(this.i18n.transform(this.translationKey + 'rejected')));
    }
    this.selected = items;

    const modalSetting: ModalSettings = {
      header: { title: this.i18n.transform(this.translationKey + 'installModalTitle', { value: this.computerName }), textOverflow: true },
      footer: { okButton: { text: this.i18n.transform('buttons:install') } }
    };

    this.cdr.detectChanges();

    return this.modal
      .open(modalSetting, this.confirmInstallTpl)
      .then(() => {
        this.installHotfixes(items);
      })
      .catch(noop);
  }

  /**
   * Install selected hotfixes from table
   */
  handleInstallFewHotfixes(): void {
    this.handleInstallHotfixes(this.selected)
      .then(() => {
        this.installMany = true;
        this.selected = [];
        this.cdr.detectChanges();
      })
      .catch();
  }

  installHotfixes(items: HotfixAvailableInfoExtend[]): void {
    const updates = items.map((update) => (this.isMacOS || this.isLinuxOS ? update.title : update.kb));

    const command = RmmCommand.create('DownloadAndInstall').addParam('KB', updates.join(', '));

    const successMessage =
      items.length == 1
        ? this.i18n.transform(this.translationKey + 'messages.installationUpdateStartedWithValue', { value: items[0].kb || items[0].title })
        : this.i18n.transform(this.translationKey + 'messages.installationUpdateStarted');
    const errorMessage =
      items.length === 1
        ? this.i18n.transform(this.translationKey + 'messages.sendingCommandInstallationError')
        : this.i18n.transform(this.translationKey + 'messages.sendingCommandInstallationErrorMulti');

    this.hasInstallationActivities = true;

    this.commandService
      .sendCommandAsync('UpdateCmd', command, this.hid, true)
      .pipe(first(), untilDestroyed(this))
      .subscribe((result) => {
        const disabled = result?.error === false;
        if (disabled) this.toastService.success(successMessage);
        items.forEach((item) => {
          item.disabled = disabled;
          item.loading = disabled;
        });
      });
  }

  disableItems(updateStatus: UpdateStatus): void {
    if (!this.allData || this.allData.length == 0) {
      return;
    }

    const itemKey = this.isMacOS || this.isLinuxOS ? 'title' : 'kb';

    if (updateStatus && Array.isArray(updateStatus.inWork) && updateStatus.inWork.length > 0) {
      const inProgressUpdates = new Map<string, HotfixAvailableInfoExtend>(
        updateStatus.inWork.map((it) => [it[itemKey], it as HotfixAvailableInfoExtend])
      );

      this.allData.forEach((item) => {
        const update = inProgressUpdates.get(item[itemKey]);
        item.disabled = !!update;
        item.loading =
          item.disabled && (updateStatus.downloadStatus == Status.Started || updateStatus.installationStatus == Status.Started);
      });
    }
  }

  getAvailableUpdatesFromStore() {
    this.rmmService
      .fetchLastData<RmmLastStatData<HotfixAvailableInfoExtend>>('update')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (fixes) => {
          if (fixes) {
            const availableUpdatesData = fixes.data
              .filter((p) => p.isInstalled === 'False')
              // .map(setSeverityScore)
              .sort((a, b) => a.severityScore - b.severityScore)
              .reverse();
            this.allData = cloneDeep(availableUpdatesData);
            this.updateData(this.allData);
          }
          this.loading = false;
        },
        error: (err) => (this.loading = false)
      });
  }

  trackBy(index: number, item: ExtendedTableRow): string {
    return (item?.item as HotfixAvailableInfoExtend)?.title;
  }

  // #region For Debug Purposes
  generateStatusForTest(downloadStatus: Status, installationStatus: Status): UpdateStatus {
    return {
      updatesKBList: [],
      tablesIsUpdated: true,
      lastDownloadAndUpdateCall: new Date(Date.now()),
      lastTablesUpdate: new Date(Date.now()),
      downloadProgressDescriptor: {
        currentUpdateIndex: 0,
        updatesCount: 0,
        totalBytesDownloaded: 0,
        totalBytesToDownload: 0
      },
      installationProgressDescriptor: {
        currentUpdateIndex: 0,
        updatesCount: 0,
        currentUpdatePercentComplete: 0
      },
      downloadStatus,
      installationStatus,
      downloadError: 'downloadError',
      installationError: 'installationError'
    } as any;
  }

  printDebugStatus(updateStatus: UpdateStatus) {
    console.log('---------------');
    console.log(
      updateStatus.downloadStatus,
      updateStatus.downloadError,
      '|',
      updateStatus.installationStatus,
      updateStatus.installationError
    );
    console.log(
      `${updateStatus.tablesIsUpdated}, Last call: ${updateStatus.lastDownloadAndUpdateCall}, Last table update: ${updateStatus.lastTablesUpdate}`
    );
    console.log(
      `Download: ${updateStatus.downloadProgressDescriptor.currentUpdateIndex}/${updateStatus.downloadProgressDescriptor.updatesCount}, ` +
        `${updateStatus.downloadProgressDescriptor.totalBytesToDownload} B/${updateStatus.downloadProgressDescriptor.totalBytesToDownload} B`
    );
    console.log(
      `Install: ${updateStatus.installationProgressDescriptor.currentUpdateIndex}/${updateStatus.installationProgressDescriptor.updatesCount}, ` +
        `${updateStatus.installationProgressDescriptor.currentUpdatePercentComplete}%`
    );
  }
  // #endregion

  public export() {
    exportToCsv(
      this.getTableName(),
      this.headers.map((header) => header.name),
      this.headers.map((header) => header.sort),
      this.data.map((item) => ({
        ...item,
        size: formatBytes(Number(item.size))
      }))
    );
  }

  private getTableName(): string {
    return `${this.computerName} ${this.i18n.transform('rmm-side-panel:sidePanelTabsName.availableUpdates')} ${moment().format(
      baseDateFileNameFormatHoursAndMinutes
    )}`;
  }

  getTooltip(value: any): string {
    if (value && Array.isArray(value) && value.length) return value[0];
    return value;
  }
}
