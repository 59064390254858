<mbs-modal [title]="'Disable Immutability'">
  <ng-container modal-body>
    <div class="text-muted mb-3">
      With the &rsquo;Allow Immutability&rsquo; option disabled, the Immutability will be&nbsp;unavailable in&nbsp;new
      backup plans with this backup destination. Existing backups with the earlier applied Immutability will remain
      immutable until their GFS keeping period expires
    </div>
    <mbs-alert class="mb-0"
               [type]="mbsPopupType.warning"
               [icon]="true">
      It&nbsp;is&nbsp;recommended to&nbsp;disable the Immutability in&nbsp;existing backup plans with this backup
      destination, otherwise these backup plans will be&nbsp;terminated with errors
    </mbs-alert>
  </ng-container>
  <ng-container modalFooter>
    <mbs-button type="primary"
                class="mr-1"
                (click)="confirmClickHandler()">Disable</mbs-button>
    <mbs-button type="secondary"
                class="ml-1"
                (click)="cancelClickHandler()">Cancel</mbs-button>
  </ng-container>
</mbs-modal>
