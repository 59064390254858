import { StepCondition } from 'mbs-ui-kit/stepper/stepper.constants';

export type StepType<Id> = {
  id: Id;
  name?: string;
  prev?: Id;
  next?: Id;
  condition?: StepCondition;
  parentId?: Id;
  isAutomatic?: true;
  touched?: true;
  children?: StepType<Id>[];
  customTransitionForward?: true;
  hidden?: boolean;
};

export enum Direction {
  Next = 'next',
  Back = 'back'
}
