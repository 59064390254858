import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ThrowErrorDirectlyHelper } from '@shared/interceptors/helpers/throw-error-directly.helper';
import { ThrowErrorWithHandlingHelper } from '@shared/interceptors/helpers/throw-error-with-handling.helper';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const unidentifiedErrorText = 'Sorry, something went wrong';
export const invalidCredentialsText = 'The specified credentials are invalid';
export const forbiddenErrorText = "Forbidden. You don't have permission to access this";

export const MANUAL_ERROR_HANDLED = 'MANUAL_ERROR_HANDLED';
export const URL_ERROR_TYPE = 'https://mspbackups.com/errors';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorHandlerService,
    private throwErrorDirectly: ThrowErrorDirectlyHelper,
    private throwErrorHandling: ThrowErrorWithHandlingHelper
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorService.error = error;

        if (this.throwErrorDirectly.isThrowErrorDirectly(req, error)) {
          return throwError(() => error);
        }

        this.throwErrorHandling.init(req, error);

        return throwError(() => error);
      })
    );
  }
}
