import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { GroupTask } from '@modules/group-tasks/store/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import SoftwareInfo from '@shared/models/rmm/SoftwareInfo';
import { I18NextPipe } from 'angular-i18next';
import { memoize } from 'lodash/fp';
import { MbsPopupType } from 'mbs-ui-kit';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { GAActions, GASelectors } from '../../../../store/group-action';
import { GAComputer } from '../../../../store/group-action/group-action.model';
import EditGroupTaskUtility from '../../../../utility/edit-group-task-utility';

interface SortOptions {
  column: string;
  direction: string;
}

export interface SystemUpdate {
  id: SystemUpdate['updateID'];
  allComputers: boolean;
  autoDownload: string;
  autoSelection: string;
  categories: string[];
  computerCount: number;
  description: string;
  downloadPriority: string;
  installedBy: string;
  installedOn: string;
  isBeta: string;
  isDownloaded: string;
  isHidden: string;
  isInstalled: string;
  isMandatory: string;
  isPresent: string;
  isUninstallable: string;
  kb: string;
  rebootRequired: string;
  size: number;
  status: null;
  title: string;
  type: string;
  updateID: string;
  severity: string;
  severityScore: number;
  computers: SystemUpdateComputer[];
}

export interface SystemUpdateComputer {
  hid: GAComputer['hid'];
  computerName: string;
}
export interface Software extends SoftwareInfo {
  id: string;
  identifyingNumber: Software['id'];
  allComputers: boolean;
  canUninstall: number;
  computerCount: number;
  computers: Partial<GAComputer>[];
  dataDeleted: boolean;
  name: string;
  vendor: string;
  version: string;
}

function getComputerNames(data: SystemUpdate) {
  return data.computers.map((computer) => computer.computerName).join(', ');
}

@UntilDestroy()
@Component({
  selector: 'mbs-install-system-update',
  templateUrl: './install-system-update.component.html',
  styles: []
})
export class InstallSystemUpdateComponent implements OnInit {
  public loading$: Observable<boolean>;
  public data$: Observable<SystemUpdate[]>;
  public selected$: Observable<SystemUpdate[]>;
  public total$: Observable<number>;
  public selectedCount$: Observable<number>;
  public computersCount$: Observable<number>;

  public selectedItems: Software[];

  public dataToShow$: Observable<any>;
  // variable to disable additional click. select() event happens each time you're clicking on the table
  private isSortAction = false;
  public sort$ = new BehaviorSubject<SortOptions>({
    column: 'name',
    direction: 'none'
  });

  public searchForm = new UntypedFormGroup({
    searchValue: new FormControl('')
  });
  public isLoading = false;

  public readonly alertType = MbsPopupType;
  public readonly headers = [
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderName'),
      sort: 'title',
      overflow: true,
      gridColSize: '60fr'
    },
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderSize'),
      sort: 'size',
      gridColSize: '20fr'
    },
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderSeverity'),
      overflow: true,
      gridColSize: '20fr',
      sort: 'severityScore'
    },
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderNumberOfPC'),
      sort: 'computerCount',
      gridColSize: '20fr',
      overflow: true
    }
  ];

  constructor(private store: Store, private i18n: I18NextPipe, private editGroupTaskUtility: EditGroupTaskUtility) {
    this.fetchData();

    this.dataToShow$ = combineLatest([this.data$, this.sort$]).pipe(
      map(([data, sort]) => {
        return this.handleSorting(data, sort);
      }),
      untilDestroyed(this)
    );
  }

  handleSelected(stream$: Observable<SystemUpdate[]>) {
    this.selectedCount$ = stream$.pipe(map((updates) => updates.length));
    this.computersCount$ = stream$.pipe(map((updates) => updates.reduce((ac, next) => (ac += next.computerCount), 0)));
    const osType$ = this.store.select(GASelectors.selectGActionOsType);

    combineLatest([stream$, osType$])
      .pipe(untilDestroyed(this))
      .subscribe(([updates, osType]) => {
        if (!updates.length) {
          this.store.dispatch(GAActions.setGActionParameters({ parameters: null }));
          return;
        }

        this.store.dispatch(GAActions.setGActionParameters({ parameters: this.getGroupActionParams(updates) }));
      });

    this.selected$ = stream$;
  }

  ngOnInit() {
    this.fetchData();
    this.loadGroupTaskData();
  }

  fetchData(params?) {
    this.data$ = this.editGroupTaskUtility.getAvailableWindowsUpdatesStream(params).pipe(
      tap((data) => (this.total$ = of(data.length))),
      untilDestroyed(this)
    );
  }

  getComputerNames = memoize(getComputerNames);

  handleSorting(softwareList: SystemUpdate[], sort: SortOptions): SystemUpdate[] {
    switch (sort.direction) {
      case 'asc':
        return softwareList.slice().sort((a, b) => (a[sort.column] > b[sort.column] ? 1 : -1));
      case 'desc':
        return softwareList.slice().sort((a, b) => (a[sort.column] < b[sort.column] ? 1 : -1));
      default:
        return softwareList.slice();
    }
  }

  handleSort(event) {
    this.isSortAction = true;
    this.sort$.next(event);
  }

  handleClear(e) {
    this.searchForm.setValue({ searchValue: '' });
    // UX improvement
    this.fetchData();
  }

  handleSearch(e) {
    this.isLoading = true;
    this.fetchData({ title: this.searchForm.value.searchValue.trim() });
    this.isLoading = false;
  }

  select(systemUpdates) {
    if (this.isSortAction) {
      this.isSortAction = false;
    } else {
      this.handleSelected(of(systemUpdates));
    }
  }

  updateFilters(event) {
    // console.warn(event);
  }

  private loadGroupTaskData(): void {
    combineLatest([this.dataToShow$, this.editGroupTaskUtility.getWindowsUpdatesStream()])
      .pipe(take(1))
      .subscribe(([dataToShow, dataFromTask]) => {
        const windowsUpdatesTobeInstalled = dataFromTask?.updateNameList?.flatMap(
          (updateName) => dataToShow.find((update) => update.kb === updateName) ?? []
        );
        this.selectedItems = windowsUpdatesTobeInstalled;
      });
  }

  getGroupActionParams(data: any): Partial<GroupTask> {
    const updates = data.map((update) => update.kb).join(', ');

    const parameters = [{ name: 'KB', value: updates }];

    return {
      disabled: false,
      type: 'pluginCommand',
      pluginCommandId: 'UpdateCmd',
      parameters: JSON.stringify({
        asyncID: `${getGuid()}`,
        withAsyncProgress: false,
        id: 'DownloadAndInstall',
        parameters: parameters
      }),
      confidentialOutputData: false,
      pluginActiveCommand: true,
      pluginPsCommand: false,
      description: this.getDescription(updates),
      enableOffline: false,
      eventType: 0,
      scriptGuid: null,
      softwareSourceGuid: null,
      allComputers: false,
      computers: [],
      computerTags: [],
      scheduleType: 0,
      scheduleData: null
    };
  }

  getDescription(data: Software[]): string {
    const translationKey = 'rmm.module:groupActions.actionDescription.';
    const descriptionPart1 = this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'installWindowUpdatesInfo', {});
    const descriptionPart2 = this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'installWindowUpdatesList', {
      value: data
    });
    return descriptionPart1 + '\r\n' + descriptionPart2;
  }
}
