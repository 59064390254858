import { APP_BASE_HREF } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { convertToClientUrl } from '@utils/pipes/client-url.pipe';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent } from 'mbs-ui-kit';
import { ValidationErrorType } from 'mbs-ui-kit/form/input-base/input-base.types';
import { map, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-payment-modal',
  templateUrl: './payment-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentModalComponent {
  public get creditsCount() {
    return this.form.get('creditsCount').value;
  }

  public errors: Partial<ValidationErrorType>[] = [{ key: 'creditsCount', message: this.i18pipe.transform('app:paymentModal:error') }];

  public form = new FormGroup({
    creditsCount: new FormControl(1, [Validators.min(1), Validators.required, Validators.pattern('^[0-9]*$')])
  });
  public userId = this.authService.currentUser.pipe(map((user) => user.ProviderInfo.Id));

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private authService: AuthService, @Inject(APP_BASE_HREF) private baseHref: string, private i18pipe: I18NextPipe) {}

  public getSubscribeUrl(userId: string): string {
    return `/Admin/payment.aspx?type=Subscription&OwnerID=${userId}&customPrice=${this.creditsCount}&InvoiceID=`;
  }

  public redirectToPay(): void {
    this.userId.pipe(take(1), untilDestroyed(this)).subscribe((userId) => {
      location.href = convertToClientUrl(this.getSubscribeUrl(userId), this.baseHref);
    });
  }
}
