import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewRef } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormsUtil, ModalComponent } from 'mbs-ui-kit';
import { Observable, Subject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-two-factor-auth-confirm',
  templateUrl: './two-factor-auth-confirm.component.html'
})
export class TwoFactorAuthConfirmComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;

  private invalid2FACode$ = new Subject<ValidationErrors>();
  private success2FA = false;

  public twoFactorCode = new FormControl(null, [Validators.required], [this.twoFaValidator.bind(this)]);
  public requestHIDs: string[] = [];
  public loading = false;
  public canClose = true;

  constructor(private authService: AuthService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.cdRef.detach();
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    !this.success2FA && this.authService.cancelRequestPermission();
  }

  sendCode(): void {
    if (this.twoFactorCode.invalid) {
      FormsUtil.triggerValidation(this.twoFactorCode);
      return;
    }

    this.loading = true;

    this.cdRef.detectChanges();

    this.authService
      .requestPermissions({
        hids: this.requestHIDs
      })
      .pipe(
        finalize(() => {
          this.loading = false;
          !(this.cdRef as ViewRef).destroyed && this.cdRef.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.success2FA = true;
          this.modal.save();
        },
        error: () => {
          this.invalid2FACode$.next({ invalid: { message: 'Invalid code' } });
        }
      });
  }

  twoFaValidator(control: AbstractControl): Observable<ValidationErrors> {
    return this.invalid2FACode$.pipe(first());
  }

  handleEnter(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
