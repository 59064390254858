import { StepType } from '@modules/wizards/onboarding/services/steps/steps.types';

export enum StepId {
  DownloadBuild = 'downloadBuild',
  ConfigureBackup = 'configureBackup',
  StorageAccount = 'storageAccount',
  BackupSource = 'backupSource',
  Notifications = 'notifications'
}

export const StepsConfig: StepType<StepId>[] = [
  {
    id: StepId.DownloadBuild,
    name: 'Download Build',
    prev: null,
    children: [
      {
        id: StepId.DownloadBuild,
        parentId: StepId.DownloadBuild,
        isAutomatic: true,
        name: 'Download Build',
        prev: null,
        next: StepId.StorageAccount
      }
    ]
  },
  {
    id: StepId.ConfigureBackup,
    name: 'Configure Backup',
    children: [
      {
        id: StepId.StorageAccount,
        name: 'Storage Account',
        parentId: StepId.ConfigureBackup,
        prev: StepId.DownloadBuild,
        next: StepId.BackupSource
      },
      {
        id: StepId.BackupSource,
        name: 'Backup Source',
        parentId: StepId.ConfigureBackup,
        prev: StepId.StorageAccount,
        next: StepId.Notifications
      },
      {
        id: StepId.Notifications,
        parentId: StepId.ConfigureBackup,
        name: 'Notifications',
        prev: StepId.BackupSource,
        next: null
      }
    ]
  }
];
