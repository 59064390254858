import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadConfigurations, MBS_APP_CONFIG } from '@mbs-ui/config.loader';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

function mountApp(config) {
  platformBrowserDynamic([
    {
      provide: MBS_APP_CONFIG,
      useValue: config
    }
  ])
    .bootstrapModule(AppModule)
    .then(module => {
      if (!environment.production) {
        const applicationRef = module.injector.get(ApplicationRef);
        const appComponent = applicationRef.components[0];
        enableDebugTools(appComponent);
      }
    })
    .catch(err => console.error(err));
}

loadConfigurations('assets/config/config.json')
  .then((res: string) => {
    try {
      mountApp(JSON.parse(res));
    } catch (err) {
      console.error(err);
    }
  })
  .catch(err => console.error(err.message));
