import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AddNetworkShareModalComponent } from '@components/add-network-share-modal/add-network-share-modal.component';
import { NetworkSharePath } from '@models/NetworkShareModels';
import { NetworkCredentials } from '@models/backup/network-credential';
import { DataForPath } from '@modules/wizards/helpers/bases/base-for-steps-helper';
import { CheckCredentialsResultEnum } from '@modules/wizards/models/what-backup-tree-model';
import { TreeInModalComponent } from '@modules/wizards/steps/components/tree-in-modal/tree-in-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmCommandsAbstractWrapper } from '@services/rm-commands.wrapper';
import { networkPathValidator } from '@utils/validators';
import { I18NextPipe } from 'angular-i18next';
import { FormsUtil, ModalComponent, ModalService, ModalSettings, ToastService } from 'mbs-ui-kit';
import { noop } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-network-share',
  templateUrl: './network-share-modal.component.html'
})
export class NetworkShareModalComponent implements OnInit {
  public canUseShareTree = false;
  public dataForPath: DataForPath;
  public hid: string;
  public loading = false;
  public networkSharePathArray: NetworkCredentials[] = [];
  public testResult: CheckCredentialsResultEnum = CheckCredentialsResultEnum.Unknown;

  private readonly separator = ']-[';
  private readonly treeParams = {
    agentType: 'Backup',
    commandType: 'getComputerContent',
    params: { path: '', offset: 0, limit: 300, order: 'DisplayNameAsc' }
  };

  public form: FormGroup<NetworkSharePath> = new FormGroup<NetworkSharePath>({
    path: new FormControl('', [
      Validators.required,
      networkPathValidator,
      this.isSelectedValidator.bind(this),
      this.credentialsValidator.bind(this)
    ])
  });

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    private i18nPipe: I18NextPipe,
    private modalService: ModalService,
    private rmCommands: RmCommandsAbstractWrapper,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    if (!this.baseModal.data) return;

    this.canUseShareTree = this.baseModal.data.canUseShareTree;
    this.dataForPath = this.baseModal.data.dataForPath;
    this.hid = this.baseModal.data.hid;
    this.networkSharePathArray = this.baseModal.data.networkSharePathArray;
  }

  credentialsValidator(): ValidationErrors | null {
    return this.testResult !== CheckCredentialsResultEnum.Unknown ? { invalidCredentials: true } : null;
  }

  isSelectedValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const error = { isSelected: { message: this.i18nPipe.transform('validation:existing_folder') } };
    const lowString = control.value.toLowerCase();

    return this.networkSharePathArray.some((el: NetworkCredentials) => el.path.toLowerCase() === lowString) ? error : null;
  }

  resetTestResult(): void {
    if (this.testResult !== CheckCredentialsResultEnum.Unknown) {
      this.testResult = CheckCredentialsResultEnum.Unknown;
      FormsUtil.triggerValidation(this.form);
    }
  }

  manageNetworkCredentialsClickHandler(): void {
    const settings = new ModalSettings();
    settings.isCustom = true;
    settings.data = {
      dataForPath: this.dataForPath,
      hid: this.hid,
      needSaveAfterAdd: false,
      networkSharePathArray: Array.from(this.networkSharePathArray)
    };

    this.modalService.openCustom(AddNetworkShareModalComponent, settings).then(noop).catch(noop);
  }

  pathNetworkTreeOpen(): void {
    const dataForTree = {
      title: this.i18nPipe.transform('wizards:addNetworkShare', { format: 'title' }),
      hid: this.hid,
      resultFolders: [],
      isEncrypted: false,
      hideFileType: true,
      oldBackupContentVariant: true,
      onlyOneSelect: true,
      params: this.treeParams,
      dataForPath: this.dataForPath
    };

    this.modalService
      .openCustom(TreeInModalComponent, { data: dataForTree, collapsing: true })
      .then((result: any) => result?.length && this.form.get('path').setValue(result[0]))
      .catch(noop);
  }

  addClickHandler(): void {
    FormsUtil.triggerValidation(this.form);

    if (!this.form.valid) return void (this.testResult = CheckCredentialsResultEnum.Error);

    this.setLoading(true);

    const path = this.form.value?.path;
    const newValue = {
      path: path,
      login: '',
      id: this.networkSharePathArray.length - 1 + this.separator + path.replaceAll(' ', '-')
    };

    this.rmCommands
      .checkNetworkCredentials(this.hid, { path, login: '', password: '' })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toast.success();
          this.setLoading(false);
          this.networkSharePathArray.push(newValue);
          this.baseModal.save(this.networkSharePathArray);
        },
        error: (error) => {
          if (error?.error?.detail) this.toast.error(error.error.detail);

          this.testResult = CheckCredentialsResultEnum.Error;

          this.setLoading(false);
        }
      });
  }

  cancelClickHandler(): void {
    this.baseModal.close();
  }

  private setLoading(state: boolean): void {
    this.loading = state;
    this.form[state ? 'disable' : 'enable']();
  }
}
