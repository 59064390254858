/* eslint-disable sonarjs/no-identical-functions */
import { Injectable } from '@angular/core';
import RmmCommand from '@models/rmm/RmmCommand';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RegistryValueKind } from '../components/sidepanel-rmm/remote-registry-tab/constants';

const RegistryCommandType = 'RegistryCmd';

@Injectable()
export class RmmRegEditService {
  private hid: string;
  constructor(private commandService: CommandService) {}

  getMessageByAsyncId(response) {
    if (response?.result) {
      const obj = JSON.parse(response?.result);
      const splitter = ' = ';
      const asyncId = obj.data.split(splitter)[1];

      return this.getResponseData(this.commandService.selectMessagesByAsyncId$(asyncId));
    } else {
      return this.getResponseData(this.commandService.messages$);
    }
  }

  private getResponseData(stream$: Observable<any>) {
    return stream$.pipe(map((response: any) => response?.Data && typeof response?.Data === 'string' && JSON.parse(response?.Data)));
  }

  setHid(hid: string) {
    this.hid = hid;
  }

  takeKey(path: string): Observable<any> {
    const command = RmmCommand.create('TakeKey').addParam('ADDRESSORKEYNAME', path);
    return this.sendCommandAsync(command);
  }

  createKey(path: string, keyName: string): Promise<{ path: string; keyName: string }> {
    const commandGoTo = RmmCommand.create('Goto').addParam('ADDRESSORKEYNAME', path);
    const command2 = RmmCommand.create('CreateSubkey').addParam('SUBKEYNAME', keyName);

    return new Promise<{ path: string; keyName: string }>((resolve, reject) => {
      this.commandService
        .sendCommandAsync(RegistryCommandType, commandGoTo, this.hid, false)
        .pipe(
          switchMap((data) => this.commandService.sendCommandAsync(RegistryCommandType, command2, this.hid, true)),
          switchMap((data) => this.getMessageByAsyncId(data)),
          map((message) => message?.data)
        )
        .subscribe((operationResult: any) => {
          if (this.isSuccessfulResult(operationResult) || operationResult === 'Ok') {
            resolve({ path, keyName });
          } else {
            // Todo fix @roman.sh
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Can not create key.');
          }
        });
    });
  }

  renameKey(path: string, newKeyName: string): Promise<{ path: string; keyName: string }> {
    const tmp = path.split('\\');
    const oldKeyName = tmp.pop();
    const parentPath = tmp.join('\\');
    const commandGoTo = RmmCommand.create('Goto').addParam('ADDRESSORKEYNAME', parentPath);
    const command2 = RmmCommand.create('Rename').addParam('OLDNAME', oldKeyName).addParam('NEWNAME', newKeyName);

    return new Promise<{ path: string; keyName: string }>((resolve, reject) => {
      this.commandService
        .sendCommandAsync(RegistryCommandType, commandGoTo, this.hid, false)
        .pipe(
          switchMap((data) => this.commandService.sendCommandAsync(RegistryCommandType, command2, this.hid, true)),
          switchMap((data) => this.getMessageByAsyncId(data))
        )
        .subscribe((result: any) => {
          if (result && result.data) {
            resolve({ path: parentPath, keyName: newKeyName });
          } else {
            // Todo fix @roman.sh
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Can not create key.');
          }
        });
    });
  }

  saveValue(path: string, valueName: string, valueData: string, regtype: RegistryValueKind) {
    const commandGoTo = RmmCommand.create('Goto').addParam('ADDRESSORKEYNAME', path);
    const command2 = RmmCommand.create('SetValue')
      .addParam('VALUENAME', valueName)
      .addParam('VALUE', valueData)
      .addParam('VALUEKIND', regtype.toString());

    return new Promise<{ path: string; valueName: string; valueData: string }>((resolve, reject) => {
      this.commandService
        .sendCommandAsync(RegistryCommandType, commandGoTo, this.hid, false)
        .pipe(
          switchMap((data) => this.commandService.sendCommandAsync(RegistryCommandType, command2, this.hid, true)),
          switchMap((data) => this.getMessageByAsyncId(data))
        )
        .subscribe((operationResult: any) => {
          if (this.isSuccessfulResult(operationResult)) {
            resolve({ path, valueName, valueData: operationResult.data });
          } else {
            // Todo fix @roman.sh
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Can not save registry.');
          }
        });
    });
  }

  deleteKey(path: string, keyName: string): Promise<{ path: string; keyName: string }> {
    const commandGoTo = RmmCommand.create('Goto').addParam('ADDRESSORKEYNAME', path);
    const command2 = RmmCommand.create('DeleteSubkeyOrTree').addParam('SUBKEYNAME', keyName);

    return new Promise<{ path: string; keyName: string }>((resolve, reject) => {
      this.commandService
        .sendCommandAsync(RegistryCommandType, commandGoTo, this.hid, false)
        .pipe(
          switchMap((data) => this.commandService.sendCommandAsync(RegistryCommandType, command2, this.hid, true)),
          switchMap((data) => this.getMessageByAsyncId(data))
        )
        .subscribe((operationResult: any) => {
          if (this.isSuccessfulResult(operationResult)) {
            resolve({ path, keyName });
          } else {
            // Todo fix @roman.sh
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Can not delete registry key.');
          }
        });
    });
  }

  deleteValue(path: string, valueName: string) {
    const commandGoTo = RmmCommand.create('Goto').addParam('ADDRESSORKEYNAME', path);
    const command2 = RmmCommand.create('DeleteValue').addParam('VALUENAME', valueName);

    return new Promise<{ path: string; valueName: string }>((resolve, reject) => {
      this.commandService
        .sendCommandAsync(RegistryCommandType, commandGoTo, this.hid, false)
        .pipe(
          switchMap((data) => this.commandService.sendCommandAsync(RegistryCommandType, command2, this.hid, true)),
          switchMap((data) => this.getMessageByAsyncId(data))
        )
        .subscribe((operationResult) => {
          if (this.isSuccessfulResult(operationResult)) {
            resolve({ path, valueName });
          } else {
            // Todo fix @roman.sh
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Can not delete registry value.');
          }
        });
    });
  }

  find(text: string) {
    const command = RmmCommand.create('Find')
      .addParam('withAsyncProgress', 'false')
      .addParam('STARTADDRESS', 'HKEY_LOCAL_MACHINE')
      .addParam('NAMEORFRAGMENT', text);
    return this.sendCommandAsync(command);
  }

  findNext(text: string) {
    const command = RmmCommand.create('FindNext')
      .addParam('withAsyncProgress', 'false')
      // .addParam('STARTADDRESS', 'HKEY_LOCAL_MACHINE')
      .addParam('NAMEORFRAGMENT', text);
    return this.sendCommandAsync(command);
  }

  sendCommandAsync(command: RmmCommand<any>, isActive?: boolean): Observable<any> {
    return this.commandService.sendCommandAsync(RegistryCommandType, command, this.hid, isActive);
  }

  getParsedResponse(data): any {
    // @TODO return old or legacy response depending on version and/or something else
  }

  isSuccessfulResult(operationResult: any): boolean {
    return operationResult && operationResult?.data;
  }
}
