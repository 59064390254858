import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComputersSharedModule } from '@components/computers-shared/computers-shared.module';
import { ScriptLibraryModule } from '@modules/script-library/script-library.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { SidepanelRmmManagementComponent } from './sidepanel-rmm-management.component';

const components = [SidepanelRmmManagementComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    MbsUiKitModule,
    NgbModule,
    ComputersSharedModule,
    I18NextModule,
    RouterModule,
    ScriptLibraryModule,
    AbilityModule
  ],
  exports: [...components],
  providers: []
})
export class SidepanelRmmManagementModule {}
