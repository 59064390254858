<h4>{{ 'rmm.module:groupActions.stepApplyTo.title' | i18next }}</h4>

<mbs-alert [type]="alertType.warning"
           [icon]="true"
           class="mb-3">
  {{ 'rmm.module:groupActions.stepApplyTo.computerBanner' | i18next }}
</mbs-alert>


<form [formGroup]="modeSelectionForm">
  <div class="mb-3">
    <mbs-radio [id]="elementSelectors.allCompanies"
               [label]="'rmm.module:groupActions.stepApplyTo.applyToAllCompanies' | i18next"
               [value]="selectOptions.ApplyToAllCompanies"
               formControlName="modeSelect">
    </mbs-radio>
  </div>

  <div class="mb-3">
    <mbs-radio [id]="elementSelectors.companies"
               [label]="'rmm.module:groupActions.stepApplyTo.applyToSelectedCompanies' | i18next"
               [value]="selectOptions.ApplyToAllComputersInSelectedCompanies"
               [descriptionOutside]="false"
               formControlName="modeSelect">
      <ng-template radioDescription>
        <mbs-iteration-ctrl-button (buttonClick)="openSelectCompaniesModal()"
                                   [disabled]="false"
                                   [editIconVisible]='true'
                                   [selected]="selectedCompanyList"
                                   [emptyLabel]="'rmm.module:groupActions.stepApplyTo.selectCompanies' | i18next">
        </mbs-iteration-ctrl-button>
      </ng-template>
    </mbs-radio>
  </div>

  <div class="mb-3">
    <mbs-radio [id]="elementSelectors.computers"
               [label]="'rmm.module:groupActions.stepApplyTo.applyToSelectedComputersOnly' | i18next"
               [value]="selectOptions.ApplyToSelectedComputersOnly"
               [descriptionOutside]="false"
               formControlName="modeSelect">
      <ng-template radioDescription>
        <mbs-iteration-ctrl-button (buttonClick)="openSelectComputersModal()"
                                   [disabled]="false"
                                   [editIconVisible]='true'
                                   [selected]="selectedComputerList"
                                   [emptyLabel]="'rmm.module:groupActions.stepApplyTo.selectComputers' | i18next">
        </mbs-iteration-ctrl-button>
      </ng-template>
    </mbs-radio>
  </div>

  <hr>

  <div class="row">
    <div class="col-auto">
      <mbs-switcher formControlName="enableExclude"
                    [id]="elementSelectors.exclude">
      </mbs-switcher>
    </div>

    <div class="col">
      <label class="mbs-form_label mb-0"
             for="enabled">
        {{ 'rmm.module:groupActions.stepApplyTo.excludeComputersDescription' | i18next }}
      </label>
      <div class="row">
        <div class="col-10">
          <mbs-iteration-ctrl-button (buttonClick)="openSelectComputersModal(true)"
                                     [selected]="selectedExcludedComputersList"
                                     [emptyLabel]="'rmm.module:groupActions.stepApplyTo.selectComputers' | i18next">
          </mbs-iteration-ctrl-button>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-auto">
      <mbs-switcher formControlName="enableTags"
                    [id]="elementSelectors.tags">
      </mbs-switcher>
    </div>

    <div class="col">
      <label class="mbs-form_label mb-0"
             for="enabled">
        {{ 'rmm.module:groupActions.stepApplyTo.selectTagDescription' | i18next }}
      </label>

      <div class="row">
        <div class="col-10">
          <div *ngIf="selectedTagNames.length"
               class="mt-2">
            <span *ngFor="let tag of selectedTagNames"
                  class="mr-1">
              <mbs-tag [clickable]="false"
                       [closable]="true"
                       [active]="false"
                       [noBorder]="true"
                       [class.bg-danger-20]="tag?.removed"
                       (mbsClose)="handleClose(tag)">
                {{ tag?.name ?? 'rmm-group-task.module:removedTagName' | i18next: { value: tag.id } }}
              </mbs-tag>
            </span>
          </div>
          <div class="mt-2">
            <mbs-button [isCtrl]="true"
                        [size]="'md'"
                        (click)="openSelectTagsModal()">
              {{ 'rmm.module:groupActions.stepApplyTo.selectTags' | i18next }}
            </mbs-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>


<ng-template #tagSelectionModal>
  <mbs-list-select [data]="tags"
                   bindSelected="id"
                   [showSearch]="true"
                   [showCheckboxes]="true"
                   [showTopControls]="true"
                   [topControlsLabelSelected]="false"
                   [selectedData]="selectedTagList"
                   (changeSelected)="changeTagsHandler($event)"
                   [keepState]="true"
                   [loadSource]="false"
                   filterFields="name"
                   placeholder="Enter search request"
                   [selectRowClass]="'-selected'"
                   [headers]="tagHeaders">

    <ng-template prependTopControlsLabel>
      <strong>{{ 'buttons:selectAll' | i18next }}</strong>
    </ng-template>

    <ng-template let-tag
                 mbsTableCell>
      {{ tag.name }}
    </ng-template>
  </mbs-list-select>
</ng-template>
