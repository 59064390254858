import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';
import { selectCurrentEntry, selectFilteredEntry } from '../store/event-total.selectors';

import { EventEntry } from '../store/model';
import { EventTableView } from '../event-total-tab.model';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent } from 'mbs-ui-kit';
import { Store } from '@ngrx/store';
import { showDetailEntry } from '../store/event-entry.actions';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { OsType } from '@models/Computer';
@UntilDestroy()
@Component({
  selector: 'mbs-event-entry-modal',
  templateUrl: './event-entry-modal.component.html',
  styleUrls: ['./event-entry-modal.component.scss']
})
export class EventEntryModalComponent implements OnInit {
  public dataTable$: Observable<EventTableView[]>;
  public data$: Observable<EventEntry>;
  public entries$: Observable<EventEntry[]>;
  public title$: Observable<string>;
  private veiwFields: string[] = ['logName', 'source', 'eventID', 'logLevel', 'userName', 'opcode', 'keywords', 'timeWritten', 'category'];

  public next$: Observable<number>;
  public prev$: Observable<number>;
  public isMacOrLinuxOS: boolean;

  public siblings$: Observable<{
    next: number;
    prev: number;
  }>;

  @ViewChild('modal', { static: false }) baseModal: ModalComponent;

  constructor(private i18nPipe: I18NextPipe, private store: Store, private computersFacade: ComputersFacade) {
    this.computersFacade.currentComputer$
      .pipe(untilDestroyed(this))
      .subscribe(computer => {
      this.isMacOrLinuxOS = [OsType.apple, OsType.linux].includes(computer?.os);
      });
  }

  ngOnInit(): void {
    const data$ = this.store.select(selectCurrentEntry).pipe(filter(Boolean));
    const entriesIdsMap$ = this.store.select(selectFilteredEntry).pipe(
      map(entries =>
        entries.reduce((acc, i) => {
          acc[i.order] = i.id;
          return acc;
        }, {})
      )
    );

    const siblings$: Observable<{
      prev: number;
      next: number;
    }> = combineLatest([data$, entriesIdsMap$]).pipe(
      map(([data, entriesIdsMap]) => {
        const position = data.order;

        return {
          next: this.findSibling(position, 1, entriesIdsMap),
          prev: this.findSibling(position, -1, entriesIdsMap)
        };
      })
    );

    this.siblings$ = siblings$;

    this.data$ = data$;
    this.title$ = data$.pipe(map(this.getTitle));
    this.dataTable$ = data$.pipe(map(this.mapToView));
  }

  showSibling(id) {
    if (id) {
      this.store.dispatch(showDetailEntry({ id }));
    }
  }

  close() {
    this.baseModal.close();
  }

  findSibling(position, direction, entriesIdsMap): number | undefined {
    const keys = Object.keys(entriesIdsMap);
    const index = keys.findIndex(i => i == position);
    const sibling = keys[index + direction];
    return entriesIdsMap[sibling];
  }

  getTitle = (entry: EventEntry): string => {
    const tEvent = this.i18nPipe.transform('labels.event', { format: 'title' });
    return this.isMacOrLinuxOS ? entry.source : `${tEvent} ${entry.eventID}, ${entry.source}`;
  };

  mapToView = (entry: EventEntry): EventTableView[] => {
    return this.veiwFields.map(key => {
      switch (key) {
        case 'logName':
          return { key, value: entry.logType };
        case 'logLevel':
          return { key, value: entry.level, cssClass: entry.cssClass };
        case 'timeWritten': {
          return { key, value: moment(entry[key]).format('MMM DD, YYYY, h:mm A') };
        }
        case 'category': {
          return { key, value: entry[key] == '(0)' ? null : entry[key] };
        }
        default:
          return { key, value: entry[key] };
      }
    });
  };
}
