<ng-container *ngIf="!loading; else loader">

  <div *ngIf="selectedView"
       class="d-flex justify-content-between mb-4 pb-2">
    <mbs-button [isCtrl]="true"
                [icon]="'fa fa-arrow-circle-left text-lg'"
                (click)="onViewChoiceButtonClick(null)">
      {{ 'onboarding:buttons.back_to_start' | i18next }}
    </mbs-button>

    <mbs-button [isCtrl]="true"
                [icon]="'fa fa-arrow-circle-right text-lg'"
                (click)="finish(finishPath.Computers)">
      {{ 'onboarding:buttons.skip_wizard' | i18next: { format: 'title' } }}
    </mbs-button>
  </div>

  <ng-container [ngSwitch]="selectedView">

    <ng-container *ngSwitchCase="null">
      <div class="text-center">
        <h1>{{ 'onboarding:main_title' | i18next: { format: 'title' } }}</h1>

        <p class="pre-wrap m-0 mbs-onboarding-wizard_description">{{ 'onboarding:welcome_to_trial' | i18next }}</p>
      </div>

      <div class="row justify-content-center pt-5">
        <div *ngFor="let tile of tilesConfig"
             class="col-4">
          <mbs-tile [tileTemplate]="tileTemplate"
                    [disabled]="loading"
                    [selectable]="true"
                    (click)="onViewChoiceButtonClick(tile.id)">
            <ng-template #tileTemplate>
              <div class="d-flex flex-column align-items-center p-5">
                <img [src]="tile.icon"
                     [alt]="tile.title"
                     class="mb-4">
                <span class="font-weight-semibold mb-1 pre-wrap text-center">{{ tile.title }}</span>
                <span *ngIf="tile.description"
                      class="text-md text-gray-600">{{ tile.description }}</span>
              </div>
            </ng-template>
          </mbs-tile>
        </div>
      </div>

      <div class="text-center">
        <mbs-button class="mt-5"
                    [isCtrl]="true"
                    [icon]="'fa fa-arrow-circle-right text-lg'"
                    (click)="finish(finishPath.Computers)">
          {{ 'onboarding:buttons.skip_wizard' | i18next: { format: 'title' } }} </mbs-button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="view.Computers">
      <mbs-onboarding-wizard-computers-view [mode]="mode"
                                            (finish)="finish($event)"></mbs-onboarding-wizard-computers-view>
    </ng-container>

    <ng-container *ngSwitchCase="view.HyperV">
      <mbs-onboarding-wizard-hyper-v-view (finish)="finish($event)"></mbs-onboarding-wizard-hyper-v-view>
    </ng-container>

    <ng-container *ngSwitchCase="view.Apps">
      <mbs-onboarding-apps-view [mode]="mode"
                                [step]="step"
                                (finish)="finish($event)"></mbs-onboarding-apps-view>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loader>
  <mbs-loader></mbs-loader>
</ng-template>
