<div ngbDropdown
     container="body"
     [dropdownClass]="dropdownClass"
     placement="bottom-left"
     class="mbs-tags-input">
  <mbs-input-label *ngIf="label && id"
                   [label]="label"
                   [id]="id"
                   [disabled]="disabledState"
                   [boldLabel]="boldLabel"
                   [info]="info"
                   [labelSize]="labelSize"
                   [infoPlacement]="infoPlacement"
                   [infoTriggers]="infoTriggers"
                   [infoContainer]="infoContainer"
                   [required]="hasRequiredValidator && showRequiredMark"
                   [labelClasses]="labelClasses"
                   [labelContentClasses]="labelContentClasses">
    <ng-content select="[prependLabel]"
                ngProjectAs="[prependLabel]"></ng-content>
    <ng-content select="[appendLabel]"
                ngProjectAs="[appendLabel]"></ng-content>
  </mbs-input-label>

  <div class="mbs-tags-input_box"
       ngbDropdownAnchor
       [class.-focused]="isFocused"
       [class.-error]="control?.invalid">
    <ng-container *ngFor="let item of value; let index = index">
      <mbs-tag [closable]="true"
               [noBorder]="true"
               [class.-error]="item.errors?.length"
               [class.bg-red-20]="item.errors?.length"
               [disabled]="loading"
               [id]="idPrefix + item.name"
               (mbsClose)="handleClose(index)">
        {{ item.name }}
      </mbs-tag>
    </ng-container>

    <input class="mbs-tags-input_input"
           #inputField
           [class.-invalid]="inputControl.invalid"
           ngbDropdownToggle
           [formControl]="inputControl"
           [tabindex]="tabindex"
           [attr.aria-describedby]="id"
           [attr.aria-labelledby]="label"
           [placeholder]="placeholder"
           [ngClass]="bindClasses"
           [id]="id"
           [autocomplete]="'off'"
           (blur)="handleBlur()"
           (focus)="handleFocus()"
           (input)="handleChange($event)"
           (keydown)="handleKeyDown($event)"
           [readonly]="loading" />

    <span *ngIf="loading" class="loader ml-auto mr-2"></span>
  </div>

  <mbs-input-errors *ngIf="control.invalid" [errors]="mapErrors(control.errors)"></mbs-input-errors>

  <div ngbDropdownMenu>
    <mbs-button *ngIf="actionButton && inputControl.value && !filteredSuggestions.includes(inputControl.value)"
                class="border-bottom pb-2"
                (click)="onActionButtonPressed()"
                ngbDropdownItem
                [isCtrl]="true">{{ actionButton }} {{ inputControl.value }}</mbs-button>
    <button *ngFor="let suggestion of filteredSuggestions"
            ngbDropdownItem
            (click)="onSuggestionItemClick(suggestion)">{{ suggestion }}</button>
  </div>
</div>
