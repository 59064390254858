import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PosthogService } from './posthog.service';

@NgModule({
  imports: [CommonModule],
  declarations: []
})
export class PosthogModule {
  /**
   *
   */
  constructor(private posthogService: PosthogService) {
    this.posthogService.initialize();
  }
}
