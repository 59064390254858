import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class LicensesGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.currentUser.pipe(
      switchMap((user) => {
        const isPostPayment = user.ProviderInfo?.IsPostPayment;

        if (state.url === RoutingPath.ApLicenses && !isPostPayment) {
          void this.router.navigate([RoutingPath.ApLicensesAspx]);
        }

        if (state.url === RoutingPath.ApLicensesAspx && isPostPayment) {
          void this.router.navigate([RoutingPath.ApLicenses]);
        }

        return of(true);
      })
    );
  }
}
