<div class="mbs-form-group">
  <mbs-select [items]="commandTypeList"
              label="Command type:"
              required
              [(ngModel)]="selectedCommandType"
              [clearable]="false"
              (change)="commandTypeSelectChangeHandler($event)">
  </mbs-select>
</div>

<div class="mbs-form-group">
  <mbs-select [items]="commandList"
              label="Command:"
              required
              [(ngModel)]="selectedCommand"
              [clearable]="false"
              (change)="commandSelectChangeHandler($event)">
  </mbs-select>
</div>

<div class="mbs-form-group">
  <mbs-input label="Parameters:"
             type="textarea"
             [rows]="6"
             [(ngModel)]="commandParams">
  </mbs-input>
</div>

<div class="mbs-form-group">
  <div class="row align-items-center flex-nowrap">
    <div class="col-auto">
      <mbs-button ngbTooltip="Send command"
                  (click)="sendCommand()">
        Send command
      </mbs-button>
    </div>
    <div class="col">
      <mbs-checkbox [(ngModel)]="isAsync"
                    label="Async"></mbs-checkbox>
    </div>
  </div>
</div>

<div class="mbs-form-group">
  <mbs-input type="textarea"
             label="Command result:"
             [rows]="12"
             [(ngModel)]="commandResult"
             disabled>
  </mbs-input>
</div>
