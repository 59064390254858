import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ComputersAbstractService, ComputersService } from '@services/computers.service';
import { RmCommandsAbstractService, RmCommandsService } from '@services/rm-commands.service';
import { RmCommandsAbstractWrapper, RmCommandsWrapper } from '@services/rm-commands.wrapper';
import { ComputerAppsEffects } from './store/computer-apps.effects';
import { reducer } from './store/computer-apps.reducer';
import { FeatureKey } from './store/const';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(FeatureKey, reducer), EffectsModule.forFeature([ComputerAppsEffects])],
  providers: [
    { provide: ComputersAbstractService, useClass: ComputersService },
    { provide: RmCommandsAbstractService, useClass: RmCommandsService },
    { provide: RmCommandsAbstractWrapper, useClass: RmCommandsWrapper }
  ]
})
export class ComputerAppsModule {}
