import { LineTypes, PromptBufferConfig, ShellLineFactory } from './model';
export class ShellLine {
  type: LineTypes;
  cols: number;
  source: string;
  data: string;
  offset: number;
  y: number;
  viewportY: number;

  get length(): number {
    return this.source.length;
  }

  constructor(source = '', config: PromptBufferConfig, y = 0) {
    this.cols = config.cols;
    this.type = config.type;
    this.viewportY = config.viewportY;
    this.source = source;
    this.y = y;

    switch (config.type) {
      case LineTypes.Prompt: {
        const offset = config.prompt.length;
        this.offset = offset;
        this.data = source.slice(offset);
        break;
      }
      case LineTypes.New: {
        const offset = config.promptNewLine.length;
        this.offset = offset;
        this.data = source.slice(offset);
        break;
      }
      case LineTypes.Regular:
      case LineTypes.Blank:
      default: {
        this.offset = 0;
        this.data = source;
      }
    }
  }

  static create = (config: PromptBufferConfig, viewportY): ShellLineFactory => (source?: string, y?: number): ShellLine => {
    let type;

    if (!source) {
      return new ShellLine(source, Object.assign({ type: LineTypes.Blank, viewportY }, config), y);
    }

    switch (true) {
      case source.indexOf(config.prompt) == 0: {
        return new ShellLine(source, Object.assign({ type: LineTypes.Prompt, viewportY }, config), y);
      }
      case source.indexOf(config.promptNewLine) == 0: {
        return new ShellLine(source, Object.assign({ type: LineTypes.New, viewportY }, config), y);
      }
      case source.indexOf(config.errorLine) == 0: {
        return new ShellLine(source, Object.assign({ type: LineTypes.Error, viewportY }, config), y);
      }
      default: return new ShellLine(source, Object.assign({ type: LineTypes.Regular, viewportY }, config), y);
    }
  };
}
