import { SortDirection, SortEvent } from 'mbs-ui-kit';

export const getReversedTableSorting = (orderBy: SortEvent): SortEvent => {
  return {
    column: orderBy.column,
    direction: getReversedSortingDirection(orderBy)
  };
};

export const getReversedSortingDirection = (orderBy: SortEvent): SortDirection => {
  return ['desc', ''].includes(orderBy.direction) ? 'asc' : 'desc';
};
