import { BuildType } from '@models/BuildType.enum';
import Company, { LicensesGranted, LicensesSettings } from '@models/Company';
import { LicenseType } from '@models/LicenseType.enum';
import { StorageAccountState } from '@models/StorageAccount';
import { StorageType } from '@models/StorageType.enum';

export enum UserType {
  Default = 0,
  Gapps = 1,
  Master = 2
}

export type UserDestination = {
  bucketId: string;
  bucketName: string;
  displayDestinationName: string;
  displayStorageName: string;
  id: string;
  region: string;
  regionDisplayName: string;
  storageAccountState: StorageAccountState;
  storageAccountType: StorageType;
  storageLimitId: number;
  storageLimitName: string;
};

export type UserCompany = Partial<Company & { licensingMode: LicensesSettings }>;

export type User = {
  company: UserCompany;
  createDate: string;
  displayName: string;
  email: string;
  enabled: boolean;
  firstName: string;
  id: string;
  integrated: string;
  lastName: string;
  licensingMode: LicensesSettings;
  licensesGranted: LicensesGranted[];
  notificationEmails: string[];
  ownerId: string;
  userType: UserType;
  windowsIdentityName: string;
  userDestinations: UserDestination[];
  inheritedDestinations: UserDestination[];
  licenses: number;
  storageUsed: number;
  storageTotal: number;
  isDataDeleting: boolean;
  isDeleting: boolean;
};

export type CommonUserDto = {
  name?: string;
  password?: string;
  enabled?: boolean;
  firstName?: string;
  lastName?: string;
  notificationEmails?: string[];
};

export type CreateUserDto = CommonUserDto & {
  companyId?: string;
  signUpNotificationBuildList?: BuildType[];
  sendEmailWithInstructions?: boolean;
};

export type UpdateUserDto = CommonUserDto & {
  userDestinations?: {
    id: UserDestination['id'];
    bucketId: UserDestination['bucketId'];
    storageLimitId: UserDestination['storageLimitId'];
  }[];
  licensingMode?: LicensesSettings;
  licensesGranted?: {
    licenseType: LicenseType;
    granted: number;
  }[];
};

export enum DeleteUserMode {
  OnlyUser,
  UserAndBackupData,
  DataOnly
}

export type ChangeCompanyInfo = {
  userCommercialLicenseCount: number;
  userAssignedToCompLicenseCount: number;
  userNotAssignedToCompLicenses: number;
};

export type UserComputerData = {
  destinationId: string;
  computerName: string;
  bucketDisplayName: string;
  isAzureWithoutAdCredentials: boolean;
  storageType: StorageType;
};
