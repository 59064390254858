import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { getAdministrativeAccountConfig } from '@modules/wizards/onboarding/onboarding.helpers';
import { StepsService } from '@modules/wizards/onboarding/services/steps/steps.service';
import { AccountType } from '@modules/wizards/onboarding/steps/apps/sign-in/sign-in.types';
import { BaseStep } from '@modules/wizards/onboarding/steps/shared/base/base.step';
import { OnboardingMode } from '@modules/wizards/services/onboarding/onboarding.constants';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountId } from '@services/apps/apps.constants';
import { AppsService } from '@services/apps/apps.service';
import { I18NextPipe } from 'angular-i18next';

@UntilDestroy()
@Component({
  selector: 'mbs-apps-summary-step',
  templateUrl: './summary.component.html'
})
export class AppsSummaryComponent<StepId> extends BaseStep<StepId> implements OnInit {
  @Input() mode: OnboardingMode;
  @Input() form: UntypedFormGroup;
  @Input() email: string;
  @Input() isReadyToBackup: boolean;

  public readonly accountConfig = getAdministrativeAccountConfig(this.i18N);
  public readonly poweredByByTypeMap = {
    [OnboardingMode.Wasabi]: this.i18N.transform('onboarding:storage.wasabi.poweredBy'),
    [OnboardingMode.AWS]: this.i18N.transform('onboarding:storage.amazon.poweredBy')
  };

  public stepId = 'summary';
  public isValid = true;
  public emailControl: FormControl;
  public account: AccountType;
  public isLoading = false;

  constructor(
    public stepService: StepsService<StepId>,
    private i18N: I18NextPipe,
    private appsService: AppsService,
    private onboardingService: OnboardingService
  ) {
    super(stepService);
  }

  ngOnInit() {
    this.emailControl = this.form.get('email') as FormControl;
    this.account = this.accountConfig.find((acc) => acc.id === this.form.get('accountId')?.value);

    if (this.account.id === AccountId.MicrosoftPersonal) {
      this.isLoading = true;

      this.appsService
        .getUsers(this.form.get('tokenData')?.value)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: ({ value }) => {
            this.form.get('users').setValue({
              all: value,
              selected: value,
              loading: false
            });
            this.isLoading = false;
          },
          error: () => this.onboardingService.hasError$.next(true)
        });
    }
  }

  public getAccountDescription(): string {
    return `${this.form.value?.userName} (${this.account.text})`;
  }
}
