import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { MbsSize, ModalService } from 'mbs-ui-kit';
import { Observable } from 'rxjs';
import { EventEntryModalComponent } from '../event-entry-modal/event-entry-modal.component';
import { hideDetailEntry } from '../store/event-entry.actions';
import { selectCurrentState } from '../store/event-total.selectors';
import { EventEntry } from '../store/model';

@UntilDestroy()
@Component({
  selector: 'mbs-event-entry',
  templateUrl: './event-entry.component.html'
})
export class EventEntryComponent implements OnInit {
  public data$: Observable<EventEntry>;
  constructor(private modalService: ModalService, private store: Store) {}

  ngOnInit(): void {
    const open$ = this.store.select(selectCurrentState);
    open$.pipe(untilDestroyed(this)).subscribe(state => {
      state && this.openModal();
    });
  }

  openModal = (): void => {
    this.modalService.openRef(EventEntryModalComponent, { size: MbsSize.sm }).result.finally(() => this.store.dispatch(hideDetailEntry()));
  };
}
