import { MyTreeElements } from '@models/backup/storages-type';
import Computer from '@models/Computer';
import { StorageConnection } from '@models/storge-connections';
import { isWindows } from '@utils/is-windows';

export type ParamsForRestorePointObjectPath = {
  computer: Computer;
  storage: StorageConnection;
  item: MyTreeElements;
  storageHash: {
    [key: string]: { prefix?: string; generationId?: string; isLoading?: boolean; isIbb?: boolean; data?: MyTreeElements[] };
  };
};

export function getRestorePointObjectPath(settings: ParamsForRestorePointObjectPath, usePath = false): string {
  if (usePath || (!settings.computer.online && settings.item?.restorePoint)) {
    return settings.item.restorePointObjectPath || settings.item?.path;
  }

  if (settings.storage) {
    return `${settings.storage.ID}${isWindows() ? '\\' : '/'}${
      settings.storageHash[settings.storage ? settings.storage.ID : settings.item.storageId]?.prefix || settings.computer.name
    }`;
  }

  return settings.item.restorePointObjectPath || (settings.item?.parent as any)?.path;
}
