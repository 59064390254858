<div *ngIf="computer?.online"
     class="form-row justify-content-end mb-3">
  <div class="col-auto">
    <mbs-button (click)="handleRefresh()"
                icon="ico ico-Refresh">
    </mbs-button>
  </div>
</div>

<div *ngIf="computer"
     class="text-sm row">
  <div class="col-12"
       [ngClass]="{'col-lg-10 col-xxl-8': fullscreen}">
    <ng-container *ngFor="let row of getComputerGeneralInfo(computer, i18nPipe); let i = index">
      <ng-container *ngIf="row.show">
        <div class="row py-1">
          <ng-container *ngIf="i === 0 && computer?.online; else common">
            <div class="text-muted col-4"
                 [ngClass]="{'col-md-3 col-xl-2': fullscreen}">
              {{row.label}}:
            </div>

            <div class="_hover-parent col">
              <mbs-button [isCtrl]="true"
                          [size]="mbsSize.xs"
                          type="primary"
                          (click)="editUser()">{{ row.value }}</mbs-button>

              <div class="_hover-target d-inline ml-2">
                <mbs-button [isCtrl]="true"
                            [size]="mbsSize.xs"
                            type="primary"
                            icon="fa fa-pencil text-xs"
                            (click)="editUser()"></mbs-button>
              </div>
            </div>
          </ng-container>

          <ng-template #common>
            <div class="text-muted col-4"
                 [ngClass]="{'col-md-3 col-xl-2': fullscreen}">
              {{row.label}}:
            </div>
            <div class="col">
              {{ row.value }}
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="hasAgent$ | async; else installAgent">
  <ng-container *ngIf="(loaded$ | async); else notLoadedHostInfo">
    <ng-container *ngIf="hostInfo$ | async as hostInfo">
      <ng-container *ngIf="hostInfo.length > 0">
        <div class="text-sm row">
          <div class="col-12"
               [ngClass]="{'col-lg-10 col-xxl-8': fullscreen}">
            <div class="row py-1"
                 *ngFor="let row of hostInfo">
              <div class="text-muted col-4"
                   [ngClass]="{'col-md-3 col-xl-2': fullscreen}">
                {{row.key}}:
              </div>
              <div class="col">
                {{ row.value || 'N/A'}}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!hostInfo.length && computer?.online">
        <ng-template #updateVersion>
          <div class="text-center text-md mb-3">
            {{ 'computers.module:backupSidePanel.pleaseUpdateBackupToAccess' | i18next }}
            <span class="font-weight-semibold">{{ 'computers.module:backupSidePanel.general' | i18next }}</span>
            {{ 'computers.module:backupSidePanel.info' | i18next }}
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #notLoadedHostInfo>
    <ng-container *ngIf="computer?.online">
      <div class="text-center text-gray-500 mt-1 mb-3">
        {{ 'computers.module:backupSidePanel.somethingWentWrong' | i18next }}
      </div>
      <div class="text-center">
        <mbs-button (click)="handleRefresh()"
                    type="secondary">{{ 'computers.module:backupSidePanel.reloadGeneralInfo' | i18next }}</mbs-button>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #installAgent>
  <div class="text-center">
    <img src="assets/load-image.svg"
         alt="No Backup Agent" />
  </div>
  <div class="text-center text-gray-500 mt-1 mb-3">
    {{ 'computers.module:backupSidePanel.noBackupAgent' | i18next }}
  </div>
  <div class="text-center">
    <mbs-button (click)="handleInstall()">
      {{ 'computers.module:backupSidePanel.installBackupAgent' | i18next }}
    </mbs-button>
  </div>
</ng-template>
