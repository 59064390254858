<section>
  <header class="mb-3 text-center">
    <h4 class="mb-0">
      {{ 'wizards:tryRmm:computer_select_step_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <div class="row justify-content-center">

    <div class="col-10">
      <p class="text-center mb-4">
        {{ 'wizards:tryRmm:computer_select_step_description' | i18next }}
      </p>
    </div>

    <div class="col-7">
      <mbs-form-group [formGroup]="modeSelectionForm">

        <mbs-radio [label]="'wizards:tryRmm:install_manual' | i18next"
                   [value]="'manual'"
                   formControlName="mode">
        </mbs-radio>

        <mbs-radio [label]="'wizards:tryRmm:install_on_selected_computers' | i18next"
                   [value]="'computerSelection'"
                   [descriptionOutside]="false"
                   formControlName="mode">
          <ng-template radioDescription>
            <mbs-iteration-ctrl-button (buttonClick)="openSelectComputersModal()"
                                       [disabled]="false"
                                       [editIconVisible]='true'
                                       [selected]="selectedComputerList"
                                       [emptyLabel]="'wizards:tryRmm:add_computers' | i18next">
            </mbs-iteration-ctrl-button>
          </ng-template>
        </mbs-radio>
        <mbs-radio [label]="'wizards:tryRmm:install_on_selected_companies' | i18next"
                   [value]="'companySelection'"
                   [descriptionOutside]="false"
                   formControlName="mode">
          <ng-template radioDescription>
            <mbs-iteration-ctrl-button (buttonClick)="openSelectCompaniesModal()"
                                       [disabled]="false"
                                       [editIconVisible]='true'
                                       [selected]="selectedCompanyList"
                                       [emptyLabel]="'wizards:tryRmm:add_companies' | i18next">
            </mbs-iteration-ctrl-button>
          </ng-template>
        </mbs-radio>
      </mbs-form-group>
    </div>
  </div>
</section>
