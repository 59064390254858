import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as EventEntryFilterActions from './event-entry-filter.actions';
import { EventEntryFilter } from './model';

export const eventEntryFiltersFeatureKey = 'eventEntryFilters';

export type State = EntityState<EventEntryFilter>;

export const adapter: EntityAdapter<EventEntryFilter> = createEntityAdapter<EventEntryFilter>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialState,
  on(EventEntryFilterActions.addAllEventEntryFilters, (state, action) => adapter.setAll(action.eventEntryFilters, state)),
  on(EventEntryFilterActions.addEventEntryFilter, (state, action) => adapter.addOne(action.eventEntryFilter, state)),
  on(EventEntryFilterActions.upsertEventEntryFilter, (state, action) => adapter.upsertOne(action.eventEntryFilter, state)),
  on(EventEntryFilterActions.addEventEntryFilters, (state, action) => adapter.addMany(action.eventEntryFilters, state)),
  on(EventEntryFilterActions.upsertEventEntryFilters, (state, action) => adapter.upsertMany(action.eventEntryFilters, state)),
  on(EventEntryFilterActions.updateEventEntryFilters, (state, action) => adapter.updateMany(action.eventEntryFilters, state)),
  on(EventEntryFilterActions.deleteEventEntryFilter, (state, action) => adapter.removeOne(action.id, state)),
  on(EventEntryFilterActions.deleteEventEntryFilters, (state, action) => adapter.removeMany(action.ids, state)),
  on(EventEntryFilterActions.clearEventEntryFilters, state => adapter.removeAll(state))
);

export function eventEntryFilterReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
