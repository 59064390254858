<h4>{{'onboarding:storage_account' | i18next: { format: 'title' } }}</h4>

<ng-container *ngIf="mode === backupMode.B2; else staticMode">
  <div class="font-weight-semibold">{{ 'onboarding:b2Title' | i18next }}</div>

  <div class="mt-3">
    <mbs-alert *ngIf="errorMessage"
               [type]="mbsPopupType.danger"
               [closable]="true"
               [icon]="true"
               (close)="removeError()">{{ errorMessage }}</mbs-alert>
  </div>

  <ng-container *ngIf="b2CredentialsForm">
    <form [formGroup]="b2CredentialsForm" class="mt-4">
      <div class="mbs-form-group">
        <mbs-input formControlName="storageUnitName"
                   [label]="'onboarding:b2.bucketName' | i18next"
                   name="storageUnitName"
                   [readonly]="true"></mbs-input>
      </div>

      <div class="mbs-form-group">
        <mbs-input formControlName="publicKey"
                   [label]="'onboarding:b2.publicKey' | i18next"
                   name="publicKey"
                   [validationWhitespace]="true"></mbs-input>
      </div>

      <div class="mbs-form-group">
        <mbs-input formControlName="secretKey"
                   [label]="'onboarding:b2.secretKey' | i18next"
                   name="secretKey"
                   [validationWhitespace]="true"></mbs-input>
      </div>
    </form>
  </ng-container>
</ng-container>

<ng-template #staticMode>
  <div class="font-weight-semibold">{{getText( 'getting_free_storage' )}}</div>

  <ul class="mbs-storage-account_options-list">
    <li>
      <i class="fa fa-check-circle mbs-storage-account_check-icon"></i>
      <span>{{getText( 'free_trial' )}}</span>
    </li>

    <li class="mt-2">
      <i class="fa fa-check-circle mbs-storage-account_check-icon"></i>
      <span>{{getText( 'payment_after_trial' )}}</span>

      <ng-container *ngIf="mode === backupMode.Wasabi && ability.can('read', 'HelpMarketing')">
        <a href="/AP/Help/billing-storage/storage-providers/msp360-storage"
           target="_blank"> {{ 'buttons:learnMore' | i18next }}</a>
      </ng-container>
    </li>
  </ul>

  <div> {{'onboarding:addYourAccount.' + (viewMode === onboardingView.Computers ? 'computersMode' : 'appsMode') |
      i18next}} </div>
  <div> {{'onboarding:following_options' | i18next}} </div>

  <div class="d-flex mt-4 justify-content-between">
    <img *ngFor="let icon of icons"
         [alt]="icon.replace('2', '')"
         [src]="'assets/images/storages/' + icon + '.svg'"/>
  </div>
</ng-template>
