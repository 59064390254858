<ng-template #alertTemplate>
  <mbs-button *ngIf="isRedirectEnabled"
              [isCtrl]="true"
              (click)="redirect()"
              [size]="MbsSize.md"
              type="primary">
    {{ totalUnsupportedComputers }}
    {{ 'error:unsupportedVersion:computer' + (isMultipleUnsupportedComputers ? 's' : '') | i18next }}
  </mbs-button>
  <span *ngIf="!isRedirectEnabled">
    {{ totalUnsupportedComputers }}
    {{ 'error:unsupportedVersion:computer' + (isMultipleUnsupportedComputers ? 's' : '') | i18next }}
  </span>
  {{ 'error:unsupportedVersion:' + (isMultipleUnsupportedComputers ? 'areRunning' : 'isRunning') | i18next }}
  <mbs-button *ngIf="isRedirectEnabled"
              [isCtrl]="true"
              (click)="redirect()"
              [size]="MbsSize.md"
              type="primary">
    {{ 'error:unsupportedVersion:update' | i18next }}
  </mbs-button>
  <span *ngIf="!isRedirectEnabled">
    {{ 'error:unsupportedVersion:update' | i18next }}
  </span>
  {{ 'error:unsupportedVersion:asap' | i18next }}
</ng-template>
