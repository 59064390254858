<div class="mbs-form-group mb-0">
  <mbs-advanced-search (input)="handleInputSearch($event)"
                       (search)="updateFilters($event)"
                       [(ngModel)]="model"
                       [accessUrlQuery]="false">
  </mbs-advanced-search>
</div>

<mbs-table-nav (pageChange)="handlePageChange($event)"
               (pageSizeChange)="handlePageSizeChange($event)"
               (refresh)="fetchData()"
               [showRefreshButton]="true"
               [showExportToCSVButton]="isModal"
               (export)="export()"
               [exportButtonName]="'buttons:exportToCSV' | i18next"
               [switcherView]="false">

  <ng-template mbsTableFilter="label">
    {{ 'rmm-side-panel:hyperVTab.hyperVCount' | i18next }} {{ paginationOptions.dataSize }}
  </ng-template>

  <ng-container *ngIf="isOnline && readonly === false">
    <ng-template mbsTableFilter="divider"></ng-template>
    <ng-template mbsTableFilter="label">

      <mbs-button class="mr-2"
                  (click)="handleHyperVInstance(hyperVOperation.START)"
                  [disabled]="disabledStart"
                  icon="ico ico-play-circle"
                  [ngbTooltip]="!isModal ? ('buttons:start' | i18next) : ''"
                  size="sm"
                  tooltipClass="-no-arrow">
        {{ isModal ? ('buttons:start' | i18next) : ''}}
      </mbs-button>

      <mbs-button class="mr-2"
                  (click)="handleHyperVInstance(hyperVOperation.DISABLE)"
                  [disabled]="disabledStop"
                  icon="ico ico-stop-circle"
                  [ngbTooltip]="!isModal ? ('buttons:turnOff' | i18next) : ''"
                  size="sm"
                  tooltipClass="-no-arrow">
        {{ isModal ? ('buttons:turnOff' | i18next) : ''}}
      </mbs-button>

      <mbs-button class="mr-2"
                  (click)="handleHyperVInstance(hyperVOperation.REBOOT)"
                  [disabled]="disabledRestart"
                  icon="ico ico-Restart"
                  [ngbTooltip]="!isModal ? ('buttons:reset' | i18next) : ''"
                  size="sm"
                  tooltipClass="-no-arrow">
        {{ isModal ? ('buttons:reset' | i18next) : ''}}
      </mbs-button>
    </ng-template>


  </ng-container>

  <ng-template *ngIf="!isModal"
               mbsTableControl="append">
    <mbs-modal-tab-content modalTitle="{{ 'rmm-side-panel:hyperVTab.hyperVModalTitle' | i18next }}"
                           [showExpandedCross]="true">
      <ng-template>
        <mbs-hyperv-tab [hid]="hid"
                        [isModal]="true"
                        [readonly]="readonly"
                        [computerName]="computerName"
                        [isOnline]="isOnline"></mbs-hyperv-tab>
      </ng-template>
    </mbs-modal-tab-content>
  </ng-template>

</mbs-table-nav>

<mbs-table-grid (changeSelected)="itemsChecked($event)"
                [multipleSelect]="false"
                [selectable]="true"
                (sort)="handleSort($event)"
                [changeSortState]="orderBy"
                [data]="data"
                [disableViewSwitch]="true"
                [headers]="headers"
                [loading]="loading$ | async"
                [myTrackBy]="trackBy"
                [paginationOptions]="paginationOptions"
                [rotateSequence]="rotateSequence"
                [selectedItems]="selectedHyperV"
                [showNav]="false"
                [showTableCheckboxes]="isOnline"
                [switcherView]="false"
                [viewMode]="sharedPersistentStateEnum.table"
                bindSelected="name"
                maxHeight="{{ isModal || !isOnline ? '60vh' : '68vh' }}"
                [noDataMessage]="'rmm-side-panel:noDataProvided' | i18next">


  <ng-template mbsTableFilter="label">
    {{ 'rmm-side-panel:hyperVTab.virtualMachinesCount' | i18next }} ({{ paginationOptions.dataSize }})
  </ng-template>


  <ng-template *ngFor="let header of headers"
               let-item
               mbsTableCell>

    <ng-container [ngSwitch]="header.sort">
      <ng-container *ngSwitchCase="'memoryAvailable'">
        <mbs-text-ellipsis [tooltip]="item[header.sort]"
                           container="body"
                           placement="auto"
                           tooltipClass="tooltip-lg text-left">
          {{ item[header.sort] | size }}
        </mbs-text-ellipsis>
      </ng-container>


      <ng-container *ngSwitchCase="'creationTime'">
        <mbs-rmm-date-time-format [container]="'body'"
                                  [isUtc]="true"
                                  [time]="item[header.sort]"
                                  [showIcon]="true"></mbs-rmm-date-time-format>
      </ng-container>


      <ng-container *ngSwitchDefault>
        <mbs-text-ellipsis [tooltip]="item[header.sort]"
                           container="body"
                           placement="auto"
                           tooltipClass="tooltip-lg text-left">
          {{ item[header.sort] }}
        </mbs-text-ellipsis>
      </ng-container>
    </ng-container>
  </ng-template>

</mbs-table-grid>
