import { EntityState } from '@ngrx/entity';
import { AdvancedSearchModel, SortEvent } from 'mbs-ui-kit';

export enum ScriptLibraryEntryExtends {
  CATEGORIES = 'categories'
}

export enum ScriptLibraryEntryTypes {
  Batch = 0,
  PowerShell = 1,
  Bash = 2
}
export enum ScriptAccessLevel {
  Private = 0,
  Public = 1,
  Common = 2
}

export enum ScriptLibraryAccessLevel {
  Private = 'Private',
  Public = 'Public',
  Common = 'Common'
}

export interface ScriptLibraryTag {
  tagGuid: string;
  name: string;
  accessLevel: ScriptLibraryAccessLevel;
}
export interface ScriptLibraryEntry {
  id: string;
  scriptGuid: string;
  type: ScriptLibraryEntryTypes | 'PowerShell' | 'Bash';
  name: string;
  body: string;
  enableOffline: boolean;
  confidentialOutputData: boolean;
  tags: ScriptLibraryTag[];
  description: string;
  subadminGuid: string;
  owner: string;
  scheduled: string[];
  accessLevel: ScriptLibraryAccessLevel;
  [key: string]: any; // for extends
}
export interface ScriptLibraryTransfer {
  accessLevel: ScriptLibraryAccessLevel;
  type: number;
  name: string;
  description: string;
  body: string;
  enableOffline: boolean;
  confidentialOutputData: boolean;
  tags: string[];
}
export type State = EntityState<ScriptLibraryEntry> & {
  edit: string;
  loading: boolean;
  sort: SortEvent;
  filters: AdvancedSearchModel;
  tags: ScriptLibraryTag[];
};
