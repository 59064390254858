import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { I18NextService } from 'angular-i18next';
import { MbsSize } from 'mbs-ui-kit';

@Component({
  selector: 'mbs-feedback',
  templateUrl: './feedback.component.html',
  host: {
    class: 'mbs-feedback'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackComponent {
  @Output() closeFeedback = new EventEmitter();
  public readonly MbsSize = MbsSize;

  constructor(public i18nextService: I18NextService, private appPersistent: AppPersistentStateService) {}

  handleClose(): void {
    this.closeFeedback.emit();
  }

  setRemitLateDate(): void {
    this.appPersistent.data.feedbackRemindLateDate = new Date();
    this.closeFeedback.emit();
  }
}
