import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ComputersFacade } from '@facades/computers.facade';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import Administrator from '@models/Administrator';
import AdministratorInCamelCase from '@models/AdministratorInCamelCase';
import { BuildType } from '@models/BuildType.enum';
import Computer from '@models/Computer';
import { LicenseType } from '@models/LicenseType.enum';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdministratorService } from '@services/administrator.service';
import { AuthService } from '@services/auth.service';
import { BuildService } from '@services/build-service/build.service';
import { I18NextPipe } from 'angular-i18next';
import { WizardComponent, WizardStep } from 'mbs-ui-kit';
import { first, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

export enum WizardType {
  TryRmm = 'TryRmm',
  Onboarding = 'Onboarding'
}

const WIZARD_RMM_OUTER_HEIGHT = 570;
const WIZARD_HEADER_HEIGHT = 55;
const WIZARD_FOOTER_HEIGHT = 59;

@UntilDestroy()
@Component({
  selector: 'mbs-try-rmm-for-providers-wizard',
  templateUrl: './try-rmm-for-providers.component.html',
  styleUrls: ['./try-rmm-for-providers.component.scss']
})
export class TryRMMForProvidersWizardComponent implements OnInit {
  public readonly titleSteps = {
    startInfo: 'Start Info Action',
    addUsersSelection: 'Add Users Mode Action',
    userSelection: 'User Selection', // to be added if needs (requirement dependency)
    installModeSelection: 'Computer Installation Mode Action',
    selectComputers: 'Computer Selection', // to be added if needs (requirement dependency)
    installation: 'Manual/Automatic Installation Action',
    finalInfo: 'Final Action'
  };
  public readonly wizardTypeEnum = WizardType;
  public currentStep: WizardStep;
  @ViewChild(WizardComponent, { static: true }) wizard: WizardComponent;
  public wizardContentHeight: string;
  public isLoading = true;
  public nextButtonEnabled = true;
  public showContinueButton = true;
  public showSaveButton = false;
  public saveButtonText: string;
  public showFooter = true;
  public selectedAdminList: (AdministratorInCamelCase | Administrator)[] = [];
  public providerAdmin: AdministratorInCamelCase | Administrator;
  public selectedComputerList: Computer[] = [];
  public autoInstall = false;
  public currentStepNumber = 1;
  public wizardType: WizardType = WizardType.TryRmm;

  private totalStepCount: 2 | 4 = 4;
  private computerWithInstalledRMM: Computer;
  private readonly finishPath = RoutingPath.ApComputers;

  buildGenerationLaunched = false;

  constructor(
    private router: Router,
    private i18nPipe: I18NextPipe,
    private adminService: AdministratorService,
    private buildService: BuildService,
    private computersFacade: ComputersFacade,
    private auth: AuthService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    this.saveButtonText =
      this.wizardType === WizardType.TryRmm
        ? `Proceed to ${this.i18nPipe.transform('app:products.rmm')}`
        : this.i18nPipe.transform('onboarding:buttons.finish');
    this.setHeight();
    this.setTotalStepCount();
    this.wizard.title = this.getWizardTitle();
    this.isLoading = false;

    if (this.wizardType === WizardType.Onboarding) {
      this.auth.currentUser.pipe(first(Boolean), untilDestroyed(this)).subscribe((admin) => {
        this.setProvider(admin);
      });
      this.onboardingService
        .initialize()
        .pipe(
          untilDestroyed(this),
          switchMap(() => this.onboardingService.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone))
        )
        .subscribe();
    }
  }

  setTotalStepCount(): void {
    switch (this.wizardType) {
      case WizardType.Onboarding:
        this.totalStepCount = 2;
        break;
      case WizardType.TryRmm:
      default:
        this.totalStepCount = 4;
        break;
    }
  }

  setHeight(withoutFooter = false): void {
    this.wizardContentHeight = withoutFooter
      ? WIZARD_RMM_OUTER_HEIGHT - WIZARD_HEADER_HEIGHT + 'px'
      : WIZARD_RMM_OUTER_HEIGHT - WIZARD_HEADER_HEIGHT - WIZARD_FOOTER_HEIGHT + 'px';
  }

  public setSelectedAdminList(selectedAdminList: AdministratorInCamelCase[]): void {
    this.selectedAdminList = [...selectedAdminList];
    if (this.currentStepNumber === 1) this.nextButtonEnabled = true;
  }

  public setProvider(provider: AdministratorInCamelCase | Administrator): void {
    this.providerAdmin = provider;
  }

  public setSelectedComputerList(selectedComputerList: AdministratorInCamelCase[]): void {
    this.selectedComputerList = [...selectedComputerList];
  }

  public setNextButtonEnabled(enabled: boolean): void {
    this.nextButtonEnabled = enabled;
    this.wizard.checkChanges;
  }

  public setComputerWithInstalledRMM(computer: Computer): void {
    this.computerWithInstalledRMM = computer;
  }

  public forceNextStep(): void {
    if (this.currentStep.title === this.titleSteps.finalInfo) return;

    this.wizard.changeStep(1);
    this.wizard.checkChanges;
  }

  public handleExecute(): void {
    const redirectUrl = `${RoutingPath.ApComputers}/${this.computerWithInstalledRMM?.hid}`;

    if (this.wizardType === this.wizardTypeEnum.TryRmm) {
      this.computerWithInstalledRMM?.hid && this.computersFacade.loadComputerByHid({ hid: this.computerWithInstalledRMM.hid });
      this.router.navigate([redirectUrl], { queryParams: { sidepanel: 'rmm' } });
    } else {
      const query = this.computerWithInstalledRMM?.hid
        ? this.onboardingService.configureComputerByHid(this.computerWithInstalledRMM.hid)
        : this.onboardingService.configureComputers();

      query.pipe(switchMap(() => this.onboardingService.complete())).subscribe({
        next: () => (location.href = `${redirectUrl}?sidepanel=rmm`)
      });
    }
  }

  public handleStepChange(currentStep: WizardStep): void {
    this.currentStep = currentStep;
    this.currentStepNumber++;
    if (this.selectedAdminList.length === 1 && this.currentStepNumber === 2) this.currentStepNumber++;
    this.wizard.title = this.getWizardTitle();
    this.setHeight();

    switch (currentStep.title) {
      case this.titleSteps.startInfo:
      case this.titleSteps.addUsersSelection:
      case this.titleSteps.userSelection:
      case this.titleSteps.selectComputers:
      case this.titleSteps.installModeSelection:
        this.showFooter = true;
        this.showContinueButton = true;
        this.showSaveButton = false;
        break;
      case this.titleSteps.installation:
        this.enableTrialLicence();
        this.enableBuilds();
        this.selectedComputerList.length > 0 && (this.autoInstall = true);
        this.showFooter = this.wizardType === this.wizardTypeEnum.Onboarding;
        this.setHeight(this.wizardType !== this.wizardTypeEnum.Onboarding);
        this.showContinueButton = false;
        break;
      case this.titleSteps.finalInfo:
        this.showFooter = true;
        this.showContinueButton = false;
        this.showSaveButton = true;
        break;
      default:
        break;
    }
  }

  private getWizardTitle(): string {
    return this.currentStepNumber <= this.totalStepCount
      ? this.i18nPipe.transform('wizards:tryRmm:wizard_title', {
          stepNumber: this.currentStepNumber,
          totalStepsCount: this.totalStepCount
        })
      : this.i18nPipe.transform('wizards:tryRmm:wizard_title_final');
  }

  private enableTrialLicence(): void {
    if (!this.selectedAdminList.some((admin) => (admin as AdministratorInCamelCase).isProvider || (admin as Administrator).IsProvider))
      this.selectedAdminList.push(this.providerAdmin);
    this.selectedAdminList.forEach((administrator) => {
      if (this.adminService.isTrialLicenseAvailable(LicenseType.RMM)) {
        const id = (administrator as AdministratorInCamelCase).id || (administrator as Administrator).Id;
        this.adminService.grantLicense(LicenseType.RMM, id, true).pipe(untilDestroyed(this)).subscribe();
      }
    });
  }

  private enableBuilds(): void {
    this.buildService
      .requestBuild({
        buildsTypeList: [BuildType.RMMAgent],
        isBuildDestinationSandbox: false
      })
      .pipe(untilDestroyed(this))
      .subscribe();
    this.buildGenerationLaunched = true;
  }

  public skipOnboardingWizard(): Observable<any> {
    return of(null).pipe(
      switchMap(() => this.onboardingService.complete()),
      tap(() => (location.href = this.finishPath))
    );
  }
}
