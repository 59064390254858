import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { ConfigurationService } from '@services/configuration.service';
import { mapAsId } from '@utils/operators/ngrx-data-operators';
import { I18NextPipe } from 'angular-i18next';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScriptLibraryAccessLevel, ScriptLibraryEntry, ScriptLibraryTag, ScriptLibraryTransfer } from './store/script-library.model';

@Injectable()
export class ScriptLibraryService {
  private libraryHref: string;
  private mbsStageKey: string;

  constructor(private http: HttpClient, private config: ConfigurationService, private auth: AuthService, private i18: I18NextPipe) {
    this.libraryHref = this.config.get('rmmScriptLibraryHref');
    this.mbsStageKey = this.config.get('mbsStageKey');
  }

  /**
   * @region SCRIPTS REST API
   */

  loadScriptBody(scriptGuid: ScriptLibraryEntry['scriptGuid']): Observable<string> {
    const params = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    return this.http.get(this.libraryHref + `/api/Scripts/${scriptGuid}/body`, { params }).pipe(
      map((result: any) => {
        return result?.body as string;
      })
    );
  }

  loadScripts(): Observable<ScriptLibraryEntry[]> {
    const params = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    return this.http.get(this.libraryHref + '/api/Scripts', { params }).pipe(mapAsId('scriptGuid'), map(this.mapOwner));
  }

  loadScript(scriptGuid): Observable<any> {
    const params = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    return this.http.get(this.libraryHref + '/api/Script/' + scriptGuid, { params });
  }

  registerScript(transfer: ScriptLibraryTransfer, params: HttpParams): Observable<any> {
    params = params.append('mbsStageKey', this.mbsStageKey);
    return this.http.post(this.libraryHref + '/api/Scripts/register', transfer, { params });
  }

  updateScript(scriptGuid, transfer: ScriptLibraryTransfer): Observable<any> {
    const params = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    return this.http.put(this.libraryHref + '/api/Scripts/' + scriptGuid, transfer, { params });
  }

  deleteScript(scriptGuid): Observable<any> {
    const params = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    return this.http.delete(this.libraryHref + '/api/Scripts/' + scriptGuid, { params });
  }

  /**
   * @region TAGS REST API
   */

  loadTags(): Observable<ScriptLibraryTag[]> {
    const params = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    return this.http.get(this.libraryHref + '/api/Tags', { params }).pipe(mapAsId('tagGuid'));
  }

  loadTag(tagGuid): Observable<any> {
    const params = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    return this.http.get(this.libraryHref + '/api/Tag/' + tagGuid, { params });
  }

  registerTag(tagName: string, params: HttpParams): Observable<any> {
    params = params.append('mbsStageKey', this.mbsStageKey);
    return this.http.post(this.libraryHref + '/api/Tags/register', { name: tagName }, { params });
  }

  updateTag(tagGuid, transfer: ScriptLibraryTag): Observable<any> {
    const params = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    return this.http.put(this.libraryHref + '/api/Tags/' + tagGuid, transfer, { params });
  }

  deleteTag(tagGuid): Observable<any> {
    const params = new HttpParams({ fromObject: { mbsStageKey: this.mbsStageKey } });
    return this.http.delete(this.libraryHref + '/api/Tags/' + tagGuid, { params });
  }

  private mapOwner = (transfers: ScriptLibraryEntry[]) => {
    return transfers.map((transfer) => {
      switch (transfer.accessLevel) {
        case ScriptLibraryAccessLevel.Common: {
          return { ...transfer, owner: this.i18.transform(`rmm-script-library.module:defaultOwners.${ScriptLibraryAccessLevel.Common}`) };
        }
        case ScriptLibraryAccessLevel.Public: {
          return { ...transfer, owner: this.i18.transform(`rmm-script-library.module:defaultOwners.${ScriptLibraryAccessLevel.Public}`) };
        }
        case ScriptLibraryAccessLevel.Private:
        default:
          return { ...transfer, owner: this.auth.currentUserValue?.ProviderInfo?.EmailNotificationsTo };
      }
    }) as ScriptLibraryEntry[];
  };
}
