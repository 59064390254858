<section>
  <header class="mb-3 text-center">
    <h4 class="mb-0">
      {{ 'wizards:tryRmm:user_mode_step_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <div class="row justify-content-center">

    <div class="col-10">
      <p class="text-center mb-4">
        {{ 'wizards:tryRmm:user_mode_step_description' | i18next }}
      </p>
    </div>

    <div class="col-7">
      <mbs-form-group [formGroup]="modeSelectionForm">

        <mbs-radio [label]="'wizards:tryRmm:give_trial_to_self' | i18next"
                   [value]="'manual'"
                   formControlName="mode">
        </mbs-radio>

        <mbs-radio [label]="'wizards:tryRmm:give_trial_to_selected' | i18next"
                   [value]="'customSelection'"
                   [descriptionOutside]="false"
                   formControlName="mode">
          <ng-template radioDescription>
            <mbs-iteration-ctrl-button (buttonClick)="openSelectAdministratorsModal()"
                                       [disabled]="false"
                                       [editIconVisible]='true'
                                       [selected]="selectedAdminList"
                                       [emptyLabel]="'wizards:tryRmm:add_admins' | i18next">
            </mbs-iteration-ctrl-button>
          </ng-template>
        </mbs-radio>

        <mbs-radio [label]="'wizards:tryRmm:give_trial_to_all' | i18next"
                   [value]="'allSelection'"
                   formControlName="mode">
        </mbs-radio>
      </mbs-form-group>
    </div>
  </div>
</section>
