<table *ngIf="script"
       class="table table-simple table-sm">
  <tr *ngFor="let item of order; index as index; last as last">
    <td class="w-25 pr-1 align-top">
      {{ 'rmm-script-library.module:sidePanel.generalInfoTitles.' + item | i18next }}
    </td>
    <td *ngIf="item !== 'owner'"
        class="pr-0">
      {{ script[item] }}
    </td>
    <td *ngIf="item === 'owner'"
        class="pr-0">
      {{ script?.accessLevel === 'Common' ? 'MSP360' : script.subadminName ? script?.subadminName : script?.owner }}
    </td>
  </tr>
</table>

<div *ngFor="let groupTask of (groupTasks$ | async) as groupTasks; index as index">

  <mbs-card [size]="'sm'">
    <mbs-card-header [title]="groupTask.name">
    </mbs-card-header>

    <div class="text-sm">
      <span *ngFor="let computer of groupTask.computers">
        {{ computer.computerName }}
      </span>
    </div>

    <div class="text-sm text-muted">
      {{ groupTask.subadminName || 'Service Provider' }}
    </div>

  </mbs-card>
</div>
