<mbs-ios-top-widget [activeRouteComponent]="activateRouteComponent"></mbs-ios-top-widget>
<mbs-header (openFeedback)="handleOpenHelpFeedback()"></mbs-header>

<main class="mbs-container">
  <div class="mbs-container-inner">
    <div class="mbs-container-content">
      <mbs-loader *ngIf="isLoading$ | async"></mbs-loader>
      <ng-container *ngIf="(isLoading$ | async) === false">

        <router-outlet (activate)="handleActiveRoute($event)"
                       (deactivate)="handleActiveRoute()"></router-outlet>
      </ng-container>
    </div>
    <mbs-unsupported-version *ngIf="('MBS' | can:'read') && ('RemoteManagement' | can:'read')"></mbs-unsupported-version>
    <mbs-ub-notifications *ngIf="('MBS' | can:'read')"></mbs-ub-notifications>
    <mbs-email-service-status-component *ngIf="('Notification' | can: 'read')"></mbs-email-service-status-component>
    <mbs-toasts data-position="bottom-right"></mbs-toasts>

    <mbs-feedback *ngIf="('HelpMarketing' | can:'read') && !('HideFeedback' | can:'read')"
                  [hidden]="(showFeedback$.asObservable() | async) === false"
                  (closeFeedback)="handleCloseFeedbackModal()"></mbs-feedback>

    <mbs-footer *ngIf="showFooter"></mbs-footer>
  </div>
</main>

<ng-template #timezoneAlertTemplate>
  The default Provider Time Zone (UTC) is currently used to indicate the time.
  <mbs-button [isCtrl]="true"
              size="inherit"
              type="link"
              (click)="changeTimezoneClickHandler()">Change.</mbs-button>
</ng-template>

<ng-template #disconnectAlertTemplate>
  Connection lost. <mbs-button [isCtrl]="true" (click)="startSignalConnection()" size="inherit">Retry</mbs-button>
</ng-template>
