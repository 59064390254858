import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmmService, StatType } from '@services/rmm.service';

@UntilDestroy()
@Component({
  selector: 'mbs-json-viewer-tab',
  templateUrl: './json-viewer-tab.component.html'
})
export class JsonViewerTabComponent implements OnInit {
  @Input() public statType: StatType;
  @Input() public hid: string;

  jsonValue = '';
  size = 'sm';

  constructor(public rmmService: RmmService) {}

  ngOnInit() {
    this.jsonValue = '';
    this.rmmService
      .fetchLastStat(this.statType)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res && res.data && res.data.length > 0) {
          this.jsonValue = JSON.stringify(res.data.length === 1 ? res.data[0] : res.data, null, 2);
        } else {
          this.jsonValue = '{}';
        }
      });
  }
}
