import { Component, EventEmitter, Output } from '@angular/core';
import { BuildType } from '@models/BuildType.enum';
import { OsType } from '@models/Computer';
import { FinishPath, OnboardingView } from '@modules/wizards/onboarding/onboarding.constants';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InstallationView } from '@modules/wizards/steps/installation/installation.constants';

@UntilDestroy()
@Component({
  selector: 'mbs-onboarding-wizard-hyper-v-view',
  templateUrl: './hyper-v.component.html'
})
export class HyperVViewComponent {
  @Output() finish = new EventEmitter<string>();
  readonly viewMode = OnboardingView.HyperV;
  readonly installationView = InstallationView.HyperV;
  readonly buildsSelectionConfig = [
    {
      buildType: BuildType.VMware,
      os: OsType.windows
    }
  ];

  constructor(public onboardingService: OnboardingService) {}

  public finishWizard(): void {
    this.onboardingService.stable$.next(false);
    this.finish.emit(FinishPath.Computers);
  }
}
