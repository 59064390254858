import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateNativeAdapter,
  NgbDatepicker,
  NgbDatepickerI18n,
  NgbDatepickerNavigateEvent,
  NgbDropdown,
  NgbDropdownMenu
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { DateFormat, MbsSize, host } from '../../utils';
import { InputBase } from '../input-base/input-base';
import { DOMEvent, InputClasses, ValidClasses } from '../input-base/input-base.model';
import { InputButton } from '../input-base/input-pre-append/input-button';
import { Datepicker, DatepickerViewMode } from './datepicker.model';

@UntilDestroy()
/**
 * Datepicker component based on
 * <a href="https://ng-bootstrap.github.io/#/components/datepicker/overview" target="_blank">`NgbDatepicker`</a>
 */
@Component({
  selector: 'app-datepicker,mbs-datepicker',
  templateUrl: 'datepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'mbs-datepicker'
  }
})
export class DatepickerComponent extends InputBase<Datepicker> implements OnInit, AfterViewInit, OnDestroy {
  public readonly DatepickerViewMode = DatepickerViewMode;
  public readonly MbsSize = MbsSize;
  public readonly dateAdapter = new NgbDateNativeAdapter();
  public readonly dateSync$ = new ReplaySubject<Datepicker>();
  public readonly currentDate = new Date();
  public monthList$: BehaviorSubject<NgbDate[]> = new BehaviorSubject<NgbDate[]>([]);

  public toDate: Datepicker;
  public hoveredDate: NgbDate | null = null;

  private selectedDate: NgbDateStruct;
  private navigatedYear: number;

  @Input() public set date(value: Datepicker) {
    this.dateSync$.next(value);
  }

  public get date(): Datepicker {
    return this.myValue;
  }

  public get bindClasses(): string {
    const classesObject: InputClasses = Object.assign<Record<string, boolean>, ValidClasses>({}, this.validClasses);
    return Object.entries(classesObject)
      .filter(([k, v]) => !!v)
      .map(([k]) => k)
      .concat([this.sizeClass])
      .join(' ');
  }

  @Input() public isValidated = false;

  /**
   * Mask format for displaying in input
   * @private
   */
  @Input() private maskFormat = DateFormat.mediumDate;

  /**
   * if `true` datepicker will use `NgbDatepicker` component,<br>
   * else - it will be `input` with `ngbDatepicker` directive
   */
  @Input() public plain: boolean;

  /**
   * if `true` datepicker will use `NgbDatepicker` component in two month range mode
   */
  @Input() public range: boolean;

  /**
   * The number of months to display.
   */
  @Input() public displayMonths = 1;

  /**
   * The reference to a custom template for the day.
   * Allows to completely override the way a day 'cell' in the calendar is displayed.
   */
  @Input() public dayTemplate = '';

  /**
   * The first day of the week. <br>
   * With default calendar we use ISO 8601: 'weekday' is 1=Mon ... 7=Sun.
   */
  @Input() public firstDayOfWeek = 7;

  /**
   * The reference to the custom template for the datepicker footer
   */
  @Input() public footerTemplate: TemplateRef<any>;

  @Input() public hideFooter = false;

  /**
   * The latest date that can be displayed or selected. <br>
   * If not provided, 'year' select box will display 10 years after the current month.
   */
  @Input() public maxDate: Date;

  /**
   * The earliest date that can be displayed or selected.
   * If not provided, 'year' select box will display 10 years before the current month.
   */
  @Input() public minDate: Date;

  /**
   * Navigation type. <br>
   * `"select"` - select boxes for month and navigation arrows <br>
   * `"arrows"` - only navigation arrows <br>
   * `"none"` - no navigation visible at all <br>
   * For the 2+ months view, days in between months are never shown.
   */
  @Input() public navigation: 'select' | 'arrows' | 'none' = 'select';

  /**
   * The way of displaying days that don't belong to the current month.
   * <ul><li> `"visible"` - days are visible</li>
   * <li> `"hidden"` - days are hidden, white space preserved</li>
   * <li> `"collapsed"` - days are collapsed, so the datepicker height might change between months</li></ul>
   * For the 2+ months view, days in between months are never shown.
   */
  @Input() public outsideDays: 'visible' | 'collapsed' | 'hidden' = 'visible';

  /**
   * If `true`, weekdays will be displayed.
   */
  @Input() public showWeekdays = true;

  /**
   * If `true`, week numbers will be displayed.
   */
  @Input() public showWeekNumbers = false;

  /**
   * The date to open calendar with. <br>
   * With the default calendar we use ISO 8601: 'month' is 1=Jan ... 12=Dec.
   * If nothing or invalid date is provided, calendar will open with current month. <br>
   * You could use `navigateTo(date)` method as an alternative.
   */
  @Input() public startDate: { year: number; month: number; day?: number };

  /**
   * only for `[plain]="false"` <br>
   * The preferred placement of the datepicker popup. <br>
   * Possible values are `auto`, `top`, `top-left`, `top-right`, `bottom`, `bottom-left`, `bottom-right`,
   * `left`, `left-top`, `left-bottom`, `right`, `right-top`, `right-bottom`<br>
   * see <a href="https://ng-bootstrap.github.io/#/positioning" target="_blank">ng-bootstrap docs</a> for details
   */
  @Input() public placement: string | string[] = 'auto';

  /**
   * only for `[plain]="false"` <br>
   * Indicates whether the datepicker popup should be closed automatically after date selection / outside click or not. <br>
   * <ul><li>`true` - the popup will close on both date selection and outside click.</li>
   * <li> `false` - the popup can only be closed manually via close() or toggle() methods.</li>
   * <li> `"inside"` - the popup will close on date selection, but not outside clicks.</li>
   * <li> `"outside"` - the popup will close only on the outside click and not on date selection/inside clicks.</li></ul>
   */
  @Input() public autoClose: boolean | 'inside' | 'outside' = 'outside';

  /**
   * only for `[plain]="false"` <br>
   * A css selector or html element specifying the element the datepicker popup should be positioned against.
   * By default the input is used as a target.
   */
  @Input() public positionTarget: string | HTMLElement;

  /**
   * If set `false`, you'll hidden all errors. Tag a `mbs-input-errors` will be hidden
   */
  @Input() public isShowErrors = true;

  /**
   * Show/hide spinner
   */
  @Input() public loading = false;

  /**
   * To mark some dates as disabled.
   It is called for each new date when navigating to a different month.
   current is the month that is currently displayed by the datepicker.
   * @param {NgbDate} date
   * @param {any} current
   * @return {boolean}
   */
  @Input() public markDisabled: (date: NgbDate, current: { year: number; month: number }) => boolean = (date, current) => {
    if (date.month !== current.month) {
      return false;
    }
    if (this.minDate && this.getFormattedDateWithTimeReset(date, { hours: 23, min: 59, sec: 59, ms: 999 }) < this.minDate) {
      return true;
    }
    if (this.maxDate && this.getFormattedDateWithTimeReset(date, { hours: 0, min: 0, sec: 0, ms: 0 }) > this.maxDate) {
      return true;
    }
    return false;
  };

  /**
   * NgbDate has no time, so when converted to a new Date() object, it defaults to set to 12:00:00.
   * It breaks the logic when comparing.
   * Therefore, we need to reset the time to 00:00:00 or 23:59:59:999
   * @private
   * @param {NgbDate} date
   * @param {any} time
   * @return {Date}
   */
  private getFormattedDateWithTimeReset(date: NgbDate, time: { hours: number; min: number; sec: number; ms: number }): Date {
    return new Date(this.dateAdapter.toModel(date).setHours(time.hours, time.min, time.sec, time.ms));
  }

  /**
   * To mark some months as disabled (in 'year' mode).
   It is called for each new month when navigating to a different year.
   current is the month that is currently displayed by the datepicker.
   * @param {any} current
   */
  @Input() markMonthDisabled: (current: { year: number; month: number }) => boolean;

  @Input() viewMode: DatepickerViewMode = DatepickerViewMode.month;

  @Input() needSetCurrent = false;

  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel  = false;

  /**
   * An event emitted when user selects a date using keyboard or mouse. <br>
   * The payload of the event is currently selected `NgbDate`.
   */
  @Output() change = new EventEmitter<Datepicker>();

  /**
   * An event emitted when user selects a date range using keyboard or mouse. <br>
   * The payload of the event is currently selected two `NgbDate`'s.
   */
  @Output() rangeSelect = new EventEmitter<{ from: Datepicker; to: Datepicker }>();

  /**
   * An event emitted right before the navigation happens and displayed month changes. <br>
   * See <a href="https://ng-bootstrap.github.io/#/components/datepicker/api#NgbDatepickerNavigateEvent"
   * target="_blank">NgbDatepickerNavigateEvent</a> for the payload info.
   */
  @Output() navigate = new EventEmitter<NgbDatepickerNavigateEvent>();

  /**
   * An emit event by click close button dropdown or outside dropdown container
   */
  @Output() closed = new EventEmitter();

  /**
   * An emit event by click on custom prepend button
   */
  @Output() buttonClickPrepend = new EventEmitter<InputButton>();

  /**
   * An emit event by click on custom append button
   */
  @Output() buttonClickAppend = new EventEmitter<InputButton>();
  /**
   * The callback to mark some dates as disabled. <br>
   * It is called for each new date when navigating to a different month. <br>
   * `current` is the month that is currently displayed by the datepicker.
   */
  /**
   * @ignore
   */
  @Output() buttonClick = new EventEmitter();

  @ViewChild('dropdown', { static: false, read: NgbDropdown }) dpDropdown: NgbDropdown;
  @ViewChild('dropdownMenu', { static: false, read: NgbDropdownMenu }) dropdownMenu: NgbDropdownMenu;
  @ViewChild('datepicker', { static: false, read: NgbDatepicker }) datepicker: NgbDatepicker;
  @ViewChild('datepicker', { static: false, read: ElementRef }) datepickerRef: ElementRef<HTMLInputElement>;

  get dropdownMenuDrop(): NgbDropdown {
    return this.dropdownMenu?.dropdown as NgbDropdown;
  }

  constructor(
    private calendar: NgbCalendar,
    @Optional() @Self() ngControl: NgControl,
    protected cd: ChangeDetectorRef,
    public i18n: NgbDatepickerI18n
  ) {
    super(ngControl, cd);
  }

  ngOnInit(): void {
    if (this.label && !this.id) {
      console.error('Datepicker requires ID if label in use');
    }
    if (this.viewMode === DatepickerViewMode.year) {
      this.maskFormat = DateFormat.shortMonth;
      if (isNil(this.startDate)) {
        this.startDate = { year: new Date().getFullYear(), month: 1 };
      }
    }
  }

  ngAfterViewInit(): void {
    this.monthList$.next(this.datepicker.state.months);
    // wait view init, and send all values from parent model;
    this.dateSync$.pipe(untilDestroyed(this)).subscribe((value: Date) => {
      queueMicrotask(() => this.handleDateSet(value));
    });

    this.writeValue(this.date);
    this.cd.detectChanges();
  }

  handleSetToday(): void {
    this.date = this.currentDate;
    this.navigateTo(this.currentDate);
  }

  handleSetToYears(): void {
    this.date = this.currentDate;
    this.datepicker.navigateTo({ year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1 });
  }

  handleDateSet(value: Datepicker) {
    this.myValue = this.convertDate(value);
    this.datepickerRef.nativeElement.value = this.myValue;
    this.selectedDate = this.dateAdapter.fromModel(new Date(value));
    if (this.onChange) {
      this.onChange(value);
    }
    this.cd.markForCheck();
    this.change.emit(value);
  }

  handleBlur(event: DOMEvent<HTMLInputElement>): void {
    super.handleBlur(event);
    this.date = new Date(event.target.value);
  }

  handleClick(event: Event): void {
    if (this.viewMode === DatepickerViewMode.month || this.viewMode === DatepickerViewMode.year) {
      !this.dropdownMenuDrop.isOpen() && this.dropdownMenuDrop.open();
      this.date && this.navigateTo(this.date);
      queueMicrotask(() => {
        host(this.datepickerRef).focus();
      });
    }
  }

  handleButtonClick(event): void {
    if (this.viewMode === DatepickerViewMode.year) {
      this.dpDropdown.toggle();
    } else {
      this.dropdownMenuDrop.toggle();
      if (this.dropdownMenuDrop.isOpen() && this.date) {
        this.navigateTo(this.date);
      }
    }
  }

  handleMonthSelect(date: NgbDate) {
    const navigatedDate = new Date(this.navigatedYear, date.month - 1);
    const ngbDate = new NgbDate(navigatedDate.getFullYear(), navigatedDate.getMonth(), 1);
    this.date = navigatedDate;
    this.datepicker.navigateTo(ngbDate);
  }

  handleButtonEnter() {
    if (this.viewMode === DatepickerViewMode.year) {
      this.dropdownMenuDrop.close();
    } else {
      this.dpDropdown.close();
    }
  }

  ngOnDestroy(): void {
    this.datepicker?.ngOnDestroy();
  }

  writeValue(date: Datepicker): void {
    if (!date) return;
    this.selectedDate = this.dateAdapter.fromModel(new Date(date));
    super.writeValue(this.convertDate(date));
  }

  /**
   * Displaying in the input in the correct maskFormat, for example: `Jun 17, 2021`. <br />
   * Convert Date object using func `formatDate`
   * @param {Datepicker} date
   * @return {string}
   */
  private convertDate(date: Datepicker): string {
    if (!date || date == 'Invalid Date') {
      if (this.needSetCurrent) return formatDate(this.currentDate, this.maskFormat, 'en-US');
      else return null;
    }
    return formatDate(new Date(date), this.maskFormat, 'en-US');
  }

  /**
   * Navigates to the provided date. <br>
   * With the default calendar we use ISO 8601: 'month' is 1=Jan ... 12=Dec. <br>
   * If nothing or invalid date provided calendar will open current month. <br>
   * Use the ` [startDate] ` input as an alternative.
   * @param {Date|string} date
   */
  navigateTo(date: Date | string): void {
    this.datepicker.navigateTo(this.dateAdapter.fromModel(new Date(date)));
  }

  /**
   * Checking if a value is a weekend
   * @param {NgbDate} date
   * @return {boolean}
   */
  isWeekend(date: NgbDate): boolean {
    return this.calendar.getWeekday(date) >= 6;
  }

  /**
   * Checking if a value is a selected date. <br />
   * It is necessary to set class for selection
   * @param {NgbDate} date
   * @return {boolean}
   */
  isSelected(date: NgbDate): boolean {
    return (
      this.selectedDate &&
      date.day === this.selectedDate.day &&
      date.month === this.selectedDate.month &&
      date.year === this.selectedDate.year
    );
  }

  isTodayMonth(date: NgbDate): boolean {
    return date.month - 1 === this.currentDate?.getMonth() && this.navigatedYear === this.currentDate?.getFullYear();
  }

  isSelectedMonth(date: NgbDate): boolean {
    return date.month === this.selectedDate?.month && this.navigatedYear === this.selectedDate?.year;
  }

  isDisabledMonth(date: NgbDate): boolean {
    return !this.isSelectedMonth(date) && this.markMonthDisabled && this.markMonthDisabled({ year: this.navigatedYear, month: date.month });
  }

  /**
   * Will be call in case of set value in the input from dropdown
   * @param {NgbDate | DOMEvent<HTMLInputElement>} event
   */
  handleChange(event: NgbDate | DOMEvent<HTMLInputElement>): void {
    if (event instanceof Event) {
      this.date = new Date(event.target.value);
    } else {
      this.date = new Date(event.year, event.month - 1, event.day);
    }
  }

  /**
   * Emmit event right before the navigation happens and displayed month changes
   * @param {NgbDatepickerNavigateEvent} event
   */
  onNavigate(event: NgbDatepickerNavigateEvent): void {
    this.navigatedYear = event.next.year;
    this.navigate.emit(event);
  }

  /**
   * Closing datepicker dropdown
   * @param {Event} event
   */
  onClosed(event): void {
    this.closed.emit(event);
  }

  onDateSelection(date: NgbDate) {
    const pickedDate = date as unknown as Datepicker;
    if (!this.myValue && !this.toDate) {
      this.myValue = pickedDate;
    } else if (this.myValue && !this.toDate && date.after(this.myValue as unknown as NgbDate)) {
      this.toDate = pickedDate;
    } else {
      this.toDate = null;
      this.myValue = pickedDate;
    }

    this.rangeSelect.emit({ from: this.myValue, to: this.toDate });
  }

  isHovered(date: NgbDate) {
    return (
      this.myValue && !this.toDate && this.hoveredDate && date.after(this.myValue as unknown as NgbDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.myValue as unknown as NgbDate) && date.before(this.toDate as unknown as NgbDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.myValue as unknown as NgbDate) ||
      (this.toDate && date.equals(this.toDate as unknown as NgbDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
}
