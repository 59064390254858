<ng-template #subButton
             let-storage>
  <mbs-button [isCtrl]="true"
              size="inherit"
              (click)="handleSubscribe(storage)">subscribe</mbs-button>.
</ng-template>

<ng-template #checkPayment>
  <mbs-button *ngIf="('Licenses' | can:'read')"
              (click)="handleGoLicensesPage()"
              [isCtrl]="true"
              size="inherit">Check your payment method</mbs-button>.
  <a *ngIf="!('Licenses' | can:'read')"
     [href]="'mailto:' + ubSubscribe.providerContactEmail">Contact the account owner.</a>
</ng-template>



<ng-template #alertTemplate
             let-context>

  <!-- manually delete -->
  <ng-container *ngIf="ubSubscribe.deletedTypes.includes(context?.context?.storage?.usageType); else noDeletedManually">
    <!-- overdue -->
    <ng-container *ngIf="UnifiedBillingAccountType.PaidWithDebtManuallyDeactivated === context?.context?.storage.usageType
                  else normalDeletion">
      {{ context?.context?.storage.storageTypeDisplayName }} Storage: The payment for the {{
      context?.context?.storage.storageTypeDisplayName }} storage service is overdue.
    </ng-container>
    <ng-template #normalDeletion>
      <ng-container *ngIf="context?.context?.storage.usageTypeDaysCount > 0">
        {{ context?.context?.storage.storageTypeDisplayName }} Storage: You have deleted your account. The data will be deleted in
        {{context?.context?.durationToRemoveOrExpire}}.
        During this time, you can restore your subscription with data saving.
      </ng-container>
    </ng-template>
  </ng-container>
  <!-- /manually delete -->

  <ng-template #noDeletedManually>
    <ng-container *ngIf="ubSubscribe.overdueTypes.includes(context?.context?.storage.usageType); else noOverdue">
      {{ context?.context?.storage.storageTypeDisplayName }} Storage: The payment is overdue. The data in the {{
      context?.context?.storage.storageTypeDisplayName }} Storage will be deleted in
      {{context?.context?.durationToRemoveOrExpire}}. <ng-container *ngTemplateOutlet="checkPayment"></ng-container>

    </ng-container>

    <ng-template #noOverdue>
      <ng-container *ngIf="context?.context?.storage?.usageType === UnifiedBillingAccountType.Trial; else unsuccessStep">


        {{ context?.context?.storage.storageTypeDisplayName }} Storage: Your trial period expires in
        {{context?.context?.durationToRemoveOrExpire}}. Click to <ng-container *ngTemplateOutlet="subButton; context: { $implicit: context?.context?.storage }"></ng-container>

      </ng-container>

      <ng-template #unsuccessStep>

        <ng-container *ngIf="context?.context?.storage.usageType === UnifiedBillingAccountType.TrialReadOnly">
          {{ context?.context?.storage.storageTypeDisplayName }} Storage: You have reached the limit of your trial storage
          account. To continue without any limits,
          <ng-container *ngTemplateOutlet="subButton; context: { $implicit: context?.context?.storage }"></ng-container>
        </ng-container>

        <ng-container
                      *ngIf="context?.context?.storage.usageType === UnifiedBillingAccountType.TrialDeactivated && context?.context?.storage.daysFromStartCurrentState > 0">
          {{ context?.context?.storage.storageTypeDisplayName }} Storage: The data will be deleted in
          {{context?.context?.durationToRemoveOrExpire}}. To continue without any
          limits,
          <ng-container *ngTemplateOutlet="subButton; context: { $implicit: context?.context?.storage }"></ng-container>
        </ng-container>

      </ng-template>

    </ng-template>
  </ng-template>
</ng-template>
