import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RmmDateTimeFormatModule } from '@components/date-time-format/rmm-date-time-format.module';
import { DownloadBuildModule } from '@components/download-build/download-build.module';
import { DownloadConnectModule } from '@components/download-connect/download-connect.module';
import { ConfirmPasswordModule } from '@modules/confirm-password/confirm-password.module';
import { ImmutabilityConfirmModalModule } from '@modules/immutability-modals/immutability-confirm-modal/immutability-confirm-modal.module';
import { ImmutabilityDeleteModalModule } from '@modules/immutability-modals/immutability-delete-modal/immutability-delete-modal.module';
import { IterationCtrlButtonModule } from '@modules/iteration-ctrl-button/iteration-ctrl-button.module';
import { PasswordModalModule } from '@modules/password-modal/password-modal.module';
import { PasswordRecoveryModule } from '@modules/password-recovery/password-recovery.module';
import { UtilitySelectionModalModule } from '@modules/utility-selection-modal/utility-selection-modal.module';
import { OnboardingWizardComponent } from '@modules/wizards/onboarding/onboarding.component';
import { AppsConfigureBackupComponent } from '@modules/wizards/onboarding/steps/apps/configure-backup/configure-backup.component';
import { AppsSelectUsersComponent } from '@modules/wizards/onboarding/steps/apps/select-users/select-users.component';
import { AppsSignInComponent } from '@modules/wizards/onboarding/steps/apps/sign-in/sign-in.component';
import { AppsSummaryComponent } from '@modules/wizards/onboarding/steps/apps/summary/summary.component';
import { BackupSourceComponent } from '@modules/wizards/onboarding/steps/computers/backup-source/backup-source.component';
import { NotificationsComponent } from '@modules/wizards/onboarding/steps/computers/notifications/notifications.component';
import { StorageAccountComponent } from '@modules/wizards/onboarding/steps/shared/storage-account/storage-account.component';
import { AppsViewComponent } from '@modules/wizards/onboarding/views/apps/apps.component';
import { ComputersViewComponent } from '@modules/wizards/onboarding/views/computers/computers.component';
import { HyperVViewComponent } from '@modules/wizards/onboarding/views/hyper-v/hyper-v.component';
import { RemoteDesktopOnboardingWizardComponent } from '@modules/wizards/remote-desktop-onboarding/remote-desktop-onboarding.component';
import { ArchiveModalContentComponent } from '@modules/wizards/remote-management-wizards/archive-modal-content/archive-modal-content.component';
import { BackupFilesWizard } from '@modules/wizards/remote-management-wizards/backup-files-wizard/backup-files-wizard.component';
import { HyperVWizard } from '@modules/wizards/remote-management-wizards/hyper-v-wizard/hyper-v-wizard.component';
import { ImageBasedRestoreWizard } from '@modules/wizards/remote-management-wizards/image-based-restore-wizard/image-based-restore-wizard.component';
import { ImageBasedWizard } from '@modules/wizards/remote-management-wizards/image-based-wizard/image-based-wizard.component';
import { RestoreFileWizard } from '@modules/wizards/remote-management-wizards/restore-file-wizard/restore-file-wizard.component';
import { SQLWizard } from '@modules/wizards/remote-management-wizards/sql-wizard/sql-wizard.component';
import { VMWareWizard } from '@modules/wizards/remote-management-wizards/vmware-wizard/vmware-wizard.component';
import { AdvancedFilterStepComponent } from '@modules/wizards/steps/advanced-filter-step/advanced-filter-step.component';
import { AdvancedOptionsStepComponent } from '@modules/wizards/steps/advanced-options-step/advanced-options-step.component';
import { AdvancedSettingsVirtualStepComponent } from '@modules/wizards/steps/advanced-settings-virtual-step/advanced-settings-virtual-step.component';
import { AppAwareStepComponent } from '@modules/wizards/steps/app-aware-step/app-aware-step.component';
import { ArchiveConsistencyStepComponent } from '@modules/wizards/steps/archive-consistency-step/archive-consistency-step.component';
import { BackupToRestoreStepComponent } from '@modules/wizards/steps/backup-to-restore-step/backup-to-restore-step.component';
import { CantOpenModalComponent } from '@modules/wizards/steps/components/cant-open-modal/cant-open-modal.component';
import { CheckboxLegendComponent } from '@modules/wizards/steps/components/checkbox-legend/checkbox-legend.component';
import { EnableStepModalComponent } from '@modules/wizards/steps/components/enable-step-modal/enable-step-modal.component';
import { FilesFoldersListComponent } from '@modules/wizards/steps/components/files-folders-list/files-folders-list.component';
import { TreeInModalComponent } from '@modules/wizards/steps/components/tree-in-modal/tree-in-modal.component';
import { CompressionEncryptionStepComponent } from '@modules/wizards/steps/compression-encryption-step/compression-encryption-step.component';
import { DestinationStepComponent } from '@modules/wizards/steps/destination-step/destination-step.component';
import { EncryptionOptionsStepComponent } from '@modules/wizards/steps/encription-options-step/encryption-options-step.component';
import { InstallationComponent } from '@modules/wizards/steps/installation/installation.component';
import { NotificationsStepComponent } from '@modules/wizards/steps/notifications-step/notifications-step.component';
import { EC2StepComponent } from '@modules/wizards/steps/only-restore-ibb-steps/EC2-step/EC2-step.component';
import { AzureStepComponent } from '@modules/wizards/steps/only-restore-ibb-steps/azure-step/azure-step.component';
import { PartitionsStepComponent } from '@modules/wizards/steps/only-restore-ibb-steps/partitions-step/partitions-step.component';
import { TemporaryInstanceStepComponent } from '@modules/wizards/steps/only-restore-ibb-steps/temporary-instance-step/temporary-instance-step.component';
import { TypeDataStepComponent } from '@modules/wizards/steps/only-restore-ibb-steps/type-data-step/type-data-step.component';
import { PlanNameStepComponent } from '@modules/wizards/steps/plan-name-step/plan-name-step.component';
import { PrePostActionsStepComponent } from '@modules/wizards/steps/pre-post-actions-step/pre-post-actions-step.component';
import { RestorePointStepComponent } from '@modules/wizards/steps/restore-point-step/restore-point-step.component';
import { RestoreSourceStepComponent } from '@modules/wizards/steps/restore-source-step/restore-source-step.component';
import { RetentionPolicyStepComponent } from '@modules/wizards/steps/retention-policy-step/retention-policy-step.component';
import { RmmConnectGreetingStepComponent } from '@modules/wizards/steps/rmm-connect-greeting-step/rmm-connect-greeting-step.component';
import { ScheduleAdvancedStepComponent } from '@modules/wizards/steps/schedule-advanced-step/schedule-advanced-step.component';
import { ScheduleModalComponent } from '@modules/wizards/steps/schedule-step/schedule-modal/schedule-modal.component';
import { ScheduleStepComponent } from '@modules/wizards/steps/schedule-step/schedule-step.component';
import { SelectComputerStepComponent } from '@modules/wizards/steps/select-computer-step/select-computer-step.component';
import { SelectHostStepComponent } from '@modules/wizards/steps/select-host-step/select-host-step.component';
import { SelectVirtualDisksStepComponent } from '@modules/wizards/steps/select-virtual-disks-step/select-virtual-disks-step.component';
import { SelectVirtualMachinesStepComponent } from '@modules/wizards/steps/select-virtual-machines-step/select-virtual-machines-step.component';
import { SimpleScheduleStepComponent } from '@modules/wizards/steps/simple-schedule-step/simple-schedule-step.component';
import { SQLServerInstanceStepComponent } from '@modules/wizards/steps/sql-server-instance-step/sql-server-instance-step.component';
import { SQLSourceDatabasesStepComponent } from '@modules/wizards/steps/sql-source-databases-step/sql-source-databases-step.component';
import { WhatBackupStepComponent } from '@modules/wizards/steps/what-backup-step/what-backup-step.component';
import { NetworkShareModalComponent } from '@modules/wizards/steps/what-backup-tree-step/network-share-modal/network-share-modal.component';
import { WhatBackupTreeStepComponent } from '@modules/wizards/steps/what-backup-tree-step/what-backup-tree-step.component';
import { WhereBackupStepComponent } from '@modules/wizards/steps/where-backup-step/where-backup-step.component';
import { ComputerSelectStepComponent } from '@modules/wizards/try-rmm-for-providers/computer-select/computer-select-step.component';
import { InstallationStepComponent } from '@modules/wizards/try-rmm-for-providers/installation-step/installation-step.component';
import { ResultStepComponent } from '@modules/wizards/try-rmm-for-providers/result-step/result-step.component';
import { TryRMMForProvidersWizardComponent } from '@modules/wizards/try-rmm-for-providers/try-rmm-for-providers.component';
import { UserSelectStepComponent } from '@modules/wizards/try-rmm-for-providers/user-select-step/user-select-step.component';
import { UtilityIconsComponent } from '@modules/wizards/try-rmm-for-providers/utility-icons/utility-icons.component';
import { NgbDatepickerModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ComputersAspxAbstractService, ComputersAspxService } from '@services/computers-legacy/computers-legacy.service';
import { UtilsModule } from '@utils/utils.module';
import { I18NextModule } from 'angular-i18next';
import { InputPasswordModule, MbsUiKitModule, StepperModule, TreeModule, WizardModule } from 'mbs-ui-kit';
import { SwiperModule } from 'swiper/angular';

const wizards: [
  typeof BackupFilesWizard,
  typeof HyperVWizard,
  typeof ImageBasedWizard,
  typeof ImageBasedRestoreWizard,
  typeof OnboardingWizardComponent,
  typeof RestoreFileWizard,
  typeof SQLWizard,
  typeof VMWareWizard,
  typeof RemoteDesktopOnboardingWizardComponent
] = [
  BackupFilesWizard,
  HyperVWizard,
  ImageBasedWizard,
  ImageBasedRestoreWizard,
  OnboardingWizardComponent,
  RestoreFileWizard,
  SQLWizard,
  VMWareWizard,
  RemoteDesktopOnboardingWizardComponent
];

const components = [
  ...wizards,
  BackupFilesWizard,
  ImageBasedWizard,
  AzureStepComponent,
  AdvancedFilterStepComponent,
  AdvancedOptionsStepComponent,
  AdvancedSettingsVirtualStepComponent,
  AppAwareStepComponent,
  ArchiveConsistencyStepComponent,
  ArchiveModalContentComponent,
  BackupToRestoreStepComponent,
  SelectComputerStepComponent,
  SelectHostStepComponent,
  SelectVirtualDisksStepComponent,
  SelectVirtualMachinesStepComponent,
  SQLServerInstanceStepComponent,
  CompressionEncryptionStepComponent,
  DestinationStepComponent,
  EC2StepComponent,
  EncryptionOptionsStepComponent,
  TreeInModalComponent,
  CheckboxLegendComponent,
  CantOpenModalComponent,
  EnableStepModalComponent,
  FilesFoldersListComponent,
  NotificationsStepComponent,
  PlanNameStepComponent,
  PrePostActionsStepComponent,
  PartitionsStepComponent,
  RestorePointStepComponent,
  RestoreSourceStepComponent,
  RetentionPolicyStepComponent,
  ScheduleAdvancedStepComponent,
  ScheduleModalComponent,
  ScheduleStepComponent,
  SimpleScheduleStepComponent,
  SQLSourceDatabasesStepComponent,
  TemporaryInstanceStepComponent,
  TypeDataStepComponent,
  WhatBackupStepComponent,
  WhatBackupTreeStepComponent,
  WhereBackupStepComponent,
  TryRMMForProvidersWizardComponent,
  UserSelectStepComponent,
  ComputerSelectStepComponent,
  InstallationStepComponent,
  ResultStepComponent,
  UtilityIconsComponent,
  WhereBackupStepComponent,
  ComputersViewComponent,
  AppsViewComponent,
  HyperVViewComponent,
  StorageAccountComponent,
  BackupSourceComponent,
  NotificationsComponent,
  AppsConfigureBackupComponent,
  AppsSelectUsersComponent,
  AppsSignInComponent,
  AppsSummaryComponent,
  InstallationComponent,
  RmmConnectGreetingStepComponent,
  NetworkShareModalComponent
];

@NgModule({
  imports: [
    CommonModule,
    ConfirmPasswordModule,
    FormsModule,
    ImmutabilityConfirmModalModule,
    ImmutabilityDeleteModalModule,
    MbsUiKitModule,
    NgbTooltipModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    TreeModule,
    WizardModule,
    UtilsModule,
    RouterModule,
    I18NextModule,
    RmmDateTimeFormatModule,
    StepperModule,
    IterationCtrlButtonModule,
    PasswordRecoveryModule,
    UtilitySelectionModalModule,
    SwiperModule,
    NgbDatepickerModule,
    DownloadBuildModule,
    DownloadConnectModule,
    InputPasswordModule
  ],
  declarations: [components],
  providers: [
    { provide: ComputersAspxAbstractService, useClass: ComputersAspxService },
    PasswordRecoveryModule,
    UtilitySelectionModalModule,
    PasswordModalModule
  ],
  exports: wizards
})
export class AllWizardsModule {
  constructor() {}
}
