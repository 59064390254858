<ng-container [formGroup]="passwordForm">
  <mbs-form-group>
    <mbs-input formControlName="password"
               [tooltipClass]="errorsTooltipClass"
               [placement]="errorsTooltipPlacement"
               [container]="errorsTooltipContainer"
               [showErrorsInTooltip]="showErrorsInTooltip"
               [errorTooltipTitle]="errorTooltipTitle"
               [label]="label"
               [placeholder]="placeholder"
               [id]="id"
               [autocomplete]="'new-password'"
               [trim]="false"
               [type]="passwordInputType"
               (buttonClick)="changeTypePassword()"
               (blur)="passwordBlurHandler($event)"
               (focus)="passwordFocusHandler($event)"
               [appendButtons]="[
                 {
                   icon: passwordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                   id: 'changeTypePassword',
                   disabled: disabledButtons || passwordForm.get('password').disabled || !passwordForm.get('password').value || passwordForm.get('password')?.value === initialPassword,
                   type: 'secondary'
                 }
               ]">
      <ng-container appendLabel>
        <span class="ico ico-InfoCircle ml-2 cursor-help text-info"
              tooltipClass="tooltip-xl"
              container="body"
              [ngbTooltip]="tooltipPassPolicyDescription"></span>
        <ng-template #tooltipPassPolicyDescription>
          <ul class="text-left m-0 p-3">
            <li *ngFor="let item of passwordRules;let last = last"
                class="text-md ml-2 mb-1 pb-0">
              {{ item }}<br *ngIf="!last">
            </li>
          </ul>
        </ng-template>
      </ng-container>
    </mbs-input>
  </mbs-form-group>
  <mbs-form-group class="mb-0">
    <mbs-input formControlName="confirmationPassword"
               [label]="confirmPasswordLabel"
               [placeholder]="confirmPlaceholder"
               [id]="'confirmPassComponentConfirmationPassword'"
               [autocomplete]="'new-password'"
               [type]="confirmPasswordInputType"
               [trim]="false"
               (buttonClick)="changeTypeConfirmationPassword()"
               (blur)="passwordBlurHandler($event, true)"
               (focus)="passwordFocusHandler($event, true)"
               [appendButtons]="[
                 {
                   icon: confirmPasswordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash',
                   id: 'changeTypeConfirmationPassword',
                   disabled: disabledButtons || passwordForm.get('confirmationPassword').disabled || !passwordForm.get('confirmationPassword').value || passwordForm.get('confirmationPassword')?.value === initialPassword,
                   type: 'secondary'
                 }
               ]">
    </mbs-input>
  </mbs-form-group>
</ng-container>
