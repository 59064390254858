<h4>{{ 'rmm.module:groupActions.stepSchedule.title' | i18next }}</h4>
<form *ngIf="!isUpdatePolicy;else scheduleOptions">
  <mbs-form-group [formGroup]="modeSelectionForm">

    <mbs-radio [id]="elementSelectors.runManually"
               [label]="formModeSelectionOptionsLabel[0]"
               [value]="formModeSelectionOptions[0]"
               formControlName="mode">
    </mbs-radio>

    <mbs-radio [id]="elementSelectors.runOnce"
               [label]="formModeSelectionOptionsLabel[1]"
               [value]="formModeSelectionOptions[1]"
               formControlName="mode">
    </mbs-radio>

    <mbs-radio [id]="elementSelectors.specificDate"
               [label]="formModeSelectionOptionsLabel[2]"
               [value]="formModeSelectionOptions[2]"
               [descriptionOutside]="false"
               formControlName="mode">
      <ng-template radioDescription
                   [hide]="!showSchedule">

        <ng-container *ngTemplateOutlet="scheduleOptions"></ng-container>

      </ng-template>
      <ng-container appendLabel>
        <span class="ml-1 text-info ico ico-InfoCircle"
              [placement]="'top-right auto'"
              [container]="'body'"
              [ngbTooltip]="'rmm.module:groupActions.stepSchedule.scheduleTimeTooltip' | i18next">
        </span>
      </ng-container>
    </mbs-radio>
  </mbs-form-group>
</form>

<!-- isUpdatePolicy -->
<ng-template #scheduleOptions>
  <div [id]="elementSelectors.scheduleData"
       class="d-flex flex-column justify-content-between">

    <mbs-time-scheduler (onScheduleDataChange)="onScheduleDataChange($event)"
                        [scheduleData]="scheduleData"
                        [isAgentTimeUsed]="isUpdatePolicy"
                        [enableDailyOccursEvery]="!isUpdatePolicy">
    </mbs-time-scheduler>

    <form [formGroup]="scheduleMisfireHandlingForm">
      <div class="mb-3">
        <mbs-checkbox formControlName="handleMissFire"
                      [label]="'rmm.module:groupActions.stepSchedule.runMissedOnComputerStartup' | i18next">
        </mbs-checkbox>
      </div>
    </form>

    <mbs-card [size]="'sm'"
              class="mb-0"
              [staticHeight]="mbsCardStaticHeight">
      <mbs-card-header [title]="'rmm.module:groupActions.stepSchedule.totalInfo' | i18next">
      </mbs-card-header>
      <p class="mb-0">
        {{ dataDescription }}
      </p>
    </mbs-card>
  </div>
</ng-template>
