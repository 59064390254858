<mbs-form-group [formGroup]="installForm">
  <div class="mb-3">
    <label class="mbs-form_label font-weight-bold"
           for="url">
      {{ 'rmm.module:groupActions.stepConfigureAction.downloadLink' | i18next }}
    </label>
    <mbs-input [autocomplete]="'off'"
               [class]="'text-base'"
               formControlName="url">
    </mbs-input>
  </div>

  <label class="mbs-form_label font-weight-bold"
         for="arguments">
    {{ 'rmm.module:groupActions.stepConfigureAction.installKeys' | i18next }}
  </label>
  <mbs-input [autocomplete]="'off'"
             [class]="'text-base'"
             formControlName="arguments">
  </mbs-input>

  <div>
    {{ 'rmm-side-panel:softwareTab.installUrlDescription' | i18next }}
  </div>
</mbs-form-group>
