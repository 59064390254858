import { Component, ContentChild, Inject, Input, TemplateRef } from '@angular/core';
import { merge } from 'lodash';
import { MbsSize, ModalService, ModalSettings } from 'mbs-ui-kit';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mbs-modal-tab-content',
  template: `
    <mbs-button
      (click)="handleOpenModal()"
      icon="ico ico-external-link-square"
      [isCtrl]="false"
      container="body"
      triggers="hover"
      ngbTooltip="Open in&nbsp;modal"
      tooltipClass="tooltip-lg -no-arrow"
      placement="top-right">
    </mbs-button>
  `
})
export class ModalTabContentComponent {
  @ContentChild(TemplateRef, { static: true }) content: TemplateRef<any>;

  private myModalSettings: ModalSettings = {
    footer: { show: false },
    size: MbsSize.xl,
    collapsing: true
  };

  @Input() set modalSettings(value: ModalSettings) {
    this.myModalSettings = merge(this.myModalSettings, value);
  }

  @Input() modalTitle = '';
  @Input() showExpandedCross = false;

  constructor(
    private modal: ModalService, // prettier
    @Inject('IS_MODAL_CONTENT_OPEN') private IS_MODAL_CONTENT_OPEN: BehaviorSubject<boolean>
  ) {}

  handleOpenModal(): void {
    const settings = merge(this.myModalSettings, { header: { title: this.modalTitle, showExpandedCross: this.showExpandedCross } });
    this.IS_MODAL_CONTENT_OPEN.next(true);
    this.modal.open(settings, this.content).finally(() => this.IS_MODAL_CONTENT_OPEN.next(false));
  }
}
