import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import AntivirusInfo from '@models/rmm/AntivirusInfo';
import RmmCommand from '@models/rmm/RmmCommand';
import { RmmHubCommandResponse } from '@models/rmm/RmmHubCommandResponse';
import RmmLastStatData from '@models/rmm/RmmLastStatData';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmmService } from '@services/rmm.service';
import { baseDateFileNameFormatHoursAndMinutes, mediumDateWithTime } from '@utils/date';
import { versionCompare } from '@utils/version-compare';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { MbsPopupType, ModalService, ModalSettings, SharedPersistentStateEnum, TableHeader, ToastService } from 'mbs-ui-kit';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { finalize, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { RmmTabBaseComponent } from '../rmm-tab-base.component';

@UntilDestroy()
@Component({
  selector: 'mbs-antivirus-tab',
  templateUrl: './antivirus-tab.component.html'
})
export class AntivirusTabComponent extends RmmTabBaseComponent<AntivirusInfo> implements OnInit {
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;
  public readonly alertType = MbsPopupType;
  public shouldShowAlert = true;
  public shortHeaders: TableHeader[] = [
    {
      name: this.i18n.transform('rmm-side-panel:antivirusTab.tableHeaders.name'),
      overflow: true,
      sort: 'displayName',
      gridColSize: '28fr'
    },
    {
      name: this.i18n.transform('rmm-side-panel:antivirusTab.tableHeaders.latest'),
      sort: 'upToDate',
      gridColSize: '11fr'
    },
    {
      name: this.i18n.transform('rmm-side-panel:antivirusTab.tableHeaders.date'),
      sort: 'timeStamp',
      gridColSize: '30fr',
      gridColMin: '160px'
    },
    {
      name: this.i18n.transform('rmm-side-panel:antivirusTab.tableHeaders.active'),
      sort: 'enabled',
      gridColSize: '10fr',
      gridColMin: '60px'
    }
  ];

  public fullHeaders: TableHeader[] = this.shortHeaders.concat([
    {
      name: this.i18n.transform('rmm-side-panel:antivirusTab.tableHeaders.autoUpdate'),
      sort: 'autoUpdate',
      gridColSize: '15fr',
      gridColMin: '125px'
    },
    {
      name: this.i18n.transform('rmm-side-panel:antivirusTab.tableHeaders.productExecutable'),
      overflow: true,
      sort: 'pathToSignedProductExe',
      gridColSize: '30fr',
      gridColMin: '135px'
    },
    {
      name: this.i18n.transform('rmm-side-panel:antivirusTab.tableHeaders.reportingExe'),
      overflow: true,
      sort: 'pathToSignedReportingExe',
      gridColSize: '30fr'
    }
  ]);

  @Input() public computerName: string;
  @Input() public readOnly: boolean;
  @Input() public agentVersion: { version: string; supported: boolean };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  public specificDirectory = new FormControl('', [Validators.required, this.pathValidator]);

  // public selectedAntivirus: AntivirusInfo[] = [];
  public latestScanData: string[] = [];
  public canHandleScan = false;
  public canHandleDBUpdate = false;
  public isVersionSupported = true;
  public minimalVersion = '1.3.0';
  public isWaitingForResponse = false;
  private noDataText = this.i18n.transform('rmm-side-panel:noDataProvided');

  private pathValidator(control: AbstractControl): ValidationErrors | null {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(?:[a-z]:)?[\/\\]{0,2}(?:[.\/\\ ](?![.\/\\\n])|[^<>:"|?*.\/\\ \n])+$/gim;
    const paths: string[] = (control.value || '')
      .split(';')
      .map((p) => p.trim())
      .filter((p) => p);
    const invalid = paths.some((p) => !new RegExp(regex).test(p));
    return invalid ? { pattern: { message: this.i18n.transform('rmm-side-panel:antivirusTab.invalidPath') } } : null;
  }

  constructor(
    public rmmService: RmmService,
    private commandService: CommandService,
    private modal: ModalService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private i18n: I18NextPipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.headers = this.isModal ? this.fullHeaders : this.shortHeaders;
    this.isVersionSupported = this.agentVersion && versionCompare(this.agentVersion.version, this.minimalVersion) >= 0;
    this.searchFields = ['displayName'];
    this.rmmService
      .fetchLastData<RmmLastStatData<AntivirusInfo>>('antivirus')
      .pipe(first())
      .subscribe((antiviruses) => {
        if (antiviruses) {
          this.loading = false;
          this.allData = cloneDeep(antiviruses.data).map((value, index) => Object.assign({}, value, { id: index }));
          this.updateData(this.allData);
        } else {
          this.loading = true;
          this.fetchData();
        }
      });
  }

  fetchData(): void {
    this.loading = true;
    this.rmmService
      .fetchLastData<RmmLastStatData<AntivirusInfo>>('antivirus')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.loading = false))
      )
      .subscribe((stat) => {
        if (stat.data[0]) {
          const antivirus = cloneDeep(stat.data);
          this.allData = antivirus.map((value, index) => Object.assign({}, value, { id: index }));
          this.updateData(this.allData);
        }
      });
  }

  handleCheckSpecificDirectory(template: TemplateRef<any>): void {
    const settings: ModalSettings = {
      header: { title: `${this.i18n.transform('rmm-side-panel:antivirusTab.scanOrSpecify')} ${this.computerName}` },
      footer: {
        okButton: {
          text: this.i18n.transform('buttons:run'),
          disabled$: this.specificDirectory.valueChanges.pipe(
            map((v) => !v),
            startWith(true)
          )
        }
      }
    };

    this.modal
      .open(settings, template)
      .then(() => {
        const antivirus = this.selected[0];
        const command = RmmCommand.create('PathScan')
          .addParam('PATH', this.specificDirectory.value)
          .addParam('PRODUCT', antivirus.displayName);

        this.commandService
          .sendCommandAsync<RmmHubCommandResponse<string>>('AntivirusCmd', command, this.hid, true)
          .pipe(
            switchMap((response: any) => (response?.error?.code ? throwError(() => response) : of(response))),
            first(),
            tap((value) => (this.isWaitingForResponse = true)),
            finalize(() => this.specificDirectory.reset('')),
            switchMap((r) => {
              this.toastService.success(
                `${this.i18n.transform('rmm-side-panel:antivirusTab.scanStarted')} <div class="text-break">"${
                  this.specificDirectory.value
                }"</div>`
              );
              this.shouldShowAlert = true;

              return this.getMessageByAsyncId(r);
            })
          )
          .subscribe((data) => {
            this.latestScanData = data?.filter((d) => !!d) ?? [this.noDataText];
            this.isWaitingForResponse = false;
            this.toastService.success(this.i18n.transform('rmm-side-panel:antivirusTab.scanFinished'));
          });
      })
      .catch(() => {
        this.isWaitingForResponse = false;
        this.specificDirectory.reset('');
      });
  }

  handleUpdateBase(): void {
    let selectedAntivirusStr: string;
    if (this.selected.length === 1) {
      selectedAntivirusStr = `${this.i18n.transform('rmm-side-panel:antivirusTab.dataBaseFor')} <span class="font-weight-semibold">${
        this.selected[0].displayName
      }</span>`;
    } else if (this.selected.length > 1) {
      selectedAntivirusStr = `${this.i18n.transform(
        'rmm-side-panel:antivirusTab.dataBasesFor'
      )} <span class="font-weight-semibold">${this.selected.map((item) => item.displayName).join(', ')}</span>`;
    }
    const confirmMessage = `${this.i18n.transform('rmm-side-panel:antivirusTab.updateThe')} ${selectedAntivirusStr}?`;

    this.modal
      .open(
        {
          header: { title: this.i18n.transform('rmm-side-panel:antivirusTab.updateAntivirusTitle') },
          footer: { okButton: { text: this.i18n.transform('buttons:update') } }
        },
        confirmMessage
      )
      .then(() => {
        const command = RmmCommand.create('SignatureUpdate');
        this.selected.forEach((antivirus) => {
          command.addParam('PRODUCT', antivirus.displayName);
        });
        this.commandService
          .sendCommandAsync('AntivirusCmd', command, this.hid, true)
          .pipe(
            switchMap((response: any) => (response?.error?.code ? throwError(() => response) : of(response))),
            first(),
            tap(() => (this.isWaitingForResponse = false)),
            switchMap((response) => this.getMessageByAsyncId(response))
          )
          .subscribe((data) => {
            this.shouldShowAlert = true;
            this.isWaitingForResponse = false;
            this.latestScanData = data?.filter((d) => !!d) ?? [this.noDataText];
            this.toastService.success(this.i18n.transform('rmm-side-panel:antivirusTab.antivirusDataBaseUpdated'));
          });

        this.toastService.success(this.i18n.transform('rmm-side-panel:antivirusTab.antivirusDataBaseBeingUpdated'));
      })
      .catch(() => (this.isWaitingForResponse = false));
  }

  trackBy(index: number, item: ExtendedTableRow): number {
    return (item?.item as AntivirusInfo)?.id;
    // return item.instanceGuid;
  }

  itemsChecked(items: AntivirusInfo[]): void {
    this.handleChangeSelected(items);
    this.cdr.detectChanges();
    this.antivirusSearchAndUpdateAvailability();
  }

  handleGetLatestData(): void {
    const antivirus = this.selected[0];
    const command = RmmCommand.create('GetLatestDataIfPresent').addParam('PRODUCT', antivirus.displayName);
    this.commandService
      .sendCommandAsync<RmmHubCommandResponse<string>>('AntivirusCmd', command, this.hid, false)
      .pipe(
        first(),
        switchMap((response) => this.getMessageByAsyncId(response))
      )
      .subscribe((data) => {
        this.shouldShowAlert = true;
        this.latestScanData = data?.filter((d) => !!d) ?? [this.noDataText];
      });
  }

  private antivirusSearchAndUpdateAvailability(): void {
    const hasSelected = this.selected.length > 0;

    if (!this.isVersionSupported) {
      this.canHandleDBUpdate = hasSelected;
      this.canHandleScan = hasSelected;
      return null;
    }

    // disable angular reRender issue using queueMicrotask
    if (hasSelected) {
      queueMicrotask(() => {
        this.canHandleDBUpdate = true;
        this.canHandleScan = true;
        this.selected.forEach((element) => {
          if (!element.canScan) this.canHandleScan = false;
          if (!element.canUpdate) this.canHandleDBUpdate = false;
        });
      });
    } else {
      this.canHandleDBUpdate = false;
      this.canHandleScan = false;
    }
  }

  public onAlertClose(): void {
    this.shouldShowAlert = false;
  }

  getMessageByAsyncId(response) {
    if (response?.result) {
      const obj = JSON.parse(response?.result);
      const splitter = ' = ';
      const asyncId = obj.data.split(splitter)[1];

      return this.getResponseData(this.commandService.selectMessagesByAsyncId$(asyncId));
    } else {
      return this.getResponseData(this.commandService.messages$);
    }
  }

  private getResponseData(stream$: Observable<any>) {
    return stream$.pipe(
      map((response: any) => response?.Data && typeof response?.Data === 'string' && JSON.parse(response?.Data)?.data),
      map((dataToDisplay) => (dataToDisplay?.length ? dataToDisplay : [this.noDataText]))
    );
  }

  public getTableName(): string {
    return `${this.computerName} ${this.i18n.transform('rmm-side-panel:sidePanelTabsName.antivirus')} ${moment().format(baseDateFileNameFormatHoursAndMinutes)}`;
  }
}
