import { MbsSize } from 'mbs-ui-kit';

/*
 * exist css vars list
 */
export enum CSSVariables {
  Blue = 'blue',
  Indigo = 'indigo',
  Purple = 'purple',
  Pink = 'pink',
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  Green = 'green',
  Teal = 'teal',
  Cyan = 'cyan',
  Black = 'black',
  White = 'white',
  GrayLight = 'gray-light',
  Gray = 'gray',
  GrayDark = 'gray-dark',
  BlueLink = 'blue-link',
  BlueDark = 'blue-dark',
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Light = 'light',
  Dark = 'dark',
  Brand = 'brand'
}
/*
 * result of parsing color string
 */
export type CssColorMeta = {
  /* @summary color format */
  format?: CssColorFormat;
  /* @summary raw color string */
  color?: string;
  /* @summary red value */
  r?: string;
  /* @summary green value */
  g?: string;
  /* @summary blue value */
  b?: string;
  /* @summary alfa */
  a?: string;
};
/*
 * parce value of css variable
 * @param name string with\without -- prefix
 * @param root element hosted styles
 * @returns value of css rule
 */
export function getCssVar(name: string, root: HTMLElement = document.body): string {
  name = name.replace(/^--/, '');
  return getComputedStyle(document.body).getPropertyValue(`--${name}`);
}
/*
 * color formats etc
 */
export enum CssColorFormat {
  HEX = 'hex',
  RGB = 'rgb',
  RGBA = 'rgba',
  /*
   * as Raw format
   */
  SELF = ''
}
// disable becouse lint cant correct parse RegExp string
/* eslint-disable */
/*
 * RexExp patterns for parse color to CssColorMeta
 */
export const ColorFormatPatterns = {
  /*
   * @summary exec : [0]color: #ffffff, [1]r: ff, [2]g: ff, [3]b: ff,
   */
  HEX: /\#([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})/i,
  /*
   * @summary exec : [0]color: rgb(000, 000, 000), [1]r: 000, [2]g: 000, [3]b: 000,
   */
  RGB: /rgb\(\s*?(\d{1,3}),\s*?(\d{1,3}),\s*?(\d{1,3})\s*?\)/i,
  /*
   * @summary exec : [0]color: rgba(000, 000, 000, 0.0), [1]r: 000, [2]g: 000, [3]b: 000, [4]a: 0.0
   */
  RGBA: /rgba\(\s*?(\d{1,3}),\s*?(\d{1,3}),\s*?(\d{1,3}),\s*?([\d\.]+)\s*?\)/i
};
/* eslint-enable */
/*
 * parser by ColorFormatPatterns
 * @param color : #000 \ #fff000 \ rgb \ rgba
 * @returns CssColorMeta
 */
export const parseColor = (color): CssColorMeta => {
  const meta = Object.keys(ColorFormatPatterns).reduce(
    (acc: CssColorMeta, key) => {
      const parsed = ColorFormatPatterns[key].exec(color);
      if (parsed && !acc.color) {
        acc = { format: CssColorFormat[key], color: parsed[0], r: parsed[1], g: parsed[2], b: parsed[3], a: parsed[4] || '1' };
      }
      return acc;
    },
    { color: '' }
  );

  if (!meta) {
    throw new Error(`Color format not supported : ${color}`);
  }

  return meta;
};
/*
 * Conver color to Hex : #000fff
 * @param color
 * @returns color string
 */
export const toHex = (color): string => {
  const parsed: CssColorMeta = parseColor(color);

  switch (parsed.format) {
    case CssColorFormat.HEX: {
      return parsed.color;
    }
    case CssColorFormat.RGB:
    case CssColorFormat.RGBA: {
      const r = parseInt(parsed.r).toString(16);
      const g = parseInt(parsed.g).toString(16);
      const b = parseInt(parsed.b).toString(16);
      return `#${r}${g}${b}`;
    }
    default:
      return '';
  }
};
/*
 * Conver color to rgb : rgb(0, 0, 0)
 * @param color
 * @returns color string
 */
export const toRGB = (color): string => {
  const parsed: CssColorMeta = parseColor(color);

  switch (parsed.format) {
    case CssColorFormat.RGB: {
      return parsed.color;
    }
    case CssColorFormat.HEX: {
      const r = parseInt(parsed.r, 16);
      const g = parseInt(parsed.g, 16);
      const b = parseInt(parsed.b, 16);
      return `rgb(${r}, ${g}, ${b})`;
    }
    case CssColorFormat.RGBA: {
      const r = parseInt(parsed.r);
      const g = parseInt(parsed.g);
      const b = parseInt(parsed.b);
      return `rgb(${r}, ${g}, ${b})`;
    }
    default:
      return '';
  }
};
/*
 * Conver color to rgba : rgba(0, 0, 0, 1)
 * @param color
 * @returns color string
 */
export const toRGBA = (color): string => {
  const parsed: CssColorMeta = parseColor(color);

  switch (parsed.format) {
    case CssColorFormat.RGBA: {
      return parsed.color;
    }
    case CssColorFormat.HEX: {
      const r = parseInt(parsed.r, 16);
      const g = parseInt(parsed.g, 16);
      const b = parseInt(parsed.b, 16);
      return `rgba(${r}, ${g}, ${b}, 1)`;
    }
    case CssColorFormat.RGB: {
      const r = parseInt(parsed.r);
      const g = parseInt(parsed.g);
      const b = parseInt(parsed.b);
      return `rgba(${r}, ${g}, ${b}, 1)`;
    }
    default:
      return '';
  }
};
/*
 *  Conver color to requared format
 * @suppoted CssColorFormat
 * @param format
 * @returns color string
 */
export const toColorFormat =
  (format?: CssColorFormat) =>
  (color: string): string => {
    switch (format) {
      case CssColorFormat.HEX: {
        return toHex(color);
      }
      case CssColorFormat.RGB: {
        return toRGB(color);
      }
      case CssColorFormat.RGBA: {
        return toRGBA(color);
      }
      default:
        return parseColor(color).color;
    }
  };
/*
 * get color by css variable name, with requared format or raw
 * @suppoted CssColorFormat
 * @param name
 * @param format
 * @param root
 * @returns color string
 */
export const getCssColorVariable = (
  name: string | CSSVariables,
  format: CssColorFormat = CssColorFormat.SELF,
  root: HTMLElement = document.body
) => toColorFormat(format)(getCssVar(name, root));
/*
 * get Breakpoint by syffix css valible
 * @suppoted CssColorFormat
 * @param name
 * @param root
 * @returns number as px.
 */
export const getCssBreakpointVariable = (name: string | MbsSize, root: HTMLElement = document.body): number => {
  name = `--breakpoint-${name}`;
  return parseInt(getCssVar(name, root).replace(/[\s(px)]/g, ''));
};
