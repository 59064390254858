import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidepanelRmmModule } from '@components/sidepanel-rmm/sidepanel-rmm.module';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { GroupTasksModule } from '@modules/group-tasks/group-tasks.module';
import { ScriptLibraryModule } from '@modules/script-library/script-library.module';
import { TerminalEmulatorModule } from '@modules/terminal-emulator/terminal-emulator.module';
import { TimeSchedulerModule } from '@modules/time-scheduler/time-scheduler.module';
import { UtilitySelectionModalModule } from '@modules/utility-selection-modal/utility-selection-modal.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ComputersService, RmmService, TFARMMPermissionHelperService } from '@services';
import { CompaniesService } from '@services/companies.service';
import { RmmPowershellService } from '@services/rmm-powershell.service';
import { RmmSoftwareService } from '@services/rmm-software.service';
import { RmmWebsocketService } from '@services/rmm-websocket.service';
import { ComputerSmartSearchService } from '@services/smart-search-template.service';
import { ComputerTagsStoreModule } from '@shared/store/computerTags';
import { StoreTypes } from '@shared/store/storeTypes.enum';
import { I18NextModule, I18NEXT_NAMESPACE } from 'angular-i18next';
import { ListSelectModule, MbsUiKitModule, SmartSearchConfig, TagsInputModule } from 'mbs-ui-kit';
import { CodeEditorModule } from '../code-editor/code-editor.module';
import { IterationCtrlButtonModule } from '../iteration-ctrl-button/iteration-ctrl-button.module';
import { CommandService } from '../rmm/services/rmm-command.service';
import { ApplyToComponent } from './components/apply-to/apply-to.component';
import { ActionScriptLibraryComponent } from './components/configure-action/components/execute-script/components/script-library/script-library.component';
import { TerminalActionComponent } from './components/configure-action/components/execute-script/components/terminal/terminal.component';
import { ExecuteScriptComponent } from './components/configure-action/components/execute-script/execute-script.component';
import { InstallSoftwareComponent } from './components/configure-action/components/install-software-common/components/install-software/install-software.component';
import { InstallWinGetComponent } from './components/configure-action/components/install-software-common/components/install-winget/install-winget.component';
import { InstallSoftwareCommonComponent } from './components/configure-action/components/install-software-common/install-software-common';
import { InstallSystemUpdateComponent } from './components/configure-action/components/install-system-update/install-system-update.component';
import { UninstallSoftwareComponent } from './components/configure-action/components/uninstall-software/uninstall-software.component';
import { SelectionModalComponent } from './components/configure-action/components/update-policy/selection-modal/selection-modal.component';
import { UpdatePolicyComponent } from './components/configure-action/components/update-policy/update-policy.component';
import { ConfigureActionComponent } from './components/configure-action/configure-action.component';
import { ScheduleActionComponent } from './components/schedule-action/schedule-action.component';
import { SelectActionComponent } from './components/select-action/select-action.component';
import { ShowResultComponent } from './components/show-result/show-result.component';
import { ShowSummaryComponent } from './components/show-summary/show-summary.component';
import { RMMGroupActionWizardComponent } from './rmm-group-action-wizard.component';
import { RmmGroupTaskWizardEffects } from './store/group-action/group-action.effects';
import { groupActionWizardReducer } from './store/group-action/group-action.reducer';
import EditGroupTaskUtility from './utility/edit-group-task-utility';

const declarations = [
  SelectActionComponent,
  ApplyToComponent,
  ConfigureActionComponent,
  ScheduleActionComponent,
  ShowSummaryComponent,
  ShowResultComponent,
  ExecuteScriptComponent,
  ActionScriptLibraryComponent,
  TerminalActionComponent,
  UpdatePolicyComponent,
  SelectionModalComponent,
  InstallSoftwareCommonComponent,
  InstallSoftwareComponent,
  InstallWinGetComponent,
  UninstallSoftwareComponent,
  InstallSystemUpdateComponent
];

@NgModule({
  imports: [
    CommonModule,
    MbsUiKitModule,
    NgbTooltipModule,
    FormsModule,
    SidepanelRmmModule,
    I18NextModule,
    ReactiveFormsModule,
    TimeSchedulerModule,
    GroupTasksModule,
    ScriptLibraryModule,
    UtilitySelectionModalModule,
    IterationCtrlButtonModule,
    TerminalEmulatorModule,
    CodeEditorModule,
    ListSelectModule,
    TagsInputModule,
    ComputerTagsStoreModule,
    StoreModule.forFeature(StoreTypes.RMMGroupActionWizard, groupActionWizardReducer),
    EffectsModule.forFeature([RmmGroupTaskWizardEffects])
  ],
  declarations: [RMMGroupActionWizardComponent, ...declarations],
  providers: [
    RmmService,
    CommandService,
    RmmSoftwareService,
    RmmWebsocketService,
    RmmPowershellService,
    ComputerSmartSearchService,
    CompaniesService,
    ComputersFacade,
    ComputersService,
    EditGroupTaskUtility,
    TFARMMPermissionHelperService,
    {
      provide: SmartSearchConfig,
      useClass: SmartSearchConfig
    },
    {
      provide: I18NEXT_NAMESPACE,
      useValue: ['rmm.module']
    }
  ],
  exports: [RMMGroupActionWizardComponent]
})
export class RMMGroupActionWizardModule {}
