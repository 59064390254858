import { BehaviorSubject, Observable, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DataChangeWatcherService, SidepanelCrudBase } from 'mbs-ui-kit';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ScriptLibraryEntry } from '../store/script-library.model';

const IS_MODAL_CONTENT_OPEN = new BehaviorSubject<boolean>(false);
@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-rmm-management',
  templateUrl: './sidepanel-rmm-management.component.html',
  // styleUrls: ['./name.component.scss']
  viewProviders: [
    {
      provide: 'IS_MODAL_CONTENT_OPEN',
      useValue: IS_MODAL_CONTENT_OPEN
    }
  ]
})
export class SidepanelRmmManagementComponent extends SidepanelCrudBase<any> implements OnInit {
  script: ScriptLibraryEntry;

  updateData(data: any): void {
    // throw new Error('Method not implemented.');
  }
  handleSave(): Observable<boolean> {
    return of(null);
  }
  handleDelete(): Observable<boolean> {
    return of(null);
  }
  constructor(protected cdNew: DataChangeWatcherService) {
    super(cdNew);
  }

  ngOnInit(): void {
    this.data$.pipe(untilDestroyed(this)).subscribe((data) => (this.script = data.script));
  }

  handlePanelClose() {
    //
  }
}
