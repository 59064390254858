import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GroupTask, GroupTaskOsTypes } from '@modules/group-tasks/store/model';
import { GAActions, GASelectors } from '@modules/schedule-group-action/store/group-action';
import {
  DEFAULT_RMM_GROUP_TASK_TIMEOUT,
  GroupActionsCommands,
  ScriptModeSelectTypes,
  TimeMeasureValues
} from '@modules/schedule-group-action/store/group-action/group-action.model';
import EditGroupTaskUtility from '@modules/schedule-group-action/utility/edit-group-task-utility';
import { NgTerminalWrapperComponent } from '@modules/terminal-emulator/components/ng-terminal-wrapper.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { toByteArray } from 'base64-js';
import { combineLatest, filter, first, map, noop, Observable, of, pluck, switchMap, take, tap, withLatestFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-terminal-action',
  templateUrl: './terminal.component.html'
})
export class TerminalActionComponent implements OnInit {
  @Input() enableTitle = true;
  @Input() modeSelection: ScriptModeSelectTypes;
  @Output() setModeSelect = new EventEmitter<ScriptModeSelectTypes>();

  public scriptModeSelectTypes = ScriptModeSelectTypes;

  @ViewChild(NgTerminalWrapperComponent, { static: false }) wrapper: NgTerminalWrapperComponent;

  private defaultPowerShellScript = 'echo test' as const;
  private defaultBashScript = '#!/bin/bash\r\necho test' as const;

  public timeMeasureList = [
    {
      id: TimeMeasureValues.minutes,
      label: this.i18n.transform('app:timeUnits.minutes', { format: 'title' })
    },
    {
      id: TimeMeasureValues.hours,
      label: this.i18n.transform('app:timeUnits.hours', { format: 'title' })
    }
  ];

  public terminalScriptForm = new FormGroup({
    scriptBody: new FormControl(),
    scriptTimeoutTime: new FormControl(5),
    scriptTimeMeasure: new FormControl(this.timeMeasureList[0])
  });
  public fileExtension: '' | 'ps1' = '';
  private scriptLibraryBody = '%SCRIPT_BODY%';

  constructor(private store: Store, private editGroupTaskUtility: EditGroupTaskUtility, private i18n: I18NextPipe) {
    combineLatest([this.store.select(GASelectors.selectGActionCommandType), this.store.select(GASelectors.selectGActionOsType)])
      .pipe(
        filter(([commandType, osType]) => !!commandType && !!osType),
        withLatestFrom(this.editGroupTaskUtility.getTerminalScriptStream()),
        tap(([[commandType, osType], data]) => {
          this.fileExtension = commandType === GroupActionsCommands.POWERSHELL ? 'ps1' : '';
          if (this.modeSelection === ScriptModeSelectTypes.fromFile) return;

          this.terminalScriptForm
            .get('scriptBody')
            .setValue(
              data
                ? data
                : commandType === GroupActionsCommands.POWERSHELL && osType === GroupTaskOsTypes.Windows
                ? this.defaultPowerShellScript
                : this.defaultBashScript
            );
        }),
        untilDestroyed(this)
      )
      .subscribe(noop);

    this.terminalScriptForm.valueChanges
      .pipe(withLatestFrom(this.store.select(GASelectors.selectGActionOsType)), untilDestroyed(this))
      .subscribe(([data, osType]) => {
        if (!data.scriptBody) {
          this.store.dispatch(GAActions.setGActionParameters({ parameters: null }));
          return;
        }

        this.store.dispatch(
          GAActions.setGActionParameters({
            parameters: this.getGroupActionParams(osType !== GroupTaskOsTypes.Windows)
          })
        );
      });

    this.terminalScriptForm
      .get('scriptTimeMeasure')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value.id === TimeMeasureValues.hours && this.terminalScriptForm.get('scriptTimeoutTime').value > 23)
          this.terminalScriptForm.get('scriptTimeoutTime').setValue(23);
        if (value.id === TimeMeasureValues.minutes && this.terminalScriptForm.get('scriptTimeoutTime').value < 5)
          this.terminalScriptForm.get('scriptTimeoutTime').setValue(5);
      });
  }

  ngOnInit(): void {
    this.loadGroupTaskData();
  }

  private loadGroupTaskData(): void {
    const timeValues$ = this.editGroupTaskUtility.getTerminalTimeoutStream();
    this.getScriptBodyFromGroupAction()
      .pipe(
        switchMap((scriptBody) => (scriptBody ? of(scriptBody) : this.editGroupTaskUtility.getTerminalScriptStream())),
        withLatestFrom(timeValues$),
        take(1)
      )
      .subscribe(([scriptBody, data]) => {
        this.terminalScriptForm
          .get('scriptBody')
          .setValue(scriptBody && scriptBody !== this.scriptLibraryBody ? scriptBody : this.terminalScriptForm.get('scriptBody').value);

        if (data) {
          this.terminalScriptForm.get('scriptTimeoutTime').setValue(data.scriptTime, { emitEvent: false });
          this.terminalScriptForm
            .get('scriptTimeMeasure')
            .setValue(this.timeMeasureList.find((time) => time.id === data.scriptTimeMeasure));
        }
      });
  }

  private getScriptBodyFromGroupAction(): Observable<any> {
    return this.store.select(GASelectors.selectGActionParameters).pipe(
      filter((parameters) =>
        [GroupActionsCommands.POWERSHELL, GroupActionsCommands.BASH].includes(
          this.editGroupTaskUtility.parseCommandTypeFromGroupTask(parameters)
        )
      ),
      first(),
      pluck('parameters'),
      map((parameters) => (parameters ? JSON.parse(parameters) : null)),
      pluck('parameters', '0', 'value')
    );
  }

  fileLoadHandler(e): void {
    let base64 = e.base64;
    const index = base64.indexOf(',');
    base64 = base64.slice(index + 1);
    const byteArray = toByteArray(base64);
    const script = new TextDecoder().decode(byteArray);

    if (script) {
      this.setModeSelect.emit(ScriptModeSelectTypes.fromScript);
      this.terminalScriptForm.get('scriptBody').setValue(script);
    }
  }

  getTimeoutValue(scriptTimeoutTime, scriptTimeMeasure): number {
    const multiplierForMilliseconds = scriptTimeMeasure.id === TimeMeasureValues.minutes ? 1 : 60;
    return multiplierForMilliseconds * scriptTimeoutTime;
  }

  getGroupActionParams(isBash = false): Partial<GroupTask> {
    const data = this.terminalScriptForm.value;
    const time =
      data?.scriptTimeoutTime && data?.scriptTimeMeasure
        ? `${this.getTimeoutValue(data.scriptTimeoutTime, data.scriptTimeMeasure)}`
        : DEFAULT_RMM_GROUP_TASK_TIMEOUT;

    const parameters = [
      { name: 'SCRIPT', value: data.scriptBody },
      {
        name: 'TIMEOUT',
        value: time
      }
    ];

    return {
      disabled: false,
      type: 'pluginCommand',
      pluginCommandId: isBash ? 'BashCmd' : 'PowerShellTerminalCmd',
      parameters: JSON.stringify({ asyncID: getGuid(), id: 'Script', parameters: parameters }),
      enableOffline: false,
      confidentialOutputData: false,
      pluginActiveCommand: true,
      pluginPsCommand: true,
      description: this.getDescription(data, time, isBash),
      eventType: 0,
      scriptGuid: null,
      softwareSourceGuid: null,
      allComputers: false,
      computers: [],
      computerTags: [],
      runType: 0,
      scheduleType: 0,
      scheduleData: null
    };
  }

  getDescription(data: any, time: string, isBash = false): string {
    const translationKey = 'rmm.module:groupActions.actionDescription.';
    let description = this.editGroupTaskUtility.getTranslationByKeyAndValue(
      translationKey + (isBash ? 'bashCommandInfo' : 'terminalCommandInfo'),
      {}
    );

    if (time) description += `\r\n${this.editGroupTaskUtility.getTimeValuesForDescription(Number(time))}`;
    return description;
  }
}
