import { GroupTaskOsTypes, RunTypes } from '@modules/group-tasks/store/model';
import { createReducer, on } from '@ngrx/store';
import * as GroupActionActions from './group-action.actions';
import { GroupActionsCommands, WindowsAvailableActions, WizardGroupAction } from './group-action.model';

export const RMMGroupTaskFeatureKey = 'RMMGroupTaskWizard';

export const groupActionWizardEmptyObject: WizardGroupAction = {
  name: '',
  osType: GroupTaskOsTypes.Windows,
  actionSelectCommand: WindowsAvailableActions.PowerShell,
  applyTo: {
    companies: [],
    computers: [],
    allCompanies: true,
    computerTags: []
  },
  groupActionCommand: GroupActionsCommands.POWERSHELL,
  parameters: null,
  schedule: {
    runType: RunTypes.immediately,
    scheduleType: 0,
    scheduleData: null,
    scheduleDesc: null
  }
};

export const initialState: Readonly<WizardGroupAction> = {
  ...groupActionWizardEmptyObject
};

export const groupActionWizardReducer = createReducer(
  initialState,
  on(GroupActionActions.setGActionName, (state, action) => ({ ...state, name: action.name })),
  on(GroupActionActions.setGActionOsType, (state, action) => ({ ...state, osType: action.osType, parameters: null })),
  on(GroupActionActions.setGActionSelectedType, (state, action) => {
    const parameters = state.actionSelectCommand === action.actionSelectCommand ? { ...state.parameters } : null;
    return { ...state, actionSelectCommand: action.actionSelectCommand, parameters };
  }),
  on(GroupActionActions.setGActionNameAndOsTypeAndCommandType, (state, action) => ({
    ...state,
    name: action.name,
    osType: action.osType,
    actionSelectCommand: action.actionSelectCommand,
    parameters: null
  })),
  //
  on(GroupActionActions.setGActionApplyTo, (state, action) => ({ ...state, applyTo: action.applyTo })),
  on(GroupActionActions.setGActionCommandType, (state, action) => {
    const parameters = state.groupActionCommand === action.groupActionCommand ? { ...state.parameters } : null;
    return { ...state, groupActionCommand: action.groupActionCommand, parameters };
  }),
  on(GroupActionActions.setGActionParameters, (state, action) => ({ ...state, parameters: action.parameters })),
  //
  on(GroupActionActions.setGActionSchedule, (state, action) => ({ ...state, schedule: action.schedule })),
  on(GroupActionActions.resetGActionSchedule, (state, action) => ({ ...state, schedule: { ...groupActionWizardEmptyObject.schedule } })),
  on(GroupActionActions.setGActionScheduleMisfireHandling, (state, action) => ({
    ...state,
    schedule: {
      ...state.schedule,
      scheduleData: {
        ...state.schedule.scheduleData,
        misfireHandling: action.misfireHandling
      }
    }
  })),
  on(GroupActionActions.resetGActionWizard, (state) => ({ ...state, ...groupActionWizardEmptyObject })),
  on(GroupActionActions.setupGroupTask, (state, action) => ({ ...state, ...action.wizardGroupAction }))
);
