<mbs-modal [title]="baseModal.data.title"
           [isCustomModal]="true"
           [showFooter]="true"
           [titleTextOverflow]="true"
           #modal>
  <div modal-body [formGroup]="form">
    <div class="form-row">
      <div class="col-12">
        <mbs-input formControlName="creditsCount"
                   [isErrorsShow]="false"
                   [label]="'app:paymentModal:label' | i18next"
                   [placeholder]="'app:paymentModal:placeholder' | i18next"></mbs-input>
      </div>
    </div>

    <div class="form-row"
         *ngIf="form.get('creditsCount').invalid">
      <div class="col-12">
        <mbs-input-errors [errors]="errors"></mbs-input-errors>
      </div>

    </div>
  </div>
  <ng-container modalFooter>
    <mbs-button (click)="redirectToPay()" [type]="'primary'" [disabled]="form.invalid">{{ 'app:buttons:buy' | i18next: { format: 'title' } }}</mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">{{ 'app:buttons:cancel' | i18next: { format: 'title' } }}
    </mbs-button>
  </ng-container>
</mbs-modal>
