import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../button/button.module';
import { LoaderModule } from '../loader/loader.module';
import { ModalModule } from '../modal/modal.module';
import { WizardStepComponent } from './wizard-step.component';
import { WizardTitle } from './wizard-title.directive';
import { WizardComponent, WizardFooterPrependDirective } from './wizard.component';
@NgModule({
  imports: [CommonModule, LoaderModule, ModalModule, NgbModalModule, ButtonModule],
  declarations: [WizardComponent, WizardStepComponent, WizardTitle, WizardFooterPrependDirective],
  exports: [WizardComponent, WizardStepComponent, WizardTitle, WizardFooterPrependDirective]
})
export class WizardModule {}
