import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { i18KeysByType, ItemTypeEnum, MyTreeElements } from '@models/backup/storages-type';
import Computer from '@models/Computer';
import { UntilDestroy } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { isNil } from 'lodash';
import {
  IntMaxValue,
  MbsSize,
  ModalService,
  ModalSettings,
  SharedPersistentStateEnum,
  StatusIconType,
  TableHeader,
  TreeElement
} from 'mbs-ui-kit';
import { AlertType } from 'mbs-ui-kit/alert/alert.model';
import { noop } from 'rxjs';

const OriginalSizeKey = 'originalSize';

@UntilDestroy()
@Component({
  selector: 'mbs-browse-storage-tables',
  templateUrl: './browse-storage-tables.component.html',
  styleUrls: ['./browse-storage-tables.component.scss']
})
export class BrowseStorageTablesComponent implements OnChanges, OnInit {
  @Input() rightTableData: MyTreeElements[];
  @Input() selectedItemsForQR: MyTreeElements[] = [];
  @Input() selectedTreeItem: MyTreeElements;
  @Input() computerData: Computer;
  @Input() deleteProcess = false;

  @Output() itemDblClick = new EventEmitter<{ event: MouseEvent; item: TreeElement }>();
  @Output() selectedItemsChange = new EventEmitter<MyTreeElements[]>();
  @Output() deleteClick = new EventEmitter<MyTreeElements>();
  @Output() restoreToLocal = new EventEmitter<{ isLocal: boolean; item: MyTreeElements }>();

  public headers: TableHeader[] = [];

  public readonly alertType = AlertType;
  public readonly MbsSize = MbsSize;
  public readonly itemTypeEnum = ItemTypeEnum;
  public readonly StatusIconType = StatusIconType;
  public readonly viewMode: SharedPersistentStateEnum = SharedPersistentStateEnum.table;
  public readonly alignRightTableClass = 'd-flex justify-content-start';
  public readonly rightTableDataHeaders: TableHeader[] = [
    {
      name: this.getTextFromI18Pipe('name'),
      headerName: 'label',
      gridColSize: '4.5fr',
      class: this.alignRightTableClass,
      overflow: true
    },
    { name: this.getTextFromI18Pipe('modifyDate'), headerName: 'modifyDateUTC', gridColSize: '3.5fr', class: this.alignRightTableClass },
    {
      name: this.getTextFromI18Pipe(OriginalSizeKey),
      headerName: 'originalSize',
      gridColSize: '3fr',
      class: this.alignRightTableClass,
      overflow: true
    }
  ];
  private readonly notUniqueHeaders: TableHeader[] = [
    { name: this.getTextFromI18Pipe('firstRestorePoint'), gridColMin: '160px', gridColSize: '160px', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe('lastRestorePoint'), gridColMin: '160px', gridColSize: '160px', class: this.alignRightTableClass },
    { name: 'Icons', headerName: 'Icons', gridColMin: '96px', gridColSize: '96px' },
    { name: this.getTextFromI18Pipe(OriginalSizeKey), gridColMin: '88px', gridColSize: '88px', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe('sizeOnStorage'), gridColMin: '104px', gridColSize: '104px', class: this.alignRightTableClass },
    {
      name: this.getTextFromI18Pipe('daysToPurge'),
      headerName: 'daysToPurge',
      gridColMin: '98px',
      gridColSize: '98px',
      class: this.alignRightTableClass
    },
    {
      name: this.getTextFromI18Pipe('totalSavings'),
      headerName: 'totalSavings',
      gridColMin: '126px',
      gridColSize: '126px',
      class: this.alignRightTableClass
    },
    {
      name: '',
      headerName: 'Actions',
      gridColMin: '86px',
      gridColSize: '86px',
      isGridColumn: false
    }
  ];
  public readonly rightTableBunchHeaders: TableHeader[] = [
    {
      name: this.getTextFromI18Pipe('planName'),
      gridColMin: '140px',
      gridColSize: '140px',
      class: this.alignRightTableClass,
      overflow: true
    },
    ...this.notUniqueHeaders.filter((item) => item.headerName !== 'daysToPurge')
  ];
  public readonly rightTableGenerationHeaders: TableHeader[] = [
    { name: '', gridColMin: '37px', gridColSize: '37px' },
    ...this.notUniqueHeaders
  ];
  public readonly rightTablePointFullHeaders: TableHeader[] = [
    { name: '', gridColMin: '37px', gridColSize: '37px' },
    { name: this.getTextFromI18Pipe('date'), gridColMin: '154px', gridColSize: '154px', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe('backupStatus'), gridColMin: '116px', gridColSize: '116px' },
    { name: this.getTextFromI18Pipe('consistencyCheck'), headerName: 'consistencyCheck', gridColMin: '140px', gridColSize: '140px' },
    { name: this.getTextFromI18Pipe('restoreVerification'), headerName: 'restoreVerification', gridColMin: '148px', gridColSize: '148px' },
    { name: this.getTextFromI18Pipe('duration'), gridColMin: '80px', gridColSize: '80px', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe(OriginalSizeKey), gridColMin: '95px', gridColSize: '95px', class: this.alignRightTableClass },
    { name: this.getTextFromI18Pipe('sizeOnStorage'), gridColMin: '104px', gridColSize: '104px', class: this.alignRightTableClass },
    {
      name: this.getTextFromI18Pipe('totalSavings'),
      headerName: 'totalSavings',
      gridColMin: '126px',
      gridColSize: '126px',
      class: this.alignRightTableClass
    },
    {
      name: '',
      headerName: 'Actions',
      gridColMin: '46px',
      gridColSize: '46px',
      isGridColumn: false
    }
  ];
  public readonly elementsSelector = {
    name: {
      rightTableDefault: 'right-table-default',
      rightTableBunches: 'right-table-bunches',
      rightTableGeneration: 'right-table-generation',
      rightTablePoints: 'right-table-points',
      modifyDateUTCTableRow: 'modify-date-utc-table-row',
      originalSizeTableRow: 'original-size-table-row',
      firstRestoreDateTableRow: 'first-restore-date-table-row',
      lastRestoreDateTableRow: 'last-restore-date-table-row',
      sizeOnStorageTableRow: 'size-on-storage-table-row',
      daysToPurgeTableRow: 'days-to-purge-table-row',
      totalSavingsTableRow: 'total-savings-table-row',
      dateTableRow: 'date-table-row',
      backupResultTableRow: 'backup-result-table-row',
      consistencyCheckResultTableRow: 'consistency-check-result-table-row',
      restoreVerificationResultTableRow: 'restore-verification-result-table-row',
      durationTableRow: 'duration-result-table-row'
    }
  };

  private readonly titleMap: { [key: number]: string } = {
    [ItemTypeEnum.Bunch]: this.i18nPipe.transform('backup-storages:browseStorage.backupPlans'),
    [ItemTypeEnum.Generation]: this.i18nPipe.transform('backup-storages:browseStorage.backupGenerations'),
    [ItemTypeEnum.RestorePoint]: this.i18nPipe.transform('backup-storages:browseStorage.restorePoints')
  };
  private readonly downloadSettings: ModalSettings = {
    header: { title: this.i18nPipe.transform('backup-storages:browseStorage.dataRestore', { format: 'title' }) },
    footer: { okButton: { text: this.i18nPipe.transform('buttons:restore'), type: 'primary' } }
  };

  public isLocal = false;
  public isGenerations = false;
  public isPoints = false;
  public isBackups = false;

  public existColumns: { label: boolean; modifyDateUTC: boolean; originalSize: boolean } = {
    label: true,
    modifyDateUTC: true,
    originalSize: true
  };

  get headerText(): string {
    return (this.isBackups || this.isGenerations || this.isPoints) && this.rightTableData?.length
      ? this.titleMap[this.rightTableData[0].itemType]
      : this.i18nPipe.transform('backup-storages:browseStorage.filesFolder');
  }

  get disabledQRButton(): boolean {
    return !this.selectedTreeItem?.checked || (!this.selectedTreeItem?.isCanBeQuickRestored && !this.selectedItemsForQR?.length);
  }

  get disabledDeleteButton(): boolean {
    return (
      !this.selectedTreeItem?.isCanBeDeleted ||
      this.deleteProcess ||
      (this.selectedTreeItem?.itemType !== ItemTypeEnum.Bunch && this.selectedTreeItem?.itemType !== ItemTypeEnum.Generation)
    );
  }

  get showCheckboxes(): boolean {
    return (
      (this.selectedTreeItem?.isCanBeQuickRestored && this.selectedTreeItem?.itemType !== ItemTypeEnum.File) ||
      this.rightTableData.some((item) => item.isCanBeQuickRestored)
    );
  }

  get rightTablePointHeaders(): TableHeader[] {
    if (this.computerData?.online) {
      return this.rightTablePointFullHeaders.filter(
        (header) => this.selectedTreeItem?.needShowRV || header.headerName !== 'restoreVerification'
      );
    }

    return this.rightTablePointFullHeaders.filter(
      (header) => this.selectedTreeItem?.needShowRV || header.headerName !== 'restoreVerification'
    );
  }

  @ViewChild('downloadModal', { static: true, read: TemplateRef }) downloadModal: TemplateRef<any>;

  constructor(private i18nPipe: I18NextPipe, private modalService: ModalService) {}

  ngOnInit(): void {
    this.applyNewTableData(this.rightTableData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyNewTableData(changes.rightTableData?.currentValue);
  }

  selectedItemsForQRChangeHandler(elements: MyTreeElements[]): void {
    this.selectedItemsChange.emit(elements);
  }

  itemDblClickHandler(event: MouseEvent, item: TreeElement): void {
    this.itemDblClick.emit({ event, item });
  }

  getDiskSizeFromKB(bytes: number): string {
    if (bytes < 1024) return bytes + ' B';

    const kb = +(bytes / 1024).toFixed(1);

    if (kb < 1024) return kb + ' KB';

    const mb = +(kb / 1024).toFixed(1);

    if (mb < 1024) return mb + ' MB';

    return +(mb / 1024).toFixed(1) + ' GB';
  }

  daysToPurgeToString(days: number): string {
    if (days === IntMaxValue) return 'keep forever';

    if (days < 31) return `${days} Days`;

    if (days < 365) return `${Math.floor(days / 31)} Months`;

    return `${Math.floor(days / 365)} Year`;
  }

  existPointsHeaderColumn(headerName: string): boolean {
    return this.rightTablePointHeaders.some((h: TableHeader) => h.headerName === headerName);
  }

  getDeleteTooltipText(item: MyTreeElements): string {
    if (!this.computerData?.online) return this.i18nPipe.transform('backup-storages:browseStorage.tables.cantDeleteOffline');

    if (this.deleteProcess || !item.isCanBeDeleted) {
      return this.i18nPipe.transform('backup-storages:browseStorage.tables.cantDelete');
    }

    const level = this.i18nPipe.transform(`backup-storages:browseStorage.tables.${i18KeysByType[item?.itemType]}`);

    return this.i18nPipe.transform('backup-storages:browseStorage.tables.delete', { level });
  }

  deleteSelectedClickHandler(item: MyTreeElements = null): void {
    this.deleteClick.emit(item);
  }

  downloadClickHandler(item: MyTreeElements = null): void {
    this.isLocal = false;

    if (!this.computerData?.online) {
      return this.restoreToLocal.emit({ isLocal: this.isLocal, item });
    }

    this.modalService
      .open(this.downloadSettings, this.downloadModal)
      .then((confirm) => {
        if (!confirm) return;

        this.restoreToLocal.emit({ isLocal: this.isLocal, item });
      })
      .catch(noop);
  }

  private applyNewTableData(tableData: MyTreeElements[] = []): void {
    if (tableData?.length) {
      this.setViewTypeFromItem(tableData[0]);
      this.setHeaders();
    }
  }

  private setViewTypeFromItem(item: MyTreeElements): void {
    this.isGenerations = item.itemType === ItemTypeEnum.Generation;
    this.isPoints = item.itemType === ItemTypeEnum.RestorePoint;
    this.isBackups = item.itemType === ItemTypeEnum.Bunch;
  }

  private setHeaders(): void {
    this.existColumns = { label: true, modifyDateUTC: false, originalSize: false };

    this.rightTableData?.forEach((item: any) => {
      if (!isNil(item.modifyDateUTC)) this.existColumns.modifyDateUTC = true;
      if (!isNil(item.originalSize)) this.existColumns.originalSize = true;
    });

    this.headers = this.rightTableDataHeaders.filter((item) => this.existColumns[item.headerName]);
  }

  private getTextFromI18Pipe(name: string): string {
    return this.i18nPipe.transform(`backup-storages:browseStorage.tables.${name}`);
  }
}
