<div *ngIf="myHostInfoRows?.length > 0">
  <div class="row justify-content-end">
    <mbs-button [icon]="'fa fa-download mr-1'"
                [class]="'mb-3'"
                [size]="'sm'"
                [disabled]="loading"
                [isCtrl]="true"
                (click)="exportToCsv()">
      {{ 'rmm-side-panel:exportToCSV' | i18next: { format: 'title'} }}
    </mbs-button>
  </div>
  <table class="table table-simple table-sm">
    <tr *ngFor="let row of myHostInfoRows">
      <ng-container *ngIf="row.value">
        <td class="text-muted">
          {{ row.key }}:
        </td>
        <td>
          {{ row.value }}
        </td>
      </ng-container>
    </tr>
  </table>
</div>

<ng-container *ngIf="!myHostInfoRows || myHostInfoRows.length === 0">
  <div class="text-md mb-3">{{ 'rmm-side-panel:loadingDataTitle' | i18next}}</div>
  <div class="text-md mb-3">{{ 'rmm-side-panel:loadingDataDescription' | i18next }}</div>
  <mbs-button (click)="refreshHostInfo()"
              [type]="'primary'"
              [icon]="'ico ico-Refresh mr-2'"
              [size]="'md'"
              [disabled]="loading"
              [isCtrl]="true">
    {{ 'rmm-side-panel:refreshData' | i18next: { format: 'title' } }}
  </mbs-button>
</ng-container>
