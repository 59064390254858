import { Injectable } from '@angular/core';
import { BuildType } from '@models/BuildType.enum';
import { QueryFiltersParams } from '@models/Paging';
import { ChangeCompanyInfo, CreateUserDto, DeleteUserMode, UpdateUserDto, User, UserComputerData } from '@models/user';
import { Store } from '@ngrx/store';
import { UsersService } from '@services/users/users.service';
import { DeleteUsersGroupActionParamsType, EnableUsersGroupActionParamsType } from '@services/users/users.types';
import { PagingStoreFacade } from '@store/pagingStoreFactory/pagingStore.facade';
import { UsersStore } from '@store/users/usersStore';
import { CustomUserStoreProperties } from '@store/users/usersStore.types';
import { Observable, tap } from 'rxjs';
import { CompaniesFacade } from './companies.facade';
import { ComputersFacade } from './computers.facade';

@Injectable({
  providedIn: 'root'
})
export class UsersFacade extends PagingStoreFacade<User, CustomUserStoreProperties> {
  public getADRequests$ = this.store.select(this.usersStore.selectors.selectADRequests);

  constructor(
    public store: Store,
    public usersStore: UsersStore,
    private service: UsersService,
    private companiesFacade: CompaniesFacade,
    private computersFacade: ComputersFacade
  ) {
    super(store, usersStore);
  }

  public loadADRequests(): void {
    this.store.dispatch(this.usersStore.actions.loadADRequests());
  }

  public create(params: CreateUserDto): Observable<User> {
    return this.service.create(params).pipe(tap(() => this.invalidateEntities(true, false)));
  }

  public updateUser(params: UpdateUserDto, id: string): Observable<User> {
    return this.service.updateUser(params, id).pipe(tap((user) => this.invalidateEntities(true, true, user)));
  }

  public enable(user: User, enabled: boolean): Observable<User> {
    return this.service.enable(user, enabled).pipe(tap((user) => this.invalidateEntities(false, false, user)));
  }

  public enableUsers(params: EnableUsersGroupActionParamsType): Observable<null> {
    return this.service.enableUsers(params).pipe(tap(() => this.invalidateEntities(false, false)));
  }

  public delete(userId: string, deleteUserMode: DeleteUserMode, computers?: UserComputerData[]): Observable<null> {
    return this.service.deleteUser(userId, deleteUserMode, computers).pipe(tap(() => this.invalidateEntities(true, true)));
  }

  public deleteUsers(params: DeleteUsersGroupActionParamsType): Observable<null> {
    return this.service.deleteUsers(params).pipe(tap(() => this.invalidateEntities(true, true)));
  }

  public cancelDeleting(userId: string): Observable<null> {
    return this.service.cancelDeleting(userId);
  }

  public resetPassword(
    userId: string,
    params: { notificationEmails: string[]; signUpNotificationBuildList: BuildType[] }
  ): Observable<null> {
    return this.service.resetPassword(userId, params);
  }

  public getChangeCompanyInfo(userId: string): Observable<ChangeCompanyInfo> {
    return this.service.changeCompanyInfo(userId);
  }

  public export(body: { users?: string[]; filter?: QueryFiltersParams }): Observable<string> {
    return this.service.export(body);
  }

  public updateCompany(userId: string, params: { companyId: string; leaveLicenseInLastCompany?: boolean }): Observable<User> {
    return this.service.updateCompany(userId, params).pipe(tap((user) => this.invalidateEntities(true, true, user)));
  }

  private invalidateEntities(invalidateCompanies: boolean, invalidateComputers: boolean, user?: User): void {
    this.refreshAfterCRUD(user);
    invalidateCompanies && this.invalidateCompanies();
    invalidateComputers && this.invalidateComputers();
  }

  private invalidateCompanies(): void {
    this.companiesFacade.invalidate();
  }

  private invalidateComputers(): void {
    this.computersFacade.invalidate();
  }
}
