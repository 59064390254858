<mbs-modal [title]="'rmm-side-panel:two-factor-modal.title' | i18next"
           [isCustomModal]="true"
           [showFooter]="('2FAEnabled' | can: 'read')"
           (keyup.enter)="handleEnter($event)"
           [showCloseCross]="canClose">

  <ng-container modal-body>

    <form *ngIf="('2FAEnabled' | can: 'read')"
          (submit)="sendCode()">

      <p>The <span class="nowrap">two-factor</span> authentication is&nbsp;required for using Remote Management.<br />
        Please
        enter code
        and
        click Verify.</p>

      <div class="row">
        <div class="col">
          <div class="mbs-form-group">
            <mbs-input [formControl]="twoFactorCode"
                       autocomplete="off"
                       name="two-fa-code"
                       mbsAutoFocus
                       [placeholder]="'rmm-side-panel:two-factor-modal.placeholder' | i18next"></mbs-input>
          </div>
        </div>
      </div>

    </form>

    <p *ngIf="!('2FAEnabled' | can: 'read')">
      To&nbsp;use Remote Management, the <span class="nowrap">two-factor</span> authentication must
      be&nbsp;<a
         [routerLink]="['/AP/Settings']">enabled.</a>
    </p>

  </ng-container>

  <ng-container modalFooter>

    <mbs-button type="primary"
                [loading]="loading"
                (click)="sendCode()">
      {{ 'rmm-side-panel:two-factor-modal.confirmBtn' | i18next }}
    </mbs-button>

    <mbs-button *ngIf="canClose"
                (click)="modal.close()"
                data-dismiss="modal"
                type="secondary">
      {{ 'rmm-side-panel:two-factor-modal.cancelBtn' | i18next }}
    </mbs-button>

  </ng-container>

</mbs-modal>
