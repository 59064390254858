import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GroupTask } from '@modules/group-tasks/store/model';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { GAActions, GASelectors } from '@modules/schedule-group-action/store/group-action';
import { GroupActionsCommands, WindowsAvailableActions } from '@modules/schedule-group-action/store/group-action/group-action.model';
import EditGroupTaskUtility from '@modules/schedule-group-action/utility/edit-group-task-utility';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import RmmLastStatData from '@shared/models/rmm/RmmLastStatData';
import SoftwareInfo from '@shared/models/rmm/SoftwareInfo';
import Computer, { AgentType } from '@shared/models/Computer';
import { RmmService, RmmSoftwareService, TFARMMPermissionHelperService } from '@shared/services';
import { getReversedTableSorting } from '@shared/utils/helpers/rmm/table-reversed-sorting';
import { InMemorySorter } from '@shared/utils/inMemorySorter';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { SortEvent, TableHeader } from 'mbs-ui-kit';
import { combineLatest, filter, first, map, Observable, of, pluck, switchMap, take, throwError, withLatestFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-install-winget-action',
  templateUrl: './install-winget.component.html'
})
export class InstallWinGetComponent implements OnInit {
  @Input() enableTitle = true;

  public wingetForm = new FormGroup({
    value: new FormControl('7zip'),
    filter: new FormControl('')
  });

  public minAgentVersion = 200;
  public userPreviousInputValue = '';
  public bindDisabledValues = { key: 'found', value: false };
  public showTable = false;
  public showError = false;
  public isLoading = false;
  public disableValidateSoftwareBtn = true;
  public data = [];
  public dataToShow = [];
  private hid: string;
  public selectedItems = [];
  public orderBy: SortEvent = { column: 'name', direction: '' };
  public headers: TableHeader[] = [
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderName'),
      sort: 'name',
      isGridColumn: false,
      overflow: true,
      gridColSize: '10fr',
      gridColMin: '80px'
    },
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableLatestVersion'),
      sort: 'version',
      isGridColumn: false,
      gridColSize: '10fr',
      gridColMin: '80px'
    },
    {
      name: '',
      sort: 'customTooltip',
      isGridColumn: false,
      gridColSize: '5fr',
      gridColMin: '40px',
      class: '-end'
    }
  ];

  constructor(
    private store: Store,
    private softwareService: RmmSoftwareService,
    private commandService: CommandService,
    private editGroupTaskUtility: EditGroupTaskUtility,
    private rmmService: RmmService,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService,
    private i18n: I18NextPipe
  ) {
    // reset hid on applyToValueChange
    this.store
      .select(GASelectors.selectGActionApplyTo)
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.hid = null;
      });

    this.store
      .select(GASelectors.selectGActionSelectedType)
      .pipe(
        filter((value) => value === WindowsAvailableActions.InstallAndUpdate),
        untilDestroyed(this)
      )
      .subscribe((value) => this.wingetForm.get('value').setValue(''));

    this.wingetForm
      .get('value')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.disableValidateSoftwareBtn = !value;

        if (!this.showTable) this.clearStoreParameters();
        // Group Task creation were disabled for simple input mode. Commented code if that would be changed.
        // value ? this.setStoreParameters(this.valueToStringUtility(value)) : this.clearStoreParameters();
      });

    this.wingetForm
      .get('filter')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.dataToShow = this.data.filter((item) => [item.id, item.name, item.publisher].join(' ').includes(value));
      });
  }

  ngOnInit(): void {
    this.loadGroupTaskData();
  }

  private loadGroupTaskData(): void {
    this.getScriptBodyFromGroupAction()
      .pipe(
        switchMap((scriptBody) => (scriptBody ? of(scriptBody) : this.editGroupTaskUtility.getTerminalScriptStream())),
        take(1)
      )
      .subscribe((scriptBody) => {
        this.wingetForm.get('value').setValue(scriptBody ?? this.wingetForm.get('value').value);
      });
  }

  private getScriptBodyFromGroupAction(): Observable<any> {
    return this.store.select(GASelectors.selectGActionParameters).pipe(
      withLatestFrom(this.editGroupTaskUtility.getGroupTask()),
      filter(([parameters, groupTaskToEdit]) =>
        [
          this.editGroupTaskUtility.parseCommandTypeFromGroupTask(parameters),
          this.editGroupTaskUtility.parseCommandTypeFromGroupTask(groupTaskToEdit)
        ].includes(GroupActionsCommands.WIN_GET)
      ),
      map(([parameters, groupTaskToEdit]) =>
        this.editGroupTaskUtility.parseCommandTypeFromGroupTask(parameters) === GroupActionsCommands.WIN_GET ? parameters : groupTaskToEdit
      ),
      first(),
      map((groupTask) => (groupTask?.parameters ? JSON.parse(groupTask?.parameters) : null)),
      pluck('parameters', '0', 'value')
    );
  }

  handleSort({ column, direction }: SortEvent): void {
    this.orderBy = { column, direction };
    this.dataToShow = InMemorySorter.sort(getReversedTableSorting(this.orderBy), this.dataToShow);
  }

  setStoreParameters(value: string) {
    this.store.dispatch(GAActions.setGActionParameters({ parameters: this.getGroupActionParams(value) }));
  }

  clearStoreParameters() {
    this.store.dispatch(GAActions.setGActionParameters({ parameters: null }));
  }

  selected(event: any) {
    this.selectedItems = event.filter((item) => item.found);

    if (this.selectedItems.length) {
      this.setStoreParameters(this.selectedItems.map((item) => item.id).join(';'));
    } else {
      this.clearStoreParameters();
    }
  }

  switchToList() {
    this.showTable = false;
    this.wingetForm.get('value').setValue(this.userPreviousInputValue);
  }

  searchSoftwareUsingWinget() {
    this.showError = false;
    this.disableValidateSoftwareBtn = true;
    this.userPreviousInputValue = this.wingetForm.get('value').value;

    const stream$ = this.hid ? this.sofwareUpdateSearchStream() : this.getSwoftwareUpdateSearchStreamUsingApplyToSelection();

    stream$.pipe(take(1)).subscribe({
      next: (res) => {
        if (!res) {
          this.onErrorHandler();

          return;
        }

        this.disableValidateSoftwareBtn = false;
        this.data = cloneDeep(res?.Data?.data) ?? [];
        this.dataToShow = this.data.map((item) => ({ ...item, name: item.name ?? item.searchText }));

        this.handleSort({ ...this.orderBy });

        this.isLoading = false;

        if (this.selectedItems.length) {
          this.selectedItems = this.selectedItems.filter((selected) => this.data.some((item) => item.id === selected.id));
          this.setStoreParameters(this.selectedItems.map((item) => item.id).join(';'));

          return;
        }

        this.clearStoreParameters();
      },
      error: (err) => {
        // err itself will be handled on error service
        this.onErrorHandler();
      }
    });
  }

  private getSwoftwareUpdateSearchStreamUsingApplyToSelection() {
    let computers: Computer[] = [];
    return this.editGroupTaskUtility.getComputerStreamPerApplyToStepExpectation().pipe(
      switchMap((data) => {
        computers = data.filter(computer => Computer.IsSupportedAgentVersion(computer, AgentType.RMM, this.minAgentVersion));
        if (!computers.length) return of(null);

        // gathering computer hids and looking for winget package using getHidIfWingetFoundInSoftware with data stored in DB
        const streams$ = this.getComputerStreamsAndCheckIfWingetAvailable(computers);

        return combineLatest(streams$);
      }),
      switchMap(() => {
        if (this.hid || !computers.length) return of(null);

        // gathering computer hids and looking for winget package using getHidIfWingetFoundInSoftware with in fresh Data
        return combineLatest(this.getComputerStreamsAndCheckIfWingetAvailable(computers, true));
      }),
      // no need in result since we put hid into the common variable
      switchMap(() => {
        if (this.hid) return this.sofwareUpdateSearchStream();
        return of(null);
      }),
      untilDestroyed(this)
    );
  }

  private getComputerStreamsAndCheckIfWingetAvailable(data: Computer[], getFromAgentService = false) {
    return data.map((comp) => {
      return of(true).pipe(
        switchMap((value) => {
          if (this.hid) return of(this.hid);

          return this.getHidIfWingetFoundInSoftware(comp.hid, getFromAgentService);
        })
      );
    });
  }

  private onErrorHandler() {
    this.disableValidateSoftwareBtn = false;
    this.clearStoreParameters();
    this.data = [];
    this.showError = true;
    this.isLoading = false;
  }

  private getHidIfWingetFoundInSoftware(hid: string, getFromAgentService = false): Observable<string> {
    const wingetPackageSource = 'winget';
    if (this.hid) return of(this.hid);

    const fetchSofwareStream$ = getFromAgentService
      ? this.rmmService.fetchLastData<RmmLastStatData<SoftwareInfo>>('software', this.hid)
      : this.rmmService.fetchSpecSoftwareDetails(hid);

    return fetchSofwareStream$.pipe(
      pluck('data'),
      switchMap((softList) => {
        const isValidHid = softList.some((soft) => soft.packageSource === wingetPackageSource);
        this.hid = isValidHid ? hid : this.hid;

        return of(this.hid);
      })
    );
  }

  getGroupActionParams(data: string): Partial<GroupTask> {
    const parameters = [
      { name: 'COMMANDDATA', value: data ?? '' },
      { name: 'TIMEOUT', value: `${1200}` }
    ];

    return {
      disabled: false,
      type: 'pluginCommand',
      pluginCommandId: 'SoftwareCmd',
      parameters: JSON.stringify({ asyncID: getGuid(), id: 'InstallSoftwareUsingWinget', parameters: parameters }),
      enableOffline: false,
      confidentialOutputData: false,
      pluginActiveCommand: true,
      pluginPsCommand: true,
      description: this.getDescription(this.selectedItems),
      eventType: 0,
      scriptGuid: null,
      softwareSourceGuid: null,
      allComputers: false,
      computers: [],
      computerTags: [],
      runType: 0,
      scheduleType: 0,
      scheduleData: null
    };
  }

  getDescription(data: any[]): string {
    const translationKey = 'rmm.module:groupActions.actionDescription.';
    return (
      this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'wingetCommandInfo', {}) +
      `\r\n${this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'software', {})} ` +
      (data.map((item) => item.name ?? item.id).join('; ') ?? '')
    );
  }

  sofwareUpdateSearchStream() {
    this.isLoading = true;
    this.showTable = true;

    const softListInString = this.valueToStringUtility(this.wingetForm.get('value').value);

    return this.softwareService.searchSoftwareUsingWinget(this.hid, softListInString).pipe(
      switchMap((response: any) => {
        if (response?.error?.code) {
          return throwError(() => response);
        }

        return this.getMessageByAsyncId(response);
      }),
      map((data) => this.commandService.parseMessage(data)),
      untilDestroyed(this)
    );
  }

  getMessageByAsyncId(response: any) {
    if (response?.result) {
      const obj = JSON.parse(response?.result);
      const splitter = ' = ';
      const asyncId = obj.data.split(splitter)[1];

      return this.commandService.selectMessagesByAsyncId$(asyncId);
    }

    return this.commandService.messages$;
  }

  valueToStringUtility(value: string) {
    return value.replaceAll('\r\n', ';').replaceAll('\n', ';');
  }
}
