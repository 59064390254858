import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { ComputersFacade } from '@facades/computers.facade';
import Administrator from '@models/Administrator';
import Computer, { AgentType, OsType } from '@models/Computer';
import { NotificationPlanRule, OldNotificationPerPlanSettings } from '@models/Notification';
import { PlanMode, PlanTypes } from '@models/PlanTypes.enum';
import { OnboardingView } from '@modules/wizards/onboarding/onboarding.constants';
import { Direction, StepType } from '@modules/wizards/onboarding/services/steps/steps.types';
import { CommonView } from '@modules/wizards/onboarding/views/common-view';
import { StepId, StepsConfig } from '@modules/wizards/onboarding/views/computers/computers.constants';
import { getBuildsSelectionConfig, getConfigureBackupConfig } from '@modules/wizards/onboarding/views/computers/computers.helpers';
import { BackupDataType, NotificationsType } from '@modules/wizards/onboarding/views/computers/computers.types';
import { OnboardingMode } from '@modules/wizards/services/onboarding/onboarding.constants';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { InstallationView } from '@modules/wizards/steps/installation/installation.constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidepanelRouteType } from '@root/mbs-ui/src/app/shared/models/backup/sidepanel-route-type';
import { AuthService } from '@services/auth.service';
import { ComputersAspxAbstractService } from '@services/computers-legacy/computers-legacy.service';
import { transformDate } from '@utils/date';
import { I18NextPipe } from 'angular-i18next';
import { MaxFileSizeForBackend, StepCondition } from 'mbs-ui-kit';
import { mergeMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PlanDataFromStepsHelper } from '../../../helpers/plan-data-from-steps-helper/plan-data-from-steps-helper';
import { WhatBackupStepValue } from '../../../models/what-backup-models';
import { WhatBackupTreeStepValue } from '../../../models/what-backup-tree-model';
import { RemoteManagementWizardsService } from '../../../services/remote-management-wizards.service';
import { StepsService } from '../../services/steps/steps.service';

@UntilDestroy()
@Component({
  selector: 'mbs-onboarding-wizard-computers-view',
  templateUrl: './computers.component.html',
  styleUrls: ['../common-view.scss'],
  providers: [{ provide: StepsService }, { provide: 'steps', useValue: StepsConfig }]
})
export class ComputersViewComponent extends CommonView<StepId> implements OnInit, AfterContentChecked {
  readonly viewMode = OnboardingView.Computers;
  readonly installationView = InstallationView.Computers;
  readonly buildsSelectionConfig = getBuildsSelectionConfig(this.i18NPipe);
  readonly stepCondition = StepCondition;
  readonly stepId = StepId;
  readonly configureBackupConfig = getConfigureBackupConfig(this.i18NPipe);
  readonly direction = Direction;

  public provider: Administrator;
  public userId: string;
  public userEmail: string;
  public computer: Computer;
  public hid: string;
  public plan;
  public backupData: BackupDataType;
  public notificationsData: NotificationsType;
  public storageId: string;
  public isLinux: boolean;
  public backupVersionUpdated: string;

  @Input() mode: OnboardingMode;
  @Output() criticalError = new EventEmitter<boolean>();

  constructor(
    private rmwService: RemoteManagementWizardsService,
    public authService: AuthService,
    private computersLegacyService: ComputersAspxAbstractService,
    public onboardingService: OnboardingService,
    public stepsService: StepsService<StepId>,
    public i18NPipe: I18NextPipe,
    fb: FormBuilder,
    cdr: ChangeDetectorRef,
    private computersFacade: ComputersFacade,
    private backupFacade: ComputerBackupFacade
  ) {
    super(stepsService, authService, cdr, fb);
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public onDownloadBuildStepCompleted(computer: Computer): void {
    this.onboardingService
      .configureComputerByHid(computer.hid)
      .pipe(
        untilDestroyed(this),
        tap((userId) => {
          this.userId = userId;
        }),
        mergeMap(() => {
          return this.computersLegacyService.getInfo(computer);
        })
      )
      .subscribe((data) => {
        this.userEmail = data.Email;
        this.computer = computer;
        this.isLinux = computer.os !== OsType.windows;
        this.hid = computer.hid;
        const app = computer?.apps?.find((app) => app.applicationId === AgentType.Backup);
        this.backupVersionUpdated = app?.version.replaceAll('.', '');

        this.stepsService.transition(this.currentStep.next);
      });
  }

  public onPlanTypeChanged(type: PlanTypes): void {
    const settings = this.configureGetPlanSettings(type);
    this.backupFacade
      .getLegacyPlan(settings)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (planWrapper) => {
          this.plan = planWrapper;
        },
        error: () => this.handleError()
      });
  }

  public isBackButtonShown(): boolean {
    const currentStep = this.stepsService.currentStep.getValue();

    return currentStep.prev !== null && !this.stepsService.getStepById(currentStep.prev).isAutomatic;
  }

  public onBackupDataChanged(data: BackupDataType): void {
    this.backupData = data;
  }

  public onStorageIdUpdate(id: string): void {
    this.storageId = id;
  }

  public get parentSteps(): { name: string; condition: StepCondition }[] {
    return this.stepsService.parentSteps.getValue();
  }

  public get currentStep(): StepType<StepId> {
    return this.stepsService.currentStep.getValue();
  }

  public onTabClick(id: StepId): void {
    this.stepsService.transition(id);
  }

  public onNotificationsDataChanged(data: NotificationsType): void {
    this.notificationsData = data;
  }

  public handleError() {
    this.onboardingService.hasError$.next(true);
  }

  public isValid(): boolean {
    if (!this.backupData?.type) return false;

    return this.backupData?.type === PlanTypes.Plan
      ? !!(this.backupData?.data as WhatBackupTreeStepValue)?.includeFolders?.length
      : !!(this.backupData?.data as WhatBackupStepValue)?.partitions?.length;
  }

  public runBackup(): void {
    this.onboardingService.stable$.next(false);
    const newPlan = this.preparePlanData(this.plan);

    this.backupFacade
      .saveLegacyPlan(newPlan, this.configurePostPlanSettings(this.backupData.type))
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          return this.rmwService.updateOverdueAlert(this.plan.OverdueAfterSuccess, newPlan.Name, {
            email: this.userEmail,
            planId: newPlan.ID,
            userId: this.userId,
            hid: this.hid,
            compName: this.computer.name
          });
        }),
        mergeMap(() => {
          const notificationSettings = this.prepareNotificationSettings(this.notificationsData, newPlan);
          return this.rmwService.SavePlanNotification(notificationSettings, this.hid);
        }),
        mergeMap(() => {
          return this.onboardingService.complete();
        })
      )
      .subscribe({
        next: () => this.computersFacade.pollingComputersAndOpenSidePanel(SidepanelRouteType.Backup, this.computer.hid),
        error: () => this.handleError()
      });
  }

  public initialize(): void {
    this.authService.currentUser.pipe(untilDestroyed(this)).subscribe((provider) => (this.provider = provider));

    this.onboardingService.hasError$.pipe(untilDestroyed(this)).subscribe((hasError) => {
      hasError && this.criticalError.emit(true);
    });
  }

  private configureGetPlanSettings(type: PlanTypes) {
    return {
      mode: PlanMode.create,
      type,
      email: this.userEmail,
      planId: '',
      userId: this.userId,
      hid: this.computer.hid,
      profile: ''
    };
  }

  private configurePostPlanSettings(type: PlanTypes) {
    return {
      mode: PlanMode.create,
      type,
      email: this.userEmail,
      userId: this.userId,
      hid: this.computer.hid,
      profile: '',
      computerName: this.computer.name,
      fromGettingStarted: true
    };
  }

  private preparePlanData(planWrapper) {
    const { plan } = planWrapper;

    plan.ConnectionID = planWrapper?.Storages?.CloudStorages?.[0]?.ID;

    plan.Name = this.i18NPipe.transform(
      `onboarding:${this.backupData.type === PlanTypes.Plan ? 'filesBackupPlanName' : 'ibbBackupPlanName'}`,
      {
        computerName: this.computer.name,
        date: transformDate(new Date().getTime() / 1000, 'medium')
      }
    );

    if (plan.MaxFileSize) {
      plan.MaxFileSize = MaxFileSizeForBackend;
    }

    if (plan?.Schedule?.StopAfterTicks) {
      plan.Schedule.StopAfterTicks = MaxFileSizeForBackend;
    }

    if (plan?.ForceFullSchedule?.StopAfterTicks) {
      plan.ForceFullSchedule.StopAfterTicks = MaxFileSizeForBackend;
    }

    if (this.backupData.type === PlanTypes.Plan) {
      PlanDataFromStepsHelper.updateNewPlanByWhatBackupTreeStep(plan, this.backupData.data as WhatBackupTreeStepValue);
    } else {
      PlanDataFromStepsHelper.updateNewPlanByWhatBackupStep(plan, this.backupData.data as WhatBackupStepValue);
    }

    if (!this.isLinux) {
      plan.IsArchive = true;
      if (this.backupData.type === PlanTypes.Plan) plan.SyntheticFull = true;
    }

    return plan;
  }

  private prepareNotificationSettings(notificationsData: NotificationsType, plan): OldNotificationPerPlanSettings {
    const settings = new OldNotificationPerPlanSettings();
    const { Email, SendNotification, OnlyOnFailure } = notificationsData;

    ['Failure', 'Success', 'Warning'].forEach((status) => {
      const needToSkipNotification = !SendNotification || (status === 'Success' && OnlyOnFailure);
      const email = needToSkipNotification ? null : Email;
      const rule = needToSkipNotification ? NotificationPlanRule.None : NotificationPlanRule.SpecifiedEmails;

      settings[status].Emails = [email];
      settings[status].Rule = rule;
    });

    return {
      ...settings,
      ...{
        UserId: this.userId,
        PlanId: plan.ID,
        Enabled: true
      }
    };
  }
}
