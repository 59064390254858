<div class="mbs-form-group">
  <ng-container *ngIf="showLabel">
    <label class="mbs-form_label -accented -sm"
           for="{{id + 'input'}}">
      Add {{entityName | titlecase }}
    </label>
  </ng-container>
  <div class="row no-gutters">

    <div class="col-9">
      <mbs-input placeholder="Enter {{entityName | lowercase }} here"
                 [type]="inputType"
                 [formControl]="currentValue"
                 (keyup.enter)="handleAddValue()"
                 [id]="id + 'input'"
                 [clearButton]="true"
                 (clear)="handleReset()">
      </mbs-input>
    </div>
    <div class="col-3 pl-2">
      <mbs-button [block]="true"
                  [disabled]="checkValid()"
                  (click)="handleAddValue()">Add
      </mbs-button>
    </div>
  </div>
</div>
<div class="mbs-form-group"
     *ngIf="copyData">
  <div class="mbs-form-dropdown_data"
       [ngClass]="{'-filled' : copyData.size > 0}">
    <div class="mbs-form-dropdown_data-item"
         *ngFor="let item of copyData">
      <div class="row">
        <div class="col-10 text-overflow">
          {{ item }}
        </div>
        <div class="col-2 text-right">
          <button class="ctrl ctrl-danger ctrl-sm"
                  (click)="handleDeleteValue(item)">
            <span class="ico ico-Close"
                  title="Delete"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
