<div class="mbs-number"
     [ngClass]="[size ? '-' + size : '']">
  <mbs-input-label *ngIf="label && id"
                   [label]="label"
                   [id]="id"
                   [labelSize]="labelSize"
                   [disabled]="disabledState"
                   [boldLabel]="boldLabel"
                   [info]="info"
                   [infoPlacement]="infoPlacement"
                   [infoTriggers]="infoTriggers"
                   [infoContainer]="infoContainer"
                   [required]="hasRequiredValidator && showRequiredMark"
                   [labelClasses]="labelClasses"
                   [labelContentClasses]="labelContentClasses"></mbs-input-label>
  <div [class]="calculatedContainerClasses">
    <input [id]="id"
           class="form-control mbs-number-input"
           [placeholder]="placeholder"
           [disabled]="disabledState"
           [tabindex]="tabindex"
           [attr.aria-describedby]="id"
           [attr.aria-labelledby]="label"
           autocomplete="off"
           [ngClass]="bindClasses"
           (blur)="numberHandleBlur($event)"
           (focus)="handleFocus($event)"
           [imask]="imaskOpts"
           (keydown.ArrowDown)="$event.preventDefault(); changeValueHandler(false)"
           (keydown.ArrowUp)="$event.preventDefault(); changeValueHandler()"
           (change)="numberChangeHandler($event)"
           (input)="handleInput($event)"
           [unmask]="true"
           (accept)="handleAccept($event)">
    <mbs-input-pre-append *ngIf="showButtons"
                          class="mbs-number-buttons"
                          position="append"
                          [disabled]="disabledState">
      <ng-template>
        <mbs-button-group [vertical]="true">
          <mbs-button class="mbs-number-btn -up"
                      [attr.data-test-element]="elementSelectors.id.btnUp"
                      [size]="[size ? size : '']"
                      [disabled]="disabledState || value >= max"
                      icon="ico ico-PlusThin mr-0"
                      (click)="changeValueHandler()">
          </mbs-button>
          <mbs-button class="mbs-number-btn -down"
                      [attr.data-test-element]="elementSelectors.id.btnDown"
                      [size]="[size ? size : '']"
                      [disabled]="disabledState || value <= min"
                      icon="ico ico-MinusThin mr-0"
                      (click)="changeValueHandler(false)">
          </mbs-button>
        </mbs-button-group>
      </ng-template>
    </mbs-input-pre-append>
  </div>
  <mbs-input-errors [errors]="errors"
                    [errorsTemplate]="errorsTemplate?.templateRef"></mbs-input-errors>
</div>
