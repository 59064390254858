import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { ProblemDetailsObject } from '@models/ProblemDetailsObject';
import { ErrorHandlerService } from '@services/error-handler.service';
import { MANUAL_ERROR_HANDLED, URL_ERROR_TYPE } from '@shared/interceptors/error-handler.interceptor';
import { ErrorCode } from '@utils/errorCodes';
import { getLastUrlSegment } from '@utils/get-url-segment.helper';

const subscriptionError = 'Subscription required to activate license';
const licenseCreateError = 'License can not be create';

const excludedRequests = [
  {
    url: '/api/v1/Licenses/usage/assign',
    error: subscriptionError
  },
  {
    url: '/api/v1/Licenses/usage/set-auto-renewal',
    error: subscriptionError
  },
  {
    url: 'api/brands/licenses/',
    error: subscriptionError
  },
  {
    url: 'api/administrators/',
    error: subscriptionError
  },
  {
    url: 'api/administrators/',
    error: licenseCreateError
  },
  {
    url: 'api/brands/licenses',
    error: licenseCreateError
  }
];

const excludeRemoteCommandErrorCodes = [
  ErrorCode.AnErrorOccured,
  ErrorCode.DeepSyncIsRequired,
  ErrorCode.InvalidDecryptionPassword,
  ErrorCode.InvalidBitlockerPassword,
  ErrorCode.UnknownError
];

const excludeErrorCodes = [
  ErrorCode.NoLicences,
  ErrorCode.LicenseLimitExceeded,
  ErrorCode.ObjectNotFound,
  ErrorCode.ExternalServiceRequestError,
  ErrorCode.GroupTaskNotFound
];

const excludedRoutes = [RoutingPath.ApGettingStart, RoutingPath.ApAppsGeneral];

@Injectable({ providedIn: 'root' })
export class ThrowErrorDirectlyHelper {
  constructor(private errorService: ErrorHandlerService) {}

  isThrowErrorDirectly(req: HttpRequest<any>, error: HttpErrorResponse): boolean {
    const errorResponse = error.error;

    return (
      this.isExcludedRoutes() ||
      this.isExcludeErrorCodes(errorResponse) ||
      this.isExcludeErrorType(errorResponse) ||
      this.isExcludeRemoteCommandErrorCodes(errorResponse, error) ||
      this.isExcludedRequest(req, error) ||
      this.isRegularError(req, error)
    );
  }

  private isExcludedRoutes(): boolean {
    return excludedRoutes.includes(<RoutingPath>location.pathname);
  }

  private isExcludeErrorCodes(error: any): boolean {
    return error?.code && excludeErrorCodes.includes(error?.code);
  }

  private isExcludeErrorType(error: ProblemDetailsObject): boolean {
    return String(error?.type).includes(URL_ERROR_TYPE) && excludeErrorCodes.includes(+getLastUrlSegment(error?.type));
  }

  private isExcludeRemoteCommandErrorCodes(errorResponse: any, error: HttpErrorResponse): boolean {
    return excludeRemoteCommandErrorCodes.includes(+errorResponse?.errorCode) && this.errorService.isRemoteCommand(error);
  }

  private isExcludedRequest(req: HttpRequest<any>, error: HttpErrorResponse): boolean {
    if (this.isErrorIncludedText(error, subscriptionError) || this.isErrorIncludedText(error, licenseCreateError)) {
      this.errorService.showSubscriptionErrorToast();
    }

    return !!excludedRequests.find(
      (excludeUrl) => error?.error && req.url.includes(excludeUrl.url) && this.isErrorIncludedText(error, excludeUrl.error)
    );
  }

  private isErrorIncludedText(error: HttpErrorResponse, errorText: string): boolean {
    return error.error?.title?.includes(errorText) || error.error?.detail?.includes(errorText);
  }

  private isRegularError(req: HttpRequest<any>, error: HttpErrorResponse): boolean {
    return error.status === 401 || error.status === 420 || req.headers.has(MANUAL_ERROR_HANDLED); // skip 401 or rethrow for prevent show toast
  }
}
