import { get } from 'lodash/fp'

export class ShellHistory {
  /**
   * @summary history entry's
   */
  private stack: Set<string> = new Set();
  /**
   * history state
   */
  private _isActive = false;
  /**
   * @summary cached history array
   */
  private _cache: Array<string>;
  /**
   * @summary current history position for restore in prompt
   */
  private _position = 0;
  /**
   * history position getter
   */
  public get position() {
    return this._position;
  }
  /**
   * history active state getter
   */
  public get active() {
    return this._isActive;
  }
  /**
   * @summary return size of history stack
   */
  public get lastIndex() {
    return this.stack.size - 1;
  }

  constructor(depth?: number) {
    this.initParams();
    this._position = this.stack.size;
  }
  /*
   * change position to next, or 0
   * @returns next history entry
   */
  public next() {
    if (this._isActive) {
      const result = this.get(this._position);
      const pos = this._position >= this.lastIndex ? 0 : this._position + 1;

      this._position = pos;
      return result;
    }
    return '';
  }

  /*
   * change position to prev, or last stack index
   * @returns prev history entry
   */
  public prev() {
    if (this._isActive) {
      const result = this.get(this._position);
      const pos = this._position <= 0 ? this.lastIndex : this._position - 1;

      this._position = pos;
      return result;
    }
    return '';
  }

  /*
   * activate history counter
   * @returns
   */
  public start() {
    this._isActive = true;
    return true;
  }

  /*
   * stop history counter
   * @returns
   */
  public stop() {
    this._position = this.lastIndex;
    this._isActive = false;
    return false;
  }
  /**
   * alias for init params, restore default
   */
  public clear() {
    this.initParams();
  }
  /*
   * return history entry by key
   * @param position
   * @returns history entry
   */
  public get(position: number): string {
    return get(position, this._cache);
  }
  /*
   * set history entry to top stack.
   * remap stack and keys
   * @param value
   */
  public set(value: string) {
    if (this.stack.has(value)) {
      this.stack.delete(value);
    }
    this.stack.add(value);

    this._position = this.lastIndex;
    this._cache = Array.from(this.stack.values());
  }

  public last(): string {
    return this.get(this.lastIndex);
  }

  /*
   * Set history params
   * @param depth
   */
  private initParams(): void {
    this.stack.clear();
    this.stack.add('');
  }
}
