<mbs-sidepanel id="computer_rmm_info"
               side="right"
               [large]="('MBS' | can:'read')"
               [innerContentType]="('MBS' | can:'read') ? 'tabs' : 'form'"
               showDelete="false"
               (panelClosed)="handlePanelClose()"
               hideSave="true"
               showFooter="true"
               [showExpandedCross]="false"
               [headerClass]="'-light'"
               [loadingData]="loadingData">
  <div header-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-ico"
         [ngClass]="isOnline ? 'text-primary' : 'text-muted'">
      <span class="{{ computer | computerOsIcon }}"></span>
    </div>
    <div class="mbs-sidepanel_title-name">
      {{computerName}} <small>({{onlineStatus}})</small>
    </div>
  </div>
  <div loading-data-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-ico">
      <span class="fa fa-windows"></span>
    </div>
    <div class="mbs-sidepanel_title-name">
      {{ 'app:common.loadingComputerInfo' | i18next }}
    </div>
  </div>


  <ng-container body>
    <mbs-alert *ngIf="rmmWebsocket.failLastConnect"
               [type]="alertType.warning"
               [icon]="true"
               class="mb-0">
      {{ 'rmm-side-panel:sidePanelTexts.connectionLostP1' | i18next }}
      <mbs-button [isCtrl]="true"
                  (click)="rmmWebsocket.init()">
        {{ 'buttons:retry' | i18next }}
      </mbs-button>
      {{ 'rmm-side-panel:sidePanelTexts.connectionLostP2' | i18next }}
    </mbs-alert>

    <div class="mbs-sidepanel_tabset -vertical">
      <div class="d-flex h-100 flex-column justify-content-between">
        <ul mbsTabset
            mbsTabSetValidated
            (beforeUpdate)="handleBeforeTabChange($event)"
            mode="vertical"
            #activeContent="mbsTabset">
          <li mbsTabsetItem="general"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.general' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <div *ngIf="showJson; else elseBlock">
                <mbs-json-viewer-tab statType="host"
                                     [hid]="hid">
                </mbs-json-viewer-tab>
              </div>
              <ng-template #elseBlock>
                <mbs-sidepanel-rmm-general-tab [hid]="hid"
                                               [isOnline]="isOnlineAgent"
                                               [(isModalContent)]="isModalContent"
                                               [tabset]="tabset"
                                               [loading]="loadingGeneral">
                </mbs-sidepanel-rmm-general-tab>
              </ng-template>
            </ng-template>
          </li>

          <li mbsTabsetItem="pc-info"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.computerInfo' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <div *ngIf="showJson; else elseBlock">
                <mbs-json-viewer-tab statType="host"
                                     [hid]="hid">
                </mbs-json-viewer-tab>
              </div>
              <ng-template #elseBlock>
                <mbs-sidepanel-rmm-pc-info-tab [hid]="hid"
                                               [hostInfo]="hostInfo$ | async"
                                               [isOnline]="isOnlineAgent"
                                               [lastUpdated]="lastUpdated$ | async"
                                               [loading]="loadingGeneral">
                </mbs-sidepanel-rmm-pc-info-tab>
              </ng-template>
            </ng-template>
          </li>

          <li mbsTabsetItem="processes"
              [mbsTabSetValid]="false">
            <button mbsTabsetGroupTrigger="processes"
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.taskManager' | i18next }}
            </button>
            <ul mbsTabsetGroup="processes">
              <li *ngIf="isOnlineAgent"
                  mbsTabsetItem="processesCharts">
                <button mbsTabsetLink
                        [disabled]="noRmmAgentFound">
                  {{ 'rmm-side-panel:sidePanelTabsName.performance' | i18next }}
                </button>
                <ng-template mbsTabsetContent>
                  <mbs-processes-charts-tab [hid]="hid"
                                            [isOnline]="isOnlineAgent"
                                            *ngFor="let hid of [hid]; trackBy:trackByFn">
                  </mbs-processes-charts-tab>
                </ng-template>
              </li>
              <li mbsTabsetItem="processesGrid">
                <button mbsTabsetLink
                        [disabled]="noRmmAgentFound">
                  {{ 'rmm-side-panel:sidePanelTabsName.processes' | i18next }}
                </button>
                <ng-template mbsTabsetContent>
                  <div *ngIf="showJson; else elseBlock">
                    <mbs-json-viewer-tab statType="runtime"
                                         [hid]="hid">
                    </mbs-json-viewer-tab>
                  </div>
                  <ng-template #elseBlock>
                    <mbs-runtime-tab [hid]="hid"
                                     [readOnly]="readonly"
                                     [agentOptions]="agentOptions"
                                     [isOnline]="isOnlineAgent"
                                     [computerName]="computerName"
                                     *ngFor="let hid of [hid]; trackBy:trackByFn">
                    </mbs-runtime-tab>
                  </ng-template>
                </ng-template>
              </li>

              <li mbsTabsetItem="services"
                  [mbsTabSetValid]="false">
                <button mbsTabsetLink
                        [disabled]="noRmmAgentFound">
                  {{ ('rmm-side-panel:sidePanelTabsName.' + (isMacOS || isLinuxOS ? 'daemons' : 'services')) | i18next
                  }}
                </button>
                <ng-template mbsTabsetContent>
                  <div *ngIf="showJson; else elseBlock">
                    <mbs-json-viewer-tab statType="service"
                                         [hid]="hid">
                    </mbs-json-viewer-tab>
                  </div>
                  <ng-template #elseBlock>
                    <mbs-services-tab [hid]="hid"
                                      [isOnline]="isOnlineAgent"
                                      [readonly]="readonly"
                                      [computerName]="computerName"
                                      *ngFor="let hid of [hid]; trackBy:trackByFn">
                    </mbs-services-tab>
                  </ng-template>
                </ng-template>
              </li>
            </ul>

          </li>

          <li mbsTabsetItem="software"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.installedSoftware' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <div *ngIf="showJson; else elseBlock">
                <mbs-json-viewer-tab statType="software"
                                     [hid]="hid">
                </mbs-json-viewer-tab>
              </div>
              <ng-template #elseBlock>
                <mbs-software-tab [hid]="hid"
                                  [readonly]="readonly"
                                  [agentOptions]="agentOptions"
                                  [isOnline]="isOnlineAgent"
                                  [agentVersion]="agentVersion$ | async"
                                  [computerName]="computerName"
                                  *ngFor="let hid of [hid]; trackBy:trackByFn">
                </mbs-software-tab>
              </ng-template>
            </ng-template>
          </li>

          <li mbsTabsetItem="patch">
            <button mbsTabsetGroupTrigger="patchGroup"
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.patchManagement' | i18next }}
            </button>
            <ul mbsTabsetGroup="patchGroup">
              <li mbsTabsetItem="availableHotfixes">
                <button mbsTabsetLink
                        [disabled]="noRmmAgentFound">
                  {{ 'rmm-side-panel:sidePanelTabsName.availableUpdates' | i18next: {name: isMacOS || isLinuxOS ? '' :
                  'Windows '} }}
                </button>
                <ng-template mbsTabsetContent>
                  <div *ngIf="showJson; else elseBlock">
                    <mbs-json-viewer-tab statType="update"
                                         [hid]="hid">
                    </mbs-json-viewer-tab>
                  </div>
                  <ng-template #elseBlock>
                    <mbs-hotfixes-available-tab [hid]="hid"
                                                [readOnly]="readonly"
                                                [isOnline]="isOnlineAgent"
                                                [computerName]="computerName"
                                                *ngFor="let hid of [hid]; trackBy:trackByFn">
                    </mbs-hotfixes-available-tab>
                  </ng-template>
                </ng-template>
              </li>
              <li mbsTabsetItem="installedHotfixes">
                <button mbsTabsetLink
                        [disabled]="noRmmAgentFound">
                  {{ 'rmm-side-panel:sidePanelTabsName.installedUpdates' | i18next }}
                </button>
                <ng-template mbsTabsetContent>
                  <div *ngIf="showJson; else elseBlock">
                    <mbs-json-viewer-tab statType="hotfix"
                                         [hid]="hid">
                    </mbs-json-viewer-tab>
                  </div>
                  <ng-template #elseBlock>
                    <mbs-hotfixes-installed-tab [hid]="hid"
                                                [isOnline]="isOnlineAgent"
                                                [computerName]="computerName"
                                                *ngFor="let hid of [hid]; trackBy:trackByFn">
                    </mbs-hotfixes-installed-tab>
                  </ng-template>
                </ng-template>
              </li>
            </ul>
          </li>

          <li *ngIf="!isMacOS && !isLinuxOS"
              mbsTabsetItem="hardware"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.hardware' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <div *ngIf="showJson; else elseBlock">
                <mbs-json-viewer-tab statType="hardware"
                                     [hid]="hid">
                </mbs-json-viewer-tab>
              </div>
              <ng-template #elseBlock>
                <mbs-hardware-tab [hid]="hid"
                                  [isOnline]="isOnlineAgent"
                                  [computerName]="computerName"
                                  *ngFor="let hid of [hid]; trackBy:trackByFn">
                </mbs-hardware-tab>
              </ng-template>
            </ng-template>
          </li>

          <li mbsTabsetItem="disk">
            <button mbsTabsetLink="diskGroup"
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.hddSSDInfo' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <div *ngIf="showJson; else elseBlock">
                <mbs-json-viewer-tab statType="hdd"
                                     [hid]="hid">
                </mbs-json-viewer-tab>
              </div>
              <ng-template #elseBlock>
                <mbs-hdd-tab [hid]="hid"
                             *ngFor="let hid of [hid]; trackBy:trackByFn">
                </mbs-hdd-tab>
              </ng-template>
            </ng-template>
          </li>

          <li mbsTabsetItem="printers"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.printers' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <div *ngIf="showJson; else elseBlock">
                <mbs-json-viewer-tab statType="printer"
                                     [hid]="hid">
                </mbs-json-viewer-tab>
              </div>
              <ng-template #elseBlock>
                <mbs-printers-tab [hid]="hid"
                                  [isOnline]="isOnlineAgent"
                                  [computerName]="computerName"
                                  *ngFor="let hid of [hid]; trackBy:trackByFn">
                </mbs-printers-tab>
              </ng-template>
            </ng-template>
          </li>

          <li *ngIf="!isMacOS && !isLinuxOS"
              mbsTabsetItem="antivirus"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.antivirus' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <div *ngIf="showJson; else elseBlock">
                <mbs-json-viewer-tab statType="antivirus"
                                     [hid]="hid">
                </mbs-json-viewer-tab>
              </div>
              <ng-template #elseBlock>
                <mbs-antivirus-tab statType="antivirus"
                                   [hid]="hid"
                                   [readOnly]="readonly"
                                   [isOnline]="isOnlineAgent"
                                   [computerName]="computerName"
                                   [agentVersion]="agentVersion$ | async"
                                   *ngFor="let hid of [hid]; trackBy:trackByFn">
                </mbs-antivirus-tab>
              </ng-template>
            </ng-template>
          </li>

          <li mbsTabsetItem="event"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ ('rmm-side-panel:sidePanelTabsName.' + (isMacOS || isLinuxOS ? 'unixEventLog' : 'winEventLog')) |
              i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <div *ngIf="showJson; else elseBlock">
                <mbs-json-viewer-tab statType="eventtotal"
                                     [hid]="hid">
                </mbs-json-viewer-tab>
              </div>
              <ng-template #elseBlock>
                <div *ngIf="!(agentVersion$ | async).supported; else eventTotal"
                     class="text-center mt-3">
                  <div class="d-ib mb-4">
                    <svg width="40"
                         height="36"
                         viewBox="0 0 40 36"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.0009 0C21.7095 0 23.2623 1.13004 24.3772 3.04345L24.3796 3.0475L39.0097 28.5097C39.0102 28.5105 39.0106 28.5112 39.0111 28.512C40.1241 30.4234 40.3336 32.3234 39.4556 33.7934C38.5834 35.2535 36.8086 36 34.5951 36H5.3301C3.14951 36 1.39276 35.2497 0.532829 33.7771C-0.329618 32.3002 -0.122013 30.3861 0.988527 28.4387L0.990267 28.4357L15.6228 3.04663L15.6246 3.04345C16.7396 1.13004 18.2923 0 20.0009 0ZM16.947 3.82306C16.9467 3.82361 16.9464 3.82417 16.946 3.82472L2.31711 29.2076C2.31682 29.2081 2.31652 29.2086 2.31623 29.2091C1.35919 30.888 1.3754 32.1759 1.85348 32.9946C2.33423 33.8178 3.45036 34.4566 5.3301 34.4566H34.5951C36.5186 34.4566 37.655 33.814 38.1427 32.9976C38.6241 32.1917 38.6426 30.9286 37.6883 29.291L37.686 29.2869L23.0558 3.82472C23.0554 3.82404 23.055 3.82337 23.0546 3.82269C22.1013 2.18776 20.9733 1.54341 20.0009 1.54341C19.0285 1.54341 17.9003 2.18786 16.947 3.82306Z"
                            fill="#FC8E26" />
                      <path fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.0392 28.746C19.6161 28.746 19.2731 29.0915 19.2731 29.5177C19.2731 29.9439 19.6161 30.2894 20.0392 30.2894C20.4623 30.2894 20.8053 29.9439 20.8053 29.5177C20.8053 29.0915 20.4623 28.746 20.0392 28.746ZM17.7409 29.5177C17.7409 28.2391 18.7699 27.2026 20.0392 27.2026C21.3085 27.2026 22.3375 28.2391 22.3375 29.5177C22.3375 30.7963 21.3085 31.8328 20.0392 31.8328C18.7699 31.8328 17.7409 30.7963 17.7409 29.5177Z"
                            fill="#FC8E26" />
                      <path fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.0392 12.5402C19.3898 12.5402 18.8135 13.1207 18.8135 13.7749C18.8135 13.8468 18.8356 14.078 18.8919 14.4906C18.9452 14.8807 19.0219 15.3846 19.1149 15.9646C19.3007 17.1237 19.5489 18.571 19.7977 19.9878C19.8789 20.4504 19.9602 20.9095 20.0392 21.3538C20.1183 20.9095 20.1995 20.4504 20.2808 19.9878C20.5295 18.571 20.7777 17.1237 20.9636 15.9646C21.0565 15.3846 21.1333 14.8807 21.1865 14.4906C21.2429 14.078 21.265 13.8468 21.265 13.7749C21.265 13.1207 20.6887 12.5402 20.0392 12.5402ZM20.0392 25.6592C19.286 25.8001 19.286 25.8 19.286 25.7999L19.2002 25.3328C19.1457 25.0349 19.0678 24.6082 18.9744 24.0928C18.7875 23.0623 18.5382 21.6765 18.2889 20.2566C18.0397 18.8373 17.7899 17.3811 17.6023 16.2106C17.5085 15.6258 17.4297 15.1084 17.374 14.7009C17.3214 14.3159 17.2813 13.9732 17.2813 13.7749C17.2813 12.2683 18.5436 10.9968 20.0392 10.9968C21.5349 10.9968 22.7972 12.2683 22.7972 13.7749C22.7972 13.9732 22.757 14.3159 22.7045 14.7009C22.6488 15.1084 22.5699 15.6258 22.4762 16.2106C22.2885 17.3811 22.0387 18.8373 21.7896 20.2566C21.5402 21.6765 21.291 23.0623 21.1041 24.0928C21.0106 24.6082 20.9327 25.0349 20.8782 25.3328L20.7925 25.7995C20.7925 25.7996 20.7924 25.8001 20.0392 25.6592ZM20.0392 25.6592L20.7925 25.7995C20.7251 26.1652 20.4084 26.4309 20.0392 26.4309C19.6701 26.4309 19.3534 26.1655 19.286 25.7999L20.0392 25.6592Z"
                            fill="#FC8E26" />
                    </svg>
                  </div>

                  {{ 'rmm-side-panel:sidePanelTexts.versionSupportP1' | i18next }}
                  <span class="font-weight-semibold">{{ (agentVersion$ | async).version }}</span> {{
                  'rmm-side-panel:sidePanelTexts.versionSupportP2' | i18next }}
                  <span class="font-weight-semibold">{{ computerName }}</span>
                  {{ 'rmm-side-panel:sidePanelTexts.versionSupportP3' | i18next }}
                  <mbs-button [isCtrl]="true"
                              icon="ico ico-Download mr-2"
                              (click)="handleClickDownloads()"
                              type="primary">download
                    {{ 'buttons:downloadBtn' | i18next }}
                  </mbs-button>
                  {{ 'rmm-side-panel:sidePanelTexts.versionSupportP4' | i18next }}
                </div>

                <ng-template #eventTotal>
                  <mbs-event-total-tab [hid]="hid"
                                       [hostInfo]="hostInfo$ | async"
                                       [isOnline]="isOnlineAgent"
                                       [computerName]="computerName"
                                       *ngFor="let hid of [hid]; trackBy:trackByFn">
                  </mbs-event-total-tab>
                </ng-template>
              </ng-template>
            </ng-template>
          </li>


          <li *ngIf="!isMacOS && !isLinuxOS"
              mbsTabsetItem="hyperv"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.hyperVManager' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <div *ngIf="showJson; else elseBlock">
                <mbs-json-viewer-tab statType="hyperv"
                                     [hid]="hid">
                </mbs-json-viewer-tab>
              </div>
              <ng-template #elseBlock>
                <mbs-hyperv-tab [hid]="hid"
                                [readonly]="readonly"
                                [isOnline]="isOnlineAgent"
                                [computerName]="computerName"
                                *ngFor="let hid of [hid]; trackBy:trackByFn">
                </mbs-hyperv-tab>

              </ng-template>
            </ng-template>
          </li>

          <!-- disabled required due bugs loadingData mode -->
          <li *ngIf="showTerminalTab"
              mbsTabsetItem="powershell"
              #mbsTabsetItem="mbsTabsetItem"
              [loadOnAppearance]="true"
              [mbsTabSetValid]="false"
              [disabled]="loadingData">
            <button mbsTabsetLink
                    [disabled]="loadingData || noRmmAgentFound">
              {{!isMacOS && !isLinuxOS ? 'PowerShell' : 'Bash'}}
            </button>
            <ng-template mbsTabsetContent>
              <mbs-powershell-terminal-tab [hid]="hid"
                                           *ngFor="let hid of [hid]; trackBy:trackByFn">
              </mbs-powershell-terminal-tab>
            </ng-template>
          </li>

          <li *ngIf="isOnlineAgent && !isMacOS && !isLinuxOS"
              mbsTabsetItem="remote-registry"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.registryEditor' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <mbs-remote-registry-tab [hid]="hid"
                                       [regTree]="regTree"
                                       [readOnly]="readonly"
                                       [agentOptions]="agentOptions"
                                       *ngFor="let hid of [hid]; trackBy:trackByFn">
              </mbs-remote-registry-tab>
            </ng-template>
          </li>

          <li *ngIf="rmmStateService.debugMode && isOnlineAgent"
              mbsTabsetItem="command"
              [mbsTabSetValid]="false">
            <button mbsTabsetLink
                    [disabled]="noRmmAgentFound">
              {{ 'rmm-side-panel:sidePanelTabsName.executeCommand' | i18next }}
            </button>
            <ng-template mbsTabsetContent>
              <mbs-command-tab [hid]="hid">
              </mbs-command-tab>
            </ng-template>
          </li>

        </ul>

        <ul class="mbs-tabset -vertical">
          <li *ngIf="hasAgent(computer, AgentType.Backup)"
              class="mbs-tabset_item mb-6">
            <button class="mbs-tabset_link py-1"
                    (click)="openBackup(computer)">
              <span class="ico ico-BarChart text-primary mr-2"></span>
              {{ 'app:common.open' | i18next: { format: 'title'} }}&nbsp;{{ 'app:products.backup' | i18next }}
            </button>
          </li>
          <li class="mbs-tabset_item">
            <mbs-connect-button-group [computer]="computer"
                                      [isCtrl]="true"
                                      [customClass]="'mbs-tabset_link pr-1 py-1'"
                                      [dropdownPlacement]="'top-right'"></mbs-connect-button-group>
          </li>
        </ul>
      </div>

      <div class="mbs-form_content"
           *ngIf="needToShowContent"
           [class.overflow-y-hidden]="isModalContent"
           [class.position-relative]="isModalContent"
           [mbsTabsetOutlet]="activeContent">

        <mbs-alert *ngIf="!isOnlineAgent && loaded"
                   [type]="alertType.info"
                   prepend>
          <div *ngIf="noRmmAgentFound; else rmmServiceNotAvailable">
            {{ 'rmm-side-panel:sidePanelTexts.rmmAgentIsNotInstalled' | i18next }}
          </div>
          <ng-template #rmmServiceNotAvailable>
            {{ 'rmm-side-panel:sidePanelTexts.rmmServiceUnavailable' | i18next }}
            <ng-container *ngIf="(lastUpdated$ | async) as lastUpdated">
              <br />
              {{ 'rmm-side-panel:sidePanelTexts.lastUpdated' | i18next }}
              <mbs-rmm-date-time-format [container]="'body'"
                                        [time]="lastUpdated"
                                        [showIcon]="true">
              </mbs-rmm-date-time-format>
            </ng-container>
          </ng-template>
        </mbs-alert>

      </div>
    </div>
  </ng-container>

  <ng-template #footerTemplate>
    <div class="mbs-sidepanel_footer-col">
      <mbs-switcher *ngIf="rmmStateService.debugMode"
                    [(ngModel)]="showJson"
                    label="Show JSON"></mbs-switcher>
    </div>
    <div class="mbs-sidepanel_footer-col">
      <mbs-button type="secondary"
                  (click)="close.emit()">
        {{ 'buttons:close' | i18next }}
      </mbs-button>
    </div>
  </ng-template>

</mbs-sidepanel>
