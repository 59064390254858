import { TreeElement } from 'mbs-ui-kit';

export enum ItemTypeEnum {
  Folder = 0,
  File = 1,
  Version = 2,
  Disk = 3,
  Volume = 4,
  Snapshot = 5,
  Machine = 6,
  Host = 7,
  RestorePoint = 8,
  Generation = 9,
  Bunch = 10,
  Prefix = 11,
  Connection = 12
}

export const i18KeysByType = {
  [ItemTypeEnum.Bunch]: 'backup',
  [ItemTypeEnum.Generation]: 'generation',
  [ItemTypeEnum.RestorePoint]: 'restorePoint'
};

export enum DeepSyncEnum {
  InProgress,
  InProgressColdStorage,
  InvalidPassword,
  NeedPassword,
  Done,
  Error
}

export type MyTreeElements = TreeElement & {
  firstRestoreDate?: string;
  lastRestoreDate?: string;
  sizeOnStorage?: number;
  daysToPurge?: number;
  totalSavings?: number;
  countOfErrorStarts?: number;
  countOfSuccessfulStarts?: number;
  countOfWarningStarts?: number;
  compressionRatio?: number;
  deduplicationRatio?: number;
  duration?: string;
  date?: string;
  backupResult?: string;
  consistencyCheckResult?: string;
  restoreVerificationResult?: string;
  restorePointObjectPath?: string;
  type?: GetBackupContentType | GetBackupContentBunchType;
  itemType?: ItemTypeEnum;
  deepSync?: DeepSyncEnum;
  deepSyncProgress?: number;
  isEncrypted?: StorageEncryptionState;
  isObjectLock?: boolean;
  isLegacy?: boolean;
  isCanBeDeleted?: boolean;
  isDeleted?: boolean;
  keepBackupFor?: string;
  needShowRV?: boolean;
  encryptionPasswordHint?: string;
  hideButton?: boolean;
  generationId?: string;
  storageId?: string;
  bunchId?: string;
  restorePoint?: string;
  modifyDateUTC?: string;
  isCanBeQuickRestored?: boolean;
  iconCustom?: string;
  leftTopIcon?: string;
  rightBottomIcon?: string;
  GFSSettings?: { type: GenerationGFSType };
  purgeSummary?: string;
  restorePath?: string;
  path?: string;
};

export enum GenerationGFSType {
  NotAssigned = 'NotAssigned',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export enum GenerationGFSTypeIcons {
  Weekly = 'assets/images/storages-tree/weekly.svg',
  Monthly = 'assets/images/storages-tree/monthly.svg',
  Yearly = 'assets/images/storages-tree/yearly.svg',
  NotAssigned = 'assets/images/storages-tree/x.svg'
}

export enum GetBackupContentBunchType {
  DiskImage = 'DiskImage',
  Exchange = 'Exchange',
  Database = 'Database',
  VMware = 'VMware',
  HyperV = 'HyperV',
}

export enum GetBackupContentType {
  Disk = 'Disk',
  Version = 'Version',
  Volume = 'Volume',
  File = 'File',
  Folder = 'Folder'
}

export enum TreeBunchesIcons {
  Ibb = 'assets/images/storages-tree/systemImagePlanPicture.svg',
  HyperV = 'assets/images/storages-tree/hyperv-icon.svg',
  VmWare = 'assets/images/storages-tree/vmware-icon.svg',
  VmWareCurrent = 'assets/images/storages-tree/VmWareCurrent.svg',
  Exchange = 'assets/images/storages-tree/exchange.svg',
  Database = 'assets/images/storages-tree/SQL.svg'
}

export enum TreeRestorePointsIconPath {
  Server = 'assets/images/storages-tree/ServerPicture.svg',
  ServerGroup = 'assets/images/storages-tree/ServerGroupPicture.svg',
  Full = 'assets/images/storages-tree/DbFullBackupPicture.svg',
  File = 'assets/images/storages-tree/filePicture.svg',
  Disk = 'assets/images/storages-tree/DiskPicture.svg',
  ParentDisk = 'assets/images/storages-tree/CdDiskPicture.svg',
  Diff = 'assets/images/storages-tree/DbDiffBackupPicture.svg',
  Chain = 'assets/images/storages-tree/ChainDraw.svg',
  Success = 'assets/images/storages-tree/okStatusPicture.svg',
  Warn = 'assets/images/storages-tree/warningStatusPicture.svg',
  WarnTriangle = 'assets/images/storages-tree/warningTrianglePicture.svg',
  Error = 'assets/images/storages-tree/errorStatusPicture.svg',
  Encrypt = 'assets/images/storages-tree/EncryptionLockPicture.svg',
  Quest = 'assets/images/storages-tree/questionPicture.svg'
}

export const ComputerIcon = 'assets/images/for-wizards/MyCompPicture.svg';
export const HomeComputerIcon = 'assets/images/for-wizards/HomeComputerPicture.svg';

export enum StorageEncryptionState {
  NotEncrypted,
  PossiblyEncrypted,
  Encrypted
}
