import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { isTemplate } from '../../utils';
import { InputButton, InputComponent } from '../index';

@Component({
  selector: 'mbs-input-range',
  templateUrl: './input-range.component.html'
})
export class InputRangeComponent {
  @Input() loading = false;
  @Input() label: string;
  @Input() appendButtons: InputButton[];
  @Input() prependButtons: InputButton[];

  @Output() buttonClick = new EventEmitter();

  @ContentChild('startDate') startInput: InputComponent;
  @ContentChild('endDate') endInput: InputComponent;

  public isFocus = false;

  public get isLabelTemplate(): boolean {
    return isTemplate(this.label);
  }
}
