import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { fromEvent } from 'rxjs';
import { PrismService } from '../prism.service';

@UntilDestroy()
@Component({
  selector: 'mbs-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss']
})
export class CodeEditorComponent implements ControlValueAccessor, OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('textArea', { static: true }) textArea: ElementRef;
  @ViewChild('codeContent', { static: true }) codeContent: ElementRef;
  @ViewChild('pre', { static: true }) pre: ElementRef;
  @Input() public disabledSelf = false;
  @Input() codeType = 'powershell';
  /**
   * Readonly input
   */
  @Input() public readonly: boolean;
  public get readonlyState(): boolean {
    return !isNil(this.readonly) && this.readonly !== false;
  }

  @Input() public disabled: boolean;
  get disabledState(): boolean {
    return (!isNil(this.disabled) && this.disabled !== false) || (!isNil(this.disabledSelf) && this.disabledSelf !== false);
  }

  @Input() public id: string = 'code-' + Math.random().toString(36).substring(7);

  public value = '';
  private indent = 4;
  private highlighted = false;
  protected ngControl: NgControl;

  constructor(
    @Optional() @Self() ngControl: NgControl,
    protected cd: ChangeDetectorRef,
    private prismService: PrismService,
    private renderer: Renderer2
  ) {
    this.ngControl = ngControl || ({} as NgControl);
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange: (value: string) => void;
  onTouched: () => void;

  writeValue(value: string): void {
    this.value = value;
    this.getIndent(value);
    this.renderHighLight();
    this.cd.detectChanges();
    this.cd.markForCheck();
  }
  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  ngOnInit(): void {
    this.synchronizeScroll();
    this.handleInput();
  }

  ngAfterViewInit() {
    this.prismService.highlightAll();
  }

  ngAfterViewChecked() {
    // highlight code & copyPaste fix using timeout
    setTimeout(() => {
      this.prismService.highlightAll();
    }, 0);
    if (this.highlighted) this.highlighted = false;
  }

  handleInput() {
    const element = this.textArea.nativeElement;
    fromEvent(element, 'keydown')
      .pipe(untilDestroyed(this))
      .subscribe((event: KeyboardEvent) => {
        this.keyBehavior(event);
        this.renderHighLight();
        this.onTouched();
      });
  }

  keyBehavior(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab': {
        event.preventDefault();
        this.setIndent();
        break;
      }
      case 'Enter': {
        event.preventDefault();
        this.caretIndent();
        break;
      }
      default:
        null;
    }
  }

  getIndent(value: string) {
    const first = value?.split('\n').find((line) => line.match(/^\s/));
    if (first) {
      this.indent = first.length - first.trimStart().length;
    }
  }

  caretIndent() {
    const element: HTMLTextAreaElement = this.textArea.nativeElement;
    const [start, end] = [element.selectionStart, element.selectionEnd];
    const [last] = element.value.substr(0, start).split('\n').slice(-1);
    const count = last.length - last.trimStart().length;

    const indent = last.match(/\{$/) ? this.createIndentString(count + this.indent) : this.createIndentString(count);

    element.setRangeText('\n' + indent, start, end, 'end');
  }

  setIndent() {
    const element = this.textArea.nativeElement;
    const [start, end] = [element.selectionStart, element.selectionEnd];
    const indent = this.createIndentString();
    element.setRangeText(indent, start, end, 'end');
  }

  createIndentString(count = this.indent) {
    return new Array(count + 1).join(' ');
  }

  renderHighLight() {
    setTimeout(() => {
      const element = this.textArea.nativeElement;
      const modifiedContent = this.prismService.convertHtmlIntoString(element.value);
      this.onChange(element.value);
      this.renderer.setProperty(this.codeContent.nativeElement, 'innerHTML', modifiedContent);
      this.highlighted = true;
    }, 0);
  }

  private synchronizeScroll() {
    fromEvent(this.textArea.nativeElement, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const toTop = this.textArea.nativeElement.scrollTop;
        const toLeft = this.textArea.nativeElement.scrollLeft;

        this.renderer.setProperty(this.pre.nativeElement, 'scrollTop', toTop);
        this.renderer.setProperty(this.pre.nativeElement, 'scrollLeft', toLeft + 0.2);
      });
  }
}
