import { EventLogEntryTypeEnum, EventLogTypesEnum } from '@models/rmm/EventLogInfo';
import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';
import { EventTotalTabMode } from '../event-total-tab.model';
import { getISOStrings, isLastTimeOfDate } from '../event-total-tab.utils';
import * as EventTotalActions from './event-total.actions';

export const eventTotalFeatureKey = 'eventTotal';

export interface State {
  date: Date;
  entry: EventLogEntryTypeEnum;
  hid: string;
  logType: EventLogTypesEnum;
  mode: EventTotalTabMode;
  online: boolean;
  start: string;
  stop: string;
  utcOffset: number;
}

export const initialState: State = {
  date: new Date(),
  entry: null,
  hid: null,
  logType: EventLogTypesEnum.Application,
  mode: EventTotalTabMode.CHART,
  online: null,
  start: moment(new Date())
    .subtract(24, 'hours')
    .toISOString(),
  stop: moment(new Date()).toISOString(),
  utcOffset: 0
};

const reducer = createReducer(
  initialState,
  on(EventTotalActions.UpdateDate, (state, { date }) => {
    if (isLastTimeOfDate(state.date, date, state.stop)) {
      return state;
    } else {
      const { start, stop } = getISOStrings(state.utcOffset)(date);
      return Object.assign({}, state, { date, start, stop });
    }
  }),
  on(EventTotalActions.ChangeMode, (state, { mode }) => {
    if (mode === undefined) {
      mode = Number(!state.mode);
    }

    return Object.assign({}, state, { mode, logType: EventLogTypesEnum.Application, entry: null });
  }),
  on(EventTotalActions.UpdateType, (state, { logType }) => {
    return Object.assign({}, state, { logType, mode: EventTotalTabMode.GRID, entry: null });
  }),
  on(EventTotalActions.ViewLogByEntryType, (state, { logType, entry }) => {
    return Object.assign({}, state, { logType, mode: EventTotalTabMode.GRID, entry });
  }),
  on(EventTotalActions.UpdateHost, (_state, action) => {
    return Object.assign({}, initialState, action);
  }),
  on(EventTotalActions.ClearEventTotal, () => {
    return Object.assign({}, initialState);
  })
);

export function eventTotalReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
