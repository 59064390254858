import { Component, ViewChild } from '@angular/core';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';

@Component({
  selector: 'mbs-immutability-confirm-modal',
  templateUrl: './immutability-confirm-modal.component.html'
})
export class ImmutabilityConfirmModalComponent {
  public confirmed = false;
  public mbsPopupType = MbsPopupType;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  confirmClickHandler(): void {
    if (this.confirmed) this.baseModal.save({ confirm: true });
    this.confirmed = false;
  }

  cancelClickHandler(): void {
    this.baseModal.close();
    this.confirmed = false;
  }
}
