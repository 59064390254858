<div class="ng-terminal-wrapper h-100"
     [ngClass]="{'initialized': (OnInitialized | async)}"
     (scroll)="handleScroll($event)">
  <mbs-button *ngIf="showModalButton"
              mbsModalTerminal
              #mbsModalTerminal = "mbsModalTerminal"
              (click)="mbsModalTerminal.handleShowModal()"
              (modalState)="handleModalState($event)"
              [isCtrl]="true"
              class="ng-terminal-wrapper-show-modal bg-transparent-white"
              icon="ico ico-external-link-square text-white">
  </mbs-button>
  <ng-template ng-terminal-host></ng-template>
</div>

<div class="row ml-1 mt-1 ng-terminal-warning">
  {{ warning }}
</div>
