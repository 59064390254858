<div class="mb-3">
  <div>
    <span class="font-weight-semibold">
      {{ 'rmm-side-panel:hddTab.status' | i18next }}
    </span>
    {{ smartDisk?.isOk ? ('rmm-side-panel:hddTab.states.ok' | i18next) : ('rmm-side-panel:hddTab.states.alert' |
    i18next) }}
  </div>

  <div *ngIf="smartDisk?.smartErrorInfo as errorInfo">
    <div>
      <span class="font-weight-semibold">
        {{ 'rmm-side-panel:hddTab.smartErrorStatus' | i18next }}
      </span>
      {{errorInfo?.message ? errorInfo.message : 'N/A'}}
      <mbs-button [isCtrl]="true"
                  [hidden]="!errorInfo?.details"
                  (click)="onClick()">
        {{ (showMore ? 'rmm-side-panel:hddTab.showLess' : 'rmm-side-panel:hddTab.showMore') | i18next}}
      </mbs-button>
    </div>

    <div *ngIf="showMore">
      <div class="font-weight-semibold">
        {{ 'rmm-side-panel:hddTab.details' | i18next }}
      </div>
      <pre class="text-md">{{ errorInfo.details }}</pre>
    </div>
  </div>
</div>

<ng-container *ngIf="smartDisk?.smartAttributes?.length">
  <mbs-table-grid [headers]="headers"
                  [data]="smartDisk.smartAttributes"
                  [switcherView]="false"
                  (sort)="handleSort($event)"
                  sizeTable="sm"
                  maxHeight="60vh"
                  [rotateSequence]="rotateSequence"
                  [changeSortState]="orderBy"
                  [disableViewSwitch]="true"
                  [noDataMessage]="'rmm-side-panel:noDataProvided' | i18next">
    <ng-template mbsTableCell
                 let-disk>
      {{ disk.register }}
    </ng-template>
    <ng-template mbsTableCell
                 let-disk>
      {{ disk.name }}
    </ng-template>
    <ng-template mbsTableCell
                 let-disk>
      {{ disk.current }}
    </ng-template>
    <ng-template mbsTableCell
                 let-disk>
      {{ disk.worst }}
    </ng-template>
    <ng-template mbsTableCell
                 let-disk>
      {{ disk.threshold }}
    </ng-template>
    <ng-template mbsTableCell
                 let-disk>
      {{ disk.data }}
    </ng-template>
    <ng-template mbsTableCell
                 let-disk>
      {{ disk ? (disk.isOk ? ('rmm-side-panel:hddTab.states.good' | i18next) : ('rmm-side-panel:hddTab.states.bad' |
      i18next)) : ('rmm-side-panel:hddTab.states.unknown' | i18next)}}
    </ng-template>

  </mbs-table-grid>
</ng-container>

<ng-container *ngIf="smartDisk?.smartHealthAttributes as data">
  <mbs-table-grid [headers]="nameValueHeader"
                  [data]="data"
                  [switcherView]="false"
                  (sort)="handleSort($event)"
                  sizeTable="sm"
                  maxHeight="60vh"
                  [rotateSequence]="rotateSequence"
                  [changeSortState]="orderBy"
                  [disableViewSwitch]="true"
                  [noDataMessage]="'rmm-side-panel:noDataProvided' | i18next">
    <ng-template mbsTableCell
                 let-item>
      {{ item.name }}
    </ng-template>
    <ng-template mbsTableCell
                 let-item>
      {{ item.value }}
    </ng-template>

  </mbs-table-grid>
</ng-container>
