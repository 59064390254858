import * as ShellInputActions from './actions';
import * as ansiEscapes from 'ansi-escapes';

import { IBuffer, IDisposable, ITerminalAddon, Terminal } from 'xterm';
import {KeyAction, KeyActions, LineTypes, PromptBufferConfig, ShellCommandOperator, TerminalState} from './model';
import { NEW_LINE, PROMPT, TERMINAL_GREETINGS, TERMINAL_OPTIONS } from '../const';
import { Observable, Subject, Subscription, from, interval, startWith } from 'rxjs';
import { buffer, concatMap, map, scan } from 'rxjs/operators';
import { clear, del, down, end, error, history, home, insert, left, newLine, output, paste, prompt, reminder, right, script, up } from './shell-commands';
import {colorError, colorWarn, convertCacheToAnsi, restoreHistory, sliceByCols} from './utils';
import { createKeyAction, keyEventHandler, pasteEventHandler } from './handler';
import { isEmpty, isString, noop } from 'lodash';

import { Action } from '@ngrx/store';
import { EventBehavior } from './events';
import { PromptBuffer } from './prompt-buffer';
import { ShellHistory } from './history';
import { ShellLine } from './shell-line';
import { flatten } from 'lodash/fp';

/**
 * ShellAddon - addon for xterm.js that implements command interpreter in browser,
 * supports navigation and command history.
 * See https://xtermjs.org/docs/guides/using-addons/
 */

export class ShellAddon implements ITerminalAddon {
  public execute$: Subject<PromptBuffer> = new Subject();
  public break$: Subject<string> = new Subject();
  public error$: Subject<string> = new Subject();
  public inputChange$: Subject<string> = new Subject();
  public bufferChange$: Subject<PromptBuffer> = new Subject();
  public cacheRequest$: Subject<number> = new Subject();
  public history: ShellHistory;
  private write$: Subject<string[]> = new Subject();

  public processing: Subscription;
  public asyncQuery: Subscription;

  public actions$: Observable<KeyAction>;
  private events$ = new EventBehavior();

  private prompt = PROMPT;
  public state: TerminalState = TerminalState.READY;

  get execution() {
    return this.state === TerminalState.PROCESSING;
  }

  get locked() {
    return this.state === TerminalState.LOCK;
  }

  os: 'Windows' | string;
  term: Terminal;
  buffer: IBuffer;
  disposables: IDisposable[];
  outputBuffer;
  customKeyHandling;
  simulationMode;
  promptLine: ShellLine;

  constructor(os) {
    this.os = os;
    this.history = new ShellHistory(20);

    this.initEvents();
    this.initQuery();
  }
  /**
   * activate xterm hook
   * API method
   *
   * @param {Terminal} terminal
   */
  activate(terminal: Terminal): void {
    this.term = terminal;
    this.buffer = terminal.buffer.active;
    this.term.onKey(this.handleEvent);
    this.dispatchGreetings(this.os)
    this.term.textarea.onpaste = this.handlePaste
  }

  softReset() {
    this.term.reset();
    this.dispatchGreetings(this.os);
    setTimeout(() => {
      this.cacheRequest$.next(Date.now())
    })

    if (this.state !== TerminalState.PROCESSING) {
      setTimeout(() => {
        this.dispatchPrompt();
      }, 1000)
    }
  }
  /**
   * Handle keyboard events
   *
   * @async
   * @param {object} event
   * @param {string} key
   * @param {KeyboardEvent} domEvent
   * @return {void}
   */
  handleEvent = async (event: { key: string; domEvent: KeyboardEvent }) => {
    const action = await keyEventHandler(event.domEvent, { write: event.key }, { history: this.history.active, execution: this.execution });
    this.dispatchAction(action);
  };
  /**
   * Handle paste from context menu
   *
   * @async
   * @param {ClipboardEvent} event
   * @return {void}
   */
  handlePaste = async (event: ClipboardEvent) => {
    const action = await pasteEventHandler(event);
    this.dispatchAction(action);
  }
  /**
   * action dispatcher
   *
   * @param {Action} action
   * @return {void}
   */
  dispatchAction = (action: Action) => {
    if (action) {
      this.events$.dispatch(action);
    }
  };
  /**
   * Disconnection status dispatcher
   *
   * @param {boolean} status
   * @return {void}
   */
  dispatchOffline(status) {
    if (status) {
      this.state = TerminalState.DISCONNECTED;
      this.applyServiceLine();
    } else {
      if (this.state === TerminalState.DISCONNECTED) {
        this.writeAsync([NEW_LINE]);
        this.dispatchPrompt();
      }
    }
  }
  /**
   * Processing status dispatcher
   *
   * @param {boolean} status
   * @return {void}
   */
  dispatchProcessing(status) {
    if (status) {
      this.state = TerminalState.PROCESSING;
      this.applyServiceLine();
    } else {
      this.state = TerminalState.LOCK;
    }
  }
  /**
   * input change dispatcher
   * @param {PromptBuffer} buffer
   * @param {boolean} backspace
   * @return {void}
   */
  dispatchChangeInput(buffer = this.createPromptBuffer(), backspace = false) {
    const value = backspace ? buffer.getInput().slice(0, -1) : buffer.getInput();
    this.inputChange$.next(value);
  }
  /**
   * buffer change dispatcher
   * @param {PromptBuffer} buffer
   * @return {void}
   */
  dispatchBufferChange(buffer = this.createPromptBuffer()) {
    this.bufferChange$.next(buffer);
  }
  /**
   * generate greetings for xterm
   * @param {string} os
   * @return {void}
   */
  dispatchGreetings(os:string) {
    const greetings = TERMINAL_GREETINGS[os];
    const output = greetings.join(NEW_LINE);
    this.dispatchAction(createKeyAction(KeyActions.Output, null, { output }));
  }
  /**
   * output dispatcher
   * @param {*} output
   * @return {void}
   */
  dispatchOutput(output) {
    this.dispatchAction(createKeyAction(KeyActions.Output, null, { output }));
  }
  /**
   * output dispatcher
   * @param {*} cache
   * @return {void}
   */
  dispatchCache(cache) {
    const buffer = this.createPromptBuffer();
    const output = ansiEscapes.eraseDown + convertCacheToAnsi(cache, buffer);
    this.dispatchAction(createKeyAction(KeyActions.Output, null, { output }));
    this.restoreHistory(cache);
  }
  /**
   * error dispatcher
   * @param {*} error
   * @return {void}
   */
  dispatchOutputError(error) {
    const buffer = this.createPromptBuffer();
    this.dispatchAction(createKeyAction(KeyActions.Output, null, { output: colorError(error, buffer) }));
  }
  /**
   * warn dispatcher
   * @param {*} warn
   * @return {void}
   */
  dispatchOutputWarning(warn) {
    const buffer = this.createPromptBuffer();
    this.dispatchAction(createKeyAction(KeyActions.Output, null, { output: colorWarn(warn, buffer) }));
  }
  /**
   * change Execution
   * @param {?string} [prompt]
   * @return {void}
   */
  dispatchPrompt(prompt?: string) {
    this.prompt = isString(prompt) ? prompt : this.prompt;
    this.state = TerminalState.READY;
    this.term.textarea.focus();

    setTimeout(() => {
      this.dispatchAction(createKeyAction(KeyActions.Prompt));
    }, 300);
  }
  /**
   * create async commands query
   */
  initQuery() {
    this.asyncQuery = this.write$.pipe(
      concatMap((commands) => from(this.writeAsync(commands))),
    ).subscribe(noop);
  }
  /**
   * register events to addon
   */
  initEvents() {
    this.actions$ = this.events$.actions$;
    // execution commands group
    this.events$.subscribeOfType(ShellInputActions.Run, () => this.execute());
    this.events$.subscribeOfType(ShellInputActions.Break, this.applyBreak([clear, prompt]));
    this.events$.subscribeOfType(ShellInputActions.Prompt, this.applyPromptCommand([clear, prompt]));
    this.events$.subscribeOfType(ShellInputActions.Error, this.applyAsyncCommand([reminder, error]));
    this.events$.subscribeOfType(ShellInputActions.Output, this.applyAsyncCommand([clear, output]));
    // manage commands group
    this.events$.subscribeOfType(ShellInputActions.Copy, this.applyCopy);
    this.events$.subscribeOfType(ShellInputActions.Right, this.applyCommand([right]));
    this.events$.subscribeOfType(ShellInputActions.Left, this.applyCommand([left]));
    this.events$.subscribeOfType(ShellInputActions.Home, this.applyCommand([home]));
    this.events$.subscribeOfType(ShellInputActions.End, this.applyCommand([end]));
    this.events$.subscribeOfType(ShellInputActions.Up, this.applyCommand([up]));
    this.events$.subscribeOfType(ShellInputActions.Down, this.applyCommand([down]));
    // history commands group
    this.events$.subscribeOfType(ShellInputActions.Next, () => this.applyHistory([home, clear, history], this.history.next()));
    this.events$.subscribeOfType(ShellInputActions.Prev, () => this.applyHistory([home, clear, history], this.history.prev()));
    this.events$.subscribeOfType(ShellInputActions.LastCMD, () => this.applyHistory([home, clear, prompt, history], this.history.last()));
    // sync commands
    this.events$.subscribeOfType(ShellInputActions.Insert, this.applyCommandWrite([clear, insert, right]));
    this.events$.subscribeOfType(ShellInputActions.Paste, this.applyCommandWrite([clear, paste, right]));
    this.events$.subscribeOfType(ShellInputActions.Delete, this.applyCommandWrite([clear, del]));
    this.events$.subscribeOfType(ShellInputActions.Backspace, this.applyCommandWrite([left, clear, reminder, left]));
    this.events$.subscribeOfType(ShellInputActions.NewLine, this.applyCommandWrite([clear, newLine, reminder, right]));
  }
  /**
   * Execute command in out side service
   */
  execute = () => {
    const promptBuffer = this.createPromptBuffer();
    const result = promptBuffer.getInput();
    if (!this.execution) {
      this.state = TerminalState.PROCESSING
      this.history.set(result);
      this.history.stop();
      this.execute$.next(promptBuffer);
    }
  };
  /**
   * restored inputted string from a PromptBuffer
   * @param {PromptBuffer} [buffer=this.createPromptBuffer()]
   * @return {string}
   */
  getCurrentCommand(buffer = this.createPromptBuffer()): string {
    return buffer.getInput();
  }
  /**
   * apply outside command to terminal
   * @param {*} payload
   */
  inputCommand(payload) {
    const promptBuffer = this.createPromptBuffer();
    const commands = [clear, script].map(operator => operator({ payload } as any)(promptBuffer));

    this.writeAsync(commands);
  }
  /**
   * copy action handler
   * @param {?KeyAction} [_action]
   */
  applyCopy = (_action?: KeyAction) => {
    if (this.term.getSelection()) {
      document.execCommand('copy');
    }
  }
  /**
   * handle input from history
   * @param {ShellCommandOperator[]} operators
   * @param {string} history
   */
  applyHistory = (operators: ShellCommandOperator[], history: string) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map(operator => operator({ history } as any)(promptBuffer));
    this.write(commands);
  };
  /**
   * handle Break command
   * @param {ShellCommandOperator[]} _operators
   * @return {void}
   */
  applyBreak = (_operators: ShellCommandOperator[]) => (_action: KeyAction) => {
    this.history.start();
    this.break$.next(null);
  };
  /**
   * handle common commands in xterm
   * @param {ShellCommandOperator[]} operators
   * @return {void}
   */
  applyCommand = (operators: ShellCommandOperator[]) => (action: KeyAction) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map(operator => operator(action)(promptBuffer));
    this.write(commands);
  };
  /**
   * handle async commands in xterm
   * @param {ShellCommandOperator[]} operators
   * @return {void}
   */
  applyAsyncCommand = (operators: ShellCommandOperator[]) => (action: KeyAction) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map(operator => operator(action)(promptBuffer));
    const prediction = sliceByCols(commands, promptBuffer.config.newLine, promptBuffer.config.cols)

    if ((promptBuffer.cursorY + prediction.length) > 9000) {
      this.softReset();
    } else {
      this.write$.next(commands);
    }

  };
  /**
   * handle start Prompt in xterm
   * @param {ShellCommandOperator[]} operators
   * @return {void}
   */
  applyPromptCommand = (operators: ShellCommandOperator[]) => (action: KeyAction) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map(operator => operator(action)(promptBuffer));
    this.write$.next(commands);
    this.history.start();
  };
  /**
   * handle commands for write input in xterm
   * @param {ShellCommandOperator[]} operators
   * @return {void}
   */
  applyCommandWrite = (operators: ShellCommandOperator[]) => (action: KeyAction) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map(operator => operator(action)(promptBuffer));

    if (promptBuffer.isRowLimit()) {
      this.dispatchAction(createKeyAction(KeyActions.Error, null, { error: 'input limit' }));
    } else {
      this.write(commands, this.writeCallBack);
    }
  };
  applyServiceLine() {
    this.processing?.unsubscribe();
    this.processing = null;
    const buffer = this.createPromptBuffer();

    switch (this.state) {
      case TerminalState.DISCONNECTED: {
        const template = colorWarn('DISCONNECTED! Wait for reconnection', buffer) + ansiEscapes.cursorHide;
        this.term.write(ansiEscapes.cursorSavePosition + template + ansiEscapes.cursorRestorePosition);
        break;
      }
      case TerminalState.PROCESSING: {
        this.term.write(ansiEscapes.cursorSavePosition);
        this.processing = interval(200)
          .pipe(
            startWith(0),
            scan(acc => (acc < 3 ? acc + 1 : 0), 0),
            map(count => ({ dots: new Array(count).fill('.').join(' ')}))
          ).subscribe(({dots}) => {
            const template =  NEW_LINE + ansiEscapes.eraseLine + dots  + ansiEscapes.cursorHide;
            this.term.write(template + ansiEscapes.cursorRestorePosition);
        })

        break;
      }
      case TerminalState.READY: {
        if (buffer.cursorY === this.buffer.length - 1) {

          this.term.write(ansiEscapes.cursorSavePosition + NEW_LINE + ansiEscapes.cursorRestorePosition);
        }
        return;
      }
      default: null;
    }
  }

  restoreHistory = (cache) => {
    restoreHistory(cache, this.history);
  }

  writeCallBack = () => {
    const buffer = this.createPromptBuffer();
    this.toggleHistory(buffer);
    this.dispatchBufferChange(buffer);
    this.dispatchChangeInput(buffer);
  };
  /**
   * Create buffer of inputted data
   * @param {IBuffer} [buffer=this.buffer]
   * @return {PromptBuffer}
   */
  private createPromptBuffer = (buffer: IBuffer = this.buffer): PromptBuffer => {
    this.term.scrollToBottom();

    const config: PromptBufferConfig = {
      prompt: this.prompt,
      cols: this.term.cols,
      rows: this.term.rows,
      history: Number.isInteger(this.history.position)
    };
    return new PromptBuffer(buffer, config);
  };
  /**
   * toggle history loop
   * @param {PromptBuffer} [buffer=this.createPromptBuffer()]
   */
  private toggleHistory = (buffer:PromptBuffer = this.createPromptBuffer()) => {
    const lines = Array.from(buffer.getCacheLines(true).values()).filter(i => i.type === LineTypes.New);
    lines.length ? this.history.stop() : this.history.start();
  };
  /**
   * async write input to terminal from Rxjs query
   *
   * @private
   * @param {string[]} commands
   * @return {Promise<unknown>}
   */
  private writeAsync(commands: string[]): Promise<unknown> {
    const head = ansiEscapes.cursorRestorePosition + ansiEscapes.cursorHide;
    const tail = ansiEscapes.cursorSavePosition + ansiEscapes.cursorShow;

    this.term.write(head);
    const promised = new Promise((resolve) => {
      this.term.write(flatten(commands).join('') + tail, () => {
        setTimeout(() => {
         resolve(commands)
        })
      })
    })

    return promised
      .then((c) => {
        this.createPromptBuffer();
        this.applyServiceLine();
        this.term.scrollToBottom();

        return c;
      })
      .catch(noop)
  }
  /**
   * write input to terminal
   *
   * @param {string[]} commands
   * @param {Function} callback
   * @return {void}
   */
  private write = (commands: string[], callback = () => null) => {
    if (this.state === TerminalState.LOCK) {
      return;
    }

    const head = ansiEscapes.cursorRestorePosition;
    const tail = ansiEscapes.cursorSavePosition;

    this.term.write(head);
    const promises = flatten(commands).map(
      command =>
        new Promise(resolve => {
          this.term.write(command, () => resolve(true));
        })
    );

    Promise.all(promises)
      .then(() => {
        this.dispatchBufferChange();
        this.term.write(tail);
        this.applyServiceLine()
        callback();
      })
      .catch(noop);
  };
  /**
   * dispose xterm hook
   * API method
   */
  dispose(): void {
    this.events$.dispose();
    this.processing?.unsubscribe();
    this.asyncQuery?.unsubscribe();
  }
}
