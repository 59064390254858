import { NotificationPlanRule, NotifyToPlatform } from '../../../shared/models/Notification';

export interface CustomAlertingStore {
  loading: boolean;
  type: TypeOfSetting;
  id: string;
  settings: CustomAlertingSettings;
  globalSettings: CustomAlertingSettings;
  companySettings: CustomAlertingSettings;
  computerSettings: CustomAlertingSettings;
}

export enum TypeOfSetting {
  Global = 'global',
  Company = 'company',
  Computer = 'computer'
}

export interface AlertingScanRange {
  type: TypeOfSetting;
  id?: string;
}

export interface CustomAlertingSettings {
  recipient: { rule?: number; type?: number; emails: [] };
  pushRecipient: { type: NotificationPlanRule; users: string[] };
  pushNotification: { enabled: boolean };
  highCpuUsage: HighCpuUsage;
  highMemoryUsage: HighMemoryUsage;
  noAntivirus: NoAntivirus;
  lowDiskSpace: LowDiskSpace;
  notUpdated: NotUpdated;
  wrongSystemTime: WrongSystemTime;
  smartNotHealthy: SmartNotHealthy;
  connectionLoss: ConnectionLoss;
  highTemperature: HighTemperature;
  softwareChanges: SoftwareChanges;
  hardwareChanges: HardwareChanges;
  snmpDevice: SnmpDevice;
  serviceState: ServiceState;
}

export enum RMMAlertTypes {
  HighCpuUsage = 'highCpuUsage',
  HighTemperature = 'highTemperature',
  HighMemoryUsage = 'highMemoryUsage',
  WrongSystemTime = 'wrongSystemTime',
  SmartNotHealthy = 'smartNotHealthy',
  ConnectionLoss = 'connectionLoss',
  NoAntivirus = 'noAntivirus',
  NotUpdated = 'notUpdated',
  ServiceState = 'serviceState',
  SoftwareChanges = 'softwareChanges',
  HardwareChanges = 'hardwareChanges',
  LowDiskSpace = 'lowDiskSpace',
  SnmpDevice = 'snmpDevice'
}

export enum RMMAlertPushSettings {
  PushRecipient = 'pushRecipient',
  PushNotification = 'pushNotification'
}

export interface HighCpuUsage {
  warningPercent: number;
  alertPercentMin: number;
  alertPercent: number;
  duration: string;
  enabled: boolean;
}

export interface HighMemoryUsage {
  warningPercent: number;
  alertPercentMin: number;
  alertPercent: number;
  duration: string;
  enabled: boolean;
}

export interface NoAntivirus {
  duration: string;
  enabled: boolean;
}

export interface LowDiskSpace {
  warningPercent: number;
  alertPercentMin: number;
  alertPercent: number;
  excludedVolumeLabels: [];
  excludedMountPoints: [];
  excludeBootVolume: boolean;
  excludeRemovableDisks: boolean;
  duration: string;
  enabled: boolean;
}

export interface NotUpdated {
  duration: string;
  enabled: boolean;
}

export interface WrongSystemTime {
  alertWrongSystemTimeDifference: string;
  duration: string;
  enabled: boolean;
}

export interface SmartNotHealthy {
  duration: string;
  enabled: boolean;
}

export interface ConnectionLoss {
  enabled: boolean;
  notifyToPlatform: NotifyToPlatform;
  notifyOnRestore: boolean;
  duration: string;
}

export interface HighTemperature {
  temperatureMin: number;
  alertHighTemperature: number;
  duration: string;
  enabled: boolean;
}

export interface SoftwareChanges {
  excludedNames: [];
  excludedVendor: [];
  enabled: boolean;
}

export interface HardwareChanges {
  excludedNames: string[];
  excludedPNPClasses: string[];
  excludedPNPDeviceIDs: string[];
  excludedManufacturer: [];
  enabled: boolean;
}

export interface SnmpDevice {
  alerts: string[];
  enabled: boolean;
}

export interface ServiceState {
  enabled: boolean;
  duration: string;
  includeServices: IncludeServices[];
}

export const RmmNotificationFormDefaultValues = {
  recipient: {
    type: NotificationPlanRule.None,
    emails: []
  },
  pushNotification: { enabled: false },
  pushRecipient: {
    type: NotificationPlanRule.None,
    users: []
  },
  [RMMAlertTypes.HighCpuUsage]: { enabled: false },
  [RMMAlertTypes.HighMemoryUsage]: { enabled: false },
  [RMMAlertTypes.LowDiskSpace]: { enabled: false },
  [RMMAlertTypes.SmartNotHealthy]: { enabled: false },
  [RMMAlertTypes.NoAntivirus]: { enabled: false },
  [RMMAlertTypes.NotUpdated]: { enabled: false },
  [RMMAlertTypes.SoftwareChanges]: { enabled: false },
  [RMMAlertTypes.HardwareChanges]: { enabled: false },
  [RMMAlertTypes.ConnectionLoss]: { enabled: false },
  [RMMAlertTypes.SnmpDevice]: { enabled: false },
  [RMMAlertTypes.ServiceState]: { enabled: false },
  [RMMAlertTypes.HighTemperature]: { enabled: false },
  [RMMAlertTypes.WrongSystemTime]: { enabled: false }
};

export interface IncludeServices {
  failedRestart: boolean;
  failedStart: boolean;
  serviceDisplayName: string;
  serviceName: string;
  stop: boolean;
}
