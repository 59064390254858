import { APP_BASE_HREF } from '@angular/common';
import { Component, EventEmitter, Inject, Output, QueryList, Type, ViewChildren } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { PaymentDebtModalComponent } from '@components/payment-debt-modal/payment-debt-modal.component';
import { PaymentModalComponent } from '@components/payment-modal/payment-modal.component';
import { AdministratorsFacade } from '@facades/administrators.facade';
import { ApplicationStateFacade } from '@facades/application.facade';
import { BuildsFacade } from '@facades/builds.facade';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { environment } from '@mbs-ui/environments/environment';
import AccountManager from '@models/AccountManager';
import Administrator from '@models/Administrator';
import AdministratorInCamelCase from '@models/AdministratorInCamelCase';
import { AppEnvironmentState } from '@models/AppEnvironmentState';
import { AgentType, ComputerModeAdapter, ComputersMode } from '@models/Computer';
import { LicenseType } from '@models/LicenseType.enum';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { addNew } from '@models/SidePanelUriParams';
import { UiStorageKey } from '@models/ui-storage';
import { SidepanelPasswordRecoveryComponent } from '@modules/password-recovery/components/sidepanel-password-recovery/sidepanel-password-recovery.component';
import { RMMGroupActionWizardComponent } from '@modules/schedule-group-action/rmm-group-action-wizard.component';
import { TryRMMForProvidersWizardComponent } from '@modules/wizards/try-rmm-for-providers/try-rmm-for-providers.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { AdministratorService } from '@services/administrator.service';
import { AppEnvStateService } from '@services/app-env-state.service';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { AuthService } from '@services/auth.service';
import { ConnectSettingsService } from '@services/connect.settings.service';
import { LicensesService } from '@services/licenses.service';
import { ProviderService } from '@services/provider.service';
import { SupportPortalService } from '@services/support-portal.service';
import { POST_PAYMENT_TOKEN } from '@shared/components/licenses/tokens/post-payment.token';
import { APP_BACKEND_BUILD_VERSIONS } from '@utils/app-backend-build-versions';
import { isFontExists } from '@utils/isFontExists';
import { AbilityService, Action } from 'ability';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { MbsSize, ModalService, Sidepanel, SidepanelService, ToastService } from 'mbs-ui-kit';
import { Observable, combineLatest, noop, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { NavCollapsedHideDirective } from '../../directives/nav-collapsed-hide.directive';
import { SidepanelDownloadsComponent } from '../sidepanel-downloads/sidepanel-downloads.component';
import { SidepanelIpWhiteListComponent } from '../sidepanel-ip-white-list/sidepanel-ip-white-list.component';
import { SidepanelOnlineAccessComponent } from '../sidepanel-online-access/sidepanel-online-access.component';

interface MenuItemType {
  name: string;
  iconClass?: string;
  secondName?: string;
  secondIconClass?: string;
  routeUrl?: string;
  queryParams?: { [key: string]: string };
  externalUrl?: string;
  target?: '_blank' | '_self';
  action?: () => void;
  children?: Array<MenuItemType>;
  can?: boolean;
  title?: string;
  id: string;
  isHorizontalDivider?: boolean;
  hasDot?: true;
}

@UntilDestroy()
@Component({
  selector: 'mbs-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent {
  private currentUser$: Observable<Administrator>;
  public currentUser: Administrator;
  public helpMenuItems: Array<MenuItemType> = [];
  public addMenuItems: Array<MenuItemType> = [];
  public mainMenu: Array<MenuItemType> = [];
  public appEnvState: AppEnvironmentState;
  public accountManager: AccountManager;

  @ViewChildren(NavCollapsedHideDirective) collapse: QueryList<NavCollapsedHideDirective>;
  @Output() openFeedback = new EventEmitter();

  public isNavbarCollapsed = true;
  public downloadPanel = null;
  public showTryRMMBtn = false;
  public showAddBtn = false;
  public showBalanceBtn = false;
  public showMainUserMenuBadge = false;
  public isProduction = environment.production;

  private myProfileLink = RoutingPath.ApSettings;
  private connectClientUrl: string = environment.raDownloadURL.windows;

  public balance = null;

  public get applicationVersions(): string {
    return '' + this.backendVersion;
  }

  public get getNavbarButtonsClass(): string {
    const coef = (this.showAddBtn ? 10 : 0) + (this.showTryRMMBtn ? 10 : 0) + (this.showBalanceBtn ? 15 : 0);
    return `navbar-buttons-${coef}`;
  }

  private createdSidepanels = new Set<Type<Sidepanel>>();
  public mode2022 = false;
  private showDownloadsUpdatesDot = false;

  constructor(
    private auth: AuthService,
    @Inject(APP_BASE_HREF) private appBaseHref: string,
    private router: Router,
    private ability: AbilityService,
    private sidepanelService: SidepanelService,
    public providerService: ProviderService,
    @Inject(APP_BACKEND_BUILD_VERSIONS) private backendVersion: string,
    private modalService: ModalService,
    private stateService: AppEnvStateService,
    private adminService: AdministratorService,
    private computersFacade: ComputersFacade,
    private buildsFacade: BuildsFacade,
    private connectSettings: ConnectSettingsService,
    private adminsFacade: AdministratorsFacade,
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    private appPersistentState: AppPersistentStateService,
    private applicationStateFacade: ApplicationStateFacade,
    @Inject(POST_PAYMENT_TOKEN) public isPostPayment: Observable<boolean>,
    private licensesService: LicensesService,
    private supportPortalService: SupportPortalService,
    private toastService: ToastService
  ) {
    this.currentUser$ = auth.currentUser;
    this.initStreams();
  }

  private initStreams(): void {
    this.currentUser$.pipe(filter((user) => !!user)).subscribe((admin) => {
      this.setBalance(admin);
      this.currentUser = admin;
      this.updateMenu(admin);

      this.downloadPanel = SidepanelDownloadsComponent;
    });

    this.stateService.appEnvState$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.appEnvState = state;
    });

    this.router.events
      .pipe(
        filter((v) => v instanceof NavigationStart),
        filter((eventParams) => this.isUrlChanged(eventParams)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.isNavbarCollapsed = true;
        this.sidepanelService.remove('download_options');
        this.sidepanelService.remove('preinstall_options');
        this.sidepanelService.remove('global_downloads');

        this.modalService.dismissAll();

        this.createdSidepanels.forEach((s) => this.sidepanelService.removeByType(s));
        this.createdSidepanels = new Set();
      });

    this.appPersistentState.change.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateRmmLink();
    });
    this.buildsFacade.newVersionsAlerts$.pipe(untilDestroyed(this)).subscribe(({ showBackupUpdateDot, showRmmUpdateDot }) => {
      this.showDownloadsUpdatesDot = showBackupUpdateDot || showRmmUpdateDot;
    });

    this.connectSettings.getConnectClientBuild().subscribe((connectClientUrl) => {
      this.connectClientUrl = connectClientUrl;
    });

    combineLatest([this.supportPortalService.getAccountManager(), this.applicationStateFacade.getUiStorage()])
      .pipe(untilDestroyed(this))
      .subscribe(([accountManager, storage]) => {
        this.accountManager = accountManager;
        this.showMainUserMenuBadge =
          this.ability.can('read', 'HelpMarketing') && storage[UiStorageKey.HideMainUserMenuBadge] !== 'true' && !!accountManager;
      });
  }

  setBalance(user: Administrator) {
    this.licensesService
      .getBalance()
      .pipe(
        shareReplay(),
        catchError(() => of(null)),
        map((balance) => {
          const balanceValue = `${balance?.subscriptionCurrencySign}${Math.abs(balance?.balance)}`;
          return {
            ...balance,
            balance: !balance?.hasDebt ? balanceValue : `- ${balanceValue}`
          };
        })
      )
      .subscribe((balance) => {
        this.balance = balance;
        this.showBalanceBtn = this.ability.can('read', 'LicenseBuy') && user.ProviderInfo.IsPostPayment && !!this.balance;
      });
  }

  get homePageUrl(): string {
    return this.ability.can('read', 'Dashboard') ? '/AP/Default.aspx' : this.myProfileLink;
  }

  updateMenu(admin: Administrator): void {
    const ability = (rule, action: Action = 'read') => this.ability.can(action, rule);
    const canRead = (rules) => (Array.isArray(rules) ? rules.every((r) => ability(r)) : ability(rules));

    const groupIconClassName = 'fa fa-group';
    const userIconClassName = 'fa fa-user';
    const downloadIconClassName = 'fa fa-download';
    const wrenchIconClassName = 'fa fa-wrench';
    const barCharIconClassName = 'fa fa-bar-chart';
    const monitoringIconClassName = 'fa fa-line-chart';
    const globeIconClassName = 'fa fa-globe';
    const certificateIconClassName = 'fa fa-certificate';
    const magicIconClassName = 'fa fa-magic';
    const envelopeIconClassName = 'fa fa-envelope';
    const computerIconClassName = 'fa fa-television';
    const remoteDeployIconClassName = 'fa fa-play-circle-o';
    const restoreToCloudIconClassName = 'fa fa-cloud-upload';
    const settingsIconClassName = 'fa fa-cog';
    const storageIconClassName = 'ico ico-hdd-solid';
    const editIconClassName = 'fa fa-edit';
    const lockIconClassName = 'fa fa-lock';

    const sidePanelIpWhiteListAction = () => {
      this.sidepanelService.add(SidepanelIpWhiteListComponent);
      this.sidepanelService.toggleLoadingDataByType(SidepanelIpWhiteListComponent);
      this.sidepanelService.openByType(SidepanelIpWhiteListComponent).subscribe();
      this.createdSidepanels.add(SidepanelIpWhiteListComponent);
    };

    const RemoteDesktop = this.i18nextService.t('app:products.remote_desktop');

    this.mode2022 = canRead(PermissionsEnum.Menu2022);

    this.helpMenuItems = [
      {
        name: 'Documentation',
        id: 'documentation',
        iconClass: 'fa fa-question-circle',
        routeUrl: '/AP/Help/getting-started',
        title: this.applicationVersions,
        target: '_blank',
        can: true
      },
      {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        name: 'Knowledge Base',
        id: 'knowledge-base',
        iconClass: 'fa fa-book',
        externalUrl: 'https://kb.msp360.com/',
        title: 'Knowledge Base',
        target: '_blank',
        can: true
      },
      {
        name: 'Feedback',
        id: 'feedback',
        iconClass: envelopeIconClassName,
        action: () => this.openFeedback.emit(),
        title: 'Feedback',
        can: canRead('HelpMarketing') && !canRead('HideFeedback')
      },
      {
        name: 'Forum',
        id: 'forum',
        iconClass: 'fa fa-comment',
        externalUrl: 'http://forum.msp360.com',
        title: 'Forum',
        can: true
      },
      {
        name: 'Marketing',
        id: 'marketing',
        iconClass: 'fa fa-file',
        routeUrl: '/AP/Marketing',
        title: 'Marketing',
        can: canRead('HelpMarketing')
      },
      {
        name: 'Support',
        id: 'support',
        iconClass: 'fa fa-life-ring',
        action: () => this.auth.redirectToSupportPortal(),
        title: 'Support',
        can: true
      },
      {
        name: RemoteDesktop,
        id: 'rd',
        iconClass: 'fa fa-desktop',
        action: () => this.openOrDownloadBSP(),
        title: 'Download ' + RemoteDesktop,
        can: true
      }
    ];
    if (this.mode2022) {
      const computersMenu = [
        {
          name: 'Computers',
          id: 'rm',
          iconClass: computerIconClassName,
          routeUrl: this.getRmmLink(),
          can: canRead('RemoteManagement')
        }
      ];
      let backupMenu = [];
      if (
        canRead('RemoteManagement') ||
        canRead('Monitoring') ||
        canRead('RemoteDeploy') ||
        (canRead('CloudRestore') && !canRead('B2Only')) ||
        canRead('GoogleApps') ||
        (canRead('Storage') && !canRead('SolutionUnion')) ||
        canRead('Package')
      ) {
        backupMenu = [
          {
            name: 'Backup',
            id: 'rm-backup-parent',
            iconClass: 'mbs-agent mbs-agent-sm mbs-agent_backup',
            can: true,
            children: [
              {
                name: 'Computers',
                id: 'rm-backup',
                iconClass: computerIconClassName,
                routeUrl: this.getComputerUrlByMode(ComputersMode.Backup),
                can: canRead('RemoteManagement')
              },
              {
                name: 'Monitoring / History',
                id: 'monitoring-history',
                iconClass: monitoringIconClassName,
                routeUrl: '/AP/Monitoring',
                can: canRead('Monitoring')
              },
              {
                name: 'Backup Templates',
                id: 'remote-deploy',
                iconClass: remoteDeployIconClassName,
                routeUrl: '/AP/BackupTemplates',
                can: canRead('RemoteDeploy')
              },
              {
                name: 'Restore to Cloud',
                id: 'restore-to-cloud',
                iconClass: restoreToCloudIconClassName,
                routeUrl: '/AP/Restore2Cloud',
                can: canRead('CloudRestore') && !canRead('B2Only')
              },
              {
                isHorizontalDivider: true,
                name: 'HR1',
                id: 'hr1',
                can: canRead('GoogleApps')
              },
              {
                name: 'M365/Google',
                id: 'gapps',
                iconClass: envelopeIconClassName,
                action: () => this.router.navigateByUrl(RoutingPath.ApAppsDomains),
                can: canRead('GoogleApps')
              },
              {
                isHorizontalDivider: true,
                name: 'HR2',
                id: 'hr2',
                can: (canRead('Storage') && !canRead('SolutionUnion')) || canRead('Package')
              },
              {
                // eslint-disable-next-line sonarjs/no-duplicate-string
                name: 'Storage Accounts',
                // eslint-disable-next-line sonarjs/no-duplicate-string
                id: 'storage-accounts',
                iconClass: storageIconClassName,
                routeUrl: RoutingPath.ApEditAccountAspx,
                can: canRead('Storage') && !canRead('SolutionUnion')
              },
              {
                name: 'Storage Limits',
                id: 'storage-limits',
                iconClass: storageIconClassName,
                routeUrl: '/AP/Tariffs.aspx',
                can: canRead('Package')
              }
            ]
          }
        ];
      }
      let m365Menu = [];
      if (canRead('GoogleApps')) {
        m365Menu = [
          {
            name: 'M365/Google Backup',
            id: 'appsMenu',
            iconClass: `text-primary ${envelopeIconClassName}`,
            can: true,
            children: [
              {
                name: 'General',
                id: 'apps-general',
                iconClass: wrenchIconClassName,
                routeUrl: RoutingPath.ApAppsGeneral,
                can: true
              },
              {
                name: 'Domains',
                id: 'apps-domains',
                iconClass: envelopeIconClassName,
                routeUrl: RoutingPath.ApAppsDomains,
                can: true
              },
              {
                name: 'Storage Accounts',
                id: 'storage-accounts',
                iconClass: storageIconClassName,
                action: () => this.router.navigateByUrl(RoutingPath.ApEditAccountAspx),
                can: canRead('Storage') && !canRead('SolutionUnion')
              }
            ]
          }
        ];
      }
      let rmmMenu = [];
      if (canRead('RemoteManagement') && canRead('Rmm')) {
        rmmMenu = [
          {
            name: 'RMM',
            id: 'rm-rmm-parent',
            iconClass: 'mbs-agent mbs-agent-sm mbs-agent_rmm',
            can: true,
            children: [
              {
                name: 'Computers',
                id: 'rm-rmm',
                iconClass: computerIconClassName,
                routeUrl: this.getComputerUrlByMode(ComputersMode.RMM),
                can: canRead('RemoteManagement')
              },
              {
                name: 'RMM Alert History',
                id: 'rmm-alerts-history',
                iconClass: barCharIconClassName,
                routeUrl: '/AP/RMMAlertsHistory',
                can: canRead('Notification') && canRead('Rmm')
              },
              {
                name: 'New Group Action Task',
                id: 'schedule-tasks',
                iconClass: 'ico ico-calendar-alt',
                action: () => this.openRMMGroupActionNew(),
                can: canRead('Rmm')
              },
              {
                name: 'Group Actions Tasks',
                id: 'schedule-tasks',
                iconClass: 'ico ico-calendar-alt',
                routeUrl: '/AP/RMMGroupTaskAction',
                can: canRead('Rmm')
              },
              {
                name: 'RMM Script Library',
                id: 'script-library',
                iconClass: 'fa fa-file-text',
                routeUrl: '/AP/ScriptLibrary',
                can: canRead('Rmm')
              },
              {
                name: 'SNMP Monitoring',
                id: 'snmp-monitoring',
                iconClass: 'ico ico-snmp',
                routeUrl: '/AP/Snmp',
                can: canRead('Rmm')
              }
            ]
          }
        ];
      } else if (canRead('RemoteManagement')) {
        rmmMenu = [
          {
            name: 'RMM',
            id: 'rm-rmm-parent',
            iconClass: 'mbs-agent mbs-agent-sm mbs-agent_rmm',
            routeUrl: this.getComputerUrlByMode(ComputersMode.RMM),
            can: true
          }
        ];
      }
      const reportingMenu = [
        {
          name: 'Reporting',
          id: 'reporting',
          iconClass: barCharIconClassName,
          can: true,
          children: [
            {
              name: 'Plan Report Settings',
              id: 'plan-report-settings',
              iconClass: 'fa fa-envelope-square',
              routeUrl: '/AP/PlanReportSettings',
              can: canRead('Notification')
            },
            {
              name: 'Scheduled Reports',
              id: 'scheduled-reports',
              iconClass: 'fa fa-clock-o',
              routeUrl: '/AP/ScheduledReports.aspx',
              can: canRead('Notification')
            },
            {
              name: 'Billing',
              id: 'billing',
              iconClass: 'fa fa-money',
              routeUrl: '/AP/Billing.aspx',
              can: canRead('Billing') && !canRead('NinjaMode')
            },
            {
              name: 'Backup History',
              id: 'backup-history',
              iconClass: editIconClassName,
              routeUrl: '/AP/BackupHistory',
              can: canRead('BackupHistory')
            },
            {
              name: 'License Usage',
              id: 'license-usage',
              iconClass: 'fa fa-random',
              routeUrl: '/AP/LicenseUsageHistory.aspx',
              can: canRead('LicenseUsageHistory')
            },
            {
              name: 'Storage Usage',
              id: 'storage-usage',
              iconClass: barCharIconClassName,
              routeUrl: '/AP/StorageUsageReport.aspx',
              can: canRead('StorageUsage')
            },
            {
              name: 'Capacity Report',
              id: 'capacity-report',
              iconClass: 'fa fa-pie-chart',
              routeUrl: '/AP/StorageCapacityReport',
              can: canRead('CapacityReport')
            },
            {
              name: 'Emails Customization',
              id: 'emails-customization',
              iconClass: 'fa fa-edit',
              routeUrl: '/AP/EmailsCustomization.aspx',
              can: canRead('Notification')
            },
            {
              name: RemoteDesktop + ' Statistics',
              iconClass: monitoringIconClassName,
              routeUrl: '/AP/RDStatistics',
              can: canRead('RemoteManagement'),
              id: 'rd-stats'
            }
          ]
        }
      ];
      const organizationMenu = [
        {
          name: 'Organization',
          id: 'organization',
          iconClass: groupIconClassName,
          can: true,
          children: [
            {
              name: 'Users',
              id: 'users',
              iconClass: 'fa fa-user-circle',
              routeUrl: RoutingPath.ApUsers,
              can: canRead('Users')
            },
            {
              name: 'Licenses',
              id: 'licenses',
              iconClass: 'fa fa-key',
              action: () => this.handleLicensesPage(admin.ProviderInfo.IsPostPayment ? RoutingPath.ApLicenses : RoutingPath.ApLicensesAspx),
              can: canRead('Licenses')
            },
            {
              name: 'Purchase History',
              id: 'purchase-history',
              iconClass: 'fa fa-credit-card',
              routeUrl: '/AP/LicensesSubscriptionHistory.aspx',
              can: canRead('PurchaseHistory') && !canRead('NinjaMode') && !canRead('SolutionUnion')
            },
            {
              name: 'Companies',
              id: 'companies',
              iconClass: groupIconClassName,
              routeUrl: RoutingPath.ApCompanies,
              can: canRead('ManageCompanies')
            },
            {
              name: 'Administrators',
              id: 'administrators',
              iconClass: userIconClassName,
              routeUrl: RoutingPath.ApAdministrators,
              can: canRead('Administrator')
            },
            {
              name: 'Audit Log',
              id: 'audit-log',
              iconClass: 'fa fa-history',
              routeUrl: '/AP/AuditLog',
              can: canRead('AuditLog')
            }
          ]
        },
        {
          name: 'Settings',
          id: 'settings',
          iconClass: settingsIconClassName,
          can: true,
          children: [
            {
              name: 'General',
              id: 'general',
              iconClass: wrenchIconClassName,
              routeUrl: RoutingPath.ApSettings,
              can: canRead('Provider')
            },
            {
              name: 'IP Allowlisting',
              id: 'ip-allowlisting',
              iconClass: lockIconClassName,
              action: sidePanelIpWhiteListAction,
              can: admin.IsProvider
            },
            {
              name: 'Notifications',
              id: 'notifications',
              iconClass: envelopeIconClassName,
              routeUrl: RoutingPath.ApNotifications,
              can: canRead('Notification')
            },
            {
              name: 'Email Service',
              id: 'email-service',
              iconClass: envelopeIconClassName,
              routeUrl: '/AP/EmailService',
              can: canRead('Notification')
            },
            {
              name: 'ConnectWise Manage',
              id: 'connectWise-manage',
              iconClass: 'fa fa-rss',
              routeUrl: '/AP/ConnectWise/Authentication',
              can: canRead('PSA')
            },
            {
              name: 'Autotask',
              id: 'autotask',
              iconClass: 'fa fa-tasks',
              routeUrl: '/AP/IntegrationAutotask.aspx',
              can: canRead('PSA')
            },
            {
              name: 'Online Access',
              id: 'online-access',
              iconClass: globeIconClassName,
              action: () => this.handleOpenOnlineAccess(),
              can: canRead('OnlineAccess')
            },
            {
              name: 'Bindings And Certificates',
              id: 'bindings-and-certificates',
              iconClass: certificateIconClassName,
              routeUrl: '/AP/SSL',
              can: canRead('CustomCertificatesBinding')
            },
            {
              name: 'Web Console Rebranding',
              id: 'web-console-rebranding',
              iconClass: magicIconClassName,
              routeUrl: '/AP/WebConsoleRebranding',
              can: canRead('WebConsoleRebranding')
            },
            {
              name: 'Branding',
              id: 'branding',
              iconClass: 'fa fa-star',
              can: canRead('Rebranding') && canRead('ManageCompanies'),
              routeUrl: '/AP/Branding'
            },
            {
              name: 'Global Agent Options',
              id: 'global-agent-options',
              iconClass: userIconClassName,
              can: (canRead('ManageCompanies') && canRead('AccessToAllCompanies')) || admin.IsProvider,
              routeUrl: '/AP/GlobalAgentOptions'
            },
            {
              name: 'Password Recovery',
              id: 'password-recovery',
              can: admin.IsProvider,
              iconClass: lockIconClassName,
              action: () => this.openPasswordRecoverySidepanel()
            }
          ]
        },
        {
          name: 'Downloads',
          id: 'downloads',
          iconClass: downloadIconClassName,
          action: () => this.handleOpenDownloads(),
          can: canRead('Downloads')
        }
      ];
      this.mainMenu = [...computersMenu, ...backupMenu, ...m365Menu, ...rmmMenu, ...reportingMenu, ...organizationMenu];
    } else {
      this.mainMenu = [
        {
          name: 'My Profile',
          id: 'profile',
          // eslint-disable-next-line sonarjs/no-duplicate-string
          iconClass: 'fa fa-cloud',
          can: !admin.IsProvider,
          routeUrl: this.myProfileLink
        },
        {
          name: 'Storage',
          id: 'storage',
          iconClass: 'fa fa-cloud',
          can: true,
          children: [
            {
              name: 'Storage Accounts',
              id: 'storage-accounts',
              iconClass: 'fa fa-cloud',
              routeUrl: RoutingPath.ApEditAccountAspx,
              can: canRead('Storage') && !canRead('SolutionUnion')
            },
            {
              name: 'Storage Limits',
              id: 'storage-limits',
              iconClass: 'fa fa-briefcase',
              routeUrl: '/AP/Tariffs.aspx',
              can: canRead('Package')
            }
          ]
        },
        {
          name: 'Organization',
          id: 'organization',
          iconClass: userIconClassName,
          can: true,
          children: [
            {
              name: 'Users',
              id: 'users',
              iconClass: 'fa fa-user-circle',
              routeUrl: RoutingPath.ApUsers,
              can: canRead('Users')
            },
            {
              name: 'Licenses',
              id: 'licenses',
              iconClass: 'fa fa-key',
              action: () => this.handleLicensesPage(admin.ProviderInfo.IsPostPayment ? RoutingPath.ApLicenses : RoutingPath.ApLicensesAspx),
              can: canRead('Licenses')
            },
            {
              name: 'Purchase History',
              id: 'purchase-history',
              iconClass: 'fa fa-credit-card',
              routeUrl: '/AP/LicensesSubscriptionHistory.aspx',
              can: canRead('PurchaseHistory') && !canRead('NinjaMode') && !canRead('SolutionUnion')
            },
            {
              name: 'Companies',
              id: 'companies',
              iconClass: groupIconClassName,
              routeUrl: RoutingPath.ApCompanies,
              can: canRead('ManageCompanies')
            },
            {
              name: 'Administrators',
              id: 'administrators',
              iconClass: userIconClassName,
              routeUrl: RoutingPath.ApAdministrators,
              can: canRead('Administrator')
            },
            {
              name: 'Audit Log',
              id: 'audit-log',
              iconClass: 'fa fa-history',
              routeUrl: '/AP/AuditLog',
              can: canRead('AuditLog')
            }
          ]
        },
        {
          name: 'Downloads',
          id: 'downloads',
          hasDot: true,
          iconClass: downloadIconClassName,
          action: () => this.handleOpenDownloads(),
          can: canRead('Downloads')
        },
        {
          name: canRead('NinjaMode') ? 'Devices' : 'Computers',
          iconClass: globeIconClassName,
          can: true,
          id: 'rmm-parent',
          children: [
            {
              name: 'Monitoring / History',
              id: 'monitoring-history',
              iconClass: barCharIconClassName,
              routeUrl: '/AP/Monitoring',
              can: canRead('Monitoring')
            },
            {
              name: 'Remote Management',
              id: 'rmm',
              iconClass: globeIconClassName,
              routeUrl: this.getRmmLink(),
              can: canRead('RemoteManagement')
            },
            {
              name: 'Backup Templates',
              id: 'remote-deploy',
              iconClass: globeIconClassName,
              routeUrl: '/AP/BackupTemplates',
              can: canRead('RemoteDeploy')
            },
            {
              name: 'Restore to Cloud',
              id: 'restore-to-cloud',
              iconClass: globeIconClassName,
              routeUrl: '/AP/Restore2Cloud',
              can: canRead('CloudRestore') && !canRead('B2Only')
            }
          ]
        },
        {
          name: 'M365/Google',
          id: 'appsMenu',
          iconClass: envelopeIconClassName,
          can: canRead('GoogleApps'),
          children: [
            {
              name: 'General',
              id: 'apps-general',
              iconClass: wrenchIconClassName,
              routeUrl: RoutingPath.ApAppsGeneral,
              can: true
            },
            {
              name: 'Domains',
              id: 'apps-domains',
              iconClass: envelopeIconClassName,
              routeUrl: RoutingPath.ApAppsDomains,
              can: true
            }
          ]
        },
        {
          name: 'Reporting',
          id: 'reporting',
          iconClass: barCharIconClassName,
          can: true,
          children: [
            {
              name: 'Plan Report Settings',
              id: 'plan-report-settings',
              iconClass: 'fa fa-envelope-square',
              routeUrl: '/AP/PlanReportSettings',
              can: canRead('Notification')
            },
            {
              name: 'RMM Alert History',
              id: 'rmm-alerts-history',
              iconClass: barCharIconClassName,
              routeUrl: '/AP/RMMAlertsHistory',
              can: canRead('Notification') && canRead('Rmm')
            },
            {
              name: 'Scheduled Reports',
              id: 'scheduled-reports',
              iconClass: 'fa fa-clock-o',
              routeUrl: '/AP/ScheduledReports.aspx',
              can: canRead('Notification')
            },
            {
              name: 'Billing',
              id: 'billing',
              iconClass: 'fa fa-money',
              routeUrl: '/AP/Billing.aspx',
              can: canRead('Billing') && !canRead('NinjaMode')
            },
            {
              name: 'Backup History',
              id: 'backup-history',
              iconClass: editIconClassName,
              routeUrl: '/AP/BackupHistory',
              can: canRead('BackupHistory')
            },
            {
              name: 'License Usage',
              id: 'license-usage',
              iconClass: 'fa fa-random',
              routeUrl: '/AP/LicenseUsageHistory.aspx',
              can: canRead('LicenseUsageHistory')
            },
            {
              name: 'Storage Usage',
              id: 'storage-usage',
              iconClass: barCharIconClassName,
              routeUrl: '/AP/StorageUsageReport.aspx',
              can: canRead('StorageUsage')
            },
            {
              name: 'Capacity Report',
              id: 'capacity-report',
              iconClass: 'fa fa-pie-chart',
              routeUrl: '/AP/StorageCapacityReport',
              can: canRead('CapacityReport')
            },
            {
              name: 'Emails Customization',
              id: 'emails-customization',
              iconClass: editIconClassName,
              routeUrl: '/AP/EmailsCustomization.aspx',
              can: canRead('Notification')
            },
            {
              name: RemoteDesktop + ' Statistics',
              iconClass: monitoringIconClassName,
              routeUrl: '/AP/RDStatistics',
              can: canRead('RemoteManagement'),
              id: 'rd-stats'
            }
          ]
        },
        {
          name: 'Settings',
          id: 'settings',
          iconClass: wrenchIconClassName,
          can: true,
          children: [
            {
              name: 'General',
              id: 'general',
              iconClass: wrenchIconClassName,
              routeUrl: RoutingPath.ApSettings,
              can: canRead('Provider')
            },
            {
              name: 'IP Allowlisting',
              id: 'ip-allowlisting',
              iconClass: lockIconClassName,
              action: sidePanelIpWhiteListAction,
              can: admin.IsProvider
            },
            {
              name: 'Notifications',
              id: 'notifications',
              iconClass: envelopeIconClassName,
              routeUrl: RoutingPath.ApNotifications,
              can: canRead('Notification')
            },
            {
              name: 'Email Service',
              id: 'email-service',
              iconClass: envelopeIconClassName,
              routeUrl: '/AP/EmailService',
              can: canRead('Notification')
            },
            {
              name: 'ConnectWise Manage',
              id: 'connectWise-manage',
              iconClass: 'fa fa-rss',
              routeUrl: '/AP/ConnectWise/Authentication',
              can: canRead('PSA')
            },
            {
              name: 'Autotask',
              id: 'autotask',
              iconClass: 'fa fa-tasks',
              routeUrl: '/AP/IntegrationAutotask.aspx',
              can: canRead('PSA')
            },
            {
              name: 'Online Access',
              id: 'online-access',
              iconClass: globeIconClassName,
              action: () => this.handleOpenOnlineAccess(),
              can: canRead('OnlineAccess')
            },
            {
              name: 'Bindings And Certificates',
              id: 'bindings-and-certificates',
              iconClass: certificateIconClassName,
              routeUrl: '/AP/SSL',
              can: canRead('CustomCertificatesBinding')
            },
            {
              name: 'Web Console Rebranding',
              id: 'web-console-rebranding',
              iconClass: magicIconClassName,
              routeUrl: '/AP/WebConsoleRebranding',
              can: canRead('WebConsoleRebranding')
            },
            {
              name: 'Branding',
              id: 'branding',
              iconClass: 'fa fa-star',
              can: canRead('Rebranding') && canRead('ManageCompanies'),
              routeUrl: '/AP/Branding'
            },
            {
              name: 'Global Agent Options',
              id: 'global-agent-options',
              iconClass: userIconClassName,
              can: (canRead('ManageCompanies') && canRead('AccessToAllCompanies')) || admin.IsProvider,
              routeUrl: '/AP/GlobalAgentOptions'
            },
            {
              name: 'Password Recovery',
              id: 'password-recovery',
              can: admin.IsProvider,
              iconClass: lockIconClassName,
              action: () => this.openPasswordRecoverySidepanel()
            }
          ]
        }
      ];
    }

    this.addMenuItems = [
      {
        name: 'Computer',
        id: 'add-computer',
        iconClass: computerIconClassName,
        action: () => this.addComputerByMode(),
        can: canRead('RemoteManagement')
      },
      {
        name: 'Company',
        id: 'add-company',
        iconClass: groupIconClassName,
        routeUrl: `${RoutingPath.ApCompanies}/${addNew}`,
        can: canRead('ManageCompanies')
      },
      {
        name: 'M365/Google Domain',
        id: 'add-apps-domain',
        iconClass: envelopeIconClassName,
        routeUrl: RoutingPath.ApAppsDomains,
        queryParams: { addNew: 'true' },
        can: canRead('GoogleApps')
      },
      {
        name: 'License',
        id: 'add-license',
        iconClass: 'fa fa-key',
        action: () => this.handleLicensesPage(RoutingPath.ApLicensesAspx, { buy: 'true' }),
        can: canRead('LicenseBuy') && !admin.ProviderInfo.IsPostPayment
      },
      {
        name: 'Credit',
        id: 'add-credit',
        iconClass: 'fa fa-usd',
        action: () => this.openPaymentModal(), // top up
        can: canRead('LicenseBuy') && admin.ProviderInfo.IsPostPayment
      }
    ];
    // buttons
    this.showAddBtn = canRead('RemoteManagement') || canRead('ManageCompanies') || canRead('GoogleApps') || canRead('LicenseBuy');
    this.enableTryRMMWizardBtn();

    this.updateMainMenu();
    this.updateHelpMenu();
  }

  private updateExternalUrl(menu: MenuItemType) {
    if (menu.externalUrl && menu.externalUrl.startsWith('/AP')) {
      menu.externalUrl = this.appBaseHref + menu.externalUrl;
    }
    return menu;
  }

  updateMainMenu(): void {
    this.mainMenu.forEach((menu) => {
      this.updateExternalUrl(menu);
      if (menu.children) {
        menu.can = menu.can && menu.children.some((c) => c.can);
        menu.children.forEach((childMenuItem) => this.updateExternalUrl(childMenuItem));
      }
    });
  }

  updateHelpMenu(): void {
    this.helpMenuItems.forEach((menu) => this.updateExternalUrl(menu));
  }

  filterCanMenus(menus: MenuItemType[]) {
    return menus?.filter((m) => m.can);
  }

  isActiveRoute(route) {
    return this.router.isActive(route, { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' });
  }

  private getActiveChild(menuItem: MenuItemType): MenuItemType {
    const getActiveSubMenuItem = (subMenuItems: MenuItemType[]): MenuItemType => {
      if (subMenuItems) {
        for (let i = 0; i < subMenuItems.length; i++) {
          const child = subMenuItems[i];
          if (child?.children?.length) {
            const found = getActiveSubMenuItem(subMenuItems[i].children);
            if (found) return found;
          } else if (!child.action && !child.isHorizontalDivider && this.isActiveRoute(child.routeUrl || child.externalUrl)) {
            return child;
          }
        }
      }
      return undefined;
    };

    return getActiveSubMenuItem(menuItem?.children);
  }

  getActiveSubTitle(menuItem: MenuItemType): string {
    return this.getActiveChild(menuItem)?.name ?? '';
  }

  isActiveMenuClass(menuItem: MenuItemType): boolean {
    return !!this.getActiveSubTitle(menuItem);
  }

  getParentIcon(menuItem: MenuItemType): string {
    return this.getActiveChild(menuItem)?.iconClass ?? menuItem.iconClass;
  }

  displayName(user) {
    return user.FirstName + ' ' + user.LastName;
  }

  handleOpenSidepanel<T extends Sidepanel>(type: Type<T>): Observable<T> {
    if (!this.currentUser.IsWizardComplete) {
      return of<T>(null);
    }
    const panel = this.sidepanelService.get(type) || this.sidepanelService.add(type);
    this.createdSidepanels.add(type);

    this.isNavbarCollapsed = true;

    return this.sidepanelService.openByType(type).pipe(
      map(() => panel),
      untilDestroyed(this)
    );
  }

  openPasswordRecoverySidepanel(): void {
    this.handleOpenSidepanel(SidepanelPasswordRecoveryComponent).subscribe();
  }

  openOrDownloadBSP() {
    const openBSPLnk = 'cblra://qwer';
    const isFontExist = isFontExists('CloudBerry1');
    if (isFontExist) {
      window.open(openBSPLnk, '_self');
    } else {
      window.open(this.connectClientUrl, '_blank');
    }
  }

  handleOpenDownloads(): void {
    this.handleOpenSidepanel(this.downloadPanel).subscribe();
  }

  handleOpenOnlineAccess(): void {
    this.handleOpenSidepanel(SidepanelOnlineAccessComponent).subscribe();
  }

  handleLogout(): void {
    this.auth.logoutUser();
  }

  handleMyProfile() {
    this.router.navigate([this.myProfileLink]);
  }

  handleLicensesPage(url: string, queryParams: { [key: string]: string } = null) {
    this.appPersistentState.data.licensesPageOpened = true;
    this.router.navigate([url], { queryParams });
  }

  getRmmLink(): string {
    return this.getComputerUrlByMode(ComputersMode.Computers);
  }

  addComputerByMode(): void {
    const mode = ComputerModeAdapter.find((modeUrl) => this.router.url.includes(modeUrl.url))?.mode ?? ComputersMode.Computers;
    const url = this.getComputerUrlByMode(mode);
    const queryParamsHandling = this.isActiveRoute(url) ? 'merge' : '';

    this.router.navigate([url], {
      queryParams: { addNew: mode === ComputersMode.RMM ? AgentType.RMM : AgentType.Backup },
      queryParamsHandling
    });
  }

  updateRmmLink(): void {
    if (this.mode2022) {
      const rm = this.mainMenu.find((el) => el.id === 'rm');
      rm.routeUrl = this.getRmmLink();
    } else {
      const rmmParent = this.mainMenu.find((el) => el.id === 'rmm-parent');
      if (rmmParent) {
        const rmmItem = rmmParent.children.find((el) => el.id === 'rmm');
        rmmItem.routeUrl = this.getRmmLink();
      }
    }
  }

  public openPaymentModal() {
    this.modalService
      .openCustom(PaymentModalComponent, {
        data: {
          title: this.i18nextService.t('app:paymentModal:title', {
            format: 'title'
          }),
          userId: this.currentUser.Id
        },
        size: MbsSize.sm
      })
      .finally(noop);
  }

  public openPaymentDebtModal() {
    this.modalService
      .openCustom(PaymentDebtModalComponent, {
        data: {
          title: this.i18nextService.t('app:paymentDebtModal:title', {
            format: 'title'
          }),
          userId: this.currentUser.Id
        },
        size: MbsSize.sm
      })
      .finally(noop);
  }

  // @TODO uncomment
  handleRMMGettingStartedWizard(): void {
    this.modalService
      .openRef(TryRMMForProvidersWizardComponent, {
        size: MbsSize.md
      })
      .result.finally(() => this.enableTryRMMWizardBtn());
  }

  private enableTryRMMBtnPermissionCheck(): boolean {
    return this.currentUser.IsProvider && this.adminService.isTrialLicenseAvailable(LicenseType.RMM) && !this.currentUser.IsSuperAdmin;
  }

  private noPurchasedRMMLicense(provider: AdministratorInCamelCase): boolean {
    if (provider.license && provider.license.licenseType === LicenseType.RMM) {
      return provider.license.isTrial;
    } else {
      return true;
    }
  }

  private enableTryRMMWizardBtn(): void {
    if (this.enableTryRMMBtnPermissionCheck()) {
      this.computersFacade.applicationsCountAfterInit$
        .pipe(
          take(1),
          map((installedAppsByType) => (installedAppsByType.backup > 0 || installedAppsByType.ra > 0) && !installedAppsByType.rmm),
          switchMap((showTryRMMBtn) => {
            showTryRMMBtn && this.adminsFacade.loadData();

            return showTryRMMBtn
              ? this.adminsFacade.getDataWhenLoaded$.pipe(
                  take(1),
                  map((adminList) => {
                    const provider = adminList.find((admin) => admin.isProvider);

                    return provider ? this.noPurchasedRMMLicense(provider) : true;
                  })
                )
              : of(false);
          }),
          untilDestroyed(this)
        )
        .subscribe((showTryRMMBtn) => (this.showTryRMMBtn = showTryRMMBtn));
    } else {
      this.showTryRMMBtn = false;
    }
    this.computersFacade.loadApplicationsCount();
  }

  public openRMMGroupActionNew() {
    if (this.ability.can('read', PermissionsEnum.Rmm)) {
      this.modalService.openWizard(RMMGroupActionWizardComponent, { size: 'lg' }).finally(noop);
      return true;
    }
    return false;
  }

  public hasUpdates(item) {
    if (item.id === 'downloads') {
      return this.showDownloadsUpdatesDot;
    }

    return false;
  }

  public get hasUserUpdates(): boolean {
    return this.showMainUserMenuBadge;
  }

  public userSawUpdates(): void {
    if (!this.showMainUserMenuBadge) return;

    this.showMainUserMenuBadge = false;
    this.applicationStateFacade.setStorageSetting(UiStorageKey.HideMainUserMenuBadge, 'true', true).subscribe();
  }

  public handleCopyAccountManagerEmail(): void {
    if (!this.accountManager?.email) return;

    navigator.clipboard.writeText(this.accountManager.email);
    this.toastService.success(this.i18nPipe.transform('app-header:texts.emailCopied'));
  }

  public getAccountManagerPhotoURL(): string {
    if (!this.accountManager?.fullPhotoUrl) return '';

    const photoPathIndex = this.accountManager.fullPhotoUrl.lastIndexOf(this.supportPortalService.photoPath);

    if (!photoPathIndex) return '';

    return `${location.origin}/${this.accountManager.fullPhotoUrl.substring(photoPathIndex)}`;
  }

  private getComputerUrlByMode(mode: ComputersMode): string {
    return ComputerModeAdapter.find((modeUrl) => mode === modeUrl.mode)?.url ?? '';
  }

  private getUrlWithoutParams(url: string) {
    if (!url) return url;
    const urlTree = this.router.parseUrl(url);
    urlTree.queryParams = {};
    urlTree.fragment = null;
    return urlTree.toString();
  }

  private isUrlChanged(eventParams: any): boolean {
    const currentUrl = this.getUrlWithoutParams(this.router.url) ?? '';
    const nextUrl = this.getUrlWithoutParams(eventParams?.url) ?? '';
    return currentUrl !== nextUrl;
  }
}
