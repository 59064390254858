<section *ngIf="computer$ | async as computer"
         [attr.data-test-element]="elementsSelector.name.mainBlock">
  <div *ngIf="(visiblePlans$ | async) as plans"
       [attr.data-test-element]="elementsSelector.name.plansBlock">
    <!-- top controls -->
    <div *ngIf="plans?.length === 0 && computer?.userAccount?.id"
         class="mbs-form-group"
         [attr.data-test-element]="elementsSelector.name.noPlansHasUserAccountBlock">
      <div class="text-center">
        <img src="assets/load-image.svg"
             [attr.alt]="noPlansMessage" />
      </div>
      <div class="text-center text-gray-500 mt-1 mb-3">
        {{ noPlansMessage }}
      </div>
      <div *ngIf="!needDisableForRestore(computer) && canShowCreteButtons(computer)"
           class="text-center">
        <ng-container *ngTemplateOutlet="newPlanButton"></ng-container>
      </div>
    </div>

    <mbs-alert *ngIf="!computer?.userAccount?.id"
               [attr.data-test-element]="elementsSelector.name.noUserAccountAlert"
               [type]="alertType.info"
               [icon]="true">
      {{'computers.module:editAccount:please' | i18next}}
      <mbs-button [isCtrl]="true"
                  type="primary"
                  [size]="'md'"
                  (click)="authorizeComputer(computer)">
        {{'computers.module:editAccount:specifyUserAccount' | i18next}}
      </mbs-button>
    </mbs-alert>

    <div *ngIf="plans?.length > 0 && computer?.userAccount?.id"
         [attr.data-test-element]="elementsSelector.name.plansAndUserAccountBlock">
      <div class="form-row justify-content-end mb-3">
        <div *ngIf="!needDisableForRestore(computer) && canShowCreteButtons(computer)"
             class="col-auto">
          <ng-container *ngTemplateOutlet="newPlanButton"></ng-container>
        </div>
        <div class="col-auto">
          <mbs-button icon="ico ico-OpenHistory"
                      (click)="openBackupHistory(computer)">
            <span class="text-sm">
              History
            </span>
          </mbs-button>
        </div>
        <div class="col-auto">
          <mbs-button (click)="handleRefresh()"
                      icon="ico ico-Refresh">
          </mbs-button>
        </div>
      </div>
    </div>
    <!-- /top controls -->

    <mbs-alert *ngIf="syncProcess$ | async"
               [attr.data-test-element]="elementsSelector.name.syncProcessAlert"
               [type]="alertType.warning"
               [icon]="true">
      Repository synchronization in progress. Wait a while, once the synchronization is complete, you can run or edit plans.
      <br>
      <a *ngIf="ability.can('read', 'HelpMarketing')"
         href="/AP/Help/backup/about/repository-synchronization"
         target="_blank">
        {{ 'app:common.learnMore' | i18next }}
      </a>
    </mbs-alert>

    <div *ngIf="plans"
         class="row"
         [attr.data-test-element]="elementsSelector.name.plansListBlock">
      <div *ngFor="let plan of plans; trackBy: myTrackBy"
           class="col-12 py-2"
           [attr.data-test-element]="elementsSelector.name.planBlock"
           [ngClass]="{'col-lg-6 col-xxl-4': fullscreen}">
        <mbs-card [id]="'plan-'+plan.id"
                  [size]="'sm'">

          <mbs-card-header>
            <ng-template>
              <div class="w-100">
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <div class="d-flex align-items-center justify-content-center position-relative mbs-backup-plans-plan_img">
                      <img *ngIf="plan.encryptionSummary && plan.encryptionSummary !== 'Disabled'"
                           [attr.data-test-element]="elementsSelector.name.encryptionSummary"
                           class="mbs-backup-plans-plan_img-top"
                           src="assets/images/storages-tree/EncryptionLockPicture.svg">
                      <span *ngIf="mode === 'backup'"
                            [attr.data-test-element]="elementsSelector.name.modeBackup"
                            [ngClass]="[(plan.type | planType) || '', plan.lastRunInfo?.status | planStatus, 'mbs-backup-plans-plan_icon']"></span>
                      <span *ngIf="mode !== 'backup'"
                            [attr.data-test-element]="elementsSelector.name.modeNotBackup"
                            class="mbs-storage mbs-storage-sm"
                            [ngClass]="['mbs-storage_' + (getDestinationIconType(plan, destinationTypes) | storageIcon)]"></span>
                    </div>


                  </div>
                  <div class="col-auto">
                    <span class="fa fa-caret-right text-primary"></span>
                  </div>
                  <div class="col-auto mbs-storage-column">
                    <span *ngIf="mode !== 'backup'"
                          [attr.data-test-element]="elementsSelector.name.modeNotBackupStorage"
                          [ngClass]="[plan.type | planType, plan.lastRunInfo?.status | planStatus, 'mbs-backup-plans-plan_icon']"></span>
                    <span *ngIf="mode === 'backup'"
                          class="mbs-storage mbs-storage-sm"
                          [attr.data-test-element]="elementsSelector.name.modeBackupStorage"
                          [ngClass]="['mbs-storage_' + (getDestinationIconType(plan, destinationTypes) | storageIcon)]"></span>
                  </div>

                  <div #disableTooltip
                       class="col overflow-hidden h6 my-0"
                       placement="auto"
                       [ngbTooltip]="plan.displayName"
                       tooltipClass="tooltip-lg"
                       triggers="manual"
                       #planTooltip="ngbTooltip"
                       (mouseenter)="!plan?.hidePlanNameTooltip && planTooltip.open()"
                       (mouseleave)="!plan?.hidePlanNameTooltip && planTooltip.close()">
                    <mbs-text-ellipsis (checkFit)="handlePlanNameView($event, plan, planTooltip)">
                      {{ plan.displayName }}
                    </mbs-text-ellipsis>
                  </div>
                  <div *ngIf="mode === 'backup' && plan?.formatType === planFormatTypes.NBF"
                       [attr.data-test-element]="elementsSelector.name.modeBackupNBFTag"
                       class="col-auto overflow-hidden">
                    <mbs-tag [clickable]="false"
                             [closable]="false"
                             [active]="true"
                             [noBorder]="true"
                             [class]="'background-green'">
                      {{ getPlanFormatShortName(plan) }}
                    </mbs-tag>
                  </div>
                  <div *ngIf="computer.online"
                       [attr.data-test-element]="elementsSelector.name.compOnlineBlock"
                       class="col-auto d-flex">
                    <mbs-button-group *ngIf="!(facade.isPlanSavingById$(plan.id) | async)"
                                      size="xs"
                                      class="mbs-backup-plans-plan_buttons">
                      <mbs-button *ngIf="plan?.lastRunInfo?.status !== planRunSimpleStatus.Running; else runningButtons"
                                  [attr.data-test-element]="elementsSelector.name.lastInfoNotRunningBtn"
                                  (click)="handleStartPlan(plan, computer)"
                                  placement="auto"
                                  [ngbTooltip]="(plan.type === planTypes.DataBasePlan ? 'computers.module:tooltips.runFullBackup' :  'computers.module:tooltips.runBackup') | i18next"
                                  container="auto"
                                  tooltipClass="tooltip-lg text-nowrap"
                                  size="xs"
                                  customClasses="lh-1 py-0 mbs-backup-plans-plan_buttons-btn">
                        <span class="fa fa-play-circle-o text-success text-base"></span>
                      </mbs-button>
                      <ng-template #runningButtons>
                        <span class="loader loader-success align-self-center mr-2"></span>
                        <mbs-button (click)="handleStopPlan(plan, computer)"
                                    [attr.data-test-element]="elementsSelector.name.lastInfoRunningBtn"
                                    size="xs"
                                    customClasses="lh-1 py-0 mbs-backup-plans-plan_buttons-btn">
                          <span class="fa fa-stop text-danger text-base"></span>
                        </mbs-button>
                      </ng-template>
                      <mbs-button *ngIf="plan?.availableStartModes?.length > 1 || (plan?.availableStartModes?.length === 1 && plan?.availableStartModes[0])"
                                  [attr.data-test-element]="elementsSelector.name.availableStartModesBtn"
                                  size="xs"
                                  customClasses="py-0 px-2 mbs-backup-plans-plan_buttons-btn">
                        <ng-template mbsButtonDropdown
                                     container="auto"
                                     display="dynamic">
                          <ng-template ngFor
                                       let-planStartMode
                                       [ngForOf]="plan.availableStartModes">
                            <button [attr.data-test-element]="elementsSelector.name.startModesBtn"
                                    (click)="handleStartPlan(plan, computer, planStartMode)"
                                    ngbDropdownItem>
                              <ng-container *ngIf="planStartMode; else runTemplate">
                                {{plan.type === planTypes.DataBasePlan ? planStartModesDisplayWithRun[planStartMode]  : planStartModesDisplay[planStartMode] }}
                              </ng-container>
                              <ng-template #runTemplate>
                                {{(plan.type === planTypes.DataBasePlan ? 'computers.module:tooltips.runFullBackup' :  'computers.module:tooltips.runBackup') | i18next}}
                              </ng-template>
                            </button>
                          </ng-template>
                        </ng-template>
                      </mbs-button>
                    </mbs-button-group>
                  </div>
                  <span *ngIf="facade.isPlanSavingById$(plan.id) | async"
                        class="loader loader-primary align-self-center ml-2"></span>
                  <div class="col-auto d-flex">
                    <mbs-button *ngIf="!(facade.isPlanSavingById$(plan.id) | async)"
                                [hideDropdownIcon]="true"
                                [disabled]="(isRestore && !isBackupAgentSupportsNewRM)"
                                size="xs"
                                customClasses="lh-1 py-0 mbs-backup-plans-plan_buttons-btn">
                      <span class="ico ico-EllipsisV text-base"></span>
                      <ng-template mbsButtonDropdown
                                   container="auto"
                                   display="dynamic">
                        <button ngbDropdownItem
                                [disabled]="needDisableForRestore(computer)"
                                (click)="handleEditPlan(plan, computer)">Edit</button>
                        <button *ngIf="plan.type !== planTypes.ConsistencyCheck"
                                [disabled]="!canShowCreteButtons(computer) || needDisableForRestore(computer)"
                                (click)="handleClonePlan(plan, computer)"
                                ngbDropdownItem>Clone</button>
                        <button (click)="handleDeletePlan(plan, computer)"
                                [disabled]="!canShowCreteButtons(computer)"
                                ngbDropdownItem>Delete</button>
                        <button (click)="handleViewBackupStorage(plan)"
                                ngbDropdownItem>View Backup Storage</button>
                      </ng-template>
                    </mbs-button>
                  </div>
                </div>
              </div>
            </ng-template>
          </mbs-card-header>

          <table class="table table-simple table-sm mbs-backup-plans-plan_table-properties">
            <ng-container *ngIf="plan.lastRunInfo as lastInfo">
              <tr class="mbs-backup-plans-plan_table-properties-row"
                  [attr.data-test-element]="elementsSelector.name.lastResultBlock">
                <td class="text-muted nowrap mbs-backup-plans-middle-row">
                  Last Result
                </td>
                <td>
                  <div class="row mbs-backup-plans-complex-row-cols">
                    <div class="col-auto">
                      <span [ngClass]="[lastInfo?.status | planStatus]">
                        {{ lastInfo?.status | planStatus:'display' }}
                      </span>
                    </div>
                    <div *ngIf="showReportProblem(computer, plan)"
                         [attr.data-test-element]="elementsSelector.name.reportProblem"
                         class="col-auto px-0">
                      <mbs-button size="xs"
                                  (click)="reportProblem(computer, plan)">
                        Report a Problem
                      </mbs-button>
                    </div>
                    <div *ngIf="lastInfo?.progressLines?.length > 0"
                         [attr.data-test-element]="elementsSelector.name.progressLineBtn"
                         class="col mbs-backup-plans-complex-last-col">
                      <mbs-button [isCtrl]="true"
                                  type="primary"
                                  (click)="detailsOpenState.set(plan.id, !detailsOpenState.get(plan.id))">
                        {{ (isOpenStatePlanDetail(plan?.lastRunInfo?.status, plan.id) ? 'buttons:hideDetails' : 'buttons:viewDetails') | i18next : { format: 'title' } }}
                        <span class="dropdown-toggle ml-1"
                              [class.-open]="isOpenStatePlanDetail(plan?.lastRunInfo?.status, plan.id)"></span>
                      </mbs-button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="plan.lastRunInfo?.progressLines && (plan?.lastRunInfo?.status === planRunSimpleStatus.Succeed ? detailsOpenState.get(plan.id) : !detailsOpenState.get(plan.id))"
                [attr.data-test-element]="elementsSelector.name.lastRunInfoContentBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td colspan="2">

                <table class="table table-simple table-sm mbs-backup-plans-plan_table-total">

                  <ng-container *ngFor="let progressItem of plan.lastRunInfo?.progressLines; trackBy:trackByProgressLine">
                    <ng-container *ngIf="progressItem.visibility">
                      <tr class="mbs-backup-plans-plan_table-total-row"
                          [attr.data-test-element]="elementsSelector.name.lastInfoProgressItem">
                        <td class="mbs-backup-plans-plan_table-total-cell -status">
                          <ng-container *ngIf="progressItem.status !== ProgressLineStatus.Total">
                            <span class="ico"
                                  [attr.data-test-element]="elementsSelector.name.lastInfoProgressItemIco"
                                  [ngClass]="getStatusIconsClasses(progressItem)"
                                  *ngIf="plan.id + progressItem.operationName as key"></span>
                          </ng-container>
                        </td>

                        <td class="mbs-backup-plans-plan_table-total-cell -operationName">
                          <span *ngIf="progressItem.status === ProgressLineStatus.Total"
                                class="font-weight-semibold"
                                [attr.data-test-element]="elementsSelector.name.lastInfoProgressItemTotal">
                            {{ progressItem.operationName }}
                          </span>
                          <span *ngIf="!progressItem.description && progressItem.status !== ProgressLineStatus.Total"
                                [attr.data-test-element]="elementsSelector.name.lastInfoProgressItemOperationName">
                            {{ progressItem.operationName }}
                          </span>
                          <ng-container *ngIf="progressItem.status !== ProgressLineStatus.Total">
                            <ng-container *ngIf="plan.id + progressItem.operationName as key">
                              <mbs-button *ngIf="progressItem.description"
                                          [attr.data-test-element]="elementsSelector.name.lastInfoProgressItemOperationNameBtn"
                                          [isCtrl]="true"
                                          type="dark"
                                          (click)="progressLineOpenState.set(key, !progressLineOpenState.get(key))">
                                {{ progressItem.operationName }}
                                <span class="dropdown-toggle ml-2"
                                      [class.-open]="!progressLineOpenState.get(key)"></span>
                              </mbs-button>
                            </ng-container>
                          </ng-container>
                        </td>

                        <td class="mbs-backup-plans-plan_table-total-cell text-right text-gray-600 -time">
                          <div *ngIf="plan.lastRunInfo?.status === planRunSimpleStatus.Running; else progressTime">
                            <mbs-progress-bar [attr.data-test-element]="elementsSelector.name.lastInfoProgressRunning"
                                              [type]="
                                                progressItem.status === ProgressLineStatus.Failed || progressItem.status === ProgressLineStatus.Warning ?
                                                  ProgressLineStatus[progressItem.status].toLowerCase() :
                                                  'success'
                                              "
                                              [value]="progressItem.progress < 0 ? 0 : progressItem.progress"
                                              class="mbs-rm-progress w-100"></mbs-progress-bar>
                          </div>
                          <ng-template #progressTime>
                            {{ progressItem.time }}
                          </ng-template>
                        </td>
                      </tr>

                      <tr class="mbs-backup-plans-plan_table-total-row"
                          [attr.data-test-element]="elementsSelector.name.lastInfoTotalDescription"
                          [ngbCollapse]="progressItem.status === ProgressLineStatus.Total ? false : progressLineOpenState.get(plan.id + progressItem.operationName)">
                        <td class="mbs-backup-plans-plan_table-total-cell">
                          <!-- just fill  -->
                        </td>
                        <td class="mbs-backup-plans-plan_table-total-cell"
                            colspan="2">
                          {{ progressItem.description }}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>


                  <ng-container *ngFor="let error of plan.lastRunInfo?.errorDetails; trackBy:trackByDetailedError">
                    <tr class="mbs-backup-plans-plan_table-total-row">
                      <td class="mbs-backup-plans-plan_table-total-cell"
                          colspan="3">
                        <hr class="mx-0 my-2">
                      </td>
                    </tr>
                    <tr [attr.data-test-element]="elementsSelector.name.errorDetailTopBox">
                      <td class="mbs-backup-plans-plan_table-total-cell -status">
                        <span class="ml-1"
                              [attr.data-test-element]="elementsSelector.name.errorDetailPriority"
                              [ngClass]="error.priority | priority"
                              *ngIf="plan.id + error.id as key"></span>
                      </td>
                      <td class="mbs-backup-plans-plan_table-total-cell -operationName"
                          colspan="2">
                        <div class="d-flex justify-content-start align-items-center">
                          <mbs-button *ngIf="plan.id + error.id as key"
                                      [attr.data-test-element]="elementsSelector.name.errorDetailOpenBtn"
                                      [isCtrl]="true"
                                      type="dark"
                                      (click)="errorDetailOpenState.set(key, !errorDetailOpenState.get(key))">
                            {{ error.title }}
                            <span class="dropdown-toggle"
                                  [class.-open]="!errorDetailOpenState.get(key)"></span>
                          </mbs-button>
                          <span *ngIf="isNumber(error.id)"
                                class="ml-2 text-nowrap"
                                [attr.data-test-element]="elementsSelector.name.errorDetailCodeLinkBlock">
                            (code:
                            <a *ngIf="ability.can('read','HelpMarketing') && error.kbid; else onlyTextCode"
                               [attr.data-test-element]="elementsSelector.name.errorDetailCodeLink"
                               class="nav-link d-inline p-0 m-0"
                               target="_blank"
                               [href]="kbidBase + error.kbid">{{ error.id }}</a>
                            <ng-template #onlyTextCode>{{ error.id }}</ng-template>
                            )
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr class="mbs-backup-plans-plan_table-total-row">
                      <td class="mbs-backup-plans-plan_table-total-cell"></td>
                      <td class="mbs-backup-plans-plan_table-total-cell"
                          colspan="2">
                        <div [ngbCollapse]="errorDetailOpenState.get(plan.id + error.id)"
                             [attr.data-test-element]="elementsSelector.name.errorDetailContentBlock">
                          {{ error.detail }}

                          <div *ngIf="error.solutions?.length"
                               [attr.data-test-element]="elementsSelector.name.errorDetailSolutionsBlock">
                            <strong class="d-block pt-2 pb-1">Solutions:</strong>
                            <ul class="m-0 pl-4 text-xs">
                              <li *ngFor="let solution of error.solutions; trackBy:trackBySolution">
                                {{ solution.description }}
                              </li>

                              <li *ngIf="ability.can('read','HelpMarketing') && error.kbid">
                                <a class="nav-link d-inline p-0 m-0"
                                   target="_blank"
                                   [attr.data-test-element]="elementsSelector.name.errorDetailSolutionsLink"
                                   [href]="kbidBase + error.kbid">Read KB article</a>
                              </li>
                            </ul>

                          </div>

                        </div>
                      </td>
                    </tr>
                  </ng-container>

                </table>

              </td>
            </tr>
            <tr class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Type
              </td>
              <td>
                {{ plan.type | planType:'name' }}
              </td>
            </tr>

            <tr *ngIf="mode === 'backup'"
                [attr.data-test-element]="elementsSelector.name.modeBackupFormatBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Format
              </td>
              <td>
                {{ getPlanFormatFullName(plan) }}
              </td>
            </tr>

            <tr class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Destination
              </td>
              <td>
                {{ plan.destinationDisplayName }}
              </td>
            </tr>

            <tr *ngIf="plan.source"
                [attr.data-test-element]="elementsSelector.name.planSourceBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Source
              </td>
              <td>
                <mbs-text-ellipsis [tooltip]="plan.source">
                  {{ plan.source }}
                </mbs-text-ellipsis>
              </td>
            </tr>

            <tr *ngIf="plan.scheduleSummary"
                [attr.data-test-element]="elementsSelector.name.planScheduleSummaryBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Schedule
              </td>
              <td>
                {{ plan.scheduleSummary }}
              </td>
            </tr>

            <tr *ngIf="plan.compressionSummary"
                [attr.data-test-element]="elementsSelector.name.planCompressionSummaryBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Compression
              </td>
              <td>
                {{ plan.compressionSummary }}
              </td>
            </tr>

            <tr *ngIf="plan.encryptionSummary"
                [attr.data-test-element]="elementsSelector.name.planEncryptionSummaryBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Encryption
              </td>
              <td>
                {{ plan.encryptionSummary }}
              </td>
            </tr>

            <tr *ngIf="mode === planType.Backup && !isNil(plan.syntheticFullEnabled) && (osType$ | async) === osTypes.windows"
                [attr.data-test-element]="elementsSelector.name.planSyntheticFullEnabledBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Synthetic Full Backup
              </td>
              <td>
                {{ plan.syntheticFullEnabled | boolDisplay:'enabled' }}
              </td>
            </tr>

            <tr *ngIf="mode === planType.Backup && plan?.formatType === planFormatTypes.NBF && !isNil(plan.fullConsistencyCheckEnabled)"
                [attr.data-test-element]="elementsSelector.name.planConsistencyBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Full Consistency Check
              </td>
              <td>
                {{ plan.fullConsistencyCheckEnabled | boolDisplay:'enabled' }}
              </td>
            </tr>

            <tr *ngIf="mode === planType.Backup && !isNil(plan.immutabilityEnabled) && (osType$ | async) === osTypes.windows"
                [attr.data-test-element]="elementsSelector.name.planImmutabilityBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                {{'computers.module:immutability' | i18next }}
              </td>
              <td>
                {{ plan.immutabilityEnabled | boolDisplay:'enabled' }}
              </td>
            </tr>

            <tr *ngIf="!!plan?.restoreVerificationSettingsSummary || !!plan?.restoreVerificationSummary"
                [attr.data-test-element]="elementsSelector.name.planRestoreBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                {{'computers.module:backupSidePanel.restoreVerification' | i18next }}
              </td>
              <td>
                {{ plan.restoreVerificationSummary }}
                <br />
                {{ plan.restoreVerificationSettingsSummary }}
              </td>
            </tr>

            <tr *ngIf="plan.retentionSummary"
                [attr.data-test-element]="elementsSelector.name.planRetentionBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                {{mode === planType.Backup ? 'Retention' : 'Restore Point'}}
              </td>
              <td>
                {{ plan.retentionSummary }}
              </td>
            </tr>

            <!-- empty row for whitespace -->
            <tr>
              <td colspan="2"></td>
            </tr>

            <tr *ngIf="plan.nextRunDateUTC"
                [attr.data-test-element]="elementsSelector.name.planNextRunBlock"
                class="mbs-backup-plans-plan_table-properties-row">
              <td class="text-muted nowrap">
                Next Run
              </td>
              <td>
                <mbs-backup-date-time-format [time]="plan.nextRunDateUTC"
                                             [showIcon]="true">
                </mbs-backup-date-time-format>
              </td>
            </tr>
            <ng-container *ngIf="plan.lastRunInfo as lastInfo">
              <tr *ngIf="lastInfo?.dateTimeUTC"
                  [attr.data-test-element]="elementsSelector.name.planDateTimeUTCBlock"
                  class="mbs-backup-plans-plan_table-properties-row">
                <td class="text-muted nowrap">
                  Last Run
                </td>
                <td>
                  <mbs-backup-date-time-format [time]="lastInfo?.dateTimeUTC"
                                               [showIcon]="true"
                                               defaultText="Unknown">
                  </mbs-backup-date-time-format>
                </td>
              </tr>
            </ng-container>
          </table>

        </mbs-card>
      </div>
    </div>
  </div>

  <ng-template #newPlanButton>
    <mbs-button icon="ico ico-Plus"
                [disabled]="readonly || (isRestore && !isBackupAgentSupportsNewRM)"
                [hideDropdownIcon]="true">
      <span class="text-sm">Add New Plan</span>

      <ng-template mbsButtonDropdown
                   container="auto">
        <button *ngFor="let availableType of availablePlanTypes"
                (click)="handleCreatePlan(availableType, computer)"
                ngbDropdownItem>
          {{ availableType.planType | planType:'name' }}
          {{getEndingForPlanName(availableType, computer)}}
        </button>
      </ng-template>
    </mbs-button>
  </ng-template>
</section>
