import { merge, pipe } from 'lodash/fp';
import { ERROR_LINE, NEW_LINE, PROMPT, PROMPT_NEW_LINE, WARN_LINE } from '../const';
import { CursorPosition, LineTypes, PromptBufferConfig, ShellLineFactory } from './model';

import { IBuffer } from 'xterm';
import { ShellLine } from './shell-line';

const defaultPromptBufferConfig: PromptBufferConfig = {
  prompt: PROMPT,
  promptNewLine: PROMPT_NEW_LINE,
  newLine: NEW_LINE,
  errorLine: ERROR_LINE,
  warnLine: WARN_LINE,
  cols: 0,
  rows: 0
};
/*
 * Collect info about input in xterm,
 * resolve helpers for line management
 */
export class PromptBuffer {
  /*
   * @summary xterm buffer
   */
  private _buffer: IBuffer;
  /*
   * @summary PromptBuffer configuration
   */
  private _config: PromptBufferConfig = defaultPromptBufferConfig;
  /*
   * @summary cash inputted lines
   */
  private linesCash: Map<number, ShellLine>;
  /*
   * @summary cash active line with siblings
   */
  public siblings: Map<number, ShellLine> = new Map();
  /*
   * @summary cash start input
   */
  public promptLine: ShellLine;
  /*
   * @summary history active flag
   */
  public get history() {
    return Boolean(this._config.history);
  }
  /*
   * @summary prompt line marker
   */
  public get prompt() {
    return this._config.prompt;
  }
  /*
   * @summary absolute cursor Y position
   */
  public get cursorY(): number {
    return this._buffer.cursorY + this._buffer.viewportY;
  }
  /*
   * @summary relative be viewport cursor Y position
   */
  public get relativeY(): number {
    return this._buffer.cursorY;
  }
  /*
   * @summary cursor x position
   */
  public get cursorX(): number {
    return this._buffer.cursorX;
  }
  /*
   * @summary config getter
   */
  get config() {
    return this._config;
  }
  /*
   * @summary config setter
   */
  set config(value) {
    this._config = merge(this._config, value);
  }
  /*
   * @summary Memoize line constructor by config
   */
  lineConstructor: ShellLineFactory;

  constructor(buffer: IBuffer, config: PromptBufferConfig = defaultPromptBufferConfig) {
    this._buffer = buffer;
    this.config = config;
    this.lineConstructor = ShellLine.create(this.config, this._buffer.viewportY);
    this.cashSiblings();
  }

  parsePaste(source: any) {
    return source
      .split('\n')
      .map((line) => this.lineConstructor(line))
      .map((line: ShellLine) => line.data);
  }
  /**
   * finder prev and next line by active line
   */
  cashSiblings(): void {
    for (let index = 0; index < 3; index++) {
      const positionY = index - 1 + this.cursorY;
      const relativeY = index - 1 + this.relativeY;

      if (relativeY > this.config.rows - 1) {
        this.siblings.set(index, this.lineConstructor());
      } else {
        const buffer = this._buffer.getLine(positionY);

        const line = buffer ? this.lineConstructor(buffer.translateToString(true), positionY) : this.lineConstructor();
        this.siblings.set(index, line);
      }
    }
  }
  /**
   * Return raw data from xterm line
   *
   * @return {string}
   */
  getRaw = (): string =>
    Array.from(this.getCacheLines().values())
      .sort((a, b) => a.y - b.y)
      .map((line) => line.source)
      .join('\r\n');
  /**
   * Find prompt line
   *
   * @return {ShellLine}
   */
  getPrompt(): ShellLine {
    const cash = this._findEnd(this.cursorY)();
    return Array.from(cash.values()).sort((a, b) => a.y - b.y)[0];
  }
  /*
   * @returns last input line
   */
  getEnd() {
    const cash = this._findEnd(this.cursorY)();
    return Array.from(cash.values()).sort((a, b) => b.y - a.y)[0];
  }
  /*
   * memoize inputted line for full data operations
   * if full - force save lines (hard operation)
   * @param full
   * @returns cash inputted lines
   */
  getCacheLines(full?) {
    if (!this.linesCash || full) {
      return pipe(full ? this._findPrompt() : () => undefined, this._findEnd())();
    }
    return this.linesCash;
  }
  /*
   * return clear lines to next operations as one multiline string
   * @param full
   * @returns
   */
  getLines(full = false) {
    const lines = this.getCacheLines(full);
    return this.reduceWrite(lines, '', null, full);
  }
  /*
   * return lines only after cursor to rewrite
   * @param inject
   * @returns
   */
  getReminder(inject = '', cut: number = null) {
    const lines = this.getCacheLines(false);
    return this.reduceWrite(lines, inject, cut, false);
  }
  /*
   * return lines as write
   * @returns
   */
  getInput() {
    return this.getLines(true).join(this.config.newLine).trimRight();
  }
  /*
   * calc count lines
   * @returns
   */
  getLinesCount() {
    return this.getCacheLines(true).size;
  }
  /*
   * calc next position by siblings offset, insert length, line length limits
   * @param insert
   * @returns
   */
  getNextPosition(insert: string = null): CursorPosition {
    const line = this.siblings.get(1);
    const sibling = this.siblings.get(2);
    const cursorX = this.cursorX;
    const cursorY = this.relativeY;

    if (insert) {
      const parse = insert.split('\n');
      const startY = parse.length - 1 + cursorY;
      const startX = parse.length == 1 ? this.cursorX : this.config.promptNewLine.length;
      const offset = parse.reverse()[0].length;
      const { count, rem } = this._calcOffset(startX + offset, line.cols);
      const nextY = startY + count;

      return { cursorX: rem, cursorY: nextY, jump: this.cursorY !== nextY };
    }

    if (sibling.type === LineTypes.New && line.length <= cursorX) {
      return { cursorX: sibling.offset, cursorY: cursorY + 1, jump: true };
    }

    if (line.cols - 1 <= cursorX) {
      return { cursorX: sibling.offset, cursorY: cursorY + 1, jump: true };
    }

    return { cursorX: cursorX + 1, cursorY, jump: false };
  }
  /*
   * calc next position by siblings offset, insert length, line length limits
   * @param cursorX
   * @returns
   */
  getPrevPosition(cursorX = this.cursorX): CursorPosition {
    const line = this.siblings.get(1);
    const sibling = this.siblings.get(0);
    const cursorY = this.relativeY;

    if (line.offset >= cursorX) {
      cursorX = sibling.length >= sibling.offset ? sibling.length : sibling.offset;

      return line.type === LineTypes.Prompt ? { cursorX, cursorY, jump: false } : { cursorX, cursorY: cursorY - 1, jump: true };
    }

    return { cursorX: cursorX - 1, cursorY, jump: false };
  }
  /*
   * find spaces before cursor to new or prompt line.
   * @param cursorY
   * @param cursorX
   * @returns
   */
  hasSpaceBefore(cursorY = this.cursorY, cursorX = this.cursorX): boolean {
    const buffer = this._buffer.getLine(cursorY);

    if (buffer) {
      const line = this.lineConstructor(buffer.translateToString(true));
      const regExp = new RegExp(/\s/);
      const offset = Number.isInteger(cursorX) ? cursorX - line.offset : undefined;

      switch (line.type) {
        case LineTypes.New:
        case LineTypes.Prompt: {
          return regExp.test(line.data.slice(0, offset));
        }
        default: {
          return regExp.test(line.data.slice(0, offset)) ? true : this.hasSpaceBefore(cursorY - 1, null);
        }
      }
    }

    return false;
  }
  /*
   * calc maximum line to input
   * @returns view limit state
   */
  isRowLimit(): boolean {
    return this.getLinesCount() >= this.config.rows - 1;
  }
  /*
   * union lines arraw to ona multiline string
   * @param data
   * @returns
   */
  unionInput(data: string[]): string {
    return data.join(this.config.newLine + this.config.promptNewLine).trimRight();
  }
  /*
   * reduce lines by config
   * @param lines
   * @param inject
   * @param cut
   * @param full
   * @returns
   */
  private reduceWrite = (lines: Map<number, ShellLine>, inject, cut: number = null, full): string[] => {
    const array = Array.from(lines.values());
    let cursorX = this._setWriteCursor(full, cut);
    inject = this._prepareWriteInject(inject);

    const { result } = array
      .sort((a, b) => a.y - b.y)
      .reduce(
        (acc, line, index) => {
          if (cursorX > line.length) cursorX = line.length;

          const lineData = cursorX && index === 0 ? line.data.slice(cursorX - line.offset) : line.data;
          const value = index === 0 ? inject + lineData : lineData;
          const next = array[index + 1];

          if (next && next.type === LineTypes.New) {
            acc.result.push(acc.buffer + value);
            acc.buffer = '';
          } else {
            acc.buffer = acc.buffer + value;
          }

          if (!next) {
            acc.result.push(acc.buffer);
          }

          return acc;
        },
        { buffer: '', result: [] }
      );

    return result;
  };
  /*
   * cash lines from active line to prompt line
   * @param y
   * @returns
   */
  private _findPrompt =
    (y: number = this.cursorY) =>
    (acc: Map<number, ShellLine> = new Map()): Map<number, ShellLine> => {
      const buffer = this._buffer.getLine(y);
      let line = this.lineConstructor();

      if (buffer) {
        line = this.lineConstructor(buffer.translateToString(true), y);
      }

      if (!acc.has(y)) {
        acc.set(y, line);
      }

      if (y == 0) {
        return acc;
      }

      return line.type !== LineTypes.Prompt ? this._findPrompt(y - 1)(acc) : acc;
    };
  /*
   * cash lines from active line to last line
   * @param y
   * @returns
   */
  private _findEnd =
    (y: number = this.cursorY) =>
    (acc: Map<number, ShellLine> = new Map()): Map<number, ShellLine> => {
      const buffer = this._buffer.getLine(y);
      const nextBuffer = this._buffer.getLine(y + 1);

      let line = this.lineConstructor();
      let next = this.lineConstructor();

      if (buffer) {
        line = this.lineConstructor(buffer.translateToString(true), y);
      }

      if (nextBuffer) {
        next = this.lineConstructor(nextBuffer.translateToString(true), y);
      }

      if (!acc.has(y)) {
        acc.set(y, line);
      }

      if (this.relativeY == this.config.rows - 1) {
        return acc;
      }

      return next.length > 0 && next.type !== LineTypes.Error ? this._findEnd(y + 1)(acc) : acc;
    };
  /*
   * calc count jumps by paste big text data
   * @param rem reminder length
   * @param max maximum cols
   * @param count jumps
   * @returns {count: number, rem: number}
   */
  private _calcOffset = (rem: number, max: number, count = 0): { count: number; rem: number } => {
    if (rem < max) {
      return { count, rem };
    }

    return this._calcOffset(rem - max, max, count + 1);
  };

  private _setWriteCursor(full, cut) {
    const cursorX = this.cursorX;

    if (cut) {
      return cursorX + cut;
    }
    if (full) {
      return 0;
    }

    return cursorX;
  }

  private _prepareWriteInject(inject) {
    return inject ? inject.split('\n').join(this.config.newLine + this.config.promptNewLine) : '';
  }
}
