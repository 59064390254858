import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import Computer, { AgentType } from '@models/Computer';
import { ComputerBasedModal } from '@models/ComputersModals';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AlertType } from 'mbs-ui-kit/alert/alert.model';
import { ModalComponent } from 'mbs-ui-kit/modal/modal.component';

const connectWithTokenSupportsAgentVersion = 340;
const backupWithTokenSupportsAgentVersion = 770;

@UntilDestroy()
@Component({
  selector: 'mbs-computers-auth-required-modal-component',
  templateUrl: './computers-auth-required-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputersAuthRequiredModalComponent implements ComputerBasedModal {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  public readonly alertType = AlertType;
  public computer: Computer;

  public get needUpgradeBackupAgent(): boolean {
    return (
      Computer.isWindows(this.computer) &&
      Computer.hasAgent(this.computer, AgentType.Backup) &&
      !Computer.IsSupportedAgentVersion(this.computer, AgentType.Backup, backupWithTokenSupportsAgentVersion)
    );
  }

  public get reAuthRequired(): boolean {
    return (
      Computer.isWindows(this.computer) &&
      !Computer.hasAgent(this.computer, AgentType.Backup) &&
      Computer.hasAgent(this.computer, AgentType.RA) &&
      Computer.IsSupportedAgentVersion(this.computer, AgentType.RA, connectWithTokenSupportsAgentVersion)
    );
  }

  public get computerName(): string {
    return Computer.getComputerName(this.computer);
  }
}
