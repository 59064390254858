import { ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import Computer from '@models/Computer';
import RmmCommand from '@models/rmm/RmmCommand';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import * as SummaryComputerSelectors from '@modules/summary-computers/store/summary-computer.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TFARMMPermissionHelperService } from '@shared/services';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, ModalService, ModalSettings, ToastService } from 'mbs-ui-kit';
import { filter, noop, switchMap, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-reboot-computer',
  templateUrl: './reboot-computer.component.html'
})
export class RebootComputerComponent {
  @Input() container: string;
  @Input() disabled = false;
  @ViewChild('confirmationModalContent', { static: true, read: TemplateRef }) confirmContent: TemplateRef<any>;
  private hid: string;
  public readonly AlertType = MbsPopupType;
  public icon = 'fa fa-refresh';
  public modalTitle = this.i18nPipe.transform('rmm-side-panel:reboot.modalTitle');
  public computerName: string;

  constructor(
    private toastService: ToastService,
    private commandService: CommandService,
    private modalService: ModalService,
    private i18nPipe: I18NextPipe,
    private store: Store,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService,
    private cdr: ChangeDetectorRef
  ) {
    this.store
      .select(SummaryComputerSelectors.selectCurrentComputer)
      .pipe(
        filter((computer) => !!computer),
        tap((computer) => {
          this.hid = computer.hid;
          this.computerName = Computer.getComputerName(computer);
        }),
        untilDestroyed(this)
      )
      .subscribe(noop);
  }

  public openConfirmModal() {
    const settings: ModalSettings = {
      header: { title: this.modalTitle, icon: '', showExpandedCross: false },
      footer: { okButton: { text: this.i18nPipe.transform('rmm-side-panel:reboot.btnName') } }
    };
    this.showModal(settings, this.confirmContent);
  }

  private showModal(settings: ModalSettings, content: TemplateRef<any>): void {
    this.modalService.open(settings, content).then(() => {
      this.tfaRMMPermissionHelper
        .is2FAPassedStream([this.hid], this.container ? this.container : null)
        .pipe(
          filter((confirmed) => confirmed),
          switchMap((confirmed) => {
            const command = RmmCommand.create('Restart').addParam('FORCEAPPCLOSED', 'False');
            return this.commandService.sendCommand('SummaryCmd', command, this.hid, true);
          }),
          untilDestroyed(this)
        )
        .subscribe((response: any) => {
          if (!response?.error) this.toastService.success(this.i18nPipe.transform('rmm-side-panel:reboot.restartMessage'));
        });
    }, noop);
  }
}
