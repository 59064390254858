import { PlanTypes } from '@models/PlanTypes.enum';
import { I18NextPipe } from 'angular-i18next';
import { SourceConfigItemType } from './backup-source.types';

export const getSourcesConfig = (i18N: I18NextPipe): SourceConfigItemType[] => {
  return [
    {
      type: PlanTypes.Plan,
      title: i18N.transform('backup.source.file.title'),
      description: i18N.transform('backup.source.file.description'),
      icon: 'assets/images/for-wizards/file-small.svg'
    },
    {
      type: PlanTypes.BackupDiskImagePlan,
      title: i18N.transform('backup.source.image_based.title'),
      description: i18N.transform('backup.source.image_based.description'),
      icon: 'assets/images/for-wizards/ibb-small.svg'
    }
  ];
};
