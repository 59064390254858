import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getProductTilesConfig } from '@components/add-computer/steps/select-action/select-action.constants';
import { I18NextPipe } from 'angular-i18next';
import { CompaniesFacade } from '@facades/companies.facade';
import { BuildOsType } from '@models/build';
import { WizardForm } from '@components/add-computer/add-computer.types';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { EMPTY, from } from 'rxjs';
import { AddCompanyModalComponent } from '@components/add-company/add-company-modal.component';
import { catchError } from 'rxjs/operators';
import Company from '@models/Company';
import { ModalService } from 'mbs-ui-kit';

@UntilDestroy()
@Component({
  selector: 'add-computer-wizard-select-action-step',
  templateUrl: './select-action.component.html',
  styleUrls: ['../../add-computer.component.scss']
})
export class SelectActionComponent implements OnInit {
  public readonly permissionsEnum = PermissionsEnum;

  public tilesConfig = getProductTilesConfig(this.i18N);
  public companies$ = this.companiesFacade.getData$();
  public agentTypeControl: FormControl;

  @Input() wizardForm: WizardForm;
  @Input() availableOsTypes: BuildOsType[];
  @Input() showCompanyUnsupportedAlert = false;

  constructor(
    private i18N: I18NextPipe,
    private companiesFacade: CompaniesFacade,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.agentTypeControl = this.wizardForm.get('agentType') as FormControl;
    this.companiesFacade.loadData();
  }

  public onAddCompanyButtonClick() {
    from(this.modalService.openCustom(AddCompanyModalComponent))
      .pipe(
        catchError(() => EMPTY),
      )
      .subscribe((company) => {
        this.wizardForm.get('companyId').patchValue((company as Company).id);
      });
  }
}
