import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComputerAppsModule } from '@modules/computer-apps/computer-apps.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeatureKey } from './store/const';
import { SummaryComputerEffects } from './store/summary-computer.effects';
import { reducer } from './store/summary-computer.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ComputerAppsModule,
    StoreModule.forFeature(FeatureKey, reducer),
    EffectsModule.forFeature([SummaryComputerEffects])
  ]
})
export class SummaryComputersModule {}
