import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MbsPopupType } from 'mbs-ui-kit';

@UntilDestroy()
@Component({
  selector: 'mbs-install-software',
  templateUrl: './install-software.component.html'
})
export class InstallUsingSoftwareLinkComponent implements OnInit {
  @Output() updateInstallLinkParams = new EventEmitter<{ link: string; parameters: string }>();
  public readonly alertType = MbsPopupType;

  public installForm = new UntypedFormGroup({
    url: new FormControl('', Validators.required),
    arguments: new FormControl('', Validators.required)
  });

  constructor() {}

  ngOnInit(): void {
    const value$ = this.installForm.valueChanges.pipe(untilDestroyed(this));

    value$.subscribe((data) => {
      if (!this.installForm.valid) {
        this.updateInstallLinkParams.emit({ link: '', parameters: '' });
        return;
      }

      this.updateInstallLinkParams.emit({ link: data.url, parameters: data.arguments });
    });
  }
}
