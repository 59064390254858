import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import HddInfo from '@models/rmm/HddInfo';
import { Unpacked } from 'mbs-ui-kit/utils/types/types';

type HddVolume = Unpacked<HddInfo['driveLetters']>;

@Component({
  selector: 'mbs-hdd-volume-info',
  templateUrl: './hdd-volume-info.component.html'
})
export class HddVolumeInfoComponent implements OnChanges {
  @Input() public hddVolumeInfo: HddVolume;

  public usageStatus: 'success' | 'warning' | 'danger';
  public usageValue = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hddVolumeInfo.currentValue) {
      const usage = ((this.hddVolumeInfo.totalSpace - this.hddVolumeInfo.freeSpace) * 100) / this.hddVolumeInfo.totalSpace;
      this.usageValue = usage;
      this.usageStatus = usage < 70 ? 'success' : usage < 90 ? 'warning' : 'danger';
    }
  }
}
