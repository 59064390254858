export enum AccountId {
  MicrosoftPersonal = 'OfficeHome',
  MicrosoftBusiness = 'OfficeBusiness',
  GoogleWorkspace = 'GoogleBusiness'
}


export enum AuthDataSuccessResponseState {
  Success = 'Success'
}

export enum AuthDataFailedResponseState {
  WrongAccount = 'WrongAccount',
  AccessDenied = 'AccessDenied',
  AccessRequest = 'AccessRequest',
  ServiceUnavailable = 'ServiceUnavailable',
  TokenInvalid = 'TokenInvalid',
  NotGrant = 'NotGrant',
  Approve = 'Approve',
  Blocked = 'Blocked',
  SignInDisabled = 'SignInDisabled',
  NoLicensesAvailable = 'NoLicensesAvailable'
}
