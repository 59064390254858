<h4>{{ 'onboarding:select_users' | i18next: { format: 'title' } }}</h4>

<ng-container *ngIf="allUsersControl?.value?.length ; else loader">

  <mbs-alert [type]="alertType.info"
             [icon]="true">
    <p class="mb-0">
      {{ (form.get('users.loading')?.value ? 'onboarding:alerts.syncInProgress' : 'onboarding:alerts.afterSync') | i18next }}
    </p>
  </mbs-alert>

  <mbs-list-select (changeSelected)="changeSelectedHandler($event)"
                   [data]="allUsersControl?.value"
                   [headers]="headers"
                   [keepState]="true"
                   [selectedData]="selectedUsersControl?.value"
                   [showTopControls]="true"
                   [topControlsLabelSelected]="true"
                   [topControlsLabel]="true"
                   [maxHeight]="'332px'"
                   bindSelected="Id"
                   filterFields="Name"
                   [placeholder]="'common.userName' | i18next: { format: 'title' }">

    <ng-template appendTopControlsLabel>
      {{ 'common.selected' | i18next }}
    </ng-template>

    <ng-template let-person
                 mbsTableCell>
      <div>{{ person.Name }}</div>
      <div>{{ person.Email }}</div>
    </ng-template>

  </mbs-list-select>

</ng-container>

<ng-template #loader>
  <mbs-loader></mbs-loader>
</ng-template>
