import { ChangeDetectorRef, Component, forwardRef, Input, OnInit, TemplateRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { ViewMode } from '@modules/wizards/constants/view-mode';
import { ExtendedTreeElement, GenerateTreeDataSettings, StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { ParamsForTreeData, WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { CheckboxLegendComponent } from '@modules/wizards/steps/components/checkbox-legend/checkbox-legend.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { linuxInvalidPath, networkValidPath, windowsInvalidPath } from '@root/mbs-ui/src/app/shared/utils/constants/folders-path-constants';
import { linuxPathValidator, winNetworkPathValidator } from '@utils/validators';
import { I18NextPipe } from 'angular-i18next';
import { FormsUtil, ModalService, ModalSettings, TreeElement } from 'mbs-ui-kit';
import { BehaviorSubject, noop, Observable } from 'rxjs';
import { BaseForStepsHelper, DataForPath } from '../../helpers/bases/base-for-steps-helper';
import {
  AlternativeTreeIconPath,
  TreeIconPath,
  WhatBackupTreeStepFoldersForm,
  WhatBackupTreeStepValue,
  WhatBackupTreeStepValueForm
} from '../../models/what-backup-tree-model';

import { NetworkCredentials } from '@models/backup/network-credential';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { NetworkShareModalComponent } from '@modules/wizards/steps/what-backup-tree-step/network-share-modal/network-share-modal.component';
import { StepBase } from '../StepBase.class';

type MyTreeElement = TreeElement & { needUncheckIfChildUncheck?: boolean; notSetUncheckFlag?: boolean };
type TreeRequestData = { data: { items: any[]; totalCount: number; count: number } };
type PathArrays = { mainFormArray: string[]; excFormArray: string[]; newInc: string[]; newExc: string[] };

const WhatBackupTreeStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => WhatBackupTreeStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-what-backup-tree-step',
  templateUrl: './what-backup-tree-step.component.html',
  styleUrls: ['./what-backup-tree-step.component.scss'],
  providers: [WhatBackupTreeStepValueAccessor]
})
export class WhatBackupTreeStepComponent extends StepBase<WhatBackupTreeStepValue> implements OnInit {
  public readonly viewMode = ViewMode;

  public showPlainText = false;
  public editedString: { value: string; isInclude: boolean; index: number } = null;
  public foldersForm: FormGroup<WhatBackupTreeStepFoldersForm>;

  private treeParams: ParamsForTreeData = {
    agentType: 'Backup',
    commandType: 'getComputerContent',
    params: { path: '', offset: 0, limit: 300, order: 'DisplayNameAsc' }
  };
  public separator = ']-[';
  public treeData: TreeElement[] = [];

  public loading = false;

  private needIncludeStrings: string[] = [];
  private needExcludeStrings: string[] = [];
  private needValidate = false;
  private needUpdate = false;

  private shareTreeItems: TreeElement[] = [];
  private networkSharePathArray: NetworkCredentials[] = [];

  @Input() view?: ViewMode = ViewMode.Default;
  @Input() settings?: {
    hid: string;
    isLinux: boolean;
  };

  get isLinux() {
    return super.isLinux || !!this.settings?.isLinux;
  }

  get hid(): string {
    return this.mainService.hid || this.settings?.hid;
  }

  get pastFromClipboardNotSupported(): boolean {
    return !navigator?.clipboard?.readText;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public mainService: RemoteManagementWizardsService,
    private modal: ModalService,
    private stepService: WizardStepsService,
    public i18nPipe: I18NextPipe
  ) {
    super(mainService);
    this.setBaseFlags();
  }

  ngOnInit(): void {
    this.foldersForm = new FormGroup<WhatBackupTreeStepFoldersForm>({
      includeString: new FormControl('', [
        this.includeRequiredValidator.bind(this),
        this.isLinux ? linuxPathValidator : winNetworkPathValidator,
        this.isLinux ? this.isSelectedValidatorLinux.bind(this) : this.isSelectedValidatorWindows.bind(this)
      ]),
      excludeString: new FormControl('', [
        this.isLinux ? linuxPathValidator : winNetworkPathValidator,
        this.isLinux ? this.isSelectedValidatorLinux.bind(this) : this.isSelectedValidatorWindows.bind(this)
      ])
    });

    this.initForm();

    this.setValues();
  }

  initForm(): void {
    this.stepForm = new FormGroup<WhatBackupTreeStepValueForm>({
      includeFolders: new FormControl([], [Validators.required]),
      excludeFolders: new FormControl([])
    });

    this.foldersForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (!value.includeString && !value.excludeString) this.needValidate = false;
      this.stepForm.updateValueAndValidity();
    });

    this.initFormEvents();
  }

  onStepFormChange(value: WhatBackupTreeStepValue): void {
    this.value = {
      ...value,
      valid: this.stepForm.valid && !this.foldersForm.value.includeString && !this.foldersForm.value.excludeString
    };
  }

  forceValid(): void {
    const sec = this.foldersForm.value;
    this.needValidate = !!sec.includeString || !!sec.excludeString || !this.stepForm.value.includeFolders.length;
    FormsUtil.triggerValidation(this.foldersForm);
    FormsUtil.triggerValidation(this.stepForm);
  }

  updateForm(value: WhatBackupTreeStepValue): void {
    this.stepForm.reset(value);
    if (!this.showPlainText) this.getBaseTreeData();
  }

  setBaseFlags(): void {
    if (this.isRDMode || this.isOffline) this.showPlainText = true;
  }

  includeRequiredValidator(control: AbstractControl): ValidationErrors | null {
    if (!this.stepForm) return null;

    const incArray = this.stepForm.get('includeFolders').value;
    const errors = control.errors ? Object.keys(control.errors) : [];
    const valid = control.valid && (!errors.length || (errors.length === 1 && control.errors.required));
    return this.needValidate && ((valid && control.value) || !incArray.length)
      ? {
          required: {
            message: control.value
              ? this.i18nPipe.transform('wizards:includeTextNotAdded')
              : this.i18nPipe.transform('wizards:includeTextRequired')
          }
        }
      : null;
  }

  isSelectedValidatorLinux(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const error = { isSelected: { message: this.i18nPipe.transform('validation:existing_folder') } };
    const incArray = this.stepForm.get('includeFolders').value;
    const excArray = this.stepForm.get('excludeFolders').value;
    const formValue = control.parent.getRawValue();
    const valid = formValue.includeString !== formValue.excludeString;

    if (this.editedString) return !valid || this.validateIsEditLinux(incArray, excArray, control.value) ? error : null;

    return !valid || ~incArray.indexOf(control.value) || ~excArray.indexOf(control.value) ? error : null;
  }

  isSelectedValidatorWindows(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const error = { isSelected: { message: this.i18nPipe.transform('validation:existing_folder') } };
    const incArray = this.stepForm.get('includeFolders').value;
    const excArray = this.stepForm.get('excludeFolders').value;
    const formValue = control.parent.getRawValue();
    const valid = formValue.includeString.toLowerCase() !== formValue.excludeString.toLowerCase();
    const lowVal = control.value.toLowerCase();

    if (this.editedString) return !valid || this.validateIsEditWindows(incArray, excArray, lowVal) ? error : null;

    return !valid || ~incArray.findIndex((v) => v.toLowerCase() === lowVal) || ~excArray.findIndex((v) => v.toLowerCase() === lowVal)
      ? error
      : null;
  }

  validateIsEditLinux(incArray: string[], excArray: string[], value: string): boolean {
    const isNew = value !== this.editedString.value;

    if (this.editedString.isInclude) return !!(~incArray.indexOf(value) && isNew) || !!~excArray.indexOf(value);

    return !!(~excArray.indexOf(value) && isNew) || !!~incArray.indexOf(value);
  }

  validateIsEditWindows(incArray: string[], excArray: string[], value: string): boolean {
    const lowVal = value.toLowerCase();
    const isNew = lowVal !== this.editedString.value.toLowerCase();

    if (this.editedString.isInclude) {
      return (
        !!(~incArray.findIndex((v) => v.toLowerCase() === lowVal) && isNew) || !!~excArray.findIndex((v) => v.toLowerCase() === lowVal)
      );
    }

    return !!(~excArray.findIndex((v) => v.toLowerCase() === lowVal) && isNew) || !!~incArray.findIndex((v) => v.toLowerCase() === lowVal);
  }

  showHidePlaneTextHandler(): void {
    this.showPlainText = !this.showPlainText;
    if (!this.showPlainText) this.getBaseTreeData();
  }

  includeExcludeStringBlur(event, name: string): void {
    if (!event.target.value) this.foldersForm.get(name).reset('');
  }

  includeExcludeInputHandler(event, isInclude = true): void {
    const dataForPath: DataForPath = {
      isLinux: this.isLinux,
      regExpAfter: this.regExpForInputAfter,
      regExpBefore: this.regExpForInputBefore,
      split: this.splitForInput,
      join: this.joinForInput,
      splitForBefore: this.splitForInputBefore
    };
    const formattedObj = BaseForStepsHelper.getStringPathFormatted(event, dataForPath);
    if (formattedObj && formattedObj.needUpdate) {
      this.foldersForm.get(isInclude ? 'includeString' : 'excludeString').patchValue(formattedObj.path);
    }
  }

  getFilteredStringsByString(myArray, str: string, isInclude = true): string[] {
    const last = str.slice(-1);
    const lowStr = this.isLinux ? str : str.toLowerCase();

    return Array.from(
      myArray.filter((f) => {
        const lowF = this.isLinux ? f : f.toLowerCase();

        if (isInclude && !lowStr.includes(lowF)) return true;

        if (isInclude) {
          const fLast = f.slice(-1);
          const strByFLen = str[f.length];

          return fLast && fLast !== this.joinForInput && strByFLen && strByFLen !== this.joinForInput;
        }

        if (!lowF.includes(lowStr)) return true;

        const fByStrLen = f[str.length];

        return last && last !== this.joinForInput && fByStrLen && fByStrLen !== this.joinForInput;
      })
    );
  }

  addIncludeExcludeStringHandler(event, isInclude = true): void {
    const strControl = this.foldersForm.get(isInclude ? 'includeString' : 'excludeString') as FormControl;

    if (event.id === 'closeIncludeFolderButton' || event.id === 'closeExcludeFolderButton') {
      this.editedString = null;

      return void this.resetFormsAfterChangeStrings(strControl);
    }

    const newStr = strControl.value.trim();
    const errorsCount = !strControl.valid && Object.keys(strControl.errors).length;
    const valid =
      strControl.valid ||
      (errorsCount === 1
        ? strControl.errors.whitespace || strControl.errors.required
        : errorsCount === 2
        ? strControl.errors.whitespace && strControl.errors.required
        : false);

    if (valid && newStr && (event.id === 'newIncludeFolderButton' || event.id === 'newExcludeFolderButton')) {
      this.changeIncludeExcludeStringsArrays(isInclude, newStr);
      this.resetFormsAfterChangeStrings(strControl);
    }
  }

  resetFormsAfterChangeStrings(strControl): void {
    strControl.reset('');
    this.needValidate = false;
  }

  changeIncludeExcludeStringsArrays(isInclude = true, newStr: string): void {
    const controlName = isInclude ? 'includeFolders' : 'excludeFolders';
    const otherName = !isInclude ? 'includeFolders' : 'excludeFolders';
    const folders = BaseForStepsHelper.getFilteredStringsByString(
      this.stepForm.get(controlName),
      newStr,
      this.editedString && this.editedString.value,
      this.isLinux
    );
    const secondFolders = this.getFilteredStringsByString(this.stepForm.get(otherName).value, newStr, isInclude);

    if (this.editedString) {
      BaseForStepsHelper.updateFoldersAfterEdit(newStr, this.editedString.value, folders, this.isLinux);
      this.stepForm.get(controlName).reset([...folders]);
      this.stepForm.get(otherName).reset([...secondFolders]);
      this.editedString = null;
      return;
    }

    this.stepForm.get(controlName).reset([...folders, newStr]);
    this.stepForm.get(otherName).reset([...secondFolders]);
  }

  someStringExist(strings, inculde, exclude): boolean {
    if (this.isLinux) return strings.some((s) => exclude.some((str) => s === str) || inculde.some((str) => s === str));

    return strings.some((s) => {
      const lowS = s.toLowerCase();
      return exclude.some((str) => lowS === str.toLowerCase()) || inculde.some((str) => lowS === str.toLowerCase());
    });
  }

  pasteFromClipboardHandler(error: TemplateRef<any>, isInclude = true): void {
    navigator.clipboard
      .readText()
      .then((value) => {
        const strings = BaseForStepsHelper.stringsArrayFromClipboard(value, this.isLinux, this.splitForInput, this.joinForInput);
        const includeItems = this.stepForm.get(isInclude ? 'includeFolders' : 'excludeFolders').value;
        const otherStrings = this.stepForm.get(!isInclude ? 'includeFolders' : 'excludeFolders').value;
        const exist = this.someStringExist(strings, includeItems, otherStrings);
        const invalidPaths = strings.filter((s) => (this.isLinux ? linuxInvalidPath.test(s) : windowsInvalidPath.test(s)));
        const valid = !invalidPaths.length && !exist;

        if (valid) {
          const filteredOldStrings = BaseForStepsHelper.getFilteredStrings(includeItems, strings, this.joinForInput, this.isLinux);
          filteredOldStrings.push(...strings);

          this.needIncludeStrings = isInclude
            ? BaseForStepsHelper.filterFoldersIfExistParent(filteredOldStrings, this.joinForInput, this.isLinux)
            : otherStrings;
          this.needExcludeStrings = isInclude
            ? otherStrings
            : BaseForStepsHelper.filterFoldersIfExistParent(filteredOldStrings, this.joinForInput, this.isLinux);

          this.updateStrings();
          return void this.resetFoldersFormIfNeed();
        }

        const title = exist
          ? this.i18nPipe.transform('validation:must_unique', { name: 'Path' })
          : this.i18nPipe.transform('validation:invalid', { name: 'path' });
        const body = exist
          ? this.i18nPipe.transform('validation:contains_selected_path')
          : this.i18nPipe.transform('validation:clipboard_invalid_path', { paths: invalidPaths.join(', ') });

        this.stepService.infoModalShow(title, body);
        this.resetFoldersFormIfNeed();
      })
      .catch((_) => this.stepService.infoModalShow(this.i18nPipe.transform('validation:read_permission'), error));
  }

  editHandler(index: number, isInclude = true): void {
    const strControl = this.foldersForm.get(isInclude ? 'includeString' : 'excludeString');
    const folders = this.stepForm.get(isInclude ? 'includeFolders' : 'excludeFolders');

    this.editedString = { value: folders.value[index], isInclude, index };
    strControl.setValue(folders.value[index]);
  }

  delIncludeExcludeStringHandler(index: number, isInclude = true): void {
    const control = this.stepForm.get(isInclude ? 'includeFolders' : 'excludeFolders');
    const array = Array.from(control.value);

    array.splice(index, 1);
    control.patchValue(array);
    this.foldersForm.get(isInclude ? 'includeString' : 'excludeString').updateValueAndValidity();
  }

  getSubtree(root: TreeElement): Observable<TreeElement[]> {
    const subtree$ = new BehaviorSubject<TreeElement[]>(null);
    const pathArrays: PathArrays = this.getPathArraysAndSetParamsPath(root);

    this.treeParams.params.offset = root?.children?.length | 0;
    if (root.indeterminate && !root.checked) (root as MyTreeElement).needUncheckIfChildUncheck = true;

    this.stepService.getRemoteCommandData(this.treeParams, this.hid).subscribe({
      next: (data) => subtree$.next(this.getTreeItemsAndUpdateRoot(data, root, pathArrays)),
      error: () => subtree$.next([])
    });

    return subtree$ as Observable<TreeElement[]>;
  }

  changeTreeHandler(item: MyTreeElement): void {
    this.changeTreeStateFromItemChanged(item);

    this.updateStringsArraysFromItem(item, this.getStringFromTreeItem(item));
    let parent: MyTreeElement = item.parent || item;
    while (parent?.parent) {
      parent = parent.parent;
    }
    this.updateStringsFromTree(parent);

    this.needUpdate = true;
    if (!this.showPlainText) this.updateStrings();
  }

  updateStrings(): void {
    const mainForm = this.stepForm.get('includeFolders');
    const secondForm = this.stepForm.get('excludeFolders');
    mainForm.reset([...new Set(this.needIncludeStrings)]);
    secondForm.reset([...new Set(this.needExcludeStrings)]);
    this.needUpdate = false;
    this.foldersForm.get('includeString').updateValueAndValidity();
    this.foldersForm.get('excludeString').updateValueAndValidity();
  }

  showLegend(): void {
    const modal = this.modal.openRef(CheckboxLegendComponent, { isCustom: true });

    modal.result.then(noop).catch(noop);
  }

  addNetworkShare(): void {
    const settings = new ModalSettings();
    settings.isCustom = true;
    settings.data = {
      canUseShareTree: this.mainService.backupVersionUpdated && +this.mainService.backupVersionUpdated.substring(0, 3) > 795, // TODO Check valid version
      networkSharePathArray: Array.from(this.networkSharePathArray),
      hid: this.mainService.hid,
      dataForPath: this.getValueForOperationsWithPath
    };

    this.modal
      .openCustom(NetworkShareModalComponent, settings)
      .then((newPathArray: NetworkCredentials[]) => {
        this.networkSharePathArray = newPathArray;

        const shareHash: { [key: string]: TreeElement } = {};
        const newTreeData = this.treeData.filter((el: any) => !el.isShare);
        this.shareTreeItems.forEach((item) => (shareHash[item.id] = item));
        this.shareTreeItems = [];
        this.needIncludeStrings = this.stepForm.get('includeFolders').value;
        this.needExcludeStrings = this.stepForm.get('excludeFolders').value;

        newPathArray.forEach((item: NetworkCredentials) => {
          const path = (shareHash[item.id] as any)?.path?.toLowerCase();
          const isEqualPath = path === item.path.toLowerCase();

          if (!isEqualPath) {
            this.needIncludeStrings = this.needIncludeStrings.filter((str: string) => {
              return StepsHelpers.pathNotIncludePath(false, this.joinForInput, str, path);
            });
            this.needExcludeStrings = this.needExcludeStrings.filter((str: string) => {
              return StepsHelpers.pathNotIncludePath(false, this.joinForInput, str, path);
            });
          }

          this.shareTreeItems.push({
            label: item.path,
            id: item.id,
            checked: !!shareHash[item.id]?.checked && isEqualPath,
            indeterminate: !!shareHash[item.id]?.indeterminate && isEqualPath,
            icon: AlternativeTreeIconPath[1],
            gotChildren: false,
            shown: true,
            path: item.path,
            isShare: true
          } as any);
        });

        newTreeData.push(...this.shareTreeItems);
        this.treeData = newTreeData;
        this.stepForm.get('includeFolders').reset(this.needIncludeStrings);
        this.stepForm.get('excludeFolders').reset(this.needExcludeStrings);
      })
      .catch(noop);
  }

  // Private block start
  private resetFoldersFormIfNeed(): void {
    const formInput = this.foldersForm.get('includeString');

    if (!formInput.value) formInput.reset('');
  }

  private getBaseTreeData(): void {
    this.treeParams.params.path = '';
    this.needIncludeStrings = this.stepForm.get('includeFolders').value;
    this.needExcludeStrings = this.stepForm.get('excludeFolders').value;
    this.loading = true;

    this.stepService
      .getRemoteCommandData(this.treeParams, this.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          if (data?.data) {
            this.treeData = data.data.items.map((item, idx) => {
              return {
                label: item.displayName,
                id: idx.toString(),
                checked: false,
                expanded: false,
                icon: +item.type === 1 ? TreeIconPath.Folder : TreeIconPath.Disk,
                shown: true,
                path: item.path
              };
            });
            this.updateTreeFromStepFormArray();
            this.loading = false;
          }
        },
        error: () => (this.loading = false)
      });
  }

  private getPathArraysAndSetParamsPath(root: TreeElement): PathArrays {
    const mainFormArray = this.stepForm.get('includeFolders').value;
    const excFormArray = this.stepForm.get('excludeFolders').value;
    let newInc = [];
    let newExc = [];

    this.treeParams.params.path =
      this.isLinux || networkValidPath.test((root as any).path)
        ? (root as any).path
        : StepsHelpers.getPathInStringFormatByOs(root, this.isLinux);

    if (this.isLinux) {
      newInc = StepsHelpers.findInNotRootFolder(mainFormArray, this.treeData);
      newExc = StepsHelpers.findInNotRootFolder(excFormArray, this.treeData);
    }

    return { mainFormArray, excFormArray, newInc, newExc };
  }

  private updateDataItemsAndGetSettingsForHelper(
    data: { data: { items: any[]; totalCount: number; count: number } },
    root: TreeElement,
    newInc: string[],
    newExc: string[]
  ): GenerateTreeDataSettings {
    const existData = data?.data?.items?.length;
    const settings = {
      root,
      newInc,
      newExc,
      joinStr: this.joinForInput,
      sep: this.separator,
      alt: existData && isNaN(+data.data.items[0].type),
      isLinux: this.isLinux
    };

    if (existData) {
      data.data.items = StepsHelpers.getSortedTreeItems(data.data.items, settings.alt);
    }

    return settings;
  }

  private getTreeItemsAndUpdateRoot(data: TreeRequestData, root: MyTreeElement, pathArrays: PathArrays): TreeElement[] {
    const newElements: ExtendedTreeElement[] = StepsHelpers.generateTreeData(
      data,
      pathArrays.mainFormArray,
      pathArrays.excFormArray,
      this.updateDataItemsAndGetSettingsForHelper(data, root, pathArrays.newInc, pathArrays.newExc)
    );

    root.totalChildren = StepsHelpers.getTotalChildren(data?.data, newElements.length);

    return newElements;
  }

  private floatNeedUncheckToLast(item: MyTreeElement): void {
    item.needUncheckIfChildUncheck = !item.notSetUncheckFlag;
    if (item.parent && !item.parent.checked) this.floatNeedUncheckToLast(item.parent);
  }

  private floatNotNeedUncheckToLast(item: MyTreeElement): void {
    if (item?.needUncheckIfChildUncheck) item.checked = false;

    if (
      item?.needUncheckIfChildUncheck &&
      item.children?.length &&
      !item.children[0].indeterminate &&
      (item.children.length === 1 ||
        item.children.every((c) => c.indeterminate === item.children[0].indeterminate && c.checked === item.children[0].checked))
    ) {
      item.indeterminate = false;
    }

    if (
      !item?.needUncheckIfChildUncheck &&
      item?.children?.length > 1 &&
      item.children[0].checked &&
      item.children.every((c) => !c.indeterminate && c.checked === item.children[0].checked)
    ) {
      item.indeterminate = false;
    }

    if (item?.parent) this.floatNotNeedUncheckToLast(item.parent);
  }

  private floatNeedUncheckToLastIfChecked(item: MyTreeElement): void {
    if (item?.children?.every((c) => c.checked)) {
      item.checked = true;
      item.indeterminate = false;
      item.needUncheckIfChildUncheck = !item.notSetUncheckFlag;

      this.floatNeedUncheckToLastIfChecked(item.parent);
    }
  }

  private changeTreeStateFromItemChanged(item: MyTreeElement): void {
    item.notSetUncheckFlag = item.checked;
    this.updateChildrenNotSetUncheckFlag(item.children, item.checked);
    item.needUncheckIfChildUncheck = false;

    if (!item.checked || !item.parent) {
      return void (item.parent && this.floatNotNeedUncheckToLast(item.parent));
    }

    if (!item.parent.checked) return void this.floatNeedUncheckToLast(item.parent);

    if (!(item.parent as MyTreeElement).notSetUncheckFlag && item.parent.children?.length === 1 && item.parent.children[0].checked) {
      return void this.floatNeedUncheckToLastIfChecked(item.parent);
    }

    this.checkParentToAllCheckedAndUpdateCheckState(item.parent);
  }

  private checkParentToAllCheckedAndUpdateCheckState(item: MyTreeElement): void {
    if (!item?.parent) return;

    if (!item.parent.checked && item.parent.children?.every((c) => c.checked && !c.indeterminate)) {
      item.parent.checked = true;
      item.parent.indeterminate = false;
    }

    this.checkParentToAllCheckedAndUpdateCheckState(item.parent);
  }

  private updateChildrenNotSetUncheckFlag(children: MyTreeElement[], state: boolean): void {
    if (children?.length) {
      children?.forEach((c) => {
        c.notSetUncheckFlag = state;
        this.updateChildrenNotSetUncheckFlag(c.children, state);
      });
    }
  }

  private getLastNeedUncheckParent(item: MyTreeElement): MyTreeElement {
    let needNextParent = (item.parent as MyTreeElement)?.needUncheckIfChildUncheck && (item.parent?.checked || !item.parent?.indeterminate);
    let parent: MyTreeElement = needNextParent ? item.parent : null;

    while (needNextParent) {
      needNextParent =
        (parent.parent as MyTreeElement)?.needUncheckIfChildUncheck && (parent.parent?.checked || !parent.parent?.indeterminate);

      if (needNextParent) parent = parent.parent;
    }

    return parent;
  }

  private updateStringsArraysFromItem(item: MyTreeElement, str = ''): void {
    const parentForGetStr = this.getLastNeedUncheckParent(item);
    const newStr = parentForGetStr ? this.getStringFromTreeItem(parentForGetStr) : str;

    this.filterNeedUpdatedStringsArray(newStr, this.needIncludeStrings);
    this.filterNeedUpdatedStringsArray(newStr, this.needExcludeStrings, false);
    this.needIncludeStrings = this.needIncludeStrings.filter((s) => !StepsHelpers.equalStrings(s, newStr, this.isLinux));
    this.needExcludeStrings = this.needExcludeStrings.filter((s) => !StepsHelpers.equalStrings(s, newStr, this.isLinux));
    this.deleteStringsByParent(item.parent);
  }

  private deleteStringsByParent(item: MyTreeElement): void {
    let parent: MyTreeElement = item;

    while (parent) {
      const str = this.getStringFromTreeItem(parent);

      if (parent.checked && !parent.indeterminate) {
        this.filterNeedUpdatedStringsArray(str, this.needIncludeStrings);
        this.filterNeedUpdatedStringsArray(str, this.needExcludeStrings, false);
      }

      if (!parent.checked && parent.indeterminate) {
        this.needIncludeStrings = this.needIncludeStrings.filter((s) => !StepsHelpers.equalStrings(s, str, this.isLinux));
        this.needExcludeStrings = this.needExcludeStrings.filter((s) => !StepsHelpers.equalStrings(s, str, this.isLinux));
      }

      parent = parent.parent;
    }
  }

  private updateStringsFromTree(item: MyTreeElement, path = '', needPushChecked = true): void {
    const pathArrayName = needPushChecked ? 'needIncludeStrings' : 'needExcludeStrings';

    if (item.checked ? needPushChecked : !needPushChecked) {
      const str = path || this.getStringFromTreeItem(item);
      this[pathArrayName].push(str);
    }

    if (item.indeterminate && item.children?.length) item.children.forEach((child) => this.updateStringsFromTree(child, '', !item.checked));
  }

  private filterNeedUpdatedStringsArray(str: string, stringsArray: string[], isInclude = true): void {
    const strArrayLength = str.split(this.joinForInput).filter((s) => !!s && s !== ' ').length;
    const lowStr = this.isLinux ? str : str.toLowerCase();
    const newArray = stringsArray.filter((s) => {
      const lowS = this.isLinux ? s : s.toLowerCase();
      const sLen = s.split(this.joinForInput).filter((s) => !!s && s !== ' ').length;
      return strArrayLength === sLen
        ? lowS !== lowStr
        : !lowS.startsWith(lowStr) ||
            (StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, lowS, lowStr) &&
              (StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, lowStr, lowS) || strArrayLength > sLen));
    });

    this[isInclude ? 'needIncludeStrings' : 'needExcludeStrings'] = newArray;
  }

  private getStringFromTreeItem(item: TreeElement & { path?: string }): string {
    return item.path;
  }

  private updateTreeFromStepFormArray(): void {
    const incArray = this.stepForm.get('includeFolders').value;
    const excArray = this.stepForm.get('excludeFolders').value;
    const incExistInTree: string[] = [];
    const excExistInTree: string[] = [];

    this.treeData.forEach((item: TreeElement) => {
      const path = this.isLinux ? (item as any).path : (item.label as string);
      const incCurrent = incArray.some((incStr) => !StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, incStr, path));
      const excCurrent = excArray.some((excStr) => !StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, excStr, path));
      item.checked = incArray.some((s) => StepsHelpers.equalStrings(path, s, this.isLinux));
      if (this.isLinux && path === this.joinForInput) {
        const newInc = StepsHelpers.findInNotRootFolder(incArray, this.treeData);
        const newExc = StepsHelpers.findInNotRootFolder(excArray, this.treeData);
        item.indeterminate = !!newInc.length && (!item.checked || !!newExc.length);
      } else {
        item.indeterminate = incCurrent && (!item.checked || excCurrent);
      }

      if (incCurrent || item.checked) incExistInTree.push(path);
      if (excCurrent) excExistInTree.push(path);
    });

    if (!this.isLinux) {
      this.addShareTreeItems(incArray, excArray);
      this.addTreeItemFroUniquePaths(incArray, excArray, incExistInTree, excExistInTree);
    }
  }

  private addShareTreeItems(incArray: string[], excArray: string[]): void {
    if (this.shareTreeItems?.length) return void this.treeData.push(...this.shareTreeItems);

    const newInc = incArray.filter((str: string) => networkValidPath.test(str));
    const newExc = excArray.filter((str: string) => networkValidPath.test(str));

    const rootsMap: { [key: string]: boolean } = {};

    newInc.forEach((path: string, idx: number) => {
      const rootPath = StepsHelpers.getRootPathForShare(path);
      const checked = newInc.some((s) => StepsHelpers.equalStrings(rootPath, s, this.isLinux));
      const existInExclude = newExc.some((str) => !StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, str, path));

      if (!rootsMap[rootPath]) {
        rootsMap[rootPath] = true;

        this.shareTreeItems.push({
          label: rootPath,
          id: idx + this.separator + rootPath.replaceAll(' ', '-'),
          checked: checked,
          indeterminate: !checked || existInExclude,
          icon: AlternativeTreeIconPath[1],
          gotChildren: false,
          shown: true,
          path: rootPath,
          isShare: true
        } as any);
      }
    });

    this.networkSharePathArray = this.shareTreeItems.map((item) => ({ path: (item as any).path, login: '', id: item.id }));
    this.treeData.push(...this.shareTreeItems);
  }

  private addTreeItemFroUniquePaths(incArray: string[], excArray: string[], incExistInTree: string[], excExistInTree: string[]): void {
    const uniqIncArray = incArray.filter((str: string) => /^(%)([A-Za-z0-9 _\-.]*%\\)/.test(str) && !incExistInTree.includes(str));
    const uniqExcArray = excArray.filter((str: string) => /^(%)([A-Za-z0-9 _\-.]*%\\)/.test(str) && !excExistInTree.includes(str));

    uniqIncArray.forEach((path: string, idx: number) => {
      const existInExclude = uniqExcArray.some((str) => !StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, str, path));
      this.treeData.push({
        label: path,
        id: idx + this.separator + path.replaceAll(' ', '-'),
        checked: !existInExclude,
        indeterminate: existInExclude,
        icon: TreeIconPath.Folder,
        gotChildren: true,
        shown: true,
        path: path
      } as TreeElement);
    });
  }
}
