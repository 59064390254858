import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { IterationCtrlButtonComponent } from './iteration-ctrl-button.component';

@NgModule({
  imports: [NgbTooltipModule, MbsUiKitModule, CommonModule],
  declarations: [IterationCtrlButtonComponent],
  exports: [IterationCtrlButtonComponent]
})
export class IterationCtrlButtonModule {
  constructor() {}
}
