import * as TerminalActions from './terminal.actions';

import {EntityAdapter, EntityState, createEntityAdapter} from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { TerminalChunk } from './terminal.model';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface State extends EntityState<TerminalChunk> {};
export const adapter: EntityAdapter<TerminalChunk> = createEntityAdapter<TerminalChunk>();
export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(TerminalActions.addChunk, (state, action) => adapter.addOne(action.line, state)),
  on(TerminalActions.clearCache, state => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
