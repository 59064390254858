import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { LicensesService } from '@services/licenses.service';
import { ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject, map, take } from 'rxjs';
import { Balance, Debt } from '../licenses/models/license.model';
import { BALANCE_TOKEN } from '../licenses/tokens/balance.token';
@Component({
  selector: 'mbs-payment-debt-modal',
  templateUrl: './payment-debt-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentDebtModalComponent implements OnInit {
  public userId = this.authService.currentUser.pipe(map((user) => user.Id));

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public debt: Debt = null;
  public loading = true;
  public payLinkLoading = false;

  constructor(
    public authService: AuthService,
    private licenseService: LicensesService,
    private cdr: ChangeDetectorRef,
    @Inject(BALANCE_TOKEN) public balance: BehaviorSubject<Balance>
  ) {}

  ngOnInit() {
    this.setDebt();
  }

  goToPay() {
    this.payLinkLoading = true;

    this.licenseService
      .getPayDebt()
      .pipe(take(1))
      .subscribe((payDebt) => {
        this.payLinkLoading = false;
        this.cdr.detectChanges();
        window.open(payDebt.paymentLink, '_blank');
      });
  }

  setDebt() {
    this.loading = true;

    this.licenseService
      .getDebt()
      .pipe(take(1))
      .subscribe((debt) => {
        this.debt = debt;
        this.loading = false;
        this.cdr.detectChanges();
      });
  }
}
