import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { addBreadcrumb, SeverityLevel } from '@sentry/browser';
import { isNil } from 'lodash';
import { merge, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { DOMEvent } from '../form/input-base/input-base.model';
import { InputButton } from '../form/input-base/input-pre-append/input-button';
import { ModalService, ModalSettings } from '../modal/modal.service';
import { BootstrapTheme } from '../utils';
import { WizardStepComponent } from './wizard-step.component';
import { WizardTitle } from './wizard-title.directive';
import { WizardStepQueue } from './wizard/queue';
import { WizardStep } from './WizardStep';

const WIZARD_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line no-use-before-define
  useExisting: forwardRef(() => WizardComponent),
  multi: true
};

const discardChangesText = 'Discard Changes';

@Directive({
  selector: '[mbsWizardFooterPrepend]'
})
export class WizardFooterPrependDirective {
  @Input() hide = false;
  constructor(public template: TemplateRef<any>) {}
}

@UntilDestroy()
@Component({
  selector: 'mbs-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  providers: [WIZARD_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardComponent implements OnInit, OnDestroy, OnChanges, AfterContentInit, AfterViewChecked, ControlValueAccessor {
  public readonly defaultWizardHeight = '594px';

  @Input() ignoreSteps: string[] = [];
  @Input() height: string | number = this.defaultWizardHeight;
  @Input() loading = false;
  @Input() buttonLoadingMode = false;
  @Input() stepQueue: WizardStepQueue = null;
  @Input() nextAndBackButtonType: BootstrapTheme = 'primary';
  @Input() hideNextAndBackButtons? = false;
  @Input() backButton: InputButton = { text: 'Back' };
  @Input() nextButton: InputButton = { text: 'Next' };
  @Input() saveButton: InputButton = { text: 'Save' };
  @Input() saveButtonGroup?: InputButton[];
  @Input() cancelButton: InputButton = { text: 'Cancel' };
  @Input() disableSpinner = false;
  @Input() hideCrossButton? = false;
  @Input() showSaveBtnOnClose = false;

  /**
   * `True` - wizard will close modal
   * @param {boolean} value
   */
  @Input() set canClosed(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.spinnerShow = false;
        this.activeModal.close('Save');
      }, 100);
    }
  }

  /**
   * If `false` wizard will not display whole footer.
   */
  @Input() showFooter = true;

  /**
   * If `false` wizard will not check changes by itself, when it closes.
   */
  @Input() checkChanges = true;

  /**
   * Wizard title
   */
  @Input() title = 'Wizard Title';
  /**
   * Steps orientation.\
   * if value is not set, the steps location will be based on the number of steps.\
   * 5 > is `vertical` else `horizontal`
   */
  @Input() stepsOrientation: 'horizontal' | 'vertical';
  /**
   * Set false to hide steps.
   */
  @Input() showSteps = true;
  /**
   * Custom complete style step
   */
  @Input() completeClass = 'success';
  @Input() invalidClass = 'invalid';
  /**
   * Custom active style step
   */
  @Input() activeClass = 'active';

  @Input() isOpenSteps = false;

  @Input() stepsContainerClass = '';

  @Input() blockEnterIfControlFocus = true;

  /**
   * Disable internal @HostListener if the wizard has complex logic
   * Next step action on Enter key will be disabled
   * Previous step action on Backspace will be disabled
   */
  @Input() disableStepChangeOnKeyboardKey = false;

  /**
   * Pass 'true' to prevent 'Unsaved Changes' modal showing
   */
  @Input() canCloseWithoutSave = false;

  /**
   * Custom handler, which called on close button click
   */
  @Input() closeHandler?: () => Observable<any>;

  /**
   * Pass 'true' in case of initial data loading.
   * Difference between this prop and 'loading' in a loader-content position
   * In case of 'initialLoading' loader occupies all content area. Step tabs doesn't display
   */
  @Input() initialLoading = false;

  /**
   * Emit on finish save click. Wizard don't close self after the save emit.\
   * You need to set `canClosed` flag to close it.
   */
  @Output('save') saveEvent = new EventEmitter<any>();
  @Output('next') nextEvent = new EventEmitter<WizardStep>();

  @Output() stepSelected = new EventEmitter<WizardStep>();

  /**
   * Find all available steps @see WizardStepComponent
   */
  @ContentChildren(WizardStepComponent) steps: QueryList<WizardStepComponent>;
  @ContentChild(WizardTitle, { static: true, read: WizardTitle }) wizardTitle: WizardTitle;
  @ContentChild(WizardFooterPrependDirective, { static: false, read: WizardFooterPrependDirective })
  public sanitizedHeight: SafeStyle = this.defaultWizardHeight;
  public spinnerShow = false;
  public prepend: WizardFooterPrependDirective;

  private internalStep: WizardStepComponent;
  private confirmClose: boolean;

  set currentStep(value: WizardStepComponent) {
    this.nextEvent.emit(value);
    this.internalStep.nextEvent.emit(this.internalStep);
    this.updateStepState(value);
    this.internalStep = value;
    this.notifyValueChange();
    this.stepQueue?.registerStepDisplay(value.title);
  }

  get currentStep(): WizardStepComponent {
    return this.internalStep;
  }

  get allStepValid(): boolean {
    return !this.steps?.some(s => s.valid === false);
  }

  constructor(
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewChecked(): void {
    if (this.height && !this.sanitizedHeight) this.setSanitizedHeight();
  }

  onChange: (value) => void;
  onTouched: () => void;

  @HostListener('window:keydown.esc', ['$event'])
  closeWizard(event: KeyboardEvent): void {
    const modalList = this.document.querySelectorAll('ngb-modal-window');
    const listLength = modalList.length;

    if (listLength > 1 && !modalList[listLength - 1].querySelector('mbs-wizard')) return;
    else {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.confirmClose) return;

    const reason = 'keyboard:escape';

    if (!this.currentStep.hasChanges && !this.steps.some((s) => s.changesBeforeValidation)) this.activeModal.close(reason);
    else this.closeWithConfirm(reason);
  }

  closeWithConfirm(reason: string): void {
    if (
      this.checkChanges &&
      (this.steps.toArray().indexOf(this.currentStep) > 0 || this.currentStep.hasChanges) &&
      !this.canCloseWithoutSave
    ) {
      this.confirmClose = true;
      const canSave = (this.allStepValid && this.currentStep === this.steps.last) || this.showSaveBtnOnClose;
      const modalSettings: ModalSettings = {
        header: { title: 'Unsaved Changes' },
        footer: { okButton: { text: canSave ? 'Save' : 'Back' }, cancelButton: { text: discardChangesText } }
      };
      const modalTitle =
        this.allStepValid || this.currentStep !== this.steps.last ? 'All changes will be lost' : 'Not all fields are filled in correctly';

      this.modalService
        .confirm(modalSettings, modalTitle)
        .then(() => {
          this.confirmClose = false;
          canSave ? this.handleSave() : this.goToErrorStep();
        })
        .catch(() => this.confirmClose && this.activeModal.close(discardChangesText));
    } else {
      this.activeModal.close(reason);
    }
  }

  @HostListener('document:keyup.enter', ['$event'])
  nextStepWizard(event: KeyboardEvent & DOMEvent<HTMLInputElement>): void {
    if (this.disableStepChangeOnKeyboardKey) return;
    if (
      !this.loading &&
      !this.spinnerShow &&
      (!this.blockEnterIfControlFocus ||
        !this.currentStep.blockEnterIfControlFocus ||
        (event.target && (event.target.classList.contains('mbs-wizard_nav-tabs') || isNil(event.target.type))))
    ) {
      this.steps.last === this.currentStep ? this.handleSave() : this.changeStep(1);
    }
  }

  @HostListener('document:keyup.backspace', ['$event'])
  backStepWizard(event: KeyboardEvent & DOMEvent<HTMLInputElement>): void {
    if (this.disableStepChangeOnKeyboardKey) return;

    if (!this.loading && isNil(event?.target?.type) && this.steps.first !== this.currentStep) this.changeStep(-1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.steps && changes && changes.isOpenSteps && changes.isOpenSteps.currentValue) {
      this.steps.forEach(step => (step.completed = true));
    }

    if (changes && changes.height) {
      this.setSanitizedHeight();
    }
  }

  ngAfterContentInit(): void {
    if (this.isOpenSteps) this.steps.forEach(step => (step.completed = true));

    this.steps.changes.subscribe((v: WizardStepComponent[]) => this.initWizardSteps(v));
    this.initWizardSteps(this.steps.toArray());
    merge(...this.steps.map(s => s.change))

    merge(...this.steps.map((s) => s.change))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetConfirmClose();
        this.markForCheck();
      });

    this.steps.changes.subscribe((steps: WizardStepComponent[]) => {
      if (this.isOpenSteps) steps.forEach(step => (step.completed = true));
    });

    if (this.height) this.setSanitizedHeight();

    this.markForCheck();
  }

  setSanitizedHeight(): void {
    const height = typeof this.height === 'number' ? String(this.height) + 'px' : String(this.height);

    this.sanitizedHeight = this.sanitizer.bypassSecurityTrustStyle(height);
    this.cd.detectChanges();
  }

  resetConfirmClose(): void {
    if (!isNil(this.confirmClose) && this.confirmClose) this.confirmClose = false;
  }

  handleSave(action?: () => void): void {
    const hasQueue = this.stepQueue?.getQueue().length;
    const allStepValid = this.allStepValid;

    if (allStepValid && !hasQueue) {
      this.spinnerShow = true;
      return void (action ? action() : this.saveEvent.emit());
    }

    this.currentStep.isSave = true;
    allStepValid ? this.goToRequiredStep(true) : this.goToErrorStep(true);

    if (!this.currentStep.valid) {
      this.currentStep.nextEvent.emit(this.currentStep);
    }
  }

  handleClose(): void {
    const handler$ = this.closeHandler ? this.closeHandler() : of(null);

    handler$.pipe(untilDestroyed(this), take(1)).subscribe(() => {
      this.activeModal.close(discardChangesText);
    });
  }

  private initWizardSteps(newSteps: WizardStepComponent[]): void {
    if (!this.stepsOrientation) {
      this.stepsOrientation = newSteps.length > 5 ? 'vertical' : 'horizontal';
    }

    this.internalStep = this.internalStep || newSteps[0];
    this.internalStep.visible = true;
    this.internalStep.activated = true;

    this.notifyValueChange();
  }

  goToRequiredStep(skipCurrentStep = false): void {
    const steps = this.steps.toArray();
    const queue = this.stepQueue.getQueue();
    const nextStep = steps.find(step => {
      return skipCurrentStep ? queue.includes(step.title) && step.title !== this.currentStep.title : queue.includes(step.title);
    });

    if (nextStep) this.currentStep = nextStep;
  }

  goToErrorStep(skipCurrentStep = false): void {
    const steps = this.steps.toArray();
    const idx = steps.findIndex(step => (!step.valid || !step.completed) && (!skipCurrentStep || step.title !== this.currentStep.title));

    if (~idx) this.currentStep = steps[idx];
  }

  /**
   * Change step to N positions
   * @param {number} direction
   */
  changeStep(direction: number): void {
    this.currentStep.isNext = direction >= 0;
    this.currentStep.isSave = false;
    this.internalStep.changesBeforeValidation = this.internalStep.hasChanges;

    if (!this.currentStep.valid && direction > 0) {
      this.currentStep.nextEvent.emit(this.currentStep);

      return;
    }

    this.currentStep = this.getStepByDirection(direction) || this.currentStep;
  }

  /**
   * Open some step
   * @param {DOMEvent<HTMLElement>} event
   * @param {WizardStepComponent} value
   */
  selectManualStep(event: DOMEvent<HTMLElement>, value: WizardStepComponent): void {
    const stepsArray = this.steps.toArray();
    const currentStepIndex = stepsArray.findIndex((s) => s.title === this.currentStep.title);
    const targetStepIndex = stepsArray.findIndex((s) => s.title === value.title);

    this.currentStep.isNext = targetStepIndex > currentStepIndex;

    if (event?.target) event.target.blur();

    this.stepSelected.emit(value);
    this.currentStep = value;
  }

  /**
   * Set active and complete classes for previous and current step
   * @param {WizardStepComponent} value
   */
  updateStepState(value: WizardStepComponent): void {
    const steps = this.steps.toArray();

    if (steps.indexOf(value) > steps.indexOf(this.internalStep)) {
      this.internalStep.complete();
    } else {
      this.internalStep.notActivate();
    }

    value.activate();

    this.markForCheck();
  }

  notifyValueChange(): void {
    if (this.onChange) {
      setTimeout(() => this.onChange(this.currentStep), 100);
    }
  }

  markForCheck(): void {
    if (!(this.cd as ViewRef).destroyed) {
      this.cd.markForCheck();
    }
  }

  writeValue(obj: { title: string }): void {
    if (!obj) return;

    this.selectManualStep(null, this.steps.find((g) => g.title === obj.title) || this.steps.first);
  }

  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Reset all follow steps after this step
   * @param {{title: string}} step Start step to reset
   */
  resetStepsAfter(step: { title: string }): void {
    const stepsArray = this.steps.toArray();
    const index = stepsArray.findIndex((s) => s.title === step.title);

    stepsArray.slice(index + 1).forEach((step) => step.reset());
    this.markForCheck();
  }

  stepContainerClasses(): string[] {
    const classesArr: string[] = [];

    if (this.stepsOrientation === 'vertical' && this.showSteps) classesArr.push('col-9');
    if (this.stepsOrientation === 'vertical' && !this.showSteps) classesArr.push('col-12');
    if (this.stepsContainerClass) classesArr.push(this.stepsContainerClass);

    return classesArr;
  }
  stepClasses(step: WizardStepComponent): any {
    const stepInQueue = this.stepQueue?.getQueue().includes(step.title);

    return {
      [this.activeClass]: step.activated,
      [this.invalidClass]: !step.valid,
      [this.completeClass]: (this.isOpenSteps && step.valid) || step.completed,
      [this.completeClass]: !stepInQueue && ((this.isOpenSteps && step.valid) || step.completed),
      disabled: !(step.completed || step.activated || step.canOpen) && !this.isOpenSteps
    };
  }

  ngOnInit(): void {
    const modal = this.document.querySelector<HTMLElement>('ngb-modal-window');

    if (modal) modal.focus();
    addBreadcrumb({
      category: 'wizard',
      timestamp: new Date().getTime() / 1000,
      message: `Open wizard '${this.title}'`,
      level: 'info' as SeverityLevel
    });
  }

  ngOnDestroy() {
    addBreadcrumb({
      category: 'wizard',
      timestamp: new Date().getTime() / 1000,
      message: `Close wizard '${this.title}'`,
      level: 'info' as SeverityLevel
    });
  }

  private getStepByDirection(direction: number): WizardStepComponent {
    const s = this.steps.toArray();
    let index = direction;
    let step = s[s.indexOf(this.currentStep) + index];
    const isFirst = !this.currentStep.isNext && this.steps.first.title === step.title;
    const isLast = this.currentStep.isNext && this.steps.last.title === step.title;

    if (this.ignoreSteps.includes(step?.title) && !isLast && !isFirst) {
      step.complete();
      index = this.currentStep.isNext ? index + 1 : index - 1;
      step = s[s.indexOf(this.currentStep) + index];
    }

    return step;
  }
}
