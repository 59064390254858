import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepCondition } from './stepper.constants';
import { StepType } from './stepper.types';

@Component({
  selector: 'mbs-stepper',
  templateUrl: './stepper.component.html',
  host: {
    '[class]': 'classList'
  }
})
export class StepperComponent {
  readonly condition = StepCondition;
  @Input() steps: StepType[];
  @Input() size: 'sm' | 'lg' = 'sm';

  get classList(): string {
    return `mbs-stepper -${this.size}`;
  }

  @Output() stepChanged: EventEmitter<StepType> = new EventEmitter<StepType>();
}
