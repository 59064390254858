<div class="mbs-select">
  <mbs-input-label *ngIf="label"
                   [boldLabel]="boldLabel"
                   [disabled]="disabledState"
                   [id]="id"
                   [infoContainer]="infoContainer"
                   [infoPlacement]="infoPlacement"
                   [infoTriggers]="infoTriggers"
                   [info]="info"
                   [labelClasses]="labelClasses"
                   [labelPosition]="labelPosition"
                   [labelSize]="labelSize"
                   [label]="label"
                   [required]="hasRequiredValidator && showRequiredMark"></mbs-input-label>
  <ng-select (add)="addEvent.emit($event)"
             (blur)="blurEvent.emit($event)"
             (change)="ngSelectChangeHandler($event)"
             (clear)="clearEvent.emit($event)"
             (close)="handleClose($event)"
             (focus)="focusEvent.emit($event)"
             (open)="handleOpen()"
             (remove)="removeEvent.emit($event)"
             (search)="onSearch($event)"
             (scroll)="scroll.emit($event)"
             (scrollToEnd)="scrollToEnd.emit()"
             [addTagText]="addTagText"
             [addTag]="addTag"
             [appearance]="appearance"
             [appendTo]="appendTo"
             [bindLabel]="bindLabel"
             [bindValue]="bindValue"
             [clearAllText]="clearAllText"
             [clearOnBackspace]="clearOnBackspace && !isCounterShowing"
             [clearSearchOnAdd]="clearSearchOnAdd"
             [clearable]="clearable"
             [closeOnSelect]="!isCheckboxSelect && closeOnSelect"
             [dropdownPosition]="dropdownPosition"
             [formControl]="formControlSelect"
             [groupBy]="groupBy"
             [hideSelected]="hideSelected"
             [isOpen]="isOpen"
             [items]="items"
             [labelForId]="id"
             [loadingText]="loadingText"
             [loading]="loading"
             [markFirst]="markFirst"
             [maxSelectedItems]="maxSelectedItems"
             [multiple]="multiple"
             [ngClass]="{
               'ng-select-sm': size === 'sm',
               'ng-select-lg': size === 'lg',
               'checkbox': isCheckboxSelect,
               'auto-width': isAutoWidth
             }"
             [notFoundText]="notFoundText"
             [placeholder]="placeholder"
             [readonly]="disabledState"
             [searchFn]="searchFn"
             [searchable]="searchable"
             [selectableGroupAsModel]="selectableGroupAsModel"
             [selectableGroup]="selectableGroup"
             [typeToSearchText]="typeToSearchText"
             [typeahead]="typeahead"
             [virtualScroll]="virtualScroll"
             class="mbs-select_ng-select">
    <ng-container *ngIf="isCounterShowing">
      <ng-template let-items="items" ng-multi-label-tmp>
        <ng-container *ngIf="!searchState?.term?.length">
          <div
            class="counter inline-block bg-primary text-white font-weight-semibold px-1 rounded d-flex align-items-center justify-content-center">
            {{ items?.length }}
          </div>
          {{ labelForCounter }}
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="isCheckboxSelect">
      <ng-template ng-header-tmp *ngIf="!isNotFoundItems()">
        <mbs-checkbox (change)="onSelectAll($event)"
                      [checked]="isAllItemsSelected"
                      [indeterminate]="isSomeItemsSelected()"
                      [labelClasses]="'w-100'"
                      [labelContentClasses]="'w-100'"
                      [label]="'All'"></mbs-checkbox>
      </ng-template>
      <ng-template let-index="index"
                   let-item="item"
                   let-item$="item$"
                   ng-option-tmp>
        <mbs-checkbox (click)="$event.preventDefault()"
                      [checked]="item$.selected"
                      [label]="item[bindLabel]"></mbs-checkbox>
      </ng-template>
    </ng-container>

    <ng-template ng-optgroup-tmp
                 let-item$="item$"
                 let-index="index"
                 let-item="item">
      <mbs-checkbox [style.pointer-events]="'none'"
                    [label]="item.groupBy"
                    [id]="index"
                    [indeterminate]="isSomeItemsSelected(item$) && !item$.selected"
                    [ngModel]="item$.selected"
                    [ngModelOptions]="{ standalone: true }"
      >
      </mbs-checkbox>
    </ng-template>
  </ng-select>
  <mbs-input-errors [errors]="errors"></mbs-input-errors>
</div>
