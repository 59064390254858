<ng-container *ngIf="!isLoading; else loader">
  <h4>{{ 'common.summary' | i18next: { format: 'title' } }}</h4>

  <mbs-alert *ngIf="!isReadyToBackup"
             [type]="mbsPopupType.danger"
             [icon]="true">
    {{ 'onboarding:alerts.noDataSelected' | i18next }}
  </mbs-alert>

  <div class="mb-3">
    <h5 class="mb-2">{{ 'onboarding:dashboardUrl' | i18next: { format: 'title' } }}</h5>

    <a [href]="form.get('aliasUrl')?.value"
       target="_blank">
      {{ form.get( 'aliasUrl' )?.value }}
    </a>
  </div>

  <div class="pt-3 border-top">
    <h5 class="mb-2">{{ 'onboarding:storage_account' | i18next: { format: 'title' } }}</h5>

    <p>{{ poweredByByTypeMap[mode] }}</p>
  </div>

  <div class="pt-3 border-top">
    <h5 class="mb-2">{{ 'onboarding:administrative_account.title' | i18next: { format: 'title' } }}</h5>

    <p>{{ getAccountDescription() }}</p>
  </div>

  <div class="pt-3 border-top">
    <h5 class="mb-2">{{ 'onboarding:backupConfiguration' | i18next: { format: 'title' } }}</h5>

    <ng-container *ngFor="let source of form.get('sources.names').value; let index = index">
      <p class="mb-2">
        {{ source }}: {{
          form.get( 'sources.selected' ).value[index]
            ? ('common.on' | i18next: { format: 'title' })
            : ('common.off' | i18next:{ format: 'title' })
        }}
      </p>
    </ng-container>
  </div>

  <div *ngIf="form?.value?.users?.selected?.length"
       class="pt-3 pb-3 border-top">
    <h5 class="mb-2">{{ 'onboarding:selectedUsers' | i18next: { format: 'title' } }}</h5>

    <ng-container *ngIf="stepService.getStepById('selectUsers')?.hidden; else stepLink">
      {{ form?.value?.users?.selected?.[0]?.Email }}
    </ng-container>

    <ng-template #stepLink>

      <mbs-button [isCtrl]="true"
                  (click)="stepService.transition('selectUsers')">
        {{ form?.value?.users?.selected?.length }}
        {{ 'common.selected' | i18next }}
      </mbs-button>

    </ng-template>

  </div>

  <div class="pt-3 border-top">
    <p class="mb-2">{{ 'onboarding:summaryEmailDescription' | i18next }}</p>

    <div class="mt-2">
      <mbs-input [validationWhitespace]="true"
                 [formControl]="emailControl"></mbs-input>
    </div>
  </div>

  <div class="d-flex align-items-center mt-3">
    <i class="fa fa-info-circle text-lg text-primary mr-2"></i>
    <p class="font-weight-semibold mb-0">
      {{ 'onboarding:appsSummaryBackupScheduled' | i18next }}
    </p>
  </div>

</ng-container>

<ng-template #loader>
  <mbs-loader></mbs-loader>
</ng-template>
