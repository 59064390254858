import { I18NextPipe } from 'angular-i18next';
import { AgentType } from '@models/Computer';

export const getProductTilesConfig = (i18N: I18NextPipe) => ([
  {
    id: AgentType.Backup,
    title: i18N.transform('app:products.backup'),
    description: i18N.transform('computers.module:addComputerWizard.actionStep.backupDescription'),
    icon: 'mbs-agent mbs-agent-md mbs-agent_backup'
  },
  {
    id: AgentType.RMM,
    title: i18N.transform('app:products.rmm'),
    description: i18N.transform('computers.module:addComputerWizard.actionStep.rmmDescription'),
    icon: 'mbs-agent mbs-agent-md mbs-agent_rmm'
  },
  {
    id: AgentType.RA,
    title: i18N.transform('app:products.ra'),
    description: i18N.transform('computers.module:addComputerWizard.actionStep.raDescription'),
    icon: 'mbs-agent mbs-agent-md mbs-agent_connect'
  }
]);
