<div class="mbs-page">

  <div class="mbs-page_content">

    <mbs-loader *ngIf="showLoader"></mbs-loader>
    <iframe #iframe
            [src]="marketingSrc | safe: 'resourceUrl'"
            class="border-0 w-100"
            [class.d-none]="showLoader"
            sandbox="allow-downloads allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"></iframe>

  </div>
</div>
