<mbs-modal [title]="modalTitle"
           [loading]='false'>

  <ng-container *ngTemplateOutlet="mode === 'accordeonList' ? accordeon : inputList"
                modal-body>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button [type]="'primary'"
                (click)="save()">
      {{ saveButtonText }}
    </mbs-button>
    <mbs-button [type]="'secondary'"
                data-dismiss="modal"
                (click)="close()">
      {{ closeButtonText }}
    </mbs-button>
  </ng-container>

</mbs-modal>


<ng-template #accordeon>
  <div class="row">
    <div class="col-12">
      <mbs-accordion size="small"
                     [checkboxes]="true"
                     [showTopControls]="true"
                     [topControlsLabel]="true"
                     [activeIds]="'itemList'"
                     (changeTop)="handleTopChange($event)"
                     (change)="checkChanged($event)"
                     (changeHeader)="headerCheckChanged($event)"
                     [topControlsLabelSelected]="true"
                     (changeTotalSelectedCount)="handleSelectedCountAll($event)">
        <ng-template topControlsPrependLabel>
          <strong>Selected: </strong>
        </ng-template>
        <ng-template topControlsAppendLabel>
          &nbsp;{{ selectedEntities?.length === 1 ? entityNaming : entitiesNaming }}
        </ng-template>


        <mbs-accordion-panel id="itemList"
                             [rows]="entities"
                             [keepOpened]="true"
                             [shown]="true"
                             [itemsCanBeHidden]="false"
                             [title]="entitiesNaming">
          <ng-template let-item
                       rowTemplate>
            {{ entityValueToBeShownInTheList ? item[entityValueToBeShownInTheList] : item }}
          </ng-template>
        </mbs-accordion-panel>

      </mbs-accordion>
    </div>
  </div>
</ng-template>


<ng-template #inputList>
  <mbs-alert [type]="'info'"
             [icon]="true"
             class="mb-2"
             *ngIf="inputListInfo">
    <span>
      {{ inputListInfo }}
    </span>
  </mbs-alert>

  <mbs-input type="textarea"
             [label]="textAreaInputName"
             [rows]="5"
             [size]="size"
             [(ngModel)]="textInputData">
  </mbs-input>
</ng-template>
