import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TwoFactorEnableModalComponent } from '@components/tfa/components/two-factor-enable-modal/two-factor-enable-modal.component';
import { ApplicationStateFacade } from '@facades/application.facade';
import { UiStorageKey } from '@models/ui-storage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalComponent, ModalService } from 'mbs-ui-kit';
import { noop, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-force-msp-2fa',
  templateUrl: './force-msp-2fa-modal.component.html'
})
export class ForceMSP2FaModalComponent {
  public confirmationCheckbox = new FormControl(false);

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(private modalService: ModalService, private applicationStateFacade: ApplicationStateFacade) {}

  public onForceMSPAppButtonClick() {
    const modal = this.modalService.openRef(TwoFactorEnableModalComponent, {
      data: {
        title: 'Enable Two-Factor Authentication',
        isForceMSP: true
      }
    });

    modal.componentInstance.showThirdPartyAuthButton = false;

    modal.result.then(() => this.baseModal.save()).catch(noop);
  }

  public closeModal() {
    const query$ = this.confirmationCheckbox.value
      ? this.applicationStateFacade.setStorageSetting(UiStorageKey.HideMSP2FAModal, 'true')
      : of(null);

    query$
      .pipe(
        untilDestroyed(this),
        catchError(() => of(null))
      )
      .subscribe({
        next: () => {
          this.baseModal.close();
        }
      });
  }
}
