<div class="d-flex flex-column h-100">
  <div class="d-block w-100 mb-3">
    <div class="row align-items-center">
      <div class="col-7">
        <mbs-button type="primary"
                    [loading]="storageHashTable[mySelectedStorage?.ID]?.isLoading"
                    [isCtrl]="true"
                    (click)="backToStoragesClickHandler()">
          <span class="fa fa-long-arrow-left"></span>
          <span *ngIf="!storageHashTable[mySelectedStorage?.ID]?.isLoading">
          {{ 'backup-storages:browseStorage.backToStorages' | i18next : { format: 'title' } }}
        </span>
        </mbs-button>
      </div>

      <div class="col-5">
        <mbs-select [bindLabel]="'DisplayName'"
                    [(ngModel)]="mySelectedStorage"
                    (change)="mySelectedStorageChangeHandler($event)"
                    [clearable]="false"
                    [disabled]="loadingRightTableData || storageHashTable[mySelectedStorage?.ID]?.isLoading"
                    [hideSelected]="true"
                    [items]="storages"
                    [id]="'selected-storage-select'"
                    [searchable]="true"
                    [attr.data-test-element]="elementsSelector.name.selectedStorage">
          <ng-template ng-label-tmp
                       let-storage="item">
            <div class="form-row align-items-center flex-nowrap">
              <div class="col-auto d-flex align-items-center">
                <span class="mbs-storage mbs-storage-sm mbs-storage_{{ storage.StorageType | storageIcon }}"></span>
              </div>
              <div class="col text-overflow font-weight-semibold"
                   [class.text-text-dark]="!loadingRightTableData && !storageHashTable[mySelectedStorage?.ID]?.isLoading"
                   [class.text-muted]="loadingRightTableData || storageHashTable[mySelectedStorage?.ID]?.isLoading">
                {{storage.DestinationName}} <span class="text-muted">({{storage.Bucket}})</span>
              </div>
            </div>
          </ng-template>
          <ng-template ng-option-tmp
                       let-storage="item">
            <div class="form-row flex-nowrap">
              <div class="col-auto d-flex align-items-center">
                <span class="mbs-storage mbs-storage-sm mbs-storage_{{ storage.StorageType | storageIcon }}"></span>
              </div>
              <div class="col text-overflow font-weight-semibold text-black">
                <span>{{storage.DestinationName}}</span><br>
                <span class="text-muted">({{storage.Bucket}})</span>
              </div>
            </div>
          </ng-template>
        </mbs-select>
      </div>
    </div>
  </div>

  <mbs-card class="mbs-browse-storage-card h-100"
            [size]="MbsSize.xs"
            [innerBordered]="true">
    <!--LEFT TREE START-->
    <mbs-card-group class='d-flex flex-column'>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h4 class="mb-0">{{ 'backup-storages:browseStorage.computersTitle' | i18next }}</h4>
        <mbs-switcher (change)="showComputersChangeHandler()"
                      [(ngModel)]="showComputers"
                      [disabled]="!computerData?.online"
                      [label]="'backup-storages:browseStorage.showForAllComputers' | i18next"></mbs-switcher>
      </div>

      <div *ngIf="storageHashTable[mySelectedStorage?.ID]?.isLoading"
           class="d-block position-relative py-2 w-100 h-100"
           [attr.data-test-element]="elementsSelector.name.treeLoader">
        <mbs-loader size="small"></mbs-loader>
      </div>

      <div *ngIf="noBackup"
           class="d-flex align-items-center justify-content-center flex-column w-100 h-100 text-muted text-center">
        <span class="fa fa-desktop text-gray-500 no-backup-data_icon mb-2"></span>
        {{ 'backup-storages:browseStorage.noBackupData' | i18next: { computerName: computerData?.name } }}
        <mbs-button class="mt-3"
                    type="secondary"
                    (click)="loadMore(mySelectedStorage?.ID)">
          <span class="text-left">{{ 'backup-storages:browseStorage.showAllComputers' | i18next }}</span>
        </mbs-button>
      </div>

      <div *ngIf="!noBackup && !storageHashTable[mySelectedStorage?.ID]?.isLoading && !storageHashTable[mySelectedStorage?.ID]?.data?.length"
           class="text-center text-gray-500 mt-3"
           [attr.data-test-element]="elementsSelector.name.treeNoDataForTree">
        {{ 'backup-storages:browseStorage.noBackups' | i18next }}
      </div>

      <ng-scrollbar *ngIf="!noBackup && !storageHashTable[mySelectedStorage?.ID]?.isLoading && !!storageHashTable[mySelectedStorage?.ID]?.data?.length"
                    autoWidthDisabled="true"
                    autoHeightDisabled="true"
                    track="all"
                    class="w-100 h-100">
        <div class="ng-scroll-content">
          <div [attr.data-test-element]="elementsSelector.name.treeBlock"
               class="backup-storage-tree pb-3">
            <mbs-tree #subTree="mbsTree"
                      mode="text"
                      (mbsPreClick)="expandedLazyNodeHandler($event)"
                      (mbsClick)="expandedLazyNodeHandler($event)"
                      [childrenManualControl]="true"
                      [clickEmitBeforeLoad]="true"
                      [selectedItemId]="selectedTreeItem?.id"
                      [selectable]="true"
                      [itemsCanBeHidden]="true"
                      [root]="getRoot(mySelectedStorage?.ID)"
                      [disableIfLoadingSubtree]="true"
                      [data]="storageHashTable[mySelectedStorage?.ID]?.data || []"
                      [lazy]="true"
                      [getItems]="getSubtree.bind(this)"
                      [loadMore]="getSubtree.bind(this)">
              <ng-template treeElement
                           let-item>
                <div class="d-flex align-items-center justify-content-between flex-nowrap _hover-parent w-100">
                  <span class="d-flex align-items-center justify-content-center position-relative backup-storage-tree_img mr-1">
                    <img [src]="item.iconCustom"
                         class="backup-storage-tree_img-main"
                         alt="Tree main icon">
                    <img *ngIf="item.rightBottomIcon"
                         [src]="item.rightBottomIcon"
                         [attr.data-test-element]="elementsSelector.name.treeRightBottomIcon"
                         class="backup-storage-tree_img-bottom"
                         alt="Tree icon right bottom">
                    <img *ngIf="item.leftTopIcon"
                         [src]="item.leftTopIcon"
                         [attr.data-test-element]="elementsSelector.name.treeLeftTopIcon"
                         class="backup-storage-tree_img-top"
                         alt="Tree icon left top">
                  </span>

                  <span class="text-overflow text-gray-900 text-sm d-flex align-items-center h-100 px-1"
                        [class.backup-storage-tree_generation]="item.itemType === itemTypeEnum.Generation"
                        (click)="subTree.loadingSubtree && $event.stopPropagation()">
                    <ng-container *ngIf="item.deepSync === deepSyncEnum.InProgress || item.deepSync === deepSyncEnum.InProgressColdStorage; else labelData">
                      <span class="loader loader-success align-self-center mr-2"
                            [attr.data-test-element]="elementsSelector.name.treeDeepSyncProcessLoader"></span>
                      {{ 'backup-storages:browseStorage.deepSyncProgress' | i18next: { deepSyncProgress: item.deepSyncProgress } }}
                    </ng-container>

                    <ng-template #labelData>
                      <mbs-text-ellipsis [tooltip]="item.label">{{item.label}}</mbs-text-ellipsis>
                    </ng-template>
                  </span>

                  <span *ngIf="needShowSeparator(item)"
                        class="text-gray-500">│</span>
                    <span *ngIf="item?.label === computerData.name && item?.itemType === itemTypeEnum.File"
                          class="text-gray-500">
                    {{ 'backup-storages:browseStorage.noBackups' | i18next }}
                  </span>

                  <mbs-tag *ngIf="item.isLegacy"
                           [clickable]="false"
                           [closable]="false"
                           [active]="true"
                           [noBorder]="true"
                           [class]="'bg-info-20 ml-1 border-radius-sm-important text-uppercase text-dark font-weight-semibold'"
                           [size]="MbsSize.xs">
                    {{ 'backup-storages:browseStorage.legacy' | i18next }}
                  </mbs-tag>

                  <span *ngIf="item.keepBackupFor"
                        class="backup-storage-tree_keep-backup text-overflow text-gray text-sm mr-1"
                        [class.ml-1]="item.isLegacy || item.isDeleted">
                    <mbs-text-ellipsis [tooltip]="item.keepBackupFor">{{item.keepBackupFor}}</mbs-text-ellipsis>
                  </span>

                  <img *ngIf="item.GFSSettings && item.GFSSettings.type !== generationGFSType.NotAssigned"
                       [src]="generationGFSTypeIcons[item.GFSSettings.type]"
                       [attr.data-test-element]="elementsSelector.name.treeGFSImage"
                       class="backup-storage-tree_img-gfs mx-1 text-info"
                       alt="Tree icon for gfs settings type - {{ item.GFSSettings.type }}" />
                  <span *ngIf="item.purgeSummary"
                        [class.ml-1]="(item.isLegacy || item.isDeleted) && !item.keepBackupFor && (!item.GFSSettings || item.GFSSettings.type === generationGFSType.NotAssigned)"
                        class="backup-storage-tree_purge-summary text-overflow text-gray text-sm mr-1">
                    <mbs-text-ellipsis [tooltip]="item.purgeSummary">{{item.purgeSummary}}</mbs-text-ellipsis>
                  </span>
                  <mbs-tag *ngIf="item.isObjectLock"
                           [clickable]="false"
                           [closable]="false"
                           [active]="true"
                           [noBorder]="true"
                           [size]="MbsSize.xs">
                    {{ 'backup-storages:browseStorage.locked' | i18next }}
                  </mbs-tag>
                </div>
              </ng-template>

              <ng-template treeLoadMore
                           let-item>
                <ng-container *ngIf="getOtherComputers(mySelectedStorage.ID) as otherComputersData">
                  <mbs-button (click)="subTree.handleClickLoadMore(item.node?.parent, $event)"
                              [isCtrl]="true"
                              [attr.data-test-element]="elementsSelector.name.loadMoreBtn"
                              type="primary">
                  <span class="d-flex align-items-center"
                        [attr.data-test-element]="elementsSelector.name.treeLoadMoreDefault">
                    <span *ngIf="subTree.loadingSubtree"
                          class="loader mr-2"></span>
                    <span *ngIf="!subTree.loadingSubtree"
                          class="ico ico-Refresh mr-2"></span>
                    {{ 'backup-storages:browseStorage.loadMore' | i18next }}
                  </span>
                  </mbs-button>
                </ng-container>
              </ng-template>

            </mbs-tree>
          </div>
        </div>

      </ng-scrollbar>
    </mbs-card-group>
    <!--LEFT TREE END-->


    <mbs-card-group>
      <div *ngIf="!loadingRightTableData && !rightTableData"
           class="text-center text-gray-500 mt-3"
           [attr.data-test-element]="elementsSelector.name.treeNoDataForRightTable">
        {{ 'backup-storages:browseStorage.noBackups' | i18next }}
      </div>

      <div *ngIf="loadingRightTableData"
           [attr.data-test-element]="elementsSelector.name.loadingRightTableData"
           class="d-block position-relative py-2 w-100 h-100">
        <mbs-loader size="small"></mbs-loader>
      </div>

      <!--RIGHT TABLE START-->
      <div *ngIf="!loadingRightTableData && rightTableData"
           [attr.data-test-element]="elementsSelector.name.rightTable">
        <mbs-browse-storage-tables (deleteClick)="deleteSelectedClickHandler($event)"
                                   (itemDblClick)="itemDblClickHandler($event)"
                                   (restoreToLocal)="restoreToLocalClickHandler($event)"
                                   (selectedItemsChange)="selectedItemsForQRChangeHandler($event)"
                                   [computerData]="computerData"
                                   [deleteProcess]="deleteProcess"
                                   [rightTableData]="rightTableData"
                                   [selectedItemsForQR]="selectedItemsForQR"
                                   [selectedTreeItem]="selectedTreeItem"></mbs-browse-storage-tables>
      </div>
      <!--RIGHT TABLE END-->
    </mbs-card-group>
  </mbs-card>
</div>

<ng-template #bitLockerPasswordModal>
  <p>{{ 'backup-storages:bitLockerPassModal.topText' | i18next }}</p>
  <form class="mbs-form-group"
        [formGroup]="bitlockerPasswordForm">
    <mbs-radio [label]="'backup-storages:bitLockerPassModal.usePassword' | i18next"
               value="1"
               [checked]="true"
               formControlName="passwordType"
               [id]="'bitlocker-password-radio1'"
               class="mb-2"
               (change)="changeBitLockerPasswordType()"></mbs-radio>
    <mbs-input [id]="'encryptionPassword'"
               name="encryptionPassword"
               [autocomplete]="'off'"
               [type]="encryptionPasswordType ? 'text' : 'password'"
               formControlName="password"
               (buttonClick)="encryptionPasswordType = !encryptionPasswordType"
               [appendButtons]="[
                 {
                   icon: encryptionPasswordType ? 'fa fa-eye-slash' : 'fa fa-eye',
                   id: 'changeTypeEncryptionPassword',
                   disabled: false,
                   type: 'secondary'
                 }
               ]">
    </mbs-input>
    <mbs-radio [label]="'backup-storages:bitLockerPassModal.useRecoveryPassword' | i18next"
               value="2"
               formControlName="passwordType"
               [id]="'bitlocker-password-radio2'"
               class="mt-2 mb-2"
               (change)="changeBitLockerPasswordType()"></mbs-radio>
    <mbs-input [id]="'recoveryPassword'"
               name="recoveryPassword"
               [autocomplete]="'off'"
               [type]="recoveryPasswordInputType ? 'text' : 'password'"
               formControlName="recoveryPassword"
               (buttonClick)="recoveryPasswordInputType = !recoveryPasswordInputType"
               [appendButtons]="[
                   {
                     icon: recoveryPasswordInputType ? 'fa fa-eye-slash' : 'fa fa-eye',
                     id: 'changeTypeRecoveryPassword',
                     disabled: false,
                     type: 'secondary'
                   }
                 ]">
    </mbs-input>
    <mbs-alert *ngIf="!isBitLockedFileSupported"
               [type]="AlertType.warning"
               [icon]="true"
               class="mt-4 mb-1">
      {{ this.bitlockerFileNotAvailable }}
    </mbs-alert>
    <mbs-radio [label]="'backup-storages:bitLockerPassModal.useKeyFile' | i18next"
               value="3"
               formControlName="passwordType"
               [id]="'bitlocker-password-radio3'"
               class="mt-2 mb-2"
               [disabled]="!isBitLockedFileSupported"
               (change)="changeBitLockerPasswordType()"></mbs-radio>
    <mbs-input [type]="'file'"
               [clearButton]="true"
               [placeholder]="bitLockerFileName ? bitLockerFileName : 'Choose file'"
               [filePlaceholderOverflow]="true"
               [trim]="false"
               [multiple]="false"
               formControlName="keyFile"
               [disabled]="!isBitLockedFileSupported"
               (change)="onChangeBLPassFile($event)"></mbs-input>
  </form>
</ng-template>

<ng-template #deleteConfirmTemplate>
  <mbs-alert [type]="AlertType.error"
             [icon]="true"
             class="mb-0">
    {{
      'backup-storages:browseStorage.deleteItemAlertText' | i18next: {
        level: textForDeleteMap[treeItemForActions?.itemType] || ''
      }
    }}
    <span class="font-weight-semibold">“{{treeItemForActions?.label}}”</span>?
    {{ 'backup-storages:browseStorage.impossibleRestoreAfterDelete' | i18next }}
  </mbs-alert>
</ng-template>
