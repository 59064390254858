import * as moment from 'moment';
import { merge, partition } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, map } from 'rxjs/operators';

export function distinctUntilDateChanged(sensitivity?: 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year') {
  return source$ => {
    const [moment$, any$] = partition(source$, (date: any) => {
      return moment(date).isValid();
    });

    const date$ = moment$.pipe(
      map((date: any) => {
        const _moment: moment.Moment = moment(date);
        const buffer = sensitivity
          ? moment(_moment)
              .startOf(sensitivity)
              .toISOString()
          : _moment.toISOString();

        return { date, buffer };
      }),
      distinctUntilKeyChanged('buffer'),
      map(({ date }) => date)
    );

    return merge(date$, any$.pipe(distinctUntilChanged()));
  };
}
