import { SortEvent } from 'mbs-ui-kit';
import { ScriptLibraryEntryTypes, ScriptLibraryTransfer } from './script-library.model';

export const FeatureKey = 'ScriptLibraries';
export const FeatureKeyPrefix = '[Script Libraries]';
export const NEW_SCRIPT_HID = '[New Script]';
export const DEFAULT_SORT: SortEvent = {
  direction: 'asc',
  column: 'name'
};
export const DEFAULT_TRANSFER_OBJECT: Partial<ScriptLibraryTransfer> = {
  type: ScriptLibraryEntryTypes.PowerShell,
  enableOffline: true,
  confidentialOutputData: true
};
