<mbs-wizard [title]="'wizards:raGSW.title' | i18next: {format: 'title'}"
            [showSteps]="false"
            [stepsOrientation]="'vertical'"
            [saveButton]="{ hide: true }"
            [canCloseWithoutSave]="true"
            [height]="'560px'"
            [cancelButton]="{
              text: 'buttons:close' | i18next
            }"
            [backButton]="{ hide: true }"
            [nextButton]="{ hide: true }"
            #wizard>
  <mbs-wizard-step [title]="steps.download"
                   [valid]="true">
    <h4>{{ 'wizards:raGSW.header' | i18next }}</h4>

    <mbs-alert [type]="alertType.info"
               class="mb-4"
               [icon]="true">
      {{'computers.module:downloadStep.rdAlert' | i18next }}
    </mbs-alert>

    <mbs-download-connect></mbs-download-connect>
  </mbs-wizard-step>
</mbs-wizard>
