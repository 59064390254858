<h4 *ngIf="enableTitle">{{ 'rmm.module:groupActions.stepConfigureAction.installWingetTitle' | i18next: { format: 'title'
  } }}</h4>


<mbs-alert *ngIf="!showTable || (showTable && !showError) "
           [type]="'warning'">
  {{ 'rmm.module:groupActions.stepConfigureAction.wingetMinVersionInfo' | i18next }}
</mbs-alert>

<div *ngIf="!showTable">
  <form [formGroup]="wingetForm">
    <div class="row">
      <div class="col-12">
        <mbs-input formControlName="value"
                   type="textarea"
                   placeholder="{{ 'rmm.module:groupActions.stepConfigureAction.wingetInputPlaceholder' | i18next }}"
                   [rows]="5">

        </mbs-input>

        <div *ngIf="showError"
             class="text-danger mt-1">
          {{ 'rmm.module:groupActions.stepConfigureAction.softwareValidationError' | i18next }}
        </div>
      </div>
    </div>

  </form>

  <mbs-button class="mt-3"
              [type]="'primary'"
              (click)="searchSoftwareUsingWinget()"
              [disabled]="disableValidateSoftwareBtn">
    {{ 'rmm.module:groupActions.stepConfigureAction.validateBtn' | i18next }}
  </mbs-button>
</div>

<div *ngIf="showTable">

  <mbs-alert *ngIf="showError"
             [type]="'danger'"
             [icon]="true">
    {{ 'rmm.module:groupActions.stepConfigureAction.softwareValidationError' | i18next }}
  </mbs-alert>

  <div class="row d-flex align-items-center justify-content-between mb-3">
    <form [formGroup]="wingetForm"
          class="col-10">
      <mbs-input formControlName="filter"
                 [placeholder]="'app:placeholders.search' | i18next"></mbs-input>
    </form>
    <div class="col-2">
      <mbs-button (click)="switchToList()"
                  icon="fa fa-pencil">
        {{ 'rmm.module:groupActions.stepConfigureAction.editBtn' | i18next }}
      </mbs-button>
    </div>
  </div>



  <mbs-table-grid [loading]="isLoading"
                  [headers]="headers"
                  [data]="dataToShow"
                  (sort)="handleSort($event)"
                  (changeSelected)="selected($event)"
                  [showTableCheckboxes]="true"
                  [multipleSelect]="true"
                  [bindDisabledValues]="bindDisabledValues"
                  [keepState]="true"
                  [selectedItems]="selectedItems"
                  [changeSortState]="orderBy"
                  [maxHeight]="'32vh'"
                  [noDataMessage]="'rmm.module:groupActions:noTableDataMessage' | i18next: { format: 'capitalize' }">


    <ng-template mbsTableCell
                 *ngFor="let header of headers"
                 let-item>

      <ng-container [ngSwitch]="header.sort">
        <ng-container *ngSwitchCase="'customTooltip'">

          <span *ngIf="item?.found"
                class="fa fa-info-circle text-lg text-info"
                placement="top"
                tooltipClass="tooltip-lg"
                container="body"
                [ngbTooltip]="toastInfoTemplate">
          </span>

          <ng-template #toastInfoTemplate>
            <div class="text-left">
              <div class="mb-1">
                {{ 'rmm.module:groupActions.stepConfigureAction.wingetToastInfoTitles.searchText' | i18next }} {{
                item.searchText }}
              </div>
              <div class="mb-1">
                {{ 'rmm.module:groupActions.stepConfigureAction.wingetToastInfoTitles.id' | i18next }} {{ item.id }}
              </div>
              <div class="mb-1">
                {{ 'rmm.module:groupActions.stepConfigureAction.wingetToastInfoTitles.publisher' | i18next }} {{
                item.publisher }}
              </div>
            </div>
          </ng-template>
        </ng-container>


        <ng-container *ngSwitchCase="'version'">
          <span *ngIf="item.found; else notFound">
            {{ item.version }}
          </span>

          <ng-template #notFound>
            <span class="text-danger">
              {{ 'rmm.module:groupActions.stepConfigureAction.softwareNotFound' | i18next }}
            </span>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'name'">

          <mbs-text-ellipsis placement="top-left"
                             container="body"
                             tooltipClass="tooltip-lg"
                             [tooltip]="item.name ?? item.searchText">
            {{ item.name ?? item.searchText }}
          </mbs-text-ellipsis>
        </ng-container>



        <ng-container *ngSwitchDefault>
          <mbs-text-ellipsis placement="top-left"
                             container="body"
                             tooltipClass="tooltip-lg"
                             [tooltip]="item[header.sort]">
            {{ item[header.sort] }}
          </mbs-text-ellipsis>
        </ng-container>

      </ng-container>

    </ng-template>
  </mbs-table-grid>
</div>
