<h4>{{ 'rmm.module:groupActions.stepConfigureAction.scriptLibraryTitle' | i18next: { format: 'title' } }}</h4>


<div class="row mb-3">
  <div class="col-12">
    <div class="row button-group text-center m-0 mb-1">
      <button class="col btn btn-secondary d-flex powershell-tab-group-button-rounded-left-button justify-content-center align-items-center"
              [class.active]="modeSelected(modeSelectType.fromScriptLibrary)"
              (click)="setModeSelect(modeSelectType.fromScriptLibrary)">
        {{ 'rmm.module:groupActions.stepConfigureAction.scriptFromLibraryLabel' | i18next }}
      </button>
      <button class="col btn btn-secondary d-flex rounded-0 justify-content-center align-items-center"
              [class.active]="modeSelected(modeSelectType.fromScript)"
              (click)="setModeSelect(modeSelectType.fromScript)">
        {{ 'rmm.module:groupActions.stepConfigureAction.scriptCode' | i18next }}
      </button>
      <button class="col btn btn-secondary d-flex powershell-tab-group-button-rounded-right-button justify-content-center align-items-center"
              [class.active]="modeSelected(modeSelectType.fromFile)"
              (click)="setModeSelect(modeSelectType.fromFile)">
        {{ 'rmm.module:groupActions.stepConfigureAction.scriptFromFileLabel' | i18next }}
      </button>
    </div>
  </div>
</div>

<ng-container [ngSwitch]="executeScriptFormType.get('modeSelect').value">
  <mbs-action-script-library *ngSwitchCase="modeSelectType.fromScriptLibrary"
                             [modeSelection]="executeScriptFormType.get('modeSelect').value"
                             [enableTitle]="false">
  </mbs-action-script-library>

  <mbs-terminal-action *ngSwitchDefault
                       [enableTitle]="false"
                       [modeSelection]="executeScriptFormType.get('modeSelect').value"
                       (setModeSelect)="setModeSelect($event)">
  </mbs-terminal-action>
</ng-container>
