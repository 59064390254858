<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>Temporary Instance</h4>
  </header>
  <div class="mbs-form-group">
    <mbs-radio formControlName="useTemporary"
               name="useTemporary"
               label="Use Temporary Instance"
               (change)="useTemporaryChangeHandler($event)"
               [descriptionClasses]="'mt-1'"
               [descriptionOutside]="false"
               [id]="'use-temporary-radio-0'"
               [value]="true">
      <ng-template radioDescription>
        <span class="text-sm">Files will be copied faster through temporary virtual machine. Extra fee will be charged depending on instance type and restore running time.</span>
      </ng-template>
    </mbs-radio>
    <mbs-radio formControlName="useTemporary"
               name="useTemporary"
               label="Do Not Use Temporary Instance"
               (change)="useTemporaryChangeHandler($event)"
               [descriptionClasses]="'mt-1'"
               [descriptionOutside]="false"
               [id]="'use-temporary-radio-1'"
               [value]="false">
      <ng-template radioDescription>
        <span class="text-sm">Files will be copied through local computer. Usually slower, depends on local Internet connection.</span>
      </ng-template>
    </mbs-radio>
  </div>



  <ng-container *ngIf="stepForm.get('useTemporary').value">
    <h5>Temporary EC2 Instance Account</h5>
    <div class="mbs-form-group">
      <mbs-select formControlName="account"
                  [appendTo]="'body'"
                  [bindValue]="'value'"
                  [bindLabel]="'label'"
                  [label]="'Select Account'"
                  [clearable]="false"
                  [id]="'temporary-account-select'"
                  [items]="temporaryData.accounts"></mbs-select>
      <mbs-alert class="mb-0 mt-3"
                 [type]="mbsPopupType.info"
                 [icon]="true">
        We strongly recommend you to use our <a href="/AP/PolicyDesigner.aspx" target="_blank">policy designer</a> to tune required permissions for the specified account.
      </mbs-alert>
    </div>
    <h5 class="mt-3">Temporary EC2 Instance Details</h5>
    <div class="mbs-form-group">
      <div class="row">
        <div class="col-4">
          <mbs-select formControlName="region"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Region'"
                      [clearable]="false"
                      [id]="'temporary-region-select'"
                      [items]="temporaryData.regions"></mbs-select>
        </div>
        <div class="col-4">
          <mbs-select formControlName="availabilityZones"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Availability Zones'"
                      [clearable]="false"
                      [id]="'temporary-availability-zones-select'"
                      [items]="temporaryData.zones"></mbs-select>
        </div>
        <div class="col-4">
          <mbs-select formControlName="instanceType"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Instance Type'"
                      [clearable]="false"
                      [id]="'temporary-instance-type-select'"
                      [items]="temporaryData.types"></mbs-select>
        </div>
      </div>
    </div>
    <div class="mbs-form-group">
      <div class="row">
        <div class="col-4">
          <mbs-select formControlName="subnet"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Subnet'"
                      [clearable]="false"
                      [id]="'temporary-subnet-select'"
                      [items]="temporaryData.subnetItems"></mbs-select>
        </div>
        <div class="col-4">
          <mbs-select formControlName="securityGroup"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Security Group'"
                      [clearable]="false"
                      [id]="'temporary-security-group-select'"
                      [items]="temporaryData.securityGroups"></mbs-select>
        </div>
        <div class="col-4">
          <mbs-select formControlName="ami"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'AMI'"
                      [clearable]="false"
                      [id]="'temporary-ami-select'"
                      [items]="temporaryData.amiItems"></mbs-select>
        </div>
      </div>
    </div>
  </ng-container>
</section>
