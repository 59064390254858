import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OnlineAccessSettings } from '@models/onlineAccessSettings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigurationService } from '@services/configuration.service';
import { OnlineAccessService } from '@services/onlineAccess';
import { unidentifiedErrorText } from '@shared/interceptors/error-handler.interceptor';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import {
  DataChangeWatcherService,
  FormsUtil,
  InputButton,
  MbsSize,
  MbsValidators,
  SidepanelCrudBase,
  SidepanelService,
  ToastService
} from 'mbs-ui-kit';
import { SidepanelInfoEvent } from 'mbs-ui-kit/sidepanel/sidepanel.service';
import { ClipboardService } from 'ngx-clipboard';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, finalize, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-sidepanel-online-access',
  templateUrl: './sidepanel-online-access.component.html',
  styleUrls: ['./sidepanel-online-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidepanelOnlineAccessComponent extends SidepanelCrudBase<any> {
  public readonly sidePanelId = 'online-access-panel';
  public readonly mbsSize = MbsSize;
  public loadingSave = false;

  public form = this.formBuilder.group({
    enableOnlineAccess: [false],
    optionsForm: this.formBuilder.group({
      enableBackup: false,
      enableConnect: false,
      alias: ['', [Validators.required, MbsValidators.aliasTextValidator.bind(this)]],
      legacyURL: [''],
      commonURL: ['']
    })
  });

  public get enableOnlineAccess(): FormControl<boolean> {
    return this.form.get('enableOnlineAccess') as FormControl;
  }

  public get optionsForm(): FormGroup {
    return this.form.get('optionsForm') as FormGroup;
  }

  public get enableBackup(): FormControl<boolean> {
    return this.optionsForm.get('enableBackup') as FormControl;
  }

  public get enableConnect(): FormControl<boolean> {
    return this.optionsForm.get('enableConnect') as FormControl;
  }

  public get alias(): FormControl<string> {
    return this.optionsForm.get('alias') as FormControl;
  }

  public get legacyURL(): FormControl<string> {
    return this.optionsForm.get('legacyURL') as FormControl;
  }

  public get commonURL(): FormControl<string> {
    return this.optionsForm.get('commonURL') as FormControl;
  }

  public get isConnectAvailable(): boolean {
    return this.ability.can('read', 'ConnectInOnlineAccess2');
  }

  public get betaLabel(): string {
    return this.i18next.transform('app:beta').toUpperCase();
  }

  private baseAppendButtons: InputButton[] = [
    {
      text: '',
      id: 'pasteURL',
      type: 'secondary',
      icon: 'fa fa-level-up',
      loading: false
    },
    {
      text: '',
      id: 'copyURL',
      type: 'secondary',
      icon: 'fa fa-files-o',
      loading: false
    }
  ];

  public appendButtonsLegacy = this.baseAppendButtons.map((button) => ({ ...button, id: `${button.id}_legacy` }));
  public appendButtonsCommon = this.baseAppendButtons.map((button) => ({ ...button, id: `${button.id}_common` }));

  private legacyURLPrefix = this.config.get('onlineAccessLegacyHref') ?? '';
  private commonURLPrefix = this.config.get('onlineAccessHref') ?? '';

  constructor(
    private ability: AbilityService,
    private i18next: I18NextPipe,
    dcws: DataChangeWatcherService,
    private sidepanelService: SidepanelService,
    private cdr: ChangeDetectorRef,
    private service: OnlineAccessService,
    private toastService: ToastService,
    private router: Router,
    private clipboardService: ClipboardService,
    private config: ConfigurationService,
    private formBuilder: FormBuilder
  ) {
    super(dcws);
    this.sidepanelService.onOpen
      .pipe(
        debounceTime(100),
        filter((data: SidepanelInfoEvent) => data?.id === this.sidePanelId && !this.loadingData),
        untilDestroyed(this)
      )
      .subscribe(() => this.updateData());
    this.enableOnlineAccess.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.optionsForm.enable();
      } else {
        this.enableBackup.setValue(false);
        this.isConnectAvailable && this.enableConnect.setValue(false);
        this.optionsForm.disable();
      }
      this.cdr.markForCheck();
    });
  }

  public updateData(): void {
    this.resetForm();
    this.switchLoading(true);
    this.service
      .getSettings()
      .pipe(finalize(() => this.switchLoading(false)))
      .subscribe((data: OnlineAccessSettings) => {
        this.enableOnlineAccess.setValue(data.enableOnlineAccess);
        this.alias.setValue(data.alias);
        this.alias.markAsDirty();
        this.enableBackup.setValue(data.enableOnlineAccessBackupData);
        this.isConnectAvailable && this.enableConnect.setValue(data.enableOnlineAccessConnect);
        this.handleAliasChange();
      });
  }

  switchLoading(state: boolean): void {
    this.loadingData = state;
    this.loadingSave = state;
    this.cdr.markForCheck();
  }

  isValidSidepanel(): boolean {
    return !this.enableOnlineAccess.value || this.form.valid;
  }

  // Save all sidepanel data
  handleSave(): Observable<boolean> {
    if (!this.isValidSidepanel()) {
      FormsUtil.triggerValidation(this.alias);
      return of(false);
    }
    this.loadingSave = true;
    this.cdr.markForCheck();
    // save
    this.service
      .setSettings({
        enableOnlineAccess: this.enableOnlineAccess.value,
        enableOnlineAccessBackupData: this.enableBackup.value,
        enableOnlineAccessConnect: this.isConnectAvailable && this.enableConnect.value,
        alias: this.alias.value
      })
      .pipe(
        tap(() => {
          this.toastService.success(this.i18next.transform('app:notifications.successfullySaved'));
          this.genericPanel.close();
        }),
        finalize(() => {
          this.loadingSave = false;
        })
      )
      .subscribe();
    return of(false);
  }

  // Reset sidepanel data
  handleClose(): Observable<boolean> {
    return super.handleClose().pipe(
      tap((result) => {
        if (result) {
          this.resetForm();
        }
      })
    );
  }

  handleDelete(): Observable<boolean> {
    return of(false);
  }

  handleAliasChange(): void {
    const alias = this.alias.value;
    this.legacyURL.setValue(`${this.legacyURLPrefix}${alias}/`);
    this.commonURL.setValue(`${this.commonURLPrefix}${alias}/`);
    this.cdr.markForCheck();
  }

  resetForm(): void {
    this.form.reset();
  }

  showErrorToast(err: HttpErrorResponse): void {
    const message = typeof err === 'string' ? err : err?.message || err?.error?.message || err?.error?.title || unidentifiedErrorText;
    this.toastService.error(message);
  }

  handleBindingRedirect(): void {
    this.handleClose().subscribe();
    this.router.navigate(['/AP/SSL']);
  }

  handleURLButton(data: InputButton): void {
    const isPasteURLMode = data?.id.startsWith('pasteURL') ?? false;
    const isCopyURLMode = data?.id.startsWith('copyURL') ?? false;
    const isLegacyMode = data?.id.endsWith('_legacy') ?? false;
    const isCommonMode = data?.id.endsWith('_common') ?? false;

    if ((!isPasteURLMode && !isCopyURLMode) || (!isLegacyMode && !isCommonMode)) return;

    const url = isLegacyMode ? this.legacyURL.value : this.commonURL.value;

    if (isPasteURLMode) {
      window.open(url, '_blank');
    }
    this.clipboardService.copyFromContent(url);
  }
}
