import { Injectable } from '@angular/core';
import { OfflineEditVersionModalComponent } from '@domains/computers/modals/computer-based/offline-edit-version-modal/offline-edit-version-modal.component';
import { PlanInfo } from '@models/backup/plan-info-model';
import Computer, { AgentType } from '@models/Computer';
import { ComputerBasedModal, ComputerPlanBasedModal } from '@models/ComputersModals';
import { NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MbsSize, ModalService } from 'mbs-ui-kit';
import { catchError, from, map, noop, of } from 'rxjs';
import { ComputersBulkInstallAgentConfirmationModalComponent } from '../components/computers-modal/computers-bulk-install-agent-confirmation-modal/computers-bulk-install-agent-confirmation-modal.component';
import { ComputerI18nFacade } from './computer.i18.facade';

@Injectable({
  providedIn: 'root'
})
export class ComputerModalsFacade {
  constructor(protected modalService: ModalService, protected i18n: ComputerI18nFacade) {}

  public openComputerBasedModal(modalComponent, computer?: Computer, freezeBody = false) {
    const modal = this.modalService.openRef(modalComponent, { freezeBody });

    (modal.componentInstance as ComputerBasedModal).computer = computer;
    modal.result.catch(noop);

    return modal;
  }

  public openPlanBasedModal(modalComponent, computer?: Computer, plan?: PlanInfo): NgbModalRef {
    const modal = this.modalService.openRef(modalComponent);

    (modal.componentInstance as ComputerPlanBasedModal).computer = computer;
    (modal.componentInstance as ComputerPlanBasedModal).plan = plan;
    modal.result.catch(noop);

    return modal;
  }

  public openInstallationStartedModal(agent, computer?: Computer) {
    const body = [
      this.i18n.getModalText('installationOf'),
      this.i18n.getAgentName(agent),
      computer ? this.i18n.getModalTextbyParam('startedOn', Computer.getComputerName(computer)) : this.i18n.getModalText('started')
    ].join('');

    return this.openInfoModal(body, this.getInfoModalFooter(this.i18n.getAppButtonsTitle('close'), null));
  }

  public openInfoModal(body: string, footer) {
    return from(this.modalService.open({ header: { title: this.i18n.getComputersModalTitle('header') }, footer }, body)).pipe(
      catchError(() => of(false))
    );
  }

  protected getInfoModalFooter(okButtonCaption = 'Ok', cancelButtonCaption = 'Cancel') {
    return { okButton: { text: okButtonCaption }, cancelButton: { show: !!cancelButtonCaption, text: cancelButtonCaption } };
  }

  public openNoPublicBuildsModal(showCloseButtonOnly = false) {
    return this.openInfoModal(
      this.i18n.getModalText('noPublicBuilds'),
      this.getInfoModalFooter(this.i18n.getAppButtonsTitle('close'), showCloseButtonOnly ? null : this.i18n.getComputersTitle('downloads'))
    ).pipe(
      map((res) => !!res),
      catchError(() => of(false))
    );
  }

  public openSQLPlanModal() {
    const body = `${this.i18n.getModalText('sqlPlanTextFirstRow')}<br />${this.i18n.getModalText('sqlPlanTextLastRow')}`;
    return from(
      this.modalService.open(
        {
          header: { title: this.i18n.getComputersModalTitle('sqlPlanCaption') },
          footer: this.getInfoModalFooter('Open')
        },
        body
      )
    );
  }

  public openEC2PlanModal() {
    const body = `${this.i18n.getModalText('EC2PlanTextFirstRow')}<br />${this.i18n.getModalText('sqlPlanTextLastRow')}`;
    return from(
      this.modalService.open(
        {
          header: { title: this.i18n.getComputersModalTitle('EC2PlanCaption') },
          footer: this.getInfoModalFooter('Open')
        },
        body
      )
    );
  }

  public openInstallAgentModal(agent: AgentType.Backup | AgentType.RMM, computer: Computer) {
    const header = { title: this.i18n.getModalText(agent === AgentType.Backup ? 'installBackupCaption' : 'installRMMCaption') };
    const body = `<div class="row"><div class="col-auto">${this.i18n.getModalTextbyParam(
      agent === AgentType.Backup ? 'installBackupQuestion' : 'installRMMQuestion',
      Computer.getComputerName(computer)
    )}</div></div>`;
    const footer = this.getInfoModalFooter('Install');
    return from(this.modalService.open({ header, footer }, body));
  }

  public openBulkInstallAgentModal(agent: AgentType) {
    if (agent === AgentType.DeepInst) {
      const header = { title: this.i18n.getModalText('installBulkDeepInstCaption') };
      const body = `<div class="row"><div class="col-auto">
      ${this.i18n.getModalText('installBulkDeepInstBody')}
      </div></div>
      <div class="row"><div class="col-auto">
      <ul class="mt-3"><li>
      ${this.i18n.getModalText('installBulkDeepInstOption1')}
      </li><li>
      ${this.i18n.getModalText('installBulkDeepInstOption2')}
      </li><li>
      ${this.i18n.getModalText('installBulkDeepInstOption3')}
      </li><li>
      ${this.i18n.getModalText('installBulkDeepInstOption4')}
      </li></ul>
      </div></div>
      <div class="row"><div class="col-auto">
      <b>${this.i18n.getModalText('install')}</b>${this.i18n.getModalText('installBulkDeepInstQuestion')}
      </div></div>
      `;
      const footer = this.getInfoModalFooter('Install');
      return from(this.modalService.open({ header, size: MbsSize.sm, footer }, body));
    } else {
      const modal = this.modalService.openRef(ComputersBulkInstallAgentConfirmationModalComponent);
      const modalInstance = modal.componentInstance as ComputersBulkInstallAgentConfirmationModalComponent;

      modalInstance.agent = agent;

      return from(modal.result);
    }
  }

  public openSimpleModal(modalComponent) {
    const modal = this.modalService.openRef(modalComponent);
    modal.result.catch(noop);
    return modal;
  }

  public openWizard(content, settings?: NgbModalOptions) {
    return this.modalService.openWizard(content, settings).catch(noop);
  }

  public openOfflineEditLowVersionModal(): void {
    this.modalService.openRef(OfflineEditVersionModalComponent)?.result?.then(noop).catch(noop);
  }
}
