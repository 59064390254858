import { I18NextPipe } from 'angular-i18next';
import { InstallationMode } from '@components/add-computer/add-computer.constants';

export const getTilesConfig = (i18N: I18NextPipe) => ([
  {
    id: InstallationMode.Manual,
    title: i18N.transform('computers.module:addComputerWizard.downloadStep.manualInstallation'),
    icon: 'fa fa-desktop',
    isBeta: false
  },
  {
    id: InstallationMode.Bulk,
    title: i18N.transform('computers.module:addComputerWizard.downloadStep.bulkInstallation'),
    icon: 'fa fa-sitemap',
    isBeta: true
  }
])
