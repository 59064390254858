import { Component, Input, OnInit } from '@angular/core';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { OsType } from '@models/Computer';
import HotfixInfo from '@models/rmm/HotfixInfo';
import RmmLastStatData from '@models/rmm/RmmLastStatData';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmmService } from '@services/rmm.service';
import { baseDateFileNameFormatHoursAndMinutes, mediumDateWithoutTimeMoment, mediumDateWithTime } from '@utils/date';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { SharedPersistentStateEnum, TableHeader, ToastService } from 'mbs-ui-kit';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import { RmmTabBaseComponent } from '../rmm-tab-base.component';

export enum HeaderAvailability {
  Windows = OsType.windows,
  Linux = OsType.linux,
  Mac = OsType.apple
}

type TableHeaderExtend = TableHeader & {
  isFullHeader?: boolean;
  availableFor: HeaderAvailability[];
};

@UntilDestroy()
@Component({
  selector: 'mbs-hotfixes-installed-tab',
  templateUrl: './hotfixes-installed-tab.component.html'
})
export class HotfixesInstalledTabComponent extends RmmTabBaseComponent<HotfixInfo> implements OnInit {
  @Input() computerName: string;
  public readonly mediumDateWithTime = mediumDateWithTime;
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;
  public translationKey = 'rmm-side-panel:hotfixesInstalledTab.';
  public fullHeaders: TableHeaderExtend[] = [
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.name'),
      overflow: false,
      sort: 'kb',
      gridColSize: '30fr',
      gridColMin: '80px',
      availableFor: [HeaderAvailability.Linux]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.hotfix'),
      overflow: true,
      sort: 'kb',
      gridColSize: '20fr',
      availableFor: [HeaderAvailability.Windows]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.severity'),
      overflow: true,
      sort: 'severity',
      gridColSize: '20fr',
      availableFor: [HeaderAvailability.Windows]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.title'),
      overflow: true,
      sort: 'title',
      gridColSize: '30fr',
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Mac]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.description'),
      overflow: true,
      sort: 'description',
      gridColSize: '20fr',
      isFullHeader: true,
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Mac, HeaderAvailability.Linux]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.version'),
      overflow: true,
      sort: 'updateID',
      gridColSize: '30fr',
      gridColMin: '80px',
      availableFor: [HeaderAvailability.Linux]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.installationDate'),
      overflow: true,
      sort: 'installedOn',
      sortType: 'date',
      gridColSize: '10fr',
      gridColMin: '120px',
      class: '-end',
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Mac, HeaderAvailability.Linux]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.category'),
      overflow: true,
      sort: 'categories',
      gridColSize: '20fr',
      isFullHeader: true,
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Mac, HeaderAvailability.Linux]
    }
  ];

  public shortHeaders: TableHeaderExtend[] = this.fullHeaders.filter((h) => !h.isFullHeader);
  public isMacOS: boolean;
  public isLinuxOS: boolean;
  public installationDateTimeFormat = mediumDateWithoutTimeMoment;

  constructor(
    public rmmService: RmmService, // prettier
    private toastService: ToastService,
    private computersFacade: ComputersFacade,
    private i18n: I18NextPipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (computer && computer.os) {
        this.isMacOS = computer.os === OsType.apple;
        this.isLinuxOS = computer.os === OsType.linux;
      }
      this.headers = this.isModal
        ? this.getTableHeadersWithOSDependency(this.fullHeaders)
        : this.getTableHeadersWithOSDependency(this.shortHeaders);
    });

    this.searchFields = ['kb', 'title', 'description', 'size', 'severity', 'type', 'updateID'];
    this.fetchData();
  }

  private getTableHeadersWithOSDependency(tableHeaders: TableHeaderExtend[]): TableHeaderExtend[] {
    switch (true) {
      case this.isLinuxOS:
        return tableHeaders.filter((column) => column.availableFor.includes(HeaderAvailability.Linux));
      case this.isMacOS:
        return tableHeaders.filter((column) => column.availableFor.includes(HeaderAvailability.Mac));
      default:
        return tableHeaders.filter((column) => column.availableFor.includes(HeaderAvailability.Windows));
    }
  }

  getInstalledUpdatesFromStore(): void {
    this.loading = true;
    this.rmmService
      .fetchLastData<RmmLastStatData<HotfixInfo>>('update')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.loading = false))
      )
      .subscribe((stat) => {
        if (stat) {
          const hotfixes = stat.data.filter((update) => update.isInstalled === 'True');
          this.allData = cloneDeep(hotfixes);
          this.allData.forEach((element) => {
            element.kb = element.kb ? element.kb : '';
          });
          this.updateData(this.allData);
        }
      });
  }

  fetchData(): void {
    this.getInstalledUpdatesFromStore();
  }

  trackBy(index: number, item: ExtendedTableRow): string {
    return (item?.item as HotfixInfo)?.updateID;
  }

  public getDateInFormat(data: string | Date) {
    return data ? moment(data).format(this.installationDateTimeFormat) : '';
  }

  public getTableName(): string {
    return `${this.computerName} ${this.i18n.transform('rmm-side-panel:sidePanelTabsName.installedUpdates')} ${moment().format(
      baseDateFileNameFormatHoursAndMinutes
    )}`;
  }

  public getTooltip(value: any): string {
    if (value && Array.isArray(value) && value.length) return value[0];
    return value;
  }
}
