import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18NEXT_NAMESPACE, I18NextModule } from 'angular-i18next';

import {CodeEditorModule} from '../code-editor/code-editor.module';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { FeatureKey } from './store/const';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScriptLibraryBodyComponent } from './components/script-library-body/script-library-body.component';
import { ScriptLibraryDeleteModalComponent } from './components/script-library-delete-modal/script-library-delete-modal.component';
import { ScriptLibraryEditorComponent } from './components/script-library-editor/script-library-editor.component';
import { ScriptLibraryEntryComponent } from './components/script-library-entry/script-library-entry.component';
import { ScriptLibraryManageComponent } from './components/script-library-manage/script-library-manage.component';
import { ScriptLibraryRoutingModule } from './script-library.routing.module';
import { ScriptLibrarySearchTemplates } from './script-library-search-templates.service';
import { ScriptLibraryService } from './script-library.service';
import { StoreModule } from '@ngrx/store';
import { ScriptLibraryEffects as effect } from './store/script-library.effects';
import { reducer } from './store/script-library.reducer';
import { CategoryListEditorComponent } from './components/category-list-editor/category-list-editor.component';

const components = [
  ScriptLibraryManageComponent, // Main Page with a Table
  ScriptLibraryEntryComponent, // General Tab in SidePanel
  ScriptLibraryBodyComponent, // Script Body Tab in SidePanel
  ScriptLibraryEditorComponent, // Edit Script Modal Window
  ScriptLibraryDeleteModalComponent,
  CategoryListEditorComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    ScriptLibraryRoutingModule,
    I18NextModule,
    MbsUiKitModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    CommonModule,
    StoreModule.forFeature(FeatureKey, reducer),
    EffectsModule.forFeature([effect]),
    CodeEditorModule
  ],
  exports: [...components],
  providers: [
    ScriptLibraryService,
    ScriptLibrarySearchTemplates,
    {
      provide: I18NEXT_NAMESPACE,
      useValue: ['rmm-script-library.module']
    }
  ]
})
export class ScriptLibraryModule {}
