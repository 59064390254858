<span class="font-weight-semibold">
  {{ 'tfa:mspQRCode:enableTwoFactorAuth' | i18next }}
</span>
<ol class="pl-4">
  <li class="mt-2">
    {{ 'tfa:downloadApplication' | i18next }}
    <mbs-install-msp-app-badges></mbs-install-msp-app-badges>
  </li>
  <li class="mt-2">
    {{ 'tfa:mspQRCode:openApplication' | i18next }}
  </li>
  <li class="mt-2">
    <!-- TODO: Find out how to change the text style in a translation -->
    Go to application settings to scan the QR code above to try the enhanced 2FA process with MSP360
    application. Confirm enabling
    2FA by selecting <b>Confirm</b> in the received push notification. Refresh the Management Console page, if
    it is not updated
    automatically.
  </li>
</ol>
