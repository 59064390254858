import { freezeMap } from '@utils/maps';
type EnumType = { [key: string]: string | number };
type EnumAsArrayType = {
  key: string;
  value: string | number;
}[];
export class EnumConverter<T> {
  public readonly keyValue: Map<any, any>;
  public readonly valueKey: Map<any, any>;
  public readonly size: number;

  constructor(raw) {
    this.valueKey = freezeMap(this.prepare(raw).reduce((acc, item) => {
      acc.set(item.value, item.key)
      return acc;
    }, new Map));

    this.keyValue = freezeMap(this.prepare(raw).reduce((acc, item) => {
      acc.set(item.key, item.value)
      return acc;
    }, new Map));
  }

  private prepare = (data: EnumType): EnumAsArrayType => Object.keys(data)
      .filter((key) => Number.isNaN(+key))
      .map((key: string) => ({
        key,
        value: data[key] as any,
      }));

  public valueToKey(value: T, strict = false): string | number {
    if (!this.valueKey.has(`${value}` as any) && strict) {
      throw new Error('Invalid value');
    }

    return this.valueKey.get(`${value}` as any);
  }

  public keyToValue(key: string | number, strict = false): T {
    if (!this.keyValue.has(key) && strict) {
      throw new Error('Invalid key');
    }

    return this.keyValue.get(key);
  }

  public toObjectArray(exclude: string[] = []): { key: string; value: T }[] {
    let keys = Array.from(this.keyValue.keys());

    if (exclude.length) {
      keys = keys.filter((key) => !exclude.includes(key))
    }

    return keys.reduce((acc: { key: string; value: T }[], key) => {
      acc.push({ key, value: this.keyValue.get(key) });
      return acc;
    }, []);
  }

  public toEntryArray(exclude: string[] = []): [string, T][] {
    let keys = Array.from(this.keyValue.keys());

    if (exclude.length) {
      keys = keys.filter((key) => !exclude.includes(key))
    }

    return keys.reduce((acc: [string, T][], key) => {
      acc.push([key, this.keyValue.get(key)]);
      return acc;
    }, []);
  }
}
