import { StorageType } from '@models/StorageType.enum';
import { StorageAccountState } from '@models/storage-accounts/StorageAccountState';

export enum UnifiedBillingAccountType {
  Undefined = 'Undefined',
  Trial = 'Trial',
  TrialReadOnly = 'TrialReadOnly',
  TrialManuallyDeactivated = 'TrialManuallyDeactivated',
  TrialNotPaid = 'TrialNotPaid',
  TrialDeactivated = 'TrialDeactivated',
  Paid = 'Paid',
  /**
   * full access yet
   * can restore
   */
  PaidWithDebt = 'PaidWithDebt',
  PaidWithDebtReadOnly = 'PaidWithDebtReadOnly',
  PaidManuallyDeactivated = 'PaidManuallyDeactivated',
  PaidWithDebtManuallyDeactivated = 'PaidWithDebtManuallyDeactivated',
  PaidWithDebtNotPaid = 'PaidWithDebtNotPaid',
  PaidWithDebtDeactivated = 'PaidWithDebtDeactivated'
}

export class UnifiedBillingAccount {
  id: string;
  storageType: StorageType;
  displayName: string;
  createDate: string;
  paidModeUsageDate: string;
  paymentDate: string;
  usageType: UnifiedBillingAccountType;
  /** @deprecated */
  usageTypeEndDate: string;
  usageTypeDaysCount: number;
  currentUsedVolume: number;

  storageTypeDisplayName: string;

  /**
   * Date of receipt of the first unpaid invoice
   */
  ubInvoiceDate: string;

  ubTrialLimitSize: number;

  state: StorageAccountState;

  usageTypeByLifeCycle: UnifiedBillingAccountType;

  daysFromStartCurrentState: number | null;
}
