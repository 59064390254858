import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Placement, PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

export type modalText = { title?: string; text?: string; textInvalid?: string; save?: string; back?: string; cancel?: string };

/**
 * A component that provides a sidebar
 */
@Component({
  selector: 'app-sidepanel,mbs-sidepanel',
  templateUrl: './sidepanel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidepanelComponent implements OnInit, OnDestroy, OnChanges {
  private expandedClass = '-fullscreen';
  private showPanel = false;

  public isExpanded = false;

  /**
   * Custom text for the modal
   */
  @Input() modalText: modalText = null;
  /**
   * A uniq id in the page scope
   */
  @Input() id: string;
  /**
   * Current panel visibility state
   * @param {boolean} state
   */
  @Input() set show(state: boolean) {
    this.showPanel = state;
  }

  get show(): boolean {
    return this.showPanel;
  }

  /**
   * Which one side a panel appears
   * * Available values: `left | right`.
   * * default - `right`
   */
  @Input() side: string;

  /**
   * Toggles the `-tabs` class that includes special styles for `ngb-tabset`
   * * use only if there is a `ngb-tabset` inside of `sidepanel`
   * * default - `false`
   */

  @Input() tabs: boolean;
  /**
   * Toggles the `large mode`.
   * * If `true` panel becomes almost twice wider than usual.
   * * default - `false`
   */
  @Input() large: boolean;
  @Input() headerClass: string;

  @Input() innerContentType: 'form' | 'inner' | 'tabs' | 'nowrap' = 'form';
  @Input() showFooter = true;
  @Input() disabledSave: boolean;
  @Input() saveButtonTooltip: string;
  @Input() saveButtonTooltipPosition: string | Placement | PlacementArray = 'top';
  @Input() disabledDelete: boolean;
  @Input() hideSave: boolean;
  @Input() showDelete = true;
  @Input() isCreate = true;

  @Input('footerTemplate') set footerTemplateInput(value: TemplateRef<any>) {
    this.footerTemplate = value;
  }
  @Input() actionNames: Partial<{ delete: string; save: string; close: string }> = {};

  /**
   * Save button loading
   */
  @Input() loading = false;

  @Input() loadingData = false;

  @Input() loadingDelete = false;
  @Input() showExpandedCross = false;

  @Output() panelClosed = new EventEmitter();

  @Output('delete') deleteEvent = new EventEmitter();
  @Output('save') saveEvent = new EventEmitter();
  @Output() panelOpened = new EventEmitter();
  @Output() expandedChange = new EventEmitter<boolean>();

  @ViewChild('sidePanel', { static: false }) sidePanel: ElementRef<HTMLElement>;

  @ContentChild('footerTemplate', { static: true }) footerTemplate: TemplateRef<any>;

  constructor(private cd: ChangeDetectorRef, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (!this.id) {
      throw Error('modal must have an id');
    }
  }

  ngOnChanges(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    [this.panelClosed, this.deleteEvent, this.saveEvent].forEach((event) => event.unsubscribe());
  }

  public open(): void {
    this.showPanel = true;
    this.applyExpandedStyle();
    this.panelOpened.emit();
    this.cd.detectChanges();
  }

  public close(): void {
    this.showPanel = false;
    this.applyExpandedStyle();
    this.cd.detectChanges();
  }

  public sidebarSideClass(): { [key: string]: boolean } {
    return {
      '-open': this.showPanel,
      '-left': this.side && this.side === 'left',
      '-right': (this.side && this.side === 'right') || !this.side,
      '-tabs': this.tabs,
      '-lg': this.large
    };
  }

  public toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.applyExpandedStyle();
    this.expandedChange.emit(this.isExpanded);
  }

  @HostListener('window:keydown.esc', ['$event'])
  public handleClose(): void {
    this.applyExpandedStyle();
    this.panelClosed.emit();
  }

  private applyExpandedStyle(): void {
    const expandedOverflow = this.showPanel && this.isExpanded ? 'overflow-y: hidden' : 'overflow-y: auto';

    this.renderer.setAttribute(this.document.body, 'style', expandedOverflow);

    if (this.isExpanded) {
      this.renderer.addClass(this.sidePanel.nativeElement, this.expandedClass);
    } else {
      this.renderer.removeClass(this.sidePanel.nativeElement, this.expandedClass);
    }
  }
}
