import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import Computer from '@models/Computer';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import * as SummaryComputerSelectors from '@modules/summary-computers/store/summary-computer.selectors';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RemoteCommandType } from '@shared/models/rm/remote-command-model';
import { ComputersAbstractService, TFARMMPermissionHelperService } from '@shared/services';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, ModalService, ModalSettings, ToastService } from 'mbs-ui-kit';
import { filter, from, map, noop, Observable, of, switchMap, take, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-wake-on-lan',
  templateUrl: './wake-on-lan.component.html'
})
export class WakeOnLanComponent implements OnChanges {
  @Input() hid: string;
  @Input() computer: Computer;
  @Input() isDropdownItem: boolean;
  @Input() showText = true;
  @Input() isLoading = false;
  @Input() btnClass = '';
  @Input() iconClass = '';
  @Input() container: string;

  @ViewChild('confirmationModalContent', { static: true, read: TemplateRef }) confirmContent: TemplateRef<any>;
  @ViewChild('warningModalContent', { static: true, read: TemplateRef }) warningContent: TemplateRef<any>;

  public readonly AlertType = MbsPopupType;
  public icon = 'fa fa-power-off ';

  public modalTitle = this.i18nPipe.transform('rmm-side-panel:wakeOnLan.btnName');
  public computerName: string;
  private allowRemoteManagement = false;

  constructor(
    private toastService: ToastService,
    private commandService: CommandService,
    private modalService: ModalService,
    private i18nPipe: I18NextPipe,
    private store: Store,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService,
    private computerService: ComputersAbstractService,
    private cdr: ChangeDetectorRef
  ) {}

  getAllowRemoteManagementStream(): Observable<boolean> {
    if (this.isDropdownItem || this.hid) {
      return this.computerService
        .getComputerAgentOptions(this.hid)
        .pipe(map((agentOptions) => (agentOptions?.rmmValues ? !agentOptions.rmmValues.readOnly : false)));
    } else {
      return this.store.select(SummaryComputerSelectors.selectCurrentComputer).pipe(
        filter((computer) => !!computer),
        tap((computer) => {
          this.hid = computer.hid;
          this.computerName = Computer.getComputerName(computer);
        }),
        switchMap((computer) => this.computerService.getComputerAgentOptions(computer.hid)),
        map((agentOptions) => (agentOptions?.rmmValues ? !agentOptions.rmmValues.readOnly : false))
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.computer?.currentValue) {
      this.hid = this.computer.hid;
      if (this.hid && this.isDropdownItem) this.allowRemoteManagement = false;
      this.computerName = Computer.getComputerName(this.computer);
    }
  }

  private openWarningModal() {
    const settings: ModalSettings = {
      header: { title: this.i18nPipe.transform('rmm-side-panel:wakeOnLan.failedModalTitle'), icon: '', showExpandedCross: false },
      footer: { okButton: { show: false }, cancelButton: { text: this.i18nPipe.transform('buttons:close') } },
      freezeBody: true
    };

    this.modalService.open(settings, this.warningContent).then(noop).finally(noop);
  }

  public openConfirmModal() {
    let stream$ = this.openConfirmModalStream();

    if (!this.allowRemoteManagement) {
      stream$ = this.getAllowRemoteManagementStream().pipe(
        switchMap((allowed) => {
          return allowed ? this.openConfirmModalStream() : of(false);
        })
      );
    }

    stream$.pipe(take(1)).subscribe({
      next: (isOk) => {
        isOk
          ? this.toastService.success(this.i18nPipe.transform('rmm-side-panel:toast-messages:wakeOnLan', { format: 'title' }))
          : this.toastService.error(this.i18nPipe.transform('rmm-side-panel:toast-messages:remoteManagementDisabled'));
      },
      error: (err) => {
        if (err !== false) this.openWarningModal();
      }
    });
  }

  private openConfirmModalStream() {
    return of(true).pipe(
      switchMap(() =>
        from(this.modalService.open(this.getWakeOnLanModalSettings(), this.confirmContent)).pipe(
          switchMap(() =>
            this.tfaRMMPermissionHelper.is2FAPassedStream([this.hid], this.container ? this.container : null).pipe(
              filter((confirmed) => confirmed),
              switchMap((confirmed) => this.commandService.sendAgentCommand(RemoteCommandType.WakeOnLan, this.hid))
            )
          ),
          map((value) => value)
        )
      )
    );
  }

  getWakeOnLanModalSettings(): ModalSettings {
    return {
      header: { title: this.modalTitle, icon: '', showExpandedCross: false },
      footer: { okButton: { text: this.modalTitle } }
    };
  }

  public redirectOnHelp(): void {
    const url = 'https://learn.microsoft.com/en-us/mem/configmgr/core/clients/deploy/configure-wake-on-lan';
    window.open(url, '_blank');
    this.modalService.dismissAll();
  }
}
