import { Component, Input } from '@angular/core';
import { getTilesConfig } from '@components/add-computer/steps/read-manual/read-manual.constants';
import { I18NextPipe } from 'angular-i18next';
import { WizardForm } from '@components/add-computer/add-computer.types';
import { InstallationMode } from '@components/add-computer/add-computer.constants';

@Component({
  selector: 'add-computer-wizard-read-manual-step',
  templateUrl: './read-manual.component.html',
  styleUrls: ['../../add-computer.component.scss']
})
export class ReadManualComponent {
  public readonly tilesConfig = getTilesConfig(this.i18N);
  public readonly installationMode = InstallationMode;

  @Input() wizardForm: WizardForm;

  constructor(private i18N: I18NextPipe){}

  public getText(step: string, part: 'title' | 'description'): string {
    return this.i18N.transform(`computers.module:addComputerWizard.readManualStep.${this.wizardForm.value.mode}.${step}.${part}`);
  }
}
