import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompaniesFacade } from '@facades/companies.facade';
import Company from '@models/Company';
import HostInfo from '@models/rmm/HostInfo';
import HotfixAvailableListInfo from '@models/rmm/HotfixAvailableListInfo';
import SoftwareInfo from '@models/rmm/SoftwareInfo';
import { RmmService } from '@services';
import SmartSearchTemplatesBase from '@services/smart-search-templates-base';
import { I18NextPipe } from 'angular-i18next';
import { flatMapDeep } from 'lodash';
import { getClearTerm, getWildcardTerm, ModelTemplate, SmartSearchState } from 'mbs-ui-kit';
import { asapScheduler, Observable, scheduled } from 'rxjs';
import { map, tap } from 'rxjs/operators';

enum fields {
  software = 'ext.field.software.name',
  computer = 'field.computerName',
  patch = 'ext.field.update.kb',
  os = 'field.productType',
  company = 'mbs.companyid'
}

export const I18_KEY = 'rmm.module:groupActions.';

const i18keys = {
  software: `${I18_KEY}smart-search-tags.software`,
  computer: `${I18_KEY}smart-search-tags.computer`,
  patch: `${I18_KEY}smart-search-tags.patch`,
  os: `${I18_KEY}smart-search-tags.os`,
  company: `${I18_KEY}smart-search-tags.company`
};

@Injectable()
export class ComputerSmartSearchService extends SmartSearchTemplatesBase {
  private companies: Company[];

  constructor(private rmmService: RmmService, private companiesFacade: CompaniesFacade, private i18n: I18NextPipe) {
    super();
    this.companiesFacade.loadData();
  }

  public software: ModelTemplate<string> = {
    itemFormatter: (value: string) => {
      return value.split(/\s/).length > 1 ? `{${value}}` : value;
    },
    tag: this.i18n.transform(i18keys.software),
    items: (state: SmartSearchState): Observable<string[]> => {
      const key = 'software-name_';
      const term = state.leftCaretValue;

      const query: any = {
        online: 'true',
        size: '10',
        groupby: 'name'
      };
      if (term) {
        query[fields.software] = getWildcardTerm(term);
      }

      const params = new HttpParams({ fromObject: query });

      return scheduled(
        this.rmmService.fetchLastStatAll<SoftwareInfo>('software', params).pipe(
          map((data) => {
            if (!data.aggregations) {
              return [];
            }

            const softwareNames = data.aggregations[0].keys as string[];

            const clearTerm = getClearTerm(term).toLocaleLowerCase();

            const softwares = clearTerm ? softwareNames.filter((name) => name.toLocaleLowerCase().includes(clearTerm)) : softwareNames;
            return softwares.slice(0, 10);
          }),
          tap(() => this.writeToCache(key + term))
        ),
        asapScheduler
      );
    },
    isMultiple: true
  };

  public computer: ModelTemplate<HostInfo> = {
    itemFormatter: (value: HostInfo) => value.computerName,
    tag: this.i18n.transform(i18keys.computer),
    items: (state: SmartSearchState): Observable<HostInfo[]> => {
      const key = 'computer_';
      const term = state.leftCaretValue;

      const query: any = {
        online: 'true',
        size: '10'
      };
      if (term) {
        query[fields.computer] = getWildcardTerm(term);
      }
      const params = new HttpParams({ fromObject: query });

      return this.rmmService.fetchLastStatAll<HostInfo>('host', params).pipe(
        map((data) => {
          return flatMapDeep(data.data.map((pc) => pc.data));
        })
      );
    },
    isMultiple: true
  };

  public patch: ModelTemplate<string> = {
    itemFormatter: (value: string) => value,
    tag: this.i18n.transform(i18keys.patch),
    items: (state: SmartSearchState): Observable<string[]> => {
      const key = 'patch_';
      const term = state.leftCaretValue;

      const query: any = {
        online: 'true',
        size: '10',
        groupby: 'kb'
      };
      if (term) {
        query[fields.patch] = getWildcardTerm(term);
      }

      const params = new HttpParams({ fromObject: query });

      return scheduled(
        this.rmmService.fetchLastStatAll<HotfixAvailableListInfo>('update', params).pipe(
          map((data) => {
            if (!data.aggregations) {
              return [];
            }
            const clearTerm = getClearTerm(term);

            let patches = data.aggregations[0].keys as string[];
            patches = clearTerm ? patches.filter((name) => name.includes(clearTerm)) : patches;

            return patches.slice(0, 10);
          })
        ),
        asapScheduler
      );
    },
    isMultiple: true
  };

  public serverHashtag: ModelTemplate<string> = {
    itemFormatter: (value: string) => value,
    tag: this.i18n.transform(i18keys.os),
    items: (state: SmartSearchState): Observable<string[]> => {
      const term = state.leftCaretValue;

      const query: any = {
        online: 'true',
        size: '10',
        groupby: 'productType'
      };
      if (term) {
        query[fields.os] = getWildcardTerm(term);
      }

      const params = new HttpParams({ fromObject: query });

      return this.rmmService.fetchLastStatAll<string>('host', params).pipe(
        map((data) => {
          if (!data.aggregations) {
            return [];
          }

          const clearTerm = getClearTerm(term);

          let osTypes = data.aggregations[0].keys as string[];
          osTypes = clearTerm ? osTypes.filter((name) => name.includes(clearTerm)) : osTypes;

          return osTypes.slice(0, 10);
        })
      );
    },
    isMultiple: true
  };

  public company: ModelTemplate<Company> = {
    itemFormatter: (company: Company) => `{${company.name}}`,
    tag: this.i18n.transform(i18keys.company),
    items: (state: SmartSearchState): Observable<Company[]> => {
      const key = 'company_';
      const term = state.leftCaretValue;

      const query: any = {
        online: 'true',
        size: '10'
      };
      if (term) {
        query[fields.company] = getWildcardTerm(term);
      }

      return scheduled(
        this.companiesFacade.getData$().pipe(
          map((data: Company[]) => {
            if (!data.length) {
              return [];
            }

            // let companies = data
            const clearTerm = getClearTerm(term);

            const companies = clearTerm ? data.filter((company) => company.name.includes(clearTerm)) : data;
            this.companies = companies;

            return companies.slice(0, 10);
          })
        ),
        asapScheduler
      );
    },
    isMultiple: true
  };

  getHttpParams(search): HttpParams {
    const params = new URLSearchParams();
    if (!search) {
      return new HttpParams({ fromString: params.toString() });
    }

    const walkTags = (key: string, tagValues: { condition: string; value: string }[]): void => {
      const tagParams = tagValues.map((tag) => (tag.condition || '') + tag.value).join('~,');
      params.append(key, tagParams);
    };

    if (Array.isArray(search[this.i18n.transform(i18keys.software)])) {
      walkTags(fields.software, search[this.i18n.transform(i18keys.software)]);
    }

    if (Array.isArray(search[this.i18n.transform(i18keys.patch)])) {
      walkTags(fields.patch, search[this.i18n.transform(i18keys.patch)]);
    }

    if (Array.isArray(search[this.i18n.transform(i18keys.computer)])) {
      walkTags(fields.computer, search[this.i18n.transform(i18keys.computer)]);
    }

    if (Array.isArray(search[this.i18n.transform(i18keys.os)])) {
      walkTags(fields.os, search[this.i18n.transform(i18keys.os)]);
    }

    if (Array.isArray(search[this.i18n.transform(i18keys.company)])) {
      const companies = search[this.i18n.transform(i18keys.company)];

      const hids = companies
        .map((company: { value: string }) => {
          const name = company.value.replace(/{/g, '');
          const fromCache = this.companies.find((cached) => cached.name == name);

          return { value: fromCache && fromCache.id };
        })
        .filter(({ value }) => Boolean(value));

      walkTags(fields.company, hids);
    }

    if (Array.isArray(search.words)) {
      walkTags(
        fields.computer,
        search.words.map((it) => ({
          value: `*${it.value}*`
        }))
      );
    }

    return new HttpParams({ fromString: params.toString() });
  }
}
