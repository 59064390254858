import { createReducer, on } from '@ngrx/store';
import * as GroupTasksActions from './actions';
import { GroupTasksDataResponse, GroupTasksStore } from './model';

const groupTasksEmptyObject: GroupTasksDataResponse = {
  data: [],
  registeredTask: null,
  taskGuidToEdit: 'null',
  dataCount: 0,
  pageCount: 0,
  pageNumber: 1,
  pageSize: 10
};

export const initialState: Readonly<GroupTasksStore> = {
  groupTasksDataResponse: groupTasksEmptyObject,
  loading: false,
  error: ''
};

export const reducer = createReducer(
  initialState,
  on(GroupTasksActions.loadGroupTasks, (state) => ({ ...state, loading: true })),
  on(GroupTasksActions.loadGroupTasksSuccess, (state, action) => ({
    ...state,
    groupTasksDataResponse: { ...action.data },
    loading: false
  })),
  on(GroupTasksActions.loadGroupTasksError, (state, action) => ({ ...state, loading: false, error: action.error })),
  on(GroupTasksActions.clearGroupTasks, (state) => {
    return {
      ...initialState,
      groupTasksDataResponse: {
        ...initialState.groupTasksDataResponse,
        taskGuidToEdit: state.groupTasksDataResponse.taskGuidToEdit
      }
    };
  }),
  on(GroupTasksActions.registerGroupTask, (state) => ({ ...state, loading: true })),
  on(GroupTasksActions.forceRunGroupTaskForPC, (state) => ({ ...state, loading: true })),
  on(GroupTasksActions.updateGroupTask, (state, action) => {
    const groupTasks = [...state.groupTasksDataResponse.data];
    groupTasks[groupTasks.findIndex((groupTask) => groupTask.groupTaskGuid === action.groupTask.groupTaskGuid)] = action.groupTask;

    return {
      ...state,
      groupTasksDataResponse: {
        ...state.groupTasksDataResponse,
        data: [...groupTasks]
      }
    };
  }),
  on(GroupTasksActions.deleteGroupTask, (state) => ({ ...state, loading: true })),
  on(GroupTasksActions.setGroupTaskGuidToEdit, (state, action) => {
    const groupTasksDataResponse = { ...state.groupTasksDataResponse } ?? groupTasksEmptyObject;
    groupTasksDataResponse.taskGuidToEdit = action.groupTaskGuid;
    return { ...state, groupTasksDataResponse };
  }),
  on(GroupTasksActions.clearGroupTaskGuidToEdit, (state) => {
    return {
      ...state,
      groupTasksDataResponse: {
        ...Object.assign({}, state.groupTasksDataResponse ?? groupTasksEmptyObject),
        taskGuidToEdit: null
      }
    };
  }),
  on(GroupTasksActions.clearRegisteredGroupTaskData, (state) => {
    return {
      ...state,
      groupTasksDataResponse: {
        ...Object.assign({}, state.groupTasksDataResponse ?? groupTasksEmptyObject),
        taskGuidToEdit: null,
        registeredTask: null
      }
    };
  })
);
