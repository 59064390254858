import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackupAgentUpdateRequiredModalComponent } from '@components/computers-modal/backup-agent-update-required-modal/backup-agent-update-required-modal.component';
import { BackupUpdateRequiredModalComponent } from '@components/computers-modal/backup-update-required-modal/backup-update-required-modal.component';
import { RemoteConnectionAllowModalComponent } from '@components/computers-modal/remote-connection-allow-modal/remote-connection-allow-modal.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { ComputersAuthRequiredModalComponent } from './computers-auth-required-modal/computers-auth-required-modal.component';
import { ComputersBulkInstallAgentConfirmationModalComponent } from './computers-bulk-install-agent-confirmation-modal/computers-bulk-install-agent-confirmation-modal.component';
import { ComputersForceUpdateModalComponent } from './computers-force-update-modal/computers-force-update-modal.component';
import { ComputersSendLogsModalComponent } from './computers-send-logs-modal/computers-send-logs-modal.component';
import { ComputersUninstallAgentModalComponent } from './computers-uninstall-agent-modal/computers-uninstall-agent-modal.component';
import { GrantLicenseForComputerModalComponent } from './grant-license-for-computer-modal/grant-license-for-computer-modal.component';
import { RemoteConnectionInstallUpdateModalComponent } from './remote-connection-install-update-modal/remote-connection-install-update-modal.component';
import { RemoteConnectionNotAvailableModalComponent } from './remote-connection-not-available-modal/remote-connection-not-available-modal.component';
import { RemoteConnectionSuperAdminModalComponent } from './remote-connection-super-admin-modal/remote-connection-super-admin-modal.component';

const declarations = [
  ComputersSendLogsModalComponent,
  ComputersUninstallAgentModalComponent,
  ComputersForceUpdateModalComponent,
  RemoteConnectionAllowModalComponent,
  RemoteConnectionInstallUpdateModalComponent,
  BackupUpdateRequiredModalComponent,
  BackupAgentUpdateRequiredModalComponent,
  RemoteConnectionNotAvailableModalComponent,
  RemoteConnectionSuperAdminModalComponent,
  GrantLicenseForComputerModalComponent,
  ComputersBulkInstallAgentConfirmationModalComponent,
  ComputersAuthRequiredModalComponent
];

@NgModule({
  declarations: declarations,
  exports: declarations,
  imports: [CommonModule, I18NextModule, FormsModule, ReactiveFormsModule, MbsUiKitModule, AbilityModule, NgbTooltipModule]
})
export class ComputersModalModule {}
