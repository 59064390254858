import { Injectable } from '@angular/core';
import { getGeneralHostInfo, HostInfoRow } from '@components/computers-shared/get-general-host-Info';
import { AvailablePlanTypes, KindPlanEnum } from '@models/backup/available-plan-types';
import Computer, { OsType } from '@models/Computer';
import { LicenseType } from '@models/LicenseType.enum';
import { PlanFormatTypes, PlanTypes } from '@models/PlanTypes.enum';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ComputersAspxAbstractService } from '@services/computers-legacy/computers-legacy.service';
import { ComputersAbstractService } from '@services/computers.service';
import { RmCommandsAbstractWrapper } from '@services/rm-commands.wrapper';
import { ComputersStoreSelectors } from '@store/computers';
import { AbilityService } from 'ability';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import * as ComputersBackupActions from './actions';
import * as ComputersBackupSelectors from './selectors';

interface ServiceResult<T> {
  result: boolean;
  errorMessage: string;
  data: T[];
}

@Injectable()
export class ComputersBackupEffects {
  loadGeneralInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersBackupActions.loadGeneralInfo),
      concatLatestFrom(() => [
        this.store.select(ComputersBackupSelectors.selectSelected),
        this.store.select(ComputersBackupSelectors.selectCurrentHostInfoLoaded)
      ]),
      filter(([action, hid, loaded]) => !!hid && (!loaded || action.force)),
      map(([, hid]) => hid),
      mergeMap((hid) => this.getHostOnlineInfo(hid).pipe(map((data) => ({ data, hid })))),
      map(({ data, hid }) =>
        data.result
          ? ComputersBackupActions.loadGeneralInfoSuccess({ hid: hid, data: data.data })
          : ComputersBackupActions.loadGeneralInfoError({ hid: hid })
      )
    );
  });

  loadDestination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersBackupActions.loadDestinations),
      concatLatestFrom(() => this.store.select(ComputersBackupSelectors.selectSelected)),
      map(([action, hid]) => ({ ...action, hid: action?.hid || hid })),
      filter((params) => !!params.hid),
      concatLatestFrom((params) => this.store.select(ComputersBackupSelectors.selectDestinationsByHidLoaded(params.hid))),
      filter(([params, loaded]) => !loaded || params.force),
      mergeMap(([params]) =>
        this.computersService.getDestinationsByComputerHid(params.hid).pipe(
          map((data) => {
            return ComputersBackupActions.loadDestinationsSuccess({
              hid: params.hid,
              data: data || []
            });
          }),
          catchError(() => {
            return of(ComputersBackupActions.loadDestinationsError({ hid: params.hid }));
          })
        )
      )
    );
  });

  loadPlans$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersBackupActions.loadPlans),
      concatLatestFrom(() => this.store.select(ComputersBackupSelectors.selectSelected)),
      map(([action, hid]) => ({ ...action, hid: action?.hid || hid })),
      filter((params) => !!params.hid),
      concatLatestFrom((action) => this.store.select(ComputersBackupSelectors.selectPlansByHidLoaded(action.hid))),
      filter(([action, loaded]) => !loaded || action.force),
      mergeMap(([params]) =>
        this.rmCommands.getComputerPlans(params.hid).pipe(
          map((data) =>
            ComputersBackupActions.loadPlansSuccess({
              hid: params.hid,
              data: data?.planInfos || [],
              timeZoneOffset: data?.timeZoneOffset || 0
            })
          ),
          catchError(() => of(ComputersBackupActions.loadPlansError({ hid: params.hid })))
        )
      )
    );
  });

  loadAvailablePlans$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputersBackupActions.loadAvailablePlans),
      concatLatestFrom(() => this.store.select(ComputersBackupSelectors.selectSelected)),
      map(([action, hid]) => ({ ...action, hid: action?.hid || hid })),
      filter((params) => !!params.hid),
      concatLatestFrom((action) => [
        this.store.select(ComputersBackupSelectors.selectAvailablePlansByHidLoaded(action.hid)),
        this.store.select(ComputersStoreSelectors.selectSelectedComputer)
      ]),
      filter(([action, loaded, computer]) => (!loaded || action.force) && !!computer?.hid),
      mergeMap(([, , computer]) =>
        this.getAvailablePlans(computer).pipe(map((data) => ComputersBackupActions.loadAvailablePlansSuccess({ hid: computer.hid, data })))
      )
    );
  });

  constructor(
    private ability: AbilityService,
    private store: Store,
    private actions$: Actions,
    private computersLegacyService: ComputersAspxAbstractService,
    private rmCommands: RmCommandsAbstractWrapper,
    private computersService: ComputersAbstractService
  ) {}

  private getHostOnlineInfo(hid: string): Observable<ServiceResult<HostInfoRow>> {
    return of({ hid, defaultResult: { result: false, data: [] as HostInfoRow[] } as ServiceResult<HostInfoRow> }).pipe(
      mergeMap(({ hid, defaultResult }) =>
        hid
          ? this.computersLegacyService.getComputerHostInfoLegacy(hid).pipe(
              map((data) =>
                data && !data.message
                  ? ({
                      result: true,
                      data: getGeneralHostInfo(data, true).filter((r) => (r.visible ? r.visible() : true))
                    } as ServiceResult<HostInfoRow>)
                  : Object.assign({}, defaultResult, { errorMessage: data?.message })
              )
            )
          : of(defaultResult)
      ),
      catchError((error) => of({ result: false, data: [] as HostInfoRow[], errorMessage: error?.message } as ServiceResult<HostInfoRow>))
    );
  }

  private getAvailablePlans(computer: Computer): Observable<AvailablePlanTypes> {
    return computer?.hid && computer?.online && computer?.os === OsType.windows
      ? this.rmCommands.getAvailablePlanTypes(computer.hid).pipe(
          map((plans) => this.filterRestoreSystemState(plans)),
          map((plans) => this.filterCBFIfNotCanLegacy(plans)),
          catchError(() => this.getCalculatedAvailablePlanTypes(computer))
        )
      : this.getCalculatedAvailablePlanTypes(computer);
  }

  private filterRestoreSystemState(plans: AvailablePlanTypes): AvailablePlanTypes {
    return this.ability.can('read', 'EnableSystemState') ? plans : plans.filter((p) => p.planType !== PlanTypes.RestoreSystemState);
  }

  private filterCBFIfNotCanLegacy(plans: AvailablePlanTypes): AvailablePlanTypes {
    return this.ability.can('read', 'ShowLegacyPlans')
      ? plans
      : plans.filter(
          (p) => p.format !== PlanFormatTypes.CBF || (p.planType !== PlanTypes.Plan && p.planType !== PlanTypes.BackupDiskImagePlan)
        );
  }

  // For old agent versions where getAvailablePlanTypes method is not implemented
  private getCalculatedAvailablePlanTypes(computer: Computer): Observable<AvailablePlanTypes> {
    const isWindows = computer.os === OsType.windows;
    const canLegacy = this.ability.can('read', 'ShowLegacyPlans');
    const isSQL = Computer.isUltimateOrSQLServer(computer);
    const types: AvailablePlanTypes = [];

    if (isWindows && computer?.backupLicense?.licenseType === LicenseType.VirtualMachine) {
      types.push(
        { kind: KindPlanEnum.Backup, planType: PlanTypes.BackupVMware, format: PlanFormatTypes.NBF },
        { kind: KindPlanEnum.Backup, planType: PlanTypes.BackupHyperV, format: PlanFormatTypes.NBF }
      );
    }
    types.push({ kind: KindPlanEnum.Backup, planType: PlanTypes.Plan, format: PlanFormatTypes.NBF });
    if (canLegacy) types.push({ kind: KindPlanEnum.Backup, planType: PlanTypes.Plan, format: PlanFormatTypes.CBF });
    if (isSQL && computer.online) types.push({ kind: KindPlanEnum.Backup, planType: PlanTypes.DataBasePlan });
    if (isWindows) {
      types.push({ kind: KindPlanEnum.Backup, planType: PlanTypes.BackupDiskImagePlan, format: PlanFormatTypes.NBF });
      if (canLegacy) types.push({ kind: KindPlanEnum.Backup, planType: PlanTypes.BackupDiskImagePlan, format: PlanFormatTypes.CBF });
      if (computer.online) types.push({ kind: KindPlanEnum.ConsistencyCheck, planType: PlanTypes.ConsistencyCheck });
    }

    types.push({ kind: KindPlanEnum.Restore, planType: PlanTypes.RestorePlan, format: PlanFormatTypes.NBF, needShow: true });
    if (isWindows) {
      types.push({ kind: KindPlanEnum.Restore, planType: PlanTypes.RestoreDiskImagePlan, format: PlanFormatTypes.NBF });
    }
    if (!isWindows && canLegacy)
      types.push({ kind: KindPlanEnum.Restore, planType: PlanTypes.RestorePlan, format: PlanFormatTypes.CBF, needShow: true });

    if (isWindows && computer?.backupLicense?.licenseType === LicenseType.VirtualMachine) {
      types.push(
        { kind: KindPlanEnum.Restore, planType: PlanTypes.RestoreHyperV, format: PlanFormatTypes.NBF },
        { kind: KindPlanEnum.Restore, planType: PlanTypes.RestoreVMware, format: PlanFormatTypes.NBF }
      );
    }

    if (isSQL) {
      types.push(
        { kind: KindPlanEnum.Restore, planType: PlanTypes.RestoreDataBasePlan },
        { kind: KindPlanEnum.Restore, planType: PlanTypes.RestoreDatabaseFilesPlan }
      );
    }

    return of(types);
  }
}
