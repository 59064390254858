import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { DevicesService } from '@services/devices.service';
import { I18NextService } from 'angular-i18next';
import { MbsSize, ModalComponent, ModalService } from 'mbs-ui-kit';
import { noop, take } from 'rxjs';
import { AddDeviceModalComponent } from '../add-device-modal/add-device-modal.component';

@Component({
  selector: 'mbs-enable-push-modal',
  templateUrl: './enable-push-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnablePushModalComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  loading = false;

  constructor(
    public authService: AuthService,
    private modal: ModalService,
    private devicesService: DevicesService,
    private i18: I18NextService
  ) {}

  register() {
    this.loading = true;

    this.modal
      .openCustom(AddDeviceModalComponent, {
        data: {
          title: this.i18.t('tfa:enablePush'),
          device: this.baseModal.data.device
        },
        size: MbsSize.md
      })
      .then((success) => {
        this.enablePush();
      })
      .catch(noop);
  }

  enablePush() {
    this.devicesService
      .enablePush(this.baseModal.data.device.id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.loading = false;
          this.baseModal.save();
        },
        (error) => {
          this.baseModal.close();
        }
      );
  }
}
