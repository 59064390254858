import { Inject, Injectable } from '@angular/core';
import { StepCondition } from 'mbs-ui-kit/stepper/stepper.constants';
import { BehaviorSubject } from 'rxjs';
import { Direction, StepType } from './steps.types';

@Injectable()
export class StepsService<StepId> {
  public parentSteps = new BehaviorSubject<{ name: string; condition: StepCondition }[]>(null);
  public currentStep = new BehaviorSubject<StepType<StepId>>(null);
  public customTransitionForward = new BehaviorSubject<StepType<StepId>>(null);

  constructor(@Inject('steps') private readonly steps: StepType<StepId>[]) {
    this.steps = this.initiate(this.steps);
    this.parentSteps.next(this.steps.map(({ name, condition }) => ({ name, condition })));
    this.currentStep.next(
      this.steps
        .map(({ children }) => children)
        .flat()
        .find((step) => step.prev === null)
    );
  }

  public transition(id: StepId, options: { needMakePrevCompleted?: boolean; direction?: Direction } = {}): void {
    const { direction, needMakePrevCompleted } = options;
    const currentStep = this.getStepById(id);

    if (currentStep?.hidden && direction) {
      const id = direction === Direction.Next ? currentStep?.next : currentStep?.prev;
      this.transition(id, options);

      return;
    }

    const stepBeforeCurrent = this.getStepById(currentStep.prev);
    const stepAfterCurrent = this.getStepById(currentStep.next);
    let isPositionBefore = true;

    if (direction === Direction.Next && stepBeforeCurrent.customTransitionForward) {
      this.customTransitionForward.next(stepBeforeCurrent);

      return;
    }

    this.steps.forEach((step) => {
      step.children.forEach((subStep) => {
        if (subStep.id === stepBeforeCurrent?.id) {
          needMakePrevCompleted && (subStep.condition = StepCondition.Completed);
          subStep.parentId !== currentStep.parentId && (step.condition = StepCondition.Completed);
        } else if (subStep.id === currentStep.id) {
          subStep.condition = StepCondition.Active;
          subStep.touched = true;
          step.condition = StepCondition.Active;
        } else if (subStep.id === stepAfterCurrent?.id) {
          isPositionBefore = false;
          subStep.parentId !== currentStep.parentId && (step.condition = StepCondition.Disabled);
        } else {
          needMakePrevCompleted && isPositionBefore && (subStep.condition = StepCondition.Completed);
          step.condition = isPositionBefore ? StepCondition.Completed : StepCondition.Disabled;
        }
      });
    });

    this.currentStep.next(currentStep);
    this.parentSteps.next(this.steps.map(({ name, condition }) => ({ name, condition })));
  }

  public setProperty(stepId: StepId, propName: string, value: any) {
    const step = this.getStepById(stepId);

    step[propName] = value;
  }

  public getStepById(stepId: StepId): StepType<StepId> {
    return this.steps
      .map(({ children }) => children)
      .flat()
      .find(({ id }) => stepId === id);
  }

  private initiate(steps: StepType<StepId>[]): StepType<StepId>[] {
    return steps.map((step) => ({
      ...step,
      condition: step.prev === null ? StepCondition.Active : StepCondition.Disabled,
      children: step.children ? this.initiate(step.children) : null
    }));
  }
}
