import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, UntypedFormGroup } from '@angular/forms';
import { StepsHelpers } from '@modules/wizards/helpers/steps-helpers';
import { HoursOrMinutesItems } from '@modules/wizards/models/schedule-advanced-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnumHelper, ModalComponent } from 'mbs-ui-kit';
import { debounceTime, map } from 'rxjs';
import { DayOfWeek } from '@models/DayOfWeek.enum';
import { WeekNumber } from '@models/WeekNumber.enum';
import { RecurType, RecurTypeText } from '@models/RecurType.enum';

@UntilDestroy()
@Component({
  selector: 'mbs-time-scheduler',
  templateUrl: './time-scheduler.component.html'
})
export class TimeSchedulerComponent implements OnInit, OnChanges {
  @Input() scheduleData: any = null;
  @Input() isAgentTimeUsed = false;
  @Input() enableDailyOccursEvery = true;
  @Output() onScheduleDataChange = new EventEmitter<any>();
  public isModal = true;
  public recurringPeriodItems = ['Daily', 'Monthly'];
  public hoursOrMinutesItems = HoursOrMinutesItems;
  public daysOfWeekEnumArray = EnumHelper.EnumToSelectIndexesArray(DayOfWeek);
  public weekNumberEnumArray = EnumHelper.EnumToSelectIndexesArray(WeekNumber).sort((a, b) =>
    a.label === WeekNumber[-1] || b.label === 'Penultimate' ? -1 : 0
  );
  public loading = true;

  // Isolated launch
  private initData = {
    isIncremental: true,
    validVersionForSchedule: this.validVersionForSchedule,
    value: StepsHelpers.getDefaultIncrementalSchedule(true),
    weeklyDayOfWeek: [],
    dailyFreqPeriodOption: 'OccursAt',
    occursAtTime: '00:00'
  };

  public form: UntypedFormGroup;

  get isIncremental(): boolean {
    if (!this.isModal) return true;
    return !!this.baseModal && !!this.baseModal.data && this.baseModal.data.isIncremental;
  }

  get validVersionForSchedule(): boolean {
    if (!this.isModal) return true;
    return !!this.baseModal && !!this.baseModal.data && this.baseModal.data.validVersionForSchedule;
  }

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor() {}

  ngOnInit(): void {
    if (this.isIncremental) {
      this.form = new UntypedFormGroup(StepsHelpers.getDefaultIncrementalSchedule(this.validVersionForSchedule));
      this.setForm();
    } else {
      this.form = new UntypedFormGroup(StepsHelpers.getDefaultFullSchedule(this.validVersionForSchedule));
      this.setForm(false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.scheduleData.currentValue) {
      this.setForm(false);
    }
  }

  setForm(isIncremental = true): void {
    const data = this.setFormDataSetup();
    for (const key in data) {
      if (!(data[key] === null || data[key] === undefined)) {
        if (key === 'dayOfMonthCount' && !+data[key]) data[key] = 1;
        if (this.form.get(key)) this.form.get(key).reset(data[key]);
      }
    }

    // @TODO Move updateDaysOfWeek from wizard module to shared and/or wait for component refactoring
    StepsHelpers.updateDaysOfWeek(data.weeklyDayOfWeek, this.form, this.daysOfWeekEnumArray);
    if (!this.isModal) this.setDailyDefaultValues();
    if (isIncremental || !this.isModal) this.occursAtChangeHandler(data.dailyFreqPeriodOption);
  }

  setFormDataSetup(): any {
    if (this.baseModal && this.baseModal.data && this.baseModal.data.value) {
      return this.baseModal.data.value;
    } else {
      this.isModal = false;
      this.recurringPeriodItems = ['Once', 'Daily', 'Monthly'];
      this.form = this.scheduleData ? this.getFormUtilFromInput() : this.getFormUtils();
      this.setDailyDefaultValues();
      this.handleOutputOnChange();
      return { ...this.initData, ...this.form.value };
    }
  }

  setDailyDefaultValues(): void {
    const dayList = this.form.get('weeklyDayOfWeek').value
    if (dayList.every((enabled) => !enabled)) {
      this.form.get('weeklyDayOfWeek').setValue(dayList.map(disabled => !disabled));
    }
  }

  handleOutputOnChange(): void {
    this.form.valueChanges
      .pipe(
        map((formValues) => {
          // Minimum time period = 10 minutes
          if (
            formValues.recurringPeriod === 'Daily' &&
            formValues.dailyFreqPeriodOption === 'OccursEvery' &&
            formValues.occursEveryPeriod === 'minutes' &&
            formValues.occursEveryCount < 10
          )
            this.form.get('occursEveryCount').setValue(10);
          return formValues;
        }),
        debounceTime(300),
        untilDestroyed(this)
      )
      .subscribe((value) => this.onScheduleDataChange.emit(value));
  }

  getFormUtils(): UntypedFormGroup {
    return new UntypedFormGroup({
      recurringPeriod: new FormControl('Once'),
      weeklyDayOfWeek: new FormArray([]),
      occurrence: new FormControl(-1),
      dayOfWeek: new FormControl(3),
      dayOfMonthCount: new FormControl(1),
      dailyFreqPeriodOption: new FormControl('OccursAt'),
      occursAtTime: new FormControl('00:00'),
      occursEveryCount: new FormControl(1),
      occursEveryPeriod: new FormControl('hours'),
      occursEveryFromTime: new FormControl('00:00'),
      occursEveryToTime: new FormControl('23:59'),
      repeatEveryCount: new FormControl(1),
      startFromDate: new FormControl(new Date())
    });
  }

  getFormUtilFromInput(): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});

    Object.keys(this.scheduleData).forEach((key) => {
      key === 'weeklyDayOfWeek'
        ? formGroup.addControl(key, new FormArray(this.scheduleData[key].map((value) => new FormControl(value))))
        : formGroup.addControl(key, new FormControl(this.scheduleData[key]));
    });
    return formGroup;
  }

  getCounterText(): string {
    const formValue = this.form.get('recurringPeriod').value;
    return formValue ? RecurTypeText[RecurType[formValue]] : '';
  }

  occursAtChangeHandler(event: string, disable = true): void {
    if (event === 'OccursAt') {
      this.form.get('occursAtTime').enable();
      this.form.get('occursEveryCount').disable();
      this.form.get('occursEveryPeriod').disable();
      this.form.get('occursEveryFromTime').disable();
      this.form.get('occursEveryToTime').disable();
    } else if (event === 'OccursEvery') {
      if (disable) this.form.get('occursAtTime').disable();
      this.form.get('occursEveryCount').enable();
      this.form.get('occursEveryPeriod').enable();
      this.form.get('occursEveryFromTime').enable();
      this.form.get('occursEveryToTime').enable();
    }
  }

  recurringPeriodChangeHandler(event: string): void {
    if (event === 'Monthly') {
      this.form.get('weeklyDayOfWeek').disable();
      if (this.isIncremental) {
        this.occursAtChangeHandler('OccursAt');
        this.form.get('dailyFreqPeriodOption').setValue('OccursAt');
        this.form.get('dayOfWeek').enable();
        this.form.get('dayOfMonthCount').enable();
      }
    } else if (event === 'Daily' || event === 'Once') {
      this.form.get('weeklyDayOfWeek').enable();
      if (this.isIncremental) {
        this.occursAtChangeHandler('OccursAt', false);
        this.form.get('dayOfWeek').disable();
        this.form.get('dayOfMonthCount').disable();
      }
    }
    this.form.updateValueAndValidity();
  }

  occursEveryPeriodChangeHandler(event): void {
    if (event === 'hours' && this.form.get('occursEveryCount').value > 24) {
      this.form.get('occursEveryCount').setValue(24);
    }
  }

  cancelClickHandler(): void {
    this.baseModal.close();
  }

  saveClickHandler(): void {
    this.baseModal.save({ [this.isIncremental ? 'incrementalData' : 'fullBackupData']: this.form.value });
  }
}
