import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { StepsService } from '@modules/wizards/onboarding/services/steps/steps.service';
import { BaseStep } from '@modules/wizards/onboarding/steps/shared/base/base.step';
import { NotificationsType } from '@modules/wizards/onboarding/views/computers/computers.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'mbs-onboarding-notifications-step',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent<StepId> extends BaseStep<StepId> implements OnInit, OnDestroy {
  public stepId = 'notifications';
  public isValid = true;
  public notificationsForm: UntypedFormGroup;
  public sendNotificationControl: FormControl;
  public onlyOnFailureControl: FormControl;
  private emailControl: FormControl;

  constructor(private fb: FormBuilder, private onboardingService: OnboardingService, private stepService: StepsService<StepId>) {
    super(stepService);
  }

  @Input() accountEmail;
  @Input() initialData: NotificationsType;
  @Output() notificationsChanged = new EventEmitter<NotificationsType>();

  ngOnInit() {
    this.configureForm();
    this.setSendNotificationSubscription();
    this.formValueChangesSubscription();
    this.notificationsChanged.emit(this.notificationsForm.value);
  }

  private configureForm(): void {
    const sendNotification = !isNil(this.initialData?.SendNotification) ? this.initialData?.SendNotification : true;

    this.notificationsForm = this.fb.group({
      SendNotification: [sendNotification],
      OnlyOnFailure: [{ value: this.initialData?.OnlyOnFailure || false, disabled: !sendNotification }],
      Email: [{ value: this.initialData?.Email || this.accountEmail, disabled: !sendNotification }, [Validators.required, Validators.email]]
    });
    this.onlyOnFailureControl = this.notificationsForm.get('OnlyOnFailure') as FormControl;
    this.emailControl = this.notificationsForm.get('Email') as FormControl;
    this.sendNotificationControl = this.notificationsForm.get('SendNotification') as FormControl;
  }

  private setSendNotificationSubscription(): void {
    this.sendNotificationControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.onlyOnFailureControl?.enable();
        this.emailControl.enable();
      } else {
        this.onlyOnFailureControl?.disable();
        this.emailControl.disable();
      }
    });
  }

  private formValueChangesSubscription() {
    this.notificationsForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (this.notificationsForm.valid) {
        this.notificationsChanged.emit(this.notificationsForm.value);
        this.onboardingService.stable$.next(true);
      } else {
        this.onboardingService.stable$.next(false);
      }
    });
  }
}
