import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Computer, { OsType } from '@models/Computer';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { ComputersFacade } from '@shared/facades/computers.facade';
import HostInfo from '@shared/models/rmm/HostInfo';
import { RmmService } from '@shared/services';
import { setSeverityScore } from '@shared/utils/helpers/rmm/severity-score';
import { I18NextService } from 'angular-i18next';
import moment from 'moment';
import { forkJoin, map, Observable, of, pluck, switchMap } from 'rxjs';
import {
  ComputerFromGroupTaskComputers,
  GroupTask,
  GroupTaskOsTypes,
  GroupTaskSummary,
  GroupTaskTypes,
  UpdatePolicyInfo
} from '../../group-tasks/store/model';
import * as GroupTaskSelectors from '../../group-tasks/store/selectors';
import { Software, SystemUpdate } from '../components/configure-action/components/install-system-update/install-system-update.component';
import { SeverityLevels, WindowsUpdateCategories } from '../components/configure-action/components/update-policy/update-policy.models';
import { getRegExpMatchesFromTimeSpanFormat } from '../components/configure-action/components/update-policy/update-policy.utility';
import { GASelectors } from '../store/group-action';
import {
  GAComputer,
  GroupActionsCommands,
  GroupTaskOsTypeNames,
  ScheduleState,
  SelectedEntities,
  TimeMeasureValues,
  WizardGroupAction
} from '../store/group-action/group-action.model';

@Injectable()
export default class EditGroupTaskUtility {
  public translationKey = 'rmm.module:groupActions.updatePolicy.';

  constructor(
    private store: Store,
    private i18next: I18NextService,
    private rmmService: RmmService,
    private computerFacade: ComputersFacade
  ) {}

  public getGroupTask(): Observable<GroupTask> {
    return this.store.select(GroupTaskSelectors.selectGroupTaskToEdit);
  }

  public getNameAndTypeAndOSFromGroupTaskStream(): Observable<{ command: GroupActionsCommands; name: string; osType: GroupTaskOsTypes }> {
    return this.store
      .select(GroupTaskSelectors.selectGroupTaskToEdit)
      .pipe(
        map((groupTask) => ({ command: this.parseCommandTypeFromGroupTask(groupTask), name: groupTask?.name, osType: groupTask?.osType }))
      );
  }

  public getApplyToDataFromGroupTaskStream(): Observable<SelectedEntities> {
    return this.store.select(GroupTaskSelectors.selectApplyToFromGroupTaskToEdit);
  }

  public getScheduleDataFromGroupTaskStream(): Observable<ScheduleState> {
    return this.store.select(GroupTaskSelectors.selectScheduleFromGroupTaskToEdit);
  }

  public getUpdatePolicyDataFromGroupTaskStream(): Observable<any> {
    return this.store.select(GroupTaskSelectors.selectGroupTaskToEdit).pipe(
      map((groupTask) =>
        groupTask?.type === GroupTaskTypes.Policy || groupTask?.type === 'Policy' ? JSON.parse(groupTask?.parameters) : []
      ),
      pluck('parameters', '0', 'value'),
      map((objectInString: string) => (objectInString ? JSON.parse(objectInString) : null))
    );
  }

  public getTerminalScriptStream(): Observable<string> {
    return this.store
      .select(GroupTaskSelectors.selectParametersFromGroupTaskToEdit)
      .pipe(map((parameters) => (parameters ? JSON.parse(parameters)?.parameters[0]?.value : null)));
  }

  public getTerminalTimeoutStream(): Observable<{ scriptTime: number; scriptTimeMeasure: TimeMeasureValues }> {
    return this.store.select(GroupTaskSelectors.selectParametersFromGroupTaskToEdit).pipe(
      map((parameters) => (parameters ? JSON.parse(parameters)?.parameters[1]?.value : null)),
      map((value) => {
        if (!value) return null;

        return value < 60
          ? { scriptTime: value, scriptTimeMeasure: TimeMeasureValues.minutes }
          : { scriptTime: value / 60, scriptTimeMeasure: TimeMeasureValues.hours };
      })
    );
  }

  public getScriptGuidStream(): Observable<string> {
    return this.store.select(GroupTaskSelectors.selectScriptGuidFromGroupTaskToEdit);
  }

  public getUninstallSoftwareStream(): Observable<{ identifyingNumber: string; productdisplayname: string }> {
    return this.store.select(GroupTaskSelectors.selectParametersFromGroupTaskToEdit).pipe(
      map((parameters) => {
        const dataArray: { name: string; value: string }[] = parameters ? JSON.parse(parameters)?.parameters : [];

        const identifyingNumber = dataArray.find((object) => object?.name === 'identifyingNumber')?.value;
        const productdisplayname = dataArray.find((object) => object?.name === 'productdisplayname')?.value;

        return { identifyingNumber, productdisplayname };
      })
    );
  }

  public getWindowsUpdatesStream(): Observable<{ name: string; updateNameList: string[] }> {
    return this.store.select(GroupTaskSelectors.selectParametersFromGroupTaskToEdit).pipe(
      map((parameters) => {
        const parameterSplitter = ', ';
        const dataArray: { name: string; value: string }[] = parameters ? JSON.parse(parameters)?.parameters : [];

        const name = dataArray[0]?.name;
        const updateNameList = dataArray[0]?.value.split(parameterSplitter);

        return { name, updateNameList };
      })
    );
  }

  public getInstallAppDataStream(): Observable<{ url: string; params: string }> {
    return this.store.select(GroupTaskSelectors.selectParametersFromGroupTaskToEdit).pipe(
      map((parameters) => {
        const dataArray: { name: string; value: string }[] = parameters ? JSON.parse(parameters)?.parameters : [];

        const url = dataArray.find((object) => object?.name === 'url')?.value;
        const params = dataArray.find((object) => object?.name === 'arguments')?.value;

        return { url, params };
      })
    );
  }

  public parseCommandTypeFromGroupTask(groupTask: Partial<GroupTask>): GroupActionsCommands {
    if (!groupTask || (!groupTask?.osType && !groupTask?.parameters && !groupTask?.policyId && !groupTask?.scriptGuid)) return null;

    if (groupTask?.scriptGuid)
      return groupTask.osType === GroupTaskOsTypes.Windows ? GroupActionsCommands.SCRIPT_LIBRARY : GroupActionsCommands.SCRIPT_LIBRARY_BASH;

    if (groupTask?.policyId) return GroupActionsCommands.UPDATE_POLICY;

    if (!groupTask?.parameters) return null;

    switch (JSON.parse(groupTask?.parameters).id) {
      case 'Script':
        return groupTask.osType === GroupTaskOsTypes.Windows ? GroupActionsCommands.POWERSHELL : GroupActionsCommands.BASH;
      case 'DownloadAndInstall':
        return GroupActionsCommands.INSTALL_PATCH;
      case 'InstallProductSimple':
        return GroupActionsCommands.INSTALL_SOFTWARE;
      case 'UninstallProduct':
        return GroupActionsCommands.UNINSTALL_SOFTWARE;
      case 'InstallSoftwareUsingWinget':
        return GroupActionsCommands.WIN_GET;
      default:
        return GroupActionsCommands.POWERSHELL;
    }
  }

  public getApplyToDataFromGroupTask(groupTask: GroupTask): SelectedEntities {
    if (groupTask?.allComputers) {
      return {
        computers: this.getDisabledComputerList(groupTask?.computers),
        companies: [],
        allCompanies: true,
        computerTags: groupTask.computerTags ?? []
      };
    } else if (!groupTask?.allComputers && groupTask?.companies.length) {
      return {
        computers: this.getDisabledComputerList(groupTask?.computers),
        companies: groupTask?.companies.map((company) => ({
          companyGuid: company.companyGuid,
          name: company?.name,
          disabled: company.disabled
        })),
        allCompanies: false,
        computerTags: groupTask.computerTags ?? []
      };
    } else {
      return {
        computers: groupTask?.computers.map((computer) => ({ hid: computer.hid, disabled: computer.disabled })),
        companies: [],
        allCompanies: false,
        computerTags: groupTask.computerTags ?? []
      };
    }
  }

  public getDisabledComputerList(computers: ComputerFromGroupTaskComputers[]): ComputerFromGroupTaskComputers[] {
    if (!computers?.length) return [];

    return computers.flatMap((computer) => (computer.disabled ? { hid: computer.hid, disabled: true } : []));
  }

  // Utility to parse and gather all necessary data from existing GroupTask Entity can be found below

  public getGroupTaskSummary(groupTask: GroupTask): GroupTaskSummary {
    return {
      name: groupTask.name,
      type: this.parseCommandTypeFromGroupTask(groupTask),
      description: groupTask?.description,
      applyTo: this.getApplyToInfoFromGroupTask(groupTask), // make applyToMoreFlexible, Add exclude computers as a separate, so , main parameter as what includes and sub parameter
      schedule: this.getScheduleInfoFromGroupTask(groupTask),
      updatePolicy: this.getUpdatePolicyDataFromGroupTask(groupTask)
    };
  }

  public getApplyToInfoFromGroupTask(groupTask: GroupTask): { includeInfo: string; excludeInfo: string; tags: number[] } {
    const applyTo = this.getApplyToDataFromGroupTask(groupTask);

    if (applyTo.allCompanies) {
      return {
        includeInfo: this.i18next.t('rmm.module:groupActions.updatePolicy.allCompaniesInfo'),
        excludeInfo: this.getApplyToComputersInfo(groupTask?.computers),
        tags: applyTo.computerTags ?? []
      };
    }

    if (applyTo.companies.length) {
      let textInfo = this.i18next.t('rmm.module:groupActions.updatePolicy.companyListInfo');
      applyTo.companies.forEach((company, index) => (textInfo += index !== 0 ? `, ${company.name}` : company.name));

      return { includeInfo: textInfo, excludeInfo: this.getApplyToComputersInfo(groupTask?.computers), tags: applyTo.computerTags ?? [] };
    }

    return {
      includeInfo: this.getApplyToComputersInfo(groupTask?.computers, false),
      excludeInfo: this.getApplyToComputersInfo(groupTask?.computers),
      tags: applyTo.computerTags ?? []
    };
  }

  public getApplyToComputersInfo(computers: Partial<ComputerFromGroupTaskComputers>[], isExcluded = true): string {
    if (!computers.length) return null;

    let textInfo = isExcluded
      ? this.i18next.t('rmm.module:groupActions.updatePolicy.excludedComputersInfo')
      : this.i18next.t('rmm.module:groupActions.updatePolicy.includedComputersInfo');

    const filtered = computers.filter((comp) => comp.disabled === isExcluded);

    filtered.forEach(
      (computer, index) => (textInfo += index !== 0 ? `, ${Computer.getComputerName(computer)}` : Computer.getComputerName(computer))
    );

    if (isExcluded && this.i18next.t('rmm.module:groupActions.updatePolicy.excludedComputersInfo') === textInfo) return null;
    if (!isExcluded && this.i18next.t('rmm.module:groupActions.updatePolicy.includedComputersInfo') === textInfo) return null;

    return textInfo;
  }

  private getScheduleInfoFromGroupTask(groupTask: GroupTask): string {
    return groupTask?.scheduleDescription;
  }

  public getUpdatePolicyDataFromGroupTask(groupTask: Partial<GroupTask>): UpdatePolicyInfo {
    if ((groupTask?.type === GroupTaskTypes.Policy || groupTask?.type === 'Policy') && groupTask?.parameters) {
      const dataValue = JSON.parse(groupTask?.parameters).parameters[0].value;
      const policyData = JSON.parse(dataValue);

      return {
        severityLevels: this.getSeverityLevelsFromData(policyData?.severityLevels),
        updateCategories: this.getUpdateCategoriesFromData(policyData?.categories),
        excludeUpdates: policyData?.excludeUpdates,
        deferInfo: this.getDeferInfoFromData(policyData?.deferInstall),
        rebootInfo: this.getRebootInfoFromData(policyData?.rebootTime)
      };
    } else {
      return null;
    }
  }

  public getSeverityLevelsFromData(severityLevels: number[]): string[] {
    if (!severityLevels?.length) return [];

    return Object.keys(SeverityLevels).flatMap((key, index) =>
      severityLevels.find((id) => id === index) !== undefined ? this.i18next.t(this.translationKey + SeverityLevels[key]) : []
    );
  }

  public getUpdateCategoriesFromData(categories: number[]): string[] {
    if (!categories?.length) return [];

    return Object.keys(WindowsUpdateCategories).flatMap((key, index) =>
      categories.find((id) => id == index) !== undefined ? this.i18next.t(this.translationKey + WindowsUpdateCategories[key]) : []
    );
  }

  public getDeferInfoFromData(timeSpanInfo: string): string {
    if (!timeSpanInfo) return null;

    const { days: days, hours: hours, minutes: minutes } = getRegExpMatchesFromTimeSpanFormat(timeSpanInfo);
    const timeUnit = this.i18next.t('app:timeUnits.' + (days ? 'days' : hours ? 'hours' : 'minutes'));

    if (days || hours || minutes)
      return this.i18next.t('rmm.module:groupActions.stepResult.deferInfo', {
        value: Number(days || hours || minutes),
        valueType: timeUnit
      });

    return null;
  }

  public getRebootInfoFromData(timeSpanInfo: string): string {
    if (!timeSpanInfo) return null;

    const { days: days, hours: hours, minutes: minutes } = getRegExpMatchesFromTimeSpanFormat(timeSpanInfo);
    if (days || hours || minutes)
      return this.i18next.t('rmm.module:groupActions.stepResult.rebootOptionEnabled', {
        value: moment(timeSpanInfo, 'HHmmss').format('h:mm A')
      });

    return null;
  }

  public getGroupActionFromGroupTask(groupTask: GroupTask): Partial<WizardGroupAction> {
    if (!groupTask) return null;

    return {
      id: groupTask?.groupTaskGuid ?? getGuid(),
      name: groupTask.name,
      osType: groupTask.osType,
      actionSelectCommand: this.parseCommandTypeFromGroupTask(groupTask) as any,
      groupActionCommand: this.parseCommandTypeFromGroupTask(groupTask),
      applyTo: this.getApplyToDataFromGroupTask(groupTask),
      parameters: { ...groupTask },
      schedule: {
        runType: groupTask?.runType,
        scheduleType: groupTask?.scheduleType,
        scheduleData: groupTask?.scheduleData
      }
    };
  }

  public getTranslationByKeyAndValue(translationKey: string, translationValue: {}): string {
    return this.i18next.t(translationKey, translationValue);
  }

  public getWindowsLocalUpdatesValue(update: boolean = null): string {
    switch (update) {
      case null:
        return this.i18next.t(this.translationKey + 'localUpdatesSelectionItems.noAction');
      case false:
        return this.i18next.t(this.translationKey + 'localUpdatesSelectionItems.disable');
      case true:
        return this.i18next.t(this.translationKey + 'localUpdatesSelectionItems.enable');
      default:
        return null;
    }
  }

  public getTimeValuesForDescription(timeInMinutes: number): string {
    const { valueNumber, valueMeasure } =
      timeInMinutes < 60
        ? { valueNumber: timeInMinutes, valueMeasure: this.i18next.t('app:timeUnits.minutes') }
        : { valueNumber: timeInMinutes / 60, valueMeasure: this.i18next.t('app:timeUnits.hours') };

    return this.i18next.t('rmm.module:groupActions.stepSummary.scriptTimeout', { valueNumber, valueMeasure });
  }

  public getAvailableSoftwareToRemoveStream(params: any = {}) {
    return this.getComputerHidListStreamPerApplyToStepExpectation().pipe(
      switchMap((hids) => {
        if (!hids.length) return of([]);

        const httpParams = { ...params, 'ext.field.host.operationSystemID': 'windows' };
        const body: { hids: string[]; companies: string[] } = {
          hids,
          companies: []
        };

        return this.rmmService.fetchSpecPost<Software>('software', httpParams, body).pipe(
          map((res) =>
            res
              .filter((software) => software.canUninstall !== 2)
              .map((software) => ({
                ...software,
                id: `${software.identifyingNumber}:${software.name}`
              }))
              .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
          )
        );
      })
    );
  }

  public getAvailableWindowsUpdatesStream(params: any = {}) {
    return this.getComputerHidListStreamPerApplyToStepExpectation().pipe(
      switchMap((hids) => {
        if (!hids.length) return of([]);

        const httpParams = { ...params, 'ext.field.host.operationSystemID': 'windows' };
        const body: { hids: string[]; companies: string[] } = {
          hids,
          companies: []
        };

        return this.rmmService.fetchSpecPost<SystemUpdate>('update', httpParams, body).pipe(
          map((res) =>
            res
              .map((update: SystemUpdate) => ({
                ...update,
                id: update.updateID,
                size: Number(update.size),
                severityScore: setSeverityScore(update).severityScore
              }))
              .sort((a, b) => a.severityScore - b.severityScore)
              .reverse()
          )
        );
      })
    );
  }

  private getComputerHidListStreamPerApplyToStepExpectation(): Observable<string[]> {
    return this.getComputerStreamPerApplyToStepExpectation().pipe(
      map((computers) => (computers?.length ? computers.map((comp) => comp.hid) : []))
    );
  }

  public getComputerStreamPerApplyToStepExpectation(): Observable<Computer[]> {
    return this.store.select(GASelectors.selectGActionApplyTo).pipe(
      switchMap((applyTo) => {
        let stream$ = this.computerFacade.getComputers({
          os: [OsType.windows],
          offline: false,
          tags: [...applyTo.computerTags]
        });

        if (applyTo.companies.length) {
          const selectedCompanies = applyTo.companies.filter((item) => !item.disabled);

          if (selectedCompanies.length)
            stream$ = this.computerFacade.getComputers({
              companyIds: selectedCompanies.map((item) => item.companyGuid),
              os: [OsType.windows],
              offline: false,
              tags: [...applyTo.computerTags]
            });
        }

        if (applyTo.computers.length) {
          const selectedComputersWithHid = applyTo.computers.filter((item) => !item.disabled);
          if (selectedComputersWithHid.length) {
            const getComputersStream$ = selectedComputersWithHid.map((comp) =>
              this.computerFacade
                .getComputers({
                  os: [OsType.windows],
                  offline: false,
                  tags: [...applyTo.computerTags],
                  hid: comp.hid
                })
                .pipe(
                  map((data) => {
                    if (!data?.data?.length) return null;

                    if (!applyTo.computerTags.length) return data.data[0];

                    // computer api doesn't filter by tag in case of hid usage. Checking manually
                    return data.data[0].tags.some((tag) => (applyTo.computerTags.length ? applyTo.computerTags.includes(tag.id) : true))
                      ? data.data[0]
                      : null;
                  })
                )
            );

            stream$ = forkJoin(getComputersStream$).pipe(
              map((computers) => {
                const newComputerList: Computer[] = computers.flatMap((comp) => (comp ? comp : []));
                return {
                  data: newComputerList,
                  total: newComputerList.length
                };
              })
            );
          }
        }

        return stream$.pipe(
          switchMap((data) => {
            if (!data.total) return of([]);

            const computers = data?.data;
            const excludedHids = applyTo.computers.filter((comp) => comp.disabled).map((comp) => comp.hid);

            return of(excludedHids.length ? computers.filter((comp) => !excludedHids.includes(comp.hid)) : computers);
          })
        );
      })
    );
  }

  public getComputerListForGActionStream(osType: GroupTaskOsTypes, httpParams: HttpParams = new HttpParams()): Observable<GAComputer[]> {
    httpParams = httpParams.append('field.operationSystemID', this.getOsTypeName(osType).toLowerCase());
    return this.rmmService.fetchSpec<HostInfo>('host', httpParams);
  }

  public getOsTypeName(osType: GroupTaskOsTypes): GroupTaskOsTypeNames {
    switch (osType) {
      case GroupTaskOsTypes.Mac:
        return GroupTaskOsTypeNames.MacOS;
      case GroupTaskOsTypes.Linux:
        return GroupTaskOsTypeNames.Linux;
      case GroupTaskOsTypes.Windows:
        return GroupTaskOsTypeNames.Windows;
      case GroupTaskOsTypes.None:
      default:
        return null;
    }
  }
}
