import {
  CompanyFromRegGroupTask,
  ComputerFromRegGroupTask,
  GroupTask,
  GroupTaskOsTypes,
  RunTypes,
  ScheduledDataFromGroupTask
} from '@modules/group-tasks/store/model';
import HostInfo from '@shared/models/rmm/HostInfo';
import { WizardStepComponent } from 'mbs-ui-kit';

export interface WizardGroupAction {
  id?: string;
  name: string;
  osType: GroupTaskOsTypes;
  actionSelectCommand: WindowsAvailableActions | LinuxAvailableActions | MacAvailableActions;

  applyTo: SelectedEntities;

  groupActionCommand: GroupActionsCommands;
  parameters: Partial<GroupTask>;

  schedule: ScheduleState;
}

export enum GroupTaskOsTypeNames {
  Windows = 'windows',
  MacOS = 'macos',
  Linux = 'linux'
}

// Result of the Schedule step in wizard
export interface ScheduleState {
  runType: RunTypes;
  scheduleType: number | string;
  scheduleData: ScheduledDataFromGroupTask;
  scheduleDesc?: string;
}

// Result of the ApplyTo step in wizard
export interface SelectedEntities {
  computers: ComputerFromRegGroupTask[];
  companies: CompanyFromRegGroupTask[];
  computerTags: number[];
  allCompanies: boolean;
}

export interface Step {
  id: GroupActionStepsList;
  title: string;
  valid: boolean;
  canNext: boolean;
  ref?: WizardStepComponent;
}

export enum GroupActionStepsList {
  ACTION = 'action',
  COMPUTER = 'computers',
  SCHEDULE = 'schedule',
  RUN = 'run',
  SUMMARY = 'summary',
  RESULT = 'result'
}

export interface StepAnnotation {
  [key: string]: Step['title'];
}

export type ExecutionStatus = 'executing' | 'ok' | 'fail' | 'unknown';

// entire command naming
export enum GroupActionsCommands {
  POWERSHELL = 'powerSHell',
  BASH = 'bash',
  FILE_SCRIPT = 'execScript',
  SCRIPT_LIBRARY = 'scriptLibrary',
  SCRIPT_LIBRARY_BASH = 'scriptLibraryBash',
  // UNINSTALL_PATCH = 'uninstallPatch',
  INSTALL_SOFTWARE = 'installProductSimple',
  UNINSTALL_SOFTWARE = 'uninstallProduct',
  INSTALL_PATCH = 'downloadAndInstall',
  UPDATE_POLICY = 'updatePolicy',
  WIN_GET = 'winGet'
}

export enum WindowsAvailableActions {
  PowerShell = GroupActionsCommands.POWERSHELL,
  Uninstall = GroupActionsCommands.UNINSTALL_SOFTWARE,
  WindowsUpdates = GroupActionsCommands.INSTALL_PATCH,
  UpdatePolicy = GroupActionsCommands.UPDATE_POLICY,
  InstallAndUpdate = GroupActionsCommands.WIN_GET
}

export enum LinuxAvailableActions {
  Bash = GroupActionsCommands.BASH
}

export enum MacAvailableActions {
  Bash = GroupActionsCommands.BASH
}

export type GAComputer = HostInfo & { id?: string; hid?: string };

// utility
export enum ScriptModeSelectTypes {
  fromScript,
  fromScriptLibrary,
  fromFile
}

export enum InstallSofwareSelectionTypes {
  usingWinget,
  usingLink
}

export enum TimeMeasureValues {
  minutes,
  hours
}

export const DEFAULT_RMM_GROUP_TASK_TIMEOUT = '5';
