import { Component, Input, OnInit } from '@angular/core';
import HardwareInfo from '@models/rmm/HardwareInfo';
import RmmLastStatData from '@models/rmm/RmmLastStatData';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RmmService } from '@services/rmm.service';
import { baseDateFileNameFormatHoursAndMinutes } from '@utils/date';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { SharedPersistentStateEnum, TableHeader } from 'mbs-ui-kit';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import moment from 'moment';
import { finalize, first } from 'rxjs/operators';
import { RmmTabBaseComponent } from '../rmm-tab-base.component';

@UntilDestroy()
@Component({
  selector: 'mbs-hardware-tab',
  templateUrl: './hardware-tab.component.html'
})
export class HardwareTabComponent extends RmmTabBaseComponent<HardwareInfo> implements OnInit {
  @Input() computerName: string;
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;
  public shortHeaders: TableHeader[] = [
    {
      name: this.i18n.transform('rmm-side-panel:hardwareTab.tableHeaders.name'),
      overflow: true,
      sort: 'name',
      gridColSize: '50fr'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hardwareTab.tableHeaders.status'),
      sort: 'status',
      class: '-stretch text-right',
      gridColSize: '12fr'
    }
  ];

  public fullHeaders: TableHeader[] = this.shortHeaders.concat([
    {
      name: this.i18n.transform('rmm-side-panel:hardwareTab.tableHeaders.manufacturer'),
      overflow: true,
      sort: 'manufacturer',
      gridColSize: '25fr'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hardwareTab.tableHeaders.pnpType'),
      sort: 'pnpClass',
      gridColSize: '25fr'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hardwareTab.tableHeaders.pnpDeviceID'),
      overflow: true,
      sort: 'pnpDeviceID',
      gridColSize: '25fr'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hardwareTab.tableHeaders.deviceType'),
      sort: 'service',
      overflow: true,
      gridColSize: '25fr'
    }
  ]);

  constructor(public rmmService: RmmService, private i18n: I18NextPipe) {
    super();
    this.headers = this.shortHeaders;
  }

  ngOnInit(): void {
    this.headers = this.isModal ? this.fullHeaders : this.shortHeaders;
    this.searchFields = ['manufacturer', 'name'];
    if (this.isModal) {
      this.searchFields = this.searchFields.concat(['service', 'pnpDeviceID', 'pnpClass']);
    }
    this.rmmService
      .fetchLastData<RmmLastStatData<HardwareInfo>>('hardware')
      .pipe(first())
      .subscribe((hardwares) => {
        if (hardwares) {
          this.loading = false;
          // TODO Refactor Sergey I
          // What are you going to check here?
          // eslint-disable-next-line  sonarjs/no-gratuitous-expressions
          if (hardwares) {
            this.allData = cloneDeep(hardwares.data);
            this.updateData(this.allData);
          }
        } else {
          this.loading = true;
          this.fetchData();
        }
      });
  }

  fetchData(): void {
    this.loading = true;
    this.rmmService
      .fetchLastData<RmmLastStatData<HardwareInfo>>('hardware')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.loading = false))
      )
      .subscribe((stat) => {
        if (stat) {
          const hardwares = stat.data;
          this.allData = cloneDeep(hardwares);
          this.updateData(this.allData);
        }
      });
  }

  trackBy(index: number, item: ExtendedTableRow): string {
    return (item?.item as HardwareInfo)?.pnpDeviceID;
  }

  public getTableName(): string {
    return `${this.computerName} ${this.i18n.transform('rmm-side-panel:sidePanelTabsName.hardware')} ${moment().format(
      baseDateFileNameFormatHoursAndMinutes
    )}`;
  }
}
