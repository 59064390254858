import { Component, Inject, Input, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ComputersFacade } from '@facades/computers.facade';
import Administrator from '@models/Administrator';
import Computer, { AgentType, OsType } from '@models/Computer';
import { ModalTerminalComponent } from '@modules/terminal-emulator/components/modal-terminal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComputersAbstractService, TFARMMPermissionHelperService } from '@services';
import { AuthService } from '@services/auth.service';
import { RmmPowershellService } from '@services/rmm-powershell.service';
import { versionCompare } from '@utils/version-compare';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { noop } from 'lodash';
import { MbsSize, ModalService, ModalSettings, ToastService } from 'mbs-ui-kit';
import { NgTerminal } from 'ng-terminal';
import { BehaviorSubject, combineLatest, debounceTime, filter, pluck, switchMap, tap } from 'rxjs';
import { TFARequireModalComponent } from '../../tfa/components/tfa-require-modal/tfa-require-modal.component';

const enum ActionAfterTwoFA {
  PowerShell,
  WakeOnLan
}

@UntilDestroy()
@Component({
  selector: 'rmm-quick-tools',
  templateUrl: './rmm-quick-tools.component.html'
})
export class RmmQuickToolsComponent implements OnInit {
  @Input() hid: string;
  @Input() isOnline: boolean;

  @ViewChild('powerShellModal', { static: true, read: TemplateRef }) content: TemplateRef<any>;
  @ViewChild('newTerminalModal', { static: true, read: TemplateRef }) newTerminal: TemplateRef<any>;
  @Optional() @Inject('IS_MODAL_CONTENT_OPEN') private IS_MODAL_CONTENT_OPEN: BehaviorSubject<boolean>;

  public size = MbsSize;

  public powerShellEnabled = false;
  public copyLinkEnabled = false;

  public isLoading = true;
  public warning: string;
  private ngTerminal: NgTerminal;

  public computer: Computer;
  public isSnmpSupported = false;
  public rmmGeneralTabContainer = 'mbs-sidepanel-rmm-info .mbs-form_content';
  private minimalVersion = '1.5';
  private currentUser: Administrator = null;
  public isRebootSupported = false;
  public allowRemoteManagement = false;

  public newTerminalAgentVersion = 200;
  public shouldUseNewTerminalVersion = true;
  public isWindows = true;

  public newTerminalForm: UntypedFormGroup = new UntypedFormGroup({
    terminalValue: new FormControl('')
  });

  constructor(
    private computerService: ComputersAbstractService,
    private rmmPowershellService: RmmPowershellService,
    public ability: AbilityService,
    private router: Router,
    private modalService: ModalService,
    private toastService: ToastService,
    public i18nPipe: I18NextPipe,
    private computersFacade: ComputersFacade,
    private authService: AuthService,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService
  ) {
    this.authService.currentUser.subscribe((user) => (this.currentUser = user));
  }

  ngOnInit(): void {
    const computer$ = this.computersFacade.currentComputer$.pipe(debounceTime(300), untilDestroyed(this));

    const allowPowerShell$ = computer$.pipe(
      filter((computer) => !!computer?.hid),
      switchMap((computer) => this.computerService.getComputerAgentOptions(computer.hid)),
      debounceTime(300),
      tap((data) => (this.allowRemoteManagement = data?.rmmValues ? !data.rmmValues.readOnly : false)),
      pluck('rmmValues', 'allowPowerShell'),
      untilDestroyed(this)
    );

    combineLatest([computer$, allowPowerShell$]).subscribe(([computer, allowPowerShell]) => {
      if (computer) {
        this.computer = computer;
        this.hid = computer.hid;

        this.powerShellEnabled = allowPowerShell && computer.os === OsType.windows;
        this.rmmPowershellService.setHid(this.hid);
        this.shouldUseNewTerminalVersion = Computer.IsSupportedAgentVersion(computer, AgentType.RMM, this.newTerminalAgentVersion);

        this.copyLinkEnabled = true;
        this.isWindows = Computer.isWindows(computer);

        const agentVersion = Computer.getAgent(computer, AgentType.RMM)?.version;
        this.isSnmpSupported = agentVersion && versionCompare(agentVersion, this.minimalVersion) >= 0;

        const wakeOnLanAndRebootMinVersionForUnix = 160;
        this.isRebootSupported =
          this.isWindows || Computer.IsSupportedAgentVersion(computer, AgentType.RMM, wakeOnLanAndRebootMinVersionForUnix);

        this.isLoading = false;
      }
    });
  }

  public copyLink(): void {
    // clipboardService
    const location = window.location.href.split('/');
    const url = `${location[0]}//${location[2]}/${location[3]}/${location[4]}`;
    const urn = `/${this.hid.replace('{', '%7B').replace('}', '%7D')}?sidepanel=rmm`;
    navigator.clipboard.writeText(url + urn);

    this.toastService.success(this.i18nPipe.transform('rmm-side-panel:toast-messages:linkCopied', { format: 'title' }));
  }

  public openPowerShell(): void {
    switch (true) {
      case !this.isOnline:
        this.toastService.error(this.i18nPipe.transform('rmm-side-panel:toast-messages:rmmOffline'));
        break;
      case !this.powerShellEnabled:
        this.toastService.error(this.i18nPipe.transform('rmm-side-panel:toast-messages:powerShellDisabled'));
        break;
      default:
        this.requestPermissions(ActionAfterTwoFA.PowerShell);
        break;
    }
  }

  public openPowerSmnp(): void {
    const queryParams = { computer: this.hid };
    this.router.navigate(['AP/Snmp'], { queryParams });
  }

  public wakeOnLan(): void {
    this.requestPermissions(ActionAfterTwoFA.WakeOnLan);
  }

  // PowerShell Tab block
  public handleInitialized(ngTerminal: NgTerminal): void {
    this.ngTerminal = ngTerminal;
  }

  handlePowerShellTerminalOpen(): void {
    if (!this.ability.can('read', '2FAEnabled')) {
      this.modalService
        .openCustom(TFARequireModalComponent, {
          size: MbsSize.sm,
          container: this.IS_MODAL_CONTENT_OPEN?.getValue() ? 'body .modal .modal-body' : this.rmmGeneralTabContainer
        })
        .finally(noop);
      return;
    }

    const modalSettings: ModalSettings = {
      size: MbsSize.xl,
      header: { title: this.getTerminalWindowTitle(), icon: 'ico-hyperv', showExpandedCross: true },
      footer: { show: false },
      collapsing: true
    };

    const terminalModalRef = this.modalService.openRef(ModalTerminalComponent, modalSettings);
    terminalModalRef.result.finally(noop);
  }

  requestPermissions(actionAfterTwoFA: ActionAfterTwoFA) {
    const container = this.IS_MODAL_CONTENT_OPEN?.getValue() ? 'body .modal .modal-body' : this.rmmGeneralTabContainer;
    this.tfaRMMPermissionHelper
      .is2FAPassedStream([this.hid], container)
      .pipe(untilDestroyed(this))
      .subscribe((confirmed) => confirmed && this.proceedWithAction(actionAfterTwoFA));
  }

  private proceedWithAction(actionAfterTwoFA: ActionAfterTwoFA): void {
    if (actionAfterTwoFA !== ActionAfterTwoFA.PowerShell) return;

    this.shouldUseNewTerminalVersion ? this.openTerminalInModalTab() : this.handlePowerShellTerminalOpen();
  }

  get isConnectAvailable(): boolean {
    return (
      this.isWindows || (Computer.isMacOrLinux(this.computer) && Computer.hasAgent(this.computer, AgentType.Backup))
    );
  }

  openTerminalInModalTab(): void {
    const settings: ModalSettings = {
      size: MbsSize.md,
      responsive: false,
      header: {
        title: this.getTerminalWindowTitle(),
        showCloseCross: true,
        showExpandedCross: true,
        icon: 'ico ico-Terminal'
      },
      footer: { show: false }
    };

    this.modalService.open(settings, this.newTerminal).finally(noop);
  }

  getTypeOfTerminal() {
    return this.isWindows ? this.i18nPipe.transform('rmm-side-panel:quickToolsFromGeneralTab.powershell') : '';
  }

  getTerminalWindowTitle(): string {
    return this.i18nPipe.transform('rmm-side-panel:quickToolsFromGeneralTab.terminalModalTitle', {
      value: this.getTypeOfTerminal()
    });
  }
}
