import Company from '@models/Company';

export enum TagType {
  BuiltIn,
  CustomGlobal,
  SpecifiedCompanies
}

export enum TagKind {
  Unknown,
  Computer
}

export type Tag = {
  id: number;
  name: string;
  kind: TagKind;
  type: TagType,
  allowedCompanies: Company[];
  /* Can be null by design, if request doesn't include calculateCount param */
  computersCount: number | null;
}
