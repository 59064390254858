import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GroupTask, GroupTaskOsTypes } from '@modules/group-tasks/store/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { I18NextService } from 'angular-i18next';
import { GAActions, GASelectors } from '../../../../store/group-action';

import { withLatestFrom } from 'rxjs';
import { GroupActionsCommands, ScriptModeSelectTypes } from '../../../../store/group-action/group-action.model';
import EditGroupTaskUtility from '../../../../utility/edit-group-task-utility';

export enum ScriptType {
  Bash = 'Bash',
  Powershell = 'PowerShell'
}

@UntilDestroy()
@Component({
  selector: 'mbs-execute-script',
  templateUrl: './execute-script.component.html',
  styleUrls: ['./execute-script.component.scss']
})
export class ExecuteScriptComponent implements OnInit {
  public modeSelectType = ScriptModeSelectTypes;
  public executeScriptFormType: FormGroup = new FormGroup({
    modeSelect: new FormControl(this.modeSelectType.fromScriptLibrary)
  });

  public fromFileLabel: string;
  public fromLibraryLabel: string;

  public lastScriptCodeParameters: Partial<GroupTask>;
  public lastLibraryParameters: Partial<GroupTask>;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private editGroupTaskUtility: EditGroupTaskUtility,
    private i18n: I18NextService
  ) {
    this.store
      .select(GASelectors.selectGActionOsType)
      .pipe(untilDestroyed(this))
      .subscribe((osType) => {
        this.setupFormLabels(osType === GroupTaskOsTypes.Windows ? ScriptType.Powershell : ScriptType.Bash);

        this.lastScriptCodeParameters = null;
        this.lastLibraryParameters = null;
        this.store.dispatch(GAActions.setGActionParameters({ parameters: null }));
        this.executeScriptFormType.get('modeSelect').setValue(ScriptModeSelectTypes.fromScriptLibrary);
      });

    this.executeScriptFormType
      .get('modeSelect')
      .valueChanges.pipe(
        withLatestFrom(this.store.select(GASelectors.selectGActionOsType), this.store.select(GASelectors.selectGActionParameters)),
        untilDestroyed(this)
      )
      .subscribe(([mode, osType, params]) => {
        // save previous values
        if (params?.parameters) {
          if (params.scriptGuid) {
            this.lastLibraryParameters = params;
          } else {
            this.lastScriptCodeParameters = params;
          }
        }

        // use case to block groupAction Configuration Step
        if (this.modeSelectType.fromFile === mode) {
          this.store.dispatch(GAActions.setGActionCommandType({ groupActionCommand: null }));
        }

        if (this.modeSelectType.fromScript === mode) {
          this.store.dispatch(
            GAActions.setGActionCommandType({
              groupActionCommand: osType === GroupTaskOsTypes.Windows ? GroupActionsCommands.POWERSHELL : GroupActionsCommands.BASH
            })
          );
        }

        if (this.modeSelectType.fromScriptLibrary === mode) {
          this.store.dispatch(
            GAActions.setGActionCommandType({
              groupActionCommand:
                osType === GroupTaskOsTypes.Windows ? GroupActionsCommands.SCRIPT_LIBRARY : GroupActionsCommands.SCRIPT_LIBRARY_BASH
            })
          );
        }

        this.loadPreviousParameters(
          this.modeSelectType.fromScriptLibrary === mode ? this.lastLibraryParameters : this.lastScriptCodeParameters
        );
      });
  }

  setupFormLabels(value: string): void {
    this.fromLibraryLabel = this.i18n.t('rmm.module:groupActions.stepConfigureAction.scriptFromLibraryLabel', { value });
    this.fromFileLabel = this.i18n.t('rmm.module:groupActions.stepConfigureAction.scriptFromFileLabel', { value });
  }

  loadPreviousParameters(parameters: Partial<GroupTask>): void {
    if (parameters) this.store.dispatch(GAActions.setGActionParameters({ parameters }));
  }

  ngOnInit(): void {
    this.editGroupTaskUtility
      .getGroupTask()
      .pipe(untilDestroyed(this))
      .subscribe((groupTask) => {
        if (!groupTask) {
          this.executeScriptFormType.setValue({ modeSelect: this.modeSelectType.fromScriptLibrary });
          return;
        }

        this.executeScriptFormType.setValue({
          modeSelect: groupTask.scriptGuid ? this.modeSelectType.fromScriptLibrary : this.modeSelectType.fromScript
        });
      });
  }

  modeSelected(modeSelectType: ScriptModeSelectTypes) {
    return modeSelectType === this.executeScriptFormType.get('modeSelect').value;
  }

  setModeSelect(modeSelectType: ScriptModeSelectTypes) {
    this.executeScriptFormType.get('modeSelect').setValue(modeSelectType);
  }
}
