import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@mbs-ui/environments/environment';
import { AuthService } from '@services/auth.service';
import { ConfigurationService } from '@services/configuration.service';
import { convertToClientUrl } from '@utils/pipes/client-url.pipe';
import { ModalService } from 'mbs-ui-kit';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

const sessionExpiredTemplate = `
<div class="row flex-nowrap align-items-center">
  <div class="col-auto">
    <div class="modal_content-ico text-white bg-info -circle">
      <span class="ico ico-Info"></span>
    </div>
  </div>
  <div class="mbs-modal_content-text col-10">
    Your session has expired. Please log in again.
  </div>
</div>`;

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  private showSessionExpired = false;
  private excludeUrls: string[];
  private noUnauthorizedCheckList: string[];

  constructor(
    private authService: AuthService, // prevent prettier format
    private router: Router,
    private modalService: ModalService,
    private configService: ConfigurationService
  ) {
    this.excludeUrls = ['/Admin/PlansManagement/GetPlan', '/Admin/PlansManagement/PostPlan', '/Admin/Monitoring', '/Admin/Computers'];
    this.noUnauthorizedCheckList = [
      'api/auth/new-token-scope/feedback-access',
      '/api/version',
      'api/auth/new-token-scope/appsbackup-access',
      'api/odata/Mbs'
    ];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addToken(this.authService.authToken, req);

    this.noUnauthorizedCheckList = this.noUnauthorizedCheckList.concat(
      this.configService.loaded ? ['/commands/invokepscommand', '/commands/invokeactivecommand'] : []
    );

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401 || this.noUnauthorizedCheckList.some((x) => req.url.includes(x))) {
          // rethrow to next interceptor
          return throwError(() => error);
        }

        const refreshAndRetry = this.authService.tryRefreshToken$.pipe(
          switchMap((refreshed) => {
            if (!refreshed) {
              if (environment.production) {
                this.handleUnauthorizedProd();
              } else {
                void this.router.navigate(['login']);
              }

              return throwError(() => error);
            }

            req = this.addToken(this.authService.authToken, req);
            this.authService.fetchCurrentUser().subscribe();

            return next.handle(req).pipe(
              // again received 401 show modal or go to login
              catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                  if (environment.production) {
                    this.handleUnauthorizedProd();
                  } else {
                    void this.router.navigate(['login']);
                  }
                }

                return throwError(() => error);
              })
            );
          })
        );

        if (environment.production) {
          // for external service that required auth by JWT
          if (environment.useSessionToken) {
            return refreshAndRetry;
          }

          this.handleUnauthorizedProd();

          return throwError(() => error);
        } else {
          return refreshAndRetry;
        }
      })
    );
  }

  private addToken(tokenData: string, req: HttpRequest<any>): HttpRequest<any> {
    const excludeHosts = this.configService?.loaded ? [this.configService.get('appsBaseHref')] : [];

    if (excludeHosts.some((host) => req.url.includes(host))) {
      return req;
    } else if (tokenData && this.excludeUrls.every((url) => !req.url.includes(url)) && environment.production) {
      req = req.clone({ headers: req.headers.set('Authorization', this.authService.authToken) });
    } else if (tokenData && !environment.production)
      req = req.clone({ headers: req.headers.set('Authorization', this.authService.authToken) });

    return req;
  }

  private showSessionExpiredModal(loginPage: string): void {
    const popupSettings = {
      header: { title: 'Session Expired' },
      footer: {
        okButton: { text: 'Login' },
        cancelButton: { show: false }
      }
    };

    this.showSessionExpired = true;

    this.modalService.open(popupSettings, sessionExpiredTemplate).finally(() => {
      if (environment.production) {
        this.authService.logoutUser();
      } else {
        void this.router.navigate(['login']);
      }

      this.showSessionExpired = false;
    });
  }

  private handleUnauthorizedProd(): void {
    if (!this.authService.isMBSMode) {
      this.authService.logoutUser();
      return;
    }
    this.authService.loaded$.pipe(first()).subscribe((loaded) => {
      if (!loaded) {
        this.authService.logoutUser();
      } else if (!this.showSessionExpired) {
        // don't double modal
        const loginPage = location.origin + convertToClientUrl('/Admin/Login.aspx?logoff=1');
        this.showSessionExpiredModal(loginPage);
      }
    });
  }
}
