import { ChangeDetectorRef, Component, forwardRef, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { TreeBunchesIcons } from '@models/backup/storages-type';
import { AgentType } from '@models/Computer';
import { SelectHostStepValue } from '@modules/wizards/models/select-host-models';
import { SelectVirtualDisksStepValue, VirtualDisk, VirtualDisksSelectedType } from '@modules/wizards/models/select-virtual-disks-models';
import {
  SelectVirtualMachinesStepValue,
  VirtualMachine,
  VirtualMachinesSelectedType,
  VirtualMachinesType
} from '@modules/wizards/models/select-virtual-machines-models';
import { TreeIconPath } from '@modules/wizards/models/what-backup-tree-model';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import {
  MachinesDisksLoadedStatuses,
  ParamsForGetVirtualMachinesOrDisksList,
  WizardStepsService
} from '@modules/wizards/services/wizard-steps.service';
import { TreeInModalComponent } from '@modules/wizards/steps/components/tree-in-modal/tree-in-modal.component';
import { StepBase } from '@modules/wizards/steps/StepBase.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { isEqual } from 'lodash';
import { MbsSize, ModalService, ModalSettings, TableHeader } from 'mbs-ui-kit';
import { noop, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const SelectVirtualDisksStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => SelectVirtualDisksStepComponent),
  multi: true
};

type ExtendedVirtualMachine = VirtualMachine & { existDisk?: boolean; disks?: VirtualDisk[] };

export class DisksHash {
  private storage: { [key: string]: VirtualDisk } = {};

  constructor() {
    return new Proxy(this, {
      set(target: DisksHash, key: string, newValue: VirtualDisk): boolean {
        target.storage[key.toLowerCase()] = newValue;

        return true;
      }
    });
  }

  hasDisk(uiGuid: string): boolean {
    return !!this.storage[uiGuid.toLowerCase()];
  }

  reset(): void {
    Object.keys(this.storage).forEach((key) => delete this.storage[key]);
  }
}

/**
 * Component mbs-select-virtual-disks-step
 * Displays the drives for the computers selected in the mbs-select-virtual-machines-step step
 * Used within the HyperV and VMWare wizards
 * @author Roman.Sh
 */
@UntilDestroy()
@Component({
  selector: 'mbs-select-virtual-disks-step',
  templateUrl: './select-virtual-disks-step.component.html',
  styleUrls: ['./select-virtual-disks-step.component.scss'],
  providers: [SelectVirtualDisksStepValueAccessor]
})
export class SelectVirtualDisksStepComponent extends StepBase<SelectVirtualDisksStepValue> implements OnInit {
  @Input() selectedHostCredentials: SelectHostStepValue;
  @Input() selectedMachinesStepValue: SelectVirtualMachinesStepValue;
  public readonly elementsSelector = {
    name: {
      computerExcludeTag: 'computerExcludeTag',
      diskExcludeTag: 'diskExcludeTag',
      invalidTableAlert: 'invalidTableAlert'
    }
  };
  public readonly diskIcon = TreeIconPath.Disk;
  public readonly mbsSize = MbsSize;
  public readonly virtualDisksSelectedType = VirtualDisksSelectedType;
  private needUpdateTableSubject$: Subject<void> = new Subject();
  public virtualMachinesIcon = TreeBunchesIcons.HyperV;

  public validVersionForShowExcludes = false;
  public validVersionHyperVLabelDisk = false;

  public tableData: Array<ExtendedVirtualMachine> = [];

  public availableDisks: VirtualDisk[] = [];
  public selectedDisks: Array<ExtendedVirtualMachine & VirtualDisk> = [];
  public headers: TableHeader[] = [{ name: 'VM Name', gridColSize: '100fr', class: 'w-100', overflow: true }];
  public tableIsInvalid = false;
  public subHeaders: TableHeader[] = [
    { name: 'Disk Name', gridColSize: '64fr', overflow: true },
    { name: 'Size', overflow: true, gridColSize: '36fr', isGridColumn: true, class: '-right' }
  ];

  private VMHash: { [key: string]: VirtualMachine & { existDisk?: boolean; disks: VirtualDisk[] } } = {};
  private initialAllDisksHash: DisksHash = new DisksHash();

  private canUpdateSelectedDisks = true;
  private offlineFirst = true;
  private firstCallUpdateFormAfterBuildTable = true;
  private machinesDisksLoadedStatuses: MachinesDisksLoadedStatuses = null;

  constructor(
    public mainService: RemoteManagementWizardsService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private i18nPipe: I18NextPipe,
    private stepService: WizardStepsService
  ) {
    super(mainService);
    this.subscribeToUpdateTableAndBaseData();
    if (this.isVMWare) this.virtualMachinesIcon = TreeBunchesIcons.VmWare;
    this.checkValidAgentVersion();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      allDisks: new FormControl([]),
      disks: new FormControl([]),
      type: new FormControl(VirtualDisksSelectedType.Selected)
    });

    this.initFormEvents();

    this.mainService.machinesDisksStatuses$.pipe(untilDestroyed(this)).subscribe((statuses) => {
      this.machinesDisksLoadedStatuses = statuses;

      this.updateTableValidity();
    });
  }

  onStepFormChange(value: SelectVirtualDisksStepValue): void {
    this.value = {
      ...value,
      valid: this.stepForm.valid && (!!value.disks.length || value.type === VirtualDisksSelectedType.All) && !this.tableIsInvalid
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedMachinesStepValue) {
      const stepCurrent = changes?.selectedMachinesStepValue?.currentValue;
      const previousMachines = changes?.selectedMachinesStepValue?.previousValue?.machinesFullFormat;
      const currentMachines = stepCurrent?.machinesFullFormat;
      const isNotSelected = stepCurrent?.type !== VirtualMachinesSelectedType.Selected;
      const notEqualOrFirstIsOffline = !isEqual(previousMachines, currentMachines) || (this.isOffline && this.offlineFirst);

      if (isNotSelected || (currentMachines?.length && notEqualOrFirstIsOffline)) {
        if (this.isOffline) {
          this.waitFinishInitialSubjectAndRunAgain();
        } else this.needUpdateTableSubject$.next(undefined);
      }
    }
  }

  waitFinishInitialSubjectAndRunAgain(): void {
    this.offlineFirst = false;
    setTimeout(() => this.needUpdateTableSubject$.next(undefined));
  }

  forceValid(data: any = null): void {
    this.stepForm.updateValueAndValidity();
    this.stepForm.markAllAsTouched();
  }

  checkValidAgentVersion(): void {
    const version = this.mainService.backupVersionUpdated && this.mainService.backupVersionUpdated.substring(0, 3);
    this.validVersionForShowExcludes = version && +version >= (this.isHyperV ? 790 : 783);
    this.validVersionHyperVLabelDisk =
      this.isHyperV && this.mainService.backupVersionUpdated && +this.mainService.backupVersionUpdated >= 790255;
  }

  buildVMHashesAndResetData(): void {
    this.canUpdateSelectedDisks = false;
    this.VMHash = {};
    this.availableDisks = [];
    this.tableData = [];
    this.initialAllDisksHash = new DisksHash();

    if (this.isOffline) {
      this.value.disks.forEach((disk: VirtualDisk) => {
        this.VMHash[disk.virtualMachineId] = {
          ...{ virtualMachineId: disk.virtualMachineId, displayName: disk.virtualMachineName },
          existDisk: false,
          uiGuid: disk.virtualMachineId,
          disks: []
        } as VirtualMachine & { existDisk?: boolean; disks: [] };
      });

      return;
    }

    this.selectedMachinesStepValue.machinesFullFormat.forEach((machine: VirtualMachine) => {
      this.VMHash[machine.virtualMachineId] = { ...machine, uiGuid: machine.virtualMachineId, existDisk: false, disks: [] };
    });
  }

  subscribeToUpdateTableAndBaseData(): void {
    this.needUpdateTableSubject$
      .pipe(
        switchMap(() => {
          const params: Partial<SelectHostStepValue> = this.selectedHostCredentials || {};
          const isParamsValid = params.server && params.login && params.password;

          this.buildVMHashesAndResetData();
          this.mainService.machinesDisksStatuses$.next({ voidMachines: [], allVoid: false, loadingDisks: true });

          if (this.selectedMachinesStepValue.type !== VirtualMachinesSelectedType.Selected || this.isOffline) {
            return of(this.isOffline ? { data: this.value.disks } : {});
          }

          return isParamsValid || this.isHyperV
            ? this.stepService.getRemoteCommandData(this.getRequestParams(), this.mainService.hid)
            : of({});
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (data) => this.updateDataAfterLoadDisks(data.data),
        error: () => this.mainService.machinesDisksStatuses$.next({ voidMachines: [], allVoid: true, loadingDisks: false })
      });
  }

  updateDataAfterLoadDisks(disks: VirtualDisk[]): void {
    if (disks.length) {
      const disksExcludes = {};

      this.stepForm.get('allDisks').value.forEach((disk) => (this.initialAllDisksHash[disk.uiGuid] = disk));
      this.stepForm.get('disks')?.value?.forEach((d) => (disksExcludes[d.uiGuid] = d.excludes));

      disks.forEach((d) => {
        d.uiGuid = d.uuid + d.virtualMachineId;
        d.excludes = disksExcludes[d.uiGuid] || [];
      });

      const sortedData = disks.sort((a, b) => (a.virtualMachineName > b.virtualMachineName ? 1 : 0));
      this.stepForm.get('allDisks').reset(sortedData || []);

      sortedData.forEach((disk) => {
        disk.diskCapacityStr = this.getSizeFromDiskCapacity(disk.diskCapacity);

        if (this.VMHash[disk.virtualMachineId]) {
          this.VMHash[disk.virtualMachineId].existDisk = true;
          this.VMHash[disk.virtualMachineId].disks.push(disk);
          this.availableDisks.push(disk);
        }
      });

      this.tableData = Object.values(this.VMHash);
      this.allMachinesHasDisks();
      this.updateFormAfterBuildTable();
      this.canUpdateSelectedDisks = true;

      return;
    }

    this.mainService.machinesDisksStatuses$.next({ voidMachines: [], allVoid: true, loadingDisks: false });
  }

  allMachinesHasDisks(): void {
    const machinesDisksStatuses: MachinesDisksLoadedStatuses = { voidMachines: [], allVoid: false, loadingDisks: false };
    this.tableData.forEach((machine) => !machine.existDisk && machinesDisksStatuses.voidMachines.push(machine.virtualMachineId));
    this.mainService.machinesDisksStatuses$.next(machinesDisksStatuses);
  }

  getSizeFromDiskCapacity(capacity: number): string {
    const kb = +(capacity / 1024).toFixed(2);
    if (kb < 1024) {
      return kb ? Math.ceil(kb * 10) / 10 + ' KB' : '';
    }

    const mb = +(kb / 1024).toFixed(2);
    if (mb < 1024) {
      return mb ? Math.ceil(mb * 10) / 10 + ' MB' : '';
    }

    const gb = +(mb / 1024).toFixed(2);
    if (gb < 1024) {
      return gb ? Math.ceil(gb * 10) / 10 + ' GB' : '';
    }

    const tb = +(gb / 1024).toFixed(2);
    return tb ? Math.ceil(tb * 10) / 10 + ' TB' : '';
  }

  updateFormAfterBuildTable(): void {
    const control = this.stepForm.get('type');

    if (!this.isOffline) {
      const notDisks = !this.value?.disks?.length;
      const disksLengthEqualAvailable = this.value?.disks?.length === this.availableDisks?.length;
      const notExcludes = !this.value?.disks?.some((d) => !!d.excludes?.length);
      const notFirstAndAll = !this.firstCallUpdateFormAfterBuildTable && control.value === VirtualMachinesSelectedType.All;
      const notExcludeNotFirstAndAllOrDiffLength = (notFirstAndAll || disksLengthEqualAvailable) && notExcludes;

      control.reset(
        control.value === VirtualDisksSelectedType.Selected && (notDisks || notExcludeNotFirstAndAllOrDiffLength)
          ? VirtualDisksSelectedType.All
          : control.value
      );
    }

    this.virtualDiskSelectedTypeChangeHandler(control.value);

    if (this.firstCallUpdateFormAfterBuildTable) this.firstCallUpdateFormAfterBuildTable = false;
  }

  virtualDiskSelectedTypeChangeHandler(selectedType: VirtualDisksSelectedType): void {
    if (selectedType === VirtualDisksSelectedType.All) {
      this.selectedDisks = this.tableData.reduce((acc, next) => {
        acc.push(next, ...next.disks);

        return acc;
      }, []);

      this.stepForm.get('disks').reset(this.availableDisks);

      return void this.updateTableValidity();
    }

    const machineIdArr = this.stepForm.get('disks')?.value.map((disk) => disk.virtualMachineId.toLowerCase());
    const diskUUIDArr = this.stepForm.get('disks')?.value.map((disk) => disk.uiGuid.toLowerCase());

    this.selectedDisks = this.tableData.reduce((acc, next) => {
      if (next.disks.every((d) => !this.initialAllDisksHash.hasDisk(d.uiGuid))) {
        acc.push(next, ...next.disks);

        return acc;
      }

      if (machineIdArr.includes(next.virtualMachineId.toLowerCase())) {
        const disks = next.disks.filter((d) => diskUUIDArr.includes(d.uiGuid.toLowerCase()) || !this.initialAllDisksHash.hasDisk(d.uiGuid));

        acc.push(next, ...disks);
      }

      return acc;
    }, []);

    this.stepForm.get('disks')?.reset(this.getDisksFromSelectedArray(this.selectedDisks));
    this.updateTableValidity();
  }

  diskSelectChangeHandler(selectedDisks: Array<ExtendedVirtualMachine & VirtualDisk>): void {
    if (this.canUpdateSelectedDisks && !isEqual(this.selectedDisks, selectedDisks)) {
      this.selectedDisks = selectedDisks;
      const newSelectedDisks: VirtualDisk[] = this.getDisksFromSelectedArray(selectedDisks);

      this.stepForm.reset({
        ...this.stepForm.value,
        disks: newSelectedDisks,
        type:
          newSelectedDisks.length < this.availableDisks.length || selectedDisks.some((disk) => !!disk.excludes?.length)
            ? VirtualDisksSelectedType.Selected
            : this.stepForm.value.type
      });
    }

    this.updateTableValidity();
    this.cdr.detectChanges();
  }

  excludeClickHandler(disk: VirtualDisk): void {
    if (this.isOffline) return;

    this.modalService
      .openCustom(TreeInModalComponent, this.getParamsForTreeModal(disk))
      .then((newExcludes: string[]) => this.updateExcludesAfterSelectInModal(disk, newExcludes))
      .catch(noop);
  }

  getParamsForTreeModal(disk: VirtualDisk): ModalSettings {
    const excludeParams = {
      title: this.i18nPipe.transform('wizards:exclude_items', { format: 'title' }),
      hid: this.mainService.hid,
      isVirtual: true,
      notCheckParentIfSelectAll: true,
      resultFolders: disk.excludes || [],
      params: {
        agentType: 'backup',
        commandType: 'GetVirtualMachineDiskContent',
        params: {
          VirtualMachineId: disk.virtualMachineId,
          DiskId: disk.id,
          path: null,
          offset: 0,
          limit: 120,
          Order: 'DisplayNameAsc',
          Search: null,
          Type: this.isVMWare ? VirtualMachinesType.VMWare : VirtualMachinesType.HyperV,
          Server: this.selectedHostCredentials?.server || '',
          Login: this.selectedHostCredentials?.login || '',
          Password: this.selectedHostCredentials?.password,
          PlanId: this.mainService.planId,
          IsCluster: false
        }
      },
      dataForPath: this.getValueForOperationsWithPath()
    };

    return { data: excludeParams, size: MbsSize.sm, collapsing: true };
  }

  updateExcludesAfterSelectInModal(disk: VirtualDisk, newExcludes: string[]): void {
    if (newExcludes) {
      const disks = this.stepForm.get('disks').value || [];
      const allDisks = this.stepForm.get('allDisks').value || [];
      const idx = disks.findIndex((d) => d.uuid === disk.uuid && d.id === disk.id && d.virtualMachineId === disk.virtualMachineId);
      const allIdx = allDisks.findIndex((d) => d.uuid === disk.uuid && d.id === disk.id && d.virtualMachineId === disk.virtualMachineId);
      const availableIdx = this.availableDisks.findIndex(
        (d) => d.uuid === disk.uuid && d.id === disk.id && d.virtualMachineId === disk.virtualMachineId
      );

      if (idx !== -1) disks[idx].excludes = newExcludes;
      if (allIdx !== -1) allDisks[allIdx].excludes = newExcludes;
      if (availableIdx !== -1) this.availableDisks[availableIdx].excludes = newExcludes;

      this.stepForm.get('disks').reset(disks || []);
      this.stepForm.get('allDisks').reset(allDisks || []);

      if (idx !== -1 && newExcludes.length) this.stepForm.get('type').reset(VirtualDisksSelectedType.Selected);
    }
  }

  needShowTag(computer: ExtendedVirtualMachine): boolean {
    return this.stepForm?.get('type')?.value === VirtualDisksSelectedType.Selected && computer.disks.some((disk) => disk.excludes?.length);
  }

  private getRequestParams(): ParamsForGetVirtualMachinesOrDisksList {
    const idArray = Object.keys(this.VMHash);

    return {
      agentType: AgentType.Backup,
      commandType: 'GetVirtualMachineDiskList',
      params: {
        PlanId: this.mainService.planId,
        VirtualMachineIds: idArray,
        Type: this.isVMWare ? 'VMWare' : 'HyperV',
        Server: this.selectedHostCredentials?.server,
        Login: this.selectedHostCredentials?.login,
        Password: this.selectedHostCredentials?.password,
        IsCluster: false
      }
    };
  }

  private getDisksFromSelectedArray(selectedDisks: Array<ExtendedVirtualMachine & VirtualDisk>): VirtualDisk[] {
    const newSelectedDisks: VirtualDisk[] = [];

    selectedDisks.forEach((diskOrComp) => {
      if (!diskOrComp.disks) newSelectedDisks.push(diskOrComp);
    });

    return newSelectedDisks;
  }

  /**
   * There are 2 reasons to make table invalid:
   *  - One or more machines failed to load their disks
   *  - One or more machines has not selected disks
   *  @return {void}
   */
  private updateTableValidity(): void {
    const finish = (valid) => {
      this.tableIsInvalid = valid;

      queueMicrotask(() => {
        this.stepForm.updateValueAndValidity();
      });
    };

    if (this.stepForm.get('type').value !== VirtualDisksSelectedType.Selected) {
      return void finish(false);
    }

    const hasFailedDisks = this.machinesDisksLoadedStatuses?.allVoid || this.machinesDisksLoadedStatuses?.voidMachines.length;

    if (hasFailedDisks) {
      return void finish(true);
    }

    const tableMachines: Array<String> = this.tableData.map((vm) => vm.virtualMachineId);
    const machinesFromSelectedDisks: Array<String> = this.stepForm.get('disks').value.map((disk) => disk.virtualMachineId);
    const hasEmptyMachine = tableMachines.some((vmId) => {
      const hasSelectedDisk = machinesFromSelectedDisks.includes(vmId);

      return !hasSelectedDisk;
    });

    finish(hasEmptyMachine);
  }
}
