import { Injectable } from '@angular/core';
import RmmCommand from '@models/rmm/RmmCommand';
import RmmHubResponse from '@models/rmm/RmmHubResponse';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { Store } from '@ngrx/store';
import { RmmService } from '@services/rmm.service';
import { isUndefined } from 'lodash/fp';
import { asapScheduler, scheduled } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TerminalActions } from './store';
import { TerminalTransfer } from './store/terminal.model';
import { createSessionId } from './terminal-emulator.util';

@Injectable({
  providedIn: 'root'
})
export class TerminalEmulatorService {
  constructor(private commandService: CommandService, private store: Store, private rmmService: RmmService) {
    this.commandService.messages$
      .pipe(
        map(this.commandService.parseMessage),
        filter((message: RmmHubResponse<TerminalTransfer>) => {
          return !isUndefined(message?.Data?.outType);
        })
      )
      .subscribe((message: RmmHubResponse<TerminalTransfer>) => {
        this.store.dispatch(TerminalActions.output({ asyncId: message.MessageId, transfer: message.Data }));
      });
  }

  init(hid: string, asyncId: string) {
    const command = RmmCommand.create('ShellInit', asyncId);
    return this.commandService.sendStrictCommandAsync('PowerShellTerminalCmd', command, hid, true);
  }

  getTimeOut(hid: string) {
    const command = RmmCommand.create('GetTimeOut', createSessionId({ hid }));

    return this.commandService.sendStrictCommandAsync('PowerShellTerminalCmd', command, hid, true);
  }

  setTimeout(hid: string, number: number) {
    const command = RmmCommand.create('SetTimeOut', createSessionId({ hid }));
    command.addParam('TIMEOUT', number);

    return this.commandService.sendStrictCommandAsync('PowerShellTerminalCmd', command, hid, true);
  }

  break(hid: string, asyncId: string) {
    const command = RmmCommand.create('RestartPowerShell', asyncId);
    return this.commandService.sendStrictCommandAsync('PowerShellTerminalCmd', command, hid, true);
  }

  send(value, hid, asyncId: string) {
    const command = RmmCommand.create('Script', asyncId);

    command.addParam('SCRIPT', value);
    command.addParam('TIMEOUT', null);
    command.addParam('INTERACTIVE', 'true');
    command.addParam('STOP', 'false');

    return this.commandService.sendStrictCommandAsync('PowerShellTerminalCmd', command, hid, true);
  }

  tokenExpired() {
    return scheduled(this.commandService.tokenExpired, asapScheduler);
  }

  checkDisconnected(hid: string) {
    return this.rmmService.onlineStatus(hid).pipe(map((status) => !status));
  }
}
