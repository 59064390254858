import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, NgModel, UntypedFormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { EnumConverter } from '@utils/emun';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { toByteArray } from 'base64-js';
import { isUndefined, uniq } from 'lodash/fp';
import { ModalComponent } from 'mbs-ui-kit';
import { EMPTY, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DEFAULT_TRANSFER_OBJECT, NEW_SCRIPT_HID } from '../../store/const';
import * as ScriptLibraryActions from '../../store/script-library.actions';
import {
  ScriptLibraryAccessLevel,
  ScriptLibraryEntry,
  ScriptLibraryEntryTypes,
  ScriptLibraryTransfer
} from '../../store/script-library.model';
import * as ScriptLibrarySelectors from '../../store/script-library.selectors';

const accessConverter = new EnumConverter<ScriptLibraryAccessLevel>(ScriptLibraryAccessLevel);
const typeConverter = new EnumConverter<ScriptLibraryEntryTypes>(ScriptLibraryEntryTypes);
@UntilDestroy()
@Component({
  selector: 'mbs-script-library-editor',
  templateUrl: './script-library-editor.component.html',
  styleUrls: ['./script-library-editor.component.scss']
})
export class ScriptLibraryEditorComponent {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  private _categories = new Set();
  private get id() {
    return this.form.get('id').value;
  }
  public get categories() {
    return Array.from(this._categories.values());
  }
  public scriptLabelText: string;

  public nonCategoryName = 'Uncategorized';

  public permission: string[] = [];
  public accessLevels: { key: string; value: ScriptLibraryAccessLevel }[];
  public form: UntypedFormGroup;
  public category: NgModel;
  public isCreateMode$: Observable<boolean>;
  public fileExtension: '' | 'ps1' = 'ps1';
  public uploadMode = false;
  public isBashScript = false;
  public isCreateMode = true;

  public showCreateCategoryForm = false;
  public showError = false;
  public newCategoryForm: UntypedFormGroup = new UntypedFormGroup({
    name: new FormControl('', [Validators.required])
  });

  private availableScriptTypes = [
    {
      type: ScriptLibraryEntryTypes.PowerShell,
      name: this.i18n.transform('rmm-script-library.module:createScriptModal.types.powershell')
    },
    {
      type: ScriptLibraryEntryTypes.Bash,
      name: this.i18n.transform('rmm-script-library.module:createScriptModal.types.bash')
    }
  ];
  public typeItems = this.availableScriptTypes.map((type) => type.name);

  constructor(private store: Store, private fb: FormBuilder, private ability: AbilityService, private i18n: I18NextPipe) {
    this.permission = uniq([
      this.ability.can('read', 'Rmm') ? ScriptLibraryAccessLevel.Private : null,
      this.ability.can('read', 'Provider') && this.ability.can('read', 'Rmm') ? ScriptLibraryAccessLevel.Public : null,
      this.ability.can('read', 'AccessToAllCompanies') && this.ability.can('read', 'Rmm') ? ScriptLibraryAccessLevel.Public : null,
      this.ability.can('read', 'SuperAdmin') ? ScriptLibraryAccessLevel.Common : null
    ]).filter(Boolean);

    this.accessLevels = accessConverter.toObjectArray().filter(({ value }) => this.permission.includes(value));

    this.form = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      type: [this.typeItems[0], Validators.required],
      isPublic: [true],
      accessLevel: [this.accessLevels[1]?.value || null],
      tags: ['', Validators.required],
      description: [''],
      body: ['', Validators.required]
    });

    this.form
      .get('type')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.isBashScript = value === this.typeItems[1];
        this.fileExtension = value === this.i18n.transform('rmm-script-library.module:createScriptModal.types.powershell') ? 'ps1' : '';
        this.setEnterScriptLabel(value);
      });

    this.setEnterScriptLabel(this.form.get('type').value);
    this.initStreams();
  }

  initStreams() {
    const tagsControl = this.form.get('tags');
    this.store.dispatch(ScriptLibraryActions.loadTags());

    tagsControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      tagsControl.patchValue(value.slice(value.length - 1), { onlySelf: true, emitEvent: false });
    });

    const id$ = this.store.select(ScriptLibrarySelectors.edit).pipe(filter(Boolean));

    this.isCreateMode$ = id$.pipe(map((id) => id == NEW_SCRIPT_HID));

    const data$: Observable<ScriptLibraryEntry> = id$.pipe(
      switchMap((id) => {
        return id !== NEW_SCRIPT_HID ? this.store.select(ScriptLibrarySelectors.selectEntry(id)) : EMPTY;
      })
    );

    id$
      .pipe(
        filter((id) => id !== NEW_SCRIPT_HID),
        untilDestroyed(this)
      )
      .subscribe((scriptGuid) => {
        this.isCreateMode = scriptGuid == NEW_SCRIPT_HID;
        this.store.dispatch(ScriptLibraryActions.loadScriptBody({ scriptGuid }));
      });

    this.store
      .select(ScriptLibrarySelectors.selectFilteredTags((tag) => this.permission.includes(tag.accessLevel)))
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((collection) => {
        if (collection.length) {
          const createdCategoryCollection = collection.filter((item) => item.name === this.newCategoryForm.get('name').value);
          const defaultCollection = collection.filter((item) => item.name === this.nonCategoryName);

          const patchCollection = createdCategoryCollection.length
            ? createdCategoryCollection[0]
            : defaultCollection.length
            ? defaultCollection[0]
            : collection[0];

          if (this.isCreateMode || createdCategoryCollection.length) this.form.get('tags').patchValue([patchCollection?.name]);
          this.newCategoryForm.get('name').setValue('', { emitEvent: false });
        }
        for (const tag of collection) {
          this._categories.add(tag.name);
        }
      });

    data$.pipe(untilDestroyed(this)).subscribe(this.fillForm);
  }

  fillForm = (data: ScriptLibraryEntry) => {
    const keys = Object.keys(this.form.value);

    for (const key of keys) {
      const control = this.form.get(key);
      let value;
      switch (key) {
        case 'tags':
          value = data[key].map((tag) => tag.name);
          break;
        case 'isPublic': {
          const converted = accessConverter.keyToValue(data['accessLevel']);
          value = isUndefined(converted) ? data['accessLevel'] : converted;
          // required to be in the next queue for switch component proper behaviour
          setTimeout(() => {
            this.form.get('isPublic').setValue(value === ScriptLibraryAccessLevel.Public, { emitEvent: true });
          }, 0);
          break;
        }
        case 'accessLevel': {
          const converted = accessConverter.keyToValue(data[key]);
          value = isUndefined(converted) ? data[key] : converted;
          control.disable();
          break;
        }
        case 'type': {
          const converted = typeConverter.keyToValue(data[key]);
          const scriptTypeName = this.availableScriptTypes.find((type) => type.type === converted)?.name;
          this.setEnterScriptLabel(scriptTypeName);
          value = isUndefined(scriptTypeName) ? data[key] : scriptTypeName;
          this.isBashScript = scriptTypeName === this.typeItems[1];
          break;
        }
        default:
          value = data[key];
      }

      control.patchValue(isUndefined(value) ? '' : value, { emitEvent: false, onlySelf: true });
    }
  };

  removeSelected(tag) {
    const control = this.form.get('tags');
    const update = control.value.filter((i) => i !== tag);

    control.patchValue(update);
  }

  addCategory(component: NgSelectComponent): void {
    this.showCreateCategoryForm = true;
    component.close();
  }

  handleSave() {
    this.store.dispatch(ScriptLibraryActions.updateScript({ id: this.id, transfer: this.createTransfer() }));
    this.baseModal.close();
  }

  handleAdd() {
    this.store.dispatch(ScriptLibraryActions.registerScript({ transfer: this.createTransfer() }));
    this.baseModal.close();
  }

  fileLoadHandler(file) {
    const fileControl = this.form.get('body');
    const nameControl = this.form.get('name');
    let base64 = file.base64;

    const [fileName, _fileType] = (file?.file?.name || '').split(/(\.\w+$)/);

    const index = base64.indexOf(',');

    base64 = base64.slice(index + 1);
    const byteArray = toByteArray(base64);
    const body = new TextDecoder().decode(byteArray);

    if (!nameControl.value) {
      nameControl.patchValue(fileName || '');
    }
    fileControl.patchValue(body);
    this.uploadMode = false;
  }

  ngOnDestroy(): void {
    this.store.dispatch(ScriptLibraryActions.callEditor({ id: null }));
  }

  createTransfer(): ScriptLibraryTransfer {
    const type = this.availableScriptTypes.find((type) => type.name === this.form.get('type').value).type;

    const payload: Partial<ScriptLibraryTransfer> = {
      name: this.form.value.name,
      accessLevel: this.form.get('isPublic').value ? ScriptLibraryAccessLevel.Public : ScriptLibraryAccessLevel.Private,
      tags: this.form.value.tags,
      description: this.form.value.description,
      body: this.form.value.body
    };

    return { ...payload, ...DEFAULT_TRANSFER_OBJECT, type } as ScriptLibraryTransfer;
  }

  private setEnterScriptLabel(value: string): void {
    if (!value) return;
    this.scriptLabelText = this.i18n.transform('rmm-script-library.module:createScriptModal.enterScriptLabel', {
      value: this.availableScriptTypes.find((type) => type.name === value)?.name
    });
  }

  public setScriptType(bash = false): void {
    this.form.get('type').setValue(bash ? this.typeItems[1] : this.typeItems[0]);
  }

  public setUploadMode(value: boolean): void {
    this.uploadMode = value;
  }

  public createCategory(): void {
    this.showError = false;
    const categoryName = this.newCategoryForm.get('name').value;

    if (!categoryName) return;

    if (this.categories.some((name: string) => name?.toLowerCase() === categoryName?.toLowerCase())) {
      this.showError = true;
      this.newCategoryForm.controls['name'].setErrors({ notUnique: true });
    } else {
      this.store.dispatch(ScriptLibraryActions.registerTag({ tagName: categoryName }));
      this.closeCreateCategoryForm();
    }
  }

  public closeCreateCategoryForm(): void {
    this.showCreateCategoryForm = false;
  }
}
