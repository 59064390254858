import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { SentryErrorHandler } from './error-handler.service';

@NgModule()
export class ErrorHandlerModule {
  constructor() {}

  static forRoot(): ModuleWithProviders<ErrorHandlerModule> {
    return {
      ngModule: ErrorHandlerModule,
      providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }]
    };
  }
}
