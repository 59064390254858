import { APP_BASE_HREF } from '@angular/common';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { subscribePrice } from '@components/licenses/constants/price';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { environment } from '@mbs-ui/environments/environment';
import Administrator from '@models/Administrator';
import { PassCheckType, Permission, rulesType, TempTokenData } from '@models/auth-models';
import BrandingSetting from '@models/BrandingSetting';
import FeedBackCredential from '@models/FeedBackCredential';
import { JwtToken } from '@models/JwtToken';
import OAuthCredential from '@models/OAuthCredential';
import OAuthData from '@models/OAuthData';
import { ExtendedOnlineAccessInfo, OnlineAccessInfo } from '@models/OnlineAccess';
import { OneTimeAuthToken } from '@models/support-portal/one-time-token.model';
import TwoFactorComputers from '@models/TwoFactorComputers';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import * as AuthStoreActions from '@store/auth/actions';
import { convertToClientUrl } from '@utils/pipes/client-url.pipe';
import { AbilityService, Action, Rule } from 'ability';
import jwtDecode from 'jwt-decode';
import { cloneDeep, noop } from 'lodash';
import { BehaviorSubject, combineLatest, defer, EMPTY, first, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, share, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppPersistentStateService } from './app-persistent-state.service';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly mbsAuthUrlPrefix = 'api/auth/';
  private readonly mbsProviderUrlPrefix = 'api/provider/';
  private readonly mbsStageUrlPrefix = 'api/stage/';
  private readonly onlineAccessAuthUrlPrefix = 'api/auth/';

  private myLoaded$ = new BehaviorSubject<boolean>(false);
  public loaded$ = this.myLoaded$.asObservable();

  private tokenLoading$ = new BehaviorSubject<boolean>(false);
  private meLoading$ = new BehaviorSubject<boolean>(false);
  private loginLoading$ = new BehaviorSubject<boolean>(false);

  public loading$ = combineLatest([
    this.tokenLoading$.asObservable(),
    this.meLoading$.asObservable(),
    this.loginLoading$.asObservable()
  ]).pipe(map(([tokenLoading, meLoading, loginLoading]) => tokenLoading || meLoading || loginLoading));

  private myCurrentUser = new BehaviorSubject<Administrator>(undefined);
  private myCurrentBrandingSetting = new BehaviorSubject<BrandingSetting>(undefined);

  private oAuthCredential: BehaviorSubject<OAuthCredential>;

  private onlineAccessInfo$: Observable<ExtendedOnlineAccessInfo>;

  public get authToken(): string {
    const tokenData = this.oAuthCredential ? this.oAuthCredential.value : undefined;

    return tokenData ? tokenData.token_type + ' ' + tokenData.access_token : '';
  }

  public get accessToken(): string {
    return this.oAuthCredential && this.oAuthCredential.value ? this.oAuthCredential.value.access_token : undefined;
  }

  public get decodedToken(): JwtToken {
    return new JwtToken(jwtDecode(this.accessToken));
  }

  private myTwoFAConfirmed = new Subject<boolean>();
  public twoFAConfirmed = this.myTwoFAConfirmed.asObservable();
  public authError: HttpErrorResponse;

  /**
   * Wrapper for use in interceptor
   */
  public readonly tryRefreshToken$: Observable<boolean>;

  get currentUser(): Observable<Administrator> {
    return this.myCurrentUser.asObservable();
  }
  get currentUserValue(): Administrator {
    return this.myCurrentUser.value;
  }

  get subscriptionUrl(): string {
    return `/Admin/payment.aspx?type=Subscription&OwnerID=${this.currentUserValue.ProviderInfo?.Id}&customPrice=${subscribePrice}&InvoiceID=`;
  }

  get BrandingSetting(): Observable<BrandingSetting> {
    return this.myCurrentBrandingSetting.asObservable();
  }

  private readonly fetchUser$: Observable<Administrator> = of(true).pipe(
    tap(() => this.meLoading$.next(true)),
    switchMap(() => this.http.get<Administrator & { BrandingSetting: BrandingSetting }>(`${this.mbsAuthUrlPrefix}me`)),
    catchError((error) => {
      this.authError = error;
      this.isMBSMode && this.router.navigate(['AS'], { state: error });

      return of(null);
    }),
    switchMap((res) => {
      const user: Administrator = Object.assign(new Administrator(), res);
      this.appPersistent.userId$.emit(user.Id);
      user.Permissions && this.handlePermissions(user);
      // emit next user ONLY after the handlePermission operation
      // there are some places where permissions are updated based on some additional parameters
      // it shouldn't be updated after that here
      this.myCurrentUser.next(user);
      res?.BrandingSetting && this.myCurrentBrandingSetting.next(res.BrandingSetting);
      this.appPersistent.data.userId = user.Id;
      this.appPersistent.data.signUpDate = user.SignUpDate;

      try {
        // Add a user data for Sentry
        Sentry.configureScope(function (scope) {
          const ownerId = user.ProviderInfo?.Id;
          scope.setUser({
            username: user.FirstName + user.LastName,
            email: user.Email,
            id: user.Id,
            ownerId
          });
          scope.setExtra('Provider', user?.IsProvider);
          scope.setExtra('Super Admin', user.IsSuperAdmin);
          scope.setExtra('Read Only', user.IsReadOnly);
          scope.setTag('user.ownerId', ownerId);
        });
      } catch (err) {
        noop;
      }
      return of(user);
    }),
    finalize(() => {
      this.myLoaded$.next(true);
      this.meLoading$.next(false);
    }),
    share()
  );

  public readonly getAuthTokenBySession$ = new HttpClient(this.httpBackend)
    .get<OAuthCredential>(`${location.origin}/${this.mbsAuthUrlPrefix}token`)
    .pipe(
      tap((res) => this.oAuthCredential.next(res)),
      share()
    );

  constructor(
    protected http: HttpClient,
    protected httpBackend: HttpBackend, // for request without interceptors
    protected injector: Injector,
    protected appPersistent: AppPersistentStateService,
    protected config: ConfigurationService,
    private router: Router,
    @Inject(APP_BASE_HREF) private appBaseHref: string,
    private abilityService: AbilityService,
    private store: Store
  ) {
    const credsKey = 'tokenData';
    const creds: OAuthCredential = JSON.parse(localStorage.getItem(credsKey));
    this.oAuthCredential = new BehaviorSubject(creds);
    this.oAuthCredential.subscribe((tokenData) => {
      if (tokenData) {
        localStorage.setItem(credsKey, JSON.stringify(tokenData));
      } else {
        localStorage.removeItem(credsKey);
      }
    });

    this.tryRefreshToken$ = defer(() => of(this.oAuthCredential.getValue())).pipe(
      switchMap((creds) =>
        creds
          ? this.refreshToken().pipe(
              map(() => true),
              catchError(() => of(false))
            )
          : of(false)
      ),
      share()
    );

    this.appPersistent.change.subscribe((state) => {
      if (this.myCurrentUser.value && state.userId !== this.myCurrentUser.value.Id) {
        if (!environment.production) {
          this.clearSessionData();
        }
        // TODO NESTED SUBSCRIPTIONS
        this.fetchCurrentUser().subscribe();
      }
    });
  }

  fetchCurrentUser(): Observable<Administrator> {
    return this.fetchUser$;
  }

  /*
   * Internal login form
   */
  loginUser({ email, password }): Observable<Administrator> {
    return this.isMBSMode ? this.loginMBSUser({ email, password }) : this.loginOnlineAccessUser({ username: email, password });
  }

  private loginMBSUser({ email, password }): Observable<Administrator> {
    return this.getAuthIdentity(
      new OAuthData({
        grant_type: 'password',
        username: email,
        password: password
      })
    ).pipe(
      switchMap(() => {
        this.loginLoading$.next(true);

        return this.http
          .post(`${this.mbsStageUrlPrefix}login/auth`, {
            login: email,
            password
          })
          .pipe(finalize(() => this.loginLoading$.next(false)));
      }),
      switchMap(() => this.fetchCurrentUser())
    );
  }

  logoutUser(ref?: string): void {
    // otherwise cyclic dependency
    const router = this.injector.get(Router);
    if (environment.production && this.isMBSMode) {
      setTimeout(() => this.clearSessionData(), 500);
      const baseHref = this.injector.get(APP_BASE_HREF);
      const lastPage = router.url.replace('/AP/', '');
      // added ref on last page for return after authorization
      location.replace(convertToClientUrl(`/Admin/Login.aspx?logoff=1&ref=${ref || lastPage}`, baseHref));
    } else {
      this.router.navigate(['login']).then(() => {
        this.clearSessionData();
        this.store.dispatch(AuthStoreActions.logout());
      });
    }
  }

  /*
   * Get a new access_token by refresh_token
   */
  refreshToken(): Observable<OAuthCredential> {
    // try get access_token from session for external services
    if (environment.useSessionToken) {
      return this.getAuthTokenBySession$;
    }
    return this.isMBSMode
      ? this.getAuthIdentity(
          new OAuthData({
            grant_type: 'refresh_token',
            refresh_token: this.oAuthCredential.value ? this.oAuthCredential.value.refresh_token : ''
          })
        )
      : this.refreshOnlineAccessToken();
  }

  public getAppsToken(request: { domain: string }): Observable<{ access_token: string }> {
    return this.http.post<{ access_token: string }>(`${this.mbsAuthUrlPrefix}new-token-scope/appsbackup-access`, request);
  }

  private getAuthIdentity(options: OAuthData): Observable<OAuthCredential> {
    const url = 'connect/token';
    const formData = new FormData();
    for (const key of Object.keys(options)) {
      formData.append(key, options[key]);
    }

    this.tokenLoading$.next(true);
    return this.http.post<OAuthCredential>(url, formData).pipe(
      tap((res) => this.oAuthCredential.next(res)),
      finalize(() => this.tokenLoading$.next(false))
    );
  }

  public clearSessionData(): void {
    this.oAuthCredential.next(null);
    this.abilityService.update([]);
    this.myCurrentUser.next(null);
    this.myCurrentBrandingSetting.next(null);
    this.myLoaded$.next(false);
  }

  private handlePermissions(user: Administrator): void {
    const preparedPermissions = this.preparePermissions(user.Permissions as Permission[]);

    Object.assign(user, { Permissions: preparedPermissions });

    const rules: rulesType[] = Object.keys(user.Permissions)
      .map((key) => {
        const actions: Action = 'read';
        return { actions, subject: key, access: user.Permissions[key] };
      })
      .filter((p) => p.access);

    if (user?.IsProvider) {
      rules.push({ actions: 'read', subject: 'Provider' });
    } else {
      rules.push({ actions: 'read', subject: 'SubAdmin' });
    }

    if (user.IsSuperAdmin) {
      rules.push({ actions: ['create', 'update', 'delete', 'read', 'save'], subject: 'SuperAdmin' });
    }

    if (user.Is2FAEnabled) {
      rules.push({ actions: ['create', 'update', 'delete', 'read', 'save'], subject: '2FAEnabled' });
    }

    rules.push({
      actions: ['create', 'update', 'delete', 'read', 'save'],
      subject: 'Readonly',
      inverted: !user.IsReadOnly
    });

    rules.push(...this.extendedPermission(user));
    this.abilityService.update(rules);
  }

  private extendedPermission(user: Administrator): Rule[] {
    const rules: Rule[] = [];

    const crud: Array<Action> = ['create', 'update', 'delete'];

    rules.push(
      { actions: 'read', subject: 'MBS' },
      { actions: 'create', subject: ['Administrator', 'AdministratorInCamelCase'] },
      {
        actions: ['update', 'delete'],
        subject: ['Administrator', 'AdministratorInCamelCase'],
        conditions: { Id: (value) => value !== user.Id }
      },
      {
        actions: 'update',
        subject: ['Administrator', 'AdministratorInCamelCase'],
        conditions: { Id: (value) => value === user.Id },
        fields: ['FirstName', 'LastName', 'Email', 'Password']
      }
      // { actions: ['read', 'update', 'hide'], subject: 'RemoteManagement' }
    );
    if (user?.IsProvider) {
      rules.push({ actions: crud, subject: 'Company' });
      rules.push({ actions: crud, subject: 'CustomCertificatesBinding' });
    } else {
      rules.push({ actions: ['update', 'delete'], subject: 'Company' });
    }

    return rules;
  }

  private preparePermissions(permissions: Permission[]): Permissions {
    const objPermissions = {};
    permissions?.length &&
      permissions.forEach((permission) => {
        //  remove role from name, need to discuss for change permissions name.
        const permissionName = permission.Name.split(':')[1];

        objPermissions[permissionName] = permission.Allow;
      });
    return objPermissions as Permissions;
  }

  setCompleteGettingStartWizard(): void {
    const copyUser = cloneDeep(this.myCurrentUser.value);
    copyUser.IsWizardComplete = true;
    this.myCurrentUser.next(copyUser);
  }

  requestPermissions(payload: TwoFactorComputers): Observable<boolean> {
    const result$ = new Subject<boolean>();
    this.http.post<OAuthCredential>(`${this.mbsAuthUrlPrefix}new-token-scope/mbs-rm-access`, payload).subscribe(
      (res) => {
        const payload = Object.assign({}, this.oAuthCredential.value, { access_token: res.access_token });
        this.oAuthCredential.next(payload);
        result$.next(true);
        this.myTwoFAConfirmed.next(true);
      },
      (err) => {
        result$.error(err);
      },
      () => result$.complete()
    );

    return result$.asObservable();
  }

  cancelRequestPermission(): void {
    this.myTwoFAConfirmed.next(false);
  }

  getTempToken(userId: string, hid = null): Observable<TempTokenData> {
    const data: { tokenTarget: string; computerHid?: string } = { tokenTarget: 'TempInstance' };
    if (hid) {
      data.tokenTarget = 'OneTime';
      data.computerHid = hid;
    }
    return this.http.post<TempTokenData>(this.isMBSMode ? `api/users/${userId}/temptoken` : `api/computers/${hid}/temptoken`, data);
  }

  public getFeedBackToken() {
    return this.http.get<FeedBackCredential>(`${this.mbsAuthUrlPrefix}new-token-scope/feedback-access`);
  }

  public checkPasswordStrength(password: string): Observable<PassCheckType> {
    return this.http.post<PassCheckType>(`${this.mbsAuthUrlPrefix}passcheck`, { password });
  }

  public getPasswordRules(): Observable<string[]> {
    return this.http.get<string[]>(`${this.mbsAuthUrlPrefix}getpassrules`);
  }

  public changeEmail(value: { email: string; password: string }) {
    return this.http.put(`${this.mbsProviderUrlPrefix}email`, value);
  }

  public getOneTimeAuthToken(): Observable<OneTimeAuthToken> {
    return this.http.get<OneTimeAuthToken>(`${this.mbsAuthUrlPrefix}onetimetoken`);
  }

  // Online Access part

  public getOnlineAccessHref(): string {
    return this.config.get('onlineAccessHref');
  }

  public getOnlineAccessKey(): string {
    return this.config.get('onlineAccessKey');
  }

  public get isMBSMode(): boolean {
    return !this.getOnlineAccessKey();
  }

  public get getOnlineAccessInfo$(): Observable<ExtendedOnlineAccessInfo> {
    if (this.onlineAccessInfo$) return this.onlineAccessInfo$;

    const isLocalHostHostName = window.location.hostname.startsWith('localhost');
    let onlineAccessHref = isLocalHostHostName ? this.getOnlineAccessKey() : this.getOnlineAccessHref();
    let params = {};

    if (!isLocalHostHostName && !this.isValidOnlineAccessHref(onlineAccessHref)) return EMPTY;

    if (isLocalHostHostName) {
      const isAliasMode = !onlineAccessHref.startsWith('http');

      if (!isAliasMode) {
        onlineAccessHref = onlineAccessHref.replace('http://', '').replace('https://', '');
        onlineAccessHref = onlineAccessHref.endsWith('/') ? onlineAccessHref.slice(0, -1) : onlineAccessHref;
      }

      params = isAliasMode ? { alias: onlineAccessHref } : { customDns: onlineAccessHref };
    } else {
      const notRootFolder = (this.appBaseHref ?? '').split('/').length > 2;
      const href = `${location.origin}${location.pathname}`;
      const onlineAccessHrefBased = href.startsWith(onlineAccessHref);
      const alias = onlineAccessHrefBased ? href.replace(onlineAccessHref, '').split('/')[0] : '';
      const isAliasMode = notRootFolder && !!alias;

      params = isAliasMode ? { alias } : { customDns: window.location.hostname };
    }

    this.onlineAccessInfo$ = this.http.get<OnlineAccessInfo>(`${this.onlineAccessAuthUrlPrefix}info`, { params }).pipe(
      map((info) => ({ info, params })),
      shareReplay()
    );

    return this.onlineAccessInfo$;
  }

  private isValidOnlineAccessHref(href: string): boolean {
    if (!href) {
      console.log(`Incorrect Online Access configuration. Please check onlineAccessHref parameter`);
      return false;
    }
    if (!href.endsWith('/')) {
      console.log(`Incorrect Online Access configuration. Please add '/' at the end of onlineAccessHref parameter`);
      return false;
    }
    if (href.split('/').length < 5) {
      console.log(
        `Incorrect Online Access configuration. Please check onlineAccessHref parameter includes at least one folder after specifying the domain`
      );
      return false;
    }

    return true;
  }

  /*
   * Internal login form for Online Access
   */
  loginOnlineAccessUser({ username, password }): Observable<Administrator> {
    const request = (info: ExtendedOnlineAccessInfo): Observable<Administrator> => {
      return this.http
        .post<OAuthCredential>(`${this.onlineAccessAuthUrlPrefix}login`, {
          username,
          password,
          ...info.params
        })
        .pipe(
          tap((res) => this.oAuthCredential.next(res)),
          switchMap(() => this.fetchCurrentUser())
        );
    };

    this.loginLoading$.next(true);
    return this.getOnlineAccessInfo$.pipe(
      // take(1),
      switchMap((info) => request(info)),
      finalize(() => this.loginLoading$.next(false))
    );
  }

  /*
   * Get a new access_token for Online Access by refresh_token
   */
  refreshOnlineAccessToken(): Observable<OAuthCredential> {
    // try get access_token from session for external services
    if (environment.useSessionToken) {
      return this.getAuthTokenBySession$;
    }
    return this.http
      .post<OAuthCredential>(`${this.onlineAccessAuthUrlPrefix}login/refresh`, {
        refreshToken: this.oAuthCredential.value?.refresh_token ?? ''
      })
      .pipe(tap((res) => this.oAuthCredential.next(res)));
  }

  generatePassword(): Observable<string> {
    return this.http.get<string>(`${this.mbsAuthUrlPrefix}passgenerate`);
  }

  redirectToSupportPortal(): void {
    const returnUrl = `${location.origin}${RoutingPath.ApComputers}`;

    this.getOneTimeAuthToken().subscribe({
      next: (res: OneTimeAuthToken) => {
        const url = `${this.config.get('supportUrl')}?OneTimeAuthToken=${encodeURIComponent(res.access_token)}&MbsReturnUrl=${returnUrl}`;

        window.open(url, '_blank');
      }
    });
  }

  allowOfflineEdit(state = true): Observable<null> {
    return this.http
      .put<null>(`${this.mbsAuthUrlPrefix}offlineedit`, { allowOfflineEdit: state })
      .pipe(tap(() => this.fetchCurrentUser().pipe(first()).subscribe()));
  }
}
