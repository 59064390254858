<mbs-modal [title]="'Change Minimum Storage Retention'">
  <ng-container modal-body>
    <p>
      Specify the minimum storage retention period based on your storage account settings<br />
      <a *ngIf="ability.can('read', 'HelpMarketing')"
         href="https://help.mspbackups.com/billing-storage/storage-providers/wasabi/min-retention-policy" target="_blank">
        {{'buttons:learnMore' | i18next}}
      </a>
    </p>
    <mbs-alert [type]="mbsPopupType.warning"
               [icon]="true">
      The minimum storage retention period is set on Wasabi side: to change it, contact Wasabi support team
    </mbs-alert>
    <mbs-form-group class="mb-0">
      <mbs-form-group>
        <mbs-radio name="periodVariants"
                   [disabled]="loading"
                   [label]="'30 days'"
                   [value]="periodVariants.WasabiSmall"
                   [(ngModel)]="duration"
                   [id]="'wasabi-small-radio'"></mbs-radio>
      </mbs-form-group>
      <mbs-radio name="periodVariants"
                 [disabled]="loading"
                 [label]="'90 days (default)'"
                 [value]="periodVariants.WasabiBig"
                 [(ngModel)]="duration"
                 [id]="'wasabi-big-radio'"></mbs-radio>
    </mbs-form-group>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button [type]="'primary'"
                class="mr-2"
                [loading]="loading"
                (click)="selectClickHandler()">Save</mbs-button>
    <mbs-button [type]="'secondary'"
                [loading]="loading"
                (click)="cancelClickHandler()">Cancel</mbs-button>
  </ng-container>
</mbs-modal>
