<mbs-wizards-installation-step [buildsSelectionConfig]="buildsSelectionConfig"
                               (hasError)="onboardingService.hasError$.next($event)"
                               (stable)="onboardingService.stable$.next($event)"
                               [view]="installationView"></mbs-wizards-installation-step>

<ng-container *ngIf="(onboardingService.stable$ | async)">
  <div class="mt-5 d-flex justify-content-end">
    <mbs-button [type]="'success'"
                [size]="'lg'"
                (click)="finishWizard()"> {{'onboarding:buttons.finish' | i18next: {
      format: 'title'
    } }} </mbs-button>
  </div>
</ng-container>
