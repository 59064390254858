<mbs-modal class="ph-no-capture"
           [title]="modalTitle"
           [loading]='loading'>

  <ng-container modal-body>
    <mbs-alert *ngIf="!data || !data.length"
               [icon]="true"
               [type]="alertType.info">
      {{noComputersText}}
    </mbs-alert>

    <ng-container *ngIf="applyTo === applyToEnum.administrators && data && data.length">
      <mbs-list-select [data]="data"
                       bindSelected="id"
                       [showSearch]="true"
                       [showCheckboxes]="true"
                       [showTopControls]="true"
                       [topControlsLabelSelected]="true"
                       [selectedData]="selectedAdministratorsIds"
                       (changeSelected)="changeSelectedAdminsHandler($event)"
                       [keepState]="true"
                       [loadSource]="false"
                       filterFields="title"
                       placeholder="Email"
                       [selectRowClass]="'-selected'"
                       [headers]="headers">
        <ng-template appendTopControlsLabel>
          {{ selectedAdministratorsIds.length === 1 ? ('wizards:tryRmm:modal_pick_admin' | i18next) :
          ('wizards:tryRmm:modal_pick_admins' | i18next) }}
        </ng-template>
        <ng-template prependTopControlsLabel>
          <strong>{{'wizards:tryRmm:selected' | i18next }}</strong>
        </ng-template>

        <ng-template mbsTableCell
                     let-admin>
          {{ admin.title }}
        </ng-template>
      </mbs-list-select>
    </ng-container>

    <ng-container *ngIf="applyTo === applyToEnum.companies && data && data.length">
      <mbs-list-select [data]="data"
                       bindSelected="id"
                       [showSearch]="true"
                       [showCheckboxes]="true"
                       [showTopControls]="true"
                       [topControlsLabelSelected]="true"
                       [selectedData]="selectedCompaniesIds"
                       (changeSelected)="changeSelectedCompaniesHandler($event)"
                       [keepState]="true"
                       [loadSource]="false"
                       filterFields="title"
                       placeholder="Company Name"
                       [selectRowClass]="'-selected'"
                       [headers]="headers">
        <ng-template appendTopControlsLabel>
          {{ selectedCompaniesIds.length === 1 ? ('wizards:tryRmm:modal_pick_company' | i18next) :
          ('wizards:tryRmm:modal_pick_companies' | i18next) }}
        </ng-template>
        <ng-template prependTopControlsLabel>
          <strong>{{'wizards:tryRmm:selected' | i18next }}</strong>
        </ng-template>

        <ng-template mbsTableCell
                     let-companyGroup>
          {{ companyGroup.title }}
        </ng-template>
      </mbs-list-select>
    </ng-container>

    <ng-container *ngIf="applyTo === applyToEnum.computers">
      <ng-container *ngIf="computerSearchWereActivated || data?.length">
        <mbs-form-group [formGroup]="computerSearchForm">
          <mbs-smart-search [data]="searchTemplates"
                            (search)="updateFilters($event)"
                            [accessUrlQuery]='false'
                            formControlName="search">
          </mbs-smart-search>
        </mbs-form-group>
      </ng-container>

      <div *ngIf="data?.length">
        <mbs-accordion size="small"
                       [checkboxes]="true"
                       [showTopControls]="true"
                       [topControlsLabel]="true"
                       [activeIds]='activeIds'
                       (changeTop)="handleTopChange($event)"
                       (change)="checkChanged($event)"
                       (changeHeader)="headerCheckChanged($event)"
                       [topControlsLabelSelected]="true"
                       (changeTotalSelectedCount)="handleSelectedCountAll($event)">
          <ng-template topControlsPrependLabel>
            <strong>{{'wizards:tryRmm:selected' | i18next }}</strong>
          </ng-template>
          <ng-template topControlsAppendLabel>
            {{ selectedComputersCount === 1 ? ('wizards:tryRmm:modal_pick_computer' | i18next) :
            ('wizards:tryRmm:modal_pick_computers' | i18next) }}
          </ng-template>
          <mbs-accordion-panel *ngFor="let group of data; let j = index"
                               [id]="selectComputersAccordionPanelIdPrefix + group.id"
                               [rows]="checkboxData[j]"
                               [fullWidthLabel]="true"
                               [itemsCanBeHidden]='true'
                               [hideCheckboxes]="false"
                               [disabled]='false'
                               [keepOpened]='false'
                               [title]="group.title"
                               [shown]="group.selectedItems?.length > 0 || group.shown">
            <ng-template let-computer
                         rowTemplate>
              <mbs-text-ellipsis [tooltip]="getCompName(computer)">
                {{ getCompName(computer) }}
              </mbs-text-ellipsis>
            </ng-template>
          </mbs-accordion-panel>
        </mbs-accordion>
      </div>
    </ng-container>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button *ngIf="!voidComputersList"
                [type]="'primary'"
                (click)="save()">
      {{ saveButtonText }}
      <!-- @TODO submit form -->
    </mbs-button>
    <mbs-button [type]="'secondary'"
                data-dismiss="modal"
                (click)="close()">
      {{ voidComputersList ? ('wizards:tryRmm:modal_close_btn' | i18next) : 'wizards:tryRmm:modal_cancel_btn' | i18next }}
    </mbs-button>
  </ng-container>

</mbs-modal>
