<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>EC2</h4>
  </header>

  <h5 class="mt-0">Temporary S3 storage and target EC2 instance account:</h5>
  <div class="mbs-form-group">
    <mbs-select formControlName="account"
                [appendTo]="'body'"
                [bindValue]="'value'"
                [bindLabel]="'label'"
                [label]="'Select Account'"
                [clearable]="false"
                [id]="'EC2-account-select'"
                [items]="EC2Data.accounts"></mbs-select>
    <mbs-alert class="mb-0 mt-3"
               [type]="mbsPopupType.info"
               [icon]="true">
      We strongly recommend you to use our <a href="/AP/PolicyDesigner.aspx" target="_blank">policy designer</a> to tune required permissions for the specified account.
    </mbs-alert>
  </div>
  <h5 class="mt-3">Target EC2 Instance Details</h5>
  <ng-container *ngIf="restoreAs === restoreAsDiskEnum.EBSVolume">
    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="region"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Region'"
                      [clearable]="false"
                      [id]="'EC2-region-select'"
                      [items]="EC2Data.regions"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-select formControlName="availabilityZones"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Availability Zones'"
                      [clearable]="false"
                      [id]="'EC2-availability-zones-select'"
                      [items]="EC2Data.zones"></mbs-select>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="restoreAs === restoreAsDiskEnum.EC2">
    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="region"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Region'"
                      [clearable]="false"
                      [id]="'EC2-region-select'"
                      [items]="EC2Data.regions"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-select formControlName="instanceType"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Instance Type'"
                      [clearable]="false"
                      [id]="'EC2-instance-type-select'"
                      [items]="EC2Data.types"></mbs-select>
        </div>
      </div>
    </div>
    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="subnet"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Subnet'"
                      [clearable]="false"
                      [id]="'EC2-subnet-select'"
                      [items]="EC2Data.subnetItems"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-select formControlName="securityGroup"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Security Group'"
                      [clearable]="false"
                      [id]="'EC2-security-group-select'"
                      [items]="EC2Data.securityGroups"></mbs-select>
        </div>
      </div>
    </div>
  </ng-container>
</section>
