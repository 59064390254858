import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GroupTasksService } from '@services/group-tasks.service';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { ToastService } from 'mbs-ui-kit';
import { catchError, concatMap, map, mergeMap, of, toArray, withLatestFrom } from 'rxjs';
import * as GroupTasksActions from './actions';
import { GroupTask, GroupTaskFiltersPayload } from './model';
import * as GroupTaskSelectors from './selectors';

@Injectable()
export class GroupTasksEffects {
  loadDataFromAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupTasksActions.loadGroupTasks),
      concatMap(({ params }) => {
        return this.groupTasksService.getGroupTasks(params ? { ...params } : this.httpParams).pipe(
          concatMap((result) => {
            if (result) {
              return of(GroupTasksActions.loadGroupTasksSuccess({ data: result }));
            } else {
              this.toastService.warn(this.i18nPipe.transform(this.errorMessage));
              return of(GroupTasksActions.loadGroupTasksError({ error: this.i18nPipe.transform(this.errorMessage) }));
            }
          }),
          catchError((error) => of(GroupTasksActions.loadGroupTasksError({ error: error })))
        );
      })
    );
  });

  loadGroupTaskByGuid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupTasksActions.loadGroupTask),
      withLatestFrom(this.store.select(GroupTaskSelectors.selectLastGroupTasksResponse)),
      concatMap(([groupTask, storeData]) => {
        return this.groupTasksService.getGroupTask(groupTask.guid).pipe(
          concatMap((result) => {
            if (result) {
              const newResult = {
                data: this.getNewDataList(cloneDeep(storeData.data), result),
                registeredTask: result,
                taskGuidToEdit: result.groupTaskGuid,
                dataCount: storeData.dataCount,
                pageCount: storeData.pageCount,
                pageNumber: storeData.pageNumber,
                pageSize: storeData.pageSize
              };
              return of(GroupTasksActions.loadGroupTasksSuccess({ data: newResult }));
            } else {
              this.toastService.warn(this.i18nPipe.transform(this.errorMessage));
              return of(GroupTasksActions.loadGroupTasksError({ error: this.i18nPipe.transform(this.errorMessage) }));
            }
          }),
          catchError((error) => of(GroupTasksActions.loadGroupTasksError({ error: error })))
        );
      })
    );
  });

  deleteGroupTaskByGuid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupTasksActions.deleteGroupTask),
      concatMap(({ guid, params }) => {
        return this.groupTasksService.deleteGroupTask(guid).pipe(
          concatMap((_result, _params) => {
            this.toastService.success(this.i18nPipe.transform(this.deleteMessage));
            return of(GroupTasksActions.loadGroupTasks({ params }));
          }),
          catchError((error) => of(GroupTasksActions.loadGroupTasksError({ error: error })))
        );
      })
    );
  });

  registerGroupTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupTasksActions.registerGroupTask),
      concatMap(({ task }) => {
        return this.groupTasksService.registerGroupTask(task).pipe(
          concatMap((result, _params) => {
            if (result) {
              return of(GroupTasksActions.loadGroupTask({ guid: result }));
            } else {
              this.toastService.warn(this.i18nPipe.transform(this.errorMessage));
              return of(GroupTasksActions.loadGroupTasksError({ error: this.i18nPipe.transform(this.errorMessage) }));
            }
          }),
          catchError((error) => of(GroupTasksActions.loadGroupTasksError({ error: error })))
        );
      })
    );
  });

  saveChangesToGroupTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupTasksActions.saveChangesToGroupTask),
      concatMap(({ groupTaskGuid, task }) => {
        return this.groupTasksService.updateGroupTask(groupTaskGuid, task).pipe(
          concatMap((result, _params) => {
            if (!result) {
              return of(GroupTasksActions.loadGroupTask({ guid: groupTaskGuid }));
            } else {
              this.toastService.warn(this.i18nPipe.transform(this.errorMessage));
              return of(GroupTasksActions.loadGroupTasksError({ error: this.i18nPipe.transform(this.errorMessage) }));
            }
          }),
          catchError((error) => of(GroupTasksActions.loadGroupTasksError({ error: error })))
        );
      })
    );
  });

  forceRunGroupTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupTasksActions.forceRunGroupTask),
      concatMap(({ task }) => {
        const computers = (task?.expected?.length ? task.computers.concat(task.expected) : task.computers).filter(
          (computer) => !computer.disabled
        );
        const streams$ = computers.map((computer) => this.groupTasksService.executeGroupTask(computer.hid, task.groupTaskGuid));
        return of(streams$).pipe(
          mergeMap((streams) => streams),
          toArray(),
          map((resultsArray) => resultsArray.map((obs) => obs.subscribe())),
          mergeMap(() => {
            this.toastService.success(this.i18nPipe.transform(this.successMessage));
            return of(GroupTasksActions.refreshGroupTask({ guid: task.groupTaskGuid }));
          }),
          catchError((error) => of(GroupTasksActions.loadGroupTasksError({ error: error })))
        );
      })
    );
  });

  forceRunGroupTaskPerComputer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupTasksActions.forceRunGroupTaskForPC),
      concatMap(({ hid, groupTaskGuid }) => {
        return this.groupTasksService.executeGroupTask(hid, groupTaskGuid).pipe(
          concatMap((result) => {
            return of(GroupTasksActions.loadGroupTasks({ params: this.httpParams }));
          }),
          catchError((error) => of(GroupTasksActions.loadGroupTasksError({ error: error })))
        );
      })
    );
  });

  updateGroupTaskState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupTasksActions.changeGroupTaskState),
      concatMap(({ groupTaskGuid, state }) => {
        return this.groupTasksService.changeGroupTaskStateUtility(groupTaskGuid, state).pipe(
          concatMap((result) => {
            if (result) this.toastService.success(this.i18nPipe.transform(this.successMessage));
            return of(GroupTasksActions.refreshGroupTask({ guid: groupTaskGuid }));
          })
        );
      })
    );
  });

  refreshGroupTaskByGuid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GroupTasksActions.refreshGroupTask),
      concatMap(({ guid }) => {
        return this.groupTasksService.getGroupTask(guid).pipe(
          concatMap((result) => {
            return of(GroupTasksActions.updateGroupTask({ groupTask: result }));
          }),
          catchError((error) => of(GroupTasksActions.loadGroupTasksError({ error: error })))
        );
      })
    );
  });

  private getNewDataList(data: GroupTask[], created: GroupTask): GroupTask[] {
    const shouldUpdateExistingGroupTaskInList = data.some((groupTask) => groupTask.groupTaskGuid === created.groupTaskGuid);

    return shouldUpdateExistingGroupTaskInList
      ? data.map((groupTask) => (groupTask.groupTaskGuid === created.groupTaskGuid ? created : groupTask))
      : [created].concat(data);
  }

  private httpParams: GroupTaskFiltersPayload = {
    all: true,
    orderBy: 'dateCreated desc',
    pageSize: 10,
    pageNumber: 1
  };
  private errorMessage = 'rmm-group-task.module:errorMessage';
  private successMessage = 'rmm-group-task.module:successMessage';
  private deleteMessage = 'rmm-group-task.module:deleteMessage';

  constructor(
    private actions$: Actions,
    private store: Store,
    private groupTasksService: GroupTasksService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe
  ) {
    // add default httpParams if needs
  }
}
