<mbs-modal [title]='"computers.module:authorization" | i18next'
           [loading]="loading$ | async"
           #modal>
  <ng-container modal-body>
    <mbs-alert *ngIf="isGroupOperation"
               [type]="alertType.info"
               [icon]="true">
      {{ authorizeComputersAlertText$ | async }}
    </mbs-alert>
    <form [formGroup]="modalForm"
          class="mbs-form_content">
      <mbs-select [items]="companies"
                  formControlName="company"
                  [placeholder]='"computers.module:selectCompanyInputPlaceHolder" | i18next'
                  bindLabel="name"
                  [label]='"common.company" | i18next: {format: "title"}'
                  [appendTo]="'.modal-content'"
                  [searchable]="true"
                  [clearable]="false">
        <ng-template ng-footer-tmp>
          <!-- Admin can create a company only if he has the permissions, as well as the permissions to create a user,
          otherwise creating a company in this case does not make sense -->
          <ng-container *ngIf="(permissionsEnum.ManageCompanies | can: 'read') && (permissionsEnum.AccessToAllCompanies
           | can: 'read') && (permissionsEnum.UsersCreateEdit | can: 'read')">
            <mbs-button [isCtrl]="true"
                        (click)="onAddCompanyButtonClick()">{{'computers.module:addNewCompany' | i18next}}</mbs-button>
          </ng-container>
        </ng-template>
      </mbs-select>

      <mbs-checkbox *ngIf="mode === computerAuthorizationModalMode.Create"
                    [disabled]="!modalForm.value.company"
                    formControlName="assignUserManually"
                    class="mt-4 mb-4"
                    [label]='"computers.module:assignUserManually" | i18next'></mbs-checkbox>

      <ng-container *ngIf="modalForm.value.assignUserManually">
        <mbs-alert *ngIf="mode === computerAuthorizationModalMode.Create"
                   [type]="alertType.warning"
                   [icon]="true">
          {{ 'computers.module:assignUserManuallyAlertText' | i18next }}
        </mbs-alert>

        <div [class.mt-3]="mode === computerAuthorizationModalMode.Edit">
          <mbs-select [items]="selectedCompanyUsers"
                      formControlName="user"
                      bindLabel="email"
                      [placeholder]='"computers.module:selectUserInputPlaceHolder" | i18next'
                      [label]='"common.user" | i18next: {format: "title"}'
                      [appendTo]="'.modal-content'"
                      [searchable]="true"
                      [clearable]="false">
            <ng-template ng-footer-tmp>
              <ng-container *ngIf="showAddUserButton">
                <mbs-button [isCtrl]="true"
                            (click)="onAddUserButtonClick()">{{'computers.module:addNewUser' | i18next}}</mbs-button>
              </ng-container>
            </ng-template>
          </mbs-select>
        </div>
      </ng-container>
    </form>
  </ng-container>

  <ng-container modalFooter>
    <mbs-button type="primary"
                (click)="onSaveButtonClick()"
                [disabled]="!modalForm.valid"
                [loading]="requestInProgress">
      {{ 'buttons:save' | i18next }}
    </mbs-button>
    <mbs-button type="secondary"
                (click)="modal.close()">
      {{ 'buttons:cancel' | i18next }}
    </mbs-button>
  </ng-container>
</mbs-modal>
