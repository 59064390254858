import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { StorageType } from '@models/StorageType.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StorageAccountsService } from '@services/storage-accounts/storage-accounts.service';
import { AbilityService } from 'ability';
import { ModalComponent } from 'mbs-ui-kit';
import { MbsPopupType } from 'mbs-ui-kit/utils/enums/mbs-popup-enum';

enum PeriodVariants {
  WasabiSmall = 30,
  WasabiBig = 90
}

@UntilDestroy()
@Component({
  templateUrl: './minimum-storage-duration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinimumStorageDurationComponent implements OnInit {
  public readonly periodVariants = PeriodVariants;
  public readonly storageTypes = StorageType;
  public readonly mbsPopupType = MbsPopupType;
  public storageType: StorageType;
  public storageAccountId: string;
  public duration: PeriodVariants = PeriodVariants.WasabiBig;
  public loading = false;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(public ability: AbilityService, private cdr: ChangeDetectorRef, private storageAccounts: StorageAccountsService) {}

  ngOnInit(): void {
    this.storageAccountId = this.baseModal.data.storageAccountId;
    this.storageType = this.baseModal.data.storageType;
    this.loading = true;
    this.storageAccounts
      .getMinimumStorageDurationForWasabi(this.storageAccountId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: { duration: number }) => {
          if (result?.duration) this.duration = result.duration;
          this.loading = false;
          this.cdr.detectChanges();
        },
        error: () => {
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }

  selectClickHandler(): void {
    if (this.storageType === StorageType.Wasabi) {
      this.loading = true;
      this.storageAccounts
        .minimumStorageDurationForWasabi(this.storageAccountId, this.duration)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.loading = false;
            this.baseModal.save(true);
          },
          error: () => {
            this.loading = false;
            this.baseModal.close();
          }
        });
    }
  }

  cancelClickHandler(): void {
    this.baseModal.close();
  }
}
