import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@components/tfa/models/device';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  constructor(private http: HttpClient) {}

  // TODO Set Types
  getDevices(): Observable<Array<Device>> {
    return this.http.get<Array<Device>>('api/devices');
  }

  addDevice(tfa = true): Observable<{
    qrCodeUrl: string;
    timeExpired: string;
  }> {
    return this.http.get<{
      qrCodeUrl: string;
      timeExpired: string;
    }>(`api/devices/qr?tfa=${tfa}`);
  }

  deleteDevice(id: string, hasTwoFactorAuth: boolean) {
    return this.http.delete(`api/devices/${id}`);
  }

  editDevice(data: { deviceId: string; push: boolean; tfa: boolean }) {
    return this.http.put('api/devices/settings', data);
  }

  enablePush(id: string) {
    return this.http.put('api/devices/push-settings', { DeviceId: id, Enable: true });
  }

  disablePush(id: string) {
    return this.http.put('api/devices/push-settings', { DeviceId: id, Enable: false });
  }

  enableTFA(id: string): any {
    return this.http.put('api/devices/tfa/on', { DeviceId: id });
  }

  disableTFA(id: string) {
    return this.http.put('api/devices/tfa/off', { DeviceId: id });
  }

  recoverPassword(hid, backupPath) {
    return this.http.post('api/backup-plans/recover-password', { hid, backupPath });
  }
}
