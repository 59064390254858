<div class="ng-terminal-wrapper background-primary p-1 h-100 initialized"
     (scroll)="handleScroll($event)">
  <mbs-button *ngIf="showModalButton"
              (click)="handleShowModal()"
              [isCtrl]="true"
              class="ng-terminal-wrapper-show-modal bg-transparent-white"
              icon="ico ico-external-link-square text-white">

  </mbs-button>
  <ng-terminal #terminal></ng-terminal>
</div>

<div class="row ml-1 mt-1 ng-terminal-warning">
  {{ warning }}
</div>
