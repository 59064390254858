import { Action, on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import * as ComputerBackupActions from './actions';
import { ComputerDetails, computersBackupAdapter, ComputersBackupState, initialComputersBackupState } from './state';

const reducer = createImmerReducer(
  initialComputersBackupState,
  on(ComputerBackupActions.setComputer, (state, action) => {
    state.selected = action.hid;
    return state;
  }),
  on(ComputerBackupActions.clear, (state) => {
    return computersBackupAdapter.removeAll(state);
  }),

  on(ComputerBackupActions.loadGeneralInfo, (state, action): ComputersBackupState => {
    const hid = action.hid || state.selected;
    if (!hid) return { ...state };
    return replaceEntity(
      {
        id: hid,
        hostInfo: { ...state.entities[hid]?.hostInfo, ...{ loading: (!state.entities[hid]?.hostInfo || action.force) ?? false } },
        destinations: state.entities[hid]?.destinations,
        plans: state.entities[hid]?.plans,
        availablePlans: state.entities[hid]?.availablePlans,
        timeZoneOffset: state.entities[hid]?.timeZoneOffset
      },
      state
    );
  }),
  on(ComputerBackupActions.loadGeneralInfoSuccess, (state, action) => {
    if (!state.entities[action.hid]) return state;

    state.entities[action.hid].hostInfo = { data: action.data, loaded: true, loading: false };
    return state;
  }),
  on(ComputerBackupActions.loadGeneralInfoError, (state, action) => {
    if (!state.entities[action.hid]) return state;

    state.entities[action.hid].hostInfo.loading = false;
    return state;
  }),

  on(ComputerBackupActions.loadDestinations, (state, action): ComputersBackupState => {
    const hid = action.hid || state.selected;
    if (!hid) return { ...state };
    return replaceEntity(
      {
        id: hid,
        hostInfo: state.entities[hid]?.hostInfo,
        destinations: {
          ...state.entities[hid]?.destinations,
          ...{ loading: (!action.quiet && (!state.entities[hid]?.destinations || action.force)) ?? false }
        },
        plans: state.entities[hid]?.plans,
        availablePlans: state.entities[hid]?.availablePlans,
        timeZoneOffset: state.entities[hid]?.timeZoneOffset
      },
      state
    );
  }),
  on(ComputerBackupActions.loadDestinationsSuccess, (state, action) => {
    if (!state.entities[action.hid]) return state;

    state.entities[action.hid].destinations = { data: action.data, loaded: true, loading: false };
    return state;
  }),
  on(ComputerBackupActions.loadDestinationsError, (state, action) => {
    if (!state.entities[action.hid]) return state;

    state.entities[action.hid].destinations.loading = false;
    return state;
  }),

  on(ComputerBackupActions.loadPlans, (state, action): ComputersBackupState => {
    const hid = action.hid || state.selected;
    if (!hid) return { ...state };
    return replaceEntity(
      {
        id: hid,
        hostInfo: state.entities[hid]?.hostInfo,
        destinations: state.entities[hid]?.destinations,
        plans: {
          ...state.entities[hid]?.plans,
          ...{ loading: (!action.quiet && (!state.entities[hid]?.plans || action.force)) ?? false }
        },
        availablePlans: state.entities[hid]?.availablePlans,
        timeZoneOffset: state.entities[hid]?.timeZoneOffset
      },
      state
    );
  }),
  on(ComputerBackupActions.loadPlansSuccess, (state, action) => {
    if (!state.entities[action.hid]) return state;

    state.entities[action.hid].plans = { data: action.data, loaded: true, loading: false };
    state.entities[action.hid].timeZoneOffset = action.timeZoneOffset;
    return state;
  }),
  on(ComputerBackupActions.loadPlansError, (state, action) => {
    if (!state.entities[action.hid]) return state;

    state.entities[action.hid].plans.loading = false;
    return state;
  }),
  on(ComputerBackupActions.setPlanRunning, (state, action) => {
    if (!state.plansStatuses[action.id]) return state;

    state.plansStatuses[action.id].running = action.running;
    return state;
  }),
  on(ComputerBackupActions.setPlanSaving, (state, action) => {
    if (!state.plansStatuses[action.id]) return state;

    state.plansStatuses[action.id] = { ...state.plansStatuses[action.id], saving: action.saving };
    return state;
  }),

  on(ComputerBackupActions.loadAvailablePlans, (state, action): ComputersBackupState => {
    const hid = action.hid || state.selected;
    if (!hid) return { ...state };
    return replaceEntity(
      {
        id: hid,
        hostInfo: state.entities[hid]?.hostInfo,
        destinations: state.entities[hid]?.destinations,
        plans: state.entities[hid]?.plans,
        availablePlans: {
          ...state.entities[hid]?.availablePlans,
          ...{ loading: (!state.entities[hid]?.availablePlans || action.force) ?? false }
        },
        timeZoneOffset: state.entities[hid]?.timeZoneOffset
      },
      state
    );
  }),
  on(ComputerBackupActions.loadAvailablePlansSuccess, (state, action) => {
    if (!state.entities[action.hid]) return state;

    state.entities[action.hid].availablePlans = { data: action.data, loaded: true, loading: false };
    return state;
  }),
  on(ComputerBackupActions.loadAvailablePlansError, (state, action) => {
    if (!state.entities[action.hid]) return state;

    state.entities[action.hid].availablePlans.loading = false;
    return state;
  })
);

function replaceEntity(entity: ComputerDetails, state: ComputersBackupState): ComputersBackupState {
  const newState = computersBackupAdapter.removeOne(computersBackupAdapter.selectId(entity) as string, state);

  return computersBackupAdapter.addOne(entity, newState);
}

export function computersBackupReducer(state: ComputersBackupState | undefined, action: Action) {
  return reducer(state, action);
}
