// @TODO string to be localised
export enum SeverityLevels {
  Critical = 'critical',
  Important = 'important',
  Moderate = 'moderate',
  Low = 'low'
}

export enum WindowsUpdateCategories {
  CriticalUpdate = 'criticalUpdates',
  DefinitionUpdate = 'definitionUpdates',
  Driver = 'driversSoftware',
  FeaturePack = 'featurePacks',
  SecurityUpdate = 'securityPacks',
  ServicePack = 'servicePacks',
  Tool = 'tools',
  Update = 'update',
  UpdateRollup = 'updateRollups'
}

export enum ModalSelectionType {
  Severity,
  Category,
  Excluded
}

export enum OperatingSystemID {
  Undefined,
  Android,
  Browser,
  FreeBSD,
  IOS,
  Linux,
  MacCatalyst,
  MacOS,
  WatchOS,
  Windows,
  TvOS
}

export enum NotifyTimeType {
  Hours = 'Hour(s)',
  Days = 'Day(s)'
}

export type UpdatePolicyObjectForSelection = {
  id: number;
  name: string;
};

export type UpdatePolicyParameters = {
  categories: number[];
  severityLevels: number[];
  excludeUpdates: string[];
  windowsLocalUpdates: boolean;
  deferInstall: string;
  rebootTime: string;
  notifyUserBeforeRebootTime: string;
  identificator?: Identificator;
  compatibility?: Compatibility[];
  permissions?: {
    enabled?: boolean;
  };
};

export type Identificator = {
  id?: string;
  version?: string;
  lastEditTimeUTC?: string;
  description?: string;
};

export type Compatibility = {
  pluginName?: string;
  operatingSystemID?: number;
};

export type UpdatePolicyFormValues = {
  severityLevel: boolean;
  updateCategory: boolean;
  excludeUpdates: boolean;
  localUpdatesPriority: boolean;
  localUpdatesSelection: string;
  updateDefer: boolean;
  updateDeferNumber: string | number;
  updateDeferType: string;
  rebootIfRequired: boolean;
  rebootIfRequiredTime: string;
  rebootIfRequiredNotifySelection?: string;
};

export const DEFAULT_UPDATE_POLICY_PARAMETERS: UpdatePolicyParameters = {
  categories: null,
  severityLevels: null,
  excludeUpdates: null,
  windowsLocalUpdates: true,
  deferInstall: null,
  rebootTime: null,
  notifyUserBeforeRebootTime: null,
  identificator: {
    id: null,
    version: null,
    lastEditTimeUTC: null,
    description: null
  },
  compatibility: [],
  permissions: {
    enabled: false
  }
};

export interface LocalUpdatesItem {
  name: string
  value: boolean;
}
