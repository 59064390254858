import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'mbs-card-group-header',
  template: `
    <header class="mbs-card_group-header">
      <ng-container *ngIf="!headerTemplate; else headerTemplate">
        <h5 class="mbs-card_group-title" [ngClass]="[headerTitleClass]">
          {{ headerTitle }}
        </h5>
        <span
          *ngIf="info"
          class="tooltip-btn {{ infoClass ? infoClass : 'ico ico-InfoCircle' }}"
          [placement]="infoPlacement"
          [triggers]="infoTriggers"
          [ngbTooltip]="info"
        ></span>
      </ng-container>
    </header>
  `
})
export class CardGroupHeaderComponent {
  @Input('title') headerTitle: string;
  @Input('titleClass') headerTitleClass = '';
  @ContentChild(TemplateRef, { read: TemplateRef, static: true }) headerTemplate;

  /**
   * Group detail description. Append info icon as tooltip.
   */
  @Input() public info: string;

  /**
   * Tooltip position
   */
  @Input() public infoPlacement = 'auto';
  /**
   * Specifies events that should trigger the tooltip
   */
  @Input() public infoTriggers = 'hover focus';

  /**
   * Custom info tooltip class
   */
  @Input() public infoClass: string;
}
