<mbs-modal [isCustomModal]="true"
           [showFooter]="false"
           [showCloseCross]="true"
           #modal>
  <ng-container modal-header>
    {{ 'rmm-script-library.module:manageCategories' | i18next }}
  </ng-container>

  <div modal-body
       [formGroup]="form">


    <div class="row">
      <div class="col-12">
        <mbs-alert [type]="'info'"
                   [closable]="false">
          {{ 'rmm-script-library.module:categoryDeletionInfo' | i18next }}
        </mbs-alert>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mbs-form-group>
          <div class="row d-flex align-items-center justify-content-between">
            <div class="col">
              <mbs-input formControlName="name"
                         maxLength="50"
                         [placeholder]="'rmm-script-library.module:createScriptModal.placeholders.category' | i18next">
              </mbs-input>
            </div>
            <div class="col-3 d-flex justify-content-end">
              <mbs-button [isCtrl]="true"
                          [disabled]="addButtonDisabled"
                          (click)="addCategory()">
                <span class="fa fa-plus"></span>
                {{ 'rmm-script-library.module:createScriptModal.addCategoryBtn' | i18next }}
              </mbs-button>
            </div>
          </div>

          <div *ngIf="showError"
               class="text-danger text-sm">
            {{ 'rmm-script-library.module:createScriptModal.createCategoryErrorMsg' | i18next }}
          </div>
        </mbs-form-group>
      </div>
    </div>

    <div class="row script-category-list-custom-size">
      <div class="col-12"
           *ngFor="let category of (categoryList$ | async)">

        <div class="d-flex align-items-center justify-content-between">
          <span> {{ category.name }}
            <span class="text-muted">
              {{ 'rmm-script-library.module:scriptCount' | i18next: { value: category.scriptCounter } }}
            </span>
          </span>

          <mbs-button [icon]="'fa fa-trash-o text-lg'"
                      [disabled]="unRemovableCategoryNames.includes(category.name)"
                      (click)="deleteCategory(category.tagGuid)">
          </mbs-button>
        </div>

        <hr>

      </div>
    </div>


  </div>
</mbs-modal>
