import { Injectable } from '@angular/core';
import { AgentType } from '@models/Computer';
import { I18NextPipe } from 'angular-i18next';

@Injectable({
  providedIn: 'root'
})
export class ComputerI18nFacade {
  private readonly i18nComputersModulePath = 'computers.module';
  private readonly i18nAppModulePath = 'app';
  private readonly i18nModalSuffix = 'modals';
  private readonly i18nButtonsSuffix = 'buttons';

  constructor(private i18nPipe: I18NextPipe) {}

  getAgentName(agent: AgentType): string {
    return this.getComputersText(`${agent}Agent`);
  }

  getModalText(key: string): string {
    return this.getComputersText(key, this.i18nModalSuffix);
  }

  getAppButtonsTitle(key: string): string {
    return this.getAppTitle(key, this.i18nButtonsSuffix);
  }

  getComputersModalTitle(key: string): string {
    return this.getComputersTitle(key, this.i18nModalSuffix);
  }

  getModalTextbyParam(key: string, param: string): string {
    return this.getComputersText(key, this.i18nModalSuffix, '', param);
  }

  getAppTitle(key: string, suffix = ''): string {
    return this.getAppText(key, suffix, 'title');
  }

  getComputersTitle(key: string, suffix = ''): string {
    return this.getComputersText(key, suffix, 'title');
  }

  getAppText(key: string, suffix = '', format = '', param = ''): string {
    return this.getText(this.prepareAppPrefix(key, suffix), format, param);
  }

  getComputersText(key: string, suffix = '', format = '', param = ''): string {
    return this.getText(this.prepareComputersPrefix(key, suffix), format, param);
  }

  getText(prefix: string, format = '', param = ''): string {
    return this.i18nPipe.transform(prefix, format ? { format } : param ? { param } : {});
  }

  private prepareAppPrefix(key: string, suffix = ''): string {
    return this.preparePrefix(this.i18nAppModulePath, key, suffix);
  }

  private prepareComputersPrefix(key: string, suffix = ''): string {
    return this.preparePrefix(this.i18nComputersModulePath, key, suffix);
  }

  private preparePrefix(modulePath: string, key: string, suffix = ''): string {
    return `${modulePath}:${suffix}${suffix ? ':' : ''}${key}`;
  }
}
