<div class="bg-primary-5 pl-3 rounded-bottom">
  <div class="code-container">
    <textarea #textArea
              class="text-area-code-editor"
              spellcheck="false"
              [value]="value"
              [id]="id"
              [disabled]="disabledState"
              [readonly]="readonlyState"
              (paste)="onPaste($event)">
    </textarea>
    <pre id="pre-el-id"
         aria-hidden="true"
         class="pre code-editor-height"
         [style]="{height: height}"
         #pre><code [ngClass]="['code', 'language-' + codeType]"
            #codeContent></code>
    </pre>
  </div>
</div>
