export function getSentryEnvironment(): 'staging' | 'production' | 'local' {
  if (location.hostname.includes('localhost')) {
    return 'local';
  }

  if (location.hostname.includes('stage')) {
    return 'staging';
  }

  return 'production';
}
