import { Component, forwardRef, Input, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { EC2DataValue, EC2StepValue } from '../../../models/EC2-models';
import { RestoreAsDiskEnum } from '../../../models/type-data-models';
import { RemoteManagementWizardsService } from '../../../services/remote-management-wizards.service';
import { FormPipeOperators, StepBase } from '../../StepBase.class';

const EC2StepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => EC2StepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-EC2-step',
  templateUrl: './EC2-step.component.html',
  providers: [EC2StepValueAccessor]
})
export class EC2StepComponent extends StepBase<EC2StepValue> {
  @Input() EC2Data: EC2DataValue = { accounts: [], regions: [], zones: [], types: [], subnetItems: [], securityGroups: [] };
  @Input() restoreAs: RestoreAsDiskEnum;

  public restoreAsDiskEnum = RestoreAsDiskEnum;
  constructor(public mainService: RemoteManagementWizardsService) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();

    this.restoreAsChangeHandler(this.restoreAs);
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this), debounceTime(200)];
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      account: new FormControl('', Validators.required),
      region: new FormControl(''),
      availabilityZones: new FormControl(''),
      instanceType: new FormControl(''),
      subnet: new FormControl(''),
      securityGroup: new FormControl('')
    });

    this.initFormEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.restoreAs && this.stepForm) this.restoreAsChangeHandler(changes.restoreAs.currentValue);
  }

  restoreAsChangeHandler(event): void {
    if (event === RestoreAsDiskEnum.EBSVolume) {
      this.stepForm.get('availabilityZones').enable();
      this.stepForm.get('instanceType').disable();
      this.stepForm.get('subnet').disable();
      this.stepForm.get('securityGroup').disable();
    } else if (event === RestoreAsDiskEnum.EC2) {
      this.stepForm.get('availabilityZones').disable();
      this.stepForm.get('instanceType').enable();
      this.stepForm.get('subnet').enable();
      this.stepForm.get('securityGroup').enable();
    }
  }
}
