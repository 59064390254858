import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreTypes } from '../storeTypes.enum';
import { ComputersStatisticEffects } from './effects';
import { computersStatisticReducer } from './reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(StoreTypes.ComputersStatistic, computersStatisticReducer),
    EffectsModule.forFeature([ComputersStatisticEffects])
  ],
  providers: []
})
export class ComputersStatisticStoreModule {}
