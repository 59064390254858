import { NgModule } from '@angular/core';
import { environment } from '@mbs-ui/environments/environment';
import { EntityCollectionReducerMethodsFactory, PersistenceResultHandler } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EntityStoreModule } from '@shared/entity-store/entity-store.module';
import { AdministratorsStoreModule } from '@store/administrators/administratorsStore';
import { ApplicationStoreModule } from '@store/application';
import { BuildsStoreModule } from '@store/builds/store.module';
import { CompaniesStoreModule } from '@store/companies/companiesStore';
import { ComputersStoreModule } from '@store/computers/store.module';
import { ComputersBackupStoreModule } from '@store/computersBackup/store.module';
import { ComputersStatisticStoreModule } from '@store/computersStatistic/store.module';
import { ExtendedEntityCollectionReducerMethodsFactory, ExtendedPersistenceResultHandler } from '@store/extendedPersistenceResultHandler';
import { metaReducers } from '@store/meta.reducers';
import { UsersStoreModule } from '@store/users/usersStore';

@NgModule({
  imports: [
    StoreModule.forRoot(
      {},
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        }
      }
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EntityStoreModule,
    ApplicationStoreModule,
    CompaniesStoreModule,
    ComputersStoreModule,
    ComputersBackupStoreModule,
    ComputersStatisticStoreModule,
    BuildsStoreModule,
    UsersStoreModule,
    AdministratorsStoreModule
  ],
  providers: [
    { provide: PersistenceResultHandler, useClass: ExtendedPersistenceResultHandler },
    { provide: EntityCollectionReducerMethodsFactory, useClass: ExtendedEntityCollectionReducerMethodsFactory }
  ]
})
export class AppStoreModule {}
