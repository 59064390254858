import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { AgentOptions } from '@models/AgentOptions';
import Computer, { AgentType, OsType } from '@models/Computer';
import RmmCommand from '@models/rmm/RmmCommand';
import RmmLastStatData from '@models/rmm/RmmLastStatData';
import SoftwareInfo from '@models/rmm/SoftwareInfo';
import { GroupTaskOsTypes } from '@modules/group-tasks/store/model';
import { InstallWinSoftwareCommonComponent } from '@modules/rmm-utility-features/install-win-software/install-win-software-common';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { RMMGroupActionWizardComponent } from '@modules/schedule-group-action/rmm-group-action-wizard.component';
import { GAActions } from '@modules/schedule-group-action/store/group-action';
import { WindowsAvailableActions } from '@modules/schedule-group-action/store/group-action/group-action.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RmmSoftwareService, UninstalResult } from '@services/rmm-software.service';
import { RmmService } from '@services/rmm.service';
import { baseDateFileNameFormatHoursAndMinutes } from '@utils/date';
import { versionCompare } from '@utils/version-compare';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash/fp';
import { MbsPopupType, MbsSize, ModalService, ModalSettings, SharedPersistentStateEnum, TableHeader, ToastService } from 'mbs-ui-kit';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import moment from 'moment';
import { BehaviorSubject, noop, of, Subject, throwError } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, filter, finalize, map, pluck, switchMap, take, tap } from 'rxjs/operators';
import { RmmTabBaseComponent } from '../rmm-tab-base.component';

export type CommandServiceResponse = {
  result: string;
  agentVersion: string;
  taskGuid: string;
  error: string;
};

export enum HeaderAvailability {
  Windows = OsType.windows,
  Linux = OsType.linux,
  Mac = OsType.apple
}

export enum ActionTypeInProgress {
  Install,
  Update,
  Uninstall
}

type TableHeaderExtend = TableHeader & {
  isFullHeader?: boolean;
  availableFor: HeaderAvailability[];
};

@UntilDestroy()
@Component({
  selector: 'mbs-software-tab',
  templateUrl: './software-tab.component.html'
})
export class SoftwareTabComponent extends RmmTabBaseComponent<SoftwareInfo> implements OnInit, OnChanges {
  public readonly sharedPersistentStateEnum = SharedPersistentStateEnum;

  public translationKey = 'rmm-side-panel:softwareTab.';
  public fullHeaders: TableHeaderExtend[] = [
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.name'),
      overflow: true,
      sort: 'name',
      gridColSize: '40fr',
      isFullHeader: false,
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Linux, HeaderAvailability.Mac]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.version'),
      sort: 'version',
      gridColSize: '60fr',
      gridColMin: '80px',
      overflow: true,
      isFullHeader: false,
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Linux, HeaderAvailability.Mac]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.publisher'),
      sort: 'vendor',
      overflow: true,
      gridColSize: '50fr',
      isFullHeader: true,
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Mac]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.description'),
      sort: 'description',
      overflow: true,
      gridColSize: '75fr',
      isFullHeader: true,
      availableFor: [HeaderAvailability.Linux]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.productID'),
      sort: 'identifyingNumber',
      overflow: true,
      gridColSize: '50fr',
      isFullHeader: true,
      availableFor: [HeaderAvailability.Windows, HeaderAvailability.Mac]
    },
    {
      name: this.i18n.transform(this.translationKey + 'tableHeaders.user'),
      sort: 'user',
      gridColSize: '25fr',
      overflow: true,
      isFullHeader: true,
      availableFor: [HeaderAvailability.Windows]
    }
  ];
  private smallerAgentVersion = '1.4.0';
  public shortHeaders: TableHeaderExtend[] = this.fullHeaders.filter((h) => !h.isFullHeader);

  private statusRequest$ = new Subject();
  public currentlyUninstalling: string;
  public currentlyInstalling = false;
  public inProgressAmount = 0;
  public MbsSize = MbsSize;
  public MbsPopupType = MbsPopupType;
  private currentlyUninstallingAsyncId = '';
  private currentlyUninstallingSoftwareId = '';
  public disableUninstall = false;
  public disableInstall = false;
  private minimalVersion = '1.2';
  public uninstallSoftMinVersionForLinux = 150;
  public uninstallSoftMinVersionForMac = 160;
  public installUpdateForWinMinVersion = 200;
  public isUninstallAvailable = false;

  @Input() computerName: string;
  @Input() agentOptions: AgentOptions;
  @Input() readonly: boolean;
  @Input() isOnline: boolean;
  @Input() agentVersion: { version: string; supported: boolean };
  public isVersionSupported = false;
  public isInstallSupported = false;
  public showGroupTaskInfoPopup = true;
  private firstModalStatusRequestFlag = true;
  public isMacOS: boolean;
  public isLinuxOS: boolean;
  private uninstalledSoftware$: BehaviorSubject<SoftwareInfo> = new BehaviorSubject(null);
  public latestInfoMessage$ = null;
  public emptyTableMsgTranslationKey: 'noDataProvided' | 'scanningForSoftware' = 'noDataProvided';

  public actionInProgress = false;
  public actionTypeInProgress: ActionTypeInProgress = null;
  public responseAsyncId = null;
  public actionTypeEnum = ActionTypeInProgress;
  public isUninstall = false;
  public isWingetSupported = false;
  public softwareUninstallList: SoftwareInfo[] = [];
  public showOutdatedOnly = false;

  public computer: Computer;

  // TODO: replace these two outputs with Store
  @Output() onFetchData = new EventEmitter();
  @Output() onFirstStatusRequest = new EventEmitter();

  constructor(
    private rmmService: RmmService,
    private softwareService: RmmSoftwareService,
    private commandService: CommandService,
    private toast: ToastService,
    private modal: ModalService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private computersFacade: ComputersFacade,
    private i18n: I18NextPipe,
    private modalService: ModalService
  ) {
    super();
  }

  initStreams(): void {
    this.emptyTableMsgTranslationKey = 'scanningForSoftware';
    const software$ = this.rmmService.fetchLastData<RmmLastStatData<SoftwareInfo>>('software', this.hid).pipe(
      pluck('data'),
      finalize(() => this.onLoad$.next(false))
    );

    this.onFetchLastData$
      .pipe(
        concatMap(() => software$),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.uninstalledSoftware$.getValue()
          ? (this.allData = this.getDataWithoutUninstalled(cloneDeep(data), this.uninstalledSoftware$.getValue()))
          : (this.allData = cloneDeep(data));

        this.updateData(this.allData);
        this.setWingetSupported();

        if (this.currentlyUninstallingSoftwareId) {
          const soft = this.getUninstallingSoftwareById(this.currentlyUninstallingSoftwareId);
          if (!(soft === null || soft === undefined)) {
            this.currentlyUninstalling = soft.name;
          }
        }
        this.emptyTableMsgTranslationKey = 'noDataProvided';
        this.oudatedFiltering(this.showOutdatedOnly);
      });

    this.fetchData();
  }

  private setWingetSupported() {
    this.isWingetSupported =
      this.allData.some((item) => item.packageSource === 'winget') &&
      Computer.IsSupportedAgentVersion(this.computer, AgentType.RMM, this.installUpdateForWinMinVersion);
  }

  private getDataWithoutUninstalled(data: SoftwareInfo[], uninstalled: SoftwareInfo): SoftwareInfo[] {
    this.uninstalledSoftware$.next(null);
    return data.filter((software) => !(software.identifyingNumber === uninstalled.identifyingNumber && software.name === uninstalled.name));
  }

  ngOnInit(): void {
    this.checkOSType();

    this.headers = this.isModal
      ? this.getTableHeadersWithOSDependency(this.fullHeaders)
      : this.getTableHeadersWithOSDependency(this.shortHeaders);
    this.searchFields = ['name'];
    this.initStreams();
    this.isVersionSupported = this.isOnline && this.agentVersion && versionCompare(this.agentVersion.version, this.minimalVersion) >= 0;

    if (this.canUninstall() && this.isModal) {
      this.onFirstStatusRequest.emit();
    }

    this.latestInfoMessage$ = this.commandService.messages$.pipe(
      debounceTime(300),
      filter((response) => !!response && (this.responseAsyncId ? response.MessageId === this.responseAsyncId : true)),
      map((response: any) => response?.Data && typeof response?.Data === 'string' && JSON.parse(response?.Data)?.data),
      tap((data) => {
        this.resetInProgressAction();
        this.firstModalStatusRequestFlag = true;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.agentVersion?.currentValue || changes.isOnline?.currentValue)
      this.isVersionSupported = this.isOnline && this.agentVersion && versionCompare(this.agentVersion.version, this.minimalVersion) >= 0;
  }

  private getTableHeadersWithOSDependency(tableHeaders: TableHeaderExtend[]): TableHeaderExtend[] {
    switch (true) {
      case this.isLinuxOS:
        return tableHeaders
          .filter((column) => column.availableFor.includes(HeaderAvailability.Linux))
          .map((header) => {
            if (header.name !== this.i18n.transform(this.translationKey + 'tableHeaders.description')) header.gridColSize = '35fr';
            return header;
          });
      case this.isMacOS:
        return tableHeaders.filter((column) => column.availableFor.includes(HeaderAvailability.Mac));
      default:
        return tableHeaders.filter((column) => column.availableFor.includes(HeaderAvailability.Windows));
    }
  }

  checkOSType(): void {
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      this.computer = computer;
      this.setWingetSupported();
      if (computer && computer.os) this.isMacOS = computer.os === OsType.apple;
      if (computer && computer.os) this.isLinuxOS = computer.os === OsType.linux;
      this.isUninstallAvailable =
        (!this.isMacOS && !this.isLinuxOS) ||
        Computer.IsSupportedAgentVersion(
          computer,
          AgentType.RMM,
          this.isMacOS ? this.uninstallSoftMinVersionForMac : this.uninstallSoftMinVersionForLinux
        );

      this.isInstallSupported =
        this.isMacOS || this.isLinuxOS
          ? false
          : Computer.IsSupportedAgentVersion(computer, AgentType.RMM, this.installUpdateForWinMinVersion);
    });
  }

  canUninstall(): boolean {
    return this.agentOptions && !this.agentOptions.rmmValues.readOnly && this.isVersionSupported;
  }

  handleStatusRequestFromModal(): void {
    if (this.firstModalStatusRequestFlag) {
      this.statusRequest$.next(true);
    }
    this.firstModalStatusRequestFlag = false;
  }

  getUninstallingSoftwareById(softwareId: string): SoftwareInfo {
    return this.allData.find((soft: SoftwareInfo) => soft.identifyingNumber === softwareId);
  }

  uninstallSelected(): void {
    if (!this.selected.length) return;

    this.openConfirmModal(this.selected);
  }

  private openConfirmModal(softwares: SoftwareInfo[]): void {
    const modalSettings: ModalSettings = {
      header: { title: this.i18n.transform(this.translationKey + 'uninstallSoftModalTitle') },
      footer: {
        okButton: {
          text: this.i18n.transform('buttons:uninstall'),
          type: 'danger'
        }
      }
    };

    this.modalService
      .open(modalSettings, this.getUninstallConfirmationText(softwares))
      .then((confirm) => {
        if (confirm) {
          softwares.length > 1 ? this.uninstallSoftwares(softwares) : this.uninstallSoftware(softwares[0]);
        }
      })
      .catch(noop);
  }

  getUninstallConfirmationText(softwares: SoftwareInfo[]): string {
    const applicationInfoMiddleText =
      softwares.length > 1
        ? this.i18n.transform(this.translationKey + 'uninstallApplicationCount', { value: softwares.length })
        : softwares[0].name;

    return `${this.i18n.transform(
      this.translationKey + 'uninstallMsgPart1'
    )} <span class="font-weight-semibold">${applicationInfoMiddleText}</span> ${this.i18n.transform(
      this.translationKey + 'uninstallMsgPart2',
      { computerName: Computer.getComputerName(this.computer) }
    )}`;
  }

  private uninstallSoftware(software: SoftwareInfo): void {
    this.actionTypeInProgress = ActionTypeInProgress.Uninstall;
    this.softwareService
      .uninstallProduct(this.hid, software)
      .pipe(
        tap((response: any) => {
          if (response?.error) return;

          this.responseAsyncId = this.commandService.getAsyncIdFromResponse(response);
          this.actionInProgress = true;
          this.toast.success(this.i18n.transform(this.translationKey + 'toasts.uninstallInProgress'));
          this.currentlyUninstalling = software.name;
          this.softwareUninstallList = [cloneDeep(software)];
          this.setupInstallAndUninstallBtns(true);
        }),
        switchMap(() => this.latestInfoMessage$.pipe(take(1))),
        take(1)
      )
      .subscribe((result: any) => {
        this.fetchData();
        this.rmActionToastHandler(result, 1, true);
      });
  }

  private uninstallSoftwares(softwares: SoftwareInfo[]): void {
    this.actionTypeInProgress = ActionTypeInProgress.Uninstall;
    this.softwareService
      .uninstallProductMultiple(this.hid, softwares)
      .pipe(
        switchMap((response: any) => (response?.error?.code ? throwError(() => response) : of(response))),
        tap((response: any) => {
          if (response?.error) return;

          this.responseAsyncId = this.commandService.getAsyncIdFromResponse(response);
          this.actionInProgress = true;
          this.toast.success(this.i18n.transform(this.translationKey + 'toasts.uninstallInProgress'));
          this.currentlyUninstalling = softwares.map((item) => item.name).join(', ');
          this.softwareUninstallList = cloneDeep(softwares);
          this.setupInstallAndUninstallBtns(true);
        }),
        switchMap(() => this.latestInfoMessage$.pipe(take(1))),
        take(1)
      )
      .subscribe({
        next: (result: any) => {
          this.fetchData();
          this.rmActionToastHandler(result, softwares.length, true);
        },
        error: () => this.resetInProgressAction()
      });
  }

  openInstallModal() {
    this.modalService
      .openCustom(InstallWinSoftwareCommonComponent, {
        data: {
          hid: this.hid,
          wingetEnabled: this.isWingetSupported
        },
        size: MbsSize.md,
        collapsing: true
      })
      .then((data: { isWinget: boolean; selected: SoftwareInfo[]; link: string; params: string }) => {
        if (data) data.isWinget ? this.updateSelected(data.selected, true) : this.installByLink(data.link, data.params);
      })
      .finally(noop);
  }

  installByLink(link: string, params: string) {
    this.actionTypeInProgress = ActionTypeInProgress.Install;
    const commandParams = RmmCommand.create('InstallProductSimple').addParam('url', `${link}`).addParam('arguments', params);

    this.commandService
      .sendCommandAsync('SoftwareCmd', commandParams, this.hid, true)
      .pipe(
        switchMap((response: any) => (response?.error?.code ? throwError(() => response) : of(response))),
        tap((response: any) => {
          if (response?.error) return;

          this.responseAsyncId = this.commandService.getAsyncIdFromResponse(response);
          this.actionInProgress = true;
          this.currentlyInstalling = true;
          this.inProgressAmount = 1;
          this.toast.success(this.i18n.transform(this.translationKey + 'toasts.installStarted'));
          this.setupInstallAndUninstallBtns(true);
        }),
        switchMap(() => this.latestInfoMessage$.pipe(take(1))),
        take(1)
      )
      .subscribe({
        next: (result: any) => {
          this.fetchData();
          this.rmActionToastHandler(result, 1, false, true);
        },
        error: () => this.resetInProgressAction()
      });
  }

  updateSelected(selected: SoftwareInfo[], useInstallMode = false): void {
    if (!selected.length) return;

    this.actionTypeInProgress = useInstallMode ? ActionTypeInProgress.Install : ActionTypeInProgress.Update;
    this.softwareService
      .updateProduct(this.hid, selected, useInstallMode)
      .pipe(
        switchMap((response: any) => (response?.error?.code ? throwError(() => response) : of(response))),
        distinctUntilChanged(),
        tap((response: any) => {
          if (response?.error) return;

          this.responseAsyncId = this.commandService.getAsyncIdFromResponse(response);
          this.actionInProgress = true;
          if (useInstallMode) this.currentlyInstalling = true;
          this.inProgressAmount = selected.length;

          this.toast.success(
            this.i18n.transform(this.translationKey + (useInstallMode ? 'toasts.installStarted' : 'toasts.updateInProgress'))
          );
          this.setupInstallAndUninstallBtns(true);
        }),
        switchMap(() => this.latestInfoMessage$.pipe(take(1))),
        take(1)
      )
      .subscribe({
        next: (result: any) => {
          this.fetchData();
          this.rmActionToastHandler(result, selected.length, false, useInstallMode);
        },
        error: () => this.resetInProgressAction()
      });
  }

  private rmActionToastHandler(result: any, softwareCount: number, isUninstall = false, isInstall = false) {
    if (result?.isCompletedSuccessfully) {
      this.toast.success(
        `<span class="font-weight-semibold">${this.i18n.transform(this.translationKey + 'toasts.withCounterPart1', {
          count: softwareCount
        })}</span> ${this.getSuccessMessageLastText(isUninstall, isInstall)}`
      );
    } else {
      const successSearchWord = isUninstall ? 'successfully' : 'Successfully installed';
      const successCount = (isUninstall ? result?.errorMessage : result?.message)?.split(successSearchWord).length - 1 ?? 0;

      if (successCount > 0) {
        this.toast.warn(
          `<span class="font-weight-semibold">${this.i18n.transform(this.translationKey + 'toasts.withCounterOfPart1', {
            failedCount: softwareCount - successCount,
            count: softwareCount
          })}</span> ${this.getWarnMessageLastText(isUninstall, isInstall)}`
        );
      } else {
        this.toast.error(
          this.i18n.transform(
            this.translationKey + (isUninstall ? 'toasts.uninstallFailed' : isInstall ? 'toasts.installFailed' : 'toasts.updateFailed')
          )
        );
      }
    }
  }

  private getSuccessMessageLastText(isUninstall = false, isInstall = false) {
    return this.i18n.transform(
      this.translationKey + `toasts.${isUninstall ? 'uninstallSuccessPart2' : isInstall ? 'installSuccessPart2' : 'updateSuccessPart2'}`
    );
  }

  private getWarnMessageLastText(isUninstall = false, isInstall = false) {
    return this.i18n.transform(
      this.translationKey +
        `toasts.${isUninstall ? 'uninstallFailedPartlyPart2' : isInstall ? 'installFailedPartlyPart2' : 'updateFailedPartlyPart2'}`
    );
  }

  resetInProgressAction() {
    this.actionInProgress = false;
    this.currentlyUninstalling = '';
    this.currentlyInstalling = false;
    this.inProgressAmount = 0;
    this.setupInstallAndUninstallBtns();
  }

  parseNewResult(result: any, error: boolean): void {
    if (error) {
      this.toast.error(result.auditText);
    } else {
      this.toast.success(result.auditText);
    }
  }

  showResult(item: UninstalResult): void {
    if (item?.isCompletedSuccessfully) {
      this.toast.success(item?.errorMessage);
    } else {
      this.toast.error(item?.errorMessage);
    }
  }

  fetchData(): void {
    this.onLoad$.next(true);
    this.onFetchLastData$.next({});
    this.onFetchData.emit();
    this.cdr.detectChanges();
  }

  itemsChecked(items: SoftwareInfo[]): void {
    this.handleChangeSelected(items);
    this.setupInstallAndUninstallBtns();
    this.cdr.detectChanges();
  }

  trackBy(index: number, item: ExtendedTableRow): string {
    return item?.item?.name;
  }

  getResultObjectFromResponse(response: CommandServiceResponse): any {
    if (response?.result && response?.agentVersion && versionCompare(response?.agentVersion, this.smallerAgentVersion) > 0) {
      return JSON.parse(response?.result);
    }
    return null;
  }

  public getTableName(): string {
    return `${this.computerName} ${this.i18n.transform('rmm-side-panel:sidePanelTabsName.installedSoftware')} ${moment().format(
      baseDateFileNameFormatHoursAndMinutes
    )}`;
  }

  public setupInstallAndUninstallBtns(disableAll = false): void {
    if (disableAll || !!this.currentlyUninstalling) {
      this.disableInstall = true;
      this.disableUninstall = true;
      return;
    }

    if (this.selected.length) {
      this.disableInstall = this.isInstallSupported ? !this.selected.every((item) => item?.availableVersion) : true;
      this.disableUninstall = false;
    }
  }

  public handleOpenRmmScheduleGroupAction(): void {
    this.modalService.openWizard(RMMGroupActionWizardComponent, { size: 'lg' }).finally(noop);
    setTimeout(() => {
      this.store.dispatch(GAActions.setGActionOsType({ osType: GroupTaskOsTypes.Windows }));
      this.store.dispatch(GAActions.setGActionSelectedType({ actionSelectCommand: WindowsAvailableActions.InstallAndUpdate }));
    }, 0);
  }

  public getOutdatedCount(): number {
    return this.allData.reduce((a, b) => (b?.availableVersion ? ++a : a), 0);
  }

  updateFilters(event): void {
    if (event) {
      this.searchQuery = null;
      if (event.words && event.words.filter((w) => !!w)) {
        this.searchQuery = event.words;
      }
    }
    this.updateDataDebounce(this.getDataWithOudatedFiltering());
  }

  getDataWithOudatedFiltering() {
    return this.showOutdatedOnly ? this.allData.filter((item) => !!item.availableVersion) : this.allData;
  }

  public oudatedFiltering(showOutdatedOnly = true): void {
    this.showOutdatedOnly = showOutdatedOnly;
    this.updateData(this.getDataWithOudatedFiltering());
  }
}
