<h4>{{ 'computers.module:addComputerWizard.readManualStep.header' | i18next }}</h4>

<div class="row">
  <div *ngFor="let data of tilesConfig; let index = index" class="col-6">
    <button (click)="wizardForm.get('mode').patchValue(data.id)"
            [class.-selected]="data.id === wizardForm.value.mode"
            class="app-add-computer-wizard_tile">
      <div class="mx-3 my-2 w-100 d-flex align-items-center">
        <div class="app-add-computer-wizard_tile-icon-wrapper">
              <span [class]="data.icon"
                    [class.mt-1]="index === 0"
                    class="app-add-computer-wizard_tile-icon"></span>
        </div>

        <div class="text-lg font-weight-bold ml-2">{{ data.title }}</div>
      </div>
    </button>
  </div>
</div>

<div class="mt-2">
  <mbs-steps>
    <ng-container *ngTemplateOutlet="stepTemplate; context: {
          number: 1,
          text: 'step1'}">
    </ng-container>
    <ng-container *ngTemplateOutlet="stepTemplate; context: {
          number: 2,
          text: 'step2'}">
    </ng-container>
    <ng-container *ngTemplateOutlet="stepTemplate; context: {
          number: 3,
          text: 'step3'}">
    </ng-container>
    <ng-container *ngIf="wizardForm.value.mode === installationMode.Bulk">
      <ng-container *ngTemplateOutlet="stepTemplate; context: {
          number: 4,
          text: 'step4'}">
      </ng-container>
    </ng-container>
    <ng-template #stepTemplate
                 let-number="number"
                 let-text="text">
      <mbs-step [number]="number"
                [readOnly]="true"
                [bodyClasses]="'text-md'">
        <ng-container title>
          {{ getText(text, 'title') }}
        </ng-container>

        <ng-container body>
          {{ getText(text, 'description') }}
        </ng-container>
      </mbs-step>
    </ng-template>
  </mbs-steps>
</div>
