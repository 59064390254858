import { RmmCommandType } from '@models/rmm/RmmCommandType';

export interface GroupTasksStore {
  groupTasksDataResponse: GroupTasksDataResponse;
  loading: boolean;
  error: string;
}

export interface GroupTasksDataResponse {
  pageNumber: number;
  pageSize: number;
  pageCount: number;
  dataCount: number;
  data: GroupTask[];
  registeredTask: GroupTask;
  taskGuidToEdit: string;
}

export interface GroupTask {
  dateCreated: string;
  dateCreatedUtc: string;
  type: TypeOfGroupTask;
  osType: GroupTaskOsTypes;
  name: string;
  subadminName?: string;
  description: string;
  enableOffline: boolean;
  disabled: boolean;
  completed: boolean;
  eventType: EventTypes;
  scheduleType: number;
  scheduleData: ScheduledDataFromGroupTask;
  scheduleDescription: string;
  scriptGuid: string;
  softwareSourceGuid: string;
  pluginCommandId: RmmCommandType;
  parameters: string;
  allComputers: boolean;
  groupTaskGuid: string;
  profileGuid: string;
  companies: CompanyFromRegGroupTask[];
  computers: ComputerFromGroupTaskComputers[];
  expected?: ComputerFromGroupTaskComputers[];
  computerTags?: number[],
  tasks: TaskFromGroupTask[];
  stateAbortedCount: number;
  stateFailureCount: number;
  stateNoneCount: number;
  stateNotStartedCount: number;
  stateProcessingCount: number;
  stateStartingCount: number;
  stateSuccessCount: number;
  stateTimeoutCount: number;
  pluginPsCommand: boolean;
  pluginActiveCommand: boolean;
  confidentialOutputData: boolean;
  runType: RunTypes;
  dateNext?: DateNext;
  policyId?: string;
  totalComputersCount?: number;
}

export interface DateNext {
  hid: string;
  date: string;
  missingRun: boolean;
}

export interface ScheduledDataFromGroupTask {
  misfireHandling?: number | string;

  sunday?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;

  timeInterval?: number;
  timeStart?: string;
  timeEnd?: string;
  timeUnit?: TimeUnits;
  day?: number;

  dateStart?: string;
  dateEnd?: string;
  dateTimeStart?: string;
}

export interface ComputerFromGroupTaskComputers {
  hid: string;
  disabled: boolean;
  deleted: boolean;
  state: TaskStates;
  result: string;
  error: string;
  message?: string;
  name: string;
  displayName: string;
  computerName: string;
  computerTag: string;
  online: boolean;
  dateStart: string;
  dateNext: string;
  dateEnd: string;
  activeTask: string;
  forced: boolean;
  ownerDateEnd: string;
  ownerDateNext: string;
  ownerDateStart: string;
  progress: number;
  tzOffsetInMinutes: number;
  missingRun: boolean;
  excludedByTaskTags?: boolean;
}

export interface TaskFromGroupTaskResponse {
  pageNumber: number;
  pageSize: number;
  pageCount: number;
  dataCount: number;
  data: TaskFromGroupTask[];
}
export interface TaskFromGroupTask {
  actions: string;
  hid: string;
  taskGuid: string;
  name: string;
  dateStart: string; // date
  progress: number;
  dateEnd: string; // date
  state: number;
  reports: ReportFromTaskFromGroupTask[];
  result: string;
  error: string;
}

export interface ReportFromTaskFromGroupTask {
  hid: string;
  dateReport: string; // date
  taskReportGuid: string;
  progress: number;
  message: string;
  state: number;
}

export interface GroupTaskReg {
  type: TypeOfGroupTask;
  name: string;
  description: string;
  enableOffline: boolean;
  disabled: boolean;
  osType: GroupTaskOsTypes;
  runType: RunTypes;
  eventType: EventTypes;
  scheduleType: number | string;
  scheduleData: ScheduledDataFromGroupTask;
  scriptGuid: string;
  policyId: string;
  softwareSourceGuid: string;
  pluginCommandId: string;
  parameters: string;
  allComputers: boolean;
  computers?: ComputerFromRegGroupTask[];
  companies?: CompanyFromRegGroupTask[];
  computerTags?: number[],
  confidentialOutputData: boolean;
  pluginActiveCommand: boolean;
  pluginPsCommand: boolean;
}

export interface ComputerFromRegGroupTask {
  hid: string;
  name?: string;
  disabled: boolean;
  deleted?: boolean;
}

export type CompanyFromRegGroupTask = {
  companyGuid: string;
  name?: string;
  disabled: boolean;
};

export enum GroupTaskTypes {
  'pluginCommand' = 0,
  'runScript' = 1,
  'installSoftware' = 2,
  'Policy' = 3
}

export type TypeOfGroupTask =
  | 'pluginCommand'
  | GroupTaskTypes.pluginCommand
  | 'runScript'
  | GroupTaskTypes.runScript
  | 'installSoftware'
  | GroupTaskTypes.installSoftware
  | 'Policy'
  | GroupTaskTypes.Policy;

export enum TimeUnits {
  Seconds = 0,
  Minutes = 1,
  Hours = 2
}

export enum SheduleTypes {
  'none' = 0,
  'oneTime' = 1,
  'dailyOneTime' = 2,
  'dailyEveryTime' = 3,
  'weeklyOneTime' = 4,
  'weeklyEveryTime' = 5,
  'monthlyOneTime' = 6
}

export enum RunTypes {
  'none' = 0,
  'immediately' = 1,
  'schedule' = 2,
  'event' = 3
}

export enum TaskStates {
  None = 0,
  Starting = 1,
  Processing = 2,
  Failure = 3,
  Success = 4,
  Aborted = 5,
  Timeout = 6,
  NotStarted = 7
}

// Probably no need in this one
export enum EventTypes {
  'none' = 0,
  'rmmAgentStarted' = 1
}

export type GroupTaskSummary = {
  name: string;
  type: string;
  applyTo: ApplyToInfo;
  schedule: string;
  description?: string;
  updatePolicy?: UpdatePolicyInfo;
};

export type ApplyToInfo = {
  includeInfo: string;
  excludeInfo: string;
  tags?: number[];
};

export type UpdatePolicyInfo = {
  severityLevels: string[];
  updateCategories: string[];
  excludeUpdates: string[];
  deferInfo: string;
  rebootInfo: string;
};

export enum GroupTaskPolicyActions {
  Nope = 0,
  Taken = 1
}

export enum GroupTaskOsTypes {
  None = 0,
  Windows = 1,
  Mac = 2,
  Linux = 4
}

export type GroupTaskFiltersPayload = {
  all?: boolean;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  hid?: string;
  searchText?: string;
  policy?: string;
  scriptGuid?: string;
  groupTaskGuid?: string;
  mbsStageKey?: string;
  computerTags?: string;
};
