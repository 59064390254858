import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ComputerTagsEffects } from './effects';
import { computerTagsReducer } from './reducer';
import { COMPUTER_TAGS_STORE_TYPE } from './state';

@NgModule({
  imports: [StoreModule.forFeature(COMPUTER_TAGS_STORE_TYPE, computerTagsReducer), EffectsModule.forFeature([ComputerTagsEffects])]
})
export class ComputerTagsStoreModule {}
