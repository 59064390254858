<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>Azure</h4>
  </header>

  <ng-container *ngIf="restoreAs === restoreAsDiskEnum.AVM">
    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="account"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Select Account'"
                      [clearable]="false"
                      [id]="'azure-step-account-select'"
                      [items]="azureData.accounts"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-input formControlName="computerName"
                     label="Computer Name"
                     name="computerName"
                     [autocomplete]="'off'"
                     [id]="'azure-step-computer-name-input'"
                     [validationWhitespace]="true"></mbs-input>
        </div>
      </div>
    </div>

    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="locations"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Locations'"
                      [clearable]="false"
                      [id]="'azure-step-locations-select'"
                      [items]="azureData.locations"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-select formControlName="resourceGroup"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Resource Group'"
                      [clearable]="false"
                      [id]="'azure-step-resource-group-select'"
                      [items]="azureData.resourceGroups"></mbs-select>
        </div>
      </div>
    </div>

    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="vmSize"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'VM Size'"
                      [clearable]="false"
                      [id]="'azure-step-vm-size-select'"
                      [items]="azureData.vmSizes"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-select formControlName="network"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Network'"
                      [clearable]="false"
                      [id]="'azure-step-network-select'"
                      [items]="azureData.networks"></mbs-select>
        </div>
      </div>
    </div>

    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="subnet"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Subnet'"
                      [clearable]="false"
                      [id]="'azure-step-subnet-select'"
                      [items]="azureData.subnetItems"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-select formControlName="storage"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Storage'"
                      [clearable]="false"
                      [id]="'azure-step-storage-select'"
                      [items]="azureData.storageItems"></mbs-select>
        </div>
      </div>
    </div>

    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="container"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Container'"
                      [clearable]="false"
                      [id]="'azure-step-container-select'"
                      [items]="azureData.containers"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-select formControlName="bootDiagnosticStorage"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Boot Diagnostic Storage'"
                      [clearable]="false"
                      [id]="'azure-step-boot-diagnostic-storage-select'"
                      [items]="azureData.BDSItems"></mbs-select>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="restoreAs === restoreAsDiskEnum.ADD">
    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="account"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Select Account'"
                      [clearable]="false"
                      [id]="'azure-step-account-select'"
                      [items]="azureData.accounts"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-input formControlName="dataDiskName"
                     label="Data Disk Name"
                     name="dataDiskName"
                     [id]="'azure-step-data-disk-name-select'"
                     [autocomplete]="'off'"></mbs-input>
        </div>
      </div>
    </div>

    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="resourceGroup"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Resource Group'"
                      [clearable]="false"
                      [id]="'azure-step-resource-group-select'"
                      [items]="azureData.resourceGroups"></mbs-select>
        </div>
        <div class="col-6">
          <mbs-select formControlName="storage"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Storage'"
                      [clearable]="false"
                      [id]="'azure-step-storage-select'"
                      [items]="azureData.storageItems"></mbs-select>
        </div>
      </div>
    </div>

    <div class="mbs-form-group">
      <div class="row">
        <div class="col-6">
          <mbs-select formControlName="container"
                      [appendTo]="'body'"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [label]="'Container'"
                      [clearable]="false"
                      [id]="'azure-step-container-select'"
                      [items]="azureData.containers"></mbs-select>
        </div>
      </div>
    </div>
  </ng-container>
</section>
