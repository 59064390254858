<ng-container *ngIf="!isLoading; else loader">

  <div class="mt-3">
    <mbs-alert *ngIf="errorMessage"
               [type]="mbsPopupType.danger"
               [closable]="true"
               [icon]="true"
               (close)="removeError()">
      {{ errorMessage }}
    </mbs-alert>
  </div>

  <div class="row no-gutters">

    <div class="col-3">

      <ul mbsTabset
          mode="vertical"
          [loadOnAppearance]="true"
          #activeContent="mbsTabset"
          class="bg-white h-100 p-0"
          (afterUpdate)="onTabClick($event)">

        <ng-container *ngFor="let item of tabs | async; let index = index">

          <li [mbsTabsetItem]="item.id"
              [disabled]="stepsService.getStepById(item.id).condition === stepCondition.Disabled"
              [active]="stepCondition.Active===stepsService.getStepById(item.id).condition">

            <div *ngIf="!item.hidden"
                 mbsTabsetLink
                 role="button">

              <div class="d-flex align-items-baseline">

                <div class="mbs-onboarding-wizard-common-view_step-condition text-xxs"
                     [ngClass]="'-' + stepsService.getStepById(item.id).condition">
                  {{ index + 1 }}
                </div>

                <div class="d-flex flex-column">
                  <span class="font-weight-semibold">
                    {{ item.title }}
                  </span>
                  <span class="text-xs">
                    {{ item.description }}
                  </span>
                </div>

              </div>

            </div>

            <ng-container [ngSwitch]="item.id">

              <ng-template *ngSwitchCase="stepId.StorageAccount"
                           mbsTabsetContent>
                <div class="mbs-onboarding-wizard-common-view_substep-wrapper">
                  <mbs-storage-account-step [mode]="mode"
                                            [viewMode]="viewMode"
                                            [b2CredentialsForm]="b2CredentialsForm"></mbs-storage-account-step>
                </div>
              </ng-template>

              <ng-template *ngSwitchCase="stepId.SignIn"
                           mbsTabsetContent>
                <div class="mbs-onboarding-wizard-common-view_substep-wrapper">
                  <mbs-apps-sign-in-step [form]="form"></mbs-apps-sign-in-step>
                </div>
              </ng-template>

              <ng-template *ngSwitchCase="stepId.WhatToBackup"
                           mbsTabsetContent>
                <div class="mbs-onboarding-wizard-common-view_substep-wrapper">
                  <mbs-apps-configure-backup-step [form]="form"></mbs-apps-configure-backup-step>
                </div>
              </ng-template>

              <ng-template *ngSwitchCase="stepId.SelectUsers"
                           mbsTabsetContent>
                <div class="mbs-onboarding-wizard-common-view_substep-wrapper">
                  <mbs-apps-select-users-step [form]="form"></mbs-apps-select-users-step>
                </div>
              </ng-template>

              <ng-template *ngSwitchCase="stepId.Summary"
                           mbsTabsetContent>
                <div class="mbs-onboarding-wizard-common-view_substep-wrapper">
                  <mbs-apps-summary-step [form]="form"
                                         [mode]="mode"
                                         [isReadyToBackup]="isReadyToBackup"
                                         [email]="provider.Email"></mbs-apps-summary-step>
                </div>
              </ng-template>

            </ng-container>

          </li>

        </ng-container>

      </ul>

    </div>

    <div class="col border-top border-right border-bottom"
         [mbsTabsetOutlet]="activeContent"></div>

  </div>

  <div *ngIf="!currentStep.isAutomatic"
       class="mt-4 d-flex justify-content-end form-row">

    <div class="col-auto"
         *ngIf="isBackButtonShown()">
      <mbs-button [disabled]="!(onboardingService.stable$ | async)"
                  (click)="stepsService.transition(currentStep.prev, { direction: direction.Back })">
        {{ 'onboarding:buttons.back' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>

    <div class="col-auto"
         *ngIf="currentStep.next !== null">
      <mbs-button [disabled]="!(onboardingService.stable$ | async)"
                  type="primary"
                  (click)="stepsService.transition(currentStep.next, { direction: direction.Next })">
        {{ 'onboarding:buttons.next' | i18next : { format: 'title' } }}
      </mbs-button>
    </div>

    <div class="col-auto"
         *ngIf="currentStep.next === null">
      <mbs-button [disabled]="!(onboardingService.stable$ | async) || !isReadyToBackup"
                  type="success"
                  (click)="finishWizard()">
        {{ 'onboarding:buttons.run' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>

  </div>
</ng-container>

<ng-template #loader>
  <mbs-loader></mbs-loader>
</ng-template>
