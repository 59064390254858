<div class="mbs-container">
  <div class="mbs-container-inner justify-content-center align-items-center">
    <form class="mbs-form mbs-form-welcome" #recovery="ngForm" (ngSubmit)="onPasswordChange(recovery)">
      <div class="mbs-form_content">
        <div class="mbs-form-welcome_header">
          <div class="mbs-form-welcome_header-text">Restore password for</div>
          <div class="mbs-form-welcome_header-name">Managed Backup Service</div>
        </div>

        <fieldset class="mbs-form-welcome_content">

          <div class="mbs-form-group">
            <div class="mbs-form_input-group">
              <input type="email" class="form-control form-control-lg" placeholder="Enter email" email required name="email"
                [class.is-invalid]="email.touched && email.invalid" #email="ngModel" ngModel />
            </div>
          </div>

        </fieldset>

        <div class="mbs-form-group pb-4">
          <button [disabled]="recovery.form.invalid" type="submit" class="btn btn-warning btn-lg btn-block">
            Restore
          </button>
        </div>

        <div class="mbs-form-group">
          <div class="text-center mbs-form_label">
            Don't have an account yet? <a routerLink="/signup">Sign Up</a>
          </div>
        </div>

        <div class="mbs-form-group">
          <div class="text-center mbs-form_label">
            Already have an account? <a routerLink="/login">Login</a>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
