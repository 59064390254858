<mbs-sidepanel side="right"
               innerContentType="inner"
               id="devices-sidepanel"
               [hideSave]="true"
               [loadingData]="loading"
               [headerClass]="'-light'"
               [showExpandedCross]="true"
               (panelOpened)="setDevices()"
               (expandedChange)="onExpandedChange($event)">

  <div header-title
       class="mbs-sidepanel_title">

    <div class="mbs-sidepanel_title-name">
      {{ 'tfa:devicesSidepanel:deviceList' | i18next }}
    </div>

  </div>

  <ng-container body>

    <ng-container *ngIf="devices">
      <ng-container *ngTemplateOutlet="devicesTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="!devices">
      <ng-container *ngTemplateOutlet="noDevicesTemplate"></ng-container>
    </ng-container>

  </ng-container>

</mbs-sidepanel>

<ng-template #devicesTemplate>

  <div class="form-row">
    <div class="col-12">
      <mbs-button icon="fa fa-plus"
                  [id]="'add-device-button'"
                  (click)="addDevice()">
        {{ 'tfa:devicesSidepanel:addNewDevice' | i18next: { format: 'title' } }}
      </mbs-button>
    </div>
  </div>

  <mbs-table-nav>
    <ng-container *ngIf="devices">
      <ng-template mbsTableFilter="label">
        <span id="devices-count">
          {{ 'tfa:devicesSidepanel:devicesCount' | i18next: { count: devices?.length } }}
        </span>
      </ng-template>
    </ng-container>
  </mbs-table-nav>

  <mbs-table-grid [minHeight]="tableMinHeight"
                  [maxHeight]="tableMaxHeight"
                  [data]="devices || []"
                  [multipleSelect]="true"
                  [serverSidePagination]="true"
                  [bindSelected]="'id'"
                  [headers]="headers"
                  [loading]="loading"
                  [selectable]="true">

    <ng-template let-device
                 mbsTableCell>
      {{ device.name }}
    </ng-template>

    <ng-container *ngIf="isExpanded">
      <ng-template let-device
                   mbsTableCell>
        {{ device.ipAddress }}
      </ng-template>
    </ng-container>

    <ng-template let-device
                 mbsTableCell>
      {{ device.lastAccessDate | date: 'mediumDate' }}
    </ng-template>

    <ng-container *ngIf="isExpanded">
      <ng-template let-device
                   mbsTableCell>
        {{ device.location }}
      </ng-template>
    </ng-container>

    <ng-template let-device
                 mbsTableCell>
      <mbs-checkbox [checked]="device.push"
                    (change)="device.push ? disablePush(device) : enablePush(device)"></mbs-checkbox>
    </ng-template>

    <ng-template let-device
                 mbsTableCell>
      <mbs-checkbox [checked]="device.isEnabledTwoFactorAuth"
                    (change)="device.isEnabledTwoFactorAuth ? disableTFA(device) : enableTFA(device)"></mbs-checkbox>
    </ng-template>

    <ng-template mbsTableCell
                 let-device>
      <mbs-button icon="fa fa-times"
                  [type]="'icon'"
                  (click)="deleteDevice(device)">
      </mbs-button>
    </ng-template>

  </mbs-table-grid>

</ng-template>

<ng-template #noDevicesTemplate>

  <div class="d-flex align-items-center justify-content-center flex-column text-center">

    <svg width="77"
         height="81"
         viewBox="0 0 77 81"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">

      <path d="M3.17666 40.4829C3.17666 51.0168 3.17666 61.5507 3.17666 72.0847C3.17666 75.4332 5.56997 77.8236 8.99159 77.8297C16.3601 77.8459 23.7266 77.8297 31.0992 77.8297C34.6811 77.8297 38.2629 77.8297 41.8488 77.8297C42.979 77.8297 43.7204 78.6312 43.7204 79.4395C43.7204 80.2874 42.8148 80.998 41.8488 80.998C41.5005 80.998 41.3566 80.998 40.9138 80.998C30.367 80.998 19.8203 80.998 9.27351 80.998C6.10339 80.998 3.50118 79.7825 1.53989 77.2686C0.525177 75.9849 -0.018177 74.3926 0.000464153 72.7572C0.000464153 51.2558 0.000464153 29.7545 0.000464153 8.25312C0.000464153 4.42242 3.12393 0.980665 7.18241 0.24329C7.91235 0.10903 8.65299 0.0412269 9.3952 0.0407146C24.1303 0.02856 38.8653 0.0873068 53.6105 0.000199307C58.6709 -0.030187 61.5206 3.41765 61.5611 7.80543C61.6544 15.9085 61.5956 23.4037 61.5895 31.5068C61.5895 32.6412 60.9628 33.3664 60.0359 33.3867C59.0766 33.4089 58.4133 32.6574 58.4133 31.4683C58.4133 23.6023 58.4133 16.346 58.3991 8.48203C58.4096 7.74226 58.3285 7.00403 58.1578 6.28409C57.6487 4.29277 56.2249 3.21912 54.1784 3.21912C39.1384 3.21912 24.0992 3.21912 9.06054 3.21912C7.07491 3.21912 5.46247 4.01322 4.1928 5.50215C3.46062 6.35702 3.17869 7.35369 3.17869 8.49216C3.18816 19.1517 3.18748 29.8153 3.17666 40.4829Z"
            fill="#424242" />
      <path d="M9.69944 16.2468V63.2443H41.1978C41.4675 63.2443 41.7393 63.2321 42.0091 63.2443C43.0374 63.2969 43.7473 63.9654 43.731 64.8649C43.7148 65.7643 43.0353 66.3579 42.0537 66.4186C41.8509 66.4308 41.648 66.4186 41.4452 66.4186H8.79079C6.96539 66.4186 6.48267 65.9385 6.48267 64.164C6.48267 47.8607 6.48267 31.5561 6.48267 15.2501C6.48267 13.514 6.98161 13.0076 8.69749 13.0076C23.4008 13.0076 38.1034 13.0076 52.8053 13.0076C54.6064 13.0076 55.0911 13.4917 55.0911 15.2825C55.0911 20.752 55.0911 25.6138 55.0911 31.0834C55.1057 31.3868 55.0969 31.6909 55.0647 31.9929C54.937 32.8215 54.2311 33.4454 53.4564 33.3846C52.6106 33.3178 52.0974 32.8255 51.9433 31.999C51.9063 31.6973 51.8968 31.3929 51.9149 31.0895C51.9149 26.3316 51.9149 22.1795 51.9149 17.4176V16.2528L9.69944 16.2468Z"
            fill="#424242" />
      <path d="M46.2399 58.3176C46.2399 52.5456 46.2399 46.7742 46.2399 41.0035C46.2399 37.7238 48.2682 35.694 51.5356 35.692C58.2599 35.692 64.9841 35.692 71.7084 35.692C74.986 35.692 77 37.7177 77 41.0076C77 52.5503 77 64.0931 77 75.6358C77 79.0087 75.0164 80.998 71.6556 81C64.9638 81 58.2707 81 51.5762 81C48.2317 81 46.2359 78.9884 46.2339 75.6338C46.2352 69.8631 46.2372 64.091 46.2399 58.3176ZM49.4202 58.3176C49.4202 64.1248 49.4202 69.9319 49.4202 75.7391C49.4202 77.2625 49.982 77.8277 51.4829 77.8297H71.6556C73.2944 77.8297 73.8218 77.2949 73.8218 75.6378C73.8218 64.0951 73.8218 52.5523 73.8218 41.0096C73.8218 39.4234 73.2741 38.8704 71.7063 38.8704H51.5356C49.9698 38.8704 49.4202 39.4275 49.4202 41.0116C49.4188 46.7809 49.4188 52.551 49.4202 58.3217V58.3176Z"
            fill="#424242" />
      <path d="M27.0327 9.69345C25.905 9.67927 25.1789 9.06142 25.1565 8.14172C25.1342 7.18759 25.8908 6.52112 27.0712 6.52112C29.5632 6.50896 32.0572 6.50896 34.5533 6.52112C35.679 6.52112 36.4071 7.15113 36.4294 8.07487C36.4517 9.03103 35.7013 9.67725 34.5168 9.69548L27.0327 9.69345Z"
            fill="#424242" />
      <path d="M34.241 71.3108C34.2329 71.9358 34.0393 72.5443 33.6847 73.0593C33.3301 73.5743 32.8305 73.9727 32.2491 74.204C31.6677 74.4352 31.0307 74.489 30.4187 74.3584C29.8068 74.2278 29.2473 73.9189 28.8113 73.4706C28.3752 73.0223 28.0821 72.4548 27.9692 71.84C27.8562 71.2253 27.9284 70.5909 28.1766 70.0171C28.4249 69.4434 28.838 68.9561 29.3637 68.617C29.8894 68.2779 30.504 68.1022 31.1297 68.1122C31.9648 68.1308 32.7592 68.4766 33.3413 69.075C33.9233 69.6735 34.2465 70.4765 34.241 71.3108Z"
            fill="#424242" />
      <path d="M61.587 43.7241C61.5201 43.7241 60.0883 43.7241 59.2551 43.7241C58.3038 43.7241 57.6122 43.009 57.6102 42.1541C57.6081 41.2992 58.2884 40.5761 59.2327 40.5761C60.246 40.5761 62.4392 40.576 63.9788 40.5761C64.9535 40.5761 65.6217 41.2851 65.6237 42.144C65.6257 43.0029 64.9512 43.7261 64.0011 43.7261C63.1982 43.7261 61.6539 43.7241 61.587 43.7241Z"
            fill="#424242" />
      <path d="M64.1735 73.8207C64.1147 75.2225 62.8612 76.3732 61.4922 76.284C60.9909 76.2579 60.5085 76.0845 60.1055 75.7856C59.7025 75.4868 59.3967 75.0757 59.2266 74.604C59.0565 74.1323 59.0296 73.621 59.1493 73.1341C59.269 72.6472 59.5299 72.2064 59.8993 71.867C60.2688 71.5276 60.7303 71.3047 61.2261 71.2262C61.7218 71.1477 62.2298 71.2171 62.6862 71.4257C63.1426 71.6344 63.5273 71.9729 63.7918 72.399C64.0564 72.825 64.1892 73.3196 64.1735 73.8207Z"
            fill="#424242" />
    </svg>

    <span class="mt-4">
      {{ 'tfa:devicesSidepanel:noDevices' | i18next }}
    </span>

    <span>{{ 'tfa:devicesSidepanel:addNewDeviceInfo' | i18next }}</span>

    <div class="form-row mt-4">
      <div class="col-12">
        <mbs-button icon="fa fa-plus"
                    (click)="addDevice()">
          {{ 'tfa:devicesSidepanel:addNewDevice' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>
    </div>

  </div>

</ng-template>
