<h4>{{'onboarding:backup_source' | i18next: { format: 'title' } }}</h4>

<ng-container *ngIf="!settings.isLinux">
  <div class="d-flex justify-content-between">
    <div class="font-weight-semibold">{{'onboarding:select_backup_type' | i18next: { format: 'title'} }}</div>

    <ng-container *ngIf="selectedSource.type === PlanType.Plan">
      <mbs-button type="primary"
                  [isCtrl]="true"
                  (click)="showLegend()">
        {{'wizards:show_legend_text' | i18next: {format: 'title'} }}
      </mbs-button>
    </ng-container>
  </div>
  
  <div class="row my-3">
    <ng-container *ngFor="let source of sources">
      <div class="col-6">
        <mbs-tile [selectable]="true"
                  [selected]="source.type === selectedSource.type"
                  [tileTemplate]="tileTemplate"
                  (click)="onSourceChanged(source)">
          <ng-template #tileTemplate>
            <div class="d-flex align-items-baseline mbs-backup-source-step_source w-100"
                 [class.-selected]="source.type === selectedSource.type">
              <div class="mbs-backup-source-step_source-icon">
                <img src="{{source.icon}}"/>
              </div>
              <div class="d-flex flex-column">
                <span class="font-weight-semibold">{{source.title}}</span>
                <span class="mbs-backup-source-step_source-description">{{source.description}}</span>
              </div>
            </div>
          </ng-template>
        </mbs-tile>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!isDataLoading; else loader">
  <ng-container *ngIf="selectedSource.type === PlanType.BackupDiskImagePlan">
    <mbs-what-backup-step [view]="whatBackupViewMode.Onboarding"
                          [settings]="{isNBF: settings?.isNBF, backupVersionUpdated: settings?.backupVersionUpdated, mode}"
                          (ngModelChange)="whatBackupStep.next($event)"
                          [ngModel]="whatBackupStep | async"></mbs-what-backup-step>
  </ng-container>

  <ng-container *ngIf="selectedSource.type === PlanType.Plan">
    <mbs-what-backup-tree-step [view]="whatBackupViewMode.Onboarding"
                               [settings]="{hid: settings?.hid, isLinux: settings?.isLinux}"
                               (ngModelChange)="whatBackupTreeStep.next($event)"
                               [ngModel]="whatBackupTreeStep | async"></mbs-what-backup-tree-step>
  </ng-container>
</ng-container>

<ng-template #loader>
  <div class="mt-4 position-relative p-5">
    <mbs-loader [size]="'small'"></mbs-loader>
  </div>
</ng-template>
