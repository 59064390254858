import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StorageType } from '@models/StorageType.enum';
import { OnboardingView } from '@modules/wizards/onboarding/onboarding.constants';
import { StepsService } from '@modules/wizards/onboarding/services/steps/steps.service';
import { BaseStep } from '@modules/wizards/onboarding/steps/shared/base/base.step';
import { OnboardingMode } from '@modules/wizards/services/onboarding/onboarding.constants';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { CreateStorageAccountB2DataType } from '@modules/wizards/services/onboarding/onboarding.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StorageAccountsService } from '@services/storage-accounts/storage-accounts.service';
import { CompanyType } from '@services/storage-accounts/storage-accounts.types';
import { AbilityService } from 'ability';
import { I18NextPipe } from 'angular-i18next';
import { mergeMap, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Icons } from './storage-account.constants';

@UntilDestroy()
@Component({
  selector: 'mbs-storage-account-step',
  templateUrl: './storage-account.component.html',
  styleUrls: ['./storage-account.component.scss']
})
export class StorageAccountComponent<StepId> extends BaseStep<StepId> implements OnInit {
  public readonly stepId = 'storageAccount';
  public readonly isValid = true;
  public readonly storageNameByTypeMap = {
    [OnboardingMode.Wasabi]: 'wasabi',
    [OnboardingMode.AWS]: 'amazon'
  };
  public readonly onboardingView = OnboardingView;
  public readonly backupMode = OnboardingMode;
  public storageId: string;
  public errorMessage: string;

  @Input() mode: OnboardingMode;
  @Input() b2CredentialsForm: UntypedFormGroup;
  @Input() viewMode: OnboardingView;

  public get icons() {
    const excludedIcons = this.viewMode === OnboardingView.Apps ? ['LocalStorage'] : [];

    return Icons.filter((icon) => !excludedIcons.includes(icon));
  }

  constructor(
    private i18N: I18NextPipe,
    private stepService: StepsService<StepId>,
    private onboardingService: OnboardingService,
    private storageAccountService: StorageAccountsService,
    public ability: AbilityService
  ) {
    super(stepService);
  }

  ngOnInit() {
    this.onboardingService.stable$.next(true);
    if (this.mode === OnboardingMode.B2) {
      this.stepService.customTransitionForward.pipe(untilDestroyed(this)).subscribe((step) => {
        if (step?.id !== (this.stepId as unknown as StepId)) return;

        this.transition(step?.next);
      });

      this.stepService.setProperty(this.stepId as unknown as StepId, 'customTransitionForward', true);
    }
  }

  transition(stepId: StepId): void {
    if (!this.b2CredentialsForm?.valid) return;
    const { publicKey, secretKey, storageUnitName } = this.b2CredentialsForm.value;

    const isAccountAlreadyUsed = this.b2CredentialsForm?.value?.alreadyUsedAccounts?.some((account) => {
      return account.publicKey === publicKey && account.secretKey === secretKey && account.storageUnitName === storageUnitName;
    });

    const query$ = isAccountAlreadyUsed
      ? of(null)
      : this.onboardingService
          .validateB2({
            publicKey,
            secretKey
          })
          .pipe(
            untilDestroyed(this),
            switchMap(() => {
              return this.storageAccountService.getCompanies();
            }),
            mergeMap((companies) => {
              return this.onboardingService.createB2Storage(this.generateB2StorageData(companies[0]));
            }),
            tap(() => {
              const alreadyUsedAccounts = this.b2CredentialsForm?.value?.alreadyUsedAccounts;
              this.b2CredentialsForm?.get('alreadyUsedAccounts').patchValue([
                ...alreadyUsedAccounts,
                {
                  publicKey,
                  secretKey,
                  storageUnitName
                }
              ]);
            })
          );

    query$.subscribe({
      next: () => {
        this.stepService.customTransitionForward.next(null);
        this.stepService.transition(stepId);
      },
      error: (err) => {
        const message = err.message === 'bad_auth_token' ? this.i18N.transform('error:b2.badAuthToken') : err.message;
        this.showAlert(message);
      }
    });
  }

  public generateB2StorageData(company: CompanyType): CreateStorageAccountB2DataType {
    const { publicKey, secretKey, storageUnitName } = this.b2CredentialsForm.value;

    return {
      credentialsInfo: {
        storageType: StorageType.BackblazeB2,
        publicKey,
        secretKey,
        accountDisplayName: storageUnitName
      },
      destinationInfo: {
        storageUnitName,
        destinationDisplayName: storageUnitName,
        storageUnitCreationOption: 0,
        assignTo: 0,
        assignToIds: [company.value]
      },
      assignedToNamesOrEmails: [company]
    };
  }

  getText(part: string): string {
    return this.i18N.transform(`onboarding:storage.${this.storageNameByTypeMap[this.mode]}.${part}`);
  }
}
