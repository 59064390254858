<mbs-button [ngbTooltip]="'rmm-side-panel:reboot.tooltip' | i18next"
            triggers="hover"
            [size]="'md'"
            [container]="'body'"
            [placement]="['top', 'auto']"
            [disabled]="disabled"
            (click)="openConfirmModal()">
  <span [class]="icon"></span>
  <span>{{ 'rmm-side-panel:reboot.btnName' | i18next }}</span>
</mbs-button>

<ng-template #confirmationModalContent>
  <mbs-alert [type]="AlertType.warning"
             [icon]="true">
    {{ 'rmm-side-panel:reboot.confirmationModalAlert' | i18next }}
  </mbs-alert>
</ng-template>
