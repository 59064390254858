import * as ansiEscapes from 'ansi-escapes';
import * as ansiStyle from 'ansi-colors';

import { COLOR_WHITE, EMPTY_COMMAND, NEW_LINE } from '../const';
import { LineTypes, ShellCommandOperator } from './model';

import { PromptBuffer } from './prompt-buffer';
import { ShellLine } from './shell-line';
import { colorPowerShellWrite } from './utils';

/**
 * calc cursor position for move be Y axis
 * @param {PromptBuffer} promptBuffer
 * @param {ShellLine} sibling
 * @param {(1 | -1)} direction
 * @return {*} { cursorX, cursorY }
 */
function moveY(promptBuffer: PromptBuffer, sibling: ShellLine, direction: 1 | -1) {
  const cursorY = promptBuffer.relativeY + direction;
  const maxPosX = sibling.length > sibling.offset ? sibling.length : sibling.offset;
  const minPosX = sibling.offset;

  let cursorX = promptBuffer.cursorX;

  if (maxPosX < promptBuffer.cursorX) {
    cursorX = maxPosX;
  }

  if (promptBuffer.cursorX < minPosX) {
    cursorX = minPosX;
  }

  return { cursorX, cursorY };
}
/*
 * show action and buffer info, only for debag
 * @param action
 * @returns
 */
export const debug: ShellCommandOperator = action => promptBuffer => {
  console.warn('\n Action is :: ', action, '\n Buffer is :: ', promptBuffer);
  return EMPTY_COMMAND;
};
/**
 * paste text in terminal
 * with all data after cursor, because terminal used rewrite mode by default
 * @param {KeyAction} action
 * @return {string[]}
 */
export const paste: ShellCommandOperator = action => promptBuffer => {
  if (!action.payload && !action.payload.write) {
    return EMPTY_COMMAND;
  }
  const paste = promptBuffer.parsePaste(action.payload.write);
  const colored = colorPowerShellWrite(paste, promptBuffer.hasSpaceBefore());
  const lines = promptBuffer.getReminder(colored.join('\n'));
  return promptBuffer.unionInput(lines);
}

/**
 * insert text in terminal
 * with all data after cursor, because terminal used rewrite mode by default
 * @param {KeyAction} action
 * @return {string[]}
 */
export const insert: ShellCommandOperator = action => promptBuffer => {
  if (!action.payload && !action.payload.write) {
    return EMPTY_COMMAND;
  }
  const lines = promptBuffer.getReminder(action.payload.write);
  const colored = colorPowerShellWrite(lines, promptBuffer.hasSpaceBefore());
  return promptBuffer.unionInput(colored);
};
/**
 * Serialize output
 * @param {KeyAction} action
 * @return {string[]}
 */
export const output: ShellCommandOperator = action => promptBuffer => {
  return promptBuffer.relativeY == 0 
    ? action.payload.output
    : NEW_LINE + action.payload.output;
}
/**
 * restore command from string for terminal, by config. used multiline
 * @param {KeyAction} action
 * @return {string[]}
 */
export const history: ShellCommandOperator = action => promptBuffer => {
  if (!action.history) {
    return EMPTY_COMMAND;
  }
  const writeArray = action.history.split(promptBuffer.config.newLine).map(i => i.replace(promptBuffer.config.promptNewLine, ''));
  const colored = colorPowerShellWrite(writeArray, promptBuffer.hasSpaceBefore());
  return promptBuffer.unionInput(colored);
};
/**
 * insert script
 * @param {KeyAction} action
 * @return {string[]}
 */
export const script: ShellCommandOperator = action => promptBuffer => {
  if (!action.payload) {
    return EMPTY_COMMAND;
  }
  const writeArray = action.payload.split('\n');

  const colored = colorPowerShellWrite(writeArray, promptBuffer.hasSpaceBefore());
  return promptBuffer.unionInput(colored);
};
/**
 * delete cursor next char and rewrite all data after, because terminal used rewrite mode by default.
 * @param {KeyAction} _action
 * @return {string}
 */
export const del: ShellCommandOperator = _action => promptBuffer => {
  const writeArray = colorPowerShellWrite(promptBuffer.getReminder('', 1), promptBuffer.hasSpaceBefore());
  const remainder = promptBuffer.unionInput(writeArray);
  return ansiEscapes.cursorSavePosition +  remainder + ansiEscapes.cursorRestorePosition;
};
/**
 * clear all data after cursor
 * @param {KeyAction} _action
 * @return {string}
 */
export const clear: ShellCommandOperator = _action => promptBuffer => {
  return ansiEscapes.eraseDown;
};
/**
 * get data after cursor
 * @param {KeyAction} _action
 * @return {string}
 */
export const reminder: ShellCommandOperator = _action => promptBuffer => {
  const lines = promptBuffer.getLines();
  const writeArray = colorPowerShellWrite(lines, promptBuffer.hasSpaceBefore());
  return promptBuffer.unionInput(writeArray);
};
/**
 * move cursor left, if line is over jump to last char previous line
 * ignore move if position === line offset
 * @param {KeyAction} _action
 * @return {string}
 */
export const left: ShellCommandOperator = _action => promptBuffer => {
  const line = promptBuffer.siblings.get(1);

  if (promptBuffer.cursorX <= line.offset && line.type === LineTypes.Prompt) {
    return EMPTY_COMMAND;
  }

  const { cursorX, cursorY } = promptBuffer.getPrevPosition();

  return ansiEscapes.cursorTo(cursorX, cursorY);
};
/**
 * move cursor right, if line is over jump to 0 position next line.
 * if has insert - add move offset by insert length
 * @param {KeyAction} action
 * @return {string}
 */
export const right: ShellCommandOperator = action => promptBuffer => {
  const line = promptBuffer.siblings.get(1);
  const write = (action.payload && action.payload.write) || EMPTY_COMMAND;
  const position = promptBuffer.getNextPosition(write);

  if (!write && position.cursorX > line.length && !position.jump) {
    return EMPTY_COMMAND;
  }

  return ansiEscapes.cursorTo(position.cursorX, position.cursorY);
};
/**
 * move cursor prev line, with prev line offsets. only for multiline mode
 * @param {KeyAction} _action
 * @return {string}
 */
export const up: ShellCommandOperator = _action => promptBuffer => {
  const sibling = promptBuffer.siblings.get(0);
  if (promptBuffer.siblings.get(1).type !== LineTypes.New) return EMPTY_COMMAND;

  const { cursorX, cursorY } = moveY(promptBuffer, sibling, -1);

  return ansiEscapes.cursorTo(cursorX, cursorY);
};
/**
 * move cursor next line, with next line offsets. only for multiline mode
 * @param {KeyAction} _action
 * @return {string}
 */
export const down: ShellCommandOperator = _action => promptBuffer => {
  const sibling = promptBuffer.siblings.get(2);
  if (sibling.type !== LineTypes.New) return EMPTY_COMMAND;

  const { cursorX, cursorY } = moveY(promptBuffer, sibling, 1);

  return ansiEscapes.cursorTo(cursorX, cursorY);
};
/**
 * move to prompt
 * @param {KeyAction} _action
 * @return {string}
 */
export const home: ShellCommandOperator = _action => promptBuffer => {

  const line = promptBuffer.getPrompt();

  const cursorY = line.y - line.viewportY;
  const cursorX = line.offset;

  return ansiEscapes.cursorTo(cursorX, cursorY);
};
/**
 * move to last char, last line
 * @param {KeyAction} _action
 * @return {string}
 */
export const end: ShellCommandOperator = _action => promptBuffer => {
  const line = promptBuffer.getEnd();

  const cursorY = line.y - line.viewportY;
  const cursorX = line.length > line.offset ? line.length : line.offset;

  return ansiEscapes.cursorTo(cursorX, cursorY);
};
/**
 * show internal error in terminal window
 * @param {KeyAction} action
 * @return {string}
 */
export const error: ShellCommandOperator = action => promptBuffer => {
  const printError = ansiStyle.bgRed(COLOR_WHITE + promptBuffer.config.errorLine + action.payload.error);
  return ansiEscapes.cursorSavePosition + promptBuffer.config.newLine + printError + ansiEscapes.cursorRestorePosition;
};
/**
 * set new line in multiline mode
 * @param {KeyAction} _action
 * @return {string}
 */
export const newLine: ShellCommandOperator = _action => promptBuffer => {
  return COLOR_WHITE + promptBuffer.config.newLine + promptBuffer.config.promptNewLine;
};
/**
 * set prompt line
 * @param {KeyAction} _action
 * @return {string}
 */
export const prompt: ShellCommandOperator = _action => promptBuffer => {
  return ansiEscapes.eraseLine + ansiEscapes.cursorLeft + COLOR_WHITE + promptBuffer.config.prompt;
};
/**
 * set empty line
 * @param {KeyAction} _action 
 * @return {string}
 */
export const line: ShellCommandOperator = _action => promptBuffer => {
  return promptBuffer.config.newLine;
}