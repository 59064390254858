<div [ngClass]="[size ? '-' + size : '']">
  <mbs-input-label *ngIf="label && id"
                   [label]="label"
                   [id]="id"
                   [labelSize]="labelSize"
                   [disabled]="disabledState"
                   [info]="info"
                   [boldLabel]="boldLabel"
                   [infoPlacement]="infoPlacement"
                   [infoTriggers]="infoTriggers"
                   [infoContainer]="infoContainer"
                   [required]="hasRequiredValidator && showRequiredMark"
                   [labelClasses]="labelClasses"
                   [labelContentClasses]="labelContentClasses"></mbs-input-label>
  <div class="input-group mbs-number-input-group">
    <input [id]="id"
           [type]="'text'"
           class="form-control mbs-number-input"
           [pattern]="ipPattern"
           [placeholder]="placeholder"
           [disabled]="disabledState"
           [tabindex]="tabindex"
           [attr.aria-describedby]="id"
           [attr.aria-labelledby]="label"
           autocomplete="off"
           [ngClass]="bindClasses"
           (focus)="handleFocus($event)"
           [imask]="imaskOpts"
           (change)="valueChanged($event)"
           (input)="handleInput($event)"
           [unmask]="false"
           (accept)="handleAccept($event)">
  </div>
  <!-- inner error handler -->
  <mbs-input-errors *ngIf='!isValidIp'
                    [errors]="[{ message: innerErrorMsg }]"></mbs-input-errors>
  <!-- regular errors -->
  <mbs-input-errors [errors]="errors"
                    [errorsTemplate]="errorsTemplate?.templateRef"></mbs-input-errors>
</div>
