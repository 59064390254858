import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { environment } from '@mbs-ui/environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RmmPowershellService } from '@services/rmm-powershell.service';
import { MbsSize, ModalService, ModalSettings, ToastService } from 'mbs-ui-kit';
import { NgTerminal } from 'ng-terminal';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { FitAddon } from 'xterm-addon-fit';
import { ShellAddon } from '../shell-addon/addon-legacy';
import { PromptBuffer } from '../shell-addon/prompt-buffer';

@UntilDestroy()
@Component({
  selector: 'mbs-ng-terminal-wrapper',
  templateUrl: './ng-terminal-wrapper.component.html',
  styleUrls: ['./ng-terminal-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgTerminalWrapperComponent implements AfterViewInit, OnChanges, OnDestroy {
  executeScript$: BehaviorSubject<any> = new BehaviorSubject(null);

  private defaultTheme = {
    background: '#2D6CA2'
  };

  private modalSettings: ModalSettings = {
    size: MbsSize.xl,
    header: { title: 'Remote PowerShell terminal', icon: 'ico-hyperv', showExpandedCross: true },
    footer: { show: false },
    collapsing: true
  };

  @Input() showModalButton = true;
  @Input() autoFit = false;
  @Input() warning = '';

  @ViewChild('terminal') ngTerminal: NgTerminal;
  @Output() public initialized: EventEmitter<NgTerminal> = new EventEmitter();
  @Output() public warningText: EventEmitter<string> = new EventEmitter();
  @Output() public showModal = new EventEmitter<boolean>();
  @Output() public inputChange: Subject<string>;
  @Output() public bufferChange: Subject<PromptBuffer>;
  @Output() public executeCommand: Subject<string>;
  @Output() public breakCommand: EventEmitter<string> = new EventEmitter();
  @Output() public errorCommand: Subject<string>;
  @Output() public onPaste = new EventEmitter<any>();

  public commandShellAddon: ShellAddon;
  private lastCommand: string;

  get textaria() {
    return this.commandShellAddon.textaria || null;
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private rmmPowershellService: RmmPowershellService,
    private modalService: ModalService,
    private toast: ToastService,
    public store$: Store,
    @Optional() @Inject('IS_MODAL_CONTENT_OPEN') private IS_MODAL_CONTENT_OPEN: BehaviorSubject<boolean>
  ) {
    this.cdRef.detach();

    const outputBuffer = this.rmmPowershellService.outputBuffer;
    this.commandShellAddon = new ShellAddon(outputBuffer, true);

    this.executeCommand = this.commandShellAddon.executeCommand$;
    this.executeCommand.pipe(untilDestroyed(this)).subscribe((command) => {
      this.lastCommand = command;
    });
    this.commandShellAddon.breakCommand$.pipe(untilDestroyed(this)).subscribe(() => this.breakCommand.emit(this.lastCommand));
    this.breakCommand.pipe(untilDestroyed(this)).subscribe((e) => {
      this.lastCommand = null;
    });
    this.errorCommand = this.commandShellAddon.errorCommand$;
    this.errorCommand.pipe(untilDestroyed(this)).subscribe((errorMessageText) => {
      this.toast.error(errorMessageText);
    });

    this.inputChange = this.commandShellAddon.inputChange$;
    this.bufferChange = this.commandShellAddon.bufferChange$;
  }

  resetTerminal(cleanHistory = false) {
    this.commandShellAddon.history.clear();

    if (cleanHistory) {
      this.rmmPowershellService.resetOutputBuffer();
    }
    setTimeout(() => {
      this.ngTerminal['fitAddon'].fit(); // VERY DIRTY HACK
    }, 100);
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();

    // load CommandShellAddon addon
    this.ngTerminal.underlying.loadAddon(this.commandShellAddon);
    this.ngTerminal.underlying.attachCustomKeyEventHandler((e: KeyboardEvent): boolean => {
      if ((e.key.toLowerCase() === 'v' && (e.ctrlKey || e.metaKey)) || (e.key.toLowerCase() === 'insert' && e.shiftKey)) {
        window.navigator.clipboard
          .readText()
          .then((text) => {
            this.onPaste.emit(text);
          })
          .catch((err) => {
            this.toast.error('Failed to read clipboard');
          });
      }
      return true;
    });

    if (this.autoFit) {
      // load autofit addon
      const fitAddon = new FitAddon();
      this.ngTerminal.underlying.loadAddon(fitAddon);
      fitAddon.fit();
    }

    this.rmmPowershellService.writeOutput.pipe(untilDestroyed(this)).subscribe((text) => {
      // print output only if lastCommand not empty
      if (this.lastCommand) {
        this.commandShellAddon.dispatchOutput(text);
      }
    });

    this.rmmPowershellService.warningOutput.pipe(untilDestroyed(this)).subscribe((warning) => {
      this.warning = warning;
      this.warningText.emit(this.warning);
    });

    this.rmmPowershellService.commandExecuting.pipe(untilDestroyed(this)).subscribe((command) => {
      // for synchronous printing command on two opened terminals:
      // print only if it's typed on another terminal
      if (command && command !== this.commandShellAddon.getCurrentCommand()) {
        this.lastCommand = command;
        this.commandShellAddon.writeCommand(command, true);
      }
    });

    this.rmmPowershellService.commandCompleted.pipe(untilDestroyed(this), debounceTime(100), delay(300)).subscribe((message) => {
      this.complete();
    });

    this.initTerminal(this.ngTerminal);
    this.initialized.emit(this.ngTerminal);

    // focus to terminal
    setTimeout(() => this.ngTerminal.underlying.textarea.focus(), 0);
  }

  ngOnInit(): void {
    //
  }

  complete = () => {
    this.commandShellAddon.setExecutingComplete();
  };

  execute = () => {
    this.commandShellAddon.execute();
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModalButton || changes.warning) {
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    // this.commandShellAddon.destroy();
  }

  initTerminal(ngTerminal: NgTerminal): void {
    ngTerminal.setXtermOptions({
      theme: {
        ...this.defaultTheme,

        border: this.defaultTheme.background
      },
      fontSize: 12,
      cursorBlink: true,
      disableStdin: true
    });
  }

  handleScroll(event: Event): void {
    event.preventDefault();
  }
}
