<mbs-form-group [formGroup]="registrySearchForm">
  <mbs-input [placeholder]="'rmm-side-panel:registryTab.search' | i18next"
             formControlName="searchValue"
             [validationWhitespace]="true"
             [buttonClickOnEnter]="true"
             [clearButton]="true"
             (clear)="handleClear($event)"
             (buttonClick)="handleSearch($event)"
             [appendButtons]="[{
               text: '',
               id: 'registry-tab-search',
               type: 'secondary',
               icon: 'ico ico-Search',
               loading: isLoading
            }]">
  </mbs-input>
</mbs-form-group>

<div *ngIf="breadCrumbs?.length"
     class="mb-2">
  <mbs-breadcrumbs (navigate)="handleBreadCrumbNavigate($event)"
                   [list]="breadCrumbs"
                   bindLabel="label"></mbs-breadcrumbs>
</div>

<mbs-alert *ngIf="nothingFound"
           [type]="alertType.info"
           [closable]="true"
           (close)="nothingFound = false">
  {{ 'rmm-side-panel:nothingFound' | i18next }}
</mbs-alert>

<mbs-tree (click)="treeClick($event)"
          [childrenManualControl]="true"
          [lazy]="true"
          [data]="regTree.regTreeData"
          [disableChildren]="disableChildren"
          [dontSelectChildren]="dontSelectChildren"
          [getItems]="getSubtree.bind(this)"
          [lazy]="true"
          [height]="'calc(100vh - 315px)'"
          [selectable]="true"
          [virtualItemsNumber]="15"
          [virtualScroll]="true"
          [classesForRoot]="'mbs-tree_reg-tree'"
          #treeComponent>
  <ng-template let-item
               treeElement>
    <span class="form-row align-items-center">
      <span class="col-auto d-flex">
        <img [src]="item.treeIcon"
             alt="" />
      </span>
      <span class="col text-overflow"
            [ngbTooltip]="item.value.type ? item.value.value : item.label"
            [placement]="'left'"
            container="body">
        <pre class="mbs-reg-tree-label-pre-wrap mb-0 text-blue-link">{{ item.label }}</pre>
      </span>
    </span>
  </ng-template>
  <ng-template let-item
               *ngIf="readOnly === false"
               mbsTreeAppend>
    <div [placement]="['bottom-right', 'auto']"
         container="body"
         display="dynamic"
         ngbDropdown
         (openChange)="dropdownChange($event)">
      <mbs-button [isCtrl]="true"
                  class="-no-arrow"
                  icon="ico ico-EllipsisH"
                  ngbDropdownToggle
                  size="sm"
                  type="dark"></mbs-button>
      <div ngbDropdownMenu>
        <button (click)="handleAddKey($event, item)"
                ngbDropdownItem
                [disabled]="item.value.type !== null">
          {{ 'buttons:addKey' | i18next }}
        </button>
        <button (click)="handleAddValue($event, item)"
                ngbDropdownItem
                [disabled]="item.value.type !== null">
          {{ 'buttons:addValue' | i18next }}
        </button>
        <button (click)="handleEdit($event, item)"
                ngbDropdownItem>
          {{ 'buttons:edit' | i18next }}
        </button>
        <button (click)="handleDelete($event, item)"
                ngbDropdownItem>
          {{ 'buttons:delete' | i18next }}
        </button>
      </div>
    </div>
  </ng-template>
</mbs-tree>

<ng-template #editForm>
  <div [formGroup]="form">

    <div class="mbs-form-group">
      <mbs-input mbsAutoFocus
                 [disabled]="!keyMode && !addKeyValueMode"
                 [id]="'imputName'"
                 [trim]="true"
                 formControlName="name"
                 label="{{ 'rmm-side-panel:registryTab.name' | i18next }}"></mbs-input>
    </div>

    <div [hidden]="keyMode"
         class="mbs-form-group">

      <mbs-select [clearable]="false"
                  [disableControl]="!addKeyValueMode"
                  [items]="registryTypesList"
                  [labelForId]="'regType'"
                  bindLabel="name"
                  bindValue="key"
                  class="mbs-select"
                  formControlName="regType"
                  label="{{ 'rmm-side-panel:registryTab.type' | i18next }}"></mbs-select>
    </div>

    <div class="mbs-form-group">
      <mbs-input [hidden]="keyMode"
                 [id]="'inputValue'"
                 [trim]="true"
                 [type]="valueInputType"
                 formControlName="value"
                 label="{{ 'rmm-side-panel:registryTab.value' | i18next }}"
                 rows="4"></mbs-input>
    </div>
  </div>
</ng-template>

<ng-template #messageBox>
  {{ messageText }}
</ng-template>
