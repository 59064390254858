<mbs-modal [title]="'MSP360 Storage Subscription'" [isCustomModal]="true" [showFooter]="true" #modal>
  <div modal-body>
    <div class="row">
      <div class="col-auto">
        <span class="mbs-storage mbs-storage-lg mbs-storage_CblWasabi switch-commercial_CblWasabi"></span>
      </div>
      <div class="col">
        <h4>MSP360 Storage (Wasabi)</h4>
        <p>
          MSP360 in&nbsp;collaboration with Wasabi provides you with reliable, <span class="nowrap">cost-effective</span> and secure object
          storage
        </p>
      </div>
    </div>

    <h6 class="mt-4">Subscription Terms</h6>
    <div class="row">
      <div class="col-12 col-md-4 py-2">
        <div class="mbs-infoblock h-100 bg-primary-10">
          <div class="mbs-infoblock_body text-center">
            <span class="ico ico-listStar switch-commercial_term-icon text-primary"></span>
            <div class="mt-3">Minimum retention period of&nbsp;30 days</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 py-2">
        <div class="mbs-infoblock h-100 bg-primary-10">
          <div class="mbs-infoblock_body text-center">
            <span class="ico ico-creditDollar switch-commercial_term-icon text-primary"></span>
            <div class="mt-3">
              Pay-<span class="nowrap">As-You-Go</span> pricing model

              <ng-container *ngIf="'HelpMarketing' | can: 'read'">
                <a href="/AP/Help/billing-storage/storage-providers/msp360-storage"
                   target="_blank">{{ 'buttons:learnMore' | i18next }}</a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 py-2">
        <div class="mbs-infoblock h-100 bg-primary-10">
          <div class="mbs-infoblock_body text-center">
            <span class="ico ico-listClock switch-commercial_term-icon text-primary"></span>
            <div class="mt-3">Minimum monthly charge associated with 1 TB&nbsp;of&nbsp;storage</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container modalFooter>
    <div class="d-flex w-100 align-items-center justify-content-between">
      <div class="mt-1 mb-0">
        <mbs-checkbox [(ngModel)]="acceptTerms" label="I agree with">
          <a appendLabel class="ml-1" href="/AP/Help/billing-storage/storage-providers/msp360-storage" target="_blank"
            >Terms and Conditions <span class="fa fa-external-link"></span
          ></a>
        </mbs-checkbox>
      </div>
      <div class="ml-3">
        <mbs-button type="primary" class="mr-3" [loading]="loading$ | async" [disabled]="!acceptTerms" (click)="handleResolve()">{{
          okButtonText$ | async | i18next: { format: 'title' }
        }}</mbs-button>
        <mbs-button type="secondary" data-dismiss="modal" (click)="modal.close()"
          >{{ 'storages.unified-billing.cancel' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>
    </div>
  </ng-container>
</mbs-modal>
