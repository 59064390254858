import { ChangeDetectorRef, ContentChild, Directive, EventEmitter, Input, Optional, Output, Self, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ValidationErrors } from '@angular/forms';
import { isNil } from 'lodash';
import { MbsLabelSize, MbsSize, MbsValidators } from '../../utils';
import { InputAppendDirective } from './input-append.directive';
import { ValidClasses } from './input-base.model';
import { ValidationErrorType } from './input-base.types';
import { InputErrorsDirective } from './input-errors.directive';
import { InputLabelDirective } from './input-label.directive';
import { MbsNgClass } from './input-label/input-label.model';
import { InputButton } from './input-pre-append/input-button';
import { InputPrependDirective } from './input-prepend.directive';

@Directive({})
export abstract class InputBase<T> implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  protected myValue: T = '' as any;
  @Input() public set value(value: T) {
    this.myValue = value;

    if (this.onChange) {
      this.onChange(this.value);
    }
    this.cd.markForCheck();
    this.change.emit(value);
  }
  public get value(): T {
    return this.myValue;
  }

  /**
   * HTML Id
   */
  @Input() public id: string = 'input-' + Math.random().toString(36).substring(7);
  /**
   * Input placeholder
   */
  @Input() public placeholder = '';
  /**
   * Input autofocus
   */
  @Input() public autofocus = false;
  /**
   * Input label.
   * if `string` then use default template
   * `TemplateRef` will insert
   */
  @Input() public label: string | TemplateRef<any>;

  @Input() public labelPosition: 'left' | 'right' | 'top' | 'bottom';

  /**
   * CSS Classes for label content wrapper
   */
  @Input() public labelContentClasses: MbsNgClass;

  /**
   * The name of the control, which is submitted with the form data.
   */
  @Input() public name: string;
  /**
   * HTML tabindex
   */
  @Input() public tabindex = 0;

  /**
   * Disable input with ignore FormControl warnings
   */
  @Input() public disabledSelf = false;
  /**
   * Disable input
   */
  @Input() public disabled: boolean;
  get disabledState(): boolean {
    return (!isNil(this.disabled) && this.disabled !== false) || (!isNil(this.disabledSelf) && this.disabledSelf !== false);
  }

  /**
   * Readonly input
   */
  @Input() public readonly: boolean;
  public get readonlyState(): boolean {
    return !isNil(this.readonly) && this.readonly !== false;
  }

  /**
   * Classes for label
   */
  @Input() labelClasses: MbsNgClass;

  /**
   * @ignore
   */
  public get errors(): ValidationErrorType[] {
    if (!this.ngControl.errors || this.ngControl.pristine || (this.ngControl.control && this.ngControl.control.valid)) {
      return [];
    }

    return this.mapErrors(this.ngControl.errors);
  }

  /**
   * Label info. Append info icon
   */
  @Input() public info: string;

  /**
   * Label tooltip position
   */
  @Input() public infoPlacement = 'auto';

  /**
   * Specifies events that should trigger the tooltip
   */
  @Input() public infoTriggers = 'hover focus';

  /**
   * A selector specifying the element the tooltip should be appended to.
   */
  @Input() public infoContainer;

  @Input() public autocomplete: 'on' | 'off' | 'new-password' = 'on';
  /**
   * Flag for label `text-overflow` CSS-class
   */
  @Input() public textOverflow = false;

  /**
   * Radio size `lg` or `sm`.
   */
  @Input() public size: MbsSize.xxs | MbsSize.xs | MbsSize.sm | MbsSize.md | MbsSize.lg = null;

  /**
   * Label size from `xxs` to `lg`.
   */
  @Input() public labelSize: MbsLabelSize;
  public get sizeClass(): string {
    return this.size ? 'form-control-' + this.size : '';
  }

  /** Displaying '*' for required fields is the default behavior, but can be overridden. */
  @Input() public showRequiredMark = true;

  /**
   * @ignore
   */
  public get validClasses(): ValidClasses {
    return {
      'ng-untouched': this.ngControl.control ? this.ngControl.untouched : false,
      'ng-touched': this.ngControl.control ? this.ngControl.touched : false,
      'ng-pristine': this.ngControl.control ? this.ngControl.control.pristine : false,
      'ng-dirty': this.ngControl.control ? this.ngControl.control.dirty : false,
      'ng-valid': this.ngControl.control ? this.ngControl.control.valid : false,
      'ng-invalid':
        this.ngControl.control && !isNil(this.ngControl.control.validator || this.ngControl.control.asyncValidator)
          ? this.ngControl.control.invalid
          : false,
      'ng-pending': this.ngControl.control ? this.ngControl.control.pending : false
    };
  }

  @Input() prependButtons: InputButton[];
  @Input() appendButtons: InputButton[];

  @ContentChild(InputPrependDirective, { read: TemplateRef, static: true }) prepend: TemplateRef<any>;
  @ContentChild(InputAppendDirective, { read: TemplateRef, static: true }) append: TemplateRef<any>;

  @ContentChild(InputLabelDirective, { static: true }) labelTemplate: InputLabelDirective;
  @ContentChild(InputErrorsDirective, { static: true }) errorsTemplate: InputErrorsDirective;

  protected ngControl: NgControl;

  /**
   * Required indicator
   * Append red wildcard to label
   */
  public get hasRequiredValidator(): boolean {
    if (this.ngControl.control && this.ngControl.control.validator) {
      const validators = this.ngControl.control.validator(new FormControl());
      return Boolean(validators && validators.required);
    }

    return false;
  }

  @Output() change = new EventEmitter<T>();
  @Output() blur = new EventEmitter<Event>();
  @Output() focus = new EventEmitter<FocusEvent>();

  constructor(@Optional() @Self() ngControl: NgControl, protected cd: ChangeDetectorRef) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.ngControl = ngControl || ({} as any);
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange: (value: T) => void;
  onTouched: () => void;

  writeValue(obj: T): void {
    this.myValue = obj;
    this.cd.markForCheck();
  }
  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  handleBlur(event: Event): void {
    if (this['trim'] && this.value && this.value['trim']) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      this.myValue = (this.value as any).trim();
      if (this.ngControl && this.ngControl.control) {
        this.ngControl.control.setValue(this.myValue);
      }
    }

    // https://angular.io/api/forms/AbstractControl A control is marked touched once the user has triggered a blur event on it.
    if (this.onTouched) {
      this.onTouched();
    }

    this.blur.emit(event);
  }

  handleFocus(event: FocusEvent): void {
    if (this.onTouched) {
      this.onTouched();
    }
    this.focus.emit(event);
  }

  public mapErrors(errors: ValidationErrors): ValidationErrorType[] {
    if (!errors) return [];

    return Object.entries(errors)
      .map(([key, value]) => Object.assign({}, { key }, value) as ValidationErrorType)
      .map((er) => {
        if (!er.message) {
          const errorMessage = MbsValidators.validatorMessages[er.key] as (error) => string;
          er.message = errorMessage ? errorMessage(er) : undefined;
        }
        return er;
      })
      .filter((er) => er.message);
  }
}
