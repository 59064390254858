import {
  EventLogEntrySourceTypeEnum,
  EventLogEntryTypeEnum,
  EventLogIconEnum,
  EventLogTypesEnum,
  IEventLogEntry
} from '@models/rmm/EventLogInfo';
import { CSSVariables } from '@utils/getCssVariable';
import { Chart, ChartConfiguration, ChartDataset } from 'chart.js';

export type ChartController = { chart: Chart; data: { datasets: ChartDataset[] } };

export interface StateFormData {
  date: Date;
  eventLogType: EventLogTypesEnum;
}
export interface FilterEntry {
  id: EventLogEntryTypeEnum;
  name?: EventLogEntryTypeEnum;
  value?: EventLogEntrySourceTypeEnum;
  type?: CSSVariables | string;
  active?: boolean;
  count?: number;
  icon?: EventLogIconEnum;
}

export type FilterEntryMap = { [key: string]: FilterEntry };
export interface ToggleFilterData {
  include?: EventLogEntrySourceTypeEnum;
  exclude?: EventLogEntrySourceTypeEnum;
  clear?: boolean;
}

export type EventChartSelected = {
  logType?: EventLogTypesEnum;
  entryType?: EventLogEntryTypeEnum;
};

export type EventDateRange = {
  /**
   * @summary start date as a string value in ISO format
   */
  start: string;
  /**
   * @summary stop date as a string value in ISO format
   */
  stop: string;
};

export enum EventTotalTabMode {
  GRID = 0,
  CHART = 1
}
export interface EventTableView {
  key: string;
  value: string;
  cssClass?: string;
}

export interface EventTable extends IEventLogEntry {
  level?: string;
  cssClass?: string;
  id?: number;
  time?: Date | string;
  timeUTC?: Date;
  source: string;
}

export interface EventChartConfig extends ChartConfiguration<'doughnut'> {
  customLegend?: boolean;
}
