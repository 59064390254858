import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { OverdueModalComponent } from './overdue-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, MbsUiKitModule, I18NextModule],
  declarations: [OverdueModalComponent],
  exports: [OverdueModalComponent]
})
export class OverdueModalModule {
  constructor() {}
}
