<mbs-modal [title]="'MSP360 Storage Subscription'" [isCustomModal]="true" [showFooter]="true" #modal>
  <div modal-body>
    <div class="row">
      <div class="col-auto">
        <span class="mbs-storage mbs-storage-lg mbs-storage_UbAmazonS3 subscribe-ub-aws_UbAmazonS3"></span>
      </div>
      <div class="col">
        <h4>MSP360 Storage (Amazon S3)</h4>
        <p>
          MSP360 in&nbsp;collaboration with Amazon provides you with reliable, <span class="nowrap">cost-effective</span> and secure object
          storage
        </p>
      </div>
    </div>

    <h6 class="mt-4">Subscription Terms</h6>
    <div class="row">
      <div class="col-12 col-md-4 py-2">
        <div class="mbs-infoblock h-100 bg-primary-10">
          <div class="mbs-infoblock_body text-center">
            <span class="ico ico-listStar subscribe-ub-aws_term-icon text-primary"></span>
            <div class="mt-3">Easy flat pricing<br />No&nbsp;hidden extra charges</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 py-2">
        <div class="mbs-infoblock h-100 bg-primary-10">
          <div class="mbs-infoblock_body text-center">
            <span class="ico ico-creditDollar subscribe-ub-aws_term-icon text-primary"></span>
            <div class="mt-3">
              Pay-<span class="nowrap">As-You-Go</span> pricing model with {{ (balance | async)?.subscriptionCurrencySign }}0.023&nbsp;GB/month
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 py-2">
        <div class="mbs-infoblock h-100 bg-primary-10">
          <div class="mbs-infoblock_body text-center">
            <span class="ico ico-listClock subscribe-ub-aws_term-icon text-primary"></span>
            <div class="mt-3">
              No&nbsp;minimum retention period<br />
              No&nbsp;early deletion fee
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container modalFooter>
    <div class="d-flex w-100 align-items-center justify-content-between">
      <div class="mt-1 mb-0">
        <mbs-checkbox [(ngModel)]="acceptTerms" label="I agree with">
          <a appendLabel class="ml-1" href="/AP/Help/billing-storage/storage-providers/msp360-aws-storage" target="_blank"
            >Terms and Conditions <span class="fa fa-external-link"></span
          ></a>
        </mbs-checkbox>
      </div>
      <div class="ml-3">
        <mbs-button type="primary" class="mr-3" [loading]="loading$ | async" [disabled]="!acceptTerms" (click)="handleResolve()">{{
          okButtonText$ | async | i18next: { format: 'title' }
        }}</mbs-button>
        <mbs-button type="secondary" data-dismiss="modal" (click)="modal.close()"
          >{{ 'storages.unified-billing.cancel' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>
    </div>
  </ng-container>
</mbs-modal>
