<mbs-button class="mt-1 mw-100 overflow-hidden"
            [disabled]="disabled"
            [isCtrl]="true"
            [size]="size"
            customClasses="_hover-parent mw-100 lh-base"
            (click)="!disabled && handleClick($event)">
  <span class="row no-gutters overflow-hidden">
    <span class="col overflow-hidden">
      <mbs-text-ellipsis (checkFit)="handleCheck($event)"
                         #textEllipsisRef>
        <ng-container *ngIf="!selected.length; else selectedItemsEnumerationTemplate">
          <span class="d-inline-block mb-1">
            {{ emptyLabel }}
          </span>
        </ng-container>
        <ng-template #selectedItemsEnumerationTemplate>
          <span *ngFor="let element of firstNElements; let last=last"
                class="d-inline-block mb-1"
                [ngClass]="last ? '' : 'mr-1'">
            {{ (bindLabel ? element[bindLabel] : (element.name || element.email)) + (last ? ' ' : ',') }}
          </span>
          <ng-template mbsTextEllipsisAppend
                       [hide]="myHide">
            <span class="pl-2 d-inline-block mb-1">
              (Total: {{ selected.length }})
            </span>
          </ng-template>
          <!--<span class=""
                *ngIf='selected.length > elementsToShowCount'>
            <ng-container *ngTemplateOutlet='andMoreTemplate'></ng-container>
          </span>-->
        </ng-template>
      </mbs-text-ellipsis>
    </span>

    <span class="col-auto"
          *ngIf='editIconVisible'>
      <span class="fa fa-pencil pl-2 _hover-target"></span>
    </span>
  </span>
</mbs-button>
