import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ActionPrefix, EventEntryFilter } from './model';

export const addAllEventEntryFilters = createAction(
  `${ActionPrefix} Load EventEntryFilters`,
  props<{ eventEntryFilters: EventEntryFilter[] }>()
);

export const addEventEntryFilter = createAction(`${ActionPrefix} Add EventEntryFilter`, props<{ eventEntryFilter: EventEntryFilter }>());

export const upsertEventEntryFilter = createAction(
  `${ActionPrefix} Upsert EventEntryFilter`,
  props<{ eventEntryFilter: EventEntryFilter }>()
);

export const addEventEntryFilters = createAction(
  `${ActionPrefix} Add EventEntryFilters`,
  props<{ eventEntryFilters: EventEntryFilter[] }>()
);

export const upsertEventEntryFilters = createAction(
  `${ActionPrefix} Upsert EventEntryFilters`,
  props<{ eventEntryFilters: EventEntryFilter[] }>()
);

export const setActiveFilter = createAction(
  `${ActionPrefix} Update EventEntryFilter`,
  props<{ id: EventEntryFilter['id']}>()
)

export const updateEventEntryFilters = createAction(
  `${ActionPrefix} Update EventEntryFilters`,
  props<{ eventEntryFilters: Update<EventEntryFilter>[] }>()
);

export const deleteEventEntryFilter = createAction(`${ActionPrefix} Delete EventEntryFilter`, props<{ id: string }>());

export const deleteEventEntryFilters = createAction(`${ActionPrefix} Delete EventEntryFilters`, props<{ ids: string[] }>());

export const clearEventEntryFilters = createAction(`${ActionPrefix} Clear EventEntryFilters`);
