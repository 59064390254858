import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FinishPath, OnboardingView } from '@modules/wizards/onboarding/onboarding.constants';
import { Direction } from '@modules/wizards/onboarding/services/steps/steps.types';
import { TabsConfigType } from '@modules/wizards/onboarding/views/apps/apps.types';
import { CommonView } from '@modules/wizards/onboarding/views/common-view';
import { B2DestinationError } from '@modules/wizards/services/onboarding/onboarding.errors';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { AppsSummaryType } from '@modules/wizards/services/onboarding/onboarding.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountId } from '@services/apps/apps.constants';
import { AppsService } from '@services/apps/apps.service';
import { LoginData } from '@services/apps/apps.types';
import { AuthService } from '@services/auth.service';
import { I18NextPipe } from 'angular-i18next';
import { isEqual } from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { StepsService } from '../../services/steps/steps.service';
import { StepId, StepsConfig } from './apps.constants';
import { getTabsConfig } from './apps.helpers';

@UntilDestroy()
@Component({
  selector: 'mbs-onboarding-apps-view',
  templateUrl: './apps.component.html',
  styleUrls: ['../common-view.scss'],
  providers: [{ provide: StepsService }, { provide: 'steps', useValue: StepsConfig }]
})
export class AppsViewComponent extends CommonView<StepId> implements OnInit {
  @Input() step;

  public readonly viewMode = OnboardingView.Apps;
  public readonly tabsConfig = getTabsConfig(this.i18NPipe);
  public readonly stepId = StepId;
  public readonly direction = Direction;
  public isLoading = true;
  public form: UntypedFormGroup;
  public tabs = new BehaviorSubject(this.tabsConfig);
  public isReadyToBackup = false;

  constructor(
    public stepsService: StepsService<StepId>,
    public i18NPipe: I18NextPipe,
    public authService: AuthService,
    public onboardingService: OnboardingService,
    private appsService: AppsService,
    fb: FormBuilder,
    cdr: ChangeDetectorRef
  ) {
    super(stepsService, authService, cdr, fb);
  }

  ngOnInit() {
    this.initialize();
    this.createForm();
    this.onboardingService.stable$.next(true);
    this.form
      .get('accountId')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((id: AccountId) => {
        this.onAccountIdChanged(id);
      });

    this.form?.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        untilDestroyed(this)
      )
      .subscribe((formValue) => {
        this.isReadyToBackup = !!formValue?.users?.selected?.length && !!formValue?.sources?.selected?.some((selected) => selected);
      });

    this.appsService.getAuthSettings().subscribe({
      next: (data: LoginData[]) => {
        this.form.get('authSettings').setValue(data);
        this.isLoading = false;

        if ([StepId.SignIn].includes(this.step)) {
          this.stepsService.transition(StepId.SignIn, { needMakePrevCompleted: true });
        }
      },
      error: () => this.onboardingService.hasError$.next(true)
    });
  }

  finishWizard() {
    this.onboardingService.stable$.next(false);
    const { providerId, domainName } = this.form.value;
    const backupData = {
      Services: this.prepareServices(),
      Users: this.prepareUsers()
    };
    const appsSummaryData = this.getAppsSummaryData();

    const query$ = backupData.Services.some((service) => ['SharePoint', 'Teams', 'TeamDrives'].includes(service))
      ? this.onboardingService.assignSharePoint({ domainName, ownerId: providerId })
      : of(null);

    query$
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.appsService.launchBackup(this.form.value?.tokenData, backupData)),
        switchMap(() => this.onboardingService.sendAppsSummary(appsSummaryData))
      )
      .subscribe({
        next: () => {
          this.finish.emit(FinishPath.Domains);
        },
        error: (e) => {
          if (e instanceof B2DestinationError) {
            this.showAlert(e.message);
            this.onboardingService.stable$.next(true);
          } else {
            this.onboardingService.hasError$.next(true);
          }
        }
      });
  }

  private getAppsSummaryData(): AppsSummaryType {
    return {
      administrativeAccountEmail: this.form.value.userName,
      backupConfigurations: this.form.value.sources.names?.reduce((acc, current, index) => {
        acc[current] = this.form.value.sources.selected?.[index];
        return acc;
      }, {}),
      selectedUsers: this.form.value.users.selected?.length,
      copyInfoEmail: this.form.value.email
    };
  }

  private onAccountIdChanged(id: AccountId): void {
    this.tabs.next(this.transformTabsConfig(id));
    this.stepsService.setProperty(StepId.SelectUsers, 'hidden', id === AccountId.MicrosoftPersonal);
    this.form.get('sources.names').reset();
    (this.form.get('sources.selected') as FormArray).clear();
    this.form.get('users').reset({
      all: [],
      selected: [],
      loading: true
    });
  }

  private transformTabsConfig(id: AccountId): TabsConfigType {
    return this.tabsConfig.filter((tab) => {
      if (tab.id !== StepId.SelectUsers) {
        return true;
      }

      return id !== AccountId.MicrosoftPersonal;
    });
  }

  private createForm(): void {
    this.form = this.fb.group({
      providerId: this.provider.Id,
      accountId: [''],
      aliasUrl: [''],
      sources: this.fb.group({
        names: [null],
        selected: this.fb.array([])
      }),
      users: this.fb.group({
        all: [[]],
        selected: [[]],
        loading: true
      }),
      authSettings: [null],
      domainId: [null],
      domainName: [null],
      tokenData: [null],
      googleData: [null],
      msalData: [null],
      userName: [null],
      email: [this.provider.Email, [Validators.required, Validators.email]]
    });
  }

  private prepareServices(): string[] {
    const allSources = this.form.get('sources.names');
    const selectedSources = this.form.get('sources.selected');
    return allSources.value.filter((source, index) => selectedSources.value[index]);
  }

  private prepareUsers(): string[] {
    return this.form.get('users.selected').value?.map((user) => user.Id);
  }
}
