<section class="d-flex flex-column h-100">
  <header class="mb-3 text-center">
    <h4 class="mb-0">
      {{ 'wizards:tryRmm:rmm_final_rmm_title'| i18next: { format: 'title' } }}
    </h4>
  </header>

  <p class="text-center">
    {{ 'wizards:tryRmm:rmm_final_rmm_description' | i18next }}
  </p>
</section>
