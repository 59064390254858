import { Injectable, Injector, QueryList } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SidepanelBackupComponent } from '@components/sidepanel-backup/sidepanel-backup.component';
import { SidepanelRmmComponent } from '@components/sidepanel-rmm/sidepanel-rmm.component';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import { ComputersFacade } from '@facades/computers.facade';
import Administrator from '@models/Administrator';
import { SidepanelRouteType } from '@models/backup/sidepanel-route-type';
import { BackupSidePanelTab } from '@models/backup/sidepanel-tab';
import Computer, { AgentType, ComputerModeAdapter, ComputersMode } from '@models/Computer';
import { LicenseType } from '@models/LicenseType.enum';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdministratorService } from '@services/administrator.service';
import { AuthService } from '@services/auth.service';
import { SidepanelService, TabsetItemDirective } from 'mbs-ui-kit';
import { asapScheduler, interval, of, scheduled, Subject } from 'rxjs';
import { delay, filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AbilityService } from 'ability';

const delayOnResetDataAfterPanelClosed = 500;

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ComputerSidepanelWrapper {
  currentUser: Administrator;
  public deprecatedSubscription: Subject<void> = new Subject();
  private licenseCheckStarted = false;

  constructor(
    private router: Router,
    private sidepanelService: SidepanelService,
    private ability: AbilityService,
    private adminService: AdministratorService,
    private authService: AuthService,
    private computersFacade: ComputersFacade,
    private backupFacade: ComputerBackupFacade,
    private injector: Injector
  ) {
    this.authService.currentUser.subscribe((user) => (this.currentUser = user));
  }

  handleOpenRmmInfo(computer: Computer, activeTab?: string): boolean {
    if (this.licenseCheckStarted) return false;

    if (!computer.online || !Computer.isAgentOnline(computer, AgentType.RMM)) {
      activeTab = null;
    }
    this.computersFacade.setSelected(computer?.hid);
    return this.invokeWithCheckRmmPermission(() => {
      this.deprecatedSubscription.next();

      const rmmSidepanel: SidepanelRmmComponent = this.sidepanelService.add(SidepanelRmmComponent, null, this.injector);

      rmmSidepanel.open
        .pipe(take(1), takeUntil(this.deprecatedSubscription), untilDestroyed(this))
        .subscribe(() => this.updateSlug(computer, SidepanelRouteType.RMM, activeTab));
      rmmSidepanel.close.pipe(take(1), takeUntil(this.deprecatedSubscription), untilDestroyed(this)).subscribe(() => this.updateSlug(null));
      rmmSidepanel.openBackupPanel
        .pipe(take(1), takeUntil(this.deprecatedSubscription), untilDestroyed(this))
        .subscribe((computer) => this.switchMode(ComputersMode.Backup, computer));

      scheduled(
        of(1).pipe(
          tap(() => {
            this.sidepanelService.toggle('computer_rmm_info', computer);
            this.sidepanelService.toggleLoadingDataByType(SidepanelRmmComponent);
          }),
          delay(300) // prevent blinking
        ),
        asapScheduler
      ).subscribe(() => {
        // TODO use Computer from store in RMM-Panel

        rmmSidepanel.data$.next(computer);
        const selectTab = (items: QueryList<TabsetItemDirective>) => {
          const isTabExist = items.toArray().some((tab) => tab.id === activeTab);
          if (activeTab && isTabExist) {
            rmmSidepanel.tabset.select(activeTab);
          } else {
            rmmSidepanel.tabset.select('general');
          }
        };

        if (computer?.online) {
          // if computer is offline tab list will not change
          // so - try to find requested tab

          rmmSidepanel.tabset.items.changes
            .pipe(startWith(rmmSidepanel.tabset.items), take(1), takeUntil(this.deprecatedSubscription), untilDestroyed(this))
            .subscribe(selectTab);
        } else {
          // if computer is online - tab list will change after all checks in sidepanel
          // so we wait for changes and then try to find requested tab in updated list
          selectTab(rmmSidepanel.tabset.items);
        }
      });
    });
  }

  handleOpenBackupPanel(computer: Computer, activeTab?: string | BackupSidePanelTab): boolean {
    const defaultTab = BackupSidePanelTab.backupStorage;

    if (!computer.online || !Computer.isAgentOnline(computer, AgentType.Backup)) {
      activeTab = null;
    }
    this.backupFacade.setComputer(computer.hid);
    this.computersFacade.setSelected(computer?.hid);
    this.deprecatedSubscription.next();

    const backupPanel: SidepanelBackupComponent = this.sidepanelService.add(SidepanelBackupComponent, null, this.injector);

    backupPanel.open
      .pipe(take(1), takeUntil(this.deprecatedSubscription), untilDestroyed(this))
      .subscribe(() => this.updateSlug(computer, SidepanelRouteType.Backup, activeTab));
    backupPanel.close
      .pipe(
        tap(() => this.updateSlug(null)),
        switchMap(() => interval(delayOnResetDataAfterPanelClosed)),
        take(1),
        takeUntil(this.deprecatedSubscription),
        untilDestroyed(this)
      )
      .subscribe(() => this.backupFacade.setComputer(null));
    backupPanel.openRMMPanel
      .pipe(take(1), takeUntil(this.deprecatedSubscription), untilDestroyed(this))
      .subscribe((computer) => this.switchMode(ComputersMode.RMM, computer));
    backupPanel.tabChanged
      .pipe(takeUntil(this.deprecatedSubscription), untilDestroyed(this))
      .subscribe((tab) => this.updateSlug(computer, SidepanelRouteType.Backup, tab));
    this.sidepanelService.openByType(SidepanelBackupComponent).subscribe(() => {
      backupPanel.data$.next(computer);
      const selectTab = (items: QueryList<TabsetItemDirective>) => {
        // setTimeout needed for get actual data in QueryList
        setTimeout(() => {
          const selectTab = activeTab && items.toArray().some((tab) => tab.id === activeTab && !tab.disabled) ? activeTab : defaultTab;
          backupPanel.tabset.select(selectTab);
        });
      };

      if (backupPanel.online) {
        // if computer is offline tab list will not change
        // so - try to find requested tab

        backupPanel.tabset.items.changes
          .pipe(startWith(backupPanel.tabset.items), take(1), takeUntil(this.deprecatedSubscription), untilDestroyed(this))
          .subscribe(selectTab);
      } else {
        // if computer is online - tab list will change after all checks in sidepanel
        // so we wait for changes and then try to find requested tab in updated list
        selectTab(backupPanel.tabset.items);
      }
    });
    return true;
  }

  updateSlug(computer: Computer, sidepanelType: SidepanelRouteType = null, activeTab?: string): void {
    if (!this.isComputersPageActive()) return;

    const basePath = `${this.router.url.split('Computers')[0]}Computers`;
    const opts: NavigationExtras = {
      queryParamsHandling: 'merge',
      queryParams: {
        sidepanel: sidepanelType,
        activeTab: activeTab
      }
    };
    const commands: unknown[] = [basePath];
    computer && computer.hid && commands.push(computer.hid);
    this.router.navigate(commands, opts);
  }

  private switchMode(mode: ComputersMode.Backup | ComputersMode.RMM, computer: Computer): void {
    if (!this.isComputersPageActive()) {
      mode === ComputersMode.Backup ? this.handleOpenBackupPanel(computer) : this.handleOpenRmmInfo(computer);
      return;
    }

    const url = ComputerModeAdapter.find((modeUrl) => mode === modeUrl.mode);
    let tabParam = {};

    if (!url) return;
    if (mode === ComputersMode.Backup && computer.online && Computer.isAgentOnline(computer, AgentType.Backup)) {
      tabParam = { activeTab: BackupSidePanelTab.general };
    }

    this.router.navigate([url.url, computer.hid], { queryParams: { sidepanel: url.agentType, ...tabParam } });
  }

  private isComputersPageActive(): boolean {
    return ComputerModeAdapter.some((modeUrl) => this.router.url.includes(modeUrl.url));
  }

  invokeWithCheckRmmPermission(fn: () => unknown): boolean {
    this.licenseCheckStarted = true;

    if (this.ability.can('read', PermissionsEnum.Rmm)) {
      fn.call(this);
      this.licenseCheckStarted = false;
      return true;
    }

    this.adminService
      .openGrantLicenseDialogueWithInfoModal$({
        email: this.currentUser.Email,
        adminID: this.currentUser.Id,
        filter: [LicenseType.RMM]
      })
      .pipe(
        tap(() => (this.licenseCheckStarted = false)),
        filter((licenseAssign) => !!licenseAssign?.license)
      )
      .subscribe(() => fn.call(this));

    return false;
  }
}
