import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RmmSoftwareService, TFARMMPermissionHelperService } from '@services';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { CommandService } from '../rmm/services/rmm-command.service';
import { InstallUsingSoftwareLinkComponent } from './install-win-software/components/install-software/install-software.component';
import { InstallUsingWinGetComponent } from './install-win-software/components/install-winget/install-winget.component';
import { InstallWinSoftwareCommonComponent } from './install-win-software/install-win-software-common';
import { RebootComputerComponent } from './reboot-computer/reboot-computer.component';
import { WakeOnLanComponent } from './wake-on-lan/wake-on-lan.component';

@NgModule({
  declarations: [
    WakeOnLanComponent,
    RebootComputerComponent,
    InstallWinSoftwareCommonComponent,
    InstallUsingWinGetComponent,
    InstallUsingSoftwareLinkComponent
  ],
  imports: [CommonModule, MbsUiKitModule, I18NextModule, NgbTooltipModule, NgbDropdownModule, FormsModule, ReactiveFormsModule],
  providers: [TFARMMPermissionHelperService, CommandService, RmmSoftwareService],
  exports: [WakeOnLanComponent, RebootComputerComponent, InstallWinSoftwareCommonComponent]
})
export class RMMUtilityFeaturesModule {}
