import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { StorageAccountsService } from '@services/storage-accounts/storage-accounts.service';
import { ModalComponent } from 'mbs-ui-kit';
import { BehaviorSubject, noop, Observable, of } from 'rxjs';
import { catchError, finalize, first, map, shareReplay, switchMap, switchMapTo, tap } from 'rxjs/operators';

export type UbTypeToc = 'ubw_toc_agree' | 'uba_toc_agree';
@UntilDestroy()
@Directive()
export abstract class SubscribeUbComponent implements OnInit {
  public okButtonText$: Observable<string>;
  public canSwitch$: Observable<boolean>;
  public isSigned$: Observable<boolean>;
  public loading$ = new BehaviorSubject<boolean>(false);

  public selectedStorageId = '';
  public acceptTerms = false;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  constructor(
    private storageAccounts: StorageAccountsService,
    public auth: AuthService, // prettier
    private router: Router,
    @Inject(DOCUMENT) private dom: Document
  ) {}

  ngOnInit(): void {
    this.isSigned$ = this.auth.currentUser.pipe(map((u) => u && u.ProviderInfo.SubscriptionStatus === 'Signed'));

    this.okButtonText$ = this.isSigned$.pipe(
      map((isSigned) => 'storages.unified-billing.' + (isSigned ? 'agree' : 'continue')),
      untilDestroyed(this)
    );

    this.canSwitch$ = this.storageAccounts.canSwitchToPaidMode(this.selectedStorageId).pipe(
      catchError(() => of(false)),
      shareReplay(1)
    );
  }

  handleResolve(termAccepted: boolean, storageId: string, ubToc: UbTypeToc): void {
    if (!termAccepted) {
      return;
    }

    this.loading$.next(true);
    this.canSwitch$
      .pipe(
        first(),
        switchMap((canSwitch) => {
          // now it is that customer don't have 5 paid licenses
          if (canSwitch) {
            return this.isSigned$.pipe(
              first(),
              switchMap((isSigned) => {
                return isSigned
                  ? this.storageAccounts
                      .switchToCommercial(storageId)
                      .pipe(finalize(() => this.loading$.next(false)))
                      .pipe(
                        // if we get an error then modal will not close
                        // otherwise modal close before fetch unified billing accounts
                        // 'signed' will check in empty-page-component
                        tap(() => this.baseModal.save({ signed: false }), switchMapTo(this.storageAccounts.getUnifiedBillings()))
                      )
                  : this.storageAccounts
                      .getBuyLink(storageId)
                      .pipe(finalize(() => this.loading$.next(false)))
                      .pipe(
                        tap((link) => {
                          this.dom.location.assign(link);
                          this.baseModal.save({ signed: true });
                        })
                      );
              })
            );
          } else {
            this.router.navigate([RoutingPath.ApLicensesAspx], { queryParams: { buy: 'true', [ubToc]: storageId } }).then(noop);
            return of(true);
          }
        })
      )
      .subscribe(noop, noop);
  }
}
