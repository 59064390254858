import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { SortEvent } from 'mbs-ui-kit';
import { State } from './event-total.reducer';
import { ActionPrefix, EventEntry } from './model';

export const loadEventEntries = createAction(
  `${ActionPrefix} Load EventEntries`,
  props<{
    state: Partial<State>;
  }>()
);

export const showDetailEntry = createAction(`${ActionPrefix} Show Detail Entry`, props<{ id?: EventEntry['id'] }>());
export const hideDetailEntry = createAction(`${ActionPrefix} Hide Detail Entry`);

export const sortEntry = createAction(`${ActionPrefix} Sort Entry`, props<{ sort: SortEvent[] }>());

export const addAllEventEntry = createAction(`${ActionPrefix} Add All EventEntry`, props<{ eventEntries: EventEntry[] }>());

export const addEventEntry = createAction(`${ActionPrefix} Add EventEntry`, props<{ eventEntry: EventEntry }>());

export const upsertEventEntry = createAction(`${ActionPrefix} Upsert EventEntry`, props<{ eventEntry: EventEntry }>());

export const addEventEntries = createAction(`${ActionPrefix} Add EventEntries`, props<{ eventEntries: EventEntry[] }>());

export const upsertEventEntries = createAction(`${ActionPrefix} Upsert EventEntries`, props<{ eventEntries: EventEntry[] }>());

export const updateEventEntry = createAction(`${ActionPrefix} Update EventEntry`, props<{ eventEntry: Update<EventEntry> }>());

export const updateEventEntries = createAction(`${ActionPrefix} Update EventEntries`, props<{ eventEntries: Update<EventEntry>[] }>());

export const deleteEventEntry = createAction(`${ActionPrefix} Delete EventEntry`, props<{ id: string }>());

export const deleteEventEntries = createAction(`${ActionPrefix} Delete EventEntries`, props<{ ids: string[] }>());

export const clearEventEntries = createAction(`${ActionPrefix} Clear EventEntries`);
