import * as TerminalCacheReducer from './terminal-cache.reducer';
import * as TerminalSessionReducer from './terminal-sessions.reducer';

import { TerminalCacheKey, TerminalSessionKey } from './const';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TerminalSession } from './terminal.model';
import { createSessionId } from '../terminal-emulator.util';

// Entity TerminalCache selectors
const selectTerminalCache = createFeatureSelector<TerminalCacheReducer.State>(TerminalCacheKey);
export const selectCacheIds = createSelector(selectTerminalCache, TerminalCacheReducer.selectIds);
export const selectCacheEntities = createSelector(selectTerminalCache, TerminalCacheReducer.selectEntities);
export const selectCacheAll = createSelector(selectTerminalCache, TerminalCacheReducer.selectAll);
export const selectCacheTotal = createSelector(selectTerminalCache, TerminalCacheReducer.selectTotal);

// Entity TerminalSession selectors
const selectTerminalSession = createFeatureSelector<TerminalSessionReducer.State>(TerminalSessionKey);
export const selectSessionIds = createSelector(selectTerminalSession, TerminalSessionReducer.selectIds);
export const selectSessionEntities = createSelector(selectTerminalSession, TerminalSessionReducer.selectEntities);
export const selectSessionAll = createSelector(selectTerminalSession, TerminalSessionReducer.selectAll);
export const selectSessionTotal = createSelector(selectTerminalSession, TerminalSessionReducer.selectTotal);

// Session selectors
export const selectSession = (hid) => createSelector(selectSessionEntities, (entities) => entities[createSessionId({ hid })] || {} as TerminalSession);
export const selectInfo = (hid) => createSelector(selectSession(hid), (session) => session.info);
export const selectSubscribers = (hid) => createSelector(selectSession(hid), (session) => session.subscribers);
export const selectSessionBySubscriber = (emuId) => createSelector(selectSessionAll, (sessions) => {
  return sessions.find(session => session.subscribers?.includes(emuId))
})

// Status selectors
export const selectProcessing = (hid) => createSelector(selectSession(hid), (session) => session?.processing);
export const selectProcessingByInstance = (emuId) => createSelector(selectSessionBySubscriber(emuId), (session) => session?.processing);
export const selectDisconnected = (emuId) => createSelector(selectSessionBySubscriber(emuId), (session) => session?.disconnected);

// Lines selectors
export const selectChunks = (emuId: string) => createSelector(selectSessionBySubscriber(emuId), selectCacheAll, (session, lines) => {
  return lines.filter(line => line.session === session?.id)
})
export const selectOrderedChunks = (emuId: string) => createSelector(selectChunks(emuId), (chunks) => {
  return chunks.sort((a, b) => (a.id).localeCompare(b.id, 'en', { numeric: true }))
})
export const selectLastChunk = (emuId: string) => createSelector(selectOrderedChunks(emuId), (chunks) => {
  return chunks.slice(-1)[0];
})
export const selectOrderError = (emuId: string) => createSelector(selectChunks(emuId), (chunks) => {
  const last = chunks.slice(-1)[0];
  if (!last) {
    return false
  }
  const scope = chunks.slice(-10).sort((a, b) => (a.id).localeCompare(b.id, 'en', { numeric: true }))
  return scope.slice(-1)[0].id !== last.id;
})
