<ng-container *ngIf="isOnline">

  <div class="text-sm mb-3">
    {{ 'rmm-side-panel:runTimeTab.updateEvery' | i18next }}
    <mbs-button [isCtrl]="true"
                size="xs"
                class="mr-2">
      {{ updateInterval.value }} {{ 'app:timeUnits:seconds' | i18next }}
      <ng-template mbsButtonDropdown
                   container="body"
                   display="dynamic">
        <div class="py-1 px-2">
          <mbs-number [formControl]="updateInterval"
                      [min]="minInterval"
                      step="5"
                      [max]="maxInterval"
                      [id]="'rmm-runtime-update-interval'"
                      [imaskOpts]="updateIntervalMask"
                      size="sm"></mbs-number>
        </div>
      </ng-template>

    </mbs-button>
    {{ 'rmm-side-panel:runTimeTab.last' | i18next }}
    <mbs-rmm-date-time-format [time]="updatedTime"
                              [isUtc]="true"
                              [showIcon]="true"></mbs-rmm-date-time-format>
  </div>

  <mbs-alert *ngIf="autoFetchError"
             [type]="alertType.danger">
    {{ autoFetchError }}
  </mbs-alert>
</ng-container>

<div class="mbs-form-group mb-0">
  <mbs-advanced-search [accessUrlQuery]="false"
                       [(ngModel)]="model"
                       (search)="updateFilters($event)"
                       (input)="handleInputSearch($event)">
  </mbs-advanced-search>
</div>

<mbs-table-nav [switcherView]="false"
               [showRefreshButton]="!isOnline"
               [showExportToCSVButton]="isModal"
               [exportButtonName]="'buttons:exportToCSV' | i18next"
               (export)="export()"
               (refresh)="refresh()">

  <ng-template mbsTableFilter="label">
    {{ 'rmm-side-panel:runTimeTab.processes' | i18next }} {{ paginationOptions.dataSize }}
  </ng-template>

  <ng-container *ngIf="isOnline && !readOnly">
    <ng-template mbsTableFilter="divider"></ng-template>
    <ng-template mbsTableFilter="label">
      <mbs-button class="mr-2"
                  (click)="handleRunProgram(runProcess)"
                  [disabled]="selectedProcesses.length !== 0"
                  ngbTooltip="Run"
                  icon="ico ico-play-circle">
      </mbs-button>
      <mbs-button class="mr-2"
                  (click)="handleProcessKill(killProcess)"
                  icon="ico ico-times-circle"
                  [disabled]="killProcessDisabled"
                  ngbTooltip="Kill">
      </mbs-button>
    </ng-template>

  </ng-container>

  <!-- hidden before best time -->
  <ng-template mbsTableControl="append"
               *ngIf="!isModal">
    <mbs-modal-tab-content modalTitle="{{ 'rmm-side-panel:runTimeTab.modalTitle' | i18next }}"
                           [showExpandedCross]="true">
      <ng-template>
        <mbs-runtime-tab [hid]="hid"
                         [isModal]="true"
                         [readonly]="readOnly"
                         [isOnline]="isOnline"
                         [computerName]="computerName">
        </mbs-runtime-tab>
      </ng-template>
    </mbs-modal-tab-content>
  </ng-template>

</mbs-table-nav>

<mbs-table class="ph-no-capture"
           [headers]="headers"
           [subtitleHeaders]="headers"
           [showChildrenHeaders]="false"
           [myTrackBy]="myTrackBy"
           [data]="processes"
           bindChildren="childs"
           bindParentKey="processId"
           [collapsibleMode]="true"
           [showCheckboxes]="isOnline"
           [striped]="false"
           [rotateSequence]="rotateSequence"
           (sort)="handleSort($event)"
           [mbsTableSortGroup]="orderBy"
           [highlightOpened]="true"
           [loading]="loading$ | async"
           bindSelected="processId"
           bindSelectedChildren="processId"
           [dependenceChildren]="false"
           [multipleSelect]="false"
           [selectable]="true"
           [virtualScrolling]="true"
           [maxHeight]="isModal || !isOnline ? '60vh' : '50vh'"
           (changeSelected)="itemsChecked($event)"
           [keepState]="true"
           [selectedItems]="selectedProcesses"
           rowClasses="runtime-tab-process">

  <ng-template mbsTableHeader="Name"
               let-header>
    <div [sortable]="header.sort">
      <span sortArrow></span>
      <span class="text-sm text-gray-700">
        {{ header.name }}
      </span>
    </div>
  </ng-template>

  <ng-template mbsTableHeader="Memory"
               let-header>
    <div [sortable]="header.sort"
         class="text-right">
      <div class="font-weight-normal text-xs">
        {{ header.name }}
      </div>
      <div [hidden]="!totalProcessesInfo">
        <span sortArrow></span>
        <span class="text-lg font-weight-normal">
          {{ totalProcessesInfo?.physicalMemoryUsage | size }}
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template mbsTableHeader="CPU"
               let-header>
    <div [sortable]="header.sort"
         class="text-right">
      <div class="font-weight-normal text-xs">
        {{ header.name }}
      </div>
      <div [hidden]="!totalProcessesInfo">
        <span sortArrow></span>
        <span class="text-lg font-weight-normal">
          {{ totalProcessesInfo?.cpuUsagePercent }}%
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template mbsTableCell
               let-item>
    {{ item.name }}
  </ng-template>

  <ng-template mbsTableCell
               let-item>
    {{ item.physicalMemoryUsage | size }}
  </ng-template>
  <ng-template mbsTableCell
               let-item>
    {{ item.cpuUsagePercent }}
  </ng-template>
  <ng-template mbsTableCell
               group="child"
               let-item>
    {{ item.name }}
  </ng-template>
  <ng-template mbsTableCell
               group="child"
               let-item>
    {{ item.physicalMemoryUsage | size }}
  </ng-template>
  <ng-template mbsTableCell
               group="child"
               let-item>
    {{ item.cpuUsagePercent }}
  </ng-template>


</mbs-table>



<ng-template #runProcess>

  <div class="mbs-from-group">
    <mbs-input [formControl]="processName"
               label="{{ 'rmm-side-panel:runTimeTab.processInputLabel' | i18next }}"
               placeholder="{{ 'rmm-side-panel:runTimeTab.processInputPlaceholder' | i18next }}"
               autocomplete="off"
               mbsAutoFocus></mbs-input>
  </div>
  <div class="mt-2">
    <div class="mbs-form-text small mt-0">
      {{ 'rmm-side-panel:runTimeTab.example' | i18next }} <span class="nowrap">{{ runProcessExampleText }}</span>
    </div>
  </div>

</ng-template>

<ng-template #killProcess>
  <p>{{ 'rmm-side-panel:runTimeTab.kill' | i18next }}
    <ng-container *ngIf="selectedProcesses.length === 1">{{ 'rmm-side-panel:runTimeTab.process' | i18next }}
      <span class="font-weight-semibold">{{selectedProcesses[0].name}}</span>?
    </ng-container>
    <ng-container *ngIf="selectedProcesses.length > 1">{{ 'rmm-side-panel:runTimeTab.followingProcesses' | i18next }}
      <span *ngFor="let selectedProcess of selectedProcesses; last as isLast"
            class="font-weight-semibold">
        {{ selectedProcess.name }}{{ isLast ? '' : ', ' }}</span>?
    </ng-container>
  </p>
  <mbs-alert [type]="alertType.warning"
             [icon]="true"
             class="mb-0">
    {{ 'rmm-side-panel:runTimeTab.warningMsg' | i18next }}
  </mbs-alert>
</ng-template>
