import { Injectable, NgModule } from '@angular/core';
import AdministratorInCamelCase from '@models/AdministratorInCamelCase';
import { PagedResponse, QueryFiltersParams, QueryPagingParams } from '@models/Paging';
import { Actions, EffectsModule } from '@ngrx/effects';
import { ReducerManager, Store } from '@ngrx/store';
import { AdministratorService } from '@services/administrator.service';
import { Observable } from 'rxjs';
import { PagingStoreFactory } from '../pagingStoreFactory/pagingStoreFactory';
import { StoreTypes } from '../storeTypes.enum';

@Injectable({
  providedIn: 'root'
})
export class AdministratorsStore extends PagingStoreFactory<AdministratorInCamelCase> {
  protected storeType = StoreTypes.Administrators;
  protected selectId = (entity: AdministratorInCamelCase): string => {
    return entity.id;
  };

  protected getEntityById(id: string): Observable<AdministratorInCamelCase> {
    return this.administratorsService.getById(id);
  }
  protected getEntities(params: QueryFiltersParams & QueryPagingParams): Observable<PagedResponse<AdministratorInCamelCase>> {
    return this.administratorsService.get(params);
  }

  constructor(
    public actions$: Actions,
    public store: Store,
    public reducerManager: ReducerManager,
    private administratorsService: AdministratorService
  ) {
    super(actions$, store, reducerManager);
    this.initStore();
  }
}

@NgModule({
  imports: [EffectsModule.forFeature([AdministratorsStore])]
})
export class AdministratorsStoreModule {}
