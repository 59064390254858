<mbs-form-group [formGroup]="monitoringForm"
                *ngIf="!loading">
  <div class="row align-items-center">
    <div class="col d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <label class="mbs-form_label font-weight-bold mb-0"
               for="enabled">
          {{'rmm-side-panel:monitoring-form:title' | i18next: { format: 'title' } }}
          <!-- {{ showPCStats() ? '(Enabled)' : '(Disabled)' }}  -->
        </label>
        <div class="col-auto">
          <mbs-switcher formControlName="enabled"
                        [id]="'enabled'"
                        [selected]="agentState$ | async">
          </mbs-switcher>
        </div>
      </div>
      <ng-container *ngIf="agentState$ | async"
                    class="col align-self-end">
        <mbs-button (click)="onRmmAppDataChanged()"
                    [icon]="'ico ico-Refresh'"
                    [size]="'md'"
                    [disabled]="loading">
        </mbs-button>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-10">
      <ng-container *ngIf="!(agentState$ | async)">
        <p class="mbs-form-text small text-muted mb-0">
          {{'rmm-side-panel:monitoring-form:disabledByDesc' | i18next: { name: (agent$ | async)?.stateChangedBy } }}
        </p>
        <p class="mbs-form-text small text-muted mt-0 mb-0">
          {{ (agent$ | async)?.stateChangedDate | date: mediumDateWithTime }}
        </p>
      </ng-container>
      <p class="mbs-form-text small text-muted mb-0">
        {{'rmm-side-panel:monitoring-form:description' | i18next }}
      </p>
    </div>
  </div>
</mbs-form-group>

<ng-container *ngIf="!loading">

  <table *ngIf="summary"
         class="table table-simple table-sm">
    <tr *ngFor="let item of summary; index as index; last as last"
        class="row"
        [ngbTooltip]="item.tooltip"
        [placement]="'top-left'"
        (click)="clickOnItemHandler(item.key)">
      <td class="col-4 text-muted pr-1 align-top"
          [class.pt-0]="item.key === ''">
        <mbs-status [size]="item.size"
                    [type]="item.type"
                    [icon]="item.icon"
                    [clickable]="false">
        </mbs-status>
        {{ 'rmm-side-panel:general-info-titles.' + item.key | i18next: { format: 'title' } }}
      </td>
      <td class="pr-0 col-8"
          [class.pt-0]="item.key === ''">
        <div class="d-flex">
          {{ item.text }}
          <span *ngIf="item.sub"
                class="ml-1 text-muted">{{item.sub}}</span>
        </div>
        <ng-container *ngIf="item.detail">
          <div class=""
               *ngFor="let detail of item.detail">
            {{ detail }}
          </div>
        </ng-container>
      </td>
    </tr>
  </table>

  <div class="mbs-form-text mb-3">
    <h5 class="mb-0">{{ 'rmm-side-panel:notifications.notificationSettings' | i18next }}
      <span class="ml-1 text-info ico ico-InfoCircle"
            [placement]="'top-right auto'"
            [container]="'body'"
            tooltipClass="tooltip-lg"
            [ngbTooltip]="'app:customizeThreshold.versionSupportMessageComputer' | i18next">
      </span>
    </h5>
    <mbs-button [isCtrl]="true"
                [size]="'md'"
                [disabled]="!isAlertCustomisationSupported"
                (click)="openAlertCustomisation()">
      {{ 'app:customizeThreshold.customizeNotificationThresholdsBtn' | i18next }}
    </mbs-button>
  </div>

  <rmm-quick-tools [isOnline]="isOnline">
  </rmm-quick-tools>
</ng-container>
