import * as ScriptLibraryActions from './script-library.actions';

import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ScriptLibraryEntry, State } from './script-library.model';
import { createReducer, on } from '@ngrx/store';

import { DEFAULT_SORT } from './const';

export const adapter: EntityAdapter<ScriptLibraryEntry> = createEntityAdapter<ScriptLibraryEntry>();

export const initialState: State = adapter.getInitialState({
  edit: null,
  loading: false,
  filters: null,
  sort: DEFAULT_SORT,
  tags: null,
});

export const reducer = createReducer(
  initialState,
  on(ScriptLibraryActions.catchError, state => ({ ...state, loading: false })),
  on(ScriptLibraryActions.loadTags, state => ({ ...state, loading: true })),
  on(ScriptLibraryActions.registerScript, state => ({ ...state, loading: true })),
  on(ScriptLibraryActions.loadScriptBody, state => ({ ...state, loading: true })),
  on(ScriptLibraryActions.updateScript, state => ({ ...state, loading: true })),
  on(ScriptLibraryActions.registerTag, state => ({ ...state, loading: true })),
  on(ScriptLibraryActions.deleteScript, state => ({ ...state, loading: true })),
  on(ScriptLibraryActions.deleteTag, state => ({ ...state, loading: true })),
  on(ScriptLibraryActions.callEditor, (state, action) => ({ ...state, edit: action.id })),
  on(ScriptLibraryActions.sortScriptsInLibrary, (state, { sort }) => ({ ...state, sort })),
  on(ScriptLibraryActions.filterScriptsInLibrary, (state, { filters }) => ({ ...state, filters })),
  on(ScriptLibraryActions.loadScriptsInLibrary, state => ({ ...state, loading: true })),
  on(ScriptLibraryActions.addTag, (state, action) => ({...state, tags: [action.tag].concat([...state.tags]),  loading: false})),
  on(ScriptLibraryActions.upsertTags, (state, action) => ({...state, tags: action.tags,  loading: false})),
  on(ScriptLibraryActions.upsertScriptBody, (state, { scriptGuid, body }) => {
    return adapter.updateOne(
      {
        id: scriptGuid,
        changes: {
          body
        }
      },
      { ...state, loading: false }
    );
  }),
  on(ScriptLibraryActions.addScripIntLibrary, (state, action) => adapter.addOne(action.script, state)),
  on(ScriptLibraryActions.upsertScriptInLibrary, (state, action) => adapter.upsertOne(action.script, { ...state, loading: false })),
  on(ScriptLibraryActions.addScriptsInLibrary, (state, action) => adapter.addMany(action.scripts, state)),
  on(ScriptLibraryActions.upsertScripsInLibrary, (state, action) => adapter.upsertMany(action.scripts, { ...state, loading: false })),
  on(ScriptLibraryActions.updateScriptLibrary, (state, action) => adapter.updateOne(action.script, state)),
  on(ScriptLibraryActions.updateScriptsInLibrary, (state, action) => adapter.updateMany(action.scripts, state)),
  on(ScriptLibraryActions.deleteScriptInLibrary, (state, action) => adapter.removeOne(action.id, { ...state, loading: false })),
  on(ScriptLibraryActions.deleteScriptsInLibrary, (state, action) => adapter.removeMany(action.ids, state)),
  on(ScriptLibraryActions.clearFilters, (state) => ({...state, filters: null, sort: DEFAULT_SORT})),
  on(ScriptLibraryActions.clearScriptLibrary, state => adapter.removeAll({...state}))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
