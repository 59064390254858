<ng-container *ngIf="!tabItem?.isLoading">
  <ng-container *ngIf="isBackupAgentSupportsNewRM || !computerData?.online; else unsupported">
    <div *ngIf="!(storages$ | async)?.length"
         [attr.data-test-element]="elementsSelector.name.notStoragesBlock">
      <div class="text-center">
        <img src="/Areas/Admin/AngularClientApp/mbs-spa/dist/assets/load-image.svg"
             [alt]="'backup-storages:noDestination' | i18next" />
      </div>
      <div class="text-center text-gray-500 mt-1 mb-3">
        {{ 'backup-storages:noDestination' | i18next }}
      </div>
      <div class="text-center">
        <mbs-button (click)="handleRefresh()"
                    type="secondary">{{ 'backup-storages:reloadDestinations' | i18next }}</mbs-button>
      </div>
    </div>

    <div *ngIf="!!(storages$ | async)?.length && !selectedBrowseStorage"
         [attr.data-test-element]="elementsSelector.name.existStoragesBlock">
      <div class="d-flex justify-content-end mb-3">
        <mbs-button *ngIf="authService?.isMBSMode"
                    icon="ico ico-OpenHistory"
                    class="mr-3"
                    (click)="openBackupHistory()">
          <span class="text-sm">{{ 'backup-storages:history' | i18next }}</span>
        </mbs-button>
        <mbs-button (click)="handleRefresh()"
                    icon="ico ico-Refresh">
        </mbs-button>
      </div>
    </div>

    <div class="row"
         *ngIf="(storages$ | async) && !selectedBrowseStorage"
         [attr.data-test-element]="elementsSelector.name.storagesBlock">
      <div *ngFor="let storage of (storages$ | async); let last = last"
           class="col-12"
           [class.mb-3]="!last"
           [attr.data-test-element]="elementsSelector.name.storageItem">
        <mbs-card [size]="MbsSize.xs">
          <span class="form-row align-items-center mb-3">
            <span class="col-1 d-flex align-items-center">
              <span class="mbs-storage mbs-storage-sm mbs-storage_{{ storage.StorageType | storageIcon }}"></span>
            </span>

            <span class="col-11">
              <span class="form-row align-items-center">
                <span class="col overflow-hidden h5 my-0">
                  <mbs-text-ellipsis [tooltip]="storage.DisplayName">{{storage.DisplayName}}</mbs-text-ellipsis>
                </span>
              </span>
            </span>
          </span>

          <span class="form-row text-xs font-weight-base mb-2"
                *ngIf="storage.StorageAccountName"
                [attr.data-test-element]="elementsSelector.name.storageItemAccountName">
            <span class="col-5 offset-1 text-muted">{{ 'backup-storages:descriptions.accountName' | i18next }}</span>
            <span class="col overflow-hidden">
              <mbs-text-ellipsis [tooltip]="storage.StorageAccountName">{{storage.StorageAccountName}}</mbs-text-ellipsis>
            </span>
          </span>

          <span class="form-row text-xs font-weight-base mb-2"
                *ngIf="storage.Bucket"
                [attr.data-test-element]="elementsSelector.name.storageItemBucket">
            <span class="col-5 offset-1 text-muted">{{ 'backup-storages:descriptions.bucket' | i18next }}</span>
            <span class="col overflow-hidden">
              <mbs-text-ellipsis [tooltip]="storage.Bucket">{{storage.Bucket}}</mbs-text-ellipsis>
            </span>
          </span>

          <span class="form-row text-xs font-weight-base"
                *ngIf="storage.Region"
                [attr.data-test-element]="elementsSelector.name.storageItemRegion">
            <span class="col-5 offset-1 text-muted">{{ 'backup-storages:descriptions.region' | i18next }}</span>
            <span class="col overflow-hidden">
              <mbs-text-ellipsis [tooltip]="storage.Region">{{storage.Region}}</mbs-text-ellipsis>
            </span>
          </span>

          <div class="form-row mt-3">
            <div class="col">
              <mbs-button type="primary"
                          [block]="true"
                          (click)="browseStorageData(storage)">
                {{ 'backup-storages:browse' | i18next }}
              </mbs-button>
            </div>
            <div *ngIf="isWindows()"
                 class="col">
              <mbs-button type="secondary"
                          [block]="true"
                          (click)="quickRestoreAppClickHandler(storage)">
                {{ 'backup-storages:quickRestoreAppBtn' | i18next }}
              </mbs-button>
            </div>
          </div>
        </mbs-card>
      </div>
    </div>

    <div class="w-100 h-100 d-flex flex-column align-items-stretch"
         *ngIf="(storages$ | async) && selectedBrowseStorage"
         [attr.data-test-element]="elementsSelector.name.browseStoragesBlock">
      <mbs-browse-storage class="flex-grow-1"
                          [computerData]="computerData"
                          [downloadLinkData]="downloadLinkData"
                          [currentPlanDestination]="currentPlanDestination"
                          [selectedStorage]="selectedBrowseStorage"
                          [storages]="storages$ | async"
                          (fullScreenChange)="backToStoragesClickHandler()"
                          (changedSelectedStorage)="changedSelectedStorageChangeHandler($event)"></mbs-browse-storage>
    </div>
  </ng-container>

  <ng-template #unsupported>
    <mbs-alert [type]="mbsPopupType.danger"
               [icon]="true">
      {{ 'sidepanel-backup:backupStorageTab.unsupportedAlertText' | i18next }}
    </mbs-alert>
  </ng-template>
</ng-container>
