<h4>{{ 'rmm.module:groupActions.stepResult.title' | i18next: { format: 'title' } }}</h4>

<div *ngIf="(groupTaskGuidInStore) === groupTaskGuid">
  <div *ngIf="groupTaskGuid && (groupTask$ | async)?.runType === 1">
    <mbs-table-nav [switcherView]="false">

      <ng-template mbsTableFilter="item">
        <mbs-button (click)="filterResults('all')"
                    type="{{ showComputerByResult === 'all' ? 'dark' : 'primary' }}"
                    size="sm"
                    [isCtrl]="true">
          {{ 'app:labels.computers' | i18next: { format: 'title' } }}: {{ counts?.all || 0
          }}
        </mbs-button>
      </ng-template>

      <ng-template mbsTableFilter="item">
        <mbs-button (click)="filterResults('done')"
                    [disabled]="false"
                    type="{{ showComputerByResult === 'done' ? 'dark' : 'primary' }}"
                    size="sm"
                    [icon]="cssClasses.success + ' ' + 'mr-1'"
                    [isCtrl]="true">
          {{ 'app:labels.success' | i18next: { format: 'title' } }}: {{ counts?.done || 0
          }}
        </mbs-button>
      </ng-template>

      <ng-template mbsTableFilter="item">
        <mbs-button (click)="filterResults('warn')"
                    [disabled]="false"
                    type="{{ showComputerByResult === 'warn' ? 'dark' : 'primary' }}"
                    size="sm"
                    [icon]="cssClasses.warn + ' ' + 'mr-1'"
                    [isCtrl]="true">
          {{ 'app:labels.warning' | i18next: { format: 'title' } }}: {{ counts?.warn || 0
          }}
        </mbs-button>
      </ng-template>

      <ng-template mbsTableFilter="item">
        <mbs-button (click)="filterResults('error')"
                    [disabled]="false"
                    type="{{ showComputerByResult === 'error' ? 'dark' : 'primary' }}"
                    size="sm"
                    [icon]="cssClasses.error + ' ' + 'mr-1'"
                    [isCtrl]="true">
          {{ 'app:labels.failed' | i18next: { format: 'title' } }}: {{ counts?.error || 0
          }}
        </mbs-button>
      </ng-template>

      <ng-template mbsTableControl="append">
        <mbs-button (click)="exportToCSV()"
                    type="primary"
                    size="sm"
                    [isCtrl]="true"
                    icon="fa fa-download">
          {{ 'buttons:download' | i18next: { format: 'title' } }}
        </mbs-button>
      </ng-template>

    </mbs-table-nav>

    <div class="row">
      <div *ngFor="let computer of (computers$ | async); let i=index; trackBy: trackByComputers"
           class="col-6 pb-3">
        <div class="mbs-tile -selectable"
             (click)="showComputerOutput(computer)"
             role="button">
          <div class="form-row align-items-center justify-content-between px-3 flex-nowrap computer-block-height">
            <div class="col-1 mbs-tile_ico">
              <span class="fa {{ computerIcon }}"
                    [ngClass]="computer.online ? 'text-primary' : 'text-muted'">
              </span>
            </div>
            <div class="col-2 px-1 flex-grow-2 text-overflow">
              <ng-container *ngIf="computer.displayName; else showComputerName">
                {{ computer.displayName }} <br> [{{ computer.name }}]
              </ng-container>
              <ng-template #showComputerName>
                {{ computer.name }}
              </ng-template>
            </div>
            <div class="col-1 p-1">
              <span *ngIf="computer.online"
                    class="{{ getComputerCss(computer) }}"></span>
            </div>
          </div>
          <div class="row px-4"
               *ngIf="showTerminalOutput && computer.endStamp">
            <div class="col-auto offset-1 flex-grow-2 text-overflow text-sm">
              {{ 'rmm.module:groupActions.resultSubMessage.powerShell' | i18next: { source: computer.state }
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="groupTaskGuid && (groupTask$ | async)?.runType === 0">
    {{ 'rmm.module:groupActions.stepResult.scheduleTaskNoRunLinkText' | i18next }}
    <mbs-button [isCtrl]="true"
                (click)="openGroupTaskLink()">
      {{ (groupTask$ | async)?.name }}
    </mbs-button>
  </div>

  <div *ngIf="groupTaskGuid && (groupTask$ | async)?.runType === 2">
    {{ 'rmm.module:groupActions.stepResult.scheduleTaskLinkText' | i18next }}
    <mbs-button [isCtrl]="true"
                (click)="openGroupTaskLink()">
      {{ (groupTask$ | async)?.name }}
    </mbs-button>
  </div>

  <div *ngIf="groupTaskGuid && (groupTask$ | async)?.runType !== 1"
       class="text-md mt-3">

    <div class="font-weight-semibold mb-2">
      {{ 'rmm.module:groupActions.stepResult.taskName' | i18next }}
    </div>
    <div class="mb-2">{{ groupTaskSummary.name }}</div>

    <div class="font-weight-semibold mb-2">
      {{ 'rmm.module:groupActions.stepResult.taskActionType' | i18next }}
    </div>
    <div class="mb-2">
      {{ 'rmm.module:groupActions.actionNames.' + groupTaskSummary.type | i18next}}
    </div>

    <div class="font-weight-semibold my-2">
      {{ 'rmm.module:groupActions.stepResult.taskApplyTo' | i18next }}
    </div>
    <div>
      {{ groupTaskSummary.applyTo.includeInfo }}
    </div>
    <div>
      {{ groupTaskSummary.applyTo.excludeInfo }}
    </div>

    <div *ngIf="groupTaskSummary.applyTo?.tags?.length"
         class="mb-2">
      <span>{{ 'rmm.module:groupActions.stepResult.taskTags' | i18next }}</span>
      <span *ngFor="let tag of groupTaskSummary.applyTo.tags; last as last"
            class="mr-1">
        {{ (getTagById(tag) | async)?.name }}{{ last ? '' : ', ' }}
      </span>
    </div>

    <div class="font-weight-semibold my-2">
      {{ 'rmm.module:groupActions.stepSchedule.title' | i18next }}
    </div>
    <div class="mb-2">
      {{ groupTaskSummary?.schedule ?? ('rmm.module:groupActions.stepSchedule.runManually' | i18next) }}
    </div>

    <div class="font-weight-semibold my-2"
         *ngIf="groupTaskSummary?.description">
      {{ 'rmm.module:groupActions.stepResult.taskDescription' | i18next }}
    </div>
    <div class="mb-2 pre-wrap"
         *ngIf="groupTaskSummary?.description">{{ groupTaskSummary.description }}
    </div>

  </div>
</div>

<ng-template #resultBox>
  <div class="text-monospace mb-0"
       *ngIf="resultOutput">
    <pre class="mbs-infoblock background-secondary">{{ resultOutput }}</pre>
  </div>
</ng-template>
