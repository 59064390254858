import HotfixAvailableInfo from '@models/rmm/HotfixAvailableInfo';

export type HotfixAvailableInfoExtend = HotfixAvailableInfo & {
  loading?: boolean;
  installed?: boolean;
  disabled: boolean;
  severity: string;
  severityScore: number;
};

export const setSeverityScore = (update: HotfixAvailableInfoExtend | any): HotfixAvailableInfoExtend | any => {
  switch (update.severity) {
    case 'Critical': {
      update.severityScore = 2;
      return update;
    }
    case 'Important': {
      update.severityScore = 1;
      return update;
    }
    default: {
      update.severityScore = 0;
      return update;
    }
  }
};
