import { ModuleWithProviders, NgModule } from '@angular/core';
import { SignalRService } from './signal-r.service';

@NgModule()
export class SignalRModule {
  static forRoot(): ModuleWithProviders<SignalRModule> {
    return {
      ngModule: SignalRModule,
      providers: [SignalRService]
    };
  }
}
