import { EventLogEntryTypeEnum, EventLogTypesEnum } from '@models/rmm/EventLogInfo';
import { createAction, props } from '@ngrx/store';
import { EventTotalTabMode } from '../event-total-tab.model';
import { ActionPrefix } from './model';

export const ChangeMode = createAction(`${ActionPrefix} Update Mode`, props<{ mode?: EventTotalTabMode }>());
export const loadError = createAction(`${ActionPrefix} Load Error`, props<{ error: any }>());
export const UpdateDate = createAction(`${ActionPrefix} Update Date`, props<{ date: Date }>());
export const UpdateHost = createAction(
  `${ActionPrefix} Update Host`,
  props<{ hid: any; online: boolean; utcOffset: number; mode?: EventTotalTabMode }>()
);
export const UpdateType = createAction(`${ActionPrefix} Update Type`, props<{ logType: EventLogTypesEnum }>());
export const ViewLogByEntryType = createAction(
  `${ActionPrefix} View Log By Entry Type`,
  props<{ logType: EventLogTypesEnum; entry: EventLogEntryTypeEnum }>()
);

export const ClearEventTotal = createAction(`${ActionPrefix} Clear`);
