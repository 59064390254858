import { Component, forwardRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms';
import { DeepSyncEnum, MyTreeElements } from '@models/backup/storages-type';
import { PasswordModalComponent } from '@modules/password-modal/password-modal.component';
import { CheckboxLegendComponent } from '@modules/wizards/steps/components/checkbox-legend/checkbox-legend.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { unidentifiedErrorText } from '@shared/interceptors/error-handler.interceptor';
import { windowsInvalidPath } from '@utils/constants/folders-path-constants';
import { cobbledDateFormat, dateTimePointsFormat, dateTimePointsReg } from '@utils/date';
import { I18NextService } from 'angular-i18next';
import { FormsUtil, GuidEmpty, MbsPopupType, ModalService, ToastService, TreeElement } from 'mbs-ui-kit';
import * as moment from 'moment';
import { BehaviorSubject, noop, Observable } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { ExtendedTreeElement, StepsHelpers } from '../../helpers/steps-helpers';
import { RestorePointItem } from '../../models/restore-point-models';
import { RestoreSourceStepValue } from '../../models/restore-source-models';
import { TreeIconPath } from '../../models/what-backup-tree-model';
import { WhatRunAfterPassword } from '../../models/what-run-model';
import { RemoteManagementWizardsService } from '../../services/remote-management-wizards.service';
import { GetBackupContentParams, ParamsForDeepSync, WizardStepsService } from '../../services/wizard-steps.service';
import { FilesFoldersListComponent } from '../components/files-folders-list/files-folders-list.component';
import { FormPipeOperators, StepBase } from '../StepBase.class';

const RestoreSourceStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => RestoreSourceStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-restore-source-step',
  templateUrl: './restore-source-step.component.html',
  styleUrls: ['./restore-source-step.component.scss'],
  providers: [RestoreSourceStepValueAccessor]
})
export class RestoreSourceStepComponent extends StepBase<RestoreSourceStepValue> {
  @Input() computerName: string;
  @Input() resultPoint: RestorePointItem;
  @Input() storageId: string = null;
  @Input() manually: boolean;
  @Input() bunchId: string;

  public allowedRecovery = false;

  public foldersForm = new UntypedFormGroup({ filesFoldersData: new FormControl({ enabledFields: true, paths: [] }) });

  public treeData: TreeElement[] = [];
  public separator = ')-(';
  public loadingTree = false;

  private passwordsForBunches: { [key: string]: string } = {};

  private treeParams: GetBackupContentParams = {
    agentType: 'backup',
    commandType: 'GetBackupContent',
    params: {
      ConnectionId: this.storageId,
      RestoreSourcePrefix: this.mainService.compName,
      BunchId: 'Image Based',
      RestorePointDateUtc: '',
      path: '',
      offset: 0,
      limit: 2,
      order: 'DisplayNameAsc'
    }
  };

  private needAllCheckChildren: { [key: string]: boolean } = {};
  private pathStringLength = 1;
  private firstLoad = true;
  public passwordInvalid = false;

  @ViewChild('fileFoldersComponent', { static: true, read: FilesFoldersListComponent }) fileFoldersComponent: FilesFoldersListComponent;

  public restoreSourceInfoAlert = this.i18nextService.t('wizards:restore_source_info_alert', { returnObjects: true });

  private currentSyncData: { progress: number; deepSync: DeepSyncEnum; progressMessage: string };

  constructor(
    public mainService: RemoteManagementWizardsService,
    private modal: ModalService,
    private stepService: WizardStepsService,
    private toastService: ToastService,
    public i18nextService: I18NextService,
    private auth: AuthService
  ) {
    super(mainService);
  }

  ngOnInit(): void {
    this.auth.currentUser.pipe(untilDestroyed(this)).subscribe((user) => {
      this.allowedRecovery = user.ProviderInfo.PasswordRecoveryEnabled && user.IsProvider;
    });
    this.auth.fetchCurrentUser();

    this.initForm();

    this.setValues();
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this), debounceTime(200)];
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({ folders: new FormControl([], [Validators.required]) });

    this.subscribeToFoldersForm();
    this.initFormEvents();
  }

  subscribeToFoldersForm(): void {
    this.foldersForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value.filesFoldersData && value.filesFoldersData.paths && value.filesFoldersData.paths.length) {
        this.stepForm.get('folders').setValue(value.filesFoldersData.paths);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bunchId?.currentValue && changes.bunchId.currentValue !== changes.bunchId.previousValue) {
      this.mainService.password.next(this.passwordsForBunches[changes.bunchId.currentValue] || '');
    }
    if (
      this.isRDMode ||
      (changes.computerName && !changes.computerName.currentValue) ||
      (changes.bunchId && !changes.bunchId.currentValue) ||
      (changes.storageId && (!changes.storageId.currentValue || changes.storageId.currentValue === GuidEmpty))
    ) {
      this.treeData = [];
    }

    if (!this.isRDMode && this.computerName && this.storageId && this.storageId !== GuidEmpty) {
      const bunchIsChanged = !this.isNBF && changes.bunchId?.currentValue && changes.bunchId.currentValue !== GuidEmpty;
      const manuallyChanged = changes.manually && this.bunchId;
      const pointChanged =
        changes.resultPoint?.currentValue &&
        (!this.isNBF || !changes.resultPoint.currentValue.needDeepSync || changes.resultPoint.currentValue.needRunSync) &&
        this.bunchId;

      if (bunchIsChanged || manuallyChanged || pointChanged) {
        this.resetComputersIfCreateOrNotFirstLoad();
        this.loadTreeData();
      }
    }
  }

  resetComputersIfCreateOrNotFirstLoad(): void {
    if (this.isCreate || !this.firstLoad) {
      if (this.stepForm) {
        this.stepForm.get('folders').markAsTouched();
        this.stepForm.get('folders').setValue([]);
      }
    } else {
      if (this.isLinux && this.isEdit && !this.isNBF) {
        if (this.resultPoint) this.firstLoad = false;
      } else this.firstLoad = false;
    }
  }

  updateForm(value: RestoreSourceStepValue): void {
    this.stepForm.reset(value);
  }

  forceValid(): void {
    if (this.isRDMode) this.fileFoldersComponent.updateValidate();
    FormsUtil.triggerValidation(this.stepForm);
  }

  loadTreeData(password = ''): void {
    this.treeParams.params.Password = password;
    this.treeParams.params.path = '';
    this.treeParams.params.BunchId = this.bunchId;
    this.treeParams.params.ConnectionId = this.storageId;
    this.treeParams.params.RestorePointDateUtc =
      this.resultPoint && (this.isRestoreIbb || this.isNBF) ? StepsHelpers.getNormalDate(this.resultPoint.date) : '';
    this.treeParams.params.offset = 0;

    if (this.isNBF) {
      this.pathStringLength = [
        this.computerName || this.mainService.compName,
        this.storageId,
        this.treeParams.params.RestorePointDateUtc,
        this.bunchId
      ].filter((i) => !!i).length;
    } else this.pathStringLength = 1;

    this.treeParams.params.RestoreSourcePrefix = this.computerName || this.mainService.compName;
    this.loadingTree = true;
    this.loadInfo.emit({ loading: true });
    this.stepService
      .getRemoteCommandData(this.treeParams, this.mainService.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          if (data.data) {
            this.treeData = data.data.items.map((item, idx) => {
              const pathArray = item.path.split(this.joinForInput);
              return {
                label:
                  this.isLinux && (pathArray[pathArray.length - 1] || item.displayName === this.joinForInput)
                    ? item.displayName || pathArray[pathArray.length - 1].toUpperCase()
                    : item.displayName + this.joinForInput,
                id: `disk-${idx}`,
                icon: item?.type === 'Folder' ? TreeIconPath.Folder : TreeIconPath.Disk,
                checked: false,
                expanded: false,
                path: StepsHelpers.getRootPathWithJoin(item.path, this.joinForInput, this.isLinux, this.computerName),
                shown: true
              };
            });
            this.updateTreeFromStepFormArray();
          } else this.treeData = [];
          if (this.resultPoint && this.resultPoint.needDeepSync) this.stepService.deepSyncSuccess$.next(true);
          this.loadingTree = false;
          this.loadInfo.emit({ loading: false });
        },
        error: (e) => {
          this.loadInfo.emit({ loading: false });
          if (+e.error.errorCode === 2000 || +e.error.errorCode === 2046) {
            this.showPasswordModal(WhatRunAfterPassword.main);
          } else if (e && e.error && e.error.errorCode && +e.error.errorCode === 2526) {
            this.runDeepSync(this.treeParams.params.Password || '');
          } else {
            this.treeData = [];
            this.loadingTree = false;
          }
        }
      });
  }

  updateTreeFromStepFormArray(): void {
    const formArray = this.stepForm.get('folders').value;
    this.treeData.forEach((item: TreeElement) => {
      const path = (item as any).path;
      const formStrCurrent = formArray.some((incStr) => !StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, incStr, path));
      item.checked = formArray.some((s) => StepsHelpers.equalStrings(path, s, this.isLinux));
      if (this.isLinux && path === this.joinForInput) {
        const newInc = StepsHelpers.findInNotRootFolder(formArray, this.treeData);
        item.indeterminate = !!newInc.length && !item.checked;
      } else {
        item.indeterminate = formStrCurrent && !item.checked;
      }
    });
  }

  getSubtree(root: TreeElement): Observable<TreeElement[]> {
    const subtree$ = new BehaviorSubject<TreeElement[]>(null);
    this.treeParams.params.path = (root as any).path;
    const newInc = [];
    const newExc = [];
    this.treeParams.params.offset = root?.children
      ? root.children.reduce((acc, next) => {
          if (next.shown || next.shown !== false) acc++;
          return acc;
        }, 0)
      : 0;

    this.stepService
      .getRemoteCommandData(this.treeParams, this.mainService.hid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          const existData = data && data.data && data.data.items.length;
          const folders = this.stepForm.get('folders').value;
          const checkedAll = root.checked && !root.indeterminate;
          const settings = {
            rootTree: this.treeData,
            root,
            newInc,
            newExc,
            joinStr: this.joinForInput,
            sep: this.separator,
            alt: existData && isNaN(+data.data.items[0].type),
            isManually: this.manually,
            isLinux: this.isLinux,
            disableCheckbox:
              checkedAll &&
              (root.disableCheckbox ||
                (data?.data?.totalCount && data.data.totalCount > data.data.items?.length + this.treeParams.params.offset))
          };

          if (existData) {
            data.data.items = StepsHelpers.getSortedTreeItems(data.data.items, settings.alt);
          }

          if (this.treeParams.params.offset) {
            const pathMap = {};
            data.data.items.forEach((el) => (pathMap[el.restorePath] = true));
            root.children = root.children.filter((ch: MyTreeElements) => ch.shown || !pathMap[ch.path]);
          }

          let treeData = StepsHelpers.generateTreeData(data, folders, [], settings);

          this.updateNewTreeElementsIfExistVersion(treeData, folders, root);

          const isVersionNeedUpdate = this.manually && treeData.length && treeData[0].isVersion;

          if (isVersionNeedUpdate) {
            root.onlyOneSelect = true;
            treeData = this.updateCheckStateForFileVersions(treeData, root);
          }

          root.totalChildren = StepsHelpers.getTotalChildren(data?.data, treeData.length);

          if (root.children?.[0]?.disableCheckbox && treeData?.length + this.treeParams.params.offset >= root?.totalChildren) {
            root.children.forEach((el) => (el.disableCheckbox = false));
            treeData.forEach((el) => (el.disableCheckbox = false));
          }

          subtree$.next(treeData);
        },
        error: () => subtree$.next([])
      });
    return subtree$ as Observable<TreeElement[]>;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  updateNewTreeElementsIfExistVersion(treeData: ExtendedTreeElement[], folders: string[], root: TreeElement): void {
    const rootStr = this.getPathFromItem(root);
    const linuxOnWindowsPC = !this.isLinux && windowsInvalidPath.test(rootStr);
    let needPushVoid =
      root.indeterminate &&
      !root.checked &&
      treeData.every((c) => c.checked && !c.indeterminate) &&
      !folders.some((s) => StepsHelpers.equalStrings(s, rootStr, this.isLinux));
    treeData.forEach((element) => {
      if (element.isFolder && element.checked && !element.indeterminate) {
        const str = this.getPathFromItem(element);
        let includeVersion = false;
        let needAllCheck = false;
        folders.some((s) => {
          if (!StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, s, str) && this.testStrForVersion(s)) {
            includeVersion = true;
            if (s.split(this.joinForInput)?.length - str.split(this.joinForInput)?.length === 2) {
              needAllCheck = true;
            }
          }
        });
        if (includeVersion) {
          this.needAllCheckChildren[element.id] = needAllCheck;
          element.checked = false;
          element.indeterminate = true;
        }
      } else if (!element.isVersion && this.needAllCheckChildren[root.id]) {
        element.checked = true;
        element.indeterminate = false;
        needPushVoid = true;
      }
    });
    if (needPushVoid) treeData.push({ label: '', id: root.id + this.separator, shown: false });
    if (this.isLinux || linuxOnWindowsPC) {
      this.fixNotShownItemParent(root, folders);
      const foundRoot = this.getIndeterminateParent(root);
      if (foundRoot && foundRoot.children) foundRoot.children.push({ label: '', id: root.parent.id + this.separator, shown: false });
    } else if (!root.parent?.checked && root.parent?.indeterminate && !root.parent?.children?.some((c) => !c.shown)) {
      root.parent.children.push({ label: '', id: root.parent.id + this.separator, shown: false });
    }
    if (this.needAllCheckChildren[root.id]) {
      this.needAllCheckChildren[root.id] = false;
    }
  }

  fixNotShownItemParent(root: ExtendedTreeElement, folders: string[]): void {
    let parent: ExtendedTreeElement = root;
    while (parent) {
      const str = this.getPathFromItem(parent);
      if (folders.some((s) => StepsHelpers.equalStrings(s, str, this.isLinux))) {
        const notShownIdx = parent?.children?.length ? parent.children.findIndex((c) => !c.shown && !c.checked) : -1;
        if (parent?.checked && !parent?.indeterminate && notShownIdx !== -1) {
          parent.children[notShownIdx].checked = true;
          parent = null;
        } else parent = parent.parent;
      } else parent = parent.parent;
    }
  }

  getIndeterminateParent(root: ExtendedTreeElement): ExtendedTreeElement {
    let parent: ExtendedTreeElement = root;
    let result: ExtendedTreeElement = null;
    while (parent) {
      if (!parent?.checked && parent?.indeterminate && !parent?.children?.some((c) => !c.shown)) {
        result = parent;
        parent = null;
      } else parent = parent.parent;
    }
    return result;
  }

  updateCheckStateForFileVersions(treeData: ExtendedTreeElement[], root): ExtendedTreeElement[] {
    const newTreeData = treeData.sort((a: ExtendedTreeElement, b: ExtendedTreeElement) => {
      const aPath = a.path.split(this.joinForInput);
      const bPath = b.path.split(this.joinForInput);
      return moment(aPath[aPath.length - 1], cobbledDateFormat).isAfter(moment(bPath[bPath.length - 1], cobbledDateFormat)) ? -1 : 1;
    });

    if (!newTreeData.some((td) => !td.checked) || (root.checked && newTreeData.every((td) => !td.checked))) {
      newTreeData.forEach((e, i) => (e.checked = (i === 0 && e.shown) || (!newTreeData[0].shown && i === 1 && e.shown)));
    }

    return newTreeData;
  }

  testStrForVersion(str: string): boolean {
    const strArr = str.split(this.joinForInput);
    const lastElem = strArr[strArr.length - 1];
    const existNormal = dateTimePointsReg.test(lastElem);
    const existCobble = dateTimePointsFormat.test(lastElem);
    return existNormal || existCobble;
  }

  getPathFromItem(item: TreeElement & { path?: string }): string {
    return ((item as any).restorePath || item.path || item.label) as string;
  }

  getFilteredPathStrings(
    strings: string[],
    isVersion: boolean,
    str: string,
    parenStrings,
    linuxOnWindowsPC = false,
    repStr?: string
  ): string[] {
    let newStrings = strings.filter((s) => {
      const sLast = s[!linuxOnWindowsPC && parenStrings.strParLen <= 3 ? parenStrings.strParLen - 1 : parenStrings.strParLen];
      return (
        !s.includes(parenStrings.strParent) ||
        (isVersion
          ? StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, s, parenStrings.strParent) &&
            !StepsHelpers.equalStrings(s, parenStrings.strParent, this.isLinux)
          : sLast && sLast !== this.joinForInput) ||
        (!StepsHelpers.equalStrings(s, str, this.isLinux) &&
          StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, s, str) &&
          s.split(this.joinForInput).length - parenStrings.strParent.split(this.joinForInput).filter((s) => !!s).length > 1)
      );
    });
    if (this.isLinux && repStr)
      newStrings = this.getFilteredPathStrings(newStrings, isVersion, repStr, {
        strParent: parenStrings.replacedParent,
        strParLen: parenStrings.replacedParentLen
      });
    return newStrings;
  }

  getReplacedStr(str: string): string {
    return this.isLinux && str.startsWith('CBB_ROOT')
      ? str.startsWith('CBB_ROOT/')
        ? str.replace('CBB_ROOT/', this.joinForInput)
        : str.replace('CBB_ROOT', this.joinForInput)
      : str;
  }

  getParentStrAndLengthStr(parent: ExtendedTreeElement): {
    strParent: string;
    strParLen: number;
    replacedParent: string;
    replacedParentLen: number;
  } {
    let strParent = this.getPathFromItem(parent);
    const replacedParent = this.getReplacedStr(strParent);
    let strParLen = strParent.length;
    const replacedParentLen = replacedParent.length;
    if (!this.isLinux && strParLen === 2) {
      strParent += this.joinForInput;
      strParLen += 1;
    }
    return { strParent, strParLen, replacedParent, replacedParentLen };
  }

  getFilteredPathStringsForUncheckEvent(strings: string[], str: string, repStr?: string): string[] {
    let result = strings.filter(
      (s) => !StepsHelpers.equalStrings(s, str, this.isLinux) && StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, str, s)
    );
    if (this.isLinux && repStr) result = this.getFilteredPathStringsForUncheckEvent(result, repStr);
    return result;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  changeTreeHandler(event: ExtendedTreeElement): void {
    let str = !this.isLinux && !event.parent ? (event.label as string) : this.getPathFromItem(event);
    let childrenShowedCount = 0;
    const replacedStr = this.getReplacedStr(str);
    const linuxOnWindowsPC = !this.isLinux && windowsInvalidPath.test(str);

    if (!this.isLinux && str.length === 2) str += this.joinForInput;
    if (event.parent) {
      const parenStrings = this.getParentStrAndLengthStr(event.parent);
      let pathStrings = this.getFilteredPathStrings(
        this.stepForm.get('folders').value,
        event.isVersion,
        str,
        parenStrings,
        linuxOnWindowsPC,
        replacedStr
      );
      event.children?.forEach((ch) => {
        if (ch.shown) childrenShowedCount++;
        if (!ch.shown && ch.checked) ch.checked = false;
        if (ch.disableCheckbox && !event.checked) ch.disableCheckbox = false;
      });
      event.parent?.children?.forEach((c: MyTreeElements) => !c.shown && c.checked && c.path && pathStrings.push(c.path));
      if (event.checked) {
        event.parent.children.forEach(
          (c) =>
            c.shown && c.checked && pathStrings.push(StepsHelpers.getPathInStringFormatByOs(c, this.isLinux, false, false, this.isLinux))
        );
        if (!event.isVersion) StepsHelpers.uncheckAllParent(event.parent, true, true);
        else if (event.parent?.parent) StepsHelpers.uncheckAllParent(event.parent.parent, true, true);
        if (event.children?.some((c: ExtendedTreeElement) => c.isVersion)) {
          event.children.forEach((child, i) => (child.checked = child.shown && i === 0));
        }
        if (event.totalChildren > childrenShowedCount) event.children.forEach((ch) => (ch.disableCheckbox = true));
      } else {
        if (
          (!event.parent.indeterminate ||
            pathStrings.some((s) => str.includes(s)) ||
            linuxOnWindowsPC ||
            this.isLinuxAndRootBranch(event.parent)) &&
          !event.parent.checked &&
          event.parent.parent
        ) {
          const indeterminateOrChecked = this.getIndeterminateOrCheckedParent(event.parent.parent, pathStrings);
          const newStr = this.getPathFromItem(indeterminateOrChecked);
          pathStrings = this.getFilteredPathStringsForUncheckEvent(pathStrings, newStr, this.getReplacedStr(newStr));
          this.updateAllChildren(indeterminateOrChecked.children, pathStrings);
        }
        if (event.parent.indeterminate || event.isVersion) {
          pathStrings = pathStrings.filter((s) => {
            return this.isLinux
              ? !StepsHelpers.equalStrings(s, parenStrings.strParent, this.isLinux) &&
                  !StepsHelpers.equalStrings(s, parenStrings.replacedParent, this.isLinux)
              : !StepsHelpers.equalStrings(s, parenStrings.strParent, this.isLinux);
          });
        }
        if (!event.isVersion && event.parent.children?.some((c) => c.checked)) {
          const existVersion = event.parent?.children?.some(
            (c) =>
              c.shown &&
              c.checked &&
              pathStrings.some((s) => {
                const strFromChild = this.getPathFromItem(c);
                return this.isLinux
                  ? !StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, s, strFromChild) &&
                      !StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, s, this.getReplacedStr(strFromChild)) &&
                      this.testStrForVersion(s)
                  : !StepsHelpers.pathNotIncludePath(this.isLinux, this.joinForInput, s, strFromChild) && this.testStrForVersion(s);
              })
          );
          if (!existVersion) {
            event.parent.children.forEach(
              (c) =>
                c.shown &&
                c.checked &&
                pathStrings.push(StepsHelpers.getPathInStringFormatByOs(c, this.isLinux, false, false, this.isLinux))
            );
          }
          StepsHelpers.uncheckAllParent(event.parent, true, true, linuxOnWindowsPC);
        } else {
          StepsHelpers.uncheckAllParent(event.parent, true, true, linuxOnWindowsPC);
        }
      }
      this.stepForm.get('folders').reset(Array.from(new Set(pathStrings)));
    } else {
      const pathStrings = this.isLinux
        ? this.stepForm
            .get('folders')
            .value.filter((s) => !s.includes(str) && !str.includes(s) && !s.includes(replacedStr) && !replacedStr.includes(s))
        : this.stepForm.get('folders').value.filter((s) => !s.includes(str) && !str.includes(s));

      event.children?.forEach((ch) => {
        if (ch.shown) childrenShowedCount++;
        if (ch.disableCheckbox && !event.checked) ch.disableCheckbox = false;
      });

      if (event.checked) {
        pathStrings.push(str);
        if (event.totalChildren > childrenShowedCount) event.children.forEach((ch) => (ch.disableCheckbox = true));
      }

      this.stepForm.get('folders').reset(Array.from(new Set(pathStrings)));
    }
  }

  updateAllChildren(children: ExtendedTreeElement[], stringsArray: string[]): void {
    children.forEach((c) => {
      if (c.shown && c.checked) stringsArray.push(StepsHelpers.getPathInStringFormatByOs(c, this.isLinux, false, false, this.isLinux));
      if (c.children?.length) this.updateAllChildren(c.children, stringsArray);
    });
  }

  isLinuxAndRootBranch(item: ExtendedTreeElement): boolean {
    let parent: ExtendedTreeElement = item.parent || item;
    while (parent?.parent) {
      parent = parent.parent;
    }
    const path = this.getPathFromItem(parent);
    return (this.isLinux && path === this.joinForInput) || path === 'CBB_ROOT';
  }

  getIndeterminateOrCheckedParent(item: ExtendedTreeElement, stringsArray: string[]): ExtendedTreeElement {
    if (
      !item.parent ||
      ((item.indeterminate || !item.checked) &&
        (stringsArray.some((s) => StepsHelpers.equalStrings(s, this.getPathFromItem(item), this.isLinux)) || !item.parent))
    )
      return item;
    return this.getIndeterminateOrCheckedParent(item.parent, stringsArray);
  }

  showLegend(): void {
    const modal = this.modal.openRef(CheckboxLegendComponent, { isCustom: true });

    modal.componentInstance.isRestore = true;

    modal.result.then(noop).catch(noop);
  }

  runDeepSync(pass = ''): void {
    this.loadInfo.emit({ loading: true });
    const deepSyncParams = this.getDeepSyncParams(pass, true);
    this.stepService
      .getRemoteCommandData(deepSyncParams, this.mainService.hid)
      .pipe(first(), untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.loadInfo.emit({ loading: false });
          if (result && result.data) {
            const syncDataFromRes = { deepSync: DeepSyncEnum[result.data as string], progress: 0, progressMessage: '' };
            if (syncDataFromRes.deepSync === DeepSyncEnum.InvalidPassword || syncDataFromRes.deepSync === DeepSyncEnum.NeedPassword) {
              if (syncDataFromRes.deepSync === DeepSyncEnum.InvalidPassword) this.passwordInvalid = true;
              this.showPasswordModal(WhatRunAfterPassword.deepSync);
            } else {
              this.getDeepSyncStatus(pass, syncDataFromRes);
            }
          }
        },
        error: () => this.loadInfo.emit({ loading: false })
      });
  }

  getDeepSyncStatus(pass = '', syncDate = { progress: 0, deepSync: DeepSyncEnum.InProgress, progressMessage: '' }): void {
    this.loadInfo.emit({ loading: true });
    const toast = { header: 'DeepSync process...', content: `${syncDate.progress}%`, type: MbsPopupType.info, delay: 5000 };
    if (syncDate.deepSync !== DeepSyncEnum.Error) {
      if (syncDate.deepSync === DeepSyncEnum.InvalidPassword || syncDate.deepSync === DeepSyncEnum.NeedPassword) {
        this.currentSyncData = null;
        if (syncDate.deepSync === DeepSyncEnum.InvalidPassword) this.passwordInvalid = true;
        this.loadInfo.emit({ loading: false });
        this.showPasswordModal(WhatRunAfterPassword.deepSync);
        toast.content =
          syncDate.deepSync === DeepSyncEnum.InvalidPassword ? syncDate.progressMessage || 'Invalid Password' : 'Need Password';
        toast.type = MbsPopupType.danger;
      } else if (syncDate.deepSync !== DeepSyncEnum.InProgressColdStorage && syncDate.deepSync !== DeepSyncEnum.InProgress) {
        this.currentSyncData = null;
        this.loadInfo.emit({ loading: false });
        toast.type = MbsPopupType.success;
        this.stepService.deepSyncSuccess$.next(true);
        this.loadTreeData();
      } else {
        toast.type = MbsPopupType.info;
        const getDeepSyncStatusParams = this.getDeepSyncParams(pass);
        this.stepService
          .getRemoteCommandData(getDeepSyncStatusParams, this.mainService.hid)
          .pipe(first(), debounceTime(800), untilDestroyed(this))
          .subscribe({
            next: (res) => {
              if (res) {
                if (res.data) {
                  const syncDataFromRes = {
                    deepSync: DeepSyncEnum[res.data.state as string],
                    progressMessage: res.data.progressMessage,
                    progress: res.data.state === 'Done' ? 100 : res.data.progress
                  };
                  this.getDeepSyncStatus(pass, syncDataFromRes);
                } else if (res.ok) {
                  const syncDataFromRes = { deepSync: DeepSyncEnum.InProgress, progress: 0, progressMessage: '' };
                  this.getDeepSyncStatus(pass, syncDataFromRes);
                }
              }
              this.loadInfo.emit({ loading: false });
            },
            error: (e) => {
              this.loadInfo.emit({ loading: false });
              const newToast = Object.assign({}, toast);
              newToast.content = (e && e.error && e.error.message) || unidentifiedErrorText;
              newToast.type = MbsPopupType.danger;
              this.toastService.toast(newToast);
              this.currentSyncData = null;
            }
          });
      }
    } else {
      this.loadInfo.emit({ loading: false });
      toast.content = 'Error';
      toast.type = MbsPopupType.warning;
    }
    if (
      !this.currentSyncData ||
      this.currentSyncData.deepSync !== syncDate.deepSync ||
      this.currentSyncData.progress !== syncDate.progress
    ) {
      this.toastService.toast(toast);
    }
    this.currentSyncData = syncDate;
  }

  private getDeepSyncParams(pass = '', isRun = false): ParamsForDeepSync {
    const newParams: ParamsForDeepSync = {
      agentType: 'backup',
      commandType: isRun ? 'RunDeepSync' : 'GetDeepSyncStatus',
      params: {
        connectionId: this.storageId,
        restoreSourcePrefix: this.computerName,
        bunchId: this.bunchId,
        restorePointDateUtc: StepsHelpers.getNormalDate(this.resultPoint.date)
      }
    };
    if (pass) newParams.params.Password = pass;
    return newParams;
  }

  showPasswordModal(whatRun: WhatRunAfterPassword): void {
    const data = {
      password: this.mainService.password.value || '',
      hid: this.mainService.hid,
      passwordInvalid: this.passwordInvalid,
      showHint: false,
      passwordRecoveryEnabled: this.allowedRecovery && this.isNBF && !this.isLinux,
      backupVersionUpdated: this.mainService.backupVersionUpdated,
      restorePoint: this.resultPoint
    };

    this.modal
      .openCustom(PasswordModalComponent, { data, collapsing: true })
      .then((result: { password: FormControl }) => {
        const passControl = result.password;
        this.mainService.password.next(passControl.value);
        this.passwordsForBunches[this.bunchId] = passControl.value;
        if (passControl.valid) {
          if (whatRun === WhatRunAfterPassword.deepSync) {
            this.runDeepSync(passControl.value);
          } else if (whatRun === WhatRunAfterPassword.statusDeepSync) this.getDeepSyncStatus(passControl.value);
          else {
            this.loadTreeData(passControl.value);
          }
        }
      })
      .catch(() => this.loadInfo.emit({ loading: false }));
  }
}
