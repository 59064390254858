import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import Administrator from '@models/Administrator';
import { DisablePasswordRecoveryModalComponent } from '@modules/password-recovery/modals/disable-password-recovery-modal/disable-password-recovery-modal.component';
import { EnablePasswordRecoveryWizardComponent } from '@modules/password-recovery/modals/enable-password-recovery-wizard/enable-password-recovery-wizard.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TFARequireModalComponent } from '@root/mbs-ui/src/app/shared/components/tfa/components/tfa-require-modal/tfa-require-modal.component';
import { ProviderService } from '@services/provider.service';
import { mediumDateWithTimeMoment } from '@utils/date';
import { AbilityService } from 'ability';
import { MbsSize, ModalService, SidepanelBase } from 'mbs-ui-kit';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@services';

@UntilDestroy()
@Component({
  templateUrl: './sidepanel-password-recovery.component.html',
  styleUrls: ['./sidepanel-password-recovery.component.scss']
})
export class SidepanelPasswordRecoveryComponent extends SidepanelBase implements OnInit {
  public isDataLoaded = false;
  public showErrorAlert = false;

  public dateFormat = mediumDateWithTimeMoment;

  public dateCreated: string | number | Date = new Date();

  public form = new UntypedFormGroup({
    switcher: new FormControl(false)
  });

  public switcher = this.form.get('switcher');

  constructor(
    private auth: AuthService,
    private providerService: ProviderService,
    private modalService: ModalService,
    private ability: AbilityService
  ) {
    super();
    this.auth.currentUser.pipe(untilDestroyed(this)).subscribe((user: Administrator) => {
      this.updateSwitcher(user.ProviderInfo.PasswordRecoveryEnabled);
    });
  }

  ngOnInit(): void {
    this.switcher.valueChanges.pipe(untilDestroyed(this)).subscribe(this.handleSwitch);
    this.refreshRecoveryStatus();
  }

  handleSwitch = (value: boolean): void => {
    if (this.ability.cannot('read', '2FAEnabled')) {
      this.modalService
        .openCustom(TFARequireModalComponent, {
          size: MbsSize.sm,
          data: {
            actionName: 'Password Recovery'
          }
        })
        .catch(() => {
          this.updateSwitcher(!value);
        });
      return;
    }

    if (value) {
      this.showEnableModal()
        .then(() => {
          this.updateSwitcher(true);
          this.refreshRecoveryStatus();
        })
        .catch(() => {
          this.updateSwitcher(false);
        });
    } else {
      this.showDisableModal()
        .then(() => {
          this.updateSwitcher(false);
          this.refreshRecoveryStatus();
        })
        .catch(() => this.updateSwitcher(true));
    }
  };

  showEnableModal(): Promise<any> {
    return this.modalService.openRef(EnablePasswordRecoveryWizardComponent, { size: MbsSize.sm, isCustom: true }).result;
  }

  showDisableModal(): Promise<any> {
    return this.modalService.openRef(DisablePasswordRecoveryModalComponent, { size: MbsSize.sm, isCustom: true }).result;
  }

  updateSwitcher = (value): void => {
    this.switcher.setValue(value, { onlySelf: true, emitEvent: false });
  };

  refreshRecoveryStatus(): void {
    this.providerService
      .getPasswordRecoveryState()
      .pipe(
        untilDestroyed(this),
        catchError(() => of(null))
      )
      .subscribe({
        next: (data) => {
          this.updateSwitcher(data.enabled);
          data?.keyDate && (this.dateCreated = data.keyDate);
        },
        error: (err) => {
          if (err.status === 500 && err.error.type === 'https://mspbackups.com/errors/mbs/4123') {
            this.showErrorAlert = true;
          }
        },
        complete: () => {
          this.isDataLoaded = true;
        }
      });
  }
}
