import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputBaseModule } from '../input-base/input-base.module';
import { TagsInputComponent } from './tags-input.component';
import { TagModule } from '../../tag';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../button';

@NgModule({
  imports: [
    CommonModule,
    InputBaseModule,
    ReactiveFormsModule,
    TagModule,
    ButtonModule,
    NgScrollbarModule,
    NgbDropdownModule
  ],
  declarations: [TagsInputComponent],
  exports: [TagsInputComponent]
})
export class TagsInputModule {}
