import { APP_BASE_HREF, DOCUMENT, Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, GuardsCheckEnd, Router } from '@angular/router';
import { ComputersForceUpdateModalComponent } from '@components/computers-modal/computers-force-update-modal/computers-force-update-modal.component';
import { ComputersSendLogsModalComponent } from '@components/computers-modal/computers-send-logs-modal/computers-send-logs-modal.component';
import { ComputersUninstallAgentModalComponent } from '@components/computers-modal/computers-uninstall-agent-modal/computers-uninstall-agent-modal.component';
import { DeepInstinctModalComponent } from '@components/deep-instinct-modal/deep-instinct-modal.component';
import { PaymentDebtModalComponent } from '@components/payment-debt-modal/payment-debt-modal.component';
import { SidepanelDownloadsComponent } from '@components/sidepanel-downloads/sidepanel-downloads.component';
import { SidepanelRmmComponent } from '@components/sidepanel-rmm/sidepanel-rmm.component';
import { SubscribeUbAwsComponent } from '@components/storage-accounts/subscribe-ub-aws/subscribe-ub-aws.component';
import { SubscribeUbComponent } from '@components/storage-accounts/subscribe-ub-base';
import { SubscribeUbWasabiComponent } from '@components/storage-accounts/subscribe-ub-wasabi/subscribe-ub-wasabi.component';
import { UbCancelDeletionComponent } from '@components/storage-accounts/ub-cancel-deletion/ub-cancel-deletion.component';
import { debtData, UbSubscribeService } from '@components/storage-accounts/ub-subscribe.service';
import { ChangeEmailModalComponent } from '@components/tfa/components/change-email-modal/change-email-modal.component';

import { RecoveryCodeModalComponent } from '@components/tfa/components/recovery-code-modal/recovery-code-modal.component';
import { SidepanelDevicesComponent } from '@components/tfa/components/sidepanel-devices/sidepanel-devices.component';
import { TwoFactorEnableModalComponent } from '@components/tfa/components/two-factor-enable-modal/two-factor-enable-modal.component';
import { TwoFactorAuthActionType, TwoFactorMessages } from '@components/tfa/models/tfa';
import { TFAService } from '@components/tfa/services/tfa.service';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { environment } from '@mbs-ui/environments/environment';
import Administrator from '@models/Administrator';
import { PlanInfo } from '@models/backup/plan-info-model';
import { SidepanelRouteType } from '@models/backup/sidepanel-route-type';
import { BackupSidePanelTab } from '@models/backup/sidepanel-tab';
import Computer, { AgentType } from '@models/Computer';
import { TokenRedirect } from '@models/empty-page/token-redirect.model';
import { PaymentSystem } from '@models/PaymentSystem';
import { PlanMode, PlanTypes } from '@models/PlanTypes.enum';
import { GroupActionMode } from '@models/rm/remote-command-model';
import StorageAccount, { StorageAccountCamelCase } from '@models/StorageAccount';
import { StorageType } from '@models/StorageType.enum';
import { EmptyPageService } from '@modules/empty-page/empty-page.service';
import { IframeMessage } from '@modules/empty-page/IframeMessage';
import { InteractionEvent } from '@modules/empty-page/InteractionEvent';
import { MinimumStorageDurationComponent } from '@modules/empty-page/modals/minimum-storage-duration/minimum-storage-duration.component';
import { OverdueModalComponent } from '@modules/overdue-modal/overdue-modal.component';
import { UbAwsChangingPriceModalComponent } from '@modules/storage-accounts/ub-aws-changing-price-modal/ub-aws-changing-price-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { AdministratorService, FilterLicenseAndAdminId } from '@services/administrator.service';
import { AuthService } from '@services/auth.service';
import { ConnectService } from '@services/connect.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { StorageAccountsService } from '@services/storage-accounts/storage-accounts.service';
import { booleanFromStringFormat } from '@utils/booleanFromStringFormat';
import { handleRMMError } from '@utils/handleError';
import { convertToClientUrl, removeDoubleSlash } from '@utils/pipes/client-url.pipe';
import { getWizardComponentType } from '@utils/rd-and-wizards/getWizardComponentType';
import { ComputerSidepanelWrapper } from '@wrappers/computer.sidepanel.wrapper';
import { I18NextService } from 'angular-i18next';
import { get } from 'lodash';
import { MbsSize, ModalService, ModalSettings, SidepanelService, Toast, ToastService } from 'mbs-ui-kit';
import {
  asapScheduler,
  combineLatest,
  forkJoin,
  from,
  noop,
  Observable,
  of,
  scheduled,
  Subject,
  Subscription,
  take,
  throwError
} from 'rxjs';
import { catchError, delay, filter, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import * as URLParse from 'url-parse';
import { ImmutabilityConfirmModalComponent } from '../immutability-modals/immutability-confirm-modal/immutability-confirm-modal.component';
import { ImmutabilityDeleteModalComponent } from '../immutability-modals/immutability-delete-modal/immutability-delete-modal.component';
import { RMMGroupActionWizardComponent } from '../schedule-group-action/rmm-group-action-wizard.component';
import { SignalRService } from '../signal-r/signal-r.service';
import { PlansWizardMode } from '../wizards/models/base/base-plan-models';
import { RemoteManagementWizardsService, WizardSettings } from '../wizards/services/remote-management-wizards.service';
import { ConfirmRedirectToAppsAsProviderComponent } from './modals/confirm-redirect-to-apps-as-provider/confirm-redirect-to-apps-as-provider.component';

@UntilDestroy()
@Component({
  selector: 'mbs-empty-page',
  template: `
    <div [ngStyle]="{ height: scrollHeight + 'px' }" style="overflow-y:hidden">
      <iframe
        [src]="iframeSrc | safe: 'resourceUrl'"
        name="mbs-iframe-body"
        [height]="scrollHeight"
        class="iframe-settings"
        [ngStyle]="{ visibility: loading ? 'hidden' : 'visible' }"
        (load)="handleLoadIframe()"
        (window:resize)="handleResizeIframe()"
        #iframeRef></iframe>
    </div>
    <mbs-loader *ngIf="loading"></mbs-loader>
    <router-outlet></router-outlet>
  `,

  styles: [
    `
      .iframe-settings {
        border: 0;
        width: 100%;
      }
    `
  ],
  providers: [EmptyPageService]
})
export class EmptyPageComponent implements AfterContentInit, OnInit, OnDestroy {
  readonly ASPX_PAGE_POSTFIX = '.aspx';
  public iframeSrc: string;
  @ViewChild('iframeRef', { static: true }) iframeRef: ElementRef<HTMLIFrameElement>;

  iframeWindow: Window;
  mainWindow: Window;

  public isTurnOnTFA = false;
  private isGrantLicense = false;
  public loading = true;
  public scrollHeight = 0;
  public realHeight = 0;
  private fullHref: string;
  public layoutElms: {
    header: Element;
    main: Element;
    footer: Element;
  };

  currentUser: Administrator;

  @Input() addAspxPostfix? = false;
  @Output() iframeContentLoaded = new EventEmitter<boolean>();

  private mailto = 'sales@msp360.com';

  public messagesSubj$: Subject<IframeMessage> = new Subject();

  constructor(
    public administratorService: AdministratorService,
    private sidepanelService: SidepanelService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(APP_BASE_HREF) private appBaseHref: string,
    private cd: ChangeDetectorRef,
    private hostEl: ElementRef,
    @Inject(DOCUMENT) private dom: Document,
    private auth: AuthService,
    private wizardService: RemoteManagementWizardsService,
    private modal: ModalService,
    private toastService: ToastService,
    private storageAccounts: StorageAccountsService,
    private location: Location,
    private ubSubscribeService: UbSubscribeService,
    private emptyPageService: EmptyPageService,
    private http: HttpClient,
    private i18nextService: I18NextService,
    private computersFacade: ComputersFacade,
    private TFAService: TFAService,
    private connectService: ConnectService,
    private errorService: ErrorHandlerService,
    private signalRService: SignalRService,
    private computerSidepanelWrapper: ComputerSidepanelWrapper
  ) {
    this.fullHref = (environment.baseHref || '') + this.appBaseHref;

    this.layoutElms = {
      header: this.dom.querySelector('.mbs-header'),
      main: this.dom.querySelector('.mbs-container .mbs-container-inner .mbs-container-content'),
      footer: this.dom.querySelector('.mbs-footer')
    };

    this.setIframeSrc(this.router.url.toString());

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      const state = router.getCurrentNavigation().extras.state || {};
      if (state.skipUpdateIframeSrc !== true && event instanceof GuardsCheckEnd && event.shouldActivate) {
        this.sidepanelService.remove('computer_rmm_info');
        this.sidepanelService.remove('devices-sidepanel');
        this.setIframeSrc(event.url);
      }
    });
  }

  ngOnInit(): void {
    this.sidepanelService.onOpen
      .pipe(untilDestroyed(this))
      .subscribe(({ name }) => this.emptyPageService.handleSendMessageToChild({ msg: InteractionEvent.PARENT_SIDEPANEL_OPEN, name }));
    this.sidepanelService.onClose
      .pipe(untilDestroyed(this))
      .subscribe(({ name }) => this.emptyPageService.handleSendMessageToChild({ msg: InteractionEvent.PARENT_SIDEPANEL_CLOSE_END, name }));

    this.auth.currentUser.pipe(untilDestroyed(this)).subscribe((user) => {
      this.currentUser = user;
      this.setIframeSrc(this.router.url.toString());
    });
  }

  ngOnDestroy(): void {
    this.iframeContentLoaded.unsubscribe();
    this.emptyPageService.destroy();
  }

  ngAfterContentInit(): void {
    this.iframeWindow = this.iframeRef.nativeElement.contentWindow;
    this.mainWindow = window.parent;

    this.emptyPageService.init(this.iframeWindow);
  }

  setIframeSrc(url: string): void {
    const currentUrl = new URLParse(url);
    currentUrl.set('pathname', currentUrl.pathname.replace('/AP/', '/Admin/'));

    if (this.addAspxPostfix && !currentUrl.pathname.includes(this.ASPX_PAGE_POSTFIX)) {
      currentUrl.set('pathname', currentUrl.pathname + this.ASPX_PAGE_POSTFIX);
    }

    this.iframeSrc = removeDoubleSlash(this.appendIframeParameter(currentUrl.href.replace(currentUrl.origin, '')));
    if (this.iframeSrc.includes('AP/Help')) {
      this.loading = false;
    }
    if (this.iframeWindow) {
      setTimeout(() => {
        try {
          this.iframeWindow && this.iframeWindow.location.assign(this.iframeSrc);
        } catch (error) {
          this.iframeRef.nativeElement.src = this.iframeSrc;
        }
      });
    }
  }

  reloadIframe(): void {
    this.iframeWindow.location.reload();
  }

  appendIframeParameter(rawUrl: string): string {
    const url = new URLParse(rawUrl);
    const rawQuery = url.query; // seem bug in lib
    const query = URLParse.qs.parse(rawQuery);
    query['IFRAME'] = String(true);
    url.set('query', query);

    let clearUrl = url.href.replace(url.origin, '');
    if (clearUrl.startsWith(this.appBaseHref)) {
      clearUrl = clearUrl.substring(this.appBaseHref.length);
    }
    return removeDoubleSlash(this.fullHref + clearUrl);
  }

  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent): void {
    this.messagesSubj$.next(event.data);
    const hid: string = event.data?.computer?.Hid || event.data?.computer?.hid || event.data?.computerHid;
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (event.data.msg) {
      case 'open RD app':
        if (event.data.url) {
          document.location.href = event.data.url;
        }
        break;
      case 'Open Downloads':
        // eslint-disable-next-line no-case-declarations
        const panel = SidepanelDownloadsComponent;

        if (!this.sidepanelService.contains(panel)) {
          this.sidepanelService.add(panel);
        }
        this.sidepanelService.openByType(panel).subscribe();
        break;
      case InteractionEvent.RMM_SIDEPANEL_OPEN:
        // eslint-disable-next-line no-case-declarations
        let subscription: Subscription;
        // eslint-disable-next-line no-case-declarations
        let rmmSidepanel = this.sidepanelService.get(SidepanelRmmComponent);
        if (rmmSidepanel === null || rmmSidepanel === undefined) {
          rmmSidepanel = this.sidepanelService.add(SidepanelRmmComponent);
        }
        scheduled(
          this.computersFacade.currentComputer$.pipe(
            filter(Boolean),
            first((computer) => computer.hid === hid),
            untilDestroyed(this),
            tap(() => {
              this.sidepanelService.open('computer_rmm_info');
              this.sidepanelService.toggleLoadingDataByType(SidepanelRmmComponent);
              rmmSidepanel.openBackupPanel.pipe(first()).subscribe((computer) =>
                this.router.navigate(['/AP', 'Computers.aspx'], {
                  queryParams: { sidepanel: 'backup', hid: computer.hid }
                })
              );
            }),
            delay(300)
          ),
          asapScheduler
        ).subscribe((computer) => {
          const selectTab = (items) => {
            const isTabExist = items.toArray().some((tab) => tab.id === event.data.activeTab);
            if (event.data.activeTab && isTabExist) {
              rmmSidepanel.tabset.select(event.data.activeTab);
              if (subscription) {
                subscription.unsubscribe();
              }
            } else {
              rmmSidepanel.tabset.select('general');
            }
          };
          rmmSidepanel.data$.next(computer);
          if (computer?.online) {
            // if computer is offline tab list will not change
            // so - try to find requested tab
            subscription = rmmSidepanel.tabset.items.changes
              .pipe(startWith(rmmSidepanel.tabset.items), untilDestroyed(this))
              .subscribe(selectTab);
          } else {
            // if computer is online - tab list will change after all checks in sidepanel
            // so we wait for changes and then try to find requested tab in updated list
            selectTab(rmmSidepanel.tabset.items);
          }
        });
        if (hid) {
          this.computersFacade.setSelected(hid);
        } else {
          this.toastService.error(this.i18nextService.t('error:emptyPage:hidIsMissingForRMMInfo'));
        }
        break;
      case InteractionEvent.BACKUP_SIDEPANEL_OPEN:
        if (hid) {
          scheduled(
            this.computersFacade.currentComputer$.pipe(
              filter(Boolean),
              first((computer) => computer.hid === hid),
              untilDestroyed(this)
            ),
            asapScheduler
          ).subscribe((computer) => {
            this.computerSidepanelWrapper.handleOpenBackupPanel(computer, BackupSidePanelTab.backupPlans);
          });
          this.computersFacade.setSelected(hid);
        } else {
          this.toastService.error(this.i18nextService.t('error:emptyPage:hidIsMissingForBackupInfo'));
        }
        break;
      case InteractionEvent.RMM_MANAGEMENT_SIDEPANEL_OPEN:
        this.router.navigate(['/AP/ScriptLibrary']);
        break;
      case InteractionEvent.RMM_TASKS_SIDEPANEL_OPEN: {
        this.router.navigate(['/AP/RMMGroupTaskAction']);
        break;
      }
      case InteractionEvent.SCHEDULED_RMM_GROUP_ACTION_OPEN: {
        this.modal.openWizard(RMMGroupActionWizardComponent, { size: 'lg' }).finally(noop);
        break;
      }
      case 'iframe click':
        this.hostEl.nativeElement.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
        this.hostEl.nativeElement.dispatchEvent(new MouseEvent('mouseup', { bubbles: true }));
        this.handleClickHref(event.data);
        break;
      case 'loadstart':
        this.loading = true;
        break;
      case 'loadend':
        if (event.data.locationUrl && !this.iframeSrc.includes(new URLParse(event.data.locationUrl).pathname)) {
          this.handleClickHref({ url: event.data.locationUrl });
          break;
        }
        this.handleLoadIframe();
        this.loading = false;
        break;
      case 'href':
        this.handleClickHref(event.data);
        break;
      case InteractionEvent.IFRAME_SIDEPANEL_OPEN:
        this.showModalIfBackupHistory(event.data);
        this.sidepanelService
          .closeAll()
          .subscribe((result) =>
            this.emptyPageService.handleSendMessageToChild({ msg: InteractionEvent.PARENT_SIDEPANEL_CLOSE_END, result })
          );
        break;
      case InteractionEvent.IFRAME_IN_IFRAME:
        if (environment.production || !window.top.location.pathname.includes(window.location.pathname)) {
          this.handleRedirect({ redirectUrl: event.data.currentRoute, sameHost: true });
        }
        break;
      case 'redirect':
        this.handleRedirect(event.data);
        break;
      case 'refreshAuth':
        this.refreshAuth();
        break;
      case 'fetchCurrentUser':
        this.fetchCurrentUser();
        break;
      case InteractionEvent.GETTING_START_WIZARD_COMPLETE:
        this.auth.setCompleteGettingStartWizard();
        this.computersFacade.pollingComputersAndOpenSidePanel(SidepanelRouteType.RMM, null, 1);
        break;
      case 'openPlansWizard':
        this.openWizard(JSON.parse(event.data.req));
        break;
      case 'openImmutabilityModal':
        this.openImmutabilityModal(event);
        break;
      case 'disableImmutabilityModal':
        this.disableImmutabilityModal(event);
        break;
      case 'grantLicense':
        this.grantLicense(event.data.req);
        break;
      case 'openPlansWizardWithShortParamsModel':
        this.openWizardWithShortDataModel(JSON.parse(event.data.req));
        break;
      case 'openWebRTCConnect':
        // this.modals.openWebRTCConnect(event.data.req);
        break;
      case InteractionEvent.CONFIRM_MODAL_SWITCH_UB:
        this.handleUbSwitchMode(event.data.storageAccountId);
        break;
      case InteractionEvent.SEND_LOGS_MODAL_OPEN:
        this.openModalByParams(ComputersSendLogsModalComponent, hid, event.data?.plan);
        break;
      case InteractionEvent.UNINSTALL_AGENT_MODAL_OPEN:
        this.openModalByParams(ComputersUninstallAgentModalComponent, hid);
        break;
      case InteractionEvent.FORCE_UPDATE_MODAL_OPEN:
        // eslint-disable-next-line no-case-declarations
        const openForceUpdateModal = this.modal.openRef(ComputersForceUpdateModalComponent);
        // eslint-disable-next-line no-case-declarations
        const isGroupAction = event.data.isGroupAction;
        // eslint-disable-next-line no-case-declarations
        const modalInstance = openForceUpdateModal.componentInstance as ComputersForceUpdateModalComponent;
        openForceUpdateModal.result.finally(noop);
        modalInstance.mode = GroupActionMode.Legacy;
        modalInstance[isGroupAction ? 'legacyFilters' : 'computer'] = event.data[isGroupAction ? 'filters' : 'computer'];
        break;
      case InteractionEvent.CONFIRM_MODAL_UB_ACCESS_DENIED:
        this.modal
          .confirm(
            { header: { title: 'MSP360 Storage Subscription' }, footer: { cancelButton: { show: false } } },
            'This operation can only be performed by the account owner'
          )
          .catch(noop);
        break;
      case InteractionEvent.UPDATE_STORAGE_LIMIT:
        this.minimumStorageDuration(event.data.storageAccountId, event.data.storageType);
        break;
      case InteractionEvent.UB_RESTORE_REMOVED:
        this.storageAccounts.getUnifiedBillings().subscribe(({ items }) => {
          const ubCancelDeletionRef = this.modal.openRef(UbCancelDeletionComponent);
          const ubCancel = ubCancelDeletionRef.componentInstance as UbCancelDeletionComponent;

          ubCancel.storageAccount = items.find((s) => s.id === event.data.storageAccountId);

          ubCancelDeletionRef.result
            .then(() => {
              this.reloadIframe();
            })
            .catch(noop);
        });
        break;
      case InteractionEvent.SHOW_OVERDUE_MODAL:
        this.showOverdueModal(event.data);
        break;
      case InteractionEvent.UB_REFRESH_STATE:
        this.storageAccounts.getUnifiedBillings();
        break;
      case InteractionEvent.TOAST_SHOW:
        // eslint-disable-next-line no-case-declarations
        const payload = event.data;
        this.toastService.toast(new Toast({ content: payload.content, type: payload.type }));
        break;
      case InteractionEvent.REDIRECT_TO_COMPUTERS_WITHOUT_PARAMS:
        this.location.replaceState(this.location.path().split(/[?#]/)[0]);
        break;
      case InteractionEvent.UPDATE_QUERY_PARAMS:
        // eslint-disable-next-line no-case-declarations
        const params = URLParse.qs.parse(event.data.search);
        delete params['IFRAME'];
        this.router.navigate([], {
          queryParams: params,
          relativeTo: this.route,
          state: { skipUpdateIframeSrc: true }
        });
        break;
      case InteractionEvent.UB_AWS_SHOW_CREDITS_STATE:
        if (event.data.storageType === StorageType.CblWasabi || event.data.storageType === StorageType.UbAmazonS3) {
          this.ubSubscribeService
            .getUbDebt(event.data.storageType)
            .pipe(untilDestroyed(this))
            .subscribe((result: debtData) => {
              if (result && result.debtTotal) {
                this.openDebtModal(event.data.storageType, result);
              } else this.ubAWSShowCreditsStateHandler(event);
            });
        } else this.ubAWSShowCreditsStateHandler(event);
        break;
      case InteractionEvent.TWO_FACTOR_AUTH_TURN_ON_MODAL_OPEN:
        this.showTwoAuthModal();
        break;
      case InteractionEvent.TWO_FACTOR_AUTH_MODAL_OPEN:
        this.showConfirmActionModal(event.data);
        break;
      case InteractionEvent.ALTERNATIVE_CODES_RESET:
        this.resetCodes();
        break;
      case InteractionEvent.DEVICES_PANEL_OPEN:
        this.showDevicesSidepanel();
        break;
      case InteractionEvent.CHANGE_EMAIL_MODAL_OPEN:
        this.showChangeEmailModal();
        break;
      case InteractionEvent.ERROR_MODAL_OPEN:
        this.errorService.showToastWithDetails({ error: event.data.details, toastText: 'Something wrong, please...' });
        break;
      case InteractionEvent.OPEN_RD_CONNECT_MODAL:
        if (hid) {
          const connectionType = event.data.connectionType;
          this.computersFacade
            .getByHid(hid)
            .pipe(
              filter(Boolean),
              take(1),
              switchMap((comp) => this.connectService.connectAction(comp, connectionType))
            )
            .subscribe();
          this.computersFacade.loadComputerByHid({ hid });
        } else {
          this.toastService.error(this.i18nextService.t('error:emptyPage:hidIsMissingForModal'));
        }
        break;
      case InteractionEvent.REDIRECT_TO_APPS_AS_PROVIDER: {
        const { domain, redirectDomain } = event.data.provider;

        from(this.confirmRedirectToAppsAsProvider(domain))
          .pipe(
            switchMap((confirmed: boolean) => (confirmed ? this.getTokenForRedirectToAppsAsProvider(domain) : of(false))),
            catchError((err: HttpErrorResponse) => throwError(() => err)),
            untilDestroyed(this)
          )
          .subscribe({
            next: (token: TokenRedirect) => {
              if (token && token.access_token) {
                const url = `${redirectDomain}/office/Account/ProviderSignIn?token=${token.access_token}`;
                window.open(url, '_blank');
              }
            },
            error: (err: HttpErrorResponse) => {
              if (err instanceof HttpErrorResponse) {
                this.toastService.error(get(err, 'error.error')?.message, 'Confirm Transition to Dashboard');
              }
            }
          });
        break;
      }
      case InteractionEvent.UB_GET_DEBT: {
        this.openPaymentDebtModal();
        break;
      }
      case InteractionEvent.INSTALL_DEEPINSTINCT: {
        this.handleDeepInstinctInstall(event.data.computerHid);
        break;
      }
      default:
        break;
    }

    this.cd.markForCheck();
  }

  private openModalByParams(modalComponent, hid: string, plan?: PlanInfo) {
    if (hid) {
      this.computersFacade
        .getByHid(hid)
        .pipe(filter(Boolean), take(1))
        .subscribe((computer) => {
          const openRefModal = this.modal.openRef(modalComponent);
          openRefModal.componentInstance.computer = computer;
          if (plan) openRefModal.componentInstance.plan = plan;
          openRefModal.result.finally(noop);
        });
      this.computersFacade.loadComputerByHid({ hid });
    } else {
      this.toastService.error(this.i18nextService.t('error:emptyPage:hidIsMissingForModal'));
    }
  }

  minimumStorageDuration(storageAccountId: string, storageType: StorageType): void {
    const modalSettings = new ModalSettings();
    modalSettings.data = {
      storageAccountId,
      storageType: storageType && typeof storageType === 'string' ? StorageType[storageType] : storageType
    };
    this.modal.openCustom(MinimumStorageDurationComponent, modalSettings).finally(noop);
  }

  showOverdueModal(data): void {
    const modalSettings = new ModalSettings();
    modalSettings.data = data.data;
    modalSettings.size = MbsSize.md;
    modalSettings.responsive = true;
    this.modal
      .openCustom(OverdueModalComponent, modalSettings)
      .then((success) => {
        if (success) {
          this.emptyPageService.handleSendMessageToChild({ msg: 'successOverdueModal', success: true });
        }
      })
      .catch(noop);
  }

  private confirmRedirectToAppsAsProvider(domain: string): Promise<boolean> {
    const settings: ModalSettings = {
      size: MbsSize.md,
      responsive: true,
      data: { domain }
    };

    return this.modal.openCustom(ConfirmRedirectToAppsAsProviderComponent, settings);
  }

  private getTokenForRedirectToAppsAsProvider(domain: string): Observable<TokenRedirect> {
    const endpoint = 'api/auth/new-token-scope/appsbackup-access';
    return this.http.post<TokenRedirect>(endpoint, { domain }).pipe(catchError((err: HttpErrorResponse) => throwError(() => err)));
  }

  openDebtModal(storageType: StorageType, debt: debtData): void {
    const settings: ModalSettings = {
      header: { title: 'Add New Cloud Storage Account' },
      footer: { okButton: { text: 'Pay', type: 'success' }, cancelButton: { text: 'Cancel' } }
    };
    const name = storageType === StorageType.CblWasabi ? 'Wasabi' : 'Amazon S3';
    const needCredit = debt.creditsTotal && debt.creditsLeft < debt.creditsTotal;
    let message = `
      <span class="d-block mb-3">
      The MSP360 (${name}) storage account was used previously and has unpaid bills worth <strong>$${debt.debtTotal}</strong>
      </span>
      <span class="d-block ${needCredit ? 'mb-3' : 'mb-0'}">
      To add the MSP360 (${name}) again, you must pay the debt
      </span>
    `;
    if (debt.creditsTotal && debt.creditsLeft < debt.creditsTotal) {
      message += `<span class="d-block">The debt will be covered with credits $${debt.creditsTotal}, and the rest of the debt $${debt.debtLeft} will be withdrawn from your account</span>`;
    }
    this.modal
      .open(settings, message)
      .then((pay) => {
        if (pay) {
          this.ubSubscribeService
            .getPayUbDebt(storageType)
            .pipe(untilDestroyed(this))
            .subscribe((payResult) => {
              if (payResult && payResult.paymentLink) {
                window.location.href = payResult.paymentLink;
              }
            });
        } else this.emptyPageService.handleSendMessageToChild({ msg: 'forceNextStorageAccountWizardStep', close: true });
      })
      .catch(() => this.emptyPageService.handleSendMessageToChild({ msg: 'forceNextStorageAccountWizardStep', close: true }));
  }

  ubAWSShowCreditsStateHandler(event): void {
    this.ubSubscribeService
      .getCurrentCredits(event.data.storageType)
      .pipe(untilDestroyed(this))
      .subscribe((credits) => {
        if (!credits) {
          this.emptyPageService.handleSendMessageToChild({ msg: 'forceNextStorageAccountWizardStep' });
          return;
        }
        if (!+credits.licenseCount) {
          this.ubSubscribeService
            .spendCredits(event.data.storageType)
            .pipe(untilDestroyed(this))
            .subscribe((secondCredits) => {
              if (!secondCredits || secondCredits.paymentLink == null) {
                this.emptyPageService.handleSendMessageToChild({ msg: 'forceNextStorageAccountWizardStep' });
              } else {
                window.location.href = secondCredits.paymentLink;
              }
            });
          return;
        }
        const modalRef = this.modal.openRef(UbAwsChangingPriceModalComponent);
        modalRef.result.finally(noop);
        const modal = modalRef.componentInstance as UbAwsChangingPriceModalComponent;

        modal.credits = credits;
        modal.storageType = event.data.storageType;
      });
  }

  showModalIfBackupHistory(data): void {
    if (this.router.url.includes('AP/BackupHistory') && data.hid) {
      this.computersFacade.getByHid(data.hid, true).subscribe((computer) => {
        if (computer && (!computer.apps || !computer.apps.some((i) => i.applicationId === 'backup' && i.applicationState === 'Enabled'))) {
          this.modal
            .open({ header: { title: 'Warning' }, footer: { cancelButton: { show: false } } }, 'The Backup Agent is not installed.')
            .finally(noop);
        }
      });
    }
  }

  disableImmutabilityModal(params): void {
    const modalSettings = new ModalSettings();
    this.modal
      .openCustom(ImmutabilityDeleteModalComponent, modalSettings)
      .then((result) => {
        this.iframeWindow.postMessage({ msg: 'ConfirmDisableImmutability', payload: result }, '*');
      })
      .catch((e) => {
        this.iframeWindow.postMessage({ msg: 'ConfirmDisableImmutability', payload: false }, '*');
      });
  }

  openImmutabilityModal(params): void {
    const modalSettings = new ModalSettings();
    modalSettings.size = MbsSize.md;
    modalSettings.responsive = true;
    this.modal
      .openCustom(ImmutabilityConfirmModalComponent, modalSettings)
      .then((result) => {
        this.iframeWindow.postMessage({ msg: 'ConfirmImmutability', payload: result }, '*');
      })
      .catch((e) => {
        this.iframeWindow.postMessage({ msg: 'ConfirmImmutability', payload: false }, '*');
      });
  }

  openWizard(params): void {
    const settings = new ModalSettings();
    settings.size = MbsSize.lg;
    let settingsForWizard: WizardSettings = null;
    if (+PlansWizardMode[params.forPage] !== PlansWizardMode.forRD) {
      const mode = +PlanMode[params.mode];
      settingsForWizard = {
        hid: params.hid,
        mode: mode,
        compName: params.name,
        email: params.email,
        planId: mode === PlanMode.create ? '' : params.id,
        type: +PlanTypes[params.type],
        userId: params.userId,
        wizardMode: +PlansWizardMode[params.forPage] || PlansWizardMode.forRMM,
        backupVersion: params.backupVersion || null,
        isLinux: booleanFromStringFormat(params.isLinux),
        isNBF: booleanFromStringFormat(params.isArchive),
        wasNotExecuted: params.lastStart === 'Not+Run' || params.lastStart === 'Not Run' || !params.lastStart
      };
      const component = getWizardComponentType(settingsForWizard.type);
      this.wizardService.setWizard(component, settings, settingsForWizard);
    }
  }

  openWizardWithShortDataModel(params): void {
    const settings = new ModalSettings();
    settings.size = MbsSize.lg;
    let settingsForWizard: WizardSettings = null;
    if (+PlansWizardMode[params.forPage] !== PlansWizardMode.forRD) {
      settingsForWizard = {
        fromGettingStarted: true,
        hid: params.hid,
        mode: typeof params.mode === 'number' ? params.mode : +PlanMode[params.mode],
        compName: params.name,
        backupVersion: params.backupVersion,
        email: this.currentUser.Email,
        type: typeof params.type === 'number' ? params.type : +PlanTypes[params.type],
        userId: params.userId ? params.userId : this.currentUser.Id,
        wizardMode: +PlansWizardMode[params.forPage] || PlansWizardMode.forRMM,
        isLinux: booleanFromStringFormat(params.isLinux),
        supressShowingBackupFormatDialog: booleanFromStringFormat(params.supressShowingBackupFormatDialog),
        isNBF: booleanFromStringFormat(params.isArchive)
      };
      const component = getWizardComponentType(settingsForWizard.type);
      this.wizardService.setWizardAndGetRequiredData(component, settings, settingsForWizard).afterClosed.subscribe((v) => {
        this.emptyPageService.handleSendMessageToChild({
          msg: InteractionEvent.PLANS_WIZARD_CLOSED,
          data: JSON.stringify(v)
        } as IframeMessage);
      });
    }
  }

  grantLicense(data: FilterLicenseAndAdminId): void {
    if (this.isGrantLicense) return;

    this.isGrantLicense = true;

    if (this.router.url.includes('AP/Computers.aspx')) {
      this.administratorService.licensesHasBeenAssigned$.next(null);
      this.administratorService
        .openGrantLicenseDialogueWithInfoModal$(data)
        .pipe(
          tap(() => (this.isGrantLicense = false)),
          untilDestroyed(this)
        )
        .subscribe({
          next: (assigned) => {
            if (assigned && assigned.adminId && assigned.license) window.location.reload();
          },
          error: () => window && window.sessionStorage && window.sessionStorage.removeItem('btnClientId')
        });
    }
  }

  showTwoAuthModal(): void {
    this.isTurnOnTFA = true;
    this.modal
      .openCustom(TwoFactorEnableModalComponent, {
        data: {
          title: this.i18nextService.t('tfa:enableTwoFactorAuth', { format: 'title' })
        },
        size: MbsSize.md
      })
      // eslint-disable-next-line sonarjs/no-identical-functions
      .then((success) => {
        this.iframeWindow.postMessage({ msg: TwoFactorMessages.TurnTwoFactorAuthCheckBox, payload: { value: true } }, '*');
        this.reloadIframe();
      })
      .catch(() => {
        this.iframeWindow.postMessage({ msg: TwoFactorMessages.TurnTwoFactorAuthCheckBox, payload: { value: false } }, '*');
        this.TFAService.resetAuthId().subscribe();
      });
  }

  showChangeEmailModal() {
    this.modal
      .openCustom(ChangeEmailModalComponent, {
        data: {
          title: 'Change Provider Email Address'
        },
        size: MbsSize.sm
      })
      .finally(noop);
  }

  showConfirmActionModal(data): void {
    this.TFAService.openApproveModal(data)
      .then(() => {
        switch (data.actionType) {
          case TwoFactorAuthActionType.ChangePassword:
            this.iframeWindow.postMessage({ msg: TwoFactorMessages.InvokeSavePasswordButtonClick, payload: null }, '*');
            break;
          case TwoFactorAuthActionType.TurnOffTwoFactorAuth:
            this.iframeWindow.postMessage({ msg: TwoFactorMessages.TwoFactorAuthCheckBoxOnClickInvoke, payload: { value: false } }, '*');
            this.TFAService.updateTFAState().subscribe();
            break;
          case TwoFactorAuthActionType.DropUser:
            this.iframeWindow.postMessage({ msg: TwoFactorMessages.InvokeDropUserButtonClick, payload: null }, '*');
            break;
          case TwoFactorAuthActionType.DropCloudAccount:
            this.iframeWindow.postMessage({ msg: TwoFactorMessages.InvokeDropAccountButtonClick, payload: null }, '*');
            break;
          default:
            break;
        }
      })
      .catch(() => {
        if (data.actionType === TwoFactorAuthActionType.TurnOffTwoFactorAuth) {
          this.iframeWindow.postMessage({ msg: TwoFactorMessages.TurnTwoFactorAuthCheckBox, payload: { value: true } }, '*');
        }
      });
  }

  showDevicesSidepanel() {
    this.sidepanelService.add(SidepanelDevicesComponent);
    this.sidepanelService.openByType(SidepanelDevicesComponent).pipe(untilDestroyed(this)).subscribe();
  }

  handleClickHref(data: { url: string; target?: string }): void {
    if (!data.url || data.url.startsWith('javascript:') || data.target == '_blank') {
      return;
    }

    const url: URLParse<{ id: string }> = new URLParse<{ id: string }>(data.url);
    if (!url.protocol.startsWith('http')) {
      return;
    }

    const iframeUrl = new URLParse<{ id: string }>(this.iframeSrc);
    // update only query parameters
    if (url.origin === iframeUrl.origin && url.pathname === iframeUrl.pathname) {
      if (url.query !== iframeUrl.query) {
        const q = URLParse.qs.parse(url.query as any);
        delete q['IFRAME'];

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: q
        });

        this.iframeSrc = this.appendIframeParameter(url.href);
        setTimeout(() => {
          this.iframeWindow.location.href = this.iframeSrc;
        });
      }

      return;
    }

    const prefixAdmin = convertToClientUrl('/Admin/', this.appBaseHref).toLowerCase();
    const prefixAP = convertToClientUrl('/AP/', this.appBaseHref).toLowerCase();
    // redirect to other SPA page
    if (url.pathname.toLowerCase().startsWith(prefixAdmin)) {
      const findRouteUrl = url.pathname.substring(prefixAdmin.length);
      let navigateUrl = url.pathname.replace(new RegExp(prefixAdmin, 'i'), '/AP/') + url.query.toString();
      switch (findRouteUrl) {
        case 'Company.aspx':
        case 'Companies.aspx':
          navigateUrl = RoutingPath.ApCompanies + url.query['id'];
          break;
        case 'AnotherPage':
          // eslint
          break;
        case 'Login.aspx':
          this.dom.location.href = data.url;
          break;
        default:
          break;
      }

      this.router.navigateByUrl(navigateUrl.replace('IFRAME=true', ''));
      // redirect to other SPA page from pages /Admin/ by AP link
    } else if (url.pathname.toLowerCase().startsWith(prefixAP)) {
      const navigateUrl = url.pathname.replace(new RegExp(prefixAP, 'i'), '/AP/') + url.query.toString();
      this.router.navigateByUrl(navigateUrl.replace('IFRAME=true', ''));
    }
  }

  fetchCurrentUser(): void {
    this.auth.fetchCurrentUser().pipe(first()).subscribe();
  }

  refreshAuth(): void {
    this.auth.tryRefreshToken$.pipe(first()).subscribe((result) => {
      if (result) {
        this.emptyPageService.handleSendMessageToChild({ msg: 'InitRMMSummaryInfo' });
      }
    });
  }

  /*
   * Generic redirect
   */
  handleRedirect(data: { msg?: string; redirectUrl: string; sameHost?: boolean; statusCode?: number; pushState?: boolean }): void {
    if (data.sameHost) {
      const url = new URL(data.redirectUrl, window.location.origin);
      if (url.search) {
        if (data.pushState) {
          this.dom.location.assign(data.redirectUrl);
        } else {
          this.dom.location.replace(data.redirectUrl);
        }
      } else {
        const localUrl = removeDoubleSlash(data.redirectUrl.replace(this.appBaseHref, '/'));
        this.router.navigate([localUrl]);
      }
    } else if (data.pushState) {
      this.dom.location.assign(data.redirectUrl);
    } else {
      this.dom.location.replace(data.redirectUrl);
    }
  }

  handleLoadIframe(): void {
    this.loading = false;
    this.handleResizeIframe();

    this.iframeContentLoaded.emit(true);
  }

  handleResizeIframe(): void {
    this.scrollHeight = this.getContentFreeHeight();
  }

  getContentFreeHeight(): number {
    if (!this.layoutElms.header) {
      return window.innerHeight;
    }

    const fillHeight = this.layoutElms.header.clientHeight;
    return window.innerHeight - fillHeight;
  }

  getFooterVisibleHeight(): number {
    const rect = this.layoutElms.footer.getBoundingClientRect();
    const elemTop = rect.top;

    return Math.max(window.innerHeight - elemTop, 0);
  }

  handleUbSwitchMode(storageId: string): void {
    forkJoin([
      this.auth.currentUser.pipe(
        map((user) => user && user.ProviderInfo.PaymentSystem === PaymentSystem.FastSpring),
        first()
      ),
      this.storageAccounts.getByKey(storageId).pipe(
        catchError((e) => of(null)),
        map((data) => (data || {}) as StorageAccount)
      )
    ]).subscribe(([isFastSpring, storageAccount]) => {
      if (isFastSpring) {
        let subscriptionModalType: Type<SubscribeUbComponent>;

        const storageAccountCamel = storageAccount as unknown as StorageAccountCamelCase;
        switch (storageAccountCamel.storageType) {
          case StorageType.CblWasabi:
            subscriptionModalType = SubscribeUbWasabiComponent;
            break;
          case StorageType.UbAmazonS3:
            subscriptionModalType = SubscribeUbAwsComponent;
            break;

          default:
            console.error(`Undefined storage type ${StorageType[storageAccount.StorageType]}`);
            return;
        }
        const switchUbModal = this.modal.openRef(subscriptionModalType, { size: MbsSize.lg });
        switchUbModal.result
          .then(({ signed }) => {
            if (signed) {
              this.iframeWindow.location.reload();
            }
          })
          .catch(noop);
        (switchUbModal.componentInstance as SubscribeUbComponent).selectedStorageId = storageId;
      } else {
        this.modal
          .open(
            { header: { title: 'Warning' }, footer: { cancelButton: { show: false } } },
            `Commercial mode can't be activated. Please <a href="mailto:${this.mailto}"> contact us.</a>`
          )
          .finally(noop);
      }
    });
  }

  private openPaymentDebtModal() {
    this.modal
      .openCustom(PaymentDebtModalComponent, {
        data: {
          title: this.i18nextService.t('app:paymentDebtModal:title', {
            format: 'title'
          }),
          userId: this.currentUser.Id
        },
        size: MbsSize.sm
      })
      .finally(noop);
  }

  private handleDeepInstinctInstall(hid) {
    combineLatest({
      computer: this.computersFacade.getByHid(hid, true),
      installedApps: this.computersFacade.applicationsCountAfterInit$
    })
      .pipe(
        take(1),
        switchMap(({ computer, installedApps }) =>
          installedApps.deepInst
            ? this.computersFacade.installAgent(computer, AgentType.DeepInst).pipe(map((result) => ({ installed: true, result, computer })))
            : of({ installed: false, result: {}, computer })
        ),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (!data.installed) {
          this.openDeepInstinctModal(data.computer);
        } else if (data.result.isErrorsInResultList) {
          handleRMMError(data.result.resultList, this.toastService);
        }
      });
    this.computersFacade.loadApplicationsCount();
  }

  private openDeepInstinctModal(computer: Computer) {
    this.modal
      .openCustom(DeepInstinctModalComponent, {
        data: {
          title: this.i18nextService.t('app:deepInstinctModal:title', {
            format: 'title'
          }),
          computer,
          userId: this.currentUser?.Id
        },
        size: MbsSize.lg
      })
      .finally(noop);
  }

  private resetCodes() {
    this.TFAService.handleTFA(this.TFAService.resetCodes()).subscribe((codes) => {
      this.openRecoveryCodeModal(codes);
    });
  }

  private openRecoveryCodeModal(codes: Array<string>) {
    this.modal
      .openCustom(RecoveryCodeModalComponent, {
        data: {
          title: 'Reset 2FA Recovery Codes',
          codes
        },
        size: MbsSize.sm
      })
      .then(() => {
        this.reloadIframe();
      })
      .catch(() => this.reloadIframe());
  }
}
