import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupTask, GroupTaskOsTypes } from '@modules/group-tasks/store/model';
import { GAActions, GASelectors } from '@modules/schedule-group-action/store/group-action';
import {
  DEFAULT_RMM_GROUP_TASK_TIMEOUT,
  ScriptModeSelectTypes,
  TimeMeasureValues
} from '@modules/schedule-group-action/store/group-action/group-action.model';
import EditGroupTaskUtility from '@modules/schedule-group-action/utility/edit-group-task-utility';
import * as ScriptLibraryActions from '@modules/script-library/store/script-library.actions';
import { ScriptLibraryEntry, ScriptLibraryEntryTypes, ScriptLibraryTag } from '@modules/script-library/store/script-library.model';
import * as ScriptLibrarySelectors from '@modules/script-library/store/script-library.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { I18NextPipe } from 'angular-i18next';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilKeyChanged, filter, startWith, switchMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-action-script-library',
  templateUrl: './script-library.component.html',
  styleUrls: ['./script-library.component.scss']
})
export class ActionScriptLibraryComponent implements OnInit {
  @Input() enableTitle = true;
  @Input() modeSelection: ScriptModeSelectTypes;

  private osType: GroupTaskOsTypes;
  public form: UntypedFormGroup;

  public timeMeasureList = [
    {
      id: TimeMeasureValues.minutes,
      label: this.i18n.transform('app:timeUnits.minutes', { format: 'title' })
    },
    {
      id: TimeMeasureValues.hours,
      label: this.i18n.transform('app:timeUnits.hours', { format: 'title' })
    }
  ];

  collections: ScriptLibraryTag[];
  entries: ScriptLibraryEntry[];
  filteredEntries: ScriptLibraryEntry[];

  entry$: Observable<ScriptLibraryEntry>;
  entry: ScriptLibraryEntry;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private editGroupTaskUtility: EditGroupTaskUtility,
    private i18n: I18NextPipe
  ) {
    this.form = this.fb.group({
      collection: [null],
      entry: [null, Validators.required],
      scriptTimeoutTime: new FormControl(5),
      scriptTimeMeasure: new FormControl(this.timeMeasureList[0])
    });

    this.store
      .select(GASelectors.selectGActionOsType)
      .pipe(untilDestroyed(this))
      .subscribe((osType) => (this.osType = osType));

    this.entry$ = this.form.get('entry').valueChanges.pipe(distinctUntilKeyChanged('scriptGuid'));

    this.entry$.pipe(untilDestroyed(this)).subscribe((entry: ScriptLibraryEntry) => {
      if (entry && !entry.body) {
        this.store.dispatch(ScriptLibraryActions.loadScriptBody({ scriptGuid: entry.scriptGuid }));
      }
    });

    this.form
      .get('scriptTimeMeasure')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value.id === TimeMeasureValues.hours && this.form.get('scriptTimeoutTime').value > 23)
          this.form.get('scriptTimeoutTime').setValue(23);
        if (value.id === TimeMeasureValues.minutes && this.form.get('scriptTimeoutTime').value < 5)
          this.form.get('scriptTimeoutTime').setValue(5);
      });

    combineLatest([
      this.entry$.pipe(
        filter(Boolean),
        switchMap((entry) => this.store.select(ScriptLibrarySelectors.selectEntry(entry.id)))
      ),
      this.form.get('scriptTimeoutTime').valueChanges.pipe(startWith(this.form.get('scriptTimeoutTime').value)),
      this.form.get('scriptTimeMeasure').valueChanges.pipe(startWith(this.form.get('scriptTimeMeasure').value))
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([entry, scriptTimeoutTime, scriptTimeMeasure]) => {
        if (!entry) return;

        this.entry = entry;

        this.store.dispatch(
          GAActions.setGActionParameters({
            parameters: this.getGroupActionParams([GroupTaskOsTypes.Mac, GroupTaskOsTypes.Linux].includes(this.osType))
          })
        );
      });

    this.form
      .get('collection')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.filteredEntries = this.getEntriesPerCollection(this.entries, value);
        if (this.filteredEntries.length) this.form.get('entry').setValue(this.filteredEntries[0]);
      });
  }

  getFilteredEntriesPerOsType(entries: ScriptLibraryEntry[]): ScriptLibraryEntry[] {
    if (this.osType === GroupTaskOsTypes.Windows)
      return entries.filter((entry) => entry.type === ScriptLibraryEntryTypes.PowerShell || entry.type === 'PowerShell');

    if ([GroupTaskOsTypes.Linux, GroupTaskOsTypes.Mac].includes(this.osType))
      return entries.filter((entry) => entry.type === ScriptLibraryEntryTypes.Bash || entry.type === 'Bash');

    return [];
  }

  ngOnInit(): void {
    this.loadGroupTaskData();
  }

  loadGroupTaskData() {
    const entries$ = this.store.select(ScriptLibrarySelectors.selectAll).pipe(
      filter((entries) => {
        // evade dispatch if entries loaded
        if (!entries.length) this.store.dispatch(ScriptLibraryActions.loadScriptsInLibrary());
        return !!entries.length;
      })
    );
    const groupActions$ = this.store.select(GASelectors.selectGActionParameters);

    combineLatest([entries$, groupActions$, this.editGroupTaskUtility.getScriptGuidStream()])
      .pipe(untilDestroyed(this))
      .subscribe(([entries, parameters, scriptGuid]) => {
        this.entries = [...this.getFilteredEntriesPerOsType(entries)];
        this.collections = [];
        this.entries.forEach((entry) =>
          entry.tags.forEach((tag) => {
            if (this.collections.find((tagEntity) => tagEntity.name === tag.name && tagEntity.accessLevel === tag.accessLevel)) return;
            this.collections.push(tag);
          })
        );
        this.collections.sort((a, b) => (a.name > b.name ? 1 : -1));
        const formCollectionValue = this.form.get('collection');

        if (!formCollectionValue.value || !this.collections.includes(formCollectionValue.value)) {
          this.setFormValues(parameters, this.entries, scriptGuid);
        } else {
          this.filteredEntries = this.getEntriesPerCollection(this.entries, this.form.get('collection').value);
        }
      });

    // load execution time separately
    this.editGroupTaskUtility
      .getTerminalTimeoutStream()
      .pipe(
        filter((data) => !!data),
        take(1)
      )
      .subscribe((data) => {
        this.form.get('scriptTimeoutTime').setValue(data.scriptTime, { emitEvent: false });
        this.form.get('scriptTimeMeasure').setValue(
          this.timeMeasureList.find((time) => time.id === data.scriptTimeMeasure),
          { emitEvent: false }
        );
      });
  }

  private setFormValues(parameters: Partial<GroupTask>, entries: ScriptLibraryEntry[], previousScriptGuid: string): void {
    const formCollection = this.form.get('collection');
    const defaultCollectionName = this.collections.find((collection) => collection.name === 'General');

    const selectedGroupActionGuid = parameters?.scriptGuid;
    if (previousScriptGuid || selectedGroupActionGuid) {
      const selectedEntry = this.getSelectedEntry(entries, previousScriptGuid ?? selectedGroupActionGuid ?? null);
      const selectedCollection = this.getSelectedCollection(this.collections, selectedEntry);

      const requiredCollection =
        this.collections.find(
          (collection) => collection?.name === selectedCollection?.name && collection?.accessLevel === selectedCollection?.accessLevel
        ) ?? null;

      formCollection.setValue(requiredCollection ?? defaultCollectionName);

      this.filteredEntries = this.getEntriesPerCollection(this.entries, selectedCollection);

      const formEntry = this.form.get('entry');
      const formNewValue = this.filteredEntries.find((entry) => entry.id === selectedEntry.id);
      if (formNewValue) formEntry.setValue(this.filteredEntries.find((entry) => entry.id === selectedEntry.id));
    } else if (defaultCollectionName) {
      formCollection.setValue(defaultCollectionName);
    } else {
      formCollection.setValue(this.collections[0]);
    }
  }

  private getSelectedEntry(entries: ScriptLibraryEntry[], scriptGuid?: string): ScriptLibraryEntry {
    return entries.find((entry) => entry.scriptGuid === scriptGuid);
  }

  private getSelectedCollection(collections: ScriptLibraryTag[], entry: ScriptLibraryEntry): ScriptLibraryTag {
    let scriptLibraryTag: ScriptLibraryTag = null;

    collections.forEach((collectionTag) => {
      entry?.tags.forEach((entryTag) => {
        if (entryTag.name === collectionTag.name && entryTag.accessLevel === collectionTag.accessLevel) {
          scriptLibraryTag = collectionTag;
        }
      });
    });
    return scriptLibraryTag;
  }

  private getEntriesPerCollection(entries: ScriptLibraryEntry[], collection: ScriptLibraryTag): ScriptLibraryEntry[] {
    if (!entries.length || !collection) return [];
    const filteredEntries = [];

    entries.forEach((entry) => {
      if (entry.tags.find((entryTag) => entryTag.name === collection.name && entryTag.accessLevel === collection.accessLevel))
        filteredEntries.push(entry);
    });
    return filteredEntries;
  }

  private getTimeoutValue(scriptTimeoutTime, scriptTimeMeasure): number {
    const multiplierForMilliseconds = scriptTimeMeasure.id === TimeMeasureValues.minutes ? 1 : 60;
    return multiplierForMilliseconds * scriptTimeoutTime;
  }

  getGroupActionParams(isBash = false): Partial<GroupTask> {
    const data = { ...this.form.value, entry: this.entry };
    const time =
      data?.scriptTimeoutTime && data?.scriptTimeMeasure
        ? `${this.getTimeoutValue(data.scriptTimeoutTime, data.scriptTimeMeasure)}`
        : DEFAULT_RMM_GROUP_TASK_TIMEOUT;

    const parameters = [
      { name: 'SCRIPT', value: '%SCRIPT_BODY%' },
      {
        name: 'TIMEOUT',
        value: time
      }
    ];

    return {
      disabled: false,
      type: 'pluginCommand',
      pluginCommandId: isBash ? 'BashCmd' : 'PowerShellTerminalCmd',
      parameters: JSON.stringify({ asyncID: getGuid(), id: 'Script', parameters: parameters }),
      confidentialOutputData: false,
      pluginActiveCommand: true,
      pluginPsCommand: true,
      description: this.getDescription(data, time, isBash),
      enableOffline: false,
      eventType: 0,
      scriptGuid: data.entry.scriptGuid,
      softwareSourceGuid: null,
      allComputers: false,
      computers: [],
      computerTags: [],
      runType: 0,
      scheduleType: 0,
      scheduleData: null
    };
  }

  getDescription(data: any, time: string, isBash = false): string {
    const splitLine = '\r\n';
    const translationKey = 'rmm.module:groupActions.actionDescription.';
    const descriptionPart1 = this.editGroupTaskUtility.getTranslationByKeyAndValue(
      translationKey + (isBash ? 'scriptLibraryBashInfo' : 'scriptLibraryInfo'),
      {}
    );
    const descriptionPart2 = this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'scriptLibraryGroup', {
      value: data.entry?.tags?.map((tag) => tag.name)
    });
    const descriptionPart3 = this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'scriptLibraryScriptName', {
      value: data.entry.name
    });
    const descriptionPart4 = this.editGroupTaskUtility.getTranslationByKeyAndValue(translationKey + 'scriptLibraryDescription', {
      value: data.entry.description
    });
    const timeoutDesc = time ? `${splitLine}${this.editGroupTaskUtility.getTimeValuesForDescription(Number(time))}${splitLine}` : splitLine;

    return `${descriptionPart1}${timeoutDesc}${[descriptionPart2, descriptionPart3, descriptionPart4].join(splitLine)}`;
  }
}
