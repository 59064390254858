import { Component, Input } from '@angular/core';
import HddSmartInfo from '@models/rmm/HddSmartInfo';
import { InMemorySorter } from '@utils/inMemorySorter';
import { I18NextPipe } from 'angular-i18next';
import { SortEvent, TableHeader } from 'mbs-ui-kit';

@Component({
  selector: 'mbs-hdd-smart-tab',
  templateUrl: './hdd-smart-tab.component.html'
})
export class HddSmartTabComponent {
  public rotateSequence = {
    asc: 'desc',
    desc: 'asc',
    '': 'asc'
  };

  constructor(private i18n: I18NextPipe) {}

  public headers: TableHeader[] = [
    {
      name: this.i18n.transform('rmm-side-panel:hddTab.tableHeaders.id'),
      sort: 'register',
      gridColSize: '55px',
      class: '-end'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hddTab.tableHeaders.name'),
      overflow: true,
      sort: 'name',
      gridColSize: '55fr'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hddTab.tableHeaders.value'),
      sort: 'current',
      gridColSize: '25fr',
      class: '-end'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hddTab.tableHeaders.worst'),
      sort: 'worst',
      gridColSize: '25fr',
      class: '-end'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hddTab.tableHeaders.threshold'),
      sort: 'threshold',
      gridColSize: '30fr',
      class: '-end'
    },

    {
      name: this.i18n.transform('rmm-side-panel:hddTab.tableHeaders.rawValue'),
      overflow: true,
      sort: 'data',
      gridColSize: '40fr',
      class: '-end'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hddTab.tableHeaders.status'),
      overflow: true,
      sort: 'isOk',
      gridColSize: '55px'
    }
  ];

  public nameValueHeader = [
    {
      name: this.i18n.transform('rmm-side-panel:hddTab.tableHeaders.name'),
      overflow: true,
      sort: 'name',
      gridColSize: '30fr'
    },
    {
      name: this.i18n.transform('rmm-side-panel:hddTab.tableHeaders.value'),
      sort: 'current',
      gridColSize: '30fr'
    }
  ];

  @Input() public smartDisk: HddSmartInfo;
  public orderBy: SortEvent = { column: 'name', direction: 'asc' };
  public showMore = false;

  handleSort(orderBy: SortEvent): void {
    this.orderBy = orderBy;
    this.smartDisk.smartAttributes = Array.from(InMemorySorter.sort(this.orderBy, this.smartDisk.smartAttributes));
  }

  onClick() {
    this.showMore = !this.showMore;
  }
}
