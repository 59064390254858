import { Component, Inject, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import Company from '@models/Company';
import HostInfo from '@models/rmm/HostInfo';
import { ComputerFromRegGroupTask, GroupTaskOsTypes } from '@modules/group-tasks/store/model';
import { UtilitySelectionModalComponent } from '@modules/utility-selection-modal/utility-selection-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ComputerSmartSearchService } from '@services/smart-search-template.service';
import { ComputerTagsFacade } from '@root/mbs-ui/src/app/shared/facades/computer.tags.facade';
import { Tag } from '@shared/models/tag';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, MbsSize, ModalService, ModelTemplate, WizardStepComponent } from 'mbs-ui-kit';
import { BehaviorSubject, combineLatest, noop, Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { GAActions, GASelectors } from '../../store/group-action';
import { GAComputer } from '../../store/group-action/group-action.model';
import EditGroupTaskUtility from '../../utility/edit-group-task-utility';

export enum SelectOptions {
  ApplyToAllCompanies,
  ApplyToAllComputersInSelectedCompanies,
  ApplyToSelectedComputersOnly
}
@UntilDestroy()
@Component({
  selector: 'mbs-apply-to',
  templateUrl: './apply-to.component.html',
  styleUrls: ['./apply-to.component.scss'],
  host: {
    class: 'rmm-group-action'
  }
})
export class ApplyToComponent implements OnInit {
  @ViewChild('tagSelectionModal', { static: true, read: TemplateRef }) tagSelectionModal: TemplateRef<any>;

  public computers$: Observable<GAComputer[]>;
  private computers: GAComputer[];
  public readonly alertType = MbsPopupType;

  advancedTemplates: ModelTemplate<string | Company | HostInfo>[] = [];

  public selectedComputerList: GAComputer[] = [];
  public selectedExcludedComputersList: GAComputer[] = [];
  public selectedCompanyList: { id: string; name: string }[] = [];
  public excludedComputersEnabled = false;

  public selectOptions = SelectOptions;
  public modeSelectionForm: UntypedFormGroup = new UntypedFormGroup({
    modeSelect: new FormControl(this.selectOptions.ApplyToAllCompanies),
    enableExclude: new FormControl(false),
    enableTags: new FormControl(false),
    tags: new FormControl([])
  });

  public osType = new BehaviorSubject<GroupTaskOsTypes>(null);
  private groupTaskDataIsLoaded = false;

  public elementSelectors = {
    allCompanies: 'select-computers-all',
    companies: 'select-computers-companies',
    computers: 'select-computers-computers',
    exclude: 'select-computers-exclude',
    tags: 'select-enable-tags'
  };

  public selectTagEnabled = false;
  public selectedTagList: string[] = [];
  public tags: Tag[] = [];
  public selectedTagNames: Array<{ id: string; name: string, removed?: boolean }> = [];

  public tagHeaders = [
    {
      name: '',
      sort: 'name',
      gridColSize: '3fr',
      isGridColumn: false
    }
  ];

  constructor(
    protected injector: Injector,
    @Inject(WizardStepComponent) protected parent: WizardStepComponent,
    private smartSearch: ComputerSmartSearchService,
    private modalService: ModalService,
    private i18n: I18NextPipe,
    private store: Store,
    private editGroupTaskUtility: EditGroupTaskUtility,
    private tagsFacade: ComputerTagsFacade
  ) {
    this.advancedTemplates = [
      this.smartSearch.software,
      this.smartSearch.patch,
      this.smartSearch.computer,
      this.smartSearch.serverHashtag,
      this.smartSearch.company
    ];

    this.computers$ = this.store.select(GASelectors.selectGActionOsType).pipe(
      tap((osType) => this.osType.next(osType)),
      switchMap((osType) => this.editGroupTaskUtility.getComputerListForGActionStream(osType)),
      untilDestroyed(this)
    );

    this.tagsFacade
      .loadAllTags()
      .pipe(untilDestroyed(this))
      .subscribe((tags) => (this.tags = tags));

    this.modeSelectionForm
      .get('tags')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((tagIDs) => {
        const excludedTags = tagIDs
          .filter((id) => !this.tags.some((tag) => `${tag.id}` === id))
          .map((excludedID) => ({ id: excludedID, removed: true, name: this.i18n.transform('rmm-group-task.module:removedTagName', {value: excludedID}) }));

        this.selectedTagNames = this.tags
          .filter((tag) => tagIDs.includes(`${tag.id}`))
          .map((tag) => ({ id: `${tag.id}`, name: tag.name }))
          .concat(excludedTags);

        if (this.modeSelectionForm.get('enableTags').value) this.onComputerModeChange(this.modeSelectionForm.get('modeSelect').value);
      });
  }

  ngOnInit(): void {
    this.modeSelectionForm
      .get('modeSelect')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => this.onComputerModeChange(value));

    this.modeSelectionForm
      .get('enableExclude')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        this.excludedComputersEnabled = value;
        if (value && !this.selectedExcludedComputersList.length) this.openSelectComputersModal(true);
        this.onComputerModeChange(this.modeSelectionForm.get('modeSelect').value);
      });

    this.modeSelectionForm
      .get('enableTags')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        this.selectTagEnabled = value;
        if (value && !this.selectedTagList.length) this.openSelectTagsModal();

        this.onComputerModeChange(this.modeSelectionForm.get('modeSelect').value);
      });

    this.osType
      .pipe(
        distinctUntilChanged(),
        filter((value) => !!value),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        this.selectedComputerList = [];
        this.selectedExcludedComputersList = [];
        this.groupTaskDataIsLoaded = false;
      });

    this.loadGroupTaskData();
  }

  private loadGroupTaskData(): void {
    combineLatest([this.computers$, this.editGroupTaskUtility.getApplyToDataFromGroupTaskStream()])
      .pipe(
        filter(
          ([computers, selectedEntities]) =>
            !this.groupTaskDataIsLoaded && !!selectedEntities && (!!computers?.length || !selectedEntities.computers.length)
        ),
        untilDestroyed(this)
      )
      .subscribe(([computers, selectedEntities]) => {
        this.computers = computers;

        this.selectedComputerList = [];
        this.selectedExcludedComputersList = [];
        if (!selectedEntities) return;

        if (selectedEntities?.computerTags.length) {
          this.selectedTagList = selectedEntities.computerTags.map((tag) => tag.toString());
          this.modeSelectionForm.get('tags').setValue(selectedEntities.computerTags.map((tag) => tag.toString()));
          this.modeSelectionForm.get('enableTags').setValue(true);
        }

        computers.forEach((computer) => {
          const computerData = selectedEntities?.computers?.find((selected) => selected.hid === computer.hid);
          if (!computerData) return;
          computerData.disabled ? this.selectedExcludedComputersList.push(computer) : this.selectedComputerList.push(computer);
        });

        this.selectedCompanyList = selectedEntities?.companies?.map((company) => ({
          id: company.companyGuid,
          name: company?.name
        }));

        if (selectedEntities?.allCompanies || selectedEntities?.companies?.length) this.selectedComputerList = [];

        this.modeSelectionForm
          .get('modeSelect')
          .setValue(
            selectedEntities.allCompanies
              ? SelectOptions.ApplyToAllCompanies
              : selectedEntities?.companies?.length
              ? SelectOptions.ApplyToAllComputersInSelectedCompanies
              : SelectOptions.ApplyToSelectedComputersOnly
          );
        this.modeSelectionForm.get('enableExclude').setValue(!!this.selectedExcludedComputersList.length);
        this.groupTaskDataIsLoaded = true;
      });
    if (this.selectedExcludedComputersList.length) this.excludedComputersEnabled = true;
  }

  trackByFn(index: number, item: GAComputer): string {
    return item.hid;
  }

  public onComputerModeChange(formValue: number): void {
    switch (formValue) {
      case this.selectOptions.ApplyToAllComputersInSelectedCompanies:
        this.store.dispatch(
          GAActions.setGActionApplyTo({
            applyTo: {
              companies: this.selectedCompanyList.map((company) => ({ companyGuid: company.id, name: company.name, disabled: false })),
              computers: this.excludedComputersEnabled ? this.getPickedComputerList() : [],
              allCompanies: false,
              computerTags: this.selectTagEnabled ? this.selectedTagList.map((id) => Number(id)) : []
            }
          })
        );
        break;
      case this.selectOptions.ApplyToSelectedComputersOnly:
        this.store.dispatch(
          GAActions.setGActionApplyTo({
            applyTo: {
              companies: [],
              computers: this.excludedComputersEnabled
                ? this.getPickedComputerList()
                : this.selectedComputerList.map((computer: any) => ({ hid: computer.hid, name: computer.computerName, disabled: false })),
              allCompanies: false,
              computerTags: this.selectTagEnabled ? this.selectedTagList.map((id) => Number(id)) : []
            }
          })
        );
        break;
      case this.selectOptions.ApplyToAllCompanies:
      default:
        this.store.dispatch(
          GAActions.setGActionApplyTo({
            applyTo: {
              companies: [],
              computers: this.excludedComputersEnabled ? this.getPickedComputerList() : [],
              allCompanies: true,
              computerTags: this.selectTagEnabled ? this.selectedTagList.map((id) => Number(id)) : []
            }
          })
        );
        break;
    }
  }

  private getPickedComputerList(): ComputerFromRegGroupTask[] {
    const computersEntity = [];

    this.selectedComputerList.forEach((computer) => {
      if (this.selectedExcludedComputersList.find((excludedComputer) => excludedComputer.hid === computer.hid)) return;
      computersEntity.push({ hid: computer.hid, name: computer.computerName, disabled: false });
    });

    this.selectedExcludedComputersList.forEach((computer) =>
      computersEntity.push({ hid: computer.hid, name: computer.computerName, disabled: true })
    );

    return computersEntity;
  }

  openSelectComputersModal(excludeMode = false): void {
    if (excludeMode && !this.modeSelectionForm.get('enableExclude').value && this.tags) {
      this.modeSelectionForm.get('enableExclude').setValue(true);

      if (!this.selectedExcludedComputersList.length) return;
    }

    !excludeMode && this.modeSelectionForm.get('modeSelect').setValue(this.selectOptions.ApplyToSelectedComputersOnly);

    const groups = excludeMode ? this.selectedExcludedComputersList.map((x) => x.hid) : this.selectedComputerList.map((x) => x.hid);
    this.modalService
      .openCustom(UtilitySelectionModalComponent, {
        data: {
          model: groups,
          computers: this.computers,
          modalTitle: excludeMode
            ? this.i18n.transform('rmm.module:groupActions.stepApplyTo.selectionModal.selectExcludedComputersModalTitle')
            : this.i18n.transform('rmm.module:groupActions.stepApplyTo.selectionModal.selectComputersModalTitle'),
          applyTo: 'Computer List',
          noComputersText: this.i18n.transform('rmm.module:groupActions.stepApplyTo.selectionModal.selectComputersModalNoComputersDesc'),
          saveButtonText: excludeMode ? this.i18n.transform('buttons:confirm') : this.i18n.transform('buttons:apply'),
          isGroupActionUseCase: true,
          smartSearchTemplates: this.advancedTemplates,
          getComputersFromStore: this.computers$,
          osType: this.osType.getValue()
        },
        size: MbsSize.sm,
        collapsing: true
      })
      .then((selectedEntities: any) => {
        if (selectedEntities) {
          excludeMode
            ? (this.selectedExcludedComputersList = selectedEntities.flatMap((entity) => entity.selectedItems.map((computer) => computer)))
            : (this.selectedComputerList = selectedEntities.flatMap((entity) => entity.selectedItems.map((computer) => computer)));
        }
        this.onComputerModeChange(this.modeSelectionForm.get('modeSelect').value);
      })
      .catch(noop);
  }

  openSelectCompaniesModal(): void {
    this.modeSelectionForm.get('modeSelect').setValue(this.selectOptions.ApplyToAllComputersInSelectedCompanies);
    const groups = this.selectedCompanyList.map((companyId) => companyId.id);
    this.modalService
      .openCustom(UtilitySelectionModalComponent, {
        data: {
          model: groups,
          computers: this.computers,
          modalTitle: this.i18n.transform('rmm.module:groupActions.stepApplyTo.selectionModal.selectCompaniesModalTitle'),
          applyTo: 'Company List',
          noComputersText: this.i18n.transform('rmm.module:groupActions.stepApplyTo.selectionModal.selectCompaniesModalNoCompaniesDesc'),
          saveButtonText: this.i18n.transform('buttons:apply')
        },
        size: MbsSize.sm,
        collapsing: true
      })
      .then((selectedEntities: any) => {
        if (selectedEntities) {
          this.selectedCompanyList = selectedEntities.map((entity) => ({
            id: entity.id,
            name: entity.title
          }));
        }
        this.onComputerModeChange(this.modeSelectionForm.get('modeSelect').value);
      })
      .catch(noop);
  }

  openSelectTagsModal(): void {
    if (!this.modeSelectionForm.get('enableTags').value) {
      this.modeSelectionForm.get('enableTags').setValue(true);

      if (!this.selectedTagList.length) return;
    }

    this.modalService
      .open(
        {
          header: { title: this.i18n.transform('rmm.module:groupActions.stepApplyTo.selectTags', { format: 'title' }) },
          size: MbsSize.sm,
          footer: {
            show: true,
            cancelButton: { text: this.i18n.transform('buttons:cancel') },
            okButton: { text: this.i18n.transform('buttons:save') }
          },
          collapsing: true
        },
        this.tagSelectionModal
      )
      .then((confirmed) => {
        if (confirmed) {
          this.modeSelectionForm.get('tags').setValue([...this.selectedTagList]);
        } else {
          this.selectedTagList = [...this.modeSelectionForm.get('tags').value];
        }
      })
      .catch(noop);
  }

  changeTagsHandler(data: string[]): void {
    this.selectedTagList = data.map((data) => data);
  }

  handleClose(tag: { id: string; name: string }) {
    this.selectedTagList = this.selectedTagList.filter((id) => id !== tag.id);
    this.modeSelectionForm.get('tags').setValue([...this.selectedTagList]);
  }
}
