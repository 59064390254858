import { Component, forwardRef, Input, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { AzureDataValue, AzureStepValue } from '../../../models/azure-models';
import { RestoreAsDiskEnum } from '../../../models/type-data-models';
import { RemoteManagementWizardsService } from '../../../services/remote-management-wizards.service';
import { FormPipeOperators, StepBase } from '../../StepBase.class';

const AzureStepValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => AzureStepComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'mbs-azure-step',
  templateUrl: './azure-step.component.html',
  providers: [AzureStepValueAccessor]
})
export class AzureStepComponent extends StepBase<AzureStepValue> {
  @Input() azureData: AzureDataValue = {
    accounts: [],
    locations: [],
    resourceGroups: [],
    vmSizes: [],
    networks: [],
    subnetItems: [],
    storageItems: [],
    containers: [],
    BDSItems: []
  };
  @Input() restoreAs: RestoreAsDiskEnum;

  public restoreAsDiskEnum = RestoreAsDiskEnum;
  constructor(public mainService: RemoteManagementWizardsService) {
    super(mainService);
  }

  ngOnInit(): void {
    this.initForm();

    this.restoreAsChangeHandler(this.restoreAs);
  }

  protected getPipeOperators(): FormPipeOperators {
    return [untilDestroyed(this), debounceTime(200)];
  }

  initForm(): void {
    this.stepForm = new UntypedFormGroup({
      account: new FormControl('', Validators.required),
      computerName: new FormControl(''),
      dataDiskName: new FormControl(''),
      locations: new FormControl(''),
      resourceGroup: new FormControl(''),
      vmSize: new FormControl(''),
      network: new FormControl(''),
      subnet: new FormControl(''),
      storage: new FormControl(''),
      container: new FormControl(''),
      bootDiagnosticStorage: new FormControl('')
    });

    this.initFormEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.restoreAs && this.stepForm) this.restoreAsChangeHandler(changes.restoreAs.currentValue);
  }

  restoreAsChangeHandler(event): void {
    if (event === RestoreAsDiskEnum.AVM) {
      this.stepForm.get('dataDiskName').disable();
      this.stepForm.get('computerName').enable();
      this.stepForm.get('bootDiagnosticStorage').enable();
      this.stepForm.get('subnet').enable();
      this.stepForm.get('network').enable();
      this.stepForm.get('vmSize').enable();
      this.stepForm.get('locations').enable();
    } else if (event === RestoreAsDiskEnum.ADD) {
      this.stepForm.get('dataDiskName').enable();
      this.stepForm.get('computerName').disable();
      this.stepForm.get('bootDiagnosticStorage').disable();
      this.stepForm.get('subnet').disable();
      this.stepForm.get('network').disable();
      this.stepForm.get('vmSize').disable();
      this.stepForm.get('locations').disable();
    }
  }
}
