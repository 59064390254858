<mbs-alert *ngIf="!actionInProgress && !isMacOS && !isLinuxOS && showGroupTaskInfoPopup"
           [type]="MbsPopupType.info"
           [icon]="true"
           [class]="'mt-2'"
           [closable]="true"
           (close)="showGroupTaskInfoPopup = false">
  <div *ngIf="!currentlyInstalling && isInstallSupported && isWingetSupported; else wingetCommonInfo"
       class="row">
    <div class="col">
      {{ 'rmm-side-panel:softwareTab.manageInfoPart1' | i18next }}
      <mbs-button [isCtrl]="true"
                  [size]="'md'"
                  (click)="handleOpenRmmScheduleGroupAction()">
        {{ 'rmm-side-panel:softwareTab.createGroupTask' | i18next }}
      </mbs-button>
      {{ 'rmm-side-panel:softwareTab.manageInfoPart2' | i18next }}
    </div>
  </div>
  <ng-template #wingetCommonInfo>
    {{ 'rmm-side-panel:softwareTab.wingetNotSupportedInfo' | i18next }}
  </ng-template>
</mbs-alert>

<mbs-alert [type]="MbsPopupType.info"
           [class]="'mt-2'"
           *ngIf="actionInProgress">
  <div class="row">
    <div class="col">
      <ng-container [ngSwitch]="actionTypeInProgress">

        <mbs-text-ellipsis *ngSwitchCase="actionTypeEnum.Install"
                           tooltip="Installing {{inProgressAmount}} application(s)">
          Installing {{inProgressAmount}} application(s)
        </mbs-text-ellipsis>

        <mbs-text-ellipsis *ngSwitchCase="actionTypeEnum.Update"
                           tooltip="Installing {{inProgressAmount}} application(s)">
          Updating {{inProgressAmount}} application(s)
        </mbs-text-ellipsis>

        <mbs-text-ellipsis *ngSwitchDefault
                           [tooltip]="'Uninstalling ' + currentlyUninstalling">Uninstalling
          {{ currentlyUninstalling }}
        </mbs-text-ellipsis>

      </ng-container>

    </div>
  </div>
</mbs-alert>

<div class="mbs-form-group mb-0">
  <mbs-advanced-search [accessUrlQuery]="false"
                       [(ngModel)]="model"
                       (search)="updateFilters($event)"
                       (input)="handleInputSearch($event)">
  </mbs-advanced-search>
</div>

<mbs-table-nav [switcherView]="false"
               (pageChange)="handlePageChange($event)"
               (pageSizeChange)="handlePageSizeChange($event)"
               [showRefreshButton]="true"
               [showExportToCSVButton]="isModal"
               (export)="export(getTableName())"
               [exportButtonName]="'buttons:exportToCSV' | i18next"
               (refresh)="fetchData()">

  <ng-template mbsTableFilter="label">
    <mbs-button *ngIf="showOutdatedOnly; else allSoftwareCount"
                [isCtrl]="true"
                [size]="'sm'"
                (click)="oudatedFiltering(false)">
      <ng-container *ngTemplateOutlet="allSoftwareCount"></ng-container>
    </mbs-button>
    <ng-template #allSoftwareCount>
      {{ 'rmm-side-panel:softwareTab.softwareCount' | i18next }} {{ allData.length ?? 0 }}
    </ng-template>
  </ng-template>

  <ng-template mbsTableFilter="label"
               *ngIf="isInstallSupported && isWingetSupported && !isMacOS && !isLinuxOS">

    <mbs-button *ngIf="!showOutdatedOnly; else outdated"
                [isCtrl]="true"
                [size]="'sm'"
                (click)="oudatedFiltering()">
      <ng-container *ngTemplateOutlet="outdated"></ng-container>
    </mbs-button>

    <ng-template #outdated>
      {{ 'rmm-side-panel:softwareTab.outdated' | i18next }} {{ getOutdatedCount() }}
    </ng-template>
  </ng-template>

  <ng-template mbsTableFilter
               *ngIf="isOnline && !readonly && isVersionSupported">

    <mbs-button *ngIf="!isMacOS && !isLinuxOS"
                class="mr-1"
                [size]="MbsSize.sm"
                [disabled]="!!selected.length || actionInProgress"
                [ngbTooltip]="'buttons:install' | i18next"
                (click)="openInstallModal()">
      <span class="fa fa-plus font-weight-semibold"></span>
      <span *ngIf="isModal">{{ 'buttons:install' | i18next }}</span>
    </mbs-button>

    <mbs-button *ngIf="isInstallSupported"
                class="mr-1"
                [size]="MbsSize.sm"
                [disabled]="!selected.length || actionInProgress || disableInstall || !isWingetSupported"
                [ngbTooltip]="'buttons:update' | i18next"
                (click)="updateSelected(selected)">
      <span class="fa fa-arrow-circle-o-down"></span>
      <span *ngIf="isModal">{{ 'buttons:update' | i18next }}</span>
    </mbs-button>

    <mbs-button *ngIf="isUninstallAvailable"
                [size]="MbsSize.sm"
                [disabled]="!selected.length || disableUninstall || actionInProgress"
                [ngbTooltip]="'rmm-side-panel:softwareTab.uninstall' | i18next"
                (click)="uninstallSelected()">
      <span class="fa fa-trash-o"></span>
      <span *ngIf="isModal">{{ 'buttons:uninstall' | i18next }}</span>
    </mbs-button>
  </ng-template>


  <ng-template mbsTableControl="append"
               *ngIf="!isModal">
    <mbs-modal-tab-content modalTitle="{{ 'rmm-side-panel:softwareTab.modalTitle' | i18next }}"
                           [showExpandedCross]="true">
      <ng-template>
        <mbs-software-tab [hid]="hid"
                          [isOnline]="isOnline"
                          [computerName]="computerName"
                          [agentOptions]="agentOptions"
                          [agentVersion]="agentVersion"
                          [readonly]="readonly"
                          (onFetchData)="fetchData()"
                          (onFirstStatusRequest)="handleStatusRequestFromModal()"
                          [isModal]="true"></mbs-software-tab>
      </ng-template>
    </mbs-modal-tab-content>
  </ng-template>

</mbs-table-nav>

<mbs-table-grid class="ph-no-capture"
                [loading]="loading$ | async"
                [headers]="headers"
                [showTableCheckboxes]="isVersionSupported"
                [data]="data"
                [selectedItems]="selected"
                [multipleSelect]="isInstallSupported && isVersionSupported"
                (sort)="handleSort($event)"
                [changeSortState]="orderBy"
                [bindSelected]="'name'"
                (changeSelected)="itemsChecked($event)"
                [switcherView]="false"
                [paginationOptions]="paginationOptions"
                [myTrackBy]="trackBy"
                [checkboxCellWidth]="'32px'"
                [maxHeight]="isModal || !isOnline ? '65vh' : '52vh'"
                [rotateSequence]="rotateSequence"
                [showNav]="false"
                classesTable="mb-0"
                [viewMode]="sharedPersistentStateEnum.table"
                [disableViewSwitch]="true"
                [noDataMessage]="('rmm-side-panel:' + emptyTableMsgTranslationKey) | i18next">

  <ng-template mbsTableCell
               *ngFor="let header of headers"
               let-item>

    <ng-template #withAvailableVersion>
      <span ngbTooltip="Current version - {{ item[header.sort] }}"
            container="body"
            tooltipClass="tooltip-lg text-left">
        {{ item[header.sort] }}
      </span>

      <span *ngIf="item.availableVersion"
            ngbTooltip="New version - {{ item.availableVersion }}"
            container="body"
            tooltipClass="tooltip-lg text-left">
        <span class="text-gray-300 mx-2">|</span>
        <span class="bg-info-20 font-weight-semibold text-sm p-1 mr-2">
          {{ ('app:common.new' | i18next).toUpperCase() }}
        </span>
        {{ item.availableVersion }}
      </span>
    </ng-template>


    <ng-template #tooltipOverflow>
      <div placement="auto"
           [ngbTooltip]="header.sort !== 'version' ? item[header.sort] : ''"
           container="body"
           tooltipClass="tooltip-lg text-left"
           class="text-overflow">

        <span *ngIf="header.sort !== 'version'; else withAvailableVersion">
          {{ item[header.sort] }}
        </span>
      </div>
    </ng-template>

    <ng-container *ngIf="!header.overflow; else tooltipOverflow">
      {{ item[header.sort] }}
    </ng-container>

  </ng-template>

</mbs-table-grid>
