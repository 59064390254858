import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MbsSize, TextEllipsisComponent } from 'mbs-ui-kit';
import { ButtonSize } from 'mbs-ui-kit/button/button.component';
import Computer from '@shared/models/Computer';

@Component({
  selector: 'mbs-iteration-ctrl-button',
  templateUrl: './iteration-ctrl-button.component.html'
})
export class IterationCtrlButtonComponent implements OnChanges, AfterViewInit {
  @ViewChild('textEllipsisRef') textEllipsisRef: TextEllipsisComponent;

  @Input() disabled: boolean;
  @Input() selected: Array<{ id: string; name: string }> = [];
  @Input() emptyLabel = 'Select Computers...';
  @Input() size: ButtonSize = MbsSize.md;
  @Input() editIconVisible = true;
  @Input() requestPending: boolean;
  @Input() bindLabel: string;

  @Output() buttonClick = new EventEmitter();

  public elementsToShowCount = 5;
  public firstNElements = [];
  public myHide = true;

  constructor() {}

  handleCheck(canFit: boolean): void {
    const rangeSelected = this.selected.length >= 2 && this.selected.length <= 5;
    const singleSelected = this.selected.length === 1;
    this.myHide = (canFit && rangeSelected) || singleSelected;
  }

  handleClick(event: Event): void {
    this.buttonClick.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.selected = changes.selected.currentValue || [];
      const computersToBeShown = this.selected.slice(0, this.elementsToShowCount);
      this.firstNElements = computersToBeShown.map((computer) => {
        const computerNew = { ...computer };
        computerNew.name = this.getComputerName(computerNew);
        return computerNew;
      });
    }
    if (changes.requestPending && this.textEllipsisRef) {
      !changes.requestPending.currentValue && this.textEllipsisRef.check();
    }
  }

  ngAfterViewInit(): void {
    this.textEllipsisRef && this.textEllipsisRef.check();
  }

  private getComputerName(computer: Computer): string {
    if (computer.name) return computer.name;
    if (computer.computerName) return computer.computerTag ? `${computer.computerTag} [${computer.computerName}]` : computer.computerName;

    return null;
  }
}
