import { HttpClient } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import Administrator from '@models/Administrator';
import { PaymentSystem } from '@models/PaymentSystem';
import { CurrentCredits } from '@models/storage-accounts/CurrentCredits';
import { SpentCredits } from '@models/storage-accounts/SpentCredits';
import { UnifiedBillingAccount, UnifiedBillingAccountType } from '@models/storage-accounts/UnifiedBillingAccount';
import { StorageType } from '@models/StorageType.enum';
import { AuthService } from '@services/auth.service';
import { AbilityService } from 'ability';
import { MbsSize, ModalService } from 'mbs-ui-kit';
import { noop, Observable } from 'rxjs';
import { SubscribeUbAwsComponent } from './subscribe-ub-aws/subscribe-ub-aws.component';
import { SubscribeUbComponent } from './subscribe-ub-base';
import { SubscribeUbWasabiComponent } from './subscribe-ub-wasabi/subscribe-ub-wasabi.component';

export type debtData = { creditsLeft: number; creditsTotal: number; debtLeft: number; debtTotal: number };

@Injectable()
export class UbSubscribeService {
  private currentUser: Administrator;

  public get isFastSpring(): boolean {
    return this.currentUser && this.currentUser.ProviderInfo.PaymentSystem === PaymentSystem.FastSpring;
  }

  public get providerContactEmail(): string {
    return this.currentUser && this.currentUser.ProviderInfo.EmailNotificationsTo;
  }

  private mailto = 'sales@msp360.com';

  public readonly canRestoreTypes = [
    UnifiedBillingAccountType.Trial, // prettier
    UnifiedBillingAccountType.Paid,
    UnifiedBillingAccountType.PaidWithDebt
  ];

  public readonly readonlyTypes = [
    UnifiedBillingAccountType.PaidWithDebtReadOnly, // prettier
    UnifiedBillingAccountType.TrialReadOnly
  ];

  /**
   * Manually deleted account
   */
  public readonly deletedTypes = [
    UnifiedBillingAccountType.TrialManuallyDeactivated,
    UnifiedBillingAccountType.PaidManuallyDeactivated,
    UnifiedBillingAccountType.PaidWithDebtManuallyDeactivated
  ];

  public readonly deleteByOurSystemTypes = [
    UnifiedBillingAccountType.TrialDeactivated, // prettier
    UnifiedBillingAccountType.PaidWithDebtDeactivated
  ];

  public readonly overdueTypes = [
    UnifiedBillingAccountType.PaidWithDebt,
    UnifiedBillingAccountType.PaidWithDebtReadOnly,
    UnifiedBillingAccountType.PaidWithDebtNotPaid,
    UnifiedBillingAccountType.PaidWithDebtDeactivated
  ];

  constructor(
    private modalService: ModalService,
    private ability: AbilityService, // prettier
    private auth: AuthService,
    private http: HttpClient
  ) {
    this.auth.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
  }

  showSwitchCommercial(storageAccount: UnifiedBillingAccount): void {
    if (this.ability.can('read', 'Provider')) {
      if (this.isFastSpring) {
        let subscriptionModalType: Type<SubscribeUbComponent>;
        switch (storageAccount.storageType) {
          case StorageType.CblWasabi:
            subscriptionModalType = SubscribeUbWasabiComponent;
            break;
          case StorageType.UbAmazonS3:
            subscriptionModalType = SubscribeUbAwsComponent;
            break;

          default:
            console.error(`Undefined storage type ${storageAccount.storageTypeDisplayName}`);
            return;
        }
        const ubRef: SubscribeUbComponent = this.modalService.openRef(subscriptionModalType, { size: MbsSize.lg }).componentInstance;
        ubRef.selectedStorageId = storageAccount.id;
      } else {
        this.modalService.open(
          { header: { title: 'Warning' }, footer: { cancelButton: { show: false } } },
          `Commercial mode can't be activated. Please <a href="mailto:${this.mailto}"> contact us.</a>`
        );
      }
    } else {
      this.modalService
        .confirm(
          { header: { title: 'MSP360 Storage Subscription' }, footer: { cancelButton: { show: false } } },
          'This operation can only be performed by the account owner'
        )
        .catch(noop);
    }
  }

  getCurrentCredits(storageType: StorageType): Observable<CurrentCredits> {
    return this.http.get<CurrentCredits>('api/licenses/cancel-ubaws-pricing', { params: { storageType: storageType.toString() } });
  }

  getUbDebt(storageType: StorageType): Observable<debtData> {
    return this.http.get<debtData>('api/storageaccounts/ub/debt', { params: { storageType: storageType.toString() } });
  }

  getPayUbDebt(storageType: StorageType): Observable<{ paymentLink: string }> {
    return this.http.put<{ paymentLink: string }>('api/storageaccounts/ub/pay-debt', null, {
      params: { storageType: storageType.toString() }
    });
  }

  spendCredits(storageType: StorageType): Observable<SpentCredits> {
    return this.http.put<SpentCredits>('api/licenses/cancel-ubaws-pricing', null, { params: { storageType: storageType.toString() } });
  }
}
