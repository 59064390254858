import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { ConfirmRedirectToAppsAsProviderComponent } from './confirm-redirect-to-apps-as-provider/confirm-redirect-to-apps-as-provider.component';
import { MinimumStorageDurationComponent } from './minimum-storage-duration/minimum-storage-duration.component';

const modals = [ConfirmRedirectToAppsAsProviderComponent, MinimumStorageDurationComponent];

@NgModule({
  imports: [CommonModule, FormsModule, MbsUiKitModule, I18NextModule],
  declarations: modals
})
export class EmptyPageModalsModule {}
