import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';
import { EmptyPageComponent } from '@modules/empty-page/empty-page.component';

function getShowPromoIosState(cookie: string): boolean {
  return !cookie.toLowerCase().includes('show_promo_ios=false');
}
@UntilDestroy()
@Component({
  selector: 'mbs-ios-top-widget',
  templateUrl: './ios-top-widget.component.html',
  styleUrls: ['./ios-top-widget.component.scss']
})
export class IosTopWidgetComponent implements OnInit, OnDestroy, AfterViewChecked {
  public show = false;
  private isAppleDevice = /Mac|iOS/.test(this.deviceService.os);
  @Output() onClose = new EventEmitter<any>();

  private myActiveRouteComponent: any;
  @Input() set activeRouteComponent(value: any) {
    this.myActiveRouteComponent = value;
    if (value instanceof EmptyPageComponent) {
      value.iframeContentLoaded.subscribe(() => {
        this.updateDomElementHeight();
      });
    }
  }

  constructor(
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private dom: Document, // prettier
    private router: Router,
    private renderer2: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.isAppleDevice) {
      const media = matchMedia('(min-width: 1023px)');
      media.addListener(this.toggleWidget.bind(this));
      this.toggleWidget(media);

      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          untilDestroyed(this)
        )
        .subscribe(() => this.toggleWidget(media));
    }
  }

  ngAfterViewChecked(): void {
    this.show && this.updateDomElementHeight();
  }

  ngOnDestroy(): void {
    matchMedia('(min-width: 1023px)').removeListener(this.toggleWidget.bind(this));
  }

  handleClose(): void {
    this.onClose.emit(this);
    this.show = false;
    this.dom.cookie = `SHOW_PROMO_IOS=${String(false)}; path=/`;

    this.updateDomElementHeight();
  }

  toggleWidget(event: MediaQueryList): void {
    this.show = this.router.url.includes('/AP/Computers.aspx') && !event.matches;
    const SHOW_PROMO_IOS = getShowPromoIosState(this.dom.cookie);
    this.show = this.show && SHOW_PROMO_IOS;

    this.updateDomElementHeight();
  }

  updateDomElementHeight(): void {
    const header = this.dom.querySelector('.mbs-header');
    const containerContent = this.dom.querySelector('.mbs-container-content');
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const iframe = this.dom.querySelector('iframe[name=mbs-iframe-body]') as HTMLIFrameElement;
    const iframeDocument = iframe ? iframe.contentDocument : null;
    const iframeBody = iframeDocument ? iframeDocument.getElementById('ctlBody') : null;
    const widget = this.el.nativeElement.querySelector('.mbs-widget') as HTMLDivElement;
    if (this.show) {
      const widgetHeight = widget ? widget.clientHeight : 0;
      const headerHeight = header ? header.clientHeight : 0;
      header && this.renderer2.setStyle(header, 'top', widgetHeight + 'px');
      containerContent && this.renderer2.setStyle(containerContent, 'paddingTop', 0);
      iframeBody && this.renderer2.setStyle(iframeBody, 'padding-top', widgetHeight + headerHeight + 'px');
    } else {
      header && this.renderer2.removeStyle(header, 'top');
      containerContent && this.renderer2.removeStyle(containerContent, 'paddingTop');
      iframeBody && this.renderer2.setStyle(iframeBody, 'padding-top', 'unset');
    }
  }
}
