import { Injectable } from '@angular/core';
import { TagKind } from '@models/tag';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TagsService } from '@services/tags.service';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { ComputerTagsStoreActions, ComputerTagsStoreSelectors } from '.';

@Injectable()
export class ComputerTagsEffects {
  loadTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputerTagsStoreActions.loadComputerTagById),
      concatLatestFrom((action) => this.store.select(ComputerTagsStoreSelectors.selectById(action.id))),
      filter(([action, tag]) => !!action.id && (!tag || action.force)),
      mergeMap(([action]) => {
        return this.tagsService.getTagById(action.id).pipe(
          map((result) => ComputerTagsStoreActions.setComputerTags({ tags: [result] })),
          catchError(() => of(ComputerTagsStoreActions.loadComputerTagsError()))
        );
      })
    );
  });

  loadAllTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputerTagsStoreActions.loadAllComputerTags),
      concatLatestFrom(() => this.store.select(ComputerTagsStoreSelectors.selectLoaded)),
      filter(([action, loaded]) => !loaded || action.force),
      mergeMap(([action]) => {
        return this.tagsService.getTagsByKind(TagKind.Computer, action.calculateCount).pipe(
          map((tags) => ComputerTagsStoreActions.setComputerTags({ tags })),
          catchError(() => of(ComputerTagsStoreActions.loadComputerTagsError()))
        );
      })
    );
  });

  setSelectedTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComputerTagsStoreActions.setSelected),
      concatLatestFrom(() => [
        this.store.select(ComputerTagsStoreSelectors.selectSelected),
        this.store.select(ComputerTagsStoreSelectors.selectEntities)
      ]),
      filter(([action, selected]) => action.selected !== selected),
      switchMap(([action, , tags]) => {
        if (tags[action.selected]) return of(ComputerTagsStoreActions.setSelectedSuccess({ selected: action.selected }));
        return this.tagsService.getTagById(action.selected).pipe(
          concatMap((result) =>
            of(
              ComputerTagsStoreActions.setComputerTags({ tags: [result] }),
              ComputerTagsStoreActions.setSelectedSuccess({ selected: result.id })
            )
          ),
          catchError(() => of(ComputerTagsStoreActions.loadComputerTagsError()))
        );
      })
    );
  });

  constructor(private actions$: Actions, private tagsService: TagsService, public store: Store) {}
}
