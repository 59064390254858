export enum IpWhiteListState {
  None,
  Change,
  Add,
  Delete
}

export default class IpWhiteList {
  id?: number;
  description: string;
  ips: string[];
  ipRanges: string[];
  State: IpWhiteListState;
  hidden?: boolean;

  constructor() {
    this.description = '';
    this.State = IpWhiteListState.None;
  }
}
