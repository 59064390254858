import { Component, ViewChild } from '@angular/core';
import { MbsPopupType, ModalComponent } from 'mbs-ui-kit';

@Component({
  selector: 'mbs-immutability-delete-modal',
  templateUrl: './immutability-delete-modal.component.html'
})
export class ImmutabilityDeleteModalComponent {
  public mbsPopupType = MbsPopupType;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  confirmClickHandler(): void {
    this.baseModal.save({ confirm: true });
  }

  cancelClickHandler(): void {
    this.baseModal.close();
  }
}
