import * as fromReducer from './computer-apps.reducer';

import Computer, { AgentState, AgentType } from '@models/Computer';
import { ComputerApp, State } from './computer-apps.model';
import { FeatureKey, generateAgentId } from './const';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectFeature = createFeatureSelector<State>(FeatureKey);
export const selectIds = createSelector(selectFeature, fromReducer.selectIds);
export const selectEntities = createSelector(selectFeature, fromReducer.selectEntities);
export const selectAll = createSelector(selectFeature, fromReducer.selectAll);
export const selectTotal = createSelector(selectFeature, fromReducer.selectTotal);

export const selectLoading = createSelector(selectFeature, state => state.loading);
// Apps selectors
export const selectRAAgent = (hid: Computer['hid']) =>
  createSelector(selectEntities, entities => {
    const id = generateAgentId(AgentType.RA, hid);
    return entities[id] || null;
  });
export const selectBackupAgent = (hid: Computer['hid']) =>
  createSelector(selectEntities, entities => {
    const id = generateAgentId(AgentType.Backup, hid);
    return entities[id] || null;
  });
export const selectRMMAgent = (hid: Computer['hid']) =>
  createSelector(selectEntities, entities => {
    const id = generateAgentId(AgentType.RMM, hid);
    return entities[id] || null;
  });
export const selectDeepInstAgent = (hid: Computer['hid']) =>
  createSelector(selectEntities, entities => {
    const id = generateAgentId(AgentType.DeepInst, hid);
    return entities[id] || null;
  });
// Prop selectors
export const selectRMMAgentState = (hid: Computer['hid']) =>
  createSelector(selectRMMAgent(hid), (app = {} as ComputerApp) => app && app.applicationState === AgentState.Enabled);
