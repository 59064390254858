import { AfterContentChecked, ChangeDetectorRef, Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Administrator from '@models/Administrator';
import { OnboardingMode } from '@modules/wizards/services/onboarding/onboarding.constants';
import { StepsService } from '@modules/wizards/onboarding/services/steps/steps.service';
import { StepType } from '@modules/wizards/onboarding/services/steps/steps.types';
import { untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { MbsPopupType, StepCondition } from 'mbs-ui-kit';

@Directive()
export abstract class CommonView<StepId> implements AfterContentChecked {
  @Output() finish = new EventEmitter<string>();
  @Input() mode: OnboardingMode;
  public readonly stepCondition = StepCondition;
  public readonly mbsPopupType = MbsPopupType;
  public provider: Administrator;
  public b2CredentialsForm = this.fb.group({
    publicKey: ['', [Validators.required]],
    alreadyUsedAccounts: [[]],
    secretKey: ['', [Validators.required]],
    storageUnitName: [`cloudbackup${Math.floor(Math.random() * 100000000)}`]
  });
  public errorMessage = null;

  protected constructor(
    protected stepsService: StepsService<StepId>,
    public authService: AuthService,
    protected cdr: ChangeDetectorRef,
    public fb: FormBuilder
  ) {}

  public get parentSteps(): { name: string; condition: StepCondition }[] {
    return this.stepsService.parentSteps.getValue();
  }

  public get currentStep(): StepType<StepId> {
    return this.stepsService.currentStep.getValue();
  }

  public ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public onTabClick(id: StepId): void {
    this.stepsService.transition(id);
  }

  public isBackButtonShown(): boolean {
    const currentStep = this.stepsService.currentStep.getValue();

    return currentStep.prev !== null && !this.stepsService.getStepById(currentStep.prev).isAutomatic;
  }

  protected initialize(): void {
    this.authService.currentUser.pipe(untilDestroyed(this)).subscribe((provider) => (this.provider = provider));
  }

  public showAlert(message: string): void {
    this.errorMessage = message;
    setTimeout(() => (this.errorMessage = null), 5000);
  }

  public removeError() {
    this.errorMessage = null;
  }
}
