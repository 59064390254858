<div class="text-base mb-1"
     *ngIf="!isMacOS && !isLinuxOS">
  <span class="font-weight-semibold">
    {{ (translationKey + 'lastStatus') | i18next }}&nbsp;
  </span>
  {{updateStatusText}}

  <ng-container [ngSwitch]="rebootStatus$ | async">
    <ng-container *ngSwitchCase="true">
      <mbs-button class="ml-1"
                  [isCtrl]="true"
                  icon="ico ico-OverdueSettings"
                  container="body"
                  [ngbTooltip]="(translationKey + 'rebootIsRequired') | i18next"
                  type="warning"></mbs-button>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mbs-button class="ml-1"
                  *ngIf="lastUpdateStatusText"
                  [isCtrl]="true"
                  icon="ico ico-InfoCircle"
                  container="body"
                  ngbTooltip="{{lastUpdateStatusText}}"
                  type="primary"></mbs-button>
    </ng-container>
  </ng-container>
</div>

<div class="row text-xs mb-2 pb-1"
     *ngIf="isMacOS || isLinuxOS">
  <div class="col-12">
    <mbs-alert *ngIf="macUpdateStatus"
               [type]="'info'"
               [icon]="true"
               class="mb-3">
      <span>
        {{(translationKey + 'updates') | i18next}} {{ macUpdateStatus }}
      </span>
    </mbs-alert>
  </div>
</div>

<div class="row text-xs mb-2 pb-1"
     *ngIf="!isMacOS && !isLinuxOS">

  <div class="col-auto">
    <span class="font-weight-semibold">
      {{ (translationKey + 'downloadProgressCount') | i18next }}&nbsp;
    </span>
    {{downloadedCount}}/{{totalDownloadCount}}
  </div>

  <div class="col-auto">
    <span class="font-weight-semibold">
      {{ (translationKey + 'sizeProgressCount') | i18next }}&nbsp;
    </span>
    {{downloadedBytes}}/{{totalBytesForDownload}}
  </div>

  <div class="col-auto">
    <span class="font-weight-semibold">
      {{ (translationKey + 'installedProgressCount') | i18next }}&nbsp;
    </span>
    {{installedCount}}/{{totalInstalledCount}}
  </div>
</div>

<div class="mbs-form-group mb-0">
  <mbs-advanced-search [accessUrlQuery]="false"
                       [(ngModel)]="model"
                       (search)="updateFilters($event)"
                       (input)="handleInputSearch($event)">
  </mbs-advanced-search>
</div>

<mbs-table-nav [switcherView]="false"
               (pageChange)="handlePageChange($event)"
               (pageSizeChange)="handlePageSizeChange($event)"
               [showRefreshButton]="true"
               [showExportToCSVButton]="isModal"
               (export)="export()"
               [exportButtonName]="'buttons:exportToCSV' | i18next"
               (refresh)="fetchData()">

  <ng-template mbsTableFilter="label">
    {{ (translationKey + 'availableUpdates') | i18next: { value: paginationOptions.dataSize } }}
  </ng-template>

  <ng-template mbsTableFilter
               *ngIf="isOnline && readOnly === false">
    <mbs-button size="sm"
                [hidden]="!isInstallUpdateAvailable"
                [disabled]="data?.length === 0 || selected.length === 0 || hasInstallationActivities"
                (click)="handleInstallFewHotfixes()">
      <span class="fa fa-play-circle-o"></span>
      {{ (translationKey + 'installSelected') | i18next }}
    </mbs-button>
  </ng-template>

  <ng-template mbsTableControl="append"
               *ngIf="!isModal && !isMacOS && !isLinuxOS">
    <mbs-modal-tab-content modalTitle="Available Windows Updates"
                           [showExpandedCross]="true">
      <ng-template>
        <mbs-hotfixes-available-tab [hid]="hid"
                                    [isOnline]="isOnline"
                                    [isModal]="true"
                                    [readOnly]="readOnly"
                                    [computerName]="computerName"></mbs-hotfixes-available-tab>
      </ng-template>
    </mbs-modal-tab-content>
  </ng-template>

</mbs-table-nav>

<mbs-table-grid class="ph-no-capture"
                [loading]="loading"
                [headers]="headers"
                [data]="data"
                [changeSortState]="orderBy"
                [switcherView]="false"
                [paginationOptions]="paginationOptions"
                [myTrackBy]="trackBy"
                [rotateSequence]="rotateSequence"
                [showNav]="false"
                [disableViewSwitch]="true"
                [showTableCheckboxes]="isOnline && showTableCheckboxes"
                [checkboxCellWidth]="'32px'"
                [selectedItems]="selected"
                [bindDisabledValues]="{ key: 'disabled', value: true }"
                [selectAllIgnoreDisabled]="true"
                (sort)="handleSort($event)"
                (changeSelected)="handleChangeSelected($event)"
                [multipleSelect]="true"
                [selectable]="isInstallUpdateAvailable"
                [bindSelected]="bindSelected"
                [maxHeight]="isModal || !isOnline ? '65vh' : '62vh'"
                [viewMode]="sharedPersistentStateEnum.table"
                classesTable="mb-0"
                [noDataMessage]="'rmm-side-panel:noDataProvided' | i18next">

  <ng-template mbsTableCell
               *ngFor="let header of headers"
               let-item>

    <ng-template #tooltipOverflow>

      <div placement="auto"
           [ngbTooltip]="getTooltip(item[header.sort])"
           container="body"
           tooltipClass="tooltip-lg text-left"
           class="text-overflow">

        <ng-container [ngSwitch]="header.sort">
          <ng-container *ngSwitchCase="'severityScore'">
            {{ !!item[header.sort] ? item.severity : 'rmm-side-panel:noInfo' | i18next }}
          </ng-container>

          <ng-container *ngSwitchCase="'categories'">
            {{ !!item[header.sort] && item[header.sort].length > 0 ? item[header.sort][0] : '' }}
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{ !!item[header.sort] ? item[header.sort] : 'rmm-side-panel:noInfo' | i18next }}
          </ng-container>
        </ng-container>

      </div>
    </ng-template>

    <ng-container *ngIf="!header.overflow; else tooltipOverflow">
      <ng-container *ngIf="(header.sort === 'size')">
        {{ !!+item[header.sort] ? (+item[header.sort] | size) : 'rmm-side-panel:noInfo' | i18next }}
      </ng-container>
      <ng-container *ngIf="(header.sort !== 'size')">
        {{ !!item[header.sort] ? item[header.sort] : 'rmm-side-panel:noInfo' | i18next }}
      </ng-container>
    </ng-container>


  </ng-template>

</mbs-table-grid>

<ng-template #confirmInstallModal>
  <p>
    <ng-container *ngIf="selected.length === 1">
      {{ (translationKey + 'installUpdate') | i18next }}
      <span class="font-weight-semibold">{{selected[0].kb || selected[0].title}}</span>?
    </ng-container>
    <ng-container *ngIf="selected.length > 1">
      {{ (translationKey + 'installUpdate') | i18next }}
      <span *ngFor="let item of selected; last as isLast"
            class="font-weight-semibold">
        {{ item.kb || item.title }}{{ isLast ? '' : ', ' }}</span>?
    </ng-container>
  </p>
  <mbs-alert [type]="alertType.warning"
             [icon]="true"
             class="mb-0">
    {{ (translationKey + 'restartRequired') | i18next }}
  </mbs-alert>
</ng-template>
