import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { environment } from '@mbs-ui/environments/environment';
import { FOMAT_TOKEN, formatTitle } from '@utils/i18-format-title';
import { defaultInterpolationFormat, I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import Backend from 'i18next-http-backend';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

const suffixAP = '/AP';
const suffixAS = '/AS';

function basePath(skipReplaceSuffix = false): string {
  let path = document.querySelector('base')?.baseURI.replace(location.origin, '').replace(/\/$/, '');
  !skipReplaceSuffix && (path = path.split(suffixAS)[0].split(suffixAP)[0]);
  return path;
}

const LOCALE_DATA = {
  en: localeEn
};

/**
 * Detect lang be html lang="" attr async
 * @return {Observable<string>}
 */
export function documentLangObserver(): Observable<string> {
  const subject: Subject<string> = new Subject();
  const mutationObserver = new MutationObserver((mutationsList, _observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
        subject.next((mutation.target as HTMLElement).getAttribute('lang'));
      }
    }
  });

  mutationObserver.observe(document.documentElement, { attributes: true });
  return subject.pipe(startWith(document.documentElement.getAttribute('lang')));
}
/**
 * Lang detect ngApp integration
 */
const LANGUAGE_DETECTOR = {
  type: 'languageDetector',
  async: true,
  init: function (services, detectorOptions, i18nextOptions) {
    /* use services and options */
  },
  detect: function (callback) {
    documentLangObserver().subscribe((lang) => {
      const data = LOCALE_DATA[lang] || LOCALE_DATA.en;
      registerLocaleData(data, lang);
      callback(lang);
    });
  },
  cacheUserLanguage: function (lng) {
    /* cache language */
  }
};

function getI18nextOptions(skipReplaceSuffix = false) {
  return {
    whitelist: ['en'],
    fallbackLng: 'en',
    debug: false,
    returnEmptyString: true,
    defaultNS: 'app',
    interpolation: {
      format: (value, format, lng) => {
        if (format === FOMAT_TOKEN) return formatTitle(value, lng);
        if (value instanceof Date) return moment(value).format(format);
        return defaultInterpolationFormat(value, format, lng);
      }
    },
    backend: {
      loadPath: basePath(skipReplaceSuffix) + environment.localePath,
      addPath: basePath(skipReplaceSuffix) + environment.localePath
    },
    ns: [
      'app',
      'app-header',
      'binding-certificates',
      'validation',
      'wizards',
      'overdue',
      'buttons',
      'immutability',
      'error',
      'agent-options',
      'feedback',
      'branding',
      'sidepanel-online-access',
      'sidepanel-downloads',
      'download-connect.module',
      'sidepanel-preinstall-settings',
      'builds',
      'tfa',
      'bandwidth-settings',
      'uninstall-agent-modal',
      'backup-storages',
      'licenses',
      'webrtc',
      'tfa',
      'rmm.module',
      'backup',
      'quick-restore-modal',
      'download-connect.module',
      'computers.module',
      'sidepanel-backup',
      'settings'
    ]
  };
}

function i18NextInit(i18next: ITranslationService, skipReplaceSuffix = false) {
  return () => {
    return i18next.use<any>(Backend).use<any>(LANGUAGE_DETECTOR).init(getI18nextOptions(skipReplaceSuffix));
  };
}

/*
 * Before ngApp init
 */
export function appInit(i18next: ITranslationService) {
  return i18NextInit(i18next);
}

export function cleanAppInit(i18next: ITranslationService) {
  return i18NextInit(i18next, true);
}

/*
 * get i18next instance ID
 */
export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export function I18N_PROVIDERS(skipReplaceSuffix = false) {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: skipReplaceSuffix ? cleanAppInit : appInit,
      deps: [I18NEXT_SERVICE],
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [I18NEXT_SERVICE],
      useFactory: localeIdFactory
    }
  ];
}
