import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PowerShellOutType } from '@shared/models/rmm/PowerShellResultModel';
import { isNil } from 'lodash';
import { filter, fromEvent, noop, take, tap } from 'rxjs';
import { TerminalNewPrismService } from '../terminal-new-prism.service';
import { TerminalNewService } from '../terminal-new.service';

@UntilDestroy()
@Component({
  selector: 'mbs-terminal-new',
  templateUrl: './terminal-new.component.html',
  styleUrls: ['./terminal-new.component.scss']
})
export class TerminalNewComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewChecked, AfterViewInit {
  @ViewChild('textArea', { static: true }) textArea: ElementRef;
  @ViewChild('codeContent', { static: true }) codeContent: ElementRef;
  @ViewChild('pre', { static: true }) pre: ElementRef;
  @Input() isModal = true;
  @Input() shouldInit = false;
  @Input() shouldFocus = false;
  @Input() hid = null;
  @Input() public disabledSelf = false;
  @Input() isMac = false;
  @Input() codeType = 'powershell';
  @Input() height = '300px';
  @Output() enableOpenInModal = new EventEmitter<boolean>(false);
  /**
   * Readonly input
   */
  @Input() public readonly: boolean;
  public get readonlyState(): boolean {
    return !isNil(this.readonly) && this.readonly !== false;
  }

  @Input() public disabled: boolean;
  get disabledState(): boolean {
    return (!isNil(this.disabled) && this.disabled !== false) || (!isNil(this.disabledSelf) && this.disabledSelf !== false);
  }

  @Input() public id: string = 'code-' + Math.random().toString(36).substring(7);

  private sessionCommandList = [];
  private sessionPreviousCommandIndex = 0;

  public value = '';
  private indent = 4;
  private NEW_LINE = '\n';
  private highlighted = false;
  private waitingForResponse = true;
  public currentAsyncID: string;

  public PROMT = 'PS>';
  public PROM_START_TEXT = 'PS';
  public PROMT_END_TEXT = '>';
  protected ngControl: NgControl;

  constructor(
    @Optional() @Self() ngControl: NgControl,
    protected cd: ChangeDetectorRef,
    private terminalNewPrismService: TerminalNewPrismService,
    private renderer: Renderer2,
    private commandService: CommandService,
    private terminalNewService: TerminalNewService
  ) {
    this.ngControl = ngControl || ({} as NgControl);
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.getOutputResult();
  }

  onChange: (value: string) => void;
  onTouched: () => void;

  writeValue(value: string): void {
    this.value = value;
    this.getIndent(value);
    this.renderHighLight();
    this.cd.detectChanges();
    this.cd.markForCheck();
  }
  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  ngOnInit(): void {
    this.synchronizeScroll();
    this.handleInput();

    this.addStartupTextToTerminal();

    this.getTheProperPromt();

    setTimeout(() => {
      this.wrongScrollFix();
    }, 0);
    this.textArea?.nativeElement?.focus();

    this.waitingForResponse = false;
  }

  addStartupTextToTerminal() {
    if (this.textArea.nativeElement.value.includes('+C to copy text from terminal')) return;

    this.addOutput(`Use ${this.isMac ? 'Cmd' : 'Ctrl'}+C to copy text from terminal,`);
    this.addOutput(`    ${this.isMac ? 'Cmd' : 'Ctrl'}+V to paste`, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes?.hid?.currentValue && changes?.hid?.currentValue !== changes?.hid?.previousValue && changes?.shouldInit?.currentValue) {
      this.terminalNewService
        .init(this.hid)
        .pipe(
          tap((response) => (this.currentAsyncID = this.commandService.getAsyncIdFromResponse(response))),
          take(1)
        )
        .subscribe(noop);
    }

    if (changes?.shouldFocus?.currentValue) {
      this.reFocusTerminal();
    }
  }

  reFocusTerminal() {
    setTimeout(() => {
      const element = this.textArea.nativeElement;
      element.blur();
      element.focus();
    }, 1000);
    this.shouldFocus = false;
  }

  getTheProperPromt() {
    const element = this.textArea.nativeElement;
    const rows = element.value?.split('\n');

    if (rows.length) {
      for (let i = rows.length - 1; i > 0; i--) {
        if (rows[i].search(this.PROM_START_TEXT) === 0 && rows[i].search(this.PROMT_END_TEXT) !== -1) {
          this.PROMT = rows[i].substring(0, rows[i].search(this.PROMT_END_TEXT) + 1);

          break;
        }
      }
    }
  }

  addOutput(value: string, cursorOnNextLine = true) {
    this.value += value + (cursorOnNextLine ? this.NEW_LINE : '');
    this.renderHighLight();
  }

  ngAfterViewInit() {
    if (this.isModal) {
      const el = this.textArea.nativeElement;
      el.addEventListener('blur', () => el.focus());
    }

    this.terminalNewPrismService.highlightAll();
  }

  ngAfterViewChecked() {
    if (this.highlighted) {
      this.terminalNewPrismService.highlightAll();
      this.highlighted = false;
    }
  }

  handleInput() {
    const element = this.textArea.nativeElement;
    fromEvent(element, 'keydown')
      .pipe(untilDestroyed(this))
      .subscribe((event: KeyboardEvent) => {
        this.keyBehavior(event);
        this.renderHighLight();
        this.onTouched();
        this.wrongScrollFix();
      });
  }

  // fixing scroll jump on the last row escape
  private wrongScrollFix() {
    const toTop = this.textArea.nativeElement.scrollTop;
    this.renderer.setProperty(this.pre.nativeElement, 'scrollTop', toTop);
    setTimeout(() => {
      this.renderer.setProperty(this.pre.nativeElement, 'scrollTop', toTop);
    }, 0);
  }

  // show text on Paste function fix
  onPaste(event: ClipboardEvent) {
    this.renderHighLight();
  }

  keyBehavior(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab': {
        event.preventDefault();
        this.setIndent();
        break;
      }
      case 'Enter': {
        event.preventDefault();
        if (this.waitingForResponse) return;

        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          this.value = this.textArea.nativeElement.value + this.NEW_LINE;
          break;
        }

        // value should be updated explicitly
        this.value = this.textArea.nativeElement.value + this.NEW_LINE;
        this.sessionCommandList.push(this.getLastCommand());
        this.sessionPreviousCommandIndex = this.sessionCommandList.length;
        this.sendFullCommand();
        break;
      }
      case 'Backspace': {
        event.preventDefault();
        this.removeIndent();
        break;
      }
      case 'ArrowUp': {
        if (this.isOnLastCommandStartingLine()) {
          event.preventDefault();
          this.loadPreviousCommand();
        }
        break;
      }
      case 'ArrowDown':
        if (this.textArea.nativeElement.start === this.textArea.nativeElement.length) this.loadNextCommand();
        break;
      case 'ArrowLeft': {
        if (this.isNearCommandPromtPart()) event.preventDefault();
        break;
      }
      default:
        null;
    }

    this.onCopyPasteEventHandler(event);
    if (this.waitingForResponse) event.preventDefault();
  }

  onCopyPasteEventHandler(event: KeyboardEvent) {
    const el = this.textArea.nativeElement;
    // Ctrlor Cmd pressed?
    if (event.ctrlKey || event.metaKey) {
      // Ctrl+C or Cmd+C pressed?
      if (([67, 'KeyC'].includes(event.keyCode) || ['c', 'C'].includes(event.key)) && el.selectionStart === el.selectionEnd) {
        // should break command instead of copy
        event.preventDefault();

        this.terminalNewService
          .breakCurrentCommand(this.hid)
          .pipe(
            tap((response) => (this.currentAsyncID = this.commandService.getAsyncIdFromResponse(response))),
            take(1)
          )
          .subscribe(noop);
      }

      if (this.waitingForResponse) {
        event.preventDefault();
        return;
      }

      // Ctrl+V or Cmd+V pressed?
      if ([86, 'KeyV'].includes(event.keyCode) || ['v', 'V'].includes(event.key)) {
        this.moveToLastCommandPromtIfOutOfRange();
      }

      // Ctrl+X or Cmd+X pressed?
      if ([88, 'KeyX'].includes(event.keyCode) || ['x', 'X'].includes(event.key)) {
        event.preventDefault();
      }
    } else {
      this.moveToLastCommandPromtIfOutOfRange();
    }
  }

  getIndent(value: string) {
    const first = value?.split('\n').find((line) => line.match(/^\s/));
    if (first) {
      this.indent = first.length - first.trimStart().length;
    }
  }

  caretIndent() {
    const element: HTMLTextAreaElement = this.textArea.nativeElement;
    const [start, end] = [element.selectionStart, element.selectionEnd];
    const [last] = element.value.substr(0, start).split('\n').slice(-1);
    const count = last.length - last.trimStart().length;

    const indent = last.match(/\{$/) ? this.createIndentString(count + this.indent) : this.createIndentString(count);

    element.setRangeText('\n' + indent, start, end, 'end');
  }

  removeIndent() {
    const element: HTMLTextAreaElement = this.textArea.nativeElement;

    const [start, end] = [element.selectionStart, element.selectionEnd];
    const [prev, last] = element.value.substr(0, start).split('\n').slice(-2);

    switch (true) {
      // Do not remove PROMT part
      case last === this.PROMT:
        break;
      case last?.trim() === this.PROMT:
        break;
      // Do not remove if out of range
      case this.getLastPromtIndex() > start:
        break;
      // End of Line
      case last?.length === 0 && Boolean(prev): {
        element.value = element.value.substring(0, element.value.length - this.NEW_LINE.length);
        break;
      }
      case last?.trimStart().length === 0: {
        element.setRangeText('', start - this.indent, end - 1, 'select');
        break;
      }
      default: {
        element.setRangeText('', start - 1, end, 'start');
      }
    }
  }

  setIndent() {
    const element = this.textArea.nativeElement;
    const [start, end] = [element.selectionStart, element.selectionEnd];
    const indent = this.createIndentString();
    element.setRangeText(indent, start, end, 'end');
  }

  createIndentString(count = this.indent) {
    return new Array(count + 1).join(' ');
  }

  renderHighLight() {
    setTimeout(() => {
      const element = this.textArea.nativeElement;
      const modifiedContent = this.terminalNewPrismService.convertHtmlIntoString(element.value);
      this.onChange(element.value);
      this.renderer.setProperty(this.codeContent.nativeElement, 'innerHTML', modifiedContent);
      this.highlighted = true;
    }, 0);
  }

  private synchronizeScroll() {
    fromEvent(this.textArea.nativeElement, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const toTop = this.textArea.nativeElement.scrollTop;
        const toLeft = this.textArea.nativeElement.scrollLeft;

        this.renderer.setProperty(this.pre.nativeElement, 'scrollTop', toTop);
        this.renderer.setProperty(this.pre.nativeElement, 'scrollLeft', toLeft + 0.2);
      });
  }

  sendFullCommand() {
    this.waitingForResponse = true;
    this.terminalNewService
      .send(this.getLastCommand(), this.hid, this.terminalNewService.createSessionId({ hid: this.hid }))
      .pipe(
        tap((response) => (this.currentAsyncID = this.commandService.getAsyncIdFromResponse(response))),
        take(1)
      )
      .subscribe(noop);
  }

  getLastPromtIndex() {
    return this.textArea.nativeElement.value.lastIndexOf(this.PROMT);
  }

  getLastCommand() {
    const element = this.textArea.nativeElement;
    const rows = element.value?.split('\n');
    const rowsToUse = [];

    if (rows.length) {
      for (let i = rows.length - 1; i > 0; i--) {
        rowsToUse.push(rows[i]);
        if (this.getStringWithoutDoubleSlash(rows[i]).includes(this.PROMT)) {
          break;
        }
      }

      return this.getStringWithoutDoubleSlash(rowsToUse.reverse().join(this.NEW_LINE)).replace(this.PROMT, '');
    }

    return '';
  }

  getStringWithoutDoubleSlash(value = '') {
    return value.replaceAll('\\\\', '\\');
  }

  getCursorEntireLine() {
    const element = this.textArea.nativeElement;

    const [start, end] = [element.selectionStart, element.selectionEnd];
    const [prev, last] = element.value.substr(0, start).split('\n').slice(-2);

    const lineNumber = element.value.substr(0, start).split('\n').length;

    return element.value.split('\n')[lineNumber - 1];
  }

  isOnLastCommandStartingLine() {
    return this.getCursorEntireLine()?.includes(this.PROMT);
  }

  isLastLineEmpty() {
    const lines = this.textArea?.nativeElement?.value?.split(this.NEW_LINE);
    if (!lines?.length) return false;

    return lines[lines.length - 1] === '';
  }

  isNearCommandPromtPart() {
    const element = this.textArea.nativeElement;
    const start = element.selectionStart;

    return element.value.substring(start - this.PROMT.length, start).includes(this.PROMT);
  }

  moveToLastCommandPromtIfOutOfRange() {
    const element = this.textArea.nativeElement;
    element.focus();

    if (!element.value?.includes(this.PROMT)) return;

    const start = element.selectionStart;
    const index = element.value.lastIndexOf(this.PROMT);

    if (index + this.PROMT.length > start) element.setSelectionRange(index + this.PROMT.length, index + this.PROMT.length);

    element.focus();
  }

  getOutputResult() {
    this.terminalNewService
      .getOutputResult()
      .pipe(
        filter((result) => !!result),
        filter((response) => response.MessageId === this.currentAsyncID),
        untilDestroyed(this)
      )
      .subscribe((result) => {
        if (result?.Data?.outType === PowerShellOutType.Completed) {
          this.enableOpenInModal?.emit(true);

          if (!this.isLastLineEmpty()) this.addOutput('');

          this.addOutput(this.getOutputMessageFromResponse(result), false);
          this.PROMT = this.getNewPromtFromMessage(result);
        } else {
          if (!this.isLastLineEmpty()) this.addOutput('');
          this.addOutput(this.getOutputMessageFromResponse(result), false);
        }

        this.renderHighLight();
        this.fixCursorPosition();
        this.waitingForResponse = false;
      });
  }

  getOutputMessageFromResponse(result: any) {
    return result.Data?.outType !== PowerShellOutType.Debug ? result?.Data?.data : this.NEW_LINE;
  }

  // A new promt is the last line of the response with a code #5
  getNewPromtFromMessage(result: any) {
    const outputList = result?.Data?.data?.split(this.NEW_LINE);
    return outputList[outputList.length - 1] ?? this.PROMT;
  }

  fixCursorPosition() {
    const element: HTMLTextAreaElement = this.textArea?.nativeElement;
    if (!element) return;

    const [start, end] = [element.selectionStart, element.selectionEnd];
    this.getLastPromtIndex();
    element.setRangeText('', start, end, 'end');

    setTimeout(() => {
      element.blur();
      element.focus();
    }, 0);
  }

  clearCurrentCommand() {
    const element = this.textArea.nativeElement;
    element.value = element.value.substring(0, this.getLastPromtIndex() + this.PROMT.length);
    this.value = element.value;
  }

  loadPreviousCommand() {
    if (this.sessionPreviousCommandIndex === 0) return;
    this.sessionPreviousCommandIndex--;
    this.loadCommand();
  }

  loadNextCommand() {
    if (this.sessionPreviousCommandIndex >= this.sessionCommandList.length - 1) return;
    this.sessionPreviousCommandIndex++;
    this.loadCommand();
  }

  loadCommand() {
    this.clearCurrentCommand();
    this.addOutput(this.sessionCommandList[this.sessionPreviousCommandIndex] ?? '', false);
  }
}
