<mbs-sidepanel id="password-recovery-sidepanel"
               side="right"
               innerContentType="inner"
               [isCreate]="false"
               [showExpandedCross]="true"
               [loadingData]="!isDataLoaded"
               [showFooter]="false">
  <div header-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-name">
      <span class="ico ico-Lock2"></span> Backup Password Recovery Service
    </div>
  </div>
  <div body>
    <mbs-alert [type]="'danger'"
               *ngIf="showErrorAlert"
               [icon]="true">
      {{ 'pass-recovery:unavailableService' | i18next }}
      <div class="row">
        <div class="col-auto ml-auto">
          <mbs-button (click)="refreshRecoveryStatus()"
                      [icon]="'fa fa-refresh'">
            {{ 'pass-recovery:refreshBtn' | i18next }}
          </mbs-button>
        </div>
      </div>
    </mbs-alert>
    <mbs-alert [type]="'warning'"
               *ngIf="!showErrorAlert"
               [icon]="true">
      The Password Recovery service is&nbsp;only supported for backups created in&nbsp;Backup Agent&nbsp;7.7 for Windows
      or&nbsp;later. The legacy backup format is&nbsp;not supported.
      <a *ngIf="'HelpMarketing' | can: 'read'"
         class="d-inline-block ml-2"
         href="/AP/Help/security/pwd-recovery"
         target="_blank">
        {{'buttons:learnMore' | i18next}}
      </a>
    </mbs-alert>
    <div class="row">
      <div class="col">
        <span [ngClass]="{'text-muted': showErrorAlert}">Backup Password Recovery Service</span>
        <p *ngIf="!showErrorAlert"
           class="mt-2 text-sm sidepanel-password-recovery__line-height-fix">
          <ng-container *ngIf="!switcher.value">
            Backup Password Recovery service provides recovery of&nbsp;forgotten
            encryption passwords for
            backups. Passwords can be&nbsp;recovered for backups created after this service is&nbsp;enabled
          </ng-container>
          <ng-container *ngIf="switcher.value">
            Backup Password Recovery service provides recovery of&nbsp;forgotten encryption passwords for backups
            created after
            <span class="font-weight-bold">
              <mbs-rmm-date-time-format [container]="'body'"
                                        [isProviderTime]="true"
                                        [dateTimeFormat]="dateFormat"
                                        [time]="dateCreated"></mbs-rmm-date-time-format>
            </span>
          </ng-container>
        </p>
      </div>
      <form class="col-auto"
            [formGroup]="form">
        <mbs-switcher formControlName="switcher"
                      [disabled]="showErrorAlert"
                      [name]="'backup-password-service'"></mbs-switcher>
      </form>
    </div>
  </div>
</mbs-sidepanel>
