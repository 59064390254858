import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComputerAuthorizationModalComponent } from '@components/computers-modal/computer-authorization/computer-authorization-modal.component';
import { ComputerAuthorizationModalMode } from '@components/computers-modal/computer-authorization/computer-authorization-modal.constants';
import { HostInfoRow } from '@components/computers-shared/get-general-host-Info';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import Computer, { AgentType } from '@models/Computer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComputerAgentWrapper } from '@wrappers/computer.agent.wrapper';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, MbsSize, ModalService, TabsetItemDirective } from 'mbs-ui-kit';
import { Observable, tap } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { getComputerGeneralInfo } from './general-tab.helpers';

@UntilDestroy()
@Component({
  selector: 'mbs-general-tab',
  templateUrl: './general-tab.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralTabComponent implements OnInit {
  public readonly getComputerGeneralInfo = getComputerGeneralInfo;
  public readonly mbsSize = MbsSize;

  @Input() fullscreen: boolean;

  public hasAgent$: Observable<boolean>;
  public hostInfo$: Observable<HostInfoRow[]>;
  public loaded$: Observable<boolean>;
  public computer: Computer;
  public AlertType = MbsPopupType;

  constructor(
    private tabsetItem: TabsetItemDirective,
    private facade: ComputerBackupFacade,
    private cdRef: ChangeDetectorRef,
    private agentWrapper: ComputerAgentWrapper,
    private modalService: ModalService,
    public i18nPipe: I18NextPipe
  ) {}

  ngOnInit(): void {
    this.initStream();
  }

  initStream(): void {
    this.hasAgent$ = this.facade.hasBackupAgent$;
    this.hostInfo$ = this.facade.hostInfo$.pipe(tap(() => this.cdRef.markForCheck()));
    this.loaded$ = this.facade.hostInfoLoaded$;
    this.facade.computer$.pipe(filter(Boolean), untilDestroyed(this)).subscribe((computer) => (this.computer = computer));

    this.facade.hostInfoLoading$
      .pipe(
        tap((value) => queueMicrotask(() => (this.tabsetItem.loading = value))),
        untilDestroyed(this)
      )
      .subscribe();
  }

  handleInstall(): void {
    this.facade.computer$.pipe(first()).subscribe((computer) => this.agentWrapper.handleInstallAgent(computer, AgentType.Backup));
  }

  handleRefresh(): void {
    this.facade.loadGeneralInfo({ force: true });
  }

  public editUser() {
    const modal = this.modalService.openRef(ComputerAuthorizationModalComponent);
    modal.componentInstance.computer = this.computer;
    this.computer.userAccount?.id && (modal.componentInstance.mode = ComputerAuthorizationModalMode.Edit);
  }
}
