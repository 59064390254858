<div class="mbs-form-group mb-0">
  <mbs-advanced-search [accessUrlQuery]="false"
                       [(ngModel)]="model"
                       (search)="updateFilters($event)"
                       (input)="handleInputSearch($event)">
  </mbs-advanced-search>
</div>

<mbs-table-nav [switcherView]="false"
               (pageChange)="handlePageChange($event)"
               (pageSizeChange)="handlePageSizeChange($event)"
               [showRefreshButton]="true"
               [showExportToCSVButton]="isModal"
               [exportButtonName]="'buttons:exportToCSV' | i18next"
               (export)="export(getTableName())"
               (refresh)="fetchData()">

  <ng-template mbsTableFilter="label">
    {{ 'rmm-side-panel:hotfixesInstalledTab.installedUpdates' | i18next }} {{ paginationOptions.dataSize }}
  </ng-template>

  <ng-template mbsTableControl="append"
               *ngIf="!isModal && !isMacOS">
    <mbs-modal-tab-content modalTitle="{{ 'rmm-side-panel:hotfixesInstalledTab.installedUpdatesModalTitle' | i18next }}"
                           [showExpandedCross]="true">
      <ng-template>
        <mbs-hotfixes-installed-tab [hid]="hid"
                                    [computerName]="computerName"
                                    [isOnline]="isOnline"
                                    [isModal]="true"></mbs-hotfixes-installed-tab>
      </ng-template>
    </mbs-modal-tab-content>
  </ng-template>

</mbs-table-nav>

<mbs-table-grid class="ph-no-capture"
                [loading]="loading"
                [headers]="headers"
                [data]="data"
                (sort)="handleSort($event)"
                [changeSortState]="orderBy"
                [switcherView]="false"
                [paginationOptions]="paginationOptions"
                [myTrackBy]="trackBy"
                maxHeight="{{ isModal || !isOnline ? '65vh' : '56vh' }}"
                [rotateSequence]="rotateSequence"
                [showNav]="false"
                [viewMode]="sharedPersistentStateEnum.table"
                [disableViewSwitch]="true"
                [noDataMessage]="'rmm-side-panel:noDataProvided' | i18next">

  <ng-template mbsTableCell
               *ngFor="let header of headers; let last = last"
               let-item>


    <ng-template #tooltipOverflow>

      <div placement="auto"
           [ngbTooltip]="getTooltip(item[header.sort])"
           container="body"
           tooltipClass="tooltip-lg text-left"
           class="text-overflow">

        <ng-container [ngSwitch]="header.sort">
          <ng-container *ngSwitchCase="'severityScore'">
            {{ !!item[header.sort] ? item.severity : 'rmm-side-panel:noInfo' | i18next }}
          </ng-container>

          <ng-container *ngSwitchCase="'installedOn'">
            {{ getDateInFormat(item[header.sort]) }}
          </ng-container>

          <ng-container *ngSwitchCase="'categories'">
            {{ !!item[header.sort] && item[header.sort].length > 0 ? item[header.sort][0] : '' }}
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{ !!item[header.sort] ? item[header.sort] : 'rmm-side-panel:noInfo' | i18next }}
          </ng-container>
        </ng-container>

      </div>
    </ng-template>

    <ng-container *ngIf="!header.overflow; else tooltipOverflow">
      <ng-container>
        {{ !!item[header.sort] ? item[header.sort] : 'rmm-side-panel:noInfo' | i18next }}
      </ng-container>
    </ng-container>

  </ng-template>

</mbs-table-grid>
