import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import Administrator from '@models/Administrator';
import { AgentType, convertAgentTypeToNeededCase } from '@models/Computer';
import { ComputerBasedModal } from '@models/ComputersModals';
import { PermissionsEnum } from '@models/PermissionsEnum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { AbilityService } from 'ability';
import { I18NextPipe, I18NextService } from 'angular-i18next';
import { MbsPopupType, ModalComponent, Toast, ToastService } from 'mbs-ui-kit';
import { noop, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-computers-uninstall-agent-modal',
  templateUrl: './computers-uninstall-agent-modal.component.html'
})
export class ComputersUninstallAgentModalComponent implements ComputerBasedModal, OnInit {
  public readonly AgentType = AgentType;
  public readonly alertType = MbsPopupType;
  public computer: any;
  public computerName = '';
  public loading = false;
  public currentUser: Administrator;

  public get isPluralText(): boolean {
    return this.computer && this.computer.apps && this.computer.apps.length > 1;
  }

  public get title(): string {
    return (
      this.i18nPipe.transform(`uninstall-agent-modal:${this.isPluralText ? 'titlePlural' : 'title'}`, { format: 'title' }) +
      this.computerName
    );
  }

  @ViewChild(ModalComponent, { static: true }) private baseModal;

  public uninstallAgentForm: UntypedFormGroup = new UntypedFormGroup({
    agentList: new UntypedFormGroup(
      {
        backup: new FormControl(false),
        rmm: new FormControl(false),
        ra: new FormControl(false),
        deepInst: new FormControl(false)
      },
      [Validators.required, this.agentListValidator.bind(this)]
    )
  });

  constructor(
    private auth: AuthService,
    private facade: ComputersFacade,
    private toastService: ToastService,
    public i18nextService: I18NextService,
    public i18nPipe: I18NextPipe,
    private ability: AbilityService
  ) {}

  ngOnInit(): void {
    this.auth.currentUser.pipe(untilDestroyed(this)).subscribe((user) => (this.currentUser = user));
    this.computerName = this.computer.displayName && this.computer.displayName !== '' ? this.computer.displayName : this.computer.name;
  }

  /*
   * Send computer data + AppsId for uninstall agent(s)
   */
  handleResolve(): void {
    const appsIds = this.prepareAppsId(this.uninstallAgentForm.get('agentList').value);
    if (!appsIds) return;
    this.loading = true;

    this.facade
      .uninstallAgents(this.computer, appsIds)
      .pipe(
        catchError(() => {
          this.loading = false;
          return of(false);
        })
      )
      .subscribe(
        (results) => {
          for (const result of results) {
            if (!result) continue;
            this.handleShowToast(result);
          }
          this.baseModal.close();
        },
        noop,
        () => {
          this.loading = false;
        }
      );
  }

  /*
   * Validation for the presence of at least one agent
   * @param agentList
   * @private
   */
  private agentListValidator(agentList: UntypedFormGroup): ValidationErrors | null {
    const Backup = agentList.get('backup').value;
    const RMM = agentList.get('rmm').value;
    const RA = agentList.get('ra').value;
    const DeepInst = agentList.get('deepInst').value;

    if (Backup || RMM || RA || DeepInst) {
      return null;
    }

    return { agent: { message: 'Not any agent type selected' } };
  }

  /*
   * Prepare appsId as a string for before sending the request(PUT) to server
   * @param agentList
   * @private
   */
  private prepareAppsId(agentList: any): string[] {
    return Object.entries(agentList)
      .filter(([, value]) => !!value)
      .map(([key]) => key.toLowerCase());
  }

  /*
   * Check the computer has agent (application) by applicationId
   * @param agentType
   */
  public isAgentTypeByApplicationID(agentType: AgentType): boolean {
    return this.computer.apps.some((app) => app.applicationId.toLowerCase() === agentType);
  }

  /**
   * Check if permission is available
   * @return {boolean}
   * @private
   */
  public isDeepInstinctDisabled(): boolean {
    return !this.ability.can('read', PermissionsEnum.ShowDeepInstinct) || !this.isAgentTypeByApplicationID(AgentType.DeepInst);
  }

  private handleShowToast(result: any): void {
    this.toastService.toast(
      new Toast({
        header: this.i18nPipe.transform('uninstall-agent-modal:uninstallAgent'),
        content: result.ok
          ? `Uninstalling ${convertAgentTypeToNeededCase(result.appId)} agent has been started...`
          : `${result.errorDescription}`,
        type: result.ok ? MbsPopupType.success : MbsPopupType.danger,
        icon: true
      })
    );
  }
}
