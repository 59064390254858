import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ButtonType } from '../button';
import { isTemplate, MbsSize } from '../utils';
import { ModalComponent, ModalSize } from './modal.component';
import { isNil } from 'lodash';

class Button {
  show?: boolean;
  text?: string;
  type?: ButtonType;
  loading$?: Observable<boolean>;
  disabled$?: Observable<boolean>;
}

class Header {
  title?: string;
  headerCustomClasses?: string;
  textOverflow?: boolean;
  icon?: string;
  size?: MbsSize.sm | MbsSize.lg;
  showCloseCross?: boolean;
  showExpandedCross?: boolean;
}

class Footer {
  show?: boolean;
  okButton?: Button;
  cancelButton?: Button;
}

export class ModalSettings {
  header?: Header | TemplateRef<any> | string;
  container?: string;
  size?: ModalSize;
  responsive?: boolean;
  footer?: Footer;
  beforeDismiss?: () => boolean | Promise<boolean>;
  data?: any;
  backdrop?: string;
  keyboard?: boolean;
  isCustom?: boolean = false;
  collapsing?: boolean = false;
  titleTextOverflow?: boolean = false;
  freezeBody?: boolean = false;
}

@Injectable()
export class ModalService {
  private readonly body: HTMLElement;
  private renderer: Renderer2;
  private randomString: string;
  private overflow = 'overflow-y: auto;';

  constructor(private modalService: NgbModal, private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.body = this.document.body;
  }

  private getRandomString(): string {
    return Math.random().toString(36).substr(2, 10);
  }

  /**
   * Open confirm modal
   * @param {ModalSettings} settings
   * @param {string} message
   * @return {Promise<boolean>}
   */
  public confirm(settings: ModalSettings, message: string): Promise<boolean> {
    this.randomString = this.getRandomString();
    const options = this.getOptions(settings);
    if (settings) {
      options.size = this.getSizeClass(settings.size, settings.responsive);
    }
    const modalRef = this.modalService.open(ModalComponent, options);
    this.setSettings(modalRef.componentInstance as ModalComponent, settings);

    (modalRef.componentInstance as ModalComponent).message = message;
    if (settings && settings.container && settings.container !== 'body') {
      this.restoreDocumentScroll();
    }
    if (!settings?.freezeBody && (!settings || !settings.container || settings.container === 'body')) {
      this.renderer.setAttribute(this.body, 'style', this.overflow);
    }
    this.toggleOptionCollapsing(settings && settings.collapsing);
    return modalRef.result as Promise<boolean>;
  }

  /**
   * Open common modal.
   * @param settings modal settings
   * @param body custom template
   */
  public open(settings: ModalSettings, body: TemplateRef<any> | string): Promise<boolean>;
  public open<TResult>(settings: ModalSettings, body: TemplateRef<any> | string): Promise<TResult> {
    this.randomString = this.getRandomString();
    const modalRef = this.modalService.open(ModalComponent, this.getOptions(settings));
    this.setSettings(modalRef.componentInstance as ModalComponent, settings);
    (modalRef.componentInstance as ModalComponent).body = body;

    if (settings && settings.container && settings.container !== 'body') {
      this.restoreDocumentScroll();
    }
    if (!settings?.freezeBody && (!settings || !settings.container || settings.container === 'body')) {
      this.renderer.setAttribute(this.body, 'style', this.overflow);
    }
    this.toggleOptionCollapsing(settings && settings.collapsing);
    return modalRef.result as Promise<TResult>;
  }

  /**
   * Open custom modal
   * @param {any}content
   * @param {ModalSettings}settings
   * @return {Promise<TResult>}
   */
  public openCustom<TResult>(content: any, settings?: ModalSettings): Promise<TResult> {
    this.randomString = this.getRandomString();
    this.toggleOptionCollapsing(settings && settings.collapsing);
    return this.openRef(content, settings).result as Promise<TResult>;
  }

  /**
   * Open wizard modal with settings
   * @param {any} content Wizard component or template
   * @param {NgbModalOptions} settings Modal settings.
   * @return {Promise<TResult>}
   */
  public openWizard<TResult>(content: any, settings?: NgbModalOptions): Promise<TResult> {
    const wizardSettings: ModalSettings = {
      size: MbsSize.lg,
      backdrop: 'static',
      keyboard: false
    };

    return this.openCustom<TResult>(content, Object.assign(wizardSettings, settings));
  }

  /**
   * Open modal with settings.
   * Return link an modal instance
   * @param {any} content
   * @param {ModalSettings} settings
   * @return {NgbModalRef}
   */
  public openRef(content: any, settings?: ModalSettings): NgbModalRef {
    this.randomString = this.getRandomString();

    if (!settings) {
      settings = new ModalSettings();
    }
    settings.isCustom = true;
    const modalRef = this.modalService.open(content, this.getOptions(settings));

    if (settings && settings.container && settings.container !== 'body') {
      this.restoreDocumentScroll();
    }

    if (!settings?.freezeBody && (!settings || !settings.container || settings.container === 'body')) {
      this.renderer.setAttribute(this.body, 'style', this.overflow);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.setSettings((modalRef.componentInstance.baseModal || modalRef.componentInstance) as ModalComponent, settings);
    this.toggleOptionCollapsing(settings && settings.collapsing);

    return modalRef;
  }

  /**
   * Dismisses all currently displayed modal windows with the supplied reason.
   */
  public dismissAll(): void {
    this.modalService.dismissAll();
  }

  /**
   * Indicates if there are currently any open modal windows in the application.
   * @return {boolean}
   */
  public hasOpenModals(): boolean {
    return this.modalService.hasOpenModals();
  }

  private getOptions(settings: ModalSettings): NgbModalOptions {
    const options: NgbModalOptions = { keyboard: true, backdrop: 'static', centered: true, beforeDismiss: () => true };
    const randomModalClass = settings && settings.collapsing ? 'modal-' + this.randomString : '';
    if (settings && settings.size) {
      options.size = this.getSizeClass(settings.size, settings.responsive);
    }
    if (settings && settings.container) {
      options.container = settings.container;
      options.backdropClass = 'modal-backdrop-inside';
      options.windowClass = `modal-inside mbs-modal ${randomModalClass}`;
      options.beforeDismiss = settings.beforeDismiss ? settings.beforeDismiss : () => true;
    } else {
      options.windowClass = randomModalClass;
    }
    return options;
  }

  private setSettings(component: ModalComponent, settings: ModalSettings): void {
    if (!settings) {
      settings = new ModalSettings();
    }
    component.header = settings.header;
    // conditions for support Header types
    if (settings.header && typeof settings.header === 'object' && !isTemplate(settings.header)) {
      const header = settings.header as Header;
      component.title = header.title;
      component.titleIconClass = header.icon;
      component.headerSize = header.size;
      component.showExpandedCross = isNil(header.showExpandedCross) ? component.showExpandedCross : header.showExpandedCross;
      component.showCloseCross = isNil(header.showCloseCross) ? component.showCloseCross : header.showCloseCross;
      component.titleTextOverflow = header.textOverflow;
    }
    if (settings.footer) {
      component.showFooter = settings.footer.show !== undefined ? !!settings.footer.show : true;
    }

    component.size = settings.size;
    component.responsive = settings.responsive;

    if (settings.footer && settings.footer.okButton) {
      component.okButtonText = settings.footer.okButton.text || 'OK';
      component.okButtonDisabled$ = settings.footer.okButton.disabled$;
      component.okButtonLoading$ = settings.footer.okButton.loading$;
      component.okButtonType = settings.footer.okButton.type || 'primary';
      const okShow = settings.footer.okButton.show;
      component.showOkButton = isNil(okShow) ? true : okShow;
    } else {
      component.okButtonText = 'OK';
      component.showOkButton = true;
    }

    if (settings.footer && settings.footer.cancelButton) {
      component.cancelButtonText = settings.footer.cancelButton.text || 'Cancel';
      const cancelShow = settings.footer.cancelButton.show;
      component.showCancelButton = isNil(cancelShow) ? true : cancelShow;
    } else {
      component.cancelButtonText = 'Cancel';
      component.showCancelButton = true;
    }
    if (settings.data) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      component.data = settings.data;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      component.context = settings.data.context;
    }
    component.isCustomModal = settings.isCustom;
    component.collapsing = settings.collapsing;
  }

  private getSizeClass(size: string, responsive?: boolean): string {
    let tmp = '';
    if (responsive) {
      tmp += 'res-';
    }
    return tmp + size;
  }

  private restoreDocumentScroll(): void {
    this.renderer.removeClass(this.body, 'modal-open');
    this.renderer.setAttribute(this.body, 'style', '');
  }

  private toggleOptionCollapsing(condition): void {
    setTimeout(() => {
      const $modalContent = this.body.querySelector(`.modal-${this.randomString} .modal-content`);
      if (condition) {
        this.renderer.addClass($modalContent, '-collapsing');
      }
    }, 0);
  }
}
