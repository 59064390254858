import * as TerminalCacheReducer from './store/terminal-cache.reducer';
import * as TerminalSessionReducer from './store/terminal-sessions.reducer';

import { TerminalCacheKey, TerminalSessionKey } from './store/const';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ComputersAbstractService, ComputersService } from '@services/computers.service';
import { UtilsModule } from '@utils/utils.module';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { NgTerminalModule } from 'ng-terminal';
import { ModalTerminalComponent } from './components/modal-terminal.component';
import { NgTerminalWrapperComponent } from './components/ng-terminal-wrapper.component';
import { TerminalComponent } from './components/terminal.component';
import { MbsModalTerminalDirective } from './directives/mbs-modal-terminal.directive';
import { NgTerminalHostDirective } from './directives/ng-terminal-host.directive';
import { TerminalEffects } from './store/terminal.effects';
import { TerminalEmulatorService } from './terminal-emulator.service';

const components = [NgTerminalWrapperComponent, TerminalComponent, ModalTerminalComponent];

@NgModule({
  declarations: [...components, NgTerminalHostDirective, MbsModalTerminalDirective],
  exports: components,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MbsUiKitModule,
    NgTerminalModule,
    UtilsModule,
    StoreModule.forFeature(TerminalCacheKey, TerminalCacheReducer.reducer),
    StoreModule.forFeature(TerminalSessionKey, TerminalSessionReducer.reducer),
    EffectsModule.forFeature([TerminalEffects])
  ],
  providers: [TerminalEmulatorService, CommandService, { provide: ComputersAbstractService, useClass: ComputersService }]
})
export class TerminalEmulatorModule {}
