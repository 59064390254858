import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { ImmutabilityDeleteModalComponent } from './immutability-delete-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, MbsUiKitModule],
  declarations: [ImmutabilityDeleteModalComponent],
  exports: [ImmutabilityDeleteModalComponent]
})
export class ImmutabilityDeleteModalModule {
  constructor() {}
}
