import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AgentType, ComputersHealthFilterType } from '@models/Computer';
import Brand from '@models/Brand';
import { Build, BuildOsType, getSupportCompanyMinimalVersion } from '@models/build';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WizardComponent } from 'mbs-ui-kit';
import { tap } from 'rxjs/operators';
import { BrandsService } from '@services/brands.service';
import { filter, Observable } from 'rxjs';
import { BuildsFacade } from '@facades/builds.facade';
import { BuildType } from '@models/BuildType.enum';
import { WizardForm } from '@components/add-computer/add-computer.types';
import Company from '@models/Company';
import { InstallationMode } from '@components/add-computer/add-computer.constants';
import { getVersionNumeralRepresentation } from '@utils/version-numeral-representation';

@UntilDestroy()
@Component({
  selector: 'add-computer-wizard',
  templateUrl: './add-computer.component.html'
})
export class AddComputerComponent implements OnInit {
  public readonly agentType = AgentType;
  public readonly location = location;

  public mode: AgentType.Backup | AgentType.RMM | AgentType.RA;
  public loading = true;
  public availableProducts: Set<AgentType> = new Set();
  public osTypes = [BuildOsType.Windows, BuildOsType.Linux, BuildOsType.Mac];
  public availableOsTypes = this.osTypes;
  public wizardForm: WizardForm = this.fb.group({
    os: [BuildOsType.Windows, [Validators.required]],
    agentType: [null as AgentType, [Validators.required]],
    companyId: [null as Company['id']],
    mode: [InstallationMode.Manual]
  });
  public builds: Build[];
  public displayedBuilds: Build[];
  public licensedBrandList: Brand[];
  public updateHealthyFilters: (type: ComputersHealthFilterType) => void;
  public showPendingFilterButton = true;
  public networkDiscoveryBuild: Build = null;
  public showCompanyUnsupportedAlert = false;

  @ViewChild('downloadStep', { static: true }) downloadStep;
  @ViewChild(WizardComponent, { static: true }) wizard: WizardComponent;

  constructor(
    private fb: FormBuilder,
    private brandsService: BrandsService,
    private buildsFacade: BuildsFacade
  ) {}

  ngOnInit() {
    this.initBrands().subscribe();

    this.buildsFacade.builds$.pipe(
      untilDestroyed(this),
      filter(Boolean)
    ).subscribe(builds => {
      this.builds = builds;
      this.networkDiscoveryBuild = builds.find(({ mbsBuildType }) => mbsBuildType === BuildType.NetworkDiscovery);
      this.updateDisplayedBuilds();

      if (this.loading) {
        this.setAvailableFormValues();
        this.initFormSubscriptions();
        this.setDefaultFormValues();
        this.loading = false;
      }
    })
  }

  public updateDisplayedBuilds(): void {
    const { agentType, os } = this.wizardForm.value;
    this.displayedBuilds = this.getDisplayedBuilds({ builds: this.builds, agentType, os});

    this.showCompanyUnsupportedAlert = !this.displayedBuilds.some((build) => {
      const isPublicVersionSupportCompany =
        getVersionNumeralRepresentation(build?.public?.version, 6) >= getSupportCompanyMinimalVersion(build.mbsBuildType);
      const isSandboxVersionSupportCompany = getVersionNumeralRepresentation(build?.public?.version, 6) >= getSupportCompanyMinimalVersion(build.mbsBuildType);

      return isPublicVersionSupportCompany || isSandboxVersionSupportCompany;
    })
  }

  public getDisplayedBuilds({ builds, agentType, os }): Build[] {
    return builds.filter((build) => {
      if (agentType === AgentType.RA) return build.applicationId === agentType;

      return build.applicationId === agentType && build.buildOs === os;
    });
  }

  private initBrands(): Observable<Brand[]> {
    return this.brandsService
      .getActive()
      .pipe(
        tap((brandList) => {
          this.licensedBrandList = brandList;
        })
      )
  }

  private initFormSubscriptions(): void {
    this.wizardForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateDisplayedBuilds();
    });

    this.wizardForm.get('agentType').valueChanges.pipe(untilDestroyed(this)).subscribe((agentType) => {
      this.availableOsTypes = agentType === AgentType.RA ? this.osTypes.filter((type) => type === BuildOsType.Windows) : this.osTypes;
      agentType === AgentType.RA && this.wizardForm.get('os').patchValue(BuildOsType.Windows);
    });
  }

  private setAvailableFormValues(): void {
    this.builds.forEach((build) => {
      this.availableProducts.add(build.applicationId as AgentType);
    });
  }

  private setDefaultFormValues(): void {
    this.wizardForm.get('agentType').patchValue(this.getDefaultAgentType());
  }

  private getDefaultAgentType(): AgentType {
    if (this.mode === AgentType.Backup) {
      return AgentType.Backup;
    }

    if (this.mode === AgentType.RMM) {
      return AgentType.RMM;
    }

    return AgentType.RA;
  }
}
