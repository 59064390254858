import { AdvancedSearchModel, SortEvent } from 'mbs-ui-kit';
import {ScriptLibraryAccessLevel, ScriptLibraryEntry, ScriptLibraryTag, ScriptLibraryTransfer} from './script-library.model';
import { createAction, props } from '@ngrx/store';

import { FeatureKeyPrefix } from './const';
import { Update } from '@ngrx/entity';

export const catchError = createAction(`${FeatureKeyPrefix} Error`, props<{ error: any }>());
export const registerScript = createAction(`${FeatureKeyPrefix} Register Script`, props<{ transfer: ScriptLibraryTransfer }>());
export const updateScript = createAction(
  `${FeatureKeyPrefix} Update Script`,
  props<{ id: ScriptLibraryEntry['id']; transfer: ScriptLibraryTransfer }>()
);
export const callEditor = createAction(`${FeatureKeyPrefix} Call Scripts Editor`, props<{ id: ScriptLibraryEntry['id'] }>());

export const deleteScript = createAction(`${FeatureKeyPrefix} Delete Script`, props<{ id: ScriptLibraryEntry['id'] }>());

export const sortScriptsInLibrary = createAction(`${FeatureKeyPrefix} Sort Scripts in Library`, props<{ sort: SortEvent }>());
export const filterScriptsInLibrary = createAction(
  `${FeatureKeyPrefix} Filter Scripts in Library`,
  props<{ filters: AdvancedSearchModel }>()
);

export const loadScriptsInLibrary = createAction(`${FeatureKeyPrefix} Load Scripts in Library`);
export const loadScriptBody = createAction(`${FeatureKeyPrefix} Load Script Body`, props<{ scriptGuid: string }>());
export const addScripIntLibrary = createAction(`${FeatureKeyPrefix} Add Script in Library`, props<{ script: ScriptLibraryEntry }>());

export const upsertScriptInLibrary = createAction(`${FeatureKeyPrefix} Upsert Script in Library`, props<{ script: ScriptLibraryEntry }>());
export const upsertScriptBody = createAction(
  `${FeatureKeyPrefix} Upsert Script Body`,
  props<{ body: ScriptLibraryEntry['body']; scriptGuid: string }>()
);

export const addScriptsInLibrary = createAction(`${FeatureKeyPrefix} Add Scripts in Library`, props<{ scripts: ScriptLibraryEntry[] }>());

export const loadTags = createAction(`${FeatureKeyPrefix} Load Tags`)
export const addTag = createAction(`${FeatureKeyPrefix} Add Tag`, props<{tag: ScriptLibraryTag}>())
export const upsertTags = createAction(`${FeatureKeyPrefix} Upsert Tags`, props<{tags: ScriptLibraryTag[]}>())
export const registerTag = createAction(`${FeatureKeyPrefix} Register Tag`, props<{tagName: string}>())
export const deleteTag = createAction(`${FeatureKeyPrefix} Delete Tag`, props<{tagGuid: string}>())

export const upsertScripsInLibrary = createAction(
  `${FeatureKeyPrefix} Upsert Scripts in Library`,
  props<{ scripts: ScriptLibraryEntry[] }>()
);

export const updateScriptLibrary = createAction(
  `${FeatureKeyPrefix} Update Script in Library`,
  props<{ script: Update<ScriptLibraryEntry> }>()
);

export const updateScriptsInLibrary = createAction(
  `${FeatureKeyPrefix} Update Scripts in Library`,
  props<{ scripts: Update<ScriptLibraryEntry>[] }>()
);

export const deleteScriptInLibrary = createAction(`${FeatureKeyPrefix} Delete Script in Library`, props<{ id: string }>());
export const deleteTagInLibrary = createAction(`${FeatureKeyPrefix} Delete Tag in Library`, props<{ tagGuid: string }>());

export const deleteScriptsInLibrary = createAction(`${FeatureKeyPrefix} Delete Scripts in Library`, props<{ ids: string[] }>());
export const clearFilters = createAction(`${FeatureKeyPrefix} Clear Filters`);
export const clearScriptLibrary = createAction(`${FeatureKeyPrefix} Clear Library`);
