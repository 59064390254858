// @TODO rename to PlanType and move to computers shared
export enum PlanTypes {
  NA = 0,
  Plan = 1,
  RestorePlan = 2,
  ExchangePlan = 3,
  DataBasePlan = 4,
  RestoreDataBasePlan = 5,
  BareMetalPlan = 6,
  RestoreBareMetalPlan = 7,
  RestoreSystemState = 8,
  RestoreExchangePlan = 9,
  RestoreDatabaseFilesPlan = 10,
  BackupVMware = 11,
  RestoreVMware = 12,
  BackupHyperV = 13,
  RestoreHyperV = 14,
  ConsistencyCheck = 15,
  BackupDiskImagePlan = 16,
  RestoreDiskImagePlan = 17,
  BackupSystemState = 18,
  CloudVMBackupEC2Plan = 19,
  CloudVMRestoreEC2Plan = 20
}

export enum PlanTypesDisplay {
  'Exchange Backup Plan' = 3,
  'Restore Exchange Plan' = 9,
  'VMware Backup Plan' = 11,
  'VMware Restore Plan' = 12,
  'Hyper-V Backup Plan' = 13,
  'Hyper-V Restore Plan' = 14,
  'Consistency Check Plan' = 15,
  'Image-Based Backup Plan' = 16,
  'Files Backup Plan' = 1,
  'Files Restore Plan' = 2,
  'Image-Based Restore Plan' = 17,
  'EC2 Backup Plan' = 19,
  'EC2 Restore Plan' = 20,
  'MS SQL Backup Plan' = 4,
  'MS SQL Files Restore Plan' = 10,
  'MS SQL Database Restore Plan' = 5,
  'Backup System State' = 18,
  'Restore System State' = 8,
  'Bare Metal Plan' = 6,
  'Restore Bare Metal Plan' = 7,
  'Cloud VM Backup EC2 Plan' = 19,
  'Cloud VM Restore EC2 Plan' = 20,
  'Unknown' = 0
}

export enum UnableToCreateCBFPlanTypes {
  RestorePlan = PlanTypes.RestorePlan,
  BareMetalPlan = PlanTypes.BareMetalPlan,
  RestoreBareMetalPlan = PlanTypes.RestoreBareMetalPlan,
  BackupVMware = PlanTypes.BackupVMware,
  RestoreVMware = PlanTypes.RestoreVMware,
  BackupHyperV = PlanTypes.BackupHyperV,
  RestoreHyperV = PlanTypes.RestoreHyperV,
  RestoreDiskImagePlan = PlanTypes.RestoreDiskImagePlan,
  BackupSystemState = PlanTypes.BackupSystemState
}

export enum OldPagePlanTypes {
  ExchangePlan = PlanTypes.ExchangePlan,
  RestoreDataBasePlan = PlanTypes.RestoreDataBasePlan,
  RestoreSystemState = PlanTypes.RestoreSystemState,
  RestoreExchangePlan = PlanTypes.RestoreExchangePlan,
  RestoreDatabaseFilesPlan = PlanTypes.RestoreDatabaseFilesPlan,
  RestoreVMware = PlanTypes.RestoreVMware,
  RestoreHyperV = PlanTypes.RestoreHyperV,
  ConsistencyCheck = PlanTypes.ConsistencyCheck,
  BackupSystemState = PlanTypes.BackupSystemState,
  CloudVMRestoreEC2Plan = PlanTypes.CloudVMRestoreEC2Plan
}

export enum ImagesOrPlanTypes {
  Plan = PlanTypes.Plan,
  RestorePlan = PlanTypes.RestorePlan,
  BackupDiskImagePlan = PlanTypes.BackupDiskImagePlan,
  RestoreDiskImagePlan = PlanTypes.RestoreDiskImagePlan
}

export enum VMPlanTypes {
  BackupVMware = PlanTypes.BackupVMware,
  RestoreVMware = PlanTypes.RestoreVMware,
  BackupHyperV = PlanTypes.BackupHyperV,
  RestoreHyperV = PlanTypes.RestoreHyperV
}

export enum LastInOrderPlanTypes {
  ConsistencyCheck = PlanTypes.ConsistencyCheck,
  RestoreSystemState = PlanTypes.RestoreSystemState,
  BackupSystemState = PlanTypes.BackupSystemState
}

export enum PlanCreateCommands {
  'newexch' = PlanTypes.ExchangePlan,
  'newrestore' = PlanTypes.RestorePlan,
  'newconsistencycheck' = PlanTypes.ConsistencyCheck,
  'newvmwarerestore' = PlanTypes.RestoreVMware,
  'newhypervrestore' = PlanTypes.RestoreHyperV,
  'newrestoresystemstate' = PlanTypes.RestoreSystemState,
  'newrestoresql' = PlanTypes.RestoreDataBasePlan,
  'newfilesqlrestore' = PlanTypes.RestoreDatabaseFilesPlan
}

export enum PlanFormatTypes {
  CBF = 0,
  NBF = 1
}

export enum PlanFormatTypeNames {
  LBF = 'Legacy Backup Format',
  NBF = 'New Backup Format'
}

export enum PlanFormatTypeShortNames {
  LBF = 'LBF',
  NBF = 'NBF'
}

export const PlanFormatAdapter: { planFormat: PlanFormatTypes; shortName: PlanFormatTypeShortNames; fullName: PlanFormatTypeNames }[] = [
  { planFormat: PlanFormatTypes.CBF, shortName: PlanFormatTypeShortNames.LBF, fullName: PlanFormatTypeNames.LBF },
  { planFormat: PlanFormatTypes.NBF, shortName: PlanFormatTypeShortNames.NBF, fullName: PlanFormatTypeNames.NBF }
];

export enum PlanMode {
  create = 0,
  edit = 1
}
