import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import AccountManager from '@models/AccountManager';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportPortalService {
  private readonly controllerPath = 'api/support-portal';
  public readonly photoPath = `${this.controllerPath}/get-photo?urlParams=`;

  constructor(private http: HttpClient) {}

  getAccountManager(): Observable<AccountManager> {
    return this.http.get<AccountManager>(`${this.controllerPath}/get-account-manager`);
  }
}
