import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { PaymentDebtModalComponent } from '@components/payment-debt-modal/payment-debt-modal.component';
import { StorageAccountState } from '@models/storage-accounts/StorageAccountState';
import { UnifiedBillingAccount, UnifiedBillingAccountType } from '@models/storage-accounts/UnifiedBillingAccount';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { StorageAccountsService } from '@services/storage-accounts/storage-accounts.service';
import { POST_PAYMENT_TOKEN } from '@shared/components/licenses/tokens/post-payment.token';
import { I18NextService } from 'angular-i18next';
import { MbsSize, ModalComponent, ModalService } from 'mbs-ui-kit';
import * as moment from 'moment';
import { noop, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UbSubscribeService } from '../ub-subscribe.service';

@UntilDestroy()
@Component({
  selector: 'mbs-ub-cancel-deletion',
  templateUrl: './ub-cancel-deletion.component.html',
  styles: []
})
export class UbCancelDeletionComponent implements OnInit {
  public storageAccount: UnifiedBillingAccount;
  public loading = false;

  public StorageAccountState = StorageAccountState;
  public UnifiedBillingAccountType = UnifiedBillingAccountType;

  // also should same in ub-notifications.components.ts
  public durationDeleteDays;

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public get showFooter(): boolean {
    return (
      this.storageAccount &&
      this.ubSubscribe.canRestoreTypes.concat(this.ubSubscribe.readonlyTypes).includes(this.storageAccount.usageTypeByLifeCycle)
    );
  }

  constructor(
    private storageService: StorageAccountsService,
    private modalService: ModalService,
    private i18nextService: I18NextService,
    private authService: AuthService,
    public ubSubscribe: UbSubscribeService, // prettier,
    @Inject(POST_PAYMENT_TOKEN) public isPostPayment: Observable<boolean>
  ) {}

  ngOnInit(): void {
    this.durationDeleteDays = moment.duration(this.storageAccount.usageTypeDaysCount, 'days').format('d [days]');
  }

  handleResolve(): void {
    this.loading = true;
    this.storageService
      .cancelDeletionUb(this.storageAccount.id)
      .pipe(
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        // TODO NESTED SUBSCRIPTIONS
        this.storageService.getUnifiedBillings().subscribe(); // prettier
        this.baseModal.save();
      });
  }

  handleSubscribe(): void {
    // close current then show switchCommercial
    this.baseModal.close();
    this.ubSubscribe.showSwitchCommercial(this.storageAccount);
  }

  public openPaymentDebtModal() {
    this.baseModal.close();

    this.modalService
      .openCustom(PaymentDebtModalComponent, {
        data: {
          title: this.i18nextService.t('app:paymentDebtModal:title', {
            format: 'title'
          }),
          userId: this.authService.currentUserValue.Id
        },
        size: MbsSize.sm
      })
      .finally(noop);
  }
}
