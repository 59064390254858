import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-powershell';
import 'prismjs/plugins/line-numbers/prism-line-numbers';

import { Injectable } from '@angular/core';
import { asapScheduler, map, scheduled } from 'rxjs';
import RmmCommand from '@shared/models/rmm/RmmCommand';
import { RmmCommandType } from '@shared/models/rmm/RmmCommandType';
import { RmmService } from '@shared/services';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { getGuid } from '@ngrx/data';

export interface createSession {
  hid: string;
  emuId?: string;
  stamp?: string | number;
}

@Injectable()
export class TerminalNewService {
  private currentSession: string;
  private commandType: RmmCommandType = 'PowerShellTerminalCmd';

  constructor(private commandService: CommandService, private rmmService: RmmService) {}

  init(hid: string) {
    const command = RmmCommand.create('InteractiveInit', this.createSessionId({ hid }));
    return this.commandService.sendStrictCommandAsync(this.commandType, command, hid, true);
  }

  getTimeOut(hid: string) {
    const command = RmmCommand.create('GetTimeOut', this.createSessionId({ hid }));

    return this.commandService.sendStrictCommandAsync(this.commandType, command, hid, true);
  }

  setTimeout(hid: string, number: number) {
    const command = RmmCommand.create('SetTimeOut', this.createSessionId({ hid }));
    command.addParam('TIMEOUT', number);

    return this.commandService.sendStrictCommandAsync(this.commandType, command, hid, true);
  }

  break(hid: string, asyncId: string) {
    const command = RmmCommand.create('RestartPowerShell', asyncId);
    return this.commandService.sendStrictCommandAsync(this.commandType, command, hid, true);
  }

  breakCurrentCommand(hid: string) {
    const command = RmmCommand.create('InteractiveBreak', this.createSessionId({ hid }));
    return this.commandService.sendStrictCommandAsync(this.commandType, command, hid, true);
  }

  send(value, hid, asyncId: string) {
    const command = RmmCommand.create('InteractiveScript', asyncId);

    command.addParam('SCRIPT', value);
    command.addParam('TIMEOUT', null);
    command.addParam('INTERACTIVE', 'true');
    command.addParam('STOP', 'false');

    return this.commandService.sendStrictCommandAsync(this.commandType, command, hid, true);
  }

  tokenExpired() {
    return scheduled(this.commandService.tokenExpired, asapScheduler);
  }

  checkDisconnected(hid: string) {
    return this.rmmService.onlineStatus(hid).pipe(map((status) => !status));
  }

  createSessionId(params: createSession) {
    const { hid } = params;
    return `terminal-new:${getGuid()}`;
  }

  getOutputResult() {
    return this.commandService.messages$.pipe(map((message) => this.commandService.parseMessage(message)));
  }
}
