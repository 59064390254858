import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[collapsible]'
})
export class NavCollapsedHideDirective {
  @Output() collapse = new EventEmitter();

  @HostListener('click') rotate() {
    this.collapse.emit();
  }
}
