<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:compression_encryption_title' | i18next: { format: 'title' } }}
    </h4>
  </header>

  <div class="mbs-wizard_form-content">

    <mbs-form-group>
      <mbs-checkbox formControlName="UseCompression"
                    [label]="'wizards:enable_compression_label' | i18next"
                    [id]="'enable-compression-checkbox'"></mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group>
      <mbs-checkbox formControlName="UseEncryption"
                    [label]="'wizards:enable_encryption_label' | i18next"
                    (change)="enableEncryptionChangeHandler($event)"
                    [descriptionOutside]="false"
                    [id]="'enable-encryption-checkbox'">
        <ng-template checkboxDescription>
          <mbs-alert *ngIf="needToShowPassRecoveryAlert" [type]="alertType.warning" [icon]="true">
            {{ 'pass-recovery:passRecoveryImportanceSubadminWarning' | i18next }}
          </mbs-alert>

          <mbs-form-group>
            <div class="row">
              <div class="col-6">
                <mbs-confirm-password [(ngModel)]="passwordFormData"
                                      [ngModelOptions]="{ standalone: true }"
                                      (ngModelChange)="passwordNgModelChangeHandler($event)"
                                      [disabled]="!this.stepForm.get('UseEncryption').value"
                                      [label]="'wizards:password_label' | i18next"
                                      [placeholder]="'wizards:password_placeholder' | i18next"
                                      [confirmPlaceholder]="'wizards:confirm_password_label' | i18next"
                                      [id]="'compressionEncryptionPassword'"
                                      [initialPassword]="InitialValue?.EncryptionPassword"
                                      [fakePassword]="passwordPlaceholder"
                                      [needValidateOnMount]="passwordFormData.password !== passwordPlaceholder"
                                      [errorsTooltipContainer]="'mbs-compression-encryption-step'"
                                      [errorsTooltipClass]="'tooltip-xl w-100 mr-1'"
                                      [errorsTooltipPlacement]="'left'"
                                      [errorTooltipTitle]="'app:password-error-title' | i18next"
                                      [showErrorsInTooltip]="stepFocused"></mbs-confirm-password>
              </div>
              <div class="col-6">
                <mbs-form-group>
                  <mbs-select (close)="encryptionAlgorithmCloseHandler()"
                              [(ngModel)]="selectedEncryptionAlgorithmDisplays"
                              [appendTo]="'.modal-content'"
                              [clearable]="false"
                              [disabled]="!stepForm.get('UseEncryption').value"
                              [items]="encryptionAlgorithmDisplays"
                              [id]="'EncryptionAlgorithm'"
                              [label]="'wizards:algorithm_label' | i18next"
                              [ngModelOptions]="{ standalone: true }"
                              [searchable]="false"></mbs-select>
                </mbs-form-group>
              </div>
            </div>
          </mbs-form-group>

          <mbs-form-group *ngIf="!isLinux && showUseFileNameEncryption">
            <mbs-checkbox formControlName="UseFileNameEncryption"
                          [label]="'wizards:encryption_filenames_label' | i18next"
                          [id]="'encrypt-filenames-checkbox'"></mbs-checkbox>
          </mbs-form-group>
          <mbs-form-group *ngIf="isNBF || (isLinux && correctlyVersionAgent)">
            <mbs-input formControlName="hint"
                       [label]="'wizards:hint_label' | i18next"
                       [validationWhitespace]="true"></mbs-input>
            <mbs-alert *ngIf="!isNBF && isLinux && isRDMode"
                       class="mt-3"
                       [type]="mbsPopupType.info"
                       [icon]="true">
              {{'wizards:agent_version_hint_alert_text' | i18next}}
            </mbs-alert>
          </mbs-form-group>
        </ng-template>
      </mbs-checkbox>
    </mbs-form-group>

    <mbs-form-group *ngIf="showUseServerSideEncryption">
      <mbs-checkbox formControlName="UseServerSideEncryption"
                    [label]="'wizards:server_side_encryption_label' | i18next"
                    [id]="'server-side-encryption-checkbox'"></mbs-checkbox>
    </mbs-form-group>

    <mbs-alert *ngIf="needShowAlert"
               [type]="mbsPopupType.warning"
               [icon]="true">
      {{'wizards:compression_encryption_alert_text' | i18next}}
    </mbs-alert>

  </div>
</section>
