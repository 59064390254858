
<div class="mbs-infoblock bg-primary-5 p-3">
  <div class="code-container">
    <textarea
      #textArea
      class="text-area-code-editor"
      spellcheck="false"
      [value]="value"
      [id]="id"
      [disabled]="disabledState"
      [readonly]="readonlyState"
    ></textarea>
    <pre
      aria-hidden="true"
      class="pre code-editor-height"
      #pre
    ><code [ngClass]="['code', 'language-' + codeType]" #codeContent></code>
    </pre>
  </div>
</div>
