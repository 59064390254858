import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule, SharedPipesModule } from 'mbs-ui-kit';
import { NavCollapsedHideDirective } from './nav-collapsed-hide.directive';
import { isInSubnetDirective } from './is-in-subnet.directive';

@NgModule({
  declarations: [NavCollapsedHideDirective, isInSubnetDirective],
  exports: [NavCollapsedHideDirective, isInSubnetDirective],
  imports: [CommonModule, NgbToastModule, SharedPipesModule, ButtonModule]
})
export class DirectivesModule { }
