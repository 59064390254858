<mbs-modal [isCustomModal]="true"
           [showFooter]="true"
           [title]="title"
           #modal>
  <div modal-body>
    <form [formGroup]="uninstallAgentForm"
          class="mbs-form">
      <fieldset class="mbs-form_content">
        <div class="mbs-form-group"
             formGroupName="agentList">
          <h5 class="mbs-form-group_title">
            {{(isPluralText ?  'uninstall-agent-modal:selectAgentPlural' : 'uninstall-agent-modal:selectAgent') | i18next: { format: 'title' } }}
          </h5>
          <div class="row">
            <div class="col-6">
              <mbs-checkbox formControlName="backup"
                            [disabled]="!isAgentTypeByApplicationID(AgentType.Backup)"
                            label="{{'app:products.backup' | i18next}}"></mbs-checkbox>
            </div>
            <div class="col-6">
              <mbs-checkbox formControlName="rmm"
                            [disabled]="!isAgentTypeByApplicationID(AgentType.RMM)"
                            label="{{'app:products.rmm' | i18next}}"></mbs-checkbox>
            </div>
            <div class="col-6">
              <mbs-checkbox formControlName="ra"
                            [disabled]="!isAgentTypeByApplicationID(AgentType.RA)"
                            label="{{'app:products.remote_desktop' | i18next}}"></mbs-checkbox>
            </div>
            <div class="col-6">
              <mbs-checkbox formControlName="deepInst"
                            [disabled]="isDeepInstinctDisabled()"
                            label="{{'app:products.deepinst' | i18next}}"></mbs-checkbox>
            </div>
          </div>
        </div>
        <mbs-alert [type]="alertType.warning"
                   [icon]="true"
                   class="mb-0">
          {{(isPluralText ?  'uninstall-agent-modal:alertTextPlural' : 'uninstall-agent-modal:alertText') | i18next}}
        </mbs-alert>
      </fieldset>
    </form>
  </div>
  <ng-container modalFooter>
    <mbs-button type="danger"
                [loading]="loading"
                [disabled]="!uninstallAgentForm.get('agentList').valid"
                (click)="handleResolve()">
      {{'uninstall-agent-modal:uninstall' | i18next}}
    </mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">{{ 'buttons:cancel' | i18next: { format: 'title' } }}</mbs-button>
  </ng-container>
</mbs-modal>
