import { AfterViewInit, Component, ComponentRef, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { TFAService } from '@components/tfa/services/tfa.service';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getGuid } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import { AbilityService } from 'ability';
import { get } from 'lodash/fp';
import { ModalService } from 'mbs-ui-kit';
import { NgTerminal, NgTerminalComponent } from 'ng-terminal';
import { BehaviorSubject, Observable, Subject, Subscription, iif } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, mergeMap, shareReplay, skip, switchMap, take } from 'rxjs/operators';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { NgTerminalHostDirective } from '../directives/ng-terminal-host.directive';
import { ShellAddon } from '../shell-addon/addon';
import { PromptBuffer } from '../shell-addon/prompt-buffer';
import * as TerminalActions from '../store/terminal.actions';
import { TerminalChunk, TerminalDataType } from '../store/terminal.model';
import * as TerminalSelectors from '../store/terminal.selectors';
import { TerminalEmulatorService } from '../terminal-emulator.service';
import { createSessionId } from '../terminal-emulator.util';

@UntilDestroy()
@Component({
  selector: 'mbs-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./ng-terminal-wrapper.component.scss']
})
export class TerminalComponent implements AfterViewInit {
  @ViewChild(NgTerminalHostDirective, { static: true }) terminalHost!: NgTerminalHostDirective;
  @Input() require2faContainer = 'mbs-sidepanel-rmm-info .mbs-form_content.mbs-tabset_content';
  @Input() showModalButton = true;
  @Input() autoFit = true;
  @Input() warning = '';
  @Input() set hid(hid: string) {
    this._hid$.next(hid);
  }
  get hid() {
    return this._hid$.getValue();
  }
  get prompt() {
    return this.addon.getCurrentCommand();
  }
  get term() {
    return null;
  }
  get textaria() {
    return this.addon.term?.textarea || null;
  }

  @Output() public OnError: EventEmitter<string> = new EventEmitter();
  @Output() public OnInputChange: EventEmitter<string> = new EventEmitter();
  @Output() public OnInitialized: EventEmitter<NgTerminal> = new EventEmitter();
  @Output() public OnBufferChange: EventEmitter<PromptBuffer> = new EventEmitter();

  public hid$: Observable<string>;
  public outputListener$: Subject<unknown> = new Subject();

  public legacy = false;
  public addon: ShellAddon;
  public ngTerm: NgTerminal;
  public value: string;

  protected emuId = getGuid();
  protected modalOpen;
  protected initSub: Subscription;
  /**
   * DI by Injector
   */
  protected hostElement: ElementRef;
  protected store$: Store;
  protected modalService: ModalService;
  protected terminalService: TerminalEmulatorService;
  protected computersFacade: ComputersFacade;
  protected ability: AbilityService;
  protected auth: AuthService;
  protected TFAService: TFAService;
  protected commandService: CommandService;
  /**
   * Dynamic components Refs
   */
  protected twoFAConfirmModalRef: NgbModalRef;
  protected terminalModalRef: NgbModalRef;
  protected ngTermRef: ComponentRef<NgTerminal>;

  private _hid$: BehaviorSubject<string> = new BehaviorSubject(null);
  private outputLines = 0;

  private defaultTheme = {
    background: '#2D6CA2'
  };

  constructor(protected injector: Injector) {
    this.hostElement = this.injector.get(ElementRef);
    this.store$ = this.injector.get(Store);
    this.modalService = this.injector.get(ModalService);
    this.terminalService = this.injector.get(TerminalEmulatorService);
    this.computersFacade = this.injector.get(ComputersFacade);
    this.ability = this.injector.get(AbilityService);
    this.auth = this.injector.get(AuthService);
    this.TFAService = this.injector.get(TFAService);
    this.commandService = this.injector.get(CommandService);

    this.hid$ = this._hid$.pipe(distinctUntilChanged(), shareReplay());
  }

  ngOnInit(): void {
    this.initStreams();
  }

  initStreams() {
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      if (!this.hid) {
        this._hid$.next(computer.hid);
      }
    });

    this.hid$.pipe(untilDestroyed(this)).subscribe((hid) => this.store$.dispatch(TerminalActions.initTerminal({ hid, emuId: this.emuId })));

    const init$ = this.OnInitialized;

    init$
      .pipe(
        switchMap(() => this.store$.select(TerminalSelectors.selectDisconnected(this.emuId))),
        map(Boolean),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((status) => this.addon.dispatchOffline(status));

    init$
      .pipe(
        switchMap(() => this.store$.select(TerminalSelectors.selectProcessingByInstance(this.emuId))),
        untilDestroyed(this)
      )
      .subscribe((processing) => this.addon.dispatchProcessing(processing));

    init$
      .pipe(
        switchMap(() => this.store$.select(TerminalSelectors.selectProcessingByInstance(this.emuId))),
        mergeMap((state: boolean) =>
          iif(
            () => state,
            this.store$.select(TerminalSelectors.selectLastChunk(this.emuId)).pipe(skip(1)),
            this.store$.select(TerminalSelectors.selectLastChunk(this.emuId))
          )
        ),
        filter(Boolean),
        distinctUntilKeyChanged('id'),
        untilDestroyed(this)
      )
      .subscribe((last) => {
        this.handleOutput(last);
      });

    init$
      .pipe(
        switchMap(() => this.store$.select(TerminalSelectors.selectOrderError(this.emuId))),
        untilDestroyed(this)
      )
      .subscribe((state) => {
        if (state) {
          this.addon.softReset();
        }
      });
  }

  ngAfterViewInit(): void {
    this.reset();
  }

  reset() {
    this.initSub?.unsubscribe();
    this.initSub = this.store$
      .select(TerminalSelectors.selectSessionBySubscriber(this.emuId))
      .pipe(filter(Boolean), take(1), untilDestroyed(this))
      .subscribe((session) => {
        queueMicrotask(() => {
          const addon = new ShellAddon(session.info.osVersionType);
          this.addon = addon;
          this.legacy = Boolean(session.info.legacy);
          this.initTerminalComponent(addon);
        });
      });
  }

  initTerminalComponent(addon) {
    const viewContainerRef = this.terminalHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(NgTerminalComponent);
    this.ngTermRef = componentRef;
    const instance = componentRef.instance;
    // dirty huck to use private subject, on update can work incorrect
    const afterViewInitSubject: Subject<unknown> = get('requestRenderFromAPI', instance);
    afterViewInitSubject
      .pipe(
        switchMap((value) => {
          return this.store$.select(TerminalSelectors.selectOrderedChunks(this.emuId));
        }),
        take(1),
        untilDestroyed(this)
      )
      .subscribe((cache: TerminalChunk[]) => {
        this.initAddon(addon);
        instance.underlying.loadAddon(addon);

        // setup terminal color
        instance.setXtermOptions({
          theme: {
            ...this.defaultTheme,

            border: this.defaultTheme.background
          },
          fontSize: 12,
          cursorBlink: true,
          disableStdin: true
        });

        this.ngTerm = instance;

        setTimeout(() => {
          this.handleCache(cache, addon);
          this.OnInitialized.emit(this.ngTerm);
        });
      });
  }

  initAddon(addon: ShellAddon) {
    const execute$ = addon.execute$;
    const break$ = addon.break$;
    const error$ = addon.error$;
    const inputChange$ = addon.inputChange$;
    const bufferChange$ = addon.bufferChange$;
    const cacheRequest$ = addon.cacheRequest$;

    execute$.pipe(untilDestroyed(this)).subscribe((prompt) => {
      const hid = this.hid;
      const emuId = this.emuId;

      // Cannot freeze array buffer views with elements fix.
      const script = typeof prompt === 'string' ? prompt : prompt.getInput();

      this.store$.dispatch(TerminalActions.send({ hid, prompt: script, emuId }));
    });

    break$.pipe(untilDestroyed(this)).subscribe(() => {
      const hid = this.hid;
      const emuId = this.emuId;
      this.store$.dispatch(TerminalActions.sendBreak({ hid, emuId }));
    });

    inputChange$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.value = this.addon.getCurrentCommand();
      this.OnInputChange.emit(event);
    });

    bufferChange$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.OnBufferChange.emit(event);
    });

    error$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.OnError.emit(event);
    });

    cacheRequest$
      .pipe(
        distinctUntilChanged(),
        switchMap(() => this.store$.select(TerminalSelectors.selectOrderedChunks(this.emuId))),
        untilDestroyed(this)
      )
      .subscribe((lines) =>
        setTimeout(() => {
          addon.dispatchCache(lines);
        })
      );
  }

  execute(_event?) {
    this.addon.execute();
  }

  handleModalState(state: boolean) {
    this.modalOpen = state;
  }

  handleCache = (lines: TerminalChunk[], addon = this.addon) => {
    addon.dispatchCache(lines);
  };

  handleOutput = (out: TerminalChunk) => {
    const lineSplitter = '\r\n';
    this.value = null;

    if (this.outputLines < 1000) this.outputLines += out?.data?.split(lineSplitter)?.length ?? 0;

    switch (out.type) {
      case TerminalDataType.FILE:
      case TerminalDataType.INPUT: {
        if (out.emuId !== this.emuId) {
          this.addon.inputCommand(out.data);
        }
        break;
      }
      case TerminalDataType.PROMPT:
        this.addon.dispatchPrompt(out.data);
        break;
      case TerminalDataType.ERROR: {
        this.addon.dispatchOutputError(out.data);
        break;
      }
      case TerminalDataType.WARN: {
        this.addon.dispatchOutputWarning(out.data);
        break;
      }
      case TerminalDataType.OUTPUT: {
        this.addon.dispatchOutput(out.data);
        this.handleResetOnLineLimit();
        break;
      }
      case TerminalDataType.END: {
        this.addon.dispatchOutput(out.data);
        if (this.legacy) {
          this.addon.dispatchPrompt();
        }
        break;
      }
      default:
        null;
    }
  };

  /**
   * Disable Html scroll event
   *
   * @param {Event} event
   */
  handleScroll(event: Event): void {
    event.preventDefault();
  }

  /**
   * resets Terminal if the amount of output line is too high
   *
   */
  handleResetOnLineLimit() {
    if (this.outputLines >= 1000) {
      this.reset();
      this.outputLines = 0;
    }
  }

  ngOnDestroy(): void {
    this.initSub?.unsubscribe();
    this.terminalModalRef?.close();
    this.twoFAConfirmModalRef?.close();
    this.store$.dispatch(TerminalActions.removeSubscriber({ session: createSessionId({ hid: this.hid }), emuId: this.emuId }));
  }
}
