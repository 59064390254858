<mbs-modal [title]="'Cancel Account Deletion'"
           [isCustomModal]="true"
           [showFooter]="showFooter"
           #modal>
  <div modal-body>
    <ng-container *ngIf="ubSubscribe.canRestoreTypes.includes(storageAccount.usageTypeByLifeCycle)">
      Are you sure you want to cancel the delete operation?
    </ng-container>


    <ng-container *ngIf="ubSubscribe.readonlyTypes.includes(storageAccount.usageTypeByLifeCycle)">
      <ng-container *ngIf="storageAccount.usageTypeByLifeCycle === UnifiedBillingAccountType.TrialReadOnly">
        Your trial {{ storageAccount.storageTypeDisplayName }} storage account will be restored in read-only mode.
        To continue using {{ storageAccount.storageTypeDisplayName }} storage with full access you need to <mbs-button
                    [isCtrl]="true"
                    (click)="handleSubscribe()">subscribe.</mbs-button>

      </ng-container>

      <ng-container *ngIf="storageAccount.usageTypeByLifeCycle === UnifiedBillingAccountType.PaidWithDebtReadOnly">
        Your {{ storageAccount.storageTypeDisplayName }} storage will be restored in read-only mode. Full access cannot
        be restored until your subscription
        has been paid. <ng-container *ngTemplateOutlet="checkPaymentMethod"></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="ubSubscribe.deleteByOurSystemTypes.includes(storageAccount.usageType)">
      <ng-container *ngIf="storageAccount.usageType === UnifiedBillingAccountType.TrialDeactivated">
        Full access cannot be restored until you subscribe. Your data will be cleared in {{ durationDeleteDays }}.
        To continue using {{ storageAccount.storageTypeDisplayName }} Storage please <mbs-button [isCtrl]="true"
                    (click)="handleSubscribe()">subscribe.</mbs-button>
      </ng-container>

      <ng-container *ngIf="storageAccount.usageType === UnifiedBillingAccountType.PaidWithDebtDeactivated">
        Access cannot be restored until the subscription has been paid.
        Your data will be cleared in {{ durationDeleteDays }}. <ng-container *ngTemplateOutlet="checkPaymentMethod">
        </ng-container>
      </ng-container>

    </ng-container>

    <ng-template #checkPaymentMethod>
      <ng-container *ngIf="('Licenses' | can:'read'); else contactProvider">
        Please check your
        <a *ngIf="!(isPostPayment | async)"
           routerLink="/AP/Licenses.aspx"
           [queryParams]="{ renew: true }"
           (click)="modal.close()">payment method</a>

        <mbs-button *ngIf="(isPostPayment | async)"
                    [isCtrl]="true"
                    (click)="openPaymentDebtModal()"
                    size="inherit"
                    type="link">payment method</mbs-button>.
      </ng-container>
      <ng-template #contactProvider>
        <a [href]="'mailto:' + ubSubscribe.providerContactEmail">Contact the account owner.</a>
      </ng-template>

    </ng-template>


  </div>
  <ng-container modalFooter>
    <mbs-button type="primary"
                [loading]="loading"
                (click)="handleResolve()">OK</mbs-button>
    <mbs-button type="secondary"
                data-dismiss="modal"
                (click)="modal.close()">Close</mbs-button>
  </ng-container>

</mbs-modal>
