export function convertToCamelCase<T>(source: T): T {
  if (typeof source !== 'object') {
    throw new Error('Expected object type instead ' + typeof source);
  }

  const entries = Object.entries(source).map(([k, v]) => {
    if (v && typeof v === 'object' && !Array.isArray(v)) {
      return convertToCamelCase(v);
    }
    const camelKey = k[0].toLowerCase() + k.substring(1);
    return [camelKey, v];
  });

  return Object.fromEntries(entries) as T;
}
