import { BuildType } from '@models/BuildType.enum';
import { getVersionNumeralRepresentation } from '@utils/version-numeral-representation';

export type Build = {
  name: string;
  mbsBuildType: BuildType;
  buildType?: string;
  iconImage: string;
  description?: string;
  buildViewOrder?: number;
  downloadsBuildType?: number;
  buildOs: BuildOsType;
  buildLabel: string;
  enabled?: boolean;
  sandbox?: BuildDetails;
  public?: BuildDetails;
  applicationId: string;
};

export type BuildDetails = {
  version: string;
  downloadLink: string;
  newVersionAvailableTitle: string;
  isNewerVersionAvailable: boolean;
  availableVersion: string;
};

export type BuildsInfo = {
  autoUpdateEnabled: boolean;
  sandBoxAvailable: boolean;
  buildErrorMessage: string;
  noteOutdated: string;
  isBuildInProgress: boolean;
  settingsButtonVisible: boolean;
  buildsCRUDAvailable: boolean;
  builds: Build[];
};

export enum BuildOsType {
  Windows = 'Windows',
  Linux = 'Linux',
  Mac = 'Mac'
}

export enum BuildVersionType {
  Public = 'public',
  Sandbox = 'sandbox'
}

export const SupportCompanyMinimalVersion = {
  [BuildType.CommonWindowsBuild]: '7.9.3',
  [BuildType.VMware]: '7.9.3',
  [BuildType.LinuxMac]: '4.2.1',
  [BuildType.LinuxUbuntu14]: '4.2.1',
  [BuildType.LinuxRedHat6]: '4.2.1',
  [BuildType.CBLRA]: '3.4.0.28',
  [BuildType.RMMAgent]: '2.0.0.115',
  [BuildType.RMMAgentLinuxDeb]: '2.0.0.115',
  [BuildType.RMMAgentLinuxRpm]: '2.0.0.115',
  [BuildType.RMMAgentMac]: '2.0.0.115',
  [BuildType.NetworkDiscovery]: '1.0.0.107'
}

export const getSupportCompanyMinimalVersion = (buildType: BuildType): number => {
  return getVersionNumeralRepresentation(SupportCompanyMinimalVersion[buildType], 6);
}
