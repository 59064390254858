<ngb-toast (hidden)="handleRemove(toast)"
           (mouseenter)="autohide = false"
           (mouseleave)="autohide = true"
           *ngFor="let toast of visibleToasts; last as isLast"
           [autohide]="autohide"
           [ngClass]="getToastClasses(toast, isLast)"
           [delay]="toast.delay || delayConfig[toast.type]">

  <!-- header of toast -->
  <header *ngIf="toast.header"
          class="mbs-toast-header"
          [ngClass]="{'text-break': toast.textBreak === TextBreak.header || toast.textBreak === TextBreak.both }">

    <ng-template #defaultHeader>
      <span *ngIf="toast.icon"
            [ngClass]="getIconClasses(toast)"></span>
      <h6 class="mbs-toast-title mr-auto"
          [innerHTML]="toast.header"></h6>
    </ng-template>

    <ng-container *ngTemplateOutlet="isTemplate(toast.header) ? templateRef : defaultHeader;
                  context: { $implicit: toast, content: toast.header }"></ng-container>

    <mbs-button *ngIf="toast.showClose"
                [isCtrl]="true"
                type="dark"
                (click)="handleRemove(toast)"
                icon="fa fa-close"
                class="close"></mbs-button>

  </header>

  <!-- body of toast -->
  <div class="mbs-toast-body"
       [ngClass]="{'text-break': toast.textBreak === TextBreak.content || toast.textBreak === TextBreak.both }">

    <ng-container *ngTemplateOutlet="isTemplate(toast.content) ? templateRef : innerHTMLTemplate;
                  context: { $implicit: toast, content: toast.content, needIcon: !toast.header }"></ng-container>
  </div>

  <!-- footer of toast -->
  <footer *ngIf="toast.footer || toast.buttons && toast.buttons.length"
          class="mbs-toast-footer">

    <ng-template #defaultFooter>
      <div class="form-row">
        <div class="col-auto"
             *ngFor="let button of toast.buttons">
          <mbs-button [isCtrl]="button.isCtrl || true"
                      [type]="button.type || 'primary'"
                      [size]="MbsSize.inherit"
                      (click)="onButtonClick(button, toast)">
            {{ button.label || 'Show more...' }}
          </mbs-button>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="!toast.buttons; else defaultFooter">
      <ng-container *ngTemplateOutlet="isTemplate(toast.footer) ? templateRef : innerHTMLTemplate;
                    context: { $implicit: toast, content: toast.footer }"></ng-container>
    </ng-container>

  </footer>

</ngb-toast>

<!-- Templates -->
<ng-template #innerHTMLTemplate
             let-needIcon="needIcon"
             let-toast
             let-content="content">
  <span *ngIf="toast.icon && needIcon"
        [ngClass]="getIconClasses(toast)"></span>
  <div [outerHTML]="content | safe: 'html'"></div>
</ng-template>

<ng-template #templateRef
             let-toast
             let-content="content">
  <ng-container *ngTemplateOutlet="content; context: { $implicit: toast }"></ng-container>
</ng-template>
