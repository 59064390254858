import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromEventChart from './event-chart.reducer';
import * as fromEventEntryFilter from './event-entry-filter.reducer';
import * as fromEventEntry from './event-entry.reducer';
import * as fromEventTotal from './event-total.reducer';
import { EventLogEntryTypeEnum } from '@models/rmm/EventLogInfo';

export const selectState = createFeatureSelector<fromEventTotal.State>(fromEventTotal.eventTotalFeatureKey);
export const selectFilterState = createFeatureSelector<fromEventEntryFilter.State>(fromEventEntryFilter.eventEntryFiltersFeatureKey);
export const selectEntryState = createFeatureSelector<fromEventEntry.State>(fromEventEntry.eventEntriesFeatureKey);
export const selectChartState = createFeatureSelector<fromEventChart.State>(fromEventChart.eventChartsFeatureKey);
export const selectEntries = createSelector(selectEntryState, fromEventEntry.selectAll);

export const selectEntriesEntities = createSelector(selectEntryState, fromEventEntry.selectEntities);

export const selectCharts = createSelector(selectChartState, fromEventChart.selectAll);

export const selectChartTotalCount = createSelector(selectChartState, state => state.total);

export const selectChartWithState = createSelector(selectCharts, selectState, (charts, state) => ({ charts, state }));

export const selectFiltersMap = createSelector(selectFilterState, fromEventEntryFilter.selectEntities);

export const selectFilters = createSelector(selectFilterState, fromEventEntryFilter.selectAll);

export const selectFiltersCount = createSelector(selectFilterState, state => {
  let totalEventsCount = 0;
  const entities = state.entities;
  for (const entity in entities) {
    if (Object.prototype.hasOwnProperty.call(entities, entity)) {
      totalEventsCount += entities[entity].count;
    }
  }
  return totalEventsCount;
});

export const selectFilteredEntry = createSelector(selectEntries, selectFiltersMap, (entries, filters) => {
  return entries.filter(entry => {
    if(filters[EventLogEntryTypeEnum.All]?.active) {
      return true;
    }

    return filters[entry.level]?.active;
  });
});

export const selectChartLoading = createSelector(selectChartState, state => state.loading);
export const selectEntryLoading = createSelector(selectEntryState, state => state.loading);

export const selectLoading = createSelector(selectChartLoading, selectEntryLoading, (chart, entry) => chart || entry);

export const selectCurrentEntryId = createSelector(selectEntryState, fromEventEntry.getSelectedId);

export const selectCurrentEntry = createSelector(selectEntriesEntities, selectCurrentEntryId, (state, id) => state[id]);
export const selectCurrentState = createSelector(selectEntryState, state => state.open);
