import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import SoftwareInfo from '@shared/models/rmm/SoftwareInfo';
import { RmmSoftwareService } from '@shared/services';
import { getReversedTableSorting } from '@shared/utils/helpers/rmm/table-reversed-sorting';
import { InMemorySorter } from '@shared/utils/inMemorySorter';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep } from 'lodash';
import { SortEvent, TableHeader } from 'mbs-ui-kit';
import { map, switchMap, take, tap, throwError } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-install-winget-action',
  templateUrl: './install-winget.component.html'
})
export class InstallUsingWinGetComponent implements OnInit {
  @Input() hid = '';
  @Output() updateSelectedSoftware = new EventEmitter<SoftwareInfo[]>();

  public wingetForm = new FormGroup({
    value: new FormControl(''),
    filter: new FormControl('')
  });

  public bindDisabledValues = { key: 'found', value: false };
  public showTable = false;
  public showError = false;
  public isLoading = false;
  public disableValidateSoftwareBtn = true;
  public data = [];
  public dataToShow = [];
  public selectedItems = [];
  public orderBy: SortEvent = { column: 'name', direction: '' };
  public headers: TableHeader[] = [
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableHeaderName'),
      sort: 'name',
      isGridColumn: false,
      overflow: true,
      gridColSize: '10fr',
      gridColMin: '80px'
    },
    {
      name: this.i18n.transform('rmm.module:groupActions.stepConfigureAction.actionWindowTables.tableLatestVersion'),
      sort: 'version',
      isGridColumn: false,
      gridColSize: '10fr',
      gridColMin: '80px'
    },
    {
      name: '',
      sort: 'customTooltip',
      isGridColumn: false,
      gridColSize: '5fr',
      gridColMin: '40px',
      class: '-end'
    }
  ];

  constructor(private softwareService: RmmSoftwareService, private commandService: CommandService, private i18n: I18NextPipe) {
    this.wingetForm
      .get('value')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.disableValidateSoftwareBtn = !value;

        if (!this.showTable) {
          this.selectedItems = [];
          this.updateSelectedSoftware.emit([]);
        }
      });

    this.wingetForm
      .get('filter')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.dataToShow = this.data.filter((item) => [item.id, item.name, item.publisher].join(' ').includes(value));
      });
  }

  ngOnInit(): void {}

  handleSort({ column, direction }: SortEvent): void {
    this.orderBy = { column, direction };
    this.dataToShow = InMemorySorter.sort(getReversedTableSorting(this.orderBy), this.dataToShow);
  }

  selected(event: any) {
    this.selectedItems = event.filter((item) => item.found);

    if (this.selectedItems.length) {
      this.updateSelectedSoftware.emit([...this.selectedItems]);
    } else {
      this.updateSelectedSoftware.emit([]);
    }
  }

  switchToList() {
    this.showTable = false;
    this.wingetForm.get('value').setValue(this.wingetForm.get('value').value);
  }

  searchSoftwareUsingWinget() {
    if (!this.hid) {
      this.showError = true;
      return;
    }

    this.showError = false;
    this.disableValidateSoftwareBtn = true;

    this.isLoading = true;
    this.showTable = true;

    const softListInString = this.valueToStringUtility(this.wingetForm.get('value').value);
    this.softwareService
      .searchSoftwareUsingWinget(this.hid, softListInString)
      .pipe(
        switchMap((response: any) => {
          if (response?.error?.code) {
            return throwError(() => response);
          }

          return this.getMessageByAsyncId(response);
        }),
        map((data) => this.commandService.parseMessage(data)),
        tap((value) => (this.isLoading = false)),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (!res) {
            this.showError = true;
            this.isLoading = false;
          }

          this.disableValidateSoftwareBtn = false;
          this.data = cloneDeep(res?.Data?.data) ?? [];
          this.dataToShow = this.data.map((item) => ({ ...item, name: item.name ?? item.searchText }));

          this.handleSort({ ...this.orderBy });

          if (this.selectedItems.length) {
            this.selectedItems = this.selectedItems.filter((selected) => this.data.some((item) => item.id === selected.id));
            this.updateSelectedSoftware.emit([...this.selectedItems]);
          } else {
            this.updateSelectedSoftware.emit([]);
          }
        },
        error: (err) => {
          // err itself will be handled on error service
          this.disableValidateSoftwareBtn = false;
          this.data = [];
          this.showError = true;
          this.updateSelectedSoftware.emit([]);
          this.isLoading = false;
        }
      });
  }

  getMessageByAsyncId(response: any) {
    if (response?.result) {
      const obj = JSON.parse(response?.result);
      const splitter = ' = ';
      const asyncId = obj.data.split(splitter)[1];

      return this.commandService.selectMessagesByAsyncId$(asyncId);
    } else {
      return this.commandService.messages$;
    }
  }

  valueToStringUtility(value: string) {
    return value.replaceAll('\r\n', ',').replaceAll('\n', ';');
  }
}
