import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ComputersFacade } from '@facades/computers.facade';
import { AppLayoutService } from '@mbs-ui/app/shared/_layout/app-layout/app-layout.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-computers-legacy',
  templateUrl: './computers-legacy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputersLegacyComponent implements OnInit {
  isEmpty$: Observable<boolean>;
  isLoading$: Observable<boolean>;

  constructor(private facade: ComputersFacade, private appLayoutService: AppLayoutService) {}

  ngOnInit() {
    this.isEmpty$ = this.facade.statisticOneComputerExist$.pipe(map((exist) => !exist));
    this.isLoading$ = this.facade.statisticOneComputerLoading$;

    this.facade.checkOneComputerExist();
  }
}
