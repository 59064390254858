<section [formGroup]="stepForm">
  <header class="mb-4">
    <h4>{{ 'wizards:encryption_options_step_title' | i18next: { format: 'title' } }}</h4>
  </header>

  <mbs-form-group *ngIf="isNBF"
                  [attr.data-test-element]="elementsSelector.name.nbfPasswordBlock">
    <div class="form-row">
      <div class="col-6">
        <mbs-input #passField
                   name="Password"
                   [placeholder]="'wizards:password_placeholder' | i18next"
                   formControlName="password"
                   [label]="'wizards:password_label' | i18next"
                   [id]="elementsSelector.id.password"
                   [autocomplete]="'off'"
                   [type]="showPassword ? 'text' : 'password'"
                   (buttonClick)="changeTypePassword()"
                   readonly
                   (focus)="passwordFocusHandler($event); passField.readonly = false"
                   (blur)="checkPassword()"
                   [appendButtons]="[
                     {
                       icon: showPassword ? 'fa fa-eye-slash' : 'fa fa-eye',
                       id: 'changeTypePasswordOnEncryptionOptions',
                       disabled: stepForm.get('password')?.value === InitialValue?.password || stepForm.get('password')?.disabled,
                       type: 'secondary'
                     }
                   ]"
                   [validationWhitespace]="true">
        </mbs-input>
      </div>
    </div>
  </mbs-form-group>

  <mbs-form-group *ngIf="!isNBF"
                  [attr.data-test-element]="elementsSelector.name.notNBFPasswordBlock">
    <mbs-checkbox formControlName="isCryptWithPassword"
                  name="isCryptWithPassword"
                  [label]="'wizards:enable_is_crypt_with_pass_label' | i18next"
                  [descriptionOutside]="false"
                  [id]="'is-crypt-with-password'"
                  [value]="true">
      <ng-template checkboxDescription>
        <div class="row">
          <div class="col-6">
            <mbs-form-group>
              <mbs-input #passFieldTwo
                         [label]="'wizards:password_label' | i18next"
                         name="Password"
                         [placeholder]="'wizards:password_placeholder' | i18next"
                         formControlName="password"
                         readonly
                         [id]="'encryption-options-password'"
                         [autocomplete]="'off'"
                         [type]="showPassword ? 'text' : 'password'"
                         (buttonClick)="changeTypePassword()"
                         (focus)="passwordFocusHandler($event); passFieldTwo.readonly = false"
                         [appendButtons]="[
                           {
                             icon: showPassword ? 'fa fa-eye-slash' : 'fa fa-eye',
                             id: 'changeTypePasswordOnEncryptionOptions',
                             disabled: stepForm.get('password')?.value === InitialValue?.password || stepForm.get('password')?.disabled,
                             type: 'secondary'
                           }
                         ]"
                         [validationWhitespace]="true">
              </mbs-input>
            </mbs-form-group>
          </div>
        </div>
      </ng-template>
    </mbs-checkbox>
  </mbs-form-group>

  <mbs-form-group *ngIf="allowedRecovery && isNBF && !this.isLinux"
                  [attr.data-test-element]="elementsSelector.name.passwordRecoveryBlock">
    <div class="mb-2">
      <app-password-recovery-handler [hid]="this.mainService.hid"
                                     [resultPoint]="{ path: resultPoint?.path, restorePoint: resultPoint?.restorePointId }"
                                     [passwordControl]="stepForm.get('password')"
                                     [backupVersionUpdated]="mainService.backupVersionUpdated"></app-password-recovery-handler>
    </div>
  </mbs-form-group>


  <mbs-form-group *ngIf="resultPoint && resultPoint.encryptionPasswordHint"
                  [attr.data-test-element]="elementsSelector.name.hintBlock">
    <mbs-button [isCtrl]="true"
                type="primary"
                (click)="showPasswordHintChangeHandler()">
      {{ 'wizards:pass_hint_btn_text' | i18next: { state: showHint ? 'Hide' : 'Show' } }}
    </mbs-button>
    <div *ngIf="showHint"
         [attr.data-test-element]="elementsSelector.name.hintTextBlock"
         class="font-weight-bold">{{resultPoint.encryptionPasswordHint}}</div>
  </mbs-form-group>
</section>
