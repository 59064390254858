import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import RaActiveSession from '../models/RaActiveSession';
import RaConnection, { HistoryAPIParams, HistoryQueryParams } from '../models/RaConnection';
import { AdministratorService } from './administrator.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  subject$ = new Subject<RaConnection[]>();
  activeSessions$ = new BehaviorSubject<RaActiveSession[]>([]);

  constructor(private http: HttpClient, private adminService: AdministratorService) {}

  getHistory({ year, month, adminEmail, computerName }: HistoryQueryParams): Observable<RaConnection[]> {
    let queryAdminId = '';
    if (adminEmail) {
      this.adminService.getWithQuery({ search: adminEmail }).subscribe((admins) => {
        queryAdminId = admins[0] ? admins[0].id : '';
        this.fetchHistory({ year, month, queryAdminId, computerName });
      });
    } else {
      this.fetchHistory({ year, month, computerName, queryAdminId: '' });
    }
    return this.subject$.asObservable();
  }

  fetchHistory({ year, month, queryAdminId, computerName }: HistoryAPIParams): void {
    let qAdminId = queryAdminId;
    let qCompName = computerName;
    if (Array.isArray(queryAdminId)) {
      qAdminId = queryAdminId[0];
    }
    if (Array.isArray(computerName)) {
      qCompName = computerName[0];
    }
    const url = `api/raconnections/${year}/${month}`;
    const params = {
      tzoffset: new Date().getTimezoneOffset().toString()
    };
    if (qAdminId) {
      (params as any).adminId = qAdminId;
    }
    if (qCompName) {
      (params as any).computerName = qCompName;
    }
    this.http
      .get<RaConnection[]>(url, { params })
      .pipe(
        map((result: RaConnection[]) => {
          this.subject$.next(result);
          return result;
        })
      )
      .subscribe();
  }

  fetchActiveSessions(): Observable<RaActiveSession[]> {
    return new Observable<RaActiveSession[]>((observer) => {
      this.http
        .get<RaActiveSession[]>('api/raconnections/active')
        .pipe(tap((array) => this.activeSessions$.next(array)))
        .subscribe(observer);
    });
  }
}
