<section [formGroup]="stepForm"
         class="mbs-wizard_form">
  <header class="mbs-wizard_form-header mb-4">
    <h4 class="mbs-wizard_form-title">
      {{ 'wizards:plan_name_title' | i18next: { format: 'title', type: planTypeText } }}
    </h4>
  </header>

  <div class="mbs-wizard_form-content">
    <div class="text-center pb-4 mb-2">
      <img class="w-75"
           [src]="imageSrc"
           alt="" />
    </div>

    <mbs-form-group *ngIf="isRestore">
      <mbs-radio formControlName="SavePlanInCloud"
                 name="SaveBackupPlan"
                 class="mb-3"
                 [ngClass]="elementsSelector.css.planNameRestoreRadio"
                 [label]="'wizards:run_restore_once' | i18next"
                 (change)="changeSavePlanHandler($event)"
                 [descriptionClasses]="'mt-1'"
                 [descriptionOutside]="false"
                 [id]="'save-backup-plan-radio-0'"
                 [value]="false">
        <ng-template radioDescription>
          <span *ngFor="let item of runRestoreOnceSubtitle;let last = last"
                class="text-xs">
            {{item}}<br *ngIf="!last">
          </span>
        </ng-template>
      </mbs-radio>
      <mbs-radio formControlName="SavePlanInCloud"
                 name="SaveBackupPlan"
                 [ngClass]="elementsSelector.css.planNameRestoreRadio"
                 [label]="'wizards:save_restore_plan' | i18next"
                 (change)="changeSavePlanHandler($event)"
                 [descriptionClasses]="'text-xs mt-1'"
                 [descriptionOutside]="false"
                 [id]="'save-backup-plan-radio-1'"
                 [value]="true">
        <ng-template radioDescription>
          <div class="text-xs mb-3">{{'wizards:save_restore_plan_desc' | i18next}}</div>
          <mbs-input [id]="'planName'"
                     [validationWhitespace]="true"
                     [label]="'wizards:plan_name' | i18next"
                     [placeholder]="'wizards:plan_name_placeholder' | i18next"
                     name="planName"
                     formControlName="Name"></mbs-input>
        </ng-template>
      </mbs-radio>
    </mbs-form-group>

    <ng-container *ngIf="!isRestore">
      <mbs-form-group>
        <mbs-input [id]="'planName'"
                   [validationWhitespace]="true"
                   [label]="'wizards:plan_name' | i18next"
                   [placeholder]="'wizards:plan_name_placeholder' | i18next"
                   name="planName"
                   formControlName="Name"></mbs-input>
      </mbs-form-group>

      <ng-container *ngIf="!isNBF">
        <mbs-form-group>
          <mbs-checkbox [label]="'wizards:save_to_storage' | i18next"
                        [id]="'saveToStorage'"
                        [ngClass]="elementsSelector.css.planNameBackupCheckbox"
                        formControlName="SavePlanInCloud"></mbs-checkbox>
        </mbs-form-group>

        <mbs-alert class="mb-0 mt-3"
                   [type]="mbsPopupType.info"
                   [icon]="true">
          {{'wizards:plan_name_alert' | i18next}}
        </mbs-alert>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isFFIEnabled">
      <mbs-card [size]="'xs'">
        <div class="ml-0">
          <h5 class="mbs-wizard_form-title">
            <mbs-tag [clickable]="false"
                     [closable]="false"
                     [active]="true"
                     [noBorder]="true"
                     [class]="'background-red mt-n1'">
              {{'app:notifications:new' | i18next}}
            </mbs-tag>
            <span class="ml-2">{{ 'wizards:plan_name_ffi_title' | i18next: { format: 'title' } }}</span>
          </h5>
          <span class="text-xs">{{ 'wizards:plan_name_ffi_description' | i18next }}</span>
          <mbs-alert class="mt-3 text-sm"
                     [type]="mbsPopupType.info"
                     [icon]="true">
            {{'wizards:plan_name_ffi_info' | i18next}}
          </mbs-alert>
          <span class="text-xs">
            {{ 'wizards:plan_name_ffi_advice' | i18next }}
            <strong>
              {{ 'wizards:schedule_title' | i18next: { format: 'title', step: 7 } }}
            </strong>
            {{ 'wizards:plan_name_ffi_advice_postfix' | i18next }}
          </span>
          <a *ngIf="ability.can('read', 'HelpMarketing')"
             class="text-md mt-3 pl-0 nav-link"
             href="/AP/Help/backup/about/backup-format/ffi"
             target="_blank">
            {{ 'wizards:learn_more_link' | i18next }}
            <span class="ml-1 fa fa-external-link"></span>
          </a>
        </div>
      </mbs-card>
    </ng-container>
  </div>
</section>
