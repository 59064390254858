import { Inject, Injectable } from '@angular/core';
import { AvailablePlanTypes, KindPlanEnum } from '@models/backup/available-plan-types';
import Computer, { AgentType, AgentTypeAny, GetComputersParams, StorageConnection } from '@models/Computer';
import { AgentCommand } from '@models/ComputersModals';
import { InstallationAgents } from '@models/InstallAgent';
import { PlanFormatTypes, PlanTypes } from '@models/PlanTypes.enum';
import { AvailablePlanStartModes } from '../models/backup/available-plan-start-modes';
import { NetworkCredentials } from '../models/backup/network-credential';
import { PlanInfoData } from '../models/backup/plan-info-model';
import {
  InstallBuildsType,
  ParamsForceUpdate,
  RemoteCommandResponse,
  RemoteCommandType,
  RepositorySyncState,
  UpdateBuild
} from '@models/rm/remote-command-model';
import { isNil } from 'lodash';
import { map, Observable } from 'rxjs';
import { RmCommandsAbstractService } from './rm-commands.service';

export abstract class RmCommandsAbstractWrapper {
  abstract installPublicAgentByComputer(payload: { computer: Computer; appId: AgentType }): Observable<any>;
  abstract installPublicAgentByComputers(payload: { computers: Computer[]; appId: AgentType }): Observable<any>;
  abstract installAgent(payload: {
    computerHid: string;
    installedAgent: AgentType;
    builds: {
      appId: AgentType;
      versionType: InstallBuildsType;
    }[];
  }): Observable<any>;

  abstract updateAgent(payload: { computerHid: string; agentType: AgentType; versionType: InstallBuildsType }): Observable<any>;

  abstract uninstallAgentsByComputer(payload: { computer: Computer; appIds: string[] | AgentType[] }): Observable<any>;
  abstract uninstallAgentByComputer(payload: { computer: Computer; appId: string | AgentType }): Observable<any>;
  abstract uninstallAgent(payload: { computerHid: string; appId: string | AgentType }): Observable<any>;
  abstract uninstallAgents(payload: { computerHid: string; appIds: string[] | AgentType[] }): Observable<any>;

  abstract regenerateClientHID(payload: { computerHid: string; appId: AgentType }): Observable<unknown>;

  abstract getComputerPlans(hid: string): Observable<PlanInfoData>;
  abstract getServiceAccountName(computer: Computer, agentType: AgentType): Observable<string>;
  abstract changeServiceAccountName(
    computer: Computer,
    account: { accountName: string; accountPassword?: string },
    agentType: AgentType
  ): Observable<boolean>;
  abstract startPlan(computer: Computer, id: string, mode: AvailablePlanStartModes): Observable<boolean>;
  abstract stopPlan(computer: Computer, id: string): Observable<boolean>;
  abstract removePlan(computer: Computer, id: string): Observable<boolean>;
  abstract clonePlan(computer: Computer, id: string, name: string): Observable<boolean>;

  abstract getStorageConnections(profile: string, hid: string): Observable<{ data: Array<StorageConnection> }>;
  abstract saveStorageConnections(
    data: {
      storageId: string;
      passShare: string;
      loginShare: null;
      displayName: string;
      path: string;
      prefix: string;
    },
    hid: string
  ): Observable<{ data: string; ok: boolean }>;
  abstract deleteStorageConnection(profile: string, hid: string, storageId: string): Observable<{ data: boolean }>;
  abstract getNetworkCredentialsList(hid: string): Observable<NetworkCredentials[]>;
  abstract addNetworkCredentials(
    hid: string,
    params: { path: string; login: string; password: string; check: boolean }
  ): Observable<NetworkCredentials>;
  abstract deleteNetworkCredentials(hid: string, path: string): Observable<boolean>;
  abstract checkNetworkCredentials(hid: string, params: { path: string; login: string; password: string }): Observable<NetworkCredentials>;
  abstract getAvailablePlanTypes(hid: string): Observable<AvailablePlanTypes>;
  abstract groupForceUpdate(agentCommands: AgentCommand<ParamsForceUpdate>[]): Observable<any>;
  abstract bulkForceUpdate(filter: GetComputersParams, updateBuilds: UpdateBuild[]): Observable<any>;
  abstract bulkInstallPublicAgent(filter: GetComputersParams, appId: AgentType): Observable<any>;
  abstract bulkInstallAgent(filter: GetComputersParams, appId: AgentType, versionType: InstallBuildsType): Observable<any>;
  abstract switchWebSettings(hid: string, state: boolean): Observable<{ ok: boolean }>;
  abstract updateConnectConfig(filter: GetComputersParams, appId: AgentType): Observable<any>;
  abstract getSyncStatus(hid: string): Observable<{ state: RepositorySyncState }>;
}

@Injectable()
export class RmCommandsWrapper implements RmCommandsAbstractWrapper {
  constructor(@Inject(RmCommandsAbstractService) private rmCommands: RmCommandsAbstractService) {}

  installPublicAgentByComputer(payload: { computer: Computer; appId: AgentType }): Observable<any> {
    return this.installAgent({
      computerHid: payload.computer.hid,
      installedAgent: payload.computer.apps.find((app) => InstallationAgents.includes(app.applicationId)).applicationId,
      builds: [{ appId: payload.appId, versionType: InstallBuildsType.Public }]
    });
  }

  installPublicAgentByComputers(payload: { computers: Computer[]; appId: AgentType }): Observable<any> {
    return this.groupAgentInstall({
      computers: payload.computers.map((computer) => ({
        hid: computer.hid,
        installedAgent: computer.apps.find((app) => InstallationAgents.includes(app.applicationId)).applicationId
      })),
      builds: [{ appId: payload.appId, versionType: InstallBuildsType.Public }]
    });
  }

  installAgent(payload: {
    computerHid: string;
    installedAgent: AgentType;
    builds: {
      appId: AgentType;
      versionType: InstallBuildsType;
    }[];
  }): Observable<any> {
    return this.rmCommands.groupRemoteCommand({
      commandType: RemoteCommandType.InstallApp,
      agentCommands: [
        {
          computerHid: payload.computerHid,
          agentType: payload.installedAgent,
          params: {
            installBuilds: payload.builds
          }
        }
      ]
    });
  }

  groupAgentInstall(payload: {
    computers: { hid: string; installedAgent: AgentType }[];
    builds: {
      appId: AgentType;
      versionType: InstallBuildsType;
    }[];
  }): Observable<any> {
    return this.rmCommands.groupRemoteCommand({
      commandType: RemoteCommandType.InstallApp,
      agentCommands: payload.computers.map((computer) => {
        return {
          computerHid: computer.hid,
          agentType: computer.installedAgent,
          params: {
            installBuilds: payload.builds
          }
        };
      })
    });
  }

  updateAgent(payload: { computerHid: string; agentType: AgentType; versionType: InstallBuildsType }): Observable<any> {
    return this.rmCommands.groupRemoteCommand({
      commandType: RemoteCommandType.ForceUpdate,
      agentCommands: [
        {
          computerHid: payload.computerHid,
          agentType: payload.agentType,
          params: {
            updateBuilds: [
              {
                appId: payload.agentType,
                versionType: payload.versionType
              }
            ]
          }
        }
      ]
    });
  }

  uninstallAgentsByComputer(payload: { computer: Computer; appIds: string[] | AgentType[] }): Observable<any> {
    return this.uninstallAgents({ computerHid: payload.computer.hid, appIds: payload.appIds });
  }

  uninstallAgentByComputer(payload: { computer: Computer; appId: string | AgentType }): Observable<any> {
    return this.uninstallAgents({ computerHid: payload.computer.hid, appIds: [payload.appId] });
  }

  uninstallAgent(payload: { computerHid: string; appId: string | AgentType }): Observable<any> {
    return this.uninstallAgents({ computerHid: payload.computerHid, appIds: [payload.appId] });
  }

  uninstallAgents(payload: { computerHid: string; appIds: string[] | AgentType[] }): Observable<any> {
    return this.rmCommands.uninstallAgents({ hid: payload.computerHid, appIds: this.prepareAppIds(payload.appIds) });
  }

  regenerateClientHID(payload: { computerHid: string; appId: AgentType }): Observable<unknown> {
    return this.rmCommands.remoteCommandByHid(payload.computerHid, {
      agentType: payload.appId,
      commandType: RemoteCommandType.RegenerateClientHID
    });
  }

  getComputerPlans(hid: string): Observable<PlanInfoData> {
    return this.rmCommands
      .remoteCommandByHid<PlanInfoData>(hid, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.GetPlanInfoList,
        params: {} as any
      })
      .pipe(map((p) => p?.data || { planInfos: [], timeZoneOffset: 0 }));
  }

  getServiceAccountName(computer: Computer, agentType: AgentType = AgentType.Backup): Observable<string> {
    return this.rmCommands
      .remoteCommand<string>(computer, {
        agentType: agentType,
        commandType: RemoteCommandType.GetAccForBackupOnlineService
      })
      .pipe(map((r) => r.data));
  }

  changeServiceAccountName(
    computer: Computer,
    account: { accountName: string; accountPassword?: string },
    agentType: AgentType = AgentType.Backup
  ): Observable<boolean> {
    return this.rmCommands
      .remoteCommand<string>(computer, {
        agentType: agentType,
        commandType: RemoteCommandType.ChangeAccForBackupOnlineService,
        params: account
      })
      .pipe(map(Boolean));
  }

  startPlan(computer: Computer, id: string, mode: AvailablePlanStartModes = AvailablePlanStartModes.Regular): Observable<boolean> {
    return this.rmCommands
      .remoteCommand<string>(computer, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.StartPlan,
        params: { PlanId: id, Mode: mode }
      })
      .pipe(map(Boolean));
  }

  stopPlan(computer: Computer, id: string): Observable<boolean> {
    return this.rmCommands
      .remoteCommand<string>(computer, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.StopPlan,
        params: { PlanId: id, Force: false }
      })
      .pipe(map(Boolean));
  }

  removePlan(computer: Computer, id: string): Observable<boolean> {
    return this.rmCommands
      .remoteCommand<string>(computer, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.RemovePlan,
        params: { PlanId: id }
      })
      .pipe(map(Boolean));
  }

  clonePlan(computer: Computer, id: string, name: string): Observable<boolean> {
    return this.rmCommands
      .remoteCommand<string>(computer, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.ClonePlan,
        params: { PlanId: id, NewName: name }
      })
      .pipe(map(Boolean));
  }

  private prepareAppIds(agents: string[] | AgentType[]): string {
    return (agents as string[]).reduce((acc, item) => {
      return acc ? `appId=${item}` + `&${acc}` : `appId=${item}`;
    }, '');
  }

  getStorageConnections(profile: string, hid: string): Observable<RemoteCommandResponse<Array<StorageConnection>>> {
    return this.rmCommands.remoteCommandByHid<Array<StorageConnection>>(hid, {
      agentType: AgentType.Backup,
      commandType: RemoteCommandType.GetStorageConnections,
      params: { profile }
    });
  }

  saveStorageConnections(
    data: {
      storageId: string;
      passShare: string;
      loginShare: null;
      displayName: string;
      path: string;
      prefix: string;
    },
    hid: string
  ): Observable<RemoteCommandResponse<string>> {
    return this.rmCommands.remoteCommandByHid<string>(hid, {
      agentType: AgentType.Backup,
      commandType: RemoteCommandType.SaveFileSystemSettings,
      params: { ...data }
    });
  }

  deleteStorageConnection(profile: string, hid: string, storageId: string): Observable<RemoteCommandResponse<boolean>> {
    return this.rmCommands.remoteCommandByHid<boolean>(hid, {
      agentType: AgentType.Backup,
      commandType: RemoteCommandType.DeleteFileSystemStorage,
      params: { storageId: storageId, profile: profile }
    });
  }

  getNetworkCredentialsList(hid: string): Observable<NetworkCredentials[]> {
    return this.rmCommands
      .remoteCommandByHid<NetworkCredentials[]>(hid, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.GetNetworkCredentialsList,
        params: {} as any
      })
      .pipe(map((p) => (p && p.data) || []));
  }
  addNetworkCredentials(
    hid: string,
    params: { path: string; login: string; password: string; check: boolean }
  ): Observable<NetworkCredentials> {
    return this.rmCommands
      .remoteCommandByHid<NetworkCredentials>(hid, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.AddNetworkCredentials,
        params
      })
      .pipe(map((p) => p?.data));
  }
  deleteNetworkCredentials(hid: string, path: string): Observable<boolean> {
    return this.rmCommands
      .remoteCommandByHid<void>(hid, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.DeleteNetworkCredentials,
        params: {
          path
        } as any
      })
      .pipe(map(Boolean));
  }
  checkNetworkCredentials(hid: string, params: { path: string; login: string; password: string }): Observable<NetworkCredentials> {
    return this.rmCommands
      .remoteCommandByHid<NetworkCredentials>(hid, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.CheckNetworkCredentials,
        params
      })
      .pipe(map((p) => p?.data));
  }

  getAvailablePlanTypes(hid: string): Observable<AvailablePlanTypes> {
    return this.rmCommands
      .remoteCommandByHid<{ availablePlanTypes: AvailablePlanTypes }>(hid, {
        agentType: AgentType.Backup,
        commandType: RemoteCommandType.GetAvailablePlanTypes,
        params: {}
      })
      .pipe(
        map((p) => {
          if (p?.data?.availablePlanTypes?.length) {
            if (!isNil(p?.data?.availablePlanTypes[0].type)) {
              return p?.data?.availablePlanTypes.map((el) => {
                el.planType = el.type;
                return el;
              });
            }
            return p?.data?.availablePlanTypes.map((el) => ({
              kind: KindPlanEnum[el.kind],
              planType: PlanTypes[el.planType],
              format: PlanFormatTypes[el.format] || 0
            }));
          }
          return [];
        })
      );
  }

  groupForceUpdate(agentCommands: AgentCommand<ParamsForceUpdate>[]): Observable<any> {
    return this.rmCommands.groupRemoteCommand({
      commandType: RemoteCommandType.ForceUpdate,
      agentCommands
    });
  }

  bulkForceUpdate(filter: GetComputersParams, updateBuilds: UpdateBuild[]): Observable<any> {
    return this.rmCommands.bulkRemoteCommand({
      commandType: RemoteCommandType.ForceUpdate,
      filter,
      params: { updateBuilds }
    });
  }

  bulkInstallPublicAgent(filter: GetComputersParams, appId: AgentType): Observable<any> {
    return this.bulkInstallAgent(filter, appId, InstallBuildsType.Public);
  }

  bulkInstallAgent(filter: GetComputersParams, appId: AgentType, versionType: InstallBuildsType): Observable<any> {
    return this.rmCommands.bulkRemoteCommand({
      commandType: RemoteCommandType.InstallApp,
      filter,
      appIds: [AgentTypeAny],
      params: {
        installBuilds: [{ appId, versionType }]
      }
    });
  }

  switchWebSettings(hid: string, state: boolean): Observable<{ ok: boolean }> {
    return this.rmCommands.remoteCommandByHid(hid, {
      agentType: AgentType.Backup,
      commandType: RemoteCommandType.ConfigureService,
      params: { EnableWebSettings: state }
    });
  }

  updateConnectConfig(filter: GetComputersParams, appId: AgentType): Observable<any> {
    return this.rmCommands.bulkRemoteCommand({
      commandType: RemoteCommandType.UpdateConfig,
      filter,
      appIds: [appId]
    });
  }

  getSyncStatus(hid: string): Observable<{ state: RepositorySyncState }> {
    return this.rmCommands.getSyncStatus(hid);
  }
}
