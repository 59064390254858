<div class="mbs-form-group mb-0">
  <mbs-advanced-search [accessUrlQuery]="false"
                       [(ngModel)]="model"
                       (search)="updateFilters($event)"
                       (input)="handleInputSearch($event)">
  </mbs-advanced-search>
</div>

<mbs-table-nav [switcherView]="false"
               (pageChange)="handlePageChange($event)"
               (pageSizeChange)="handlePageSizeChange($event)"
               [showRefreshButton]="true"
               [showExportToCSVButton]="isModal"
               [exportButtonName]="'buttons:exportToCSV' | i18next"
               (export)="export(getTableName())"
               (refresh)="fetchData()">

  <ng-template mbsTableFilter="label">
    {{ 'rmm-side-panel:hardwareTab.hardware' | i18next }} {{ paginationOptions.dataSize }}
  </ng-template>


  <ng-template mbsTableControl="append"
               *ngIf="!isModal">
    <mbs-modal-tab-content modalTitle="Computer Hardware"
                           [showExpandedCross]="true">
      <ng-template>
        <mbs-hardware-tab [hid]="hid"
                          [computerName]="computerName"
                          [isModal]="true"></mbs-hardware-tab>
      </ng-template>
    </mbs-modal-tab-content>
  </ng-template>

</mbs-table-nav>

<mbs-table-grid class="ph-no-capture"
                [loading]="loading"
                [headers]="headers"
                [data]="data"
                (sort)="handleSort($event)"
                [changeSortState]="orderBy"
                [switcherView]="false"
                [paginationOptions]="paginationOptions"
                [viewMode]="sharedPersistentStateEnum.table"
                [myTrackBy]="trackBy"
                [maxHeight]="isModal || !isOnline ? '65vh' : '56vh'"
                [rotateSequence]="rotateSequence"
                [showNav]="false"
                [disableViewSwitch]="true"
                [noDataMessage]="'rmm-side-panel:noDataProvided' | i18next">

  <ng-template mbsTableCell
               *ngFor="let header of headers"
               let-item>

    <ng-template #tooltipOverflow>

      <div placement="auto"
           ngbTooltip="{{ item[header.sort] }}"
           container="body"
           tooltipClass="tooltip-lg text-left"
           class="text-overflow">
        {{ item[header.sort] }}
      </div>
    </ng-template>

    <ng-container *ngIf="!header.overflow; else tooltipOverflow">
      {{ item[header.sort] }}
    </ng-container>

  </ng-template>

</mbs-table-grid>
