import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbilityModule } from 'ability';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { NoComputersComponent } from './no-computers.component';

@NgModule({
  declarations: [NoComputersComponent],
  exports: [NoComputersComponent],
  imports: [CommonModule, I18NextModule, MbsUiKitModule, AbilityModule]
})
export class NoComputersModule {}
