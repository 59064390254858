/**
 * Synchronize interaction events in:
 * mbs-web#Admin\Iframe\IframeInteractionEvent.cs
 * mbs-web#Scripts\iframeUtils.js
 * mbs-angular#src\app\shared\services\angular-javascript-interaction.service.ts
 */
export enum InteractionEvent {
  IFRAME_SIDEPANEL_OPEN = 'IFRAME_SIDEPANEL_OPEN',
  IFRAME_SIDEPANEL_CLOSE = 'IFRAME_SIDEPANEL_CLOSE',
  PARENT_SIDEPANEL_OPEN = 'PARENT_SIDEPANEL_OPEN',
  PARENT_SIDEPANEL_CLOSE_START = 'PARENT_SIDEPANEL_CLOSE_START',
  PARENT_SIDEPANEL_CLOSE_END = 'PARENT_SIDEPANEL_CLOSE_END',
  RMM_SIDEPANEL_OPEN = 'RMM_SIDEPANEL_OPEN',
  RMM_MANAGEMENT_SIDEPANEL_OPEN = 'RMM_MANAGEMENT_SIDEPANEL_OPEN',
  RMM_TASKS_SIDEPANEL_OPEN = 'RMM_TASKS_SIDEPANEL_OPEN',
  SCHEDULED_RMM_GROUP_ACTION_OPEN = 'SCHEDULED_RMM_GROUP_ACTION_OPEN',
  RMM_GROUP_ACTION_OPEN = 'RMM_GROUP_ACTION_OPEN',
  IFRAME_IN_IFRAME = 'IFRAME_IN_IFRAME',
  REFRESH_CURRENT_USER = 'REFRESH_CURRENT_USER',
  GETTING_START_WIZARD_COMPLETE = 'GETTING_START_WIZARD_COMPLETE',
  CONFIRM_MODAL_SWITCH_UB = 'CONFIRM_MODAL_SWITCH_UB',
  SEND_LOGS_MODAL_OPEN = 'SEND_LOGS_MODAL_OPEN',
  UNINSTALL_AGENT_MODAL_OPEN = 'UNINSTALL_AGENT_MODAL_OPEN',
  FORCE_UPDATE_MODAL_OPEN = 'FORCE_UPDATE_MODAL_OPEN',
  CONFIRM_MODAL_UB_ACCESS_DENIED = 'CONFIRM_MODAL_UB_ACCESS_DENIED',
  TOAST_SHOW = 'TOAST_SHOW',
  UB_REFRESH_STATE = 'UB_REFRESH_STATE',
  UB_RESTORE_REMOVED = 'UB_RESTORE_REMOVED',
  UPDATE_STORAGE_LIMIT = 'UPDATE_STORAGE_LIMIT',
  PLANS_WIZARD_CLOSED = 'PLANS_WIZARD_CLOSED',
  SHOW_OVERDUE_MODAL = 'SHOW_OVERDUE_MODAL',
  UPDATE_QUERY_PARAMS = 'UPDATE_QUERY_PARAMS',
  PLANS_SIDEPANEL_OPEN = 'PLANS_SIDEPANEL_OPEN',
  REDIRECT_TO_COMPUTERS_WITHOUT_PARAMS = 'REDIRECT_TO_COMPUTERS_WITHOUT_PARAMS',
  UB_AWS_SHOW_CREDITS_STATE = 'UB_AWS_SHOW_CREDITS_STATE',
  /* If you have some global function in iframe, then you can try to call it with custom arguments. */
  CALL_GLOBAL_FUNCTION_IN_IFRAME = 'CALL_GLOBAL_FUNCTION_IN_IFRAME',
  TWO_FACTOR_AUTH_MODAL_OPEN = 'TWO_FACTOR_AUTH_MODAL_OPEN',
  TWO_FACTOR_AUTH_TURN_ON_MODAL_OPEN = 'TWO_FACTOR_AUTH_TURN_ON_MODAL_OPEN',
  ALTERNATIVE_CODES_RESET = 'ALTERNATIVE_CODES_RESET',
  DEVICES_PANEL_OPEN = 'DEVICES_PANEL_OPEN',
  OPEN_RD_CONNECT_MODAL = 'OPEN_RD_CONNECT_MODAL',
  REDIRECT_TO_APPS_AS_PROVIDER = 'REDIRECT_TO_APPS_AS_PROVIDER',
  UB_GET_DEBT = 'UB_GET_DEBT',
  INSTALL_DEEPINSTINCT = 'INSTALL_DEEPINSTINCT',
  CHANGE_EMAIL_MODAL_OPEN = 'CHANGE_EMAIL_MODAL_OPEN',
  ERROR_MODAL_OPEN = 'ERROR_MODAL_OPEN',
  BACKUP_SIDEPANEL_OPEN = 'BACKUP_SIDEPANEL_OPEN'
}
