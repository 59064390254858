import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { InstallSofwareSelectionTypes } from '@modules/schedule-group-action/store/group-action/group-action.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import SoftwareInfo from '@shared/models/rmm/SoftwareInfo';
import { TFARMMPermissionHelperService } from '@shared/services';
import { I18NextService } from 'angular-i18next';
import { ModalComponent, ModalService } from 'mbs-ui-kit';
import { filter, from } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-install-win-software-common',
  templateUrl: './install-win-software-common.html',
  styleUrls: ['./install-win-software-common.scss']
})
export class InstallWinSoftwareCommonComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;

  public hid: string;
  public modalTitle = 'Install Software';
  public wingetEnabled = true;

  public selectedSoftwares: any[] = [];
  public installLink = '';
  public installParams = '';
  public installDisabled = true;

  public modeSelectType = InstallSofwareSelectionTypes;
  public installationModeFormType: FormGroup = new FormGroup({
    modeSelect: new FormControl(this.modeSelectType.usingWinget)
  });

  public fromFileLabel: string;
  public fromLibraryLabel: string;

  constructor(
    private fb: FormBuilder,
    private i18n: I18NextService,
    private modalService: ModalService,
    private tfaRMMPermissionHelper: TFARMMPermissionHelperService
  ) {}

  setupFormLabels(value: string): void {
    this.fromLibraryLabel = this.i18n.t('rmm.module:groupActions.stepConfigureAction.scriptFromLibraryLabel', { value });
    this.fromFileLabel = this.i18n.t('rmm.module:groupActions.stepConfigureAction.scriptFromFileLabel', { value });
  }

  ngOnInit(): void {
    this.hid = this.baseModal.data.hid;
    this.wingetEnabled = this.baseModal.data.wingetEnabled;
    this.installationModeFormType
      .get('modeSelect')
      .setValue(this.wingetEnabled ? this.modeSelectType.usingWinget : this.modeSelectType.usingLink);

    this.installationModeFormType
      .get('modeSelect')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value == InstallSofwareSelectionTypes.usingWinget) {
          this.installDisabled = !this.selectedSoftwares.length;
        } else {
          this.installDisabled = !this.installLink || !this.installParams;
        }
      });
  }

  modeSelected(modeSelectType: InstallSofwareSelectionTypes) {
    return modeSelectType === this.installationModeFormType.get('modeSelect').value;
  }

  setModeSelect(modeSelectType: InstallSofwareSelectionTypes) {
    this.installationModeFormType.get('modeSelect').setValue(modeSelectType);
  }

  updateSelectedSoftware(softwares: SoftwareInfo[]) {
    this.selectedSoftwares = [...softwares];
    if (this.installationModeFormType.get('modeSelect').value === InstallSofwareSelectionTypes.usingWinget)
      this.installDisabled = !this.selectedSoftwares.length;
  }

  updateInstallLinkParams(data: { link: string; parameters: string }) {
    this.installLink = data.link;
    this.installParams = data.parameters;
    if (this.installationModeFormType.get('modeSelect').value === InstallSofwareSelectionTypes.usingLink)
      this.installDisabled = !this.installLink || !this.installParams;
  }

  public sendCommandAfterTwoFA(): void {
    from(this.tfaRMMPermissionHelper.is2FAPassedStream([this.hid], null))
      .pipe(
        filter((confirmed) => confirmed),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.baseModal.save({
          isWinget: this.installationModeFormType.get('modeSelect').value === InstallSofwareSelectionTypes.usingWinget,
          selected: this.selectedSoftwares.map((item) => ({ ...item, packageID: item.id })),
          link: this.installLink,
          params: this.installParams
        });
      });
  }

  public closeModal(): void {
    this.baseModal.close();
  }
}
