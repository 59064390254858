import { Injectable } from '@angular/core';
import { Agent, AgentState, AgentType } from '@models/Computer';
import SummaryStat from '@models/rmm/SummaryStat';
import * as AppsActions from '@modules/computer-apps/store/computer-apps.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RmmService } from '@services/rmm.service';
import { get } from 'lodash/fp';
import { catchError, EMPTY, of } from 'rxjs';
import { concatMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as SummaryActions from './summary-computer.actions';
import { SummaryComputer } from './summary-computer.model';
import * as SummarySelectors from './summary-computer.selectors';

@Injectable()
export class SummaryComputerEffects {
  loadOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SummaryActions.loadSummaryComputer),
      mergeMap(({ hid, optional }) => {
        let app: Agent;

        if (optional) {
          const apps: Agent[] = get(['apps'], optional) || [];
          app = apps.find((app) => app.applicationId === AgentType.RMM);
        }

        return this.rmmService.fetchLastData<SummaryStat>('summary', hid).pipe(
          catchError((error) => {
            return of(SummaryActions.handleLoadError({ error }));
          }),
          mergeMap((data: any) => {
            const summary: SummaryStat = get(['data', 0], data) || {};

            const summaryComputer: SummaryComputer = {
              id: hid,
              timestamp: new Date(),
              loading: false,
              online: data.isOnline,
              ...(app ? { monitoring: app.applicationState === AgentState.Enabled } : {}),
              ...summary
            };
            return of(SummaryActions.loadSummaryComputerSuccess({ summaryComputer }));
          })
        );
      })
    );
  });

  onSetCurrentComputer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SummaryActions.setCurrentComputer),
      withLatestFrom(this.store.select(SummarySelectors.selectCurrent)),
      mergeMap(([action, summary]) => {
        if (!action.current || !action.current.hid) {
          return EMPTY;
        }
        return summary ? EMPTY : of(SummaryActions.loadSummaryComputer({ hid: action.current.hid }));
      })
    );
  });

  monitoring$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppsActions.changeMonitoringState),
      concatMap(({ hid, monitoring, appType }) => {
        if (appType === AgentType.RMM) {
          return of(
            SummaryActions.upsertSummaryComputer({
              summaryComputer: { id: hid, monitoring: monitoring === AgentState.Enabled } as SummaryComputer
            })
          );
        }
        return EMPTY;
      })
    );
  });

  constructor(private store: Store, private actions$: Actions, private rmmService: RmmService) {}
}
