import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getTilesConfig } from '@modules/wizards/onboarding/onboarding.helpers';
import { OnboardingMode } from '@modules/wizards/services/onboarding/onboarding.constants';
import { OnboardingService } from '@modules/wizards/services/onboarding/onboarding.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { I18NextPipe } from 'angular-i18next';
import { ToastService } from 'mbs-ui-kit';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { FinishPath, OnboardingView } from './onboarding.constants';

SwiperCore.use([Navigation, Pagination]);

@UntilDestroy()
@Component({
  selector: 'mbs-onboarding-wizard',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingWizardComponent implements OnInit {
  @Input() mode: OnboardingMode;
  @Output() criticalError = new EventEmitter<boolean>();

  readonly view = OnboardingView;
  readonly tilesConfig = getTilesConfig(this.i18N);
  readonly finishPath = FinishPath;

  public selectedView: OnboardingView = null;
  public step = null;
  public loading = true;

  constructor(
    private onboardingService: OnboardingService,
    private i18N: I18NextPipe,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    const flow = localStorage.getItem('onboardingFlow') as OnboardingView;
    this.step = localStorage.getItem('onboardingStep');

    if (Object.values(OnboardingView).includes(flow)) {
      this.loading = false;
      this.selectedView = flow;

      localStorage.removeItem('onboardingFlow');
      localStorage.removeItem('onboardingStep');

      return;
    }

    this.onboardingService
      .initialize()
      .pipe(
        untilDestroyed(this),
        switchMap(() =>
          this.onboardingService.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone).pipe(catchError(() => of(null)))
        )
      )
      .subscribe({
        next: () => {
          this.loading = false;
        },
        error: () => this.onboardingService.hasError$.next(true)
      });

    this.onboardingService.hasError$.pipe(untilDestroyed(this)).subscribe((hasError: boolean) => {
      hasError && this.criticalError.emit(true);
    });
  }

  public finish(redirectUri: string): void {
    this.onboardingService.complete().subscribe({
      next: () => {
        window.location.href = redirectUri;
      },
      error: () => {
        this.toastService.error(
          `${this.i18N.transform('error:type.critical.title')} ${this.i18N.transform('error:type.critical.description')}`
        );

        this.onboardingService.stable$.next(true);
      }
    });
  }

  public onViewChoiceButtonClick(view: OnboardingView): void {
    this.selectedView = view;
  }
}
