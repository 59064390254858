<ng-template #buildDescription
             let-build>
  <ng-container [ngSwitch]="build.buildType">
    <div *ngSwitchCase="buildType.CommonWindowsBuild"
         class="small text-left">
      <p class="mb-0">
        {{ 'sidepanel-downloads:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row mbs-download-build_tooltip-list">
        <li class="col-auto"
            *ngFor="let item of windowsSupported">
          {{ item }}
        </li>
      </ul>
      <p class="mt-1 mb-0">
        {{ 'builds:windows.can_backup_description' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row mbs-download-build_tooltip-list">
        <li class="col-auto"
            *ngFor="let item of windowsCanBackup">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngSwitchCase="buildType.LinuxUbuntu14"
         class="small text-left">
      <p class="mb-0">
        {{'builds:linux_deb.supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row mbs-download-build_tooltip-list">
        <li class="col-auto"
            *ngFor="let item of linuxDebSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngSwitchCase="buildType.LinuxRedHat6"
         class="small text-left">
      <p class="mb-0">
        {{'builds:linux_rpm.supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row mbs-download-build_tooltip-list">
        <li class="col-auto"
            *ngFor="let item of linuxRpmSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngSwitchCase="buildType.LinuxMac"
         class="small text-left">
      <p class="mb-0">
        {{'sidepanel-downloads:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row mbs-download-build_tooltip-list">
        <li class="col-auto"
            *ngFor="let item of macOsSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngSwitchCase="buildType.RMM"
         class="small text-left">
      <p class="mb-0">
        {{'sidepanel-downloads:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row mbs-download-build_tooltip-list">
        <li class="col-auto"
            *ngFor="let item of windowsRmmSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngSwitchCase="buildType.RMMAgentMac"
         class="small text-left">
      <p class="mb-0">
        {{'sidepanel-downloads:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row mbs-download-build_tooltip-list">
        <li class="col-auto"
            *ngFor="let item of macRmmSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngSwitchCase="buildType.RMMAgentLinuxDeb"
         class="small text-left">
      <p class="mb-0">
        {{'sidepanel-downloads:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row mbs-download-build_tooltip-list">
        <li class="col-auto"
            *ngFor="let item of linuxDebRmmSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngSwitchCase="buildType.RMMAgentLinuxRpm"
         class="small text-left">
      <p class="mb-0">
        {{'sidepanel-downloads:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row mbs-download-build_tooltip-list">
        <li class="col-auto"
            *ngFor="let item of linuxRpmRmmSupported">
          {{ item }}
        </li>
      </ul>
    </div>
  </ng-container>
</ng-template>

<div class="mbs-download-build"
     [class.border-top]="view === installationView.Computers">
  <ng-container *ngIf="!initialLoading; else loader">
    <div class="mx-auto w-75"
         [class.pt-4]="view === installationView.Computers || view === installationView.Rmm">
      <h4 *ngIf="currentBuild$.getValue() || view === installationView.Computers || view === installationView.Rmm"
          class="mt-1">{{'wizards:installationStep.downloadAgent' | i18next: { format: 'title', type: getTypeByView(view) } }}</h4>

      <ng-container *ngIf="buildsSelectionConfig?.length > 1">
        <mbs-form-group>
          <mbs-select [items]="buildsSelectionConfig"
                      [searchable]="false"
                      size="lg"
                      [(ngModel)]="selectedBuild"
                      [clearable]="false"
                      bindLabel="name"
                      class="mbs-download-build_build-select"
                      (change)="onSelectedBuildChange($event)"
          >
            <ng-template ng-label-tmp
                         let-item="item">
            <span [ngClass]="item.iconClass"
                  class="mbs-download-build_logo-icon"></span>
              <span class="text-base font-weight-semibold mr-1">{{item.name}}</span>
              <span #tooltip="ngbTooltip"
                    (mouseenter)="handleOpenBuildTooltip(tooltip, selectedBuild)"
                    (mouseleave)="handleCloseBuildTooltip(tooltip)"
                    [ngbTooltip]="buildDescription"
                    class="ico ico-InfoCircle cursor-pointer text-primary text-base px-1"
                    container="body"
                    triggers="manual"
                    [tooltipClass]="'tooltip-lg'"></span>
            </ng-template>

            <ng-template ng-option-tmp
                         let-item="item">
        <span [ngClass]="item.iconClass"
              class="mbs-download-build_logo-icon"></span>
              <span class="text-base">{{item.name}}</span>
            </ng-template>
          </mbs-select>
        </mbs-form-group>
      </ng-container>

      <ng-container *ngIf="currentBuild$.getValue()">
        <div class="row justify-content-end align-items-center"
             [class.flex-row-reverse]="view === installationView.HyperV">
          <div class="col-auto">
            <mbs-button [isCtrl]="true"
                        (click)="copyDownloadLink(this.getDownloadLink())"
                        [icon]="'fa fa-link'"> {{'wizards:installationStep.copyLink' | i18next }} </mbs-button>
          </div>
          <div class="col-auto">
            <mbs-button type="primary"
                        (click)="downloadBuild()"> {{'common.downloadBuild' | i18next}} </mbs-button>
          </div>
        </div>

        <h4 class="mbs-download-build_title"
            [class.mt-5]="view === installationView.HyperV">{{'wizards:installationStep.installAgentTitle' | i18next: {
          format: 'title',
          type: getTypeByView(view)
        } }}</h4>

        <p class="pre-wrap">{{'wizards:installationStep.installAgentDescription.' + view | i18next}}</p>

        <ng-container *ngIf="view === installationView.HyperV">
          <div class="d-flex flex-column">
            <a href="https://help.mspbackups.com/getting-started/vmware"
               class="mb-2"
               target="_blank">
              {{'wizards:installationStep.gettingStartedWith.vmware' | i18next }}
              <i class="fa fa-external-link"></i>
            </a>

            <a href="https://help.mspbackups.com/getting-started/hyper-v"
               target="_blank">
              {{'wizards:installationStep.gettingStartedWith.hyper-v' | i18next }}
              <i class="fa fa-external-link"></i>
            </a>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!currentBuild$.getValue() || view === installationView.Computers || view ===
    installationView.Rmm">
        <div class="d-flex align-items-center justify-content-center flex-column mt-5">
          <span class="loader loader-lg"></span>

          <span class="mt-2">
        {{(currentBuild$.getValue() ? 'wizards:installationStep.installationStatus' :
            'wizards:installationStep.buildIsGenerated') | i18next}}
      </span>

          <span *ngIf="!currentBuild$.getValue()"> {{'wizards:installationStep.wait' | i18next}} </span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #loader>
    <mbs-loader></mbs-loader>
  </ng-template>
</div>
