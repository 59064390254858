<div class="d-flex align-items-center justify-content-start flex-column mb-4 position-relative"
     [ngStyle]="{ 'min-height': '230px' }"
     [ngClass]="{
       'justify-content-center': isQRCodeInvalid
     }">

  <ng-container *ngIf="QRCodeUri">
    <img id="google-qr-code"
         src="{{ QRCodeUri }}"
         alt="QR code" />
    {{ 'tfa:googleCode:setupKey' | i18next }}
    <div class="font-weight-semibold">
      {{ setupKey }}
    </div>
  </ng-container>

  <ng-container *ngIf="isQRCodeInvalid">
    {{ error }}
    <mbs-button [isCtrl]="true"
                (click)="setQRCode()"
                class="mt-2">
      {{ 'tfa:mspQRCode:requestNewCode' | i18next }}
    </mbs-button>
  </ng-container>

  <mbs-loader *ngIf="isQRCodeLoading"></mbs-loader>

</div>

<span class="font-weight-semibold">
  {{ 'tfa:googleCode:enableTwoFactorAuth' | i18next }}
</span>

<ol class="pl-4">
  <li class="mt-2">
    {{ 'tfa:googleCode:openGooglePlay' | i18next }}
  </li>
  <li class="mt-2">
    {{ 'tfa:googleCode:selectTFAAuthenticator' | i18next }}
  </li>
  <li class="mt-2">
    {{ 'tfa:googleCode:installApplication' | i18next }}
  </li>
  <li class="mt-2">
    {{ 'tfa:googleCode:onceApplicationIsInstalled' | i18next }}
  </li>
</ol>

<mbs-alert [type]="alertType.info"
           [icon]="true"
           *ngIf="isFirstTimeLogin"
           class="mt-3">
  {{ authService.currentUserValue?.IsProvider ? ('tfa:canConfigureTfaAtAnyTime' | i18next) : ('tfa:canConfigureTfaAtAnyTimeInMyProfile' | i18next) }}
</mbs-alert>

<div class="form-row align-items-end">
  <div class="col-10">
    <mbs-input [id]="'verification-code'"
               [label]="'2FA Verification Code'"
               (keyup.enter)="validate()"
               autocomplete="off"
               mbsAutoFocus
               [(ngModel)]="code"></mbs-input>
    <span id="invalid-code"
          *ngIf="isInvalidCode"
          class="small text-danger mb-2 position-absolute">
      Invalid code
    </span>
  </div>
  <div class="col-2">
    <mbs-button [id]="'google-validate-button'"
                [block]="true"
                type="primary"
                (click)="validate()"
                [loading]="isLoading">
      {{ 'tfa:googleCode:validate' | i18next }}
    </mbs-button>
  </div>
</div>

<div class="d-flex align-items-center mt-4"
     [ngClass]="{ 'justify-content-between': isFirstTimeLogin, 'justify-content-center': !isFirstTimeLogin }">

  <mbs-button [isCtrl]="true"
              icon="fa fa-refresh"
              (click)="toggleAuthType()">
    {{ 'tfa:googleCode:useMSPApplication' | i18next }}
  </mbs-button>

  <mbs-button *ngIf="isLogin"
              [isCtrl]="true"
              icon="fa fa-sign-out"
              class="ml-2"
              (click)="toLoginPage()">
    {{ 'tfa:signInWithAnotherAccount' | i18next }}
  </mbs-button>

  <mbs-button *ngIf="isFirstTimeLogin"
              [type]="'secondary'"
              (click)="skip()">
    {{ 'tfa:skip' | i18next: { format: 'title' } }}
  </mbs-button>

</div>
