import { I18NextPipe } from 'angular-i18next';
import { NotifyTimeType } from './update-policy.models';

export const parseDeferTimeFromGroupTask = (data: string): { deferTime: string; deferType: NotifyTimeType } => {
  const { days, hours, minutes } = getRegExpMatchesFromTimeSpanFormat(data);
  const deferType = days ? NotifyTimeType.Days : NotifyTimeType.Hours;

  switch (deferType) {
    case NotifyTimeType.Days:
      return { deferTime: days, deferType };
    case NotifyTimeType.Hours:
      return { deferTime: hours, deferType };
    default:
      return { deferTime: '1', deferType: NotifyTimeType.Hours };
  }
};

export const parseRebootIfRequiredTime = (data: string): string => {
  const { days, hours, minutes } = getRegExpMatchesFromTimeSpanFormat(data);
  if (!hours || !minutes) return null;
  return `${hours}:${minutes}`;
};

export const parseRequiredNotifyTimeItems = (data: string, i18next: I18NextPipe): string => {
  const { days, hours, minutes } = getRegExpMatchesFromTimeSpanFormat(data);
  const deferType = days ? NotifyTimeType.Days : NotifyTimeType.Hours;

  switch (deferType) {
    case NotifyTimeType.Hours:
      return Number(hours) === 1
        ? i18next.transform('rmm.module:groupActions.updatePolicy.notificationHourType')
        : i18next.transform('rmm.module:groupActions.updatePolicy.notificationHoursType', { value: hours });
    case NotifyTimeType.Days:
    default:
      return i18next.transform('rmm.module:groupActions.updatePolicy.notificationNoType')
  }
};

export const getRegExpMatchesFromTimeSpanFormat = (data: string): { days: string; hours: string; minutes: string } => {
  const timeSpanRegExp = /((.*)\.)?(.*):(.*):(.*)/gm;

  if (!data) return { days: null, hours: null, minutes: null };

  const result = timeSpanRegExp.exec(data);

  const days = Number(result[2]) ? result[2] : null;
  const hours = Number(result[3]) ? result[3] : null;
  const minutes = Number(result[4]) ? result[4] : null;

  return { days: days, hours: hours, minutes: minutes };
};
