import saveAs from 'file-saver';

export interface CsvTemplate {
  cols: string[];
  rows: {
    [key: string]: string;
  }[];
}

export const generateCsvTable = (template: CsvTemplate, separator = ',', head = true) => {
  const output = [];

  const header = `"sep=${separator}"`;

  if (head) {
    output.push(header);
  }
  output.push(template.cols.join(separator));

  for (const tr of template.rows) {
    const row = [];
    for (const thr of template.cols) {
      row.push(normalize(tr[thr]));
    }

    output.push(row.join(separator));
  }

  return output.join(`\n`);
};

function normalize(str: string) {
  return `${str}`.replaceAll(/[^\d\w~?!.,;:@$%^&*()_+-=\s\\\\|\\/']/g, '');
}

export const exportToCsv = (tableName: string, columnNames: string[], dataKeyNames: string[], data: any, separator = ',') => {
  const rows = data.map((element) => {
    return dataKeyNames
      .flatMap((dataKeyNames) => (element[dataKeyNames] ? [normalize(`${element[dataKeyNames]}`?.replaceAll(separator, ''))] : ''))
      .join(separator);
  });

  const output = columnNames.join(separator) + '\n' + rows.join('\n');

  const blob = new Blob([output], { type: 'text/csv' });
  saveAs(blob, `${tableName}.csv`);
};
