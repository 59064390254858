import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ComputersSharedModule } from '@components/computers-shared/computers-shared.module';
import { ComputersFacade } from '@facades/computers.facade';
import { RmmDateTimeFormatModule } from '@components/date-time-format/rmm-date-time-format.module';
import { RMMUtilityFeaturesModule } from '@modules/rmm-utility-features/rmm-utility-features.module';
import { CommandService } from '@modules/rmm/services/rmm-command.service';
import { SummaryComputersModule } from '@modules/summary-computers/summary-computers.module';
import { TerminalEmulatorModule } from '@modules/terminal-emulator/terminal-emulator.module';
import { TerminalNewModule } from '@modules/terminal-new/terminal-new.module';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { RmmSoftwareService, TFARMMPermissionHelperService } from '@services';
import { RmmPowershellService } from '@services/rmm-powershell.service';
import { RmmRegEditService } from '@services/rmm-regedit.service';
import { RmmWebsocketService } from '@services/rmm-websocket.service';
import { RmmService } from '@services/rmm.service';
import { UtilsModule } from '@utils/utils.module';
import { AppPersistentStateWrapper } from '@wrappers/app.persistent.state.wrapper';
import { I18NextModule, I18NEXT_NAMESPACE } from 'angular-i18next';
import { BreadcrumbsModule, MbsUiKitModule, TreeModule } from 'mbs-ui-kit';
import { NgTerminalModule } from 'ng-terminal';
import { NgChartsModule, ThemeService } from 'ng2-charts';
import { AntivirusTabComponent } from './antivirus-tab/antivirus-tab.component';
import { CommandTabComponent } from './command-tab/command-tab.component';
import { ErrorsTabComponent } from './errors-tab/errors-tab.component';
import { EventTotalTabModule } from './event-total-tab/event-total-tab.module';
import { SidepanelRmmGeneralTabComponent } from './general-tab/sidepanel-rmm-general-tab.component';
import { HardwareTabComponent } from './hardware-tab/hardware-tab.component';
import { HddSmartTabComponent } from './hdd-smart-tab/hdd-smart-tab.component';
import { HddTabComponent } from './hdd-tab/hdd-tab.component';
import { HddVolumeInfoComponent } from './hdd-tab/hdd-volume-info/hdd-volume-info.component';
import { HotfixesAvailableTabComponent } from './hotfixes-available-tab/hotfixes-available-tab.component';
import { HotfixesInstalledTabComponent } from './hotfixes-installed-tab/hotfixes-installed-tab.component';
import { HypervTabComponent } from './hyperv-tab/hyperv-tab.component';
import { JsonViewerTabComponent } from './json-viewer-tab/json-viewer-tab.component';
import { ModalTabContentModule } from './modal-tab-content/modal-tab-content.module';
import { SidepanelRmmPCInfoTabComponent } from './pc-info-tab/sidepanel-rmm-pc-info-tab.component';
import { PowershellTerminalTabComponent } from './powershell-terminal-tab/powershell-terminal-tab.component';
import { PrintersTabComponent } from './printers-tab/printers-tab.component';
import { ProcessesChartsTabComponent } from './processes-charts-tab/processes-charts-tab.component';
import { RemoteRegistryTabComponent } from './remote-registry-tab/remote-registry-tab.component';
import { RmmQuickToolsComponent } from './rmm-quick-tools/rmm-quick-tools.component';
import { RuntimeTabComponent } from './runtime-tab/runtime-tab.component';
import { ServicesTabComponent } from './services-tab/services-tab.component';
import { SidepanelRmmComponent } from './sidepanel-rmm.component';
import { SoftwareTabComponent } from './software-tab/software-tab.component';
import { SqlServerTabComponent } from './sql-server-tab/sql-server-tab.component';
import { TwoFactorModule } from './two-factor-auth-confirm/two-factor.module';
import { AbilityModule } from 'ability';

@NgModule({
  declarations: [
    SidepanelRmmComponent,
    SidepanelRmmGeneralTabComponent,
    SidepanelRmmPCInfoTabComponent,
    JsonViewerTabComponent,
    SoftwareTabComponent,
    ServicesTabComponent,
    HardwareTabComponent,
    HddSmartTabComponent,
    HotfixesInstalledTabComponent,
    HotfixesAvailableTabComponent,
    PrintersTabComponent,
    RuntimeTabComponent,
    ErrorsTabComponent,
    HddTabComponent,
    AntivirusTabComponent,
    HypervTabComponent,
    SqlServerTabComponent,
    HddVolumeInfoComponent,
    CommandTabComponent,
    ProcessesChartsTabComponent,
    RemoteRegistryTabComponent,
    RmmQuickToolsComponent,
    PowershellTerminalTabComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    StoreModule,
    MbsUiKitModule,
    AbilityModule,
    UtilsModule,
    NgSelectModule,
    NgbTooltipModule,
    TreeModule,
    BreadcrumbsModule,
    NgbModule,
    NgChartsModule,
    NgTerminalModule,
    EventTotalTabModule,
    ModalTabContentModule,
    RmmDateTimeFormatModule,
    ComputersSharedModule,
    I18NextModule,
    TerminalEmulatorModule,
    SummaryComputersModule,
    TwoFactorModule,
    RMMUtilityFeaturesModule,
    TerminalNewModule
  ],
  exports: [SidepanelRmmComponent],
  providers: [
    RmmService,
    CommandService,
    RmmSoftwareService,
    RmmRegEditService,
    ThemeService,
    RmmWebsocketService,
    RmmPowershellService,
    TFARMMPermissionHelperService,
    ComputersFacade,
    AppPersistentStateWrapper,
    {
      provide: I18NEXT_NAMESPACE,
      useValue: ['rmm-side-panel']
    }
  ]
})
export class SidepanelRmmModule {}
