<ng-container>
  <mbs-accordion (changeHeader)="onBuildPanelClick($event)"
                 [align]="align"
                 [activeIds]="activeIds"
                 [withoutBorder]="true"
                 [narrowHeader]="true">
    <mbs-accordion-panel [id]="panelId">
      <span [ngClass]="getIcon(build)"
            *panelIconHeader></span>
      <ng-container panelTitle>
        <span class="d-block lh-1 mb-2">
          <span class="text-md"> {{ build.name }}
          </span>
          <span *ngIf="beta"
                class="text-cyan text-xs">{{ 'app:beta' | i18next: { format: 'title' } }}</span>
          <i class="ml-2 fa fa-caret-down"
             aria-hidden="true"></i>
        </span>

        <span class="d-block lh-1 text-xs font-weight-normal mb-1">
          {{ getPublicVersionName( build ) }}
        </span>
      </ng-container>

      <ng-container panelAppendHeader>
        <ng-container *ngIf="build?.public; else noPublicBuild">
          <div [ngbTooltip]="getDisabledByMinimalVersionSupportsCompany(build.public, build.mbsBuildType)
                        ? ('download-build.module:versionIsUnsupportedByCompany' | i18next)
                        : ''"
               container="body">
            <mbs-button type="primary"
                        [disabled]="getDisabledByMinimalVersionSupportsCompany(build.public, build.mbsBuildType)"
                        [loading]="downloadBuildInProgress?.notSandbox"
                        (click)="onDownloadButtonClick(build, false)">
              {{ 'common.download' | i18next: { format: 'title' } }}
            </mbs-button>
          </div>

          <ng-container [ngTemplateOutlet]="dropdown"
                        [ngTemplateOutletContext]="{ isSandbox: false, index: index }"></ng-container>
        </ng-container>

        <ng-template #noPublicBuild>
          <ng-container *ngIf="(PermissionsEnum.Rebranding | can : 'read')">
            <mbs-button type="secondary"
                        [disabled]="isOneOfTheBuildsIsInProgress()"
                        [loading]="isBuildInProgress(build)"
                        (click)="generateBuild(build)">
              {{ 'buttons:generate' | i18next: { format: 'title' } }}
            </mbs-button>
          </ng-container>
        </ng-template>
      </ng-container>

      <ng-container [ngTemplateOutlet]="buildDescription"
                    [ngTemplateOutletContext]="{ build }"></ng-container>
    </mbs-accordion-panel>
  </mbs-accordion>
</ng-container>

<ng-container *ngIf="build.sandbox && isSandboxEnabled">
  <div class="mt-4 d-flex justify-content-between"
       [class.ml-3]="align !== 'left'">
    <div>
      <mbs-tag [noBorder]="true"
               class="background-warning"
               [active]="true"> {{
          'download-build.module:versions.sandbox' | i18next: { format: 'title' }
        }}
      </mbs-tag>

      <span class="ml-2 mr-2">v{{ build.sandbox.version }}</span>
    </div>

    <div class="d-flex">
      <mbs-button *ngIf="(PermissionsEnum.Rebranding | can : 'read')"
                  [disabled]="isOneOfTheBuildsIsInProgress()"
                  [loading]="makePublicButtonLoadingId === build.mbsBuildType"
                  (click)="makeBuildPublic(build)">
        {{ 'download-build.module:versions.makePublic' | i18next: { format: 'title' } }}
      </mbs-button>

      <div [ngbTooltip]="getDisabledByMinimalVersionSupportsCompany(build.sandbox, build.mbsBuildType)
                        ? ('download-build.module:versionIsUnsupportedByCompany' | i18next)
                        : ''"
           container="body">
        <mbs-button type="primary"
                    class="ml-3"
                    [loading]="downloadBuildInProgress?.sandbox"
                    [disabled]="getDisabledByMinimalVersionSupportsCompany(build.sandbox, build.mbsBuildType)"
                    (click)="onDownloadButtonClick(build, true)">
          {{ 'common.download' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>

      <ng-container [ngTemplateOutlet]="dropdown"
                    [ngTemplateOutletContext]="{ isSandbox: true, index: index }"></ng-container>
    </div>
  </div>
</ng-container>

<ng-container [ngTemplateOutlet]="newBuildSection"
              [ngTemplateOutletContext]="{ section: getNewBuildSection(build), isSandbox: isSandboxEnabled }">
</ng-container>

<ng-template #dropdown
             let-isSandbox="isSandbox"
             let-index="index">
  <div class="btn-group"
       [ngbTooltip]="getDisabledByMinimalVersionSupportsCompany(isSandbox ? build.sandbox : build.public, build.mbsBuildType)
                        ? ('download-build.module:versionIsUnsupportedByCompany' | i18next)
                        : ''"
       ngbDropdown
       container="body"
       placement="auto">
    <mbs-button type="secondary"
                [disabled]="getDisabledByMinimalVersionSupportsCompany(isSandbox ? build.sandbox : build.public, build.mbsBuildType)"
                [square]="true"
                class="-no-arrow ml-3"
                [ngbTooltip]="copyTooltipTemplate"
                placement="left"
                container="body"
                [autoClose]="false"
                triggers="manual"
                [tooltipClass]="BUILD_ACTIONS_TRIGGER_BUTTON_TOKEN + (isSandbox ? 'sandbox' : 'public') +
                        index + ' -no-arrow'"
                #copyTooltip="ngbTooltip"
                ngbDropdownToggle>
      <span class="ico ico-EllipsisV px-1"></span>
    </mbs-button>

    <ng-template #copyTooltipTemplate>
      <div class="row px-3 py-1">
        <span *ngIf="currentCopingStatus === copyingStatus.Copying"
              class="loader loader-primary mr-2"></span>
        <span class="text-md">{{ copyingTooltipText | async }}</span>
      </div>
    </ng-template>

    <div ngbDropdownMenu
         aria-labelledby="download-sidepanel-build-actions">
      <button ngbDropdownItem
              (click)="onCopyDownloadLinkButtonClick(build, isSandbox, index)">
        {{ 'buttons:copyLink' | i18next: { format: 'title' } }}
      </button>

      <button ngbDropdownItem
              *ngIf="isBuildSupportCommand(build)"
              (click)="onCopyCommandForDeployBuildClick(build, isSandbox, index)">
        {{ 'download-build.module:copyInstallationScript' | i18next: { format: 'title' } }}
      </button>

      <button ngbDropdownItem
              *ngIf="currentUser?.IsProvider ? showDeleteButton : (PermissionsEnum.Rebranding | can: 'read') && showDeleteButton"
              (click)="onRemoveBuildButtonClick(build, isSandbox, deleteConfirmTemplate)">
        {{ 'download-build.module:deleteInstallationPackage' | i18next: { format: 'title' } }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirmTemplate
             let-context>
  <p>
    {{
      'download-build.module:deleteBuildModalTitle' | i18next: {
        buildName: context.build.name,
        buildType:
          (context.isSandbox ? 'download-build.module:versions.sandbox' : 'download-build.module:versions.public')
            | i18next
      }
    }}
  </p>
  <mbs-alert [type]="alertType.info"
             [icon]="true"
             class="mb-0">
    {{ 'download-build.module:deleteBuildModalText' | i18next }}
  </mbs-alert>
</ng-template>

<ng-template #newBuildSection
             let-buildSection="section"
             let-isSandbox="isSandbox"
             [templateTyping]="newBuildSectionToken">
  <ng-container *ngIf="buildSection?.isNewerVersionAvailable">
    <div class="mt-4 d-flex justify-content-between"
         [class.ml-3]="align !== 'left'">
      <div>
        <mbs-tag [noBorder]="true"
                 [active]="true"> {{ 'common.new' | i18next: { format: 'title' } }}
        </mbs-tag>

        <span class="ml-2 mr-2">v{{ buildSection?.availableVersion }}</span>

        <ng-container *ngIf="showHelp">
          <a href="https://help.mspbackups.com/whats-new/release-history"
             target="_blank">
            {{ 'download-build.module:whatsNew' | i18next }}
            <i class="fa fa-external-link"></i>
          </a>
        </ng-container>
      </div>

      <ng-container *ngIf="(PermissionsEnum.Rebranding | can : 'read')">
        <mbs-button [disabled]="isOneOfTheBuildsIsInProgress()"
                    [loading]="isBuildInProgress(build)"
                    (click)="generateBuild(build, isSandbox)">
          {{
            (isSandbox ? 'download-build.module:versions.generateSandbox' :
              'buttons:generate') | i18next: { format: 'title'}
          }}
        </mbs-button>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #buildDescription
             [templateTyping]="buildDescriptionToken"
             let-build="build">
  <div class="pl-4">
    <div *ngIf="build.mbsBuildType === buildTypesEnum.CommonWindowsBuild"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of windowsSupported">
          {{ item }}
        </li>
      </ul>
      <p class="mt-1 mb-0">
        {{ 'builds:windows.can_backup_description' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of windowsCanBackup">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.VMware"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of vmwareSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.LinuxUbuntu14"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of linuxDebSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.LinuxRedHat6"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of linuxRpmSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.LinuxMac"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of macOsSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.RMMAgent"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of rmmSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.RMMAgentMac"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of rmmMacSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.CBLRA"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of connectHostForWindowsSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.RMMAgentLinuxRpm"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of rmmLinuxRpmSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.RMMAgentLinuxDeb"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of rmmLinuxDebSupported">
          {{ item }}
        </li>
      </ul>
    </div>

    <div *ngIf="build.mbsBuildType === buildTypesEnum.NetworkDiscovery"
         class="small text-left">
      <p class="mb-0">
        {{ 'download-build.module:supported_os' | i18next }}:
      </p>
      <ul class="p-0 mb-0 form-row list-style-inside">
        <li class="col-auto"
            *ngFor="let item of rmmSupported">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</ng-template>
