import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IMaskDirective } from 'angular-imask';
import IMask from 'imask';
import { Address4 } from 'ip-address';
import { InputBase } from '../input-base/input-base';
import { DOMEvent } from '../input-base/input-base.model';

export type MaskedOptions = IMask.AnyMaskedOptions;
export type MaskElement = IMask.MaskElement;

export enum ErrorMessages {
  wrongIp = 'Invalid IP address',
  wrongRange = 'The IP range is invalid. One IP range is allowed for scanning'
}

export enum NetworkClass {
  A = '8',
  B = '16',
  C = '24'
}

/**
 * Allows only IP v4
 * @author: Ikonnikov.S
 */
@Component({
  selector: 'app-ip-address,mbs-ip-address',
  templateUrl: './ip-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IPAddressComponent extends InputBase<any> implements OnInit, OnChanges, ControlValueAccessor {
  /**
   * HTML tabindex
   */
  @Input() tabindex = 0;
  /**
   * Is Starting IP point or Ending Ip point. Should be true for one IP Endpoint
   */
  @Input() isFirstInRange = true;
  /**
   * Value for comparison
   */
  @Input() connectedIpValue: string = null;
  /**
   * @ignore
   */
  /**
   * Add font-weight-bold;
   */
  @Input() public boldLabel = false;

  public get bindClasses(): string[] {
    const classesObject = { ...this.validClasses };

    return Object.entries(classesObject)
      .filter(([_, v]) => !!v)
      .map(([k]) => k)
      .concat([this.sizeClass]);
  }
  public innerErrorMsg = ErrorMessages.wrongIp;
  public ipPattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}|^(?:[0-9]{1,3}\.?:[0-9]{1,3}\.?:[0-9]{1,3}\.)/m;
  public isValidIp = false;

  private placeholderReplacement = '_';
  public imaskOpts: MaskedOptions = {
    mask: '[000]{.}`[000]{.}`[000]{.}`[000]',
    placeholderChar: this.placeholderReplacement,
    lazy: false,
    autofix: false
  };

  protected labelContentClassesDefault = 'mbs-ip-address_label-content';

  private validInputList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
  private regExp = new RegExp(this.ipPattern);

  @ViewChild(IMaskDirective, { read: IMaskDirective, static: true }) maskDirective: IMaskDirective<MaskedOptions>;

  constructor(@Optional() @Self() ngControl: NgControl, protected cd: ChangeDetectorRef) {
    super(ngControl, cd);
  }

  ngOnInit(): void {
    this.maskDirective.writeValue(String(this.myValue));
  }

  writeValue(obj: any): void {
    this.myValue = obj;
    this.onErrorValidationCheck(this.myValue);
    this.cd.markForCheck();
  }

  valueChanged(event: DOMEvent<HTMLInputElement>): void {
    event.stopPropagation();
  }

  handleInput(event: InputEvent): void {
    event.stopImmediatePropagation();

    this.validateInput(event);

    // validate user's input by mask usage
    this.maskDirective.writeValue(String(this.value));

    // override onChange value, to not sent placeholder parts
    const onChangeValue = (event?.target as HTMLInputElement).value.replaceAll('_', '');
    this.value = onChangeValue;

    this.onErrorValidationCheck(onChangeValue);
    this.cd.markForCheck();
  }

  onErrorValidationCheck(value: string): void {
    this.isValidIp = this.regExp.test(value);
    this.innerErrorMsg = ErrorMessages.wrongIp;

    if (this.isValidIp && this.connectedIpValue) this.ipRangeValidationCheck();
  }

  handleAccept(event): void {
    this.myValue = event;
    this.cd.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connectedIpValue?.currentValue) {
      this.ipRangeValidationCheck();
    }
  }

  // Main validation method to get rid of inappropriate values
  private validateInput(event: Partial<InputEvent>): void {
    const inputValue = event.data;

    // wrong character use case check and fix
    if (inputValue && !this.validInputList.includes(inputValue)) {
      event.preventDefault();
      (event?.target as HTMLInputElement).value = String(this.myValue);
    }

    // number size use case check and fix
    const valueList = (event?.target as HTMLInputElement).value.split('.').flatMap(item => {
      if (item.length > 3) {
        let [item1, item2] = [item.substring(0, 3), item.substring(3, 4)];

        if (Number(item1) > 255) item1 = '255';

        return [item1, item2];
      }

      return Number(item) > 255 ? '255' : item;
    });

    // IP address length use case check and fix
    if (valueList.length > 4) {
      valueList.length = 4;
    }

    this.myValue = valueList.join('.');
    (event?.target as HTMLInputElement).value = this.myValue;
  }

  ipRangeValidationCheck(): void {
    if (!this.connectedIpValue && !this.myValue) return;

    if (!Address4.isValid(this.connectedIpValue) && !Address4.isValid(this.myValue)) return;

    const [subnetA, subnetB] = this.isFirstInRange
      ? [this.getIpAddressV4FromString(this.value), this.getIpAddressV4FromString(this.connectedIpValue)]
      : [this.getIpAddressV4FromString(this.connectedIpValue), this.getIpAddressV4FromString(this.value)];

    this.isValidIp = this.networkInSubnetIpV4Validator(subnetA, subnetB);

    if (!this.isValidIp) this.innerErrorMsg = ErrorMessages.wrongRange;
  }

  getIpAddressV4FromString(value: string): Address4 {
    return new Address4(value + `/${NetworkClass.C}`);
  }

  public networkInSubnetIpV4Validator(subnetA: Address4, subnetB: Address4, mask: NetworkClass = NetworkClass.C): boolean {
    return subnetB.isInSubnet(subnetA) && Number(subnetA.parsedAddress[3]) < Number(subnetB.parsedAddress[3]);
  }
}
