import Computer from '@models/Computer';
import { ListSelectGroupModel } from 'mbs-ui-kit';
import Company from '@models/Company';

export class RDSelectComputersListFactory {
  static ConvertComputersToCompaniesListSelectGroupModels(computers: Computer[], selectedCompaniesGroups: ListSelectGroupModel[]) {
    const data = [];
    computers.forEach((comp, ind) => {
      if (comp.company) {
        const companyGroupIndex = data.findIndex((x) => x.id == comp.company.id);
        if (companyGroupIndex >= 0) {
          data[companyGroupIndex].items.push(comp);
        } else {
          const companyGroupSelectedIndex = selectedCompaniesGroups && selectedCompaniesGroups.findIndex((x) => x.id === comp.company.id);
          data.push(
            new ListSelectGroupModel({
              id: comp.company.id,
              title: comp.company.name,
              items: [comp],
              selectedItems:
                companyGroupSelectedIndex && companyGroupSelectedIndex >= 0
                  ? [...selectedCompaniesGroups[companyGroupSelectedIndex].selectedItems]
                  : []
            })
          );
        }
      }
    });
    return data;
  }

  static GetCompaniesListSelectGroupModels(companies: Company[], computers: Computer[]): ListSelectGroupModel<Computer>[] {
    const data: ListSelectGroupModel<Computer>[] = [];
    companies = companies.filter((x) => x.id !== 'no-company');
    companies.forEach((company, ind) => {
      const comps = computers.filter((x) => x.company && x.company.id === company.id);
      data.push(
        new ListSelectGroupModel({
          id: company.id,
          title: company.name,
          items: [...comps],
          selectedItems: []
        })
      );
    });
    return data;
  }

  static ConvertComputersToCompaniesListSelectGroupModelsBySelectedComputersIds(
    computers: Computer[],
    selectedComputersIds: string[]
  ): ListSelectGroupModel<Computer>[] {
    const data: ListSelectGroupModel<Computer>[] = [];
    if (computers) {
      computers.forEach((comp, ind) => {
        if (!comp.company) {
          comp.company = {
            id: 'no-company',
            name: 'No Company'
          } as Company;
        }
        const companyGroupIndex = data.findIndex((x) => x.id == comp.company.id);
        const computerSelected = selectedComputersIds.findIndex((x) => x === comp.id);
        if (companyGroupIndex >= 0) {
          data[companyGroupIndex].items.push(comp);
          computerSelected >= 0 && data[companyGroupIndex].selectedItems.push(comp);
        } else {
          data.push(
            new ListSelectGroupModel({
              id: comp.company.id,
              title: comp.company.name,
              items: [comp],
              selectedItems: computerSelected >= 0 ? [comp] : []
            })
          );
        }
      });
    }
    return data;
  }
}
