<h4>{{ 'rmm.module:groupActions.updatePolicy.title' | i18next: { format: 'title' } }}</h4>

<mbs-alert [type]="'info'"
           [icon]="true"
           class="mb-2">
  <span>
    {{ 'rmm.module:groupActions.updatePolicy.updatePolicyBanner' | i18next }}
  </span>
</mbs-alert>

<h5>{{ 'rmm.module:groupActions.updatePolicy.updatePolicySelectionTitle' | i18next }}</h5>

<form [formGroup]="updatePolicyForm">
  <div>
    <mbs-checkbox [descriptionOutside]="false"
                  [label]="'rmm.module:groupActions.updatePolicy.severityLabel' | i18next"
                  formControlName="severityLevel">
      <ng-template checkboxDescription>

        <div class="text-xs">
          <mbs-alert *ngIf="severityEnabled && !selectedSeverityLevels?.length"
                     [type]="'danger'"
                     [icon]="true"
                     class="mb-2">
            <span>
              {{ 'rmm.module:groupActions.updatePolicy.noUpdatesInfo' | i18next }}
            </span>
          </mbs-alert>

          <mbs-iteration-ctrl-button (buttonClick)="openSeverityLevelModal()"
                                     [disabled]="!severityEnabled"
                                     [selected]="selectedSeverityLevels"
                                     [emptyLabel]="'rmm.module:groupActions.updatePolicy.severityEmptyLabel' | i18next ">
          </mbs-iteration-ctrl-button>
        </div>
      </ng-template>
    </mbs-checkbox>

    <mbs-checkbox [descriptionOutside]="false"
                  [label]="'rmm.module:groupActions.updatePolicy.updateCategoryLabel' | i18next"
                  formControlName="updateCategory">
      <ng-template checkboxDescription>
        <div class="text-xs">

          <mbs-alert *ngIf="updatesEnabled && !selectedUpdateCategories?.length"
                     [type]="'danger'"
                     [icon]="true"
                     class="mb-2">
            <span>
              {{ 'rmm.module:groupActions.updatePolicy.noUpdatesInfo' | i18next }}
            </span>
          </mbs-alert>

          <mbs-iteration-ctrl-button (buttonClick)="openWinUpdateCategoryModal()"
                                     [disabled]="!updatesEnabled"
                                     [selected]="selectedUpdateCategories"
                                     [emptyLabel]="'rmm.module:groupActions.updatePolicy.updateCategoryEmptyLabel' | i18next">
          </mbs-iteration-ctrl-button>
        </div>
      </ng-template>
    </mbs-checkbox>

    <mbs-checkbox [descriptionOutside]="false"
                  [label]="'rmm.module:groupActions.updatePolicy.excludeUpdatesLabel' | i18next"
                  formControlName="excludeUpdates">
      <ng-template checkboxDescription>
        <div class="text-xs">

          <mbs-alert *ngIf="excludeEnabled && !selectedToExcludedUpdates?.length"
                     [type]="'danger'"
                     [icon]="true"
                     class="mb-2">
            <span>
              {{ 'rmm.module:groupActions.updatePolicy.noExcludedInfo' | i18next }}
            </span>
          </mbs-alert>

          <mbs-iteration-ctrl-button (buttonClick)="openExcludeUpdateModal()"
                                     [disabled]="!excludeEnabled"
                                     [selected]="selectedToExcludedUpdatesToShow"
                                     [emptyLabel]="'rmm.module:groupActions.updatePolicy.excludeUpdatesEmptyLabel' | i18next">
          </mbs-iteration-ctrl-button>
        </div>
      </ng-template>
    </mbs-checkbox>
  </div>

  <h5>{{ 'rmm.module:groupActions.updatePolicy.updatePolicyOptionTitle' | i18next }}</h5>

  <div>

    <mbs-checkbox [label]="'rmm.module:groupActions.updatePolicy.localUpdatesLabel' | i18next"
                  formControlName="localUpdatesPriority"
                  [id]="'localUpdatesPriority'"
                  [descriptionOutside]="false">
      <ng-template checkboxDescription>
        <div class="row mb-3">
          <div class="col-7">
            <mbs-select formControlName="localUpdatesSelection"
                        [disabled]="!localUpdatesPriorityEnabled"
                        [items]="localUpdatesSelectionItems"
                        [contentEditable]="false"
                        [editableSearchTerm]="false"
                        [clearable]="false">
            </mbs-select>
          </div>
        </div>
      </ng-template>
    </mbs-checkbox>

    <mbs-checkbox [descriptionOutside]="false"
                  [label]="'rmm.module:groupActions.updatePolicy.deferTimeLabel' | i18next"
                  formControlName="updateDefer">
      <ng-template checkboxDescription>
        <div class="row text-xs">
          <div class="col-4">
            <mbs-number formControlName="updateDeferNumber"
                        min="1"
                        [id]="'updateDeferNumber'"
                        [disabled]="!installUpdateDeferEnabled">
            </mbs-number>
          </div>
          <div class="col-6">
            <mbs-select formControlName="updateDeferType"
                        [items]="notifyItems"
                        [clearable]="false"
                        [searchable]="false"
                        [disabled]="!installUpdateDeferEnabled">
            </mbs-select>
          </div>
        </div>
      </ng-template>
      <ng-container appendLabel>
        <span class="ml-1 text-info ico ico-InfoCircle"
              [placement]="'top-right auto'"
              [container]="'body'"
              tooltipClass="tooltip-lg"
              [ngbTooltip]="'rmm.module:groupActions.updatePolicy.deferTimeTooltip' | i18next">
        </span>
      </ng-container>
    </mbs-checkbox>

    <mbs-checkbox [descriptionOutside]="false"
                  [label]="'rmm.module:groupActions.updatePolicy.rebootIfRequiredLabel' | i18next"
                  formControlName="rebootIfRequired">
      <ng-template checkboxDescription>

        <div class="row text-xs">
          <div class="col-4 mb-3">
            <mbs-timepicker formControlName="rebootIfRequiredTime"
                            [placement]="['top', 'top-start', 'bottom-start', 'bottom-end', 'top-end']"
                            [meridian]="true"
                            [needChangeInvalid]="true"
                            [disabled]="!rebootIfRequiredTimeEnabled">
            </mbs-timepicker>
          </div>
        </div>
      </ng-template>
    </mbs-checkbox>
  </div>
</form>
