import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const winUrlProd = 'https://foris.cloudberrylab.com/api/products/fe3da2a1-b709-499e-b641-d5317c742efd/cbra';
const macUrlProd = 'https://foris.cloudberrylab.com/api/products/fe3da2a1-b709-499e-b641-d5317c742efd/cbramac';
const iOSUrlProd = 'https://testflight.apple.com/join/PCiN6T5f';

@Injectable()
export class DownloadService {
  private myDownloadDataWin = new BehaviorSubject<any>({});
  private myDownloadDataMac = new BehaviorSubject<any>({});
  private myDownloadDataIOS = new BehaviorSubject<any>({
    downloadUrl: iOSUrlProd
  });

  constructor(private http: HttpClient) {
    this.fetchDownloadData();
  }

  get downloadDataWin() {
    return this.myDownloadDataWin.asObservable();
  }

  get downloadDataMac() {
    return this.myDownloadDataMac.asObservable();
  }

  get downloadDataIOS() {
    return this.myDownloadDataIOS.asObservable();
  }

  fetchDownloadData() {
    this.http.get<any>(winUrlProd).subscribe(res => {
      this.myDownloadDataWin.next(res);
    });
    this.http.get<any>(macUrlProd).subscribe(res => {
      this.myDownloadDataMac.next(res);
    });
  }

  getRaInstallToken(data) {
    return this.http.post<any>('api/build/generateInstallToken', data);
  }
}
