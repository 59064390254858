import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { DownloadConnectComponent } from '@components/download-connect/download-connect.component';
import { DownloadBuildModule } from '@components/download-build/download-build.module';

@NgModule({
  imports: [CommonModule, MbsUiKitModule, I18NextModule, DownloadBuildModule],
  declarations: [DownloadConnectComponent],
  exports: [DownloadConnectComponent]
})
export class DownloadConnectModule {}
