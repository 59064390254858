import * as ScriptLibraryActions from './script-library.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of } from 'rxjs';

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { MbsPopupType, Toast, ToastService } from 'mbs-ui-kit';
import { concatMap } from 'rxjs/operators';
import { ScriptLibraryService } from '../script-library.service';
import { ScriptAccessLevel, ScriptLibraryAccessLevel } from './script-library.model';

@Injectable()
export class ScriptLibraryEffects {
  loadScripts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptLibraryActions.loadScriptsInLibrary),
      concatMap(() => {
        return this.service.loadScripts().pipe(
          concatMap((scripts) => of(ScriptLibraryActions.upsertScripsInLibrary({ scripts }))),
          catchError((error) => {
            return of(ScriptLibraryActions.catchError({ error }));
          })
        );
      })
    );
  });

  loadScriptBody$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptLibraryActions.loadScriptBody),
      concatMap(({ scriptGuid }) => {
        return this.service.loadScriptBody(scriptGuid).pipe(
          concatMap((body) => of(ScriptLibraryActions.upsertScriptBody({ scriptGuid, body }))),
          catchError((error) => {
            return of(ScriptLibraryActions.catchError({ error }));
          })
        );
      })
    );
  });

  registerScript$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptLibraryActions.registerScript),
      concatMap(({ transfer }) => {
        const params = new HttpParams({ fromObject: { accessLevel: transfer.accessLevel } });

        return this.service.registerScript(transfer, params).pipe(
          concatMap(() => {
            this.sendToast(this.i18n.transform('rmm-script-library.module:toasts.created'));
            return of(ScriptLibraryActions.loadScriptsInLibrary());
          }),
          catchError((error) => {
            return of(ScriptLibraryActions.catchError({ error }));
          })
        );
      })
    );
  });

  registerTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptLibraryActions.registerTag),
      concatMap(({ tagName }) => {
        const params = new HttpParams({ fromObject: { accessLevel: ScriptAccessLevel.Public } });

        return this.service.registerTag(tagName, params).pipe(
          concatMap((response) => {
            this.sendToast(this.i18n.transform('rmm-script-library.module:toasts.categoryCreated'));
            return of(
              ScriptLibraryActions.addTag({
                tag: {
                  accessLevel: ScriptLibraryAccessLevel.Public,
                  name: tagName,
                  tagGuid: response.result
                }
              })
            );
          }),
          catchError((error) => {
            return of(ScriptLibraryActions.catchError({ error }));
          })
        );
      })
    );
  });

  updateScript$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptLibraryActions.updateScript),
      concatMap(({ id, transfer }) => {
        return this.service.updateScript(id, transfer).pipe(
          concatMap(() => {
            this.sendToast(this.i18n.transform('rmm-script-library.module:toasts.changed'));
            return of(ScriptLibraryActions.loadScriptsInLibrary());
          }),
          catchError((error) => {
            return of(ScriptLibraryActions.catchError({ error }));
          })
        );
      })
    );
  });

  deleteScript$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptLibraryActions.deleteScript),
      concatMap(({ id }) => {
        return this.service.deleteScript(id).pipe(
          concatMap(() => of(ScriptLibraryActions.deleteScriptInLibrary({ id }))),
          catchError((error) => {
            return of(ScriptLibraryActions.catchError({ error }));
          })
        );
      })
    );
  });

  deleteTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptLibraryActions.deleteTag),
      concatMap(({ tagGuid }) => {
        return this.service.deleteTag(tagGuid).pipe(
          // loadScriptsInLibrary action required to show Tag/Category changes
          // Uncategorised category can be created and it's scripts can be changed during deletion
          concatMap(() => of(ScriptLibraryActions.loadTags(), ScriptLibraryActions.loadScriptsInLibrary())),
          catchError((error) => {
            return of(ScriptLibraryActions.catchError({ error }));
          })
        );
      })
    );
  });

  loadCollections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptLibraryActions.loadTags),
      concatMap(() =>
        this.service.loadTags().pipe(
          concatMap((tags) => of(ScriptLibraryActions.upsertTags({ tags }))),
          catchError((error) => {
            return of(ScriptLibraryActions.catchError({ error }));
          })
        )
      )
    );
  });

  sendToast(title: string): void {
    this.toastService.toast(
      new Toast({
        header: title,
        icon: true,
        content: '',
        type: MbsPopupType.success,
        showClose: false
      })
    );
  }

  constructor(
    private actions$: Actions,
    private service: ScriptLibraryService,
    private toastService: ToastService,
    private i18n: I18NextPipe
  ) {}
}
