import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule } from 'angular-i18next';
import { MbsUiKitModule } from 'mbs-ui-kit';
import { TimeSchedulerComponent } from './time-scheduler.component';

@NgModule({
  declarations: [TimeSchedulerComponent],
  imports: [CommonModule, MbsUiKitModule, FormsModule, ReactiveFormsModule, I18NextModule, NgbTooltipModule],
  exports: [TimeSchedulerComponent]
})
export class TimeSchedulerModule {}
