<div class="mbs-form-group mb-0">
  <mbs-advanced-search [accessUrlQuery]="false"
                       [(ngModel)]="model"
                       (search)="updateFilters($event)"
                       (input)="handleInputSearch($event)">
  </mbs-advanced-search>
</div>

<mbs-table-nav [switcherView]="false"
               (pageChange)="handlePageChange($event)"
               (pageSizeChange)="handlePageSizeChange($event)"
               [showRefreshButton]="true"
               [showExportToCSVButton]="isModal"
               (export)="export(getTableName())"
               [exportButtonName]="'buttons:exportToCSV' | i18next"
               (refresh)="fetchData()">


  <ng-template mbsTableFilter="label">
    {{isWindows ? ('rmm-side-panel:servicesTab.services' | i18next) : ('rmm-side-panel:servicesTab.daemons' | i18next)}}
    : {{ paginationOptions.dataSize }}
  </ng-template>
  <ng-container *ngIf="isOnline && !readonly">
    <ng-template mbsTableFilter="divider">
    </ng-template>
    <ng-template mbsTableFilter="label">
      <mbs-button class="mr-2"
                  size="sm"
                  (click)="handleToggleService('start')"
                  [disabled]="disabledStart"
                  icon="ico ico-play-circle"
                  triggers="hover"
                  ngbTooltip="{{ !isModal ? 'Start' : ''}}"
                  tooltipClass="-no-arrow">
        {{ isModal ? ('buttons:start' | i18next) : ''}}
      </mbs-button>

      <mbs-button class="mr-2"
                  size="sm"
                  (click)="handleToggleService('stop')"
                  [disabled]="disabledStop"
                  icon="ico ico-times-circle"
                  triggers="hover"
                  ngbTooltip="{{ !isModal ? 'Stop' : ''}}"
                  tooltipClass="-no-arrow">
        {{ isModal ? ('rmm-side-panel:servicesTab.stopBtn' | i18next) : ''}}
      </mbs-button>

      <mbs-button class="mr-2"
                  size="sm"
                  (click)="handleToggleService('restart')"
                  [disabled]="disabledRestart"
                  icon="ico ico-Restart"
                  triggers="hover"
                  ngbTooltip="{{ !isModal ? 'Restart' : ''}}"
                  tooltipClass="-no-arrow">
        {{ isModal ? ('rmm-side-panel:servicesTab.restartBtn' | i18next) : ''}}
      </mbs-button>
    </ng-template>

  </ng-container>
  <ng-template mbsTableControl="append"
               *ngIf="!isModal">
    <mbs-modal-tab-content modalTitle="{{ 'app:common:computer' | i18next : { format: 'title' } }} {{isWindows ? ('rmm-side-panel:servicesTab.services' | i18next) : ('rmm-side-panel:servicesTab.daemons' | i18next) }}"
                           [showExpandedCross]="true">
      <ng-template>
        <mbs-services-tab [hid]="hid"
                          [isModal]="true"
                          [isOnline]="isOnline"
                          [readonly]="readonly"
                          [computerName]="computerName">
        </mbs-services-tab>
      </ng-template>
    </mbs-modal-tab-content>
  </ng-template>

</mbs-table-nav>

<mbs-table class="ph-no-capture"
           [loading]="loading$ | async"
           [headers]="headers"
           [data]="data"
           (sort)="handleSort($event)"
           [changeSortState]="orderBy"
           [myTrackBy]="trackBy"
           [rotateSequence]="rotateSequence"
           [showCheckboxes]="isOnline"
           [multipleSelect]="false"
           [selectable]="true"
           (changeSelected)="itemsChecked($event)"
           bindSelected="name"
           [selectedItems]="selected"
           [virtualScrolling]="true"
           [virtualItemSize]="isModal || !isOnline ? 40 : 40"
           [virtualItemsNumber]="10"
           [maxHeight]="isModal || !isOnline ? '70vh' : '60vh'"
           [keepState]="true">

  <ng-template mbsTableCell
               *ngFor="let header of headers"
               let-item>
    <mbs-text-ellipsis [tooltip]="item[header.sort]">
      {{item[header.sort] }}
    </mbs-text-ellipsis>
  </ng-template>

</mbs-table>
