<h4 *ngIf="enableTitle">{{ 'rmm.module:groupActions.stepConfigureAction.shellTerminalTitle' | i18next: { format: 'title'
  } }}</h4>

<div *ngIf="modeSelection === scriptModeSelectTypes.fromFile"
     class="row">
  <div class="col-12 mb-2">
    <mbs-drop-file [width]="'auto'"
                   [height]="'100px'"
                   [labelText]="'rmm.module:groupActions.stepConfigureAction.uploadScript' | i18next"
                   [returnBase64Allways]="true"
                   (fileLoad)="fileLoadHandler($event)"
                   [accept]="fileExtension">
    </mbs-drop-file>
  </div>
</div>

<form *ngIf="modeSelection === scriptModeSelectTypes.fromScript"
      [formGroup]="terminalScriptForm">
  <div class="row">
    <div class="col-12">
      <mbs-code-editor formControlName="scriptBody">
      </mbs-code-editor>
    </div>
  </div>


  <div class="mt-2">
    <mbs-input-label class="font-weight-semibold"
                     [label]="'rmm.module:groupActions.stepConfigureAction.scriptTimeout' | i18next">
    </mbs-input-label>


    <div class="row">
      <div class="col-2">
        <mbs-number [min]="terminalScriptForm.get('scriptTimeMeasure').value.id === 0 ? 5 : 1"
                    [max]="terminalScriptForm.get('scriptTimeMeasure').value.id === 0 ? 59 : 23"
                    formControlName="scriptTimeoutTime">
        </mbs-number>
      </div>
      <div class="col-4">
        <mbs-select formControlName="scriptTimeMeasure"
                    [items]="timeMeasureList"
                    [searchable]="false"
                    [clearable]="false">
        </mbs-select>
      </div>
    </div>

    <div class="mt-2">
      {{ 'rmm.module:groupActions.stepConfigureAction.scriptTimeoutDesc' | i18next }}
    </div>
  </div>

</form>
