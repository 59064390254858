import { I18NextService } from 'angular-i18next';

export function getTableHeaders(i18: I18NextService, isExpanded = false) {
  // eslint-disable-next-line sonarjs/no-all-duplicated-branches
  return !isExpanded
    ? [
        {
          name: i18.t('tfa:devicesSidepanel:name', { format: 'title' }),
          gridColSize: '10fr',
          gridColMin: '55px'
        },
        {
          name: i18.t('tfa:devicesSidepanel:lastAccess', { format: 'title' }),
          gridColSize: '7fr',
          gridColMin: '70px',
          isGridColumn: false,
          overflow: true
        },
        {
          name: i18.t('tfa:devicesSidepanel:notification', { format: 'title' }),
          gridColSize: '5fr',
          gridColMin: '60px',
          isGridColumn: false,
          class: '-center',
          overflow: false
        },
        {
          name: i18.t('tfa:devicesSidepanel:2FA', { format: 'title' }),
          gridColSize: '3fr',
          gridColMin: '30px',
          isGridColumn: false,
          class: '-center',
          overflow: true
        },
        {
          name: '',
          gridColSize: '52px',
          gridColMin: '52px'
        }
      ]
    : [
        {
          name: i18.t('tfa:devicesSidepanel:name', { format: 'title' }),
          gridColSize: '5fr',
          gridColMin: '55px',
          isGridColumn: false,
          overflow: true
        },
        {
          name: i18.t('tfa:devicesSidepanel:IP', { format: 'title' }),
          gridColSize: '4fr',
          gridColMin: '40px',
          isGridColumn: false,
          overflow: true
        },
        {
          name: i18.t('tfa:devicesSidepanel:lastAccess', { format: 'title' }),
          gridColSize: '8fr',
          gridColMin: '80px',
          isGridColumn: false,
          overflow: true
        },
        {
          name: i18.t('tfa:devicesSidepanel:location', { format: 'title' }),
          gridColSize: '10fr',
          gridColMin: '100px',
          isGridColumn: false,
          overflow: true
        },
        {
          name: i18.t('tfa:devicesSidepanel:notification', { format: 'title' }),
          gridColSize: '2fr',
          gridColMin: '40px',
          isGridColumn: false,
          overflow: true
        },
        {
          name: i18.t('tfa:devicesSidepanel:2FA', { format: 'title' }),
          gridColSize: '2fr',
          gridColMin: '40px',
          isGridColumn: false,
          overflow: true
        },
        {
          name: '',
          gridColSize: '52px',
          gridColMin: '52px'
        }
      ];
}
