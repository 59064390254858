export enum AuthType {
  None,
  Google,
  MbsApp,
  Alternative
}

export interface QRCodeResponse {
  authId: string;
  payload: string;
  encodedSecretCode: string;
  timeExpired: string;
}

export interface ConfirmCodeResponse {
  isValid: boolean;
  codes: Array<string>;
}

export interface TFAStatus {
  resolution: number;
  status: TFAStatusEnum;
}

export interface TFAEnabledStatus {
  enabled: boolean;
  hasToBeEnabled: boolean;
}

export enum TFAStatusEnum {
  NoPassed = 1,
  Passed = 2
}
