import { Pipe, PipeTransform } from '@angular/core';
import { BuildType } from '@models/BuildType.enum';

function toString(buildType: BuildType): string {
  switch (buildType) {
    case BuildType.BareMetal:
      return 'Server';
    case BuildType.Ultimate:
      return 'Ultimate';
    case BuildType.SqlServer:
      return 'Microsoft SQL Server';
    case BuildType.Exchange:
      return 'Microsoft Exchange';
    case BuildType.SqlExchange:
      return 'Microsoft SQL Server + Microsoft Exchange';
    case BuildType.VMware:
      return 'VM';
    case BuildType.Desktop:
      return 'File Backup (legacy)';
    case BuildType.DesktopIBB:
      return 'Desktop';
    default:
      return BuildType[buildType];
  }
}

@Pipe({
  name: 'buildType'
})
export class BuildTypeDisplayPipe implements PipeTransform {
  transform(value: BuildType, args?: any): any {
    return toString(value);
  }
}
