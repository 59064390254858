<mbs-wizard [title]="'rmm.module:groupActions.wizardTitle' | i18next"
            [canClosed]="wizardFinish"
            [showSaveBtnOnClose]="showSaveBtnOnClose"
            [checkChanges]="wizardCheckChanges"
            [(ngModel)]="currentStep"
            [nextButton]="nextButton"
            [backButton]="backButton"
            [saveButton]="saveButton"
            [cancelButton]="{ text: 'buttons:close' | i18next }"
            (next)="handleStepChange($event)"
            (save)="finishWizard()"
            [showSteps]="showSteps"
            [isOpenSteps]="editMode"
            [disableSpinner]="true"
            [stepsOrientation]="stepOrientation"
            [disableStepChangeOnKeyboardKey]="true"
            #wizard>

  <mbs-wizard-step #start
                   [title]="titleSteps.action"
                   [valid]="true">
    <mbs-select-action></mbs-select-action>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="titleSteps.computers"
                   [valid]="true">
    <mbs-apply-to></mbs-apply-to>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="titleSteps.run"
                   [valid]="true">
    <mbs-configure-action></mbs-configure-action>
  </mbs-wizard-step>

  <mbs-wizard-step #schedule
                   [title]="titleSteps.schedule"
                   [valid]="true">
    <mbs-schedule-action></mbs-schedule-action>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="titleSteps.summary"
                   [valid]="true">
    <mbs-show-summary></mbs-show-summary>
  </mbs-wizard-step>

  <mbs-wizard-step #result
                   [title]="titleSteps.result"
                   [valid]="true">
    <mbs-show-result (closeWizardOnRedirect)="closeWizardOnRedirect()"></mbs-show-result>
  </mbs-wizard-step>

</mbs-wizard>
