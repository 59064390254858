import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewRef } from '@angular/core';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import Computer, { OsType } from '@models/Computer';
import { EventLogEntryTypeEnum, EventLogIconEnum, IEventLogEntry } from '@models/rmm/EventLogInfo';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { exportToCsv } from '@utils/cvs';
import { baseDateFileNameFormatHoursAndMinutes, mediumDateWithTime } from '@utils/date';
import { I18NextPipe } from 'angular-i18next';
import { MbsSize, SortEvent, TableHeader } from 'mbs-ui-kit';
import { ExtendedTableRow } from 'mbs-ui-kit/table-grid/table/table.component';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getReversedTableSorting } from '../../../../utils/helpers/rmm/table-reversed-sorting';
import { EventTotalTabMode, FilterEntry } from '../event-total-tab.model';
import * as EventEntryFilterActions from '../store/event-entry-filter.actions';
import { showDetailEntry, sortEntry } from '../store/event-entry.actions';
import { ChangeMode } from '../store/event-total.actions';
import { selectEntryLoading, selectFilteredEntry, selectFilters, selectFiltersCount } from '../store/event-total.selectors';
import { EventEntry } from '../store/model';

@UntilDestroy()
@Component({
  selector: 'mbs-event-grid',
  templateUrl: './event-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventGridComponent implements OnInit, AfterViewInit {
  public readonly eventLogEntryTypeEnum = EventLogEntryTypeEnum;
  public sortEvent$: BehaviorSubject<SortEvent> = new BehaviorSubject(undefined);
  public tableData$: Observable<EventEntry[]>;
  public loading$: Observable<boolean>;
  public filters$: Observable<FilterEntry[]>;
  public totalEventsCount$: Observable<number>;

  public itemCount = 20;
  public techGap = 310;
  public itemSize = 100;
  public height = `calc(100vh - ${this.techGap}px)`;

  @Input() isModal = false;
  public computerName: string;

  public headers: TableHeader[];
  public isMacOrLinuxOS: boolean;
  public isLinuxOS: boolean;

  public readonly MbsSize = MbsSize;

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store,
    private i18nPipe: I18NextPipe,
    private computersFacade: ComputersFacade
  ) {
    this.computersFacade.currentComputer$.pipe(untilDestroyed(this)).subscribe((computer) => {
      this.isMacOrLinuxOS = [OsType.apple, OsType.linux].includes(computer?.os);
      this.computerName = Computer.getComputerName(computer);
      this.headers = this.setHeaders();
    });
  }

  canCdr(): boolean {
    return this.cdr && !(this.cdr as ViewRef).destroyed;
  }

  ngOnInit() {
    const itemCount = Math.floor((window.innerHeight - this.techGap) / this.itemSize) - 1;
    this.itemCount = itemCount < 8 ? 8 : itemCount;

    this.loading$ = this.store.select(selectEntryLoading).pipe(untilDestroyed(this));
    this.filters$ = this.store.select(selectFilters).pipe(untilDestroyed(this));
    this.totalEventsCount$ = this.store.select(selectFiltersCount).pipe(untilDestroyed(this));
    this.tableData$ = this.store.select(selectFilteredEntry).pipe(
      map((data) => data.map((entry) => ({ ...entry, icon: this.returnDataElementIcon(entry) }))),
      untilDestroyed(this)
    );
  }

  returnDataElementIcon(element: EventEntry): string {
    switch (element.level) {
      case 'Warn':
        return `fa fa-exclamation-triangle mr-2 text-${element.cssClass}`;
      case 'Failure':
      case 'Error':
        return `fa fa-exclamation-circle mr-2 text-${element.cssClass}`;
      case 'Info':
      case 'Success':
      case 'Warning':
      case 'Danger':
      default:
        return `${EventLogIconEnum[element.level]} text-${element.cssClass}`;
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  handleMode() {
    this.store.dispatch(ChangeMode({ mode: EventTotalTabMode.CHART }));
  }

  showDetail(entry: IEventLogEntry) {
    this.store.dispatch(showDetailEntry({ id: entry.index }));
  }

  handleSort(event: SortEvent) {
    this.store.dispatch(sortEntry({ sort: [getReversedTableSorting(event)] }));
  }

  toggleFilter(filter: FilterEntry) {
    this.store.dispatch(
      EventEntryFilterActions.setActiveFilter({
        id: filter.id
      })
    );
  }

  setHeaders() {
    if (this.isMacOrLinuxOS)
      return [
        {
          name: this.i18nPipe.transform('event-total.module:grid.time', { format: 'title' }),
          sort: 'timeUTC',
          class: '-end',
          gridColSize: '8rem',
          gridColMin: '8rem'
        },
        {
          name: this.i18nPipe.transform('event-total.module:grid.source', { format: 'title' }),
          overflow: true,
          sort: 'source',
          gridColSize: '25fr'
        },
        {
          name: this.i18nPipe.transform('event-total.module:grid.category', { format: 'title' }),
          gridColSize: '25fr',
          sort: 'category',
          overflow: true
        }
      ];

    return [
      {
        name: this.i18nPipe.transform('event-total.module:grid.level', { format: 'title' }),
        sort: 'level',
        gridColSize: '5rem',
        gridColMin: '5rem'
      },
      {
        name: this.i18nPipe.transform('event-total.module:grid.time', { format: 'title' }),
        sort: 'timeUTC',
        class: '-end',
        gridColSize: '8rem',
        gridColMin: '8rem'
      },
      {
        name: this.i18nPipe.transform('event-total.module:grid.source', { format: 'title' }),
        overflow: true,
        sort: 'source',
        gridColSize: '25fr'
      },
      {
        name: this.i18nPipe.transform('event-total.module:grid.id', { format: 'title' }),
        sort: 'eventID',
        class: '-end',
        gridColSize: '15fr',
        overflow: true
      }
    ];
  }

  trackBy(_index: number, item: ExtendedTableRow): string {
    return item?.item?.index;
  }

  export() {
    this.tableData$.pipe(take(1)).subscribe((data) => {
      exportToCsv(
        this.getTableName(),
        this.headers.map((header) => header.name),
        this.headers.map((header) => header.sort),
        data.map((item) => ({
          ...item,
          timeUTC: moment(item.timeUTC).format(mediumDateWithTime)
        }))
      );
    });
  }

  private getTableName(): string {
    return `${this.computerName} ${this.i18nPipe.transform(
      'rmm-side-panel:sidePanelTabsName.' + (this.isMacOrLinuxOS ? 'unixEventLog' : 'winEventLog')
    )} ${moment().format(baseDateFileNameFormatHoursAndMinutes)}`;
  }
}
