<mbs-wizard [title]="'computers.module:addComputer' | i18next"
            [showSteps]="true"
            [stepsOrientation]="'vertical'"
            [initialLoading]="loading"
            [saveButton]="{ hide: true }"
            [canCloseWithoutSave]="true"
            [cancelButton]="{
              text: 'buttons:close' | i18next
            }"
            #wizard>
  <mbs-wizard-step [title]="'computers.module:addComputerWizard.actionStep.title' | i18next"
                   [valid]="wizardForm.valid">
    <add-computer-wizard-select-action-step [wizardForm]="wizardForm"
                                            [showCompanyUnsupportedAlert]="showCompanyUnsupportedAlert"
                                            [availableOsTypes]="availableOsTypes"></add-computer-wizard-select-action-step>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="'computers.module:addComputerWizard.downloadStep.title' | i18next"
                   [valid]="true">
    <add-computer-wizard-download-and-install-step [displayedBuilds]="displayedBuilds"
                                                   [licensedBrandList]="licensedBrandList"
                                                   [networkDiscoveryBuild]="networkDiscoveryBuild"
                                                   [wizardForm]="wizardForm"></add-computer-wizard-download-and-install-step>
  </mbs-wizard-step>

  <mbs-wizard-step [title]="'computers.module:addComputerWizard.readManualStep.title' | i18next"
                   [valid]="true">
    <add-computer-wizard-read-manual-step [wizardForm]="wizardForm"></add-computer-wizard-read-manual-step>
  </mbs-wizard-step>
</mbs-wizard>
