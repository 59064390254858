import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TFAData } from '@components/tfa/models/tfa-data';
import { AuthType, ConfirmCodeResponse, QRCodeResponse, TFAEnabledStatus, TFAStatus } from '@components/tfa/services/tfa.types';
import { AbilityService } from 'ability';
import { MbsSize, ModalService, ModalSettings } from 'mbs-ui-kit';
import { from, Observable, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TwoFactorApproveModalComponent } from '../components/two-factor-approve-modal/two-factor-approve-modal.component';

@Injectable({
  providedIn: 'root'
})
export class TFAService {
  constructor(private http: HttpClient, private ability: AbilityService, private modal: ModalService) {}

  public doNotDisturb(authId: string, minutes: number) {
    return this.http.post('api/tfa/do-not-disturb', {
      AuthId: authId,
      Minutes: minutes
    });
  }

  public tryAgain(authId: string): Observable<TFAData> {
    return this.http.post<TFAData>(`api/tfa/retry/${authId}`, null);
  }

  public logout() {
    return this.http.post('api/logout', null, { responseType: 'text' });
  }

  public getGoogleQRCode(): Observable<QRCodeResponse> {
    return this.http.post<QRCodeResponse>('api/tfa/time-based-one-time-code/turn-on', null).pipe(
      map((result) => {
        return {
          ...result,
          payload: `/api/tfa/QR?payload=${result.payload}`
        };
      })
    );
  }

  public getTFAStatus(authId: string): Observable<TFAStatus> {
    return this.http.get<TFAStatus>(`api/tfa/status?authId=${authId}`);
  }

  public confirmGoogle(code: string, authId: string): Observable<ConfirmCodeResponse> {
    return this.http.post<ConfirmCodeResponse>('api/tfa/time-based-one-time-code/confirm', {
      code,
      authId
    });
  }

  public activateGoogle(code: string, authId: string): Observable<{ isValid: boolean }> {
    return this.http.post<{ isValid: boolean }>('api/tfa/time-based-one-time-code/confirm', {
      code,
      authId
    });
  }

  public confirmAlternativeCode(code: string, authId: string): Observable<ConfirmCodeResponse> {
    return this.http.post<ConfirmCodeResponse>('api/tfa/alternative-codes/confirm', {
      code: code,
      authid: authId
    });
  }

  public refuse() {
    return this.http.post('api/tfa/refuse', null);
  }

  public administratorDisableTFA(id: string) {
    return this.http.delete(`api/administrators/${id}/two-factor-auth`);
  }

  public resetAuthId() {
    return this.http.post('api/tfa/reset-auth-id', null);
  }

  public updateTFAState() {
    return this.getTFAEnabledStatus().pipe(
      tap((result: { enabled: boolean; hasToBeEnabled: boolean }) => {
        if (result.enabled) {
          this.ability.addRule({ actions: 'read', subject: '2FAEnabled' });
        }
        if (!result.enabled) {
          this.ability.deleteRule('2FAEnabled', 'read');
        }
      })
    );
  }

  public openApproveModal(data: ModalSettings, container?: string) {
    return this.modal
      .openCustom(TwoFactorApproveModalComponent, {
        data,
        size: MbsSize.sm,
        container
      })
      .catch((error) => {
        this.resetAuthId().subscribe();
        return Promise.reject(error);
      });
  }

  public handleTFA<T>(request: Observable<T>): Observable<T> {
    const tfaStatusCode = 420;
    return request.pipe(
      catchError((error) => {
        if (error.status === tfaStatusCode) {
          return from(this.openApproveModal(error.error)).pipe(switchMap(() => this.handleTFA(request)));
        }

        throw error;
      })
    );
  }

  public getTFAEnabledStatus(): Observable<TFAEnabledStatus> {
    return this.http.get<TFAEnabledStatus>('api/tfa/IsTwoFactorAuthEnabled');
  }

  public getAuthType(): Observable<AuthType> {
    return this.http.get<AuthType>('api/tfa/auth-type');
  }

  public turnOffTFA() {
    return this.http.delete('api/tfa/turn-off');
  }

  public resetCodes(): Observable<Array<string>> {
    return this.http.post<Array<string>>('api/tfa/alternative-codes/reset', null);
  }

  public generateCodes(authId: string): Observable<Array<string>> {
    return this.http.post<Array<string>>('api/tfa/alternative-codes/generate-new', { authId });
  }

  public getCodesCount(): Observable<number> {
    return this.http.get<number>('api/tfa/alternative-codes/count');
  }
}
