import { CommonModule } from '@angular/common';
import { CodeEditorComponent } from './components/code-editor.component';
import { NgModule } from '@angular/core';
import { PrismService } from './prism.service';
import { ReactiveFormsModule } from '@angular/forms';

const components = [
  CodeEditorComponent
];
@NgModule({
  exports: components,
  declarations: components,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  providers: [PrismService],
  bootstrap: [CodeEditorComponent]
})
export class CodeEditorModule { }