import Computer, { AgentState, AgentType } from '@models/Computer';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ComputerApp } from './computer-apps.model';
import { FeaturePrefix } from './const';

export const loadComputerApps = createAction(`${FeaturePrefix} Load ComputerApps`, props<{ computerApps: ComputerApp[] }>());
export const loadComputerApp = createAction(`${FeaturePrefix} Load ComputerApp`, props<{ hid: Computer['hid']; appType: AgentType }>());
export const loadUpdateComputerApp = createAction(
  `${FeaturePrefix} Load Update ComputerApp`,
  props<{ hid: Computer['hid']; appType: AgentType; applicationState: AgentState }>()
);

export const changeMonitoringState = createAction(
  `${FeaturePrefix} Change Monitoring State`,
  props<{ id: ComputerApp['id']; appType: AgentType; hid: Computer['hid']; monitoring: AgentState }>()
);

export const loadComputerAppsSuccess = createAction(`${FeaturePrefix} Load ComputerApps Success`, props<{ computerApps: ComputerApp[] }>());
export const loadComputerAppSuccess = createAction(`${FeaturePrefix} Load ComputerApp Success`, props<{ computerApp: ComputerApp }>());

export const addComputerApp = createAction(`${FeaturePrefix} Add ComputerApp`, props<{ computerApp: ComputerApp }>());

export const upsertComputerApp = createAction(`${FeaturePrefix} Upsert ComputerApp`, props<{ computerApp: ComputerApp }>());

export const addComputerApps = createAction(`${FeaturePrefix} Add ComputerApps`, props<{ computerApps: ComputerApp[] }>());

export const upsertComputerApps = createAction(`${FeaturePrefix} Upsert ComputerApps`, props<{ computerApps: ComputerApp[] }>());

export const updateComputerApp = createAction(`${FeaturePrefix} Update ComputerApp`, props<{ computerApp: Update<ComputerApp> }>());

export const updateComputerApps = createAction(`${FeaturePrefix} Update ComputerApps`, props<{ computerApps: Update<ComputerApp>[] }>());

export const deleteComputerApp = createAction(`${FeaturePrefix} Delete ComputerApp`, props<{ id: string }>());

export const deleteComputerApps = createAction(`${FeaturePrefix} Delete ComputerApps`, props<{ ids: string[] }>());

export const clearComputerApps = createAction(`${FeaturePrefix} Clear ComputerApps`);
