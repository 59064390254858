import { OutputBuffer } from '@models/rmm/PowerShellOutputBuffer';
import { ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as ansiEscapes from 'ansi-escapes';
import { noop } from 'lodash';
import { flatten, get } from 'lodash/fp';
import { from, fromEvent, interval, Observable, Subject, Subscription } from 'rxjs';
import { map, scan, switchMap, takeUntil } from 'rxjs/operators';
import { IBuffer, IDisposable, ITerminalAddon, Terminal } from 'xterm';
import { TERMINAL_GREETINGS } from '../const';
import * as ShellInputActions from './actions';
import { EventBehavior } from './events';
import { createKeyAction, keyEventHandler, pasteEventHandler } from './handler';
import { ShellHistory } from './history';
import { KeyAction, KeyActions, LineTypes, PromptBufferConfig, ShellCommandOperator } from './model';
import { PromptBuffer } from './prompt-buffer';
import { clear, del, down, end, error, history, home, insert, left, newLine, prompt, reminder, right, script, up } from './shell-commands';
import { ShellLine } from './shell-line';

// ShellAddon - addon for xterm.js that implements command interpreter in browser,
// supports navigation and command history.
// See https://xtermjs.org/docs/guides/using-addons/

export class ShellAddon implements ITerminalAddon {
  public executeCommand$: Subject<string> = new Subject<string>();
  public breakCommand$: Subject<string> = new Subject<string>();
  public errorCommand$: Subject<string> = new Subject<string>();
  public inputChange$: Subject<string> = new Subject<string>();
  public bufferChange$: Subject<PromptBuffer> = new Subject<PromptBuffer>();
  public history: ShellHistory;

  public pasteEvent: Subscription;
  public processing: Subscription;
  public actions$: Observable<KeyAction>;
  private events$ = new EventBehavior();

  private execution = false;

  term: Terminal;
  buffer: IBuffer;
  disposables: IDisposable[];

  outputBuffer;
  customKeyHandling;
  simulationMode;

  promptLine: ShellLine;

  get textaria() {
    return this.term.textarea || null;
  }

  constructor(outputBuffer: OutputBuffer[], customKeyHandling = false, simulationMode = false) {
    this.outputBuffer = outputBuffer;
    this.customKeyHandling = customKeyHandling;
    this.simulationMode = simulationMode;
    this.actions$ = this.events$.actions$;
    this.history = new ShellHistory(20);

    this.registerEvents();
    this.handleProcessing();
  }
  /*
   * Activate xterm hook API method
   */
  activate(terminal: Terminal): void {
    this.term = terminal;
    this.buffer = terminal.buffer.active;
    this.term.onKey(this.handleEvent);
    this.registerPasteEvent();

    this.greetings();
    this.term.write(ansiEscapes.cursorSavePosition);
    this.dispatchAction(createKeyAction(KeyActions.Prompt));
  }
  /*
   * Handle events form xterm
   */
  handleEvent = async (event: { key: string; domEvent: KeyboardEvent }) => {
    const action = await keyEventHandler(event.domEvent, { write: event.key }, { history: this.history.active, execution: this.execution });
    this.dispatchAction(action);
  };

  /*
   * Action dispatcher
   */
  dispatchAction = (action: Action) => {
    if (action) {
      this.events$.dispatch(action);
    }
  };

  /*
   * Input change dispatcher
   */
  dispatchChangeInput(buffer = this.createPromptBuffer(), backspace = false) {
    const value = backspace ? buffer.getInput().slice(0, -1) : buffer.getInput();
    this.inputChange$.next(value);
  }

  /*
   * Buffer change dispatcher
   */
  dispatchBufferChange(buffer = this.createPromptBuffer()) {
    this.bufferChange$.next(buffer);
  }

  /*
   * Output dispatcher
   */
  dispatchOutput(output) {
    this.dispatchAction(createKeyAction(KeyActions.Output, null, { output }));
  }

  /**
   * Listen textarea paste event and dispatch Paste Action.
   */
  registerPasteEvent() {
    this.pasteEvent = fromEvent(this.term.textarea, 'paste')
      .pipe(switchMap((event) => from(pasteEventHandler(event))))
      .subscribe((action) => {
        this.dispatchAction(action);
      });
  }

  /**
   * Register events to addon
   */
  registerEvents() {
    // execution commands group
    this.events$.subscribeOfType(ShellInputActions.Run, () => this.execute());
    this.events$.subscribeOfType(ShellInputActions.OutsideRun, () => {
      this.execution = true;
    });
    this.events$.subscribeOfType(ShellInputActions.Break, this.applyBreak([clear, prompt]));
    this.events$.subscribeOfType(ShellInputActions.Prompt, this.applyPrompt([clear, prompt]));
    this.events$.subscribeOfType(ShellInputActions.Error, this.applyCommand([reminder, error]));
    // manage commands group
    this.events$.subscribeOfType(ShellInputActions.Right, this.applyCommand([right]));
    this.events$.subscribeOfType(ShellInputActions.Left, this.applyCommand([left]));
    this.events$.subscribeOfType(ShellInputActions.Home, this.applyCommand([home]));
    this.events$.subscribeOfType(ShellInputActions.End, this.applyCommand([end]));
    this.events$.subscribeOfType(ShellInputActions.Up, this.applyCommand([up]));
    this.events$.subscribeOfType(ShellInputActions.Down, this.applyCommand([down]));
    this.events$.subscribeOfType(ShellInputActions.Backspace, this.applyBackSpaceCommand([left, clear, reminder, left]));
    // history commands group
    this.events$.subscribeOfType(ShellInputActions.Next, () => this.applyHistory([home, clear, history], this.history.next()));
    this.events$.subscribeOfType(ShellInputActions.Prev, () => this.applyHistory([home, clear, history], this.history.prev()));
    this.events$.subscribeOfType(ShellInputActions.LastCMD, () => this.applyHistory([home, clear, prompt, history], this.history.last()));
    // write commands group
    this.events$.subscribeOfType(ShellInputActions.Insert, this.applyCommandWrite([clear, insert, right]));
    this.events$.subscribeOfType(ShellInputActions.Paste, this.applyCommandWrite([clear, insert, right]));
    this.events$.subscribeOfType(ShellInputActions.Delete, this.applyCommandWrite([clear, del]));
    this.events$.subscribeOfType(ShellInputActions.NewLine, this.applyCommandWrite([clear, newLine, reminder, right]));
    this.events$.subscribeOfType(ShellInputActions.Output, this.applyOutputWrite([clear, this.handleOutput]));
  }

  /**
   * Show state of execute in xterm
   */
  handleProcessing() {
    const run$ = this.events$.actions$.pipe(ofType(ShellInputActions.Run, ShellInputActions.OutsideRun));
    const stop$ = this.events$.actions$.pipe(ofType(ShellInputActions.Break, ShellInputActions.Prompt, ShellInputActions.Output));
    const interval$ = interval(500);

    this.processing = run$
      .pipe(
        switchMap(() => {
          this.term.writeln('');
          this.term.write(ansiEscapes.cursorSavePosition);
          this.term.write(ansiEscapes.cursorHide);

          const buffer = this.createPromptBuffer();
          const y = buffer.cursorY;

          return interval$.pipe(
            takeUntil(stop$),
            scan((acc) => (acc < 3 ? acc + 1 : 0), 0),
            map((count) => ({ dots: new Array(count).fill('.').join(' '), y }))
          );
        })
      )
      .subscribe(({ dots, y }) => {
        this.term.write(ansiEscapes.cursorTo(0, y + 1) + ansiEscapes.eraseLine + dots);
      });
  }

  /**
   * Execute command in out side service
   */
  execute = () => {
    const promptBuffer = this.createPromptBuffer();
    const result = promptBuffer.getInput();
    if (!this.execution) {
      this.execution = true;
      this.history.set(result);
      this.history.stop();
      this.executeCommand$.next(result);
    }
  };

  /*
   * Change Execution
   */
  setExecutingComplete(message?: any) {
    if (this.execution) {
      this.term.write(ansiEscapes.cursorShow);
      this.history.start();
      this.dispatchAction(createKeyAction(KeyActions.Prompt));
    }
  }

  /*
   * Return last inputted command
   */
  getCurrentCommand(buffer = this.createPromptBuffer()) {
    return buffer.getInput();
  }

  /*
   * Apply outside command to terminal
   */
  writeCommand(payload, _any) {
    const promptBuffer = this.createPromptBuffer();
    const commands = [clear, script].map((operator) => operator({ payload } as any)(promptBuffer));

    this.write(commands);
    // Timeout to give sometime for CommandLine Cursor to be initialized in a proper place
    setTimeout(() => {
      this.dispatchAction(createKeyAction(KeyActions.OutsideRun));
    }, 100);
  }

  /*
   * Handle output limit to show in terminal
   */
  handleOutput = (action) => (promptBuffer: PromptBuffer) => {
    this.history.start();

    const output: string = get('payload.output', action) || '';
    const data = output.split(new RegExp(`(${promptBuffer.config.newLine})`));
    const limit = promptBuffer.cursorY + data.length + promptBuffer.config.rows > 1024;

    if (limit) {
      this.forceClear();
      this.dispatchAction(createKeyAction(KeyActions.LastCMD));
      return output;
    }

    return output;
  };

  /**
   * Force clear terminal window
   */
  forceClear() {
    this.term.clear();
    this.term.write(ansiEscapes.cursorTo(0, 0));
    this.term.write(ansiEscapes.eraseDown);
  }

  /**
   * Paste clear line
   */
  pasteSpace() {
    this.term.writeln('');
    this.term.writeln('');
    this.term.write(ansiEscapes.cursorSavePosition);
  }

  /*
   * Write output result from services
   */
  applyOutputWrite = (operators) => (action: KeyAction) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map((operator) => operator(action)(promptBuffer));

    this.term.writeln('');
    this.write(commands);
  };

  /*
   * Handle input from history
   */
  applyHistory = (operators: ShellCommandOperator[], history: string) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map((operator) => operator({ history } as any)(promptBuffer));

    this.write(commands);
  };

  /*
   * Handle new prompt
   */
  applyPrompt = (operators: ShellCommandOperator[]) => (action: KeyAction) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map((operator) => operator(action)(promptBuffer));

    this.execution = false;
    this.write(commands, this.promptCallBack);
  };

  /*
   * Handle Break command
   * @param operators
   * @returns
   */
  applyBreak = (_operators: ShellCommandOperator[]) => (_action: KeyAction) => {
    this.history.start();
    this.breakCommand$.next(null);
  };

  /*
   * handle common commands in xterm
   * @param operators
   * @returns
   */
  applyCommand = (operators: ShellCommandOperator[]) => (action: KeyAction) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map((operator) => operator(action)(promptBuffer));

    this.write(commands);
  };

  /*
   * handle BackSpace commands in xterm
   * @param operators
   * @returns
   */
  applyBackSpaceCommand = (operators: ShellCommandOperator[]) => (action: KeyAction) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map((operator) => operator(action)(promptBuffer));

    this.write(commands);
    this.dispatchChangeInput(promptBuffer, true);
  };

  /*
   * handle commands for write input in xterm
   * @param operators
   * @returns
   */
  applyCommandWrite = (operators: ShellCommandOperator[]) => (action: KeyAction) => {
    const promptBuffer = this.createPromptBuffer();
    const commands = operators.map((operator) => operator(action)(promptBuffer));
    if (promptBuffer.isRowLimit()) {
      this.dispatchAction(createKeyAction(KeyActions.Error, null, { error: 'input limit' }));
    } else {
      this.write(commands, this.writeCallBack);
    }
  };

  /**
   * Update history state and set new prompt line after execute command
   */
  promptCallBack = () => {
    const buffer = this.createPromptBuffer();
    this.setLastPromptLine(buffer);
    this.toggleHistory(buffer);
    this.term.write(ansiEscapes.cursorShow);
  };

  /**
   * Update history state and dispatch input change;
   */
  writeCallBack = () => {
    const buffer = this.createPromptBuffer();
    this.toggleHistory(buffer);
    this.dispatchBufferChange(buffer);
    this.dispatchChangeInput(buffer);
  };

  /*
   * Create buffer of inputted data
   * @param buffer
   * @returns
   */
  private createPromptBuffer = (buffer = this.buffer) => {
    this.term.scrollToBottom();

    const config: PromptBufferConfig = {
      cols: this.term.cols,
      rows: this.term.rows,
      history: Number.isInteger(this.history.position)
    };
    return new PromptBuffer(buffer, config);
  };

  /*
   * Toggle history loop
   */
  private toggleHistory = (buffer = this.createPromptBuffer()) => {
    const lines = Array.from(buffer.getCacheLines(true).values()).filter((i) => i.type === LineTypes.New);
    lines.length ? this.history.stop() : this.history.start();
  };

  /*
   * Cash line of start input
   */
  private setLastPromptLine = (buffer = this.createPromptBuffer()) => {
    const activeLine = buffer.siblings.get(1);

    if (activeLine.type === LineTypes.Prompt) {
      this.promptLine = activeLine;
    }
  };

  /*
   * Write input to terminal
   * @param commands
   * @param callback
   */
  private write = (commands: string[], callback = () => null) => {
    this.term.write(ansiEscapes.cursorRestorePosition);

    const promises = flatten(commands).map(
      (command) =>
        new Promise((resolve) => {
          this.term.write(command, () => resolve(true));
        })
    );

    Promise.all(promises)
      .then(() => {
        this.term.write(ansiEscapes.cursorSavePosition);
        this.dispatchBufferChange();
        callback();
      })
      .catch(noop);
  };
  /**
   * generate greetings for xterm
   */
  greetings() {
    TERMINAL_GREETINGS['Windows'].forEach((line) => this.term.writeln(line));
  }
  /**
   * dispose xterm hook
   * API method
   */
  dispose(): void {
    this.events$.dispose();
    this.processing.unsubscribe();
    this.pasteEvent.unsubscribe();
  }
}
