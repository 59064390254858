import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { MbsPopupType } from '../utils';
import Alert from './alert.model';

export const alertDefaultId = 'default-alert';

@Injectable()
export class AlertService {
  private close$ = new Subject<Alert>();
  private myAlert$ = new Subject<Alert>();
  public alert$ = this.myAlert$.asObservable();

  /**
   * Emit event for resolve all listeners.
   * @param {Alert} alert
   */
  close(alert: Alert): void {
    this.close$.next(alert);
  }

  /**
   * enable subscribing to Alert observable
   * @param {string} id
   * @return {Observable}
   */
  onAlert(id: string = alertDefaultId): Observable<Alert> {
    return this.alert$.pipe(filter((x) => x && x.id === id));
  }

  /**
   * alias for calling Alert by type: `MbsPopupType.success`
   * @param {string} msg
   * @param {Partial<Alert>} options
   * @return {Observable}
   */
  success(msg?: string, options?: Partial<Alert>): Observable<Alert> {
    const content: string = msg ? msg : 'Success';
    return this.alert(new Alert({ ...options, type: MbsPopupType.success, content }));
  }

  /**
   * alias for calling Alert by type: `MbsPopupType.danger`
   * @param {string} msg
   * @param {Partial<Alert>} options
   * @return {Observable}
   */
  error(msg?: string, options?: Partial<Alert>): Observable<Alert> {
    const content: string = msg ? msg : 'Sorry, something went wrong';
    return this.alert(new Alert({ ...options, type: MbsPopupType.danger, content }));
  }

  /**
   * alias for calling Alert by type: `MbsPopupType.info`
   * @param {string} content
   * @param {Partial<Alert>} options
   * @return {Observable}
   */
  info(content: string, options?: Partial<Alert>): Observable<Alert> {
    return this.alert(new Alert({ ...options, type: MbsPopupType.info, content }));
  }

  /**
   * alias for calling Alert by type: `MbsPopupType.warning`
   * @param {string} content
   * @param {Partial<Alert>} options
   * @return {Observable}
   */
  warn(content: string, options?: Partial<Alert>): Observable<Alert> {
    return this.alert(new Alert({ ...options, type: MbsPopupType.warning, content }));
  }

  /**
   * Main alert method
   * @param {Alert} alert
   * @return {Observable}
   */
  alert(alert: Alert): Observable<Alert> {
    alert.id = alert.id || (alertDefaultId as string);
    this.myAlert$.next(alert);
    return this.close$.pipe(first((a) => a === alert));
  }

  /**
   * Remove Alert by id
   * @param {string} id
   */
  clearById(id: string = alertDefaultId): void {
    this.myAlert$.next(new Alert({ id }));
  }
}
