import { Component } from '@angular/core';
import { ComputerBackupFacade } from '@facades/computer.backup.facade';
import Computer from '@models/Computer';
import { DateTimeFormatComponent } from '@shared/components/date-time-format/date-time-format.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbs-backup-date-time-format',
  templateUrl: '../../date-time-format/date-time-format.component.html'
})
export class BackupDateTimeFormatComponent extends DateTimeFormatComponent {
  constructor(public auth: AuthService, private facade: ComputerBackupFacade) {
    super(auth);
  }

  getAgentUtcOffsetValue() {
    return this.facade.computer$.pipe(
      switchMap((computer: Computer) => {
        if (computer?.online && Computer.isWindows(computer)) {
          return this.facade.getAgentUtcOffsetValue();
        }

        return this.facade.currentTimezone$;
      })
    );
  }
}
