import { StorageClass } from '@models/StorageType.enum';
import { PlanTypes } from '@models/PlanTypes.enum';
import { RecurType } from '@models/RecurType.enum';
import { AllowSettings } from '@modules/wizards/helpers/bases/base-for-plan-helper';
import { AdvancedSettingsVirtualStepValue, BackupSnapshotType } from '@modules/wizards/models/advanced-settings-vm-models';
import { AppAwareStepValue } from '@modules/wizards/models/app-aware-models';
import { VolumeInfoCommunication } from '@modules/wizards/models/base/base-models/plan-volume-info-model';
import { SelectHostStepValue } from '@modules/wizards/models/select-host-models';
import { SelectVirtualDisksStepValue, VirtualDisk, VirtualDisksSelectedType } from '@modules/wizards/models/select-virtual-disks-models';
import { SelectVirtualMachinesStepValue, VirtualMachinesSelectedType } from '@modules/wizards/models/select-virtual-machines-models';
import { SQLServerInstanceAuthenticationTypeEnum, SQLServerInstanceStepValue } from '@modules/wizards/models/sql-server-instance-models';
import { SQLSourceDatabasesStepValue } from '@modules/wizards/models/sql-source-databases-models';
import { firstNumberGreaterThanSecondIfNotFloat } from '@utils/bigMath';
import { MinDate } from '@utils/constants/c-sharp-constants';
import { PasswordFromPlan } from '@utils/constants/misc-constants';
import { getISODateFromTimeAndDate, GetTimeSpanString, TimeGroupToTimeSpanString } from '@utils/date';
import { ScheduleDataHelper } from '@utils/schedule-data-helper';
import { isNil } from 'lodash';
import {
  GuidDirectAccessVSS,
  GuidEmpty,
  MaxFileSizeForBackend,
  MaxFileSizeStrFormat,
  TimeSpanMaxValue,
  TimeSpanZeroValue
} from 'mbs-ui-kit/utils/constants';
import * as moment from 'moment';
import { AdvancedFilterStepValue } from '../../models/advanced-filters-models';
import { AdvancedOptionsStepValue, AzureAccessTierType } from '../../models/advanced-options-models';
import { ArchiveConsistencyStepValue, RunRestoreVerification } from '../../models/archive-consistency-step-value';
import { BaseBackupPlan } from '../../models/base/base-backup-plan-model';
import { BackupActionPost } from '../../models/base/base-models/plan-backup-actions-post-model';
import { BackupActionPre } from '../../models/base/base-models/plan-backup-actions-pre-model';
import { VolumeInfoBackupOptions } from '../../models/base/base-models/plan-volume-info-backup-model';
import { CompressionEncryptionStepValue } from '../../models/compression-encription-models';
import { NotificationSettings, NotificationsStepValue, WindowsEventLogNotificationSettings } from '../../models/notifications-models';
import { PlanNameStepValue } from '../../models/plan-name-models';
import { PrePostActionsStepValue } from '../../models/pre-post-actions-models';
import { RetentionPolicyStepValue } from '../../models/retention-policy-models';
import { ScheduleAdvancedStepValue, ScheduleSettings } from '../../models/schedule-advanced-models';
import { ScheduleStepValue, ScheduleType } from '../../models/schedule-models';
import { SimpleScheduleStepValue } from '../../models/simple-schedule-models';
import { VolumeUsageStateEnum, WhatBackupStepValue } from '../../models/what-backup-models';
import { WhatBackupTreeStepValue } from '../../models/what-backup-tree-model';
import { WhereBackupStepValue } from '../../models/where-backup-models';
import { SupportMethodsForPlanFromSteps } from '../support-methods-for-plan-from-steps';

export class PlanDataFromStepsHelper {
  public static updateNewPlanByNameStep(newPlan, planNameStep: PlanNameStepValue): void {
    newPlan.Name = planNameStep.Name;
    newPlan.SavePlanInCloud = planNameStep.SavePlanInCloud;
  }

  public static updateNewPlanByWhereBackupStep(newPlan, whereBackupStep: WhereBackupStepValue, notHybrid = false): void {
    if (!notHybrid && whereBackupStep.IsHybridBackup && whereBackupStep.HybridID) {
      newPlan.IsHybridBackup = true;
      newPlan.HybridID = whereBackupStep.HybridID;
    } else newPlan.HybridID = GuidEmpty;
    newPlan.ConnectionID = whereBackupStep.ConnectionID;
  }

  public static updateNewPlanByCompressionEncryptionStep(
    newPlan,
    step: CompressionEncryptionStepValue,
    isNBF = false,
    initialAllow: AllowSettings = null
  ): void {
    if (!initialAllow) newPlan.UseServerSideEncryption = !!step.UseServerSideEncryption;

    const allow = initialAllow || { all: true, compression: true, encryption: true };

    newPlan.UseCompression = allow.all && allow.compression && !!step.UseCompression;
    newPlan.UseEncryption = allow.all && allow.encryption && step.UseEncryption;

    if (!isNBF && !(step.UseFileNameEncryption === null || step.UseFileNameEncryption === undefined)) {
      newPlan.UseFileNameEncryption = step.UseFileNameEncryption;
    }
    if (!(step.rebackup === null || step.rebackup === undefined)) {
      (newPlan as BaseBackupPlan).ReBackupModifiedSinceUTC = step.rebackup ? moment(new Date()).toISOString(false).split('.')[0] : MinDate;
    }

    if (newPlan.UseEncryption) {
      if (isNBF) {
        newPlan.HintString = step.hint;
        newPlan.Hint = null;
      }

      newPlan.EncryptionPassword = step.EncryptionPassword;
      newPlan.EncryptionAlgorithm = step.EncryptionAlgorithm;
      newPlan.EncryptionKeySize = step.EncryptionKeySize;

      if (newPlan.EncryptionPassword === PasswordFromPlan) {
        newPlan.EncryptionPassword = null;
      }

      return;
    }

    newPlan.EncryptionAlgorithm = 3; // AES
    newPlan.EncryptionKeySize = 256;
    delete newPlan.EncryptionPassword;
  }

  public static updateNewPlanByRetentionPolicyStep(
    newPlan,
    step: RetentionPolicyStepValue,
    isHybrid = false,
    isLinux = false,
    planType = PlanTypes.Plan
  ): void {
    const isIbb = planType === PlanTypes.BackupDiskImagePlan;
    const isDB = planType === PlanTypes.DataBasePlan;

    SupportMethodsForPlanFromSteps.planDataFromRetentionPolicy(newPlan, step, false, isLinux);

    if (isDB) {
      newPlan.RetentionDeleteLastVersion = false;

      if (isHybrid && step.HybridRetentionPolicy?.RetentionUseDefaultSettings) {
        newPlan.HybridRetentionPolicy.UseBackupDate = true
      }
    }

    if (!isHybrid && !(step.deleteIfLocallyDeleted === null || step.deleteIfLocallyDeleted === undefined)) {
      newPlan.DeleteCloudVersionIfDeletedLocally = step.deleteIfLocallyDeleted;
      newPlan.DeleteIfDeletedLocallyAfter = step.deleteAfter;
    }

    if (!isLinux && !isHybrid) newPlan.HideDeleteFilesWarning = step.doNotShowWarning;

    if (!(step.HybridRetentionPolicy === null || step.HybridRetentionPolicy === undefined) && isHybrid)
      SupportMethodsForPlanFromSteps.planDataFromRetentionPolicy(
        newPlan.HybridRetentionPolicy,
        step.HybridRetentionPolicy,
        isHybrid,
        isLinux,
        isIbb
      );

    if (isIbb) {
      newPlan.UseBackupDate = true;
      if (newPlan.HybridRetentionPolicy) {
        newPlan.HybridRetentionPolicy.UseBackupDate = true
      }
    }
  }

  public static updateNewPlanArchiveByArchiveConsistencyStep(newPlan, step: ArchiveConsistencyStepValue, isIbb = false): void {
    newPlan.UseFullConsistencyCheck = step.useFullConsistencyCheck;
    if (isIbb) {
      newPlan.TestVmSettings = newPlan.TestVmSettings || {};
      newPlan.RunRestoreVerificationOn = !(step.RunRestoreVerificationOn === null || step.RunRestoreVerificationOn === undefined)
        ? step.RunRestoreVerificationOn
        : RunRestoreVerification.None;
      if (step.RunRestoreVerificationOn !== RunRestoreVerification.None) {
        newPlan.FrameRateSeconds = moment.duration({ minute: step.minutes, second: step.seconds }).asSeconds();
        newPlan.CheckTimeSeconds = step.failureMinutes ? step.failureMinutes * 60 : 0;
        newPlan.TestVmSettings.NumCpu = step.numCpu;
        newPlan.TestVmSettings.MemoryInMb = step.memoryInMb;
      }
      if (step.dynamicMemory?.enabled) {
        newPlan.TestVmSettings = {
          ...newPlan.TestVmSettings,
          ...{
            DynamicMemoryEnabled: step.dynamicMemory.enabled,
            MinMemoryInMb: step.dynamicMemory.min,
            MaxMemoryInMb: step.dynamicMemory.max
          }
        };
      } else {
        ['DynamicMemoryEnabled', 'MinMemoryInMb', 'MaxMemoryInMb'].forEach((key) => delete newPlan.TestVmSettings[key]);
      }
      if (!Object.keys(newPlan.TestVmSettings).length) {
        delete newPlan.TestVmSettings;
      }
    }
  }

  public static updateNewNBFPlanByRetentionPolicyStep(newPlan, step: RetentionPolicyStepValue, needGFS = false): void {
    newPlan.RetentionUseDefaultSettings = false;
    newPlan.AutomaticallyReduceEarlyDeletionFee = step.intelligentRetention;
    newPlan.SerializationSupportRetentionTime =
      step.RetentionUseDefaultSettings === false
        ? TimeGroupToTimeSpanString({ value: step.deleteVersionsOlderThanCount, period: step.deleteVersionsOlderThanPeriod })
        : TimeSpanMaxValue;
    newPlan.RetentionTime = newPlan.SerializationSupportRetentionTime;
    if (needGFS) {
      if (!newPlan.GFSPolicySettings) {
        newPlan.GFSPolicySettings = {
          IsEnabled: false,
          ImmutabilityEnabled: false,
          Weekly: { IsEnabled: false, StorePeriod: 1 },
          Monthly: { IsEnabled: false, StorePeriod: 1 },
          Yearly: { IsEnabled: false, StorePeriod: 1, TakeBackupFromMonth: 1 }
        };
      }
      if (
        !(step.GFSPolicySettings.enabledImmutability === null || step.GFSPolicySettings.enabledImmutability === undefined) &&
        step.GFSPolicySettings.enabled
      )
        newPlan.GFSPolicySettings.ImmutabilityEnabled = step.GFSPolicySettings.enabledImmutability;
      newPlan.GFSPolicySettings.IsEnabled = step.GFSPolicySettings.enabled;
      if (step.GFSPolicySettings.enabled) {
        newPlan.GFSPolicySettings.Weekly = {
          ...newPlan.GFSPolicySettings.Weekly,
          ...{ IsEnabled: step.GFSPolicySettings.weekly.enabled, StorePeriod: step.GFSPolicySettings.weekly.storePeriod }
        };
        newPlan.GFSPolicySettings.Monthly = {
          ...newPlan.GFSPolicySettings.Monthly,
          ...{ IsEnabled: step.GFSPolicySettings.monthly.enabled, StorePeriod: step.GFSPolicySettings.monthly.storePeriod }
        };
        newPlan.GFSPolicySettings.Yearly = {
          ...newPlan.GFSPolicySettings.Yearly,
          ...{
            IsEnabled: step.GFSPolicySettings.yearly.enabled,
            StorePeriod: step.GFSPolicySettings.yearly.storePeriod,
            TakeBackupFromMonth: step.GFSPolicySettings.yearly.takeBackupFromMonth
          }
        };
      } else {
        newPlan.GFSPolicySettings = { IsEnabled: false };
      }
    }
  }

  public static updateNewPlanByWhatBackupTreeStep(newPlan, whatBackupStep: WhatBackupTreeStepValue): void {
    newPlan.Items = whatBackupStep.includeFolders.map((f) => ({ Path: f })) || [];
    newPlan.ExcludedItems = whatBackupStep.excludeFolders.map((f) => ({ Path: f })) || [];
  }

  public static updateNewPlanByWhatBackupStep(newPlan, whatBackupStep: WhatBackupStepValue): void {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    newPlan.DiskInfo.forEach((di) => {
      const dViews = whatBackupStep.partitions.filter((x) => x.DiskId === di.DiskId && x.VolumeUsageState !== VolumeUsageStateEnum.Removed);
      if (dViews.length > 0) {
        di.Volumes.forEach((volume) => {
          const volumeExtentId = SupportMethodsForPlanFromSteps.getExtentBasedId(volume.Extent);
          const dView = dViews.find((x) => x.ExtentBasedId === volumeExtentId);
          if (dView) {
            volume.Enabled = true;
            if (!volume.BackupOptions) {
              volume.BackupOptions = new VolumeInfoBackupOptions();
            }
            volume.BackupOptions.UseVss = dView.UseVSS;
            volume.BackupOptions.KeepBitLocker = dView.KeepBitLocker;
          } else volume.Enabled = false;
          if (volume.IsBoot && di.BootVolume) {
            di.BootVolume.Enabled = volume.Enabled;
            di.BootVolume.BackupOptions = volume.BackupOptions;
          }
        });
      } else {
        if (di.Volumes) di.Volumes.forEach((volume) => (volume.Enabled = false));
        if (di.BootVolume) di.BootVolume.Enabled = false;
      }
    });
    newPlan.BackupVolumes = whatBackupStep.BackupVolumes;
  }

  public static updateNewPlanFromAdvancedClassOrTier(newPlan, step: AdvancedOptionsStepValue, flags): void {
    if (flags.StorageClassSettingPossible) newPlan.StorageClass = step.StorageClass;
    if (flags.UseS3AccelerationPossible) newPlan.UseS3Acceleration = step.UseS3Acceleration;

    newPlan.UseAzureArchive = step.azureAccessTier === AzureAccessTierType.Archive;
    newPlan.UseAzureCool = step.azureAccessTier === AzureAccessTierType.Cool;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public static updateNewPlanByAdvancedOptionsStep(
    newPlan,
    step: AdvancedOptionsStepValue,
    flags,
    planType: PlanTypes,
    isNBF = false,
    isLinux = false
  ): void {
    if (!isLinux) {
      if (!(step.AlwaysUseVSS === null || step.AlwaysUseVSS === undefined) || step.UseFastNtfsScan) {
        newPlan.AlwaysUseVSS = step.AlwaysUseVSS || step.UseFastNtfsScan;
        newPlan.UseFastNtfsScan = step.UseFastNtfsScan;
        newPlan.BackupNTFSPermissions = step.BackupNTFSPermissions;
        newPlan.SaveDeletedDataInCloud = isNBF || step.SaveDeletedDataInCloud;
      } else if (!(step.UseFastNtfsScan === null || step.UseFastNtfsScan === undefined)) newPlan.UseFastNtfsScan = false;
      if (!isNBF) {
        newPlan.BlockSize = step.BlockSize;
        newPlan.PrefetchBlockCount = step.PrefetchBlockCount;
      } else {
        newPlan.SaveDeletedDataInCloud = isNBF;
        if (!(step.PrefetchBlockCount === null || step.PrefetchBlockCount === undefined))
          newPlan.PrefetchBlockCount = step.PrefetchBlockCount;
        if (planType === PlanTypes.Plan) {
          newPlan.BackupEfsFilesAsIs = step.KeepEFSFilesAsIs;
        }
      }
      if (flags.DisableVSSPossible) newPlan.DisableVSS = step.DisableVSS;
      if (flags.ExcludePossible) newPlan.ExcludeEnabled = step.ExcludeEnabled;
      newPlan.VSSProviderID = !step.useSystemVSSProvider ? GuidEmpty : GuidDirectAccessVSS;
    }

    this.updateNewPlanFromAdvancedClassOrTier(newPlan, step, flags);

    newPlan.SyntheticFull =
      !isLinux &&
      step.StorageClass !== StorageClass.DEEP_ARCHIVE &&
      step.StorageClass !== StorageClass.GLACIER &&
      step.StorageClass !== StorageClass.GLACIER_IR &&
      step.azureAccessTier !== AzureAccessTierType.Archive &&
      (flags.SyntheticFullSettingPossible || isNBF) &&
      (step.SyntheticFull || (isNBF && (step.SyntheticFull === null || step.SyntheticFull === undefined)));

    SupportMethodsForPlanFromSteps.updateNewPlanDiskInfo(newPlan);

    if (!(step.IgnoreBadSectors === null || step.IgnoreBadSectors === undefined)) newPlan.IgnoreBadSectors = step.IgnoreBadSectors;
    if (step.excludeRules && step.excludeRules.length > 0) {
      step.excludeRules.forEach((rule) => newPlan.DiskInfo[0].Volumes[0].BackupOptions.ExcludeRules.push({ Folder: rule }));
    }
  }

  public static updateNewPlanByAdvancedFilterStep(newPlan, step: AdvancedFilterStepValue, isLinux = false): void {
    let separatorFolder = ';';
    if (step.skipFolders && ~step.skipFolderName.indexOf(',')) separatorFolder = ',';
    const fileSize =
      step.doNotBackup && step.fileSize ? SupportMethodsForPlanFromSteps.fromMBToBytes(step.fileSize.toString()) : MaxFileSizeStrFormat;
    const isGreater = firstNumberGreaterThanSecondIfNotFloat(fileSize, MaxFileSizeStrFormat);
    const afterUTC = step.backupFilesModifiedSince ? getISODateFromTimeAndDate(step.time, step.date, true) : MinDate + 'Z';

    newPlan.BackupFilter.FilterType = step.backupAllFiles;
    newPlan.BackupFilter.Filters = SupportMethodsForPlanFromSteps.getFiltersArray(step);
    newPlan.BackupEmptyFolders = !!step.BackupEmptyFolders;
    newPlan.ExcludeFodlerList = step.skipFolders ? step.skipFolderName.split(separatorFolder).map((s) => s.trim()) : [];
    newPlan.BackupFilter.IncludeSystemAndHidden = !step.ignoreSystemAndHidden;
    newPlan.MaxFileSize = isLinux || !step.doNotBackup || isGreater ? MaxFileSizeForBackend : fileSize;
    newPlan.SkipInUseFiles = !!step.SkipInUseFiles;

    if (!isLinux) {
      newPlan.BackupOnlyModifiedDaysAgo = step.backupFilesModified ? +step.daysAgo : 0;
      newPlan.BackupOnlyAfterUTC = afterUTC;
      newPlan.BackupFilter.IncludeRecallOnDataAccessAttribute = !step.ignoreOnDemand;
    }
  }

  public static updateNewPlanByScheduleStep(
    newPlan,
    step: ScheduleStepValue,
    advancedStep: ScheduleAdvancedStepValue,
    simpleStep: SimpleScheduleStepValue,
    isLinux = false,
    isSQL = false
  ): void {
    const recTypeNumber = +ScheduleType[step.ScheduleType];
    newPlan.SyncBeforeRun = step.syncBeforeRun;
    if (recTypeNumber === ScheduleType.recurring || recTypeNumber == ScheduleType.once) {
      SupportMethodsForPlanFromSteps.SetPlanScheduleSettingsAdvanced(step, newPlan, advancedStep, isLinux, false, isSQL);

      return void SupportMethodsForPlanFromSteps.delStopAfterTickFromPlan(newPlan);
    }

    if (!isLinux && recTypeNumber == ScheduleType.predefined) {
      SupportMethodsForPlanFromSteps.SetSchedulePredefinedTemplates(step, newPlan, simpleStep, isSQL);
      newPlan.ForceFullApplyDiffSizeCondition = false;
      newPlan.ForceFullDiffSizeCondition = 50;

      return void SupportMethodsForPlanFromSteps.delStopAfterTickFromPlan(newPlan);
    }

    const stopAfterDuration = moment.duration({ hours: step.stopAfterHours, minutes: step.stopAfterMinutes });
    const stopAfter = !step.StopAfterEnabled ? TimeSpanMaxValue : GetTimeSpanString(stopAfterDuration);
    newPlan.Schedule = { Enabled: false };
    newPlan.ScheduleDiff = { Enabled: false };
    newPlan.ScheduleTLog = { Enabled: false };
    newPlan.ForceFullSchedule = { Enabled: !isLinux && recTypeNumber !== ScheduleType.noschedule };
    newPlan.ForceFullSchedule.StopAfter = stopAfter;
    newPlan.Schedule.StopAfter = stopAfter;
    if (!isLinux && recTypeNumber == ScheduleType.instantly) newPlan.ForceFullSchedule.RecurType = RecurType.Instantly;
    newPlan.ForceMissedSchedule = !!step.ForceMissedSchedule;
    newPlan.UseDifferentialUpload = false;
    newPlan.ForceFullApplyDiffSizeCondition = false;
    newPlan.ForceFullDiffSizeCondition = 50;

    SupportMethodsForPlanFromSteps.delStopAfterTickFromPlan(newPlan);
  }

  public static updateNewNBFPlanByScheduleStep(newPlan, step: ScheduleStepValue, isLinux = false): void {
    const recTypeNumber = +ScheduleType[step.ScheduleType];
    const isFFI = recTypeNumber === ScheduleType.ffi;
    const stopAfter = !step.StopAfterEnabled
      ? TimeSpanMaxValue
      : GetTimeSpanString(moment.duration({ hours: step.stopAfterHours, minutes: step.stopAfterMinutes }));
    newPlan.ForwardIncremental = isFFI;
    if (recTypeNumber === ScheduleType.predefined || isFFI) {
      if (!newPlan.Schedule) newPlan.Schedule = { Enabled: false } as ScheduleSettings;
      if (isFFI || !newPlan.ForceFullSchedule || !step.fullBackupData?.enabled)
        newPlan.ForceFullSchedule = { Enabled: false } as ScheduleSettings;
      newPlan.IsPredefinedTemplatesSchedule = true;
      ScheduleDataHelper.SetArchiveSchedules(newPlan.Schedule, step, true, isLinux);
      if (step.fullBackupData?.enabled) ScheduleDataHelper.SetArchiveSchedules(newPlan.ForceFullSchedule, step, false, isLinux);
    } else {
      newPlan.ForceFullSchedule = { Enabled: false };
      newPlan.Schedule = { Enabled: recTypeNumber !== ScheduleType.noschedule };
      newPlan.UseDifferentialUpload = false;
    }
    newPlan.ForceFullSchedule.StopAfter = stopAfter;
    newPlan.Schedule.StopAfter = stopAfter;
    newPlan.ForceMissedSchedule = !!step.ForceMissedSchedule;
    SupportMethodsForPlanFromSteps.delStopAfterTickFromPlan(newPlan);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public static updateNewPlanByPrePostActionsStep(
    newPlan,
    prePostActionsStep: PrePostActionsStepValue,
    setDefault = false,
    isNBF = false,
    isLinux = false
  ): void {
    if (!newPlan.Actions) newPlan.Actions = { Pre: null, Post: null };
    if (!setDefault) {
      if (prePostActionsStep.preAction) {
        newPlan.Actions.Pre = new BackupActionPre();
        newPlan.Actions.Pre.Enabled = prePostActionsStep.executeCommandBeforeBackup;
        newPlan.Actions.Pre.CommandLine = prePostActionsStep.executeCommandBeforeBackup ? prePostActionsStep.preAction : '';
        newPlan.Actions.Pre.TerminateOnFailure = prePostActionsStep.exitOnPreActionFail;
      } else newPlan.Actions.Pre = null;
      if (prePostActionsStep.postAction) {
        newPlan.Actions.Post = new BackupActionPost();
        newPlan.Actions.Post.Enabled = prePostActionsStep.executeCommandAfterBackupCompletion;
        newPlan.Actions.Post.CommandLine = prePostActionsStep.executeCommandAfterBackupCompletion ? prePostActionsStep.postAction : '';
        newPlan.Actions.Post.RunOnBackupFailure = !prePostActionsStep.executePostActionOnSuccess;
      } else newPlan.Actions.Post = null;
    }
    if (!isLinux) {
      if (prePostActionsStep.enableBackupChain) {
        newPlan.ExecuteNextPlan = true;
        newPlan.NextExectutionPlan = prePostActionsStep.backupChainNextPlan;
        newPlan.ExecuteNextPlanOnlyIfSucces = prePostActionsStep.executeChainPlanOnSuccess;
      } else {
        newPlan.ExecuteNextPlan = false;
        newPlan.NextExectutionPlan = null;
      }
      newPlan.ForceFullNextPlan = !!prePostActionsStep.ForceFullNextPlan;
    }
  }

  public static updateNewPlanByNotificationStep(newPlan, notificationStep: NotificationsStepValue, isLinux = false): void {
    newPlan.Notification = {} as NotificationSettings;
    if (notificationStep.receiveNotificationOnCompleteGroup.flag) {
      newPlan.Notification.SendNotification = true;
      newPlan.Notification.OnlyOnFailure = notificationStep.receiveNotificationOnCompleteGroup.case === 'whenFails';
    } else newPlan.Notification.SendNotification = false;
    if (!isLinux) {
      newPlan.WindowsEventLogNotification = {} as WindowsEventLogNotificationSettings;
      if (notificationStep.addEntryToEventLogOnCompleteGroup?.flag) {
        newPlan.WindowsEventLogNotification.SendNotification = true;
        newPlan.WindowsEventLogNotification.OnlyOnFailure = notificationStep.addEntryToEventLogOnCompleteGroup.case === 'whenFails';
      } else newPlan.WindowsEventLogNotification.SendNotification = false;
    }
  }

  public static preparePlanForSending(plan: any, needReset = true): void {
    if (plan.RetentionTime && !plan.ForwardIncremental && needReset) plan.RetentionTime = TimeSpanMaxValue;
    if (plan.RetentionDelay) plan.RetentionDelay = TimeSpanZeroValue;
    if (plan.ActualRetentionDelay) plan.ActualRetentionDelay = TimeSpanZeroValue;
    if (plan.HybridRetentionPolicy) {
      if (plan.HybridRetentionPolicy.RetentionTime) plan.HybridRetentionPolicy.RetentionTime = TimeSpanZeroValue;
      if (plan.HybridRetentionPolicy.RetentionDelay) plan.HybridRetentionPolicy.RetentionDelay = TimeSpanZeroValue;
    }
  }

  public static updateNewPlanBySelectHostStep(newPlan, selectHostStep: SelectHostStepValue): void {
    const isPasswordChanged = selectHostStep.password !== newPlan.PasswordEncrypted;
    newPlan[isPasswordChanged ? 'Password' : 'PasswordEncrypted'] = selectHostStep.password;
    if (isPasswordChanged && newPlan.PasswordEncrypted) delete newPlan.PasswordEncrypted;

    newPlan.Host = selectHostStep.server;
    newPlan.VCenter = selectHostStep.server;
    newPlan.Login = selectHostStep.login;
  }

  public static updateNewPlanBySelectVirtualMachineStep(newPlan, selectVirtualMachinesStep: SelectVirtualMachinesStepValue): void {
    newPlan.VMBackupType = selectVirtualMachinesStep.type;
    newPlan.VirtualMachines = selectVirtualMachinesStep.type === VirtualMachinesSelectedType.All ? [] : selectVirtualMachinesStep.machines;
  }

  public static updateNewPlanByAppAwareStep(newPlan, appAwareStep: AppAwareStepValue, forceRemove: boolean) {
    if (forceRemove) {
      return void delete newPlan.AppAwareGuestVmIndividualSettings;
    }

    newPlan.AppAwareGuestVmIndividualSettings = appAwareStep.settings.map((item) => {
      return {
        VMName: item.VMName,
        QuiesceType: item.processingMode
      };
    });
  }

  public static updateNewPlanBySelectVirtualDisksStep(newPlan, step: SelectVirtualDisksStepValue, isVMWare = false): void {
    if (isVMWare) {
      return void PlanDataFromStepsHelper.updateVMDisks(newPlan, step);
    }

    PlanDataFromStepsHelper.updateHyperVDisks(newPlan, step);
    PlanDataFromStepsHelper.updateVMDisks(newPlan, { ...step, allDisks: step.allDisks.filter((disk) => disk?.excludes?.length) });
  }

  private static getVolumesFromDiskExcludes(disk: VirtualDisk): VolumeInfoCommunication[] {
    return (disk.excludes || []).reduce((volumes: any[] = [], excStr: string) => {
      const strArr = excStr.split('\\');
      const uuid = strArr[0].substring(strArr[0].lastIndexOf('{') + 1, strArr[0].lastIndexOf('}'));
      const idx = volumes.findIndex((v) => v.Identity === uuid);
      const excludeStr = strArr.slice(1).join('\\');

      if (idx === -1) {
        volumes.push({
          BackupOptions: {
            ExcludeRules: excludeStr ? [{ Folder: '\\' + excludeStr, Mask: '*', Recursive: true, DeleteFolder: true }] : []
          },
          Identity: uuid,
          Length: disk.diskCapacity,
          LengthString: disk.diskCapacityStr,
          StartOffset: 0,
          Enabled: !!excludeStr
        });
      } else {
        if (excludeStr)
          volumes[idx].BackupOptions.ExcludeRules.push({ Folder: '\\' + excludeStr, Mask: '*', Recursive: true, DeleteFolder: true });
        else volumes[idx].Enabled = false;
      }

      return volumes;
    }, []);
  }

  private static updateVMDisks(newPlan, step: SelectVirtualDisksStepValue): void {
    const virtualMachinesEx = [];

    if (step.type === VirtualDisksSelectedType.Selected) {
      step.allDisks.reduce((machines, disk) => {
        const idx = machines.findIndex((machine) => machine.UUID === disk.virtualMachineId);
        const Enabled = step.disks.some((d) => d.uuid === disk.uuid && d.virtualMachineId === disk.virtualMachineId);
        const Volumes = Enabled ? this.getVolumesFromDiskExcludes(disk) : [];

        if (idx === -1) {
          machines.push({
            DiskInfo: [
              {
                Capacity: disk.diskCapacity,
                DiskName: disk.fileName,
                DiskId: disk.uuid,
                Enabled,
                Volumes
              }
            ],
            VMName: disk.virtualMachineName,
            UUID: disk.virtualMachineId
          });
        } else {
          machines[idx].DiskInfo.push({
            Capacity: disk.diskCapacity,
            DiskId: disk.uuid,
            DiskName: disk.fileName,
            Enabled,
            Volumes
          });
        }

        return machines;
      }, virtualMachinesEx);
    }

    newPlan.VirtualMachinesEx = virtualMachinesEx;
  }

  private static updateHyperVDisks(newPlan, step: SelectVirtualDisksStepValue): void {
    const hyperVMachinesEx = [];

    if (step.type === VirtualDisksSelectedType.Selected) {
      step.allDisks.reduce((machines, disk) => {
        const idx = machines.findIndex((machine) => machine.VMUUID === disk.virtualMachineId);
        const diskT = {
          Enabled: step.disks.some((d) => d.uuid === disk.uuid && d.virtualMachineId.toLowerCase() === disk.virtualMachineId),
          Path: disk.fileName,
          // Controller: '', // TODO: MBS-19330
          // DriveIndex: '0', // TODO: MBS-19330
          // ControllerIndex: 'L', // TODO: MBS-19330
          UUID: disk.uuid
          // FullName: disk.fileName, // TODO: MBS-19330
          // ParentDisk: { ParentFullName: '', ParentName: '', RootName: '' } // TODO: MBS-19330
        };

        if (idx === -1) {
          const uselessSnapshotConfig = {
            Id: disk.virtualMachineId,
            ParentConfigurationId: '00000000-0000-0000-0000-000000000000', // TODO: MBS-19330 GuidEmpty from shared
            Name: disk.virtualMachineName
          };

          machines.push({
            VMName: disk.virtualMachineName,
            VMUUID: disk.virtualMachineId,
            SnapshotConfig: {
              ...uselessSnapshotConfig,
              Disks: [diskT]
            }
          });
        } else {
          machines[idx].SnapshotConfig.Disks.push(diskT);
        }

        return machines;
      }, hyperVMachinesEx);
    }

    newPlan.HyperVMachinesEx = hyperVMachinesEx;
  }

  public static updateNewPlanByVirtualAdvancedSettings(
    newPlan,
    advancedStep: AdvancedSettingsVirtualStepValue,
    isHyperV = false,
    needCurrent = false
  ): void {
    const needResetSnapshotTypeByHyperVReasons = isHyperV && needCurrent;
    const needResetSnapshotType = advancedStep.useChangeBlockTracking || needResetSnapshotTypeByHyperVReasons;

    if (needResetSnapshotType) {
      newPlan.SnapshotType = BackupSnapshotType.CurrentState;
    }

    if (!isNil(advancedStep.quiesceType)) {
      newPlan.QuiesceType = advancedStep.quiesceType;
    }

    if (!isNil(advancedStep.storageClass)) {
      newPlan.StorageClass = advancedStep.storageClass;
    }

    if (!isNil(advancedStep.useS3Acceleration)) {
      newPlan.UseS3Acceleration = advancedStep.useS3Acceleration;
    }

    newPlan.UseChangeBlockTracking = !!advancedStep.useChangeBlockTracking;
    newPlan.SyntheticFull =
      advancedStep.syntheticFull &&
      advancedStep.storageClass !== StorageClass.DEEP_ARCHIVE &&
      advancedStep.storageClass !== StorageClass.GLACIER &&
      advancedStep.storageClass !== StorageClass.GLACIER_IR &&
      advancedStep.azureAccessTier !== AzureAccessTierType.Archive;
    newPlan.UseAzureArchive = advancedStep.azureAccessTier === AzureAccessTierType.Archive;
    newPlan.UseAzureCool = advancedStep.azureAccessTier === AzureAccessTierType.Cool;
  }

  public static updateNewPlanBySQLServerInstance(newPlan, sqlStep: SQLServerInstanceStepValue): void {
    newPlan.InstanceName = sqlStep.sqlServerInstanceName;
    newPlan.WindowsAuthentication = sqlStep.authenticationType === SQLServerInstanceAuthenticationTypeEnum['Windows Authentication'];
    newPlan.UserName = sqlStep.userName;
    newPlan.Password = sqlStep.password;
    newPlan.SecureConnection = sqlStep.useSecureConnection;
  }

  public static updateNewPlanBySQLSourceDatabases(newPlan, sqlStep: SQLSourceDatabasesStepValue): void {
    newPlan.DatabaseSelectionType = sqlStep.databaseBackupType;
    newPlan.Databases = sqlStep.selectedDatabases || [];
    newPlan.CopyOnly = sqlStep.copyOnly;
  }
}
