<mbs-sidepanel id="rmm_manage"
               side="right"
               [large]="true"
               showDelete="false"
               (panelClosed)="handlePanelClose()"
               hideSave="true"
               showFooter="true"
               [headerClass]="'-light'"
               [innerContentType]="'tabs'"
               [loadingData]="false">

  <div header-title
       class="mbs-sidepanel_title">
    <div class="mbs-sidepanel_title-name">
      {{ script?.name }}
    </div>
  </div>
  <div loading-data-title>
    <div class="mbs-sidepanel_title-name">
      Loading...
    </div>
  </div>

  <ng-container body>
    <div class="mbs-sidepanel_tabset -vertical">
      <ul mbsTabset
          mode="vertical"
          #activeContent="mbsTabset">
        <li mbsTabsetItem="general">
          <button mbsTabsetLink>
            {{ 'rmm-script-library.module:sidePanel.tabTitles.generalTabName' | i18next }}
          </button>
          <ng-template mbsTabsetContent>
            <mbs-script-library-entry [script]="script">
            </mbs-script-library-entry>
          </ng-template>
        </li>
        <li mbsTabsetItem="scriptBody">
          <button mbsTabsetLink
                  [class.text-muted]="script?.accessLevel === 'Common'"
                  [hidden]="script?.accessLevel === 'Common'">
            {{ 'rmm-script-library.module:sidePanel.tabTitles.scriptBodyTabName' | i18next }}
          </button>
          <ng-template mbsTabsetContent>
            <mbs-script-library-body [script]="script">
            </mbs-script-library-body>
          </ng-template>
        </li>
      </ul>
      <div class="mbs-form_content"
           [class.overflow-y-hidden]="false"
           [class.position-relative]="false"
           [mbsTabsetOutlet]="activeContent">
      </div>
    </div>
  </ng-container>
</mbs-sidepanel>
