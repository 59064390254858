import { Injectable } from '@angular/core';
import { AgentType } from '@models/Computer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ComputersAbstractService } from '@services/computers.service';
import { of } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import * as AppsActions from './computer-apps.actions';
import { ComputerApp } from './computer-apps.model';
import { extractFromAgentId, generateAgentId } from './const';

AgentType;

@Injectable()
export class ComputerAppsEffects {
  loadComputersApps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppsActions.loadComputerApp),
      concatMap(({ hid, appType }) => {
        return this.computers.getComputerApplicationById(hid, appType).pipe(
          switchMap((data: ComputerApp) => {
            const computerApp: ComputerApp = this.serializeComputerApp(data, hid);
            return of(AppsActions.loadComputerAppSuccess({ computerApp }));
          })
        );
      })
    );
  });

  updateComputersApps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppsActions.loadUpdateComputerApp),
      concatMap(({ hid, appType, applicationState }) => {
        return this.computers.updateComputerApplicationById(hid, appType, { applicationState }).pipe(
          switchMap((data: ComputerApp) => {
            const computerApp: ComputerApp = this.serializeComputerApp(data, hid);
            return of(AppsActions.loadComputerAppSuccess({ computerApp }));
          })
        );
      })
    );
  });

  onSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppsActions.loadComputerAppSuccess),
      concatMap(({ computerApp }) => {
        const id = computerApp.id;
        const { hid, type: appType } = extractFromAgentId(id);
        return of(AppsActions.changeMonitoringState({ id, appType, hid, monitoring: computerApp.applicationState }));
      })
    );
  });

  constructor(private actions$: Actions, private computers: ComputersAbstractService) {}

  serializeComputerApp(data, hid): ComputerApp {
    return {
      id: generateAgentId(data.applicationId, hid),
      host: hid,
      ...data
    };
  }
}
