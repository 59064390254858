<mbs-modal [title]="'Disable Backup Password Recovery Service'">
  <div modal-body>
    <form class="mt-3">
      <mbs-checkbox [formControl]="confirmControl"
                    [label]="'I&nbsp;acknowledge that&nbsp;I will not be&nbsp;able to&nbsp;recover encryption passwords without the recovery key'">
      </mbs-checkbox>
    </form>
  </div>

  <ng-container modalFooter>
    <button type="button"
            class="btn btn-danger"
            [disabled]="!confirmControl.value"
            (click)="handleDisable()">Disable</button>
    <button type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="dismiss()">Cancel</button>
  </ng-container>
</mbs-modal>
