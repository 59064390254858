<ng-container *ngIf="!isLoading; else loader">

  <h4>{{ 'onboarding:administrative_account.title' | i18next: { format: 'title' } }}</h4>

  <mbs-alert *ngIf="errorMessage"
             [type]="mbsPopupType.danger"
             [closable]="true"
             [icon]="true"
             (close)="removeError()">
    {{ errorMessage }}
  </mbs-alert>

  <div class="d-flex flex-column align-items-center mt-4">

    <ng-container [ngTemplateOutlet]="choice"
                  [ngTemplateOutletContext]="{ account: findAccountById(accountId.MicrosoftBusiness) }"></ng-container>

    <ng-container [ngTemplateOutlet]="choice"
                  [ngTemplateOutletContext]="{ account: findAccountById(accountId.MicrosoftPersonal) }"></ng-container>

    <ng-container [ngTemplateOutlet]="choice"
                  [ngTemplateOutletContext]="{ account: findAccountById(accountId.GoogleWorkspace) }"></ng-container>

    <ng-container *ngIf="form?.value?.msalData && isMicrosoftProvider(form?.value?.accountId)">

      <div class="mt-3 d-flex flex-column align-items-center">

        <span>
          {{ 'onboarding:administrative_account.signedInAs' | i18next }}
        </span>

        <span class="font-weight-semibold">
          {{ form?.value?.msalData?.account?.username }}
        </span>

        <mbs-button class="mt-1"
                    [isCtrl]="true"
                    (click)="logout()">
          {{ 'buttons:signOut' | i18next }}
        </mbs-button>

      </div>

    </ng-container>

  </div>

</ng-container>

<ng-template #loader>
  <mbs-loader></mbs-loader>
</ng-template>


<ng-template #choice
             let-account="account">

  <div class="col-6 mt-2">
    <mbs-tile [selectable]="true"
              [selected]="account.id === accountIdControl?.value"
              [tileTemplate]="tileTemplate"
              [data]="account"
              (click)="onSelectedAccountChanged(account)"></mbs-tile>
  </div>

  <ng-template #tileTemplate
               let-data>

    <div class="pl-4 d-flex align-items-center mbs-apps-sign-in-step_account"
         [class.-selected]="data.id === accountIdControl?.value">

      <img [alt]="data.text"
           width="26"
           class="mr-3"
           [src]="data.icon"/>

      <span class="font-weight-semibold">
        {{ data.text }}
      </span>

    </div>

  </ng-template>

</ng-template>

<ng-template #googleInstructionsTemplate
             let-modal>

  <swiper [pagination]="true"
          [navigation]="true"
          class="mbs-apps-sign-in-step_instructions-swiper mbs-swiper"
          (swiper)="swiperInstance = $event">

    <ng-template swiperSlide>
      <div class="d-flex flex-column w-75 h-100 justify-content-center align-items-center m-auto">
        <h5>{{ 'onboarding:administrative_account.google_workspace.step1' | i18next: { format: 'title' } }}</h5>

        <a href="https://admin.google.com/ac/owl/list?tab=configuredApps"
           target="_blank">
          {{ 'onboarding:administrative_account.google_workspace.openConsole' | i18next: { format: 'title' } }}
        </a>
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <div class="d-flex flex-column w-75 h-100 justify-content-center align-items-center m-auto">
        <h5>{{ 'onboarding:administrative_account.google_workspace.step2' | i18next: { format: 'title' } }}</h5>

        <img width="75%"
             alt="oauth app name"
             [src]="getImagePath('oauth_app_name.png')" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <div class="d-flex flex-column w-75 h-100 justify-content-center align-items-center m-auto">
        <h5>{{ 'onboarding:administrative_account.google_workspace.step3' | i18next: { format: 'title' } }}</h5>

        <img width="75%"
             alt="configure oauth app"
             [src]="getImagePath('configure_oauth_app.png')" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <div class="d-flex flex-column w-75 h-100 justify-content-center align-items-center m-auto">
        <h5>{{ 'onboarding:administrative_account.google_workspace.step4' | i18next: { format: 'title' } }}</h5>

        <p>{{ 'onboarding:administrative_account.google_workspace.step4_description' | i18next: { format: 'title' } }}</p>

        <img width="75%"
             alt="select oauth apps"
             [src]="getImagePath('select_oauth_apps.png')" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <div class="d-flex flex-column w-75 h-100 justify-content-center align-items-center m-auto">
        <h5>{{ 'onboarding:administrative_account.google_workspace.step5' | i18next: { format: 'title' } }}</h5>

        <p>{{ 'onboarding:administrative_account.google_workspace.step5_description' | i18next: { format: 'title' } }}</p>

        <img width="75%"
             alt="configure access"
             [src]="getImagePath('configure_access.png')" />
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <div class="d-flex flex-column w-75 h-100 justify-content-center align-items-center m-auto">
        <h5>{{ 'onboarding:administrative_account.google_workspace.step6' | i18next: { format: 'title' } }}</h5>

        <a href="https://gsuite.google.com/u/0/marketplace/app/mspbackups/76254656058"
           target="_blank"
           class="mb-3">
          {{ 'onboarding:administrative_account.google_workspace.downloadApp' | i18next: { format: 'title' } }}
        </a>

        <img width="75%"
             alt="download and install"
             [src]="getImagePath('download_and_install.png')">
      </div>
    </ng-template>

    <ng-template swiperSlide>
      <div class="d-flex flex-column w-75 h-100 justify-content-center align-items-center m-auto">
        <span class="mb-2 font-weight-semibold">
          {{ 'onboarding:signIn' | i18next: { format: 'title' } }}
        </span>

        <p>{{ 'onboarding:useGoogleAccount' | i18next: { format: 'title' } }}</p>

        <mbs-button [type]="'primary'"
                    (click)="modal.close(true)">
          {{ 'onboarding:signIn' | i18next: { format: 'title' } }}
        </mbs-button>
      </div>
    </ng-template>

  </swiper>

  <div class="mbs-apps-sign-in-step_skip-instructions">
    <mbs-button [isCtrl]="true"
                [icon]="'fa fa-arrow-circle-right text-lg'"
                (click)="modal.close(true)">
      {{ 'onboarding:administrative_account.google_workspace.skipGoogleInstructions' | i18next: { format: 'title' } }}
    </mbs-button>
  </div>

</ng-template>
