import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import Administrator from '@models/Administrator';
import { MyTreeElements, StorageEncryptionState } from '@models/backup/storages-type';
import Computer from '@models/Computer';
import { IdsForCurrentFormats } from '@modules/wizards/models/backup-to-restore-models';
import { RemoteManagementWizardsService } from '@modules/wizards/services/remote-management-wizards.service';
import { ParamsForTreeData, WizardStepsService } from '@modules/wizards/services/wizard-steps.service';
import { TreeInModalComponent } from '@modules/wizards/steps/components/tree-in-modal/tree-in-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@services/auth.service';
import { linuxPathValidator, winPathValidator } from '@utils/validators';
import { ModalComponent, ModalService, TreeComponent } from 'mbs-ui-kit';
import { noop, Subscription } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mbs-download-to-selected-computer',
  templateUrl: './download-to-selected-computer.component.html'
})
export class DownloadToSelectedComputerComponent implements OnInit {
  public selectedPC: UntypedFormGroup;
  public passwordInputType = 'password';
  public loading: boolean;

  public computerTreeSubscription: Subscription;

  public computerData: Computer;
  public invalidPass: boolean;
  public isEncrypted: boolean;
  public encryptionPasswordHint: string;
  public showHint = false;
  public isLinux: boolean;
  public path: string;
  public restorePoint: string;
  public currentPath: string;
  public joinForInput = '\\';
  public backupVersionUpdated: string;

  public allowedRecovery = false;

  public selectedTreeElement = '';

  public computerTreeData: MyTreeElements[] = [];

  private computerTreeParams: ParamsForTreeData = {
    agentType: 'Backup',
    commandType: 'getComputerContent',
    params: { path: '', offset: 0, limit: null, order: 'DisplayNameAsc' }
  };

  @ViewChild(ModalComponent, { static: true }) baseModal: ModalComponent;
  @ViewChild('treeModalHeader', { static: true, read: TemplateRef }) treeModalHeader: TemplateRef<any>;
  @ViewChildren(TreeComponent) computerTreeComponent!: QueryList<any>;

  public user: Administrator;
  public isNbf: boolean;

  constructor(
    private modalService: ModalService,
    private stepService: WizardStepsService,
    private auth: AuthService,
    public mainService: RemoteManagementWizardsService
  ) {
    this.auth.currentUser.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.allowedRecovery = user.ProviderInfo.PasswordRecoveryEnabled && user.IsProvider;
    });
    this.auth.fetchCurrentUser();
  }

  ngOnInit(): void {
    this.invalidPass = this.baseModal.data.invalidPass;
    this.computerData = this.baseModal.data.computerData;
    const encryptionState = this.baseModal.data.isEncrypted as StorageEncryptionState;
    this.isEncrypted = [StorageEncryptionState.Encrypted, StorageEncryptionState.PossiblyEncrypted].includes(encryptionState);
    this.encryptionPasswordHint = this.baseModal.data.encryptionPasswordHint;
    this.restorePoint = this.baseModal.data.restorePoint;
    const bunchId = this.baseModal.data.bunchId;
    this.isNbf = bunchId !== IdsForCurrentFormats.File && bunchId !== IdsForCurrentFormats.Ibb;
    this.isLinux = this.computerData.os !== 'windows';
    this.backupVersionUpdated = this.baseModal.data.backupVersionUpdated;

    if (this.isEncrypted) {
      this.selectedPC = new UntypedFormGroup({
        restorePath: new FormControl(''),
        selectedPath: new FormControl(this.baseModal.data.selectedPath || '', [
          Validators.required,
          this.isLinux ? linuxPathValidator : winPathValidator
        ]),
        password: new FormControl(
          '',
          encryptionState === StorageEncryptionState.Encrypted ? [Validators.required, this.passwordValidator.bind(this)] : []
        )
      });
      if (this.invalidPass) {
        this.selectedPC.get('password').markAsDirty();
        this.selectedPC.get('password').updateValueAndValidity();
      }
    } else {
      this.selectedPC = new UntypedFormGroup({
        restorePath: new FormControl(''),
        selectedPath: new FormControl('', [Validators.required, this.isLinux ? linuxPathValidator : winPathValidator])
      });
    }

    if (!this.isLinux) {
      if (/^([a-z]:)/.test(this.baseModal.data.currentPath)) {
        this.currentPath = this.baseModal.data.currentPath[0].toUpperCase() + this.baseModal.data.currentPath.slice(1);
      } else this.currentPath = this.baseModal.data.currentPath;
      if (this.currentPath.length === 2) this.currentPath += '\\';
    } else {
      this.joinForInput = '/';
      this.currentPath = this.baseModal.data.currentPath;
    }

    this.path = this.baseModal.data.path;
    this.selectedPC.get('restorePath').setValue(this.path);
  }

  private passwordValidator(control: AbstractControl): ValidationErrors | null {
    return this.invalidPass ? { passwordInvalid: true } : null;
  }

  passwordInputHandler(): void {
    this.invalidPass = false;
  }

  changePasswordType(): void {
    this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
  }

  computerTreeOpenInModal(): void {
    const folder = this.selectedPC.get('selectedPath').value;
    const dataForTree = {
      hid: this.computerData.hid,
      resultFolders: folder ? [folder] : [],
      isEncrypted: false,
      hideFileType: true,
      oldBackupContentVariant: true,
      onlyOneSelect: true,
      params: this.computerTreeParams,
      dataForPath: this.getValueForOperationsWithPath()
    };

    this.modalService
      .openCustom(TreeInModalComponent, { data: dataForTree, collapsing: true, header: this.treeModalHeader })
      .then((result: any) => {
        if (result && result.length) {
          this.selectedPC.get('selectedPath').setValue(result[0]);
        }
      })
      .catch(noop);
  }

  getValueForOperationsWithPath(): any {
    if (this.isLinux) {
      return {
        isLinux: true,
        regExpAfter: /\/[ ]/,
        regExpBefore: /[ ]\//,
        split: '/ ',
        join: this.joinForInput,
        splitForBefore: ' /'
      };
    }

    return {
      isLinux: false,
      regExpAfter: /\\[ ]/,
      regExpBefore: /[ ]\\/,
      split: '\\ ',
      join: this.joinForInput,
      splitForBefore: ' \\'
    };
  }

  showComputerTree(): void {
    this.computerTreeParams.params.path = '';
    this.computerTreeOpenInModal();
  }

  save(): void {
    this.baseModal.save(this.selectedPC.value);
  }

  close(): void {
    this.baseModal.close();
  }

  prepareCurrentPath() {
    // Path should have format {DestinationId}\{Prefix}\{PlanId}\{GenerationId}
    return this.currentPath.split('\\').slice(0, 4).join('\\');
  }
}
