<ng-container *ngTemplateOutlet="isModal ? modalView : pageView">
</ng-container>

<!-- Backup Wizard Modal Window View -->
<ng-template #modalView>

  <mbs-modal [title]="isIncremental ? ('wizards:recurring_incremental_schedule' | i18next) : 'wizards:recurring_full_schedule' | i18next">

    <ng-container modal-body>
      <ng-template pageView>
      </ng-template>
    </ng-container>

    <ng-container modalFooter>
      <button type="button"
              class="btn btn-primary"
              (click)="saveClickHandler()">Save</button>
      <button type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              (click)="cancelClickHandler()">Cancel</button>
    </ng-container>

  </mbs-modal>

</ng-template>


<ng-template #pageView>

  <ng-container [formGroup]="form">
    <div class="mbs-form-group mb-4">
      <div class="row align-items-start">
        <div class="col-4">
          <mbs-select formControlName="recurringPeriod"
                      label="Type"
                      (change)="recurringPeriodChangeHandler($event)"
                      [items]="recurringPeriodItems"
                      [clearable]="false"
                      [searchable]="false"
                      [id]="'modal-recurring-period-select'"></mbs-select>
        </div>

        <div *ngIf="form.get('recurringPeriod').value === 'Daily'"
             class="col-8">
          <div formArrayName="weeklyDayOfWeek"
               class="d-flex justify-content-between">
            <mbs-checkbox *ngFor="let day of daysOfWeekEnumArray; let i = index; let len = count"
                          class="mt-0"
                          [class.mr-3]="i !== len - 1"
                          [formControlName]="i.toString()"
                          [label]="day.label.slice(0,3)"
                          [labelPosition]="'top'"
                          [centerCheckbox]="true"></mbs-checkbox>
          </div>
        </div>
        <div *ngIf="!isIncremental && form.get('recurringPeriod').value === 'Monthly'"
             class="col-8">
          <div class="row">
            <div class="col-6">
              <mbs-select formControlName="occurrence"
                          label="Occurrence"
                          [bindValue]="'value'"
                          [bindLabel]="'label'"
                          [clearable]="false"
                          [id]="'modal-occurrence-select'"
                          [items]="weekNumberEnumArray"
                          [searchable]="false"></mbs-select>
            </div>
            <div class="col-6">
              <mbs-number *ngIf="form.get('occurrence').value === -1"
                          formControlName="dayOfMonthCount"
                          label="Day"
                          min="1"
                          max="31"
                          [id]="'modal-day-of-month-count'"></mbs-number>
              <mbs-select *ngIf="form.get('occurrence').value !== -1"
                          formControlName="dayOfWeek"
                          label="Day"
                          [bindValue]="'value'"
                          [bindLabel]="'label'"
                          [clearable]="false"
                          [id]="'modal-day-of-week-select'"
                          [items]="daysOfWeekEnumArray"
                          [searchable]="false"></mbs-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="form.get('recurringPeriod').value === 'Once'">

      <ng-container *ngIf="isIncremental">
        <div class="mbs-form-group mb-3">
          <div class="row">
            <div class="col-4">
              <div class="mb-2">
                <mbs-input-label [label]="'wizards:occurs_at_label' | i18next"></mbs-input-label>
                <ng-container *ngIf="isAgentTimeUsed"
                              appendLabel>
                  <ng-container *ngTemplateOutlet="isAgentTimeTooltip">
                  </ng-container>
                </ng-container>
              </div>
              <mbs-timepicker formControlName="occursAtTime"
                              [meridian]="true"
                              [needChangeInvalid]="true">
              </mbs-timepicker>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="form.get('recurringPeriod').value === 'Daily'">
      <ng-container *ngIf="isIncremental">
        <div class="mbs-form-group mb-0">
          <div class="row">
            <div class="col-4">
              <mbs-radio formControlName="dailyFreqPeriodOption"
                         label="Occurs at"
                         value="OccursAt"
                         (change)="occursAtChangeHandler($event)"
                         [descriptionOutside]="true"
                         [id]="'modal-occurs-at-radio-1'"
                         [name]="'modal-daily-period-option'">
                <ng-template radioDescription>
                  <div class="row">
                    <div class="col-12">
                      <mbs-timepicker formControlName="occursAtTime"
                                      [meridian]="true"
                                      [needChangeInvalid]="true"></mbs-timepicker>
                    </div>
                  </div>
                </ng-template>
                <ng-container *ngIf="isAgentTimeUsed"
                              appendLabel>
                  <ng-container *ngTemplateOutlet="isAgentTimeTooltip">
                  </ng-container>
                </ng-container>
              </mbs-radio>
            </div>
            <div class="col-8">
              <mbs-radio formControlName="dailyFreqPeriodOption"
                         label="Occurs every"
                         value="OccursEvery"
                         [disabled]="!enableDailyOccursEvery"
                         (change)="occursAtChangeHandler($event)"
                         [descriptionOutside]="true"
                         [id]="'modal-occurs-at-radio-2'"
                         [name]="'modal-daily-period-option'">
                <ng-template radioDescription>
                  <div class="mbs-form-group mb-2">
                    <div class="form-row">
                      <div class="col-4">
                        <mbs-number formControlName="occursEveryCount"
                                    [max]="form.get('occursEveryPeriod').value === 'hours' ? 24 : 1440"
                                    [min]="form.get('occursEveryPeriod').value === 'hours' ? 1 : 10">
                        </mbs-number>
                      </div>
                      <div class="col-5">
                        <mbs-select formControlName="occursEveryPeriod"
                                    (change)="occursEveryPeriodChangeHandler($event)"
                                    [items]="hoursOrMinutesItems"
                                    [bindLabel]="'label'"
                                    [bindValue]="'value'"
                                    [searchable]="false"
                                    [clearable]="false"></mbs-select>
                      </div>
                    </div>
                  </div>

                  <div class="mbs-form-group">
                    <div class="form-row">
                      <div class="col-6">
                        <mbs-timepicker formControlName="occursEveryFromTime"
                                        label="From"
                                        [id]="'modal-occurs-every-from-time'"
                                        [meridian]="true"
                                        [needChangeInvalid]="true"></mbs-timepicker>
                      </div>
                      <div class="col-6">
                        <mbs-timepicker formControlName="occursEveryToTime"
                                        label="To"
                                        [id]="'modal-occurs-every-to-time'"
                                        [meridian]="true"
                                        [needChangeInvalid]="true"></mbs-timepicker>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </mbs-radio>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div *ngIf="isIncremental && form.get('recurringPeriod').value === 'Monthly'"
         class="mbs-form-group">
      <div class="form-row">
        <div class="col-4"
             *ngIf="isModal">
          <!-- Schedule Group Action doesn't have such function.
          Not showing it before the future improvements on backend side -->
          <mbs-select formControlName="occurrence"
                      label="Occurrence"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [clearable]="false"
                      [id]="'modal-occurrence-select'"
                      [items]="weekNumberEnumArray"
                      [searchable]="false"></mbs-select>
        </div>
        <div class="col-4">
          <mbs-number *ngIf="form.get('occurrence').value === -1"
                      formControlName="dayOfMonthCount"
                      label="Day"
                      min="1"
                      max="31"
                      [id]="'modal-day-of-month-count'"></mbs-number>
          <mbs-select *ngIf="form.get('occurrence').value !== -1"
                      formControlName="dayOfWeek"
                      label="Day"
                      [bindValue]="'value'"
                      [bindLabel]="'label'"
                      [clearable]="false"
                      [id]="'modal-day-of-week-select'"
                      [items]="daysOfWeekEnumArray"
                      [searchable]="false"></mbs-select>
        </div>
        <div class="col-4">
          <mbs-timepicker formControlName="occursAtTime"
                          label="Occurs at"
                          [meridian]="true"
                          [needChangeInvalid]="true">
            <ng-container *ngIf="isAgentTimeUsed"
                          appendLabel>
              <ng-container *ngTemplateOutlet="isAgentTimeTooltip">
              </ng-container>
            </ng-container>
          </mbs-timepicker>
        </div>
      </div>
    </div>

    <div *ngIf="validVersionForSchedule"
         class="mbs-form-group">
      <div class="form-row">
        <div class="col-3"
             *ngIf="isModal">
          <label [for]="'schedule-modal-repeat-every-count'"
                 class="mbs-form_label">
            {{ 'wizards:repeat_every_label' | i18next }}
          </label>
          <div class="form-row align-items-center">
            <div class="col-10">
              <mbs-number formControlName="repeatEveryCount"
                          [label]="'wizards:repeat_every_label' | i18next"
                          min="1"
                          [max]="form.get('recurringPeriod').value === 'Daily'
                      ? 31 : form.get('recurringPeriod').value === 'Weekly'
                        ? 52 : form.get('recurringPeriod').value === 'Monthly'
                          ? 12 : 99"
                          [id]="'schedule-modal-repeat-every-count'"></mbs-number>
            </div>
            <span class="col-auto">
              <label [for]="'schedule-modal-repeat-every-count'"
                     class="mbs-form_label mb-0">
                {{getCounterText()}}
              </label>
            </span>
          </div>
        </div>
        <div [ngClass]="form.get('recurringPeriod').value === 'Monthly' ? 'col-4' : 'col-8'">
          <div class="row">
            <div [ngClass]="form.get('recurringPeriod').value === 'Monthly' ? 'col-12' : 'col-6'">
              <mbs-datepicker formControlName="startFromDate"
                              [needSetCurrent]="true"
                              [label]="'wizards:start_from_label' | i18next"></mbs-datepicker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isIncremental"
         class="mbs-form-text">
      Start time {{form.get('occursAtTime').value}} is inherited from recurring schedule settings
    </div>
  </ng-container>

</ng-template>

<ng-template #isAgentTimeTooltip>
  <span class="ml-1 text-info ico ico-InfoCircle"
        [placement]="'top-right auto'"
        [container]="'body'"
        [ngbTooltip]="'rmm.module:groupActions.stepSchedule.scheduleTimeTooltip' | i18next">
  </span>
</ng-template>
