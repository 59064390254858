import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ComputersFacade } from '@root/mbs-ui/src/app/shared/facades/computers.facade';
import { RoutingPath } from '@mbs-ui/app/app-routing-path.enum';
import { ComputersHealthFilterType } from '@models/Computer';
import { AppPersistentStateService } from '@services/app-persistent-state.service';
import { Alert, AlertService, MbsPopupType, MbsSize } from 'mbs-ui-kit';
import { filter, switchMap, tap } from 'rxjs';
import { AbilityService } from 'ability';

@Component({
  selector: 'mbs-unsupported-version',
  templateUrl: './unsupported-version.component.html',
  styleUrls: ['./unsupported-version.component.scss']
})
export class UnsupportedVersionComponent implements AfterViewInit {
  @ViewChild('alertTemplate', { static: true }) alertTemplate: TemplateRef<any>;
  public totalUnsupportedComputers: number;
  public MbsSize = MbsSize;

  public get isMultipleUnsupportedComputers(): boolean {
    return this.totalUnsupportedComputers > 1;
  }

  public get isRedirectEnabled(): boolean {
    return !this.router.url.endsWith(`${RoutingPath.ApComputers}?health=${ComputersHealthFilterType.Unsupported}`);
  }

  constructor(
    private appStorage: AppPersistentStateService,
    private facade: ComputersFacade,
    private alertService: AlertService,
    private ability: AbilityService,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    if (this.appStorage.data.showUnsupportedVersionsMessage && this.ability.can('read', 'MBS')) {
      this.facade
        .getTotalUnsupportedVersionComputers()
        .pipe(
          filter(Boolean),
          tap((total) => (this.totalUnsupportedComputers = total)),
          switchMap(() =>
            this.alertService.alert(new Alert({ content: this.alertTemplate, keepAfterRouteChange: true, type: MbsPopupType.danger }))
          )
        )
        .subscribe(() => this.disableUnsupportedVersionMessage());
    }
  }

  redirect() {
    this.isRedirectEnabled &&
      this.router.navigate([RoutingPath.ApComputers], {
        replaceUrl: true,
        queryParams: {
          health: ComputersHealthFilterType.Unsupported,
          apply: true
        }
      });
  }

  private disableUnsupportedVersionMessage() {
    this.appStorage.data.showUnsupportedVersionsMessage = false;
  }
}
